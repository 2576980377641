import { DashboardAPI } from 'utils/api';

export const convertPdfToImage = async (data) => {
    const { pdfLink = '' } = data;
    return DashboardAPI({
        method: 'POST',
        url: '/convertPdfToImages',
        data: {
            pdfLink: pdfLink ? pdfLink : '',
        },
    }).catch((err) => console.log(err));
};
