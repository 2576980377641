import { isEmpty } from "lodash";
import { getBaseUnits } from "utils/fields";
import convertUnit from "utils/convertUnit";
import { capitalize } from "utils";
import { getLabel } from "./details";

export const getMechanicalsData = ({ modelUnits, heatingCooling, temperatures, domesticHotWater, generation }) => {
    const a1 = "Measurement System";
    const b1 = modelUnits;

    const spacing = [
        {
            [`${a1}`]: "---",
        },
        {
            [`${a1}`]: "---",
        },
        {
            [`${a1}`]: "---",
        },
    ];

    const { heating: { system: heatingSystemObj = {} } = {}, cooling: { system: coolingSystemObj = {} } = {} } =
        heatingCooling;

    const heatingSystemType = Object.keys(heatingSystemObj)[0];
    const coolingSystemType = Object.keys(coolingSystemObj)[0];

    const { [heatingSystemType]: primaryHeating = {} } = heatingSystemObj || {};
    const { [coolingSystemType]: coolingSystem = {} } = coolingSystemObj || {};
    // const primarySystems = ["baseboards", "furnace", "boiler", "combo", "p9Combo"];

    const primaryHeatingResults = ((heatingSystemType) => {
        if (heatingSystemType === "baseboards") {
            return {
                systemType: "Baseboards",
                energySource: "Electricity",
                dualFuelSystem: " ",
                dualFuelSwitchTemp: " ",
                manufacturer: primaryHeating?.manufacturer || " ",
                model: primaryHeating?.model || " ",
                equipmentType: "Baseboards",
                isEnergyStar: "No",
                outputCapacity: convertUnit({
                    value: primaryHeating?.specifications?.outputCapacity?.value || 0,
                    type: "power",
                    inputUnit: getBaseUnits("baseboardsOutputCapacity", modelUnits).trueBase,
                    outputUnit: getBaseUnits("baseboardsOutputCapacity", modelUnits).displayBase,
                }).toFixed(2),
                efficiency: primaryHeating?.specifications?.efficiency || "",
                pilotLight: " ",
                efficientFanPump: primaryHeating?.fansAndPumps?.hasEnergyEfficientMotor || " ",
                flueDiameter: " ",
            };
        } else if (heatingSystemType === "furnace" || heatingSystemType === "boiler") {
            return {
                systemType: capitalize(heatingSystemType) || "",
                energySource: getLabel(`${heatingSystemType}EnergySource`, primaryHeating?.energySource?.id) || "",
                dualFuelSystem: primaryHeating?.isBiEnergy ? "Yes" : "No",
                dualFuelSwitchTemp: convertUnit({
                    value: primaryHeating?.switchoverTemp || 0,
                    type: "temperature",
                    inputUnit: getBaseUnits(`${heatingSystemType}SwitchoverTemp`, modelUnits).trueBase,
                    outputUnit: getBaseUnits(`${heatingSystemType}SwitchoverTemp`, modelUnits).displayBase,
                }).toFixed(0),
                manufacturer: primaryHeating?.manufacturer || "",
                model: primaryHeating?.model || "",
                equipmentType:
                    getLabel(
                        `${heatingSystemType}EquipType`,
                        primaryHeating?.equipmentType?.id,
                        primaryHeating?.energySource?.id
                    ) || "",
                isEnergyStar: primaryHeating?.energyStar ? "Yes" : "No",
                outputCapacity: convertUnit({
                    value: primaryHeating?.specifications?.outputCapacity?.value || 0,
                    type: "power",
                    inputUnit: getBaseUnits(`${heatingSystemType}OutputCapacity`, modelUnits).trueBase,
                    outputUnit: getBaseUnits(`${heatingSystemType}OutputCapacity`, modelUnits).displayBase,
                }).toFixed(2),
                efficiency: primaryHeating?.specifications?.efficiency || " ",
                pilotLight: primaryHeating?.specifications?.pilotLight || 0,
                efficientFanPump: primaryHeating?.fansAndPumps?.hasEnergyEfficientMotor || " ",
                flueDiameter: convertUnit({
                    value: primaryHeating?.specifications?.flueDiameter,
                    type: "length",
                    inputUnit: getBaseUnits(`${heatingSystemType}FlueDiameter`, modelUnits).trueBase,
                    outputUnit: getBaseUnits(`${heatingSystemType}FlueDiameter`, modelUnits).displayBase,
                }).toFixed(2),
            };
        } else if (heatingSystemType === "combo") {
            return {
                energySource: getLabel("comboEnergySource", primaryHeating?.energySource?.id) || "",
                manufacturer: primaryHeating?.manufacturer,
                model: primaryHeating?.model,
                isEnergyStar: primaryHeating?.energyStar ? "Yes" : "No",
                equipmentType:
                    getLabel("comboEquipType", primaryHeating?.equipmentType?.id, primaryHeating?.energySource?.id) ||
                    "",
                outputCapacity: convertUnit({
                    value: primaryHeating?.specifications?.outputCapacity?.value || 0,
                    type: "power",
                    inputUnit: getBaseUnits("comboOutputCapacity", modelUnits).trueBase,
                    outputUnit: getBaseUnits("comboOutputCapacity", modelUnits).displayBase,
                }).toFixed(2),
                efficiency: primaryHeating?.specifications?.efficiency,
                pilotLight: primaryHeating?.specifications?.pilotLight,
                storageTankVolume: convertUnit({
                    value: primaryHeating?.tankAndPump?.tankCapacity?.value || 0,
                    type: "volume",
                    inputUnit: getBaseUnits("comboTankVolume", modelUnits).trueBase,
                    outputUnit: getBaseUnits("comboTankVolume", modelUnits).displayBase,
                }).toFixed(2),
                energyFactor: primaryHeating?.tankAndPump?.energyFactor?.value,
                storageTankLocation: primaryHeating?.tankAndPump?.tankLocation?.id,
                circulationPumpPower: primaryHeating?.tankAndPump?.circulationPump?.value,
                energyEfficientMotorTank: primaryHeating?.tankAndPump?.circulationPump?.energyEfficientMotor,
                energyEfficientPumpPower: `${heatingCooling?.heating?.fansAndPumps?.power?.high} / ${
                    heatingCooling?.heating?.fansAndPumps?.power?.low || 0
                }`,
                energyEfficientMotorPump: heatingCooling?.heating?.fansAndPumps?.hasEnergyEfficientMotor,
            };
        } else if (heatingSystemType === "p9Combo") {
            return {
                manufacturer: primaryHeating?.manufacturer,
                model: primaryHeating?.model,
                numUnits: primaryHeating?.numberOfSystems,
                thermalPerformance: primaryHeating?.thermalPerformanceFactor,
                spaceHeatingEfficiency: primaryHeating?.spaceHeatingEfficiency,
                waterHeatingPerformanceFactor: primaryHeating?.waterHeatingPerformanceFactor,
                annualElectricity: primaryHeating?.annualElectricity,
                spaceHeatingCapacity: primaryHeating?.spaceHeatingCapacity,
                burnerInput: primaryHeating?.burnerInput,
                recoveryEfficiency: primaryHeating?.recoveryEfficiency,
                energyEfficientPumpPower: `${heatingCooling?.heating?.fansAndPumps?.power?.high || 0} W / ${
                    heatingCooling?.heating?.fansAndPumps?.power?.low || 0
                }`,
                energyEfficientMotorPump: heatingCooling?.heating?.fansAndPumps?.hasEnergyEfficientMotor,
            };
        } else if (!heatingSystemType) {
            return {};
        }
    })(heatingSystemType);

    // const primaryHeatingResults = getPrimaryHeatingResults(heatingSystemType);

    const suppSystems = !isEmpty(heatingCooling.supplSystems) ? Object.values(heatingCooling.supplSystems.systems) : {};

    const suppHeatingResults = !isEmpty(suppSystems)
        ? suppSystems.map((system) => {
              return {
                  energySource: getLabel("supplHtgEnergySource", system?.energySource?.id),
                  equipmentType: getLabel("supplHtgEquipType", system.equipmentType.id, system?.energySource?.id),
                  manufacturer: system?.manufacturer || "",
                  model: system?.model || "",
                  usage: getLabel("supplHtgEnergySource", system?.specifications?.usage?.id),
                  locationHeated: getLabel("supplHtgAreaHeated", system.specifications.areaHeated.id),
                  areaHeated: convertUnit({
                      value: system?.specifications?.areaHeated?.value || 0,
                      type: "area",
                      inputUnit: getBaseUnits("supplHtgAreaHeated", modelUnits).trueBase,
                      outputUnit: getBaseUnits("supplHtgAreaHeated", modelUnits).displayBase,
                  }).toFixed(2),
                  outputCapacity: convertUnit({
                      value: system?.specifications?.outputCapacity?.value || 0,
                      type: "power",
                      inputUnit: getBaseUnits("supplHtgOutputCapacity", modelUnits).trueBase,
                      outputUnit: getBaseUnits("supplHtgOutputCapacity", modelUnits).displayBase,
                  }).toFixed(2),
                  efficiency: system?.specifications?.efficiency || "",
                  pilotLight: system?.specifications?.pilotLight || "",
                  flueDiameter: convertUnit({
                      value: system?.specifications?.flue?.diameter,
                      type: "length",
                      inputUnit: getBaseUnits("supplHtgFlueDiameter", modelUnits).trueBase,
                      outputUnit: getBaseUnits("supplHtgFlueDiameter", modelUnits).displayBase,
                  }).toFixed(2),
                  damperClosed: system?.specifications?.damperClosed,
                  epaCsa: system?.epaCsa ? "Yes" : "No",
              };
          })
        : [];

    const { radiantHeating = {}, additionalOpenings: { openings = {} } = {} } = heatingCooling || {};

    const radiantHeatingResults = !isEmpty(radiantHeating)
        ? {
              atticCeiling: `${convertUnit({
                  value: radiantHeating?.attic?.temperature,
                  type: "temperature",
                  inputUnit: getBaseUnits("radiantAtticTemp", modelUnits).trueBase,
                  outputUnit: getBaseUnits("radiantAtticTemp", modelUnits).displayBase,
              }).toFixed(1)} / ${radiantHeating?.attic?.area || 0}%`,
              flatRoof: `${convertUnit({
                  value: radiantHeating?.flatRoof?.temperature,
                  type: "temperature",
                  inputUnit: getBaseUnits("radiantAtticTemp", modelUnits).trueBase,
                  outputUnit: getBaseUnits("radiantAtticTemp", modelUnits).displayBase,
              }).toFixed(1)} / ${radiantHeating?.flatRoof?.area || 0}%`,
              floorAboveCrawlspace: `${convertUnit({
                  value: radiantHeating?.aboveCrawlspace?.temperature,
                  type: "temperature",
                  inputUnit: getBaseUnits("radiantAtticTemp", modelUnits).trueBase,
                  outputUnit: getBaseUnits("radiantAtticTemp", modelUnits).displayBase,
              }).toFixed(1)} / ${radiantHeating?.aboveCrawlspace?.area || 0}%`,
              slabOnGrade: `${convertUnit({
                  value: radiantHeating?.slabOnGrade?.temperature,
                  type: "temperature",
                  inputUnit: getBaseUnits("radiantAtticTemp", modelUnits).trueBase,
                  outputUnit: getBaseUnits("radiantAtticTemp", modelUnits).displayBase,
              }).toFixed(1)} / ${radiantHeating?.slabOnGrade?.area || 0}%`,
              floorsAboveBasement: `${convertUnit({
                  value: radiantHeating?.aboveBasementFloor?.temperature,
                  type: "temperature",
                  inputUnit: getBaseUnits("radiantAtticTemp", modelUnits).trueBase,
                  outputUnit: getBaseUnits("radiantAtticTemp", modelUnits).displayBase,
              }).toFixed(1)} / ${radiantHeating?.aboveBasementFloor?.area || 0}%`,
              basement: `${convertUnit({
                  value: radiantHeating?.basement?.temperature,
                  type: "temperature",
                  inputUnit: getBaseUnits("radiantAtticTemp", modelUnits).trueBase,
                  outputUnit: getBaseUnits("radiantAtticTemp", modelUnits).displayBase,
              }).toFixed(1)} / ${radiantHeating?.basement?.area || 0}%`,
          }
        : {};

    const additionalOpeningsResults = !isEmpty(openings)
        ? {
              opening1EquipmentType: getLabel("opening1EquipType", openings?.opening0?.type?.id),
              opening1FlueDiameter:
                  openings?.opening0?.flueDiameter && openings?.opening0?.type?.id > 0
                      ? convertUnit({
                            value: openings?.opening0?.flueDiameter,
                            type: "length",
                            inputUnit: getBaseUnits("opening1FlueDiameter", modelUnits).trueBase,
                            outputUnit: getBaseUnits("opening1FlueDiameter", modelUnits).displayBase,
                        }).toFixed(2)
                      : "N/A",
              opening1DamperClosed:
                  openings?.opening0?.type?.id == 0 ? "N/A" : openings?.opening0?.damperClosed ? "Yes" : "No",
              opening1Count: Math.min(openings?.opening0?.numOpenings || 0, 1),

              opening2EquipmentType: getLabel("opening1EquipType", openings?.opening1?.type?.id),
              opening2FlueDiameter:
                  openings?.opening1?.flueDiameter && openings?.opening1?.type?.id > 0
                      ? convertUnit({
                            value: openings?.opening1?.flueDiameter,
                            type: "length",
                            inputUnit: getBaseUnits("opening1FlueDiameter", modelUnits).trueBase,
                            outputUnit: getBaseUnits("opening1FlueDiameter", modelUnits).displayBase,
                        }).toFixed(2)
                      : "N/A",
              opening2DamperClosed:
                  openings?.opening1?.type?.id == 0 ? "N/A" : openings?.opening1?.damperClosed ? "Yes" : "No",
              opening2Count: Math.min(openings?.opening1?.numOpenings || 0, 1),

              opening3EquipmentType: getLabel("opening1EquipType", openings?.opening2?.type?.id),
              opening3FlueDiameter:
                  openings?.opening2?.flueDiameter && openings?.opening2?.type?.id > 0
                      ? convertUnit({
                            value: openings?.opening2?.flueDiameter,
                            type: "length",
                            inputUnit: getBaseUnits("opening1FlueDiameter", modelUnits).trueBase,
                            outputUnit: getBaseUnits("opening1FlueDiameter", modelUnits).displayBase,
                        }).toFixed(2)
                      : "N/A",
              opening3DamperClosed:
                  openings?.opening2?.type?.id == 0 ? "N/A" : openings?.opening2?.damperClosed ? "Yes" : "No",
              opening3Count: Math.min(openings?.opening2?.numOpenings || 0, 1),
          }
        : {};

    const coolingSystemResults = ((coolingSystemType) => {
        const {
            specifications: {
                coolingEfficiency: { value: coolEffValue, isCop: coolIsCop } = {},
                heatingEfficiency: { value: heatEffValue, isCop: heatIsCop } = {},
            } = {},
            temperature: { cutoffType: { id: cutoffId, value: cutoffValue = 0 } = {} } = {},
        } = coolingSystem || {};

        const convertedCutoff = convertUnit({
            value: cutoffValue || 0,
            type: "temperature",
            inputUnit: getBaseUnits("ashpTempCutoff", modelUnits).trueBase,
            outputUnit: getBaseUnits("ashpTempCutoff", modelUnits).displayBase,
        }).toFixed(0);

        const cutoffLabel =
            cutoffId === 1
                ? `Restricted @ ${convertedCutoff} ˚${getBaseUnits("ashpTempCutoff", modelUnits).displayBase}`
                : getLabel("ashpTempCutoff", cutoffId);

        if (coolingSystemType === "airConditioning") {
            return {
                equipmentType: getLabel("acEquipType", coolingSystem?.equipmentType?.id),
                manufacturer: coolingSystem?.manufacturer || "",
                model: coolingSystem?.model || "",
                isEnergyStar: coolingSystem?.energyStar ? "Yes" : "No",
                capacity: convertUnit({
                    value: coolingSystem?.specifications?.outputCapacity?.value || 0,
                    type: "power",
                    inputUnit: getBaseUnits("acOutputCapacity", modelUnits).trueBase,
                    outputUnit: getBaseUnits("acOutputCapacity", modelUnits).displayBase,
                }).toFixed(2),
                efficiency: `${coolEffValue.toFixed(1)} (${coolIsCop ? "COP" : "SEER"})`,
                coolingDuctedToBasement: temperatures?.basement?.cooled ? "Yes" : "No",
            };
        } else if (coolingSystemType === "airHeatPump") {
            return {
                equipmentType: `${getLabel("ashpEquipType", coolingSystem?.equipmentType?.id)} (${getLabel(
                    "ashpFunction",
                    coolingSystem?.function?.id
                )})`,
                unitFunction: coolingSystem?.function.id,
                manufacturer: coolingSystem?.manufacturer || " ",
                model: coolingSystem?.model || " ",
                ahri: coolingSystem?.ahri || " ",
                isEnergyStar: coolingSystem?.energyStar ? "Yes" : "No",
                capacity: convertUnit({
                    value: coolingSystem?.specifications?.outputCapacity?.value || 0,
                    type: "power",
                    inputUnit: getBaseUnits("ashpOutputCapacity", modelUnits).trueBase,
                    outputUnit: getBaseUnits("ashpOutputCapacity", modelUnits).displayBase,
                }).toFixed(2),
                heatingEfficiency: `${heatEffValue.toFixed(1)} (${heatIsCop ? "COP" : "HSPF"})`,
                coolingEfficiency: `${coolEffValue.toFixed(1)} (${coolIsCop ? "COP" : "SEER"})`,
                tempCutoffType: cutoffLabel,
                coolingDuctedToBasement: temperatures?.basement?.cooled ? "Yes" : "No",
            };
        } else if (coolingSystemType === "waterHeatPump" || coolingSystemType === "groundHeatPump") {
            return {
                equipmentType: getLabel("wshpFunction", coolingSystem?.function?.id),
                unitFunction: coolingSystem?.function.id,
                manufacturer: coolingSystem?.manufacturer || " ",
                model: coolingSystem?.model || " ",
                ahri: coolingSystem?.ahri || " ",
                isEnergyStar: coolingSystem?.energyStar ? "Yes" : "No",
                capacity: convertUnit({
                    value: coolingSystem?.specifications?.outputCapacity?.value || 0,
                    type: "power",
                    inputUnit: getBaseUnits("wshpOutputCapacity", modelUnits).trueBase,
                    outputUnit: getBaseUnits("wshpOutputCapacity", modelUnits).displayBase,
                }).toFixed(2),
                heatingEfficiency: `${heatEffValue.toFixed(1)} (${heatIsCop ? "COP" : "HSPF"})`,
                coolingEfficiency: `${coolEffValue.toFixed(1)} (${coolIsCop ? "COP" : "SEER"})`,
                tempCutoffType: cutoffLabel,
                coolingDuctedToBasement: temperatures?.basement?.cooled ? "Yes" : "No",
            };
        }
    })(coolingSystemType);

    const primaryDHW = domesticHotWater?.primarySystem || {};
    const secondaryDHW = domesticHotWater?.secondarySystem || {};

    const primaryDHWResults =
        isEmpty(primaryDHW) || ["combo", "p9Combo"].includes(heatingSystemType)
            ? {
                  energySource: ["combo", "p9Combo"].includes(heatingSystemType) ? "Controlled by Combo System" : "",
              }
            : {
                  energySource: getLabel("dhwEnergySource", primaryDHW?.energySource?.id),
                  manufacturer: primaryDHW?.manufacturer || "",
                  model: primaryDHW?.model || "",
                  isEnergyStar: primaryDHW?.energyStar ? "Yes" : "No",
                  tankType: getLabel("dhwTankType", primaryDHW?.tankType?.id, primaryDHW?.energySource?.id),
                  tankLocation: getLabel("dhwTankLocation", primaryDHW?.tankLocation?.id),
                  tankVolume: convertUnit({
                      value: primaryDHW?.tankVolume?.value || 0,
                      type: "volume",
                      inputUnit: getBaseUnits("dhwTankVolume", modelUnits).trueBase,
                      outputUnit: getBaseUnits("dhwTankVolume", modelUnits).displayBase,
                  }).toFixed(2),
                  energyFactor: primaryDHW?.energyFactor?.value || "",
                  insulatingBlanket: convertUnit({
                      value: primaryDHW?.insulatingBlanket || 0,
                      type: "thermalResistance",
                      inputUnit: getBaseUnits("dhwInsulatingBlanket", modelUnits).trueBase,
                      outputUnit: getBaseUnits("dhwInsulatingBlanket", modelUnits).displayBase,
                  }).toFixed(2),
                  integratedHeatPumpCOP: primaryDHW?.heatPumpCOP || "",
                  standbyHeatLossCapacity: convertUnit({
                      value: primaryDHW?.standby?.inputCapacity || 0,
                      type: "power",
                      inputUnit: getBaseUnits("dhwInputCapacity", modelUnits).trueBase,
                      outputUnit: getBaseUnits("dhwInputCapacity", modelUnits).displayBase,
                  }).toFixed(2),
                  tankThermalEfficiency: primaryDHW?.standby?.thermalEfficiency || "",
                  combinedFlue: [0, 8].includes(primaryDHW?.energySource?.id)
                      ? "N/A"
                      : primaryDHW?.combinedFlue
                      ? "Yes"
                      : "No",
                  pilotEnergy: convertUnit({
                      value: primaryDHW?.pilotEnergy || 0,
                      type: "dailyEnergy",
                      inputUnit: getBaseUnits("dhwPilotEnergy", modelUnits).trueBase,
                      outputUnit: getBaseUnits("dhwPilotEnergy", modelUnits).displayBase,
                  }).toFixed(2),
              };

    const isSolarDHW = primaryDHW?.energySource?.id == 8;

    const solarDHWResults = isSolarDHW
        ? {
              manufacturer: primaryDHW?.manufacturer,
              model: primaryDHW?.model,
              performanceRating: primaryDHW?.solar?.rating,
              slope: convertUnit({
                  value: primaryDHW?.solar?.slope,
                  type: "angle",
                  inputUnit: getBaseUnits("dhwSolarSlope", modelUnits).trueBase,
                  outputUnit: getBaseUnits("dhwSolarSlope", modelUnits).displayBase,
              })
                  .toFixed(0)
                  .replace("0", ""),
              azimuth: convertUnit({
                  value: primaryDHW?.solar?.azimuth,
                  type: "angle",
                  inputUnit: getBaseUnits("dhwSolarAzimuth", modelUnits).trueBase,
                  outputUnit: getBaseUnits("dhwSolarAzimuth", modelUnits).displayBase,
              }),
          }
        : {};

    const hasPrimaryDWHR = !!primaryDHW?.drainWaterHeatRecovery?.manufacturer;
    const primaryDWHRResults = {
        manufacturer: primaryDHW?.drainWaterHeatRecovery?.manufacturer || "",
        model: primaryDHW?.drainWaterHeatRecovery?.model || "",
        preheatShowerTank: !hasPrimaryDWHR
            ? ""
            : primaryDHW?.drainWaterHeatRecovery?.preheatShowerTank
            ? "Tank and shower"
            : "Tank only",
        murbUnitsLow: domesticHotWater?.murb?.murbNumDwhrSystems?.lowEfficiency || "",
        murbUnitsHigh: domesticHotWater?.murb?.murbNumDwhrSystems?.highEfficiency || "",
    };

    const secondaryDHWResults =
        isEmpty(secondaryDHW) || ["combo", "p9Combo"].includes(heatingSystemType)
            ? {
                  energySource: ["combo", "p9Combo"].includes(heatingSystemType) ? "Controlled by Combo System" : "",
              }
            : {
                  energySource: getLabel("dhwEnergySource", secondaryDHW?.energySource?.id),
                  manufacturer: secondaryDHW?.manufacturer || "",
                  model: secondaryDHW?.model || "",
                  isEnergyStar: secondaryDHW?.energyStar ? "Yes" : "No",
                  tankType: getLabel("dhwTankType", secondaryDHW?.tankType?.id, secondaryDHW?.energySource?.id),
                  tankLocation: getLabel("dhwTankLocation", secondaryDHW?.tankLocation?.id),
                  tankVolume: convertUnit({
                      value: secondaryDHW?.tankVolume?.value || 0,
                      type: "volume",
                      inputUnit: getBaseUnits("dhwTankVolume", modelUnits).trueBase,
                      outputUnit: getBaseUnits("dhwTankVolume", modelUnits).displayBase,
                  }).toFixed(2),
                  energyFactor: secondaryDHW?.energyFactor?.value || "",
                  insulatingBlanket: convertUnit({
                      value: secondaryDHW?.insulatingBlanket || 0,
                      type: "thermalResistance",
                      inputUnit: getBaseUnits("dhwInsulatingBlanket", modelUnits).trueBase,
                      outputUnit: getBaseUnits("dhwInsulatingBlanket", modelUnits).displayBase,
                  }).toFixed(2),
                  integratedHeatPumpCOP: secondaryDHW?.heatPumpCOP || "",
                  standbyHeatLossCapacity: convertUnit({
                      value: secondaryDHW?.standby?.inputCapacity || 0,
                      type: "power",
                      inputUnit: getBaseUnits("dhwInputCapacity", modelUnits).trueBase,
                      outputUnit: getBaseUnits("dhwInputCapacity", modelUnits).displayBase,
                  }).toFixed(2),
                  tankThermalEfficiency: secondaryDHW?.standby?.thermalEfficiency || "",
                  combinedFlue: [0, 8].includes(secondaryDHW?.energySource?.id)
                      ? "N/A"
                      : secondaryDHW?.combinedFlue
                      ? "Yes"
                      : "No",
                  pilotEnergy: convertUnit({
                      value: secondaryDHW?.pilotEnergy || 0,
                      type: "dailyEnergy",
                      inputUnit: getBaseUnits("dhwPilotEnergy", modelUnits).trueBase,
                      outputUnit: getBaseUnits("dhwPilotEnergy", modelUnits).displayBase,
                  }).toFixed(2),
              };

    const hasSecondaryDWHR = !!secondaryDHW?.drainWaterHeatRecovery?.manufacturer;
    const secondaryDWHRResults = !isEmpty(secondaryDHW)
        ? {
              manufacturer: secondaryDHW?.drainWaterHeatRecovery?.manufacturer || "",
              model: secondaryDHW?.drainWaterHeatRecovery?.model || "",
              preheatShowerTank: !hasSecondaryDWHR
                  ? ""
                  : secondaryDHW?.drainWaterHeatRecovery?.preheatShowerTank
                  ? "Tank and shower"
                  : "Tank only",
              murbUnitsLow: "",
              murbUnitsHigh: "",
          }
        : {};

    const { pvSystems = {} } = generation || {};
    const renewableEnergySystemsResults = Object.keys(pvSystems).map((sysKey, ind) => {
        const system = pvSystems[sysKey] || {};

        return {
            systemType: " ",
            manufacturer: system?.manufacturer || "",
            model: system?.model || "",
            arrayArea: convertUnit({
                value: system?.array?.area,
                type: "area",
                inputUnit: getBaseUnits("solarArrayArea", modelUnits).trueBase,
                outputUnit: getBaseUnits("solarArrayArea", modelUnits).displayBase,
            }).toFixed(2),
            slope: convertUnit({
                value: system?.array?.slope,
                type: "angle",
                inputUnit: getBaseUnits("solarArraySlope", modelUnits).trueBase,
                outputUnit: getBaseUnits("solarArraySlope", modelUnits).displayBase,
            }).toFixed(1),
            azimuth: convertUnit({
                value: system?.array?.azimuth,
                type: "angle",
                inputUnit: getBaseUnits("solarArrayAzimuth", modelUnits).trueBase,
                outputUnit: getBaseUnits("solarArrayAzimuth", modelUnits).displayBase,
            }).toFixed(1),
            moduleType: getLabel("solarModuleType", system?.module?.type?.id) || "User Specified",
            moduleEfficiency: system?.module?.efficiency || "",
            cellTemperatures: system?.module?.cellTemperature || "",
            coefficientOfEfficiency: system?.module?.coefficientOfEfficiency || "",
            miscLosses: system?.effAndLosses?.miscLosses || "",
            otherPowerLosses: system?.effAndLosses?.otherPowerLosses || "",
            inverterEfficiency: system?.effAndLosses?.inverterEfficiency || "",
            gridAbsorptionRate: system?.effAndLosses?.gridAbsorptionRate || "",
        };
    });

    const renewableSummaryResults = {
        windEnergyContribution: generation?.windEnergyContribution || "",
        solarReady: generation?.solarReady || "",
        pvCapacity: generation?.pvCapacity || "",
        batteryStorage: generation?.hasBatteryStorage || "",
    };

    const getPrimaryHeatingObjectData = () => {
        if (heatingSystemType === "baseboards" || heatingSystemType === "furnace" || heatingSystemType === "boiler") {
            return [
                { [`${a1}`]: "System Type", [`${b1}`]: primaryHeatingResults.systemType || " " },
                { [`${a1}`]: "Energy Source", [`${b1}`]: primaryHeatingResults.energySource || " " },
                { [`${a1}`]: "Dual fuel system (Bi-energy)", [`${b1}`]: primaryHeatingResults.dualFuelSystem || " " },
                {
                    [`${a1}`]: `Dual fuel switchover temp (${
                        getBaseUnits("boilerSwitchoverTemp", modelUnits).displayBase
                    })`,
                    [`${b1}`]: primaryHeatingResults.dualFuelSwitchTemp || " ",
                },
                { [`${a1}`]: "Manufacturer", [`${b1}`]: primaryHeatingResults.manufacturer || " " },
                { [`${a1}`]: "Model #", [`${b1}`]: primaryHeatingResults.model || " " },
                { [`${a1}`]: "Equipment type", [`${b1}`]: primaryHeatingResults.equipmentType || " " },
                { [`${a1}`]: "ENERGY STAR certified", [`${b1}`]: primaryHeatingResults.isEnergyStar || " " },
                {
                    [`${a1}`]: `Output Capacity (${getBaseUnits("furnaceOutputCapacity", modelUnits).displayBase})`,
                    [`${b1}`]: primaryHeatingResults.outputCapacity || " ",
                },
                { [`${a1}`]: "Efficiency", [`${b1}`]: primaryHeatingResults.efficiency || "" },
                { [`${a1}`]: "Pilot light", [`${b1}`]: primaryHeatingResults.pilotLight || "" },
                {
                    [`${a1}`]: `Flue diameter (${getBaseUnits("boilerFlueDiameter", modelUnits).displayBase})`,
                    [`${b1}`]: primaryHeatingResults.flueDiameter || "",
                },
            ];
        } else if (heatingSystemType === "combo") {
            return [
                { [`${a1}`]: "Energy Source", [`${b1}`]: primaryHeatingResults.energySource || " " },
                { [`${a1}`]: "Manufacturer", [`${b1}`]: primaryHeatingResults.manufacturer || " " },
                { [`${a1}`]: "Model #", [`${b1}`]: primaryHeatingResults.model || " " },
                { [`${a1}`]: "ENERGY STAR certified", [`${b1}`]: primaryHeatingResults.isEnergyStar || " " },
                { [`${a1}`]: "Equipment type", [`${b1}`]: primaryHeatingResults.equipmentType || " " },
                {
                    [`${a1}`]: `Output Capacity (${getBaseUnits("comboOutputCapacity", modelUnits).displayBase})`,
                    [`${b1}`]: primaryHeatingResults.outputCapacity || " ",
                },
                { [`${a1}`]: "Efficiency", [`${b1}`]: primaryHeatingResults.efficiency || " " },
                { [`${a1}`]: "Pilot light", [`${b1}`]: primaryHeatingResults.pilotLight || " " },
                {
                    [`${a1}`]: `Storage Tank Volume (${getBaseUnits("comboTankVolume", modelUnits).displayBase})`,
                    [`${b1}`]: primaryHeatingResults.storageTankVolume || " ",
                },
                { [`${a1}`]: "Energy factor", [`${b1}`]: primaryHeatingResults.energyFactor || " " },
                { [`${a1}`]: "Storage tank location", [`${b1}`]: primaryHeatingResults.storageTankLocation || " " },
                {
                    [`${a1}`]: "Cirulation pump power (W)",
                    [`${b1}`]: primaryHeatingResults.circulationPumpPower || " ",
                },
                {
                    [`${a1}`]: "Energy efficient pump motor",
                    [`${b1}`]: primaryHeatingResults.energyEfficientMotorTank || " ",
                },
                {
                    [`${a1}`]: "Energy efficient fan / pump power (High/low, W)",
                    [`${b1}`]: primaryHeatingResults.energyEfficientPumpPower || " ",
                },
                {
                    [`${a1}`]: "Energy efficient pump motor",
                    [`${b1}`]: primaryHeatingResults.energyEfficientMotorPump || " ",
                },
            ];
        } else if (heatingSystemType === "p9Combo") {
            return [
                { [`${a1}`]: "CSA P.9-11 Manufacturer", [`${b1}`]: primaryHeatingResults.manufacturer || " " },
                { [`${a1}`]: "CSA P.9-11 Model number", [`${b1}`]: primaryHeatingResults.model || " " },
                { [`${a1}`]: "CSA P.9-11 # of units", [`${b1}`]: primaryHeatingResults.numUnits || " " },
                { [`${a1}`]: "Thermal Performance Factor", [`${b1}`]: primaryHeatingResults.thermalPerformance || " " },
                {
                    [`${a1}`]: "Composite Space Heating Efficiency (%)",
                    [`${b1}`]: primaryHeatingResults.spaceHeatingEfficiency || " ",
                },
                {
                    [`${a1}`]: "Water Heating Perfomance Factor",
                    [`${b1}`]: primaryHeatingResults.waterHeatingPerformanceFactor || " ",
                },
                {
                    [`${a1}`]: "Annual Electricity Consumption (kWh)",
                    [`${b1}`]: primaryHeatingResults.annualElectricity || " ",
                },
                { [`${a1}`]: "Space Heating Capacity (W)", [`${b1}`]: primaryHeatingResults.spaceHeatingCapacity } ||
                    {},
                { [`${a1}`]: "Nominal Burner Input (W)", [`${b1}`]: primaryHeatingResults.burnerInput || " " },
                { [`${a1}`]: "Recovery Efficiency (%)", [`${b1}`]: primaryHeatingResults.recoveryEfficiency || " " },
                {
                    [`${a1}`]: "Energy efficient fan / pump power (W)",
                    [`${b1}`]: primaryHeatingResults.energyEfficientPumpPower || " ",
                },
                {
                    [`${a1}`]: "Energy efficient pump motor",
                    [`${b1}`]: primaryHeatingResults.energyEfficientMotorPump || " ",
                },
            ];
        } else {
            return [{}];
        }
    };

    const primaryHeatingObjectData = getPrimaryHeatingObjectData();

    let suppSystemList = [`${b1}`, ..."CDEFGHIJKLMNOPQRSTUVWXYZ".split("")].slice(0, suppHeatingResults.length);

    const suppHeatingObjectData = !isEmpty(suppHeatingResults)
        ? suppSystemList.map((system, ind) => {
              const result = suppHeatingResults[ind];

              return [
                  { [`${a1}`]: "Energy source", [`${system}`]: result.energySource || " " },
                  { [`${a1}`]: "Equipment type", [`${system}`]: result.equipmentType || " " },
                  { [`${a1}`]: "Manufacturer", [`${system}`]: result.manufacturer || " " },
                  { [`${a1}`]: "Model #", [`${system}`]: result.model || " " },
                  { [`${a1}`]: "Usage", [`${system}`]: result.usage || " " },
                  { [`${a1}`]: "Location heated", [`${system}`]: result.locationHeated || " " },
                  {
                      [`${a1}`]: `Floor area heated (${getBaseUnits("supplHtgAreaHeated", modelUnits).displayBase})`,
                      [`${system}`]: result.areaHeated || " ",
                  },
                  {
                      [`${a1}`]: `Output Capacity (${getBaseUnits("supplHtgOutputCapacity", modelUnits).displayBase})`,
                      [`${system}`]: result.outputCapacity || " ",
                  },
                  { [`${a1}`]: "Steady state efficiency (%)", [`${system}`]: result.efficiency || " " },
                  { [`${a1}`]: "Presence of pilot", [`${system}`]: result.pilotLight || " " },
                  {
                      [`${a1}`]: `Flue diameter (${getBaseUnits("supplHtgFlueDiameter", modelUnits).displayBase})`,
                      [`${system}`]: result.flueDiameter || " ",
                  },
                  { [`${a1}`]: "Damper closed", [`${system}`]: result.damperClosed || " " },
                  { [`${a1}`]: "EPA/CSA rated for high efficiency", [`${system}`]: result.epaCsa || " " },
              ];
          })
        : [{}];

    // make this variable
    const itemArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    const suppHeatingData = !isEmpty(suppHeatingResults)
        ? itemArray.map((objectData, ind) => {
              return suppHeatingObjectData.reduce((acc, item) => {
                  return { ...acc, ...item[ind] };
              }, {});
          })
        : [
              { [`${a1}`]: "Energy source", [`${b1}`]: " " },
              { [`${a1}`]: "Equipment type", [`${b1}`]: " " },
              { [`${a1}`]: "Manufacturer", [`${b1}`]: " " },
              { [`${a1}`]: "Model #", [`${b1}`]: " " },
              { [`${a1}`]: "Usage", [`${b1}`]: " " },
              { [`${a1}`]: "Location heated", [`${b1}`]: " " },
              {
                  [`${a1}`]: `Floor area heated (${getBaseUnits("supplHtgAreaHeated", modelUnits).displayBase})`,
                  [`${b1}`]: "",
              },
              { [`${a1}`]: "Steady state efficiency (%)", [`${b1}`]: " " },
              { [`${a1}`]: "Presence of pilot", [`${b1}`]: " " },
              {
                  [`${a1}`]: `Flue diameter (${getBaseUnits("supplHtgFlueDiameter", modelUnits).displayBase})`,
                  [`${b1}`]: "",
              },
              { [`${a1}`]: "Damper closed", [`${b1}`]: " " },
              { [`${a1}`]: "EPA/CSA rated for high efficiency", [`${b1}`]: " " },
          ];

    const numSystems = Math.min(Math.max(Object.keys(pvSystems).length, suppSystems.length), 1);

    const colsArr = [b1, "C", "D", "E", "F"];
    const suppSystemsRow = !isEmpty(suppHeatingResults)
        ? suppSystemList.reduce((acc, system, ind) => {
              return { ...acc, [`${system}`]: `System ${ind + 1}` };
          }, {})
        : { [`${b1}`]: `System 1` };

    const radiantHeatingObjectData = [
        {
            [`${a1}`]: `Attic ceiling (˚${getBaseUnits("radiantAtticTemp", modelUnits).displayBase} / % of total area)`,
            [`${b1}`]: radiantHeatingResults.atticCeiling || " ",
        },
        {
            [`${a1}`]: `Flat roof  (˚${getBaseUnits("radiantAtticTemp", modelUnits).displayBase} / % of total area)`,
            [`${b1}`]: radiantHeatingResults.flatRoof || " ",
        },
        {
            [`${a1}`]: `Floor above crawl space  (˚${
                getBaseUnits("radiantAtticTemp", modelUnits).displayBase
            } / % of total area)`,
            [`${b1}`]: radiantHeatingResults.floorAboveCrawlspace || " ",
        },
        {
            [`${a1}`]: `Slab-on-grade  (˚${
                getBaseUnits("radiantAtticTemp", modelUnits).displayBase
            } / % of total area)`,
            [`${b1}`]: radiantHeatingResults.slabOnGrade || " ",
        },
        {
            [`${a1}`]: `Floors above basement  (˚${
                getBaseUnits("radiantAtticTemp", modelUnits).displayBase
            } / % of total area)`,
            [`${b1}`]: radiantHeatingResults.floorsAboveBasement || " ",
        },
        {
            [`${a1}`]: `Basement (˚${getBaseUnits("radiantAtticTemp", modelUnits).displayBase} / % of total area)`,
            [`${b1}`]: radiantHeatingResults.basement || " ",
        },
    ];

    const additionalOpeningsObjectData = [
        { [`${a1}`]: "[1] Equipment type", [`${b1}`]: additionalOpeningsResults.opening1EquipmentType || " " },
        {
            [`${a1}`]: `[1] Flue diameter (${getBaseUnits("opening1FlueDiameter", modelUnits).displayBase})`,
            [`${b1}`]: additionalOpeningsResults.opening1FlueDiameter || " ",
        },
        { [`${a1}`]: "[1] Damper Closed?", [`${b1}`]: additionalOpeningsResults.opening1DamperClosed || " " },
        { [`${a1}`]: "[1] Count", [`${b1}`]: additionalOpeningsResults.opening1Ccount || " " },
        { [`${a1}`]: "[2] Equipment type", [`${b1}`]: additionalOpeningsResults.opening2EquipmentType || " " },
        {
            [`${a1}`]: `[2] Flue diameter (${getBaseUnits("opening1FlueDiameter", modelUnits).displayBase})`,
            [`${b1}`]: additionalOpeningsResults.opening2FlueDiameter || " ",
        },
        { [`${a1}`]: "[2] Damper Closed?", [`${b1}`]: additionalOpeningsResults.opening2DamperClosed || " " },
        { [`${a1}`]: "[2] Count", [`${b1}`]: additionalOpeningsResults.opening2Ccount || " " },
        { [`${a1}`]: "[3] Equipment type", [`${b1}`]: additionalOpeningsResults.opening3EquipmentType || " " },
        {
            [`${a1}`]: `[3] Flue diameter (${getBaseUnits("opening1FlueDiameter", modelUnits).displayBase})`,
            [`${b1}`]: additionalOpeningsResults.opening3FlueDiameter || " ",
        },
        { [`${a1}`]: "[3] Damper Closed?", [`${b1}`]: additionalOpeningsResults.opening3DamperClosed || " " },
        { [`${a1}`]: "[3] Count", [`${b1}`]: additionalOpeningsResults.opening3Ccount || " " },
    ];

    const getCoolingObjectData = () => {
        if (coolingSystemType === "airConditioning") {
            return [
                { [`${a1}`]: "Air Conditioning system" },
                { [`${a1}`]: "Equipment type", [`${b1}`]: coolingSystemResults.equipmentType || " " },
                { [`${a1}`]: "Manufacturer", [`${b1}`]: coolingSystemResults.manufacturer || " " },
                { [`${a1}`]: "Model #", [`${b1}`]: coolingSystemResults.model || " " },
                { [`${a1}`]: "ENERGY STAR certified", [`${b1}`]: coolingSystemResults.isEnergyStar || " " },
                { [`${a1}`]: "Capacity", [`${b1}`]: coolingSystemResults.capacity || " " },
                { [`${a1}`]: "Efficiency (COP or SEER)", [`${b1}`]: coolingSystemResults.efficiency || " " },
                { [`${a1}`]: "Basement Cooled?", [`${b1}`]: coolingSystemResults.coolingDuctedToBasement || " " },
            ];
        } else if (coolingSystemType === "airHeatPump") {
            return [
                { [`${a1}`]: "Air Source heat pump" },
                { [`${a1}`]: "Equipment type", [`${b1}`]: coolingSystemResults.equipmentType || " " },
                { [`${a1}`]: "Manufacturer", [`${b1}`]: coolingSystemResults.manufacturer || " " },
                { [`${a1}`]: "Model #", [`${b1}`]: coolingSystemResults.model || " " },
                { [`${a1}`]: "AHRI #", [`${b1}`]: coolingSystemResults.ahri || " " },
                { [`${a1}`]: "ENERGY STAR certified", [`${b1}`]: coolingSystemResults.isEnergyStar || " " },
                { [`${a1}`]: "Capacity", [`${b1}`]: coolingSystemResults.capacity || " " },
                {
                    [`${a1}`]: "Heating efficiency (HSPF / COP)",
                    [`${b1}`]: coolingSystemResults.heatingEfficiency || " ",
                },
                {
                    [`${a1}`]: "Cooling efficiency (SEER / COP)",
                    [`${b1}`]: coolingSystemResults.coolingEfficiency || " ",
                },
                { [`${a1}`]: "Temperature cut-off type", [`${b1}`]: coolingSystemResults.tempCutoffType || " " },
                { [`${a1}`]: "Basement Cooled?", [`${b1}`]: coolingSystemResults.coolingDuctedToBasement || " " },
            ];
        } else if (coolingSystemType === "waterHeatPump" || coolingSystemType === "groundHeatPump") {
            return [
                { [`${a1}`]: "Water Source Heat Pump" },
                { [`${a1}`]: "Equipment type", [`${b1}`]: coolingSystemResults.equipmentType || " " },
                { [`${a1}`]: "Manufacturer", [`${b1}`]: coolingSystemResults.manufacturer || " " },
                { [`${a1}`]: "Model #", [`${b1}`]: coolingSystemResults.model || " " },
                { [`${a1}`]: "AHRI #", [`${b1}`]: coolingSystemResults.ahri || " " },
                { [`${a1}`]: "ENERGY STAR certified", [`${b1}`]: coolingSystemResults.isEnergyStar || " " },
                { [`${a1}`]: "Capacity", [`${b1}`]: coolingSystemResults.capacity || " " },
                {
                    [`${a1}`]: "Heating efficiency (HSPF / COP)",
                    [`${b1}`]: coolingSystemResults.heatingEfficiency || " ",
                },
                {
                    [`${a1}`]: "Cooling efficiency (SEER / COP)",
                    [`${b1}`]: coolingSystemResults.coolingEfficiency || " ",
                },
                { [`${a1}`]: "Temperature cut-off type", [`${b1}`]: coolingSystemResults.tempCutoffType || " " },
                { [`${a1}`]: "Basement Cooled?", [`${b1}`]: coolingSystemResults.coolingDuctedToBasement || " " },
            ];
        } else {
            return [{}];
        }
    };

    const coolingObjectData = getCoolingObjectData();

    const primaryDHWObjectData = [
        { [`${a1}`]: "Energy source", [`${b1}`]: primaryDHWResults.energySource || " " },
        { [`${a1}`]: "Manufacturer", [`${b1}`]: primaryDHWResults.manufacturer || " " },
        { [`${a1}`]: "Model #", [`${b1}`]: primaryDHWResults.model || " " },
        { [`${a1}`]: "ENERGY STAR certified", [`${b1}`]: primaryDHWResults.isEnergyStar || " " },
        { [`${a1}`]: "Tank type", [`${b1}`]: primaryDHWResults.tankType || " " },
        { [`${a1}`]: "Tank location", [`${b1}`]: primaryDHWResults.tankLocation || " " },
        {
            [`${a1}`]: `Tank Volume (${getBaseUnits("dhwTankVolume", modelUnits).displayBase})`,
            [`${b1}`]: primaryDHWResults.tankVolume || " ",
        },
        { [`${a1}`]: "Energy factor (EF)", [`${b1}`]: primaryDHWResults.energyFactor || " " },
        {
            [`${a1}`]: `Tank insulating blanket (${getBaseUnits("dhwInsulatingBlanket", modelUnits).displayBase})`,
            [`${b1}`]: primaryDHWResults.insulatingBlanket || " ",
        },
        { [`${a1}`]: "Integrated heat pump COP", [`${b1}`]: primaryDHWResults.integratedHeatPumpCOP || " " },
        {
            [`${a1}`]: `Standby heat loss and input capacity for electric tank (${
                getBaseUnits("dhwInputCapacity", modelUnits).displayBase
            })`,
            [`${b1}`]: primaryDHWResults.standbyHeatLossCapacity || " ",
        },
        {
            [`${a1}`]: "Fuel fired tank thermal efficiency (%)",
            [`${b1}`]: primaryDHWResults.tankThermalEfficiency || " ",
        },
        { [`${a1}`]: "Co-vented flue", [`${b1}`]: primaryDHWResults.combinedFlue || " " },
        {
            [`${a1}`]: `Pilot light energy (${getBaseUnits("dhwPilotEnergy", modelUnits).displayBase})`,
            [`${b1}`]: primaryDHWResults.pilotEnergy || " ",
        },
    ];

    const primaryDWHRObjectData = [
        { [`${a1}`]: "Manufacturer", [`${b1}`]: primaryDWHRResults.manufacturer || " " },
        { [`${a1}`]: "Model", [`${b1}`]: primaryDWHRResults.model || " " },
        { [`${a1}`]: "Preheated cold water delivered to", [`${b1}`]: primaryDWHRResults.preheatShowerTank || " " },
        {
            [`${a1}`]: "Number of MURB unit connected to DWHR (Low efficiency)",
            [`${b1}`]: primaryDWHRResults.murbUnitsLow || " ",
        },
        {
            [`${a1}`]: "Number of MURB unit connected to DWHR (High efficiency)",
            [`${b1}`]: primaryDWHRResults.murbUnitsHigh || " ",
        },
    ];

    const solarDHWObjectData = isSolarDHW
        ? [
              { [`${a1}`]: "Manufacturer", [`${b1}`]: solarDHWResults.manufacturer || " " },
              { [`${a1}`]: "Model #", [`${b1}`]: solarDHWResults.model || " " },
              { [`${a1}`]: "Annual Perfomance Rating (MJ/year)", [`${b1}`]: solarDHWResults.performanceRating || " " },
              {
                  [`${a1}`]: `Slope (˚)`,
                  [`${b1}`]: solarDHWResults.slope || " ",
              },
              {
                  [`${a1}`]: `Azimuth (˚)`,
                  [`${b1}`]: solarDHWResults.azimuth || " ",
              },
          ]
        : [
              { [`${a1}`]: "Manufacturer", [`${b1}`]: " " },
              { [`${a1}`]: "Model #", [`${b1}`]: " " },
              { [`${a1}`]: "Annual Perfomance Rating (MJ/year)", [`${b1}`]: " " },
              { [`${a1}`]: `Slope (˚)`, [`${b1}`]: " " },
              { [`${a1}`]: `Azimuth (˚)`, [`${b1}`]: " " },
          ];

    const secondaryDHWObjectData = [
        { [`${a1}`]: "Energy source", [`${b1}`]: secondaryDHWResults.energySource || " " },
        { [`${a1}`]: "Manufacturer", [`${b1}`]: secondaryDHWResults.manufacturer || " " },
        { [`${a1}`]: "Model #", [`${b1}`]: secondaryDHWResults.model || " " },
        { [`${a1}`]: "ENERGY STAR certified", [`${b1}`]: secondaryDHWResults.isEnergyStar || " " },
        { [`${a1}`]: "Tank type", [`${b1}`]: secondaryDHWResults.tankType || " " },
        { [`${a1}`]: "Tank location", [`${b1}`]: secondaryDHWResults.tankLocation || " " },
        {
            [`${a1}`]: `Tank Volume (${getBaseUnits("dhwTankVolume", modelUnits).displayBase})`,
            [`${b1}`]: secondaryDHWResults.tankVolume || " ",
        },
        { [`${a1}`]: "Energy factor (EF)", [`${b1}`]: secondaryDHWResults.energyFactor || " " },
        { [`${a1}`]: "Tank insulating blanket (RSI/R-value)", [`${b1}`]: secondaryDHWResults.insulatingBlanket || " " },
        { [`${a1}`]: "Integrated heat pump COP", [`${b1}`]: secondaryDHWResults.integratedHeatPumpCOP || " " },
        {
            [`${a1}`]: "Standby heat loss and input capacity for electric tank (W / Btu/hr)",
            [`${b1}`]: secondaryDHWResults.standbyHeatLossCapacity || " ",
        },
        {
            [`${a1}`]: "Fuel fired tank thermal efficiency (TE % or COP)",
            [`${b1}`]: primaryDHWResults.tankThermalEfficiency || " ",
        },
        { [`${a1}`]: "Co-vented flue", [`${b1}`]: primaryDHWResults.combinedFlue || " " },
        { [`${a1}`]: "Pilot light energy", [`${b1}`]: primaryDHWResults.pilotEnergy || " " },
    ];

    const secondaryDWHRObjectData = [
        { [`${a1}`]: "Manufacturer", [`${b1}`]: secondaryDWHRResults.manufacturer || " " },
        { [`${a1}`]: "Model", [`${b1}`]: secondaryDWHRResults.model || " " },
        { [`${a1}`]: "Preheated cold water delivered to", [`${b1}`]: secondaryDWHRResults.preheatShowerTank || " " },
        {
            [`${a1}`]: "Number of MURB unit connected to DWHR (Low efficiency)",
            [`${b1}`]: secondaryDWHRResults.murbUnitsLow || " ",
        },
        {
            [`${a1}`]: "Number of MURB unit connected to DWHR (High efficiency)",
            [`${b1}`]: secondaryDWHRResults.murbUnitsHigh || " ",
        },
    ];

    let reSystemList = [`${b1}`, ..."CDEFGHIJKLMNOPQRSTUVWXYZ".split("")].slice(
        0,
        renewableEnergySystemsResults.length
    );

    const reSystemsRow = reSystemList.reduce((acc, system, ind) => {
        return { ...acc, [`${system}`]: `System ${ind + 1}` };
    }, {});

    const renewableEnergyObjectData = reSystemList.map((system, ind) => {
        const result = renewableEnergySystemsResults[ind];
        return [
            { [`${a1}`]: "System Type (Roof/Ground Mount)", [`${system}`]: result.systemType || " " },
            { [`${a1}`]: "Manufacturer", [`${system}`]: result.manufacturer || " " },
            { [`${a1}`]: "Model", [`${system}`]: result.model || " " },
            {
                [`${a1}`]: `Array Area (${getBaseUnits("solarArrayArea", modelUnits).displayBase})`,
                [`${system}`]: result.arrayArea || " ",
            },
            {
                [`${a1}`]: `Slope (˚)`,
                [`${system}`]: result.slope || " ",
            },
            {
                [`${a1}`]: `Azimuth (˚)`,
                [`${system}`]: result.azimuth || " ",
            },
            { [`${a1}`]: "Module type", [`${system}`]: result.moduleType || " " },
            { [`${a1}`]: "Module Efficiency (%)", [`${system}`]: result.moduleEfficiency || " " },
            { [`${a1}`]: "Normal operating cell temperature (˚C)", [`${system}`]: result.cellTemperatures || " " },
            {
                [`${a1}`]: "Temperature coefficient of efficiency (%/˚C)",
                [`${system}`]: result.coefficientOfEfficiency || " ",
            },
            { [`${a1}`]: "Miscellaneous array losses (%)", [`${system}`]: result.miscLosses || " " },
            { [`${a1}`]: "Other power conditioning losses (%)", [`${system}`]: result.otherPowerLosses || " " },
            { [`${a1}`]: "Inverter efficiency (%)", [`${system}`]: result.inverterEfficiency || " " },
            { [`${a1}`]: "Grid absorption rate (%)", [`${system}`]: result.gridAbsorptionRate || " " },
        ];
    });

    const reItemArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    const renewableEnergyData = !isEmpty(renewableEnergySystemsResults)
        ? reItemArray.map((objectData, ind) => {
              return renewableEnergyObjectData.reduce((acc, item) => {
                  return { ...acc, ...item[ind] };
              }, {});
          })
        : [
              { [`${a1}`]: "System Type", [`${b1}`]: "" },
              { [`${a1}`]: "Manufacturer", [`${b1}`]: "" },
              { [`${a1}`]: "Model", [`${b1}`]: "" },
              { [`${a1}`]: `Array Area (${getBaseUnits("solarArrayArea", modelUnits).displayBase})`, [`${b1}`]: "" },
              { [`${a1}`]: `Slope (${getBaseUnits("solarArraySlope", modelUnits).displayBase})`, [`${b1}`]: "" },
              { [`${a1}`]: `Azimuth (${getBaseUnits("solarArrayAzimuth", modelUnits).displayBase})`, [`${b1}`]: "" },
              { [`${a1}`]: "Module type", [`${b1}`]: "" },
              { [`${a1}`]: "Module Efficiency", [`${b1}`]: "" },
              { [`${a1}`]: "Normal operating cell temperature", [`${b1}`]: "" },
              { [`${a1}`]: "Temperature coefficient of efficiency", [`${b1}`]: "" },
              { [`${a1}`]: "Miscellaneous array losses (%)", [`${b1}`]: "" },
              { [`${a1}`]: "Other power conditioning losses (%)", [`${b1}`]: "" },
              { [`${a1}`]: "Inverter efficiency (%)", [`${b1}`]: "" },
              { [`${a1}`]: "Grid absorption rate (%)", [`${b1}`]: "" },
          ];

    const renewableSummaryObjectData = [
        { [`${a1}`]: "Total PV System Capacity (kW)", [`${b1}`]: renewableSummaryResults.pvCapacity || " " },
        { [`${a1}`]: "Solar Ready?", [`${b1}`]: renewableSummaryResults.solarReady || " " },
        {
            [`${a1}`]: "Wind Energy Contriburion (kWh)",
            [`${b1}`]: renewableSummaryResults.windEnergyContribution || " ",
        },
        { [`${a1}`]: "Has Battery Storage", [`${b1}`]: renewableSummaryResults.batteryStorage || " " },
    ];

    return [
        ...spacing,
        { [`${a1}`]: "Primary Heating Systems" },
        ...primaryHeatingObjectData,
        ...spacing,
        ...coolingObjectData,
        ...spacing,
        {
            [`${a1}`]: "Supplementary Heating Systems",
            ...suppSystemsRow,
            // # of systems per ventilator function
        },
        ...suppHeatingData,
        ...spacing,
        { [`${a1}`]: "Radiant Heating System" },
        ...radiantHeatingObjectData,
        ...spacing,
        { [`${a1}`]: "Additional Openings" },
        ...additionalOpeningsObjectData,
        ...spacing,
        { [`${a1}`]: "Primary domestic water heater" },
        ...primaryDHWObjectData,
        ...spacing,
        { [`${a1}`]: "Primary drain water heat recovery" },
        ...primaryDWHRObjectData,
        ...spacing,
        { [`${a1}`]: "Solar Domestic Hot Water System" },
        ...solarDHWObjectData,
        ...spacing,
        { [`${a1}`]: "Secondary Domestic Hot Water System" },
        ...secondaryDHWObjectData,
        ...spacing,
        { [`${a1}`]: "Secondary drain water heat recovery" },
        ...secondaryDWHRObjectData,
        ...spacing,
        {
            [`${a1}`]: "Renewable Energy Systems",
            ...reSystemsRow,
        },
        ...renewableEnergyData,
        ...spacing,
        { [`${a1}`]: "Generation Summary" },
        ...renewableSummaryObjectData,
    ];
};
