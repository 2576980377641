import React from "react";
import EquationIcon from "assets/images/icons/JSX/EquationIcon";
import classes from "./style.module.scss";
import Tooltip from "components/Tooltip";

export default ({ className, name, hasEquation, isInvalid }) => {
    const msg =
        "Type = to begin an equation. Supports addition (+), subtraction (-), division (/), multiplication (*), and exponents (^).";

    return (
        <>
            <span
                className={`${classes.equation} ${className && className}`}
                data-html
                data-tip={`<span style="display:block;max-width:14rem;">${msg}</span>`}
                data-for={`${name}_eqnTip`}
            >
                <EquationIcon hasEquation={hasEquation} isInvalid={isInvalid} />
                <Tooltip id={`${name}_eqnTip`} />
            </span>
        </>
    );
};
