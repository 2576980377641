import React from 'react';
import classes from './style.module.scss';
import sharedClasses from '../style.module.scss';

export default () => {
    return (
        <>
            <p>
                Use the Upgrades Tab to input information...
            </p>
            <h3>What is different from HOT2000?</h3>
            <p>
                <strong>Energy Star Systems.</strong> Some system types (Furnace, Boiler, AC, Heat Pumps, Hot Water, HRVs/ERVs) can be automatically populated based on the data from the Energy Star database. Only models available in Canada have been included in these searchable lists.
            </p>
            <h3>Known Issues and Limitations</h3>
            <ul>
                <li>
                    The <strong>Favourite Systems</strong> functionality has not yet been added. This will allow users to save favourite mechanical systems or sets of systems.
                </li>
                <li>
                    <strong>Multiple Systems</strong>, applicable to MURBs, and used for data collection purposes only, have not yet been integrated into Volta SNAP.
                </li>
                <li>
                    The Energy Star list of HRVs and ERVs does not include system information in the exact format that HOT2000 expects. Therefore, the following assumptions have been made:
                </li>
                <ul>
                    <li>
                        <strong>Cooling Efficiency</strong> information is not provided and is left as the default value (25%).
                    </li>
                    <li>
                        <strong>Supply and Exhaust Air Flow</strong> are not provided and have both been overwritten with the value &#34;Test airflow at 0°C CFM&#34; from the Energy Star database.
                    </li>
                    <li>
                        The <strong>Rated Fan Power at Temperature Condition 2 (-25˚C)</strong> is not provided in the database, nor is the &#34;Fan Efficacy&#34; (in cfm/W) provided at -25˚C. The <strong>Rated Fan Power at -25˚C</strong> was therefore estimated by dividing the provided flow rate at -25˚C (cfm) by the provided Fan Efficacy at 0˚C (cfm/W). To obtain a more accurate value, users will need to refer to the spec sheet 
                        for their HRV/ERV.
                    </li>
                </ul>
                <p>
                    In future updates, the available HRV/ERV models will be augmented with the HVI certified database, which has some of these missing pieces of information.
                </p>
            </ul>
        </>
    )
}