import React from "react";
import classes from "./style.module.scss";
import sharedClasses from "features/Model/sharedStyles.module.scss";
import { Field } from "redux-form";
import { getValidation, getDecimalPlaces } from "utils/fieldValidation";
import { getOptions, getBaseUnits, getUnitOptions } from "utils/fields";
import InputRow from "components/Input/Row";
import InputWithUnits from "components/Input/InputWithUnits";
import Select from "components/Input/Select";
import Checkbox from "components/Input/Checkbox";
import Accordion from "components/Accordion";

const dayHeatSetPointValidation = getValidation("tempDayHeatingSetPoint");
const coolingSetPointValidation = getValidation("tempCoolingSetPoint");
const nightHeatSetPointValidation = getValidation("tempNightHeatingSetPoint");
const nightSetbackValidation = getValidation("tempNightSetbackDuration");
const allowableRiseValidation = getValidation("tempAllowableRise");
const designHeatingValidation = getValidation("tempSizingHeatingSetPoint");
const designCoolingValidation = getValidation("tempSizingCoolingSetPoint");
const basementHeatingValidation = getValidation("tempBasementHeatingSetPoint");
const crawlspaceHeatingValidation = getValidation("tempCrawlspaceHeatingSetPoint");

export default React.memo(
    ({
        accessor,
        isCrawlspacePresent,
        isMURB,
        isBasementHeated,
        dayHeatSetPointUnits,
        coolingSetPointUnits,
        nightHeatSetPointUnits,
        nightSetbackUnits,
        designHeatingUnits,
        designCoolingUnits,
        basementHeatingUnits,
        crawlspaceHeatingUnits,
        overrideHeatSetPointUnits,
        overrideCoolSetPointUnits,
        modelUnits,
        change,
    }) => {
        return (
            <div className={sharedClasses.section}>
                <h4 style={{ marginBottom: "1.25rem" }}>Main Floor</h4>
                <InputRow gridTemplate="1fr 1fr 1fr">
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.mainFloors.daytimeHeatingSetPoint`}
                        label="Daytime Heating Set Point"
                        placeholder="0.00"
                        validate={dayHeatSetPointValidation}
                        decimals={getDecimalPlaces("tempDayHeatingSetPoint")}
                        change={change}
                        units={{
                            base: getBaseUnits("tempDayHeatingSetPoint", modelUnits),
                            options: getUnitOptions("temperature"),
                            selected: dayHeatSetPointUnits,
                            unitType: "temperature",
                            accessor: `${accessor}.mainFloors.daytimeHeatingSetPoint_u`,
                        }}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.mainFloors.coolingSetPoint`}
                        label="Cooling Set Point"
                        placeholder="0.00"
                        validate={coolingSetPointValidation}
                        decimals={getDecimalPlaces("tempCoolingSetPoint")}
                        change={change}
                        units={{
                            base: getBaseUnits("tempCoolingSetPoint", modelUnits),
                            options: getUnitOptions("temperature"),
                            selected: coolingSetPointUnits,
                            unitType: "temperature",
                            accessor: `${accessor}.mainFloors.coolingSetPoint_u`,
                        }}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.mainFloors.nighttimeHeatingSetPoint`}
                        label="Nighttime Heating Set Point"
                        placeholder="0.00"
                        validate={nightHeatSetPointValidation}
                        decimals={getDecimalPlaces("tempNightHeatingSetPoint")}
                        change={change}
                        units={{
                            base: getBaseUnits("tempNightHeatingSetPoint", modelUnits),
                            options: getUnitOptions("temperature"),
                            selected: nightHeatSetPointUnits,
                            unitType: "temperature",
                            accessor: `${accessor}.mainFloors.nighttimeHeatingSetPoint_u`,
                        }}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr 1fr">
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.mainFloors.nighttimeSetbackDuration`}
                        label="Nighttime Setback Duration"
                        placeholder="0.00"
                        validate={nightSetbackValidation}
                        decimals={getDecimalPlaces("tempNightSetbackDuration")}
                        change={change}
                        units={{
                            base: getBaseUnits("tempNightSetbackDuration", modelUnits),
                            options: getUnitOptions("time"),
                            selected: nightSetbackUnits,
                            unitType: "time",
                            accessor: `${accessor}.mainFloors.nighttimeSetbackDuration_u`,
                        }}
                    />
                    <Field
                        component={Select}
                        name={`${accessor}.mainFloors.allowableRise`}
                        options={getOptions({ fieldKey: "tempAllowableRise" })}
                        label="Allowable Rise"
                        placeholder="Choose Allowable Rise"
                        validate={allowableRiseValidation}
                    />
                </InputRow>
                <h4 style={{ marginBottom: "1.25rem", marginTop: "1.75rem" }}>Equipment Design Temperatures</h4>
                <InputRow gridTemplate="1fr 1fr 1fr">
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.equipment.heatingSetPoint`}
                        label="Design Heating Set Point"
                        placeholder="0.00"
                        validate={designHeatingValidation}
                        decimals={getDecimalPlaces("tempSizingHeatingSetPoint")}
                        change={change}
                        units={{
                            base: getBaseUnits("tempSizingHeatingSetPoint", modelUnits),
                            options: getUnitOptions("temperature"),
                            selected: designHeatingUnits,
                            unitType: "temperature",
                            accessor: `${accessor}.equipment.heatingSetPoint_u`,
                        }}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.equipment.coolingSetPoint`}
                        label="Design Cooling Set Point"
                        placeholder="0.00"
                        validate={designCoolingValidation}
                        decimals={getDecimalPlaces("tempSizingCoolingSetPoint")}
                        change={change}
                        units={{
                            base: getBaseUnits("tempSizingCoolingSetPoint", modelUnits),
                            options: getUnitOptions("temperature"),
                            selected: designCoolingUnits,
                            unitType: "temperature",
                            accessor: `${accessor}.equipment.coolingSetPoint_u`,
                        }}
                    />
                </InputRow>
                <h4 style={{ marginBottom: "1.25rem", marginTop: "1.75rem" }}>Basement</h4>
                <InputRow gridTemplate="1fr 1fr 1fr">
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.basement.heatingSetPoint`}
                        label="Basement Heating Set Point"
                        placeholder="0.00"
                        validate={basementHeatingValidation}
                        decimals={getDecimalPlaces("tempBasementHeatingSetPoint")}
                        change={change}
                        disabled={!isBasementHeated}
                        units={{
                            base: getBaseUnits("tempBasementHeatingSetPoint", modelUnits),
                            options: getUnitOptions("temperature"),
                            selected: basementHeatingUnits,
                            unitType: "temperature",
                            accessor: `${accessor}.basement.heatingSetPoint_u`,
                        }}
                    />
                </InputRow>
                <InputRow flex>
                    <Field
                        component={Checkbox}
                        name={`${accessor}.basement.heated`}
                        label="Is Basement Heated?"
                        large
                        className={classes.bottomCheckBox}
                        type="checkbox"
                    />
                    <Field
                        component={Checkbox}
                        name={`${accessor}.basement.cooled`}
                        label="Is Basement Cooled?"
                        large
                        className={classes.bottomCheckBox}
                        type="checkbox"
                    />
                    <Field
                        component={Checkbox}
                        name={`${accessor}.basement.separateThermostat`}
                        label="Separate Thermostat?"
                        large
                        className={classes.bottomCheckBox}
                        type="checkbox"
                    />
                    <Field
                        component={Checkbox}
                        name={`${accessor}.basement.basementUnit`}
                        label="Basement Unit?"
                        large
                        className={classes.bottomCheckBox}
                        type="checkbox"
                        disabled={!isMURB}
                    />
                    <Field
                        component={Checkbox}
                        name={`${accessor}.f280Setpoints.separateBasementSetpoint`}
                        label="Use 18˚C for CSA F280-12 Calculations"
                        large
                        className={classes.bottomCheckBox}
                        type="checkbox"
                        nonH2kParam="f280Default"
                    />
                </InputRow>
                <h4 style={{ marginBottom: "1.25rem", marginTop: "1.75rem" }}>Crawlspace</h4>
                <InputRow gridTemplate="31.85% 34.62% 29.08%">
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.crawlspace.heatingSetPoint`}
                        label="Crawlspace Heating Set Point"
                        placeholder="0.00"
                        validate={crawlspaceHeatingValidation}
                        decimals={getDecimalPlaces("tempCrawlspaceHeatingSetPoint")}
                        change={change}
                        disabled={!isCrawlspacePresent}
                        units={{
                            base: getBaseUnits("tempCrawlspaceHeatingSetPoint", modelUnits),
                            options: getUnitOptions("temperature"),
                            selected: crawlspaceHeatingUnits,
                            unitType: "temperature",
                            accessor: `${accessor}.crawlspace.heatingSetPoint_u`,
                        }}
                    />
                    <Field
                        component={Checkbox}
                        name={`${accessor}.crawlspace.heated`}
                        label="Is Crawlspace Heated?"
                        large
                        className={classes.bottomCheckBox}
                        type="checkbox"
                        disabled={!isCrawlspacePresent}
                    />
                    <Field
                        component={Checkbox}
                        name={`${accessor}.f280Setpoints.separateCrawlspaceSetpoint`}
                        label="Use 15˚C for CSA F280-12 Calculations"
                        large
                        className={classes.bottomCheckBox}
                        type="checkbox"
                        nonH2kParam="f280Default"
                    />
                </InputRow>
                <Accordion
                    className={sharedClasses.accordion}
                    heading={
                        <span className={sharedClasses.heading}>Design Heat Loss/Gain Indoor Set Point Overrides</span>
                    }
                    large
                >
                    <p style={{ marginBottom: "1rem" }}>
                        If indoor set point overrides are enabled, reported design heat loss and heat gain results will
                        <strong> not </strong>be CSA F280-12 compliant.
                    </p>
                    <p style={{ marginBottom: "1rem" }}>
                        Overrides should only be used at the discretion of the user to help estimate mechanical system
                        sizes to maintain indoor temperature set points that differ from the norm.
                    </p>
                    <p>
                        If confirmed, set point overrides are applied the the base case and any upgrade cases defined
                        across all operating conditions.
                    </p>
                    <h4 style={{ marginBottom: "1rem", marginTop: "1rem" }}>Heating Set Point Override</h4>
                    <InputRow gridTemplate="1fr 1fr 1fr">
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.f280Setpoints.setPointOverride.heating`}
                            label="Design Heating Set Point Override"
                            placeholder="0.00"
                            validate={dayHeatSetPointValidation}
                            decimals={getDecimalPlaces("tempDayHeatingSetPoint")}
                            change={change}
                            units={{
                                base: getBaseUnits("tempDayHeatingSetPoint", modelUnits),
                                options: getUnitOptions("temperature"),
                                selected: overrideHeatSetPointUnits,
                                unitType: "temperature",
                                accessor: `${accessor}.f280Setpoints.setPointOverride.heating_u`,
                            }}
                        />
                        <Field
                            component={Checkbox}
                            name={`${accessor}.f280Setpoints.setPointOverride.heatingConfirmed`}
                            label="Confirm Heating Set Point Override"
                            large
                            className={classes.bottomCheckBox}
                            type="checkbox"
                        />
                    </InputRow>
                    <h4 style={{ marginBottom: "1rem", marginTop: "1rem" }}>Cooling Set Point Override</h4>
                    <InputRow gridTemplate="1fr 1fr 1fr">
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.f280Setpoints.setPointOverride.cooling`}
                            label="Design Cooling Set Point Override"
                            placeholder="0.00"
                            validate={coolingSetPointValidation}
                            decimals={getDecimalPlaces("tempCoolingSetPoint")}
                            change={change}
                            units={{
                                base: getBaseUnits("tempCoolingSetPoint", modelUnits),
                                options: getUnitOptions("temperature"),
                                selected: overrideCoolSetPointUnits,
                                unitType: "temperature",
                                accessor: `${accessor}.f280Setpoints.setPointOverride.cooling_u`,
                            }}
                        />
                        <Field
                            component={Checkbox}
                            name={`${accessor}.f280Setpoints.setPointOverride.coolingConfirmed`}
                            label="Confirm Cooling Set Point Override"
                            large
                            className={classes.bottomCheckBox}
                            type="checkbox"
                        />
                    </InputRow>
                </Accordion>
            </div>
        );
    }
);
