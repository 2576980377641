import clientFields from "utils/settings/clientFields.json";
import clientFieldsV11_10 from "utils/settings/clientFields-v11-10.json";
import clientFieldsV11_11 from "utils/settings/clientFields-v11-11.json";
import unitConversions from "utils/settings/unitConversions.json";
import isEmpty from "lodash/isEmpty";
import CoolingHeatPumps from "features/Model/Mechanicals/CoolingHeatPumps";

export const getObjValAtPath = (data = {}, accessor = "") => {
    //Get the object value at the dot delimited accessor path
    accessor = accessor.split(".").filter((el) => el != "houseData") || [];

    if (["number", "string", "boolean"].includes(typeof data)) {
        return data;
    }
    //Return exactly what is in the accessor string
    //Additional processing occurs based on data type conversion
    return accessor.reduce((acc, curr) => {
        return curr in acc ? acc[curr] : acc;
    }, data);
};

const getFieldSettings = (fieldKey, version = "") => {
    let targetField = clientFields[fieldKey] || {};

    if (["v11-11", "v11-12"].includes(version) && (clientFieldsV11_11[fieldKey] || clientFieldsV11_10[fieldKey])) {
        targetField = clientFieldsV11_11[fieldKey] || clientFieldsV11_10[fieldKey] || {};
    } else if (version === "v11-10" && clientFieldsV11_10[fieldKey]) {
        targetField = clientFieldsV11_10[fieldKey] || {};
    }

    return targetField;
};

const getMessage = (label, rule, value) =>
    ({
        required: `${label} is required`,
        minLength: `${label} must be at least ${value} characters.`,
        maxLength: `${label} must not exceed ${value} characters.`,
        containLetter: `${label} must contain at least one letter.`,
        containNumber: `${label} must contain at least one number.`,
        containSpecialChar: `${label} must contain at least one special character.`,
        default: "",
    }[rule || "default"]);

export const checkValidity = (label = "", value = "", rules = {}) => {
    let isValid = true;
    let message = "";

    if (rules.required) {
        isValid = value.trim() !== "" && isValid;
        message = value.trim() === "" ? getMessage(label, "required", rules.required) : "";
    }

    if (rules.minLength) {
        isValid = value.length >= rules.minLength && isValid;
        message = !message ? getMessage(label, "minLength", rules.minLength) : message;
    }

    if (rules.maxLength) {
        isValid = value.length <= rules.maxLength && isValid;
        message = !message ? getMessage(label, "maxLength", rules.maxLength) : message;
    }

    if (rules.containLetter) {
        const regExp = /[a-zA-Z]/g;
        isValid = regExp.test(value) && isValid;
        message = !message ? getMessage(label, "containLetter", rules.containLetter) : message;
    }

    if (rules.containNumber) {
        isValid = /\d/.test(value) && isValid;
        message = !message ? getMessage(label, "containNumber", rules.containNumber) : message;
    }

    if (rules.containSpecialChar) {
        const regExp = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        isValid = regExp.test(value) && isValid;
        message = !message ? getMessage(label, "containSpecialChar", rules.containSpecialChar) : message;
    }

    return {
        isValid: isValid,
        message: message,
    };
};

const labelValueFields = [];

export const getOptions = ({ fieldKey, indKey = "default", version = "" }) => {
    const { content: { opts = {}, indField = null } = {} } = getFieldSettings(fieldKey, version) || {};

    const { default: defaultOptionObj = {}, [indKey]: optionObj = {} } = opts;

    let options = opts;

    if (indField && !isEmpty(optionObj)) {
        options = optionObj;
    }

    if (indField && isEmpty(optionObj)) {
        options = defaultOptionObj;
    }

    return Object.keys(options).map((key) => {
        const { value, english, french } = options[key] || {};

        const valueContent = value !== null ? { value: value } : {};

        // Select Field Type 1 - Label as value
        if (labelValueFields.includes(fieldKey)) {
            return {
                label: english,
                value: english,
            };
        }

        // Select Field Type 2 - object with id and sometimes value
        return {
            label: english,
            value: {
                id: parseInt(key),
                ...valueContent,
            },
        };
    });
};

export const getOptionsWithSimpleLabel = ({ fieldKey, indKey = "default", version = "" }) => {
    const { content: { opts = {}, indField = null } = {} } = getFieldSettings(fieldKey, version) || {};

    const { default: defaultOptionObj = {}, [indKey]: optionObj = {} } = opts;

    let options = opts;

    if (indField && !isEmpty(optionObj)) {
        options = optionObj;
    }

    if (indField && isEmpty(optionObj)) {
        options = defaultOptionObj;
    }

    return Object.keys(options).map((key) => {
        const { value, english, french, simpleLabel } = options[key] || {};

        const valueContent = value !== null ? { value: value } : {};

        return {
            label: english,
            value: {
                id: parseInt(key),
                ...valueContent,
            },
            simpleLabel,
        };
    });
};

export const getSlabConfigOpts = ({ fieldKey, returnHierarchy = false }) => {
    const { content: { opts = {} } = {} } = clientFields[fieldKey] || {};

    if (returnHierarchy) {
        const hierarchy = Object.keys(opts).reduce((obj, opt) => {
            const { slabInsType = "", skirt = false, coverage = "", text = "" } = opts[opt];

            const slabInsTypeKey = slabInsType.split(" ").join("_");
            const skirtKey = skirt ? "With Skirt" : "Without Skirt";
            const coverageKey = coverage.split(" ").join("_");

            return {
                ...obj,
                [slabInsTypeKey]: {
                    ...(obj[slabInsTypeKey] ? obj[slabInsTypeKey] : {}),
                    [skirtKey]: {
                        ...(obj[slabInsTypeKey] && obj[slabInsTypeKey][skirtKey] ? obj[slabInsTypeKey][skirtKey] : {}),
                        [coverageKey]: {
                            ...(obj[slabInsTypeKey] &&
                            obj[slabInsTypeKey][skirtKey] &&
                            obj[slabInsTypeKey][skirtKey][coverageKey]
                                ? obj[slabInsTypeKey][skirtKey][coverageKey]
                                : {}),
                            [opt]: {
                                value: opt,
                                text,
                                skirt,
                            },
                        },
                    },
                },
            };
        }, {});

        return hierarchy;
    }

    return Object.keys(opts).map((key) => ({ label: key, value: key }));
};

export const getFoundationConfigOpts = ({ fieldKey, returnHierarchy = false }) => {
    const { content: { opts = {} } = {} } = clientFields[fieldKey] || {};

    if (returnHierarchy) {
        const hierarchy = Object.keys(opts).reduce((obj, opt) => {
            const { type = "", insCat = "", slabCat = "", text = "", overlap = false } = opts[opt];

            const typeKey = type.split(" ").join("_");
            const insCatKey = insCat.split(" ").join("_");
            const slabCatKey = slabCat.split(" ").join("_");

            return {
                ...obj,
                [typeKey]: {
                    ...(obj[typeKey] ? obj[typeKey] : {}),
                    [insCatKey]: {
                        ...(obj[typeKey] && obj[typeKey][insCatKey] ? obj[typeKey][insCatKey] : {}),
                        [slabCatKey]: {
                            ...(obj[typeKey] && obj[typeKey][insCatKey] && obj[typeKey][insCatKey][slabCatKey]
                                ? obj[typeKey][insCatKey][slabCatKey]
                                : {}),
                            [opt]: {
                                value: opt,
                                text,
                                overlap,
                            },
                        },
                    },
                },
            };
        }, {});

        return hierarchy;
    }

    return Object.keys(opts).map((key) => ({ label: key, value: key }));
};

export const getFoundationConfigParams = ({ fieldKey, configKey = "BCCB_4" }) => {
    const { content: { opts = {} } = {} } = clientFields[fieldKey] || {};

    return opts[configKey] || {};
};

export const getFirstOption = ({ indKey = "default", depKey, version = "v11-12" }) => {
    // Get the right options
    const fieldSettings = getFieldSettings(depKey, version) || {};

    const {
        content: { opts: { default: defaultOptionObj = {}, [indKey]: optionObj = {} } = {} },
    } = fieldSettings;

    const key = isEmpty(optionObj) ? Object.keys(defaultOptionObj)[0] : Object.keys(optionObj)[0];
    const { value, english, french } = isEmpty(optionObj)
        ? Object.values(defaultOptionObj)[0]
        : Object.values(optionObj)[0];

    const valueContent = value !== null ? { value: value } : {};

    // Select Field Type 1 - Label as value
    if (labelValueFields.includes(depKey)) {
        return english;
    }

    // Select Field Type 2 - object with id and sometimes value
    return {
        id: parseInt(key),
        ...valueContent,
    };
};

export const getDefaultOption = (fieldKey) => {
    const { default: defaultOptionObj = {} } = clientFields[fieldKey] || {};

    return defaultOptionObj;
};

export const getBaseUnits = (fieldKey, modelUnits) => {
    const { baseUnits = "m" } = clientFields[fieldKey] || {};
    const { displayUnits: { [modelUnits]: baseDisplayUnits = "" } = {} } = clientFields[fieldKey] || {};
    return {
        trueBase: baseUnits,
        displayBase: baseDisplayUnits,
    };
};

export const getUnitOptions = (type) => {
    const types = unitConversions[type] || {};
    return Object.keys(types).map((type) => type);
};

export const unitLabels = (unit) => {
    switch (unit) {
        case "m2":
            return "m²";
        case "cm2":
            return "cm²";
        case "mm2":
            return "mm²";
        case "ft2":
            return "ft²";
        case "in2":
            return "in²";
        case "m3":
            return "m³";
        case "ft3":
            return "ft³";
        case "gal_imp":
            return "gal(imp)";
        case "gal_us":
            return "gal(us)";
        case "F":
            return "°F";
        case "C":
            return "°C";
        case "L/sm2":
            return "L/s·m²";
        case "L/s/m2":
            return "L/s/m²";
        case "cfm/ft2":
            return "cfm/ft²";
        case "cm2/m2":
            return "cm²/m²";
        case "in2/100ft2":
            return "in²/100ft²";

        default:
            return unit;
    }
};

export const unitLongForm = (unit) => {
    switch (unit) {
        case "m":
            return "meters";
        case "ft":
            return "feet";
        default:
            return unit;
    }
};

export const getDrawingLineFieldOptions = (componentType) => {
    switch (componentType) {
        case "wall":
            return [
                {
                    label: "Wall Height",
                    value: {
                        key: "wallHeight",
                        path: "measurements.height",
                        dependant: "measurements.area",
                        dependantKey: "wallArea",
                        dependantMultiplier: "measurements.perimeter",
                    },
                },
            ];
        case "expFloor":
            return [
                {
                    label: "Floor Length",
                    value: {
                        key: "expFloorLength",
                        path: "measurements.length",
                    },
                },
            ];
        case "ceiling":
            return [
                {
                    label: "Ceiling Length",
                    value: {
                        key: "ceilingLength",
                        path: "measurements.length",
                    },
                },
            ];
        case "window":
            return [
                {
                    label: "Header Height",
                    value: {
                        key: "windowHeaderHeight",
                        path: "measurements.headerHeight",
                    },
                },
                {
                    label: "Overhang Width",
                    value: {
                        key: "windowOverhangWidth",
                        path: "measurements.overhangWidth",
                    },
                },
            ];
        case "floorHeader":
            return [
                {
                    label: "Floor Header Height",
                    value: {
                        key: "floorHeaderHeight",
                        path: "measurements.height",
                        dependant: "measurements.area",
                        dependantKey: "floorHeaderArea",
                        dependantMultiplier: "measurements.perimeter",
                    },
                },
            ];
        case "basement":
            return [
                {
                    label: "Total Wall Height",
                    value: {
                        key: "basementHeight",
                        path: "wall.measurements.height",
                    },
                },
                {
                    label: "Wall Depth Below Grade",
                    value: {
                        key: "basementDepth",
                        path: "wall.measurements.depth",
                    },
                },
                {
                    label: "Pony Wall Height",
                    value: {
                        key: "basementPonyWallHeight",
                        path: "wall.ponyWall.ponyWallHeight",
                    },
                },
            ];
        case "crawlspace":
            return [
                {
                    label: "Total Wall Height",
                    value: {
                        key: "crawlspaceWallHeight",
                        path: "wall.measurements.height",
                    },
                },
                {
                    label: "Wall Depth Below Grade",
                    value: {
                        key: "crawlspaceWallDepthBelowGrade",
                        path: "wall.measurements.depth",
                    },
                },
            ];
        default:
            return [];
    }
};

export const programName = {
    "": "General",
    "ca.nrcan.gc.OEE.ERS.ErsProgram": "EnerGuide Rating System",
    "ca.nrcan.gc.OEE.ERS2020NBC.ErsProgram": "EnerGuide Rating System 2020 NBC",
    "ca.nrcan.gc.OEE.ONrh.OnProgram": "Ontario Reference House",
};
