import React from 'react';
// import classes from './style.module.scss';
// import sharedClasses from 'features/Model/sharedStyles.module.scss';
import { Field } from 'redux-form';
import { getBaseUnits, getUnitOptions } from 'utils/fields';
import { maxLength, getValidation, getDecimalPlaces } from 'utils/fieldValidation';
import InputRow from 'components/Input/Row';
import Input from 'components/Input';
import InputWithUnits from 'components/Input/InputWithUnits';
import Select from 'components/Input/Select';

const charLim100 = maxLength(100);
const baseboardThermostatValidation = getValidation('baseboardsElecThermostats');
const baseboardCapacityValidation = getValidation('baseboardsOutputCapacity');
const baseboardSizingFactorValidation = getValidation('baseboardsSizingFactor');
const baseboardEffValidation = getValidation('baseboardsEff');

export default ({
    accessor,
    baseboardCapacityId,
    baseboardCapacityValue,
    baseboardCapacityUnits,
    modelUnits,
    change,
}) => {
    
    return (
        <>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Field
                    component={Input}
                    name={`${accessor}.system.baseboards.manufacturer`}
                    type="text"
                    label="Manufacturer Name"
                    placeholder="Enter Manufacturer Name"
                    validate={charLim100}
                />
                <Field
                    component={Input}
                    name={`${accessor}.system.baseboards.model`}
                    type="text"
                    label="Model Name"
                    placeholder="Enter Model Name"
                    validate={charLim100}
                />
                <Field
                    component={Input}
                    type="number"
                    name={`${accessor}.system.baseboards.numElecThermostats`}
                    label="Number of Electronic Thermostats"
                    placeholder={0.00}
                    validate={baseboardThermostatValidation}
                    decimals={getDecimalPlaces('baseboardsElecThermostats')}
                />
            </InputRow>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Field
                    component={Select}
                    name={`${accessor}.system.baseboards.specifications.outputCapacity`}
                    options={[
                        {
                            label:"User Specified",
                            value:{
                                id:0,
                                value:baseboardCapacityValue,
                                uiUnits: 'kW'
                            }
                        },
                        {
                            label:"Calculated",
                            value:{
                                id:1,
                                value:baseboardCapacityValue,
                                uiUnits: 'kW'
                            }
                        },
                    ]}
                    label="Capacity Type"
                    placeholder="Choose Input Type"
                    validate={baseboardCapacityValidation}
                    onChange={
                        ({ id }) => {
                            if (id === 1) {
                                change(`${accessor}.fansAndPumps.power.isCalculated`, true);
                            }
                        }
                    }
                    info={'If the capacity is unknown, set field to Calculated. Note that the calculated value will not appear in Volta SNAP, but will be updated prior to simulating in HOT2000.'}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.system.baseboards.specifications.outputCapacity.value`}
                    label="Output Capacity"
                    placeholder="0.00"
                    validate={baseboardCapacityValidation}
                    decimals={getDecimalPlaces('baseboardsOutputCapacity')}
                    change={change}
                    disabled={baseboardCapacityId === 1}
                    units={{
                        base:getBaseUnits('baseboardsOutputCapacity', modelUnits),
                        options:getUnitOptions('power'),
                        selected:baseboardCapacityUnits,
                        unitType:'power',
                        accessor:`${accessor}.system.baseboards.specifications.outputCapacity_u`
                    }}
                />
                <Field
                    component={Input}
                    type="number"
                    name={`${accessor}.system.baseboards.specifications.sizingFactor`}
                    label="Sizing Factor"
                    placeholder={0.00}
                    validate={baseboardSizingFactorValidation}
                    decimals={getDecimalPlaces('baseboardsSizingFactor')}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.system.baseboards.specifications.efficiency`}
                    label="Efficiency"
                    placeholder="0.00"
                    validate={baseboardEffValidation}
                    decimals={getDecimalPlaces('baseboardsEff')}
                    change={change}
                    units={{
                        base:getBaseUnits('baseboardsEff', modelUnits),
                        options:['%'],
                        selected:['%'],
                        unitType:'fraction',
                        accessor:`${accessor}.system.baseboards.specifications.efficiency_u`
                    }}
                />
            </InputRow>
        </>
    )
};