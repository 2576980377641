import { connect } from 'react-redux';
import Upload from './';
import moment from 'moment';
import { actions } from 'features/Model/_ducks';
import { mixpanel } from 'components/Mixpanel';

const { uploadDrawing, fetchModelFiles } = actions;

const mapStateToProps = ({
    model:{
        modelId,
        fileUpload:{
            isUploading=false,
            progress=0,
            success=false,
            uploadSuccess=false,
            isGenerating=false,
            error=''
        }={}
    },
    user:{
        uid,
    }
}) => ({
    modelId,
    uid,
    isUploading,
    isGenerating,
    progress,
    success,
    uploadSuccess,
    error
});

const mapDispatchToProps = (dispatch) => ({
    generateImages: async ({ modelId, uid, file }) => {
        const fileName = `Drawing-${moment().format('YYYYMMDDHHmmssSS')}`;
        await dispatch(uploadDrawing({ modelId, uid, file, fileName }));
        mixpanel.track("Drawing Upload");
    }
});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Upload);