import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import ErrorList from './';
import { actions } from 'features/Model/_ducks';

const { setExportErrors } = actions;

const mapStateToProps = (
    {
        model:{
            modelId,
            review:{
                exportErrors=[]
            }={}
        },
        form
    }
) => {
    const selector = formValueSelector('model');
    const { major: h2kMajor, minor: h2kMinor} = selector({form}, 'modelData.h2kVersion') || {};

    return {
        modelId,
        h2kVersion: `v${h2kMajor}-${h2kMinor}`,
        exportErrors
    }
};

const mapDispatchToProps = (dispatch) => ({
    setExportErrors:(errors)=>dispatch(setExportErrors(errors))
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorList);
