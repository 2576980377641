import React, { useState } from 'react';
import classes from './style.module.scss';
import sharedClasses from 'features/Model/sharedStyles.module.scss';
import { Field, unregisterField } from 'redux-form';
import { getOptions, getFirstOption, getBaseUnits, getUnitOptions } from 'utils/fields';
import { categories, getAllowedCategories } from 'utils/enclosure';
import { required, maxLength, getValidation, getDecimalPlaces } from 'utils/fieldValidation';
import { CSSTransition, SwitchTransition } from "react-transition-group";
import isEmpty from 'lodash/isEmpty';
import InputRow from 'components/Input/Row';
import Input from 'components/Input';
import InputWithUnits from 'components/Input/InputWithUnits';
import Select from 'components/Input/Select';
import Checkbox from 'components/Input/Checkbox';
import Button from 'components/Button';
import Accordion from 'components/Accordion';
import VentilatorStats from './VentilatorStats/container';
import Actions from '../Actions/container';

const charLim40 = maxLength(40);
const supplyFlowValidation = getValidation('ventSupply');
const exhaustFlowValidation = getValidation('ventExhaust');
const fanPowerValidation = getValidation('ventFanPower');
const operationScheduleValidation = getValidation('dryerOperationSchedule');
const exhaustDestValidation = getValidation('dryerExhaustDestination');


export default ({
    accessor,
    isSupplemental,
    supplyFlowUnits,
    exhaustFlowUnits,
    fanPowerUnits,
    isDefaultFanpower,
    operationScheduleId,
    operationScheduleValue,
    operationScheduleUnits,
    modelUnits,
    change,
    isUpgrade=false,
    formName,
    contentOpen,
}) => {
    
    return (
        <Accordion
            className={sharedClasses.accordion}
            heading={<span className={sharedClasses.heading}>Dryer</span>}
            large
            stats={()=><VentilatorStats accessor={accessor} formName={formName}/>}
            hoverActions={
                <Actions
                    label="Dryer"
                    type="dryer"
                    accessor={accessor}
                    isSupplemental={true}
                    change={change}
                    formName={formName}
                />
            }
            forceOpen={contentOpen}
        >
            <InputRow gridTemplate="2fr 2fr 1fr 1fr">
                <Field
                    component={Input}
                    name={`${accessor}.manufacturer`}
                    type="text"
                    label="Manufacturer"
                    placeholder=""
                    validate={charLim40}
                />
                <Field
                    component={Input}
                    name={`${accessor}.model`}
                    type="text"
                    label="Model"
                    placeholder=""
                    validate={charLim40}
                />
                <Field
                    component={Checkbox}
                    name={`${accessor}.isEnergyStar`}
                    label="Energy Star"
                    large
                    className={classes.checkbox}
                    type="checkbox"
                />
                <Field
                    component={Checkbox}
                    name={`${accessor}.isHVICertified`}
                    label="HVI Certified"
                    large
                    className={classes.checkbox}
                    type="checkbox"
                />
            </InputRow>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.supplyFlowrate`}
                    label="Supply Air Flow Rate"
                    placeholder={0.00}
                    validate={supplyFlowValidation}
                    decimals={getDecimalPlaces('ventSupply')}
                    change={change}
                    units={{
                        base:getBaseUnits('ventSupply', modelUnits),
                        options:getUnitOptions('airFlow'),
                        selected:supplyFlowUnits,
                        unitType:'airFlow',
                        accessor:`${accessor}.supplyFlowrate_u`
                    }}
                    disabled={true}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.exhaustFlowrate`}
                    label="Exhaust Air Flow Rate"
                    placeholder={0.00}
                    validate={exhaustFlowValidation}
                    decimals={getDecimalPlaces('ventExhaust')}
                    change={change}
                    units={{
                        base:getBaseUnits('ventExhaust', modelUnits),
                        options:getUnitOptions('airFlow'),
                        selected:exhaustFlowUnits,
                        unitType:'airFlow',
                        accessor:`${accessor}.exhaustFlowrate_u`
                    }}
                    disabled={false}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.fanPower1`}
                    label="Fan Power"
                    placeholder={0.00}
                    validate={fanPowerValidation}
                    decimals={getDecimalPlaces('ventFanPower')}
                    change={change}
                    units={{
                        base:getBaseUnits('ventFanPower', modelUnits),
                        options:getUnitOptions('power'),
                        selected:fanPowerUnits,
                        unitType:'power',
                        accessor:`${accessor}.fanPower1_u`
                    }}
                    disabled={isDefaultFanpower}
                />
                <Field
                    component={Checkbox}
                    name={`${accessor}.isDefaultFanpower`}
                    label="Default Fan Power"
                    large
                    className={classes.checkbox}
                    type="checkbox"
                />
            </InputRow>
            <InputRow gridTemplate="1fr 1fr 1fr">
                <Field
                    component={Select}
                    name={`${accessor}.operationSchedule`}
                    options={[
                        ...getOptions({ fieldKey: 'dryerOperationSchedule' }),
                        {
                            label: "User specified",
                            value: {
                                id: 4,
                                value: operationScheduleValue
                            }
                        },
                    ]}
                    label="Operation Schedule"
                    placeholder="Choose Operation Schedule"
                    validate={operationScheduleValidation}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.operationSchedule.value`}
                    label="Daily Operation"
                    placeholder={0.00}
                    disabled={operationScheduleId !== 4}
                    validate={operationScheduleValidation}
                    setTouched={true}
                    decimals={getDecimalPlaces('dryerOperationSchedule')}
                    change={change}
                    setValue={operationScheduleValue}
                    units={{
                        base:getBaseUnits('dryerOperationSchedule', modelUnits),
                        options:getUnitOptions('time'),
                        selected:operationScheduleUnits,
                        unitType:'time',
                        accessor:`${accessor}.operationSchedule_u`
                    }}
                />
                <Field
                    component={Select}
                    name={`${accessor}.exhaust`}
                    options={getOptions({ fieldKey: 'dryerExhaustDestination' })}
                    label="Exhaust Destination"
                    placeholder="Choose Exhaust Destination"
                    validate={exhaustDestValidation}
                />
            </InputRow>
        </Accordion>
    )
};