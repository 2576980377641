import React from "react";
import checklistComplete from "../../../../assets/images/icons/checklistComplete.svg";
import classes from "./style.module.scss";
import ProjectChecklistProgress from "components/ProjectChecklistProgress";
import { useState } from "react";

export const PhaseHeader = ({ phase, checkedItems, totalCheckboxes }) => {
    const progress = Math.round((checkedItems / totalCheckboxes) * 100);

    const isComplete = checkedItems === totalCheckboxes;

    return (
        <div className={classes.accordionHeader}>
            <p className={isComplete ? classes.fade : classes.solid}>{phase}</p>
            {!isComplete ? (
                <div className={classes.progress}>
                    <strong>{`${checkedItems}/${totalCheckboxes}`} Completed</strong>
                    <ProjectChecklistProgress progress={progress} />
                </div>
            ) : (
                <div className={classes.completed}>
                    <strong>Completed</strong>
                    <img src={checklistComplete} />
                </div>
            )}
        </div>
    );
};
