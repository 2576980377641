import React, { useEffect, useState } from "react";
import classes from "./style.module.scss";
import Select from "components/Input/Select";
import OpenIcon from "assets/images/icons/JSX/OpenDrawer";
import Heatmap from "components/Heatmap/Heatmap";
import Button from "components/Button";
import EmissionsToggle from "components/EmissionsToggle";
import TabHeader from "components/TabHeader";
import HourlyDetails from "features/Model/Review/Details/HourlyDetails";
import DetailsDrawer from "components/DetailsDrawer";
import { getHourlyEmissions } from "utils/emissions/getHourlyEmissions";
import {
    calculateEmissions,
    getEnergyWebsite,
    getDailyDataByYear,
    getDailyDataByMonth,
    getDailyConsumptionByMonth,
    calcTOUCostEstimate,
    calcTieredCost,
    provinceDetails,
} from "utils/heatmap/heatmapFunctions";
import { getDetailedHourlyResults } from "utils/results/api";
import { isEmpty } from "lodash";
import Checkbox from "components/Input/Checkbox";

const elecLoadHeader = "Hourly Electrical Load (kW)";
const emissionsHeader = "Hourly Emissions from Electricity Usage (gCO₂)";

const HourlyAnalysis = ({
    weatherProvTerrId,
    electricalLoad,
    maxHourlyLoad, //Across all upgrade cases, so we can set a consistent scale
    upgradePackageId,
    modelId,
    cliId,
    resultsCase,
}) => {
    const [open, toggleOpen] = useState(false);
    const [csvSubmitting, setCsvSubmitting] = useState(false);
    const [normalizeHourlyScale, setNormalizeHourlyScale] = useState(false);

    const peakWinterLoad = Math.max(...electricalLoad.slice(0, 2880), ...electricalLoad.slice(7296, 8760));
    const peakSummerLoad = Math.max(...electricalLoad.slice(2880, 7296));

    const peakOverallLoad = Math.max(...electricalLoad);
    const averageLoad = electricalLoad.reduce((sum, curr) => sum + curr, 0) / electricalLoad.length;
    const loadFactor = (100 * averageLoad) / peakOverallLoad;

    const peakSolarExport = Math.min(...electricalLoad);

    const [heatmapData, setHeatmapData] = useState(electricalLoad);
    const [selectedDataset, setSelectedDataset] = useState("hourlyElectricalLoad");
    const [selectedEmissionFactor, setSelectedEmissionFactor] = useState("hefFf");
    const [selectedEmissionYear, setSelectedEmissionYear] = useState(2023);
    const [chartHeader, setChartHeader] = useState(elecLoadHeader);

    const [electricityRateSet, setElectrictyRateSet] = useState({
        onPeak: 0.17,
        midPeak: 0.113,
        offPeak: 0.082,
        tier1: 0.098,
        tier2: 0.115,
    });

    const dailyDataByYear = getDailyDataByYear(electricalLoad);
    const dailyDataByMonth = getDailyDataByMonth(dailyDataByYear);
    const dailyConsumptionByMonth = getDailyConsumptionByMonth(dailyDataByMonth);

    let hourlyEmissionFactors = getHourlyEmissions({
        year: selectedEmissionYear,
        type: selectedEmissionFactor,
    }); //[gCO2/kWh]

    let emissions = calculateEmissions(electricalLoad, hourlyEmissionFactors); //[gCO2]

    const TOUCostEstimate = calcTOUCostEstimate(weatherProvTerrId, electricityRateSet, dailyConsumptionByMonth);

    const TieredCostEstimate = calcTieredCost(dailyConsumptionByMonth, electricityRateSet, weatherProvTerrId);

    const numberFormatter = (value, decimal) => {
        if (value >= 100000) {
            return "+$99,999";
        } else if (value <= -9) {
            return "-$99,999";
        } else if (isNaN(value)) {
            return "...";
        }

        return "$" + parseFloat(parseFloat(value).toFixed(decimal)).toLocaleString("en-IN", { useGrouping: true });
    };

    const hasSolar = Math.min(...electricalLoad) < 0 ? true : false;

    const summedHourlyEmissions = emissions.slice(0, 8760).reduce((partialSum, a) => partialSum + a, 0) / 1000000; //[tCO2/y]

    const summedAnnualEmissions =
        ((hourlyEmissionFactors.reduce((sum, factor) => {
            return sum + factor;
        }, 0) /
            hourlyEmissionFactors.length) *
            electricalLoad.reduce((sum, emission) => {
                return sum + emission;
            }, 0)) /
        1000000;

    const emissionFactorYears = [...Array(14).keys()].map((i) => ({ label: `${i + 2010}`, value: i + 2010 })).reverse();

    const handleChangeDataSet = (value) => {
        setSelectedDataset(value);
        if (value === "hourlyEmissions") {
            setHeatmapData(emissions);
            setChartHeader(emissionsHeader);
        } else if (value === "hourlyElectricalLoad") {
            setHeatmapData(electricalLoad);
            setChartHeader(elecLoadHeader);
        }
    };

    const handleChangeEmissionFactor = (value) => {
        setSelectedEmissionFactor(value);
        setHeatmapData(
            calculateEmissions(electricalLoad, getHourlyEmissions({ year: selectedEmissionYear, type: value }))
        );
    };

    const handleChangeEmissionYear = (value) => {
        setSelectedEmissionYear(value);
        setHeatmapData(
            calculateEmissions(
                electricalLoad,
                getHourlyEmissions({
                    year: value,
                    type: selectedEmissionFactor,
                })
            )
        );
    };

    useEffect(() => {
        setHeatmapData(electricalLoad);
        setSelectedDataset("hourlyElectricalLoad");
        setChartHeader(elecLoadHeader);
    }, [electricalLoad]);

    const getCSV = async () => {
        setCsvSubmitting(true);

        const { data: { hourlyResults = {} } = {} } = await getDetailedHourlyResults({
            modelId,
            cliId,
            resultsCase,
            upgradePackageId,
        });

        if (selectedDataset !== "hourlyEmissions" && !isEmpty(hourlyResults)) {
            const {
                hourlyElectricalLoadkWh = [],
                hourlyBaseLoadskWh = [],
                hourlyVentkWh = [],
                hourlyDhwkWh = [],
                hourlyHeatingkWh = [],
                hourlyCoolingkWh = [],
                hourlySolarPVkWh = [],
                // dbTemperature = [],
                totalHourlyHeatingLoad = [],
                // heatingEfficiencyOutput = [],
                // totalHourlyCoolingLoad = [],
                // coolingEfficiencyOutput = [],
                // hourlyUnscaledElecHeatingConsumption = [],
                // hourlyUnscaledCoolingConsumption = [],
            } = hourlyResults;

            const csvHeader = `hour,hourlyBaseLoadskWh,hourlyVentkWh,hourlyDhwkWh,hourlyHeatingkWh,hourlyCoolingkWh,hourlySolarPVkWh,hourlyElectricalLoadkWh,totalHourlyHeatingLoadW`;
            // `dbTemperature,totalHourlyHeatingLoad,heatingEfficiencyOutput,totalHourlyCoolingLoad,coolingEfficiencyOutput,hourlyUnscaledElecHeatingConsumption,hourlyUnscaledCoolingConsumption,`

            const csv = hourlyElectricalLoadkWh.reduce((cache, curr, i) => {
                return `${cache}\r\n${i},${hourlyBaseLoadskWh[i]},${hourlyVentkWh[i]},${hourlyDhwkWh[i]},${hourlyHeatingkWh[i]},${hourlyCoolingkWh[i]},${hourlySolarPVkWh[i]},${curr},${totalHourlyHeatingLoad[i]},`;
                // `${dbTemperature[i]},${totalHourlyHeatingLoad[i]},${heatingEfficiencyOutput[i]},${totalHourlyCoolingLoad[i]},${coolingEfficiencyOutput[i]},${hourlyUnscaledElecHeatingConsumption[i]},${hourlyUnscaledCoolingConsumption[i]},`
            }, csvHeader);

            let cb = new Blob([csv], { type: "text/csv" });

            let url = window.URL.createObjectURL(cb);
            let anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = `HourlyElectricalLoadEndUse_kWh.csv`;

            anchor.click();
            window.URL.revokeObjectURL(url);
            anchor.remove();
            setCsvSubmitting(false);
            return;
        }

        const csvHeader =
            selectedDataset === "hourlyEmissions" ? "HourlyElectricalEmissions_gCO2" : "HourlyElectricalLoad_kWh";
        let csv = `${csvHeader} \r\n`;
        for (let row of heatmapData) {
            csv += row + "";
            csv += "\r\n";
        }

        let cb = new Blob([csv], { type: "text/csv" });

        let url = window.URL.createObjectURL(cb);
        let anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = `${csvHeader}.csv`;

        anchor.click();
        window.URL.revokeObjectURL(url);
        anchor.remove();
        setCsvSubmitting(false);
        return;
    };

    const [check1, setCheck1] = useState(true);
    const [check2, setCheck2] = useState();

    const handleRadioClick = (event) => {
        if (check1 === true) {
            setCheck1(false);
            setCheck2(true);
        } else if (check2 === true) {
            setCheck2(false);
            setCheck1(true);
        }
    };

    const isValidPriceProvince = Object.keys(provinceDetails).includes(weatherProvTerrId.toString());

    return (
        <>
            <div className={classes.hourlyAnalysisHeader}>
                <h3>Hourly Analysis</h3>
                <TabHeader
                    className={classes.infoDrawer}
                    detailsTrigger={() => toggleOpen(true)}
                    detailsText="About this calculation"
                />
                <DetailsDrawer open={open} close={() => toggleOpen(false)} title="About this calculation">
                    <HourlyDetails />
                    <div className={classes.buttons}>
                        <Button large type="hollow" onClick={() => toggleOpen(false)}>
                            Close
                        </Button>
                    </div>
                </DetailsDrawer>
            </div>
            <div className={classes.heatmap}>
                <div className={classes.heatmapRow}>
                    {electricalLoad.length > 0 && (
                        <div className={`${classes.selectorRow}`}>
                            <Select
                                className={`${classes.selection} ${classes.heatmapSelect} `}
                                name="hourlyData"
                                label="Data Set"
                                input={{
                                    value: selectedDataset,
                                    onChange: handleChangeDataSet,
                                }}
                                options={[
                                    {
                                        value: "hourlyElectricalLoad",
                                        label: "Electrical Load",
                                    },
                                    ...(weatherProvTerrId === 5
                                        ? [
                                              {
                                                  value: "hourlyEmissions",
                                                  label: "Emissions",
                                              },
                                          ]
                                        : []),
                                ]}
                            />
                            {selectedDataset === "hourlyElectricalLoad" && (
                                <Checkbox
                                    className={classes.scaleToggle}
                                    name="normalizeHourlyScale"
                                    label="Normalize Scale"
                                    large
                                    input={{
                                        onChange: (event) => setNormalizeHourlyScale(!normalizeHourlyScale),
                                        value: normalizeHourlyScale,
                                    }}
                                />
                            )}
                            <Button className={classes.CSVbutton} onClick={getCSV} disabled={csvSubmitting}>
                                {csvSubmitting ? "Fetching Data..." : "Download CSV Data"}
                            </Button>
                        </div>
                    )}
                </div>
                <div className={classes.heatmapColumn}>
                    <div className={classes.secretDiv}>
                        <div className={classes.emissionsButtons}>
                            {selectedDataset === "hourlyEmissions" && (
                                <>
                                    <EmissionsToggle
                                        handleChangeEmissionFactor={handleChangeEmissionFactor}
                                        handleRadioClick={handleRadioClick}
                                        check1={check1}
                                        check2={check2}
                                    />
                                    <Select
                                        className={`${classes.selection} ${classes.heatmapSelect} `}
                                        style={{ innerHeight: "2.5rem" }}
                                        input={{
                                            value: selectedEmissionYear,
                                            onChange: handleChangeEmissionYear,
                                        }}
                                        options={emissionFactorYears}
                                        setValue={selectedEmissionYear}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                    <Heatmap
                        hourlyData={heatmapData}
                        dataSet={chartHeader}
                        maxHourlyLoad={maxHourlyLoad}
                        normalizeHourlyScale={normalizeHourlyScale}
                    />
                    {electricalLoad.length > 0 && (
                        <div
                            className={`${classes.totals} ${classes.heatmapInfo}`}
                            style={{ gridTemplateColumns: "1fr 1fr 1fr" }}
                        >
                            {selectedDataset === "hourlyElectricalLoad" ? (
                                <>
                                    <div>
                                        <p>Winter Peak Load</p>
                                        <div>
                                            <h1>{peakWinterLoad.toFixed(2)}</h1>
                                            <p>kW</p>
                                        </div>
                                    </div>
                                    <div>
                                        <p>Summer Peak Load</p>
                                        <div>
                                            <h1>{peakSummerLoad.toFixed(2)}</h1>
                                            <p>kW</p>
                                        </div>
                                    </div>
                                    {hasSolar && (
                                        <div>
                                            <p>Peak Solar Export</p>
                                            <div>
                                                <h1>{Math.abs(peakSolarExport).toFixed(2)}</h1>
                                                <p>kW</p>
                                            </div>
                                        </div>
                                    )}
                                    <div>
                                        <p>Load Factor</p>
                                        <div>
                                            <h1>{loadFactor.toFixed(2)}</h1>
                                            <p>%</p>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div>
                                        <p>Total Hourly Emissions</p>
                                        <div>
                                            <h1>{summedHourlyEmissions.toFixed(2)}</h1>
                                            <p>tCO₂/y</p>
                                        </div>
                                    </div>
                                    <div>
                                        <p>Total Annual Emissions</p>
                                        <div>
                                            <h1>{summedAnnualEmissions.toFixed(2)}</h1>
                                            <p>tCO₂/y</p>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                </div>

                {isValidPriceProvince && (
                    <>
                        <div className={classes.priceHeader}>
                            <h3>Electricity Prices for {provinceDetails[weatherProvTerrId].name}</h3>
                            <a target="_blank" className={classes.elecLink} href={getEnergyWebsite(weatherProvTerrId)}>
                                <OpenIcon />
                            </a>
                        </div>
                        <div className={classes.heatmapRow}>
                            <div className={classes.heatmapRow}>
                                {provinceDetails[weatherProvTerrId].hasTOU && (
                                    <div className={classes.costPanel}>
                                        <div className={`${classes.heatmapRow} ${classes.costPanelInner}`}>
                                            <div className={classes.heatmapColumn}>
                                                <div className={`${classes.heatmapRow} ${classes.priceInput}`}>
                                                    <p>On-peak Price ($/kWh)</p>
                                                    <input
                                                        className={classes.electRateInput}
                                                        type="number"
                                                        onChange={(event) => {
                                                            setElectrictyRateSet({
                                                                ...electricityRateSet,
                                                                onPeak: parseFloat(event.target.value),
                                                            });
                                                        }}
                                                        defaultValue={electricityRateSet.onPeak}
                                                    />
                                                </div>
                                                <div className={`${classes.heatmapRow} ${classes.priceInput}`}>
                                                    <p>Mid-peak Price ($/kWh)</p>
                                                    <input
                                                        className={classes.electRateInput}
                                                        type="number"
                                                        onChange={(event) => {
                                                            setElectrictyRateSet({
                                                                ...electricityRateSet,
                                                                midPeak: parseFloat(event.target.value),
                                                            });
                                                        }}
                                                        defaultValue={electricityRateSet.midPeak}
                                                    />
                                                </div>
                                                <div className={`${classes.heatmapRow} ${classes.priceInput}`}>
                                                    <p>Off-peak Price ($/kWh)</p>
                                                    <input
                                                        style={{ marginTop: "0.5rem" }}
                                                        className={classes.electRateInput}
                                                        type="number"
                                                        onChange={(event) => {
                                                            setElectrictyRateSet({
                                                                ...electricityRateSet,
                                                                offPeak: parseFloat(event.target.value),
                                                            });
                                                        }}
                                                        defaultValue={electricityRateSet.offPeak}
                                                    />
                                                </div>
                                            </div>
                                            <div className={classes.costEstimate}>
                                                <p>Time-Of-Use Annual Cost</p>
                                                <h1>{numberFormatter(Math.max(0, TOUCostEstimate), 0)}</h1>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {provinceDetails[weatherProvTerrId].hasTiered && (
                                    <div className={classes.costPanel}>
                                        <div className={`${classes.heatmapRow} ${classes.costPanelInner}`}>
                                            <div className={classes.heatmapColumn}>
                                                <div
                                                    className={`${classes.heatmapRow} ${classes.priceInput}`}
                                                    style={{ marginTop: "0.5rem" }}
                                                >
                                                    <p>Tier 1 Price ($/kWh)</p>
                                                    <input
                                                        className={classes.electRateInput}
                                                        type="number"
                                                        onChange={(event) => {
                                                            setElectrictyRateSet({
                                                                ...electricityRateSet,
                                                                tier1: parseFloat(event.target.value),
                                                            });
                                                        }}
                                                        defaultValue={electricityRateSet.tier1}
                                                    />
                                                </div>
                                                <div className={`${classes.heatmapRow} ${classes.priceInput}`}>
                                                    <p>Tier 2 Price ($/kWh)</p>
                                                    <input
                                                        className={classes.electRateInput}
                                                        type="number"
                                                        onChange={(event) => {
                                                            setElectrictyRateSet({
                                                                ...electricityRateSet,
                                                                tier2: parseFloat(event.target.value),
                                                            });
                                                        }}
                                                        defaultValue={electricityRateSet.tier2}
                                                    />
                                                </div>
                                            </div>
                                            <div className={classes.costEstimate}>
                                                <p>Tiered Annual Cost</p>
                                                <h1>{numberFormatter(Math.max(0, TieredCostEstimate), 0)}</h1>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default HourlyAnalysis;
