import isEmpty from 'lodash/isEmpty';

//Templates
import furnanceTemplate from 'features/Model/Mechanicals/Templates/heating/furnace.json';
import boilerTemplate from 'features/Model/Mechanicals/Templates/heating/boiler.json';
import baseboardsTemplate from 'features/Model/Mechanicals/Templates/heating/baseboards.json';
import comboHeatingTemplate from 'features/Model/Mechanicals/Templates/heating/comboHeating.json';
import p9ComboTemplate from 'features/Model/Mechanicals/Templates/heating/p9Combo.json';
import comboDhwTemplate from 'features/Model/Mechanicals/Templates/hotWater/comboDhw.json';
import additionalOpeningsTemplate from 'features/Model/Mechanicals/Templates/heating/additionalOpenings.json';
import multipleSystemsTemplate from 'features/Model/Mechanicals/Templates/heating/multiSystems.json';
import radiantHeatingTemplate from 'features/Model/Mechanicals/Templates/heating/radiantHeating.json';
import supplSystemTemplate from 'features/Model/Mechanicals/Templates/heating/supplSystem.json';

import airConditioningTemplate from 'features/Model/Mechanicals/Templates/coolingHeatPump/airConditioning.json';
import airHeatPumpTemplate from 'features/Model/Mechanicals/Templates/coolingHeatPump/airHeatPump.json';
import waterHeatPumpTemplate from 'features/Model/Mechanicals/Templates/coolingHeatPump/waterHeatPump.json';
import groundHeatPumpTemplate from 'features/Model/Mechanicals/Templates/coolingHeatPump/groundHeatPump.json';

import dhwTemplate from 'features/Model/Mechanicals/Templates/hotWater/dhw.json';
import dwhrTemplate from 'features/Model/Mechanicals/Templates/hotWater/dwhr.json';
import dwhrOptions from 'features/Model/Mechanicals/Templates/hotWater/dwhr-options.json';
import p9Options from 'features/Model/Mechanicals/Templates/hotWater/p9-options.json';

//Check p9Dhw

import dryerTemplate from 'features/Model/Mechanicals/Templates/ventilation/dryer.json';
import hrvTemplate from 'features/Model/Mechanicals/Templates/ventilation/hrv.json';
import ventBathroomTemplate from 'features/Model/Mechanicals/Templates/ventilation/ventBathroom.json';
import ventRangeHoodTemplate from 'features/Model/Mechanicals/Templates/ventilation/ventRangeHood.json';
import ventUtilityTemplate from 'features/Model/Mechanicals/Templates/ventilation/ventUtility.json';

import pvSystemTemplate from 'features/Model/Mechanicals/Templates/generation/pvSystem.json';

//Energy Star Systems
import airConditioningEStar from 'features/Model/Mechanicals/Templates/coolingHeatPump/eStarAC.json';
import airHeatPumpEStar from 'features/Model/Mechanicals/Templates/coolingHeatPump/eStarASHP.json';
import waterGroundHeatPumpEStar from 'features/Model/Mechanicals/Templates/coolingHeatPump/eStarW-GSHP.json';
import furnaceEStar from 'features/Model/Mechanicals/Templates/heating/eStarFurnaces.json';
import boilerEStar from 'features/Model/Mechanicals/Templates/heating/eStarBoilers.json';
import hotWaterEStar from 'features/Model/Mechanicals/Templates/hotWater/eStarDHW.json';
import hrvEStar from 'features/Model/Mechanicals/Templates/ventilation/eStarHrv.json';

export const getAllowedSystems = systemType => ({
    'heating':[
        'baseboards',
        'furnace',
        'boiler',
        'combo',
        'p9Combo'
    ],
    'cooling':[
        'None',
        'airConditioning',
        'airHeatPump',
        'waterHeatPump',
        'groundHeatPump'
    ]
}[systemType]);

export const getHeatingSystemTemplate = id => ({
    'baseboards':baseboardsTemplate,
    'furnace':furnanceTemplate,
    'boiler':boilerTemplate,
    'combo':comboHeatingTemplate,
    'p9Combo':p9ComboTemplate,
    'comboDhw':comboDhwTemplate,
    'additionalOpenings':additionalOpeningsTemplate,
    'multipleSystems':multipleSystemsTemplate,
    'radiantHeating':radiantHeatingTemplate,
    'supplSystems':supplSystemTemplate
}[id]);

export const getCoolingSystemTemplate = id => ({
    'airConditioning':airConditioningTemplate,
    'airHeatPump':airHeatPumpTemplate,
    'waterHeatPump':waterHeatPumpTemplate,
    'groundHeatPump':groundHeatPumpTemplate,
}[id]);

export const getVentilationTemplate = id => ({
    'dryer':dryerTemplate,
    'hrv':hrvTemplate,
    'bathroom':ventBathroomTemplate,
    'rangeHood':ventRangeHoodTemplate,
    'utility':ventUtilityTemplate,
}[id]);

export const getDhwTemplate = id => ({
    'dhw':dhwTemplate,
    'dwhr':dwhrTemplate,
    'combo':comboDhwTemplate,
}[id]);

export const getEStarOptions = (type) => ({
    'airConditioning': airConditioningEStar,
    'airHeatPump': airHeatPumpEStar,
    'waterHeatPump': waterGroundHeatPumpEStar,
    'groundHeatPump': waterGroundHeatPumpEStar,
    'furnace': furnaceEStar,
    'boiler': boilerEStar,
    'dhw': hotWaterEStar,
    'hrv': hrvEStar
}[type]);

export const getEStarManufacturerOptions = (systemType) => {
    let sysKey = systemType;
    if (systemType === 'groundHeatPump') {
        sysKey = 'waterHeatPump';
    } else if (systemType === 'dhw') {
        sysKey = 'primarySystem';
    }

    const eStarOpts = getEStarOptions(systemType);

    if (systemType && eStarOpts) {
        const manufacturers = Object.values(eStarOpts)
            .map(eStarData => eStarData[sysKey].manufacturer)
            .filter((value, index, self) => self.indexOf(value) === index)
            .sort();

        return manufacturers.map(name => ({ value:name, label:name }))
    } else if (systemType) {
        return [{
            value: false,
            label: 'Sorry, no Energy Star systems found'
        }]
    }

    return [{
        value: false,
        label: 'Please select a heating system type'
    }]
};

export const getEStarHrvManufacturerOptions = () => {
    return Object.keys(hrvEStar).map(name => ({ value:name, label:name }))
}

export const getEStarHrvModelOptions = (manufacturer) => {
    const {
        [manufacturer]: modelOpts = {}
    } = hrvEStar;

    return Object.keys(modelOpts).map(name => ({ value:name, label:name }))
}

export const getEStarHrvSpecs = ({manufacturer, model}) => {
    if (Object.keys(hrvEStar).includes(manufacturer) && model) {
        return hrvEStar[manufacturer][model]
    }
    return {}
}

export const getEStarModelOptions = ({systemType, manufacturer}) => {
    let sysKey = systemType;
    if (systemType === 'groundHeatPump') {
        sysKey = 'waterHeatPump';
    } else if (systemType === 'dhw') {
        sysKey = 'primarySystem';
    }
    
    const eStarOpts = getEStarOptions(systemType);
    if (systemType && eStarOpts) {
        const model = Object.entries(eStarOpts)
            .reduce((acc, [key, value]) => {
                if (value[sysKey].manufacturer === manufacturer) {
                    return [
                        ...acc, 
                        {
                            value: key,
                            label: value[sysKey].model
                        }
                    ]
                }
                return acc
            },[])
            

        return model
    }

    return [{
        value: false,
        label: 'Please select a manufacturer'
    }]
};

export const getDefaultFurnaceSpecs = (equipId) => ({
    '0': {
        efficiency: 100,
        pilotLight: 0,
        flueDiameter: 0
    },
    '1': {
        efficiency: 78,
        pilotLight: 25.3,
        flueDiameter: 152.4
    },
    '2': {
        efficiency: 78,
        pilotLight: 0,
        flueDiameter: 127
    },
    '3': {
        efficiency: 78,
        pilotLight: 0,
        flueDiameter: 101.6
    },
    '4': {
        efficiency: 80,
        pilotLight: 0,
        flueDiameter: 0
    },
    '5': {
        efficiency: 90,
        pilotLight: 0,
        flueDiameter: 0
    },
    '6': {
        efficiency: 71,
        pilotLight: 0,
        flueDiameter: 152.4
    },
    '7': {
        efficiency: 71,
        pilotLight: 0,
        flueDiameter: 127
    },
    '8': {
        efficiency: 83,
        pilotLight: 0,
        flueDiameter: 127
    },
    '9': {
        efficiency: 85,
        pilotLight: 0,
        flueDiameter: 0
    },
    '10': {
        efficiency: 93,
        pilotLight: 0,
        flueDiameter: 0
    },
    '11': {
        efficiency: 87,
        pilotLight: 0,
        flueDiameter: 0
    },
    '12': {
        efficiency: 80,
        pilotLight: 25.3,
        flueDiameter: 152.4
    },
    '13': {
        efficiency: 80,
        pilotLight: 0,
        flueDiameter: 127
    },
    '14': {
        efficiency: 80,
        pilotLight: 0,
        flueDiameter: 101.6
    },
    '15': {
        efficiency: 82,
        pilotLight: 0,
        flueDiameter: 0
    },
    '16': {
        efficiency: 91,
        pilotLight: 0,
        flueDiameter: 0
    },
    '17': {
        efficiency: 70,
        pilotLight: 0,
        flueDiameter: 127
    },
    '18': {
        efficiency: 75,
        pilotLight: 0,
        flueDiameter: 101.6
    },
    '19': {
        efficiency: 50,
        pilotLight: 0,
        flueDiameter: 101.6
    },
    '20': {
        efficiency: 60,
        pilotLight: 0,
        flueDiameter: 127
    },
    '21': {
        efficiency: 75,
        pilotLight: 0,
        flueDiameter: 127
    },
    '22': {
        efficiency: 70,
        pilotLight: 0,
        flueDiameter: 127
    },
    '23': {
        efficiency: 35,
        pilotLight: 0,
        flueDiameter: 127
    },
    '24': {
        efficiency: 60,
        pilotLight: 0,
        flueDiameter: 127
    },
    '25': {
        efficiency: 70,
        pilotLight: 0,
        flueDiameter: 127
    },
    '26': {
        efficiency: 75,
        pilotLight: 0,
        flueDiameter: 101.6
    },
    '27': {
        efficiency: 50,
        pilotLight: 0,
        flueDiameter: 203.2
    },
    '28': {
        efficiency: 60,
        pilotLight: 0,
        flueDiameter: 127
    },
    '29': {
        efficiency: 75,
        pilotLight: 0,
        flueDiameter: 127
    },
    '30': {
        efficiency: 70,
        pilotLight: 0,
        flueDiameter: 127
    },
    '31': {
        efficiency: 35,
        pilotLight: 0,
        flueDiameter: 127
    },
    '32': {
        efficiency: 60,
        pilotLight: 0,
        flueDiameter: 127
    },
    '33': {
        efficiency: 70,
        pilotLight: 0,
        flueDiameter: 127
    },
    '34': {
        efficiency: 75,
        pilotLight: 0,
        flueDiameter: 101.6
    },
    '35': {
        efficiency: 50,
        pilotLight: 0,
        flueDiameter: 203.2
    },
    '36': {
        efficiency: 60,
        pilotLight: 0,
        flueDiameter: 127
    },
    '37': {
        efficiency: 75,
        pilotLight: 0,
        flueDiameter: 127
    },
    '38': {
        efficiency: 70,
        pilotLight: 0,
        flueDiameter: 127
    },
    '39': {
        efficiency: 35,
        pilotLight: 0,
        flueDiameter: 127
    },
    '40': {
        efficiency: 60,
        pilotLight: 0,
        flueDiameter: 127
    },
    '41': {
        efficiency: 70,
        pilotLight: 0,
        flueDiameter: 127
    },
    '42': {
        efficiency: 75,
        pilotLight: 0,
        flueDiameter: 101.6
    },
    '43': {
        efficiency: 50,
        pilotLight: 0,
        flueDiameter: 203.2
    },
    '44': {
        efficiency: 60,
        pilotLight: 0,
        flueDiameter: 127
    },
    '45': {
        efficiency: 75,
        pilotLight: 0,
        flueDiameter: 127
    },
    '46': {
        efficiency: 70,
        pilotLight: 0,
        flueDiameter: 127
    },
    '47': {
        efficiency: 35,
        pilotLight: 0,
        flueDiameter: 127
    },
    '48': {
        efficiency: 60,
        pilotLight: 0,
        flueDiameter: 127
    }
}[equipId] || {
    efficiency: 0,
    pilotLight: 0,
    flueDiameter: 0
});

export const getDefaultBoilerSpecs = (equipId) => ({
    '0': {
        efficiency: 100,
        pilotLight: 0,
        flueDiameter: 0
    },
    '1': {
        efficiency: 78,
        pilotLight: 25.3,
        flueDiameter: 152.4
    },
    '2': {
        efficiency: 78,
        pilotLight: 0,
        flueDiameter: 127
    },
    '3': {
        efficiency: 78,
        pilotLight: 0,
        flueDiameter: 101.6
    },
    '4': {
        efficiency: 80,
        pilotLight: 0,
        flueDiameter: 0
    },
    '5': {
        efficiency: 90,
        pilotLight: 0,
        flueDiameter: 0
    },
    '6': {
        efficiency: 71,
        pilotLight: 0,
        flueDiameter: 152.4
    },
    '7': {
        efficiency: 71,
        pilotLight: 0,
        flueDiameter: 127
    },
    '8': {
        efficiency: 83,
        pilotLight: 0,
        flueDiameter: 127
    },
    '9': {
        efficiency: 85,
        pilotLight: 0,
        flueDiameter: 0
    },
    '10': {
        efficiency: 93,
        pilotLight: 0,
        flueDiameter: 0
    },
    '11': {
        efficiency: 87,
        pilotLight: 0,
        flueDiameter: 0
    },
    '12': {
        efficiency: 80,
        pilotLight: 25.3,
        flueDiameter: 152.4
    },
    '13': {
        efficiency: 80,
        pilotLight: 0,
        flueDiameter: 127
    },
    '14': {
        efficiency: 80,
        pilotLight: 0,
        flueDiameter: 101.6
    },
    '15': {
        efficiency: 82,
        pilotLight: 0,
        flueDiameter: 0
    },
    '16': {
        efficiency: 91,
        pilotLight: 0,
        flueDiameter: 0
    },
    '17': {
        efficiency: 50,
        pilotLight: 0,
        flueDiameter: 203.2
    },
    '18': {
        efficiency: 40,
        pilotLight: 0,
        flueDiameter: 0
    },
    '19': {
        efficiency: 50,
        pilotLight: 0,
        flueDiameter: 203.2
    },
    '20': {
        efficiency: 40,
        pilotLight: 0,
        flueDiameter: 0
    },
    '21': {
        efficiency: 50,
        pilotLight: 0,
        flueDiameter: 203.2
    },
    '22': {
        efficiency: 40,
        pilotLight: 0,
        flueDiameter: 0
    },
    '23': {
        efficiency: 50,
        pilotLight: 0,
        flueDiameter: 203.2
    },
    '24': {
        efficiency: 40,
        pilotLight: 0,
        flueDiameter: 0
    }
}[equipId] || {
    efficiency: 0,
    pilotLight: 0,
    flueDiameter: 0
});

export const getDefaultComboSpecs = (equipId) => ({
    '0': {
        efficiency: 78,
        pilotLight: 25.3,
        flueDiameter: 152.4
    },
    '1': {
        efficiency: 80,
        pilotLight: 0,
        flueDiameter: 127
    },
    '2': {
        efficiency: 82,
        pilotLight: 0,
        flueDiameter: 101.6
    },
    '3': {
        efficiency: 84,
        pilotLight: 0,
        flueDiameter: 0
    },
    '4': {
        efficiency: 90,
        pilotLight: 0,
        flueDiameter: 0
    },
    '5': {
        efficiency: 72,
        pilotLight: 0,
        flueDiameter: 152.4
    },
    '6': {
        efficiency: 82,
        pilotLight: 0,
        flueDiameter: 127
    },
    '7': {
        efficiency: 85,
        pilotLight: 0,
        flueDiameter: 127
    },
    '8': {
        efficiency: 87,
        pilotLight: 0,
        flueDiameter: 0
    },
    '9': {
        efficiency: 90,
        pilotLight: 0,
        flueDiameter: 0
    },
    '10': {
        efficiency: 78,
        pilotLight: 0,
        flueDiameter: 127
    },
    '11': {
        efficiency: 80,
        pilotLight: 0,
        flueDiameter: 127
    },
    '12': {
        efficiency: 82,
        pilotLight: 0,
        flueDiameter: 101.6
    },
    '13': {
        efficiency: 84,
        pilotLight: 0,
        flueDiameter: 0
    },
    '14': {
        efficiency: 90,
        pilotLight: 0,
        flueDiameter: 0
    }
}[equipId] || {
    efficiency: 0,
    pilotLight: 0,
    flueDiameter: 0
});

export const getDefaultDhwSpecs = (tankTypeId) => ({
    '0': {
        pilotEnergy: 0,
        flueDiameter: 0
    },
    '1': {
        pilotEnergy: 0,
        flueDiameter: 0
    },
    '2': {
        pilotEnergy: 0,
        flueDiameter: 0
    },
    '3': {
        pilotEnergy: 0,
        flueDiameter: 0
    },
    '4': {
        pilotEnergy: 0,
        flueDiameter: 0
    },
    '5': {
        pilotEnergy: 0,
        flueDiameter: 0
    },
    '6': {
        pilotEnergy: 0,
        flueDiameter: 76.2
    },
    '7': {
        pilotEnergy: 17.7,
        flueDiameter: 76.2
    },
    '8': {
        pilotEnergy: 0,
        flueDiameter: 0
    },
    '9': {
        pilotEnergy: 0,
        flueDiameter: 76.2
    },
    '10': {
        pilotEnergy: 0,
        flueDiameter: 0
    },
    '11': {
        pilotEnergy: 17.7,
        flueDiameter: 76.2
    },
    '12': {
        pilotEnergy: 0,
        flueDiameter: 76.2
    },
    '13': {
        pilotEnergy: 17.7,
        flueDiameter: 76.2
    },
    '14': {
        pilotEnergy: 0,
        flueDiameter: 0
    },
    '15': {
        pilotEnergy: 17.7,
        flueDiameter: 0
    },
    '16': {
        pilotEnergy: 0,
        flueDiameter: 0
    },
    '17': {
        pilotEnergy: 0,
        flueDiameter: 152.4
    },
    '18': {
        pilotEnergy: 0,
        flueDiameter: 0
    },
    '19': {
        pilotEnergy: 0,
        flueDiameter: 76.2
    },
    '20': {
        pilotEnergy: 17.7,
        flueDiameter: 76.2
    },
    '21': {
        pilotEnergy: 0,
        flueDiameter: 0
    },
    '22': {
        pilotEnergy: 0,
        flueDiameter: 76.2
    },
    '23': {
        pilotEnergy: 0,
        flueDiameter: 0
    },
    '24': {
        pilotEnergy: 17.7,
        flueDiameter: 76.2
    },
    '25': {
        pilotEnergy: 0,
        flueDiameter: 76.2
    },
    '26': {
        pilotEnergy: 17.7,
        flueDiameter: 76.2
    },
    '27': {
        pilotEnergy: 0,
        flueDiameter: 0
    },
    '28': {
        pilotEnergy: 17.7,
        flueDiameter: 0
    },
    '29': {
        pilotEnergy: 0,
        flueDiameter: 0 
    },
    '30': {
        pilotEnergy: 0,
        flueDiameter: 127
    },
    '31': {
        pilotEnergy: 0,
        flueDiameter: 127
    },
    '32': {
        pilotEnergy: 0,
        flueDiameter: 127
    },
    '33': {
        pilotEnergy: 0,
        flueDiameter: 127
    },
    '34': {
        pilotEnergy: 0,
        flueDiameter: 127
    },
    '35': {
        pilotEnergy: 0,
        flueDiameter: 127
    },
    '36': {
        pilotEnergy: 0,
        flueDiameter: 127
    },
    '37': {
        pilotEnergy: 0,
        flueDiameter: 127
    },
    '38': {
        pilotEnergy: 0,
        flueDiameter: 127
    },
    '39': {
        pilotEnergy: 0,
        flueDiameter: 127
    },
    '40': {
        pilotEnergy: 0,
        flueDiameter: 127
    },
    '41': {
        pilotEnergy: 0,
        flueDiameter: 127
    },
    '42': {
        pilotEnergy: 0,
        flueDiameter: 127
    },
    '43': {
        pilotEnergy: 0,
        flueDiameter: 127
    },
    '44': {
        pilotEnergy: 0,
        flueDiameter: 127
    },
    '45': {
        pilotEnergy: 0,
        flueDiameter: 127
    },
    '46': {
        pilotEnergy: 0,
        flueDiameter: 127
    },
    '47': {
        pilotEnergy: 0,
        flueDiameter: 127
    },
    '48': {
        pilotEnergy: 0,
        flueDiameter: 127
    },
    '49': {
        pilotEnergy: 0,
        flueDiameter: 127
    },
    '50': {
        pilotEnergy: 0,
        flueDiameter: 127
    },
    '51': {
        pilotEnergy: 0,
        flueDiameter: 127
    },
    '52': {
        pilotEnergy: 0,
        flueDiameter: 127
    },
    '53': {
        pilotEnergy: 0,
        flueDiameter: 127
    },
    '54': {
        pilotEnergy: 0,
        flueDiameter: 0
    }
}[tankTypeId] || {
    pilotEnergy: 0,
    flueDiameter: 0
});

//Note that for an integrated heat pump (tank type = 5), the following used to be the defaults
// '5': {
//     '1': 0.845,
//     '2': 0.833,
//     '3': 0.822,
//     '4': 0.805,
//     '5': 0.787,
//     '6': 0.879
// },
//All defaults changed to 0.9 to align with workaround

export const getDhwEfDefault = ({tankTypeId, tankVolId}) => ({
    '0': {
        '0': 0.866,
        '1': 0.845,
        '2': 0.833,
        '3': 0.822,
        '4': 0.805,
        '5': 0.787,
        '6': 0.879
    },
    '1': {
        '0': 0.932,
        '1': 0.915,
        '2': 0.906,
        '3': 0.897,
        '4': 0.884,
        '5': 0.871,
        '6': 0.942
    },
    '2': {
        '0': 0.936,
        '6': 0.936
    },
    '3': {
        '0': 1.9,
        '6': 1.9
    },
    '4': {
        '0': 1.9,
        '6': 1.9
    },
    '5': {
        '0': 0.0,
        '1': 0.9,
        '2': 0.9,
        '3': 0.9,
        '4': 0.9,
        '5': 0.9,
        '6': 0.9
    },
    '6': {
        '0': 0.613,
        '1': 0.573,
        '2': 0.554,
        '3': 0.535,
        '4': 0.507,
        '5': 0.479,
        '6': 0.63
    },
    '7': {
        '0': 0.613,
        '1': 0.573,
        '2': 0.554,
        '3': 0.535,
        '4': 0.507,
        '5': 0.479,
        '6': 0.63
    },
    '8': {
        '0': 0.48,
        '6': 0.48
    },
    '9': {
        '0': 0.83,
        '6': 0.83
    },
    '10': {
        '0': 0.83,
        '6': 0.83
    },
    '11': {
        '0': 0.8,
        '6': 0.8 
    },
    '12': {
        '0': 0.617,
        '1': 0.586,
        '2': 0.571,
        '3': 0.556,
        '4': 0.534,
        '5': 0.512,
        '6': 0.63
    },
    '13': {
        '0': 0.617,
        '1': 0.586,
        '2': 0.571,
        '3': 0.556,
        '4': 0.534,
        '5': 0.512,
        '6': 0.63
    },
    '14': {
        '0': 0.618,
        '1': 0.589,
        '2': 0.575,
        '3': 0.562,
        '4': 0.541,
        '5': 0.521,
        '6': 0.63 
    },
    '15': {
        '0': 0.618,
        '1': 0.589,
        '2': 0.575,
        '3': 0.562,
        '4': 0.541,
        '5': 0.521,
        '6': 0.63 
    },
    '16': {
        '0': 0.924,
        '1': 0.881,
        '2': 0.860,
        '3': 0.840,
        '4': 0.809,
        '5': 0.780,
        '6': 0.942
    },
    '17': {
        '0': 0.56,
        '1': 0.540,
        '2': 0.530,
        '3': 0.521,
        '4': 0.506,
        '5': 0.492,
        '6': 0.568
    },
    '18': {
        '0': 0.4,
        '6': 0.4
    },
    '19': {
        '0': 0.613,
        '1': 0.573,
        '2': 0.554,
        '3': 0.535,
        '4': 0.507,
        '5': 0.479,
        '6': 0.63
    },
    '20': {
        '0': 0.613,
        '1': 0.573,
        '2': 0.554,
        '3': 0.535,
        '4': 0.507,
        '5': 0.479,
        '6': 0.63
    },
    '21': {
        '0': 0.48,
        '6': 0.48
    },
    '22': {
        '0': 0.83,
        '6': 0.83
    },
    '23': {
        '0': 0.83,
        '6': 0.83
    },
    '24': {
        '0': 0.8,
        '6': 0.8
    },
    '25': {
        '0': 0.617,
        '1': 0.586,
        '2': 0.571,
        '3': 0.556,
        '4': 0.534,
        '5': 0.512,
        '6': 0.63
    },
    '26': {
        '0': 0.617,
        '1': 0.586,
        '2': 0.571,
        '3': 0.556,
        '4': 0.534,
        '5': 0.512,
        '6': 0.63
    },
    '27': {
        '0': 0.618,
        '1': 0.589,
        '2': 0.575,
        '3': 0.562,
        '4': 0.541,
        '5': 0.521,
        '6': 0.63
    },
    '28': {
        '0': 0.618,
        '1': 0.589,
        '2': 0.575,
        '3': 0.562,
        '4': 0.541,
        '5': 0.521,
        '6': 0.63
    },
    '29': {
        '0': 0.924,
        '1': 0.881,
        '2': 0.860,
        '3': 0.840,
        '4': 0.809,
        '5': 0.779,
        '6': 0.942
    },
    '30': {
        '0': 0,
        '6': 0,
    },
    '31': {
        '0': 0.3,
        '6': 0.3,
    },
    '32': {
        '0': 0.3,
        '6': 0.3,
    },
    '33': {
        '0': 0.3,
        '6': 0.3,
    },
    '34': {
        '0': 0.3,
        '6': 0.3,
    },
    '35': {
        '0': 0.3,
        '6': 0.3,
    },
    '36': {
        '0': 0,
        '6': 0,
    },
    '37': {
        '0': 0.3,
        '6': 0.3,
    },
    '38': {
        '0': 0.3,
        '6': 0.3,
    },
    '39': {
        '0': 0.3,
        '6': 0.3,
    },
    '40': {
        '0': 0.3,
        '6': 0.3,
    },
    '41': {
        '0': 0.3,
        '6': 0.3,
    },
    '42': {
        '0': 0,
        '6': 0,
    },
    '43': {
        '0': 0.3,
        '6': 0.3,
    },
    '44': {
        '0': 0.3,
        '6': 0.3,
    },
    '45': {
        '0': 0.3,
        '6': 0.3,
    },
    '46': {
        '0': 0.3,
        '6': 0.3,
    },
    '47': {
        '0': 0.3,
        '6': 0.3,
    },
    '48': {
        '0': 0,
        '6': 0,
    },
    '49': {
        '0': 0.3,
        '6': 0.3,
    },
    '50': {
        '0': 0.3,
        '6': 0.3,
    },
    '51': {
        '0': 0.3,
        '6': 0.3,
    },
    '52': {
        '0': 0.3,
        '6': 0.3,
    },
    '53': {
        '0': 0.3,
        '6': 0.3,
    },
    '54': {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0
    }
}?.[tankTypeId]?.[tankVolId] || 0);

export const getDwhrManufacturers = () => {
    const manufacturers = Object.values(dwhrOptions)
        .map(sys => sys.manufacturer)
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort();

    return manufacturers.map(name => ({ value:name, label:name }))
}

export const getDwhrModels = (selectedManufacturer) => {
    const models = Object.values(dwhrOptions)
        .filter((sys) => sys.manufacturer === selectedManufacturer)
        .sort();

    return models.map(sys => ({ value:sys.model, label:sys.model }))
}

export const getDwhrEff = ({manufacturer, model}) => {
    const sysMatch = Object.values(dwhrOptions).filter((sys) => sys.manufacturer === manufacturer && sys.model === model)[0] || {};

    return sysMatch.efficiencyRating || 0;
}

export const getP9Manufacturers = () => {
    return Object.keys(p9Options).map(name => ({ value:name, label:name }));
}

export const getP9Models = (selectedManufacturer) => {

    const {
        [selectedManufacturer]: models = {}
    } = p9Options;

    if (isEmpty(models)) {
        return {
            value: false,
            label: 'None'
        }
    }

    return Object.keys(models).map(name => ({ value:name, label:name }));
}

export const getP9Details = ({manufacturer, model}) => {
    const {
        [manufacturer]: {
            [model]: p9Details = {} 
        } = {}
    } = p9Options;

    return p9Details
}

export const getModuleSpecs = moduleTypeId => {
    if ([0,1,2,3,4].includes(moduleTypeId)) {
        return {
            0: {
                eff: 13,
                noct: 45,
                tempCoeff: 0.4
            },
            1: {
                eff: 11,
                noct: 45,
                tempCoeff: 0.4
            },
            2: {
                eff: 5,
                noct: 50,
                tempCoeff: 0.11
            },
            3: {
                eff: 7,
                noct: 46,
                tempCoeff: 0.24
            },
            4: {
                eff: 7.5,
                noct: 47,
                tempCoeff: 0.46
            },
            5: {
                eff: 13,
                noct: 45,
                tempCoeff: 0.4
            },
        }[moduleTypeId]
    }
    return {
        eff: 13,
        noct: 45,
        tempCoeff: 0.4
    }
}

export const getPvTemplate = () => pvSystemTemplate;

export const getMechanicalReviewData = ({heatingCoolingSystems={}, ventilationSystems={}, dhwSystem={}}) => {
    const {
        heating: {
            system: heatingSystem = {}
        } = {},
        cooling: {
            system: coolingSystem = {}
        } = {}
    } = heatingCoolingSystems;

    //Get Type and specs of heating system
    const heatingType = Object.keys(heatingSystem)[0];
    let heatingEff = 0;
    let heatingFuelType = 'naturalGas'
    let isAFUE = false
    if (['furnace','boiler'].includes(heatingType)) {
        heatingFuelType = {
            '0': 'Electric',
            '1': 'Natural Gas',
            '2': 'Oil',
            '3': 'Propane',
            '4': 'Mixed Wood',
            '5': 'Hardwood',
            '6': 'Softwood',
            '7': 'Wood Pellets',
        }[heatingSystem[heatingType].energySource];
        heatingEff = heatingSystem[heatingType].specifications.efficiency;
        isAFUE = !heatingSystem[heatingType].specifications.isSteadyState;

    } else if (heatingType === 'baseboards') {
        heatingFuelType = 'Electric';
        heatingEff = heatingSystem.baseboards.specifications.efficiency;
    }
    
    //Get type and specs of cooling system or heat pump
    const coolingType = Object.keys(coolingSystem)[0];
    let isHeatPump = false;
    let isCop = false;
    let heatPumpHspf = 0;
    if (['airHeatPump', 'waterHeatPump', 'groundHeatPump'].includes(coolingType)) {
        isHeatPump = true;
        isCop = coolingSystem[coolingType].specifications.heatingEfficiency.isCop;
        heatPumpHspf = (coolingSystem[coolingType].specifications.heatingEfficiency.value)*(isCop ? (3600/1055.1) : 1);
    }

    //Get space heating system type for SB12 compliance package
    let complianceHeatingType = 'spaceHeatingAbove92';
    let mechTableData = {};
    if (isHeatPump) {
        complianceHeatingType = 'electric';
        mechTableData.heating = {
            type: 'electricHeatPump',
            isCop,
            heatPumpHspf,
        }

    } else if (heatingType === 'baseboards') {
        complianceHeatingType = 'electric';
        mechTableData.heating = {
            type: 'electric',
            heatingEff,
        }
        
    } else {
        complianceHeatingType = heatingEff >= 92 ? 'spaceHeatingAbove92' : 'spaceHeatingBelow92';
        mechTableData.heating = {
            type: complianceHeatingType,
            heatingEff,
            isAFUE,
        }
    }

    //Get HRV data, if available
    const {
        supplementalSystems: {
            hrv: {
                efficiency1: supplHrvEff = 0
            } = {}
        } = {},
        wholeHouseSystems: {
            hrv: {
                efficiency1: wholeHrvEff = 0
            } = {}
        } = {},
    } = ventilationSystems;

    mechTableData.hrv = {
        hrvPresent: supplHrvEff !== 0 || wholeHrvEff !== 0,
        hrvEff: [wholeHrvEff,supplHrvEff].reduce((max, eff) => {
            if (eff > max) return eff;
            return max
        },0),
    };

    // Get DHW data, if available (note incoming is primary)
    const {
        energyFactor: {
            value: efValue
        } = {},
        heatPumpCOP = 0,
        tankType: {id:tankTypeId=0} = {}
    } = dhwSystem;

    mechTableData.dhw = {
        dhwPresent: !!efValue,
        efValue,
        heatPumpCOP: [4,5].includes(tankTypeId) ? heatPumpCOP : 0
    };

    return {
        complianceHeatingType,
        mechTableData
    }
}

