import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import isEmpty from 'lodash/isEmpty';
import P9Combo from './';

const mapStateToProps = (
    {
        form
    },
    {
        accessor
    }
) => {
    const selector = formValueSelector('model');
    const isP9UserSpecified = selector({form}, `${accessor}.system.p9Combo.isUserSpecified`);
    const p9Manufacturer = selector({form}, `${accessor}.system.p9Combo.manufacturer`);
    const p9Model = selector({form}, `${accessor}.system.p9Combo.model`);

    const tpfValue = selector({form}, `${accessor}.system.p9Combo.thermalPerformanceFactor`);
    const tpfUnits = selector({form}, `${accessor}.system.p9Combo.thermalPerformanceFactor_u`);
    const annualElecValue = selector({form}, `${accessor}.system.p9Combo.annualElectricity`);
    const annualElecUnits = selector({form}, `${accessor}.system.p9Combo.annualElectricity_u`);
    const heatingCapValue = selector({form}, `${accessor}.system.p9Combo.spaceHeatingCapacity`);
    const heatingCapUnits = selector({form}, `${accessor}.system.p9Combo.spaceHeatingCapacity_u`);
    const heatingEffValue = selector({form}, `${accessor}.system.p9Combo.spaceHeatingEfficiency`);
    const heatingEffUnits = selector({form}, `${accessor}.system.p9Combo.spaceHeatingEfficiency_u`);
    const whpfValue = selector({form}, `${accessor}.system.p9Combo.waterHeatingPerformanceFactor`);
    const whpfUnits = selector({form}, `${accessor}.system.p9Combo.waterHeatingPerformanceFactor_u`);
    const burnerInputValue = selector({form}, `${accessor}.system.p9Combo.burnerInput`);
    const burnerInputUnits = selector({form}, `${accessor}.system.p9Combo.burnerInput_u`);
    const recoveryEffValue = selector({form}, `${accessor}.system.p9Combo.recoveryEfficiency`);
    const recoveryEffUnits = selector({form}, `${accessor}.system.p9Combo.recoveryEfficiency_u`);

    const netEff15Value = selector({form}, `${accessor}.system.p9Combo.testData.netEfficiency.loadPerformance15`);
    const netEff40Value = selector({form}, `${accessor}.system.p9Combo.testData.netEfficiency.loadPerformance40`);
    const netEff100Value = selector({form}, `${accessor}.system.p9Combo.testData.netEfficiency.loadPerformance100`);
    const netEff15Units = selector({form}, `${accessor}.system.p9Combo.testData.netEfficiency.loadPerformance15_u`);
    const netEff40Units = selector({form}, `${accessor}.system.p9Combo.testData.netEfficiency.loadPerformance40_u`);
    const netEff100Units = selector({form}, `${accessor}.system.p9Combo.testData.netEfficiency.loadPerformance100_u`);
    
    const avgElec15Value = selector({form}, `${accessor}.system.p9Combo.testData.electricalUse.loadPerformance15`);
    const avgElec40Value = selector({form}, `${accessor}.system.p9Combo.testData.electricalUse.loadPerformance40`);
    const avgElec100Value = selector({form}, `${accessor}.system.p9Combo.testData.electricalUse.loadPerformance100`);
    const avgElec15Units = selector({form}, `${accessor}.system.p9Combo.testData.electricalUse.loadPerformance15_u`);
    const avgElec40Units = selector({form}, `${accessor}.system.p9Combo.testData.electricalUse.loadPerformance40_u`);
    const avgElec100Units = selector({form}, `${accessor}.system.p9Combo.testData.electricalUse.loadPerformance100_u`);

    const blower15Value = selector({form}, `${accessor}.system.p9Combo.testData.blowerPower.loadPerformance15`);
    const blower40Value = selector({form}, `${accessor}.system.p9Combo.testData.blowerPower.loadPerformance40`);
    const blower100Value = selector({form}, `${accessor}.system.p9Combo.testData.blowerPower.loadPerformance100`);
    const blower15Units = selector({form}, `${accessor}.system.p9Combo.testData.blowerPower.loadPerformance15_u`);
    const blower40Units = selector({form}, `${accessor}.system.p9Combo.testData.blowerPower.loadPerformance40_u`);
    const blower100Units = selector({form}, `${accessor}.system.p9Combo.testData.blowerPower.loadPerformance100_u`);
    
    const controlsPowerValue = selector({form}, `${accessor}.system.p9Combo.testData.controlsPower`);
    const controlsPowerUnits = selector({form}, `${accessor}.system.p9Combo.testData.controlsPower_u`);
    const circPowerValue = selector({form}, `${accessor}.system.p9Combo.testData.circulationPower`);
    const circPowerUnits = selector({form}, `${accessor}.system.p9Combo.testData.circulationPower_u`);
    const dailyElecUseValue = selector({form}, `${accessor}.system.p9Combo.testData.dailyElecUse`);
    const dailyElecUseUnits = selector({form}, `${accessor}.system.p9Combo.testData.dailyElecUse_u`);
    const standbyLossFanOnValue = selector({form}, `${accessor}.system.p9Combo.testData.standbyLossWithFanOn`);
    const standbyLossFanOnUnits = selector({form}, `${accessor}.system.p9Combo.testData.standbyLossWithFanOn_u`);
    const standbyLossFanOffValue = selector({form}, `${accessor}.system.p9Combo.testData.standbyLossWithFanOff`);
    const standbyLossFanOffUnits = selector({form}, `${accessor}.system.p9Combo.testData.standbyLossWithFanOff_u`);
    const oneHourHotWaterValue = selector({form}, `${accessor}.system.p9Combo.testData.oneHourRatingHotWater`);
    const oneHourHotWaterUnits = selector({form}, `${accessor}.system.p9Combo.testData.oneHourRatingHotWater_u`);
    const oneHourConcurrentValue = selector({form}, `${accessor}.system.p9Combo.testData.oneHourRatingConcurrent`);
    const oneHourConcurrentUnits = selector({form}, `${accessor}.system.p9Combo.testData.oneHourRatingConcurrent_u`);
    const modelUnits = selector({form}, `modelData.uiSettings.primaryUnits`) || {};

    const isDwhr = !isEmpty(selector({form}, `modelData.domesticHotWater.primarySystem.drainWaterHeatRecovery`) || {});

    return {
        accessor,
        isP9UserSpecified,
        p9Manufacturer,
        p9Model,
        tpfValue,
        tpfUnits,
        annualElecValue,
        annualElecUnits,
        heatingCapValue,
        heatingCapUnits,
        heatingEffValue,
        heatingEffUnits,
        whpfValue,
        whpfUnits,
        burnerInputValue,
        burnerInputUnits,
        recoveryEffValue,
        recoveryEffUnits,
        netEff15Value,
        netEff40Value,
        netEff100Value,
        avgElec15Value,
        avgElec40Value,
        avgElec100Value,
        blower15Value,
        blower40Value,
        blower100Value,
        netEff15Units,
        netEff40Units,
        netEff100Units,
        avgElec15Units,
        avgElec40Units,
        avgElec100Units,
        blower15Units,
        blower40Units,
        blower100Units,
        controlsPowerValue,
        controlsPowerUnits,
        circPowerValue,
        circPowerUnits,
        dailyElecUseValue,
        dailyElecUseUnits,
        standbyLossFanOnValue,
        standbyLossFanOnUnits,
        standbyLossFanOffValue,
        standbyLossFanOffUnits,
        oneHourHotWaterValue,
        oneHourHotWaterUnits,
        oneHourConcurrentValue,
        oneHourConcurrentUnits,
        isDwhr,
        modelUnits
    }
};

const mapDispatchToProps = () => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(P9Combo);