import React, { useState } from "react";
import { storeySubComponentsFactory } from "./utils/storeySubcomponentsFactory";
import { getSValidations } from "./utils/storeyValidations";

const withTakeoffStorey = (Component) => (props) => {
    const { takeoffState, dispatch, storeyType, translateComponent, title, modelUnits, openWindowsDrawer } = props;
    const [errors, setErrors] = useState({});

    const changeStoreyValue = (origin, value, unit = "", component) => {
        dispatch({
            type: "UPDATE_TAKEOFF_COMPONENT",
            data: {
                origin: origin,
                value: value,
                unit: unit,
                component: component,
            },
        });
    };
    const inputValidation = (input, origin) => {
        getSValidations(input, origin);
    };
    const handleError = (origin, error) => {
        const { err } = error;
        if (err) {
            let newErrors = errors;
            errors[origin] = true;
            setErrors(newErrors);
        } else if (!err) {
            if (errors[origin]) {
                let newErrors = errors;
                delete errors[origin];
                setErrors(newErrors);
            }
        }
    };
    const removeSubComponent = (id) => {
        dispatch({
            type: "REMOVE_TAKEOFF_SUBCOMP",
            data: {
                parent: storeyType,
                id: id,
            },
        });
        if (errors[id]) {
            let newErrors = errors;
            delete errors[id];
            setErrors(newErrors);
        }
    };
    const duplicateSubComponent = (id) => {
        dispatch({
            type: "DUPLICATE_TAKEOFF_SUBCOMP",
            data: {
                parent: storeyType,
                id: id,
            },
        });
    };
    const addSubComponent = (type) => {
        dispatch({
            type: "ADD_TAKEOFF_SUBCOMP",
            data: {
                parent: storeyType,
                component: storeySubComponentsFactory(type),
            },
        });
    };
    const editSubComponent = (id, field, value, unit = "") => {
        dispatch({
            type: "EDIT_TAKEOFF_SUBCOMP",
            data: {
                parent: storeyType,
                id: id,
                field: field,
                value: value,
                unit: unit,
            },
        });
    };
    const addTableComponent = (component) => {
        dispatch({
            type: "ADD_TOFF_TABLE_COMP",
            data: {
                parent: storeyType,
                component: { ...component },
            },
        });
    };
    const removeTableComponent = (id) => {
        dispatch({
            type: "REMOVE_TOFF_TABLE_COMP",
            data: {
                parent: storeyType,
                id: id,
            },
        });
    };
    const editTableComponent = (id, field, value, unit = "") => {
        dispatch({
            type: "EDIT_TOFF_TABLE_COMP",
            data: {
                parent: storeyType,
                id: id,
                field: field,
                value: value,
                unit: unit,
            },
        });
    };
    const takeoffStoreyActions = {
        takeoffState,
        dispatch,
        changeStoreyValue,
        getSValidations,
        inputValidation,
        handleError,
        removeSubComponent,
        addSubComponent,
        editSubComponent,
        duplicateSubComponent,
        addTableComponent,
        removeTableComponent,
        editTableComponent,
        storeyType,
        translateComponent,
        errors,
        title,
        modelUnits,
        openWindowsDrawer,
    };
    return <Component {...takeoffStoreyActions} />;
};

export default withTakeoffStorey;
