import React from 'react';

export default () => (
    <svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Side-Bar-/-Open-Active" transform="translate(-29.000000, -86.000000)">
                <g id="Main-Side-Bar" transform="translate(-1.000000, 0.000000)">
                    <g id="Nav-Items" transform="translate(0.000000, 75.000000)">
                        <g id="Group-3" transform="translate(16.000000, 0.000000)">
                            <g id="Add" transform="translate(14.000000, 11.000000)">
                                <rect id="Rectangle" fill="#FFFFFF" x="0" y="0" width="30" height="30" rx="4"></rect>
                                <line x1="10" y1="15" x2="20" y2="15" id="Line" stroke="#1C97BB" strokeWidth="2.25" strokeLinecap="square"></line>
                                <line x1="15" y1="10" x2="15" y2="20" id="Line" stroke="#1C97BB" strokeWidth="2.25" strokeLinecap="square"></line>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)