import types from "./types";

const initialState = {
    imageData: {},
    previousRefs: {},
    toDelete: [],
    toDetach: [],
    toRemoveFromFloor: [],
    activeImageIndex: 0,
    activeComponent: "",
    action: {},
    message: {},
    tools: {},
    saving: false,
    error: "",
    processingImages: false,
    loadingData: false,
    stashedState: {},
    isUploading: false,
    isGenerating: false,
    progress: 0,
    stage: {
        scale: 1,
        coords: { x: 0, y: 0 },
        size: {
            width: window.innerWidth - 512,
            height: window.innerHeight - 73,
        },
    },
    activeTool: "",
    didUserSawNewDrawing: false,
    isCopyScaleOpen: false,
    imageDataSnapshot: {},
    imageDataToCompare: {},
};

const drawingReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.DRAWING_DATA_SAVING_START:
            return {
                ...state,
                saving: true,
            };
        case types.DRAWING_DATA_SAVING_SUCCESS:
            return {
                ...state,
                saving: false,
                imageData: action.payload,
                imageDataToCompare: action.payload,
            };
        case types.DRAWING_DATA_SAVING_FAILURE:
            return {
                ...state,
                saving: false,
                error: action.payload,
            };
        case types.UPLOADING_DRAWING_START:
            return {
                ...state,
                isUploading: true,
            };
        case types.UPLOADING_DRAWING_SUCCESS:
            return {
                ...state,
                isUploading: false,
            };
        case types.UPLOADING_DRAWING_FAILURE:
            return {
                ...state,
                isUploading: false,
                error: action.payload,
            };
        case types.GENERATING_DRAWING_START:
            return {
                ...state,
                isGenerating: true,
            };
        case types.GENERATING_DRAWING_SUCCESS:
            return {
                ...state,
                isGenerating: false,
            };
        case types.GENERATING_DRAWING_FAILURE:
            return {
                ...state,
                isGenerating: false,
                error: action.payload,
            };
        case types.FETCH_DRAWING_DATA_SUCCESS:
            return {
                ...state,
                imageData: action.payload.imageData,
                imageDataToCompare: action.payload.imageData,
                didUserSawNewDrawing: action.payload.didUserSawNewDrawing,
            };
        case types.SET_UPLOADING_PROGRESS:
            return {
                ...state,
                progress: action.payload,
            };
        case types.TOGGLE_IMAGE_PROCESSING:
            return {
                ...state,
                processingImages: !state.processingImages,
            };
        case types.UPDATE_STAGE:
            return {
                ...state,
                stage: {
                    ...state.stage,
                    ...action.payload,
                },
            };
        case types.SET_MESSAGE:
            return {
                ...state,
                message: action.payload,
            };
        case types.SET_TOOLS:
            return {
                ...state,
                tools: action.payload,
            };
        case types.SET_ACTIVE_IMAGE_INDEX:
            return {
                ...state,
                activeImageIndex: action.payload,
            };
        case types.SET_ACTION:
            return {
                ...state,
                action: action.payload,
            };
        case types.CLEAR_DRAWING_DATA:
            return {
                ...initialState,
            };
        case types.SET_SCALE: {
            const image = state.imageData[action.payload.image];

            return {
                ...state,
                imageData: {
                    ...state.imageData,
                    [action.payload.image]: {
                        ...image,
                        scale: action.payload.scale,
                    },
                },
            };
        }
        case types.SET_ACTIVE_TOOL:
            return {
                ...state,
                activeTool: action.payload,
            };
        case types.SET_ACTIVE_COMPONENT:
            return {
                ...state,
                activeComponent: action.payload,
            };
        case types.SET_POLYGON: {
            const image = state.imageData[action.payload.image];

            const { polygons = {} } = image || {};

            return {
                ...state,
                imageData: {
                    ...state.imageData,
                    [action.payload.image]: {
                        ...image,
                        polygons: {
                            ...polygons,
                            [action.payload.polygon.id]: action.payload.polygon,
                        },
                    },
                },
            };
        }
        case types.SET_LINE: {
            const image = state.imageData[action.payload.image];

            return {
                ...state,
                imageData: {
                    ...state.imageData,
                    [action.payload.image]: {
                        ...image,
                        lines: {
                            ...image.lines,
                            [action.payload.line.id]: action.payload.line,
                        },
                    },
                },
            };
        }
        case types.SET_RECTANGLE: {
            const image = state.imageData[action.payload.image];
            const { rectangles = {} } = image;
            const { [action.payload.rectangle.id]: rectangle = {} } = rectangles;

            return {
                ...state,
                imageData: {
                    ...state.imageData,
                    [action.payload.image]: {
                        ...image,
                        rectangles: {
                            ...rectangles,
                            [action.payload.rectangle.id]: {
                                ...rectangle,
                                ...action.payload.rectangle,
                            },
                        },
                    },
                },
            };
        }
        case types.SET_MULTI_POINT_LINE: {
            const image = state.imageData[action.payload.image];

            const { multiPointLines = {} } = image || {};

            return {
                ...state,
                imageData: {
                    ...state.imageData,
                    [action.payload.image]: {
                        ...image,
                        multiPointLines: {
                            ...multiPointLines,
                            [action.payload.multiPointLine.id]: action.payload.multiPointLine,
                        },
                    },
                },
            };
        }
        case types.SET_DID_USER_SAW_NEW_DRAWING:
            return {
                ...state,
                didUserSawNewDrawing: action.payload,
            };
        case types.REMOVE_POLYGON: {
            const image = state.imageData[action.payload.image];
            const { polygons } = image;
            const { [action.payload.polygonId]: polygonToRemove, ...remainingPolygons } = polygons;

            return {
                ...state,
                imageData: {
                    ...state.imageData,
                    [action.payload.image]: {
                        ...image,
                        polygons: remainingPolygons,
                    },
                },
            };
        }
        case types.REMOVE_RECTANGLE: {
            const image = state.imageData[action.payload.image];
            const { rectangles } = image;
            const { [action.payload.rectangleId]: rectangleToRemove, ...remainingRectangles } = rectangles;

            return {
                ...state,
                imageData: {
                    ...state.imageData,
                    [action.payload.image]: {
                        ...image,
                        rectangles: remainingRectangles,
                    },
                },
            };
        }
        case types.REMOVE_LINE: {
            const image = state.imageData[action.payload.image];
            const { lines } = image;
            const { [action.payload.lineId]: lineToRemove, ...remainingLines } = lines;

            return {
                ...state,
                imageData: {
                    ...state.imageData,
                    [action.payload.image]: {
                        ...image,
                        lines: remainingLines,
                    },
                },
            };
        }
        case types.REMOVE_MULTI_POINT_LINE: {
            const image = state.imageData[action.payload.image];
            const { multiPointLines } = image;
            const { [action.payload.lineId]: lineToRemove, ...remainingMultiPointLines } = multiPointLines;

            return {
                ...state,
                imageData: {
                    ...state.imageData,
                    [action.payload.image]: {
                        ...image,
                        multiPointLines: remainingMultiPointLines,
                    },
                },
            };
        }
        case types.TOGGLE_IS_COPY_SCALE_OPEN:
            return {
                ...state,
                isCopyScaleOpen: action.payload,
            };
        case types.RESET_DRAWING:
            return {
                ...initialState,
                didUserSawNewDrawing: true,
            };
        case types.IMAGE_DATA_SNAPSHOT_UPDATE:
            return {
                ...state,
                imageDataSnapshot: action.payload,
            };
        default:
            return state;
    }
};

export default drawingReducer;
