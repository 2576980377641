import React, { useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectImageData, selectActiveImageIndex, selectMessage } from "../../_ducks/selector";
import { setMessage, setScale, toggleIsCopyScaleOpen } from "../../_ducks/actions";

import Button from "components/Button";
import InputWithUnits from "components/Input/InputWithUnits";

import { getUnitOptions, unitLongForm } from "utils/fields";

import { tip, cancelButton, buttonRow, flexButtonRow, singleRow, saveButton } from "../style.module.scss";

const splitScaleFeetInches = (input, displayUnits) => {
    const inputInInches = input * 3.28084 * 12;
    const initInputInches = input === 0 || displayUnits === "m" ? 0 : Math.round(1000 * (inputInInches % 12)) / 1000; //actually stored in [in]
    const initInputRoundedFt = input === 0 || displayUnits === "m" ? 0 : input - initInputInches / (3.28084 * 12); //[m]

    return {
        initInputInches,
        initInputRoundedFt,
    };
};

const SetScale = ({
    cancel,
    onSet,
    imageData,
    image,
    activeImageIndex,
    primaryUnits = "m",
    setScale,
    setMessage,
    toggleIsCopyScaleOpen,
    images,
}) => {
    const { scale } = imageData[image] || {};

    const { points = [], input = 0, displayUnits = primaryUnits, isSet = false } = scale || {};

    const multiSelectOptions = images
        ? images
              .map((image, index) => ({
                  value: image.fileName,
                  label: `Page ${index + 1}${index === activeImageIndex ? " (Current Page)" : ""}`,
                  disabled:
                      index === activeImageIndex ||
                      imageData[image.fileName]?.scale?.copiedFrom ||
                      imageData[image.fileName]?.scale?.isSet,
                  image: image.signedURL,
              }))
              .filter(({ disabled }) => !disabled)
        : [];

    const { initInputInches, initInputRoundedFt } = splitScaleFeetInches(input, displayUnits);

    const [inputUnits, setInputUnits] = useState(displayUnits);
    const [inputValue, setInputValue] = useState(initInputRoundedFt || input);
    const [inputInchesValue, setInchesValue] = useState(initInputInches);

    const inputScale =
        inputUnits === "ft" && inputInchesValue > 0 && inputInchesValue <= 12
            ? inputValue + inputInchesValue * 0.0254
            : inputValue;

    return points.length < 4 ? (
        <div>
            <p>
                <span className={tip}>Getting Started:</span> Click anywhere on the active page to place the{" "}
                {points?.length === 0 ? "first" : "second"} scale point.
            </p>
            <Button smallPadding onClick={cancel} type="hollow" className={cancelButton}>
                Cancel
            </Button>
        </div>
    ) : (
        <div>
            <InputWithUnits
                label={
                    isSet
                        ? `Change scale for Page ${activeImageIndex + 1}*`
                        : `Set scale for Page ${activeImageIndex + 1}*`
                }
                name="scale"
                type="number"
                input={{
                    onChange: (value) => setInputValue(value),
                    value: inputValue,
                }}
                setValue={inputValue}
                change={() => {}}
                onUnitChange={(unit) => {
                    setInputUnits(unit);
                    if (unit === "m") {
                        //changing from ft to m, ensure that any remainder inches are captured
                        setInputValue(input);
                    } else if (unit === "ft") {
                        //Changing back from m to ft, split into ft and inches
                        const { initInputInches, initInputRoundedFt } = splitScaleFeetInches(input, unit);

                        setInputValue(initInputRoundedFt);
                        setInchesValue(initInputInches);
                    }
                }}
                units={{
                    base: {
                        trueBase: "m",
                        displayBase: primaryUnits,
                    },
                    options: getUnitOptions("drawingScale"),
                    selected: displayUnits,
                    unitType: "drawingScale",
                    accessor: "test",
                }}
                decimals={4}
                placeholder={0}
                info={`Input the length the scale line represents in ${unitLongForm(inputUnits)}`}
            />
            {inputUnits === "ft" && (
                <div style={{ marginTop: "0.75rem" }}>
                    <InputWithUnits
                        label={"Remainder in inches"}
                        name="scaleInch"
                        type="number"
                        input={{
                            onChange: (value) => setInchesValue(value),
                            value: inputInchesValue,
                        }}
                        setValue={inputInchesValue}
                        change={() => {}}
                        units={{
                            base: {
                                trueBase: "in",
                                displayBase: "in",
                            },
                            options: ["in"],
                            selected: ["in"],
                            unitType: "length",
                            accessor: "test",
                        }}
                        decimals={2}
                        placeholder={0}
                        info={`Input any remaining length in inches. This number will converted into feet and added to the scale.`}
                    />
                </div>
            )}
            <div className={`${buttonRow} ${flexButtonRow} ${!cancel && singleRow}`}>
                {cancel && (
                    <Button smallPadding onClick={cancel} type="hollow" className={cancelButton}>
                        Cancel
                    </Button>
                )}
                <Button
                    smallPadding
                    onClick={() => {
                        setScale({
                            image,
                            scale: {
                                ...scale,
                                input: inputScale,
                                displayUnits: inputUnits,
                                isSet: true,
                                copiedFrom: "",
                            },
                        });

                        if (!isSet && multiSelectOptions.length > 0) {
                            toggleIsCopyScaleOpen(true);
                        }

                        if (multiSelectOptions.length === 0) {
                            setMessage({
                                message: `Scale set successfully`,
                                type: "success",
                                anchorOrigin: {
                                    vertical: "top",
                                    horizontal: "right",
                                },
                                autoHideDuration: 2000,
                                isOpen: true,
                            });
                        }

                        if (isSet || multiSelectOptions.length > 0) {
                            const imageDataArr = Object.keys(imageData);

                            if (imageDataArr.length > 1) {
                                for (let i = 0; i < imageDataArr.length; i++) {
                                    const imgScale = imageData[imageDataArr[i]].scale;

                                    if (!imgScale) continue;

                                    if (!imgScale.copiedFrom) continue;

                                    if (imgScale.copiedFrom !== image) continue;

                                    setScale({
                                        image: imageDataArr[i],
                                        scale: {
                                            ...imgScale,
                                            input: inputScale,
                                            displayUnits: inputUnits,
                                        },
                                    });
                                }
                            }

                            setMessage({
                                message: "Scale updated successfully",
                                type: "success",
                                anchorOrigin: {
                                    vertical: "top",
                                    horizontal: "right",
                                },
                                autoHideDuration: 2000,
                                isOpen: true,
                            });
                        }

                        const { initInputInches: newInputInches, initInputRoundedFt: newInputFt } =
                            splitScaleFeetInches(inputScale, inputUnits);

                        setInchesValue(newInputInches);
                        // setMessage({ ...message, isOpen: false });
                    }}
                    className={saveButton}
                    disabled={inputUnits === displayUnits && inputValue === input && inputInchesValue === 0}
                >
                    {isSet ? "Change Scale" : "Set Scale"}
                </Button>
            </div>
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    imageData: selectImageData,
    activeImageIndex: selectActiveImageIndex,
    message: selectMessage,
});

const mapDispatchToProps = (dispatch) => ({
    setScale: ({ image, scale }) => dispatch(setScale({ image, scale })),
    setMessage: ({ message, type, anchorOrigin, autoHideDuration, isOpen, direction }) =>
        dispatch(
            setMessage({
                message,
                type,
                anchorOrigin,
                autoHideDuration,
                isOpen,
                direction,
            })
        ),
    toggleIsCopyScaleOpen: (bool) => dispatch(toggleIsCopyScaleOpen(bool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SetScale);
