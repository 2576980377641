import React, { useEffect } from "react";
import WorkbookCheckbox from "components/Input/WorkbookCheckbox";
import { Field } from "redux-form";
import Button from "components/Button";
import Select from "components/Input/Select";
import classes from "./style.module.scss";
import netZeroClasses from "../style.module.scss";
import Export from "assets/images/icons/JSX/Export";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import InputWithUnits from "components/Input/InputWithUnits";
import DayPicker from "components/Input/DayPicker";
import { getValidation } from "utils/fieldValidation";
import moment from "moment/moment";
import Delete from "assets/images/icons/JSX/Delete";

const chbaNzTargetCompletionDateValidation = getValidation("chbaNzTargetCompletionDate");

const PVSystemCommissioning = ({
    accessor,
    change,
    pvSystems,
    pvStrings,
    totalModules,
    moduleRating,
    currentDeclAddress = "",
    declarationAddress = "",
}) => {
    const dateId = moment.now();

    useEffect(() => {
        if (declarationAddress !== "" && currentDeclAddress === "") {
            change(`${accessor}.declaration.address`, declarationAddress);
        }
    }, [declarationAddress]);

    const addSystem = (event) => {
        event.stopPropagation();
        if (Object.values(pvSystems).length < 5) {
            change(`${accessor}.systemOrientation.systems`, {
                ...pvSystems,
                [`system-${dateId}`]: {
                    tiltAngle: 0,
                    azimuth: 0,
                    location: "roof",
                    pvModules: 0,
                },
            });
        }
    };

    const deleteSystem = (key) => {
        const { [key]: toRemove, ...systemsToKeep } = pvSystems;
        change(`${accessor}.systemOrientation.systems`, systemsToKeep);
    };

    const addString = (event) => {
        event.stopPropagation();
        if (Object.values(pvStrings).length < 5) {
            change(`${accessor}.arrayTests.pvStringOpenCircuitVoltage.strings`, {
                ...pvStrings,
                [`string-${dateId}`]: {
                    voltage: 0,
                },
            });
        }
    };

    const deleteString = (key) => {
        const { [key]: toRemove, ...stringsToKeep } = pvStrings;
        change(`${accessor}.arrayTests.pvStringOpenCircuitVoltage.strings`, stringsToKeep);
    };

    const sumPvModules = Object.values(pvSystems).reduce((acc, value) => {
        return acc + value.pvModules;
    }, 0);

    return (
        <>
            <div className={classes.topRow}>
                <div className={classes.inputColumn}>
                    <div>
                        <h3>Documentation</h3>
                        <p>The system documentation should include, as a minimum, the following elements:</p>
                    </div>
                    <Field
                        className={classes.netZeroCheckbox}
                        component={WorkbookCheckbox}
                        name={`${accessor}.documentation.asBuilt`}
                        label="As-built system drawings and specification sheets of all system components from suppliers/manufacturers"
                        // className={classes.checkbox}
                        type="checkbox"
                    />
                    <Field
                        className={classes.netZeroCheckbox}
                        component={WorkbookCheckbox}
                        name={`${accessor}.documentation.operationsManuals`}
                        label="Operations manuals of all system components from suppliers/manufacturers"
                        // className={classes.checkbox}
                        type="checkbox"
                    />
                    <Field
                        className={classes.netZeroCheckbox}
                        component={WorkbookCheckbox}
                        name={`${accessor}.documentation.verification`}
                        label="Verification of proper system installation, performance and operation (via tests & photos)"
                        // className={classes.checkbox}
                        type="checkbox"
                    />
                    <Field
                        className={classes.netZeroCheckbox}
                        component={WorkbookCheckbox}
                        name={`${accessor}.documentation.gridConnection`}
                        label="Grid connection confirmation"
                        // className={classes.checkbox}
                        type="checkbox"
                    />
                    <Field
                        className={classes.netZeroCheckbox}
                        component={WorkbookCheckbox}
                        name={`${accessor}.documentation.warranty`}
                        label="The installation is under warranty, including installer's workmanship and performance warranty"
                        // className={classes.checkbox}
                        type="checkbox"
                    />
                    <Field
                        className={classes.netZeroCheckbox}
                        component={WorkbookCheckbox}
                        name={`${accessor}.documentation.training`}
                        label="Training/orientation to owner on basic system operation, typically at pre-delivery inspection"
                        // className={classes.checkbox}
                        type="checkbox"
                    />
                </div>
                {/* <Button smallPadding icon={Export} className={netZeroClasses.exportButton}>
                    <span>Export Net Zero Workbook </span>
                </Button> */}
            </div>
            <div>
                <h3>PV System Details</h3>
                <InputRow gridTemplate="1fr 1fr 1fr">
                    <Field
                        component={Input}
                        name={`${accessor}.siteInformation.pvModule`}
                        type="text"
                        label="PV module make"
                    />
                    <Field
                        component={InputWithUnits}
                        name={`${accessor}.siteInformation.moduleRating`}
                        type="number"
                        label="Module nameplate rating"
                        units={{
                            base: ["W"],
                            options: ["W"],
                            selected: ["W"],
                            unitType: "power",
                            accessor: `${accessor}.siteInformation.moduleRating_u`,
                        }}
                    />
                    <Field
                        component={Input}
                        name={`${accessor}.siteInformation.totalModules`}
                        type="text"
                        setValue={sumPvModules}
                        label="Total number of PV modules"
                        disabled
                    />
                </InputRow>
            </div>
            <div>
                <h5 className={classes.headerSpacing}>Location & Orientation of System(s)</h5>
                {Object.keys(pvSystems).map((id) => (
                    <InputRow key={id} gridTemplate="1fr 1fr 1fr 1fr 0.3fr" className={classes.inputRow}>
                        <Field
                            component={InputWithUnits}
                            name={`${accessor}.systemOrientation.systems.${id}.tiltAngle`}
                            type="number"
                            label="Tilt angle"
                            units={{
                                base: ["°"],
                                options: ["°"],
                                selected: ["°"],
                                unitType: "angle",
                                accessor: `${accessor}.systemOrientation.tiltAngle_u`,
                            }}
                        />
                        <Field
                            component={InputWithUnits}
                            name={`${accessor}.systemOrientation.systems.${id}.azimuth`}
                            type="number"
                            label="Azimuth"
                            units={{
                                base: ["°"],
                                options: ["°"],
                                selected: ["°"],
                                unitType: "angle",
                                accessor: `${accessor}.systemOrientation.azimuth_u`,
                            }}
                        />
                        <Field
                            component={Select}
                            name={`${accessor}.systemOrientation.systems.${id}.location`}
                            type="text"
                            label="Location"
                            options={[
                                {
                                    value: "roof",
                                    label: "Roof Mount",
                                },
                                {
                                    value: "ground",
                                    label: "Ground Mount",
                                },
                                {
                                    value: "wall",
                                    label: "Wall Mount",
                                },
                            ]}
                            placeholder="Choose Location"
                        />
                        <Field
                            component={Input}
                            name={`${accessor}.systemOrientation.systems.${id}.pvModules`}
                            type="number"
                            label="Number of PV modules"
                        />
                        <div className={classes.delete} onClick={() => deleteSystem(id)} type="red">
                            <span data-tip="Delete" data-for={`delete-${id}`}>
                                <Delete />
                            </span>
                        </div>
                    </InputRow>
                ))}
                <Button
                    className={classes.addPVButton}
                    onClick={addSystem}
                    disabled={Object.values(pvSystems).length >= 5}
                >
                    Add PV System
                </Button>
            </div>
            <div>
                <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                    <Field
                        component={InputWithUnits}
                        name={`${accessor}.systemOrientation.peakWatts`}
                        type="number"
                        label="System Peak DC Watts"
                        units={{
                            base: ["W"],
                            options: ["W"],
                            selected: ["W"],
                            unitType: "power",
                            accessor: `${accessor}.arrayTests.peakWatts_u`,
                        }}
                        setValue={totalModules * moduleRating}
                        info="The product of the nameplate PV module rating and the total number of modules"
                    />
                    <Field
                        component={InputWithUnits}
                        name={`${accessor}.systemOrientation.operationalVoltage`}
                        type="number"
                        label="System Operational DC Voltage"
                        units={{
                            base: ["V"],
                            options: ["V"],
                            selected: ["V"],
                            unitType: "voltage",
                            accessor: `${accessor}.arrayTests.operationalVoltage_u`,
                        }}
                        info="The input DC voltage rating of the inverter"
                    />
                    <Field
                        component={InputWithUnits}
                        name={`${accessor}.systemOrientation.openCircuitDCVoltage`}
                        type="number"
                        label="System Open Circuit DC Voltage"
                        units={{
                            base: ["V"],
                            options: ["V"],
                            selected: ["V"],
                            unitType: "voltage",
                            accessor: `${accessor}.arrayTests.openCircuitDCVoltage_u`,
                        }}
                        info="The PV module open circuit voltage rating"
                    />
                    <Field
                        component={InputWithUnits}
                        name={`${accessor}.systemOrientation.shortCircuitDCCurrent`}
                        type="number"
                        label="System Short Circuit DC Current"
                        units={{
                            base: ["A"],
                            options: ["A"],
                            selected: ["A"],
                            unitType: "current",
                            accessor: `${accessor}.arrayTests.shortCircuitDCCurrent_u`,
                        }}
                        info="The nameplate PV module short circuit current"
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr 1fr">
                    <Field
                        component={Input}
                        name={`${accessor}.systemOrientation.otherComponentsModel`}
                        type="text"
                        label="Make/model of other major system components"
                    />
                    <Field
                        component={Input}
                        name={`${accessor}.systemOrientation.monitoringDeviceModel`}
                        type="text"
                        label="Energy monitoring device make/model"
                        info="Real time energy consumption/generation information must be available to occupants"
                    />
                    <Field
                        component={Input}
                        name={`${accessor}.systemOrientation.disconnectLocations`}
                        type="text"
                        label="Shut off/disconnect switch locations"
                        info="Must be clearly marked and visible"
                    />
                </InputRow>
            </div>
            <div>
                <h3>Array Tests</h3>
                <InputRow gridTemplate="2fr 3fr">
                    <Field
                        component={Input}
                        name={`${accessor}.arrayTests.testCondtions`}
                        type="text"
                        label="Solar conditions at time of the array tests"
                        info="Such as irradiance and temperature"
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr 1fr 1fr 1fr">
                    <Field
                        component={InputWithUnits}
                        name={`${accessor}.arrayTests.systemDCVoltage`}
                        type="number"
                        label="System DC Voltage"
                        units={{
                            base: ["V"],
                            options: ["V"],
                            selected: ["V"],
                            unitType: "voltage",
                            accessor: `${accessor}.arrayTests.systemDCVoltage_u`,
                        }}
                    />
                    <Field
                        component={InputWithUnits}
                        name={`${accessor}.arrayTests.systemDCCurrent`}
                        type="number"
                        label="System DC Current"
                        units={{
                            base: ["A"],
                            options: ["A"],
                            selected: ["A"],
                            unitType: "current",
                            accessor: `${accessor}.arrayTests.systemDCCurrent_u`,
                        }}
                    />
                    <Field
                        component={InputWithUnits}
                        name={`${accessor}.arrayTests.gridVoltage`}
                        type="number"
                        label="Grid Voltage"
                        units={{
                            base: ["V"],
                            options: ["V"],
                            selected: ["V"],
                            unitType: "voltage",
                            accessor: `${accessor}.arrayTests.gridVoltage_u`,
                        }}
                    />
                    <Field
                        component={InputWithUnits}
                        name={`${accessor}.arrayTests.systemVAC`}
                        type="number"
                        label="System VAC"
                        units={{
                            base: ["V"],
                            options: ["V"],
                            selected: ["V"],
                            unitType: "voltage",
                            accessor: `${accessor}.arrayTests.systemVAC`,
                        }}
                        info="The voltage between the inverter and the meter"
                    />
                    <Field
                        component={InputWithUnits}
                        name={`${accessor}.arrayTests.systemPAC`}
                        type="number"
                        label="System PAC"
                        units={{
                            base: ["W"],
                            options: ["W"],
                            selected: ["W"],
                            unitType: "power",
                            accessor: `${accessor}.arrayTests.systemPAC_u`,
                        }}
                        info="The power generated by the system at one point in time"
                    />
                </InputRow>
                <InputRow className={classes.pvStrings} gridTemplate="1fr 1fr 1fr 1fr ">
                    {/* make this same as pv systems list */}
                    {Object.keys(pvStrings).map((id) => (
                        <div key={id} className={classes.pvStringInput}>
                            <Field
                                component={InputWithUnits}
                                name={`${accessor}.arrayTests.pvStringOpenCircuitVoltage.strings.${id}.voltage`}
                                type="number"
                                label="PV String Open Circuit Voltage"
                                units={{
                                    base: ["V"],
                                    options: ["V"],
                                    selected: ["V"],
                                    unitType: "voltage",
                                    accessor: `${accessor}.arrayTests.pvStringOpenCircuitVoltage_u`,
                                }}
                                className={classes.stringField}
                            />
                            <div className={classes.deleteString} onClick={() => deleteString(id)} type="red">
                                <span data-tip="Delete" data-for={`delete-${id}`}>
                                    <Delete />
                                </span>
                            </div>
                        </div>
                    ))}
                </InputRow>

                <Button
                    className={classes.addPVButton}
                    onClick={addString}
                    disabled={Object.values(pvStrings).length >= 5}
                >
                    Add String
                </Button>
            </div>
            <div>
                <div className={classes.confirmationHeader}>
                    <h3>Declaration, Name & Signature</h3>
                    <p>
                        I hearby confirm that the PV system on this house has been installed and commissioned according
                        to the details included in this report.
                    </p>
                </div>
                <InputRow gridTemplate="65.92% 31.85%">
                    <Field
                        component={Input}
                        name={`${accessor}.declaration.address`}
                        type="text"
                        label="Net Zero Home Address, City, Province, Postal Code"
                    />
                    <Field
                        component={Input}
                        name={`${accessor}.declaration.signature`}
                        type="text"
                        label="Signature (print only)"
                        disabled
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr 1fr">
                    <Field component={Input} name={`${accessor}.declaration.name`} type="text" label="Name" />
                    <Field
                        component={Input}
                        name={`${accessor}.declaration.companyName`}
                        type="text"
                        label="Company Name"
                    />
                    <Field
                        component={DayPicker}
                        formatting="YYYY-MM-DD"
                        name={`${accessor}.declaration.date`}
                        label="Date of Commissioning"
                        validate={chbaNzTargetCompletionDateValidation}
                        disabledDays={{ before: null, after: null }}
                    />
                </InputRow>
            </div>
            {/* <div className={classes.buttonRow}>
                <Button>Export Net Zero Workbook</Button>
            </div> */}
        </>
    );
};

export default PVSystemCommissioning;
