import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import ClientInfo from './';

const mapStateToProps = (
    {
        form
    },
    {
        accessor
    }
) => {
    const selector = formValueSelector('model');
    const {id:selectedProvTerrId} = selector({form}, `modelData.location.weather.region`) || {};
    const isSameMailingAddress = selector({form}, `${accessor}.sameMailingInfo`) || false;
    const isDiffMailingAddress = selector({form}, `${accessor}.differentMailingInfo`) || false;
    const { major: h2kMajor, minor: h2kMinor} = selector({form}, 'modelData.h2kVersion') || {};

    return {
        accessor,
        selectedProvTerrId,
        isSameMailingAddress,
        isDiffMailingAddress,
        h2kVersion: `v${h2kMajor}-${h2kMinor}`
    }
};

const mapDispatchToProps = () => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClientInfo);