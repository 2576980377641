import { connect } from "react-redux";
import { reduxForm, formValueSelector, reset } from "redux-form";
import SelectorDrawer from "./";
import lintelCodeTemplate from "features/Model/Enclosure/Templates/lintelCode.json";
import { actions as userActions } from "store/users";
import { actions as enclosureActions } from "features/Model/Enclosure/_ducks";
import { actions as upgradeActions } from "features/Model/Upgrades/_ducks";
import isEmpty from "lodash/isEmpty";
import { lintelCodeLogic } from "utils/codes";

const { addToCodeLib, deleteCode } = userActions;
const { setInitCode } = enclosureActions;
const { updateUpgradeCodes } = upgradeActions;

const mapStateToProps = (
    {
        form,
        model: { modelId },
        user: { uid },
        enclosure: { initCodeValues: { lintelInsType = {} } = {} } = {},
        upgradeLibrary: { currentPackage, selectedUpgrade },
    },
    { fieldAccessor, modelFormChange, currentFormChange, toggleOpen, isCodeLibrary = false, componentId }
) => {
    const selector = formValueSelector("lintelCode");
    const lintelLabel = selector({ form }, "lintelCode.label") || "";
    const { value: lintelTypeValue } = selector({ form }, "lintelCode.layers.lintelType") || {};
    const { value: lintelMaterialValue } = selector({ form }, "lintelCode.layers.lintelMaterial") || {};
    const { value: lintelInsValue } = selector({ form }, "lintelCode.layers.lintelIns") || {};

    const { codeRef: editingCodeRef = null, isLibCode = false } = lintelInsType;

    const lintelValue = `${lintelTypeValue}${lintelMaterialValue}${lintelInsValue}`;

    const modelSelector = formValueSelector("model");
    const modelCodes = modelSelector({ form }, "modelData.codes") || {};
    const components = modelSelector({ form }, "modelData.components") || {};

    return {
        lintelLabel,
        lintelValue,
        fieldAccessor,
        modelFormChange,
        currentFormChange,
        initialValues: {
            lintelCode: isEmpty(lintelInsType) ? lintelCodeTemplate : lintelInsType,
            addToLibrary: isLibCode,
        },
        modelId,
        uid,
        toggleOpen,
        editingCodeRef,
        isEditing: !!editingCodeRef,
        isLibCode,
        isCodeLibrary,
        componentId,
        modelCodes,
        components,
        currentPackage,
        selectedUpgrade,
    };
};

const mapDispatchToProps = (dispatch) => ({
    clearForm: () => dispatch(reset("lintelCode")),
    clearInitCode: () => dispatch(setInitCode({}, "lintelInsType")),
});

const mergeProps = (state, dispatch, own) => ({
    ...state,
    ...dispatch,
    ...own,
});

const onSubmit = async (
    form,
    dispatch,
    {
        toggleOpen,
        uid,
        fieldAccessor,
        modelFormChange,
        currentFormChange,
        editingCodeRef,
        isCodeLibrary,
        isEditing,
        modelCodes,
        components,
        currentPackage,
        selectedUpgrade,
    }
) => {
    const { lintelCode = {}, addToLibrary = false } = form;

    toggleOpen(false);

    lintelCodeLogic({
        componentCodeType: "Lintel",
        code: lintelCode,
        codeType: "S",
        isCodeLibrary,
        isEditing,
        editingCodeRef,
        dispatch,
        modelFormChange,
        currentFormChange,
        fieldAccessor,
        components,
        modelCodes,
        addToLibrary,
        addToCodeLib,
        uid,
        deleteCode,
        updateUpgradeCodes,
        currentPackage,
        selectedUpgrade,
    });

    // Clear lintel code defaults for next time
    dispatch(setInitCode({}, "lintelInsType"));
    // Reset form
    dispatch(reset("lintelCode"));
};

const form = reduxForm({
    form: "lintelCode",
    enableReinitialize: true,
    onSubmit: onSubmit,
})(SelectorDrawer);

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(form);
