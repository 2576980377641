import React from "react";
import classes from "./style.module.scss";
import Tooltip from "react-tooltip";

export default ({ id = null, delay, hide, className, ...props }) => (
    <Tooltip
        id={id}
        className={`${classes.tooltip} ${hide ? classes.hide : ""} ${className ? className : ""}`}
        backgroundColor="#001344"
        arrowColor="#001344"
        delayShow={delay ? 500 : 0}
        effect="solid"
        multiline={true}
        {...props}
    />
);
