import React from 'react';
import sharedClasses from '../../style.module.scss';

export default React.memo(({
    supply,
    exhaust
}) => (
    <div className={sharedClasses.stats} style={{gridTemplateColumns:'1fr 1fr 1fr'}}>
        <span><strong>S:</strong> {supply}</span>
        <span><strong>E:</strong> {exhaust}</span>
    </div>
));