export const airTestZoneInfo = {
    0: { test1Equip1: { zone1Pa: false, zone2Pa: false } }, //1 blower - Whole house
    1: { test1Equip1: { zone1Pa: false, zone2Pa: false }, test1Equip2: { zone1Pa: false, zone2Pa: false } }, //2 blower - Whole house
    2: { test1Equip1: { zone1Pa: true, zone2Pa: false }, test2Equip1: { zone1Pa: true, zone2Pa: false } }, //1 blower - Duplex
    3: {
        test1Equip1: { zone1Pa: true, zone2Pa: true },
        test2Equip1: { zone1Pa: true, zone2Pa: true },
        test3Equip1: { zone1Pa: true, zone2Pa: true },
    }, //1 blower - Triplex
    4: {
        test1Equip1: { zone1Pa: true, zone2Pa: false },
        test1Equip2: { zone1Pa: true, zone2Pa: false },
        test2Equip1: { zone1Pa: true, zone2Pa: false },
        test2Equip2: { zone1Pa: false, zone2Pa: false },
    }, //2 blowers - Triplex
};
