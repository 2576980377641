import React, { useState } from "react";
import { importH2kFile } from "utils/h2kinout/api";
import classes from "./style.module.scss";

export default ({ name, id, label, text, handleUpload, accept = "", parentFolderId = "" }) => {
    const [fileName, changeFileName] = useState("");
    const [importState, updateState] = useState("");

    const handleInputChange = (e) => {
        updateState("importing");
        const formData = new FormData();
        const fileName = e.target.files[0].name || "";

        formData.append("modelFile", e.target.files[0]);
        formData.append(
            "name",
            JSON.stringify({
                name: e.target.files[0].name.split(".")[0],
                notes: "Insert notes for imported model here",
                templateID: "H2kImport",
                templateName: "H2kImport",
                parentFolderId,
            })
        );

        importH2kFile(formData)
            .then((data) => {
                handleUpload(data.data);
                updateState("success");
                changeFileName(fileName);
            })
            .catch((err) => {
                // console.log('error', err)
                handleUpload({});
                updateState("failed");
            });
    };

    const getLabel = () => {
        if (importState === "success") {
            return "Change File";
        }
        if (importState === "importing") {
            return "Processing...";
        }
        return label;
    };

    return (
        <div className={classes.inputWrapper}>
            <div className={classes.text}>
                <p>{text}</p>
                <input type="file" name={name} id={id} onChange={handleInputChange} accept={accept} />
                <label htmlFor={id}>{getLabel()}</label>
            </div>
            {fileName && <p className={classes.fileName}>{fileName}</p>}
        </div>
    );
};
