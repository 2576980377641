import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { SORT_BY_OPTIONS } from "features/Model/ImageGallery/utils/constants";

import { selectModelName } from "features/Model/_ducks/selectors";

import Select from "components/Input/Select";

import styles from "./styles.module.scss";

const ImagesHeader = ({ modelName, sortBy, setSortBy }) => {
    return (
        <div className={styles.imagesHeaderContainer}>
            <h2 className={styles.imagesHeaderText}>{modelName}</h2>
            <div className={styles.sortByContainer}>
                <span className={styles.sortBySpan}>Sort by:</span>
                <Select
                    className={styles.sortBy}
                    input={{
                        value: sortBy,
                        onChange: (val) => setSortBy(val),
                    }}
                    options={SORT_BY_OPTIONS}
                />
            </div>
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    modelName: selectModelName,
});

export default connect(mapStateToProps)(ImagesHeader);
