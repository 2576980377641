import React from 'react';
import classes from './style.module.scss';

export default ({
    title="",
    style={}
}) => (
    <div className={classes.emptyState} style={style}>
        <h3>{title}</h3>
    </div>
)