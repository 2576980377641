import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import MurbSpecs from './';

const mapStateToProps = (
    {
        form
    },
    {
        accessor
    }
) => {
    const selector = formValueSelector('model');
    const modelUnits = selector({form}, `modelData.uiSettings.primaryUnits`) || {};

    const { id:buildingTypeId } = selector({form}, 'modelData.specifications.buildingType') || 0;
    const isSingleMURB = buildingTypeId === 1;
    const isWholeBuildingMURB = buildingTypeId === 2;

    return {
        accessor,
        isSingleMURB,
        isWholeBuildingMURB,
        modelUnits
    }
};

const mapDispatchToProps = () => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MurbSpecs);