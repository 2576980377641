import React from "react";

export default ({ fill }) => (
    <svg
        width="18px"
        height="18px"
        viewBox="0 0 18 18"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g id="Artboard" fill={fill || "#000000"} fillRule="nonzero">
            <path
                d="M0.28333233,2.25676211 L4.5027045,3.83748403 L4.82662793,5.0515433 L11.1138508,8.68176211 L11.5592078,7.91155811 L17.9742108,11.6152618 C19.0370833,12.2289115 19.4012503,13.5880013 18.7876006,14.6508738 C18.3970962,15.3272472 17.7047194,15.7206738 16.9789063,15.7592263 L16.822961,15.7620295 C16.4583509,15.7557741 16.0901753,15.6595158 15.7519886,15.4642636 L9.33698556,11.7605599 L9.78085085,10.9907621 L3.49312793,7.36123305 L2.28048227,7.68648583 L-1.19814915,4.82276331 L0.28333233,2.25676211 Z M11.4195457,10.5511085 L10.975,11.321 L16.3519886,14.4250331 C16.3999282,14.4527111 16.449178,14.4760647 16.499286,14.4952149 L16.5696105,14.5191886 C16.681217,14.5527356 16.7959529,14.5662716 16.9090304,14.5610794 L17.0177508,14.5502534 L17.1315558,14.5261043 L17.2385004,14.4906012 L17.3411476,14.443468 L17.3880167,14.4171329 L17.4384335,14.3849896 L17.5292942,14.3154513 C17.5874898,14.265469 17.6406927,14.2083034 17.6874842,14.1443347 L17.749,14.048 L17.8020206,13.944194 C17.8332602,13.8719165 17.8555075,13.7975567 17.8691367,13.7225115 L17.8833183,13.607285 L17.8844164,13.493832 C17.8809549,13.4184469 17.8691698,13.3437989 17.8494412,13.2713066 L17.8397492,13.2379293 L17.8084107,13.1506258 L17.766805,13.0617148 L17.7083266,12.9644289 C17.6442707,12.8701782 17.5636248,12.7855654 17.4676716,12.7153781 L17.3742108,12.6544923 L11.997,9.55 L11.5523735,10.3206317 L3.79552718,5.84190888 L3.503,4.744 L0.814,3.737 L0.349844416,4.54333638 L2.56556233,6.36760037 L3.66245314,6.07340228 L11.4195457,10.5511085 Z"
                id="Rectangle-Copy-28"
                transform="translate(8.943772, 9.009396) rotate(-345.000000) translate(-8.943772, -9.009396) "
            ></path>
        </g>
    </svg>
);
