import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import FloorConstruction from "./";
import { actions } from "features/Model/Enclosure/_ducks";

const { setInitCode, updateCodeDrawer } = actions;

const mapStateToProps = (
    {
        form,
        user: { codeLib: { FloorsAdded: { codes: slabCodes = {} } = {} } = {} },
        enclosure: { calculatingRValue = {} } = {},
        upgradeLibrary: { currentPackage = {} },
    },
    { accessor, formName = "model", componentAccessor = accessor, id, selectedUpgrade }
) => {
    const selector = formValueSelector(formName);
    const modelSelector = formValueSelector("model");

    const modelCodes =
        currentPackage?.upgrades?.[selectedUpgrade]?.codes || modelSelector({ form }, "modelData.codes") || {};
    const modelUnits = modelSelector({ form }, `modelData.uiSettings.primaryUnits`) || {};
    const { type: slabInsConfig } = modelSelector({ form }, `${componentAccessor}.configuration`) || {};

    const {
        codeRef: selectedSlabCodeRef,
        id: slabPreBuiltId,
        value: slabPreBuiltValue,
    } = selector({ form }, `${accessor}.floor.slabInsType`) || {};
    const selectedSlabRValue = selector({ form }, `${accessor}.floor.slabInsType_nomRVal`) || 0;
    const slabCodeWarning = selector({ form }, `${accessor}.floor.slabInsType_warning`) || "";

    const rValUnitsSlab = selector({ form }, `${accessor}.floor.slabInsType_u`);
    const { [id]: { slabInsType: isCalculatingRValue = false } = {} } = calculatingRValue;

    return {
        id,
        accessor,
        libSlabCodes: slabCodes,
        modelCodes,
        selectedSlabCodeRef,
        modelUnits,
        rValUnitsSlab,
        selectedSlabRValue,
        slabPreBuiltId,
        slabPreBuiltValue,
        slabInsConfig,
        slabCodeWarning,
        isCalculatingRValue,
        isUpgradeLibrary: !!currentPackage,
    };
};

const mergeProps = (state, dispatch, own) => ({
    ...state,
    ...dispatch,
    ...own,
});

const mapDispatchToProps = (dispatch) => ({
    setInitCode: async (code, type) => {
        return dispatch(setInitCode(code, type));
    },
    updateCodeDrawer: (updates) => dispatch(updateCodeDrawer({ updates })),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(FloorConstruction);
