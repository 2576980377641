import React, { useEffect, useRef, useState } from "react";
import { isEqual } from "lodash";
import { Field } from "redux-form";

import { getValidation, required, maxLength } from "utils/fieldValidation";

import InputRow from "components/Input/Row";
import Accordion from "components/Accordion";
import Measurements from "./Measurements/container";
import Construction from "./Construction/container";
import Actions from "../Actions/container";
import ExpFloorStats from "./ExpFloorStats/container"; //* *//
import Checkbox from "components/Input/Checkbox";
import ComponentHeading from "../ComponentHeading";

import ExpFloorIcon from "assets/images/components/ExpFloor.svg";

import classes from "./style.module.scss";
import sharedClasses from "../style.module.scss";

const charLim32 = maxLength(32);

const expFloorLabelValidation = [required, charLim32, ...getValidation("expFloorLabel")];

export default React.memo(
    ({
        accessor,
        label = "",
        change,
        componentId,
        isNew = false,
        isRecentDuplicate = false,
        catId,
        parentPath = "",
        isDragging,
        dragHandleProps,
        dragActive,
        clearNewComponent,
        drawingLines,
        isDrawingComponent,
        contentOpen,
        dropPosition,
    }) => {
        const ref = useRef(null);
        const [nameDialogOpen, toggleNameDialog] = useState(false);
        useEffect(() => {
            if (isNew && !isRecentDuplicate) {
                ref.current.scrollIntoView();
            }
        }, [isNew, isRecentDuplicate]);

        return (
            <div
                className={`${classes.expFloor} ${sharedClasses.component} ${
                    dropPosition === "after"
                        ? sharedClasses.activeAfter
                        : dropPosition === "before"
                        ? sharedClasses.activeBefore
                        : ""
                }  `}
            >
                <span ref={ref} className={sharedClasses.componentRef}></span>
                <Accordion
                    className={sharedClasses.accordion}
                    heading={
                        <ComponentHeading
                            label={label}
                            componentId={componentId}
                            isDrawingComponent={isDrawingComponent}
                            icon={ExpFloorIcon}
                            toggleNameDialog={toggleNameDialog}
                        />
                    }
                    large
                    initOpenState={isNew && !isRecentDuplicate}
                    isNew={isNew}
                    isDragging={isDragging}
                    dragActive={dragActive}
                    toggleNew={() => clearNewComponent(componentId)}
                    hoverActions={
                        <Actions
                            nameField={{
                                name: `${accessor}.label`,
                                validate: expFloorLabelValidation,
                                current: label,
                                change,
                            }}
                            label="Exposed Floor"
                            type="expFloor"
                            catId={catId}
                            parentPath={parentPath}
                            componentId={componentId}
                            dragHandleProps={dragHandleProps}
                            drawingLines={drawingLines}
                            change={change}
                            nameDialogOpen={nameDialogOpen}
                            toggleNameDialog={toggleNameDialog}
                        />
                    }
                    stats={() => <ExpFloorStats accessor={accessor} />}
                    forceOpen={contentOpen}
                >
                    <h4>Measurements</h4>
                    <Measurements accessor={accessor} change={change} isDrawingComponent={isDrawingComponent} />
                    <h4>Construction</h4>
                    <Construction accessor={accessor} change={change} id={componentId} />
                    <InputRow lastRow>
                        <Field
                            component={Checkbox}
                            name={`${accessor}.adjacentEnclosedSpace`}
                            label="Adjacent to enclosed unconditioned space"
                            large
                            type="checkbox"
                        />
                    </InputRow>
                </Accordion>
            </div>
        );
    },
    isEqual
);
