import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import SummaryTable from './';
import buildReviewTable from 'utils/review/buildReviewTable';
import { getMechanicalReviewData } from 'utils/mechanicals';
import obcClimateZone1 from './standards/obcClimateZone1.json';
import obcClimateZone2 from './standards/obcClimateZone2.json';

const mapStateToProps = (
    {
        model:{
            modelId,
        },
        form
    }
) => {
    const selector = formValueSelector('model');
    const components = selector({form}, 'modelData.components') || {};
    const heatingCoolingSystems = selector({form}, 'modelData.heatingCooling') || {};
    const ventilationSystems = selector({form}, 'modelData.ventilation') || {};
    const dhwSystem = selector({form}, 'modelData.domesticHotWater.primarySystem') || {};
    const { value: hddValue } = selector({form}, 'modelData.location.weather.location') || 0;
    const airtightness50Pa = selector({form}, 'modelData.airTightness.blowerTest.airChangeRate50Pa') || 0;

    const { id: houseTypeId } = selector({form}, `modelData.specifications.buildingSubType`) || {};

    const selectedSb12Package = selector({form}, 'modelData.compliance.sb12.selectedPackage');
    // link util function here to construc review table
    const modelDataTable = buildReviewTable(components);

    const { complianceHeatingType, mechTableData } = getMechanicalReviewData({heatingCoolingSystems, ventilationSystems, dhwSystem});

    return {
        houseType: houseTypeId === 0 ? 'detached' : 'attached',
        modelDataTable,
        obcClimateZoneData: hddValue < 5000 ? obcClimateZone1 : obcClimateZone2,
        airtightness50Pa,
        complianceHeatingType,
        mechTableData,
        selectedSb12Package,
    }
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SummaryTable);
