import { connect } from "react-redux";
import { reduxForm, formValueSelector, reset } from "redux-form";
import StandardCode from "./";
import floorHeaderCodeTemplate from "features/Model/Enclosure/Templates/floorHeaderCode.json";
import { actions as userActions } from "store/users";
import { actions as enclosureActions } from "features/Model/Enclosure/_ducks";
import { actions as upgradeActions } from "features/Model/Upgrades/_ducks";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import { getMatchFloorHeaderCodeRefs } from "utils/enclosure/components";
import { standardCodeLogic } from "utils/codes";

const { addToCodeLib, deleteCode } = userActions;
const { setInitCode, getRValue } = enclosureActions;
const { updateUpgradeCodes } = upgradeActions;

const mapStateToProps = (
    {
        form,
        model: { modelId },
        user: { uid },
        enclosure: { initCodeValues: { floorHeaderInsType = {} } = {} } = {},
        upgradeLibrary: { currentPackage, selectedUpgrade },
    },
    { fieldAccessor, modelFormChange, currentFormChange, toggleOpen, isCodeLibrary = false, componentId }
) => {
    const selector = formValueSelector("floorHeaderCode");
    const { id: floorHeaderStructureId, value: floorHeaderStructureValue = 0 } =
        selector({ form }, "floorHeaderCode.layers.structureType") || {};
    const { value: floorHeaderIns1Value } = selector({ form }, "floorHeaderCode.layers.insulationLayer1") || {};
    const { value: floorHeaderIns2Value } = selector({ form }, "floorHeaderCode.layers.insulationLayer2") || {};
    const { value: floorHeaderSheathingValue } = selector({ form }, "floorHeaderCode.layers.sheathing") || {};
    const { value: floorHeaderExteriorValue } = selector({ form }, "floorHeaderCode.layers.exterior") || {};
    const floorHeaderLabel = selector({ form }, "floorHeaderCode.label") || "";

    const floorHeaderValue = `1${floorHeaderStructureValue
        .toString()
        .substr(
            -1
        )}${0}${0}${floorHeaderIns1Value}${floorHeaderIns2Value}${0}${floorHeaderSheathingValue}${floorHeaderExteriorValue}${0}`;

    const { codeRef: editingCodeRef = null, isLibCode = false } = floorHeaderInsType;

    const modelSelector = formValueSelector("model");
    const modelCodes = modelSelector({ form }, "modelData.codes") || {};
    const components = modelSelector({ form }, "modelData.components") || {};

    return {
        floorHeaderLabel,
        fieldAccessor,
        modelFormChange,
        currentFormChange,
        initialValues: {
            floorHeaderCode: isEmpty(floorHeaderInsType) ? floorHeaderCodeTemplate : floorHeaderInsType,
            addToLibrary: isLibCode,
        },
        floorHeaderStructureId,
        floorHeaderValue,
        modelId,
        uid,
        editingCodeRef,
        isEditing: !!editingCodeRef,
        isLibCode,
        toggleOpen,
        isCodeLibrary,
        isUpgradeComponent: fieldAccessor.includes("fields."),
        componentId,
        modelCodes,
        components,
        currentPackage,
        selectedUpgrade,
    };
};

const mapDispatchToProps = (dispatch) => ({
    clearForm: () => dispatch(reset("floorHeaderCode")),
    clearInitCode: () => dispatch(setInitCode({}, "floorHeaderInsType")),
});

const mergeProps = (state, dispatch, own) => ({
    ...state,
    ...dispatch,
    ...own,
});

const onSubmit = async (
    form,
    dispatch,
    {
        toggleOpen,
        uid,
        fieldAccessor,
        modelFormChange,
        currentFormChange,
        editingCodeRef,
        isCodeLibrary,
        componentId,
        isEditing,
        modelCodes,
        components,
        currentPackage,
        selectedUpgrade,
    }
) => {
    const { floorHeaderCode = {}, addToLibrary = false, setAsModelDefault = false, selectedComponents = [] } = form;

    toggleOpen(false);

    // Run standard code logic
    standardCodeLogic({
        componentCodeType: "FloorHeader",
        componentId,
        code: floorHeaderCode,
        formInputName: "floorHeaderInsType",
        codeType: "S",
        isCodeLibrary,
        isEditing,
        editingCodeRef,
        dispatch,
        getRValue,
        modelFormChange,
        currentFormChange,
        fieldAccessor,
        components,
        modelCodes,
        addToLibrary,
        addToCodeLib,
        uid,
        deleteCode,
        updateUpgradeCodes,
        setAsModelDefault,
        selectedComponents,
        currentPackage,
        selectedUpgrade,
    });

    // Clear floor header code defaults for next time
    dispatch(setInitCode({}, "floorHeaderInsType"));
    // Reset form
    dispatch(reset("floorHeaderCode"));
};

const form = reduxForm({
    form: "floorHeaderCode",
    enableReinitialize: true,
    onSubmit: onSubmit,
})(StandardCode);

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(form);
