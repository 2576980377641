import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import PVSystem from "./";

const mapStateToProps = ({ form, model: { contentOpen = false } = {} }, { accessor, formName = "model" }) => {
    const selector = formValueSelector(formName);
    const modelSelector = formValueSelector("model");

    const systemLabel = selector({ form }, `${accessor}.systemLabel`) || "";
    const manufacturer = selector({ form }, `${accessor}.manufacturer`) || "";
    const model = selector({ form }, `${accessor}.model`) || "";
    const sysNum = selector({ form }, `${accessor}.systemNumber`) || 1;

    const moduleCountValue = selector({ form }, `${accessor}.array.count`);
    const moduleWidthValue = selector({ form }, `${accessor}.array.width`);
    const moduleHeightValue = selector({ form }, `${accessor}.array.height`);
    const arrayAreaValue = selector({ form }, `${accessor}.array.area`);
    const moduleCapacityValue = selector({ form }, `${accessor}.array.moduleCapacity`);
    const arrayCapacityValue = selector({ form }, `${accessor}.array.capacity`);

    const compassDirValue = selector({ form }, `${accessor}.array.compassDir`);
    const latitudeValue = selector({ form }, `${accessor}.array.latitude`);
    const longitudeValue = selector({ form }, `${accessor}.array.longitude`);
    const azimuthValue = selector({ form }, `${accessor}.array.azimuth`);
    const declinationValue = selector({ form }, `${accessor}.array.declination`);
    const postalCodeValue = selector({ form }, "modelData.general.client.clientPostalCode");
    const clientAddressValue = selector({ form }, "modelData.general.client.clientStreetAddress");
    const clientCityValue = selector({ form }, "modelData.general.client.clientCity");

    const arrayAreaUnits = selector({ form }, `${accessor}.array.area_u`);
    const moduleWidthUnits = selector({ form }, `${accessor}.array.width_u`);
    const moduleHeightUnits = selector({ form }, `${accessor}.array.height_u`);
    const moduleCapacityUnits = selector({ form }, `${accessor}.array.moduleCapacity_u`);
    const arrayCapacityUnits = selector({ form }, `${accessor}.array.capacity_u`);

    const { id: moduleTypeId } = selector({ form }, `${accessor}.module.type`) || {};
    const moduleEffValue = selector({ form }, `${accessor}.module.efficiency`);
    const moduleEffUnits = selector({ form }, `${accessor}.module.efficiency_u`);
    const moduleNoctValue = selector({ form }, `${accessor}.module.cellTemperature`);
    const moduleNoctUnits = selector({ form }, `${accessor}.module.cellTemperature_u`);
    const moduleTempCoeffValue = selector({ form }, `${accessor}.module.coefficientOfEfficiency`);
    const moduleTempCoeffUnits = selector({ form }, `${accessor}.module.coefficientOfEfficiency_u`);

    const modelUnits = modelSelector({ form }, `modelData.uiSettings.primaryUnits`) || {};

    return {
        accessor,
        systemLabel,
        manufacturer,
        model,
        sysNum,
        moduleCountValue,
        moduleWidthValue,
        moduleHeightValue,
        arrayAreaValue,
        moduleCapacityValue,
        arrayCapacityValue,
        compassDirValue,
        latitudeValue,
        longitudeValue,
        azimuthValue,
        declinationValue,
        postalCodeValue,
        clientAddressValue,
        clientCityValue,
        arrayAreaUnits,
        moduleWidthUnits,
        moduleHeightUnits,
        moduleCapacityUnits,
        arrayCapacityUnits,
        moduleTypeId,
        moduleEffValue,
        moduleEffUnits,
        moduleNoctValue,
        moduleNoctUnits,
        moduleTempCoeffValue,
        moduleTempCoeffUnits,
        modelUnits,
        contentOpen,
    };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PVSystem);
