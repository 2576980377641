import { createSelector } from "reselect";
import { initialState } from "./reducers";

export const selectCommunity = ({ community = initialState }) => {
    return community
};

export const communitySelector = (path) => createSelector(
    [selectCommunity], 
    (community) => !path ? "" : resolveObj(path, community)
);

export const selectCommunityId = createSelector(
    [selectCommunity],
    ({ communityId }) => communityId
);

export const selectCommunityData = createSelector(
    [selectCommunity],
    ({ communityData }) => communityData
);

export const selectCommunityModelDir = createSelector(
    [selectCommunity],
    ({ modelDir }) => modelDir
);

export const selectCommunityLoading = createSelector(
    [selectCommunity],
    ({ loading }) => loading
);

export const selectCommunityError = createSelector(
    [selectCommunity],
    ({ error }) => error
);

export const resolveObj = (path, obj) => {
    return path.split(".").reduce((cache, curr) => {
        return cache ? cache[curr] : null;
    }, obj);
};
