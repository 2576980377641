import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import SlabFields from './';

const mapStateToProps = (
    {
        form,
        drawing:{
            imageData={},
        },
    },
    {
        componentId
    }
) => {
    const modelSelector = formValueSelector('model');
    const selector = formValueSelector('drawingPolygon');
    const expPerimeterUnits = selector({form}, `components.${componentId}.exposedSurfacePerimeter_u`);

    return {
        expPerimeterUnits,
    }
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SlabFields);