import { DashboardAPI } from "utils/api";

export const CHBA_RENO_COMM_ID = 'CHBA_TNZ_RENO_PILOT_ADMIN';

//Updates the entier community
export const populateChbaCommunity = async (data) => {
    const { modelId, uid } = data;
    return DashboardAPI({
        method: "POST",
        url: "/populateChbaCommunity",
        data: {
            modelId,
            uid,
        },
    }).catch((err) => {
        console.log(err);
    });
};

//Updates the community with info for the current model being saved
export const updateChbaCommunityWithModel = async (data) => {
    const { modelId, uid } = data;
    return DashboardAPI({
        method: "POST",
        url: "/updateChbaCommunityWithModel",
        data: {
            modelId,
            uid,
        },
    }).catch((err) => {
        console.log(err);
        return err;
    });
};
