import React from "react";
import classes from "./style.module.scss";
import classNames from "classnames";
import { Redirect } from "react-router";
import InfoTooltip from "components/InfoTooltip";

export default React.memo(
    ({
        children,
        tabs = [],
        tabActive,
        onChange,
        type = "",
        stretchTabs = false,
        className,
        tabClassName,
        tabNavClassName,
        stack = false,
    }) => {
        if (!tabs.map((tab) => tab.url).includes(tabActive)) {
            return <Redirect to="/404-page-not-found" />
        }

        return (
            <div className={classNames(classes.tabs, { [className]: className, [classes.subTabs]: type === "subTabs" })}>
                <div
                    className={classNames(classes.tabNav, {
                        [classes.subTabNav]: type === "subTabs",
                        [classes.stretchTabs]: stretchTabs,
                        [tabNavClassName]: tabNavClassName,
                        [classes.stack]: stack,
                    })}
                >
                    <ul>
                        {tabs.map(({ name, url = "", disabled, info, icon, className: tabClass, minWidth = "0rem" }, index) => {
                            const activeTab = url;
                            const isActive = activeTab === tabActive;

                            return (
                                <li
                                    key={index}
                                    disabled={disabled}
                                    className={`${isActive ? classes.active : ""} ${tabClass ? tabClass : ""}`}
                                    onClick={(e) => (!disabled ? onChange(url) : null)}
                                    style={{ minWidth: minWidth }}
                                >
                                    <span>
                                        {icon && <img src={icon} alt={name} />} {name}
                                        {info && <InfoTooltip info={info} className={classes.toolTip} />}
                                </span>
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <div
                    className={classNames(classes.tab, {
                        [classes.subTab]: type === "subTabs",
                        [tabClassName]: tabClassName,
                        [classes.stack]: stack,
                    })}
                >
                    {React.Children.map(children, (child, index) => {
                        const { url = "", name = "" } = tabs[index] || {};

                        const activeTab = url;
                        const isActive = activeTab === tabActive;

                        if (!isActive && !stack) {
                            return null;
                        }

                        if (stack) {
                            return (
                                <div className={classes.stackedTab}>
                                    <h2>{name}</h2>
                                    {child}
                                </div>
                            );
                        }

                        return child;
                    })}
                </div>
            </div>
        )
    }
);
