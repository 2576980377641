import React, { useState } from 'react';
import sharedClasses from 'features/Model/sharedStyles.module.scss';
import classes from '../style.module.scss';
import { Field } from 'redux-form';
import { 
    getHeatingSystemTemplate, 
    getEStarOptions, 
    getEStarManufacturerOptions, 
    getEStarModelOptions,
    getDefaultFurnaceSpecs,
    getDefaultBoilerSpecs
} from 'utils/mechanicals';
import { getValidation } from 'utils/fieldValidation';
import isEmpty from 'lodash/isEmpty';
import InputRow from 'components/Input/Row';
import Select from 'components/Input/Select';
import Button from 'components/Button';
import Baseboards from './Baseboards/container';
import FurnaceBoiler from './FurnaceBoiler/container';
import Combo from './Combo/container';
import P9Combo from './P9Combo/container';
import FansAndPumps from './FansAndPumps/container';
import Drawer from 'components/Drawer';
import AddSystem from './AddSystem/container';
import RadiantHeating from './RadiantHeating/container';
import AdditionalOpenings from './AdditionalOpenings/container';
import SupplementaryHeating from './SupplementaryHeating/container';
import EmptyState from 'components/EmptyState';

const primaryHeatingValidation = getValidation('heatingPrimarySelected');
const eStarManufacturerValidation = getValidation('heatingEStarManufacturer');
const eStarModelValidation = getValidation('heatingEStarModel');

const typeContent = (type) => ({
    baseboards: {
        name:'Baseboard, Hydronic, or Plenum Heater',
        Component:Baseboards,
    },
    furnace: {
        name:'Furnace',
        Component:FurnaceBoiler,
    },
    boiler: {
        name:'Boiler',
        Component:FurnaceBoiler,
    },
    combo: {
        name:'Combo Heating/DHW',
        Component:Combo,
    },
    p9Combo: {
        name:'Combo Heating/DHW (CSA P.9-11 Tested)',
        Component:P9Combo,
    },
    default: {
        name:"",
        Component: () => <></>
    }
}[type || "default"]);

export default ({
    accessor,
    currentPrimarySystem,
    primarySystemType,
    eStarManufacturerValue,
    eStarModelId,
    favId,
    radiantSystem,
    additionalOpenings,
    supplSystems,
    noBasementComponents,
    change,
    modelChange=change,
    isUpgrade=false,
    formName,
    hidePrimaryHeating = false,
    hideSecondaryHeating = false,
}) => {
    const [open, toggleOpen] = useState(false);
    
    const createButtonLabel = () => {
        if (favId) {
            return 'Favourite';
        } else if (eStarManufacturerValue && eStarModelId) {
            return 'Energy Star';
        } else if (primarySystemType) {
            return 'Standard';
        }
        return '';
    };

    const handleCreateSystem = ({type, manufacturer, modelId, favId}) => {
        if (favId) {
            //Favourite System

        } else if (manufacturer && modelId) {
            //EStar
            const eStarTemplate = getEStarOptions(type)[modelId] || {};

            const { [type]: { equipmentType: { id: eStarEquipId = 0 } = {} } = {} } = eStarTemplate;

            const { pilotLight=0,flueDiameter=0 } = (type === 'furnace' ? 
                getDefaultFurnaceSpecs(eStarEquipId) || {} : type === 'boiler' ? 
                getDefaultBoilerSpecs(eStarEquipId) || {} : {});

            eStarTemplate[type].specifications.pilotLight = pilotLight;
            eStarTemplate[type].specifications.flueDiameter = flueDiameter;
        
            change(`${accessor}.heating.system`,eStarTemplate);
            
            //Clear EStar selections for next time
            change(`${accessor}.heating.selected.eStar.manufacturer`, false);
            change(`${accessor}.heating.selected.eStar.modelId`, false);
        } else if (type) {
            //Create standard system from template
            const heatingTemplate = getHeatingSystemTemplate(type) || {};

            change(`${accessor}.heating.system`,heatingTemplate);
            if (!isUpgrade && ['combo','p9Combo'].includes(type)) {
                change('modelData.domesticHotWater.primarySystem',getHeatingSystemTemplate('comboDhw'));
                change('modelData.domesticHotWater.secondarySystem',{});
            }
        }  
    };

    const activeHeatingSystem = typeContent(currentPrimarySystem) || {};

    return (
        <div className={!isUpgrade && sharedClasses.section}>
            { !hidePrimaryHeating &&
                <>
                
                    <div className={classes.heading}>
                        <div>
                            <h3>{activeHeatingSystem.name}</h3>
                        </div>
                        <Button className={classes.createButton} onClick={()=>toggleOpen(true)}>
                            Change Heating System
                        </Button>
                    </div>
                    <Drawer
                        open={open}
                        close={()=>toggleOpen(false)}
                        preTitle="New System"
                        title="Change Heating System"
                        topBarColor="#0eb0a9"
                    >
                        <div>
                            <InputRow>
                                <Field
                                    component={Select}
                                    name={`${accessor}.heating.selected.type`}
                                    options={[
                                        {
                                            value:"baseboards",
                                            label:"Baseboards, Hydronic, Plenum Heaters"
                                        },
                                        {
                                            value:"furnace",
                                            label:"Furnace"
                                        },
                                        {
                                            value:"boiler",
                                            label:"Boiler"
                                        },
                                        {
                                            value:"combo",
                                            label:"Combo Heating/DHW",
                                            disabled: isUpgrade,
                                        },
                                        {
                                            value:"p9Combo",
                                            label:"Combo Heating/DHW (CSA P.9-11)",
                                            disabled: isUpgrade,
                                        }
                                    ]}
                                    label="Primary Heating System Type"
                                    placeholder="Choose Heating System"
                                    validate={primaryHeatingValidation}
                                    info={'"Type 1" system in HOT2000'}
                                    onChange={() => {
                                        change(`${accessor}.heating.selected.eStar.manufacturer`, "")
                                        change(`${accessor}.heating.selected.eStar.modelId`, "")
                                    }}
                                />
                            </InputRow>
                            <InputRow>
                                <Field
                                    component={Select}
                                    name={`${accessor}.heating.selected.eStar.manufacturer`}
                                    options={[
                                        {
                                            value: false,
                                            label: 'None'
                                        },
                                        ...getEStarManufacturerOptions(primarySystemType)
                                    ]}
                                    label="Energy Star Manufacturer"
                                    placeholder="Choose Manufacturer"
                                    validate={eStarManufacturerValidation}
                                    disabled={!['furnace','boiler'].includes(primarySystemType)}
                                    info={'Select a manufacturer of an Energy Star heating system. Only applicable for furnace and boilers.'}
                                    search={true}
                                />
                            </InputRow>
                            <InputRow>
                                <Field
                                    component={Select}
                                    name={`${accessor}.heating.selected.eStar.modelId`}
                                    options={[
                                        {
                                            value: false,
                                            label: 'None'
                                        },
                                        ...getEStarModelOptions({ systemType: primarySystemType, manufacturer: eStarManufacturerValue })
                                    ]}
                                    label="Energy Star Model"
                                    placeholder="Choose Model"
                                    validate={eStarModelValidation}
                                    disabled={!['furnace','boiler'].includes(primarySystemType) || !eStarManufacturerValue}
                                    info={'Select an Energy Star certified model. Only applicable for furnace and boilers.'}
                                    search={true}
                                />
                            </InputRow>
                            <div className={classes.buttons}>
                                <Button
                                    large
                                    type="hollow"
                                    onClick={()=>toggleOpen(false)}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={()=>{
                                        handleCreateSystem({
                                            type: primarySystemType,
                                            manufacturer: eStarManufacturerValue,
                                            modelId: eStarModelId
                                        });
                                        toggleOpen(false);
                                    }}
                                    disabled={!primarySystemType}
                                >
                                    {`Create ${createButtonLabel()} System`}
                                </Button>
                            </div>
                        </div>
                    </Drawer>
                    <activeHeatingSystem.Component formName={formName} accessor={`${accessor}.heating`} change={change} />
                    <FansAndPumps formName={formName} accessor={`${accessor}.heating`} change={change}/>
                </>
            }
            {
                !hideSecondaryHeating &&
                <>
                    <div className={classes.title}>
                        <h4>Auxiliary Heating Systems</h4>
                        <AddSystem formName={formName} accessor={accessor} change={change} />
                    </div>
                    {
                        isEmpty(supplSystems) && isEmpty(radiantSystem) && isEmpty(additionalOpenings) &&
                            <EmptyState
                                title="No auxiliary heating systems to display"
                                style={{}}
                            />
                    }
                    {
                        !isEmpty(supplSystems) && Object.keys(supplSystems)
                            .map(id => (
                                <SupplementaryHeating 
                                    formName={formName}
                                    accessor={`${accessor}.supplSystems.systems.${id}`}
                                    parentAccessor={accessor}
                                    change={change}
                                    key={id}
                                    noBasementComponents={noBasementComponents}
                                />
                            ))
                    }
                    {
                        !isEmpty(radiantSystem) && <RadiantHeating formName={formName} accessor={`${accessor}.radiantHeating`} parentAccessor={accessor} change={change} />
                    }
                    {
                        !isEmpty(additionalOpenings) && <AdditionalOpenings formName={formName} accessor={`${accessor}.additionalOpenings`} parentAccessor={accessor} change={change} />
                    }
                </>
            }
        </div>
    )
};