import React from "react";
import { Document, Page, View, Text } from "@react-pdf/renderer";
import moment from "moment";

import { styles } from "../UpgradesTableStyles";

const UpgradesTablePDFLandscape = ({
    pdfHeaderText = "",
    clientData,
    selectedPackages,
    envelopeRows,
    hvacRows,
    dhwRows,
    otherRows,
    energyEndUseRows,
    heatlossGraphs,
    simulationFuelConsuptionRows,
    simulationEstimateCostRows,
    energyEndUseGraphs,
}) => {
    //
    return (
        <Document>
            <Page style={styles.body} orientation="landscape" size={[816, 1056]}>
                <View style={styles.topTextContainer}>
                    <Text style={{ ...styles.topText, fontWeight: "bold" }}>
                        {pdfHeaderText !== "" ? `${pdfHeaderText} ` : "Net Zero Ready Renovation Path "}
                        {`${clientData.clientStreetAddress ? `- ${clientData.clientStreetAddress}` : ""}`}
                    </Text>
                    <Text style={styles.topText}>{moment(new Date()).format("YYYY-MM-DD")}</Text>
                </View>
                <View style={styles.table}>
                    <View style={[styles.tableRow, { backgroundColor: "#f0f8fd" }]}>
                        <View
                            style={{
                                borderRight: "1px solid #e9f0f4",
                                width: "15px",
                            }}
                        ></View>
                        <View style={[styles.tableCell, { flex: 3 }]}>
                            <Text style={styles.tableHeaderText}>House Components</Text>
                        </View>
                        <View style={[styles.tableCell, { backgroundColor: "#e2ebf1" }]}>
                            <Text style={styles.tableHeaderText}>Exisiting Home</Text>
                        </View>
                        {selectedPackages &&
                            selectedPackages.map((pkg, i) => (
                                <View
                                    key={pkg.id}
                                    style={{
                                        ...styles.tableCell,
                                        borderRight: selectedPackages.length - 1 === i ? "none" : "1px solid #e9f0f4",
                                    }}
                                >
                                    <Text style={styles.tableHeaderText}>{pkg.name}</Text>
                                </View>
                            ))}
                    </View>
                    <View style={[styles.tableRow, { borderTop: "none" }]}>
                        <View
                            style={{
                                borderRight: "1px solid #e9f0f4",
                                width: "16px",
                                display: "flex",
                            }}
                        >
                            <Text
                                style={{
                                    transform: "rotate(-90deg)",
                                    margin: "auto 0 auto -12.5px",
                                    fontSize: 7,
                                    maxLines: 1,
                                    width: "40px",
                                }}
                                break={false}
                            >
                                ENVELOPE
                            </Text>
                        </View>
                        <View
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                flex: "1",
                            }}
                        >
                            {envelopeRows.map((row, i) => {
                                const packageKeys = Object.keys(row).filter((name) => name != "id");
                                return (
                                    <View
                                        key={`${row.id}-${i}`}
                                        id={row.id}
                                        style={[
                                            styles.tableRow,
                                            {
                                                borderTop: "none",
                                                borderLeft: "none",
                                                borderRight: "none",
                                                borderBottom:
                                                    i === envelopeRows.length - 1 ? "none" : "1px solid #e1eaf0",
                                            },
                                        ]}
                                    >
                                        {packageKeys.map((rowKey, i) => (
                                            <View
                                                key={rowKey}
                                                style={[
                                                    styles.tableCell,
                                                    rowKey === "base" && styles.existingHomeTableCell,
                                                    rowKey === "house" && {
                                                        flex: 3,
                                                    },
                                                    i === packageKeys.length - 1 && {
                                                        borderRight: "none",
                                                    },
                                                ]}
                                            >
                                                <Text
                                                    style={{
                                                        fontFamily:
                                                            rowKey === "base" || row[rowKey]?.content[0]?.empty
                                                                ? "Helvetica-Oblique"
                                                                : rowKey === "house"
                                                                ? "Helvetica-Bold"
                                                                : "Helvetica",
                                                        maxLines: 2,
                                                        opacity: row[rowKey]?.content[0]?.empty ? 0.5 : 1,
                                                    }}
                                                    // break
                                                >
                                                    {rowKey === "base" || rowKey === "house"
                                                        ? row[rowKey].content
                                                        : row[rowKey].content[0].displayLabel}
                                                </Text>
                                            </View>
                                        ))}
                                    </View>
                                );
                            })}
                        </View>
                    </View>
                    <View style={[styles.tableRow, { borderTop: "none" }]}>
                        <View
                            style={{
                                borderRight: "1px solid #e9f0f4",
                                width: "16px",
                                display: "flex",
                            }}
                        >
                            <Text
                                style={{
                                    transform: "rotate(-90deg)",
                                    margin: "auto 0 auto 0",
                                    fontSize: 7,
                                    maxLines: 1,
                                    width: "15px",
                                }}
                                break={false}
                            >
                                HVAC
                            </Text>
                        </View>
                        <View
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                flex: "1",
                            }}
                        >
                            {hvacRows.map((row, i) => {
                                const packageKeys = Object.keys(row).filter((name) => name != "id");
                                return (
                                    <View
                                        key={`${row.id}-${i}`}
                                        id={row.id}
                                        style={[
                                            styles.tableRow,
                                            {
                                                borderTop: "none",
                                                borderLeft: "none",
                                                borderRight: "none",
                                                borderBottom: i === hvacRows.length - 1 ? "none" : "1px solid #e1eaf0",
                                            },
                                        ]}
                                    >
                                        {packageKeys.map((rowKey, i) => (
                                            <View
                                                key={rowKey}
                                                style={[
                                                    styles.tableCell,
                                                    rowKey === "base" && styles.existingHomeTableCell,
                                                    rowKey === "house" && {
                                                        flex: 3,
                                                    },
                                                    i === packageKeys.length - 1 && {
                                                        borderRight: "none",
                                                    },
                                                ]}
                                            >
                                                <Text
                                                    style={{
                                                        fontFamily:
                                                            rowKey === "base" || row[rowKey]?.content[0]?.empty
                                                                ? "Helvetica-Oblique"
                                                                : rowKey === "house"
                                                                ? "Helvetica-Bold"
                                                                : "Helvetica",
                                                        maxLines: 2,
                                                        opacity: row[rowKey]?.content[0]?.empty ? 0.5 : 1,
                                                    }}
                                                    // break
                                                >
                                                    {rowKey === "base" || rowKey === "house"
                                                        ? row[rowKey].content
                                                        : row[rowKey].content[0].displayLabel}
                                                </Text>
                                            </View>
                                        ))}
                                    </View>
                                );
                            })}
                        </View>
                    </View>
                    <View style={[styles.tableRow, { borderTop: "none" }]}>
                        <View
                            style={{
                                borderRight: "1px solid #e9f0f4",
                                width: "16px",
                                verticalAlign: "middle",
                                // display: "flex",
                            }}
                        >
                            <Text
                                style={{
                                    transform: "rotate(-90deg)",
                                    margin: "auto 0 auto 0",
                                    fontSize: 7,
                                    maxLines: 1,
                                    width: "16px",
                                    // overflow: "hidden",
                                }}
                                break={false}
                            >
                                DHW
                            </Text>
                        </View>
                        <View
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                flex: "1",
                            }}
                        >
                            {dhwRows.map((row, i) => {
                                const packageKeys = Object.keys(row).filter((name) => name != "id");

                                return (
                                    <View
                                        key={`${row.id}-${i}`}
                                        id={row.id}
                                        style={[
                                            styles.tableRow,
                                            {
                                                borderTop: "none",
                                                borderLeft: "none",
                                                borderRight: "none",
                                                borderBottom: i === dhwRows.length - 1 ? "none" : "1px solid #e1eaf0",
                                            },
                                        ]}
                                    >
                                        {packageKeys.map((rowKey, i) => (
                                            <View
                                                key={rowKey}
                                                style={[
                                                    styles.tableCell,
                                                    rowKey === "base" && styles.existingHomeTableCell,
                                                    rowKey === "house" && {
                                                        flex: 3,
                                                    },
                                                    i === packageKeys.length - 1 && {
                                                        borderRight: "none",
                                                    },
                                                ]}
                                            >
                                                <Text
                                                    style={{
                                                        fontFamily:
                                                            rowKey === "base" || row[rowKey]?.content[0]?.empty
                                                                ? "Helvetica-Oblique"
                                                                : rowKey === "house"
                                                                ? "Helvetica-Bold"
                                                                : "Helvetica",
                                                        maxLines: 2,
                                                        opacity: row[rowKey]?.content[0]?.empty ? 0.5 : 1,
                                                    }}
                                                    // break
                                                >
                                                    {rowKey === "base" || rowKey === "house"
                                                        ? row[rowKey].content
                                                        : row[rowKey].content[0].displayLabel}
                                                </Text>
                                            </View>
                                        ))}
                                    </View>
                                );
                            })}
                        </View>
                    </View>
                    <View
                        style={[
                            styles.tableRow,
                            {
                                borderTop: "none",
                                borderBottom: "1px solid #e9f0f4",
                            },
                        ]}
                    >
                        <View
                            style={{
                                borderRight: "1px solid #e9f0f4",
                                width: "16px",
                            }}
                        >
                            <Text
                                style={{
                                    transform: "rotate(-90deg)",
                                    margin: "auto 0 auto -12px",
                                    fontSize: 7,
                                    maxLines: 1,
                                    width: "40px",
                                }}
                                break={false}
                            >
                                OTHER
                            </Text>
                        </View>
                        <View
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                flex: "1",
                            }}
                        >
                            {otherRows.map((row, i) => {
                                const packageKeys = Object.keys(row).filter((name) => name != "id");
                                return (
                                    <View
                                        key={`${row.id}-${i}`}
                                        id={row.id}
                                        style={[
                                            styles.tableRow,
                                            {
                                                borderTop: "none",
                                                borderLeft: "none",
                                                borderRight: "none",
                                                borderBottom: i === otherRows.length - 1 ? "none" : "1px solid #e1eaf0",
                                            },
                                        ]}
                                    >
                                        {packageKeys.map((rowKey, i) => (
                                            <View
                                                key={rowKey}
                                                style={[
                                                    styles.tableCell,
                                                    rowKey === "base" && styles.existingHomeTableCell,
                                                    rowKey === "house" && {
                                                        flex: 3,
                                                    },
                                                    i === packageKeys.length - 1 && {
                                                        borderRight: "none",
                                                    },
                                                ]}
                                            >
                                                <Text
                                                    style={{
                                                        fontFamily:
                                                            rowKey === "base"
                                                                ? "Helvetica-Oblique"
                                                                : rowKey === "house"
                                                                ? "Helvetica-Bold"
                                                                : "Helvetica",
                                                        maxLines: 1,
                                                        opacity: row[rowKey]?.content[0]?.empty ? 0.5 : 1,
                                                    }}
                                                    // break
                                                >
                                                    {rowKey === "base" || rowKey === "house"
                                                        ? row[rowKey].content
                                                        : row[rowKey].content[0].displayLabel}
                                                </Text>
                                            </View>
                                        ))}
                                    </View>
                                );
                            })}
                        </View>
                    </View>
                </View>
            </Page>
            <Page style={styles.body} orientation="landscape" size={[816, 1056]}>
                <View style={styles.topTextContainer}>
                    <Text style={{ ...styles.topText, fontWeight: "bold" }}>
                        {pdfHeaderText !== "" ? `${pdfHeaderText} ` : "Net Zero Ready Renovation Path "}
                        {`${clientData.clientStreetAddress ? `- ${clientData.clientStreetAddress}` : ""}`}
                    </Text>
                    <Text style={styles.topText}>{moment(new Date()).format("YYYY-MM-D")}</Text>
                </View>
                <View style={styles.table}>
                    <View style={[styles.tableRow, { backgroundColor: "#f0f8fd" }]}>
                        <View
                            style={{
                                borderRight: "1px solid #e9f0f4",
                                width: "15px",
                            }}
                        ></View>
                        <View style={[styles.tableCell, { flex: 3 }]}>
                            <Text style={styles.tableHeaderText}>House Components</Text>
                        </View>
                        <View style={[styles.tableCell, { backgroundColor: "#e2ebf1" }]}>
                            <Text style={styles.tableHeaderText}>Exisiting Home</Text>
                        </View>
                        {selectedPackages &&
                            selectedPackages.map((pkg, i) => (
                                <View
                                    key={pkg.id}
                                    style={{
                                        ...styles.tableCell,
                                        borderRight: selectedPackages.length - 1 === i ? "none" : "1px solid #e9f0f4",
                                    }}
                                >
                                    <Text style={styles.tableHeaderText}>{pkg.name}</Text>
                                </View>
                            ))}
                    </View>
                    <View
                        style={[
                            styles.tableRow,
                            {
                                border: "none",
                            },
                        ]}
                    >
                        <View
                            style={{
                                borderRight: "1px solid #e9f0f4",
                                borderBottom: "1px solid #e9f0f4",
                                borderLeft: "1px solid #e9f0f4",
                                width: "16px",
                                display: "flex",
                            }}
                        >
                            <Text
                                style={{
                                    transform: "rotate(-90deg)",
                                    margin: "auto 0 auto -25.5px",
                                    fontSize: 7,
                                    maxLines: 1,
                                    width: "65px",
                                }}
                                break={false}
                            >
                                PERFORMANCE METRICS
                            </Text>
                        </View>
                        <View
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                flex: "1",
                            }}
                        >
                            {energyEndUseRows.map((row, i) => {
                                const packageKeys = Object.keys(row).filter((name) => name != "id");
                                return (
                                    <View
                                        key={`${row.id}-${i}`}
                                        id={row.id}
                                        style={[
                                            styles.tableRow,
                                            {
                                                borderTop: "none",
                                                borderLeft: "none",
                                                borderRight: "none",
                                                borderBottom:
                                                    i === energyEndUseRows.length - 1
                                                        ? "1px solid #e9f0f4"
                                                        : "1px solid #e1eaf0",
                                            },
                                        ]}
                                    >
                                        {packageKeys.map((rowKey, i) => (
                                            <View
                                                key={rowKey}
                                                style={[
                                                    styles.tableCell,
                                                    styles.singleLine,
                                                    rowKey === "base" && styles.existingHomeTableCell,
                                                    rowKey === "house" && {
                                                        flex: 3,
                                                    },
                                                    i === packageKeys.length - 1 && {
                                                        borderRight: "1px solid #e9f0f4",
                                                    },
                                                ]}
                                            >
                                                <Text
                                                    style={{
                                                        fontFamily: rowKey === "house" ? "Helvetica-Bold" : "Helvetica",
                                                        maxLines: 1,
                                                    }}
                                                    // break
                                                >
                                                    {row[rowKey].content}
                                                </Text>
                                            </View>
                                        ))}
                                    </View>
                                );
                            })}
                        </View>
                    </View>
                    <View
                        style={[
                            styles.tableRow,
                            {
                                border: "none",
                            },
                        ]}
                    >
                        <View
                            style={{
                                borderRight: "1px solid #e9f0f4",
                                borderBottom: "1px solid #e9f0f4",
                                borderLeft: "1px solid #e9f0f4",
                                width: "16px",
                                display: "flex",
                            }}
                        >
                            <Text
                                style={{
                                    transform: "rotate(-90deg)",
                                    margin: "auto 0 auto -32.5px",
                                    fontSize: 7,
                                    maxLines: 1,
                                    width: "80px",
                                }}
                                break={false}
                            >
                                ENERGY END USE (GJ)
                            </Text>
                        </View>
                        <View
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                flex: "1",
                            }}
                        >
                            {energyEndUseGraphs.map((row, i) => {
                                const packageKeys = Object.keys(row).filter((name) => name != "id");
                                return (
                                    <View
                                        key={`${row.id}-${i}`}
                                        id={row.id}
                                        style={[
                                            styles.tableRow,
                                            {
                                                borderTop: "none",
                                                borderLeft: "none",
                                                borderRight: "none",
                                                borderBottom:
                                                    i === energyEndUseGraphs.length - 1
                                                        ? "1px solid #e9f0f4"
                                                        : "1px solid #e1eaf0",
                                            },
                                        ]}
                                    >
                                        {packageKeys.map((rowKey, i) => (
                                            <View
                                                key={rowKey}
                                                style={[
                                                    styles.tableCell,
                                                    styles.singleLine,
                                                    rowKey === "base" && styles.existingHomeTableCell,
                                                    rowKey === "house" && {
                                                        flex: 3,
                                                    },
                                                    i === packageKeys.length - 1 && {
                                                        borderRight: "1px solid #e9f0f4",
                                                    },
                                                ]}
                                            >
                                                <View style={styles.graphHeadingContainer}>
                                                    {rowKey === "house" ? (
                                                        <View
                                                            style={[
                                                                styles.graphSquare,
                                                                {
                                                                    backgroundColor: row[rowKey].backgroundColor,
                                                                },
                                                            ]}
                                                        ></View>
                                                    ) : (
                                                        <View
                                                            style={[
                                                                styles.graphSquare,
                                                                {
                                                                    backgroundColor: row[rowKey].backgroundColor,
                                                                    width: row[rowKey].width
                                                                        ? Number(row[rowKey].width.replace("%", "")) < 1
                                                                            ? "1px"
                                                                            : row[rowKey].width
                                                                        : "1px",
                                                                },
                                                            ]}
                                                        ></View>
                                                    )}
                                                    <Text
                                                        style={{
                                                            fontFamily:
                                                                rowKey === "house" ? "Helvetica-Bold" : "Helvetica",
                                                            maxLines: 1,
                                                        }}
                                                        // break
                                                    >
                                                        {row[rowKey].label}
                                                    </Text>
                                                </View>
                                            </View>
                                        ))}
                                    </View>
                                );
                            })}
                        </View>
                    </View>
                    <View
                        style={[
                            styles.tableRow,
                            {
                                border: "none",
                            },
                        ]}
                    >
                        <View
                            style={{
                                borderRight: "1px solid #e9f0f4",
                                borderBottom: "1px solid #e9f0f4",
                                borderLeft: "1px solid #e9f0f4",
                                width: "16px",
                                display: "flex",
                            }}
                        >
                            <Text
                                style={{
                                    transform: "rotate(-90deg)",
                                    margin: "auto 0 auto -22.5px",
                                    fontSize: 7,
                                    maxLines: 1,
                                    width: "60px",
                                }}
                                break={false}
                            >
                                HEAT LOSS (GJ)
                            </Text>
                        </View>
                        <View
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                flex: "1",
                            }}
                        >
                            {heatlossGraphs.map((row, i) => {
                                const packageKeys = Object.keys(row).filter((name) => name != "id");
                                return (
                                    <View
                                        key={`${row.id}-${i}`}
                                        id={row.id}
                                        style={[
                                            styles.tableRow,
                                            {
                                                borderTop: "none",
                                                borderLeft: "none",
                                                borderRight: "none",
                                                borderBottom:
                                                    i === heatlossGraphs.length - 1
                                                        ? "1px solid #e9f0f4"
                                                        : "1px solid #e1eaf0",
                                            },
                                        ]}
                                    >
                                        {packageKeys.map((rowKey, i) => (
                                            <View
                                                key={rowKey}
                                                style={[
                                                    styles.tableCell,
                                                    styles.singleLine,
                                                    rowKey === "base" && styles.existingHomeTableCell,
                                                    rowKey === "house" && {
                                                        flex: 3,
                                                    },
                                                    i === packageKeys.length - 1 && {
                                                        borderRight: "1px solid #e9f0f4",
                                                    },
                                                ]}
                                            >
                                                <View style={styles.graphHeadingContainer}>
                                                    {rowKey === "house" ? (
                                                        <View
                                                            style={[
                                                                styles.graphSquare,
                                                                {
                                                                    backgroundColor: row[rowKey].backgroundColor,
                                                                },
                                                            ]}
                                                        ></View>
                                                    ) : (
                                                        <View
                                                            style={[
                                                                styles.graphSquare,
                                                                {
                                                                    backgroundColor: row[rowKey].backgroundColor,
                                                                    width: row[rowKey].width
                                                                        ? Number(row[rowKey].width.replace("%", "")) < 1
                                                                            ? "1px"
                                                                            : row[rowKey].width
                                                                        : "1px",
                                                                },
                                                            ]}
                                                        ></View>
                                                    )}
                                                    <Text
                                                        style={{
                                                            fontFamily:
                                                                rowKey === "house" ? "Helvetica-Bold" : "Helvetica",
                                                            maxLines: 1,
                                                        }}
                                                        // break
                                                    >
                                                        {row[rowKey].label}
                                                    </Text>
                                                </View>
                                            </View>
                                        ))}
                                    </View>
                                );
                            })}
                        </View>
                    </View>
                    <View
                        style={[
                            styles.tableRow,
                            {
                                border: "none",
                            },
                        ]}
                    >
                        <View
                            style={{
                                borderRight: "1px solid #e9f0f4",
                                borderBottom: "1px solid #e9f0f4",
                                borderLeft: "1px solid #e9f0f4",
                                width: "16px",
                                display: "flex",
                            }}
                        >
                            <Text
                                style={{
                                    transform: "rotate(-90deg)",
                                    margin: "auto 0 auto -10.5px",
                                    fontSize: 7,
                                    maxLines: 1,
                                    width: "35px",
                                }}
                                break={false}
                            >
                                FUEL USE
                            </Text>
                        </View>
                        <View
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                flex: "1",
                            }}
                        >
                            {simulationFuelConsuptionRows.map((row, i) => {
                                const packageKeys = Object.keys(row).filter((name) => name != "id");
                                return (
                                    <View
                                        key={`${row.id}-${i}`}
                                        id={row.id}
                                        style={[
                                            styles.tableRow,
                                            {
                                                borderTop: "none",
                                                borderLeft: "none",
                                                borderRight: "none",
                                                borderBottom:
                                                    i === simulationFuelConsuptionRows.length - 1
                                                        ? "1px solid #e9f0f4"
                                                        : "1px solid #e1eaf0",
                                            },
                                        ]}
                                    >
                                        {packageKeys.map((rowKey, i) => (
                                            <View
                                                key={rowKey}
                                                style={[
                                                    styles.tableCell,
                                                    styles.singleLine,
                                                    rowKey === "base" && styles.existingHomeTableCell,
                                                    rowKey === "house" && {
                                                        flex: 3,
                                                    },
                                                    i === packageKeys.length - 1 && {
                                                        borderRight: "1px solid #e9f0f4",
                                                    },
                                                ]}
                                            >
                                                <Text
                                                    style={{
                                                        fontFamily: rowKey === "house" ? "Helvetica-Bold" : "Helvetica",
                                                        maxLines: 1,
                                                    }}
                                                    // break
                                                >
                                                    {row[rowKey].content}
                                                </Text>
                                            </View>
                                        ))}
                                    </View>
                                );
                            })}
                        </View>
                    </View>
                    <View
                        style={[
                            styles.tableRow,
                            {
                                border: "none",
                            },
                        ]}
                    >
                        <View
                            style={{
                                borderRight: "1px solid #e9f0f4",
                                borderBottom: "1px solid #e9f0f4",
                                borderLeft: "1px solid #e9f0f4",
                                width: "16px",
                                display: "flex",
                            }}
                        >
                            <Text
                                style={{
                                    transform: "rotate(-90deg)",
                                    margin: "auto 0 auto -12.5px",
                                    fontSize: 7,
                                    maxLines: 1,
                                    width: "40px",
                                }}
                                break={false}
                            >
                                EST. COSTS
                            </Text>
                        </View>
                        <View
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                flex: "1",
                            }}
                        >
                            {simulationEstimateCostRows.map((row, i) => {
                                const packageKeys = Object.keys(row).filter((name) => name != "id");
                                return (
                                    <View
                                        key={`${row.id}-${i}`}
                                        id={row.id}
                                        style={[
                                            styles.tableRow,
                                            {
                                                borderTop: "none",
                                                borderLeft: "none",
                                                borderRight: "none",
                                                borderBottom:
                                                    i === simulationEstimateCostRows.length - 1
                                                        ? "1px solid #e9f0f4"
                                                        : "1px solid #e1eaf0",
                                            },
                                        ]}
                                    >
                                        {packageKeys.map((rowKey, i) => (
                                            <View
                                                key={rowKey}
                                                style={[
                                                    styles.tableCell,
                                                    styles.singleLine,
                                                    rowKey === "base" && styles.existingHomeTableCell,
                                                    rowKey === "house" && {
                                                        flex: 3,
                                                    },
                                                    i === packageKeys.length - 1 && {
                                                        borderRight: "1px solid #e9f0f4",
                                                    },
                                                ]}
                                            >
                                                <Text
                                                    style={{
                                                        fontFamily: rowKey === "house" ? "Helvetica-Bold" : "Helvetica",
                                                        maxLines: 1,
                                                    }}
                                                    // break
                                                >
                                                    {row[rowKey].content}
                                                </Text>
                                            </View>
                                        ))}
                                    </View>
                                );
                            })}
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

export default UpgradesTablePDFLandscape;
