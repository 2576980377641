import moment from "moment";

import general from "../Templates/general.json";
import pvSystemCommissioning from "../Templates/pvSystemCommissioning.json";
import roofMountedPV from "../Templates/roofMountedPV.json";
import groundMountedPV from "../Templates/groundMountedPV.json";
import technical from "../Templates/technical.json";
import verificationChecklist from "../Templates/verificationChecklist.json";

export const buildDeclarationAddress = ({ address = "", city = "", prov = "", postalCode = "" }) => {
    if ([address, city, prov, postalCode].every((el) => el !== "")) {
        return `${address} ${city}, ${prov} ${postalCode}`;
    }
    return "";
};

export const netZeroWorkbookTemplate = ({
    fileID = "",
    userTelephone = "",
    userCompany = "",
    userEmail = "",
    userNameEnteredBy = "",
    renovatorName = "",
    renovatorPhone = "",
    renovatorEmail = "",
    renovatorCompany = "",
    clientCity = "",
    clientStreetAddress = "",
    clientProvince = "",
    totalHouseVolume = 0,
}) => {
    return {
        general: {
            ...general,
            siteInformation: {
                ...general.siteInformation,
                ersFileNumber: fileID,
                siteAddress: clientStreetAddress,
                city: clientCity,
                provinceTerr: clientProvince,
            },
            participant: {
                ea: {
                    name: userNameEnteredBy,
                    email: userEmail,
                    phone: userTelephone,
                    company: userCompany,
                },
                renovator: {
                    company: renovatorCompany,
                    name: renovatorName,
                    email: renovatorEmail,
                    phone: renovatorPhone,
                },
            },
        },
        technical: {
            ...technical,
            spaceCooling: {
                ...technical.spaceCooling,
                houseVolume: totalHouseVolume,
            },
        },
        pvSystemCommissioning: {
            ...pvSystemCommissioning,
            declaration: {
                ...pvSystemCommissioning.declaration,
                date: moment().format("YYYY-MM-DD"),
            },
        },
        roofMountedPV: {
            ...roofMountedPV,
            declaration: {
                ...roofMountedPV.declaration,
                date: moment().format("YYYY-MM-DD"),
            },
        },
        groundMountedPV: {
            ...groundMountedPV,
            declaration: {
                ...groundMountedPV.declaration,
                date: moment().format("YYYY-MM-DD"),
            },
        },
        verificationChecklist,
    };
};
