import React, { useState, useEffect } from "react";
import classes from "../style.module.scss";
import InputWithUnits from "components/Input/InputWithUnits";
import { getUnitOptions, unitLongForm } from "utils/fields";
import Button from "components/Button";

export default ({ page = "", cancel, onSet, setScale, image, scale = {}, px = 0, units: primaryUnits = "m" }) => {
    const { input: initialValue = 0, displayUnits = "", px: initialPx } = scale;

    const [inputValue, changeValue] = useState(initialValue);
    const [inputUnits, changeUnits] = useState(displayUnits);
    const [inputInchesValue, changeInchesValue] = useState(0);

    useEffect(() => {
        changeValue(initialValue);
    }, [image, initialValue]);

    //handling units - may need change?
    const handleUnitChange = (units, value) => {
        changeUnits(value);
    };

    const inputScale =
        inputUnits === "ft" && inputInchesValue > 0 && inputInchesValue <= 12
            ? inputValue + inputInchesValue * 0.0254
            : inputValue;

    return (
        <div>
            <InputWithUnits
                label={initialValue ? `Change scale for Page ${page}*` : `Set scale for Page ${page}*`}
                name="scale"
                type="number"
                input={{
                    onChange: (value) => changeValue(value),
                    value: inputValue,
                }}
                setValue={inputValue}
                change={handleUnitChange}
                units={{
                    base: {
                        trueBase: "m",
                        displayBase: primaryUnits,
                    },
                    options: getUnitOptions("drawingScale"),
                    selected: displayUnits,
                    unitType: "drawingScale",
                    accessor: "test",
                }}
                decimals={4}
                placeholder={0}
                info={`Input the length the scale line represents in ${unitLongForm(inputUnits)}`}
            />
            {inputUnits === "ft" && (
                <div style={{ marginTop: "0.75rem" }}>
                    <InputWithUnits
                        label={"Remainder in inches"}
                        name="scaleInch"
                        type="number"
                        input={{
                            onChange: (value) => changeInchesValue(value),
                            value: inputInchesValue,
                        }}
                        setValue={inputInchesValue}
                        units={{
                            base: {
                                trueBase: "in",
                                displayBase: "in",
                            },
                            options: ["in"],
                            selected: ["in"],
                            unitType: "length",
                            accessor: "test",
                        }}
                        decimals={2}
                        placeholder={0}
                        info={`Input any remaining length in inches. This number will converted into feet and added to the scale.`}
                    />
                </div>
            )}
            <div className={`${classes.buttonRow} ${!cancel && classes.singleRow}`}>
                {cancel && (
                    <Button smallPadding onClick={cancel} type="hollow" className={classes.cancelButton}>
                        Cancel
                    </Button>
                )}
                <Button
                    smallPadding
                    onClick={() => {
                        // console.log(image, inputScale, px);
                        // setScale({
                        //     image,
                        //     scale: {
                        //         input: inputScale,
                        //         units: "m",
                        //         displayUnits: inputUnits,
                        //     },
                        // });
                        // changeInchesValue(0);
                        // onSet();
                    }}
                    className={classes.saveButton}
                    disabled={inputUnits === displayUnits && inputValue === initialValue && inputInchesValue === 0}
                >
                    {initialValue ? "Change Scale" : "Set Scale"}
                </Button>
            </div>
        </div>
    );
};
