import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { IconButton } from "@material-ui/core";

import { selectModelId, selectModelOwnerId } from "features/Model/_ducks/selectors";
import { selectUserUid } from "store/users/selectors";

import { deleteImageData } from "store/imageGallery/thunk";

import { updateImages } from "store/imageGallery/actions";

import useComponentVisible from "utils/hooks";

import Input from "components/Input";
import Tags from "features/Model/ImageGallery/components/ImageView/components/tags";
import Notes from "features/Model/ImageGallery/components/ImageView/components/Notes";
import Status from "features/Model/ImageGallery/components/ImageView/components/Status";
import DropdownItem from "components/DropdownMenu/DropdownItem";
import DropdownMenu from "components/DropdownMenu";

import styles from "./styles.module.scss";

import ThreeDots from "assets/images/icons/JSX/ThreeDots";
import infoIcon from "assets/images/icons/info.svg";
import infoRedIcon from "assets/images/icons/infoRed.svg";

const ImageView = ({
    allImages,
    fileUrl,
    filename,
    deleteImage,
    modelId,
    uid,
    imageId,
    setImages,
    status,
    notes,
    tags,
    isDeleting,
    metadata,
    modelOwnerId,
}) => {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    const inputRef = useRef(null);

    const [isEditing, setIsEditing] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    // const [currentName, setCurrentName] = useState(filename);

    useEffect(() => {
        function handleClickOutside(event) {
            if (inputRef.current && !inputRef.current.contains(event.target)) {
                setIsEditing(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [inputRef]);

    const handleEnterPress = (event) => {
        if (event.key !== "Enter") return;

        setIsEditing(false);
        // setCurrentName(currentName);
    };

    return (
        <div className={styles.imageViewContainer} style={{ opacity: isDeleting ? 0.6 : 1 }}>
            <div className={styles.imageWrapper}>
                <div
                    className={styles.infoWrapper}
                    onMouseEnter={() => setShowInfo(true)}
                    onMouseLeave={() => setShowInfo(false)}
                >
                    <img src={metadata ? infoIcon : infoRedIcon} alt="info icon" />
                </div>
                <div
                    className={styles.imageInfoContainer}
                    style={{ opacity: showInfo ? 1 : 0, borderColor: metadata ? "#0049C6FF" : "#c21818" }}
                >
                    {metadata ? (
                        <div className={styles.imageInfoText}>
                            <span>
                                <b>Date taken:</b> {metadata.dateTimeOriginal}
                            </span>
                            <br />
                            <span>
                                <b>Orientation:</b> {metadata.orientation}
                            </span>
                        </div>
                    ) : (
                        <span style={{ color: "#c21818" }}>
                            <b>Metadata is missing!</b>
                        </span>
                    )}
                </div>
                <img src={fileUrl} alt={filename} />
            </div>
            <div className={styles.imageViewInfoContainer}>
                <div className={styles.imageViewTitleContainer}>
                    {!isEditing ? (
                        <span
                            className={`${styles.imageViewTitle} ${
                                filename.length === 0 ? styles.placeHolderText : ""
                            }`}
                            onClick={() => setIsEditing(true)}
                        >
                            {filename.length === 0 ? "Name..." : filename}
                        </span>
                    ) : (
                        <div style={{ flex: "1" }} ref={inputRef}>
                            <Input
                                input={{
                                    onKeyDown: handleEnterPress,
                                    value: filename,
                                    onChange: (val) =>
                                        setImages(
                                            allImages.map((img) =>
                                                img.id === imageId
                                                    ? {
                                                          ...img,
                                                          name: val,
                                                      }
                                                    : img
                                            )
                                        ),
                                }}
                            />
                        </div>
                    )}
                    <div ref={ref} style={{ position: "relative" }}>
                        <IconButton
                            aria-label="settings"
                            onClick={(e) => setIsComponentVisible(true)}
                            style={{
                                borderRadius: "2px",
                                padding: "12px",
                            }}
                        >
                            <ThreeDots />
                        </IconButton>
                        <DropdownMenu
                            isOpen={isComponentVisible}
                            top={29}
                            style={{
                                width: "fit-content",
                            }}
                        >
                            <DropdownItem
                                key={1}
                                style={modelId === modelOwnerId ? {} : { cursor: "not-allowed", opacity: 0.8 }}
                                onClick={() => {
                                    if (isDeleting || modelId !== modelOwnerId) return;

                                    setImages(
                                        allImages.map((img) =>
                                            img.id === imageId
                                                ? {
                                                      ...img,
                                                      isDeleting: true,
                                                  }
                                                : img
                                        )
                                    );
                                    deleteImage(modelId, uid, allImages, imageId);
                                    setIsComponentVisible(false);
                                }}
                                text={"Delete"}
                            />
                        </DropdownMenu>
                    </div>
                </div>
                <Tags tags={tags || []} imageId={imageId} allImages={allImages} setImages={setImages} />
                <Notes notes={notes} imageId={imageId} allImages={allImages} setImages={setImages} />
                <Status status={status} imageId={imageId} allImages={allImages} setImages={setImages} />
            </div>
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    modelId: selectModelId,
    uid: selectUserUid,
    modelOwnerId: selectModelOwnerId,
});

const mapDispatchToProps = (dispatch) => ({
    deleteImage: (modelId, uid, images, idToDelete) => dispatch(deleteImageData(modelId, uid, images, idToDelete)),
    setImages: (images) => dispatch(updateImages(images)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageView);
