import React from "react";
import classes from "./style.module.scss";

import Card from 'components/Card';
import Button from 'components/Button';

const Options = () => {
    return (
        <div className={classes.options}>
            <Card className={classes.option}>
                <h3>Create from Scratch</h3>
                <p>Create a new house model from a HOT2000 template or by importing an existing HOT2000 file.</p>
            </Card>
            <Card className={classes.option}>
                <h3>Create from Mini Audit Id</h3>
                <p>Create a new house model by providing the id to its mini audit assesment.</p>
            </Card>
        </div>
    )
}

export default Options;