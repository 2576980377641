import React, { useEffect, useCallback, useState } from "react";
import classes from "./style.module.scss";
import globalStyles from "components/globalStyles.module.scss";
import { Field } from "redux-form";
import InputRow from "components/Input/Row";
import InputWithUnits from "components/Input/InputWithUnits";
import { required, requiredCodeRef, getValidation, getDecimalPlaces } from "utils/fieldValidation";
import Select from "components/Input/Select";
import { getOptions } from "utils/fields";
import Add from "assets/images/icons/JSX/Add";
import Book from "assets/images/icons/JSX/Book";
import Edit from "assets/images/icons/JSX/Edit";
import { getBaseUnits, getUnitOptions, getFoundationConfigParams } from "utils/fields";
import Tooltip from "components/Tooltip";
import { isEmpty, isEqual } from "lodash";

const basementWallTypeValidation = [required, ...getValidation("basementIntAddedIns")];
const basementExtWallTypeValidation = [required, ...getValidation("basementExtAddedIns")];
const lintelTypeValidation = getValidation("wallLintelInsType");

export const CodeFooter = ({ toggleDrawer, setInitCode, codeType }) => (
    <div
        className={classes.codeFooter}
        onClick={async () => {
            await setInitCode({}, codeType);
            toggleDrawer(true);
        }}
    >
        <span>Create New Code</span>
        <Add />
    </div>
);

const codeSort = (a, b) => {
    if (a.label < b.label) {
        return -1;
    }
    if (a.label > b.label) {
        return 1;
    }
    return 0;
};

const LabelActionComponent = ({ codeRef, handleEdit, codeType }) => (
    <>
        <div
            className={globalStyles.editCode}
            data-tip="Edit Code"
            data-for={codeRef}
            onClick={(event) => handleEdit(event, codeRef, codeType)}
        >
            <Edit />
            <Tooltip id={codeRef} className={globalStyles.fieldTooltip} place="left" />
        </div>
    </>
);

// BasementWall Ins. Type codes found in code library
export const basementWallTypeLibCodesMap = (libCodes, handleEditLibBasementWallCode) =>
    Object.keys(libCodes)
        .map((codeRef) => {
            const { label = "" } = libCodes[codeRef] || {};
            const [standardCode] = codeRef.split("BasementWall-U");

            return {
                label: label,
                labelIcon: Book,
                labelTag: !standardCode ? "User Defined" : "",
                labelActionComponent: () =>
                    handleEditLibBasementWallCode ? (
                        <LabelActionComponent
                            codeRef={codeRef}
                            handleEdit={handleEditLibBasementWallCode}
                            codeType={standardCode ? "S" : "U"}
                        />
                    ) : null,
                value: {
                    codeLabel: label,
                    codeRef,
                },
            };
        })
        .sort(codeSort);

// BasementWall Ins. Type codes found in model, filtered by those in library
export const basementWallTypeModelCodesMap = (modelCodes, libCodes, handleEditModelBasementWallCode) =>
    Object.values(modelCodes)
        .filter(({ codeRef }) => {
            const [component] = codeRef.split("-");
            if (!!libCodes[codeRef]) {
                return (
                    (!libCodes[codeRef] && component === "BasementWall") ||
                    !isEqual(modelCodes[codeRef].layers, libCodes[codeRef].layers)
                );
            }
            return !libCodes[codeRef] && component === "BasementWall";
        })
        .map(({ label, codeRef }) => {
            const [standardCode] = codeRef.split("BasementWall-U");

            return {
                label,
                labelTag: !standardCode ? "User Defined" : "",
                labelActionComponent: () => (
                    <LabelActionComponent
                        codeRef={codeRef}
                        handleEdit={handleEditModelBasementWallCode}
                        codeType={standardCode ? "S" : "U"}
                    />
                ),
                value: {
                    codeLabel: label,
                    codeRef,
                },
            };
        })
        .sort(codeSort);

// Lintel Type codes found in code library
export const lintelTypeLibCodesMap = (libLintelCodes, handleEditLibLintelCode) =>
    Object.keys(libLintelCodes)
        .map((codeRef) => {
            const { label = "" } = libLintelCodes[codeRef] || {};
            const [standardCode] = codeRef.split("Lintel-U");

            return {
                label: label,
                labelIcon: Book,
                labelActionComponent:
                    standardCode && handleEditLibLintelCode
                        ? () => <LabelActionComponent codeRef={codeRef} handleEdit={handleEditLibLintelCode} />
                        : null,
                value: {
                    codeLabel: label,
                    codeRef,
                },
            };
        })
        .sort(codeSort);

// Lintel Type codes found in model, filtered by those in library
export const lintelTypeModelCodesMap = (modelCodes, libCodes, handleEditModelLintelCode) =>
    Object.values(modelCodes)
        .filter(({ codeRef }) => {
            const [component] = codeRef.split("-");
            if (!!libCodes[codeRef]) {
                return (
                    (!libCodes[codeRef] && component === "Lintel") ||
                    !isEqual(modelCodes[codeRef].layers, libCodes[codeRef].layers)
                );
            }
            return !libCodes[codeRef] && component === "Lintel";
        })
        .map(({ label, codeRef }) => {
            const [standardCode] = codeRef.split("Lintel-U");

            return {
                label,
                labelActionComponent: standardCode
                    ? () => <LabelActionComponent codeRef={codeRef} handleEdit={handleEditModelLintelCode} />
                    : null,
                value: {
                    codeLabel: label,
                    codeRef,
                },
            };
        })
        .sort(codeSort);

export default React.memo(
    ({
        accessor,
        selectedCodeRef,
        selectedLintelCodeRef,
        change,
        modelChange = change,
        id,
        modelUnits,
        intRValUnits,
        extRValUnits,
        extRValId,
        extRValue,
        libCodes,
        libLintelCodes,
        modelCodes,
        selectedNomRValue,
        setInitCode,
        basementInsConfig,
        basementWallCodeWarning,
        isCalculatingRValue,
        updateCodeDrawer,
        selectedEffRValue,
        compositeIntEffRValue,
        compositeExtEffRValue,
        isConcrete,
        updateCompositeCalc,
        disabledCodeEdit = false,
        isUpgrade = false,
        formName,
    }) => {
        const handleTypeChange = useCallback(
            ({ codeRef = "" }) => {
                if (codeRef === "UserSpecified") {
                    change(`${accessor}.wall.intAddedInsType_warning`, "");
                    change(`${accessor}.wall.intAddedInsType_composite`, {});
                    return;
                }

                const modelMatch = modelCodes[codeRef] || {};
                if (!isEmpty(modelMatch)) {
                    change(`${accessor}.wall.intAddedInsType_nomRVal`, modelMatch.nominalRValue || 0);
                    change(`${accessor}.wall.intAddedInsType_effRVal`, modelMatch.nominalRValue || 0);
                    change(`${accessor}.wall.intAddedInsType_warning`, modelMatch.warningType || "");
                } else {
                    const { nominalRValue = 0, warningType = "" } = libCodes[codeRef] || {};
                    change(`${accessor}.wall.intAddedInsType_nomRVal`, nominalRValue || 0);
                    change(`${accessor}.wall.intAddedInsType_effRVal`, nominalRValue || 0);
                    change(`${accessor}.wall.intAddedInsType_warning`, warningType || "");

                    modelChange(`modelData.codes.${codeRef}`, { ...libCodes[codeRef], codeRef });
                }
                change(`${accessor}.wall.intAddedInsType_composite`, {});

                //Redundancy
                modelChange(`stashedCodes.BasementWall.${accessor.split(".").slice(-1)[0]}`, codeRef);
            },
            [libCodes, modelCodes, change, modelChange, accessor, selectedNomRValue]
        );

        const handleLintelTypeChange = useCallback(
            ({ codeRef = "" }) => {
                if (codeRef === "UserSpecified") {
                    return;
                }

                const modelMatch = modelCodes[codeRef] || {};
                if (isEmpty(modelMatch)) {
                    modelChange(`modelData.codes.${codeRef}`, { ...libLintelCodes[codeRef], codeRef });
                }

                modelChange(`stashedCodes.Lintel.${accessor.split(".").slice(-1)[0]}`, codeRef);
            },
            [change, modelChange, accessor, modelCodes, libLintelCodes]
        );

        // useEffect(() => {
        //     if ((selectedCodeRef || "").includes("BasementWall-")) {
        //         modelChange(`stashedCodes.BasementWall.${id}`, selectedCodeRef);
        //     }
        // }, [id, selectedCodeRef]);

        // useEffect(() => {
        //     if ((selectedLintelCodeRef || "").includes("Lintel-")) {
        //         modelChange(`stashedCodes.Lintel.${id}`, selectedLintelCodeRef);
        //     }
        // }, [id, selectedLintelCodeRef]);

        const handleEditModelBasementWallCode = useCallback(
            async (event, codeRef, codeType) => {
                event.stopPropagation(); //Don't let click event bubble up to parent
                const initCodeType = codeType === "U" ? "udefStandard" : "basementWallInsType";
                const code = modelCodes[codeRef];
                await setInitCode(code, initCodeType);
                updateCodeDrawer({
                    isOpen: true,
                    codeName: "Basement Wall",
                    isEditing: true,
                    componentType: "BasementWall",
                    codeType: codeType,
                    fieldAccessor: `${accessor}.wall.intAddedInsType`,
                    componentId: id,
                    id: codeRef,
                    modelFormChange: modelChange,
                    currentFormChange: change,
                    lastEdited: code.lastEdited || "",
                });
            },
            [accessor, id, modelCodes, setInitCode, updateCodeDrawer, change, modelChange]
        );

        const handleEditLibBasementWallCode = disabledCodeEdit
            ? null
            : useCallback(
                  async (event, codeRef, codeType) => {
                      event.stopPropagation(); //Don't let click event bubble up to parent
                      const initCodeType = codeType === "U" ? "udefStandard" : "basementWallInsType";
                      const code = {
                          ...libCodes[codeRef],
                          codeRef: codeRef,
                          isLibCode: true,
                      };
                      await setInitCode(code, initCodeType);
                      updateCodeDrawer({
                          isOpen: true,
                          codeName: "Basement Wall",
                          isEditing: true,
                          componentType: "BasementWall",
                          codeType: codeType,
                          fieldAccessor: `${accessor}.wall.intAddedInsType`,
                          componentId: id,
                          id: codeRef,
                          modelFormChange: modelChange,
                          currentFormChange: change,
                          lastEdited: code.lastEdited || "",
                      });
                  },
                  [accessor, id, libCodes, setInitCode, updateCodeDrawer, change, modelChange]
              );

        const handleEditModelLintelCode = useCallback(
            async (event, codeRef) => {
                event.stopPropagation(); //Don't let click event bubble up to parent
                const code = modelCodes[codeRef];
                await setInitCode(code, "lintelInsType");
                updateCodeDrawer({
                    isOpen: true,
                    codeName: "Lintel",
                    isEditing: true,
                    componentType: "Lintel",
                    codeType: "S",
                    fieldAccessor: `${accessor}.wall.lintelInsType`,
                    componentId: id,
                    id: codeRef,
                    modelFormChange: modelChange,
                    currentFormChange: change,
                    lastEdited: code.lastEdited || "",
                });
            },
            [accessor, id, modelCodes, setInitCode, updateCodeDrawer, change, modelChange]
        );

        const handleEditLibLintelCode = disabledCodeEdit
            ? null
            : useCallback(
                  async (event, codeRef) => {
                      event.stopPropagation(); //Don't let click event bubble up to parent
                      const code = {
                          ...libLintelCodes[codeRef],
                          codeRef: codeRef,
                          isLibCode: true,
                      };
                      await setInitCode(code, "lintelInsType");
                      updateCodeDrawer({
                          isOpen: true,
                          codeName: "Lintel",
                          isEditing: true,
                          componentType: "Lintel",
                          codeType: "S",
                          fieldAccessor: `${accessor}.wall.lintelInsType`,
                          componentId: id,
                          id: codeRef,
                          modelFormChange: modelChange,
                          currentFormChange: change,
                          lastEdited: code.lastEdited || "",
                      });
                  },
                  [accessor, id, libLintelCodes, setInitCode, updateCodeDrawer, change, modelChange]
              );

        const { extIns: exteriorInsAllowed, intIns: interiorInsAllowed } = getFoundationConfigParams({
            fieldKey: "basementInsConfig",
            configKey: basementInsConfig,
        });

        const gridTemplate = isUpgrade ? "2fr 1fr 2fr" : "31.85% 23.25% 31.85%";

        return (
            <>
                <InputRow gridTemplate={gridTemplate}>
                    <Field
                        component={Select}
                        type="number"
                        name={`${accessor}.wall.intAddedInsType`}
                        options={[
                            {
                                label: "User Specified",
                                value: {
                                    codeLabel: "User Specified",
                                    codeRef: "UserSpecified",
                                },
                            },
                            ...basementWallTypeModelCodesMap(modelCodes, libCodes, handleEditModelBasementWallCode),
                            ...basementWallTypeLibCodesMap(libCodes, handleEditLibBasementWallCode),
                        ]}
                        label="Interior Basement Wall Insulation"
                        placeholder="Choose Interior Insulation Type"
                        searchPlaceholder="Search Basement Wall Codes"
                        search={true}
                        footer={() => (
                            <CodeFooter
                                toggleDrawer={(value) =>
                                    updateCodeDrawer({
                                        isOpen: value,
                                        codeName: "BasementWall",
                                        isEditing: false,
                                        componentType: "BasementWall",
                                        codeType: "S",
                                        fieldAccessor: `${accessor}.wall.intAddedInsType`,
                                        componentId: id,
                                        modelFormChange: modelChange,
                                        currentFormChange: change,
                                    })
                                }
                                setInitCode={setInitCode}
                                codeType="basementWallInsType"
                            />
                        )}
                        className={classes.basementWallType}
                        disabled={!interiorInsAllowed}
                        isLoading={isCalculatingRValue}
                        onChange={handleTypeChange}
                        validate={requiredCodeRef}
                    />
                    <div className={classes.insulationCalcWrapper}>
                        {selectedCodeRef === "UserSpecified" ? (
                            <Field
                                component={InputWithUnits}
                                type="number"
                                name={`${accessor}.wall.intAddedInsType_effRVal`}
                                label={"Effective R-Value"}
                                placeholder={0.0}
                                disabled={selectedCodeRef !== "UserSpecified"}
                                validate={basementWallTypeValidation}
                                setTouched={true}
                                setValue={selectedEffRValue}
                                decimals={getDecimalPlaces("basementIntAddedIns")}
                                change={change}
                                units={{
                                    base: getBaseUnits("basementIntAddedIns", modelUnits),
                                    options: getUnitOptions("thermalResistance"),
                                    selected: intRValUnits,
                                    unitType: "thermalResistance",
                                    accessor: `${accessor}.wall.intAddedInsType_u`,
                                }}
                                onChange={(value) => {
                                    if (selectedCodeRef === "UserSpecified") {
                                        change(`${accessor}.wall.intAddedInsType_nomRVal`, value);
                                    }
                                    if (value !== compositeIntEffRValue) {
                                        change(`${accessor}.wall.intAddedInsType_composite`, {});
                                    }
                                }}
                                // info={"User specified values are assumed to be effective R-values."}
                                editToggle={() =>
                                    updateCompositeCalc({
                                        open: true,
                                        fieldId: "basementIntAddedIns",
                                        fieldAccessor: `${accessor}.wall.intAddedInsType`,
                                        isConcrete,
                                        rValUnits: intRValUnits,
                                        currentFormChange: change,
                                        formName,
                                    })
                                }
                            />
                        ) : (
                            <Field
                                component={InputWithUnits}
                                type="number"
                                name={`${accessor}.wall.intAddedInsType_nomRVal`}
                                label={"Nominal R-Value"}
                                placeholder={0.0}
                                disabled={true}
                                validate={basementWallTypeValidation}
                                setTouched={true}
                                decimals={getDecimalPlaces("basementIntAddedIns")}
                                change={change}
                                setValue={selectedNomRValue}
                                units={{
                                    base: getBaseUnits("basementIntAddedIns", modelUnits),
                                    options: getUnitOptions("thermalResistance"),
                                    selected: intRValUnits,
                                    unitType: "thermalResistance",
                                    accessor: `${accessor}.wall.intAddedInsType_u`,
                                }}
                                info={
                                    "Values calculated by the Code Selector are nominal R-values. HOT2000 re-calculates R-values based on the content of the code when running a simulation."
                                }
                                warning={basementWallCodeWarning}
                                isLoading={isCalculatingRValue}
                            />
                        )}
                    </div>
                    <Field
                        component={Select}
                        type="number"
                        name={`${accessor}.wall.lintelInsType`}
                        options={[
                            {
                                label: "N/A",
                                value: {},
                            },
                            ...lintelTypeModelCodesMap(modelCodes, libLintelCodes, handleEditModelLintelCode),
                            ...lintelTypeLibCodesMap(libLintelCodes, handleEditLibLintelCode),
                        ]}
                        label="Lintel Type"
                        placeholder="Choose Lintel Type"
                        searchPlaceholder="Search Lintel Codes"
                        search={true}
                        validate={lintelTypeValidation}
                        footer={() => (
                            <CodeFooter
                                toggleDrawer={(value) =>
                                    updateCodeDrawer({
                                        isOpen: value,
                                        codeName: "Lintel",
                                        isEditing: false,
                                        componentType: "Lintel",
                                        codeType: "S",
                                        fieldAccessor: `${accessor}.wall.lintelInsType`,
                                        componentId: id,
                                        modelFormChange: modelChange,
                                        currentFormChange: change,
                                    })
                                }
                                setInitCode={setInitCode}
                                codeType="basementLintelInsType"
                            />
                        )}
                        onChange={handleLintelTypeChange}
                        className={classes.lintelType}
                    />
                </InputRow>
                <InputRow gridTemplate={gridTemplate}>
                    <Field
                        component={Select}
                        name={`${accessor}.wall.extAddedInsType`}
                        options={[
                            ...getOptions({ fieldKey: "basementExtAddedIns" }),
                            {
                                label: "User Specified",
                                value: {
                                    id: 10,
                                    value: extRValue, //need to get rValue for this to match
                                },
                            },
                        ]}
                        label="Exterior Basement Wall Insulation"
                        placeholder="Choose Exterior Insulation Type"
                        validate={basementExtWallTypeValidation}
                        disabled={!exteriorInsAllowed}
                        onChange={() => {
                            change(`${accessor}.wall.extAddedInsType_composite`, {});
                        }}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.wall.extAddedInsType.value`}
                        label="Effective R-Value"
                        placeholder={0.0}
                        disabled={extRValId !== 10 || !exteriorInsAllowed}
                        validate={basementExtWallTypeValidation}
                        setTouched={true}
                        decimals={getDecimalPlaces("basementExtAddedIns")}
                        change={change}
                        setValue={extRValue}
                        units={{
                            base: getBaseUnits("basementExtAddedIns", modelUnits),
                            options: getUnitOptions("thermalResistance"),
                            selected: extRValUnits,
                            unitType: "thermalResistance",
                            accessor: `${accessor}.wall.extAddedInsType_u`,
                        }}
                        onChange={(value) => {
                            if (value !== compositeExtEffRValue) {
                                change(`${accessor}.wall.extAddedInsType_composite`, {});
                            }
                        }}
                        editToggle={
                            extRValId === 10
                                ? () =>
                                      updateCompositeCalc({
                                          open: true,
                                          fieldId: "basementExtAddedIns",
                                          fieldAccessor: `${accessor}.wall.extAddedInsType.value`,
                                          isConcrete,
                                          rValUnits: extRValUnits,
                                          currentFormChange: change,
                                          formName,
                                      })
                                : null
                        }
                    />
                </InputRow>
            </>
        );
    }
);
