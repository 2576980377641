import { unregisterField } from "redux-form";
import isEmpty from "lodash/isEmpty";
import uniqid from "uniqid";

export const mapComponents = (components, destination) =>
    Object.entries(components)
        .reduce((prev, [componentType, components]) => {
            if (components == null) {
                return prev;
            } else {
                const mappedComponents = Object.entries(components).map(([key, val]) => {
                    if (val === null || val === undefined) {
                        return {};
                    } else {
                        const { displayOrder, category, label = "" } = val;
                        return {
                            componentId: key,
                            componentType,
                            displayOrder,
                            category,
                            label,
                        };
                    }
                });

                return [...prev, ...mappedComponents];
            }
        }, [])
        .sort((a, b) => {
            if (a.displayOrder !== b.displayOrder) {
                return a.displayOrder - b.displayOrder;
            }

            if (a.label !== b.label) {
                if (a.label.toLowerCase() < b.label.toLowerCase()) {
                    return -1;
                }
                if (a.label.toLowerCase() > b.label.toLowerCase()) {
                    return 1;
                }
                return 0;
            }

            return 0;
        });

export const checkMousePosition = (event, currentPosition) => {
    const rect = event.currentTarget.getBoundingClientRect();

    const y = event.clientY - rect.top;

    if (y < 0) return "top";

    const halfOfDiv = rect.height / 2;

    return y < halfOfDiv && y > 0 ? "top" : "bottom";
};

export const dragStart = (index, id, draggableId, droppableId, setSource, setDraggableId) => {
    setSource({ index, droppableId, id });
    setDraggableId(draggableId);
};

export const onDragEnd = (change, destination, source, draggableId, typeOfDrop, components) => {
    const isSubComponent = typeOfDrop === "component" || typeOfDrop === "subcomponent";
    let movingComponentId = draggableId.split(".")[draggableId.split(".").length - 1];

    if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
        return;
    }

    if (isSubComponent) {
        const { droppableId: destinationId, index: destinationIndex } = destination;
        const { droppableId: sourceId, index: sourceIndex } = source;

        if (!destinationId) return;

        const accessor = draggableId.split(sourceId)[1];

        const draggableComponentData = draggableId.split(".").reduce((acc, key) => acc[key], components);

        const { category: destinationCategory = "", subcomponents = {} } = destinationId
            .split(".")
            .reduce((acc, key) => acc[key], components);

        const targetCompData = {
            ...draggableComponentData,
            category: destinationCategory,
            displayOrder: destinationIndex,
        };

        if (destinationId !== sourceId) {
            // Remove from previous parent component
            change(`modelData.components.${sourceId}${accessor}`, null);
            unregisterField("model", `modelData.components.${sourceId}${accessor}`);
            // Add to new parent component

            const newAccessor = accessor.replace(
                `${accessor.split(".")[accessor.split(".").length - 1]}`,
                `${uniqid(`${accessor.split(".")[2]}-`)}`
            );

            movingComponentId = newAccessor.split(".")[newAccessor.split(".").length - 1];

            change(`modelData.components.${destinationId}${newAccessor}`, targetCompData);
        } else {
            change(`modelData.components.${draggableId}.displayOrder`, destinationIndex);
        }

        return mapComponents(subcomponents)
            .filter((el) => !isEmpty(el))
            .sort((a, b) => a.displayOrder - b.displayOrder)
            .map(({ componentType: type, componentId: id }, currentIndex) => {
                if (id === movingComponentId) {
                    return draggableId;
                }
                if (destinationId === sourceId && sourceIndex < currentIndex && destinationIndex >= currentIndex) {
                    change(
                        `modelData.components.${destinationId}.subcomponents.${type}.${id}.displayOrder`,
                        currentIndex - 1
                    );
                    return draggableId;
                }

                if (sourceIndex > currentIndex && destinationIndex <= currentIndex) {
                    change(
                        `modelData.components.${destinationId}.subcomponents.${type}.${id}.displayOrder`,
                        currentIndex + 1
                    );
                    return draggableId;
                }

                change(`modelData.components.${destinationId}.subcomponents.${type}.${id}.displayOrder`, currentIndex);
            });
    }

    if (!isSubComponent && !destination.droppableId !== source.droppableId) {
        change(`modelData.components.${draggableId}.category`, destination.droppableId);
    }

    if (!isSubComponent && destination.index !== source.index) {
        let destinationIndex = destination.index;

        change(`modelData.components.${draggableId}.displayOrder`, destinationIndex);

        mapComponents(components, destination)
            .filter(({ category }) => category === destination.droppableId)
            .map(({ componentType: type, componentId: id }, currentIndex) => {
                if (id === movingComponentId) {
                    return draggableId;
                }

                if (
                    destination.droppableId === source.droppableId &&
                    source.index < currentIndex &&
                    destinationIndex >= currentIndex
                ) {
                    change(`modelData.components.${type}.${id}.displayOrder`, currentIndex - 1);
                    return draggableId;
                }

                if (source.index > currentIndex && destinationIndex <= currentIndex) {
                    change(`modelData.components.${type}.${id}.displayOrder`, currentIndex + 1);
                    return draggableId;
                }

                change(`modelData.components.${type}.${id}.displayOrder`, currentIndex);
            });
    }
};
