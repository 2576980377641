import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import SteelFraming from './';

const mapStateToProps = (
    {
        form,
    },
    {
        accessor
    }
) => {
    const selector = formValueSelector('uDefCode');
    const modelSelector = formValueSelector('model');
    const { id: steelGaugeId, value: steelGaugeValue} = selector({form}, `${accessor}.framing.steelGauge`) || {};

    const { id: cavityMaterialCatId } = selector({form}, `${accessor}.cavityInsulation.materialCategory`) || {};
    const { id: cavityMaterialTypeId } = selector({form}, `${accessor}.cavityInsulation.materialType`) || {};
    const cavityResistivityValue = selector({form}, `${accessor}.cavityInsulation.resistivity`);
    const cavityThicknessValue = selector({form}, `${accessor}.cavityInsulation.thickness`);
    const steelFramingLayers = selector({form}, 'uDefCode.layers.steelFraming');

    const cavityRValue = parseFloat((cavityResistivityValue * cavityThicknessValue).toFixed(2));

    const steelGaugeUnits = selector({form}, `${accessor}.framing.steelGauge_u`);
    const framingThicknessUnits = selector({form}, `${accessor}.framing.thickness_u`);
    const framingWidthUnits = selector({form}, `${accessor}.framing.width_u`);
    const framingSpacingUnits = selector({form}, `${accessor}.framing.spacing_u`);

    const cavityResistivityUnits = selector({form}, `${accessor}.cavityInsulation.resistivity_u`);
    const cavityThicknessUnits = selector({form}, `${accessor}.cavityInsulation.thickness_u`);
    const cavityRValueUnits = selector({form}, `${accessor}.cavityInsulation.rValue_u`);

    const modelUnits = modelSelector({form}, `modelData.uiSettings.primaryUnits`) || {};
    const layer = selector({form}, `${accessor}.layer`);

    return {
        accessor,
        steelGaugeId,
        steelGaugeValue,
        steelGaugeUnits,
        framingThicknessUnits,
        framingWidthUnits,
        framingSpacingUnits,
        cavityMaterialCatId,
        cavityMaterialTypeId,
        cavityResistivityValue,
        cavityResistivityUnits,
        cavityThicknessUnits,
        cavityRValue,
        cavityRValueUnits,
        modelUnits,
        layer,
        steelFramingLayers
    }
};

const mapDispatchToProps = () => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SteelFraming);