import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import isEmpty from "lodash/isEmpty";
import Geometry from "./";

const mapStateToProps = ({ form }, { accessor }) => {
    const selector = formValueSelector("model");

    const components = selector({ form }, "modelData.components") || {};

    const { id: buildingTypeId } = selector({ form }, `modelData.specifications.buildingType`) || {};
    const buildingType =
        {
            0: "house",
            1: "unitMurb",
            2: "wholeMurb",
        }[buildingTypeId] || "house";

    const activeDrawer = selector({ form }, `${accessor}.activeDrawer`);
    const agFloorAreaTotalValue = selector({ form }, `${accessor}.aboveGradeIntFloorArea.total`);
    const agFloorAreaUnits = selector({ form }, `${accessor}.aboveGradeIntFloorArea_u`);
    const bgFloorAreaTotalValue = selector({ form }, `${accessor}.belowGradeIntFloorArea.total`);
    const bgFloorAreaUnits = selector({ form }, `${accessor}.belowGradeIntFloorArea_u`);
    const volumeTotalValue = selector({ form }, `${accessor}.volume.total`);
    const volumeItems = selector({ form }, `${accessor}.volume.items`) || {};
    const volumeUnits = selector({ form }, `${accessor}.volume_u`);
    const modelUnits = selector({ form }, `modelData.uiSettings.primaryUnits`) || {};
    const windowToWallValue = selector({ form }, `${accessor}.windowToWall.value`);
    const windowToWallUnits = selector({ form }, `${accessor}.windowToWall.value_u`);
    const highestCeilingUnits = selector({ form }, `modelData.dimensions.highestCeiling.total_u`);
    const nonResidentialFloorAreaUnits = selector({ form }, `modelData.dimensions.nonResidentialFloorArea.total_u`);
    const commonHeatedFloorAreaUnits = selector({ form }, `modelData.dimensions.commonHeatedFloorArea.total_u`);

    const belowGradeItems = selector({ form }, `modelData.dimensions.belowGradeIntFloorArea.items`) || {};
    const aboveGradeItems = selector({ form }, `modelData.dimensions.aboveGradeIntFloorArea.items`) || {};

    const highestCeilingEquation = selector({ form }, `modelData.dimensions.highestCeiling.total_eqn`) || ""; //[!!]
    const nonResidentialFloorAreaEqn =
        selector({ form }, `modelData.dimensions.nonResidentialFloorArea.total_eqn`) || "";

    const nonResUnitCount = selector({ form }, "modelData.specifications.murbSpecs.numNonResUnits") || 0;

    const commonHeatedFloorAreaEqn = selector({ form }, `modelData.dimensions.commonHeatedFloorArea.total_eqn`) || "";

    const highestCeilingDrawingRef = selector({ form }, `modelData.dimensions.highestCeiling.total_drawingRef`) || "";
    const highestCeilingValue = selector({ form }, `modelData.dimensions.highestCeiling.total`) || 0;

    const componentsPresent = Object.keys(selector({ form }, `modelData.components`) || {});

    const isCrawlspacePresent = componentsPresent.includes("crawlspace");
    const isBasementPresent = componentsPresent.includes("basement");

    const floorAreaItems = {
        ...belowGradeItems,
        ...aboveGradeItems,
    };

    const hasFloorItems = Object.keys(floorAreaItems).map((item) => item).length > 0;

    const isAirTestEnabled = selector({ form }, "modelData.airTightness.testDataEnabled");
    const testData = selector({ form }, "modelData.airTightness.testData") || {};
    const { testType: { id: testTypeId } = {} } = testData || {};

    const volumeItemsSum = Object.values(volumeItems).reduce((sum, curr) => sum + curr.value, 0);

    return {
        accessor,
        activeDrawer,
        agFloorAreaTotalValue,
        agFloorAreaUnits,
        bgFloorAreaTotalValue,
        bgFloorAreaUnits,
        volumeTotalValue: parseFloat(volumeTotalValue.toFixed(2)),
        volumeUnits,
        components,
        windowToWallValue,
        windowToWallUnits,
        isCrawlspacePresent,
        isBasementPresent,
        highestCeilingUnits,
        nonResidentialFloorAreaUnits,
        commonHeatedFloorAreaUnits,
        highestCeilingValue,
        highestCeilingEquation,
        nonResidentialFloorAreaEqn,
        commonHeatedFloorAreaEqn,
        modelUnits,
        highestCeilingDrawing: !isEmpty(highestCeilingDrawingRef),
        hasFloorItems,
        isAirTestEnabled,
        testData,
        testTypeId,
        volumeItemsSum: parseFloat(volumeItemsSum.toFixed(2)),
        buildingType,
        nonResUnitCount,
    };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Geometry);
