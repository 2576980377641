import React, { useState } from "react";
import classes from "../style.module.scss";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import { Field } from "redux-form";
import MultiSelect from "components/Input/MultiSelect";
import Button from "components/Button";
import { required, maxLength } from "utils/fieldValidation";
import AddLayer from "../AddLayer";
import isEmpty from "lodash/isEmpty";
import EmptyState from "components/EmptyState";
import { getAllSubcomponents } from "utils/enclosure/components";

import WindowLegacy from "./WindowLegacy/container";
import WindowOverall from "./WindowOverall/container";

import Checkbox from "components/Input/Checkbox";
import Tooltip from "components/Tooltip";

const charLim32 = maxLength(32);

const labelValidation = [required, charLim32];
const descriptionValidation = [maxLength(100)];

const getLayerType = {
    windowLegacy: WindowLegacy,
    windowOverall: WindowOverall,
};

const codeSort = (a, b) => {
    if (a.label < b.label) {
        return -1;
    }
    if (a.label > b.label) {
        return 1;
    }
    return 0;
};

export default React.memo(
    ({
        change,
        codeLayers,
        numLayers,
        handleSubmit,
        submitting,
        invalid,
        isLibCode = false,
        hideUpdateAll = false,
        handleClose,
        isCodeLibrary,
        isUpgradeComponent = false,
        windowAccessor,
        components,
    }) => {
        const availableComponents = getAllSubcomponents("window", components, windowAccessor).sort(codeSort);

        return (
            <div className={classes.uDefCodeWrapper}>
                <h3>User Defined Code</h3>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Input}
                        type="text"
                        name={`uDefCode.label`}
                        label="User Defined Code Label"
                        placeholder="Enter Code Label"
                        validate={labelValidation}
                    />
                    <Field
                        component={Input}
                        type="text"
                        name={`uDefCode.description`}
                        label="Description"
                        placeholder="Enter Code Description"
                        validate={descriptionValidation}
                    />
                </InputRow>
                <div className={`${classes.title} ${classes.layers}`}>
                    <h4>Layers</h4>
                    <AddLayer
                        change={change}
                        numLayers={numLayers}
                        formName="uDefCode"
                        layerLimit={1}
                        options={[
                            {
                                value: "windowLegacy",
                                label: "Window Legacy",
                            },
                            {
                                value: "window",
                                label: "Window Overall",
                            },
                        ]}
                    />
                </div>
                <div>
                    {isEmpty(codeLayers) && <EmptyState title="No layers to display" style={{}} />}
                    {codeLayers.map((layer, ind) => {
                        const { id, type, ...rest } = layer;
                        const LayerType = getLayerType[type];

                        return (
                            <LayerType
                                type={type}
                                key={ind}
                                id={id}
                                value={rest}
                                change={change}
                                accessor={`uDefCode.layers.${type === "windowOverall" ? "window" : type}.${id}`}
                            />
                        );
                    })}
                </div>
                <div style={{ borderBottom: "1px solid #e1eaf0", marginTop: "1.5rem", marginBottom: "1.5rem" }}></div>
                {!isCodeLibrary && !isUpgradeComponent && (
                    <InputRow gridTemplate="1fr">
                        <Field
                            component={MultiSelect}
                            name={`selectedComponents`}
                            label={`Apply to multiple window components`}
                            options={availableComponents.map(({ disabled = false, label = "", ...rest }) => ({
                                ...rest,
                                disabled,
                                label: disabled ? `${label} (current component)` : label,
                            }))}
                            hasSelectAll
                        />
                    </InputRow>
                )}
                {!isCodeLibrary && (
                    <InputRow gridTemplate="1fr 1fr" style={{ marginTop: "1rem" }}>
                        <div data-tip="Code will automatically be updated in library" data-for="codeLibTip">
                            <Field
                                component={Checkbox}
                                name={"addToLibrary"}
                                label={isLibCode ? "Update code in library" : "Add to code library"}
                                large
                                disabled={isLibCode}
                                type="checkbox"
                            />
                        </div>
                        <Field
                            component={Checkbox}
                            name={"setAsModelDefault"}
                            label={"Set as model default"}
                            large
                            type="checkbox"
                        />
                    </InputRow>
                )}
                <div className={classes.buttons}>
                    <Button large type="hollow" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button large onClick={handleSubmit} disabled={submitting || invalid}>
                        {submitting ? "Saving Code..." : "Save Code"}
                    </Button>
                </div>
                <div style={{ paddingTop: "7.5rem" }}></div>
                <Tooltip id="codeLibTip" hide={!isLibCode} />
            </div>
        );
    }
);
