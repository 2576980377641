import React, { useEffect } from "react";
import classes from "./style.module.scss";
import sharedClasses from "../style.module.scss";
import Check from "assets/images/icons/Check.svg";
import NonH2kParamTooltip from "components/NonH2kParamTooltip";

export default ({
    input = {},
    hideLabel = false,
    label = "",
    name,
    className,
    disabled,
    large,
    style = {},
    image,
    nonH2kParam = "",
    reset = false,
}) => {
    const { value, onChange } = input;

    const classNames = [
        classes.checkboxWrapper,
        sharedClasses.inputField,
        className && className,
        disabled && classes.disabled,
        large && classes.large,
        value && classes.checked,
    ].filter((c) => c);

    useEffect(() => {
        if (reset) {
            onChange(false);
        }
    }, [reset]);

    return (
        <label className={classNames.join(" ")} htmlFor={name} style={style}>
            <div className={classes.checkbox}>{value && <img src={Check} alt="Checkmark" />}</div>
            {image && (
                <img
                    src={image}
                    style={{
                        marginRight: "0.5rem",
                        border: "0.5px solid black",
                    }}
                    width="80"
                    alt="image multi select"
                />
            )}
            <div className={`${sharedClasses.label} ${classes.label} ${hideLabel ? "invisible" : ""}`} htmlFor={name}>
                {label}
            </div>
            {nonH2kParam && (
                <NonH2kParamTooltip className={`${classes.nonH2kParamTooltip}`} nonH2kParam={nonH2kParam} />
            )}

            <input
                {...input}
                disabled={disabled}
                name={name}
                id={name}
                className={classes.checkboxInput}
                type="checkbox"
            />
        </label>
    );
};
