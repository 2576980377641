import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import CrawlspaceFields from './';

const mapStateToProps = (
    {
        form,
        drawing:{
            imageData={},
        },
    },
    {
        componentId
    }
) => {
    const modelSelector = formValueSelector('model');
    const selector = formValueSelector('drawingPolygon');
    const expPerimeterUnits = selector({form}, `components.${componentId}.exposedSurfacePerimeter_u`);
    const heightUnits = selector({form}, `components.${componentId}.wall.measurements.height_u`);
    const depthUnits = selector({form}, `components.${componentId}.wall.measurements.depth_u`);

    const lines = Object.keys(imageData).reduce((cache, image) => {
        const { lines={} } = imageData[image] || {};
        return {
            ...cache,
            ...lines,
        }
    },{});

    const fieldsWithLinesAttached = Object.keys(lines).reduce((cache, line) => {
        const {
            changeField:{
                key:fieldKey=''
            }={},
            attachTo:{
                componentId:lineComponentId,
            }={}
        } = lines[line];

        if (lineComponentId === componentId) {
            return [
                ...cache,
                fieldKey
            ];
        }

        return cache;
    },[]);

    return {
        expPerimeterUnits,
        heightUnits,
        depthUnits,
        fieldsWithLinesAttached
    }
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CrawlspaceFields);