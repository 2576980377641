import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import Window from "./";

const mapStateToProps = (
    { form, model: { newestComponents = [], recentDuplicates = [], contentOpen = false } = {} },
    { id, parentAccessor }
) => {
    const accessor = `${parentAccessor}.subcomponents.floorHeader.${id}`;

    const selector = formValueSelector("model");
    const label = selector({ form }, `${accessor}.label`) || "";
    const { id: floorHeaderFacingId } = selector({ form }, `${accessor}.facingDirection`) || {};
    const { id: parentFacingId } = selector({ form }, `${parentAccessor}.facingDirection`) || {};

    const headerHeightDrawing = selector({ form }, `${accessor}.measurements.height_drawingRef`) || "";

    const drawingLines = [...(headerHeightDrawing ? [headerHeightDrawing] : [])];

    //Check perimeters of floor header and parent components to define initial value
    //value updated in useEffect in floor header index file if floorHeaderPerimeter === -1
    const floorHeaderPerimeter = selector({ form }, `${accessor}.measurements.perimeter`) || 0;

    let parentPerimeter = 0;
    if (parentAccessor.includes("wall")) {
        //Parent is a wall
        parentPerimeter = selector({ form }, `${parentAccessor}.measurements.perimeter`) || 0;
    } else {
        //parent is a basement or crawlspace
        const isRectangular = selector({ form }, `${parentAccessor}.floor.measurements.isRectangular`) || false;
        const foundationLength = selector({ form }, `${parentAccessor}.floor.measurements.length`) || 0;
        const foundationWidth = selector({ form }, `${parentAccessor}.floor.measurements.width`) || 0;
        const foundationPerimeter = selector({ form }, `${parentAccessor}.floor.measurements.perimeter`) || 0;
        parentPerimeter = isRectangular ? 2 * (foundationLength + foundationWidth) : foundationPerimeter;
    }

    return {
        label,
        accessor,
        componentId: id,
        floorHeaderFacingId,
        parentFacingId,
        floorHeaderPerimeter,
        parentPerimeter,
        isNew: newestComponents.includes(id),
        isRecentDuplicate: recentDuplicates.includes(id),
        drawingLines,
        contentOpen,
    };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Window);
