import React, { useEffect } from "react";
import { Field } from "redux-form";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import InputWithUnits from "components/Input/InputWithUnits";
import { getValidation, getDecimalPlaces } from "utils/fieldValidation";
import { getBaseUnits, getUnitOptions } from "utils/fields";
import { getOptions, getFirstOption } from "utils/fields";
import SelectWithInput from "components/Input/SelectWithInput";

const heightValidation = getValidation("windowHeight");
const widthValidation = getValidation("windowWidth");
const areaValidation = getValidation("windowArea");
const headerValidation = getValidation("windowHeaderHeight");
const overhangValidation = getValidation("windowOverhangWidth");
const tiltValidation = getValidation("windowTilt");

export default React.memo(
    ({
        id,
        accessor,
        windowHeight = 0,
        windowWidth = 0,
        overhangWidth,
        headerHeight,
        modelUnits,
        heightUnits,
        widthUnits,
        overhangUnits,
        headerUnits,
        areaUnits,
        change,
        isDrawingComponent,
        overhangWidthDrawing,
        headerHeightDrawing,
        updateDrawingChanges,
        drawingComponent,
        drawingImage,
        windowWidthEquation,
        windowHeightEquation,
        windowHeaderHeightEquation,
        windowOverhangWidthEquation,
    }) => {
        useEffect(() => {
            updateDrawingChanges(id, {
                type: "window",
                drawingComponent,
                drawingImage,
                measurements: {
                    headerHeight,
                    overhangWidth,
                },
            });
        }, [headerHeight, overhangWidth]);

        return (
            <>
                <InputRow gridTemplate="1fr 1fr 1fr">
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.measurements.width`}
                        label="Window Width"
                        placeholder="0.00"
                        validate={widthValidation}
                        disabled={isDrawingComponent}
                        isDrawing={isDrawingComponent}
                        decimals={getDecimalPlaces("windowWidth")}
                        change={change}
                        units={{
                            base: getBaseUnits("windowWidth", modelUnits),
                            options: getUnitOptions("length"),
                            selected: widthUnits,
                            unitType: "length",
                            accessor: `${accessor}.measurements.width_u`,
                        }}
                        withEquation
                        equation={windowWidthEquation}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.measurements.height`}
                        label="Window Height"
                        placeholder="0.00"
                        validate={heightValidation}
                        decimals={getDecimalPlaces("windowHeight")}
                        disabled={isDrawingComponent}
                        isDrawing={isDrawingComponent}
                        change={change}
                        units={{
                            base: getBaseUnits("windowHeight", modelUnits),
                            options: getUnitOptions("length"),
                            selected: heightUnits,
                            unitType: "length",
                            accessor: `${accessor}.measurements.height_u`,
                        }}
                        withEquation
                        equation={windowHeightEquation}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.measurements.area`}
                        label="Window Area"
                        placeholder="0.00"
                        disabled={true}
                        setValue={(windowHeight * windowWidth) / 1000000}
                        validate={areaValidation}
                        decimals={getDecimalPlaces("windowArea")}
                        change={change}
                        units={{
                            base: getBaseUnits("windowArea", modelUnits),
                            options: getUnitOptions("area"),
                            selected: areaUnits,
                            unitType: "area",
                            accessor: `${accessor}.measurements.area_u`,
                        }}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr 1fr">
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.measurements.headerHeight`}
                        label="Header Height"
                        placeholder="0.00"
                        validate={headerValidation}
                        setValue={headerHeight}
                        disabled={headerHeightDrawing}
                        isDrawing={headerHeightDrawing}
                        decimals={getDecimalPlaces("windowHeaderHeight")}
                        change={change}
                        units={{
                            base: getBaseUnits("windowHeaderHeight", modelUnits),
                            options: getUnitOptions("length"),
                            selected: headerUnits,
                            unitType: "length",
                            accessor: `${accessor}.measurements.headerHeight_u`,
                        }}
                        withEquation
                        equation={windowHeaderHeightEquation}
                        info={
                            "The Header Height is measured from the top of the glass to the underside of the overhang. For an overhang that descends down over the glazing, enter the Header Height as a negative number."
                        }
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.measurements.overhangWidth`}
                        label="Overhang Width"
                        placeholder="0.00"
                        validate={overhangValidation}
                        setValue={overhangWidth}
                        disabled={overhangWidthDrawing}
                        isDrawing={overhangWidthDrawing}
                        decimals={getDecimalPlaces("windowOverhangWidth")}
                        change={change}
                        units={{
                            base: getBaseUnits("windowOverhangWidth", modelUnits),
                            options: getUnitOptions("length"),
                            selected: overhangUnits,
                            unitType: "length",
                            accessor: `${accessor}.measurements.overhangWidth_u`,
                        }}
                        withEquation
                        equation={windowOverhangWidthEquation}
                        info={
                            "The Overhang Width is measured from the outside of the glass to the tip of the overhang. If the glass is recessed into the wall include the recessed distance."
                        }
                    />
                    <Field
                        component={SelectWithInput}
                        inputData={{
                            triggerValue: 0,
                            inputLabel: "Tilt",
                            inputType: "number",
                            inputPlaceholder: 0,
                        }}
                        name={`${accessor}.measurements.tilt`}
                        options={getOptions({ fieldKey: "windowTilt" })}
                        label="Window Tilt"
                        placeholder="Choose Window Tilt"
                        validate={tiltValidation}
                    />
                </InputRow>
            </>
        );
    }
);
