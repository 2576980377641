export const foundationOptions = [
    {
        value: "basement",
    },
    {
        value: "walkout",
    },
    {
        value: "crawlspace",
    },
    {
        value: "slab",
    },
    // {
    //   value: "basementCrawlspace"
    // },
];

export const getFoundationName = (value) => {
    switch (value.toLowerCase()) {
        case "basement":
            return "Basement";
        case "crawlspace":
            return "Crawlspace";
        case "walkout":
            return "Walkout";
        case "slab":
            return "Slab-on-grade";
        case "basementcrawlspace":
            return "Basement-Crawlspace";
        default:
            return "";
    }
};
