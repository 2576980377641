import { connect } from 'react-redux';
import DrawingComponent from './';

const mapStateToProps = (
    {
        model:{
            modelData:{
                components:componentCats={}
            }={} 
        }={},
        drawing:{
            activeComponent='',
            activeImage='',
            imageData={},
            tools:{
                active:activeTool=''
            }={}
        }
    },
) => {
    const {
        [activeImage]:{
            components:{
                [activeComponent]:componentData={}
            }={} 
        }={}
    } = imageData;

    const { type='' } = componentData;

    return {
        type,
    };
};

const mapDispatchToProps = dispatch => ({});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DrawingComponent);