import React, { useState } from "react";
import classes from "./style.module.scss";
import WindowIcon from "assets/images/components/JSX/Window-Simple";
import DoorIcon from "assets/images/components/JSX/Rectangle";
import Line from "assets/images/components/JSX/Line";
import Polygon from "assets/images/components/JSX/Polygon";
import Arrow from "assets/images/icons/JSX/arrow-down";
import Folder from "assets/images/icons/JSX/Folder";
import { Collapse } from "@material-ui/core";
import isEmpty from "lodash/isEmpty";
import { capitalize } from "utils";
import Tooltip from "components/Tooltip";

import { newTransformer, attachAnchors, removeAnchors } from "utils/drawing/shapes";
import { useCallback } from "react";

const componentIcon = (type) =>
    ({
        measure: Line,
        door: DoorIcon,
        window: WindowIcon,
        line: Line,
        polygon: Polygon,
    }[type]);

const ImageFolder = ({
    scale,
    image,
    setActiveImage,
    activeImage,
    setActiveComponent,
    activeComponent,
    components,
    polygons,
    lines,
    setAction,
    setActiveTool,
    stage,
    layer,
    updateStage,
}) => {
    const [open, toggleOpen] = useState(true);
    const page = image.split("page-")[1].split(".jpg")[0];
    const pageNum = parseFloat(page);
    const isActive = activeImage === image;

    const deselectShape = useCallback(
        (component) => {
            const shape = stage.current.findOne(`#${component}`);
            const shapeId = shape.getAttr("id");

            if (!shape) {
                return;
            }

            // Polygons and Lines
            if (shape.name() === "outline" || shape.name() === "line" || shape.name() === "measure") {
                // Remove anchors
                removeAnchors(shape.getParent(), stage.current);
                shape.getParent().setAttr("draggable", false);
                return;
            }

            // Windows and Doors
            const transformer = stage.current.findOne(`#transformer_${shapeId}`);
            if (transformer) {
                transformer.destroy();
            }
            shape.setAttr("draggable", false);
        },
        [stage]
    );

    const activateScale = useCallback(() => {
        const scaleId = `measure_${image}`;
        const shape = stage.current.findOne(`#${scaleId}`);
        const anchors = attachAnchors(scaleId, shape, image, stage.current);

        if (activeComponent) {
            deselectShape(activeComponent);
        }

        anchors.map((anchor) => shape.getParent().add(anchor));
        shape.getParent().moveToTop();
        shape.getParent().setAttr("draggable", true);

        setActiveComponent(`measure_${image}`);
        setActiveImage(image);
        setAction({
            id: "setScale",
            meta: {
                page,
                image,
                onSet: () => {
                    setActiveComponent("");
                },
            },
        });
        setActiveTool("");
    }, [
        activeComponent,
        deselectShape,
        image,
        stage,
        setAction,
        setActiveComponent,
        setActiveImage,
        setActiveTool,
        page,
    ]);

    const activateComponent = useCallback(
        ({ id }) => {
            const shape = stage.current.findOne(`#${id}`);
            const transformer = newTransformer(id);

            if (activeComponent) {
                deselectShape(activeComponent);
            }

            // Create transformer
            transformer.nodes([shape]);
            layer.current.add(transformer);

            // Active shape
            shape.setAttr("draggable", true);
            // shape.moveToTop();
            // transformer.moveToTop();

            setActiveComponent(id);
            setActiveImage(image);
            setAction({
                id: "componentActions",
                meta: {
                    shape,
                    transformer,
                },
            });
            setActiveTool("");
        },
        [
            activeComponent,
            deselectShape,
            image,
            layer,
            setAction,
            stage,
            setActiveComponent,
            setActiveImage,
            setActiveTool,
        ]
    );

    const activatePolygon = useCallback(
        ({ id }) => {
            const shape = stage.current.findOne(`#${id}`);
            const anchors = attachAnchors(id, shape, image, stage.current);

            if (activeComponent) {
                deselectShape(activeComponent);
            }

            anchors.map((anchor) => shape.getParent().add(anchor));
            // shape.getParent().moveToTop();
            shape.getParent().setAttr("draggable", true);

            setActiveComponent(id);
            setActiveImage(image);
            setAction({
                id: "polygonFields",
                meta: {
                    shape: shape.getParent(),
                },
            });
            setActiveTool("");
        },
        [activeComponent, deselectShape, image, setAction, stage, setActiveComponent, setActiveImage, setActiveTool]
    );

    const activateLine = useCallback(
        ({ id }) => {
            const shape = stage.current.findOne(`#${id}`);
            const anchors = attachAnchors(id, shape, image, stage.current);

            if (activeComponent) {
                deselectShape(activeComponent);
            }

            anchors.map((anchor) => shape.getParent().add(anchor));
            shape.getParent().moveToTop();
            shape.getParent().setAttr("draggable", true);

            setActiveComponent(id);
            setActiveImage(image);
            setAction({
                id: "lineActions",
                meta: {
                    shape: shape.getParent(),
                },
            });
            setActiveTool("");
        },
        [activeComponent, deselectShape, image, setAction, stage, setActiveComponent, setActiveImage, setActiveTool]
    );

    return (
        <li className={`${classes.folder} ${open && classes.open} ${isActive && classes.active}`}>
            <span
                className={classes.header}
                onClick={() => {
                    const page = stage.current.findOne(`#${image}`);
                    const newX = page.x() * -1 + 10;
                    const newY = page.y() - 25;

                    updateStage({ coords: { x: newX, y: newY } });

                    if (activeComponent) {
                        deselectShape(activeComponent);
                    }
                    setActiveImage(image);
                    setActiveComponent("");
                    setAction({});
                }}
            >
                <span
                    className={classes.arrow}
                    onClick={(event) => {
                        event.stopPropagation();
                        toggleOpen(!open);
                    }}
                >
                    <Arrow />
                </span>
                <span className={classes.folder}>
                    <Folder />
                </span>
                <span className={classes.label}>Page {pageNum}</span>
            </span>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <ul className={classes.componentList}>
                    {!isEmpty(scale) && (
                        <li
                            className={`
                                    ${activeComponent === `measure_${image}` && classes.active}
                                `}
                            onClick={activateScale}
                        >
                            <span className={`${classes.componentIcon} ${classes.scaleIcon}`}>
                                <Line />
                            </span>
                            <span className={`${classes.componentLabel} ${classes.scaleLabel}`}>Scale</span>
                        </li>
                    )}
                    {Object.values(components).map(({ type, id, componentName = "" }, key) => {
                        const Icon = componentIcon(type);

                        return (
                            <li
                                key={`${image}_${key}`}
                                className={`
                                        ${activeComponent === id && classes.active}
                                    `}
                                onClick={() => activateComponent({ id })}
                            >
                                <span className={classes.componentIcon}>
                                    <Icon />
                                </span>
                                <span
                                    className={classes.componentLabel}
                                    data-for={`componentLabel-${id}`}
                                    data-html
                                    data-tip={`<span style="display:block;max-width:12rem;">${
                                        componentName || capitalize(type)
                                    }</span>`}
                                >
                                    {componentName || capitalize(type)}
                                </span>
                                <Tooltip id={`componentLabel-${id}`} delay />
                            </li>
                        );
                    })}
                    {Object.values(polygons).map(({ id, polygonName = "Polygon" }, key) => {
                        const Icon = componentIcon("polygon");

                        return (
                            <li
                                key={`${image}_${key}`}
                                className={`
                                        ${activeComponent === id && classes.active}
                                    `}
                                onClick={() => activatePolygon({ id })}
                            >
                                <span className={classes.componentIcon}>
                                    <Icon />
                                </span>
                                <span
                                    className={classes.componentLabel}
                                    data-for={`componentLabel-${id}`}
                                    data-html
                                    data-tip={`<span style="display:block;max-width:12rem;">${polygonName}</span>`}
                                >
                                    {polygonName}
                                </span>
                                <Tooltip id={`componentLabel-${id}`} delay />
                            </li>
                        );
                    })}
                    {Object.values(lines).map(({ id, lineName = "Line" }) => {
                        const Icon = componentIcon("line");

                        return (
                            <li
                                key={`${image}_${id}`}
                                className={`
                                        ${activeComponent === id && classes.active}
                                    `}
                                onClick={() => activateLine({ id })}
                            >
                                <span className={classes.componentIcon}>
                                    <Icon />
                                </span>
                                <span
                                    className={classes.componentLabel}
                                    data-for={`componentLabel-${id}`}
                                    data-html
                                    data-tip={`<span style="display:block;max-width:12rem;">${lineName}</span>`}
                                >
                                    {lineName}
                                </span>
                                <Tooltip id={`componentLabel-${id}`} delay />
                            </li>
                        );
                    })}
                </ul>
            </Collapse>
        </li>
    );
};

const Components = ({
    imageData,
    setActiveImage,
    setActiveComponent,
    activeImage,
    activeComponent,
    setAction,
    setActiveTool,
    stage,
    layer,
    processingImages,
    updateStage,
}) => {
    return (
        <div className={classes.components}>
            <div className={classes.heading}>
                <h3>Elements</h3>
            </div>
            <ul className={classes.folders}>
                {!processingImages &&
                    Object.keys(imageData)
                        .sort((a, b) => {
                            if (a.toLowerCase() < b.toLowerCase()) {
                                return -1;
                            }
                            if (a.toLowerCase() > b.toLowerCase()) {
                                return 1;
                            }
                            return 0;
                        })
                        .filter((image) => {
                            const { scale = {} } = imageData[image] || {};
                            return !isEmpty(scale);
                        })
                        .map((image) => {
                            const { scale = {}, components = {}, polygons = {}, lines = {} } = imageData[image] || {};
                            return (
                                <ImageFolder
                                    key={`folder_${image}`}
                                    scale={scale}
                                    components={components}
                                    polygons={polygons}
                                    lines={lines}
                                    image={image}
                                    setActiveImage={setActiveImage}
                                    setActiveComponent={setActiveComponent}
                                    activeImage={activeImage}
                                    setActiveTool={setActiveTool}
                                    activeComponent={activeComponent}
                                    setAction={setAction}
                                    stage={stage}
                                    layer={layer}
                                    updateStage={updateStage}
                                />
                            );
                        })}
            </ul>
        </div>
    );
};

export default Components;
