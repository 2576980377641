import React, { useEffect, useRef, useCallback } from "react";
import globalStyles from "components/globalStyles.module.scss";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import Select from "components/Input/Select";
import MultiSelect from "components/Input/MultiSelect";
import Checkbox from "components/Input/Checkbox";
import { Field } from "redux-form";
import Button from "components/Button";
import { getOptions, getFirstOption, getDefaultOption } from "utils/fields";
import { required, maxLength, getValidation } from "utils/fieldValidation";
import Tooltip from "components/Tooltip";
import { getAllComponents } from "utils/enclosure/components";

const charLim32 = maxLength(32);

const labelValidation = [required, charLim32];
const interiorValidation = getValidation("csCeilingInterior");
const structureValidation = getValidation("csCeilingStructureType");
const typeSizeValidation = getValidation("csCeilingComponentTypeSize");
const flatTypeSizeValidation = getValidation("csCeilingFlatComponentTypeSize");
const framingSpacingValidation = getValidation("csCeilingFramingSpacing");
const ins1Validation = getValidation("csCeilingInsLayer1");
const flatIns1Validation = getValidation("csCeilingFlatInsLayer1");
const ins2Validation = getValidation("csCeilingInsLayer2");

const codeSort = (a, b) => {
    if (a.label < b.label) {
        return -1;
    }
    if (a.label > b.label) {
        return 1;
    }
    return 0;
};

export default React.memo(
    ({
        toggleOpen,
        ceilingLabel,
        ceilingStructureId,
        isSolid,
        isPanel,
        isFlat,
        makeSpacingDefault,
        makeInsulationDefault,
        ceilingValue,
        submitting,
        handleSubmit,
        change,
        invalid,
        isEditing,
        isLibCode,
        clearInitCode,
        ceilingSpacingId,
        isCodeLibrary = false,
        isUpgradeComponent = false,
        components,
        fieldAccessor,
    }) => {
        const valueRef = useRef(null);

        const handleStuctureChange = useCallback(
            ({ id, value }) => {
                if (isFlat) {
                    change("ceilingCode.layers.insulationLayer1", getDefaultOption("csCeilingFlatInsLayer1"));
                    change(
                        "ceilingCode.layers.componentTypeSize",
                        getFirstOption({ indKey: id, depKey: "csCeilingFlatComponentTypeSize" })
                    );
                } else {
                    change("ceilingCode.layers.insulationLayer1", getDefaultOption("csCeilingInsLayer1"));
                    change(
                        "ceilingCode.layers.componentTypeSize",
                        getFirstOption({ indKey: id, depKey: "csCeilingComponentTypeSize" })
                    );
                }

                // Use "Framing" when set to Solid, use Spacing otherwise
                if (parseFloat(id) === 4) {
                    //set spacing to null and grab first option for framing
                    change("ceilingCode.layers.spacing", { id: null });
                    change(
                        "ceilingCode.layers.framing",
                        getFirstOption({
                            indKey: id,
                            depKey: "csCeilingFramingSpacing",
                        })
                    );
                } else {
                    //set framing to null and grab first option for spacing
                    change("ceilingCode.layers.framing", { id: null });

                    // Only grab default spacing id if not set
                    if (ceilingSpacingId === null) {
                        change("ceilingCode.layers.spacing", getDefaultOption("csCeilingFramingSpacing"));
                    }
                }

                // Panel
                if (parseFloat(id) === 5) {
                    change(
                        "ceilingCode.layers.insulationLayer1",
                        getDefaultOption(isFlat ? "csCeilingFlatInsLayer1" : "csCeilingInsLayer1")
                    );
                    change("ceilingCode.layers.insulationLayer2", getDefaultOption("csCeilingInsLayer2"));
                }
            },
            [ceilingStructureId, change, ceilingSpacingId]
        );

        useEffect(() => {
            valueRef.current = ceilingValue;
        }, [ceilingValue, ceilingLabel]);

        let availableComponents = getAllComponents(isFlat ? "ceiling-flat" : "ceiling", components, fieldAccessor).sort(
            codeSort
        );

        return (
            <div className={globalStyles.standardCodeWrapper}>
                <h3>{`Standard ${isFlat ? "Flat Ceiling" : "Ceiling"} Code`}</h3>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Input}
                        type="text"
                        name={`ceilingCode.label`}
                        label="Code Label"
                        placeholder="Code Label"
                        validate={labelValidation}
                        setValue={ceilingLabel.toString() === valueRef.current ? ceilingValue : null}
                    />
                    <Field
                        component={Input}
                        type="text"
                        name={`ceilingCode.value`}
                        label="Internal Code"
                        placeholder="###########"
                        disabled={true}
                        setValue={ceilingValue}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Select}
                        name={`ceilingCode.layers.structureType`}
                        label="Structure Type"
                        placeholder="Choose Structure Type"
                        options={getOptions({ fieldKey: "csCeilingStructureType" })}
                        validate={structureValidation}
                        onChange={handleStuctureChange}
                    />
                    <Field
                        component={Select}
                        name={`ceilingCode.layers.interior`}
                        label="Interior"
                        placeholder="Choose Interior"
                        options={getOptions({ fieldKey: "csCeilingInterior" })}
                        validate={interiorValidation}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Select}
                        name={`ceilingCode.layers.componentTypeSize`}
                        label="Component Type / Size"
                        placeholder="Choose Type / Size"
                        options={getOptions({
                            fieldKey: isFlat ? "csCeilingFlatComponentTypeSize" : "csCeilingComponentTypeSize",
                            indKey: ceilingStructureId,
                        })}
                        validate={isFlat ? flatTypeSizeValidation : typeSizeValidation}
                        onChange={({ value = "" }) => {
                            if (["6", "7", "8", "9", "A", "B", "C"].includes(value)) {
                                change(
                                    "ceilingCode.layers.insulationLayer1",
                                    getDefaultOption(isFlat ? "csCeilingFlatInsLayer1" : "csCeilingInsLayer1")
                                );
                                change("ceilingCode.layers.insulationLayer2", getDefaultOption("csCeilingInsLayer2"));
                            }
                            if (["5", "6", "7", "8", "9", "A", "B", "C"].includes(value)) {
                                change("ceilingCode.layers.framing", { id: 0, value: "0" });
                            }
                        }}
                    />
                    <div />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    {isSolid ? (
                        <Field
                            component={Select}
                            name={`ceilingCode.layers.framing`}
                            label="Framing"
                            placeholder="Choose Framing"
                            options={getOptions({ fieldKey: "csCeilingFramingSpacing", indKey: ceilingStructureId })}
                            validate={framingSpacingValidation}
                            disabled={makeSpacingDefault}
                        />
                    ) : (
                        <Field
                            component={Select}
                            name={`ceilingCode.layers.spacing`}
                            label="Spacing"
                            placeholder="Choose Spacing"
                            options={getOptions({ fieldKey: "csCeilingFramingSpacing", indKey: ceilingStructureId })}
                            validate={framingSpacingValidation}
                            disabled={isPanel}
                        />
                    )}
                    <div />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Select}
                        name={`ceilingCode.layers.insulationLayer1`}
                        label="Framing Cavity Insulation (Layer 1)"
                        placeholder="Choose Insulation"
                        options={getOptions({ fieldKey: isFlat ? "csCeilingFlatInsLayer1" : "csCeilingInsLayer1" })}
                        validate={isFlat ? flatIns1Validation : ins1Validation}
                        disabled={isPanel || makeInsulationDefault}
                    />
                    <div />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Select}
                        name={`ceilingCode.layers.insulationLayer2`}
                        label="Continuous Layer of Insulation (Layer 2)"
                        placeholder="Choose Insulation"
                        options={getOptions({ fieldKey: "csCeilingInsLayer2" })}
                        validate={ins2Validation}
                        disabled={isPanel || makeInsulationDefault}
                    />
                </InputRow>
                <div style={{ borderBottom: "1px solid #e1eaf0", marginTop: "1.5rem", marginBottom: "1.5rem" }}></div>
                {!isCodeLibrary && !isUpgradeComponent && (
                    <InputRow gridTemplate="1fr">
                        <Field
                            component={MultiSelect}
                            name={`selectedComponents`}
                            label={`Apply to multiple ${isFlat ? "flat " : " "}ceiling components`}
                            options={availableComponents.map(({ disabled = false, label = "", ...rest }) => ({
                                ...rest,
                                disabled,
                                label: disabled ? `${label} (current component)` : label,
                            }))}
                            hasSelectAll
                        />
                    </InputRow>
                )}
                {!isCodeLibrary && (
                    <InputRow gridTemplate="1fr 1fr">
                        <div data-tip="Code will automatically be updated in library" data-for="codeLibTip">
                            <Field
                                component={Checkbox}
                                name={"addToLibrary"}
                                label={isLibCode ? "Update code in library" : "Add to code library"}
                                large
                                disabled={isLibCode}
                                type="checkbox"
                            />
                        </div>
                        <Field
                            component={Checkbox}
                            name={"setAsModelDefault"}
                            label={"Set as model default"}
                            large
                            type="checkbox"
                        />
                    </InputRow>
                )}
                <div className={globalStyles.buttons}>
                    <Button
                        large
                        type="hollow"
                        onClick={() => {
                            clearInitCode();
                            toggleOpen(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button large onClick={handleSubmit} disabled={submitting || invalid}>
                        {submitting ? "Saving Code..." : "Save Code"}
                    </Button>
                </div>
                <div style={{ paddingTop: "7.5rem" }}></div>
                <Tooltip id="codeLibTip" hide={!isLibCode} />
            </div>
        );
    }
);
