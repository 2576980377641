import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { active, componentIcon, componentLabel, scaleIcon, scaleLabel } from "../style.module.scss";

import Ruler from "assets/images/components/JSX/Ruler";
import Rectangle from "assets/images/components/JSX/Rectangle";
import Line from "assets/images/components/JSX/Line";
import MultiLine from "assets/images/components/JSX/MultiLine";
import Polygon from "assets/images/components/JSX/Polygon";

const selectComponentIcon = (icon) =>
    ({
        scale: Ruler,
        rectangle: Rectangle,
        line: Line,
        multiPointLine: MultiLine,
        polygon: Polygon,
    }[icon]);

const Component = ({ currentImage, activeComponent, componentId, name, icon, onClick, id }) => {
    const CurrentComponentIcon = selectComponentIcon(icon);

    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id,
        transition: {
            duration: 1, // milliseconds
            easing: "cubic-bezier(0.25, 1, 0.5, 1)",
        },
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <li
            className={`
            ${
                name.toLowerCase() === "scale"
                    ? activeComponent === `measure_${currentImage}` && active
                    : activeComponent === componentId && active
            }
        `}
            onClick={onClick}
            ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners}
        >
            <span className={`${componentIcon} ${name.toLowerCase() === "scale" && scaleIcon}`}>
                <CurrentComponentIcon />
            </span>
            <span className={`${componentLabel} ${name.toLowerCase() === "scale" && scaleLabel}`}>{name}</span>
        </li>
    );
};

export default Component;
