import { connect } from "react-redux";
import { matchPath } from "react-router-dom";
import isEqual from "lodash/isEqual";

import { actions as modelActions } from "features/Model/_ducks";
import { actions as userActions } from "store/users";
import { fetchDrawingData } from "./DrawingNew/_ducks/thunk";
import { resetImageGallety } from "store/imageGallery/actions";

import Model from "./";
import { reduxForm } from "redux-form";

const { fetchModelData, modelLoading, clearModelData, checkModelUpdates } = modelActions;
const { fetchUserCodeLib } = userActions;

const mapStateToProps = (
    {
        user: {
            uid,
            userPermissions: { CHBA_NZ_ADMIN = false, CHBA_NZEA = false, CHBA_NZ_ASSIST = false } = {},
            codeLib = {},
        } = {},
        model = {},
        modelDir = {},
        drawing = {},
        results = {},
        upgrades = {},
    },
    { history }
) => {
    const {
        modelId,
        loading,
        modelData,
        modelTakeoff,
        lastSnapshot,
        modelDetailsToCompare,
        isSnapshotUpdating,
        modelDataToCompare,
        modelTakeoffToCompare,
        modelDetails,
        isSharingModel,
    } = model;
    //
    const { simulationRunning } = modelDetails;
    const { loading: isResultsLoading = false } = results;
    const { loading: isUpgradesLoading = false, initPackages = {} } = upgrades;

    const chbaFileTagged = model?.modelData?.chbaNz?.file?.targetLabel?.id != null;

    const snapshotModelData = lastSnapshot.modelData || {};
    const snapshotModelDetails = lastSnapshot.modelDetails || {};
    const snapshotTakeoff = lastSnapshot.modelTakeoff || {};
    const upgradesSnapshot = upgrades.initPackagesSnapshot || {};
    const { imageDataToCompare = {}, imageDataSnapshot = {}, saving: isDrawingSaving = false } = drawing || {};

    return {
        model,
        modelId,
        loading,
        history,
        uid,
        codeLib,
        modelTakeoff,
        modelData,
        modelDir,
        storeysId: modelData?.specifications?.numStoreys?.id,
        CHBA_NZ_ADMIN,
        CHBA_NZEA,
        CHBA_NZ_ASSIST,
        chbaFileTagged,
        isNewSnapshot:
            !isEqual(
                { ...snapshotModelDetails, lastEdited: {}, lastCliRun: {}, writePermission: [] },
                { ...modelDetailsToCompare, lastEdited: {}, lastCliRun: {}, writePermission: [] }
            ) ||
            !isEqual(snapshotModelData, modelDataToCompare) ||
            !isEqual(modelTakeoffToCompare, snapshotTakeoff) ||
            !isEqual(imageDataToCompare, imageDataSnapshot) ||
            !isEqual(initPackages, upgradesSnapshot),
        isSnapshotUpdating,
        simulationRunning,
        isResultsLoading,
        isUpgradesLoading,
        isDrawingSaving,
        isSharingModel,
    };
};

const mapDispatchToProps = (
    dispatch,
    {
        history: {
            location: { pathname },
        },
    }
) => ({
    fetchModelData: async () => {
        const { params: { modelId } = {} } =
            matchPath(pathname, {
                path: "/models/:modelId",
            }) || {};

        if (modelId) {
            await dispatch(modelLoading());
            await dispatch(fetchDrawingData(modelId));
            await dispatch(checkModelUpdates(modelId));
            return dispatch(fetchModelData(modelId));
        }
    },
    fetchUserCodeLib: (uid) => dispatch(fetchUserCodeLib(uid)),
    clearModelData: () => dispatch(clearModelData()),
    resetImageGallety: () => dispatch(resetImageGallety()),
});

const form = reduxForm({
    form: "model",
    enableReinitialize: true,
})(Model);

export default connect(mapStateToProps, mapDispatchToProps)(form);
