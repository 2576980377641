import React, { useEffect, useState } from "react";
import classes from "../style.module.scss";
import { Community, Folder, Model } from "../Item";
import { getProvCode } from "utils/weather";

import {
    Table,
    TableRow,
    TablePagination,
    TableFooter,
} from "@material-ui/core";

const DashboardTableBody = ({
    isRenoComm,
    columns,
    folderItemRef,
    allFoldersItems = [],
    dragAndDrop: {
        draggable = false,
        onDragStart = () => {},
        onDragEnd = () => {},
        onDragUpdate = () => {},
        dropTo = false, 
        setDropTo = () => {},
        isDragging = false,
        isDropInProcess = false,
    } = {},
    selection: {
        onClick = () => {},
        isSelected = () => {},
        multiSelectedItems = [],
    } = {},
    actions: {
        startFolderDelete = () => {},
        setFolderToMove = () => {},
        setFolderToRename = () => {},
        startItemDelete = () => {},
        setItemToDuplicate = () => {},
        setDuplicateName = () => {},
        setItemToMove = () => {},
        setFolderItemsToDelete = () => {},
    } = {},
    paginate = false,
    defaultRowsPerPage = 10,
    rowsPerPageOptions = [10, 25, 50, 100],
}) => {
    const className = isRenoComm ? classes['renoComm'] : classes[`col${Object.keys(columns).length}`];
    
    const { name: nameLabel, actions: actionsLabel, ...detailLabels } = columns;

    const getColumnData = (details) => {
        const data = {};
        Object.keys(detailLabels).forEach((key) => {
            if (Object.keys(details).includes(key))
                if (key === "lastEdited" &&  typeof(details[key] === 'object'))
                    data[key] = details[key]?.datetime;
                else if (key === "provTerr")
                    data[key] = getProvCode(details[key]);
                else
                    data[key] = details[key];
            else if (key === "region")
                data[key] = "CAN";
            else
                data[key] = "";
        })        
        return data;
        
    }

    //Pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

    useEffect(() => {
        setPage(0);
    }, [allFoldersItems]);
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const paginatedRows =
        paginate && rowsPerPage > 0
            ? allFoldersItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : allFoldersItems;

    return (
        <React.Fragment>
            <ul style={{ userSelect: "none" }} ref={folderItemRef} className={`${classes.tableBody} ${className}`}>
                {paginatedRows.map(
                    ([key, { name, sharedWithUser = false, type, disableDelete = false, tags, ...details }], index) => { 
                        return (
                            <div
                                key={key}
                                className={classes.draggableItem}
                                draggable={draggable}
                                onDragStart={(event) => onDragStart(event, key, name, type)}
                                onDragOver={(event) => {
                                    event.stopPropagation();
                                    onDragUpdate(event, key, type);
                                }}
                                onDragEnd={(event) => onDragEnd(event)}
                                onDragLeave={(event) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    setDropTo("");
                                }}
                                onClick={(event) => onClick(event, type, key, index)}
                            >
                                {type === "folder" &&
                                    <Folder
                                        key={key}
                                        folderId={key}
                                        name={name}
                                        sharedWithUser={sharedWithUser}
                                        startDelete={startFolderDelete}
                                        // pathname={pathname === "/" ? "/dashboard" : pathname}
                                        setFolderToRename={() => setFolderToRename(key)}
                                        setFolderToMove={(folderId) => setFolderToMove(folderId)}
                                        tags={tags}
                                        isSelected={isSelected(key)}
                                        isDragging={isDragging}
                                        combineWith={dropTo === key && !isSelected(key)}
                                        isDropInProcess={isDropInProcess && isSelected(key)}
                                        setFolderItemsToDelete={setFolderItemsToDelete}
                                        multiSelectedItems={multiSelectedItems}
                                        disableActions={disableDelete || !actionsLabel}
                                        details={getColumnData(details)}
                                    />
                                }
                                {type === "community" &&
                                    <Community
                                        key={key}
                                        communityId={key}
                                        name={name}
                                        startDelete={startItemDelete}
                                        // tags={tags}
                                        setCommunityToMove={(communityId) => setItemToMove(communityId)}
                                        setCommunityToDuplicate={(communityId) => setItemToDuplicate(communityId)}
                                        setDuplicateName={(name) => setDuplicateName(name)}
                                        sharedWithUser={sharedWithUser}
                                        isSelected={isSelected(key)}
                                        isDragging={isDragging}
                                        isDropInProcess={isDropInProcess && isSelected(key)}
                                        setFolderItemsToDelete={setFolderItemsToDelete}
                                        multiSelectedItems={multiSelectedItems}
                                        disableActions={sharedWithUser || !actionsLabel}
                                        details={getColumnData(details)}
                                    />
                                }
                                {type === "model" &&
                                    <Model
                                        key={key}
                                        modelId={key}
                                        name={name}
                                        startDelete={startItemDelete}
                                        tags={tags}
                                        setModelToMove={(modelId) => setItemToMove(modelId)}
                                        setModelToDuplicate={(modelId) => setItemToDuplicate(modelId)}
                                        setDuplicateName={(name) => setDuplicateName(name)}
                                        sharedWithUser={sharedWithUser}
                                        isRenoCommModel={isRenoComm}
                                        isSelected={isSelected(key)}
                                        isDragging={isDragging}
                                        isDropInProcess={isDropInProcess && isSelected(key)}
                                        multiSelectedItems={multiSelectedItems}
                                        setFolderItemsToDelete={setFolderItemsToDelete}
                                        disableActions={sharedWithUser || !actionsLabel}
                                        details={getColumnData(details)}
                                    />
                                }
                            </div>
                        )
                    }
                )}
            </ul>
            {paginate && allFoldersItems.length > rowsPerPage && (
                <Table>
                    <TableFooter style={{backgroundColor: "white"}}>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={rowsPerPageOptions}
                                count={allFoldersItems.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                SelectProps={{
                                    inputProps: {
                                        "aria-label": "rows per page",
                                    },
                                }}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            )}
        </React.Fragment>
    );
}

export default DashboardTableBody;