import React from "react";

import { CloseDialog } from "components/Dialog";
import Button from "components/Button";
import Dialog from "components/Dialog";
import Input from "components/Input";

import classes from "../style.module.scss";
import { isEmpty } from "lodash";

const ExportSummaryDialog = ({
    toRename,
    close,
    newFolderName,
    setNewFolderName,
    onClick,
    folderLoading,

    modelIds = [],
    exportLoading,
    setExportLoading = () => {},
}) => (
    <Dialog open={!isEmpty(modelIds)}>
        <CloseDialog closeActions={() => close()} />
        <div className={classes.dialog}>
            <h3>Export a csv summarizing the results of the {modelIds.length} models in this folder?</h3>
            <div className={classes.footer}>
                <Button type="hollow" onClick={() => close()}>
                    Cancel
                </Button>
                <Button onClick={onClick} type="" disabled={exportLoading}>
                    {exportLoading ? "Exporting..." : "Export"}
                </Button>
            </div>
        </div>
    </Dialog>
);

export default ExportSummaryDialog;
