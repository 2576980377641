import React from 'react';
import classes from './style.module.scss';
import sharedClasses from 'features/Model/sharedStyles.module.scss';
import { Field } from 'redux-form';
import { getOptions, getBaseUnits, getUnitOptions } from 'utils/fields';
import { maxLength, getValidation, getDecimalPlaces } from 'utils/fieldValidation';
import InputRow from 'components/Input/Row';
import Input from 'components/Input';
import InputWithUnits from 'components/Input/InputWithUnits';
import Select from 'components/Input/Select';
import Checkbox from 'components/Input/Checkbox';
import Accordion from 'components/Accordion';

const charLim100 = maxLength(100);
const wgshpFunctionValidation = getValidation('wshpFunction');
const wgshpCapacityValidation = getValidation('wshpOutputCapacity');
const wgshpHeatingEffValidation = getValidation('wshpHeatingEff');
const wgshpCoolingEffValidation = getValidation('wshpCoolingEff');
const wgshpCrankcaseHeaterValidation = getValidation('wshpCrankcaseHeater');
const sensibleHeatRatioValidation = getValidation('wshpSensibleHeatRatio');
const openableWindowAreaValidation = getValidation('wshpOpenableWindowArea');
const wgshpTempCutoffValidation = getValidation('wshpTempCutoff');
const wgshpTempRatingValidation = getValidation('wshpTempRating');
const wgshpDepthValidation = getValidation('wshpSourceDepth');
const wgshpSourceTempTypeValidation = getValidation('wshpSourceTempType');
const wgshpSourceTempMonthValidation = getValidation('wshpSourceTempMonth');
const ahriValidation = getValidation('ahriNumber');

const sysLabel = type => ({
    'waterHeatPump': 'Water',
    'groundHeatPump': 'Ground'
}[type])

export default ({
    accessor,
    currentPrimarySystem,
    functionId,
    capacityId,
    capacityValue,
    capacityUnits,
    crankcaseHeaterUnits,
    openableWindowAreaUnits,
    tempCutoffId,
    tempCutoffValue,
    tempCutoffUnits,
    tempRatingId,
    tempRatingValue,
    tempRatingUnits,
    depthUnits,
    modelUnits,
    sourceTempInputId,
    areSourceTempsEmpty,
    janSourceTempUnits,
    febSourceTempUnits,
    marSourceTempUnits,
    aprSourceTempUnits,
    maySourceTempUnits,
    junSourceTempUnits,
    julSourceTempUnits,
    augSourceTempUnits,
    sepSourceTempUnits,
    octSourceTempUnits,
    novSourceTempUnits,
    decSourceTempUnits,
    change,
    h2kMinor,
    contentOpen,
}) => {
    return (
        <>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Field
                    component={Input}
                    name={`${accessor}.system.${currentPrimarySystem}.manufacturer`}
                    type="text"
                    label="Manufacturer Name"
                    placeholder="Enter Manufacturer Name"
                    validate={charLim100}
                />
                <Field
                    component={Input}
                    name={`${accessor}.system.${currentPrimarySystem}.model`}
                    type="text"
                    label="Model Name"
                    placeholder="Enter Model Name"
                    validate={charLim100}
                />
                {
                    h2kMinor >= 11 && 
                    <Field
                        component={Input}
                        type="number"
                        name={`${accessor}.system.${currentPrimarySystem}.ahri`}
                        label="AHRI Reference Number"
                        placeholder="0"
                        validate={ahriValidation}
                        decimals={getDecimalPlaces(`ahriNumber`)}
                    />
                }
                <Field
                    component={Checkbox}
                    name={`${accessor}.system.${currentPrimarySystem}.canCsaC448`}
                    label="CAN/CSA-C448"
                    large
                    className={classes.checkbox}
                    type="checkbox"
                />
            </InputRow>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Field
                    component={Select}
                    name={`${accessor}.system.${currentPrimarySystem}.function`}
                    options={getOptions({ fieldKey: 'wshpFunction' })}
                    label="Unit Function"
                    placeholder="Choose Unit Function"
                    validate={wgshpFunctionValidation}
                />
            </InputRow>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr 1fr">
                <Field
                    component={Select}
                    name={`${accessor}.system.${currentPrimarySystem}.specifications.outputCapacity`}
                    options={[
                        {
                            label:"Calculated",
                            value:{
                                id:0,
                                value:capacityValue,
                                uiUnits: 'kW'
                            }
                        },
                        {
                            label:"User Specified",
                            value:{
                                id:1,
                                value:capacityValue,
                                uiUnits: 'kW'
                            }
                        },
                        {
                            label:"70% of heating load",
                            value:{
                                id:2,
                                value:capacityValue,
                                uiUnits: 'kW'
                            }
                        },
                    ]}
                    label="Capacity Type"
                    placeholder="Choose Input Type"
                    validate={wgshpCapacityValidation}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.system.${currentPrimarySystem}.specifications.outputCapacity.value`}
                    label="Output Capacity"
                    placeholder="0.00"
                    validate={wgshpCapacityValidation}
                    decimals={getDecimalPlaces(`wshpOutputCapacity`)}
                    change={change}
                    disabled={capacityId !== 1}
                    units={{
                        base:getBaseUnits(`wshpOutputCapacity`, modelUnits),
                        options:getUnitOptions('power'),
                        selected:capacityUnits,
                        unitType:'power',
                        accessor:`${accessor}.system.${currentPrimarySystem}.specifications.outputCapacity_u`
                    }}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.system.${currentPrimarySystem}.crankcaseHeater`}
                    label="Crankcase Heater"
                    placeholder="0.00"
                    validate={wgshpCrankcaseHeaterValidation}
                    decimals={getDecimalPlaces(`wshpCrankcaseHeater`)}
                    change={change}
                    disabled={capacityId !== 1}
                    units={{
                        base:getBaseUnits(`wshpCrankcaseHeater`, modelUnits),
                        options:getUnitOptions('power'),
                        selected:crankcaseHeaterUnits,
                        unitType:'power',
                        accessor:`${accessor}.system.${currentPrimarySystem}.crankcaseHeater_u`
                    }}
                />
                <Field
                    component={Input}
                    type="number"
                    name={`${accessor}.system.${currentPrimarySystem}.specifications.coolingParams.sensibleHeatRatio`}
                    label="Sensible Heat Ratio"
                    placeholder="0.00"
                    validate={sensibleHeatRatioValidation}
                    decimals={getDecimalPlaces(`wshpSensibleHeatRatio`)}
                    disabled={functionId === 0}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.system.${currentPrimarySystem}.specifications.coolingParams.openableWindowArea`}
                    label="Openable Window Area"
                    placeholder="0.00"
                    validate={openableWindowAreaValidation}
                    decimals={getDecimalPlaces(`wshpOpenableWindowArea`)}
                    change={change}
                    disabled={functionId === 0}
                    units={{
                        base:getBaseUnits(`wshpOpenableWindowArea`, modelUnits),
                        options:getUnitOptions('fraction'),
                        selected:openableWindowAreaUnits,
                        unitType:'fraction',
                        accessor:`${accessor}.system.${currentPrimarySystem}.specifications.coolingParams.openableWindowArea_u`
                    }}
                    info={'This field must be left at the default value (0%) for all EnerGuide files.'}
                />
            </InputRow>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Field
                    component={Input}
                    type="number"
                    name={`${accessor}.system.${currentPrimarySystem}.specifications.heatingEfficiency.value`}
                    label="Heating Efficiency"
                    placeholder="0.00"
                    validate={wgshpHeatingEffValidation}
                    decimals={getDecimalPlaces('wshpHeatingEff')}
                    info={'The HSPF for Region V should be used. If only the value for Region IV is known, divide that value by 1.15 and enter.'}
                />
                <Field
                    component={Select}
                    name={`${accessor}.system.${currentPrimarySystem}.specifications.heatingEfficiency.isCop`}
                    options={[
                        {
                            label:"COP",
                            value: true
                        },
                        {
                            label:"HSPF",
                            value: false
                        },
                    ]}
                    label="Heating Efficiency Type"
                    placeholder="Choose Efficiency Type"
                    info={'If both HSPF and COP are known, use HSPF.'}
                />
                <Field
                    component={Input}
                    type="number"
                    name={`${accessor}.system.${currentPrimarySystem}.specifications.coolingEfficiency.value`}
                    label="Cooling Efficiency"
                    placeholder="0.00"
                    validate={wgshpCoolingEffValidation}
                    decimals={getDecimalPlaces('wshpCoolingEff')}
                    disabled={functionId === 0}
                    info={'If the value is unknown, enter a value of 10 SEER.'}
                />
                <Field
                    component={Select}
                    name={`${accessor}.system.${currentPrimarySystem}.specifications.coolingEfficiency.isCop`}
                    options={[
                        {
                            label:"COP",
                            value: true
                        },
                        {
                            label:"SEER",
                            value: false
                        },
                    ]}
                    label="Cooling Efficiency Type"
                    placeholder="Choose Efficiency Type"
                    disabled={functionId === 0}
                />
            </InputRow>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Field
                    component={Select}
                    name={`${accessor}.system.${currentPrimarySystem}.temperature.cutoffType`}
                    options={[
                        {
                            label:"Balance point",
                            value:{
                                id:0,
                            }
                        },
                        {
                            label:"Restricted",
                            value:{
                                id:1,
                                value: tempCutoffValue,
                            }
                        },
                        {
                            label:"Unrestricted",
                            value:{
                                id:2,
                            }
                        }
                    ]}
                    label="Temperature Cutoff Type"
                    placeholder="Choose Cutoff Type"
                    validate={wgshpTempCutoffValidation}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.system.${currentPrimarySystem}.temperature.cutoffType.value`}
                    label="Cutoff Temperature"
                    placeholder="0.00"
                    validate={wgshpTempCutoffValidation}
                    decimals={getDecimalPlaces(`wshpTempCutoff`)}
                    change={change}
                    disabled={tempCutoffId !== 1}
                    units={{
                        base:getBaseUnits(`wshpTempCutoff`, modelUnits),
                        options:getUnitOptions('temperature'),
                        selected:tempCutoffUnits,
                        unitType:'temperature',
                        accessor:`${accessor}.system.${currentPrimarySystem}.temperature.cutoffType_u`
                    }}
                />
                <Field
                    component={Select}
                    name={`${accessor}.system.${currentPrimarySystem}.temperature.ratingType`}
                    options={[
                        ...getOptions({ fieldKey: 'wshpTempRating' }),
                        {
                            label:"User Specified",
                            value:{
                                id:2,
                                value:tempRatingValue
                            }
                        }
                    ]}
                    label="Temperature Rating Type"
                    placeholder="Choose Rating Type"
                    validate={wgshpTempRatingValidation}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.system.${currentPrimarySystem}.temperature.ratingType.value`}
                    label="Rating Temperature"
                    placeholder="0.00"
                    validate={wgshpTempRatingValidation}
                    decimals={getDecimalPlaces(`wshpTempRating`)}
                    change={change}
                    disabled={tempRatingId !== 2}
                    setValue={tempRatingValue}
                    units={{
                        base:getBaseUnits(`wshpTempRating`, modelUnits),
                        options:getUnitOptions('temperature'),
                        selected:tempRatingUnits,
                        unitType:'temperature',
                        accessor:`${accessor}.system.${currentPrimarySystem}.temperature.ratingType_u`
                    }}
                />
            </InputRow>
            <h5 style={{marginBottom:"1.25rem", marginTop:"1.75rem"}}>
                {`${sysLabel(currentPrimarySystem)} Temperature`}
            </h5>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.system.${currentPrimarySystem}.source.depth`}
                    label="Average Depth"
                    placeholder="0.00"
                    validate={wgshpDepthValidation}
                    decimals={getDecimalPlaces(`wshpSourceDepth`)}
                    change={change}
                    units={{
                        base:getBaseUnits(`wshpSourceDepth`, modelUnits),
                        options:getUnitOptions('length'),
                        selected:depthUnits,
                        unitType:'length',
                        accessor:`${accessor}.system.${currentPrimarySystem}.source.depth_u`
                    }}
                />
                <Field
                    component={Select}
                    name={`${accessor}.system.${currentPrimarySystem}.source.use`}
                    options={getOptions({ fieldKey: 'wshpSourceTempType' })}
                    label="Monthly Temperature Input Type"
                    placeholder="Choose Input Type"
                    validate={wgshpSourceTempTypeValidation}
                    onChange={
                        (value) => {
                            //Initialize if empty, but don't overwrite.
                            if (value.id === 0 && areSourceTempsEmpty) {
                                change(
                                    `${accessor}.system.${currentPrimarySystem}.source.temperatures`,
                                    {
                                        january: 6.7,
                                        february: 5.48,
                                        march: 5.69,
                                        april: 7.28,
                                        may: 9.82,
                                        june: 12.63,
                                        july: 14.96,
                                        august: 16.19,
                                        september: 15.98,
                                        october: 14.39,
                                        november: 11.85,
                                        december: 9.03,
                                    }
                                )
                            }
                        }
                    }
                />
            </InputRow>
            <Accordion
                className={sharedClasses.accordion}
                heading={
                    <span className={sharedClasses.heading}>{`Source Temperatures ${sourceTempInputId !== 0 ? '(Calculated)': ''}`}</span>
                }
                large
                forceOpen={contentOpen}
            >
                <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.system.${currentPrimarySystem}.source.temperatures.january`}
                        label="January"
                        placeholder="0.00"
                        validate={wgshpSourceTempMonthValidation}
                        decimals={getDecimalPlaces(`wshpSourceTempMonth`)}
                        change={change}
                        disabled={sourceTempInputId !== 0}
                        units={{
                            base:getBaseUnits(`wshpSourceTempMonth`, modelUnits),
                            options:getUnitOptions('temperature'),
                            selected:janSourceTempUnits,
                            unitType:'temperature',
                            accessor:`${accessor}.system.${currentPrimarySystem}.source.temperatures.january_u`
                        }}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.system.${currentPrimarySystem}.source.temperatures.february`}
                        label="February"
                        placeholder="0.00"
                        validate={wgshpSourceTempMonthValidation}
                        decimals={getDecimalPlaces(`wshpSourceTempMonth`)}
                        change={change}
                        disabled={sourceTempInputId !== 0}
                        units={{
                            base:getBaseUnits(`wshpSourceTempMonth`, modelUnits),
                            options:getUnitOptions('temperature'),
                            selected:febSourceTempUnits,
                            unitType:'temperature',
                            accessor:`${accessor}.system.${currentPrimarySystem}.source.temperatures.february_u`
                        }}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.system.${currentPrimarySystem}.source.temperatures.march`}
                        label="March"
                        placeholder="0.00"
                        validate={wgshpSourceTempMonthValidation}
                        decimals={getDecimalPlaces(`wshpSourceTempMonth`)}
                        change={change}
                        disabled={sourceTempInputId !== 0}
                        units={{
                            base:getBaseUnits(`wshpSourceTempMonth`, modelUnits),
                            options:getUnitOptions('temperature'),
                            selected:marSourceTempUnits,
                            unitType:'temperature',
                            accessor:`${accessor}.system.${currentPrimarySystem}.source.temperatures.march_u`
                        }}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.system.${currentPrimarySystem}.source.temperatures.april`}
                        label="April"
                        placeholder="0.00"
                        validate={wgshpSourceTempMonthValidation}
                        decimals={getDecimalPlaces(`wshpSourceTempMonth`)}
                        change={change}
                        disabled={sourceTempInputId !== 0}
                        units={{
                            base:getBaseUnits(`wshpSourceTempMonth`, modelUnits),
                            options:getUnitOptions('temperature'),
                            selected:aprSourceTempUnits,
                            unitType:'temperature',
                            accessor:`${accessor}.system.${currentPrimarySystem}.source.temperatures.april_u`
                        }}
                    />
                </InputRow> 
                <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.system.${currentPrimarySystem}.source.temperatures.may`}
                        label="May"
                        placeholder="0.00"
                        validate={wgshpSourceTempMonthValidation}
                        decimals={getDecimalPlaces(`wshpSourceTempMonth`)}
                        change={change}
                        disabled={sourceTempInputId !== 0}
                        units={{
                            base:getBaseUnits(`wshpSourceTempMonth`, modelUnits),
                            options:getUnitOptions('temperature'),
                            selected:maySourceTempUnits,
                            unitType:'temperature',
                            accessor:`${accessor}.system.${currentPrimarySystem}.source.temperatures.may_u`
                        }}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.system.${currentPrimarySystem}.source.temperatures.june`}
                        label="June"
                        placeholder="0.00"
                        validate={wgshpSourceTempMonthValidation}
                        decimals={getDecimalPlaces(`wshpSourceTempMonth`)}
                        change={change}
                        disabled={sourceTempInputId !== 0}
                        units={{
                            base:getBaseUnits(`wshpSourceTempMonth`, modelUnits),
                            options:getUnitOptions('temperature'),
                            selected:junSourceTempUnits,
                            unitType:'temperature',
                            accessor:`${accessor}.system.${currentPrimarySystem}.source.temperatures.june_u`
                        }}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.system.${currentPrimarySystem}.source.temperatures.july`}
                        label="July"
                        placeholder="0.00"
                        validate={wgshpSourceTempMonthValidation}
                        decimals={getDecimalPlaces(`wshpSourceTempMonth`)}
                        change={change}
                        disabled={sourceTempInputId !== 0}
                        units={{
                            base:getBaseUnits(`wshpSourceTempMonth`, modelUnits),
                            options:getUnitOptions('temperature'),
                            selected:julSourceTempUnits,
                            unitType:'temperature',
                            accessor:`${accessor}.system.${currentPrimarySystem}.source.temperatures.july_u`
                        }}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.system.${currentPrimarySystem}.source.temperatures.august`}
                        label="August"
                        placeholder="0.00"
                        validate={wgshpSourceTempMonthValidation}
                        decimals={getDecimalPlaces(`wshpSourceTempMonth`)}
                        change={change}
                        disabled={sourceTempInputId !== 0}
                        units={{
                            base:getBaseUnits(`wshpSourceTempMonth`, modelUnits),
                            options:getUnitOptions('temperature'),
                            selected:augSourceTempUnits,
                            unitType:'temperature',
                            accessor:`${accessor}.system.${currentPrimarySystem}.source.temperatures.august_u`
                        }}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.system.${currentPrimarySystem}.source.temperatures.september`}
                        label="September"
                        placeholder="0.00"
                        validate={wgshpSourceTempMonthValidation}
                        decimals={getDecimalPlaces(`wshpSourceTempMonth`)}
                        change={change}
                        disabled={sourceTempInputId !== 0}
                        units={{
                            base:getBaseUnits(`wshpSourceTempMonth`, modelUnits),
                            options:getUnitOptions('temperature'),
                            selected:sepSourceTempUnits,
                            unitType:'temperature',
                            accessor:`${accessor}.system.${currentPrimarySystem}.source.temperatures.september_u`
                        }}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.system.${currentPrimarySystem}.source.temperatures.october`}
                        label="October"
                        placeholder="0.00"
                        validate={wgshpSourceTempMonthValidation}
                        decimals={getDecimalPlaces(`wshpSourceTempMonth`)}
                        change={change}
                        disabled={sourceTempInputId !== 0}
                        units={{
                            base:getBaseUnits(`wshpSourceTempMonth`, modelUnits),
                            options:getUnitOptions('temperature'),
                            selected:octSourceTempUnits,
                            unitType:'temperature',
                            accessor:`${accessor}.system.${currentPrimarySystem}.source.temperatures.october_u`
                        }}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.system.${currentPrimarySystem}.source.temperatures.november`}
                        label="November"
                        placeholder="0.00"
                        validate={wgshpSourceTempMonthValidation}
                        decimals={getDecimalPlaces(`wshpSourceTempMonth`)}
                        change={change}
                        disabled={sourceTempInputId !== 0}
                        units={{
                            base:getBaseUnits(`wshpSourceTempMonth`, modelUnits),
                            options:getUnitOptions('temperature'),
                            selected:novSourceTempUnits,
                            unitType:'temperature',
                            accessor:`${accessor}.system.${currentPrimarySystem}.source.temperatures.november_u`
                        }}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.system.${currentPrimarySystem}.source.temperatures.december`}
                        label="December"
                        placeholder="0.00"
                        validate={wgshpSourceTempMonthValidation}
                        decimals={getDecimalPlaces(`wshpSourceTempMonth`)}
                        change={change}
                        disabled={sourceTempInputId !== 0}
                        units={{
                            base:getBaseUnits(`wshpSourceTempMonth`, modelUnits),
                            options:getUnitOptions('temperature'),
                            selected:decSourceTempUnits,
                            unitType:'temperature',
                            accessor:`${accessor}.system.${currentPrimarySystem}.source.temperatures.december_u`
                        }}
                    />
                </InputRow>
            </Accordion>
        </>
    )
};