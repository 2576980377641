import React from "react";

export default () => (
    <svg
        width="18px"
        height="18px"
        viewBox="0 0 18 18"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        fill="#C61717"
    >
        <path
            d="M9,0 C13.9705627,0 18,4.02943725 18,9 C18,13.9705627 13.9705627,18 9,18 C4.02943725,18 0,13.9705627 0,9 C0,4.02943725 4.02943725,0 9,0 Z M8.99180328,12.4209836 C8.13934426,12.4209836 7.5,13.1095082 7.5,13.9947541 C7.5,14.88 8.13934426,15.5685246 8.99180328,15.5685246 C9.8442623,15.5685246 10.5,14.88 10.5,13.9947541 C10.5,13.1095082 9.8442623,12.4209836 8.99180328,12.4209836 Z M10.204918,2.88 L7.77868852,2.88 L7.86065574,5.15868852 L8.20491803,11.2078689 L9.77868852,11.2078689 L10.1229508,5.15868852 L10.204918,2.88 Z"
            id="Oval-2"
        ></path>
    </svg>
);
