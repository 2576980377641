import React, { useEffect, useState, useRef, useCallback } from "react";
import classes from "./style.module.scss";
import Table from "components/Table";
import classNames from "classnames";
import LoadingDots from "components/LoadingDots";
import Exclamation from "assets/images/icons/JSX/Exlamation";
import convertUnit from "utils/convertUnit";

const descendingComparator = (a, b) => {
    if (!b && !a) {
        return 0;
    }

    const { value: av } = a || {};
    const { value: bv } = b || {};

    if (av === bv) {
        return 0;
    } else if (!av) {
        return 1;
    } else if (!bv) {
        return -1;
    }

    return bv < av ? -1 : 1;
};

const getHeadCells = (compType, displayUnits) => {
    if (compType === "window") {
        return [
            {
                id: "label",
                label: "Label",
                align: "left",
                width: "240",
            },
            {
                id: "netArea",
                label: `Net Area ${displayUnits === "Metric" ? "(m²)" : "(ft²)"}`,
                align: "left",
                width: "160",
            },
            {
                id: "rsi",
                label: `R-value ${displayUnits === "Metric" ? "(RSI)" : "(R)"}`,
                align: "left",
                // width: "160",
            },
            {
                id: "shgc",
                label: "SHGC",
                align: "left",
                // width: "160",
            },
            {
                id: "heatLoss",
                label: `Heat Loss ${displayUnits === "Metric" ? "(W)" : "(Btu/h)"}`,
                align: "right",
                width: "160",
            },
            {
                id: "heatGain",
                label: `Heat Gain ${displayUnits === "Metric" ? "(W)" : "(Btu/h)"}`,
                align: "right",
                width: "160",
            },
        ];
    } else if (compType === "basement") {
        return [
            {
                id: "label",
                label: "Label",
                align: "left",
                width: "400",
            },
            {
                id: "wallRsi",
                label: `Wall R-value ${displayUnits === "Metric" ? "(RSI)" : "(R)"}`,
                align: "left",
                // width: "160",
            },
            {
                id: "slabRsi",
                label: `Slab R-value ${displayUnits === "Metric" ? "(RSI)" : "(R)"}`,
                align: "left",
                // width: "160",
            },
            {
                id: "heatLoss",
                label: `Heat Loss ${displayUnits === "Metric" ? "(W)" : "(Btu/h)"}`,
                align: "right",
                width: "160",
            },
            {
                id: "heatGain",
                label: `Heat Gain ${displayUnits === "Metric" ? "(W)" : "(Btu/h)"}`,
                align: "right",
                width: "160",
            },
        ];
    } else if (["otherMajor", "otherMinor"].includes(compType)) {
        return [
            {
                id: "type",
                label: "Heat Flow Type",
                align: "left",
                // width: "240",
            },
            {
                id: "heatLoss",
                label: `Heat Loss ${displayUnits === "Metric" ? "(W)" : "(Btu/h)"}`,
                align: "right",
                width: "160",
            },
            {
                id: "heatGain",
                label: `Heat Gain ${displayUnits === "Metric" ? "(W)" : "(Btu/h)"}`,
                align: "right",
                width: "160",
            },
        ];
    } else {
        //All other component types
        return [
            {
                id: "label",
                label: "Label",
                align: "left",
                width: "240",
            },
            {
                id: "netArea",
                label: `Net Area ${displayUnits === "Metric" ? "(m²)" : "(ft²)"}`,
                align: "left",
                width: "160",
            },
            {
                id: "rsi",
                label: `R-value ${displayUnits === "Metric" ? "(RSI)" : "(R)"}`,
                align: "left",
                // width: "160",
            },
            {
                id: "heatLoss",
                label: `Heat Loss ${displayUnits === "Metric" ? "(W)" : "(Btu/h)"}`,
                align: "right",
                width: "160",
            },
            {
                id: "heatGain",
                label: `Heat Gain ${displayUnits === "Metric" ? "(W)" : "(Btu/h)"}`,
                align: "right",
                width: "160",
            },
        ];
    }
};

const otherFactorLabels = {
    airLeakage: "Air Leakage",
    mechVentilation: "Mechanical Ventilation",
    occupantGains: "Occupant Gains",
    baseloadIntGains: "Base Load Internal Gains",
    atticDuctHeatLoss: "Attic Duct Losses",
    crawlspaceDuctHeatLoss: "Crawl Space Duct Losses",
    basementDuctHeatLoss: "Basement Duct Losses",
    slabDuctHeatLoss: "Slab Perimieter Duct Losses",
    pipeHeatLoss: "Hydronic Distribution Losses",
};

const buildRows = ({ compSummary, compType, displayUnits, className = "", oddRowClass = "" }) => {
    return Object.keys(compSummary).map((compKey, ind) => {
        if (compType === "window") {
            return {
                id: compKey,
                label: {
                    content: compSummary[compKey].label,
                    className,
                },
                netArea: {
                    content: parseFloat(
                        convertUnit({
                            value: compSummary[compKey].area,
                            type: "area",
                            inputUnit: "m2",
                            outputUnit: displayUnits === "Metric" ? "m2" : "ft2",
                        })
                    ).toFixed(2),
                    className,
                },
                rsi: {
                    content: parseFloat(
                        convertUnit({
                            value: compSummary[compKey].rsi,
                            type: "thermalResistance",
                            inputUnit: "RSI",
                            outputUnit: displayUnits === "Metric" ? "RSI" : "R",
                        })
                    ).toFixed(2),
                    className,
                },
                shgc: {
                    content: compSummary[compKey].shgc,
                    className,
                },
                heatLoss: {
                    content: parseFloat(
                        convertUnit({
                            value: compSummary[compKey].heatLoss,
                            type: "power",
                            inputUnit: "W",
                            outputUnit: displayUnits === "Metric" ? "W" : "BTU/h",
                        })
                    ).toFixed(2),
                    className,
                },
                heatGain: {
                    content: parseFloat(
                        convertUnit({
                            value: compSummary[compKey].heatGain,
                            type: "power",
                            inputUnit: "W",
                            outputUnit: displayUnits === "Metric" ? "W" : "BTU/h",
                        })
                    ).toFixed(2),
                    className,
                },
            };
        } else if (compType === "basement") {
            return {
                id: compKey,
                label: {
                    content: compSummary[compKey].label,
                    className,
                },
                wallRsi: {
                    content: parseFloat(
                        convertUnit({
                            value: compSummary[compKey].wallRsi,
                            type: "thermalResistance",
                            inputUnit: "RSI",
                            outputUnit: displayUnits === "Metric" ? "RSI" : "R",
                        })
                    ).toFixed(2),
                    className,
                },
                slabRsi: {
                    content: parseFloat(
                        convertUnit({
                            value: compSummary[compKey].slabRsi,
                            type: "thermalResistance",
                            inputUnit: "RSI",
                            outputUnit: displayUnits === "Metric" ? "RSI" : "R",
                        })
                    ).toFixed(2),
                    className,
                },
                heatLoss: {
                    content: parseFloat(
                        convertUnit({
                            value: compSummary[compKey].heatLoss,
                            type: "power",
                            inputUnit: "W",
                            outputUnit: displayUnits === "Metric" ? "W" : "BTU/h",
                        })
                    ).toFixed(2),
                    className,
                },
                heatGain: {
                    content: "N/A",
                    className,
                },
            };
        } else if (["otherMajor", "otherMinor"].includes(compType)) {
            return {
                id: compKey,
                type: {
                    content: otherFactorLabels[compKey],
                    className,
                },
                heatLoss: {
                    content: parseFloat(
                        convertUnit({
                            value: compSummary[compKey].heatLoss,
                            type: "power",
                            inputUnit: "W",
                            outputUnit: displayUnits === "Metric" ? "W" : "BTU/h",
                        })
                    ).toFixed(2),
                    className,
                },
                heatGain: {
                    content: parseFloat(
                        convertUnit({
                            value: compSummary[compKey].heatGain,
                            type: "power",
                            inputUnit: "W",
                            outputUnit: displayUnits === "Metric" ? "W" : "BTU/h",
                        })
                    ).toFixed(2),
                    className,
                },
            };
        } else if (compType === "slab") {
            return {
                id: compKey,
                label: {
                    content: compSummary[compKey].label,
                    className,
                },
                netArea: {
                    content: parseFloat(
                        convertUnit({
                            value: ["wall", "ceiling", "ponyWall"].includes(compType)
                                ? compSummary[compKey].netArea
                                : compSummary[compKey].area,
                            type: "area",
                            inputUnit: "m2",
                            outputUnit: displayUnits === "Metric" ? "m2" : "ft2",
                        })
                    ).toFixed(2),
                    className,
                },
                rsi: {
                    content: parseFloat(
                        convertUnit({
                            value: compSummary[compKey].slabRsi,
                            type: "thermalResistance",
                            inputUnit: "RSI",
                            outputUnit: displayUnits === "Metric" ? "RSI" : "R",
                        })
                    ).toFixed(2),
                    className,
                },
                heatLoss: {
                    content: parseFloat(
                        convertUnit({
                            value: compSummary[compKey].heatLoss,
                            type: "power",
                            inputUnit: "W",
                            outputUnit: displayUnits === "Metric" ? "W" : "BTU/h",
                        })
                    ).toFixed(2),
                    className,
                },
                heatGain: {
                    content: parseFloat(
                        convertUnit({
                            value: compSummary[compKey].heatGain,
                            type: "power",
                            inputUnit: "W",
                            outputUnit: displayUnits === "Metric" ? "W" : "BTU/h",
                        })
                    ).toFixed(2),
                    className,
                },
            };
        } else {
            return {
                id: compKey,
                label: {
                    content: compSummary[compKey].label,
                    className,
                },
                netArea: {
                    content: parseFloat(
                        convertUnit({
                            value: ["wall", "ceiling", "ponyWall"].includes(compType)
                                ? compSummary[compKey].netArea
                                : compSummary[compKey].area,
                            type: "area",
                            inputUnit: "m2",
                            outputUnit: displayUnits === "Metric" ? "m2" : "ft2",
                        })
                    ).toFixed(2),
                    className,
                },
                rsi: {
                    content: parseFloat(
                        convertUnit({
                            value: compSummary[compKey].rsi,
                            type: "thermalResistance",
                            inputUnit: "RSI",
                            outputUnit: displayUnits === "Metric" ? "RSI" : "R",
                        })
                    ).toFixed(2),
                    className,
                },
                heatLoss: {
                    content: parseFloat(
                        convertUnit({
                            value: compSummary[compKey].heatLoss,
                            type: "power",
                            inputUnit: "W",
                            outputUnit: displayUnits === "Metric" ? "W" : "BTU/h",
                        })
                    ).toFixed(2),
                    className,
                },
                heatGain: {
                    content: parseFloat(
                        convertUnit({
                            value: compSummary[compKey].heatGain,
                            type: "power",
                            inputUnit: "W",
                            outputUnit: displayUnits === "Metric" ? "W" : "BTU/h",
                        })
                    ).toFixed(2),
                    className,
                },
            };
        }
    });
};

const SummaryTable = ({ compSummary = {}, compType = "", displayUnits = "" }) => {
    //special compTypes: "window", "basement", "crawlspace", "slab"
    return (
        <div>
            <Table
                className={classes.summaryRow}
                headClassName={classes.summaryHeader}
                headCells={getHeadCells(compType, displayUnits)}
                rows={buildRows({
                    compSummary,
                    compType,
                    displayUnits,
                    className: classes.summaryRow,
                })}
            ></Table>
        </div>
    );
};

export default SummaryTable;
