import React from "react";

export default (data) => {
    return (
        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.18945 0.975159L14.3789 6.96636L13.6507 7.84026L12.3084 6.72173V7.66369C11.9437 7.54914 11.5631 7.47034 11.1709 7.43139V5.77377L7.18945 2.45592L3.20798 5.77381V13.6599H4.9424C4.98135 14.0521 5.06015 14.4327 5.1747 14.7974H2.07043V6.72177L0.728244 7.84026L0 6.96636L7.18945 0.975159Z"
                fill="#0049C6"
            />
            <circle cx="10.602" cy="13.0911" r="3.92382" stroke="#0049C6" />
            <circle cx="10.602" cy="13.0911" r="3.92382" stroke="#0049C6" />
            <circle cx="10.602" cy="13.0911" r="3.92382" stroke="#0049C6" />
            <circle cx="10.602" cy="13.0911" r="3.92382" stroke="#0049C6" />
            <path
                d="M10.0334 13.6598L10.0334 15.3662H11.1709V13.6598H12.8772V12.5223H11.1709V10.816H10.0334L10.0334 12.5223H8.32703V13.6598H10.0334Z"
                fill="#0049C6"
            />
        </svg>
    );
};

// export default () => (
//     <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
//         <path fillRule="evenodd" clipRule="evenodd" d="M7.18945 0.398987L14.3789 6.39019L13.6507 7.26409L12.3084 6.14551V7.08752C11.7697 6.91834 11.1965 6.82713 10.602 6.82713C7.46076 6.82713 4.91426 9.37363 4.91426 12.5149C4.91426 13.1094 5.00546 13.6826 5.17464 14.2212H2.07037V6.14565L0.728244 7.26409L0 6.39019L7.18945 0.398987Z" fill="#0049C6"/>
//         <path fillRule="evenodd" clipRule="evenodd" d="M10.602 16.9387C13.0453 16.9387 15.0259 14.9581 15.0259 12.5149C15.0259 10.0717 13.0453 8.09108 10.602 8.09108C8.15883 8.09108 6.17822 10.0717 6.17822 12.5149C6.17822 14.9581 8.15883 16.9387 10.602 16.9387ZM10.0332 14.79L10.0332 13.0837H8.32692V11.9461H10.0332V10.2398H11.1708V11.9461H12.8771V13.0837H11.1708V14.79H10.0332Z" fill="#0049C6"/>
//     </svg>
// )
