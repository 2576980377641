import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import ChbaNzFile from "./";

const mapStateToProps = ({ form }, { accessor }) => {
    const selector = formValueSelector("model");

    const { id: chbaLabelId } = selector({ form }, "modelData.chbaNz.file.targetLabel") || {};

    return {
        accessor,
        chbaLabelId,
    };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ChbaNzFile);
