import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import Requirements from './';

const mapStateToProps = (
    {
        form
    },
    {
        accessor
    }
) => {
    const selector = formValueSelector('model');
    const h2kInfo = selector({form}, `${accessor}.h2kInfo`) || {};
    
    const modelUnits = selector({form}, 'modelData.uiSettings.primaryUnits') || {};

    return {
        accessor,
        h2kInfo,
        modelUnits
    }
};

const mapDispatchToProps = () => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Requirements);