import React, { useEffect, useState } from "react";
import classes from "./style.module.scss";
import InfoTooltip from "components/InfoTooltip";
import WarningTooltip from "components/WarningTooltip";
import NonH2kParamTooltip from "components/NonH2kParamTooltip";
import LoadingDots from "components/LoadingDots";

const CostTableInput = ({
    name,
    label,
    input = {},
    meta = {},
    type,
    className,
    large,
    placeholder = "",
    hideLabel,
    autoComplete = "off",
    info = "",
    warning = "",
    nonH2kParam = "",
    disabled = false,
    parentError = false,
    quantity = false,
    setValue,
    setTouched = false,
    decimals,
    isLoading = false,
    icon,
    style,
}) => {
    const [touched, toggleTouched] = useState(false);
    const { invalid = false, error = "" } = meta;
    const { value, onChange } = input;
    const isTouched = touched || setValue || setTouched;

    const classNames = [
        classes.inputField,
        className && className,
        large && classes.large,
        quantity && classes.hasQuantity,
        (parentError || (invalid && isTouched)) && classes.invalid,
    ].filter((c) => c);

    const countUp = () => onChange(parseFloat(value || 0) + 1);
    const countDown = () => {
        if (parseFloat(value) - 1 >= 0) {
            onChange(parseFloat(value || 0) - 1);
        }
    };

    const handleChange = (e) => {
        // Parse if number
        const name = e.target.name;
        const newValue = type === "number" ? parseFloat(e.target.value) : e.target.value;
        onChange(newValue, name);
    };

    const handleBlur = () => {
        toggleTouched(true);
        // Number decimal places
        if (type === "number" && !isNaN(decimals)) {
            const decimalVal = parseFloat(parseFloat(value).toFixed(decimals));
            onChange(decimalVal);
        }
    };

    const dollarFormatter = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });

    useEffect(() => {
        // Convert to decimal places
        if (setValue && type === "number" && !isNaN(decimals)) {
            return onChange(parseFloat(setValue.toFixed(decimals)));
        }
        if (setValue && type === "number") {
            return onChange(parseFloat(setValue));
        }
        if (setValue || setValue === 0) {
            onChange(setValue);
        }
    }, [setValue]);

    return (
        <div className={classNames.join(" ")} style={style}>
            <label className={hideLabel && "invisible"} htmlFor={name}>
                {label}
            </label>
            {info && <InfoTooltip className={classes.infoTooltip} info={info} />}
            {warning && (
                <WarningTooltip className={`${classes.warningTooltip} ${info && classes.pushLeft}`} warning={warning} />
            )}
            {nonH2kParam && (
                <NonH2kParamTooltip
                    className={`${classes.nonH2kParamTooltip} ${warning && classes.pushLeft}`}
                    nonH2kParam={nonH2kParam}
                />
            )}
            {isLoading && (
                <div className={classes.loading}>
                    <LoadingDots />
                </div>
            )}
            {!isLoading && (
                <input
                    {...input}
                    id={name}
                    name={name}
                    type={type}
                    placeholder={placeholder}
                    autoComplete={autoComplete}
                    disabled={disabled}
                    value={dollarFormatter.format(value)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            )}
            {icon && <img className={classes.inputIcon} src={icon} alt={icon} />}
            {quantity && !isLoading && (
                <div className={classes.quantity}>
                    <div onClick={countUp}>
                        <span></span>
                        <span></span>
                    </div>
                    <div onClick={countDown}>
                        <span></span>
                    </div>
                </div>
            )}
            {invalid && isTouched && error && <span className={classes.errorMessage}>{error}</span>}
        </div>
    );
};

export default CostTableInput;
