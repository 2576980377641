import IDGenerator from "./IDGenerator";

export const foundationFactory = (state, action) => {
    const { modelTakeoff } = state;
    const { comp, compType } = action;

    switch (compType.toLowerCase()) {
        case "crawlspace":
            return {
                ...state,
                modelTakeoff: {
                    ...modelTakeoff,
                    [comp]: {
                        compType: compType,
                        area: {
                            value: 75,
                        },
                        totalPerimeter: {
                            value: 40,
                        },
                        interiorWallLength: {
                            value: 0,
                        },
                        totalWallHeight: {
                            value: 0.9,
                        },
                        agHeight: {
                            value: 0.9,
                        },
                        corners: {
                            value: 4,
                            unit: "count",
                        },
                        floorHeaderHeight: {
                            value: 0.25,
                        },
                        wallSurfaceArea: {
                            value: 36,
                        },
                        agWallSurfaceArea: {
                            value: 36,
                        },
                        volume: {
                            value: 67.5,
                        },
                        exteriorWallLength: {
                            value: 40,
                        },
                        bgDepth: {
                            value: 0,
                        },
                        components: [],
                        tableComponents: [],
                        toffId: IDGenerator(compType),
                    },
                },
            };
        case "basement": {
            return {
                ...state,
                modelTakeoff: {
                    ...modelTakeoff,
                    [comp]: {
                        compType: compType,
                        area: {
                            value: 75,
                        },
                        totalPerimeter: {
                            value: 40,
                        },
                        interiorWallLength: {
                            value: 0,
                        },
                        totalWallHeight: {
                            value: 2.4,
                        },
                        agHeight: {
                            value: 0.8,
                        },
                        corners: {
                            value: 4,
                            unit: "count",
                        },
                        floorHeaderHeight: {
                            value: 0.25,
                        },
                        wallSurfaceArea: {
                            value: 96,
                        },
                        agWallSurfaceArea: {
                            value: 32,
                        },
                        volume: {
                            value: 180,
                        },
                        exteriorWallLength: {
                            value: 40,
                        },
                        bgDepth: {
                            value: 1.6,
                        },
                        components: [],
                        tableComponents: [],
                        toffId: IDGenerator(compType),
                    },
                },
            };
        }
        case "walkout": {
            return {
                ...state,
                modelTakeoff: {
                    ...modelTakeoff,
                    [comp]: {
                        compType: compType,
                        area: {
                            value: 75,
                        },
                        totalPerimeter: {
                            value: 40,
                        },
                        interiorWallLength: {
                            value: 0,
                        },
                        totalWallHeight: {
                            value: 2.4,
                        },
                        buriedWalkoutDepth: {
                            value: 1.6,
                        },
                        shallowWalkoutDepth: {
                            value: 0,
                        },
                        slopedGradeWallLength: {
                            value: 15,
                        },
                        upperGradeLength: {
                            value: 0,
                        },
                        walkoutSlabLength: {
                            value: 0,
                        },
                        agHeight: {
                            value: 0.8,
                        },
                        corners: {
                            value: 4,
                            unit: "count",
                        },
                        floorHeaderHeight: {
                            value: 0.25,
                        },
                        wallSurfaceArea: {
                            value: 96,
                        },
                        agWallSurfaceArea: {
                            value: 32,
                        },
                        volume: {
                            value: 180,
                        },
                        exteriorWallLength: {
                            value: 40,
                        },
                        bgDepth: {
                            value: 1.6,
                        },
                        components: [],
                        // components: [
                        //     {
                        //         type: "ponywall",
                        //         id: IDGenerator("pw"),
                        //         name: "Pony Wall Segment",
                        //         fields: [
                        //             {
                        //                 name: "Height 1",
                        //                 field: "height1",
                        //                 value: 1,
                        //
                        //             },
                        //             {
                        //                 name: "Height 2",
                        //                 field: "height2",
                        //                 value: 1,
                        //
                        //             },
                        //             {
                        //                 name: "Perimeter",
                        //                 field: "perimeter",
                        //                 value: 10,
                        //
                        //             },
                        //         ],
                        //     },
                        //     {
                        //         type: "ponywall",
                        //         id: IDGenerator("pw"),
                        //         name: "Pony Wall Segment",
                        //         fields: [
                        //             {
                        //                 name: "Height 1",
                        //                 field: "height1",
                        //                 value: 1,
                        //
                        //             },
                        //             {
                        //                 name: "Height 2",
                        //                 field: "height2",
                        //                 value: 1,
                        //
                        //             },
                        //             {
                        //                 name: "Perimeter",
                        //                 field: "perimeter",
                        //                 value: 10,
                        //
                        //             },
                        //         ],
                        //     },
                        // ],
                        tableComponents: [],
                        toffId: IDGenerator(compType),
                    },
                },
            };
        }
        case "slab": {
            return {
                ...state,
                modelTakeoff: {
                    ...modelTakeoff,
                    [comp]: {
                        compType: compType,
                        slabArea: {
                            value: 75,
                        },
                        exposedFloorArea: {
                            value: 0,
                        },
                        totalPerimeter: {
                            value: 40,
                        },
                        interiorWallLength: {
                            value: 0,
                        },
                        totalWallHeight: {
                            value: 2.77,
                        },
                        corners: {
                            value: 4,
                            unit: "count",
                        },
                        intersections: {
                            value: 4,
                            unit: "count",
                        },
                        exteriorWallLength: {
                            value: 40,
                        },
                        wallSurfaceArea: {
                            value: 110.8,
                        },
                        volume: {
                            value: 207.75,
                        },
                        components: [],
                        tableComponents: [],
                        toffId: IDGenerator(compType),
                    },
                },
            };
        }
        case "basementcrawlspace": {
            return {
                ...state,
                modelTakeoff: {
                    ...modelTakeoff,
                    [comp]: {
                        compType: compType,
                        area: {
                            value: 75,
                        },
                        totalPerimeter: {
                            value: 40,
                        },
                        interiorWallLength: {
                            value: 0,
                        },
                        totalWallHeight: {
                            value: 2.4,
                        },
                        agHeight: {
                            value: 0.8,
                        },
                        corners: {
                            value: 4,
                            unit: "count",
                        },
                        wallSurfaceArea: {
                            value: 96,
                        },
                        agWallSurfaceArea: {
                            value: 32,
                        },
                        volume: {
                            value: 180,
                        },
                        exteriorWallLength: {
                            value: 40,
                        },
                        bgDepth: {
                            value: 1.6,
                        },
                        components: [],
                        tableComponents: [],

                        crawlarea: {
                            value: 75,
                        },
                        crawltotalPerimeter: {
                            value: 40,
                        },
                        crawlinteriorWallLength: {
                            value: 0,
                        },
                        crawltotalWallHeight: {
                            value: 2.4,
                        },
                        crawlagHeight: {
                            value: 0.8,
                        },
                        crawlcorners: {
                            value: 4,
                            unit: "count",
                        },
                        crawlwallSurfaceArea: {
                            value: 96,
                        },
                        crawlagWallSurfaceArea: {
                            value: 32,
                        },
                        crawlvolume: {
                            value: 180,
                        },
                        crawlexteriorWallLength: {
                            value: 40,
                        },
                        crawlbgDepth: {
                            value: 1.6,
                        },
                        toffId: IDGenerator(compType),
                    },
                },
            };
        }

        default: {
            //? This shouldn't happen. You may want to force a crash if it ever happens.
            return {
                ...state,
            };
        }
    }
};

export const storeyFactory = (state, action) => {
    const { modelTakeoff } = state;
    const { comp } = action;

    let newModelTakeoff = modelTakeoff;

    const { id } = state?.modelData?.specifications?.numStoreys || {};

    const modelTakeoffKeys = Object.keys(newModelTakeoff);

    //Should ignore foundation
    //default component: other
    //additional components: first, second, third, lower, upper
    for (let i = 0; i < modelTakeoffKeys.length; i++) {
        const currentKey = modelTakeoffKeys[i];

        // first
        if (id === 0) {
            if (currentKey !== "foundation" && currentKey !== "other" && currentKey !== "first") {
                delete newModelTakeoff[currentKey];
            }
        }

        //first && second
        if (id === 2 || id === 3) {
            if (
                currentKey !== "foundation" &&
                currentKey !== "other" &&
                currentKey !== "first" &&
                currentKey !== "second"
            ) {
                delete newModelTakeoff[currentKey];
            }
        }

        //first && second && third
        if (id === 4) {
            if (
                currentKey !== "foundation" &&
                currentKey !== "other" &&
                currentKey !== "first" &&
                currentKey !== "second" &&
                currentKey !== "third"
            ) {
                delete newModelTakeoff[currentKey];
            }
        }

        //lower && upper
        if (id === 5) {
            if (
                currentKey !== "foundation" &&
                currentKey !== "other" &&
                currentKey !== "lower" &&
                currentKey !== "upper"
            ) {
                delete newModelTakeoff[currentKey];
            }
        }

        // only upper
        if (id === 6) {
            if (currentKey !== "foundation" && currentKey !== "other" && currentKey !== "upper") {
                delete newModelTakeoff[currentKey];
            }
        }

        newModelTakeoff = {
            ...newModelTakeoff,
            [comp]: {
                compType: comp,
                floorPlanArea: {
                    value: 75,
                },
                floorPlanPerimeter: {
                    value: 40,
                },
                interiorWallLength: {
                    value: 0,
                },
                wallHeight: {
                    value: 2.77,
                },
                corners: {
                    unit: "count",
                    value: 4,
                },
                intersections: {
                    unit: "count",
                    value: 4,
                },
                floorHeaderHeight: {
                    value: 0.25,
                },
                wallSurfaceArea: {
                    value: 110.8,
                },
                volume: {
                    value: 207.75,
                },
                equivalentPerimeter: {
                    value: 40,
                },
                components: [],
                tableComponents: [],
                toffId: IDGenerator(comp),
            },
        };
    }

    return {
        ...state,
        modelTakeoff: {
            ...newModelTakeoff,
        },
    };
};
