
import React, { useEffect, useState } from "react";
import classes from "../style.module.scss";
import { CHBA_RENO_COMM_ID } from "utils/communities/api";
import { timeDifference } from "utils";
import { isEqual } from "lodash";
import SectionHeading from "components/SectionHeading";
import Tabs from "components/Tabs";
import CommModelsIcon from "assets/images/icons/CommModels.svg";
import UpgradeIcon from "assets/images/icons/Upgrades.svg";
import OptimizationsIcon from "assets/images/icons/Optimizations.svg";
import ResultsIcon from "assets/images/icons/Results.svg";
import Models from "../Models";
import Upgrades from "../Upgrades";
import Optimizations from "../Optimizations";
import Results from "../Results";
import { selectCommunityData, selectCommunityError, selectCommunityModelDir } from "../_ducks/selectors";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

const CommTabs = React.memo(
    ({
        communityId,
        commModelDir,
        history,
        pathCallback,
        uid,
        communityData,
        error,
    }) => {
        const [intialPath, initialTab] = window.location.pathname.split(communityId);
        const [tab, changeTab] = useState(initialTab);
        const isCHBAComm = communityId === CHBA_RENO_COMM_ID;

        const { name, totalModels, region, lastEdited, editedName, createdAt, owner = {} } = communityData;
        const editedDateTime = lastEdited; // usually lastEdited is an object but for RenoComm it's just a date

        const { name: ownerName = "", uid: ownerId = uid } = owner;
        // const { name: editedName, datetime: editedDateTime } = lastEdited;
        
        // Update tab to match the current pathname when the browser's back button is pressed
        useEffect(() => {
            const [path, prevTab] = pathCallback().split(communityId);
            // mixpanel.track("Main Tab Change", {
            //     Tab: prevTab,
            // });
            return changeTab(prevTab);
        }, [pathCallback]);

        return (
            <>
                <SectionHeading
                    title={name}
                    subtitle={
                        editedDateTime ? (
                            <span>
                                Last edited{" "}
                                {
                                    <strong>
                                        {timeDifference({
                                            start: new Date(),
                                            end: editedDateTime,
                                        })}
                                    </strong>
                                }{" "}
                                ago{editedName ? ` by ${editedName}.` : "."}
                            </span>
                        ) : (
                            <span>
                                Created{" "}
                                {
                                    <strong>
                                        {timeDifference({
                                            start: new Date(),
                                            end: createdAt,
                                        })}
                                    </strong>
                                }{" "}
                                ago{ownerName ? ` by ${ownerName}.` : "."}
                            </span>
                        )
                    }
                />
                <div className={classes.modelWrapper}>
                    {error && <p>{error}</p>}
                    <Tabs
                        initialTab={initialTab || "/"}
                        tabActive={tab || "/"}
                        tabClassName={classes.tabWrapper}
                        tabNavClassName={classes.tabNavWrapper}
                        tabs={[
                            { 
                                name: "Community Models",
                                url: "/",
                                icon: CommModelsIcon
                            },
                            {
                                name: "Upgrades",
                                url: "/upgrades",
                                disabled: isCHBAComm,
                                info: isCHBAComm ? "This feature is not available for this community type" : "",
                                icon: UpgradeIcon,
                            },
                            {
                                name: "Optimizations",
                                url: "/optimizations",
                                disabled: isCHBAComm,
                                info: isCHBAComm ? "This feature is not available for this community type" : "",
                                icon: OptimizationsIcon,
                            },
                            {
                                name: "Results",
                                url: "/results",
                                icon: ResultsIcon,
                            },
                        ]}
                        onChange={(e) => {
                            history.push(`/communities/${communityId}${e}`);
                            // mixpanel.track("Main Tab Change", {
                            //     Tab: e,
                            // });
                            return changeTab(e);
                        }}
                        stretchTabs
                    >
                        <Models
                            commModelDir={commModelDir}
                            history={history}
                        />
                        <Upgrades
                            history={history}
                        />
                        <Optimizations
                            history={history}
                        />
                        <Results
                            commModelDir={commModelDir}
                            history={history}
                        />
                    </Tabs>
                </div>
            </>
        );
    },
    isEqual
);

const mapStateToProps = createStructuredSelector({
    communityData: selectCommunityData,
    commModelDir: selectCommunityModelDir,
    error: selectCommunityError,
});

const mapDispatchToProps = (dispatch, { history }) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(CommTabs);