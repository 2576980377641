import React from "react";

export default ({colour = "#65676A"}) => (
    <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            id="Rectangle"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 6L0 18H18V6H16.0133L16.0133 16.0021H1.99219L1.99219 6H0ZM9.13015 14.5874L4.18178 9.63902L5.59679 8.22401L8.13508 10.7617L8.13508 8.01086e-05H10.1351L10.1351 10.768L12.6777 8.22518L14.085 9.63251L9.13015 14.5874Z"
            fill={colour}
        />
    </svg>
);
