import React from 'react';
import classes from '../style.module.scss';
import sharedClasses from 'features/Model/sharedStyles.module.scss';
import { Field } from 'redux-form';
import { required, maxLength, getValidation, getDecimalPlaces } from 'utils/fieldValidation';
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { getOptions, getFirstOption, getBaseUnits, getUnitOptions } from 'utils/fields';
import InputRow from 'components/Input/Row';
import Input from 'components/Input';
import InputWithUnits from 'components/Input/InputWithUnits';
import Select from 'components/Input/Select';
import SelectWithInput from 'components/Input/SelectWithInput';
import Button from 'components/Button';
import Checkbox from 'components/Input/Checkbox';
import Accordion from 'components/Accordion';

const observedLowFlowValidation = getValidation('ersObservedLowFlowToilets');
const recommendLowFlowValidation = getValidation('ersRecommendLowFlowToilets');

export default React.memo(({
    accessor,
    modelUnits,
    change
}) => {

    return (
        <div>
            <InputRow gridTemplate="1fr 1fr 1fr">
                <Field
                    component={Input}
                    type="number"
                    name={`${accessor}.lowFlushToilets`}
                    label="Observed Low Flow Toilets"
                    placeholder={0}
                    validate={observedLowFlowValidation}
                    decimals={getDecimalPlaces('ersObservedLowFlowToilets')}
                />
                <Field
                    component={Input}
                    type="number"
                    name={`${accessor}.lowFlushToiletsUpgrade`}
                    label="Recommended Low Flow Toilets for Upgrade"
                    placeholder={0}
                    validate={recommendLowFlowValidation}
                    decimals={getDecimalPlaces('ersRecommendLowFlowToilets')}
                />
            </InputRow>
        </div>
    )
})