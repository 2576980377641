import React from "react";
import classes from '../style.module.scss';
import { Field } from 'redux-form';
import { required, maxLength, getValidation } from 'utils/fieldValidation';
import { CSSTransition } from "react-transition-group";

import Select from 'components/Input/Select';
import Button from 'components/Button';
import DayPicker from 'components/Input/DayPicker';
import Input from 'components/Input';
import InputRow from 'components/Input/Row';
import { ErrorBar } from "components/AlertBars";

const charLim100 = maxLength(100);

const MiniAudit = ({
    change,
    miniAuditId,
    handleSubmit,
    submitting,
    invalid,
    history,
    auditIdIsValid = false,
    validateMiniAudit,
    validationError,
    toggleAuditIdValid,
    setValidationError,
    isValidating
}) => {
    return (
        <div className={`${classes.createForm} ${classes.miniAudit}`}>
            <h4 className={classes.step}>1. Validate Assesement Id</h4>
            <InputRow gridTemplate="4fr 1fr">
                <Field
                    component={Input}
                    name="miniAuditId"
                    type="text"
                    label="Assesement Id"
                    placeholder=""
                    validate={[required]}
                    onChange={() => {
                        if (auditIdIsValid) {
                            toggleAuditIdValid(false);
                        }
                        if (validationError) {
                            setValidationError("");
                        }
                    }}
                />
                <Button
                    small
                    className={classes.validateButton}
                    onClick={() => validateMiniAudit(miniAuditId)}
                    disabled={!miniAuditId || isValidating}
                >
                    {isValidating ? "Validating..." : "Validate"}
                </Button>
            </InputRow>
            {validationError && !auditIdIsValid && (
                <ErrorBar className={classes.validationError} message={validationError} />
            )}
            <CSSTransition
                in={auditIdIsValid}
                timeout={300}
                classNames={{
                    enter: classes.contentEnter,
                    enterActive: classes.contentEnterActive,
                    enterDone: classes.contentEnterDone
                }}
            >
                <div className={classes.detailsWrapper}>
                    <h4 className={classes.step}>2. Provide Model Details</h4>
                    <InputRow lastRow>
                        <Field
                            component={Input}
                            name="name"
                            type="text"
                            label="Name*"
                            placeholder="Name Your Model"
                            validate={[required, charLim100]}
                        />
                    </InputRow>
                </div>
            </CSSTransition>
            <Button
                large
                className={classes.createButton}
                onClick={() => handleSubmit()}
                disabled={submitting || invalid || validationError || !auditIdIsValid}
            >
                {submitting ? "Creating Model..." : "Create"}
            </Button>
        </div>
            
    )
};

export default MiniAudit;