import React, { useCallback } from 'react';
import classes from '../style.module.scss';
import Button from 'components/Button';
import Select from 'components/Input/Select';
import Input from 'components/Input';
import Checkbox from 'components/Input/Checkbox';
import { Field } from 'redux-form';
import { required, maxLength } from 'utils/fieldValidation';
import isEmpty from 'lodash/isEmpty';
import debounce from "lodash/debounce";

const charLim100 = maxLength(100);

const Line = ({
    cancel,
    submitSucceeded,
    handleSubmit,
    invalid,
    pristine,
    submitting,
    removeLine,
    activeComponent,
    activeImage,
    shape,
    setAction,
    setActiveComponent,
    componentOptions,
    selectedComponent,
    lineFieldOptions=[],
    aboveGradeHeight,
    savedFieldRef,
    savedAboveGradeHeightRef,
    change,
    updateFieldsToDetach,
    lineName
}) => {
    const saveToState = useCallback(debounce(e => {
        handleSubmit();
    }, 500),[]);

    return (
        <div className={classes.fields}>
            <Field
                className={classes.inputMarginBottom}
                component={Input}
                type="text"
                label="Line Name*"
                name="lineName"
                validate={[required, charLim100]}
                placeholder="Example: Wall Height"
                onChange={saveToState}
            />
            <Field
                className={classes.inputMarginBottom}
                component={Checkbox}
                name={"aboveGradeHeight"}
                disabled={savedFieldRef || savedAboveGradeHeightRef}
                label="Use for Above Grade Height of Highest Ceiling"
                type="checkbox"
                onChange={(value)=>{
                    if (value) {
                        change("attachTo",{});
                        change("changeField",{});
                    }

                    if (lineName === '' && value) {
                        change("lineName","Highest Ceiling");
                    }

                    saveToState();
                }}
            />
            {
                !aboveGradeHeight &&
                    <Field
                        className={classes.inputMarginBottom}
                        component={Select}
                        options={componentOptions}
                        name="attachTo"
                        placeholder="Choose Component"
                        searchPlaceholder="Search Components"
                        label="Attach Line to Component"
                        search={true}
                        disabled={savedFieldRef || aboveGradeHeight || savedAboveGradeHeightRef}
                        info={`This component will be updated in the model when the drawing is saved.`}
                        onChange={saveToState}
                    />
            }
            {
                !aboveGradeHeight &&
                    <Field
                        component={Select}
                        options={lineFieldOptions}
                        name="changeField"
                        placeholder={isEmpty(selectedComponent) ? "Choose a Component First" : "Choose Field"}
                        label="Attach Line to Field"
                        disabled={savedFieldRef || aboveGradeHeight || isEmpty(selectedComponent) || savedAboveGradeHeightRef}
                        info={`This field will be updated in the model when the drawing is saved.`}
                        onChange={saveToState}
                    />
            }
            <div className={`${classes.buttonRow} ${classes.singleRow}`} style={{marginTop:'0.25rem'}}>
                {
                    cancel && !submitSucceeded ?
                        <Button
                            smallPadding
                            onClick={cancel}
                            type='hollow'
                            className={classes.cancelButton}
                        >
                            Cancel
                        </Button> :
                            <Button
                                smallPadding
                                onClick={async()=>{
                                    await updateFieldsToDetach([{
                                        id:activeComponent,
                                        fieldRef:savedFieldRef,
                                    }]);
                                    await setActiveComponent('');
                                    await removeLine({image:activeImage, lineId:activeComponent});
                                    if (shape) {
                                        shape.destroy();
                                    }
                                    setAction({id:''});
                                }}
                                type='hollow'
                                className={classes.deleteButton}
                            >
                                Delete
                            </Button> 
                }
            </div>
        </div>
    )
};

export default Line;