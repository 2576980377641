import React, { useState } from "react";
import classes from "./style.module.scss";
import sharedClasses from "../style.module.scss";
import ArrowDown from "assets/images/icons/JSX/arrow-down";
import { useOutsideClickHook } from "utils/outsideClick";
import InfoTooltip from "components/InfoTooltip";
import isEqual from "lodash/isEqual";
import LoadingDots from "components/LoadingDots";
import WarningTooltip from "components/WarningTooltip";
import Checkbox from "components/Input/Checkbox";

export const DropDown = ({
    className,
    options,
    selected = [],
    onChange,
    open,
    footer: Footer,
    dropDownWidth,
    emptyMessage = "No options meet the requirements.",
    hasSelectAll = false,
}) => {
    const classNames = [classes.dropDown, open && classes.open, className && className].filter((clss) => clss);

    const allAvailableOptions = options.filter(({ disabled = false }) => !disabled).map(({ value }) => value);
    const allSelected =
        allAvailableOptions.length > 0 && allAvailableOptions.every((option) => selected.includes(option));

    return (
        <div className={classNames.join(" ")} style={{ width: dropDownWidth }}>
            {options.length > 0 ? (
                <>
                    <div className={classes.options}>
                        {options.map(({ value, label, disabled = false, image }, index) => {
                            const classNames = [classes.option, isEqual(selected, value) && classes.selected].filter(
                                (c) => c
                            );

                            return (
                                <Checkbox
                                    className={classNames}
                                    input={{
                                        value: selected.includes(value), // checked
                                        onChange: (event) => {
                                            if (disabled) {
                                                return;
                                            }

                                            let newValue = selected;

                                            if (!selected.includes(value)) {
                                                newValue = [...selected, value];
                                            } else {
                                                newValue = [...selected].filter((item) => item !== value);
                                            }

                                            onChange(newValue);
                                        },
                                    }}
                                    label={label}
                                    name={`${value}`}
                                    disabled={disabled}
                                    image={image}
                                    key={index}
                                />
                            );
                        })}
                    </div>
                    {hasSelectAll && (
                        <div className={classes.selectAll}>
                            <Checkbox
                                className={classes.selectAllCheckbox}
                                input={{
                                    value: allSelected, // checked
                                    onChange: (event) => {
                                        let newValue = selected;

                                        if (allSelected) {
                                            newValue = [];
                                        } else {
                                            newValue = allAvailableOptions;
                                        }

                                        onChange(newValue);
                                    },
                                }}
                                label={"Select All"}
                                name={"selectAll"}
                                disabled={allAvailableOptions.length === 0}
                            />
                        </div>
                    )}
                </>
            ) : (
                <div className={classes.emptyFilter}>
                    <span>{emptyMessage}</span>
                </div>
            )}
            {Footer && <Footer />}
        </div>
    );
};

const Select = ({
    name,
    label,
    input,
    className,
    large,
    hideLabel = false,
    options = [],
    disabled = false,
    placeholder = "Select",
    updateSelected,
    info = "",
    meta = {},
    forcedLabel = "",
    parentError = false,
    search = false,
    searchPlaceholder = "",
    footer = null,
    dropDownWidth = "100%",
    isLoading = false,
    warning = "",
    hasSelectAll = false,
}) => {
    const { invalid = false, error = "", touched = false } = meta;

    const classNames = [
        classes.selectField,
        sharedClasses.inputField,
        className && className,
        large && sharedClasses.large,
        disabled && classes.disabled,
        hideLabel && classes.labelHidden,
        (parentError || invalid) && touched && sharedClasses.invalid,
        warning && classes.warning,
    ].filter((c) => c);

    const { value = [], onChange } = input;
    const [open, toggleOpen] = useState(false);
    //const [selected, toggleSelected] = useState('');
    const ref = useOutsideClickHook(() => toggleOpen(false));

    const setLabel = forcedLabel ? forcedLabel : `${value.length} Selected`;

    const handleChange = (newValue) => {
        if (updateSelected) {
            updateSelected(newValue);
        }
        onChange(newValue);
    };

    return (
        <div ref={ref} className={classNames.join(" ")}>
            <label className={hideLabel ? "invisible" : ""} htmlFor={name}>
                {label}
            </label>
            {isLoading && (
                <div className={classes.loading}>
                    <LoadingDots />
                </div>
            )}
            {info && <InfoTooltip className={sharedClasses.infoTooltip} info={info} />}
            {warning && (
                <WarningTooltip className={`${classes.warningTooltip} ${info && classes.pushLeft}`} warning={warning} />
            )}
            {!isLoading && (
                <div
                    className={`${sharedClasses.selectInput} ${classes.value} ${open && classes.open} ${
                        disabled && `${classes.disabled} ${sharedClasses.disabled}`
                    }`}
                    onClick={() => !disabled && toggleOpen(!open)}
                >
                    {value.length > 0 ? (
                        <span className={classes.selected}>{setLabel}</span>
                    ) : (
                        <span className={classes.placholder}>{hideLabel ? setLabel : placeholder}</span>
                    )}{" "}
                    <ArrowDown />
                </div>
            )}
            {!isLoading && (
                <DropDown
                    className={classes.dropDown}
                    options={options}
                    selected={value}
                    onChange={handleChange}
                    open={open}
                    toggleOpen={toggleOpen}
                    search={search}
                    searchPlaceholder={searchPlaceholder}
                    footer={footer}
                    dropDownWidth={dropDownWidth}
                    hasSelectAll={hasSelectAll}
                />
            )}
            {invalid && error && touched && typeof error === "string" && (
                <span style={{ bottom: "-1.25rem" }} className={sharedClasses.errorMessage}>
                    {error}
                </span>
            )}
        </div>
    );
};

export default Select;
