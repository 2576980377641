import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import CostTable from "./";

const mapStateToProps = ({ form }, { accessor, formName = "model", change }) => {
    const selector = formValueSelector(formName);
    const modelSelector = formValueSelector("model");

    const costItems = selector({ form }, `${accessor}.items`) || {};

    const upgradeFields = selector({ form }, "fields") || {};

    const modelUnits = modelSelector({ form }, `modelData.uiSettings.primaryUnits`) || {};

    return {
        accessor,
        formName,
        costItems,
        change,
        modelUnits,
        upgradeFields,
    };
};

const mergeProps = (state, dispatch, own) => ({
    ...state,
    ...dispatch,
    ...own,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CostTable);
