import { connect } from 'react-redux';
import Actions from './';
import { formValueSelector } from 'redux-form';


const mapStateToProps = (
    {
        form
    }, 
    {
        accessor,
        parentAccessor,
        formName="model"
    }
) => {
    const selector = formValueSelector(formName);
    const supplSystems = selector({form}, `${parentAccessor}.supplSystems.systems`) || {}; 

    return {
        accessor,
        parentAccessor,
        supplSystems
    }
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Actions);