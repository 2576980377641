import React from 'react';
import classes from '../style.module.scss';
import sharedClasses from 'features/Model/sharedStyles.module.scss';
import { Field } from 'redux-form';
import { required, maxLength, getValidation, getDecimalPlaces } from 'utils/fieldValidation';
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { getOptions, getFirstOption, getBaseUnits, getUnitOptions } from 'utils/fields';
import InputRow from 'components/Input/Row';
import Input from 'components/Input';
import InputWithUnits from 'components/Input/InputWithUnits';
import Select from 'components/Input/Select';
import SelectWithInput from 'components/Input/SelectWithInput';
import Button from 'components/Button';
import Checkbox from 'components/Input/Checkbox';
import Accordion from 'components/Accordion';

const userHDDValidation = getValidation('ersUserSpecHDD');
const agPartyWallValidation = getValidation('ersAGPartyWallArea');
const bgPartyWallValidation = getValidation('ersBGPartyWallArea');

export default React.memo(({
    accessor,
    agPartyWallUnits,
    bgPartyWallUnits,
    modelUnits,
    change
}) => {

    return (
        <div>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Field
                    component={Input}
                    type="number"
                    name={`${accessor}.userHDD`}
                    label="User Defined HDD"
                    placeholder={0.00}
                    validate={userHDDValidation}
                    decimals={getDecimalPlaces('ersUserSpecHDD')}
                    info={'18°C base'}
                />
                <Field
                    component={Checkbox}
                    name={`${accessor}.includeGeneration`}
                    label="Include Electricity Generation?"
                    large
                    className={classes.bottomCheckBox}
                    type="checkbox"
                />
                <Field
                    component={Checkbox}
                    name={`${accessor}.neutraliseFenestration`}
                    label="Neutralize Fenestration?"
                    large
                    className={classes.bottomCheckBox}
                    type="checkbox"
                />
            </InputRow>
            <h4 style={{marginBottom:"1.25rem", marginTop:"1.75rem"}}>Heat Pump</h4>
            <InputRow flex>
                <Field
                    component={Checkbox}
                    name={`${accessor}.hpLoadToType1`}
                    label="Transfer all heat load to Type 1 system"
                    large
                    className={classes.bottomCheckBox}
                    type="checkbox"
                    onChange={(value)=>{
                        const changedToTrue = value.target.value !== 'true';
                        change(`${accessor}.hpStandPerfLevel`,!changedToTrue);
                    }}
                />
                <Field
                    component={Checkbox}
                    name={`${accessor}.hpStandPerfLevel`}
                    label="Set to standard performance level"
                    large
                    className={classes.bottomCheckBox}
                    type="checkbox"
                    onChange={(value)=>{
                        const changedToTrue = value.target.value !== 'true';
                        change(`${accessor}.hpLoadToType1`,!changedToTrue);
                    }}
                />
            </InputRow>
            <h4 style={{marginBottom:"1.25rem", marginTop:"1.75rem"}}>User Specified Party Wall Area</h4>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.areaAgPw`}
                    label="Above Grade Area"
                    placeholder="0.00"
                    validate={agPartyWallValidation}
                    decimals={getDecimalPlaces('ersAGPartyWallArea')}
                    change={change}
                    units={{
                        base:getBaseUnits('ersAGPartyWallArea', modelUnits),
                        options:getUnitOptions('area'),
                        selected:agPartyWallUnits,
                        unitType:'area',
                        accessor:`${accessor}.areaAgPw_u`
                    }}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.areaBgPw`}
                    label="Below Grade Area"
                    placeholder="0.00"
                    validate={bgPartyWallValidation}
                    decimals={getDecimalPlaces('ersBGPartyWallArea')}
                    change={change}
                    units={{
                        base:getBaseUnits('ersBGPartyWallArea', modelUnits),
                        options:getUnitOptions('area'),
                        selected:bgPartyWallUnits,
                        unitType:'area',
                        accessor:`${accessor}.areaBgPw_u`
                    }}
                />
            </InputRow>
        </div>
    )
})