export const NBCMinVentIndex = {
    1: 16,
    2: 18,
    3: 22,
    4: 26,
    5: 30,
};

export const OBCMinVentIndex = {
    1: 15,
    2: 22.5,
    3: 30,
    4: 37.5,
    5: 45,
};

export const getMinVentRate = (buildingCode, numBedrooms, floorArea = 0) => {
    numBedrooms = Math.max(numBedrooms, 1);

    if (buildingCode === "OBC") {
        return numBedrooms > 5
            ? 7.07925 * (numBedrooms + 1)
            : {
                  1: 15,
                  2: 22.5,
                  3: 30,
                  4: 37.5,
                  5: 45,
              }[numBedrooms];
    } else if (buildingCode === "BCBC") {
        numBedrooms = Math.min(numBedrooms, 8);
        const bedroomCat = Math.floor(numBedrooms / 2); //Used for convenience to navigate the table

        if (floorArea <= 140) {
            return { 0: 14, 1: 21, 2: 28, 3: 35, 4: 42 }[bedroomCat];
        }
        if (floorArea <= 280 && floorArea > 140) {
            return { 0: 21, 1: 28, 2: 35, 3: 42, 4: 49 }[bedroomCat];
        }
        if (floorArea <= 420 && floorArea > 280) {
            return { 0: 28, 1: 35, 2: 42, 3: 49, 4: 56 }[bedroomCat];
        }
        if (floorArea <= 560 && floorArea > 420) {
            return { 0: 35, 1: 42, 2: 49, 3: 56, 4: 64 }[bedroomCat];
        }
        if (floorArea <= 700 && floorArea > 560) {
            return { 0: 42, 1: 49, 2: 56, 3: 64, 4: 71 }[bedroomCat];
        }
        if (floorArea > 700) {
            return { 0: 49, 1: 56, 2: 64, 3: 71, 4: 78 }[bedroomCat];
        }
    }

    return numBedrooms > 5
        ? 7.07925 * (numBedrooms + 1)
        : {
              1: 24,
              2: 28,
              3: 32,
              4: 38,
              5: 45,
          }[numBedrooms];
};
