import React, { useEffect, useState } from "react";
import classes from "./style.module.scss";
import moment from "moment";
import { programName } from "utils/fields";
import { validateH2kExport } from "utils/h2kinout/api";

import Dialog, { CloseDialog } from "components/Dialog";
import Button from "components/Button";
import GeneralResults from "./General/container";
import EnerGuideResults from "./EnerGuide/container";
import LoadingDots from "components/LoadingDots";
import MultiSelect from "components/Input/MultiSelect";
import Checkbox from "components/Input/Checkbox";

import { ReactComponent as ResetIcon } from "assets/images/icons/Icon-Reset.svg";
import { isBoolean } from "lodash";
import Tooltip from "components/Tooltip";

const Results = ({
    modelId,
    cliId,
    clidIds,
    history,
    hasResults,
    mode,
    loading,
    getResults,
    completedAt,
    programClass,
    readOnly,
    modelSave,
    saveDrawingData,
    runSimulation,
    runAzureSimulation,
    simulationRunning,
    error,
    packages,
    setExportErrors,
    h2kVersion,
    exportErrors,
    updateModalDetails,
    saveUserMeta,
    nonErsUser,
    isCertified,
    uid,
    isAirTestEnabled,
}) => {
    const [open, toggleOpen] = useState(false);
    const [eaErsCertified, toggleEaCheck] = useState(isCertified);
    const [selectedPackages, updateSelectedPackages] = useState([]);
    const Results = mode === "general" ? GeneralResults : EnerGuideResults;
    const modeLabel = mode === "general" ? "General" : "EnerGuide Rating System";

    const actionSimulation = async () => {
        setExportErrors([]);

        if (eaErsCertified) {
            saveUserMeta({ uid, userMeta: { eaErsCertified } });
        }

        //uses VM
        // await runSimulation({ packages: selectedPackages });

        //uses Azure
        await runAzureSimulation({ packages: selectedPackages });

        toggleOpen(false);
    };

    const simulate = async () => {
        if (simulationRunning) {
            return;
        }

        //handled in an action and cannot yet be disabled at the source, like handleSubmit
        // if (!readOnly) {
        //     await saveDrawingData();
        // }
        await modelSave();

        if (eaErsCertified) {
            saveUserMeta({ uid, userMeta: { eaErsCertified } });
        }

        updateModalDetails({ simulationRunning: true });
        toggleOpen(false);
        const errorList = await validateH2kExport({ modelId, h2kVersion });

        // If there are errors and it hasn't been run before
        if (exportErrors.length === 0 && errorList.length > 0) {
            setExportErrors(errorList, "simulation", () => actionSimulation());
            updateModalDetails({ simulationRunning: false });
            return;
        }

        // Otherwise, continue
        actionSimulation();
    };

    useEffect(() => {
        if (!hasResults && Object.keys(clidIds).length > 0) {
            const runIds = Object.values(clidIds).map(({ runId = "" }) => runId);
            // Get most recent cli results
            getResults({ cliIds: runIds, modelId });
        }
    }, [clidIds, hasResults, modelId]);

    const airLeakageErrorMsg =
        !hasResults && error === "Results not found." && isAirTestEnabled
            ? " There may be a problem with the blower door test results (coefficients out of bounds). Try again with air leakage test data disabled."
            : "";

    return (
        <div>
            <div className={classes.intro}>
                <div>
                    {loading && !simulationRunning ? (
                        <LoadingDots className={classes.dots} />
                    ) : hasResults ? (
                        <p>
                            <strong>{modeLabel} mode</strong> <span></span> Last simulation run{" "}
                            {moment(completedAt).format("LLL")}
                        </p>
                    ) : (
                        <p>
                            No results to display. Once ready, click Run New Simulation to see your model's performance
                            (v11.11 onward).
                        </p>
                    )}
                </div>
                <div
                    data-for={`non-ers-tooltip`}
                    data-tip={nonErsUser && programClass !== "" ? "File must be in General mode." : ""}
                >
                    <Button
                        className={simulationRunning ? classes.simulationRunning : ""}
                        disabled={
                            ["v11-9", "v11-10"].includes(h2kVersion) ||
                            simulationRunning ||
                            (nonErsUser && programClass !== "")
                        }
                        onClick={() => toggleOpen(true)}
                    >
                        <ResetIcon /> <span>{simulationRunning ? "Simulation Running" : "Run New Simulation"}</span>
                    </Button>
                </div>

                <Tooltip id={`non-ers-tooltip`} />
            </div>
            {simulationRunning && (
                <div className={classes.simulationRunningNotice}>
                    <p>
                        <LoadingDots /> A new simulation is in progress. The results should appear momentarily.
                    </p>
                </div>
            )}
            {!simulationRunning && error && !loading && (
                <div className={classes.simulationRunningError}>
                    {isBoolean(error) ? (
                        <p>
                            We're sorry, there is an error in your latest simulation run. Please ensure you have all of
                            the required elements for a HOT2000 simulation.
                        </p>
                    ) : (
                        <p>{`${error}${airLeakageErrorMsg}`}</p>
                    )}
                </div>
            )}
            {cliId && hasResults && !error && <Results history={history} />}
            <Dialog
                open={open}
                classes={{
                    rootClass: classes.dialogRoot,
                    paperClass: classes.dialogPaper,
                }}
            >
                <CloseDialog closeActions={() => toggleOpen(false)} />
                <div className={classes.simulationDialogContent}>
                    <div className={classes.top}>
                        <h2>Simulation Review</h2>
                        <p>Before running your simulation, ensure the information below is correct.</p>
                        <div className={classes.info}>
                            <div>
                                <strong>Program Type:</strong> {programName[programClass]}
                            </div>
                            <div>
                                <strong>HOT2000 Version:</strong> {h2kVersion}
                            </div>
                            <div className={classes.selectWrapper}>
                                <MultiSelect
                                    name="upgrades"
                                    label="Upgrade Packages Included (max 10)"
                                    input={{
                                        value: selectedPackages,
                                        onChange: (value) => updateSelectedPackages(value),
                                    }}
                                    options={Object.keys(packages).map((packageId) => ({
                                        value: packageId,
                                        label: packages[packageId].name,
                                    }))}
                                    meta={{
                                        error:
                                            selectedPackages.length > 10
                                                ? "You may only select up to 10 upgrade packages"
                                                : "",
                                        invalid: selectedPackages.length > 10,
                                        touched: selectedPackages.length > 10,
                                    }}
                                    placeholder="Select Upgrade Packages"
                                    hasSelectAll
                                />
                            </div>
                        </div>
                        {programClass && (
                            <div className={classes.checkbox}>
                                <Checkbox
                                    name="certification"
                                    label="I am a Certified Energy Advisor permitted to run ERS simulations"
                                    large
                                    input={{
                                        value: eaErsCertified,
                                        onChange: () => toggleEaCheck(!eaErsCertified),
                                    }}
                                    disabled={nonErsUser}
                                />
                            </div>
                        )}
                        {nonErsUser && (
                            <div style={{ marginTop: "1rem" }}>
                                <p>ERS simulation access can be requested if you are a registered energy advisor.</p>
                            </div>
                        )}
                    </div>

                    <div className={classes.buttons}>
                        <Button type="hollow" onClick={() => toggleOpen(false)}>
                            Cancel
                        </Button>
                        <Button
                            onClick={simulate}
                            disabled={
                                simulationRunning ||
                                selectedPackages.length > 10 ||
                                (programClass.includes("OEE") && !eaErsCertified) ||
                                (programClass.includes("OEE") && nonErsUser)
                            }
                        >
                            Run Simulation
                        </Button>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default Results;
