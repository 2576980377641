import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import WindowOverall from './';


const mapStateToProps = (
    {
        form,
    },
    {
        accessor
    }
) => {
    const selector = formValueSelector('uDefCodeWindow');
    const modelSelector = formValueSelector('model');
    const { id: overallResistanceId, value: overallResistanceValue } = selector({form}, `${accessor}.overallThermalResistance`) || {};
    const overallLayers = selector({form}, 'uDefCode.layers.window');

    const overallResistanceUnits = selector({form}, `${accessor}.overallThermalResistance_u`);
    const frameHeightUnits = selector({form}, `${accessor}.frameHeight_u`);
    const modelUnits = modelSelector({form}, `modelData.uiSettings.primaryUnits`) || {};



    return {
        accessor,
        overallResistanceId,
        overallResistanceValue,
        overallResistanceUnits,
        frameHeightUnits,
        modelUnits,
        overallLayers,
    }
};

const mapDispatchToProps = () => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WindowOverall);