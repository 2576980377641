import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import Slab from "./";
import isEmpty from "lodash/isEmpty";
import { actions } from "features/Model/Enclosure/_ducks";

const { updateFoundationConfig } = actions;

const mapStateToProps = (
    { form, model: { newestComponents = [], recentDuplicates = [], contentOpen = false } = {} },
    { id }
) => {
    const accessor = `modelData.components.slab.${id}`;

    const selector = formValueSelector("model");
    const label = selector({ form }, `${accessor}.label`) || "";
    const expPerimeterUnits = selector({ form }, `${accessor}.exposedSurfacePerimeter_u`);
    const configUnits = selector({ form }, `${accessor}.configuration_u`);
    const skirtValue = selector({ form }, `${accessor}.wall.rValues.skirt`) || 0;
    const thermalBreakValue = selector({ form }, `${accessor}.wall.rValues.thermalBreak`) || 0;
    const skirtUnits = selector({ form }, `${accessor}.wall.rValues.skirt_u`);
    const thermalBreakUnits = selector({ form }, `${accessor}.wall.rValues.thermalBreak_u`);
    const modelUnits = selector({ form }, `modelData.uiSettings.primaryUnits`) || {};
    const insConfig = selector({ form }, `${accessor}.configuration.type`) || "";
    const { component: drawingComponent = "" } = selector({ form }, `${accessor}.drawing`) || {};

    const subCompObj = selector({ form }, `${accessor}.subcomponents`) || {};

    const { window = {}, door = {}, floorHeader = {} } = subCompObj;

    const mapSubComponents = () => {
        const windows = Object.entries(window)
            .filter(([key, values]) => values && !isEmpty(values))
            .map(([key, values]) => ({
                id: key,
                componentId: key,
                componentType: "window",
                componentLabel: values.label,
                displayOrder: values.displayOrder,
            }));

        const doors = Object.entries(door)
            .filter(([key, values]) => values && !isEmpty(values))
            .map(([key, values]) => ({
                id: key,
                componentId: key,
                componentType: "door",
                componentLabel: values.label,
                displayOrder: values.displayOrder,
            }));

        const floorHeaders = Object.entries(floorHeader)
            .filter(([key, values]) => values && !isEmpty(values))
            .map(([key, values]) => ({
                id: key,
                componentId: key,
                componentType: "floorHeader",
                componentLabel: values.label,
                displayOrder: values.displayOrder,
            }));

        return [...doors, ...floorHeaders, ...windows].sort((a, b) => {
            if (a.displayOrder !== b.displayOrder) {
                return a.displayOrder - b.displayOrder;
            }
            if (a.componentLabel !== b.componentLabel && a.componentLabel != null && b.componentLabel !== null) {
                if (a.componentLabel?.toLowerCase() < b.componentLabel?.toLowerCase()) {
                    return -1;
                }
                if (a.componentLabel?.toLowerCase() > b.componentLabel?.toLowerCase()) {
                    return 1;
                }
                return 0;
            }
            return 0;
        });
    };

    return {
        componentId: id,
        accessor,
        label,
        subComponents: mapSubComponents(),
        isNew: newestComponents.includes(id),
        isRecentDuplicate: recentDuplicates.includes(id),
        expPerimeterUnits,
        modelUnits,
        insConfig,
        configUnits,
        skirtValue,
        thermalBreakValue,
        skirtUnits,
        thermalBreakUnits,
        isDrawingComponent: !!drawingComponent,
        contentOpen,
        mapSubComponents: mapSubComponents(),
        subCompObj,
    };
};

const mapDispatchToProps = (dispatch) => ({
    updateFoundationConfig: (updates) => dispatch(updateFoundationConfig(updates)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Slab);
