import React from "react";
import { Field } from "redux-form";
import Button from "components/Button";
import classes from "./style.module.scss";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import InputWithUnits from "components/Input/InputWithUnits";
import Export from "assets/images/icons/JSX/Export";
import netZeroClasses from "../style.module.scss";
import { getBaseUnits, getUnitOptions } from "utils/fields";

const Technical = ({ accessor, change, modelUnits, houseVolumeUnits, totalHouseVolume }) => {
    return (
        <>
            <div>
                <div className={classes.topRow}>
                    <h3 className={classes.technicalHeader}>Technical Information</h3>

                    {/* <Button smallPadding icon={Export} className={netZeroClasses.exportButton}>
                        <span>Export Net Zero Workbook </span>
                    </Button> */}
                </div>
                <div className={classes.comingSoonHeader}>
                    <h3>Full results alignment to be deployed with HOT2000 v11.12 integration</h3>
                </div>
                <InputRow gridTemplate="1fr 1fr 1fr">
                    <div>
                        <Field
                            component={Input}
                            name={`${accessor}.energyMonitoringDevice.deviceMake`}
                            type="text"
                            label="Energy Monitoring Device Make"
                        />
                    </div>
                    <Field
                        component={Input}
                        name={`${accessor}.energyMonitoringDevice.deviceModel`}
                        type="text"
                        label="Model"
                    />
                </InputRow>
            </div>
            <div>
                {/* Turns out that these fields are just tied to other values */}
                {/* <InputRow gridTemplate="1fr 1fr 1fr">
                    <div>

                        <Field
                            component={Input}
                            name={`${accessor}.needsNewAccessor`}
                            type="text"
                            label="% Better than Reference - Envelope"
                            disabled
                        />
                    </div>
                    <div>

                        <Field
                            component={Input}
                            name={`${accessor}.needsNewAccessor`}
                            type="text"
                            label="Space Cooling Threshold"
                            disabled
                        />
                    </div>
                </InputRow> */}
            </div>
            <div>
                <h3 className={classes.technicalHeader}>Building Envelope</h3>
                <div>
                    <h5 className={classes.inputRowHeader}>
                        NRCan Reference house: Energy Consumption Summary Report (ERS Reference House)
                    </h5>
                    <InputRow gridTemplate="1fr 1fr 1fr">
                        <div>
                            <div>
                                {/* <p className={classes.inputRowSubHeader}>ERS Reference house</p> */}
                                <Field
                                    component={InputWithUnits}
                                    name={`${accessor}.nrcanReferenceHouse.estimatedConsumption`}
                                    type="number"
                                    label="Estimated space heating energy consumption"
                                    disabled
                                    units={{
                                        base: ["MJ"],
                                        options: ["MJ"],
                                        selected: ["MJ"],
                                        unitType: "energy",
                                        accessor: `${accessor}.nrcanReferenceHouse.estimatedConsumption_u`,
                                    }}
                                />
                            </div>
                        </div>
                    </InputRow>
                </div>
                <div>
                    <h4 className={classes.inputRowHeader}>Annual space heating system load</h4>
                    <InputRow gridTemplate="1fr 1fr 1fr">
                        <Field
                            component={InputWithUnits}
                            name={`${accessor}.annualSpaceHeatingSysLoad.referenceHouse`}
                            type="number"
                            label="ERS Reference House"
                            disabled
                            units={{
                                base: ["MJ"],
                                options: ["MJ"],
                                selected: ["MJ"],
                                unitType: "energy",
                                accessor: `${accessor}.annualSpaceHeatingSysLoad.referenceHouse_u`,
                            }}
                        />
                        <Field
                            component={InputWithUnits}
                            name={`${accessor}.annualSpaceHeatingSysLoad.houseWithStandardConditions`}
                            type="number"
                            label="Proposed house with standard operating conditions"
                            disabled
                            units={{
                                base: ["MJ"],
                                options: ["MJ"],
                                selected: ["MJ"],
                                unitType: "energy",
                                accessor: `${accessor}.annualSpaceHeatingSysLoad.houseWithStandardConditions_u`,
                            }}
                        />
                    </InputRow>
                </div>
                <h4 className={classes.inputRowHeader}>Building Envelope Performance Target</h4>
                <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                    <Field
                        component={InputWithUnits}
                        name={`${accessor}.performanceTarget.refHouseHeatingConsumption`}
                        type="number"
                        label="Ref. house space heating consumption"
                        disabled
                        units={{
                            base: ["MJ"],
                            options: ["MJ"],
                            selected: ["MJ"],
                            unitType: "energy",
                            accessor: `${accessor}.performanceTarget.refHouseHeatingConsumption_u`,
                        }}
                    />
                    <Field
                        component={InputWithUnits}
                        name={`${accessor}.performanceTarget.spaceHeatingTarget`}
                        type="number"
                        label="Space heating performance target"
                        disabled
                        units={{
                            base: ["MJ"],
                            options: ["MJ"],
                            selected: ["MJ"],
                            unitType: "energy",
                            accessor: `${accessor}.performanceTarget.spaceHeatingTarget_u`,
                        }}
                    />
                    <Field
                        component={InputWithUnits}
                        name={`${accessor}.performanceTarget.refHvacHouseEnergyConsumption`}
                        type="number"
                        label="House energy consumption (ref. HVAC)"
                        disabled
                        units={{
                            base: ["MJ"],
                            options: ["MJ"],
                            selected: ["MJ"],
                            unitType: "energy",
                            accessor: `${accessor}.performanceTarget.refHvacHouseEnergyConsumption_u`,
                        }}
                    />
                    <></>
                </InputRow>
                <InputRow gridTemplate="1fr 1fr 1fr">
                    <Field
                        component={InputWithUnits}
                        name={`${accessor}.performanceTarget.improvementOverCodeRefHouse`}
                        type="number"
                        label="Envelope % Better than Ref. House"
                        disabled
                        units={{
                            base: ["%"],
                            options: ["%"],
                            selected: ["%"],
                            unitType: "fraction",
                            accessor: `${accessor}.performanceTarget.improvementOverCodeRefHouse_u`,
                        }}
                    />
                </InputRow>
            </div>
            <div>
                <h3 className={classes.technicalHeader}>Space Cooling</h3>
                <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                    <Field
                        component={InputWithUnits}
                        name={`${accessor}.spaceCooling.houseVolume`}
                        type="number"
                        label="House Volume"
                        disabled
                        change={change}
                        units={{
                            base: getBaseUnits("totalHouseVolume", modelUnits),
                            options: ["m3", "ft3"],
                            selected: houseVolumeUnits,
                            unitType: "volume",
                            accessor: `${accessor}.spaceCooling.houseVolume_u`,
                        }}
                        setValue={totalHouseVolume}
                    />
                    <Field
                        component={InputWithUnits}
                        name={`${accessor}.spaceCooling.annSensibleLoad`}
                        type="number"
                        label="Total Annual Sensible Load"
                        disabled
                        units={{
                            base: ["MJ"],
                            options: ["MJ"],
                            selected: ["MJ"],
                            unitType: "energy",
                            accessor: `${accessor}.spaceCooling.annSensibleLoad_u`,
                        }}
                    />
                    <Field
                        component={InputWithUnits}
                        name={`${accessor}.spaceCooling.annLatentLoad`}
                        type="number"
                        label="Total annual latent Load"
                        disabled
                        units={{
                            base: ["MJ"],
                            options: ["MJ"],
                            selected: ["MJ"],
                            unitType: "energy",
                            accessor: `${accessor}.spaceCooling.annLatentLoad_u`,
                        }}
                    />
                    <Field
                        component={InputWithUnits}
                        name={`${accessor}.spaceCooling.annCoolingLoad`}
                        type="number"
                        label="Total annual cooling Load"
                        disabled
                        units={{
                            base: ["MJ"],
                            options: ["MJ"],
                            selected: ["MJ"],
                            unitType: "energy",
                            accessor: `${accessor}.spaceCooling.annCoolingLoad_u`,
                        }}
                    />
                </InputRow>
                <div>
                    <h4 className={classes.inputRowHeader}>Space Cooling Requirement</h4>
                    <InputRow gridTemplate="1fr 1fr 1fr">
                        <Field
                            component={InputWithUnits}
                            name={`${accessor}.spaceCooling.estCoolingLoad`}
                            type="number"
                            label="Estimated Space Cooling Load"
                            disabled
                            units={{
                                base: ["MJ/m3"],
                                options: ["MJ/m3"],
                                selected: ["MJ/m3"],
                                unitType: "energy",
                                accessor: `${accessor}.spaceCooling.estCoolingLoad_u`,
                            }}
                        />
                    </InputRow>
                </div>
            </div>
        </>
    );
};

export default Technical;
