import types from "./types";
import { updateObject } from "utils";

export const initialState = {
    packageResults: {},
    loading: false,
    error: false,
    selectedPackage: "base",
    selectedCase: "socBaseCase",
    linkedBaselineResults:{}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.SET_RESULTS:
            return setResults(state, action);
        case types.SET_LINKED_RESULTS:
            return setLinkedResults(state, action);
        case types.TOGGLE_RESULTS_LOADING:
            return toggleLoading(state, action);
        case types.SET_RESULTS_ERROR:
            return setError(state, action);
        case types.RESET_RESULTS:
            return resetResults(state);
        case types.CHANGE_PACKAGE:
            return changePackage(state, action);
        case types.CHANGE_CASE:
            return changeCase(state, action);
        default:
            return state;
    }
};

const toggleLoading = (state, action) =>
    updateObject(state, {
        loading: action.isLoading,
    });

const changePackage = (state, action) =>
    updateObject(state, {
        selectedPackage: action.pkg,
    });

const changeCase = (state, action) =>
    updateObject(state, {
        selectedCase: action.cse,
    });

const setResults = (state, action) =>
    updateObject(state, {
        packageResults: {
            ...state.packageResults,
            ...action.packageResults,
        },
    });

const setLinkedResults = (state, action) => {
    // console.log('state', state)
    // console.log('action', action)
    return updateObject(state, {
        ...state,
        linkedBaselineResults: action
    });
}

const setError = (state, action) => {
    return updateObject(state, {
        error: action.error,
    });
};

const resetResults = (state) => initialState;
