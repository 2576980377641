import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import FinancialValuation from "./";

const mapStateToProps = ({ form }, { accessor, formName = "model", change }) => {
    const selector = formValueSelector(formName);

    return {
        accessor,
        formName,
        change,
    };
};

const mergeProps = (state, dispatch, own) => ({
    ...state,
    ...dispatch,
    ...own,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(FinancialValuation);
