import React, { useState } from "react";
import classes from "./style.module.scss";
import sharedClasses from "features/Model/sharedStyles.module.scss";
import { Field, unregisterField } from "redux-form";
import { getOptions, getFirstOption, getBaseUnits, getUnitOptions } from "utils/fields";
import { getDefaultFurnaceSpecs } from "utils/mechanicals";
import { required, maxLength, getValidation, getDecimalPlaces } from "utils/fieldValidation";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import InputWithUnits from "components/Input/InputWithUnits";
import Select from "components/Input/Select";
import Checkbox from "components/Input/Checkbox";
import Button from "components/Button";

const charLim100 = maxLength(100);
const furnaceEnerSourceValidation = getValidation("furnaceEnergySource");
const boilerEnerSourceValidation = getValidation("boilerEnergySource");
const furnaceEquipTypeValidation = getValidation("furnaceEquipType");
const boilerEquipTypeValidation = getValidation("boilerEquipType");
const furnaceSwitchTempValidation = getValidation("furnaceSwitchoverTemp");
const boilerSwitchTempValidation = getValidation("boilerSwitchoverTemp");

const furnaceCapacityValidation = getValidation("furnaceOutputCapacity");
const boilerCapacityValidation = getValidation("boilerOutputCapacity");

const furnaceSizingFactorValidation = getValidation("furnaceSizingFactor");
const boilerSizingFactorValidation = getValidation("boilerSizingFactor");

const furnaceEffValidation = getValidation("furnaceEff");
const boilerEffValidation = getValidation("boilerEff");

const furnacePilotValidation = getValidation("furnacePilotLight");
const boilerPilotValidation = getValidation("boilerPilotLight");
const furnaceFlueValidation = getValidation("furnaceFlueDiameter");
const boilerFlueValidation = getValidation("boilerFlueDiameter");

const selectValidation = ({ system, field }) =>
    ({
        furnace: {
            energySource: furnaceEnerSourceValidation,
            equipType: furnaceEquipTypeValidation,
            switchoverTemp: furnaceSwitchTempValidation,
            outputCapacity: furnaceCapacityValidation,
            sizingFactor: furnaceSizingFactorValidation,
            efficiency: furnaceEffValidation,
            pilotLight: furnacePilotValidation,
            flueDiameter: furnaceFlueValidation,
        },
        boiler: {
            energySource: boilerEnerSourceValidation,
            equipType: boilerEquipTypeValidation,
            switchoverTemp: boilerSwitchTempValidation,
            outputCapacity: boilerCapacityValidation,
            sizingFactor: boilerSizingFactorValidation,
            efficiency: boilerEffValidation,
            pilotLight: boilerPilotValidation,
            flueDiameter: boilerFlueValidation,
        },
    }[system][field]);

export default ({
    accessor,
    currentPrimarySystem,
    energySourceId,
    equipTypeId,
    isDualFuel,
    effValue,
    switchoverTempUnits,
    capacityId,
    capacityValue,
    capacityUnits,
    pilotLightValue,
    flueDiameterValue,
    pilotUnits,
    flueUnits,
    modelUnits,
    change,
    isRecentVintage = false,
    isSteadyState,
    disableDualFuel,
}) => {
    const sysLabel = (type) =>
        ({
            furnace: "Furnace",
            boiler: "Boiler",
        }[type]);

    return (
        <>
            <InputRow gridTemplate="23.4% 23.4% 10rem 23.4%">
                <Field
                    component={Input}
                    name={`${accessor}.system.${currentPrimarySystem}.manufacturer`}
                    type="text"
                    label="Manufacturer Name"
                    placeholder="Enter Manufacturer Name"
                    validate={charLim100}
                />
                <Field
                    component={Input}
                    name={`${accessor}.system.${currentPrimarySystem}.model`}
                    type="text"
                    label="Model Name"
                    placeholder="Enter Model Name"
                    validate={charLim100}
                />
                <Field
                    component={Checkbox}
                    name={`${accessor}.system.${currentPrimarySystem}.energyStar`}
                    label="Energy Star"
                    large
                    className={classes.checkbox}
                    type="checkbox"
                />
                <Field
                    component={Checkbox}
                    name={`${accessor}.system.${currentPrimarySystem}.epaCsa`}
                    label="EPA/CSA"
                    large
                    className={classes.checkbox}
                    type="checkbox"
                    disabled={energySourceId <= 3}
                    info={"For solid fuel-fired equipment"}
                />
            </InputRow>
            <InputRow gridTemplate="23.4% 23.4% 10rem 23.4%">
                <Field
                    component={Select}
                    name={`${accessor}.system.${currentPrimarySystem}.energySource`}
                    options={getOptions({ fieldKey: `${currentPrimarySystem}EnergySource` })}
                    label="Energy Source"
                    placeholder="Choose Energy Source"
                    validate={selectValidation({ system: currentPrimarySystem, field: "energySource" })}
                    onChange={({ id }) => {
                        const firstOption = getFirstOption({ indKey: id, depKey: `${currentPrimarySystem}EquipType` });
                        change(`${accessor}.system.${currentPrimarySystem}.equipmentType`, firstOption);

                        const {
                            efficiency = 80,
                            pilotLight = 0,
                            flueDiameter = 0,
                        } = getDefaultFurnaceSpecs(firstOption.id) || {};
                        change(`${accessor}.system.${currentPrimarySystem}.specifications.efficiency`, efficiency);
                        change(`${accessor}.system.${currentPrimarySystem}.specifications.pilotLight`, pilotLight);
                        change(`${accessor}.system.${currentPrimarySystem}.specifications.flueDiameter`, flueDiameter);
                    }}
                />
                <Field
                    component={Select}
                    name={`${accessor}.system.${currentPrimarySystem}.equipmentType`}
                    options={getOptions({ fieldKey: `${currentPrimarySystem}EquipType`, indKey: energySourceId })}
                    label="Equipment Type"
                    placeholder="Choose Equipment Type"
                    validate={selectValidation({ system: currentPrimarySystem, field: "equipType" })}
                    onChange={({ id }) => {
                        const { efficiency = 80, pilotLight = 0, flueDiameter = 0 } = getDefaultFurnaceSpecs(id) || {};
                        change(`${accessor}.system.${currentPrimarySystem}.specifications.efficiency`, efficiency);
                        change(`${accessor}.system.${currentPrimarySystem}.specifications.pilotLight`, pilotLight);
                        change(`${accessor}.system.${currentPrimarySystem}.specifications.flueDiameter`, flueDiameter);
                    }}
                />
                <Field
                    component={Checkbox}
                    name={`${accessor}.system.${currentPrimarySystem}.isBiEnergy`}
                    label="Dual Fuel System"
                    large
                    className={classes.checkbox}
                    type="checkbox"
                    disabled={disableDualFuel}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.system.${currentPrimarySystem}.switchoverTemp`}
                    label="Dual Fuel Switchover Temperature"
                    placeholder="0.00"
                    validate={selectValidation({ system: currentPrimarySystem, field: "switchoverTemp" })}
                    decimals={getDecimalPlaces(`${currentPrimarySystem}SwitchoverTemp`)}
                    change={change}
                    disabled={!isDualFuel}
                    units={{
                        base: getBaseUnits(`${currentPrimarySystem}SwitchoverTemp`, modelUnits),
                        options: getUnitOptions("temperature"),
                        selected: switchoverTempUnits,
                        unitType: "temperature",
                        accessor: `${accessor}.system.${currentPrimarySystem}.switchoverTemp_u`,
                    }}
                    info={
                        "A system in which electric baseboards function until a specified (switchover) outdoor temperature is reached. Not permitted with heat pumps."
                    }
                />
            </InputRow>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr 1fr">
                <Field
                    component={Select}
                    name={`${accessor}.system.${currentPrimarySystem}.specifications.outputCapacity`}
                    options={[
                        {
                            label: "User Specified",
                            value: {
                                id: 0,
                                value: capacityValue,
                                uiUnits: "kW",
                            },
                        },
                        {
                            label: "Calculated",
                            value: {
                                id: 1,
                                value: capacityValue,
                                uiUnits: "kW",
                            },
                        },
                    ]}
                    label="Capacity Type"
                    placeholder="Choose Input Type"
                    validate={selectValidation({ system: currentPrimarySystem, field: "outputCapacity" })}
                    onChange={({ id }) => {
                        if (id === 1) {
                            change(`${accessor}.fansAndPumps.power.isCalculated`, true);
                        }
                    }}
                    info={
                        "If the capacity is unknown, set field to Calculated. Note that the calculated value will not appear in Volta SNAP, but will be updated prior to simulating in HOT2000."
                    }
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.system.${currentPrimarySystem}.specifications.outputCapacity.value`}
                    label="Output Capacity"
                    placeholder="0.00"
                    validate={selectValidation({ system: currentPrimarySystem, field: "outputCapacity" })}
                    decimals={getDecimalPlaces(`${currentPrimarySystem}OutputCapacity`)}
                    change={change}
                    disabled={capacityId === 1}
                    units={{
                        base: getBaseUnits(`${currentPrimarySystem}OutputCapacity`, modelUnits),
                        options: getUnitOptions("power"),
                        selected: capacityUnits,
                        unitType: "power",
                        accessor: `${accessor}.system.${currentPrimarySystem}.specifications.outputCapacity_u`,
                    }}
                />
                <Field
                    component={Input}
                    type="number"
                    name={`${accessor}.system.${currentPrimarySystem}.specifications.sizingFactor`}
                    label="Sizing Factor"
                    placeholder={0.0}
                    validate={selectValidation({ system: currentPrimarySystem, field: "sizingFactor" })}
                    decimals={getDecimalPlaces(`${currentPrimarySystem}SizingFactor`)}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.system.${currentPrimarySystem}.specifications.efficiency`}
                    label="Efficiency"
                    placeholder="0.00"
                    validate={selectValidation({ system: currentPrimarySystem, field: "efficiency" })}
                    decimals={getDecimalPlaces(`${currentPrimarySystem}Eff`)}
                    change={change}
                    setValue={effValue}
                    units={{
                        base: getBaseUnits(`${currentPrimarySystem}Eff`, modelUnits),
                        options: ["%"],
                        selected: "%",
                        unitType: "fraction",
                        accessor: `${accessor}.system.${currentPrimarySystem}.specifications.efficiency_u`,
                    }}
                    warning={energySourceId === 2 && isRecentVintage ? "oilHeatingLowEff" : ""}
                />
                <Field
                    component={Select}
                    name={`${accessor}.system.${currentPrimarySystem}.specifications.isSteadyState`}
                    options={[
                        {
                            label: "Steady State",
                            value: true,
                        },
                        {
                            label: "AFUE",
                            value: false,
                        },
                    ]}
                    label="Efficiency Type"
                    placeholder="Choose Efficiency Type"
                    info={
                        "For condensing systems, always use the AFUE rating. For non-condensing systems, use AFUE whenever possible."
                    }
                    warning={equipTypeId === 5 && isSteadyState ? "condensingHeatingAfue" : ""}
                />
            </InputRow>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.system.${currentPrimarySystem}.specifications.pilotLight`}
                    label="Pilot Light"
                    placeholder="0.00"
                    validate={selectValidation({ system: currentPrimarySystem, field: "pilotLight" })}
                    decimals={getDecimalPlaces(`${currentPrimarySystem}PilotLight`)}
                    change={change}
                    disabled={false}
                    setValue={pilotLightValue}
                    units={{
                        base: getBaseUnits(`${currentPrimarySystem}PilotLight`, modelUnits),
                        options: getUnitOptions("dailyEnergy"),
                        selected: pilotUnits,
                        unitType: "dailyEnergy",
                        accessor: `${accessor}.system.${currentPrimarySystem}.specifications.pilotLight_u`,
                    }}
                    info={"Leave at the default value (variable based on system)."}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.system.${currentPrimarySystem}.specifications.flueDiameter`}
                    label="Flue Diameter"
                    placeholder="0.00"
                    validate={selectValidation({ system: currentPrimarySystem, field: "flueDiameter" })}
                    decimals={getDecimalPlaces(`${currentPrimarySystem}FlueDiameter`)}
                    change={change}
                    disabled={false}
                    setValue={flueDiameterValue}
                    units={{
                        base: getBaseUnits(`${currentPrimarySystem}FlueDiameter`, modelUnits),
                        options: getUnitOptions("length"),
                        selected: flueUnits,
                        unitType: "length",
                        accessor: `${accessor}.system.${currentPrimarySystem}.specifications.flueDiameter_u`,
                    }}
                    info={"Leave at the default value (variable based on system)."}
                />
            </InputRow>
        </>
    );
};
