import React, { useState } from "react";
import Tooltip from 'components/Tooltip';
import Move from 'assets/images/icons/JSX/Move';
import DeleteIcon from 'assets/images/icons/JSX/Delete';
import classes from "./style.module.scss";
import isEmpty from "lodash/isEmpty";

const Actions = ({
    dragHandleProps={},
    deleteLayer,
    accessor=""
}) => {
    const [moving, toggleMoving] = useState(false);

    return (
        <div className={classes.actions}>
            <div className={classes.delete} data-for={`dragTip-${accessor}`} data-tip="Delete layer" onClick={deleteLayer}>
                <DeleteIcon />
            </div>
            {!isEmpty(dragHandleProps) &&
                <div
                    {...dragHandleProps}
                >
                    <div
                        className={`${classes.move} ${moving ? classes.moving : ''}`}
                            onMouseDown={()=>toggleMoving(true)}
                            onMouseUp={()=>toggleMoving(false)}
                        {...dragHandleProps}
                    >
                        <div
                            data-for={`dragTip-${accessor}`}
                            data-tip="Move layer"
                        >
                            <Move />
                        </div>
                    </div>
                    <Tooltip id={`dragTip-${accessor}`} delay />
                </div>
            }
        </div>
       
    )
};

export default Actions;