import React, { useState, useEffect, useCallback } from 'react';
import classes from '../../style.module.scss';
import { Field } from 'redux-form';
import Button from 'components/Button';
import Select from 'components/Input/Select';
import InputWithUnits from 'components/Input/InputWithUnits';
import Input from 'components/Input';
import Checkbox from 'components/Input/Checkbox';
import { required, maxLength, getValidation, getDecimalPlaces } from 'utils/fieldValidation';
import { getBaseUnits,getUnitOptions } from 'utils/fields';
import { isEmpty } from 'lodash';
import {
    windowInsTypeLibCodesMap,
    windowInsTypeModelCodesMap,
    CodeFooter
} from 'features/Model/Enclosure/ComponentList/Window/Construction';
import Dialog from 'components/Dialog';
import debounce from "lodash/debounce";

const charLim100 = maxLength(100);
const windowInsTypeValidation = getValidation('windowInsType');
const headerValidation = getValidation('windowHeaderHeight');
const overhangValidation = getValidation('windowOverhangWidth');
const numIdenticalValidation = getValidation('windowNumber');

const Window = ({
    activeComponent,
    activeImage,
    componentOptions,
    handleSubmit,
    cancel,
    windowRValue,
    change,
    selectedCodeRef,
    rValUnits,
    primaryUnits,
    submitSucceeded,
    removeComponent,
    shape,
    transformer,
    setInitCode,
    modelCodes,
    libCodes,
    overhangUnits,
    headerUnits,
    setActiveComponent,
    savedModelRef,
    fieldsWithLinesAttached,
    updateComponentsToDelete,
    modelChange,
    componentModelId,
    isCalculatingRValue,
    updateCodeDrawer
}) => {
    const [deleteDialogOpen, toggleDeleteDialog] = useState(false);

    const handleTypeChange = useCallback(({ codeRef = '' })=>{    
        const modelMatch = modelCodes[codeRef] || {};
        if (!isEmpty(modelMatch)) {
            change('windowType_nomRVal', modelMatch.nominalRValue || 0);
            change('windowType_effRVal', modelMatch.nominalRValue || 0);
            change('shgc', modelMatch.shgc || 0);
            change('er', modelMatch.er || 0);
        } else {
            const {nominalRValue = 0, shgc = 0, er = 0} = libCodes[codeRef] || {};

            change('windowType_nomRVal', nominalRValue || 0);
            change('windowType_effRVal', nominalRValue || 0);
            change('shgc', shgc || 0);
            change('er', er || 0);
        }
    },[libCodes,modelCodes,change])

    useEffect(() => {
        if ((selectedCodeRef || '').includes('Window')) {
            modelChange(`stashedCodes.Window.${componentModelId}`, selectedCodeRef);
        }
    },[componentModelId, selectedCodeRef]);

    const saveToState = useCallback(debounce(e => {
        handleSubmit();
    }, 500),[]);

    const handleEditModelWindowCode = useCallback(async (event, codeRef, codeType) => {
        event.stopPropagation(); //Don't let click event bubble up to parent
        const initCodeType = codeType === "U" ? "windowUDef" : "windowType";
        const code = modelCodes[codeRef];
        await setInitCode(code, initCodeType);
        updateCodeDrawer({
            isOpen:true,
            codeName: "Window",
            isEditing: true,
            isDrawing: true,
            componentType: "Window",
            codeType,
            fieldAccessor: `windowType`,
            componentId:componentModelId,
            id: codeRef,
            modelFormChange: modelChange,
            drawingFormChange: change,
            drawingSave: saveToState,
            lastEdited: code.lastEdited || ''
        });
    }, [change, componentModelId, modelChange, modelCodes, setInitCode, updateCodeDrawer, saveToState]);

    const handleEditLibWindowCode = useCallback(async (event, codeRef, codeType) => {
        event.stopPropagation(); //Don't let click event bubble up to parent
        const initCodeType = codeType === "U" ? "windowUDef" : "windowType";
        const code = {...libCodes[codeRef], codeRef:codeRef, isLibCode:true };
        await setInitCode(code, initCodeType);
        updateCodeDrawer({
            isOpen:true,
            codeName: "Window",
            isEditing: true,
            isDrawing: true,
            componentType: "Window",
            codeType,
            fieldAccessor: `windowType`,
            componentId:componentModelId,
            id: codeRef,
            modelFormChange: modelChange,
            drawingFormChange: change,
            drawingSave: saveToState,
            lastEdited: code.lastEdited || ''
        });
    }, [change, componentModelId, modelChange, libCodes, setInitCode, updateCodeDrawer, saveToState]);
    
    return (
        <div>
            <Field
                className={classes.inputMarginBottom}
                component={Input}
                type="text"
                label="Window Name*"
                name="componentName"
                validate={[required, charLim100]}
                placeholder="Example: Front Window"
                onChange={saveToState}
            />
            <Field
                className={classes.inputMarginBottom}
                component={Input}
                type="number"
                name={`numIdentical`}
                label="Number of Identical Windows"
                placeholder={0}
                validate={numIdenticalValidation}
                decimals={getDecimalPlaces('windowNumber')}
                info={'In addition to size and construction details, identical windows must all face the same direction.'}
                onChange={saveToState}
            />
            <Field
                className={classes.inputMarginBottom}
                component={Select}
                options={componentOptions}
                name="attachTo"
                placeholder="Choose Component"
                searchPlaceholder="Search Components"
                label="Attach Window to Component"
                search={true}
                info={`This window will be added to your model when the drawing is saved.`}
                onChange={saveToState}
            />
            <Field
                className={classes.inputMarginBottom}
                component={InputWithUnits}
                type="number"
                name={`measurements.headerHeight`}
                label="Header Height"
                placeholder="0.00"
                validate={headerValidation}
                decimals={getDecimalPlaces('windowHeaderHeight')}
                isDrawingTip="This field is associated with a Line in the Drawing Capture. To detach, delete the Line or use it for another field."
                change={change}
                disabled={fieldsWithLinesAttached.includes('windowHeaderHeight')}
                isDrawing={fieldsWithLinesAttached.includes('windowHeaderHeight')}
                units={{
                    base:getBaseUnits('windowHeaderHeight', primaryUnits),
                    options:getUnitOptions('length'),
                    selected:headerUnits,
                    unitType:'length',
                    accessor:`measurements.headerHeight_u`
                }}
                onChange={saveToState}
                onUnitChange={saveToState}
            />
            <Field
                className={classes.inputMarginBottom}
                component={InputWithUnits}
                type="number"
                name={`measurements.overhangWidth`}
                label="Overhang Width"
                placeholder="0.00"
                validate={overhangValidation}
                decimals={getDecimalPlaces('windowOverhangWidth')}
                disabled={fieldsWithLinesAttached.includes('windowOverhangWidth')}
                isDrawing={fieldsWithLinesAttached.includes('windowOverhangWidth')}
                isDrawingTip="This field is associated with a Line in the Drawing Capture. To detach, delete the Line or use it for another field."
                change={change}
                units={{
                    base:getBaseUnits('windowOverhangWidth', primaryUnits),
                    options:getUnitOptions('length'),
                    selected:overhangUnits,
                    unitType:'length',
                    accessor:`measurements.overhangWidth_u`
                }}
                onChange={saveToState}
                onUnitChange={saveToState}
            />
            <Field
                className={`${classes.inputMarginBottom} ${classes.windowType}`}
                component={Select}
                type="number"
                name={`windowType`}
                options={[
                    ...windowInsTypeModelCodesMap(modelCodes, libCodes, handleEditModelWindowCode),
                    ...windowInsTypeLibCodesMap(libCodes, null),
                ]}
                label="Window Type"
                placeholder="Choose Window Type"
                searchPlaceholder="Search Window Codes"
                search={true}
                footer={()=><CodeFooter
                    toggleDrawer={(value) => 
                        updateCodeDrawer({
                            isOpen:value,
                            codeName: "Window",
                            isEditing: false,
                            isDrawing: true,
                            componentType: "Window",
                            codeType: "S",
                            fieldAccessor: `windowType`,
                            componentId:componentModelId,
                            modelFormChange: modelChange,
                            drawingFormChange: change,
                            drawingSave: saveToState
                        })
                    }
                    setInitCode={setInitCode}
                    codeType="windowInsType"
                />}
                isLoading={isCalculatingRValue}
                onChange={(values) => {
                    handleTypeChange(values);
                    saveToState(values);
                }}
            />
            <Field
                className={classes.inputMarginBottom}
                component={InputWithUnits}
                type="number"
                name={`windowType_effRVal`}
                label="R-Value"
                placeholder={0.00}
                disabled={true}
                validate={windowInsTypeValidation}
                setTouched={true}
                decimals={getDecimalPlaces('windowInsType')}
                change={change}
                setValue={windowRValue}
                units={{
                    base:getBaseUnits('windowInsType', primaryUnits),
                    options:getUnitOptions('thermalResistance'),
                    selected:rValUnits,
                    unitType:'thermalResistance',
                    accessor:`windowType_u`
                }}
                onChange={saveToState}
                onUnitChange={saveToState}
                isLoading={isCalculatingRValue}
            />
            <Field
                className={classes.inputMarginBottom}
                component={Checkbox}
                name={"energyStar"}
                label="Energy Star"
                type="checkbox"
                onChange={saveToState}
            />
            <div className={`${classes.buttonRow} ${classes.singleRow}`} style={{marginTop:'0.25rem'}}>
                {
                    cancel && !submitSucceeded ?
                        <Button
                            smallPadding
                            onClick={cancel}
                            type='hollow'
                            className={classes.cancelButton}
                        >
                            Cancel
                        </Button> :
                            <Button
                                smallPadding
                                onClick={()=>toggleDeleteDialog(true)}
                                type='hollow'
                                className={classes.deleteButton}
                            >
                                Delete
                            </Button> 
                }
            </div>
            <Dialog
                open={deleteDialogOpen}
                classes={{
                    paperClass:classes.dialogPaper
                }}
            >
                <div className={classes.dialogText}>
                    <h2 style={{maxWidth:'34rem'}}>Are you sure you want to delete this component?</h2>
                    <p style={{display:"block",maxWidth:"24rem",margin:"0 auto"}}>This component will be deleted from the model when Drawing Capture is saved.</p>
                </div>
                <div className={classes.dialogFooter}>
                    <Button type="hollow" onClick={()=>toggleDeleteDialog(false)}>
                        Cancel
                    </Button>
                    <Button type="red" onClick={async()=>{
                        if (savedModelRef) {
                            const refArray = savedModelRef.split('.');
                            const componentId = refArray[refArray.length - 1];
                            const componentType = componentId.split('-')[0];
                            const parentPath = savedModelRef.split(`.${componentType}.${componentId}`)[0];
                            await updateComponentsToDelete([{
                                id:componentId,
                                parentPath:`.${parentPath}`,
                                drawingType:'component'
                            }]);
                        }
                        await setActiveComponent('');
                        await removeComponent(activeImage, activeComponent);
                        if (shape) {
                            shape.destroy();
                            transformer.destroy();
                        }
                        toggleDeleteDialog(false);
                    }}>
                        Delete
                    </Button>
                </div>
            </Dialog>
        </div>
    )
};

export default Window;