import React from 'react';
import classes from './style.module.scss';
import isEmpty from 'lodash/isEmpty';
import convertUnit from 'utils/convertUnit';
import { capitalize } from 'utils';

const DisplayComponentInfo = ({
    component={},
    conversionFactor=1,
    displayUnits='m'
}) => {
    const { height, width, type } = component;
    // const convertedHeight = height * conversionFactor;

    const convertedHeight = convertUnit({
        value:height*conversionFactor,
        type:'drawingScale',
        inputUnit:'m',
        outputUnit:displayUnits
    });

    const convertedWidth = convertUnit({
        value:width*conversionFactor,
        type:'drawingScale',
        inputUnit:'m',
        outputUnit:displayUnits
    });

    return (
        <div className={classes.componentInfo}>
            <h5 className={classes.componentTitle}>{capitalize(type)}</h5>
            <div>
                <span className={classes.label}>Width:</span> <span className={classes.value}>{convertedWidth.toFixed(4)}{displayUnits}</span>
            </div>
            <div>
                <span className={classes.label}>Height:</span> <span className={classes.value}>{convertedHeight.toFixed(4)}{displayUnits}</span>
            </div>
        </div>
    )
};

const DisplayPolygonInfo = ({
    polygon={},
    conversionFactor=1,
    displayUnits='m'
}) => {
    const { perimeter, area } = polygon;

    const convertedPerimeter = convertUnit({
        value:perimeter*conversionFactor,
        type:'length',
        inputUnit:'m',
        outputUnit:displayUnits
    });

    const convertedArea = convertUnit({
        value:area*Math.pow(conversionFactor, 2),
        type:'area',
        inputUnit:'m2',
        outputUnit:`${displayUnits}2`
    });

    return (
        <div className={classes.componentInfo}>
            <h5 className={classes.componentTitle}>Polygon</h5>
            <div>
                <span className={classes.label}>Perimeter:</span> <span className={classes.value}>{convertedPerimeter.toFixed(4)}{displayUnits}</span>
            </div>
            <div>
                <span className={classes.label}>Area:</span> <span className={classes.value}>{convertedArea.toFixed(4)}{displayUnits}&sup2;</span>
            </div>
        </div>
    )
};

const DisplayLineInfo = ({
    line={},
    conversionFactor=1,
    displayUnits='m'
}) => {
    const { length } = line;

    const convertedLength = convertUnit({
        value:length*conversionFactor,
        type:'length',
        inputUnit:'m',
        outputUnit:displayUnits
    });

    return (
        <div className={classes.componentInfo}>
            <h5 className={classes.componentTitle}>Line</h5>
            <div>
                <span className={classes.label}>Length:</span> <span className={classes.value}>{convertedLength.toFixed(4)}{displayUnits}</span>
            </div>
        </div>
    )
};

export default React.memo(({
    activeImage,
    scaleValue,
    component,
    polygon,
    conversionFactor,
    displayUnits,
    line
}) => (
    <div className={classes.info}>
        <h3>Info</h3>
        {
            activeImage && scaleValue &&
                <div className={classes.scaleValue}>
                    <span className={classes.label}>Scale:</span> <span className={classes.value}>{scaleValue}</span>
                </div>
        }
        {
            !isEmpty(polygon) &&
                <DisplayPolygonInfo
                    polygon={polygon}
                    conversionFactor={conversionFactor}
                    displayUnits={displayUnits}
                />
        }
        {
            !isEmpty(component) &&
                <DisplayComponentInfo
                    component={component}
                    conversionFactor={conversionFactor}
                    displayUnits={displayUnits}
                />
        }
        {
            !isEmpty(line) &&
                <DisplayLineInfo
                    line={line}
                    conversionFactor={conversionFactor}
                    displayUnits={displayUnits}
                />
        }
    </div>
));