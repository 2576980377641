export const getFValidations = (input, type, field = "", property = "") => {
    switch (type) {
        case "string": {
            if (input.length > 0) {
                return {
                    err: false,
                    msg: "",
                };
            } else {
                return {
                    err: true,
                    msg: "Must have at least one character",
                };
            }
        }
        case "count": {
            if (input >= 0 && input < 33) {
                return {
                    err: false,
                    msg: "",
                };
            } else {
                return {
                    err: true,
                    msg: "Must be a number between 0 and 32",
                };
            }
        }
        default:
            switch (field) {
                case "Height 1":
                case "Height 2":
                case "Pitch": {
                    if (input >= 0 && input < 9999.99) {
                        return {
                            err: false,
                            msg: "",
                        };
                    } else {
                        return {
                            err: true,
                            msg: "Must be a number between 0 and 9999",
                        };
                    }
                }
                default: {
                    switch (property) {
                        case "interiorWallLength":
                        case "buriedWalkoutDepth":
                        case "shallowWalkoutDepth":
                        case "upperGradeLength":
                        case "walkoutSlabLength":
                        case "floorHeaderHeight":
                            if (input >= 0 && input < 9999.99) {
                                return {
                                    err: false,
                                    msg: "",
                                };
                            } else {
                                return {
                                    err: true,
                                    msg: "Must be a number between 0.01 and 9999",
                                };
                            }
                        default:
                            if (input >= 0.01 && input < 9999.99) {
                                return {
                                    err: false,
                                    msg: "",
                                };
                            } else {
                                return {
                                    err: true,
                                    msg: "Must be a number between 0.01 and 9999",
                                };
                            }
                    }
                }
            }
    }
};
