import React from 'react';

export default () => (
    <svg width="17px" height="4px" viewBox="0 0 17 4" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="Building---Foundation" transform="translate(-468.000000, -620.000000)" fill="#9F9F9F">
            <g id="Work-Space" transform="translate(248.000000, 154.000000)">
                <g id="Group-3" transform="translate(0.000000, 92.000000)">
                    <g id="Main" transform="translate(0.000000, 60.000000)">
                        <g id="Foundation" transform="translate(39.000000, 250.000000)">
                            <g id="Dots" transform="translate(181.000000, 64.000000)">
                                <path d="M2,0 C3.1045695,0 4,0.8954305 4,2 C4,3.1045695 3.1045695,4 2,4 C0.8954305,4 0,3.1045695 0,2 C0,0.8954305 0.8954305,0 2,0 Z M8.4,0 C9.5045695,0 10.4,0.8954305 10.4,2 C10.4,3.1045695 9.5045695,4 8.4,4 C7.2954305,4 6.4,3.1045695 6.4,2 C6.4,0.8954305 7.2954305,0 8.4,0 Z M14.8,0 C15.9045695,0 16.8,0.8954305 16.8,2 C16.8,3.1045695 15.9045695,4 14.8,4 C13.6954305,4 12.8,3.1045695 12.8,2 C12.8,0.8954305 13.6954305,0 14.8,0 Z" id="Oval-2"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)