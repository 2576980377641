import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import isEmpty from 'lodash/isEmpty';
import HRV from './';

const mapStateToProps = (
    {
        form,
        model:{
            contentOpen=false,
        }={}
    },
    {
        accessor,
        formName='model'
    }
) => {
    const selector = formValueSelector(formName);
    const modelSelector = formValueSelector('model');

    const hrvSystemObj = selector({form}, `${accessor}`) || {};
    const label = selector({form}, `${accessor}.label`) || {};
    const {id:ventTypeId} = selector({form}, `${accessor}.type`) || {};
    const supplyFlowValue = selector({form}, `${accessor}.supplyFlowrate`);
    const exhaustFlowValue = selector({form}, `${accessor}.exhaustFlowrate`);
    const fanPower1Value = selector({form}, `${accessor}.fanPower1`);
    const fanPower2Value = selector({form}, `${accessor}.fanPower2`);
    const eff1Value = selector({form}, `${accessor}.efficiency1`);
    const eff2Value = selector({form}, `${accessor}.efficiency2`);


    const supplyFlowUnits = selector({form}, `${accessor}.supplyFlowrate_u`);
    const exhaustFlowUnits = selector({form}, `${accessor}.exhaustFlowrate_u`);
    const fanPower1Units = selector({form}, `${accessor}.fanPower1_u`);
    const fanPower2Units = selector({form}, `${accessor}.fanPower2_u`);
    const temp1Units = selector({form}, `${accessor}.temperatureCondition1_u`);
    const temp2Units = selector({form}, `${accessor}.temperatureCondition2_u`);
    const eff1Units = selector({form}, `${accessor}.efficiency1_u`);
    const eff2Units = selector({form}, `${accessor}.efficiency2_u`);
    const isDefaultFanpower = selector({form}, `${accessor}.isDefaultFanpower`);

    const preheaterUnits = selector({form}, `${accessor}.preheaterCapacity_u`);
    const lowTempUnits = selector({form}, `${accessor}.lowTempVentReduction_u`);
    const coolingEffUnits = selector({form}, `${accessor}.coolingEfficiency_u`);

    const ductSupplyLengthUnits = selector({form}, `${accessor}.ducts.supply.length_u`);
    const ductSupplyDiameterUnits = selector({form}, `${accessor}.ducts.supply.length_u`);
    const ductSupplyInsUnits = selector({form}, `${accessor}.ducts.supply.length_u`);
    const ductExhaustLengthUnits = selector({form}, `${accessor}.ducts.exhaust.length_u`);
    const ductExhaustDiameterUnits = selector({form}, `${accessor}.ducts.exhaust.length_u`);
    const ductExhaustInsUnits = selector({form}, `${accessor}.ducts.exhaust.length_u`);

    const { id: operationScheduleId, value: operationScheduleValue} = selector({form}, `${accessor}.operationSchedule`) || {};
    const operationScheduleUnits = selector({form}, `${accessor}.operationSchedule_u`);

    const modelUnits = modelSelector({form}, `modelData.uiSettings.primaryUnits`) || {};

    const eStarManufacturerValue = selector({form}, `${accessor}.eStar.selected.manufacturer`) || {};
    const eStarModelValue = selector({form}, `${accessor}.eStar.selected.model`) || {};

    return {
        accessor,
        hrvSystemObj,
        label,
        ventTypeId,
        supplyFlowValue,
        exhaustFlowValue,
        supplyFlowUnits,
        exhaustFlowUnits,
        fanPower1Value,
        fanPower2Value,
        fanPower1Units,
        fanPower2Units,
        temp1Units,
        temp2Units,
        eff1Value,
        eff2Value,
        eff1Units,
        eff2Units,
        isDefaultFanpower,
        operationScheduleId,
        operationScheduleValue,
        operationScheduleUnits,
        preheaterUnits,
        lowTempUnits,
        coolingEffUnits,
        ductSupplyLengthUnits,
        ductSupplyDiameterUnits,
        ductSupplyInsUnits,
        ductExhaustLengthUnits,
        ductExhaustDiameterUnits,
        ductExhaustInsUnits,
        modelUnits,
        eStarManufacturerValue,
        eStarModelValue,
        contentOpen,
    }
};

const mapDispatchToProps = () => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HRV);