import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import Measurements from "./";
import isEmpty from "lodash/isEmpty";

const mapStateToProps = ({ form }, { accessor }) => {
    const selector = formValueSelector("model");
    const lengthUnits = selector({ form }, `${accessor}.measurements.length_u`);
    const lengthDrawing = selector({ form }, `${accessor}.measurements.length_drawingRef`) || {};
    const floorAreaUnits = selector({ form }, `${accessor}.measurements.area_u`);
    const modelUnits = selector({ form }, `modelData.uiSettings.primaryUnits`) || {};

    const expFloorLengthEquation = selector({ form }, `${accessor}.measurements.length_eqn`);
    const expFloorAreaEquation = selector({ form }, `${accessor}.measurements.area_eqn`);

    return {
        accessor,
        modelUnits,
        lengthUnits,
        floorAreaUnits,
        lengthDrawing: !isEmpty(lengthDrawing),
        expFloorLengthEquation,
        expFloorAreaEquation,
    };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Measurements);
