import React from "react";
import SectionHeading from "components/SectionHeading";
import classes from "../style.module.scss";

export default () => (
    <div>
        <SectionHeading
            title="Product Roadmap"
            subtitle="We're working hard to make improvements and develop new features. Here's a peak at what's coming soon!"
        />
        <div className={classes.contentWrapper}>
            <div className={classes.rowBlock}>
                <div className={classes.left}>
                    <h3>
                        Code Management <br />
                        and User Defined Codes
                    </h3>
                    <p>
                        <strong>Status:</strong> In development
                    </p>
                    <p>
                        <strong>Scheduled:</strong> Summer 2021
                    </p>
                </div>
                <div className={classes.right}>
                    <p>
                        Volta SNAP allows users to upload their code library files and use those codes to define
                        component types, as well as create new "Standard" codes while editing model parameters. However,
                        we have not yet implemented "User Defined" codes or a dedicated interface to manage a code
                        library within the application. These features will be rolled out very soon.
                    </p>
                </div>
            </div>
            <div className={classes.rowBlock}>
                <div className={classes.left}>
                    <h3>Favourite Systems</h3>
                    <p>
                        <strong>Status:</strong> In development
                    </p>
                    <p>
                        <strong>Scheduled:</strong> Summer 2021
                    </p>
                </div>
                <div className={classes.right}>
                    <p>
                        The Favourite Systems feature will allow users to save individual mechanical systems or packages
                        of systems. This feature requires only minor adjustments to the software and will be implemented
                        very soon.
                    </p>
                </div>
            </div>
            <div className={classes.rowBlock}>
                <div className={classes.left}>
                    <h3>
                        User Defined Codes <br />
                        (Code Editor)
                    </h3>
                    <p>
                        <strong>Status:</strong> In development
                    </p>
                    <p>
                        <strong>Scheduled:</strong> Summer 2021
                    </p>
                </div>
                <div className={classes.right}>
                    <p>
                        Since the "Code Selector" is the preferred method of defining envelope component types per
                        EnerGuide guidelines, the development team prioritized this method of defining codes. At this
                        time, "User Defined" codes cannot be created from scratch in the Volta SNAP.
                    </p>
                    <p>
                        However, Volta SNAP's backend, which includes the .h2k importing and exporting functions, does
                        support User Defined codes. This means that, if a user uploads their code library which has User
                        Defined codes, those codes are selectable within Volta SNAP. It should be noted that while the
                        associated R-Values might not appear when selecting these codes, the component will still be
                        simulated in HOT2000 as expected.
                    </p>
                </div>
            </div>
            <div className={classes.rowBlock}>
                <div className={classes.left}>
                    <h3>Upgrades</h3>
                    <p>
                        <strong>Status:</strong> In development
                    </p>
                    <p>
                        <strong>Scheduled:</strong> April 2021 - Ongoing
                    </p>
                </div>
                <div className={classes.right}>
                    <p>
                        The development team would like to reimagine how upgrades are applied to components in a more
                        streamlined manner. While the back-end functionality to support upgrades exists, the ability to
                        add upgrades in the front-end has not yet been implemented. After getting more familiar with the
                        software, let us know how you think we can make applying upgrades easier.
                    </p>
                    <p>
                        Beyond adding upgrades to components, once we begin to implement Volta SNAP's analytics, results
                        will be presented for all permutations of upgrades selected. This will allow users to analyze
                        many individual upgrades and combinations of upgrades at once.
                    </p>
                </div>
            </div>
            <div className={classes.rowBlock}>
                <div className={classes.left}>
                    <h3>
                        Other Miscellaneous <br />
                        HOT2000 Inputs
                    </h3>
                    <p>
                        <strong>Status:</strong> In development
                    </p>
                    <p>
                        <strong>Scheduled:</strong> Ongoing
                    </p>
                </div>
                <div className={classes.right}>
                    <p>
                        Certain sets of fields (e.g., User Specified Roof Cavity Inputs) have not yet been integrated
                        into the Volta SNAP interface. Generally, fields were omitted if they seemed to be rarely used.
                        By giving us feedback on what you think is missing, you can help us prioritize the most
                        important additions to the app.
                    </p>
                </div>
            </div>
            <div className={classes.rowBlock}>
                <div className={classes.left}>
                    <h3>
                        General Optimizations <br />
                        and Improvements
                    </h3>
                    <p>
                        <strong>Status:</strong> Ongoing
                    </p>
                </div>
                <div className={classes.right}>
                    <p>
                        Volta SNAP will be updated regularly. In this first iteration, the development team focused
                        heavily on including all the required functionality to perform a takeoff for new buildings. This
                        push to get the system up and running means certain portions of the code are not yet optimized,
                        and there are bound to be unnoticed bugs.
                    </p>
                    <p>
                        Volta SNAP strives to be a modern, continuously evolving web application, and the development
                        team will regularly release bug fixes and improvements. Help us improve Volta SNAP by sending us
                        your feedback!
                    </p>
                </div>
            </div>
            <div className={classes.rowBlock}>
                <div className={classes.left}>
                    <h3>
                        Organization Accounts <br />
                        and File Sharing
                    </h3>
                    <p>
                        <strong>Status:</strong> In development
                    </p>
                    <p>
                        <strong>Scheduled:</strong> Fall 2021
                    </p>
                </div>
                <div className={classes.right}>
                    <p>
                        Our software's permissions system is built based on the need to share files with others;
                        however, we have prioritized the development of its core data collection features.
                    </p>
                    <p>
                        In future updates, we will be adding the ability to share files among employees within an
                        organization. Tell us about how you think we could integrate teamwork into Volta SNAP.
                    </p>
                </div>
            </div>
            <div className={classes.rowBlock}>
                <div className={classes.left}>
                    <h3>In-app Analytics</h3>
                    <p>
                        <strong>Status:</strong> Various stages of development
                    </p>
                    <p>
                        <strong>Scheduled:</strong> Ongoing rollout starting late-2021
                    </p>
                </div>
                <div className={classes.right}>
                    <p>
                        As we update Volta SNAP we will be adding a variety of in-app analytics. Some of the planned
                        analytics include the ability to model many upgrade combinations at once, modelling hourly load
                        profiles, and estimating emissions based on hourly performance and up-to-date detailed emissions
                        data to best reflect the real-world impact of the home. Let us know what other analytics you
                        would like to see integrated into Volta SNAP.
                    </p>
                </div>
            </div>
            <div className={classes.rowBlock}>
                <div className={classes.left}>
                    <h3>
                        Mobile Support <br />
                        and Existing Homes
                    </h3>
                    <p>
                        <strong>Status:</strong> Requirements gathering
                    </p>
                    <p>
                        <strong>Scheduled:</strong> Late 2021 (BETA)
                    </p>
                </div>
                <div className={classes.right}>
                    <p>
                        Volta SNAP has been designed with new building construction in mind, as is reflected in the
                        Drawing Capture tool. With funding provided by The Atmospheric Fund (TAF), we will be expanding
                        the software to streamline the data collection process for existing buildings as well. A key
                        feature of this expansion will involve a mobile interface, connected to the core of Volta SNAP,
                        that can be used for on-site data collection. Over the coming months, we will be working closely
                        with EAs to help inform this streamlined on-site data collection process. Let us know if you are
                        interested in participating in this upcoming design and user testing exercise.
                    </p>
                </div>
            </div>
        </div>
    </div>
);
