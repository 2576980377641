import React from "react";
import { Field } from "redux-form";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import InputWithUnits from "components/Input/InputWithUnits";
import { getValidation, getDecimalPlaces } from "utils/fieldValidation";
import { getBaseUnits, getUnitOptions } from "utils/fields";

const heightValidation = getValidation("basementHeight");
const depthValidation = getValidation("basementDepth");
const cornerValidation = getValidation("basementWallCorners");

export default React.memo(
    ({
        accessor,
        modelUnits,
        wallHeight,
        wallDepth,
        heightUnits,
        depthUnits,
        change,
        wallHeightDrawing,
        wallDepthDrawing,
        basementWallHeightEquation,
        basementWallDepthEquation,
    }) => (
        <InputRow gridTemplate="1fr 1fr 1fr">
            <Field
                component={InputWithUnits}
                type="number"
                name={`${accessor}.wall.measurements.height`}
                label="Total Wall Height"
                placeholder="Enter Value"
                validate={heightValidation}
                disabled={wallHeightDrawing}
                setValue={wallHeight}
                isDrawing={wallHeightDrawing}
                decimals={getDecimalPlaces("basementHeight")}
                change={change}
                units={{
                    base: getBaseUnits("basementHeight", modelUnits),
                    options: getUnitOptions("length"),
                    selected: heightUnits,
                    unitType: "length",
                    accessor: `${accessor}.wall.measurements.height_u`,
                }}
                warning={wallHeight < 1.204 ? "basementWallHeightLowerLimit" : ""}
                withEquation
                equation={basementWallHeightEquation}
            />
            <Field
                component={InputWithUnits}
                type="number"
                name={`${accessor}.wall.measurements.depth`}
                label="Wall Depth Below Grade"
                placeholder="0.00"
                validate={depthValidation}
                disabled={wallDepthDrawing}
                setValue={wallDepth}
                isDrawing={wallDepthDrawing}
                decimals={getDecimalPlaces("basementDepth")}
                change={change}
                units={{
                    base: getBaseUnits("basementDepth", modelUnits),
                    options: getUnitOptions("length"),
                    selected: depthUnits,
                    unitType: "length",
                    accessor: `${accessor}.wall.measurements.depth_u`,
                }}
                withEquation
                equation={basementWallDepthEquation}
            />
            <Field
                component={Input}
                type="number"
                name={`${accessor}.wall.measurements.corners`}
                label="Corners"
                placeholder="Enter Value"
                quantity
                validate={cornerValidation}
            />
        </InputRow>
    )
);
