import React, { useState } from "react";
import classes from "./style.module.scss";
import classNames from "classnames";
import Button from "components/Button";

const Line = ({ color, percentage = 0, strokeWidth }) => {
    const width = 98;
    const length = percentage/100 * width;
    
    return (
        <line
            x1="10"
            y1="5"
            x2={length + "%"}
            y2="5"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
        ></line>
    );
};

const ProgressBar = ({ loading, progress, breakdown, className, color = "#0049C6", strokeWidth="0.5rem" }) => {
    // Expand to display breakdown of which models have no results
    const [isExpanded, setIsExpanded] = useState(false);

    const handleExpand = () => {
        setIsExpanded((prevState) => !prevState);
    };

    return (
        <div className={classNames(classes.progressBar, className)} style={{width: "100%"}}>
            <div className={classes.header}>
                <div className={classes.title}>
                    <h1>Progress for all models</h1>
                    {!loading && (
                        <Button small smallPadding type="emptyBg" onClick={handleExpand}>
                            {isExpanded ? "Collapse" : "Expand"}
                        </Button>
                    )}
                </div>
                <p>{loading ? "Calculating..." : `${progress.toFixed(2)}%`}</p>
            </div>
            <svg width={"100%"} height={10}>
                <Line color="#e1eaf0" percentage={100} strokeWidth={strokeWidth}/>
                {!loading && (
                    <Line color={color} percentage={progress} strokeWidth={strokeWidth}/>
                )}
            </svg>

            {(!loading && isExpanded) && (
                <div className={classes.progressBreakdown}>
                    <p>The following models have no simulation results available:</p>
                    { breakdown.map((model, index) => <li key={index}>{model.name}</li>) }
                </div>
            )}
        </div>
    );
};

// TODO: Make component reusable rather than community specific or change the folder
export default ProgressBar;
