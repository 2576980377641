import React from "react"

const CostTableHeader = ({children}) => {
    // console.log('from header',isOpen)
    return (
        <div>
            { children}
            {/* {!isOpen && children} */}
        </div>
    )
}

export default CostTableHeader