import { connect } from "react-redux";
import { reduxForm, formValueSelector, reset } from "redux-form";
import StandardCode from "./";
import crawlspaceWallCodeTemplate from "features/Model/Enclosure/Templates/wallCode.json";
import { actions as userActions } from "store/users";
import { actions as enclosureActions } from "features/Model/Enclosure/_ducks";
import { actions as upgradeActions } from "features/Model/Upgrades/_ducks";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import { getMatchCodeRefs } from "utils/enclosure/components";
import { standardCodeLogic } from "utils/codes";

const { addToCodeLib, deleteCode } = userActions;
const { setInitCode, getRValue } = enclosureActions;
const { updateUpgradeCodes } = upgradeActions;

const mapStateToProps = (
    {
        form,
        model: { modelId },
        user: { uid },
        enclosure: { initCodeValues: { crawlspaceWallInsType = {} } = {} } = {},
        upgradeLibrary: { currentPackage, selectedUpgrade },
    },
    { fieldAccessor, modelFormChange, currentFormChange, toggleOpen, isCodeLibrary = false, componentId }
) => {
    const selector = formValueSelector("crawlspaceWallCode");
    const { id: crawlspaceWallStructureId, value: crawlspaceWallStructureValue = 0 } =
        selector({ form }, "crawlspaceWallCode.layers.structureType") || {};
    const { value: crawlspaceWallTypeSizeValue } =
        selector({ form }, "crawlspaceWallCode.layers.componentTypeSize") || {};
    const { id: crawlspaceWallSpacingId, value: crawlspaceWallSpacingValue } =
        selector({ form }, "crawlspaceWallCode.layers.spacing") || {};
    const { value: crawlspaceWallFramingValue } = selector({ form }, "crawlspaceWallCode.layers.framing") || {};
    const { value: crawlspaceWallIns1Value } = selector({ form }, "crawlspaceWallCode.layers.insulationLayer1") || {};
    const { value: crawlspaceWallIns2Value } = selector({ form }, "crawlspaceWallCode.layers.insulationLayer2") || {};
    const { value: crawlspaceWallInteriorValue } = selector({ form }, "crawlspaceWallCode.layers.interior") || {};
    const { value: crawlspaceWallSheathingValue } = selector({ form }, "crawlspaceWallCode.layers.sheathing") || {};
    const { value: crawlspaceWallExteriorValue } = selector({ form }, "crawlspaceWallCode.layers.exterior") || {};
    const { value: crawlspaceWallStudsValue } =
        selector({ form }, "crawlspaceWallCode.layers.studsCornerIntersection") || {};
    const crawlspaceWallLabel = selector({ form }, "crawlspaceWallCode.label") || "";

    const frameSpacingValue =
        crawlspaceWallSpacingId === null ? crawlspaceWallFramingValue : crawlspaceWallSpacingValue;

    const crawlspaceWallValue = `1${crawlspaceWallStructureValue
        .toString()
        .substr(
            -1
        )}${crawlspaceWallTypeSizeValue}${frameSpacingValue}${crawlspaceWallIns1Value}${crawlspaceWallIns2Value}${crawlspaceWallInteriorValue}${crawlspaceWallSheathingValue}${crawlspaceWallExteriorValue}${crawlspaceWallStudsValue}`;

    const { codeRef: editingCodeRef = null, isLibCode = false } = crawlspaceWallInsType;

    const modelSelector = formValueSelector("model");
    const modelCodes = modelSelector({ form }, "modelData.codes") || {};
    const components = modelSelector({ form }, "modelData.components") || {};

    return {
        crawlspaceWallLabel,
        fieldAccessor,
        modelFormChange,
        currentFormChange,
        initialValues: {
            crawlspaceWallCode: isEmpty(crawlspaceWallInsType) ? crawlspaceWallCodeTemplate : crawlspaceWallInsType,
            addToLibrary: isLibCode,
        },
        crawlspaceWallStructureId,
        crawlspaceWallTypeSizeValue,
        isSolid: parseFloat(crawlspaceWallStructureId) === 4,
        isPanel: parseFloat(crawlspaceWallStructureId) === 5,
        isFloorHeader: parseFloat(crawlspaceWallStructureId) === 6,
        makeSpacingDefault: ["5", "6", "7", "8", "9", "A", "B", "C"].includes(crawlspaceWallTypeSizeValue),
        makeInsulationDefault: ["6", "7", "8", "9", "A", "B", "C"].includes(crawlspaceWallTypeSizeValue),
        crawlspaceWallValue,
        modelId,
        uid,
        editingCodeRef,
        isEditing: !!editingCodeRef,
        isLibCode,
        toggleOpen,
        crawlspaceWallSpacingId,
        isCodeLibrary,
        componentId,
        modelCodes,
        components,
        currentPackage,
        selectedUpgrade,
    };
};

const mapDispatchToProps = (dispatch) => ({
    clearForm: () => dispatch(reset("crawlspaceWallCode")),
    clearInitCode: () => dispatch(setInitCode({}, "crawlspaceWallInsType")),
});

const mergeProps = (state, dispatch, own) => ({
    ...state,
    ...dispatch,
    ...own,
});

const onSubmit = async (
    form,
    dispatch,
    {
        toggleOpen,
        uid,
        fieldAccessor,
        modelFormChange,
        currentFormChange,
        editingCodeRef,
        isCodeLibrary,
        componentId,
        isEditing,
        modelCodes,
        components,
        currentPackage,
        selectedUpgrade,
    }
) => {
    const { crawlspaceWallCode = {}, addToLibrary = false, setAsModelDefault = false, selectedComponents = [] } = form;

    toggleOpen(false);

    // Run standard code logic
    standardCodeLogic({
        componentCodeType: "CrawlspaceWall",
        componentId,
        code: crawlspaceWallCode,
        formInputName: "crawlspaceWallInsType",
        codeType: "S",
        isCodeLibrary,
        isEditing,
        editingCodeRef,
        dispatch,
        getRValue,
        modelFormChange,
        currentFormChange,
        fieldAccessor,
        components,
        modelCodes,
        addToLibrary,
        addToCodeLib,
        uid,
        deleteCode,
        updateUpgradeCodes,
        setAsModelDefault,
        selectedComponents,
        currentPackage,
        selectedUpgrade,
    });

    // Clear crawlspace wall code defaults for next time
    dispatch(setInitCode({}, "crawlspaceWallInsType"));
    // Reset form
    dispatch(reset("crawlspaceWallCode"));
};

const form = reduxForm({
    form: "crawlspaceWallCode",
    enableReinitialize: true,
    onSubmit: onSubmit,
})(StandardCode);

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(form);
