import { validateMiniAuditConversion, createModelFromMiniAudit } from "utils/create/api";
import types from './types';

const toggleAuditIdValid = (isValid) => ({
    type:types.TOGGLE_AUDIT_ID_VALID,
    isValid,
});

const setValidationError = (error) => ({
    type:types.SET_VALIDATION_ERROR,
    error,
});

const toggleIsAuditValidating = (isValidating) => ({
    type:types.TOGGLE_IS_AUDIT_VALIDATING,
    isValidating,
});

const setMiniAuditValid = (isValid) => ({
    type: types.SET_MINI_AUDIT_VALID,
    isValid
})

const validateMiniAudit = (miniAuditId) => dispatch => {
    dispatch(toggleIsAuditValidating(true));
    return validateMiniAuditConversion({ miniAuditId })
        .then(data => {
            dispatch(setMiniAuditValid(true));
        })
        .catch(err => {
            dispatch(setMiniAuditValid(false));
        })
};

const createModelFromAudit = ({ miniAuditId = "", uid="", name, }) => dispatch => {
    return createModelFromMiniAudit({ miniAuditId, uid, name })
        .then(({ data:{ returnHousePackage:{ modelId="" }={} }={} }) => modelId)
        .catch((err) => {
            console.log(err)
        });
}

export default {
    validateMiniAudit,
    toggleAuditIdValid,
    setValidationError,
    createModelFromAudit
}