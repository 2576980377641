import React from "react";

export default () => (
    <svg fill="#F0F8FD" width="28" height="28" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.5713 6.78571C22.5713 9.42877 20.4287 11.5714 17.7856 11.5714C15.1426 11.5714 13 9.42877 13 6.78571C13 4.14264 15.1426 2 17.7856 2C20.4287 2 22.5713 4.14264 22.5713 6.78571ZM24.5713 6.78571C24.5713 8.40009 24.0078 9.88275 23.0664 11.0477L27.3662 15.3477L25.9521 16.7619L21.5933 12.4031C20.5073 13.1405 19.1968 13.5714 17.7856 13.5714C14.0381 13.5714 11 10.5334 11 6.78571C11 3.03809 14.0381 0 17.7856 0C21.5332 0 24.5713 3.03809 24.5713 6.78571ZM2 1V26H28V28H0V1H2ZM8 5H5V24H8V5ZM11 19H14V24H11V19ZM20 16H17V24H20V16Z"
        />
    </svg>
);
