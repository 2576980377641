import React from "react";

export default ({ fill = "#0049c6" }) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.5003 8.2328V14.0001V14.5001H13.0003H3.00029H2.50029V14.0001V6.4568L1.3082 7.39331L0.69043 6.60695L7.69141 1.10695L8.00033 0.864258L8.30923 1.10699L9.12918 1.75133C8.76966 1.86429 8.42357 2.01487 8.09929 2.21384L8.00025 2.13601L3.50029 5.67119V13.5001H12.5003V9.26713L13.5003 8.2328Z"
            fill={fill}
        />
        <path
            d="M11.6468 7.97472H11.1468L12.0063 8.32225L11.6468 7.97472ZM14.9042 4.60556L15.2636 4.9531L15.5988 4.60647L15.2645 4.25897L14.9042 4.60556ZM11.6468 6.28572H12.1468V5.78572H11.6468V6.28572ZM7.30371 7.97472H6.80371L7.66363 8.32178L7.30371 7.97472ZM11.6468 2.90774V3.40774H12.1468V2.90774H11.6468ZM11.6468 1.21875L12.0072 0.872152L11.1468 1.21875H11.6468ZM12.0063 8.32225L15.2636 4.9531L14.5447 4.25803L11.2874 7.62718L12.0063 8.32225ZM11.1468 6.28572V7.97472H12.1468V6.28572H11.1468ZM11.6468 5.78572C10.847 5.78572 10.0904 5.78184 9.32674 6.01942C8.54617 6.26226 7.79842 6.74136 6.94379 7.62765L7.66363 8.32178C8.43767 7.51908 9.04715 7.15368 9.6238 6.97428C10.2174 6.78961 10.818 6.78572 11.6468 6.78572V5.78572ZM7.80371 7.97472C7.80371 6.01485 8.08216 4.91762 8.62975 4.29296C9.15804 3.69032 10.0486 3.40774 11.6468 3.40774V2.40774C9.98773 2.40774 8.70672 2.68816 7.87778 3.63376C7.06815 4.55734 6.80371 5.9936 6.80371 7.97472H7.80371ZM11.1468 1.21875V2.90774H12.1468V1.21875H11.1468ZM15.2645 4.25897L12.0072 0.872152L11.2865 1.56535L14.5438 4.95216L15.2645 4.25897Z"
            fill={fill}
        />
    </svg>
);
