import React from "react";

import SetScale from "./scale";
import SetPolygon from "./polygon";
import SetMultiPointLine from "./multiPointLine";
import SetRectangle from "./rectangle";
import SetLine from "./line";

import { actionsContainer } from "./style.module.scss";

const actions = (id) =>
    ({
        setScale: SetScale,
        setPolygon: SetPolygon,
        setMultiPointLine: SetMultiPointLine,
        setLine: SetLine,
        setRectangle: SetRectangle,
        default: () => null,
    }[id || "default"]);

const Actions = ({ units, meta, actionId, modelTakeoff, images }) => {
    const Action = actions(actionId);

    const { foundation, ...storeys } = modelTakeoff;

    return (
        <div className={actionsContainer}>
            <h3>Actions</h3>
            <div>
                <Action
                    {...meta}
                    primaryUnits={units}
                    foundation={foundation}
                    storeys={storeys}
                    images={images}
                    // setScale={setScale}
                    // scale={scale}
                    // stage={stage}
                    // modelChange={modelChange}
                />
            </div>
        </div>
    );
};

export default Actions;
