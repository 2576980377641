import React, { useState } from "react";
import classes from "./style.module.scss";
import HeatingSystems from "./HeatingSystems/container";
import CoolingHeatPumps from "./CoolingHeatPumps/container";
import Ventilation from "./Ventilation/container";
import HotWater from "./HotWater/container";
import Generation from "./Generation/container";
import Temperatures from "./Temperatures/container";
import BaseLoads from "./BaseLoads/container";
import Tabs from "components/Tabs";
import Button from "components/Button";
import Details from "./Details";
import TabHeader from "components/TabHeader";
import DetailsDrawer from "components/DetailsDrawer";
import { mixpanel } from "components/Mixpanel";

export default ({ change, modelId, history, contentOpen }) => {
    const initialTab = window.location.search;
    const [activeTab, changeActiveTab] = useState(initialTab);
    const [open, toggleOpen] = useState(false);

    return (
        <>
            <TabHeader
                title="Mechanicals"
                description="Input information about a house's Heating, Cooling, Ventilation, Hot Water, and Generation systems."
                detailsTrigger={() => toggleOpen(true)}
                detailsText="Mechanicals Details"
                noBorder
            />
            <DetailsDrawer open={open} close={() => toggleOpen(false)} title="Mechanicals Tab Details">
                <Details />
                <div className={classes.button}>
                    <Button large type="hollow" onClick={() => toggleOpen(false)}>
                        Close
                    </Button>
                </div>
                <span></span>
            </DetailsDrawer>
            <Tabs
                type="subTabs"
                initialTab={activeTab || ""}
                tabActive={activeTab || ""}
                onChange={(e) => {
                    history.push(`/models/${modelId}/mechanicals${e}`);
                    mixpanel.track("Mechanicals Tab Change", {
                        Tab: e,
                    });
                    return changeActiveTab(e);
                }}
                tabs={[
                    { name: "Heating Systems", url: "" },
                    { name: "Cooling/Heat Pump", url: "?tab=cooling" },
                    { name: "Ventilation", url: "?tab=ventilation" },
                    { name: "Hot Water", url: "?tab=hot-water" },
                    { name: "Generation", url: "?tab=generation" },
                    { name: "Temperatures", url: "?tab=temperatures" },
                    { name: "Base Loads", url: "?tab=baseLoads" },
                ]}
                className={classes.tabs}
                stack={contentOpen}
            >
                <HeatingSystems accessor={"modelData.heatingCooling"} change={change} />
                <CoolingHeatPumps accessor={"modelData.heatingCooling"} change={change} />
                <Ventilation accessor={"modelData.ventilation"} change={change} />
                <HotWater accessor={"modelData.domesticHotWater"} change={change} />
                <Generation accessor={"modelData.generation"} change={change} />
                <Temperatures accessor={"modelData.temperatures"} change={change} />
                <BaseLoads accessor={"modelData.baseLoads"} change={change} />
            </Tabs>
        </>
    );
};
