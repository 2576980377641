import React from 'react';
import classes from './style.module.scss';
import sharedClasses from 'features/Model/sharedStyles.module.scss';
import { Field } from 'redux-form';
import { required, maxLength, getValidation, getDecimalPlaces } from 'utils/fieldValidation';
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { getOptions, getFirstOption, getBaseUnits, getUnitOptions } from 'utils/fields';
import InputRow from 'components/Input/Row';
import Input from 'components/Input';
import InputWithUnits from 'components/Input/InputWithUnits';
import Select from 'components/Input/Select';
import SelectWithInput from 'components/Input/SelectWithInput';
import Button from 'components/Button';
import Checkbox from 'components/Input/Checkbox';

const hotWaterTempValidation = getValidation('waterHotTemp');
const bathroomFaucetsValidation = getValidation('waterFaucetFlow');
const showerTempValidation = getValidation('waterShowerTemp');
const showerFlowValidation = getValidation('waterShowerFlow');
const showerDurationValidation = getValidation('waterShowerDuration');
const showerOccWeekValidation = getValidation('waterShowerOccWeek');
const clothesWasherRatedValuesValidation = getValidation('clothesWasherRatedValueType');
const clothesWasherWaterValidation = getValidation('clothesWasherRatedWater');
const clothesWasherEnergyValidation = getValidation('clothesWasherAnnualEnergy');
const clothesWasherTempValidation = getValidation('clothesWasherTemp');
const clothesWasherCyclesValidation = getValidation('clothesWasherCyclesOccWeek');
const dishWasherCyclesValidation = getValidation('dishWasherCyclesOccWeek');
const dishWasherRatedValuesValidation = getValidation('dishWasherRatedValueType');
const dishWasherWaterValidation = getValidation('dishWasherRatedWater');
const dishWasherEnergyValidation = getValidation('dishWasherAnnualEnergy');
const otherHotWaterValidation = getValidation('waterOtherOccConsumption');
const lowFlowToiletsValidation = getValidation('waterNumLowFlowToilets');

//Check Shower Daily duration field - Does h2k update this?

export default React.memo(({
    accessor,
    isClothesWasherInstalled,
    isDishWasherInstalled,
    numberPerOccupantPerDayValue,
    clothesWasherRatedValuesId,
    clothesWasherWaterValue,
    clothesWasherEnergyValue,
    dishWasherWaterValue,
    dishWasherEnergyValue,
    hotWaterTempUnits,
    showerDurationUnits,
    clothesWasherWaterUnits,
    clothesWasherEnergyUnits,
    dishWasherRatedValuesId,
    dishWasherWaterUnits,
    dishWasherEnergyUnits,
    otherHotWaterUnits,
    modelUnits,
    change,
    isUpgrade=false,
}) => {

    
    return (
        <div>
            {
                !isUpgrade &&
                <>
                    <h4 style={{marginBottom:"1.25rem"}}>Hot Water</h4>
                    <InputRow gridTemplate="1fr 1fr 1fr">
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.hotWaterTemp`}
                            label="Hot Water Temperature"
                            placeholder={0.00}
                            validate={hotWaterTempValidation}
                            decimals={getDecimalPlaces('waterHotTemp')}
                            change={change}
                            units={{
                                base:getBaseUnits('waterHotTemp', modelUnits),
                                options:getUnitOptions('temperature'),
                                selected:hotWaterTempUnits,
                                unitType:'temperature',
                                accessor:`${accessor}.hotWaterTemp_u`
                            }}
                        />
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.otherHotWaterUse`}
                            label="Other Daily Hot Water Consumption per Occupant"
                            placeholder={0.00}
                            validate={otherHotWaterValidation}
                            decimals={getDecimalPlaces('waterOtherOccConsumption')}
                            change={change}
                            units={{
                                base:getBaseUnits('waterOtherOccConsumption', modelUnits),
                                options:getUnitOptions('volume'),
                                selected:otherHotWaterUnits,
                                unitType:'volume',
                                accessor:`${accessor}.otherHotWaterUse_u`
                            }}
                        />
                    </InputRow>
                </>
            }
            <h5 style={{marginBottom:"0.75rem", marginTop:"1.5rem"}}>Bathroom Faucets</h5>
            <InputRow gridTemplate="1fr 1fr 1fr">
                <Field
                    component={Select}
                    name={`${accessor}.bathroomFaucets`}
                    options={[
                        {
                            label:"Ultra Low flow 3.8  L/min (1.0 US gpm)",
                            value:{
                                id:0,
                                value:3.8,
                                numberPerOccupantPerDay: numberPerOccupantPerDayValue
                            }
                        },
                        {
                            label:"Low flow 5.7 L/min (1.5 US gpm)",
                            value:{
                                id:1,
                                value:5.7,
                                numberPerOccupantPerDay: numberPerOccupantPerDayValue
                            }
                        },
                        {
                            label:"Standard 8.3 L/min (2.2 US gpm)",
                            value:{
                                id:2,
                                value:8.3,
                                numberPerOccupantPerDay: numberPerOccupantPerDayValue
                            }
                        }
                    ]}
                    label="Faucet Flow Rate"
                    placeholder="Choose Flow Rate"
                    validate={bathroomFaucetsValidation}
                />
                <Field
                    component={Input}
                    type="number"
                    name={`${accessor}.bathroomFaucets.numberPerOccupantPerDay`}
                    label="Faucet Use per Occupant per Day"
                    placeholder={0.00}
                    validate={bathroomFaucetsValidation}
                    decimals={getDecimalPlaces('waterFaucetFlow')}
                />
            </InputRow>
            <h5 style={{marginBottom:"0.75rem", marginTop:"1.5rem"}}>Shower</h5>
            <InputRow gridTemplate="1fr 1fr 1fr">
                <Field
                    component={Select}
                    name={`${accessor}.shower.temperature`}
                    options={getOptions({fieldKey: 'waterShowerTemp'})}
                    label="Temperature"
                    placeholder="Choose Temperature"
                    validate={showerTempValidation}
                />
                <Field
                    component={Select}
                    name={`${accessor}.shower.flowRate`}
                    options={getOptions({fieldKey: 'waterShowerFlow'})}
                    label="Flow Rate"
                    placeholder="Choose Flow Rate"
                    validate={showerFlowValidation}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.shower.averageDuration`}
                    label="Average Duration"
                    placeholder={0.00}
                    validate={showerDurationValidation}
                    decimals={getDecimalPlaces('waterShowerDuration')}
                    change={change}
                    units={{
                        base:getBaseUnits('waterShowerDuration', modelUnits),
                        options:getUnitOptions('time'),
                        selected:showerDurationUnits,
                        unitType:'time',
                        accessor:`${accessor}.shower.averageDuration_u`
                    }}
                />
            </InputRow>
            <InputRow gridTemplate="1fr 1fr 1fr">
                <Field
                    component={Input}
                    type="number"
                    name={`${accessor}.shower.numPerOccupantPerWeek`}
                    label="Showers per Occupant per Week"
                    placeholder={0.00}
                    validate={showerOccWeekValidation}
                    decimals={getDecimalPlaces('waterShowerOccWeek')}
                />
            </InputRow>
            <h5 style={{marginBottom:"0.75rem", marginTop:"1.5rem"}}>Clothes Washer</h5>
            <InputRow gridTemplate="1fr 1fr 1fr">
                <Field
                    component={Checkbox}
                    name={`${accessor}.clothesWasher.isInstalled`}
                    label="Clothes Washer Present?"
                    large
                    className={classes.bottomCheckBox}
                    type="checkbox"
                />
            </InputRow>
            <InputRow gridTemplate="1fr 1fr 1fr">
                <Field
                    component={Input}
                    type="number"
                    name={`${accessor}.clothesWasher.cyclesPerOccupantPerWeek`}
                    label="Cycles per Occupant per Week"
                    placeholder={0.00}
                    validate={clothesWasherCyclesValidation}
                    decimals={getDecimalPlaces('clothesWasherCyclesOccWeek')}
                    disabled={!isClothesWasherInstalled}
                />
                <Field
                    component={Select}
                    name={`${accessor}.clothesWasher.temperature`}
                    options={getOptions({fieldKey: 'clothesWasherTemp'})}
                    label="Temperature"
                    placeholder="Choose Temperature"
                    validate={clothesWasherTempValidation}
                    disabled={!isClothesWasherInstalled}
                />
            </InputRow>
            <InputRow gridTemplate="1fr 1fr 1fr">
                <Field
                    component={Select}
                    name={`${accessor}.clothesWasher.ratedValues`}
                    options={[
                        {
                            label:"Default",
                            value:{
                                id:0,
                                waterConsumptionPerCycle: 54,
                                annualEnergyConsumption: 197
                            }
                        },
                        {
                            label:"High Efficiency",
                            value:{
                                id:1,
                                waterConsumptionPerCycle: 40,
                                annualEnergyConsumption: 145
                            }
                        },
                        {
                            label:"User Specified",
                            value:{
                                id:2,
                                waterConsumptionPerCycle: clothesWasherWaterValue,
                                annualEnergyConsumption: clothesWasherEnergyValue
                            }
                        }
                    ]}
                    label="Rated Values"
                    placeholder="Choose Rate Values"
                    validate={clothesWasherRatedValuesValidation}
                    disabled={!isClothesWasherInstalled}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.clothesWasher.ratedValues.waterConsumptionPerCycle`}
                    label="Rated Water Consumption per Cycle"
                    placeholder={0.00}
                    validate={clothesWasherWaterValidation}
                    decimals={getDecimalPlaces('clothesWasherRatedWater')}
                    change={change}
                    setValue={clothesWasherWaterValue}
                    disabled={clothesWasherRatedValuesId !== 2 || !isClothesWasherInstalled}
                    units={{
                        base:getBaseUnits('clothesWasherRatedWater', modelUnits),
                        options:getUnitOptions('volume'),
                        selected:clothesWasherWaterUnits,
                        unitType:'volume',
                        accessor:`${accessor}.clothesWasher.ratedValues.waterConsumptionPerCycle_u`
                    }}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.clothesWasher.ratedValues.annualEnergyConsumption`}
                    label="Rated Annual Energy Consumption"
                    placeholder={0.00}
                    validate={clothesWasherEnergyValidation}
                    decimals={getDecimalPlaces('clothesWasherAnnualEnergy')}
                    change={change}
                    setValue={clothesWasherEnergyValue}
                    disabled={clothesWasherRatedValuesId !== 2 || !isClothesWasherInstalled}
                    units={{
                        base:getBaseUnits('clothesWasherAnnualEnergy', modelUnits),
                        options:getUnitOptions('energy'),
                        selected:clothesWasherEnergyUnits,
                        unitType:'energy',
                        accessor:`${accessor}.clothesWasher.ratedValues.annualEnergyConsumption_u`
                    }}
                />
            </InputRow>
            <h5 style={{marginBottom:"0.75rem", marginTop:"1.5rem"}}>Dish Washer</h5>
            <InputRow gridTemplate="1fr 1fr 1fr">
                <Field
                    component={Checkbox}
                    name={`${accessor}.dishWasher.isInstalled`}
                    label="Dish Washer Present?"
                    large
                    className={classes.bottomCheckBox}
                    type="checkbox"
                />
            </InputRow>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Field
                    component={Input}
                    type="number"
                    name={`${accessor}.dishWasher.cyclesPerOccupantPerWeek`}
                    label="Cycles per Occupant per Week"
                    placeholder={0.00}
                    validate={dishWasherCyclesValidation}
                    decimals={getDecimalPlaces('dishWasherCyclesOccWeek')}
                    disabled={!isDishWasherInstalled}
                />
                <Field
                    component={Select}
                    name={`${accessor}.dishWasher.ratedValues`}
                    options={[
                        {
                            label:"Default",
                            value:{
                                id:0,
                                waterConsumptionPerCycle: 19,
                                annualEnergyConsumption: 260
                            }
                        },
                        {
                            label:"High Efficiency",
                            value:{
                                id:1,
                                waterConsumptionPerCycle: 13.6,
                                annualEnergyConsumption: 240
                            }
                        },
                        {
                            label:"User Specified",
                            value:{
                                id:2,
                                waterConsumptionPerCycle: dishWasherWaterValue,
                                annualEnergyConsumption: dishWasherEnergyValue
                            }
                        }
                    ]}
                    label="Rated Values"
                    placeholder="Choose Rate Values"
                    validate={dishWasherRatedValuesValidation}
                    disabled={!isDishWasherInstalled}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.dishWasher.ratedValues.waterConsumptionPerCycle`}
                    label="Rated Water Consumption per Cycle"
                    placeholder={0.00}
                    validate={dishWasherWaterValidation}
                    decimals={getDecimalPlaces('dishWasherRatedWater')}
                    change={change}
                    setValue={dishWasherWaterValue}
                    disabled={dishWasherRatedValuesId !== 2 || !isDishWasherInstalled}
                    units={{
                        base:getBaseUnits('dishWasherRatedWater', modelUnits),
                        options:getUnitOptions('volume'),
                        selected:dishWasherWaterUnits,
                        unitType:'volume',
                        accessor:`${accessor}.dishWasher.ratedValues.waterConsumptionPerCycle_u`
                    }}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.dishWasher.ratedValues.annualEnergyConsumption`}
                    label="Rated Annual Energy Consumption"
                    placeholder={0.00}
                    validate={dishWasherEnergyValidation}
                    decimals={getDecimalPlaces('dishWasherAnnualEnergy')}
                    change={change}
                    setValue={dishWasherEnergyValue}
                    disabled={dishWasherRatedValuesId !== 2 || !isDishWasherInstalled}
                    units={{
                        base:getBaseUnits('dishWasherAnnualEnergy', modelUnits),
                        options:getUnitOptions('energy'),
                        selected:dishWasherEnergyUnits,
                        unitType:'energy',
                        accessor:`${accessor}.dishWasher.ratedValues.annualEnergyConsumption_u`
                    }}
                />
            </InputRow>
            {
                !isUpgrade &&
                <>
                    <h4 style={{marginBottom:"1.25rem", marginTop:"1.75rem"}}>Cold Water</h4>
                    <InputRow gridTemplate="1fr 1fr 1fr">
                        <Field
                            component={Input}
                            type="number"
                            name={`${accessor}.numLowFlushToilets`}
                            label="Number of Low Flow Toilets"
                            placeholder={0.00}
                            validate={lowFlowToiletsValidation}
                            decimals={getDecimalPlaces('waterNumLowFlowToilets')}
                        />
                    </InputRow>
                </>
            }
        </div>
    )
})
