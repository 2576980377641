export default ({ basement = {}, crawlspace = {}, slab = {}, wall = {}, expFloor = {}, ceiling = {} }) => {
    //Must grab these subcomponents while iterating through others
    //We will ignore windows attached to doors because these won't be representative of windows in the house
    let allDoors = {};
    let allWindows = {};
    let allFloorHeaders = {};

    const table = {
        wall: {},
        ceiling: {},
        ceilingFlat: {},
        expFloor: {},
        basementWall: {},
        crawlspaceWall: {},
        slabOnGrade: {},
        slabBelowGrade: {},
        windows: {},
        doors: {},
        floorHeader: {},
        highLevel: {},
    };

    // ================ Walls ================ //
    const wallPrevalence = Object.keys(wall).reduce((acc, wallKey) => {
        let {
            measurements: { area, height, perimeter } = {},
            wallInsType: { codeRef } = {},
            wallInsType_nomRVal: nominalInsulation = 0,
            subcomponents: { door = {}, window = {}, floorHeader = {} } = {},
        } = wall[wallKey] || {};

        allDoors = {
            ...allDoors,
            ...Object.keys(door).reduce((doorAcc, doorKey) => {
                return { ...doorAcc, [`${doorKey}-${wallKey}`]: door[doorKey] };
            }, {}),
        };

        allWindows = {
            ...allWindows,
            ...Object.keys(window).reduce((windowAcc, windowKey) => {
                return { ...windowAcc, [`${windowKey}-${wallKey}`]: window[windowKey] };
            }, {}),
        };

        allFloorHeaders = {
            ...allFloorHeaders,
            ...Object.keys(floorHeader).reduce((floorHeaderAcc, floorHeaderKey) => {
                return { ...floorHeaderAcc, [`${floorHeaderKey}-${wallKey}`]: floorHeader[floorHeaderKey] };
            }, {}),
        };

        if (codeRef === "UserSpecified") {
            codeRef = `UserSpecified${nominalInsulation}`;
        }

        return {
            ...acc,
            [codeRef]: (acc[codeRef] || 0) + (area || height * perimeter),
        };
    }, {});

    let totWallArea = 0;
    const prevalentWallCode = Object.entries(wallPrevalence).reduce(
        (acc, [codeRef, area]) => {
            totWallArea += area;
            if (area >= acc.area) {
                return { codeRef, area };
            }
            return acc;
        },
        { codeRef: "", area: 0 }
    );

    table.wall.entries = Object.entries(wall).reduce((acc, [key, val]) => {
        let {
            measurements: { area, height, perimeter } = {},
            wallInsType: { codeRef, codeLabel } = {},
            wallInsType_nomRVal: nominalInsulation = 0,
        } = val || {};

        if (codeRef === "UserSpecified") {
            codeRef = `UserSpecified${nominalInsulation}`;
        }

        if (prevalentWallCode.codeRef === codeRef) {
            table.wall.main = {
                codeRef: prevalentWallCode.codeRef,
                area: prevalentWallCode.area,
                areaFrac: prevalentWallCode.area / totWallArea,
                codeLabel,
                nomRVal: nominalInsulation,
            };
        }

        return {
            ...acc,
            [key]: {
                label: val?.label || "None",
                area: area || height * perimeter || 0,
                areaFrac: (area || height * perimeter || 0) / totWallArea,
                nomRVal: nominalInsulation,
                codeLabel,
                codeRef,
                isPrevalent: prevalentWallCode.codeRef === codeRef,
            },
        };
    }, {});

    // ================ Ceilings ================ //
    const ceilPrevalence = Object.keys(ceiling).reduce((acc, ceilingKey) => {
        let {
            measurements: { area } = {},
            ceilingInsType: { codeRef } = {},
            ceilingInsType_nomRVal: nominalInsulation = 0,
            subcomponents: { window = {} } = {},
            constructionType: { id: constructTypeId } = {},
        } = ceiling[ceilingKey];

        allWindows = {
            ...allWindows,
            ...Object.keys(window).reduce((windowAcc, windowKey) => {
                return { ...windowAcc, [`${windowKey}-${ceilingKey}`]: window[windowKey] };
            }, {}),
        };

        const isFlat = [2, 3].includes(constructTypeId);
        if (codeRef === "UserSpecified") {
            codeRef = `${isFlat ? "CeilingFlat" : "Ceiling"}-UserSpecified${nominalInsulation}`;
        }

        return {
            ...acc,
            [codeRef]: (acc[codeRef] || 0) + (area || 0),
        };
    }, {});

    let totCeilArea = 0;
    const prevalentCeilCode = Object.entries(ceilPrevalence).reduce(
        (acc, [codeRef, area]) => {
            if (area >= acc.area && codeRef.split("-")[0] === "Ceiling") {
                totCeilArea += area;
                return { codeRef, area };
            }
            return acc;
        },
        { codeRef: "", area: 0 }
    );

    table.ceiling.entries = Object.entries(ceiling).reduce((acc, [key, val]) => {
        let {
            measurements: { area } = {},
            ceilingInsType: { codeRef, codeLabel } = {},
            ceilingInsType_nomRVal: nominalInsulation = 0,
            constructionType: { id: constructTypeId } = {},
        } = val;

        const isFlat = [2, 3].includes(constructTypeId);
        if (codeRef === "UserSpecified") {
            codeRef = `${isFlat ? "CeilingFlat" : "Ceiling"}-UserSpecified${nominalInsulation}`;
        }

        if (codeRef) {
            if (prevalentCeilCode.codeRef === codeRef) {
                table.ceiling.main = {
                    codeRef: prevalentCeilCode.codeRef,
                    area: prevalentCeilCode.area,
                    areaFrac: prevalentCeilCode.area / totCeilArea,
                    codeLabel,
                    nomRVal: nominalInsulation,
                };
            }

            return {
                ...acc,
                [key]: {
                    label: val.label,
                    area: area || 0,
                    areaFrac: (area || 0) / totCeilArea,
                    nomRVal: nominalInsulation,
                    codeLabel,
                    codeRef,
                    isPrevalent: prevalentCeilCode.codeRef === codeRef,
                },
            };
        } else {
            return acc;
        }
    }, {});

    // ================ Flat Ceilings ================ //
    let totFlatCeilArea = 0;
    const prevalentFlatCeilCode = Object.entries(ceilPrevalence).reduce(
        (acc, [codeRef, area]) => {
            if ((area >= acc.area) & (codeRef.split("-")[0] === "CeilingFlat")) {
                totFlatCeilArea += area;
                return { codeRef, area };
            }
            return acc;
        },
        { codeRef: "", area: 0 }
    );

    table.ceilingFlat.entries = Object.entries(ceiling).reduce((acc, [key, val]) => {
        let {
            measurements: { area } = {},
            ceilingInsType: { codeRef, codeLabel } = {},
            ceilingInsType_nomRVal: nominalInsulation = 0,
            constructionType: { id: constructTypeId } = {},
        } = val;

        const isFlat = [2, 3].includes(constructTypeId);
        if (codeRef === "UserSpecified") {
            codeRef = `${isFlat ? "CeilingFlat" : "Ceiling"}-UserSpecified${nominalInsulation}`;
        }

        if (codeRef) {
            if (prevalentFlatCeilCode.codeRef === codeRef) {
                table.ceilingFlat.main = {
                    codeRef: prevalentFlatCeilCode.codeRef,
                    area: prevalentFlatCeilCode.area,
                    areaFrac: prevalentFlatCeilCode.area / totFlatCeilArea,
                    codeLabel,
                    nomRVal: nominalInsulation,
                };
            }

            return {
                ...acc,
                [key]: {
                    label: val.label,
                    area: area || 0,
                    areaFrac: (area || 0) / totFlatCeilArea,
                    nomRVal: nominalInsulation,
                    codeLabel,
                    codeRef,
                    isPrevalent: prevalentFlatCeilCode.codeRef === codeRef,
                },
            };
        } else {
            return acc;
        }
    }, {});

    // ================ Exposed Floors ================ //
    const expFloorPrevalence = Object.values(expFloor).reduce((acc, curr) => {
        let {
            measurements: { area } = {},
            expFloorInsType: { codeRef } = {},
            expFloorInsType_nomRVal: nominalInsulation = 0,
        } = curr;

        if (codeRef === "UserSpecified") {
            codeRef = `UserSpecified${nominalInsulation}`;
        }

        return {
            ...acc,
            [codeRef]: (acc[codeRef] || 0) + (area || 0),
        };
    }, {});

    let totExpFloorArea = 0;
    const prevalentExpFloorCode = Object.entries(expFloorPrevalence).reduce(
        (acc, [codeRef, area]) => {
            totExpFloorArea += area;
            if (area >= acc.area) {
                return { codeRef, area };
            }
            return acc;
        },
        { codeRef: "", area: 0 }
    );

    table.expFloor.entries = Object.entries(expFloor).reduce((acc, [key, val]) => {
        let {
            measurements: { area } = {},
            expFloorInsType: { codeRef, codeLabel } = {},
            expFloorInsType_nomRVal: nominalInsulation = 0,
        } = val;

        if (codeRef === "UserSpecified") {
            codeRef = `UserSpecified${nominalInsulation}`;
        }

        if (prevalentExpFloorCode.codeRef === codeRef) {
            table.expFloor.main = {
                codeRef: prevalentExpFloorCode.codeRef,
                area: prevalentExpFloorCode.area,
                areaFrac: prevalentExpFloorCode.area / totExpFloorArea,
                codeLabel,
                nomRVal: nominalInsulation,
            };
        }

        return {
            ...acc,
            [key]: {
                label: val.label,
                area: area || 0,
                areaFrac: (area || 0) / totExpFloorArea,
                nomRVal: nominalInsulation,
                codeLabel,
                codeRef,
                isPrevalent: prevalentExpFloorCode.codeRef === codeRef,
            },
        };
    }, {});

    // ================ Slab on grade ================ //
    const slabOGPrevalence = Object.values(slab).reduce((acc, curr) => {
        let {
            floor: {
                measurements: { area, length, width, isRectangular } = {},
                slabInsType: { codeRef, id } = {},
                slabInsType_nomRVal: rValue = 0,
            } = {},
        } = curr;

        if (codeRef === "UserSpecified") {
            codeRef = `UserSpecified${rValue}`;
        } else if (id != null) {
            codeRef = `ID-${id}`;
        }

        if (isRectangular) {
            area = length * width;
        }

        return {
            ...acc,
            [codeRef]: (acc[codeRef] || 0) + area,
        };
    }, {});

    let totSOGArea = 0;
    const prevalentSOGCode = Object.entries(slabOGPrevalence).reduce(
        (acc, [codeRef, area]) => {
            totSOGArea += area;
            if (area >= acc.area) {
                return { codeRef, area };
            }
            return acc;
        },
        { codeRef: "", area: 0 }
    );

    table.slabOnGrade.entries = Object.entries(slab).reduce((acc, [key, val]) => {
        let {
            floor: {
                measurements: { area, length, width, isRectangular } = {},
                slabInsType: { codeRef, codeLabel, id } = {},
                slabInsType_nomRVal: nominalInsulation = 0,
            } = {},
        } = val;

        if (codeRef === "UserSpecified") {
            codeRef = `UserSpecified${nominalInsulation}`;
        } else if (id != null) {
            codeRef = `ID-${id}`;
        }

        if (prevalentSOGCode.codeRef === codeRef) {
            table.slabOnGrade.main = {
                codeRef: prevalentSOGCode.codeRef,
                area: prevalentSOGCode.area,
                areaFrac: prevalentSOGCode.area / totSOGArea,
                codeLabel,
                nomRVal: nominalInsulation,
            };
        }

        return {
            ...acc,
            [key]: {
                label: val.label,
                area: area || length * width || 0,
                areaFrac: (area || length * width || 0) / totSOGArea,
                nomRVal: nominalInsulation,
                codeLabel,
                codeRef,
                isPrevalent: prevalentSOGCode.codeRef === codeRef,
            },
        };
    }, {});

    // ================ Slab below grade (basement and crawlspace) ================ //
    const slabBelowPrevalence = Object.values({
        ...basement,
        ...crawlspace,
    }).reduce((acc, curr) => {
        let {
            floor: {
                measurements: { area, length, width, isRectangular } = {},
                slabInsType: { codeRef, id } = {},
                slabInsType_nomRVal: rValue = 0,
            } = {},
        } = curr;

        if (codeRef === "UserSpecified") {
            codeRef = `UserSpecified${rValue}`;
        } else if (id != null) {
            codeRef = `ID-${id}`;
        }

        if (isRectangular) {
            area = length * width;
        }

        return {
            ...acc,
            [codeRef]: (acc[codeRef] || 0) + area,
        };
    }, {});

    let totSlabBelowArea = 0;
    const prevalentSlabBelowCode = Object.entries(slabBelowPrevalence).reduce(
        (acc, [codeRef, area]) => {
            totSlabBelowArea += area;
            if (area >= acc.area) {
                return { codeRef, area };
            }
            return acc;
        },
        { codeRef: "", area: 0 }
    );

    table.slabBelowGrade.entries = Object.entries({
        ...basement,
        ...crawlspace,
    }).reduce((acc, [key, val]) => {
        let {
            floor: {
                measurements: { area, length, width, isRectangular } = {},
                slabInsType: { codeRef, codeLabel, id } = {},
                slabInsType_nomRVal: nominalInsulation = 0,
            } = {},
        } = val;

        if (codeRef === "UserSpecified") {
            codeRef = `UserSpecified${nominalInsulation}`;
        } else if (id != null) {
            codeRef = `ID-${id}`;
        }

        if (prevalentSlabBelowCode.codeRef === codeRef) {
            table.slabBelowGrade.main = {
                codeRef: prevalentSlabBelowCode.codeRef,
                area: prevalentSlabBelowCode.area,
                areaFrac: prevalentSlabBelowCode.area / totSlabBelowArea,
                codeLabel,
                nomRVal: nominalInsulation,
            };
        }

        return {
            ...acc,
            [key]: {
                label: val.label,
                area: area || length * width || 0,
                areaFrac: (area || length * width || 0) / totSlabBelowArea,
                nomRVal: nominalInsulation,
                codeLabel,
                codeRef,
                isPrevalent: prevalentSlabBelowCode.codeRef === codeRef,
            },
        };
    }, {});

    // ================ Basement Walls ================ //
    let totBaseWallBGArea = 0;
    let totBaseWallAGArea = 0;
    const baseWallPrevalence = Object.keys(basement).reduce((acc, basementKey) => {
        let {
            wall: {
                measurements: { depth, height } = {},
                intAddedInsType: { codeRef } = {},
                intAddedInsType_nomRVal: rValue = 0,
            } = {},
            floor: { measurements: { perimeter, length, width, isRectangular } = {} } = {},
            subcomponents: { window = {}, door = {}, floorHeader = {} } = {},
        } = basement[basementKey];

        allDoors = {
            ...allDoors,
            ...Object.keys(door).reduce((doorAcc, doorKey) => {
                return { ...doorAcc, [`${doorKey}-${basementKey}`]: door[doorKey] };
            }, {}),
        };

        allWindows = {
            ...allWindows,
            ...Object.keys(window).reduce((windowAcc, windowKey) => {
                return { ...windowAcc, [`${windowKey}-${basementKey}`]: window[windowKey] };
            }, {}),
        };

        allFloorHeaders = {
            ...allFloorHeaders,
            ...Object.keys(floorHeader).reduce((floorHeaderAcc, floorHeaderKey) => {
                return { ...floorHeaderAcc, [`${floorHeaderKey}-${basementKey}`]: floorHeader[floorHeaderKey] };
            }, {}),
        };

        if (codeRef === "UserSpecified") {
            codeRef = `UserSpecified${rValue}`;
        }

        let basementWallArea = 0;
        if (isRectangular) {
            basementWallArea = 2 * (length + width) * depth;
            totBaseWallBGArea += 2 * (length + width) * depth;
            totBaseWallAGArea += 2 * (length + width) * (height - depth);
        } else {
            basementWallArea = perimeter * depth;
            totBaseWallBGArea += perimeter * depth;
            totBaseWallAGArea += perimeter * (height - depth);
        }

        return {
            ...acc,
            [codeRef]: (acc[codeRef] || 0) + basementWallArea,
        };
    }, {});

    const prevalentBaseWallCode = Object.entries(baseWallPrevalence).reduce(
        (acc, [codeRef, area]) => {
            if (area >= acc.area) {
                return { codeRef, area };
            }
            return acc;
        },
        { codeRef: "", area: 0 }
    );

    table.basementWall.entries = Object.entries(basement).reduce((acc, [key, val]) => {
        let {
            wall: {
                measurements: { depth, height } = {},
                intAddedInsType: { codeRef, codeLabel } = {},
                intAddedInsType_nomRVal: nominalInsulation = 0,
            } = {},
            floor: { measurements: { perimeter, length, width, isRectangular } = {} } = {},
        } = val;

        if (codeRef === "UserSpecified") {
            codeRef = `UserSpecified${nominalInsulation}`;
        }

        if (prevalentBaseWallCode.codeRef === codeRef) {
            table.basementWall.main = {
                codeRef: prevalentBaseWallCode.codeRef,
                area: prevalentBaseWallCode.area,
                areaFrac: prevalentBaseWallCode.area / totBaseWallBGArea,
                codeLabel,
                nomRVal: nominalInsulation,
            };
        }

        let basementWallArea = 0;
        if (isRectangular) {
            basementWallArea = 2 * (length + width) * depth;
        } else {
            basementWallArea = perimeter * depth;
        }

        return {
            ...acc,
            [key]: {
                label: val.label,
                area: basementWallArea,
                areaFrac: basementWallArea / totBaseWallBGArea,
                nomRVal: nominalInsulation,
                codeLabel,
                codeRef,
                isPrevalent: prevalentBaseWallCode.codeRef === codeRef,
            },
        };
    }, {});

    // ================ Crawlspace ================ //
    let totCrawlspaceBGArea = 0;
    let totCrawlspaceAGArea = 0;
    const crawlspacePrevalence = Object.keys(crawlspace).reduce((acc, crawlspaceKey) => {
        let {
            wall: {
                measurements: { depth, height } = {},
                crawlspaceWallInsType: { codeRef } = {},
                crawlspaceWallInsType_nomRVal: rValue = {},
            } = {},
            floor: { measurements: { perimeter, length, width, isRectangular } = {} } = {},
            subcomponents: { window = {}, door = {}, floorHeader = {} } = {},
        } = crawlspace[crawlspaceKey];

        allDoors = {
            ...allDoors,
            ...Object.keys(door).reduce((doorAcc, doorKey) => {
                return { ...doorAcc, [`${doorKey}-${crawlspaceKey}`]: door[doorKey] };
            }, {}),
        };

        allWindows = {
            ...allWindows,
            ...Object.keys(window).reduce((windowAcc, windowKey) => {
                return { ...windowAcc, [`${windowKey}-${crawlspaceKey}`]: window[windowKey] };
            }, {}),
        };

        allFloorHeaders = {
            ...allFloorHeaders,
            ...Object.keys(floorHeader).reduce((floorHeaderAcc, floorHeaderKey) => {
                return { ...floorHeaderAcc, [`${floorHeaderKey}-${crawlspaceKey}`]: floorHeader[floorHeaderKey] };
            }, {}),
        };

        if (codeRef === "UserSpecified") {
            codeRef = `UserSpecified${rValue}`;
        }

        let crawlspaceWallArea = 0;
        if (isRectangular) {
            crawlspaceWallArea = 2 * (length + width) * depth;
            totCrawlspaceBGArea += 2 * (length + width) * depth;
            totCrawlspaceAGArea += 2 * (length + width) * (height - depth);
        } else {
            crawlspaceWallArea = perimeter * depth;
            totCrawlspaceBGArea += perimeter * depth;
            totCrawlspaceAGArea += perimeter * (height - depth);
        }

        return {
            ...acc,
            [codeRef]: (acc[codeRef] || 0) + crawlspaceWallArea,
        };
    }, {});

    const prevalentCrawlspaceCode = Object.entries(crawlspacePrevalence).reduce(
        (acc, [codeRef, area]) => {
            if (area >= acc.area) {
                return { codeRef, area };
            }
            return acc;
        },
        { codeRef: "", area: 0 }
    );

    table.crawlspaceWall.entries = Object.entries(crawlspace).reduce((acc, [key, val]) => {
        let {
            wall: {
                measurements: { depth, height } = {},
                crawlspaceWallInsType: { codeRef, codeLabel } = {},
                crawlspaceWallInsType_nomRVal: nominalInsulation = 0,
            } = {},
            floor: { measurements: { perimeter, length, width, isRectangular } = {} } = {},
        } = val;

        if (codeRef === "UserSpecified") {
            codeRef = `UserSpecified${nominalInsulation}`;
        }

        if (prevalentCrawlspaceCode.codeRef === codeRef) {
            table.crawlspaceWall.main = {
                codeRef: prevalentCrawlspaceCode.codeRef,
                area: prevalentCrawlspaceCode.area,
                areaFrac: prevalentCrawlspaceCode.area / totCrawlspaceBGArea,
                codeLabel,
                nomRVal: nominalInsulation,
            };
        }

        let crawlspaceWallArea = 0;
        if (isRectangular) {
            crawlspaceWallArea = 2 * (length + width) * depth;
        } else {
            crawlspaceWallArea = perimeter * depth;
        }

        return {
            ...acc,
            [key]: {
                label: val.label,
                area: crawlspaceWallArea,
                areaFrac: crawlspaceWallArea / totCrawlspaceBGArea,
                nomRVal: nominalInsulation,
                codeLabel,
                codeRef,
                isPrevalent: prevalentCrawlspaceCode.codeRef === codeRef,
            },
        };
    }, {});

    // ================ Door ================ //
    const doorPrevalence = Object.keys(allDoors).reduce((acc, doorKey) => {
        if (allDoors[doorKey] == null) {
            return acc;
        }

        const {
            measurements: { height, width } = {},
            doorType: { id } = {},
            subcomponents: { window = {} } = {},
        } = allDoors[doorKey];

        allWindows = {
            ...allWindows,
            ...Object.keys(window).reduce((windowAcc, windowKey) => {
                return { ...windowAcc, [`${windowKey}-${doorKey}`]: window[windowKey] };
            }, {}),
        };

        return {
            ...acc,
            [id]: (acc[id] || 0) + (height * width || 0),
        };
    }, {});

    let totDoorArea = 0;
    const prevalentDoorCode = Object.entries(doorPrevalence).reduce(
        (acc, [id, area]) => {
            totDoorArea += area;
            if (area >= acc.area) {
                return { id, area };
            }
            return acc;
        },
        { id: "", area: 0 }
    );

    table.doors.entries = Object.entries(allDoors).reduce((acc, [key, val]) => {
        if (val == null) {
            return acc;
        }

        const { measurements: { height, width } = {}, doorType: { id, value } = {} } = val;

        if (prevalentDoorCode.id == id) {
            table.doors.main = {
                doorId: id,
                area: prevalentDoorCode.area,
                areaFrac: prevalentDoorCode.area / totDoorArea,
                rValue: value,
            };
        }

        return {
            ...acc,
            [key]: {
                area: height * width || 0,
                areaFrac: (height * width || 0) / totDoorArea,
                rValue: value,
                doorId: id,
                isPrevalent: prevalentDoorCode.id == id,
            },
        };
    }, {});

    // ================ Windows ================ //
    const windowPrevalence = Object.values(allWindows).reduce((acc, curr) => {
        if (curr == null) {
            return acc;
        }

        const { measurements: { height, width } = {}, windowType: { codeRef } = {}, numIdentical = 0 } = curr;

        const windowArea = numIdentical * ((height * width) / 1000000);
        return {
            ...acc,
            [codeRef]: (acc[codeRef] || 0) + (windowArea || 0),
        };
    }, {});

    let totWindowArea = 0;
    const prevalentWindowCode = Object.entries(windowPrevalence).reduce(
        (acc, [codeRef, area]) => {
            totWindowArea += area;
            if (area >= acc.area) {
                return { codeRef, area };
            }
            return acc;
        },
        { codeRef: "", area: 0 }
    );

    table.windows.entries = Object.entries(allWindows).reduce((acc, [key, val]) => {
        if (val == null) {
            return acc;
        }
        const {
            measurements: { height, width } = {},
            windowType: { codeRef, codeLabel } = {},
            windowType_effRVal: rValue = 0,
        } = val;

        const windowArea = (height * width) / 1000000;
        if (prevalentWindowCode.codeRef === codeRef) {
            table.windows.main = {
                codeRef: prevalentWindowCode.codeRef,
                area: prevalentWindowCode.area,
                areaFrac: prevalentWindowCode.area / totWindowArea,
                codeLabel,
                rValue: rValue,
                uValue: 1 / rValue,
            };
        }

        return {
            ...acc,
            [key]: {
                label: val.label,
                area: windowArea || 0,
                areaFrac: (windowArea || 0) / totWindowArea,
                rValue: rValue,
                uValue: 1 / rValue,
                codeLabel,
                codeRef,
                isPrevalent: prevalentWindowCode.codeRef === codeRef,
            },
        };
    }, {});

    // ================ Floor Headers ================ //
    const floorHeaderPrevalence = Object.values(allFloorHeaders).reduce((acc, curr) => {
        if (curr == null) {
            return acc;
        }

        let {
            measurements: { height, perimeter } = {},
            floorHeaderInsType: { codeRef } = {},
            floorHeaderInsType_nomRVal: nominalInsulation = 0,
        } = curr;

        if (codeRef === "UserSpecified") {
            codeRef = `UserSpecified${nominalInsulation}`;
        }

        const floorHeaderArea = height * perimeter;

        return {
            ...acc,
            [codeRef]: (acc[codeRef] || 0) + (floorHeaderArea || 0),
        };
    }, {});

    let totFloorHeaderArea = 0;
    const prevalentFloorHeaderCode = Object.entries(floorHeaderPrevalence).reduce(
        (acc, [codeRef, area]) => {
            totFloorHeaderArea += area;
            if (area >= acc.area) {
                return { codeRef, area };
            }
            return acc;
        },
        { codeRef: "", area: 0 }
    );

    table.floorHeader.entries = Object.entries(allFloorHeaders).reduce((acc, [key, val]) => {
        if (val == null) {
            return acc;
        }

        let {
            measurements: { height, perimeter } = {},
            floorHeaderInsType: { codeRef, codeLabel } = {},
            floorHeaderInsType_nomRVal: rValue = {},
        } = val;

        if (codeRef === "UserSpecified") {
            codeRef = `UserSpecified${rValue}`;
        }

        const floorHeaderArea = height * perimeter;

        if (prevalentFloorHeaderCode.codeRef === codeRef) {
            table.floorHeader.main = {
                codeRef: prevalentFloorHeaderCode.codeRef,
                area: prevalentFloorHeaderCode.area,
                areaFrac: prevalentFloorHeaderCode.area / totFloorHeaderArea,
                codeLabel,
                rValue: rValue,
            };
        }

        return {
            ...acc,
            [key]: {
                label: val.label,
                area: floorHeaderArea || 0,
                areaFrac: (floorHeaderArea || 0) / totFloorHeaderArea,
                rValue: rValue,
                codeLabel,
                codeRef,
                isPrevalent: prevalentFloorHeaderCode.codeRef === codeRef,
            },
        };
    }, {});

    // ================ Window : Wall ================ //
    // Note that windows include sliding glass doors, and windows within door components
    table.highLevel.windowToWallRatio =
        totWindowArea / (totWallArea + totBaseWallAGArea + totCrawlspaceAGArea + totFloorHeaderArea);

    return table;
};
