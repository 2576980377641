import React, { useState } from 'react';
import classes from './style.module.scss';
import { useOutsideClickHook } from 'utils/outsideClick';
import { unitLabels } from 'utils/fields';

const getColumns = (unitOptions) => unitOptions.map(option => '1fr').join(' ');

export default ({
    unitOptions=['m'],
    selectedUnit='',
    changeSelected
}) => {
    // if (unitOptions.length <= 1) {
    //     return;
    // }

    const [open, toggleOpen] = useState(false);
    const ref = useOutsideClickHook(() => toggleOpen(false));

    const selected = selectedUnit;

    return (
        <div className={`${classes.unitButton} ${unitOptions.length <= 1 && classes.disabled}`} 
        onClick={()=>{
          toggleOpen(!open)}} 
            

            ref={ref}>
            <span>{unitLabels(selected)}</span>
            {unitOptions.length > 1 &&
                <ul
                    className={`${classes.unitList} ${open ? classes.open : ''}`}
                    style={{
                        gridTemplateColumns:getColumns(unitOptions),
                    }}
                >
                    {
                        unitOptions.map(option => (
                            <li
                                key={option}
                                className={selected === option ? classes.active : ''}
                                onClick={()=>changeSelected(option)}
                            >
                                    {unitLabels(option)}
                            </li>
                        ))
                    }
                </ul>
            }
        </div>
    )
}