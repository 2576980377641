import { connect } from 'react-redux';
import ReportGenerator from './';
import { actions } from './_ducks';

const { generatePdf } = actions;

const mapStateToProps = ({
    user: {
        uid,
        userPermissions: { ENVIROCENTRE_REPORT = false } = {},
    }
}) => ({
    uid,
    ENVIROCENTRE_REPORT,
});

const mapDispatchToProps = (dispatch) => ({
    generatePdf: (formData) => dispatch(generatePdf(formData))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReportGenerator);