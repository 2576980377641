import { isEmpty } from "lodash";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import NetZeroWorkbook from "./";

const mapStateToProps = ({ form, user: { email: userEmail = "" } = {} }, { history }) => {
    const selector = formValueSelector("model");
    const nzWorkbook = selector({ form }, `modelData.nzWorkbook`) || {};
    const {
        file: {
            fileID = "", //ERS File number
            userTelephone = "", //EA phone
            userCompany = "", //EA company
            userNameEnteredBy = "", //EA Name
        } = {},
        client: { clientCity = "", clientStreetAddress = "", clientProvince = "", clientPostalCode = "" } = {},
    } = selector({ form }, `modelData.general`) || {};

    const {
        renovatorName = "",
        renovatorPhone = "",
        renovatorEmail = "",
        renovatorCompany = "",
    } = selector({ form }, `modelData.chbaNz.file`) || {};

    const totalHouseVolume = selector({ form }, `modelData.dimensions.volume.total`) || 0;

    //Fields to pre-populate the template with:
    //  General

    //Renovator name, email, phone, company
    //EA , email,
    //  Technical
    //Results
    //House volume (technical page)

    const noNetZeroWorkbook = isEmpty(nzWorkbook);

    return {
        // accessor,
        noNetZeroWorkbook,
        nzWorkbook,
        fileID,
        userTelephone,
        userCompany,
        userEmail,
        userNameEnteredBy,
        renovatorName,
        renovatorPhone,
        renovatorEmail,
        renovatorCompany,
        clientCity,
        clientStreetAddress,
        clientProvince,
        clientPostalCode,
        totalHouseVolume,
    };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(NetZeroWorkbook);
