import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import Ceiling from './';
import { getBaseUnits, unitLabels } from 'utils/fields';
import convertUnit from 'utils/convertUnit';

const mapStateToProps = (
    {
        form
    },
    {
        accessor
    }
) => {
    const selector = formValueSelector('model');
    const ceilingLength = selector({form}, `${accessor}.measurements.length`) || {};
    const ceilingArea = selector({form}, `${accessor}.measurements.area`) || {};
    const { codeLabel: ceilingLabel } = selector({form}, `${accessor}.ceilingInsType`) || {};
    const ceilingNomRVal = selector({form}, `${accessor}.ceilingInsType_nomRVal`) || 0;
    const ceilingEffRVal = selector({form}, `${accessor}.ceilingInsType_effRVal`) || 0;

    const rValUnits = selector({form}, `${accessor}.ceilingInsType_u`);
    const lengthUnits = selector({form}, `${accessor}.measurements.length_u`);
    const areaUnits = selector({form}, `${accessor}.measurements.area_u`);
    const modelUnits = selector({form}, `modelData.uiSettings.primaryUnits`);

    const { trueBase:rValBase, displayBase:rValDisplay } = getBaseUnits('ceilingInsType', modelUnits);
    const { trueBase:lengthBase, displayBase:lengthDisplay } = getBaseUnits('ceilingLength', modelUnits);
    const { trueBase:areaBase, displayBase:areaDisplay } = getBaseUnits('ceilingArea', modelUnits);

    const rValU = rValUnits || rValDisplay || rValBase;
    const lengthU = lengthUnits || lengthDisplay || lengthBase;
    const areaU = areaUnits || areaDisplay || areaBase;
    
    return {
        codeLabel:ceilingLabel,
        rvalue:`${parseFloat(convertUnit({
            value:ceilingLabel === 'User Specified' ? ceilingEffRVal : ceilingNomRVal,
            type:'thermalResistance',
            inputUnit:rValBase,
            outputUnit:rValU,
        })).toFixed(1)}${rValU}`,
        length:`${parseFloat(convertUnit({
            value:ceilingLength,
            type:'length',
            inputUnit:lengthBase,
            outputUnit:lengthU,
        })).toFixed(1)}${lengthU}`,
        area:`${parseFloat(convertUnit({
            value:ceilingArea,
            type:'area',
            inputUnit:areaBase,
            outputUnit:areaU,
        })).toFixed(1)}${unitLabels(areaU)}`,
    }
};

const mapDispatchToProps = () => ({});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Ceiling);