import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import isEmpty from 'lodash/isEmpty';
import Door from './';
import { actions } from 'features/Model/Drawing/_ducks';
import { getAllowedCategories } from 'utils/enclosure';

const {
    attachComponent,
    removeComponent,
    setActiveComponent,
    updatePrevRefs,
    updateComponentsToDelete
} = actions;

const mapStateToProps = (
    {
        drawing:{
            activeComponent='',
            activeImage='',
            imageData={},
        },
        model:{
            modelData:{
                uiSettings:{
                    primaryUnits
                }={},
                components:componentCats={}
            }={},
        },
        form
    },
    {
        onSet
    }
) => {
    const {
        [activeImage]:{
            components:{
                [activeComponent]:componentData={}
            }={} 
        }={}
    } = imageData;

    const selector = formValueSelector('drawingDoor');
    const { id:doorTypeId, value:doorRValue } = selector({form}, "doorType") || {};
    const { modelRef:selectedModelRef='' } = selector({form}, "attachTo") || {};
    const rValUnits = selector({form}, "doorType_u");
    const {
        attachTo:{
            modelRef:savedModelRef='',
            componentType:savedComponentType='',
            componentId:savedComponentId=''
        }={}
    } = componentData;

    const allowedComponents = getAllowedCategories('door') || [];

    const componentOptions = Object.keys(componentCats)
        .reduce((cache, key) => {
            // get id and location
            const components = componentCats[key] || {};
            const componentArray = Object.keys(components).map(componentId => {
                const { label='', category='', subcomponents={} } = components[componentId];
                const modelRef = savedModelRef && savedComponentId === componentId ? { modelRef:savedModelRef } : {};

                return {
                    label,
                    value:{
                        componentType:key,
                        componentId,
                        category:'',
                        ...modelRef
                    }
                }
            });

            return [
                ...cache,
                ...componentArray
            ]
        },[])
        .filter(({ value:{
            componentType=''
        } }) => allowedComponents.includes(componentType));

    //Get unsaved options from drawing
    const newComponentOptions = Object.keys(imageData)
        .filter((key) => key.includes('image_Drawing'))
        .reduce((cache, imageKey) => {
            const {
                polygons = {}
            } = imageData[imageKey];

            if (!isEmpty(polygons)) {
                const polyComponents = Object.values(polygons).reduce((pCache, poly) => {
                    const {
                        components = {}
                    } = poly;
                    if (!isEmpty(components)) {
                        return [
                            ...pCache,
                            ...Object.entries(components).reduce((cCache, [compId, comp]) => {
                                const { label='', category='' } = comp;
                                const componentType = compId.split('-')[0];

                                return [
                                    ...cCache,
                                    {
                                        label,
                                        value: {
                                            componentType,
                                            componentId: compId,
                                            category:'',
                                        }
                                    }
                                ]
                            }, [])
                        ]
                    }

                    return pCache;
                },[])

                return [
                    ...cache,
                    ...polyComponents
                ]
            }

            return cache;

        },[])
        .filter(({ value:{
            componentType=''
        } }) => allowedComponents.includes(componentType));

    //Get unique options, prioritizing model
    const componentFlags = {};
    const uniqueCompOptions = [...componentOptions, ...newComponentOptions].filter((comp) => {
        const { value: { componentId='' } = {} } = comp;
        if (componentFlags[componentId]) {
            return false;
        }
        componentFlags[componentId] = true;
        return true
    });

    return {
        onSet,
        activeComponent,
        activeImage,
        initialValues:componentData,
        doorRValue,
        doorTypeId,
        rValUnits,
        primaryUnits,
        componentOptions:uniqueCompOptions,
        savedModelRef,
        selectedModelRef
    }
};

const mapDispatchToProps = (dispatch) => ({
    removeComponent: (image, componentId) => dispatch(removeComponent({image, componentId})),
    setActiveComponent:(component) => dispatch(setActiveComponent({activeComponent:component})),
    updateComponentsToDelete:(components) => dispatch(updateComponentsToDelete(components)),
});

const mergeProps = (state, dispatch, own) => ({
    ...state,
    ...dispatch,
    ...own,
});

const onSubmit = async (
    form,
    dispatch,
    {
        activeComponent,
        activeImage,
        onSet,
        savedModelRef
    }
) => {
    const {
        attachTo:{
            modelRef:selectedModelRef=''
        }={}
    } = form;

    const data = {
        ...form,
        image:activeImage,
        componentId:activeComponent,
    }

    if (savedModelRef && savedModelRef !== selectedModelRef) {
        const refs = {
            components:{
                [activeComponent]:savedModelRef
            }
        }
        await dispatch(updatePrevRefs(refs))
    }

    await dispatch(attachComponent(data));

    if (onSet) {
        onSet();
    }
}

const form = reduxForm({
    form:"drawingDoor",
    enableReinitialize: true,
    onSubmit
})(Door);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(form);