import React, { useCallback } from 'react';
import classes from 'features/Model/Enclosure/UserDefinedCodes/style.module.scss';
import InputRow from 'components/Input/Row';
import Input from 'components/Input';
import InputWithUnits from 'components/Input/InputWithUnits';
import Select from 'components/Input/Select';
import Checkbox from 'components/Input/Checkbox';
import { Field } from 'redux-form';
import { getOptions, getFirstOption, getBaseUnits, getUnitOptions } from 'utils/fields';
import { getValidation, getDecimalPlaces } from 'utils/fieldValidation';
import { getCavityResistivity, getFrameResistivity } from 'utils/enclosure/codeLayers';
import Accordion from 'components/Accordion';
import AccordionActions from "../../AccordionActions";

const layerValidation = getValidation('udefWoodFrameLayer');
const structureValidation = getValidation('udefWoodFrameStructureType');
const trussValidation = getValidation('udefWoodFrameTrussType');

const studsCornerValidation = getValidation('udefWoodFrameStudsCorner');
const studsIntWallValidation = getValidation('udefWoodFrameStudsIntWall');
const topBottomPlatesValidation = getValidation('udefWoodFrameTopBottomPlates');
const ridgeBoardValidation = getValidation('udefWoodFrameRidgeBoardWidth');
const blockingValidation = getValidation('udefWoodFrameBlockingWidth');

const framingCategoryValidation = getValidation('udefWoodFrameMaterialCat');
const framingTypeValidation = getValidation('udefWoodFrameMaterialType');
const framingResistivityValidation = getValidation('udefWoodFrameResistivity');
const framingThicknessValidation = getValidation('udefWoodFrameThickness');
const framingRValueValidation = getValidation('udefWoodFrameRVal');
const framingWidthValidation = getValidation('udefWoodFrameWidth');
const framingSpacingValidation = getValidation('udefWoodFrameSpacing');

const cavityCategoryValidation = getValidation('udefWoodFrameCavityMaterialCat');
const cavityTypeValidation = getValidation('udefWoodFrameCavityMaterialType');
const cavityResistivityValidation = getValidation('udefWoodFrameCavityResistivity');
const cavityThicknessValidation = getValidation('udefWoodFrameCavityThickness');
const cavityRValueValidation = getValidation('udefWoodFrameCavityRVal');


export default React.memo(({
    change,
    accessor,
    componentType,
    structureTypeId,
    framingMaterialCatId,
    framingMaterialTypeId,
    framingResistivityValue,
    framingResistivityUnits,
    framingThicknessUnits,
    framingRValue,
    framingRValueUnits,
    framingWidthUnits,
    framingSpacingUnits,
    cavityMaterialCatId,
    cavityMaterialTypeId,
    cavityResistivityValue,
    cavityResistivityUnits,
    cavityThicknessUnits,
    cavityRValue,
    cavityRValueUnits,
    modelUnits,
    isDragging= false,
    dragHandleProps,
    dragActive= false,
    layer,
    woodFramingLayers,
    id
}) => {

    const standardFraming = ['Wall','BasementWall','CrawlspaceWall','FloorHeader'].includes(componentType);
    const floorFraming = ['Floor','FloorsAbove'].includes(componentType);
    const cavityMaterialTypeOptions = getOptions({fieldKey:'udefWoodFrameCavityMaterialType', indKey: cavityMaterialCatId}) || [];

    const {
        label=""
    } = cavityMaterialTypeOptions.find(({ value:{ id=0 }={} }) => id === cavityMaterialTypeId) || {};

    const deleteLayer = useCallback(() => {
        const { [id]:_, ...remainingLayers } = woodFramingLayers;
        change('uDefCode.layers.woodFraming', remainingLayers);
    },[id, woodFramingLayers, change]);

    const { displayBase:baseRValUnits="" } = getBaseUnits('udefWoodFrameCavityRVal', modelUnits);
    const rValueUnits = cavityRValueUnits || baseRValUnits;
    const materialLabel = label || `User Defined: ${cavityRValue} ${rValueUnits}`;

    //ADD USER DEFINED DEPENDENCIES
    return (
        <div>
            <Accordion
                className={classes.accordion}
                heading={
                    <span className={classes.accordionHeader}>
                        <span className={classes.accordionPreTitle}>Wood Framing</span>
                        <span className={classes.accordionTitle}>{materialLabel}</span>
                    </span>
                }
                number={layer}
                isDragging={isDragging}
                dragActive={dragActive}
                hoverActions={<AccordionActions dragHandleProps={dragHandleProps} accessor={accessor} deleteLayer={deleteLayer} />}
            >
                <InputRow gridTemplate="1fr 1fr 1fr">
                    <Field
                        component={Select}
                        name={`${accessor}.structureType`}
                        options={getOptions({fieldKey:'udefWoodFrameStructureType'})}
                        label="Structure Type"
                        placeholder="Choose Structure Type"
                        validate={structureValidation}
                        onChange={({id})=>{
                            if (id === 1) {
                                change(`${accessor}.trussType`, { id: 0 })
                            } else {
                                change(`${accessor}.trussType`, { id: null });
                            }
                        }}
                    />
                    <Field
                        component={Select}
                        name={`${accessor}.trussType`}
                        options={getOptions({fieldKey:'udefWoodFrameTrussType'})}
                        label="Truss Type"
                        placeholder="Choose Truss Type"
                        validate={trussValidation}
                        disabled={structureTypeId !== 1}
                    />
                    {
                        standardFraming &&
                        <Field
                            component={Select}
                            name={`${accessor}.isPrimary`}
                            options={[
                                {
                                    label: 'Primary',
                                    value: true
                                },
                                {
                                    label: 'Secondary',
                                    value: false
                                }
                            ]}
                            label="Frame Type"
                            placeholder="Choose Frame Type"
                        />
                    }
                </InputRow>
                {
                    standardFraming &&
                    <>
                        <InputRow gridTemplate="1fr 1fr">
                            <Field
                                component={Input}
                                type="number"
                                name={`${accessor}.studsPerCorner`}
                                label="Studs per Corner"
                                placeholder="0"
                                quantity
                                validate={studsCornerValidation}
                            />
                            <Field
                                component={Input}
                                type="number"
                                name={`${accessor}.studsPerInteriorWall`}
                                label="Studs per Interior Intersection"
                                placeholder="0"
                                quantity
                                validate={studsIntWallValidation}
                                info={'The number of studs used when interior walls meets exterior walls.'}
                            />
                        </InputRow>
                        <InputRow gridTemplate="1fr 1fr">
                            <Field
                                component={Input}
                                type="number"
                                name={`${accessor}.topAndBottomPlates`}
                                label="Top & Bottom Plates"
                                placeholder="0"
                                quantity
                                validate={topBottomPlatesValidation}
                            />
                            <Field
                                component={Checkbox}
                                name={`${accessor}.doubleStudsOnWindows`}
                                label="Double Studs on Windows"
                                large
                                className={classes.bottomCheckBox}
                                type="checkbox"
                            />
                        </InputRow>
                    </>
                }
                {
                    !standardFraming && !floorFraming &&
                    <InputRow gridTemplate="1fr 1fr">
                        <Field
                            component={Input}
                            type="number"
                            name={`${accessor}.ridgeBoardWidth`}
                            label="Ridge Board Width"
                            placeholder="0"
                            quantity
                            validate={ridgeBoardValidation}
                        />
                        <Field
                            component={Input}
                            type="number"
                            name={`${accessor}.blockingWidth`}
                            label="Blocking Width"
                            placeholder="0"
                            quantity
                            validate={blockingValidation}
                        />
                    </InputRow>
                }
                <h4>Framing</h4>
                <InputRow gridTemplate="1fr">
                    <Field
                        component={Select}
                        name={`${accessor}.framing.materialCategory`}
                        options={getOptions({fieldKey:'udefWoodFrameMaterialCat'})}
                        label="Material Category"
                        placeholder="Choose Material Category"
                        validate={framingCategoryValidation}
                        onChange={({id})=>{
                            if (id === 0) {
                                change(`${accessor}.framing.materialType`, { id: null })
                            } else {
                                change(`${accessor}.framing.materialType`, { id: 0 });
                            }
                        }}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Select}
                        name={`${accessor}.framing.materialType`}
                        options={getOptions({fieldKey:'udefWoodFrameMaterialType', indKey: framingMaterialCatId})}
                        disabled={framingMaterialCatId === 0}
                        label="Material Type"
                        placeholder="Choose Material Type"
                        validate={framingTypeValidation}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.framing.resistivity`}
                        label="Resistivity"
                        placeholder={0.00}
                        disabled={framingMaterialCatId !== 0}
                        validate={framingResistivityValidation}
                        setTouched={true}
                        decimals={getDecimalPlaces('udefWoodFrameResistivity')}
                        change={change}
                        setValue={framingMaterialTypeId == null ? framingResistivityValue : getFrameResistivity(framingMaterialTypeId)}
                        units={{
                            base:getBaseUnits('udefWoodFrameResistivity', modelUnits),
                            options:getUnitOptions('thermalResistivity'),
                            selected:framingResistivityUnits,
                            unitType:'thermalResistivity',
                            accessor:`${accessor}.framing.resistivity_u`
                        }}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.framing.thickness`}
                        label="Thickness"
                        placeholder={0.00}
                        validate={framingThicknessValidation}
                        setTouched={true}
                        decimals={getDecimalPlaces('udefWoodFrameThickness')}
                        change={change}
                        units={{
                            base:getBaseUnits('udefWoodFrameThickness', modelUnits),
                            options:getUnitOptions('length'),
                            selected:framingThicknessUnits,
                            unitType:'length',
                            accessor:`${accessor}.framing.thickness_u`
                        }}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.framing.rValue`}
                        label="R-Value"
                        placeholder={0.00}
                        disabled={true}
                        setValue={framingRValue}
                        validate={framingRValueValidation}
                        setTouched={true}
                        decimals={getDecimalPlaces('udefWoodFrameRVal')}
                        change={change}
                        units={{
                            base:getBaseUnits('udefWoodFrameRVal', modelUnits),
                            options:getUnitOptions('thermalResistance'),
                            selected:framingRValueUnits,
                            unitType:'thermalResistance',
                            accessor:`${accessor}.framing.rValue_u`
                        }}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.framing.width`}
                        label="Width"
                        placeholder={0.00}
                        validate={framingWidthValidation}
                        setTouched={true}
                        decimals={getDecimalPlaces('udefWoodFrameWidth')}
                        change={change}
                        units={{
                            base:getBaseUnits('udefWoodFrameWidth', modelUnits),
                            options:getUnitOptions('length'),
                            selected:framingWidthUnits,
                            unitType:'length',
                            accessor:`${accessor}.framing.width_u`
                        }}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.framing.spacing`}
                        label="Spacing"
                        placeholder={0.00}
                        validate={framingSpacingValidation}
                        setTouched={true}
                        decimals={getDecimalPlaces('udefWoodFrameSpacing')}
                        change={change}
                        units={{
                            base:getBaseUnits('udefWoodFrameSpacing', modelUnits),
                            options:getUnitOptions('length'),
                            selected:framingSpacingUnits,
                            unitType:'length',
                            accessor:`${accessor}.framing.spacing_u`
                        }}
                    />
                </InputRow>
                <h4>Cavity Insulation</h4>
                <InputRow gridTemplate="1fr">
                    <Field
                        component={Select}
                        name={`${accessor}.cavityInsulation.materialCategory`}
                        options={getOptions({fieldKey:'udefWoodFrameCavityMaterialCat'})}
                        label="Material Category"
                        placeholder="Choose Material Category"
                        validate={cavityCategoryValidation}
                        onChange={({id})=>{
                            if (id === 0) {
                                change(`${accessor}.cavityInsulation.materialType`, { id: null })
                            } else {
                                change(
                                    `${accessor}.cavityInsulation.materialType`, 
                                    getFirstOption({indKey:id, depKey:'udefWoodFrameCavityMaterialType'})
                                );
                            }
                        }}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Select}
                        name={`${accessor}.cavityInsulation.materialType`}
                        options={getOptions({fieldKey:'udefWoodFrameCavityMaterialType', indKey: cavityMaterialCatId})}
                        disabled={cavityMaterialCatId === 0}
                        label="Material Type"
                        placeholder="Choose Material Type"
                        validate={cavityTypeValidation}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.cavityInsulation.resistivity`}
                        label="Resistivity"
                        placeholder={0.00}
                        disabled={cavityMaterialCatId !== 0}
                        validate={cavityResistivityValidation}
                        setTouched={true}
                        decimals={getDecimalPlaces('udefWoodFrameCavityResistivity')}
                        change={change}
                        setValue={cavityMaterialTypeId == null ? cavityResistivityValue : getCavityResistivity(cavityMaterialTypeId)}
                        units={{
                            base:getBaseUnits('udefWoodFrameCavityResistivity', modelUnits),
                            options:getUnitOptions('thermalResistivity'),
                            selected:cavityResistivityUnits,
                            unitType:'thermalResistivity',
                            accessor:`${accessor}.cavityInsulation.resistivity_u`
                        }}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.cavityInsulation.thickness`}
                        label="Thickness"
                        placeholder={0.00}
                        validate={cavityThicknessValidation}
                        setTouched={true}
                        decimals={getDecimalPlaces('udefWoodFrameCavityThickness')}
                        change={change}
                        units={{
                            base:getBaseUnits('udefWoodFrameCavityThickness', modelUnits),
                            options:getUnitOptions('length'),
                            selected:cavityThicknessUnits,
                            unitType:'length',
                            accessor:`${accessor}.cavityInsulation.thickness_u`
                        }}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.cavityInsulation.rValue`}
                        label="R-Value"
                        placeholder={0.00}
                        disabled={true}
                        setValue={cavityRValue}
                        validate={cavityRValueValidation}
                        setTouched={true}
                        decimals={getDecimalPlaces('udefWoodFrameCavityRVal')}
                        change={change}
                        units={{
                            base:getBaseUnits('udefWoodFrameCavityRVal', modelUnits),
                            options:getUnitOptions('thermalResistance'),
                            selected:cavityRValueUnits,
                            unitType:'thermalResistance',
                            accessor:`${accessor}.cavityInsulation.rValue_u`
                        }}
                    />
                </InputRow>
            </Accordion>
        </div>
    )
});