import types from "./types";
import { firestore, fieldValue, MODEL_COLL } from "_firebase";

const uploadDrawingDataToFirebase = ({ modelId, drawingData }) => {
    return firestore
        .doc(`${MODEL_COLL}/${modelId}`)
        .collection("drawingData")
        .doc("data")
        .set({ imageData: drawingData })
        .catch((err) => console.log("err", err));
};

const getDrawingData = async (modelId) => {
    const drawingData = await firestore.doc(`${MODEL_COLL}/${modelId}`).collection("drawingData").doc("data").get();
    return drawingData.data() || {};
};

const updateDrawingFieldInFirebase = ({ modelId, accessor, value }) => {
    return firestore
        .doc(`${MODEL_COLL}/${modelId}`)
        .collection("drawingData")
        .doc("data")
        .update({
            [accessor]: value,
        });
};

const deleteDrawingFieldInFirebase = ({ modelId, accessor }) => {
    return firestore
        .doc(`${MODEL_COLL}/${modelId}`)
        .collection("drawingData")
        .doc("data")
        .update({
            [accessor]: fieldValue.delete(),
        })
        .catch((err) => console.log("err", err));
};

const setScale = ({ image, scale }) => ({
    type: types.SET_SCALE,
    image,
    scale,
});

const setAction = ({ id = "", meta = {} }) => ({
    type: types.SET_ACTION,
    id,
    meta,
});

const setActiveImage = ({ activeImage = "" }) => ({
    type: types.SET_ACTIVE_IMAGE,
    activeImage,
});

const setActiveComponent = ({ activeComponent = "" }) => ({
    type: types.SET_ACTIVE_COMPONENT,
    activeComponent,
});

const setActiveTool = ({ activeTool = "" }) => ({
    type: types.SET_ACTIVE_TOOL,
    activeTool,
});

const setDisabledTools = ({ disabledTools = [] }) => ({
    type: types.SET_DISABLED_TOOLS,
    disabledTools,
});

const setMessage = ({ content = "", fill = "#646F81" }) => ({
    type: types.SET_MESSAGE,
    content,
    fill,
});

const addComponent = ({ image = "", component = {} }) => ({
    type: types.ADD_COMPONENT,
    image,
    component,
});

const addPolygon = ({ image = "", polygon = {} }) => ({
    type: types.ADD_POLYGON,
    image,
    polygon,
});

const removePolygon = ({ image = "", polygonId = "" }) => ({
    type: types.REMOVE_POLYGON,
    image,
    polygonId,
});

const removePolygonComponent = ({ image = "", polygonId = "", componentId = "" }) => ({
    type: types.REMOVE_POLYGON_COMPONENT,
    image,
    polygonId,
    componentId,
});

const addLine = ({ image = "", line = {} }) => ({
    type: types.ADD_LINE,
    image,
    line,
});

const attachComponent = ({ image = "", componentId = "", attachTo = {}, componentName = "", ...fields }) => ({
    type: types.ATTACH_COMPONENT,
    image,
    componentId,
    attachTo,
    componentName,
    fields,
});

const removeComponent = ({ image = "", componentId = "" }) => ({
    type: types.REMOVE_COMPONENT,
    image,
    componentId,
});

const updateComponent = ({ image = "", componentId = "", updates = {} }) => ({
    type: types.UPDATE_COMPONENT,
    image,
    componentId,
    updates,
});

const updatePolygon = ({ image = "", componentId = "", updates = {} }) => ({
    type: types.UPDATE_POLYGON,
    image,
    componentId,
    updates,
});

const updatePolygonComponent = ({ image = "", polygonId = "", componentId = "", updates = {} }) => ({
    type: types.UPDATE_POLYGON_COMPONENT,
    image,
    componentId,
    polygonId,
    updates,
});

const updateLine = ({ image = "", id = "", updates = {} }) => ({
    type: types.UPDATE_LINE,
    image,
    id,
    updates,
});

const removeLine = ({ image, lineId = "" }) => ({
    type: types.REMOVE_LINE,
    image,
    lineId,
});

const toggleSaving = (saving) => ({
    type: types.TOGGLE_SAVING,
    saving,
});

const setDrawingData = (data) => ({
    type: types.SET_DRAWING_DATA,
    data,
});

const clearDrawingData = (data) => ({
    type: types.CLEAR_DRAWING_DATA,
});

const updatePrevRefs = (refs) => ({
    type: types.UPDATE_PREVIOUS_REFS,
    refs,
});

const updateComponentsToDelete = (toDelete) => ({
    type: types.UPDATE_COMPONENTS_TO_DELETE,
    toDelete,
});

const updateFieldsToDetach = (toDetach) => ({
    type: types.UPDATE_FIELDS_TO_DETACH,
    toDetach,
});

const updateToRemoveFromFloor = (toRemoveFromFloor) => ({
    type: types.UPDATE_REMOVE_FROM_FLOOR,
    toRemoveFromFloor,
});

const toggleImageProcessing = (processingImages) => ({
    type: types.TOGGLE_IMAGE_PROCESSING,
    processingImages,
});

const toggleDataLoading = (dataLoading) => ({
    type: types.TOGGLE_DATA_LOADING,
    dataLoading,
});

const resetDrawing = () => ({
    type: types.RESET_DRAWING,
});

const updateStage = (updates) => ({
    type: types.UPDATE_STAGE,
    updates,
});

const fetchDrawingData = (modelId) => (dispatch) => {
    return getDrawingData(modelId).then(({ imageData = {} }) => dispatch(setDrawingData(imageData)));
    //.catch(() => dispatch(setError('error')));
};

const uploadDrawingData =
    ({ modelId, drawingData }) =>
    (dispatch) => {
        return uploadDrawingDataToFirebase({ modelId, drawingData }).then(() => dispatch(setDrawingData(drawingData)));
        //.catch(() => dispatch(setError('error')));
    };

const saveDrawingField = (modelId, accessor, value) => async (dispatch) => {
    return updateDrawingFieldInFirebase({ modelId, accessor, value }).catch((err) => console.log("errr", err));
};

const deleteDrawingField = (modelId, accessor) => async (dispatch) => {
    return deleteDrawingFieldInFirebase({ modelId, accessor }).catch((err) => console.log("errr", err));
};

const saveDrawingData =
    ({ modelId, modelComponents }) =>
    (dispatch, getState) => {
        return {};
        //Code below blocked to prevent overwriting of new drawing info (as of Jan 2024)
        const imageData = getState().drawing.imageData;
        const drawingChanges = getState().enclosure.drawingChanges;

        const flattenedModelComponents = Object.keys(modelComponents).reduce(
            (cache, category) => ({
                ...cache,
                ...modelComponents[category],
            }),
            {}
        );

        const newDrawingData = Object.keys(imageData).reduce((cache, image) => {
            const { components = {}, polygons = {} } = imageData[image] || {};

            // Iterate over windows and doors and see if there are updates in "drawingChanges"
            const updatedComponents = Object.keys(components).reduce((cache, component) => {
                const { attachTo: { modelRef = "" } = {} } = components[component] || {};
                const modelRefArray = modelRef.split(".");

                if (!modelRef || !drawingChanges[modelRefArray[modelRefArray.length - 1]]) {
                    return cache;
                }

                const componentId = modelRefArray[modelRefArray.length - 1];
                const {
                    type = "",
                    drawingComponent = "",
                    drawingImage = "",
                    ...updates
                } = drawingChanges[componentId] || {};

                return {
                    ...cache,
                    [component]: {
                        ...components[component],
                        ...updates,
                    },
                };
            }, components);

            // Iterate over
            const updatedPolygons = Object.keys(polygons).reduce((cache, polygon) => {
                const { components: polygonComponents = {} } = polygons[polygon] || {};

                const updatedPolygonComponents = Object.keys(polygonComponents).reduce((cache, component) => {
                    if (!flattenedModelComponents[component]) {
                        return cache;
                    }

                    return {
                        ...cache,
                        [component]: {
                            ...polygonComponents[component],
                            ...flattenedModelComponents[component],
                        },
                    };
                }, polygonComponents);

                return {
                    ...cache,
                    [polygon]: {
                        ...polygons[polygon],
                        components: updatedPolygonComponents,
                    },
                };
            }, polygons);

            return {
                ...cache,
                [image]: {
                    ...imageData[image],
                    components: updatedComponents,
                    polygons: updatedPolygons,
                },
            };
        }, {});

        return dispatch(uploadDrawingData({ modelId, drawingData: newDrawingData }));
    };

export default {
    setScale,
    setAction,
    setActiveImage,
    setActiveTool,
    setDisabledTools,
    setMessage,
    setActiveComponent,
    addComponent,
    attachComponent,
    removeComponent,
    updateComponent,
    addPolygon,
    updatePolygon,
    addLine,
    updateLine,
    removeLine,
    removePolygon,
    toggleSaving,
    uploadDrawingData,
    fetchDrawingData,
    updatePrevRefs,
    saveDrawingField,
    updateComponentsToDelete,
    updatePolygonComponent,
    removePolygonComponent,
    deleteDrawingField,
    updateFieldsToDetach,
    clearDrawingData,
    toggleImageProcessing,
    updateToRemoveFromFloor,
    toggleDataLoading,
    resetDrawing,
    getDrawingData,
    saveDrawingData,
    updateStage,
};
