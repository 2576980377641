import React, { useEffect, useState } from "react";
import Drawer from "components/Drawer";
import Button from "components/Button";

import TTable from "features/Model/Enclosure/Takeoff/TTable/TTable";

import classes from "./style.module.scss";
import { isEmpty } from "lodash";
import convertUnit from "utils/convertUnit";
import IDGenerator from "features/Model/Enclosure/Takeoff/TakeoffData/IDGenerator";

const getBlankRow = () => ({
    id: 0,
    title: { value: "Paste window data..." },
    width: { value: 0 },
    height: { value: 0 },
    ovWidth: { value: 0 },
    ovHeight: { value: 0 },
    qty: { value: 0 },
});

const processWindowTableInput = (window, ind, modelUnits) => {
    let { label = "", direction = "", width = 0, height = 0, overhang = 0, headerHeight = 0, qty = 1 } = window || {};

    let title = label;
    if (["S", "SE", "E", "NE", "N", "NW", "W", "SW"].includes(direction)) {
        title = `${title}_${direction}`;
    }

    if (modelUnits !== "Metric") {
        //Need to store metric values
        width = convertUnit({
            value: parseFloat(width),
            type: "length",
            inputUnit: "in",
            outputUnit: "mm",
        });
        height = convertUnit({
            value: parseFloat(height),
            type: "length",
            inputUnit: "in",
            outputUnit: "mm",
        });
        overhang = convertUnit({
            value: parseFloat(overhang),
            type: "length",
            inputUnit: "ft",
            outputUnit: "m",
        });
        headerHeight = convertUnit({
            value: parseFloat(headerHeight),
            type: "length",
            inputUnit: "ft",
            outputUnit: "m",
        });
    } else {
        width = parseFloat(width);
        height = parseFloat(height);
        overhang = parseFloat(overhang);
        headerHeight = parseFloat(headerHeight);
    }

    return {
        id: IDGenerator(`toff-table-${ind}`),
        title: { value: title, canEdit: false },
        width: { value: parseFloat(width.toFixed(4)), canEdit: false },
        height: { value: parseFloat(height.toFixed(4)), canEdit: false },
        ovWidth: { value: parseFloat(overhang.toFixed(4)), canEdit: false },
        ovHeight: { value: parseFloat(headerHeight.toFixed(4)), canEdit: false },
        qty: { value: Math.max(parseInt(qty), 1), canEdit: false },
    };
};

export default React.memo(
    ({
        windowDrawerOpen,
        handleWindowDrawerClose,
        parentLabel,
        handleWindowTableImport,
        parentPath,
        category,
        modelUnits,
    }) => {
        const [pastedWindows, setPastedWindows] = useState([]);
        const [windowTableData, setWindowTableData] = useState([getBlankRow()]);
        const [pastedWindowsTextArea, setPastedWindowsTextArea] = useState("");
        const [disableWindowImport, setDisableWindowImport] = useState(true);

        const cellName = {
            0: "label",
            1: "direction",
            2: "width",
            3: "height",
            4: "overhang",
            5: "headerHeight",
            6: "qty",
        };

        const handlePaste = (e) =>
            e.clipboardData.items[0].getAsString((text) => {
                setPastedWindows([]);
                setPastedWindowsTextArea(text);

                //Check that import contains at least 5 tabs
                if (text.split("\t").length < 5) {
                    setPastedWindows([]);
                    setWindowTableData([getBlankRow()]);
                    setDisableWindowImport(true);
                    return;
                }

                let lines = text.trimEnd().split("\n");

                const mappedLines = lines.map((line) => {
                    const row = line.split("\t");
                    const mapped = row.map((item, index) => ({
                        [cellName[index]]: item,
                    }));
                    const object = Object.assign({}, ...mapped);

                    return object;
                });

                setPastedWindows(mappedLines);

                if (isEmpty(mappedLines)) {
                    setPastedWindows([]);
                    setWindowTableData([getBlankRow()]);
                    setDisableWindowImport(true);
                    return;
                }

                //Convert into format required for table inputs
                const tableData = mappedLines
                    .map((window, ind) => processWindowTableInput(window, ind, modelUnits))
                    .slice(0, 99);

                setWindowTableData(tableData);
                setDisableWindowImport(false);
            });

        return (
            <div>
                <Drawer
                    open={windowDrawerOpen}
                    close={handleWindowDrawerClose}
                    title="Window Table Inputs"
                    subtitle={`Applied to: ${parentLabel}`}
                >
                    <div>
                        <div className={classes.windowTableInstructionText}>
                            <p>
                                Paste tabular window data matching the format of the table shown below (max 100 rows).
                            </p>
                            <p>
                                Exclude any column headers from the pasted data. Make sure that columns are in the
                                correct order and all units match the table headers.
                            </p>
                            <p>
                                Column order: Label, Direction, Width, Height, Overhang Width, Header Height, Count
                                (Optional).
                            </p>
                        </div>
                        <div style={{ marginTop: "2rem" }}>
                            <textarea
                                type="text"
                                style={{
                                    width: "100%",
                                    border: "1px solid grey",
                                    height: "150px",
                                    resize: "none",
                                }}
                                placeholder="Paste windows here..."
                                value={pastedWindowsTextArea}
                                onChange={(e) => {}}
                                onPaste={(e) => handlePaste(e)}
                            />
                        </div>

                        <TTable
                            dataset={windowTableData}
                            addTableComponent={() => {}}
                            removeTableComponent={() => {}}
                            editTableComponent={() => {}}
                            modelUnits={modelUnits}
                            hideAddRow
                        />

                        <div className={classes.drawerButtons}>
                            <Button large type="hollow" onClick={handleWindowDrawerClose}>
                                Cancel
                            </Button>
                            <Button
                                large
                                onClick={() => {
                                    handleWindowTableImport({ windowTableData, parentPath, category });
                                    handleWindowDrawerClose();
                                    setPastedWindows([]);
                                    setWindowTableData([getBlankRow()]);
                                    setPastedWindowsTextArea("");
                                    setDisableWindowImport(true);
                                }}
                                disabled={disableWindowImport}
                            >
                                Confirm Import
                            </Button>
                        </div>
                    </div>
                </Drawer>
            </div>
        );
    }
);
