import React, { useCallback, useState, useEffect } from "react";
import { isEmpty } from "lodash";
import sharedClasses from "features/Model/sharedStyles.module.scss";
import classes from "./style.module.scss";
import Button from "components/Button";
import MultiSelect from "components/Input/MultiSelect";

import Accordion from "components/Accordion";
import ErrorIcon from "assets/images/icons/JSX/Tool";
import Checkbox from "components/Input/Checkbox";
import Input from "components/Input";
import ParagraphInput from "components/paragraphInput";

const UserContent = ({ modelName, submitting, errorMessage, setErrorMessage, handleShareClick }) => {
    return (
        <div>
            <div className={classes.orgHeader}>
                <p>
                    File name: <strong>{modelName || "None"}</strong>
                </p>
                <div className={classes.descriptionTextContainer}>
                    <p>
                        Enter an error message below and click the <strong>Flag File</strong> button to share this file
                        with the Volta SNAP developers for review. A Volta SNAP developer will contact you shortly with
                        next steps.
                    </p>
                    <p>Flagging a file twice will overwrite the initial submission.</p>
                </div>
            </div>
            <div className={classes.errorMessageContainer}>
                <ParagraphInput
                    formTitle={"Error Message & Notes"}
                    className={classes.errorMessageInput}
                    placeholder="Please describe the issue you have encountered."
                    disabled={submitting}
                    value={errorMessage}
                    onChange={(val) => {
                        setErrorMessage(val.target.value);
                    }}
                />
            </div>

            <div className={classes.shareButtonContainer}>
                <Button
                    className={classes.shareButton}
                    type="default"
                    onClick={handleShareClick}
                    disabled={errorMessage.replace(/\s/g, "") === "" || submitting}
                >
                    {submitting ? "Submitting..." : "Flag File"}
                </Button>
            </div>
        </div>
    );
};

const DevContent = ({ modelName, submitting, errorDetails, modelErrorSubmitted }) => {
    const { user: userName, email, message } = errorDetails;
    return (
        <div>
            <div className={classes.orgHeader}>
                <p>
                    File name: <strong>{modelName || "None"}</strong>
                </p>
                <p>
                    User name: <strong>{userName || "None"}</strong>
                </p>
                <p>
                    User email: <strong>{email || "None"}</strong>
                </p>
            </div>
            <div className={classes.errorMessageContainer}>
                <ParagraphInput
                    formTitle={"User Error Message & Notes"}
                    className={classes.errorMessageInput}
                    placeholder="No error details provided."
                    disabled={true}
                    value={message}
                />
            </div>
        </div>
    );
};

const ErrorFlagging = ({
    uid,
    flagModelError,
    modelName = "",
    modelId,
    VOLTA_DEV,
    errorDetails = {},
    modelErrorSubmitted = false,
}) => {
    const [errorMessage, setErrorMessage] = useState("");
    const [submitting, setSubmitting] = useState(false);

    const handleShareClick = useCallback(async () => {
        setSubmitting(true);
        await flagModelError(modelId, uid, errorMessage);
        setSubmitting(false);
    }, [modelId, uid, errorMessage]);

    return (
        <div className={classes.accordionContainer}>
            <Accordion
                className={sharedClasses.accordion}
                heading={
                    <div className={sharedClasses.accordionIconHeading}>
                        Flag Model Error for Review
                        <ErrorIcon fill={VOLTA_DEV && modelErrorSubmitted ? "#ac0d34" : "#65676A"} />
                    </div>
                }
                large
            >
                {VOLTA_DEV && !isEmpty(errorDetails) ? (
                    <DevContent
                        modelName={modelName}
                        submitting={submitting}
                        errorDetails={errorDetails}
                        modelErrorSubmitted={modelErrorSubmitted}
                    />
                ) : (
                    <UserContent
                        modelName={modelName}
                        submitting={submitting}
                        errorMessage={errorMessage}
                        setErrorMessage={setErrorMessage}
                        handleShareClick={handleShareClick}
                        modelErrorSubmitted={modelErrorSubmitted}
                    />
                )}
            </Accordion>
        </div>
    );
};

export default ErrorFlagging;
