const TOGGLE_AUDIT_ID_VALID = "TOGGLE_AUDIT_ID_VALID";
const SET_VALIDATION_ERROR = "SET_VALIDATION_ERROR";
const TOGGLE_IS_AUDIT_VALIDATING = "TOGGLE_IS_AUDIT_VALIDATING";
const SET_MINI_AUDIT_VALID = "SET_MINI_AUDIT_VALID";

export default {
    TOGGLE_AUDIT_ID_VALID,
    SET_VALIDATION_ERROR,
    TOGGLE_IS_AUDIT_VALIDATING,
    SET_MINI_AUDIT_VALID,
};
