import React, { useCallback } from "react";
import classes from "./style.module.scss";
import globalStyles from "components/globalStyles.module.scss";
import { Field } from "redux-form";
import InputRow from "components/Input/Row";
import InputWithUnits from "components/Input/InputWithUnits";
import { required, requiredCodeRef, getValidation, getDecimalPlaces } from "utils/fieldValidation";
import Select from "components/Input/Select";
import { getOptions } from "utils/fields";
import { useEffect } from "react";
import Add from "assets/images/icons/JSX/Add";
import Book from "assets/images/icons/JSX/Book";
import Edit from "assets/images/icons/JSX/Edit";
import { getBaseUnits, getUnitOptions } from "utils/fields";
import Tooltip from "components/Tooltip";
import { isEmpty, isEqual } from "lodash";

const ceilingTypeValidation = [required, ...getValidation("ceilingInsType")];
const constructionTypeValidation = getValidation("ceilingConstructionType");

export const CodeFooter = ({ toggleDrawer, setInitCode, codeType }) => (
    <div
        className={classes.codeFooter}
        onClick={async () => {
            await setInitCode({}, codeType);
            toggleDrawer(true);
        }}
    >
        <span>Create New Code</span>
        <Add />
    </div>
);

const codeSort = (a, b) => {
    if (a.label < b.label) {
        return -1;
    }
    if (a.label > b.label) {
        return 1;
    }
    return 0;
};

const LabelActionComponent = ({ codeRef, handleEdit, codeType }) => (
    <>
        <div
            className={globalStyles.editCode}
            data-tip="Edit Code"
            data-for={codeRef}
            onClick={(event) => handleEdit(event, codeRef, codeType)}
        >
            <Edit />
            <Tooltip id={codeRef} className={globalStyles.fieldTooltip} place="left"/>
        </div>
    </>
);

// Ceiling (non-flat) Ins. Type codes found in code library
export const ceilingTypeLibCodesMap = (libCeilingCodes, handleEditLibCeilingCode) =>
    Object.keys(libCeilingCodes)
        .map((codeRef) => {
            const { label = "" } = libCeilingCodes[codeRef] || {};
            const [standardCode] = codeRef.split("Ceiling-U"); //TODO: why is this 'U'?

            return {
                label: label,
                labelIcon: Book,
                labelTag: !standardCode ? "User Defined" : "",
                labelActionComponent: () =>
                    handleEditLibCeilingCode ? (
                        <LabelActionComponent
                            codeRef={codeRef}
                            handleEdit={handleEditLibCeilingCode}
                            codeType={standardCode ? "S" : "U"}
                        />
                    ) : null,
                value: {
                    codeLabel: label,
                    codeRef,
                },
            };
        })
        .sort(codeSort);

// Ceiling (non-flat) Ins. Type codes found in model, filtered by those in library
export const ceilingTypeModelCodesMap = (modelCodes, libCeilingCodes, handleEditModelCeilingCode) =>
    Object.values(modelCodes)
        .filter(({ codeRef }) => {
            const [component] = codeRef.split("-");
            if (!!libCeilingCodes[codeRef]) {
                return (
                    (!libCeilingCodes[codeRef] && component === "Ceiling") ||
                    !isEqual(modelCodes[codeRef].layers, libCeilingCodes[codeRef].layers)
                );
            }
            return !libCeilingCodes[codeRef] && component === "Ceiling";
        })
        .map(({ label, codeRef }) => {
            const [standardCode] = codeRef.split("Ceiling-U");

            return {
                label,
                labelTag: !standardCode ? "User Defined" : "",
                labelActionComponent: () => (
                    <LabelActionComponent
                        codeRef={codeRef}
                        handleEdit={handleEditModelCeilingCode}
                        codeType={standardCode ? "S" : "U"}
                    />
                ),
                value: {
                    codeLabel: label,
                    codeRef,
                },
            };
        })
        .sort(codeSort);

// CeilingFlat Ins. Type codes found in code library
export const ceilingFlatTypeLibCodesMap = (libCeilingFlatCodes, handleEditLibCeilingFlatCode) =>
    Object.keys(libCeilingFlatCodes)
        .map((codeRef) => {
            const { label = "" } = libCeilingFlatCodes[codeRef] || {};
            const [standardCode] = codeRef.split("CeilingFlat-U"); //TODO: why is this 'U'?

            return {
                label: label,
                labelIcon: Book,
                labelTag: !standardCode ? "User Defined" : "",
                labelActionComponent: () =>
                    handleEditLibCeilingFlatCode ? (
                        <LabelActionComponent
                            codeRef={codeRef}
                            handleEdit={handleEditLibCeilingFlatCode}
                            codeType={standardCode ? "S" : "U"}
                        />
                    ) : null,
                value: {
                    codeLabel: label,
                    codeRef,
                },
            };
        })
        .sort(codeSort);

// CeilingFlat Ins. Type codes found in model, filtered by those in library
export const ceilingFlatTypeModelCodesMap = (modelCodes, libCeilingFlatCodes, handleEditModelCeilingFlatCode) =>
    Object.values(modelCodes)
        .filter(({ codeRef }) => {
            const [component] = codeRef.split("-");
            if (!!libCeilingFlatCodes[codeRef]) {
                return (
                    (!libCeilingFlatCodes[codeRef] && component === "CeilingFlat") ||
                    !isEqual(modelCodes[codeRef].layers, libCeilingFlatCodes[codeRef].layers)
                );
            }
            return !libCeilingFlatCodes[codeRef] && component === "CeilingFlat";
        })
        .map(({ label, codeRef }) => {
            const [standardCode] = codeRef.split("CeilingFlat-U");

            return {
                label,
                labelTag: !standardCode ? "User Defined" : "",
                labelActionComponent: () => (
                    <LabelActionComponent
                        codeRef={codeRef}
                        handleEdit={handleEditModelCeilingFlatCode}
                        codeType={standardCode ? "S" : "U"}
                    />
                ),
                value: {
                    codeLabel: label,
                    codeRef,
                },
            };
        })
        .sort(codeSort);

export default React.memo(
    ({
        accessor,
        selectedCodeRef,
        constructTypeId,
        change,
        modelChange = change,
        id,
        modelUnits,
        rValUnits,
        libCeilingCodes,
        libCeilingFlatCodes,
        modelCodes,
        selectedNomRValue,
        setInitCode,
        ceilingCodeWarning,
        isCalculatingRValue,
        updateCodeDrawer,
        disabledCodeEdit = false,
        isErsAndUSpec = false,
        isRecentVintage = false,
        isUpgrade = false,
    }) => {
        const isFlat = [2, 3].includes(constructTypeId);

        useEffect(() => {
            if (isUpgrade) {
                change(`${accessor}.constructionType.id`, constructTypeId);
            }
        }, [isUpgrade, constructTypeId, accessor]);

        const handleTypeChange = useCallback(
            ({ codeRef = "" }) => {
                if (codeRef === "UserSpecified") {
                    change(`${accessor}.ceilingInsType_warning`, "");
                    change(`${accessor}.ceilingInsType_nomRVal`, selectedNomRValue || 0.1);
                    change(`${accessor}.ceilingInsType_effRVal`, selectedNomRValue || 0.1);
                    return;
                }

                const modelMatch = modelCodes[codeRef] || {};
                if (!isEmpty(modelMatch)) {
                    change(`${accessor}.ceilingInsType_nomRVal`, modelMatch.nominalRValue || 0.1);
                    change(`${accessor}.ceilingInsType_effRVal`, modelMatch.nominalRValue || 0.1);
                    change(`${accessor}.ceilingInsType_warning`, modelMatch.warningType || "");
                } else {
                    const libCodeMatch = isFlat ? libCeilingFlatCodes[codeRef] || {} : libCeilingCodes[codeRef] || {};

                    const { nominalRValue = 0, warningType = "" } = libCodeMatch || {};
                    change(`${accessor}.ceilingInsType_nomRVal`, nominalRValue || 0.1);
                    change(`${accessor}.ceilingInsType_effRVal`, nominalRValue || 0.1);
                    change(`${accessor}.ceilingInsType_warning`, warningType || "");

                    modelChange(`modelData.codes.${codeRef}`, { ...libCodeMatch, codeRef });
                }
                //Redundancy
                const codeType = codeRef.split("-")[0];
                modelChange(`stashedCodes.${codeType}.${accessor.split(".").slice(-1)[0]}`, codeRef);
            },
            [libCeilingCodes, libCeilingFlatCodes, modelCodes, change, modelChange, accessor]
        );

        const handleConstructionChange = useCallback(
            ({ id }) => {
                const isNewSelectionFlat = [2, 3].includes(id);
                if (isFlat !== isNewSelectionFlat) {
                    change(`${accessor}.ceilingInsType`, { codeLabel: "User Specified", codeRef: "UserSpecified" });
                    change(`${accessor}.ceilingInsType_nomRVal`, selectedNomRValue || 0.1);
                    change(`${accessor}.ceilingInsType_effRVal`, selectedNomRValue || 0.1);
                    change(`${accessor}.ceilingInsType_warning`, "");
                }
            },
            [constructTypeId, isFlat]
        );

        // useEffect(() => {
        //     if ((selectedCodeRef || "").includes("Ceiling-")) {
        //         modelChange(`stashedCodes.Ceiling.${id}`, selectedCodeRef);
        //     }
        // }, [id, selectedCodeRef]);

        // useEffect(() => {
        //     if ((selectedCodeRef || "").includes("CeilingFlat-")) {
        //         modelChange(`stashedCodes.CeilingFlat.${id}`, selectedCodeRef);
        //     }
        // }, [id, selectedCodeRef]);

        const handleEditModelCeilingCode = useCallback(
            async (event, codeRef, codeType) => {
                event.stopPropagation(); //Don't let click event bubble up to parent
                const initCodeType = codeType === "U" ? "udefStandard" : "ceilingInsType";
                const code = modelCodes[codeRef];
                await setInitCode(code, initCodeType);
                updateCodeDrawer({
                    isOpen: true,
                    codeName: "Ceiling",
                    isEditing: true,
                    componentType: "Ceiling",
                    codeType: codeType,
                    fieldAccessor: `${accessor}.ceilingInsType`,
                    componentId: id,
                    id: codeRef,
                    modelFormChange: modelChange,
                    currentFormChange: change,
                    lastEdited: code.lastEdited || "",
                });
            },
            [accessor, id, modelCodes, setInitCode, updateCodeDrawer, change, modelChange]
        );

        const handleEditLibCeilingCode = disabledCodeEdit
            ? null
            : useCallback(
                  async (event, codeRef, codeType) => {
                      event.stopPropagation(); //Don't let click event bubble up to parent
                      const initCodeType = codeType === "U" ? "udefStandard" : "ceilingInsType";
                      const code = { ...libCeilingCodes[codeRef], codeRef: codeRef, isLibCode: true };
                      await setInitCode(code, initCodeType);
                      updateCodeDrawer({
                          isOpen: true,
                          codeName: "Ceiling",
                          isEditing: true,
                          componentType: "Ceiling",
                          codeType: codeType,
                          fieldAccessor: `${accessor}.ceilingInsType`,
                          componentId: id,
                          id: codeRef,
                          modelFormChange: modelChange,
                          currentFormChange: change,
                          lastEdited: code.lastEdited || "",
                      });
                  },
                  [accessor, id, libCeilingCodes, setInitCode, updateCodeDrawer, change, modelChange]
              );

        const handleEditModelCeilingFlatCode = useCallback(
            async (event, codeRef, codeType) => {
                event.stopPropagation(); //Don't let click event bubble up to parent
                const initCodeType = codeType === "U" ? "udefStandard" : "ceilingInsType";
                const code = modelCodes[codeRef];
                await setInitCode(code, initCodeType);
                updateCodeDrawer({
                    isOpen: true,
                    codeName: "CeilingFlat",
                    isEditing: true,
                    componentType: "CeilingFlat",
                    codeType: codeType,
                    fieldAccessor: `${accessor}.ceilingInsType`,
                    componentId: id,
                    id: codeRef,
                    modelFormChange: modelChange,
                    currentFormChange: change,
                    lastEdited: code.lastEdited || "",
                });
            },
            [accessor, id, modelCodes, setInitCode, updateCodeDrawer, change, modelChange]
        );

        const handleEditLibCeilingFlatCode = useCallback(
            async (event, codeRef, codeType) => {
                event.stopPropagation(); //Don't let click event bubble up to parent
                const initCodeType = codeType === "U" ? "udefStandard" : "ceilingInsType";
                const code = { ...libCeilingFlatCodes[codeRef], codeRef: codeRef, isLibCode: true };
                await setInitCode(code, initCodeType);
                updateCodeDrawer({
                    isOpen: true,
                    codeName: "CeilingFlat",
                    isEditing: true,
                    componentType: "CeilingFlat",
                    codeType: codeType,
                    fieldAccessor: `${accessor}.ceilingInsType`,
                    componentId: id,
                    id: codeRef,
                    modelFormChange: modelChange,
                    currentFormChange: change,
                    lastEdited: code.lastEdited || "",
                });
            },
            [accessor, id, libCeilingFlatCodes, setInitCode, updateCodeDrawer, change, modelChange]
        );

        const insTypeModelCodesMap = isFlat
            ? ceilingFlatTypeModelCodesMap(modelCodes, libCeilingFlatCodes, handleEditModelCeilingFlatCode)
            : ceilingTypeModelCodesMap(modelCodes, libCeilingCodes, handleEditModelCeilingCode);

        const insTypeLibCodesMap = isFlat
            ? ceilingFlatTypeLibCodesMap(libCeilingFlatCodes, handleEditLibCeilingFlatCode)
            : ceilingTypeLibCodesMap(libCeilingCodes, handleEditLibCeilingCode);

        return (
            <>
                <InputRow gridTemplate={isUpgrade ? "2fr 2fr 1fr" : "31.85% 31.85% 23.25%"}>
                    <Field
                        component={Select}
                        name={`${accessor}.constructionType`}
                        options={getOptions({ fieldKey: "ceilingConstructionType" })}
                        defaultValue={{ id: 0 }}
                        label="Ceiling Construction Type"
                        placeholder="Choose Ceiling Construction Type"
                        validate={constructionTypeValidation}
                        disabled={isUpgrade}
                        onChange={handleConstructionChange}
                        setValue={isUpgrade ? { id: constructTypeId } : null}
                    />
                    <Field
                        component={Select}
                        type="number"
                        name={`${accessor}.ceilingInsType`}
                        options={[
                            {
                                label: "User Specified",
                                value: {
                                    codeLabel: "User Specified",
                                    codeRef: "UserSpecified",
                                },
                            },
                            ...insTypeModelCodesMap,
                            ...insTypeLibCodesMap,
                        ]}
                        label={isFlat ? "Flat Ceiling Type" : "Ceiling Type"}
                        placeholder={isFlat ? "Choose Flat Ceiling Type" : "Choose Ceiling Type"}
                        searchPlaceholder="Search Ceiling Codes"
                        search={true}
                        footer={() => (
                            <CodeFooter
                                toggleDrawer={(value) =>
                                    updateCodeDrawer({
                                        isOpen: value,
                                        codeName: isFlat ? "CeilingFlat" : "Ceiling",
                                        isEditing: false,
                                        componentType: isFlat ? "CeilingFlat" : "Ceiling",
                                        codeType: "S",
                                        fieldAccessor: `${accessor}.ceilingInsType`,
                                        componentId: id,
                                        modelFormChange: modelChange,
                                        currentFormChange: change,
                                    })
                                }
                                setInitCode={setInitCode}
                                codeType="ceilingInsType"
                            />
                        )}
                        className={classes.ceilingType}
                        isLoading={isCalculatingRValue}
                        onChange={handleTypeChange}
                        validate={requiredCodeRef}
                    />
                    {selectedCodeRef === "UserSpecified" ? (
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.ceilingInsType_effRVal`}
                            label={"Effective R-Value"}
                            placeholder={0.0}
                            disabled={selectedCodeRef !== "UserSpecified"}
                            validate={ceilingTypeValidation}
                            setTouched={true}
                            decimals={getDecimalPlaces("ceilingInsType")}
                            change={change}
                            units={{
                                base: getBaseUnits("ceilingInsType", modelUnits),
                                options: getUnitOptions("thermalResistance"),
                                selected: rValUnits,
                                unitType: "thermalResistance",
                                accessor: `${accessor}.ceilingInsType_u`,
                            }}
                            onChange={(value) => {
                                if (selectedCodeRef === "UserSpecified") {
                                    change(`${accessor}.ceilingInsType_nomRVal`, value || 0.1);
                                }
                            }}
                            info={"User specified values are assumed to be effective R-values."}
                            warning={
                                isErsAndUSpec
                                    ? "ersUSpecRVal"
                                    : isRecentVintage && selectedNomRValue === 0
                                    ? "ceilingInsLow1990"
                                    : ""
                            }
                        />
                    ) : (
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.ceilingInsType_nomRVal`}
                            label="Nominal R-Value"
                            placeholder={0.0}
                            disabled={true}
                            validate={ceilingTypeValidation}
                            setTouched={true}
                            decimals={getDecimalPlaces("ceilingInsType")}
                            change={change}
                            setValue={selectedNomRValue || 0.1}
                            units={{
                                base: getBaseUnits("ceilingInsType", modelUnits),
                                options: getUnitOptions("thermalResistance"),
                                selected: rValUnits,
                                unitType: "thermalResistance",
                                accessor: `${accessor}.ceilingInsType_u`,
                            }}
                            info={
                                "Values calculated by the Code Selector are nominal R-values. HOT2000 re-calculates R-values based on the content of the code when running a simulation."
                            }
                            warning={ceilingCodeWarning}
                            isLoading={isCalculatingRValue}
                        />
                    )}
                </InputRow>
            </>
        );
    }
);
