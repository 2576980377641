import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import TestData from ".";

const mapStateToProps = ({ form, model: { modelId, contentOpen = false } = {} }, { accessor, formName = "model" }) => {
    const selector = formValueSelector(formName);
    const modelSelector = formValueSelector("model");
    const { id: airTightnessTypeId } = selector({ form }, `${accessor}.testType`) || {};
    const { id: testTypeId } = selector({ form }, "modelData.airTightness.testData.testType") || {};

    const outsideTempUnits = selector({ form }, `modelData.airTightness.testData.outsideTemp_u`);
    const barometricPressureUnits = selector({ form }, `modelData.airTightness.testData.barometricPressure_u`);

    const { id: windowTightnessId, value: windowTightnessValue } =
        modelSelector({ form }, `modelData.specifications.windowTightness`) || {};
    const { id: buildingTypeId } = modelSelector({ form }, `modelData.specifications.buildingType`) || {};
    const modelUnits = modelSelector({ form }, `modelData.uiSettings.primaryUnits`) || {};
    const houseVolume = modelSelector({ form }, `modelData.dimensions.volume.total`) || 0;

    const isMURB = buildingTypeId !== 0;

    const { major: h2kMajor, minor: h2kMinor } = selector({ form }, "modelData.h2kVersion") || {};

    const uploadedData = selector({ form }, `modelData.airTightness.uploadedData`);
    const outsideTempValue = selector({ form }, "modelData.airTightness.testData.outsideTemp");

    return {
        modelId,
        h2kMinor,
        modelUnits,
        airTightnessTypeId,
        testTypeId,
        windowTightnessId,
        windowTightnessValue,
        isMURB,
        houseVolume,
        contentOpen,
        outsideTempValue,
        outsideTempUnits,
        barometricPressureUnits,
        uploadedData,
    };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TestData);
