import { connect } from "react-redux";
import NewComponentList from "./newIndex";
import { formValueSelector } from "redux-form";
import { actions } from "features/Model/_ducks";
import isEmpty from "lodash/isEmpty";

const { clearNewComponents } = actions;

const mapStateToProps = ({ form, model: { newestComponent = "" } = {} }, { catId, availableCategories }) => {
    const selector = formValueSelector("model");
    const componentsObject = selector({ form }, "modelData.components") || {};

    const { basement = {}, crawlspace = {}, slab = {}, wall = {}, ceiling = {}, expFloor = {} } = componentsObject;

    const defaultParams = selector({ form }, "modelData.defaultCodes") || {};

    const catComponents = () => {
        const basements = Object.entries(basement)
            .filter(([key, component]) => {
                const { category = "" } = component || {};
                return (
                    !isEmpty(component) &&
                    component &&
                    (category === catId || (catId === "other" && !availableCategories.includes(category)))
                );
            })
            .map(([key, values]) => ({
                id: key,
                componentId: key,
                componentType: "basement",
                componentLabel: values.label,
                displayOrder: values.displayOrder,
                subcomponents: values.subcomponents,
            }));

        const crawlspaces = Object.entries(crawlspace)
            .filter(([key, component]) => {
                const { category = "" } = component || {};
                return (
                    !isEmpty(component) &&
                    (category === catId || (catId === "other" && !availableCategories.includes(category)))
                );
            })
            .map(([key, values]) => ({
                id: key,
                componentId: key,
                componentType: "crawlspace",
                componentLabel: values.label,
                displayOrder: values.displayOrder,
                subcomponents: values.subcomponents,
            }));

        const slabs = Object.entries(slab)
            .filter(([key, component]) => {
                const { category = "" } = component || {};
                return (
                    !isEmpty(component) &&
                    (category === catId || (catId === "other" && !availableCategories.includes(category)))
                );
            })
            .map(([key, values]) => ({
                id: key,
                componentId: key,
                componentType: "slab",
                componentLabel: values.label,
                displayOrder: values.displayOrder,
                subcomponents: values.subcomponents,
            }));

        const walls = Object.entries(wall)
            .filter(([key, component]) => {
                const { category = "" } = component || {};
                return (
                    !isEmpty(component) &&
                    (category === catId || (catId === "other" && !availableCategories.includes(category)))
                );
            })
            .map(([key, values]) => ({
                id: key,
                componentId: key,
                componentType: "wall",
                componentLabel: values.label || "",
                displayOrder: values.displayOrder,
                subcomponents: values.subcomponents,
            }));

        const ceilings = Object.entries(ceiling)
            .filter(([key, component]) => {
                const { category = "" } = component || {};
                return (
                    !isEmpty(component) &&
                    (category === catId || (catId === "other" && !availableCategories.includes(category)))
                );
            })
            .map(([key, values]) => ({
                id: key,
                componentId: key,
                componentType: "ceiling",
                componentLabel: values.label,
                displayOrder: values.displayOrder,
                subcomponents: values.subcomponents,
            }));

        const expFloors = Object.entries(expFloor)
            .filter(([key, component]) => {
                const { category = "" } = component || {};
                return (
                    !isEmpty(component) &&
                    (category === catId || (catId === "other" && !availableCategories.includes(category)))
                );
            })
            .map(([key, values]) => ({
                id: key,
                componentId: key,
                componentType: "expFloor",
                componentLabel: values.label,
                displayOrder: values.displayOrder,
                subcomponents: values.subcomponents,
            }));

        // TODO: Add all other components one at a time
        return [...slabs, ...crawlspaces, ...basements, ...ceilings, ...walls, ...expFloors].sort((a, b) => {
            if (a.displayOrder !== b.displayOrder) {
                return a.displayOrder - b.displayOrder;
            }
            if (a.componentLabel !== b.componentLabel && a.componentLabel != null && b.componentLabel !== null) {
                if (a.componentLabel?.toLowerCase() < b.componentLabel?.toLowerCase()) {
                    return -1;
                }
                if (a.componentLabel?.toLowerCase() > b.componentLabel?.toLowerCase()) {
                    return 1;
                }
                return 0;
            }
            return 0;
        });
    };

    // const components = catComponents();

    return {
        catComponents: catComponents(),
        newestComponent,
        catId,
        defaultParams,
        componentsObject,
    };
};

const mapDispatchToProps = (dispatch) => ({
    clearNewComponent: (id) => dispatch(clearNewComponents(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewComponentList);
