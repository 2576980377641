import React from "react";

export default () => (
    <svg
        width="18px"
        height="18px"
        viewBox="0 0 18 18"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        fill="#979797"
    >
        <path
            d="M7,0 L7,1.992 L1.99790764,1.99227476 L1.99790764,16.0133982 L16.0028763,16.0133982 L16.002,11 L18,11 L18,18 L0,18 L0,0 L7,0 Z M18.0046463,0.00222158432 L18.0046463,7.0002718 L16.0035226,7.0002718 L16.0027864,3.41022158 L6.5,12.9142136 L5.08578644,11.5 L14.5927864,1.99222158 L10.9973936,1.99249635 L10.9973936,0.00222158432 L18.0046463,0.00222158432 Z"
            id="Rectangle"
            fillRule="nonzero"
        ></path>
    </svg>
);
