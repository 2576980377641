import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";

import pdfIcon from "assets/images/icons/pdfIcon.svg";

import classes from "./styles.module.scss";
import FileImagePreview from "components/FileImagePreview";
import CloseIcon from "assets/images/icons/JSX/Close";
import ArrowDown from "assets/images/icons/JSX/arrow-down";

const ViewFile = ({ filename, url, type }) => {
    const location = useLocation();
    const history = useHistory();

    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <FileImagePreview open={isOpen} setIsOpen={setIsOpen}>
                <div
                    className={classes.closeButton}
                    onClick={() => {
                        setIsOpen(false);
                    }}
                >
                    <CloseIcon />
                </div>
                <div className={`${classes.previewContainer} ${type !== "image" && classes.hasPdf}`}>
                    <iframe title="Preview file" src={url} width="1000px" height="800px" />
                </div>
            </FileImagePreview>
            <div
                className={`${classes.uploadFileContainer} ${classes.withHover}`}
                style={{
                    opacity: 1,
                    pointerEvents: "auto",
                }}
                onClick={(event) => {
                    history.push(`${location.pathname}?file=${filename}&isImage=${type === "image" ? true : false}`);

                    if (event.target.className !== "deleteButton") {
                        setIsOpen(true);
                    }
                }}
            >
                <img src={pdfIcon} alt="Icon" />

                <div className={classes.nameProgressWrapper} style={{ marginTop: 0 }}>
                    <div className={classes.filenameProgressContainer}>
                        <p className={`${classes.filename} ${classes.filenameUploading}`}>{filename}</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ViewFile;
