import React, { useEffect, useRef, useCallback } from "react";
import globalStyles from "components/globalStyles.module.scss";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import Select from "components/Input/Select";
import MultiSelect from "components/Input/MultiSelect";
import Checkbox from "components/Input/Checkbox";
import { Field } from "redux-form";
import Button from "components/Button";
import { getOptions, getFirstOption, getDefaultOption } from "utils/fields";
import { required, maxLength, getValidation } from "utils/fieldValidation";
import Tooltip from "components/Tooltip";
import { getAllComponents } from "utils/enclosure/components";

const charLim32 = maxLength(32);

const labelValidation = [required, charLim32];
const interiorValidation = getValidation("csWallInterior");
const structureValidation = getValidation("csWallStructureType");
const typeSizeValidation = getValidation("csWallComponentTypeSize");
const sheathingValidation = getValidation("csWallSheathing");
const framingSpacingValidation = getValidation("csWallFramingSpacing");
const exteriorValidation = getValidation("csWallExterior");
const ins1Validation = getValidation("csWallInsLayer1");
const ins2Validation = getValidation("csWallInsLayer2");
const studsValidation = getValidation("csWallStudsCornersInt");

const codeSort = (a, b) => {
    if (a.label < b.label) {
        return -1;
    }
    if (a.label > b.label) {
        return 1;
    }
    return 0;
};

export default React.memo(
    ({
        toggleOpen,
        wallLabel,
        wallStructureId,
        isSolid,
        isPanel,
        isFloorHeader,
        makeSpacingDefault,
        makeInsulationDefault,
        wallValue,
        submitting,
        handleSubmit,
        change,
        invalid,
        isLibCode,
        clearInitCode,
        wallSpacingId,
        isCodeLibrary,
        isUpgradeComponent,
        fieldAccessor,
        components,
    }) => {
        const valueRef = useRef(null);

        const handleStuctureChange = useCallback(
            ({ id, value }) => {
                change("wallCode.layers.insulationLayer1", getFirstOption({ indKey: id, depKey: "csWallInsLayer1" }));
                change(
                    "wallCode.layers.componentTypeSize",
                    getFirstOption({ indKey: id, depKey: "csWallComponentTypeSize" })
                );

                // Use "Framing" when set to Solid, use Spacing otherwise
                if (parseFloat(id) === 4) {
                    //set spacing to null and grab first option for framing
                    change("wallCode.layers.spacing", { id: null });
                    change(
                        "wallCode.layers.framing",
                        getFirstOption({
                            indKey: id,
                            depKey: "csWallFramingSpacing",
                        })
                    );
                } else {
                    //set framing to null and grab first option for spacing
                    change("wallCode.layers.framing", { id: null });

                    // Only grab default spacing id if not set
                    if (wallSpacingId === null) {
                        change("wallCode.layers.spacing", getDefaultOption("csWallFramingSpacing"));
                    }
                }

                // Panel
                if (parseFloat(id) === 5) {
                    change("wallCode.layers.insulationLayer1", getDefaultOption("csWallInsLayer1"));
                    change("wallCode.layers.insulationLayer2", getDefaultOption("csWallInsLayer2"));
                    change("wallCode.layers.studsCornerIntersection", getDefaultOption("csWallStudsCornersInt"));
                }

                // Floor Header
                if (parseFloat(id) === 6) {
                    change("wallCode.layers.componentTypeSize", getDefaultOption("csWallComponentTypeSize"));
                    change("wallCode.layers.spacing", getDefaultOption("csWallFramingSpacing"));
                    change("wallCode.layers.interior", getDefaultOption("csWallInterior"));
                    change("wallCode.layers.studsCornerIntersection", getDefaultOption("csWallStudsCornersInt"));
                }
            },
            [wallStructureId, change, wallSpacingId]
        );

        useEffect(() => {
            valueRef.current = wallValue;
        }, [wallValue, wallLabel]);

        const availableComponents = getAllComponents("wall", components, fieldAccessor).sort(codeSort);

        return (
            <div className={globalStyles.standardCodeWrapper}>
                <h3>Standard Code</h3>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Input}
                        type="text"
                        name={`wallCode.label`}
                        label="Code Label"
                        placeholder="Code Label"
                        validate={labelValidation}
                        setValue={wallLabel.toString() === valueRef.current ? wallValue : null}
                    />
                    <Field
                        component={Input}
                        type="text"
                        name={`wallCode.value`}
                        label="Internal Code"
                        placeholder="###########"
                        disabled={true}
                        setValue={wallValue}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Select}
                        name={`wallCode.layers.structureType`}
                        label="Structure Type"
                        placeholder="Choose Structure Type"
                        options={getOptions({ fieldKey: "csWallStructureType" })}
                        validate={structureValidation}
                        onChange={handleStuctureChange}
                    />
                    <Field
                        component={Select}
                        name={`wallCode.layers.interior`}
                        label="Interior"
                        placeholder="Choose Interior"
                        options={getOptions({ fieldKey: "csWallInterior" })}
                        validate={interiorValidation}
                        disabled={isFloorHeader}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Select}
                        name={`wallCode.layers.componentTypeSize`}
                        label="Component Type / Size"
                        placeholder="Choose Type / Size"
                        options={getOptions({ fieldKey: "csWallComponentTypeSize", indKey: wallStructureId })}
                        validate={typeSizeValidation}
                        disabled={isFloorHeader}
                        onChange={({ value = "" }) => {
                            if (["6", "7", "8", "9", "A", "B", "C"].includes(value)) {
                                change("wallCode.layers.insulationLayer1", getDefaultOption("csWallInsLayer1"));
                                change("wallCode.layers.insulationLayer2", getDefaultOption("csWallInsLayer2"));
                            }
                            if (["5", "6", "7", "8", "9", "A", "B", "C"].includes(value)) {
                                change("wallCode.layers.framing", { id: 0, value: "0" });
                            }
                        }}
                    />
                    <Field
                        component={Select}
                        name={`wallCode.layers.sheathing`}
                        label="Sheathing"
                        placeholder="Choose Sheathing"
                        options={getOptions({ fieldKey: "csWallSheathing" })}
                        validate={sheathingValidation}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    {isSolid ? (
                        <Field
                            component={Select}
                            name={`wallCode.layers.framing`}
                            label="Framing"
                            placeholder="Choose Framing"
                            options={getOptions({ fieldKey: "csWallFramingSpacing", indKey: wallStructureId })}
                            validate={framingSpacingValidation}
                            disabled={makeSpacingDefault}
                        />
                    ) : (
                        <Field
                            component={Select}
                            name={`wallCode.layers.spacing`}
                            label="Spacing"
                            placeholder="Choose Spacing"
                            options={getOptions({ fieldKey: "csWallFramingSpacing", indKey: wallStructureId })}
                            validate={framingSpacingValidation}
                            disabled={isPanel || isFloorHeader}
                        />
                    )}
                    <Field
                        component={Select}
                        name={`wallCode.layers.exterior`}
                        label="Exterior"
                        placeholder="Choose Exterior"
                        options={getOptions({ fieldKey: "csWallExterior" })}
                        validate={exteriorValidation}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Select}
                        name={`wallCode.layers.insulationLayer1`}
                        label="Framing Cavity Insulation (Layer 1)"
                        placeholder="Choose Insulation"
                        options={getOptions({ fieldKey: "csWallInsLayer1", indKey: wallStructureId })}
                        validate={ins1Validation}
                        disabled={isPanel || makeInsulationDefault}
                    />
                    <Field
                        component={Select}
                        name={`wallCode.layers.studsCornerIntersection`}
                        label="Studs at Corners and Intersections"
                        placeholder="Choose"
                        options={getOptions({ fieldKey: "csWallStudsCornersInt" })}
                        validate={studsValidation}
                        disabled={isPanel || isFloorHeader}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Select}
                        name={`wallCode.layers.insulationLayer2`}
                        label="Continuous Layer of Insulation (Layer 2)"
                        placeholder="Choose Insulation"
                        options={getOptions({ fieldKey: "csWallInsLayer2" })}
                        validate={ins2Validation}
                        disabled={isPanel || makeInsulationDefault}
                    />
                </InputRow>
                <div style={{ borderBottom: "1px solid #e1eaf0", marginTop: "1.5rem", marginBottom: "1.5rem" }}></div>
                {!isCodeLibrary && !isUpgradeComponent && (
                    <InputRow gridTemplate="1fr">
                        <Field
                            component={MultiSelect}
                            name={`selectedComponents`}
                            label={`Apply to multiple wall components`}
                            options={availableComponents.map(({ disabled = false, label = "", ...rest }) => ({
                                ...rest,
                                disabled,
                                label: disabled ? `${label} (current component)` : label,
                            }))}
                            hasSelectAll
                        />
                    </InputRow>
                )}
                {!isCodeLibrary && (
                    <InputRow gridTemplate="1fr 1fr">
                        <div data-tip="Code will automatically be updated in library" data-for="codeLibTip">
                            <Field
                                component={Checkbox}
                                name={"addToLibrary"}
                                label={isLibCode ? "Update code in library" : "Add to code library"}
                                large
                                disabled={isLibCode}
                                type="checkbox"
                            />
                        </div>
                        <Field
                            component={Checkbox}
                            name={"setAsModelDefault"}
                            label={"Set as model default"}
                            large
                            type="checkbox"
                        />
                    </InputRow>
                )}
                <div className={globalStyles.buttons}>
                    <Button
                        large
                        type="hollow"
                        onClick={() => {
                            clearInitCode();
                            toggleOpen(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button large onClick={handleSubmit} disabled={submitting || invalid}>
                        {submitting ? "Saving Code..." : "Save Code"}
                    </Button>
                </div>
                <div style={{ paddingTop: "7.5rem" }}></div>
                <Tooltip id="codeLibTip" hide={!isLibCode} />
            </div>
        );
    }
);
