import React from "react";
import { Field } from "redux-form";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import InputWithUnits from "components/Input/InputWithUnits";
import { getValidation, getDecimalPlaces } from "utils/fieldValidation";
import { getBaseUnits, getUnitOptions } from "utils/fields";

const heightValidation = getValidation("crawlspaceWallHeight");
const depthValidation = getValidation("crawlspaceWallDepthBelowGrade");
const cornerValidation = getValidation("crawlspaceWallCorner");

export default React.memo(
    ({
        accessor,
        modelUnits,
        heightUnits,
        depthUnits,
        change,
        wallHeightDrawing,
        wallDepthDrawing,
        crawlspaceWallHeightEquation,
        crawlspaceWallDepthEquation,
    }) => (
        <InputRow gridTemplate="1fr 1fr 1fr">
            <Field
                component={InputWithUnits}
                type="number"
                name={`${accessor}.wall.measurements.height`}
                label="Total Wall Height"
                placeholder="Enter Value"
                validate={heightValidation}
                disabled={wallHeightDrawing}
                isDrawing={wallHeightDrawing}
                decimals={getDecimalPlaces("crawlspaceWallHeight")}
                change={change}
                units={{
                    base: getBaseUnits("crawlspaceWallHeight", modelUnits),
                    options: getUnitOptions("length"),
                    selected: heightUnits,
                    unitType: "length",
                    accessor: `${accessor}.wall.measurements.height_u`,
                }}
                withEquation
                equation={crawlspaceWallHeightEquation}
                info={
                    "Enter the wall height as measured from the top of the crawl space floor to the bottom of the floor joists above. If the height varies across the perimeter, enter the weighted average height."
                }
            />
            <Field
                component={InputWithUnits}
                type="number"
                name={`${accessor}.wall.measurements.depth`}
                label="Wall Depth Below Grade"
                placeholder="Enter Value"
                validate={depthValidation}
                disabled={wallDepthDrawing}
                isDrawing={wallDepthDrawing}
                decimals={getDecimalPlaces("crawlspaceWallDepthBelowGrade")}
                change={change}
                units={{
                    base: getBaseUnits("crawlspaceWallDepthBelowGrade", modelUnits),
                    options: getUnitOptions("length"),
                    selected: depthUnits,
                    unitType: "length",
                    accessor: `${accessor}.wall.measurements.depth_u`,
                }}
                withEquation
                equation={crawlspaceWallDepthEquation}
                info={
                    "Enter the Depth Below Grade as the weighted average height of the crawl space walls below grade (measured from the average exterior grade elevation on each side of the crawl space to the top of the crawl space floor)."
                }
            />
            <Field
                component={Input}
                type="number"
                name={`${accessor}.wall.measurements.corners`}
                label="Corners"
                placeholder="Enter Value"
                quantity
                validate={cornerValidation}
                decimals={getDecimalPlaces("crawlspaceWallCorner")}
            />
        </InputRow>
    )
);
