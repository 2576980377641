import React from 'react';

export default () => (
    <svg width="39px" height="33px" viewBox="0 0 39 33" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="Artboard" transform="translate(-12.000000, -14.000000)" fill="#939597" fillRule="nonzero">
            <g id="001-book" transform="translate(12.000000, 14.000000)">
                <path d="M32.3880597,0.270941058 C34.5918516,0.277448707 36.7886,0.522523983 38.9399108,1.00201307 L38.9399108,30.1452018 C36.9575063,29.8891882 34.9612909,29.755989 32.9625896,29.7464353 C27.7207244,29.7464353 23.9492282,30.727396 21.3542595,31.8316468 L20.8814185,32.0399292 L20.435722,32.2498449 L20.0167395,32.4601375 L19.6240406,31.6695507 L19.2571948,31.8768281 L18.9157718,31.0807133 L18.599341,31.27995 L18.599341,5.69859689 C22.0028399,1.88610015 26.698846,0.616006759 30.5756682,0.335791342 L31.196828,0.298782599 L31.8016255,0.277656242 C32.0002753,0.273097719 32.1958645,0.270941058 32.3880597,0.270941058 Z M32.384,2.27 L31.952501,2.27470285 C27.6121042,2.35004648 23.733948,3.5603921 20.9072072,6.19659021 L20.599,6.494 L20.599,29.97 L20.7163106,29.9196128 C23.8355067,28.6084594 27.6931367,27.8119257 32.3817395,27.7503157 L32.9721495,27.7464581 C34.1613023,27.7521423 35.3496571,27.7998599 36.535012,27.8894602 L36.939,27.923 L36.939,2.652 L36.4817044,2.57921167 C35.3513375,2.41165112 34.211513,2.31209093 33.0683808,2.28121229 L32.384,2.27 Z" id="Path"></path>
                <path d="M6.56774569,0.270941058 C10.6999418,0.270941058 16.4010467,1.22355004 20.3564644,5.69859689 L20.3896104,32.2910268 L19.9705811,32.4712144 L19.5,32.6806276 L19.3766795,32.6806276 L18.9873259,32.4712144 L18.5713728,32.2609218 C18.4998058,32.2258695 18.4271119,32.190833 18.3532818,32.1558383 L17.8965916,31.9465822 C15.3089845,30.8007408 11.46374,29.7575121 6.0263617,29.7575121 C4.01674985,29.7620813 2.00948585,29.891542 0.0158945532,30.1452018 L0.0158945532,1.00201307 C2.16706726,0.521554758 4.36381566,0.276341022 6.56774569,0.270941058 Z M6.57,2.27 L5.88673855,2.28090597 C4.74411568,2.31133096 3.60468374,2.41074355 2.47459326,2.57844804 L2.015,2.652 L2.015,27.927 L2.43957759,27.8929313 C3.33386742,27.8272978 4.23021571,27.7854722 5.12805138,27.7675133 L6.0263617,27.7575121 C11.0072121,27.7575121 15.0743077,28.5683774 18.310367,29.9362874 L18.385,29.969 L18.357,6.489 L18.339465,6.47046429 C15.5031162,3.62188922 11.538701,2.34896039 6.99536294,2.27442896 L6.57,2.27 Z" id="Path"></path>
            </g>
        </g>
    </svg>
)