import React from 'react';
import Drawer from 'components/Drawer';
import classes from './style.module.scss';

export default ({
    children,
    open,
    close,
    title='',
    subtitle='',
}) => (
    <Drawer
        open={open}
        close={close}
        bodyClass={classes.detailsWrapper}
    >
        <div className={classes.detailsDrawer}>
            <div className={classes.heading}>
                <h2>{title}</h2>
                {subtitle && <p>{subtitle}</p>}
            </div>
            <div className={classes.body}>
                {children}
            </div>
        </div>
    </Drawer>
)