import React, { useState, useEffect } from "react";
import classes from "../style.module.scss";
import Tabs from "components/Tabs";
import General from "../General/container";
import Enclosure from "../Enclosure/container";
import Review from "../Review/container";
import Airtightness from "../Airtightness/container";
import Mechanicals from "../Mechanicals/container";
import Upgrades from "../Upgrades";
import EnclosureIcon from "assets/images/icons/Tab-Enclosure.svg";
import AirIcon from "assets/images/icons/Tab-Air.svg";
import GenerationIcon from "assets/images/icons/Generation.svg";
import SummaryIcon from "assets/images/icons/Tab-Summary.svg";
import UpgradeIcon from "assets/images/icons/Upgrades.svg";
import GeneralIcon from "assets/images/icons/Tab-General.svg";
import { mixpanel } from "components/Mixpanel";
import Drawing from "../Drawing/container";
import SectionHeading from "components/SectionHeading";
import Tooltip from "components/Tooltip";
import { timeDifference } from "utils";
import TopButtons from "../TopButtons/container";
import { isEqual } from "lodash";
import Checklist from "../Checklist/container";
import Settings from "../Settings/container";
import CompositeRCalc from "../Enclosure/CompositeRCalc/container";
import FoundationConfigDrawer from "../Enclosure/FoundationConfigDrawer/container";
import DrawingNew from "../DrawingNew/container";
import ImageGallery from "../ImageGallery";

import { isEmpty } from "lodash";

import ProjectPathChecklist from "../ProjectPathChecklist";
import Dialog, { CloseDialog } from "components/Dialog";
import { useDispatch } from "react-redux";

const ModelTabs = React.memo(
    ({
        modelId,
        history,
        pathCallback,
        readOnly,
        handleSubmit,
        change,
        invalid,
        error,
        pristine,
        submitting,
        name,
        editedDateTime,
        editedName,
        createdAt,
        ownerName,
        saveTimeout,
        modelDir,
        uid,
        codeLib,
        CHBAProjectChecklistOpen,
        toggleProjectChecklistOpen,
        missingCodes,
        clearMissingCodeWarning,
        disableSimulation,
        VOLTA_DEV,
        modelErrorSubmitted,
        isNewSnapshot,
        fetchModelData,
        isSyncing,
    }) => {
        const dispatch = useDispatch();
        const [intialPath, initialTab] = window.location.pathname.split(modelId);
        // const [drawingOpen, toggleDrawing] = useState(false);
        const [drawingOpen, toggleDrawingOpen] = useState(false);
        const [checklistOpen, toggleChecklist] = useState(false);
        const [settingsOpen, toggleSettings] = useState(false);
        const [tab, changeTab] = useState(initialTab);
        const [warningOpen, toggleWarning] = useState(false);

        useEffect(() => {
            if (!isEmpty(missingCodes) && !warningOpen) {
                toggleWarning(true);
            }
        }, [missingCodes]);

        // Update tab to match the current pathname when the browser's back button is pressed
        useEffect(() => {
            const [path, prevTab] = pathCallback().split(modelId);
            mixpanel.track("Main Tab Change", {
                Tab: prevTab,
            });
            changeTab(prevTab);
        }, [pathCallback]);

        return (
            <div>
                {VOLTA_DEV && modelErrorSubmitted && (
                    <div className={classes.devErrorReviewNotice}>
                        <div className={classes.devText}>
                            Developer error review. See model settings drawer for details.
                        </div>
                    </div>
                )}
                <SectionHeading
                    title={name}
                    subtitle={
                        editedDateTime ? (
                            <span>
                                Last edited{" "}
                                {
                                    <strong>
                                        {timeDifference({
                                            start: new Date(),
                                            end: editedDateTime,
                                        })}
                                    </strong>
                                }{" "}
                                ago{editedName ? ` by ${editedName}.` : "."}
                            </span>
                        ) : (
                            <span>
                                Created{" "}
                                {
                                    <strong>
                                        {timeDifference({
                                            start: new Date(),
                                            end: createdAt,
                                        })}
                                    </strong>
                                }{" "}
                                ago{ownerName ? ` by ${ownerName}.` : "."}
                            </span>
                        )
                    }
                    sideContent={
                        <TopButtons
                            submitting={submitting}
                            // toggleDrawing={toggleDrawing}
                            toggleNewDrawingOpen={toggleDrawingOpen}
                            handleSubmit={handleSubmit}
                            invalid={invalid}
                            toggleChecklist={toggleChecklist}
                            saveTimeout={saveTimeout}
                            toggleSettings={toggleSettings}
                            isNewSnapshot={isNewSnapshot}
                            fetchModelData={fetchModelData}
                            isSyncing={isSyncing}
                        />
                    }
                />

                <div className={classes.modelWrapper}>
                    {error && <p>{error}</p>}
                    <Tabs
                        initialTab={initialTab || "/"}
                        tabActive={tab || "/"}
                        tabClassName={classes.tabWrapper}
                        tabNavClassName={classes.tabNavWrapper}
                        tabs={[
                            { name: "General", url: "/", icon: GeneralIcon },
                            {
                                name: "Enclosure",
                                url: "/enclosure",
                                icon: EnclosureIcon,
                            },
                            {
                                name: "Airtightness",
                                url: "/airtightness",
                                icon: AirIcon,
                            },
                            {
                                name: "Mechanicals",
                                url: "/mechanicals",
                                icon: GenerationIcon,
                            },
                            {
                                name: "Upgrades",
                                url: "/upgrades",
                                icon: UpgradeIcon,
                            },
                            {
                                name: "Review",
                                url: "/review",
                                icon: SummaryIcon,
                            },
                        ]}
                        onChange={(e) => {
                            history.push(`/models/${modelId}${e}`);
                            mixpanel.track("Main Tab Change", {
                                Tab: e,
                            });
                            return changeTab(e);
                        }}
                        stretchTabs
                    >
                        <General history={history} change={change} modelSave={handleSubmit} readOnly={readOnly} />
                        <Enclosure accessor={"modelData.dimensions"} history={history} change={change} />
                        <Airtightness accessor={"modelData.airTightness"} change={change} history={history} />
                        <Mechanicals history={history} change={change} />
                        <Upgrades
                            history={history}
                            change={change}
                            modelSave={handleSubmit}
                            uid={uid}
                            codeLib={codeLib}
                            readOnly={readOnly}
                            modelDir={modelDir}
                            modelId={modelId}
                            disableSimulation={disableSimulation}
                        />
                        <Review
                            history={history}
                            modelSave={handleSubmit}
                            isInvalid={invalid}
                            change={change}
                            readOnly={readOnly}
                        />
                    </Tabs>
                </div>
                {/* <Drawing
                    modelChange={change}
                    modelSave={handleSubmit}
                    modelName={name}
                    open={drawingOpen}
                    toggleOpen={toggleDrawing}
                /> */}
                <DrawingNew
                    open={drawingOpen}
                    toggleOpen={toggleDrawingOpen}
                    modelChange={change}
                    modelSave={handleSubmit}
                    modelName={name}
                    saving={submitting}
                    isNewSnapshot={isNewSnapshot}
                />
                <ImageGallery handleSave={handleSubmit} isSavingModel={submitting} />
                <Settings change={change} open={settingsOpen} toggleOpen={toggleSettings} />
                <ProjectPathChecklist
                    open={CHBAProjectChecklistOpen}
                    toggleOpen={toggleProjectChecklistOpen}
                    handleSave={handleSubmit}
                />
                <Settings change={change} open={settingsOpen} toggleOpen={toggleSettings} />
                <Checklist open={checklistOpen} toggleOpen={toggleChecklist} />
                <Tooltip id="saveTip" hide={!submitting && !invalid && !pristine} />
                {(isSyncing || !isNewSnapshot) && <Tooltip id="syncTip" hide={isSyncing || !isNewSnapshot} />}
                <Tooltip id="drawingTip" delay />
                <Tooltip id="openToggleTip" />
                <CompositeRCalc modelFormChange={change} />
                <Tooltip id="photoGalleryTip" />
                <FoundationConfigDrawer modelFormChange={change} />
                <Dialog open={warningOpen}>
                    <CloseDialog
                        closeActions={() => {
                            dispatch(clearMissingCodeWarning());
                            toggleWarning(false);
                        }}
                    />
                    <div>
                        <p style={{ marginBottom: "1rem" }}>
                            Code reference(s) were detected in your model whose details were not properly saved. Please
                            re-select the following codes from the Type drop down menu for all components to which the
                            following codes are applied:
                        </p>
                        <ul>
                            {Object.keys(missingCodes).map((codeRef, ind) => (
                                <li key={ind}>{`${codeRef.split("-")[0]} code: ${missingCodes[codeRef]}`}</li>
                            ))}
                        </ul>
                    </div>
                </Dialog>
            </div>
        );
    },
    isEqual
);

export default ModelTabs;
