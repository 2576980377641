import React, { useCallback } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { updateStage } from "../_ducks/actions";

import { selectNewDrawingStage } from "../_ducks/selector";

import { zoom, disable } from "./style.module.scss";

const Zoom = ({ stage, updateStage }) => {
    const handleScaleChange = useCallback(
        (newScale) => {
            updateStage({
                scale: newScale,
            });
        },
        [stage.size.width, stage.size.height, stage.scale, stage.coords.x, stage.coords.y, updateStage]
    );

    return (
        <div className={zoom}>
            <span
                className={stage.scale <= 0.25 ? disable : ""}
                onClick={() => handleScaleChange(stage.scale - 0.15)}
            ></span>
            <span>{parseInt(stage.scale * 100)}%</span>
            <span
                className={stage.scale >= 5 ? disable : ""}
                onClick={() => handleScaleChange(stage.scale + 0.15)}
            ></span>
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    stage: selectNewDrawingStage,
});

const mapDispatchToProps = (dispatch) => ({
    updateStage: (stage) => dispatch(updateStage(stage)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Zoom);
