import React from 'react';
import classes from './style.module.scss';
import NonH2kParam from 'assets/images/icons/JSX/NonH2kParam';
import getNonH2kParamText from 'utils/getNonH2kParamText';
import Tooltip from 'components/Tooltip';
import uniqid from 'uniqid';

export default ({ nonH2kParam, className }) => {
    const id = uniqid("tooltip-");
    return (
        <div
            className={`${classes.nonH2kParam} ${className && className}`}
            data-html
            data-tip={`<span style="display:block;max-width:14rem;">${getNonH2kParamText(nonH2kParam)}</span>`}
            data-for={`${id}_nonH2kParamTip`}
        >
            <NonH2kParam />
            <Tooltip id={`${id}_nonH2kParamTip`} />
        </div>
    )
}