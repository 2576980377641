import { getWalkoutGeometry, getTotalPonyWallArea } from "utils/enclosure/geometry";

const convertValue = (targetUnit, unit, value) => {
    return value;
};

const calculateFloorHeaderVolume = (foundation) => {
    const {
        floorHeaderHeight: { value: fhValue = 0 } = {},
        area: { value: areaValue = 0 },
    } = foundation;

    const addedVolume = fhValue * areaValue;
    return isNaN(addedVolume) ? 0 : Math.max(0, addedVolume);
};

export const getFoundationInputs = (type, foundation) => {
    switch (type.toLowerCase()) {
        case "slab": {
            return [
                {
                    label: "Slab Area",
                    type: "number",
                    property: "slabArea",
                    fieldKey: "takeoffArea",
                    decimals: 2,
                    stateValue: foundation.slabArea.value,
                    stateUnit: foundation.slabArea.unit,
                    units: {
                        options: ["m2", "ft2"],
                        selected: foundation.slabArea.unit,
                        unitType: "area",
                    },
                },
                {
                    label: "Total Perimeter",
                    type: "number",
                    property: "totalPerimeter",
                    fieldKey: "takeoffLength",
                    decimals: 2,
                    stateValue: foundation.totalPerimeter.value,
                    stateUnit: foundation.totalPerimeter.unit,
                    units: {
                        options: ["m", "ft"],
                        selected: foundation.totalPerimeter.unit,
                        unitType: "length",
                    },
                },
                {
                    label: "Int. (Shared) Floor Length",
                    type: "number",
                    property: "interiorWallLength",
                    fieldKey: "takeoffLength",
                    decimals: 2,
                    stateValue: foundation.interiorWallLength.value,
                    stateUnit: foundation.interiorWallLength.unit,
                    units: {
                        options: ["m", "ft"],
                        selected: foundation.interiorWallLength.unit,
                        unitType: "length",
                    },
                },
                {
                    label: "Exposed Perimeter",
                    type: "number",
                    property: "exteriorWallLength",
                    fieldKey: "takeoffLength",
                    decimals: 2,
                    stateValue:
                        convertValue(
                            foundation.exteriorWallLength.unit,
                            foundation.totalPerimeter.unit,
                            foundation.totalPerimeter.value
                        ) -
                        convertValue(
                            foundation.exteriorWallLength.unit,
                            foundation.interiorWallLength.unit,
                            foundation.interiorWallLength.value
                        ),
                    stateUnit: foundation.exteriorWallLength.unit,
                    disabled: true,
                    units: {
                        options: ["m", "ft"],
                        selected: foundation.exteriorWallLength.unit,
                        unitType: "length",
                    },
                },
                // {
                //     label: "Exposed Floor Area",
                //     type: "number",
                //     property: "exposedFloorArea",
                //     decimals: 2,
                //     stateValue: foundation.exposedFloorArea.value,
                //     stateUnit: foundation.exposedFloorArea.unit,
                //     units: {
                //         options: ["m2", "ft2"],
                //         selected: foundation.exposedFloorArea.unit,
                //         unitType: "area",
                //     },
                // },
                // {
                //     label: "Total Wall Height",
                //     type: "number",
                //     property: "totalWallHeight",
                //     decimals: 2,
                //     stateValue: foundation.totalWallHeight.value,
                //     stateUnit: foundation.totalWallHeight.unit,
                //     units: {
                //         options: ["m", "ft"],
                //         selected: foundation.totalWallHeight.unit,
                //         unitType: "length",
                //     },
                // },
                // {
                //     label: "Corners",
                //     type: "number",
                //     property: "corners",
                //     decimals: 0,
                //     stateValue: foundation.corners.value,
                //     stateUnit: foundation.corners.unit,
                //     units: {
                //         options: [],
                //         selected: foundation.corners.unit,
                //         unitType: "count",
                //     },
                // },
                // {
                //     label: "Intersections",
                //     type: "number",
                //     property: "intersections",
                //     decimals: 2,
                //     stateValue: foundation.intersections.value,
                //     stateUnit: foundation.intersections.unit,
                //     units: {
                //         options: [],
                //         selected: foundation.intersections.unit,
                //         unitType: "count",
                //     },
                // },
                // {
                //     label: "Wall Surface Area",
                //     type: "number",
                //     property: "wallSurfaceArea",
                //     decimals: 2,
                //     stateValue:
                //         convertValue(
                //             foundation.wallSurfaceArea.unit,
                //             foundation.totalPerimeter.unit,
                //             foundation.totalPerimeter.value
                //         ) *
                //         convertValue(
                //             foundation.wallSurfaceArea.unit,
                //             foundation.totalWallHeight.unit,
                //             foundation.totalWallHeight.value
                //         ),
                //     stateUnit: foundation.wallSurfaceArea.unit,
                //     disabled: true,
                //     units: {
                //         options: ["m2", "ft2"],
                //         selected: foundation.wallSurfaceArea.unit,
                //         unitType: "area",
                //     },
                // },
                // {
                //     label: "Volume",
                //     type: "number",
                //     property: "volume",
                //     decimals: 2,
                //     stateValue:
                //         convertValue(foundation.volume.unit, foundation.slabArea.unit, foundation.slabArea.value) *
                //         convertValue(
                //             foundation.volume.unit,
                //             foundation.totalWallHeight.unit,
                //             foundation.totalWallHeight.value
                //         ),
                //     stateUnit: foundation.volume.unit,
                //     disabled: true,
                //     units: {
                //         options: ["m3", "ft3"],
                //         selected: foundation.volume.unit,
                //         unitType: "volume",
                //     },
                // },
            ];
        }
        case "basementcrawlspace": {
            return [
                //! Basement
                {
                    label: "Floor Plan Area",
                    type: "number",
                    property: "area",
                    fieldKey: "takeoffArea",
                    decimals: 2,
                    stateValue: foundation.area.value,
                    stateUnit: foundation.area.unit,
                    units: {
                        options: ["m2", "ft2"],
                        selected: foundation.area.unit,
                        unitType: "area",
                    },
                },
                {
                    label: "Total Perimeter",
                    type: "number",
                    property: "totalPerimeter",
                    fieldKey: "takeoffLength",
                    decimals: 2,
                    stateValue: foundation.totalPerimeter.value,
                    stateUnit: foundation.totalPerimeter.unit,
                    units: {
                        options: ["m", "ft"],
                        selected: foundation.totalPerimeter.unit,
                        unitType: "length",
                    },
                },
                {
                    label: "Int. (Shared) Wall Length",
                    type: "number",
                    property: "interiorWallLength",
                    fieldKey: "takeoffLength",
                    decimals: 2,
                    stateValue: foundation.interiorWallLength.value,
                    stateUnit: foundation.interiorWallLength.unit,
                    units: {
                        options: ["m", "ft"],
                        selected: foundation.interiorWallLength.unit,
                        unitType: "length",
                    },
                },
                {
                    label: "Total Wall Height",
                    type: "number",
                    property: "totalWallHeight",
                    fieldKey: "takeoffLength",
                    decimals: 2,
                    stateValue: foundation.totalWallHeight.value,
                    stateUnit: foundation.totalWallHeight.unit,
                    units: {
                        options: ["m", "ft"],
                        selected: foundation.totalWallHeight.unit,
                        unitType: "length",
                    },
                },
                {
                    label: "AG Wall Height",
                    type: "number",
                    property: "agHeight",
                    fieldKey: "takeoffLength",
                    decimals: 2,
                    stateValue: foundation.agHeight.value,
                    stateUnit: foundation.agHeight.unit,
                    units: {
                        options: ["m", "ft"],
                        selected: foundation.agHeight.unit,
                        unitType: "length",
                    },
                },
                {
                    label: "Corners",
                    type: "number",
                    property: "corners",
                    fieldKey: "takeoffCount",
                    decimals: 0,
                    stateValue: foundation.corners.value,
                    stateUnit: foundation.corners.unit,
                    units: {
                        options: [],
                        selected: foundation.corners.unit,
                        unitType: "count",
                    },
                },
                {
                    label: "Wall Surface Area",
                    type: "number",
                    property: "wallSurfaceArea",
                    fieldKey: "takeoffArea",
                    decimals: 2,
                    stateValue:
                        convertValue(
                            foundation.wallSurfaceArea.unit,
                            foundation.totalPerimeter.unit,
                            foundation.totalPerimeter.value
                        ) *
                        convertValue(
                            foundation.wallSurfaceArea.unit,
                            foundation.totalWallHeight.unit,
                            foundation.totalWallHeight.value
                        ),
                    stateUnit: foundation.wallSurfaceArea.unit,
                    disabled: true,
                    units: {
                        options: ["m2", "ft2"],
                        selected: foundation.wallSurfaceArea.unit,
                        unitType: "area",
                    },
                },
                {
                    label: "AG Wall Surface Area",
                    type: "number",
                    property: "agWallSurfaceArea",
                    fieldKey: "takeoffArea",
                    decimals: 2,
                    stateValue:
                        convertValue(
                            foundation.agWallSurfaceArea.unit,
                            foundation.totalPerimeter.unit,
                            foundation.totalPerimeter.value
                        ) *
                        convertValue(
                            foundation.agWallSurfaceArea.unit,
                            foundation.agHeight.unit,
                            foundation.agHeight.value
                        ),
                    stateUnit: foundation.agWallSurfaceArea.unit,
                    disabled: true,
                    units: {
                        options: ["m2", "ft2"],
                        selected: foundation.agWallSurfaceArea.unit,
                        unitType: "area",
                    },
                },
                {
                    label: "Volume",
                    type: "number",
                    property: "volume",
                    fieldKey: "takeoffVolume",
                    decimals: 2,
                    stateValue:
                        convertValue(foundation.volume.unit, foundation.area.unit, foundation.area.value) *
                            convertValue(
                                foundation.volume.unit,
                                foundation.totalWallHeight.unit,
                                foundation.totalWallHeight.value
                            ) +
                        calculateFloorHeaderVolume(foundation),
                    stateUnit: foundation.volume.unit,
                    disabled: true,
                    units: {
                        options: ["m3", "ft3"],
                        selected: foundation.volume.unit,
                        unitType: "volume",
                    },
                },
                {
                    label: "Exposed Wall Perimeter",
                    type: "number",
                    property: "exteriorWallLength",
                    fieldKey: "takeoffLength",
                    decimals: 2,
                    stateValue:
                        convertValue(
                            foundation.exteriorWallLength.unit,
                            foundation.totalPerimeter.unit,
                            foundation.totalPerimeter.value
                        ) -
                        convertValue(
                            foundation.exteriorWallLength.unit,
                            foundation.interiorWallLength.unit,
                            foundation.interiorWallLength.value
                        ),
                    stateUnit: foundation.exteriorWallLength.unit,
                    disabled: true,
                    units: {
                        options: ["m", "ft"],
                        selected: foundation.exteriorWallLength.unit,
                        unitType: "length",
                    },
                },
                {
                    label: "BG Wall Depth",
                    type: "number",
                    property: "bgDepth",
                    fieldKey: "takeoffLength",
                    decimals: 2,
                    stateValue:
                        convertValue(
                            foundation.bgDepth.unit,
                            foundation.totalWallHeight.unit,
                            foundation.totalWallHeight.value
                        ) - convertValue(foundation.bgDepth.unit, foundation.agHeight.unit, foundation.agHeight.value),
                    stateUnit: foundation.bgDepth.unit,
                    disabled: true,
                    units: {
                        options: ["m", "ft"],
                        selected: foundation.bgDepth.unit,
                        unitType: "length",
                    },
                },
                {
                    type: "divider", //? This is just so there can be a division between the basement and the crawslpace set of inputs. It serves no functional purpose
                },
                //!Crawlspace
                {
                    label: "Floor Plan Area",
                    type: "number",
                    property: "crawlarea",
                    fieldKey: "takeoffArea",
                    decimals: 2,
                    stateValue: foundation.crawlarea.value,
                    stateUnit: foundation.crawlarea.unit,
                    units: {
                        options: ["m2", "ft2"],
                        selected: foundation.crawlarea.unit,
                        unitType: "area",
                    },
                },
                {
                    label: "Total Perimeter",
                    type: "number",
                    property: "crawltotalPerimeter",
                    fieldKey: "takeoffLength",
                    decimals: 2,
                    stateValue: foundation.crawltotalPerimeter.value,
                    stateUnit: foundation.crawltotalPerimeter.unit,
                    units: {
                        options: ["m", "ft"],
                        selected: foundation.crawltotalPerimeter.unit,
                        unitType: "length",
                    },
                },
                {
                    label: "Int. (Shared) Wall Length",
                    type: "number",
                    property: "crawlinteriorWallLength",
                    fieldKey: "takeoffLength",
                    decimals: 2,
                    stateValue: foundation.crawlinteriorWallLength.value,
                    stateUnit: foundation.crawlinteriorWallLength.unit,
                    units: {
                        options: ["m", "ft"],
                        selected: foundation.crawlinteriorWallLength.unit,
                        unitType: "length",
                    },
                },
                {
                    label: "Total Wall Height",
                    type: "number",
                    property: "crawltotalWallHeight",
                    fieldKey: "takeoffLength",
                    decimals: 2,
                    stateValue: foundation.crawltotalWallHeight.value,
                    stateUnit: foundation.crawltotalWallHeight.unit,
                    units: {
                        options: ["m", "ft"],
                        selected: foundation.crawltotalWallHeight.unit,
                        unitType: "length",
                    },
                },
                {
                    label: "AG Wall Height",
                    type: "number",
                    property: "crawlagHeight",
                    fieldKey: "takeoffLength",
                    decimals: 2,
                    stateValue: foundation.crawlagHeight.value,
                    stateUnit: foundation.crawlagHeight.unit,
                    units: {
                        options: ["m", "ft"],
                        selected: foundation.crawlagHeight.unit,
                        unitType: "length",
                    },
                },
                {
                    label: "Corners",
                    type: "number",
                    property: "crawlcorners",
                    fieldKey: "takeoffCount",
                    decimals: 0,
                    stateValue: foundation.crawlcorners.value,
                    stateUnit: foundation.crawlcorners.unit,
                    units: {
                        options: [],
                        selected: foundation.crawlcorners.unit,
                        unitType: "count",
                    },
                },
                {
                    label: "Wall Surface Area",
                    type: "number",
                    property: "crawlwallSurfaceArea",
                    fieldKey: "takeoffArea",
                    decimals: 2,
                    stateValue:
                        convertValue(
                            foundation.crawlwallSurfaceArea.unit,
                            foundation.crawltotalPerimeter.unit,
                            foundation.crawltotalPerimeter.value
                        ) *
                        convertValue(
                            foundation.crawlwallSurfaceArea.unit,
                            foundation.crawltotalWallHeight.unit,
                            foundation.crawltotalWallHeight.value
                        ),
                    stateUnit: foundation.crawlwallSurfaceArea.unit,
                    disabled: true,
                    units: {
                        options: ["m2", "ft2"],
                        selected: foundation.crawlwallSurfaceArea.unit,
                        unitType: "area",
                    },
                },
                {
                    label: "AG Wall Surface Area",
                    type: "number",
                    property: "crawlagWallSurfaceArea",
                    fieldKey: "takeoffArea",
                    decimals: 2,
                    stateValue:
                        convertValue(
                            foundation.crawlagWallSurfaceArea.unit,
                            foundation.crawltotalPerimeter.unit,
                            foundation.crawltotalPerimeter.value
                        ) *
                        convertValue(
                            foundation.crawlagWallSurfaceArea.unit,
                            foundation.crawlagHeight.unit,
                            foundation.crawlagHeight.value
                        ),
                    stateUnit: foundation.crawlagWallSurfaceArea.unit,
                    disabled: true,
                    units: {
                        options: ["m2", "ft2"],
                        selected: foundation.crawlagWallSurfaceArea.unit,
                        unitType: "area",
                    },
                },
                {
                    label: "Volume",
                    type: "number",
                    property: "crawlvolume",
                    fieldKey: "takeoffVolume",
                    decimals: 2,
                    stateValue:
                        convertValue(
                            foundation.crawlvolume.unit,
                            foundation.crawlarea.unit,
                            foundation.crawlarea.value
                        ) *
                            convertValue(
                                foundation.crawlvolume.unit,
                                foundation.crawltotalWallHeight.unit,
                                foundation.crawltotalWallHeight.value
                            ) +
                        calculateFloorHeaderVolume(foundation),
                    stateUnit: foundation.crawlvolume.unit,
                    disabled: true,
                    units: {
                        options: ["m3", "ft3"],
                        selected: foundation.crawlvolume.unit,
                        unitType: "volume",
                    },
                },
                {
                    label: "Exposed Wall Perimeter",
                    type: "number",
                    property: "crawlexteriorWallLength",
                    fieldKey: "takeoffLength",
                    decimals: 2,
                    stateValue:
                        convertValue(
                            foundation.crawlexteriorWallLength.unit,
                            foundation.crawltotalPerimeter.unit,
                            foundation.crawltotalPerimeter.value
                        ) -
                        convertValue(
                            foundation.crawlexteriorWallLength.unit,
                            foundation.crawlinteriorWallLength.unit,
                            foundation.crawlinteriorWallLength.value
                        ),
                    stateUnit: foundation.crawlexteriorWallLength.unit,
                    disabled: true,
                    units: {
                        options: ["m", "ft"],
                        selected: foundation.crawlexteriorWallLength.unit,
                        unitType: "length",
                    },
                },
                {
                    label: "BG Depth",
                    type: "number",
                    property: "crawlbgDepth",
                    fieldKey: "takeoffArea",
                    decimals: 2,
                    stateValue:
                        convertValue(
                            foundation.crawlbgDepth.unit,
                            foundation.crawltotalWallHeight.unit,
                            foundation.crawltotalWallHeight.value
                        ) -
                        convertValue(
                            foundation.crawlbgDepth.unit,
                            foundation.crawlagHeight.unit,
                            foundation.crawlagHeight.value
                        ),
                    stateUnit: foundation.crawlbgDepth.unit,
                    disabled: true,
                    units: {
                        options: ["m", "ft"],
                        selected: foundation.crawlbgDepth.unit,
                        unitType: "length",
                    },
                },
            ];
        }
        case "walkout": {
            //Calculate walkout geometry
            //AG Wall Surface Area
            //BG Wall Surface Area
            //Average BG Depth

            const totalPonyWallArea = getTotalPonyWallArea(
                foundation.components
                    .filter((comp) => comp.type === "ponywall")
                    .map((ponywall) => {
                        return ponywall.fields.reduce(
                            (cache, field) => ({
                                ...cache,
                                [field.field]: convertValue("m", field.unit, field.value),
                            }),
                            {}
                        );
                    })
            );

            const { agWalkoutArea, bgWalkoutArea, avgBelowGradeDepth } = getWalkoutGeometry({
                totalFloorArea: convertValue("m2", foundation.area.unit, foundation.area.value),
                exposedPerimeter: convertValue(
                    "m",
                    foundation.exteriorWallLength.unit,
                    foundation.exteriorWallLength.value
                ),
                totalWallHeight: convertValue("m", foundation.totalWallHeight.unit, foundation.totalWallHeight.value),
                buriedWalkoutDepth: convertValue(
                    "m",
                    foundation.buriedWalkoutDepth.unit,
                    foundation.buriedWalkoutDepth.value
                ),
                shallowWalkoutDepth: convertValue(
                    "m",
                    foundation.shallowWalkoutDepth.unit,
                    foundation.shallowWalkoutDepth.value
                ),
                slopedGradeWallLength: convertValue(
                    "m",
                    foundation.slopedGradeWallLength.unit,
                    foundation.slopedGradeWallLength.value
                ),
                upperGradeLength: convertValue(
                    "m",
                    foundation.upperGradeLength.unit,
                    foundation.upperGradeLength.value
                ),
                walkoutSlabLength: convertValue(
                    "m",
                    foundation.walkoutSlabLength.unit,
                    foundation.walkoutSlabLength.value
                ),
                totalPonyWallArea: totalPonyWallArea,
            });

            return [
                {
                    label: "Floor Plan Area",
                    type: "number",
                    property: "area",
                    fieldKey: "takeoffArea",
                    decimals: 2,
                    stateValue: foundation.area.value,
                    stateUnit: foundation.area.unit,
                    units: {
                        options: ["m2", "ft2"],
                        selected: foundation.area.unit,
                        unitType: "area",
                    },
                },
                {
                    label: "Total Perimeter",
                    type: "number",
                    property: "totalPerimeter",
                    fieldKey: "takeoffLength",
                    decimals: 2,
                    stateValue: foundation.totalPerimeter.value,
                    stateUnit: foundation.totalPerimeter.unit,
                    units: {
                        options: ["m", "ft"],
                        selected: foundation.totalPerimeter.unit,
                        unitType: "length",
                    },
                },
                {
                    label: "Int. (Shared) Wall Length",
                    type: "number",
                    property: "interiorWallLength",
                    fieldKey: "takeoffLength",
                    decimals: 2,
                    stateValue: 0, //foundation.interiorWallLength.value,
                    stateUnit: foundation.interiorWallLength.unit,
                    disabled: true,
                    units: {
                        options: ["m", "ft"],
                        selected: foundation.interiorWallLength.unit,
                        unitType: "length",
                    },
                },
                {
                    label: "Total Wall Height",
                    type: "number",
                    property: "totalWallHeight",
                    fieldKey: "takeoffLength",
                    decimals: 2,
                    stateValue: foundation.totalWallHeight.value,
                    stateUnit: foundation.totalWallHeight.unit,
                    units: {
                        options: ["m", "ft"],
                        selected: foundation.totalWallHeight.unit,
                        unitType: "length",
                    },
                },
                {
                    label: "Buried Wall Depth",
                    type: "number",
                    property: "buriedWalkoutDepth",
                    fieldKey: "takeoffLength",
                    decimals: 2,
                    stateValue: foundation?.buriedWalkoutDepth?.value || 0,
                    stateUnit: foundation?.buriedWalkoutDepth?.unit || "m",
                    units: {
                        options: ["m", "ft"],
                        selected: foundation?.buriedWalkoutDepth?.unit || "m",
                        unitType: "length",
                    },
                },
                {
                    label: "Shallow Wall Depth",
                    type: "number",
                    property: "shallowWalkoutDepth",
                    fieldKey: "takeoffLength",
                    decimals: 2,
                    stateValue: foundation?.shallowWalkoutDepth?.value || 0,
                    stateUnit: foundation?.shallowWalkoutDepth?.unit || "m",
                    units: {
                        options: ["m", "ft"],
                        selected: foundation?.shallowWalkoutDepth?.unit || "m",
                        unitType: "length",
                    },
                },
                {
                    label: "Sloped Grade Wall Length",
                    type: "number",
                    property: "slopedGradeWallLength",
                    fieldKey: "takeoffLength",
                    decimals: 2,
                    stateValue: foundation?.slopedGradeWallLength?.value || 0,
                    stateUnit: foundation?.slopedGradeWallLength?.unit || "m",
                    units: {
                        options: ["m", "ft"],
                        selected: foundation?.slopedGradeWallLength?.unit || "m",
                        unitType: "length",
                    },
                },
                {
                    label: "Upper Grade Length",
                    type: "number",
                    property: "upperGradeLength",
                    fieldKey: "takeoffLength",
                    decimals: 2,
                    stateValue: foundation?.upperGradeLength?.value || 0,
                    stateUnit: foundation?.upperGradeLength?.unit || "m",
                    units: {
                        options: ["m", "ft"],
                        selected: foundation?.upperGradeLength?.unit || "m",
                        unitType: "length",
                    },
                },
                {
                    label: "Slab Length",
                    type: "number",
                    property: "walkoutSlabLength",
                    fieldKey: "takeoffLength",
                    decimals: 2,
                    stateValue: foundation?.walkoutSlabLength?.value || 0,
                    stateUnit: foundation?.walkoutSlabLength?.unit || "m",
                    units: {
                        options: ["m", "ft"],
                        selected: foundation?.walkoutSlabLength?.unit || "m",
                        unitType: "length",
                    },
                },
                {
                    label: "Corners",
                    type: "number",
                    property: "corners",
                    fieldKey: "takeoffCount",
                    decimals: 0,
                    stateValue: foundation.corners.value,
                    stateUnit: foundation.corners.unit,
                    units: {
                        options: [],
                        selected: foundation.corners.unit,
                        unitType: "count",
                    },
                },
                {
                    label: "Floor Header Height",
                    type: "number",
                    property: "floorHeaderHeight",
                    fieldKey: "takeoffLength",
                    decimals: 2,
                    stateValue: foundation?.floorHeaderHeight?.value || 0,
                    stateUnit: foundation?.floorHeaderHeight?.unit || "",
                    units: {
                        options: ["m", "ft"],
                        selected: foundation?.floorHeaderHeight?.unit || "",
                        unitType: "length",
                    },
                },
                {
                    label: "Wall Surface Area",
                    type: "number",
                    property: "wallSurfaceArea",
                    fieldKey: "takeoffArea",
                    decimals: 2,
                    stateValue:
                        convertValue(
                            foundation.wallSurfaceArea.unit,
                            foundation.exteriorWallLength.unit,
                            foundation.exteriorWallLength.value
                        ) *
                        convertValue(
                            foundation.wallSurfaceArea.unit,
                            foundation.totalWallHeight.unit,
                            foundation.totalWallHeight.value
                        ),
                    stateUnit: foundation.wallSurfaceArea.unit,
                    disabled: true,
                    units: {
                        options: ["m2", "ft2"],
                        selected: foundation.wallSurfaceArea.unit,
                        unitType: "area",
                    },
                },
                {
                    label: "Net AG Wall Surface Area",
                    type: "number",
                    property: "agWallSurfaceArea",
                    fieldKey: "takeoffArea",
                    decimals: 2,
                    stateValue: agWalkoutArea,
                    stateUnit: foundation.agWallSurfaceArea.unit,
                    disabled: true,
                    units: {
                        options: ["m2", "ft2"],
                        selected: foundation.agWallSurfaceArea.unit,
                        unitType: "area",
                    },
                },
                {
                    label: "Volume",
                    type: "number",
                    property: "volume",
                    fieldKey: "takeoffVolume",
                    decimals: 2,
                    stateValue:
                        convertValue(foundation.volume.unit, foundation.area.unit, foundation.area.value) *
                            convertValue(
                                foundation.volume.unit,
                                foundation.totalWallHeight.unit,
                                foundation.totalWallHeight.value
                            ) +
                        calculateFloorHeaderVolume(foundation),
                    stateUnit: foundation.volume.unit,
                    disabled: true,
                    units: {
                        options: ["m3", "ft3"],
                        selected: foundation.volume.unit,
                        unitType: "volume",
                    },
                },
                {
                    label: "Exposed Wall Perimeter",
                    type: "number",
                    property: "exteriorWallLength",
                    fieldKey: "takeoffLength",
                    decimals: 2,
                    stateValue:
                        convertValue(
                            foundation.exteriorWallLength.unit,
                            foundation.totalPerimeter.unit,
                            foundation.totalPerimeter.value
                        ) -
                        convertValue(
                            foundation.exteriorWallLength.unit,
                            foundation.interiorWallLength.unit,
                            foundation.interiorWallLength.value
                        ),
                    stateUnit: foundation.exteriorWallLength.unit,
                    disabled: true,
                    units: {
                        options: ["m", "ft"],
                        selected: foundation.exteriorWallLength.unit,
                        unitType: "length",
                    },
                },
                {
                    label: "BG Wall Depth",
                    type: "number",
                    property: "bgDepth",
                    fieldKey: "takeoffLength",
                    decimals: 2,
                    stateValue: avgBelowGradeDepth,
                    stateUnit: foundation.bgDepth.unit,
                    disabled: true,
                    units: {
                        options: ["m", "ft"],
                        selected: foundation.bgDepth.unit,
                        unitType: "length",
                    },
                },
            ];
        }
        case "crawlspace":
            return [
                {
                    label: "Floor Plan Area",
                    type: "number",
                    property: "area",
                    fieldKey: "takeoffArea",
                    decimals: 2,
                    stateValue: foundation.area.value,
                    stateUnit: foundation.area.unit,
                    units: {
                        options: ["m2", "ft2"],
                        selected: foundation.area.unit,
                        unitType: "area",
                    },
                },
                {
                    label: "Total Perimeter",
                    type: "number",
                    property: "totalPerimeter",
                    fieldKey: "takeoffLength",
                    decimals: 2,
                    stateValue: foundation.totalPerimeter.value,
                    stateUnit: foundation.totalPerimeter.unit,
                    units: {
                        options: ["m", "ft"],
                        selected: foundation.totalPerimeter.unit,
                        unitType: "length",
                    },
                },
                {
                    label: "Int. (Shared) Wall Length",
                    type: "number",
                    property: "interiorWallLength",
                    fieldKey: "takeoffLength",
                    decimals: 2,
                    stateValue: foundation.interiorWallLength.value,
                    stateUnit: foundation.interiorWallLength.unit,
                    units: {
                        options: ["m", "ft"],
                        selected: foundation.interiorWallLength.unit,
                        unitType: "length",
                    },
                },
                {
                    label: "Total Wall Height",
                    type: "number",
                    property: "totalWallHeight",
                    fieldKey: "takeoffLength",
                    decimals: 2,
                    stateValue: foundation.totalWallHeight.value,
                    stateUnit: foundation.totalWallHeight.unit,
                    units: {
                        options: ["m", "ft"],
                        selected: foundation.totalWallHeight.unit,
                        unitType: "length",
                    },
                },
                {
                    label: "AG Wall Height",
                    type: "number",
                    property: "agHeight",
                    fieldKey: "takeoffLength",
                    decimals: 2,
                    stateValue: foundation.agHeight.value,
                    stateUnit: foundation.agHeight.unit,
                    units: {
                        options: ["m", "ft"],
                        selected: foundation.agHeight.unit,
                        unitType: "length",
                    },
                },
                {
                    label: "Corners",
                    type: "number",
                    property: "corners",
                    fieldKey: "takeoffCount",
                    decimals: 0,
                    stateValue: foundation.corners.value,
                    stateUnit: foundation.corners.unit,
                    units: {
                        options: [],
                        selected: foundation.corners.unit,
                        unitType: "count",
                    },
                },
                {
                    label: "Floor Header Height",
                    type: "number",
                    property: "floorHeaderHeight",
                    fieldKey: "takeoffLength",
                    decimals: 2,
                    stateValue: foundation?.floorHeaderHeight?.value,
                    stateUnit: foundation?.floorHeaderHeight?.unit || "",
                    units: {
                        options: ["m", "ft"],
                        selected: foundation?.floorHeaderHeight?.unit || "",
                        unitType: "length",
                    },
                },
                {
                    label: "Wall Surface Area",
                    type: "number",
                    property: "wallSurfaceArea",
                    fieldKey: "takeoffArea",
                    decimals: 2,
                    stateValue:
                        convertValue(
                            foundation.wallSurfaceArea.unit,
                            foundation.totalPerimeter.unit,
                            foundation.totalPerimeter.value
                        ) *
                        convertValue(
                            foundation.wallSurfaceArea.unit,
                            foundation.totalWallHeight.unit,
                            foundation.totalWallHeight.value
                        ),
                    stateUnit: foundation.wallSurfaceArea.unit,
                    disabled: true,
                    units: {
                        options: ["m2", "ft2"],
                        selected: foundation.wallSurfaceArea.unit,
                        unitType: "area",
                    },
                },
                {
                    label: "AG Wall Surface Area",
                    type: "number",
                    property: "agWallSurfaceArea",
                    fieldKey: "takeoffArea",
                    decimals: 2,
                    stateValue:
                        convertValue(
                            foundation.agWallSurfaceArea.unit,
                            foundation.totalPerimeter.unit,
                            foundation.totalPerimeter.value
                        ) *
                        convertValue(
                            foundation.agWallSurfaceArea.unit,
                            foundation.agHeight.unit,
                            foundation.agHeight.value
                        ),
                    stateUnit: foundation.agWallSurfaceArea.unit,
                    disabled: true,
                    units: {
                        options: ["m2", "ft2"],
                        selected: foundation.agWallSurfaceArea.unit,
                        unitType: "area",
                    },
                },
                {
                    label: "Volume",
                    type: "number",
                    property: "volume",
                    fieldKey: "takeoffVolume",
                    decimals: 2,
                    stateValue:
                        convertValue(foundation.volume.unit, foundation.area.unit, foundation.area.value) *
                            convertValue(
                                foundation.volume.unit,
                                foundation.totalWallHeight.unit,
                                foundation.totalWallHeight.value
                            ) +
                        calculateFloorHeaderVolume(foundation),
                    stateUnit: foundation.volume.unit,
                    disabled: true,
                    units: {
                        options: ["m3", "ft3"],
                        selected: foundation.volume.unit,
                        unitType: "volume",
                    },
                },
                {
                    label: "Exposed Wall Perimeter",
                    type: "number",
                    property: "exteriorWallLength",
                    fieldKey: "takeoffLength",
                    decimals: 2,
                    stateValue:
                        convertValue(
                            foundation.exteriorWallLength.unit,
                            foundation.totalPerimeter.unit,
                            foundation.totalPerimeter.value
                        ) -
                        convertValue(
                            foundation.exteriorWallLength.unit,
                            foundation.interiorWallLength.unit,
                            foundation.interiorWallLength.value
                        ),
                    stateUnit: foundation.exteriorWallLength.unit,
                    disabled: true,
                    units: {
                        options: ["m", "ft"],
                        selected: foundation.exteriorWallLength.unit,
                        unitType: "length",
                    },
                },
                {
                    label: "BG Wall Depth",
                    type: "number",
                    property: "bgDepth",
                    fieldKey: "takeoffLength",
                    decimals: 2,
                    stateValue:
                        convertValue(
                            foundation.bgDepth.unit,
                            foundation.totalWallHeight.unit,
                            foundation.totalWallHeight.value
                        ) - convertValue(foundation.bgDepth.unit, foundation.agHeight.unit, foundation.agHeight.value),
                    stateUnit: foundation.bgDepth.unit,
                    disabled: true,
                    units: {
                        options: ["m", "ft"],
                        selected: foundation.bgDepth.unit,
                        unitType: "length",
                    },
                },
            ];
        default: {
            //Basement
            return [
                {
                    label: "Floor Plan Area",
                    type: "number",
                    property: "area",
                    fieldKey: "takeoffArea",
                    decimals: 2,
                    stateValue: foundation.area.value,
                    stateUnit: foundation.area.unit,
                    units: {
                        options: ["m2", "ft2"],
                        selected: foundation.area.unit,
                        unitType: "area",
                    },
                },
                {
                    label: "Total Perimeter",
                    type: "number",
                    property: "totalPerimeter",
                    fieldKey: "takeoffLength",
                    decimals: 2,
                    stateValue: foundation.totalPerimeter.value,
                    stateUnit: foundation.totalPerimeter.unit,
                    units: {
                        options: ["m", "ft"],
                        selected: foundation.totalPerimeter.unit,
                        unitType: "length",
                    },
                },
                {
                    label: "Int. (Shared) Wall Length",
                    type: "number",
                    property: "interiorWallLength",
                    fieldKey: "takeoffLength",
                    decimals: 2,
                    stateValue: foundation.interiorWallLength.value,
                    stateUnit: foundation.interiorWallLength.unit,
                    units: {
                        options: ["m", "ft"],
                        selected: foundation.interiorWallLength.unit,
                        unitType: "length",
                    },
                },
                {
                    label: "Total Wall Height",
                    type: "number",
                    property: "totalWallHeight",
                    fieldKey: "takeoffLength",
                    decimals: 2,
                    stateValue: foundation.totalWallHeight.value,
                    stateUnit: foundation.totalWallHeight.unit,
                    units: {
                        options: ["m", "ft"],
                        selected: foundation.totalWallHeight.unit,
                        unitType: "length",
                    },
                },
                {
                    label: "AG Wall Height",
                    type: "number",
                    property: "agHeight",
                    fieldKey: "takeoffLength",
                    decimals: 2,
                    stateValue: foundation.agHeight.value,
                    stateUnit: foundation.agHeight.unit,
                    units: {
                        options: ["m", "ft"],
                        selected: foundation.agHeight.unit,
                        unitType: "length",
                    },
                },
                {
                    label: "Corners",
                    type: "number",
                    property: "corners",
                    fieldKey: "takeoffCount",
                    decimals: 0,
                    stateValue: foundation.corners.value,
                    stateUnit: foundation.corners.unit,
                    units: {
                        options: [],
                        selected: foundation.corners.unit,
                        unitType: "count",
                    },
                },
                {
                    label: "Floor Header Height",
                    type: "number",
                    property: "floorHeaderHeight",
                    fieldKey: "takeoffLength",
                    decimals: 2,
                    stateValue: foundation?.floorHeaderHeight?.value,
                    stateUnit: foundation?.floorHeaderHeight?.unit || "",
                    units: {
                        options: ["m", "ft"],
                        selected: foundation?.floorHeaderHeight?.unit || "",
                        unitType: "length",
                    },
                },
                {
                    label: "Wall Surface Area",
                    type: "number",
                    property: "wallSurfaceArea",
                    fieldKey: "takeoffArea",
                    decimals: 2,
                    stateValue:
                        convertValue(
                            foundation.wallSurfaceArea.unit,
                            foundation.totalPerimeter.unit,
                            foundation.totalPerimeter.value
                        ) *
                        convertValue(
                            foundation.wallSurfaceArea.unit,
                            foundation.totalWallHeight.unit,
                            foundation.totalWallHeight.value
                        ),
                    stateUnit: foundation.wallSurfaceArea.unit,
                    disabled: true,
                    units: {
                        options: ["m2", "ft2"],
                        selected: foundation.wallSurfaceArea.unit,
                        unitType: "area",
                    },
                },
                {
                    label: "AG Wall Surface Area",
                    type: "number",
                    property: "agWallSurfaceArea",
                    fieldKey: "takeoffArea",
                    decimals: 2,
                    stateValue:
                        convertValue(
                            foundation.agWallSurfaceArea.unit,
                            foundation.totalPerimeter.unit,
                            foundation.totalPerimeter.value
                        ) *
                        convertValue(
                            foundation.agWallSurfaceArea.unit,
                            foundation.agHeight.unit,
                            foundation.agHeight.value
                        ),
                    stateUnit: foundation.agWallSurfaceArea.unit,
                    disabled: true,
                    units: {
                        options: ["m2", "ft2"],
                        selected: foundation.agWallSurfaceArea.unit,
                        unitType: "area",
                    },
                },
                {
                    label: "Volume",
                    type: "number",
                    property: "volume",
                    fieldKey: "takeoffVolume",
                    decimals: 2,
                    stateValue:
                        convertValue(foundation.volume.unit, foundation.area.unit, foundation.area.value) *
                            convertValue(
                                foundation.volume.unit,
                                foundation.totalWallHeight.unit,
                                foundation.totalWallHeight.value
                            ) +
                        calculateFloorHeaderVolume(foundation),
                    stateUnit: foundation.volume.unit,
                    disabled: true,
                    units: {
                        options: ["m3", "ft3"],
                        selected: foundation.volume.unit,
                        unitType: "volume",
                    },
                },
                {
                    label: "Exposed Wall Perimeter",
                    type: "number",
                    property: "exteriorWallLength",
                    fieldKey: "takeoffLength",
                    decimals: 2,
                    stateValue:
                        convertValue(
                            foundation.exteriorWallLength.unit,
                            foundation.totalPerimeter.unit,
                            foundation.totalPerimeter.value
                        ) -
                        convertValue(
                            foundation.exteriorWallLength.unit,
                            foundation.interiorWallLength.unit,
                            foundation.interiorWallLength.value
                        ),
                    stateUnit: foundation.exteriorWallLength.unit,
                    disabled: true,
                    units: {
                        options: ["m", "ft"],
                        selected: foundation.exteriorWallLength.unit,
                        unitType: "length",
                    },
                },
                {
                    label: "BG Wall Depth",
                    type: "number",
                    property: "bgDepth",
                    fieldKey: "takeoffLength",
                    decimals: 2,
                    stateValue: foundation.totalWallHeight.value - foundation.agHeight.value,

                    stateUnit: foundation.bgDepth.unit,
                    disabled: true,
                    units: {
                        options: ["m", "ft"],
                        selected: foundation.bgDepth.unit,
                        unitType: "length",
                    },
                },
            ];
        }
    }
};
