import React, { useRef, useEffect, useState } from "react";
import { Field } from "redux-form";
import { isEqual } from "lodash";
import { DndContext, closestCenter, DragOverlay, MouseSensor, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { getOptions } from "utils/fields";
import { getValidation, required, maxLength } from "utils/fieldValidation";
import { onDragEnd } from "utils/dragAndDrop";

import InputRow from "components/Input/Row";
import Select from "components/Input/Select";
import Accordion from "components/Accordion";
import FloorMeasurements from "./FloorMeasurements/container";
import WallMeasurements from "./WallMeasurements/container";
import FloorConstruction from "./FloorConstruction/container";
import PonyWall from "./PonyWall/container";
import WallConstruction from "./WallConstruction/container";
import Actions from "../Actions/container";
import BasementStats from "./BasementStats/container";
import SelectWithInput from "components/Input/SelectWithInput";
import Window from "../Window/container";
import Door from "../Door/container";
import FloorHeader from "../FloorHeader/container";
import WalkoutInstructions from "../WalkoutInstructions/index.jsx";
import Button from "components/Button";
import DetailsDrawer from "components/DetailsDrawer";
import ComponentHeading from "../ComponentHeading";
import ComponentType from "../ComponentType";
import Droppable from "components/DragAndDrop/Droppable";

import InsulationConfiguration from "./InsulationConfiguration/container";

import classes from "./style.module.scss";
import sharedClasses from "../style.module.scss";

import BasementIcon from "assets/images/components/Foundation-Basement.svg";
import OpenIcon from "assets/images/icons/JSX/OpenDrawer";

const charLim32 = maxLength(32);

const basementLabelValidation = [required, charLim32, ...getValidation("basementLabel")];
const basementRoomValidation = getValidation("basementRoomType");

const openingUpstairsValidation = [required, ...getValidation("basementOpeningUpstairs")];

const NewLineText = ({ text }) => {
    return text.split("\n").map((str, ind) => <li key={ind}>{str.split("- ")[1]}</li>);
};

const Basement = React.memo(
    ({
        accessor,
        label = "",
        change,
        componentId,
        subComponents,
        parentPath = "",
        dragHandleProps,
        clearNewComponent,
        isNew = false,
        isRecentDuplicate = false,
        dragActive,
        allowedCategories,
        isDrawingComponent,
        drawingLines,
        contentOpen,
        activeId,
        isDragging,
        mapSubComponents,
        subCompObj,
        dropPosition,
    }) => {
        const [open, toggleOpen] = useState(false);
        //? Prop lifting was the best way to solve this. It's not DRY, but other solutions would require a refactor

        const [nameDialogOpen, toggleNameDialog] = useState(false);
        const [items, setItems] = useState([]);

        const ref = useRef(null);

        useEffect(() => {
            if (!isDragging) setItems(mapSubComponents);
        }, [isDragging, mapSubComponents]);

        useEffect(() => {
            if (isNew && !isRecentDuplicate) {
                ref.current.scrollIntoView();
            }
        }, [isNew, isRecentDuplicate]);

        const lastComponentDisplayOrder = items.reduce(
            ({ displayOrder: prevDisplay } = {}, { displayOrder: currentDisplay }) =>
                prevDisplay > currentDisplay ? prevDisplay : currentDisplay,
            0
        );

        const activeSubIndex = activeId ? items.findIndex(({ id }) => id === activeId) : -1;

        return (
            <div
                className={`${classes.basement} ${
                    dropPosition === "after"
                        ? sharedClasses.activeAfter
                        : dropPosition === "before"
                        ? sharedClasses.activeBefore
                        : ""
                }`}
            >
                <span ref={ref} className={sharedClasses.componentRef}></span>

                <Accordion
                    heading={
                        <ComponentHeading
                            label={label}
                            componentId={componentId}
                            isDrawingComponent={isDrawingComponent}
                            icon={BasementIcon}
                            toggleNameDialog={toggleNameDialog}
                        />
                    }
                    large
                    id={componentId}
                    isDragging={isDragging}
                    toggleNew={() => clearNewComponent(componentId)}
                    dragActive={dragActive}
                    initOpenState={isNew && !isRecentDuplicate}
                    isNew={isNew}
                    hoverActions={
                        <Actions
                            nameField={{
                                name: `${accessor}.label`,
                                validate: basementLabelValidation,
                                current: label,
                                change,
                            }}
                            label="Basement"
                            type="basement"
                            parentPath={parentPath}
                            componentId={componentId}
                            dragHandleProps={dragHandleProps}
                            newComponentDisplay={lastComponentDisplayOrder + 1}
                            drawingLines={drawingLines}
                            change={change}
                            nameDialogOpen={nameDialogOpen}
                            toggleNameDialog={toggleNameDialog}
                        />
                    }
                    stats={() => <BasementStats accessor={accessor} />}
                    forceOpen={contentOpen}
                >
                    <DetailsDrawer
                        open={open}
                        close={() => toggleOpen(false)}
                        title="Walkout Workaround"
                        subtitle="Modelling a walkout basement with a slab-on-grade portion"
                    >
                        <WalkoutInstructions isSlab={true} />
                        <div className={classes.buttons}>
                            <Button large type="hollow" onClick={() => toggleOpen(false)}>
                                Close
                            </Button>
                        </div>
                    </DetailsDrawer>
                    <div className={classes.firstHeader}>
                        <h4>Measurements</h4>
                        <div className={classes.workaroundButton} onClick={() => toggleOpen(true)}>
                            View Walkout Workaround <OpenIcon />
                        </div>
                    </div>
                    <h5>Basement Floor</h5>
                    <FloorMeasurements accessor={accessor} change={change} isDrawingComponent={isDrawingComponent} />
                    <h5>Basement Wall</h5>
                    <WallMeasurements accessor={accessor} change={change} />

                    <PonyWall accessor={`${accessor}`} change={change} id={componentId} />
                    <h4>Construction</h4>
                    <h5>Insulation Configuration</h5>
                    <InsulationConfiguration accessor={accessor} change={change} />
                    <h5>Basement Wall</h5>
                    <WallConstruction accessor={accessor} change={change} id={componentId} />
                    <h5>Basement Floor</h5>
                    <FloorConstruction accessor={accessor} change={change} id={componentId} />
                    <h4>Basement Details</h4>
                    <InputRow gridTemplate="1fr 1fr 1fr">
                        <Field
                            component={Select}
                            name={`${accessor}.roomType`}
                            options={getOptions({
                                fieldKey: "basementRoomType",
                            })}
                            label="Basement Room Type"
                            placeholder="Choose Basement Room Type"
                            validate={basementRoomValidation}
                            info={"Leave at the default value (Utility Room)."}
                        />
                        <Field
                            component={SelectWithInput}
                            inputData={{
                                triggerValue: 0,
                                inputLabel: "Opening Upstairs",
                                inputType: "number",
                                inputPlaceholder: 0,
                            }}
                            name={`${accessor}.openingUpstairs`}
                            options={getOptions({
                                fieldKey: "basementOpeningUpstairs",
                            })}
                            label="Opening Upstairs"
                            placeholder="Choose Opening Upstairs"
                            validate={openingUpstairsValidation}
                            info={"Leave at the default value (Standard door - open)."}
                        />
                    </InputRow>
                </Accordion>

                <SortableContext
                    items={items}
                    strategy={verticalListSortingStrategy}
                    id={`basement.${componentId}`}
                    disabled={!isDragging ? false : !allowedCategories.includes("basement")}
                >
                    <div
                        className={`${sharedClasses.subComponents} ${
                            items.length === 0 ? sharedClasses.noSubComponents : ""
                        } ${dragActive ? sharedClasses.dragActive : ""}`}
                    >
                        {items.length === 0 && (
                            <span className={sharedClasses.noSubcomponents}>
                                <span className={sharedClasses.noSubcomponents}>
                                    &nbsp;
                                    {allowedCategories.includes("basement") && (
                                        <Droppable id={`basement.${componentId}`}></Droppable>
                                    )}
                                </span>
                            </span>
                        )}
                        {items.map(({ id, componentId: subComponentId, componentType }, index) => (
                            <div key={subComponentId} className={classes.draggableComponent}>
                                <ComponentType
                                    key={id}
                                    id={id}
                                    index={index + 1}
                                    activeIndex={activeSubIndex}
                                    activeId={activeId}
                                    componentId={componentId}
                                    draggableId={`basement.${componentId}.subcomponents.${componentType}.${subComponentId}`}
                                    type={componentType}
                                    change={change}
                                    parentAccessor={accessor}
                                    parentPath={`${parentPath}.basement.${componentId}.subcomponents`}
                                    // isDragging={
                                    //     snapshot.isDragging
                                    // }
                                    allowedCategories={allowedCategories}
                                    clearNewComponent={clearNewComponent}
                                    dragActive={dragActive}
                                    typeDrop={"component"}
                                    isDragging={isDragging}
                                />
                            </div>
                        ))}
                    </div>
                </SortableContext>
                <DragOverlay>
                    {activeId && activeSubIndex >= 0 ? (
                        <ComponentType
                            id={activeId}
                            activeId={activeId}
                            clone={true}
                            label={items[activeSubIndex].componentLabel}
                            type={items[activeSubIndex].componentType}
                            componentId={items[activeSubIndex].componentId}
                            parentAccessor={accessor}
                            parentPath={`${parentPath}.basement.${componentId}.subcomponents`}
                            items={items}
                            allowedCategories={allowedCategories}
                            destination={{}}
                            isDragging={isDragging}
                            typeDrop={"component"}
                        />
                    ) : null}
                </DragOverlay>
            </div>
        );
    },
    isEqual
);

export default Basement;
