import React, { useState, useEffect } from "react";
import globalStyles from "components/globalStyles.module.scss";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import _ from "lodash";
import moment from "moment";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useSelector } from "react-redux";
import { modelFormSelector } from "store/form/selectors";

import { selectBaseUiDetails, selectUpgradePackagesSortedArray } from "../_ducks/selectors";
import { selectPackageResults } from "features/Model/Review/Results/_ducks/selectors";

import DrawerComponent from "components/Drawer";
import Select from "components/Input/Select";
import Input from "components/Input";
import { default as MultiSelect } from "components/Input/MultiSelect";
import Button from "components/Button";
import Tooltip from "components/Tooltip";

import UpgradesTablePDF from "./UpgradesTable/UpgradesTablePDF";

import DownloadIcon from "assets/images/icons/JSX/Dowload";

import { exportPdfButton } from "../style.module.scss";
import { pdfExportContainer, pdfBtnsContainer } from "./styles.module.scss";
import {
    simulationRowHeadings,
    simulationF280PDFHeadings,
    simulationFuelHeadings,
    simulationCostHeadings,
    simulationHeatlossHeadings,
} from "utils/upgrades";

const ExportPDFDrawer = ({ isOpen, close, upgradePackages, packageResults }) => {
    const [selectedFormat, setSelectedFormat] = useState("ledger");
    const [selectedUpgradesPackages, setSelectedUpgradesPackages] = useState(["existing_home"]);
    const [isOutSync, setIsOutSync] = useState(false);
    const [pdfHeaderText, setPdfHeaderText] = useState("Upgrade Pathways Report");

    useEffect(() => {
        for (let i = 0; i < upgradePackages.length; i++) {
            const pkg = upgradePackages[i];

            const { id: upgradeId, lastEdited } = pkg;
            const { metadata: { completedAt } = {} } = packageResults[upgradeId] || {};

            setIsOutSync(lastEdited && moment(lastEdited).isAfter(moment(completedAt)));
        }
    }, [upgradePackages, isOutSync, setIsOutSync, packageResults]);

    const components = useSelector(modelFormSelector)("modelData.components");
    const airtightness = useSelector(modelFormSelector)("modelData.airTightness");
    const codes = useSelector(modelFormSelector)("modelData.codes");
    const heatingCooling = useSelector(modelFormSelector)("modelData.heatingCooling");
    const domesticHotWater = useSelector(modelFormSelector)("modelData.domesticHotWater");
    const ventilation = useSelector(modelFormSelector)("modelData.ventilation");
    const generation = useSelector(modelFormSelector)("modelData.generation");
    const program = useSelector(modelFormSelector)("modelData.program");
    const baseLoads = useSelector(modelFormSelector)("modelData.baseLoads");
    const dimensions = useSelector(modelFormSelector)("modelData.dimensions");
    const fuelPrices = useSelector(modelFormSelector)("modelData.location.fuelPrices") || {};
    const client = useSelector(modelFormSelector)("modelData.general.client");
    const modelName = useSelector(modelFormSelector)("modelDetails.name");

    const { upgrades = {} } = useSelector(modelFormSelector)("modelData.uiSettings");

    const baseUi = useSelector(selectBaseUiDetails);

    const multiSelectOptions = [
        {
            value: "existing_home",
            label: "Existing Home",
            disabled: true,
        },
        ...upgradePackages.map((pkg, ind) => ({
            value: pkg.id,
            label: pkg.name,
        })),
    ];

    const resultsRowIds = [
        ...simulationRowHeadings,
        ...simulationF280PDFHeadings,
        ...simulationFuelHeadings,
        ...simulationCostHeadings,
        ...simulationHeatlossHeadings,
    ].map((el) => el.id);

    //Set up warning for hidden rows
    //Need at least 9 results rows to be hidden in the results section for ledger, 20 to be hidden for 2-pages
    const { hiddenRows = {} } = upgrades || {};
    const hiddenResultsRows = Object.keys(hiddenRows)
        .filter((key) => hiddenRows[key])
        .filter((key) => resultsRowIds.includes(key));

    const ledgerOverflow = hiddenResultsRows.length < 9;
    const landscapeOverflow = hiddenResultsRows.length < 20;
    const overflowWarning =
        ledgerOverflow && selectedFormat === "ledger"
            ? `Hide ${9 - hiddenResultsRows.length} more row${
                  hiddenResultsRows.length === 8 ? "" : "s"
              } in the results section to prevent overflow onto a second page.`
            : landscapeOverflow && selectedFormat === "landscape"
            ? `Hide ${20 - hiddenResultsRows.length} more row${
                  hiddenResultsRows.length === 19 ? "" : "s"
              } in the results section to prevent overflow onto a third page.`
            : "";

    return (
        <DrawerComponent open={isOpen} close={close} title="Export PDF">
            <div className={pdfExportContainer}>
                <Select
                    label={"Select page format"}
                    options={[
                        { value: "ledger", label: 'Ledger (11x17")' },
                        {
                            value: "landscape",
                            label: 'Landscape (2 pages @ 8.5x11")',
                        },
                    ]}
                    input={{
                        value: selectedFormat,
                        onChange: (value) => setSelectedFormat(value),
                    }}
                />
                <MultiSelect
                    label={"Select upgrade packages (Max existing home + 4 packages)"}
                    options={multiSelectOptions}
                    input={{
                        value: selectedUpgradesPackages,
                        onChange: (value) => setSelectedUpgradesPackages(value),
                    }}
                />
                <Input
                    label={"Report Title"}
                    input={{
                        value: pdfHeaderText,
                        onChange: (value) => setPdfHeaderText(value),
                    }}
                />
                {overflowWarning && (
                    <div>
                        <h5>{overflowWarning}</h5>
                    </div>
                )}
                <div className={pdfBtnsContainer}>
                    <Button type="hollow" onClick={close} style={{ width: "144px" }}>
                        Cancel
                    </Button>
                    <div
                        data-html
                        data-for="exportBtnTip"
                        data-tip={
                            _.isEmpty(packageResults)
                                ? "No simulation results are present"
                                : isOutSync
                                ? "Simulation results out of sync with upgrades"
                                : selectedUpgradesPackages.length < 2
                                ? "Please select at least one upgrade package"
                                : selectedUpgradesPackages.length > 5
                                ? "A maximum of four (4) upgrade packages may be exported in one PDF"
                                : ""
                        }
                        style={{
                            width: "fit-content",
                        }}
                    >
                        {selectedUpgradesPackages.length === 1 ||
                        multiSelectOptions.length === 1 ||
                        selectedUpgradesPackages.length > 5 ||
                        pdfHeaderText === "" ||
                        isOutSync ? (
                            <Button
                                icon={DownloadIcon}
                                className={exportPdfButton}
                                type={"lightGrey"}
                                style={{ width: "fit-content" }}
                                disabled={true}
                            >
                                Export PDF
                            </Button>
                        ) : (
                            <PDFDownloadLink
                                document={
                                    <UpgradesTablePDF
                                        pdfHeaderText={pdfHeaderText}
                                        baseUi={baseUi}
                                        components={components}
                                        airtightness={airtightness}
                                        codes={codes}
                                        heatingCooling={heatingCooling}
                                        domesticHotWater={domesticHotWater}
                                        ventilation={ventilation}
                                        generation={generation}
                                        program={program}
                                        baseLoads={baseLoads}
                                        dimensions={dimensions}
                                        fuelPrices={fuelPrices}
                                        selectedUpgradesPackages={selectedUpgradesPackages}
                                        upgradePackages={upgradePackages}
                                        clientData={client}
                                        upgrades={upgrades}
                                        packageResults={packageResults}
                                        pageFormat={selectedFormat}
                                    />
                                }
                                fileName={`${modelName}_Pathways Report.pdf`}
                            >
                                <Button
                                    icon={DownloadIcon.bind(this, {
                                        colour: "#ffffff",
                                    })}
                                    className={exportPdfButton}
                                    type={"default"}
                                    onClick={close}
                                    style={{ width: "fit-content" }}
                                    disabled={multiSelectOptions.length === 1 || isOutSync}
                                >
                                    Export PDF
                                </Button>
                            </PDFDownloadLink>
                        )}
                    </div>
                </div>
            </div>
            <Tooltip id={"exportBtnTip"} />
        </DrawerComponent>
    );
};

const mapStateToProps = createStructuredSelector({
    upgradePackages: selectUpgradePackagesSortedArray,
    packageResults: selectPackageResults,
});

export default connect(mapStateToProps, null)(ExportPDFDrawer);
