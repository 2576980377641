import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectAllImages, selectLoading } from "store/imageGallery/selectors";

import { generalTags } from "../../utils/defaults";

import SidebarSelectors from "../SidebarSelectors";
import RequiredTags from "../RequiredTags";
import Loading from "components/Loading";

import styles from "./styles.module.scss";

import { ReactComponent as AllImagesIcon } from "assets/images/icons/AllImages.svg";
import { ReactComponent as UntaggedIcon } from "assets/images/icons/Untagged.svg";

const Icon = ({ tag }) =>
    ({
        all: () => <AllImagesIcon />,
        untagged: () => <UntaggedIcon />,
    }[tag]);

const Sidebar = ({ selectedTag, setSelectedTag, allImages, isLoading, isSavingModel, isClosing }) => {
    generalTags.untagged.number = 10;

    generalTags.untagged.number = allImages
        ? allImages
              .map((img) =>
                  img.tags === undefined
                      ? {
                            ...img,
                            tags: [],
                        }
                      : img
              )
              .filter((img) => img.tags.length === 0).length
        : 0;

    return (
        <div className={styles.sidebarContainer}>
            <div className={styles.generalSelectorsContainer}>
                {Object.keys(generalTags).map((tag) => (
                    <SidebarSelectors
                        key={tag}
                        type="general"
                        Icon={Icon({ tag })}
                        text={generalTags[tag].text}
                        number={generalTags[tag].number}
                        selected={selectedTag === tag}
                        onSelect={() => setSelectedTag(tag)}
                    />
                ))}
            </div>
            {(isLoading || isSavingModel) && !isClosing ? (
                <div style={{ marginTop: "35px" }}>
                    <Loading />
                </div>
            ) : (
                <RequiredTags setSelectedTag={setSelectedTag} selectedTag={selectedTag} allImages={allImages} />
            )}
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    allImages: selectAllImages,
    isLoading: selectLoading,
});

export default connect(mapStateToProps, null)(Sidebar);
