import React, { useState, useEffect, useRef } from 'react';
import classes from './style.module.scss';
import Move from 'assets/images/icons/JSX/Move';
import DropDown from 'components/Dropdown';
import Edit from 'assets/images/icons/JSX/Edit';
import Duplicate from 'assets/images/icons/JSX/Duplicate';
import Delete from 'assets/images/icons/JSX/Delete';
import Add from 'assets/images/icons/JSX/Add';
import Dialog, { CloseDialog } from 'components/Dialog';
import { change, Field } from 'redux-form';
import Input from 'components/Input';
import Button from 'components/Button';
import Tooltip from 'components/Tooltip';
import { useOutsideClickHook } from 'utils/outsideClick';
import { getComponentIcon, getComponentSubOptions, getComponentName } from 'utils/enclosure';

export default React.memo(({
    accessor,
    type,
    isSupplemental,
    wholeHouseVentilators,
    supplementalVentilators,
    change,
    isUpgrade=false,
    formName
}) => {
    const [menuOpen, toggleMenuOpen] = useState(false);    
    const [deleteDialogOpen, toggleDeleteDialog] = useState(false);

    const ref = useOutsideClickHook(() => toggleMenuOpen(false));

    const handleDelete = () => {
        let ventilationAccessor = isSupplemental
            ? accessor.split('.supplementalSystems')[0]
            : accessor.split('.wholeHouseSystems')[0]

        if (type === 'hrv') {
            change(`${ventilationAccessor}.${isSupplemental ? 'supplementalSystems' : 'wholeHouseSystems'}.hrv`, {})
        } else if (type === 'dryer') {
            change(`${ventilationAccessor}.supplementalSystems.dryer`, {})
        } else {
            const filteredVentilators = Object.entries(isSupplemental ? supplementalVentilators : wholeHouseVentilators)
                .reduce((acc, [key, value]) => {
                    if (key === accessor.split('ventilators.')[1]) {
                        return acc;
                    }
                    return {
                        ...acc,
                        [key]: value
                    }
                }, {});

            change(`${ventilationAccessor}.${isSupplemental ? 'supplementalSystems' : 'wholeHouseSystems'}.ventilators`, filteredVentilators)
        }

        toggleDeleteDialog(false)
    }

    return (
        <div className={classes.actions} ref={ref}>
            <div className={classes.menu}>
                <div className={classes.dots} onClick={()=>toggleMenuOpen(!menuOpen)}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <DropDown
                    className={classes.dropDown}
                    open={menuOpen}
                    toggleOpen={toggleMenuOpen}
                    options={[
                        {
                            label:<span><Delete /> Delete</span>,
                            className:classes.delete,
                            onClick:()=>toggleDeleteDialog(true)
                        }
                    ]}
                />
            </div>
            <Dialog
                open={deleteDialogOpen}
            >
                <CloseDialog
                    closeActions={()=>toggleDeleteDialog(false)}
                />
                <div className={classes.dialog}>
                    <h3>Are you sure you want to delete this ventilation system?</h3>
                    <div className={classes.footer}>
                        <Button
                            type="hollow"
                            onClick={()=>toggleDeleteDialog(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => handleDelete()}
                            type="red"
                        >
                            Delete
                        </Button>
                    </div>
                </div>
            </Dialog>
        </div>
    )
})