import React, { useEffect, useRef, useCallback } from "react";
import globalStyles from "components/globalStyles.module.scss";
import wallCrossSection from "assets/images/Wall-Cross-Section.svg"; // UPDATE WHEN READY
import InputRow from "components/Input/Row";
import Input from "components/Input";
import Select from "components/Input/Select";
import Checkbox from "components/Input/Checkbox";
import { Field } from "redux-form";
import Button from "components/Button";
import { getOptions, getFirstOption, getDefaultOption } from "utils/fields";
import { required, maxLength, getValidation } from "utils/fieldValidation";
import Tooltip from "components/Tooltip";

const charLim32 = maxLength(32);

const labelValidation = [required, charLim32];
const interiorValidation = getValidation("csFloorsAboveInterior");
const structureValidation = getValidation("csFloorsAboveStructureType");
const typeSizeValidation = getValidation("csFloorsAboveComponentTypeSize");
const sheathingValidation = getValidation("csFloorsAboveSheathing");
const framingSpacingValidation = getValidation("csFloorsAboveFramingSpacing");
const exteriorValidation = getValidation("csFloorsAboveExterior");
const ins1Validation = getValidation("csFloorsAboveInsLayer1");
const ins2Validation = getValidation("csFloorsAboveInsLayer2");
const dropFramingValidation = getValidation("csFloorsAboveDropFraming");

export default React.memo(
    ({
        toggleOpen,
        floorsAboveLabel,
        floorsAboveStructureId,
        isSolid,
        isPanel,
        isDropFraming,
        makeSpacingDefault,
        makeInsulationDefault,
        floorsAboveValue,
        submitting,
        handleSubmit,
        change,
        invalid,
        isEditing,
        isLibCode,
        clearInitCode,
        floorsAboveSpacingId,
        floorsAboveIns1Value,
        isCodeLibrary = false,
    }) => {
        const structureRef = useRef(null);
        const valueRef = useRef(null);
        const prevStructureId = structureRef.current;

        const handleStuctureChange = useCallback(
            ({ id, value }) => {
                change(
                    "floorsAboveCode.layers.insulationLayer1",
                    getFirstOption({ indKey: id, depKey: "csFloorsAboveInsLayer1" })
                );
                change(
                    "floorsAboveCode.layers.componentTypeSize",
                    getFirstOption({ indKey: id, depKey: "csFloorsAboveComponentTypeSize" })
                );

                // Use "Framing" when set to Solid, use Spacing otherwise
                if (parseFloat(id) === 4) {
                    //set spacing to null and grab first option for framing
                    change("floorsAboveCode.layers.spacing", { id: null });
                    change(
                        "floorsAboveCode.layers.framing",
                        getFirstOption({
                            indKey: id,
                            depKey: "csFloorsAboveFramingSpacing",
                        })
                    );
                } else {
                    //set framing to null and grab first option for spacing
                    change("floorsAboveCode.layers.framing", { id: null });

                    // Only grab default spacing id if not set
                    if (floorsAboveSpacingId === null) {
                        change("floorsAboveCode.layers.spacing", getDefaultOption("csFloorsAboveFramingSpacing"));
                    }
                }

                // Panel
                if (parseFloat(id) === 5) {
                    change("floorsAboveCode.layers.insulationLayer1", getDefaultOption("csFloorsAboveInsLayer1"));
                    change("floorsAboveCode.layers.insulationLayer2", getDefaultOption("csFloorsAboveInsLayer2"));
                    change("floorsAboveCode.layers.spacing", getDefaultOption("csFloorsAboveFramingSpacing"));
                    change("floorsAboveCode.layers.dropFraming", getDefaultOption("csFloorsAboveDropFraming"));
                }
            },
            [floorsAboveStructureId, change, floorsAboveSpacingId]
        );

        useEffect(() => {
            valueRef.current = floorsAboveValue;
        }, [floorsAboveValue, floorsAboveLabel]);

        //Filter Insulation Layer 1 for drop framing
        const baseInsLayer1Opts = [
            "0",
            "K",
            "1",
            "2",
            "X",
            "Y",
            "3",
            "4",
            "Z",
            "a",
            "5",
            "L",
            "c",
            "J",
            "b",
            "d",
            "V",
            "W",
            "I",
            "e",
        ];
        let insLayer1Opts = getOptions({ fieldKey: "csFloorsAboveInsLayer1", indKey: floorsAboveStructureId });
        if (isDropFraming) {
            insLayer1Opts = insLayer1Opts.filter((opt) => baseInsLayer1Opts.includes(opt.value.value));
        }

        return (
            <div className={globalStyles.standardCodeWrapper}>
                <h3>Standard Code</h3>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Input}
                        type="text"
                        name={`floorsAboveCode.label`}
                        label="Code Label"
                        placeholder="Code Label"
                        validate={labelValidation}
                        setValue={floorsAboveLabel.toString() === valueRef.current ? floorsAboveValue : null}
                    />
                    <Field
                        component={Input}
                        type="text"
                        name={`floorsAboveCode.value`}
                        label="Internal Code"
                        placeholder="###########"
                        disabled={true}
                        setValue={floorsAboveValue}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Select}
                        name={`floorsAboveCode.layers.structureType`}
                        label="Structure Type"
                        placeholder="Choose Structure Type"
                        options={getOptions({ fieldKey: "csFloorsAboveStructureType" })}
                        validate={structureValidation}
                        onChange={handleStuctureChange}
                    />
                    <Field
                        component={Select}
                        name={`floorsAboveCode.layers.interior`}
                        label="Interior"
                        placeholder="Choose Interior"
                        options={getOptions({ fieldKey: "csFloorsAboveInterior" })}
                        validate={interiorValidation}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Select}
                        name={`floorsAboveCode.layers.componentTypeSize`}
                        label="Component Type / Size"
                        placeholder="Choose Type / Size"
                        options={getOptions({
                            fieldKey: "csFloorsAboveComponentTypeSize",
                            indKey: floorsAboveStructureId,
                        })}
                        validate={typeSizeValidation}
                        onChange={({ value = "" }) => {
                            if (["6", "7", "8", "9", "A", "B", "C"].includes(value)) {
                                change(
                                    "floorsAboveCode.layers.insulationLayer1",
                                    getDefaultOption("csFloorsAboveInsLayer1")
                                );
                                change(
                                    "floorsAboveCode.layers.insulationLayer2",
                                    getDefaultOption("csFloorsAboveInsLayer2")
                                );
                            }
                            if (["5", "6", "7", "8", "9", "A", "B", "C"].includes(value)) {
                                change("floorsAboveCode.layers.framing", { id: 0, value: "0" });
                            }
                        }}
                    />
                    <Field
                        component={Select}
                        name={`floorsAboveCode.layers.sheathing`}
                        label="Sheathing"
                        placeholder="Choose Sheathing"
                        options={getOptions({ fieldKey: "csFloorsAboveSheathing" })}
                        validate={sheathingValidation}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    {isSolid ? (
                        <Field
                            component={Select}
                            name={`floorsAboveCode.layers.framing`}
                            label="Framing"
                            placeholder="Choose Framing"
                            options={getOptions({
                                fieldKey: "csFloorsAboveFramingSpacing",
                                indKey: floorsAboveStructureId,
                            })}
                            validate={framingSpacingValidation}
                            disabled={makeSpacingDefault}
                        />
                    ) : (
                        <Field
                            component={Select}
                            name={`floorsAboveCode.layers.spacing`}
                            label="Spacing"
                            placeholder="Choose Spacing"
                            options={getOptions({
                                fieldKey: "csFloorsAboveFramingSpacing",
                                indKey: floorsAboveStructureId,
                            })}
                            validate={framingSpacingValidation}
                            disabled={isPanel}
                        />
                    )}
                    <Field
                        component={Select}
                        name={`floorsAboveCode.layers.exterior`}
                        label="Exterior"
                        placeholder="Choose Exterior"
                        options={getOptions({ fieldKey: "csFloorsAboveExterior" })}
                        validate={exteriorValidation}
                        info={
                            "The Exterior should only be modelled if more than 50% of the total underside of the joists have a finished ceiling (e.g. suspended ceiling, gypsum, wood). In such cases, model the entire floor above foundation's Exterior component with the finished ceiling material. Model suspended ceiling tiles as 12 mm (0.5 in.) gypsum board."
                        }
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Select}
                        name={`floorsAboveCode.layers.insulationLayer1`}
                        label="Framing Cavity Insulation (Layer 1)"
                        placeholder="Choose Insulation"
                        options={insLayer1Opts}
                        validate={ins1Validation}
                        disabled={isPanel || makeInsulationDefault}
                    />
                    <Field
                        component={Select}
                        name={`floorsAboveCode.layers.dropFraming`}
                        label="Drop Framing"
                        placeholder="Choose"
                        options={getOptions({ fieldKey: "csFloorsAboveDropFraming" })}
                        validate={dropFramingValidation}
                        disabled={isPanel}
                        onChange={({ value = "" }) => {
                            if (
                                ["9", "A", "E", "F", "U", "M", "N", "O", "P", "Q", "R", "S", "T"].includes(
                                    floorsAboveIns1Value
                                ) &&
                                value == 1
                            ) {
                                change(
                                    "floorsAboveCode.layers.insulationLayer1",
                                    getDefaultOption("csFloorsAboveInsLayer1")
                                );
                            }
                        }}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Select}
                        name={`floorsAboveCode.layers.insulationLayer2`}
                        label="Continuous Layer of Insulation (Layer 2)"
                        placeholder="Choose Insulation"
                        options={getOptions({ fieldKey: "csFloorsAboveInsLayer2" })}
                        validate={ins2Validation}
                        disabled={isPanel || makeInsulationDefault}
                    />
                </InputRow>
                <div style={{ borderBottom: "1px solid #e1eaf0", marginTop: "1.5rem", marginBottom: "1.5rem" }}></div>
                {!isCodeLibrary && (
                    <InputRow gridTemplate="1fr 1fr">
                        <div data-tip="Code will automatically be updated in library" data-for="codeLibTip">
                            <Field
                                component={Checkbox}
                                name={"addToLibrary"}
                                label="Add to Code Library"
                                large
                                disabled={isLibCode}
                                type="checkbox"
                            />
                        </div>
                        <Field
                            component={Checkbox}
                            name={"setAsModelDefault"}
                            label={"Set as model default"}
                            large
                            type="checkbox"
                        />
                    </InputRow>
                )}
                <div className={globalStyles.buttons}>
                    <Button
                        large
                        type="hollow"
                        onClick={() => {
                            clearInitCode();
                            toggleOpen(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button large onClick={handleSubmit} disabled={submitting || invalid}>
                        {submitting ? "Saving Code..." : "Save Code"}
                    </Button>
                </div>
                <Tooltip id="codeLibTip" />
            </div>
        );
    }
);
