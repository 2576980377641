import React from "react";
import classes from "./style.module.scss";
import sharedClasses from "../style.module.scss";
import RadioButton from "./RadioButton";
import { useState } from "react";

export default ({ input = {}, name, className, disabled, style = {}, options = ["renovator", "ea"] }) => {
    const classNames = [
        classes.checkboxWrapper,
        sharedClasses.inputField,
        className && className,
        classes.buttonContainer,
    ].filter((c) => c);

    let { value, onChange } = input;

    const toggleOpt1 = () => {
        onChange(value === options[0] ? "none" : options[0]);
    };

    const toggleOpt2 = () => {
        onChange(value === options[1] ? "none" : options[1]);
    };

    return (
        <label className={classNames.join(" ")} htmlFor={name} style={style}>
            <RadioButton checkBox={toggleOpt1} checked={value === options[0]} />
            <RadioButton checkBox={toggleOpt2} checked={value === options[1]} />
            <input
                {...input}
                // value={translatedValue}
                disabled={disabled}
                name={name}
                id={name}
                className={classes.checkboxInput}
            />
        </label>
    );
};
