import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import Ventilator from './';
import { getBaseUnits, unitLabels } from 'utils/fields';
import convertUnit from 'utils/convertUnit';

const mapStateToProps = (
    {
        form
    },
    {
        accessor,
        formName='model'
    }
) => {
    const selector = formValueSelector(formName);
    const modelSelector = formValueSelector('model');
    const supplyFlow = selector({form}, `${accessor}.supplyFlowrate`) || 0;
    const exhaustFlow = selector({form}, `${accessor}.exhaustFlowrate`) || 0;

    const supplyUnits = selector({form}, `${accessor}.supplyFlowrate_u`);
    const exhaustUnits = selector({form}, `${accessor}.exhaustFlowrate_u`);
    
    const modelUnits = modelSelector({form}, `modelData.uiSettings.primaryUnits`);

    const { trueBase:supplyBase, displayBase:supplyDisplay } = getBaseUnits('hrvSupply', modelUnits);
    const { trueBase:exhaustBase, displayBase:exhaustDisplay } = getBaseUnits('hrvExhaust', modelUnits);

    const supplyU = supplyUnits || supplyDisplay || supplyBase;
    const exhaustU = exhaustUnits || exhaustDisplay || exhaustBase;
    
    return {
        supply:`${parseFloat(convertUnit({
            value:supplyFlow,
            type:'airFlow',
            inputUnit:supplyBase,
            outputUnit:supplyU,
        })).toFixed(1)}${supplyU}`,
        exhaust:`${parseFloat(convertUnit({
            value:exhaustFlow,
            type:'airFlow',
            inputUnit:exhaustBase,
            outputUnit:exhaustU,
        })).toFixed(1)}${exhaustU}`,
    }
};

const mapDispatchToProps = () => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Ventilator);