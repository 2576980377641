import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import Basement from "./";
import isEmpty from "lodash/isEmpty";

const mapStateToProps = (
    { form, model: { newestComponents = [], recentDuplicates = [], contentOpen = false } = {} },
    { id, isDragging, draggingType }
) => {
    const accessor = `modelData.components.basement.${id}`;

    const selector = formValueSelector("model");
    const label = selector({ form }, `${accessor}.label`) || "";

    const { component: drawingComponent = "" } = selector({ form }, `${accessor}.drawing`) || {};

    const wallHeightDrawing = selector({ form }, `${accessor}.wall.measurements.height_drawingRef`) || "";
    const wallDepthDrawing = selector({ form }, `${accessor}.wall.measurements.depth_drawingRef`) || "";
    const ponyWallHeightDrawing = selector({ form }, `${accessor}.ponyWallHeight_drawingRef`) || "";

    const drawingLines = [
        ...(wallHeightDrawing ? [wallHeightDrawing] : []),
        ...(wallDepthDrawing ? [wallDepthDrawing] : []),
        ...(ponyWallHeightDrawing ? [ponyWallHeightDrawing] : []),
    ];

    const subCompObj = selector({ form }, `${accessor}.subcomponents`) || {};

    const { window = {}, door = {}, floorHeader = {} } = subCompObj;

    const mapSubComponents = () => {
        if (isDragging && draggingType === "category") return [];

        const windows = Object.entries(window)
            .filter(([key, values]) => values && !isEmpty(values))
            .map(([key, values]) => ({
                id: key,
                componentId: key,
                componentType: "window",
                componentLabel: values.label,
                displayOrder: values.displayOrder,
            }));

        const doors = Object.entries(door)
            .filter(([key, values]) => values && !isEmpty(values))
            .map(([key, values]) => ({
                id: key,
                componentId: key,
                componentType: "door",
                componentLabel: values.label,
                displayOrder: values.displayOrder,
            }));

        const floorHeaders = Object.entries(floorHeader)
            .filter(([key, values]) => values && !isEmpty(values))
            .map(([key, values]) => ({
                id: key,
                componentId: key,
                componentType: "floorHeader",
                componentLabel: values.label,
                displayOrder: values.displayOrder,
            }));

        return [...doors, ...floorHeaders, ...windows].sort((a, b) => {
            if (a.displayOrder !== b.displayOrder) {
                return a.displayOrder - b.displayOrder;
            }
            if (a.componentLabel !== b.componentLabel && a.componentLabel != null && b.componentLabel !== null) {
                if (a.componentLabel?.toLowerCase() < b.componentLabel?.toLowerCase()) {
                    return -1;
                }
                if (a.componentLabel?.toLowerCase() > b.componentLabel?.toLowerCase()) {
                    return 1;
                }
                return 0;
            }
            return 0;
        });
    };

    return {
        componentId: id,
        accessor,
        label,
        mapSubComponents: mapSubComponents(),
        subCompObj,
        isNew: newestComponents.includes(id),
        isRecentDuplicate: recentDuplicates.includes(id),
        isDrawingComponent: !!drawingComponent,
        drawingLines,
        contentOpen,
    };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Basement);
