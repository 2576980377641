import React, { useEffect } from "react";
import classes from "./style.module.scss";
import sharedClasses from "features/Model/sharedStyles.module.scss";
import { Field } from "redux-form";
import { requiredId, getValidation } from "utils/fieldValidation";
import { getOptions } from "utils/fields";
import { getProgramTemplate, programTypes } from "utils/evaluationProgram";
import InputRow from "components/Input/Row";
import Select from "components/Input/Select";
import Checkbox from "components/Input/Checkbox";
import Accordion from "components/Accordion";
import RURComments from "./RURComments/container";
import HouseholdOperating from "./HouseholdOperating/container";
import AtypicalLoads from "./AtypicalLoads/container";
import WaterConservation from "./WaterConservation/container";
import ReducedOperating from "./ReducedOperating/container";
import ReferenceHouse from "./ReferenceHouse/container";
import GreenerHomes from "./GreenerHomes/container";
import { isEmpty } from "lodash";

const vermiculiteValidation = getValidation("ersVermiculitePresence");

export default React.memo(
    ({
        accessor,
        programLabel,
        vermiculiteValue,
        programType,
        isApplyHoc,
        isAtypicalLoads,
        isWaterConservation,
        isApplyRoc,
        isRefHouse,
        isGreenerHomes,
        h2kMinor,
        programSectionsPresent,
        modelUnits,
        change,
    }) => {
        useEffect(() => {
            if (Object.keys(programTypes).includes(programType) && isEmpty(vermiculiteValue)) {
                change(`${accessor}.options.main.vermiculitePresence`, { id: 0 });
            }
        }, [programType]);

        if (Object.keys(programTypes).includes(programType)) {
            return (
                <div>
                    <h4 style={{ marginBottom: "1.25rem", marginTop: "1.75rem" }}>{programLabel}</h4>
                    <InputRow gridTemplate="1fr 1fr 1fr">
                        <Field
                            component={Select}
                            name={`${accessor}.options.main.vermiculitePresence`}
                            options={getOptions({ fieldKey: "ersVermiculitePresence" })}
                            label="Presence of Vermiculite"
                            placeholder="Please make a selection"
                            validate={[requiredId, ...vermiculiteValidation]}
                        />
                    </InputRow>
                    <InputRow flex>
                        <Field
                            component={Checkbox}
                            name={`${accessor}.options.main.applyHouseholdOperatingConditions`}
                            label="Household Operating Conditions"
                            large
                            className={classes.bottomCheckBox}
                            type="checkbox"
                            onChange={(value) => {
                                const changedToTrue = value.target.value !== "true";
                                if (changedToTrue && !programSectionsPresent.includes("householdOpConditions")) {
                                    change(
                                        `${accessor}.options.householdOpConditions`,
                                        getProgramTemplate("householdOpConditions")
                                    );
                                }
                            }}
                        />
                        <Field
                            component={Checkbox}
                            name={`${accessor}.options.main.atypicalElectricalLoads`}
                            label="Atypical Energy Loads"
                            large
                            className={classes.bottomCheckBox}
                            type="checkbox"
                            onChange={(value) => {
                                const changedToTrue = value.target.value !== "true";
                                if (changedToTrue && !programSectionsPresent.includes("atypicalElecLoads")) {
                                    change(
                                        `${accessor}.options.atypicalElecLoads`,
                                        getProgramTemplate("atypicalElecLoads")
                                    );
                                }
                            }}
                        />
                        <Field
                            component={Checkbox}
                            name={`${accessor}.options.main.waterConservation`}
                            label="Water Conservation"
                            large
                            className={classes.bottomCheckBox}
                            type="checkbox"
                            onChange={(value) => {
                                const changedToTrue = value.target.value !== "true";
                                if (changedToTrue && !programSectionsPresent.includes("waterConservation")) {
                                    change(
                                        `${accessor}.options.waterConservation`,
                                        getProgramTemplate("waterConservation")
                                    );
                                }
                            }}
                        />
                    </InputRow>
                    <InputRow flex>
                        <Field
                            component={Checkbox}
                            name={`${accessor}.options.main.applyReducedOperatingConditions`}
                            label="Reduced Operating Conditions and Energy Star for New Homes"
                            large
                            className={classes.bottomCheckBox}
                            type="checkbox"
                            onChange={(value) => {
                                const changedToTrue = value.target.value !== "true";
                                if (changedToTrue && !programSectionsPresent.includes("reducedOperating")) {
                                    change(
                                        `${accessor}.options.reducedOperating`,
                                        getProgramTemplate("reducedOperating")
                                    );
                                }
                            }}
                        />
                        <Field
                            component={Checkbox}
                            name={`${accessor}.options.main.referenceHouse`}
                            label="Reference House"
                            large
                            className={classes.bottomCheckBox}
                            type="checkbox"
                            onChange={(value) => {
                                const changedToTrue = value.target.value !== "true";
                                if (changedToTrue && !programSectionsPresent.includes("refHouse")) {
                                    change(`${accessor}.options.refHouse`, getProgramTemplate("refHouse"));
                                }
                            }}
                        />
                    </InputRow>
                    {h2kMinor >= 11 && (
                        <InputRow>
                            <Field
                                component={Checkbox}
                                name={`${accessor}.options.main.greenerHomesActive`}
                                label="Greener Homes"
                                large
                                className={classes.bottomCheckBox}
                                type="checkbox"
                                onChange={(value) => {
                                    const changedToTrue = value.target.value !== "true";
                                    if (changedToTrue && !programSectionsPresent.includes("greenerHomes")) {
                                        change(`${accessor}.options.greenerHomes`, getProgramTemplate("greenerHomes"));
                                    }
                                }}
                            />
                        </InputRow>
                    )}
                    <Accordion
                        className={sharedClasses.accordion}
                        heading={<span className={sharedClasses.heading}>RUR Comments</span>}
                        large
                    >
                        <RURComments accessor={`${accessor}.options.rurComments`} change={change} />
                    </Accordion>
                    {isApplyHoc ? (
                        <>
                            <Accordion
                                className={sharedClasses.accordion}
                                heading={<span className={sharedClasses.heading}>Household Operating Conditions</span>}
                                large
                            >
                                <HouseholdOperating
                                    accessor={`${accessor}.options.householdOpConditions`}
                                    change={change}
                                />
                            </Accordion>
                        </>
                    ) : (
                        <></>
                    )}
                    {isAtypicalLoads ? (
                        <>
                            <Accordion
                                className={sharedClasses.accordion}
                                heading={<span className={sharedClasses.heading}>Atypical Energy Loads</span>}
                                large
                            >
                                <AtypicalLoads accessor={`${accessor}.options.atypicalElecLoads`} change={change} />
                            </Accordion>
                        </>
                    ) : (
                        <></>
                    )}
                    {isWaterConservation ? (
                        <>
                            <Accordion
                                className={sharedClasses.accordion}
                                heading={<span className={sharedClasses.heading}>Water Conservation</span>}
                                large
                            >
                                <WaterConservation accessor={`${accessor}.options.waterConservation`} change={change} />
                            </Accordion>
                        </>
                    ) : (
                        <></>
                    )}
                    {isApplyRoc ? (
                        <>
                            <Accordion
                                className={sharedClasses.accordion}
                                heading={
                                    <span className={sharedClasses.heading}>
                                        Reduced Operating Conditions and Energy Star
                                    </span>
                                }
                                large
                            >
                                <ReducedOperating accessor={`${accessor}.options.reducedOperating`} change={change} />
                            </Accordion>
                        </>
                    ) : (
                        <></>
                    )}
                    {isRefHouse ? (
                        <>
                            <Accordion
                                className={sharedClasses.accordion}
                                heading={<span className={sharedClasses.heading}>Reference House</span>}
                                large
                            >
                                <ReferenceHouse accessor={`${accessor}.options.refHouse`} change={change} />
                            </Accordion>
                        </>
                    ) : (
                        <></>
                    )}
                    {isGreenerHomes && h2kMinor >= 11 ? (
                        <>
                            <Accordion
                                className={sharedClasses.accordion}
                                heading={<span className={sharedClasses.heading}>Greener Homes</span>}
                                large
                            >
                                <GreenerHomes accessor={`${accessor}.options.greenerHomes`} change={change} />
                            </Accordion>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            );
        }

        return <div></div>;
    }
);
