import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import isEmpty from "lodash/isEmpty";
import DhwSystem from "./";

const mapStateToProps = ({ form }, { accessor, formName = "model" }) => {
    const selector = formValueSelector(formName);
    const modelSelector = formValueSelector("model");

    const { id: energySourceId } = selector({ form }, `${accessor}.energySource`) || {};
    const { id: tankTypeId } = selector({ form }, `${accessor}.tankType`) || {};
    const { id: tankVolumeId, value: tankVolumeValue } = selector({ form }, `${accessor}.tankVolume`) || {};
    const isUniformEF = selector({ form }, `${accessor}.isUniform`);
    const { id: efId, value: efValue } = selector({ form }, `${accessor}.energyFactor`) || {};
    const {
        inputCapacity: inputCapValue,
        standbyHeatLoss: standbyHeatLossValue,
        standbyHeatLossMode: standbyHeatLossModeValue,
        thermalEfficiency: thermalEfficiencyValue,
    } = selector({ form }, `${accessor}.standby`) || {};

    const insulatingBlanketValue = selector({ form }, `${accessor}.insulatingBlanket`);
    const pilotEnergyValue = selector({ form }, `${accessor}.pilotEnergy`);
    const flueDiameterValue = selector({ form }, `${accessor}.flueDiameter`);
    const hpCopValue = selector({ form }, `${accessor}.heatPumpCOP`);
    const { id: dhwTankLocationId } = selector({ form }, `${accessor}.tankLocation`);

    const isSolarEmpty = isEmpty(selector({ form }, `${accessor}.solar`) || {});
    const isDwhr = !isEmpty(selector({ form }, `${accessor}.drainWaterHeatRecovery`) || {});

    //standbyHeatLossMode => 0 = W, 1 = %/hr
    const standbyHeatLossUnits = selector({ form }, `${accessor}.standby.standbyHeatLoss_u`);
    const tankVolumeUnits = selector({ form }, `${accessor}.tankVolume_u`);
    const thermalEffUnits = selector({ form }, `${accessor}.standby.thermalEfficiency_u`);
    const inputCapUnits = selector({ form }, `${accessor}.standby.inputCapacity_u`);
    const insulatingBlanketUnits = selector({ form }, `${accessor}.insulatingBlanket_u`);
    const pilotEnergyUnits = selector({ form }, `${accessor}.pilotEnergy_u`);
    const flueDiameterUnits = selector({ form }, `${accessor}.flueDiameter_u`);

    const solarRatingUnits = selector({ form }, `${accessor}.solar.rating_u`);
    const solarSlopeUnits = selector({ form }, `${accessor}.solar.slope_u`);
    const solarAzimuthUnits = selector({ form }, `${accessor}.solar.azimuth_u`);
    const fractionOfTankUnits = selector({ form }, `${accessor}.fractionOfTank_u`);

    const modelUnits = modelSelector({ form }, `modelData.uiSettings.primaryUnits`) || {};

    const { id: buildingTypeId } = modelSelector({ form }, `modelData.specifications.buildingType`) || {};
    const isMURB = buildingTypeId === 2; //Whole building MURB

    const heatingSystemType = Object.keys(modelSelector({ form }, "modelData.heatingCooling.heating.system") || {})[0];

    let primaryAccessor = accessor;
    if (primaryAccessor.includes("secondarySystem")) {
        primaryAccessor = primaryAccessor.split(".secondarySystem")[0] + ".primarySystem";
    }

    const isPrimarySolar = !isEmpty(selector({ form }, `${primaryAccessor}.solar`) || {});
    const fractionOfTankValue = selector({ form }, `${primaryAccessor}.fractionOfTank`);

    return {
        accessor,
        isPrimarySolar,
        fractionOfTankValue,
        heatingSystemType,
        energySourceId,
        isDwhr,
        tankTypeId,
        tankVolumeId,
        dhwTankLocationId,
        tankVolumeValue,
        isUniformEF,
        efId,
        efValue,
        inputCapValue,
        standbyHeatLossValue,
        standbyHeatLossModeValue,
        thermalEfficiencyValue,
        insulatingBlanketValue,
        pilotEnergyValue,
        flueDiameterValue,
        hpCopValue,
        isSolarEmpty,
        tankVolumeUnits,
        standbyHeatLossUnits,
        thermalEffUnits,
        inputCapUnits,
        insulatingBlanketUnits,
        pilotEnergyUnits,
        flueDiameterUnits,
        solarRatingUnits,
        solarSlopeUnits,
        solarAzimuthUnits,
        fractionOfTankUnits,
        modelUnits,
        isMURB,
    };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DhwSystem);
