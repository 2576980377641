import { getBaseUnits } from "utils/fields";
import convertUnit from "utils/convertUnit";
import { getCodeLayerLabel, getLabel } from "utils/dcfExport/details";
import { isEmpty } from "lodash";

export const getWindowResults = (windowObj, codes, modelUnits) => {
    const {
        label,
        numIdentical,
        energyStar,
        facingDirection: { id: dirId } = {},
        measurements: {
            width,
            height,
            overhangWidth,
            headerHeight,
            tilt: { value: tiltValue } = {},
        } = {},
        windowType: { codeRef = "", codeLabel = "" } = {},
    } = windowObj || {};

    const {
        [codeRef]: {
            layers: {
                glazingType: { id: glazingTypeId } = {},
                coatingsTints: { id: coatingsTintsId } = {},
                fillType: { id: fillTypeId } = {},
                spacerType: { id: spacerTypeId } = {},
                windowType: { id: windowTypeId } = {},
                frameMaterial: { id: frameMaterialId } = {},
                window: windowOverall = {},
                windowLegacy = {},
            } = {},
        } = {},
    } = codes;

    let windowTypeLabel = codeLabel;
    let glazingTypeLabel = getCodeLayerLabel(
        "csWindowGlazingType",
        glazingTypeId
    );
    let coatingsTintsLabel = getCodeLayerLabel(
        "csWindowCoatingTint",
        coatingsTintsId
    );
    let fillTypeLabel = getCodeLayerLabel("csWindowFillType", fillTypeId);
    let spacerTypeLabel = getCodeLayerLabel("csWindowSpacerType", spacerTypeId);
    let windowOperatorLabel = getCodeLayerLabel("csWindowType", windowTypeId);
    let frameMaterialLabel = getCodeLayerLabel(
        "csWindowFrameMaterial",
        frameMaterialId
    );
    let rsiLabel = " ";
    let shgcLabel = " ";

    if (codeRef.includes("-U-") && !isEmpty(windowOverall)) {
        const layerKey = Object.keys(windowOverall)[0];
        const {
            [layerKey]: {
                glazingType: { id: udefGlazingId } = {},
                lowECoating: { id: udefCoatingId } = {},
                fillGas: { id: udefFillId } = {},
                windowStyle: { id: udefStyleId } = {},
                overallThermalResistance: { id: thermalResId, value } = {},
                shgc: udefShgc,
            } = {},
        } = windowOverall;
        windowTypeLabel = `${windowTypeLabel} (User Def. Overall)`;

        glazingTypeLabel = getLabel(
            "udefWindowOverallGlazingType",
            udefGlazingId
        );
        coatingsTintsLabel = getLabel(
            "udefWindowOverallLowECoating",
            udefCoatingId
        );
        fillTypeLabel = getLabel("udefWindowOverallFillGas", udefFillId);
        spacerTypeLabel = "";
        windowOperatorLabel = getLabel("udefWindowOverallStyle", udefStyleId);
        frameMaterialLabel = "";
        rsiLabel =
            thermalResId == 1
                ? `${(1 / value).toFixed(2)}`
                : `${value.toFixed(2)}`;

        shgcLabel = udefShgc || "";
    } else if (codeRef.includes("-U-") && !isEmpty(windowLegacy)) {
        const layerKey = Object.keys(windowLegacy)[0];
        const {
            [layerKey]: {
                type: { id: udefTypeId } = {},
                rsiValues: { centreOfGlass, edgeOfGlass, frame } = {},
                shgc: udefShgc,
            } = {},
        } = windowLegacy;
        windowTypeLabel = `${windowTypeLabel} (User Def. Legacy)`;

        glazingTypeLabel = "";
        coatingsTintsLabel = "";
        fillTypeLabel = "";
        spacerTypeLabel = "";
        windowOperatorLabel = getLabel("udefWindowLegacyType", udefTypeId);
        frameMaterialLabel = "";
        rsiLabel = `${centreOfGlass.toFixed(2)} / ${edgeOfGlass.toFixed(
            2
        )} / ${frame.toFixed(2)}`;
        shgcLabel = udefShgc || "";
    }

    const convertedWidth = convertUnit({
        value: width,
        type: "length",
        inputUnit: getBaseUnits("windowWidth", modelUnits).trueBase,
        outputUnit: getBaseUnits("windowWidth", modelUnits).displayBase,
    }).toFixed(2);

    const convertedHeight = convertUnit({
        value: height,
        type: "length",
        inputUnit: getBaseUnits("windowHeight", modelUnits).trueBase,
        outputUnit: getBaseUnits("windowHeight", modelUnits).displayBase,
    }).toFixed(2);

    return {
        label: label,
        orientation: getLabel("windowDirection", dirId),
        numIdentical: numIdentical,
        energyStarCert: energyStar,
        width: convertedWidth,
        height: convertedHeight,
        overhangWidth: convertUnit({
            value: overhangWidth,
            type: "length",
            inputUnit: getBaseUnits("windowOverhangWidth", modelUnits).trueBase,
            outputUnit: getBaseUnits("windowOverhangWidth", modelUnits)
                .displayBase,
        }).toFixed(2),
        headerHeight: convertUnit({
            value: headerHeight,
            type: "length",
            inputUnit: getBaseUnits("windowHeaderHeight", modelUnits).trueBase,
            outputUnit: getBaseUnits("windowHeaderHeight", modelUnits)
                .displayBase,
        }).toFixed(2),
        tilt: tiltValue || "",
        windowTypeLabel: windowTypeLabel,
        glazingType: glazingTypeLabel,
        coatingsTints: coatingsTintsLabel,
        fillType: fillTypeLabel,
        spacerType: spacerTypeLabel,
        windowOperatorType: windowOperatorLabel,
        frameMaterial: frameMaterialLabel,
        rsiLabel: rsiLabel,
        shgcLabel: shgcLabel,
        area:
            (parseFloat(convertedWidth) * parseFloat(convertedHeight)) /
            1000000,
    };
};

const dirOrder = [
    "South",
    "Southeast",
    "East",
    "Northeast",
    "North",
    "Northwest",
    "West",
    "Southwest",
];

export const getDoorWindowData = ({
    components = {},
    codes = {},
    modelUnits = "Metric",
}) => {
    let doorInd = 0;
    let windowInd = 0;
    let windowsOnDoorsInd = 0;

    let {
        doorResults = [],
        windowResults = [],
        windowsOnDoorResults = [],
    } = ["wall", "ceiling", "basement", "crawlspace"].reduce(
        (rootCache, parentCompType) => {
            const { [parentCompType]: parentComps = {} } = components;
            if (isEmpty(parentComps)) {
                return rootCache;
            }

            const {
                doorResults = [],
                windowResults = [],
                windowsOnDoorResults = [],
            } = Object.keys(parentComps).reduce(
                (parentCache, parentKey) => {
                    const {
                        label: parentLabel = "",
                        subcomponents: { window = {}, door = {} } = {},
                    } = parentComps[parentKey];

                    let windowsOnDoorResults = [];
                    const doorResults = Object.keys(door).map((doorKey) => {
                        //check for windows on doors and re-package here
                        const {
                            label: doorLabel = "",
                            measurements: { height, width } = {},
                            doorType: {
                                id: doorTypeId,
                                value: doorRValue,
                            } = {},
                            energyStar,
                            subcomponents: { window: doorWindow = {} } = {},
                        } = door[doorKey] || {};

                        windowsOnDoorResults = [
                            ...windowsOnDoorResults,
                            ...Object.keys(doorWindow).map((windowKey, ind) => {
                                windowsOnDoorsInd += 1;
                                return {
                                    ...getWindowResults(
                                        doorWindow[windowKey],
                                        codes,
                                        modelUnits
                                    ),
                                    id: windowsOnDoorsInd,
                                    location: doorLabel,
                                };
                            }),
                        ];

                        doorInd += 1;
                        return {
                            id: doorInd,
                            componentId: doorKey,
                            label: doorLabel,
                            width: convertUnit({
                                value: width,
                                type: "length",
                                inputUnit: getBaseUnits("doorWidth", modelUnits)
                                    .trueBase,
                                outputUnit: getBaseUnits(
                                    "doorWidth",
                                    modelUnits
                                ).displayBase,
                            }).toFixed(2),
                            height: convertUnit({
                                value: height,
                                type: "length",
                                inputUnit: getBaseUnits(
                                    "doorHeight",
                                    modelUnits
                                ).trueBase,
                                outputUnit: getBaseUnits(
                                    "doorHeight",
                                    modelUnits
                                ).displayBase,
                            }).toFixed(2),
                            energyStarCert: energyStar,
                            location: parentLabel,
                            doorTypeLabel: getLabel("doorType", doorTypeId),
                            rValue:
                                modelUnits === "Metric"
                                    ? doorRValue
                                    : `R${(doorRValue * 5.678).toFixed(1)}`,
                        };
                    }, []);

                    const windowResults = Object.keys(window).map(
                        (windowKey) => {
                            windowInd += 1;
                            return {
                                ...getWindowResults(
                                    window[windowKey],
                                    codes,
                                    modelUnits
                                ),
                                id: windowInd,
                                location: parentLabel,
                            };
                        }
                    );

                    return {
                        doorResults: [
                            ...(parentCache?.doorResults || []),
                            ...doorResults,
                        ],
                        windowResults: [
                            ...(parentCache?.windowResults || []),
                            ...windowResults.sort(
                                (
                                    { orientation: dirA },
                                    { orientation: dirB }
                                ) => {
                                    return (
                                        dirOrder.indexOf(dirA) -
                                        dirOrder.indexOf(dirB)
                                    );
                                }
                            ),
                        ],
                        windowsOnDoorResults: [
                            ...(parentCache?.windowsOnDoorResults || []),
                            ...windowsOnDoorResults.sort(
                                (
                                    { orientation: dirA },
                                    { orientation: dirB }
                                ) => {
                                    return (
                                        dirOrder.indexOf(dirA) -
                                        dirOrder.indexOf(dirB)
                                    );
                                }
                            ),
                        ],
                    };
                },
                { doorResults: [], windowResults: [], windowsOnDoorResults: [] }
            );

            return {
                doorResults: [...(rootCache.doorResults || []), ...doorResults],
                windowResults: [
                    ...(rootCache.windowResults || []),
                    ...windowResults,
                ],
                windowsOnDoorResults: [
                    ...(rootCache.windowsOnDoorResults || []),
                    ...windowsOnDoorResults,
                ],
            };
        },
        { doorResults: [], windowResults: [], windowsOnDoorResults: [] }
    );

    const a1 = "Measurement System";
    const b1 = modelUnits;

    const spacing = [
        {
            [`${a1}`]: "",
        },
        {
            [`${a1}`]: "",
        },
        {
            [`${a1}`]: "",
        },
    ];

    const rowFill = {
        [`${b1}`]: "-",
        C: "-",
        D: "-",
        E: "-",
        F: "-",
        G: "-",
        H: "-",
        I: "-",
        J: "-",
        K: "-",
        L: "-",
        M: "-",
        N: "-",
        O: "-",
        P: "-",
        Q: "-",
        R: "-",
        S: "-",
        // T: "-",
    };

    let windowObjectData = !isEmpty(windowResults)
        ? windowResults.map((window) => {
              return {
                  [`${a1}`]: window.id || " ",
                  [`${b1}`]: window.label || " ",
                  C: window.orientation || " ",
                  D: window.numIdentical || " ",
                  E: window.energyStarCert || " ",
                  F: window.width || " ",
                  G: window.height || " ",
                  H: window.overhangWidth || " ",
                  I: window.headerHeight || " ",
                  J: window.tilt || " ",
                  K: window.windowTypeLabel || " ",
                  L: window.glazingType || " ",
                  M: window.coatingsTints || " ",
                  N: window.fillType || " ",
                  O: window.spacerType || " ",
                  P: window.windowOperatorType || " ",
                  Q: window.frameMaterial || " ",
                  R: window.rsiLabel || " ",
                  S: window.shgcLabel || " ",
              };
          })
        : [
              {
                  [`${a1}`]: "-",
                  ...rowFill,
              },
          ];

    windowObjectData = windowObjectData.reduce((acc, objData, ind, array) => {
        if (!isEmpty(windowResults)) {
            if (ind === 0) {
                return [
                    ...acc,
                    {
                        [`${a1}`]: `${windowResults[ind].location} &`,
                        ...rowFill,
                    },
                    objData,
                ];
            } else if (
                ind > 0 &&
                windowResults[ind].location !== windowResults[ind - 1].location
            ) {
                return [
                    ...acc,
                    {
                        [`${a1}`]: `${windowResults[ind].location} &`,
                        ...rowFill,
                    },
                    objData,
                ];
            } else {
                return [...acc, objData];
            }
        } else {
            return [...acc, objData];
        }
    }, []);

    let windowOnDoorObjectData = !isEmpty(windowsOnDoorResults)
        ? windowsOnDoorResults.map((window) => {
              return {
                  [`${a1}`]: window.id || " ",
                  [`${b1}`]: window.label || " ",
                  C: window.orientation || " ",
                  D: window.numIdentical || " ",
                  E: window.energyStar || " ",
                  F: window.width || " ",
                  G: window.height || " ",
                  H: window.overhangWidth || " ",
                  I: window.headerHeight || " ",
                  J: window.tilt || " ",
                  K: window.windowTypeLabel || " ",
                  L: window.glazingType || " ",
                  M: window.coatingsTints || " ",
                  N: window.fillType || " ",
                  O: window.spacerType || " ",
                  P: window.windowType || " ",
                  Q: window.frameMaterial || " ",
                  R: window.rsiLabel || " ",
                  S: window.shgcLabel || " ",
              };
          })
        : [
              {
                  [`${a1}`]: "-",
                  ...rowFill,
              },
          ];

    windowOnDoorObjectData = windowOnDoorObjectData.reduce(
        (acc, objData, ind, array) => {
            if (!isEmpty(windowsOnDoorResults)) {
                if (ind === 0) {
                    return [
                        ...acc,
                        {
                            [`${a1}`]: `${windowsOnDoorResults[ind].location} &`,
                            ...rowFill,
                        },
                        objData,
                    ];
                } else if (
                    ind > 0 &&
                    windowsOnDoorResults[ind].location !==
                        windowsOnDoorResults[ind - 1].location
                ) {
                    return [
                        ...acc,
                        {
                            [`${a1}`]: `${windowsOnDoorResults[ind].location} &`,
                            ...rowFill,
                        },
                        objData,
                    ];
                } else {
                    return [...acc, objData];
                }
            } else {
                return [...acc, objData];
            }
        },
        []
    );

    let doorObjectData = !isEmpty(doorResults)
        ? doorResults.map((door) => {
              return {
                  [`${a1}`]: door.id || " ",
                  [`${b1}`]: door.label || " ",
                  C: door.width || " ",
                  D: door.height || " ",
                  E: door.energyStarCert || " ",
                  F: door.doorTypeLabel || " ",
                  G: door.rValue || " ",
              };
          })
        : [
              {
                  [`${a1}`]: "-",
                  [`${b1}`]: "-",
                  C: "-",
                  D: "-",
                  E: "-",
                  F: "-",
                  G: "-",
              },
          ];

    doorObjectData = doorObjectData.reduce((acc, objData, ind, array) => {
        if (!isEmpty(doorResults)) {
            if (ind === 0) {
                return [
                    ...acc,
                    {
                        [`${a1}`]: `${doorResults[ind].location} &`,
                        [`${b1}`]: "-",
                        C: "-",
                        D: "-",
                        E: "-",
                        F: "-",
                        G: "-",
                    },
                    objData,
                ];
            } else if (
                ind > 0 &&
                doorResults[ind].location !== doorResults[ind - 1].location
            ) {
                return [
                    ...acc,
                    {
                        [`${a1}`]: `${doorResults[ind].location} &`,
                        [`${b1}`]: "-",
                        C: "-",
                        D: "-",
                        E: "-",
                        F: "-",
                        G: "-",
                    },
                    objData,
                ];
            } else {
                return [...acc, objData];
            }
        } else {
            return [...acc, objData];
        }
    }, []);

    return [
        // each object is a row indcating the column and its value
        {
            [`${a1}`]: "",
            [`${b1}`]: "",
        },
        {
            [`${a1}`]: "",
        },
        // windows
        {
            [`${a1}`]: "Windows",
        },
        {
            [`${a1}`]: "ID",
            [`${b1}`]: "Label",
            C: "Orientation",
            D: "Number of identical windows",
            E: "ENERGY STAR certified",
            F: `Width (${getBaseUnits("windowWidth", modelUnits).displayBase})`, // change unit based on global units
            G: `Height (${
                getBaseUnits("windowHeight", modelUnits).displayBase
            })`,
            H: `Overhang Width (${
                getBaseUnits("windowOverhangWidth", modelUnits).displayBase
            })`,
            I: `Header Height (${
                getBaseUnits("windowHeaderHeight", modelUnits).displayBase
            })`,
            J: "Tilt",
            K: "Window Type Label",
            L: "Glazing types $",
            M: "Coatings/Tints $",
            N: "Fill (air space) type $",
            O: "Spacer (bar) type $",
            P: "Window (operator) type $",
            Q: "Frame material $",
            R: "RSI (User Def. only) $",
            S: "SHGC (User Def. only) $",
        },
        ...windowObjectData,
        ...spacing,
        // windows on doors
        {
            [`${a1}`]: "Windows on Doors",
        },
        {
            [`${a1}`]: "ID",
            [`${b1}`]: "Label",
            C: "Orientation",
            D: "Number of identical windows",
            E: "ENERGY STAR certified",
            F: `Width (${getBaseUnits("windowWidth", modelUnits).displayBase})`, // change unit based on global units
            G: `Height (${
                getBaseUnits("windowHeight", modelUnits).displayBase
            })`,
            H: `Overhang Width (${
                getBaseUnits("windowOverhangWidth", modelUnits).displayBase
            })`,
            I: `Header Height (${
                getBaseUnits("windowHeaderHeight", modelUnits).displayBase
            })`,
            J: "Tilt",
            K: "Window Type Label",
            L: "Glazing types $",
            M: "Coatings/Tints $",
            N: "Fill (air space) type $",
            O: "Spacer (bar) type $",
            P: "Window (operator) type $",
            Q: "Frame material $",
            R: "RSI (User Def. only) $",
            S: "SHGC (User Def. only) $",
        },
        ...windowOnDoorObjectData,
        ...spacing,
        // doors
        {
            [`${a1}`]: "Doors",
        },
        {
            [`${a1}`]: "ID",
            [`${b1}`]: "Label",
            C: `Width (${getBaseUnits("doorWidth", modelUnits).displayBase})`,
            D: `Height (${getBaseUnits("doorHeight", modelUnits).displayBase})`,
            E: "ENERGY STAR certified",
            F: "Door Type",
            G: `R-Value (${getBaseUnits("doorType", modelUnits).displayBase})`,
        },
        ...doorObjectData,
    ];
};
