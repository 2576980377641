import React from "react";

export default () => (
    <svg
        width="26px"
        height="16px"
        viewBox="0 0 26 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g id="Side-Bar-/-Open" transform="translate(-31.000000, -234.000000)" fill="#F0F8FD" fillRule="nonzero">
            <g id="Main-Side-Bar" transform="translate(-1.000000, 0.000000)">
                <g id="Nav-Items" transform="translate(0.500000, 86.000000)">
                    <g id="Group-5" transform="translate(29.893957, 90.993839)">
                        <g id="Group-2" transform="translate(0.606043, 53.006161)">
                            <g id="Group" transform="translate(1.000000, 4.000000)">
                                <path
                                    d="M2.16666667,11.6363636 C3.36328362,11.6363636 4.33333333,12.6131969 4.33333333,13.8181818 C4.33333333,15.0231667 3.36328362,16 2.16666667,16 C0.970049709,16 0,15.0231667 0,13.8181818 C0,12.6131969 0.970049709,11.6363636 2.16666667,11.6363636 Z M26,12.3636364 L26,15.2727273 L7.22222222,15.2727273 L7.22222222,12.3636364 L26,12.3636364 Z M2.16666667,5.81818182 C3.36328362,5.81818182 4.33333333,6.79501509 4.33333333,8 C4.33333333,9.20498491 3.36328362,10.1818182 2.16666667,10.1818182 C0.970049709,10.1818182 0,9.20498491 0,8 C0,6.79501509 0.970049709,5.81818182 2.16666667,5.81818182 Z M26,6.54545455 L26,9.45454545 L7.22222222,9.45454545 L7.22222222,6.54545455 L26,6.54545455 Z M2.16666667,0 C3.36328362,0 4.33333333,0.976833273 4.33333333,2.18181818 C4.33333333,3.38680309 3.36328362,4.36363636 2.16666667,4.36363636 C0.970049709,4.36363636 0,3.38680309 0,2.18181818 C0,0.976833273 0.970049709,0 2.16666667,0 Z M26,0.727272727 L26,3.63636364 L7.22222222,3.63636364 L7.22222222,0.727272727 L26,0.727272727 Z"
                                    id="Combined-Shape"
                                ></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
