import { connect } from "react-redux";
import FileSharing from "./";

import { actions as userActions } from "store/users";
import { actions as modelActions } from "features/Model/_ducks";

const { fetchUserOrganization } = userActions;
const { shareModelFile } = modelActions;

const mapStateToProps = (
    { form, user: { uid, organization } = {}, model: { modelDetails: { sharedWith = [], name, owner }, modelId } = {} },
    { formName = "model" }
) => {
    // const selector = formValueSelector("model");
    // const modelSelector = formValueSelector("model");

    return {
        uid,
        organization,
        modelName: name,
        sharedWith,
        modelId,
        owner,
    };
};

const mergeProps = (state, dispatch, own) => ({
    ...state,
    ...dispatch,
    ...own,
});

const mapDispatchToProps = (dispatch) => ({
    fetchUserOrganization: (uid, orgId) => dispatch(fetchUserOrganization(uid, orgId)),
    shareModelFile: async (modelId, uid, orgId, userList, permissionsType) =>
        dispatch(shareModelFile(modelId, uid, orgId, userList, permissionsType)),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(FileSharing);
