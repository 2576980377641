import { DashboardAPI } from "utils/api";

export const validateMiniAuditConversion = async ({ miniAuditId = "" }) => {
    return DashboardAPI({
        method: "GET",
        url: "/validateMiniAuditConversion",
        query: {
            miniAuditId,
        },
    });
};

export const createModelFromMiniAudit = async ({ miniAuditId = "", uid = "", name }) => {
    return DashboardAPI({
        method: "POST",
        url: "/createModelFromMiniAudit",
        data: {
            miniAuditId,
            modelInputDetails: {
                name,
                notes: "",
                templateID: "miniAudit",
                templateName: "Mini-audit",
            },
            user: {
                uid,
            },
        },
    });
};

export const duplicateModelData = async ({ modelId, uid, folderId, duplicateName }) => {
    return DashboardAPI({
        method: "POST",
        url: "/duplicateModelData",
        data: {
            modelId,
            uid,
            folderId,
            duplicateName,
        },
    });
};
