import React from "react";
import classes from "./style.module.scss";
import FileImageDialog from "components/FileImageDialog";
import Button from "components/Button";

export const CloseModal = ({ closeActions }) => (
    <div className={classes.close} onClick={() => closeActions()}>
        <div>
            <span>close</span>
        </div>
    </div>
);

// const Transition = React.forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);

const getClasses = ({ rootClass = "", paperClass = "", imageClass = "" }) => {
    // console.log(rootClass,paperClass)
    return {
        root: `${classes.dialogRoot} ${rootClass ? rootClass : ""}`,
        paper: `${classes.paper} ${paperClass ? paperClass : ""}`,
    };
};

export default ({ setIsOpen, children, classes = {}, ...props }) => (
    <FileImageDialog
        fullScreen
        // fullWidth
        classes={getClasses(classes)}
        {...props}
    >
        {children}
    </FileImageDialog>
);
