import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import PolygonFields from './';
import { actions } from 'features/Model/Drawing/_ducks';

const { updatePolygon, removePolygon, setAction, setActiveComponent, updateComponentsToDelete, updateToRemoveFromFloor } = actions;

const mapStateToProps = (
    {
        drawing:{
            activeComponent='',
            activeImage='',
            imageData={},
        },
        model:{
            modelData:{
                uiSettings:{
                    primaryUnits
                }={}
            }={},
        },
        form
    },
    {
        onSet
    }
) => {
    const {
        [activeImage]:{
            polygons:{
                [activeComponent]:polygonData={}
            }={},
            scale:{
                px,
                input=0,
                units='m',
                displayUnits='m'
            }={}
        }={}
    } = imageData;

    const {
        perimeter,
        area,
        components={},
    } = polygonData;

    const selector = formValueSelector('drawingPolygon');
    const toDelete = selector({form}, 'toDelete') || [];
    const includeInHeatedFloor = selector({form}, 'includeInHeatedFloor');
    const conversionFactor = input / px;

    return {
        onSet,
        activeComponent,
        activeImage,
        initialValues:polygonData,
        primaryUnits,
        components,
        imageUnits:displayUnits,
        perimeter,
        area,
        conversionFactor,
        toDelete,
        includeInHeatedFloor
    }
};

const mapDispatchToProps = (dispatch) => ({
    updatePolygon:({image, componentId, updates}) => dispatch(updatePolygon({image, componentId, updates})),
    removePolygon:({image, polygonId}) => dispatch(removePolygon({image, polygonId})),
    setActiveComponent:(component) => dispatch(setActiveComponent({activeComponent:component})),
    setAction:({id, meta={}}) => dispatch(setAction({id, meta})),
    updateComponentsToDelete:(components) => dispatch(updateComponentsToDelete(components)),
    // updateToRemoveFromFloor:(id) => dispatch(updateToRemoveFromFloor(id)),
});

const mergeProps = (state, dispatch, own) => ({
    ...state,
    ...dispatch,
    ...own,
});

const onSubmit = async (
    form,
    dispatch,
    {
        activeComponent,
        activeImage,
        onSet,
    }
) => {
    const { toDelete=[], toRemoveFromFloor=[], ...rest } = form;
    const data = {
        updates:rest,
        image:activeImage,
        componentId:activeComponent,
    }

    await dispatch(updateComponentsToDelete(toDelete))
    // await dispatch(updateToRemoveFromFloor(toRemoveFromFloor))
    await dispatch(updatePolygon(data));

    if (onSet) {
        onSet();
    }
}

const form = reduxForm({
    form:"drawingPolygon",
    enableReinitialize: true,
    onSubmit
})(PolygonFields);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(form);