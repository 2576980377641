import React, { useCallback, useEffect } from "react";
import classes from "./style.module.scss";
import globalStyles from "components/globalStyles.module.scss";
import { Field } from "redux-form";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import InputWithUnits from "components/Input/InputWithUnits";
import { required, requiredCodeRef, getValidation, getDecimalPlaces } from "utils/fieldValidation";
import Select from "components/Input/Select";
import Add from "assets/images/icons/JSX/Add";
import Book from "assets/images/icons/JSX/Book";
import Edit from "assets/images/icons/JSX/Edit";
import { getBaseUnits, getUnitOptions } from "utils/fields";
import Tooltip from "components/Tooltip";
import Checkbox from "components/Input/Checkbox";
import { isEmpty, isEqual } from "lodash";
import SelectWithInput from "components/Input/SelectWithInput";

const windowInsTypeValidation = [required, ...getValidation("windowInsType")];
const erValidation = getValidation("windowER");
const shgcValidation = getValidation("windowSHGC");
const eStarUValValidation = getValidation("windowEStarUValMax");
const eStarERValValidation = getValidation("windowEStarERVal");

export const CodeFooter = ({ toggleDrawer, setInitCode, codeType }) => (
    <div
        className={classes.codeFooter}
        onClick={async () => {
            await setInitCode({}, codeType);
            toggleDrawer(true);
        }}
    >
        <span>Create New Code</span>
        <Add />
    </div>
);

const codeSort = (a, b) => {
    if (a.label < b.label) {
        return -1;
    }
    if (a.label > b.label) {
        return 1;
    }
    return 0;
};

const LabelActionComponent = ({ codeRef, handleEdit, codeType }) => (
    <>
        <div
            className={globalStyles.editCode}
            data-tip="Edit Code"
            data-for={codeRef}
            onClick={(event) => handleEdit(event, codeRef, codeType)}
        >
            <Edit />
            <Tooltip id={codeRef} className={globalStyles.fieldTooltip} place="left"/>
        </div>
    </>
);

// Window Ins. Type codes found in code library
export const windowInsTypeLibCodesMap = (libCodes, handleEditLibWindowCode) =>
    Object.keys(libCodes)
        .map((codeRef) => {
            const { label = "" } = libCodes[codeRef] || {};
            const [standardCode] = codeRef.split("Window-U");

            return {
                label: label,
                labelIcon: Book,
                labelTag: !standardCode ? "User Defined" : "",
                labelActionComponent: () =>
                    handleEditLibWindowCode ? (
                        <LabelActionComponent
                            codeRef={codeRef}
                            handleEdit={handleEditLibWindowCode}
                            codeType={standardCode ? "S" : "U"}
                        />
                    ) : null,
                value: {
                    codeLabel: label,
                    codeRef,
                },
            };
        })
        .sort(codeSort);

// Window Ins. Type codes found in model, filtered by those in library
export const windowInsTypeModelCodesMap = (modelCodes, libCodes, handleEditModelWindowCode) =>
    Object.values(modelCodes)
        .filter(({ codeRef }) => {
            const [component] = codeRef.split("-");
            if (!!libCodes[codeRef]) {
                return (
                    (!libCodes[codeRef] && component === "Window") ||
                    !isEqual(modelCodes[codeRef].layers, libCodes[codeRef].layers)
                );
            }
            return !libCodes[codeRef] && component === "Window";
        })
        .map(({ label, codeRef }) => {
            const [standardCode] = codeRef.split("Window-U");

            return {
                label,
                labelTag: !standardCode ? "User Defined" : "",
                labelActionComponent: () => (
                    <LabelActionComponent
                        codeRef={codeRef}
                        handleEdit={handleEditModelWindowCode}
                        codeType={standardCode ? "S" : "U"}
                    />
                ),
                value: {
                    codeLabel: label,
                    codeRef,
                },
            };
        })
        .sort(codeSort);

export default React.memo(
    ({
        accessor,
        selectedCodeRef,
        selectedLabel,
        change,
        modelChange = change,
        id,
        modelUnits,
        rValUnits,
        libCodes,
        modelCodes,
        selectedRValue,
        windowShgc,
        windowEr,
        setInitCode,
        updateDrawingChanges,
        enerStar,
        drawingComponent,
        drawingImage,
        isCalculatingRValue,
        updateCodeDrawer,
        isUpgrade = false,
        disabledCodeEdit = false,
        eStarERVal,
        h2kMinor,
    }) => {
        const handleTypeChange = useCallback(
            ({ codeRef = "" }) => {
                const modelMatch = modelCodes[codeRef] || {};
                if (!isEmpty(modelMatch)) {
                    change(`${accessor}.windowType_nomRVal`, modelMatch.nominalRValue || 0);
                    change(`${accessor}.windowType_effRVal`, modelMatch.nominalRValue || 0);
                    change(`${accessor}.shgc`, modelMatch.shgc || 0);
                    change(`${accessor}.er`, modelMatch.er || 0);
                } else {
                    const { nominalRValue = 0, shgc = 0, er = 0 } = libCodes[codeRef] || {};

                    change(`${accessor}.windowType_nomRVal`, nominalRValue || 0);
                    change(`${accessor}.windowType_effRVal`, nominalRValue || 0);
                    change(`${accessor}.shgc`, shgc || 0);
                    change(`${accessor}.er`, er || 0);

                    modelChange(`modelData.codes.${codeRef}`, { ...libCodes[codeRef], codeRef });
                }

                modelChange(`stashedCodes.Window.${accessor.split(".").slice(-1)[0]}`, codeRef);
            },
            [libCodes, modelCodes, change, modelChange, accessor]
        );

        const handleEditModelWindowCode = useCallback(
            async (event, codeRef, codeType) => {
                event.stopPropagation(); //Don't let click event bubble up to parent
                const initCodeType = codeType === "U" ? "windowUDef" : "windowType";
                const code = modelCodes[codeRef];
                await setInitCode(code, initCodeType);
                updateCodeDrawer({
                    isOpen: true,
                    codeName: "Window",
                    isEditing: true,
                    componentType: "Window",
                    codeType: codeType,
                    windowAccessor: accessor,
                    componentId: id,
                    id: codeRef,
                    modelFormChange: modelChange,
                    currentFormChange: change,
                    lastEdited: code.lastEdited || "",
                });
            },
            [accessor, id, modelCodes, setInitCode, updateCodeDrawer, change, modelChange]
        );

        const handleEditLibWindowCode = disabledCodeEdit
            ? null
            : useCallback(
                  async (event, codeRef, codeType) => {
                      event.stopPropagation(); //Don't let click event bubble up to parent
                      const initCodeType = codeType === "U" ? "windowUDef" : "windowType";
                      const code = { ...libCodes[codeRef], codeRef: codeRef, isLibCode: true };
                      await setInitCode(code, initCodeType);
                      updateCodeDrawer({
                          isOpen: true,
                          codeName: "Window",
                          isEditing: true,
                          componentType: "Window",
                          codeType: codeType,
                          windowAccessor: accessor,
                          componentId: id,
                          id: codeRef,
                          modelFormChange: modelChange,
                          currentFormChange: change,
                          lastEdited: code.lastEdited || "",
                      });
                  },
                  [accessor, id, libCodes, setInitCode, updateCodeDrawer, change, modelChange]
              );

        // const { shgc:windowShgc=0.00, er:windowEr=0.00 } = modelCodes[selectedCodeRef] || {};

        // useEffect(() => {
        //     modelChange(`stashedCodes.Window.${id}`, selectedCodeRef);
        //     if (!isUpgrade) {
        //         updateDrawingChanges(id, {
        //             type:'window',
        //             windowType:{
        //                 codeLabel:selectedLabel,
        //                 codeRef:selectedCodeRef,
        //             },
        //             windowType_nomRVal: selectedRValue,
        //             windowType_effRVal: selectedRValue,
        //             drawingComponent,
        //             drawingImage
        //         });
        //     }
        // }, [id, selectedCodeRef]);

        // useEffect(() => {
        //     if (!isUpgrade) {
        //         updateDrawingChanges(id, {
        //             type:'window',
        //             energyStar:enerStar,
        //             drawingComponent,
        //             drawingImage
        //         })
        //     }
        // },[enerStar, id]);

        return (
            <>
                <InputRow gridTemplate="31.85% 23.25% 23.25%">
                    <Field
                        component={Select}
                        type="number"
                        name={`${accessor}.windowType`}
                        options={[
                            ...windowInsTypeModelCodesMap(modelCodes, libCodes, handleEditModelWindowCode),
                            ...windowInsTypeLibCodesMap(libCodes, handleEditLibWindowCode),
                        ]}
                        label="Window Type"
                        placeholder="Choose Window Type"
                        searchPlaceholder="Search Window Codes"
                        search={true}
                        footer={() => (
                            <CodeFooter
                                toggleDrawer={(value) =>
                                    updateCodeDrawer({
                                        isOpen: value,
                                        codeName: "Window",
                                        isEditing: false,
                                        componentType: "Window",
                                        codeType: "S",
                                        windowAccessor: accessor,
                                        componentId: id,
                                        modelFormChange: modelChange,
                                        currentFormChange: change,
                                    })
                                }
                                setInitCode={setInitCode}
                                codeType="windowInsType"
                            />
                        )}
                        className={classes.windowType}
                        isLoading={isCalculatingRValue}
                        onChange={handleTypeChange}
                        validate={requiredCodeRef}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.windowType_effRVal`}
                        label="Effective R-Value"
                        placeholder={0.0}
                        disabled={true}
                        validate={windowInsTypeValidation}
                        setTouched={true}
                        decimals={getDecimalPlaces("windowInsType")}
                        change={change}
                        setValue={selectedRValue}
                        units={{
                            base: getBaseUnits("windowInsType", modelUnits),
                            options: getUnitOptions("thermalResistance"),
                            selected: rValUnits,
                            unitType: "thermalResistance",
                            accessor: `${accessor}.windowType_u`,
                        }}
                        info={"This is an estimate. The value will be updated accordingly when exported to HOT2000."}
                        isLoading={isCalculatingRValue}
                    />
                    <Field
                        component={Checkbox}
                        name={`${accessor}.energyStar`}
                        label="Energy Star"
                        large
                        className={classes.enerStar}
                        type="checkbox"
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr 1fr">
                    <Field
                        component={Input}
                        type="number"
                        name={`${accessor}.er`}
                        label="Window ER"
                        placeholder={0.0}
                        validate={erValidation}
                        setValue={windowEr}
                        decimals={getDecimalPlaces("windowER")}
                        disabled
                        info={"This is an estimate. The value will be updated accordingly when exported to HOT2000."}
                        isLoading={isCalculatingRValue}
                    />
                    <Field
                        component={Input}
                        type="number"
                        name={`${accessor}.shgc`}
                        label="SHGC"
                        placeholder={0.0}
                        validate={shgcValidation}
                        decimals={getDecimalPlaces("windowSHGC")}
                        setValue={windowShgc}
                        disabled
                        info={"This is an estimate. The value will be updated accordingly when exported to HOT2000."}
                        isLoading={isCalculatingRValue}
                    />
                    {enerStar && h2kMinor >= 11 && (
                        <Field
                            component={SelectWithInput}
                            inputData={{
                                triggerValue: "er",
                                inputLabel: "ER",
                                inputType: "number",
                                inputPlaceholder: 0,
                            }}
                            name={`${accessor}.energyStarType`}
                            options={[
                                {
                                    label: "U-Value > 1.22",
                                    value: { id: "uValue", uValueMin: 1.22, uValueMax: 0, value: 0 },
                                },
                                {
                                    label: "U-Value > 1.05 and <= 1.22",
                                    value: { id: "uValue", uValueMin: 1.05, uValueMax: 1.22, value: 0 },
                                },
                                {
                                    label: "U-Value <= 1.05",
                                    value: { id: "uValue", uValueMin: 0, uValueMax: 1.05, value: 0 },
                                },
                                {
                                    label: "Energy Rating",
                                    value: { id: "er", uValueMin: 0, uValueMax: 0, value: 0 },
                                },
                            ]}
                            label="Energy Star Type"
                            placeholder="Choose Energy Star Type"
                            validate={eStarERValValidation}
                            warning={eStarERVal > 62 ? "highWindowER" : ""}
                        />
                    )}
                </InputRow>
            </>
        );
    }
);
