import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import AtypicalLoads from './';


const mapStateToProps = (
    {
        form
    },
    {
        accessor
    }
) => {
    const selector = formValueSelector('model');
    const isPortableAC = selector({form}, `${accessor}.portableAirConditioning`);

    const modelUnits = selector({form}, `modelData.uiSettings.primaryUnits`) || {};

    const { id:buildingTypeId } = selector({form}, `modelData.specifications.buildingType`) || {}; 
    const isMURB = buildingTypeId !== 0;
    

    return {
        accessor,
        isPortableAC,
        isMURB,
        modelUnits
    }
};

const mapDispatchToProps = () => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AtypicalLoads);