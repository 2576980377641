import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import HouseholdOperating from './';


const mapStateToProps = (
    {
        form
    },
    {
        accessor
    }
) => {
    const selector = formValueSelector('model');
    const heatingDayUnits = selector({form}, `${accessor}.thermostat.heatingDay_u`);
    const heatingNightUnits = selector({form}, `${accessor}.thermostat.heatingNight_u`);
    const coolingUnits = selector({form}, `${accessor}.thermostat.cooling_u`);
    const modelUnits = selector({form}, `modelData.uiSettings.primaryUnits`) || {};

    return {
        accessor,
        heatingDayUnits,
        heatingNightUnits,
        coolingUnits,
        modelUnits
    }
};

const mapDispatchToProps = () => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HouseholdOperating);