import React from 'react';
import classes from '../../../style.module.scss';
import { Field } from 'redux-form';
import InputWithUnits from 'components/Input/InputWithUnits';
import Input from 'components/Input';
import Checkbox from 'components/Input/Checkbox';
import { required, maxLength, getValidation, getDecimalPlaces } from 'utils/fieldValidation';
import { getBaseUnits, getUnitOptions } from 'utils/fields';
import convertUnit from 'utils/convertUnit';
import { isEqual } from 'lodash';

const charLim100 = maxLength(100);
const exposedSurfaceValidation = getValidation('basementExpSurfacePerimeter');
const perimeterValidation = getValidation('basementPerimeter');
const areaValidation = getValidation('basementArea');
const heightValidation = getValidation('basementHeight');
const depthValidation = getValidation('basementDepth');
const cornerValidation = getValidation('basementWallCorners');
const ponyHeightValidation = getValidation('basementPonyWallHeight');

const Basement = React.memo(({
    componentId,
    primaryUnits,
    imageUnits,
    area,
    perimeter,
    conversionFactor,
    change,
    expPerimeterUnits,
    heightUnits,
    depthUnits,
    ponyWallHeightUnits,
    isEnabled,
    fieldsWithLinesAttached,
    saveToState
}) => {

    const calculatedArea = parseFloat(convertUnit({
        value:area*Math.pow(conversionFactor, 2),
        type:'area',
        inputUnit:'m2',
        outputUnit:getBaseUnits('area', primaryUnits).trueBase
    }).toFixed(2));

    const calculatedPerimeter = parseFloat(convertUnit({
        value:perimeter*conversionFactor,
        type:'length',
        inputUnit:'m',
        outputUnit:getBaseUnits('perimeter',primaryUnits).trueBase
    }).toFixed(2));

    return (
        <div>
            <Field
                className={classes.inputMarginBottom}
                component={Input}
                type="text"
                label="Basement Name*"
                name={`components.${componentId}.label`}
                validate={[required, charLim100]}
                placeholder="Example: Basement"
                onChange={saveToState}
            />
             <Field
                className={classes.inputMarginBottom}
                component={InputWithUnits}
                type="number"
                name={`components.${componentId}.floor.measurements.perimeter`}
                label="Floor Perimeter"
                placeholder="0.00"
                validate={perimeterValidation}
                decimals={getDecimalPlaces('basementPerimeter')}
                change={()=>''}
                disabled={true}
                setValue={calculatedPerimeter}
                units={{
                    base:getBaseUnits('basementPerimeter', primaryUnits),
                    options:[imageUnits],
                    selected:imageUnits,
                    unitType:'length',
                    accessor:`components.${componentId}.floor.measurements.perimeter_u`
                }}
                info="Calculated based on polygon shape and the set scale."
                onChange={saveToState}
                onUnitChange={saveToState}
            />
            <Field
                className={classes.inputMarginBottom}
                component={InputWithUnits}
                type="number"
                name={`components.${componentId}.floor.measurements.area`}
                label="Floor Area"
                placeholder="0.00"
                validate={areaValidation}
                decimals={getDecimalPlaces('basementArea')}
                change={()=>''}
                disabled={true}
                setValue={calculatedArea}
                units={{
                    base:getBaseUnits('basementArea', primaryUnits),
                    options:[`${imageUnits}2`],
                    selected:`${imageUnits}2`,
                    unitType:'area',
                    accessor:`components.${componentId}.floor.measurements.area_u`
                }}
                info="Calculated based on polygon shape and the set scale."
                onChange={saveToState}
                onUnitChange={saveToState}
            />
            <Field
                className={classes.inputMarginBottom}
                component={InputWithUnits}
                type="number"
                name={`components.${componentId}.exposedSurfacePerimeter`}
                label="Exposed Surfaces Perimeter"
                placeholder="0.00"
                validate={exposedSurfaceValidation}
                decimals={getDecimalPlaces('basementExpSurfacePerimeter')}
                change={change}
                units={{
                    base:getBaseUnits('basementExpSurfacePerimeter', primaryUnits),
                    options:getUnitOptions('length'),
                    selected:expPerimeterUnits,
                    unitType:'length',
                    accessor:`components.${componentId}.exposedSurfacePerimeter_u`
                }}
                info={"Used only when there are multiple foundations present. Enter the total perimeter of this foundation component that is not attached to any other foundation components."}
                onChange={saveToState}
                onUnitChange={saveToState}
            />
            <Field
                className={classes.inputMarginBottom}
                component={InputWithUnits}
                type="number"
                name={`components.${componentId}.wall.measurements.height`}
                label="Total Wall Height"
                placeholder="0.00"
                validate={heightValidation}
                decimals={getDecimalPlaces('basementHeight')}
                change={change}
                disabled={fieldsWithLinesAttached.includes('basementHeight')}
                isDrawing={fieldsWithLinesAttached.includes('basementHeight')}
                isDrawingTip="This field is associated with a Line in the Drawing Capture. To detach, delete the Line or use it for another field."
                units={{
                    base:getBaseUnits('basementHeight', primaryUnits),
                    options:getUnitOptions('length'),
                    selected:heightUnits,
                    unitType:'length',
                    accessor:`components.${componentId}.wall.measurements.height_u`
                }}
                onChange={saveToState}
                onUnitChange={saveToState}
            />
            <Field
                className={classes.inputMarginBottom}
                component={InputWithUnits}
                type="number"
                name={`components.${componentId}.wall.measurements.depth`}
                label="Wall Depth Below Grade"
                placeholder="0.00"
                validate={depthValidation}
                decimals={getDecimalPlaces('basementDepth')}
                disabled={fieldsWithLinesAttached.includes('basementDepth')}
                isDrawing={fieldsWithLinesAttached.includes('basementDepth')}
                isDrawingTip="This field is associated with a Line in the Drawing Capture. To detach, delete the Line or use it for another field."
                change={change}
                units={{
                    base:getBaseUnits('basementDepth', primaryUnits),
                    options:getUnitOptions('length'),
                    selected:depthUnits,
                    unitType:'length',
                    accessor:`components.${componentId}.wall.measurements.depth_u`
                }}
                onChange={saveToState}
                onUnitChange={saveToState}
            />
            <Field
                className={classes.inputMarginBottom}
                component={Input}
                type="number"
                name={`components.${componentId}.wall.measurements.corners`}
                label="Corners"
                placeholder="1"
                quantity
                validate={cornerValidation}
                onChange={saveToState}
            />
            <Field
                className={classes.inputMarginBottom}
                component={Checkbox}
                name={`components.${componentId}.wall.ponyWall.enabled`}
                label="Enable Pony Wall"
                type="checkbox"
                onChange={saveToState}
            />
            {
                isEnabled &&
                    <Field
                        className={classes.inputMarginBottom}
                        component={InputWithUnits}
                        type="number"
                        name={`components.${componentId}.wall.ponyWall.ponyWallHeight`}
                        label="Pony Wall Height"
                        placeholder="0.00"
                        validate={ponyHeightValidation}
                        decimals={getDecimalPlaces('basementPonyWallHeight')}
                        disabled={fieldsWithLinesAttached.includes('basementPonyWallHeight') || !isEnabled}
                        isDrawing={fieldsWithLinesAttached.includes('basementPonyWallHeight')}
                        isDrawingTip="This field is associated with a Line in the Drawing Capture. To detach, delete the Line or use it for another field."
                        change={change}
                        units={{
                            base:getBaseUnits('basementPonyWallHeight', primaryUnits),
                            options:getUnitOptions('length'),
                            selected:ponyWallHeightUnits,
                            unitType:'length',
                            accessor:`components.${componentId}.wall.ponyWall.ponyWallHeight_u`
                        }}
                        onChange={saveToState}
                        onUnitChange={saveToState}
                    />
            }
        </div>
    )
}, isEqual);

export default Basement;