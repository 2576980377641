import { resolveObj } from "features/Model/_ducks/selectors";
import { createSelector } from "reselect";

export const formSelector = ({ form = {} }) => form;
const modelForm = createSelector([formSelector], ({ model = {} }) => model);

export const modelFormSelector = (state) => (path) => {
    const { values = {} } = modelForm(state);
    return !path ? "" : resolveObj(path, values);
};
