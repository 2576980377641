import { extractResultValues, getBarGraphResultsData } from ".";

export const baseDefault = {
    value: "base",
    content: "Base Value",
    upgradeSet: true,
};

export const buildRow = ({ id, value, content, hideRows, hiddenRows = {}, columns = {}, className }) => ({
    id,
    house: {
        value,
        content: content,
        // verticalAlign: "top",
    },
    ...columns,
});

export const buildUpgradeCell = ({ empty, componentsRemaining, upgrades = [] }) => {
    return {
        content: upgrades.map(({ displayLabel, upgradeId, cost, onChangeCost, upgradeType }, ind) => ({
            displayLabel,
            empty,
        })),
    };
};

export const buildGraphContentHeadings = (headings) => {
    return {
        house: {
            content: headings.map(({ value, color }, ind) => ({
                label: value,
                backgroundColor: color,
            })),
        },
    };
};

export const buildUpgradeRow = ({
    id: rowKey,
    value,
    upgradePackages = [],
    allPackageResults = {},
    packageResults = {},
    rowIndex = null,
    handleSimulate = null,
    fuelPrices = {},
    packageUiSpecs = {},
}) => {
    return {
        id: rowKey,
        house: {
            content: value,
        },
        base: {
            content: extractResultValues({
                allPackageResults,
                targetPackageId: "base",
                rowKey,
                fuelPrices,
                packageUiSpecs,
                isBaseColumn: true,
            }),
        },
        ...upgradePackages.reduce((obj, upgradePackage) => {
            const { id: upgradeId } = upgradePackage;
            const { metadata: { completedAt } = {} } = packageResults[upgradeId] || {};
            const getContent = () => {
                return extractResultValues({
                    allPackageResults,
                    targetPackageId: upgradeId,
                    rowKey,
                    fuelPrices,
                    upgradePackage,
                    packageUiSpecs,
                });
            };

            return {
                ...obj,
                [upgradeId]: {
                    content: getContent(),
                },
            };
        }, {}),
    };
};

export const buildBars = (data, maxValue) => {
    //We precalculate max value so that all the graphs in the row have the same scale
    // const maxValue = Math.max(...data.map((o) => o.amount));

    return data.map(({ id, amount, label, color }) => ({
        backgroundColor: color,
        width: `${(amount / maxValue) * 100}%`,
        label,
    }));
};

export const buildHorizontalBarGraph = (
    simulationEnergyUseHeadings,
    upgradePackages,
    packageResults,
    packageUiSpecs
) => {
    const barData = {
        base: simulationEnergyUseHeadings.map(({ id, color }) => ({
            id,
            color,
            ...getBarGraphResultsData(packageResults?.base, id, "base", packageUiSpecs?.base),
        })),
        ...upgradePackages.reduce((obj, { id: upgradeId }) => {
            return {
                ...obj,
                [upgradeId]: simulationEnergyUseHeadings.map(({ id: headingId, color }) => ({
                    id: headingId,
                    color,
                    ...getBarGraphResultsData(
                        packageResults[upgradeId],
                        headingId,
                        "upgrade",
                        packageUiSpecs?.[upgradeId]
                    ),
                })),
            };
        }, {}),
    };

    //precalculate maxValue so charts have the same scale
    const maxValue = []
        .concat(...Object.values(barData))
        .reduce((max, { amount }) => (parseFloat(amount) > max ? parseFloat(amount) : max), 0);

    return {
        base: {
            content: buildBars(barData.base, maxValue),
        },
        ...upgradePackages.reduce((obj, { id: upgradeId, lastEdited }) => {
            const { metadata: { completedAt } = {} } = packageResults[upgradeId] || {};
            return {
                ...obj,
                [upgradeId]: {
                    content: buildBars(barData[upgradeId], maxValue),
                },
            };
        }, {}),
    };
};

export const buildGraphRows = (energyEndUseGraphs) => {
    let rows = [];

    for (let index = 0; index < energyEndUseGraphs["house"].content.length; index++) {
        const val = energyEndUseGraphs["house"].content[index];
        let objToPush = {};

        for (let j = 0; j < Object.keys(energyEndUseGraphs).length; j++) {
            const key = Object.keys(energyEndUseGraphs)[j];

            objToPush = {
                ...objToPush,
                [key]: {
                    backgroundColor: energyEndUseGraphs[key].content[index].backgroundColor,
                    width: energyEndUseGraphs[key].content[index].width,
                    label: energyEndUseGraphs[key].content[index].label,
                },
            };
        }

        rows.push(objToPush);
    }

    return rows;
};
