import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import RoofMountedPVReady from "./";

const mapStateToProps = ({ form }, { accessor, formName = "model" }) => {
    const selector = formValueSelector(formName);

    const roofSections = selector({ form }, `${accessor}.roofSectionSpecs.sections`) || {};

    const modelUnits = selector({ form }, `modelData.uiSettings.primaryUnits`) || {};

    const workspaceAttic = selector({ form }, `${accessor}.installTermConduits.workspace.atticTermination`) || null;
    const workspacePanel = selector({ form }, `${accessor}.installTermConduits.workspace.panelTermination`) || null;
    const currentDeclAddress = selector({ form }, `${accessor}.declaration.address`) || "";

    return {
        accessor,
        formName,
        roofSections,
        modelUnits,
        workspaceAttic,
        workspacePanel,
        currentDeclAddress,
    };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(RoofMountedPVReady);
