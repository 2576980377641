import { isEmpty } from "lodash";
import { getBaseUnits } from "utils/fields";
import convertUnit from "utils/convertUnit";

export const getVentilationData = ({ ventilation, modelUnits }) => {
    const a1 = "Measurement System";
    const b1 = modelUnits;
    const whVentilationArray = Object.entries(ventilation.wholeHouseSystems);
    const suppVentilationArray = Object.entries(ventilation.supplementalSystems);

    const whVentilators = whVentilationArray
        .filter((system) => {
            return system[0] !== "hrv";
        })
        .reduce((acc, ventData) => {
            return ventData[0] === "ventilators" ? [...acc, ...Object.values(ventData[1])] : [...acc, ventData[1]];
        }, []);

    const suppVentilators = suppVentilationArray
        .filter((system) => {
            return system[0] !== "hrv";
        })
        .reduce((acc, ventData) => {
            return ventData[0] === "ventilators" ? [...acc, ...Object.values(ventData[1])] : [...acc, ventData[1]];
        }, []);

    let whVentilatorsResults = whVentilators.map((vent) => {
        return {
            ventData: vent,
            componentType: vent.label || "Dryer",
            supplyFlowrate: convertUnit({
                value: vent.supplyFlowrate,
                type: "airFlow",
                inputUnit: getBaseUnits("ventSupply", modelUnits).trueBase,
                outputUnit: getBaseUnits("ventSupply", modelUnits).displayBase,
            }).toFixed(2),
            exhaustFlowrate: convertUnit({
                value: vent.exhaustFlowrate,
                type: "airFlow",
                inputUnit: getBaseUnits("ventExhaust", modelUnits).trueBase,
                outputUnit: getBaseUnits("ventExhaust", modelUnits).displayBase,
            }).toFixed(2),
            fanPower: vent.fanPower1,
            manufacturer: vent.manufacturer || "N/A",
            model: vent.model || "N/A",
            isEnergyStar: vent.isEnergyStar,
            isHVICertified: vent.isHVICertified,
        };
    });

    let suppVentilatorsResults = suppVentilators.map((vent) => {
        return {
            ventData: vent,
            componentType: vent.label || "Dryer",
            supplyFlowrate: convertUnit({
                value: vent.supplyFlowrate,
                type: "airFlow",
                inputUnit: getBaseUnits("ventSupply", modelUnits).trueBase,
                outputUnit: getBaseUnits("ventSupply", modelUnits).displayBase,
            }).toFixed(2),
            exhaustFlowrate: convertUnit({
                value: vent.exhaustFlowrate,
                type: "airFlow",
                inputUnit: getBaseUnits("ventExhaust", modelUnits).trueBase,
                outputUnit: getBaseUnits("ventExhaust", modelUnits).displayBase,
            }).toFixed(2),
            fanPower: vent.fanPower1,
            manufacturer: vent.manufacturer || "N/A",
            model: vent.model || "N/A",
            isEnergyStar: vent.isEnergyStar,
            isHVICertified: vent.isHVICertified,
        };
    });

    const whHRV = whVentilationArray
        .filter((system) => {
            return system[0] === "hrv";
        })
        .reduce((acc, ventData) => {
            return ventData[1];
        }, []);

    const suppHRV = suppVentilationArray
        .filter((system) => {
            return system[0] === "hrv";
        })
        .reduce((acc, ventData) => {
            return ventData[1];
        }, []);

    const whHRVResults = !isEmpty(whHRV)
        ? {
              componentType: "HRV",
              supplyFlowrate: convertUnit({
                  value: whHRV.supplyFlowrate,
                  type: "airFlow",
                  inputUnit: getBaseUnits("hrvSupply", modelUnits).trueBase,
                  outputUnit: getBaseUnits("hrvSupply", modelUnits).displayBase,
              }).toFixed(2),
              exhaustFlowrate: convertUnit({
                  value: whHRV.exhaustFlowrate,
                  type: "airFlow",
                  inputUnit: getBaseUnits("hrvExhaust", modelUnits).trueBase,
                  outputUnit: getBaseUnits("hrvExhaust", modelUnits).displayBase,
              }).toFixed(2),
              fanPower: "N/A",
              manufacturer: whHRV.manufacturer || "N/A",
              model: whHRV.model || "N/A",
              isEnergyStar: whHRV.isEnergyStar,
              isHVICertified: whHRV.isHVICertified,
              fanPower1: whHRV.fanPower1 || "",
              efficiency1: whHRV.efficiency1 || "",
              fanPower2: whHRV.fanPower2 || "",
              efficiency2: whHRV.efficiency2 || "",
          }
        : {};

    whVentilatorsResults = !isEmpty(whHRV) ? [whHRVResults, ...whVentilatorsResults] : whVentilatorsResults;

    const suppHRVResults = !isEmpty(suppHRV)
        ? {
              componentType: "HRV",
              supplyFlowrate: convertUnit({
                  value: suppHRV.supplyFlowrate,
                  type: "airFlow",
                  inputUnit: getBaseUnits("hrvSupply", modelUnits).trueBase,
                  outputUnit: getBaseUnits("hrvSupply", modelUnits).displayBase,
              }).toFixed(2),
              exhaustFlowrate: convertUnit({
                  value: suppHRV.exhaustFlowrate,
                  type: "airFlow",
                  inputUnit: getBaseUnits("hrvExhaust", modelUnits).trueBase,
                  outputUnit: getBaseUnits("hrvExhaust", modelUnits).displayBase,
              }).toFixed(2),
              fanPower: "N/A",
              manufacturer: suppHRV.manufacturer || "N/A",
              model: suppHRV.model || "N/A",
              isEnergyStar: suppHRV.isEnergyStar,
              isHVICertified: suppHRV.isHVICertified,
              fanPower1: suppHRV.fanPower1 || "",
              efficiency1: suppHRV.efficiency1 || "",
              fanPower2: suppHRV.fanPower2 || "",
              efficiency2: suppHRV.efficiency2 || "",
          }
        : {};

    suppVentilatorsResults = !isEmpty(suppHRV) ? [suppHRVResults, ...suppVentilatorsResults] : suppVentilatorsResults;

    let whSystemList = [`${b1}`, ..."CDEFGHIJKLMNOPQRSTUVWXYZ".split("")];
    whSystemList = whSystemList.slice(0, whVentilatorsResults.length);

    let suppSystemList = [`${b1}`, ..."CDEFGHIJKLMNOPQRSTUVWXYZ".split("")];
    suppSystemList = suppSystemList.slice(0, suppVentilatorsResults.length);

    const whVentilatorObjectData = whSystemList.map((system, ind) => {
        const vent = whVentilatorsResults[ind];
        const isHRV = vent.componentType === "HRV";
        return [
            { [`${a1}`]: "Component Type", [`${system}`]: vent.componentType },
            { [`${a1}`]: "Manfacturer", [`${system}`]: vent.manufacturer },
            { [`${a1}`]: "Model", [`${system}`]: vent.model },
            {
                [`${a1}`]: `Supply flow rate (${getBaseUnits("ventSupply", modelUnits).displayBase})`,
                [`${system}`]: vent.supplyFlowrate,
            },
            {
                [`${a1}`]: `Exhaust flow rate (${getBaseUnits("ventExhaust", modelUnits).displayBase})`,
                [`${system}`]: vent.exhaustFlowrate,
            },
            { [`${a1}`]: "Vent. Fan Power (W)", [`${system}`]: vent.fanPower },

            { [`${a1}`]: "ENERGY STAR certified", [`${system}`]: vent.isEnergyStar },
            { [`${a1}`]: "HVI qualified", [`${system}`]: vent.isHVICertified },
            { [`${a1}`]: "HRV Fan power at 0°C / 32°F (W)", [`${system}`]: isHRV ? vent.fanPower1 : "" },
            { [`${a1}`]: "HRV Sensible efficiency at 0°C / 32°F", [`${system}`]: isHRV ? vent.efficiency1 : "" },
            { [`${a1}`]: "HRV Fan power at -25°C / -13°F (W)", [`${system}`]: isHRV ? vent.fanPower2 : "" },
            { [`${a1}`]: "HRV Sensible efficiency at -25°C / -13°F", [`${system}`]: isHRV ? vent.efficiency2 : "" },
        ];
    });

    const suppVentilatorObjectData = !isEmpty(suppVentilatorsResults)
        ? suppSystemList.map((system, ind) => {
              const vent = suppVentilatorsResults[ind];
              const isHRV = vent.componentType === "HRV";
              return [
                  { [`${a1}`]: "Component Type", [`${system}`]: vent.componentType },
                  { [`${a1}`]: "Manufacturer", [`${system}`]: vent.manufacturer },
                  { [`${a1}`]: "Model", [`${system}`]: vent.model },
                  {
                      [`${a1}`]: `Supply flow rate (${getBaseUnits("ventSupply", modelUnits).displayBase})`,
                      [`${system}`]: vent.supplyFlowrate,
                  },
                  {
                      [`${a1}`]: `Exhaust flow rate (${getBaseUnits("ventExhaust", modelUnits).displayBase})`,
                      [`${system}`]: vent.exhaustFlowrate,
                  },
                  { [`${a1}`]: "Vent. Fan Power (W)", [`${system}`]: vent.fanPower },

                  { [`${a1}`]: "ENERGY STAR certified", [`${system}`]: vent.isEnergyStar },
                  { [`${a1}`]: "HVI qualified", [`${system}`]: vent.isHVICertified },
                  { [`${a1}`]: "HRV Fan power at 0°C / 32°F", [`${system}`]: isHRV ? vent.fanPower1 : "" } || "",
                  {
                      [`${a1}`]: "HRV Sensible efficiency at 0°C / 32°F",
                      [`${system}`]: isHRV ? vent.efficiency1 : "",
                  } || "",
                  { [`${a1}`]: "HRV Fan power at -25°C / -13°F", [`${system}`]: isHRV ? vent.fanPower2 : "" } || "",
                  {
                      [`${a1}`]: "HRV Sensible efficiency at -25°C / -13°F",
                      [`${system}`]: isHRV ? vent.efficiency2 : "",
                  } || "",
              ];
          })
        : {};

    const itemArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    const whVentilatorData = !isEmpty(whVentilatorsResults)
        ? itemArray.map((objectData, ind) => {
              return whVentilatorObjectData.reduce((acc, item) => {
                  return { ...acc, ...item[ind] };
              }, {});
          })
        : [
              { [`${a1}`]: "Component Type", [`${b1}`]: "" },
              { [`${a1}`]: "Manufacturer", [`${b1}`]: "" },
              { [`${a1}`]: "Model", [`${b1}`]: "" },
              { [`${a1}`]: `Supply flow rate (${getBaseUnits("ventSupply", modelUnits).displayBase})`, [`${b1}`]: "" },
              {
                  [`${a1}`]: `Exhaust flow rate (${getBaseUnits("ventExhaust", modelUnits).displayBase})`,
                  [`${b1}`]: "",
              },
              { [`${a1}`]: "Vent. Fan Power (W)", [`${b1}`]: "" },
              { [`${a1}`]: "ENERGY STAR certified", [`${b1}`]: "" },
              { [`${a1}`]: "HVI qualified", [`${b1}`]: "" },
              { [`${a1}`]: "HRV Fan power at 0°C / 32°F", [`${b1}`]: "" },
              { [`${a1}`]: "HRV Sensible efficiency at 0°C / 32°F", [`${b1}`]: "" },
              { [`${a1}`]: "HRV Fan power at -25°C / -13°F", [`${b1}`]: "" },
              { [`${a1}`]: "HRV Sensible efficiency at -25°C / -13°F", [`${b1}`]: "" },
          ];

    const suppVentilatorData = itemArray.map((objectData, ind) => {
        return suppVentilatorObjectData.reduce((acc, item) => {
            return { ...acc, ...item[ind] };
        }, {});
    });

    const whSystemsRow = whSystemList.reduce((acc, system, ind) => {
        return { ...acc, [`${system}`]: `System ${ind + 1}` };
    }, {});

    const suppSystemsRow = suppSystemList.reduce((acc, system, ind) => {
        return { ...acc, [`${system}`]: `System ${ind + 1}` };
    }, {});

    const spacing = [
        {
            [`${a1}`]: "---",
        },
        {
            [`${a1}`]: "---",
        },
        {
            [`${a1}`]: "---",
        },
    ];

    return [
        // whole house
        ...spacing,
        {
            [`${a1}`]: "Whole House Ventilation",
            ...whSystemsRow,
            // # of systems per ventilator function
        },
        ...whVentilatorData,
        ...spacing,
        {
            [`${a1}`]: "Supplemental Ventilation",
            ...suppSystemsRow,
            // # of systems per ventilator function
        },
        ...suppVentilatorData,
    ];
};
