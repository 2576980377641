import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import FansAndPumps from './';

const mapStateToProps = (
    {
        form
    },
    {
        accessor,
        formName="model"
    }
) => {
    const selector = formValueSelector(formName);
    const modelSelector = formValueSelector('model');
    
    const modelUnits = modelSelector({form}, `modelData.uiSettings.primaryUnits`) || {};

    const currentPrimarySystem = Object.keys(selector({form}, `${accessor}.system`) || [])[0];

    const { id: capacityId } = selector({form}, `${accessor}.system.${currentPrimarySystem}.specifications.outputCapacity`) || {};
    const isHeatingCapCalculated = capacityId === 1;

    const isFanPowerCalculated = selector({form}, `${accessor}.fansAndPumps.power.isCalculated`);
    const { id: fanPowerModeId } = selector({form}, `${accessor}.fansAndPumps.mode`) || {};

    const highPowerUnits = selector({form}, `${accessor}.fansAndPumps.power.high_u`);
    const lowPowerUnits = selector({form}, `${accessor}.fansAndPumps.power.low_u`);

    return {
        accessor,
        currentPrimarySystem,
        isHeatingCapCalculated,
        isFanPowerCalculated,
        fanPowerModeId,
        highPowerUnits,
        lowPowerUnits,
        modelUnits
    }
};

const mapDispatchToProps = () => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FansAndPumps);