import React from "react";
import classes from "../style.module.scss";
import { CloseDialog } from "components/Dialog";
import Button from "components/Button";
import Dialog from "components/Dialog";
import Input from "components/Input";

const DuplicateDialog = ({
    itemType,
    itemToDuplicate,
    newDuplicateName,
    setDuplicateName,
    onClick,
    close,
    duplicateLoading,
}) => {
    const text = itemType === "Model" ?
        {
            title: "Duplicate Model",
            label: "Model Name",
            placeholder: "Type model name..."
        } : {
            title: "Duplicate Community",
            label: "Community Name",
            placeholder: "Type community name..."
        };

    return (
        <Dialog open={!!itemToDuplicate}>
            <CloseDialog closeActions={() => close()} />
            <div className={classes.dialog}>
                <h2> {text.title} </h2>
                <Input
                    label={text.label}
                    placeholder={text.placeholder}
                    disabled={duplicateLoading}
                    input={{
                        value: newDuplicateName,
                        onChange: (value) => setDuplicateName(value),
                    }}
                />
                <div className={classes.footer}>
                    <Button type="hollow" onClick={() => close()}>
                        Cancel
                    </Button>
                    <Button onClick={onClick} type="" disabled={newDuplicateName === ""}>
                        {duplicateLoading ? "Duplicating..." : "Duplicate"}
                    </Button>
                </div>
            </div>
        </Dialog>
    )
};

export default DuplicateDialog;
