import React from "react";

import { tagsTypes } from "./tagsTypes";

import { tagContainer, tagText } from "./style.module.scss";

const ModelTags = ({ tag }) => (
    <div className={tagContainer} style={{ background: tagsTypes[tag].color }}>
        <span className={tagText}>{tagsTypes[tag].name}</span>
    </div>
);

export default ModelTags;
