import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import isEmpty from 'lodash/isEmpty';
import AddSystem from './';

const mapStateToProps = (
    {
        form
    },
    {
        accessor,
        formName="model"
    }
) => {
    const selector = formValueSelector(formName);
    const modelSelector = formValueSelector('model');

    const modelUnits = modelSelector({form}, `modelData.uiSettings.primaryUnits`) || {};
    const { id:buildingTypeId } = modelSelector({form}, `modelData.specifications.buildingType`) || {}; 
    
    const isMURB = buildingTypeId !== 0; 

    const hasRadiant = !isEmpty(selector({form}, `${accessor}.radiantHeating`) || {}); 
    const hasAdditionalOpenings = !isEmpty(selector({form}, `${accessor}.additionalOpenings`) || {}); 
    const hasMultipleSystems = !isEmpty(selector({form}, `${accessor}.multipleSystems.systems`) || {}); 
    const supplSystems = selector({form}, `${accessor}.supplSystems.systems`) || {}; 
    const hasSupplSystems = !isEmpty(supplSystems);
    const numSupplSystems = Object.keys(supplSystems).length;


    return {
        accessor,
        isMURB,
        hasRadiant,
        hasAdditionalOpenings,
        hasMultipleSystems,
        supplSystems,
        hasSupplSystems,
        numSupplSystems,
        modelUnits
    }
};

const mapDispatchToProps = () => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddSystem);