import React, { useState, useRef } from 'react';
import classes from './style.module.scss';
import { DropDown } from 'components/Input/Select';
import Add from 'assets/images/icons/JSX/Add';
import { useOutsideClickHook } from 'utils/outsideClick';
import Dialog, { CloseDialog } from 'components/Dialog';
import Input from 'components/Input';
import Button from 'components/Button';
import { getPvTemplate } from 'utils/mechanicals';
import { required, maxLength } from 'utils/fieldValidation';
import moment from 'moment';

const charLim32 = maxLength(32);

export default ({
    accessor,
    numSystems,
    change
}) => {
    const [open, toggleOpen] = useState(false);

    const ref = useOutsideClickHook(() => toggleOpen(false));

    const handleAddSystem = () => {
        change(`${accessor}.pvSystems.pvSystem${moment().format('YYYYMMDDHHmmssSS')}`, {
            ...getPvTemplate(),
            systemNumber: numSystems + 1
        });
    }
    
    return (
        <div className={classes.addComponent} ref={ref}>
            <div
                className={`${classes.addText} ${open && classes.open}`}
                onClick={()=>toggleOpen(!open)}
            >
                {numSystems < 8 ? 'Add Generation' : 'Solay PV System Limit Reached'} 
                {numSystems < 8 && <Add />}
            </div>
            {
                (numSystems < 8) && <DropDown
                    className={classes.dropDown}
                    options={[
                        {
                            value: 'pv',
                            label: 'Solar PV System'
                        }
                    ]}
                    open={open}
                    toggleOpen={toggleOpen}
                    onChange={()=>{
                        handleAddSystem();
                    }}
                    
                />
            }
        </div>
    )
}