import React, { useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectImageData, selectActiveComponent } from "../../_ducks/selector";
import { setMultiPointLine, setMessage } from "../../_ducks/actions";

import { selectOptionsForDropdown, foundationTypes, storeysTypes } from "../../_ducks/utils";

import IDGenerator from "features/Model/Enclosure/Takeoff/TakeoffData/IDGenerator";

import Button from "components/Button";
import Input from "components/Input";
import Select from "components/Input/Select";

import { cancelButton, multiPointLineButtons, saveButton, inputMarginBottom, buttonRow } from "../style.module.scss";

const SetMultiPointLine = ({
    cancel,
    onSet,
    imageData,
    image,
    activeComponent,
    foundation,
    storeys,
    deleteShape,
    setMultiPointLine,
    setMessage,
}) => {
    const { multiPointLines } = imageData[image];
    const activeMultiPoint = multiPointLines[activeComponent];

    const { id, foundationStorey = "", takeoffField = "" } = activeMultiPoint.takeoff || {};

    const totalPoints = activeMultiPoint?.points?.length / 2 || 0;

    const foundationStoreySelectArr = [
        foundation.compType,
        ...storeysTypes.filter((storey) => Object.keys(storeys).includes(storey)),
    ];

    const foundationStoreySelect = foundationStoreySelectArr.map((val, index) => ({
        value: val.toLowerCase(),
        label:
            index === 0
                ? `Foundation - ${val.charAt(0).toUpperCase() + val.slice(1)}`
                : `${val.charAt(0).toUpperCase() + val.slice(1)}${
                      index === foundationStoreySelectArr.length - 1 ? "" : " Storey"
                  }`,
    }));

    const takeoffFieldOptions =
        selectOptionsForDropdown({
            shapeType: "multiPointLine",
            foundation: foundationTypes.includes(foundationStorey) ? foundationStorey : "",
            storey: Object.keys(storeys).includes(foundationStorey) ? foundationStorey : "",
        }) || [];

    const withIdTakeoffFields = ["garage wall", "buffered wall perimeter"];

    const idGeneratorOption = {
        "garage wall": "gw",
        "buffered wall perimeter": "bf",
    };

    return (
        <div>
            {!activeMultiPoint?.isSet ? (
                <>
                    <p>
                        Click anywhere on the active page to place multi-point line's points. A multi-point line must
                        have at least 2 points.
                    </p>
                    <div className={multiPointLineButtons}>
                        <Button smallPadding onClick={cancel} type="red" className={cancelButton}>
                            Cancel
                        </Button>
                        <Button
                            smallPadding
                            onClick={onSet}
                            type="hollow"
                            className={saveButton}
                            disabled={totalPoints < 2}
                        >
                            Set Multi-point
                        </Button>
                    </div>
                </>
            ) : (
                <>
                    <div>
                        <Input
                            className={inputMarginBottom}
                            label={"Multi Point Line's Name"}
                            input={{
                                value: activeMultiPoint.name,
                                onChange: (e) =>
                                    setMultiPointLine(image, {
                                        ...activeMultiPoint,
                                        name: e,
                                        takeoff: {
                                            ...activeMultiPoint.takeoff,
                                            title: e,
                                        },
                                    }),
                            }}
                        />
                        <Select
                            className={inputMarginBottom}
                            label={"Select Foundation or Storey"}
                            options={foundationStoreySelect}
                            input={{
                                value: foundationStorey,
                                onChange: (e) =>
                                    setMultiPointLine(image, {
                                        ...activeMultiPoint,
                                        takeoff: {
                                            ...activeMultiPoint.takeoff,
                                            foundationStorey: e,
                                            takeoffField: "",
                                        },
                                    }),
                            }}
                        />
                        <Select
                            className={takeoffField === "window" && inputMarginBottom}
                            label={"Select Takeoff Field"}
                            disabled={!foundationStorey}
                            options={takeoffFieldOptions?.map((val) => ({
                                value: val.toLowerCase(),
                                label: val,
                            }))}
                            input={{
                                value: takeoffField,
                                onChange: (e) => {
                                    const newId = withIdTakeoffFields.includes(takeoffField)
                                        ? !id || !id.includes(idGeneratorOption[takeoffField])
                                            ? IDGenerator(idGeneratorOption[takeoffField])
                                            : id
                                        : "";

                                    setMultiPointLine(image, {
                                        ...activeMultiPoint,
                                        takeoff: {
                                            ...activeMultiPoint.takeoff,
                                            takeoffField: e,
                                            id: newId,
                                        },
                                    });
                                },
                            }}
                        />
                    </div>
                    <div className={`${buttonRow} `}>
                        <Button smallPadding onClick={deleteShape} type="red" className={cancelButton}>
                            Delete
                        </Button>

                        {/* <Button
                            smallPadding
                            onClick={() => {
                                const newId = withIdTakeoffFields.includes(takeoffField)
                                    ? !id || !id.includes(idGeneratorOption[takeoffField])
                                        ? IDGenerator(idGeneratorOption[takeoffField])
                                        : id
                                    : "";

                                setMultiPointLine(image, {
                                    ...activeMultiPoint,
                                    name: newName,
                                    takeoff: {
                                        ...multiPointTakeoff,
                                        id: newId,
                                    },
                                });

                                setMultiPointTakeoff({
                                    ...multiPointTakeoff,
                                    id: newId,
                                });

                                setMessage({
                                    message: "Multi Point Line was updated successfully",
                                    type: "success",
                                    anchorOrigin: {
                                        vertical: "top",
                                        horizontal: "right",
                                    },
                                    autoHideDuration: 2000,
                                    isOpen: true,
                                });
                            }}
                            className={saveButton}
                        >
                            Save Rectangle
                        </Button> */}
                    </div>
                </>
            )}
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    imageData: selectImageData,
    activeComponent: selectActiveComponent,
});

const mapDispatchToProps = (dispatch) => ({
    setMultiPointLine: (image, multiPoint) => dispatch(setMultiPointLine(image, multiPoint)),
    setMessage: ({ message, type, anchorOrigin, autoHideDuration, isOpen, direction }) =>
        dispatch(
            setMessage({
                message,
                type,
                anchorOrigin,
                autoHideDuration,
                isOpen,
                direction,
            })
        ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SetMultiPointLine);
