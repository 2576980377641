import moment from "moment";
import isEmpty from "lodash/isEmpty";

import { updateUpgrade } from "store/upgradeLibrary/actions";

import { getMatchCodeRefs, getMatchFloorHeaderCodeRefs } from "utils/enclosure/components";
import { getAllWindowAccessors, getMatchingWindowCodeRefs } from "utils/enclosure/windows";

const fieldCodeTypeMap = {
    Wall: "wallInsType",
    Window: "windowType",
    Floor: "expFloorInsType",
    FloorsAbove: "floorsAboveInsType",
    CeilingFlat: "ceilingInsType",
    Ceiling: "ceilingInsType",
    FloorHeader: "floorHeaderInsType",
    CrawlspaceWall: "crawlspaceWallInsType", //SPECIAL CASE also "ponyWallInsType"
    BasementWall: "intAddedInsType",
    Lintel: "lintelInsType", //This one is tricky, it can be in walls or basements, but both use lintelInsType
    FloorsAdded: "slabInsType", //NOT TESTED
};

const fieldPathCodeTypeMap = {
    Wall: "wallInsType",
    Window: "windowType",
    Floor: "expFloorInsType",
    FloorsAbove: "floor.floorsAboveInsType",
    CeilingFlat: "ceilingInsType",
    Ceiling: "ceilingInsType",
    FloorHeader: "floorHeaderInsType",
    CrawlspaceWall: "wall.crawlspaceWallInsType", //SPECIAL CASE also "ponyWallInsType"
    BasementWall: "wall.intAddedInsType",
    Lintel: "lintelInsType", //This one is tricky, it can be in walls or basements, but both use lintelInsType
    FloorsAdded: "floor.slabInsType", //NOT TESTED
};

export const standardCodeLogic = async ({
    componentCodeType,
    componentId,
    code,
    formInputName = "",
    codeType, // "S" or "U"
    isCodeLibrary = false,
    isEditing = false,
    editingCodeRef = "",
    dispatch,
    getRValue,
    modelFormChange,
    currentFormChange,
    fieldAccessor,
    components,
    modelCodes,
    addToLibrary = false,
    addToCodeLib,
    uid,
    deleteCode,
    updateUpgradeCodes,
    setAsModelDefault = false,
    selectedComponents = [],
    currentPackage = null,
    selectedUpgrade,
}) => {
    const { codeRef, value, nominalRValue = null, label, isLibCode, ...rest } = code;
    const codeTypeLabel = codeType === "U" ? "UserDefined" : "Standard";
    const multiComponentAllowed =
        ["Wall", "Ceiling", "CeilingFlat", "Floor", "FloorHeader"].includes(componentCodeType) && !isCodeLibrary;
    // ***************************************
    // 1. Create and change code ref
    // If we are updating a library code, either:
    // (isLibCode===TRUE && !isCodeLibrary) --> we're editing a library code outside of the code library
    // OR
    // (isEditing===TRUE) --> we're editing a code (we want the change decoupled from the rest of the model)
    // OR
    // (isEditing===TRUE && isCodeLibrary===TRUE) --> we're editing a code in the code library
    // Then we must create a new codeRef
    // ***************************************
    const newCodeRef = `${componentCodeType}-${codeType}-${moment().format("YYYYMMDDHHmmssSS")}`;
    const forceNewCodeRef = (isLibCode && !isCodeLibrary) || (isEditing && isCodeLibrary) || isEditing;
    const setCodeRef = forceNewCodeRef ? newCodeRef : editingCodeRef || newCodeRef;
    // ***************************************
    // 2. Fetch rValue, if applicable
    // ***************************************

    let rVal = 0.1;
    let nomRVal = 0.1;
    let warningType = "";

    const rValueComponent = isCodeLibrary ? "codeLib" : componentId;
    const rValueCalcfieldId = isCodeLibrary ? setCodeRef : formInputName;

    await dispatch(
        getRValue({
            codeString: value,
            codeType: componentCodeType,
            componentId: rValueComponent,
            fieldId: rValueCalcfieldId,
        })
    ).then(({ rVal: newRVal, warningType: warning }) => {
        rVal = Math.max(0.1, newRVal);
        nomRVal = Math.max(0.1, newRVal);
        warningType = warning;
    });

    // ***************************************
    // 3. If in model, do model things
    // ***************************************
    if (!isCodeLibrary) {
        // ***************************************
        // 3.1. Add code to model's codes
        // ***************************************

        const code = { ...rest, nominalRValue: nomRVal, label, value, codeRef: setCodeRef, warningType };
        //Add to upgrade codes if in upgrade library
        if (currentPackage && selectedUpgrade) {
            const currentUpgrade = currentPackage.upgrades[selectedUpgrade];

            dispatch(
                updateUpgrade(selectedUpgrade, {
                    ...currentUpgrade,
                    codes: currentUpgrade
                        ? { ...currentUpgrade.codes, [code.codeRef]: code }
                        : { [code.codeRef]: code },
                })
            );
        }

        modelFormChange(`modelData.codes.${setCodeRef}`, code);

        // ***************************************
        // 3.2. Update the type fields in the curent form
        // ***************************************
        const fieldValue = {
            codeLabel: label,
            codeRef: setCodeRef,
        };

        currentFormChange(fieldAccessor, fieldValue);
        currentFormChange(`${fieldAccessor}_effRVal`, nomRVal);
        currentFormChange(`${fieldAccessor}_nomRVal`, nomRVal);

        const compField = fieldPathCodeTypeMap[componentCodeType];
        if (setAsModelDefault) {
            let defaultValue = {};

            if (compField.includes(".")) {
                let first = compField.split(".")[0];
                let second = compField.split(".")[1];
                defaultValue = {
                    [first]: {
                        [second]: fieldValue,
                        [`${second}_nomRVal`]: nomRVal,
                        [`${second}_effRVal`]: nomRVal,
                    },
                };
            } else {
                defaultValue = {
                    [compField]: fieldValue,
                    [`${compField}_nomRVal`]: nomRVal,
                    [`${compField}_effRVal`]: nomRVal,
                };
            }

            modelFormChange(`modelData.defaultCodes.${componentCodeType}`, defaultValue);
        }

        if (!isEmpty(selectedComponents) && multiComponentAllowed) {
            selectedComponents.forEach((path) => {
                currentFormChange(`modelData.components.${path}.${compField}`, fieldValue);
                currentFormChange(`modelData.components.${path}.${compField}_effRVal`, nomRVal);
                currentFormChange(`modelData.components.${path}.${compField}_nomRVal`, nomRVal);
            });
        }

        //Add warning tag to field
        if (warningType === "compression") {
            currentFormChange(`${fieldAccessor}_warning`, "compression");
        } else {
            currentFormChange(`${fieldAccessor}_warning`, "");
        }

        // ***************************************
        // 3.3. CONDITIONAL - If editing, update components and upgrades with the same code ref
        // NEW: Nov 22, 2022 - We're changing this logic so that codes are only updated throughout the rest of the model
        // if we're editing a code library code
        // ***************************************
        // if (isEditing && !isCodeLibrary) { //OLD LOGIC WOULD UPDATE ALL CODE IN THE MODEL WHEN EDITING
        if (isEditing && isLibCode) {
            // We're editing a code library code, so update all other components in the model that are using that code
            // Components

            const accessorMatches = getMatchCodeRefs(components, setCodeRef, editingCodeRef);
            if (!isEmpty(accessorMatches)) {
                accessorMatches.forEach((path) => {
                    if (!path.includes(fieldAccessor)) {
                        modelFormChange(path, fieldValue);
                        modelFormChange(`${path}_effRVal`, nomRVal);
                        modelFormChange(`${path}_nomRVal`, nomRVal);

                        if (warningType === "compression") {
                            modelFormChange(`${path}_warning`, "compression");
                        } else {
                            modelFormChange(`${path}_warning`, "");
                        }
                    }
                });
            }

            // We should also update upgrades using this lib code
            // Upgrades
            const fieldName = fieldCodeTypeMap[componentCodeType];
            dispatch(
                updateUpgradeCodes({
                    oldCodeRef: editingCodeRef,
                    newCodeRef,
                    values: {
                        [`${fieldName}`]: fieldValue,
                        [`${fieldName}_nomRVal`]: nomRVal,
                        [`${fieldName}_effRVal`]: nomRVal,
                    },
                    fieldName,
                })
            );

            if (componentCodeType === "CrawlspaceWall") {
                //We must check for pony wall matches
                const fieldName = "ponyWallInsType";
                dispatch(
                    updateUpgradeCodes({
                        oldCodeRef: editingCodeRef,
                        newCodeRef,
                        values: {
                            [`${fieldName}`]: fieldValue,
                            [`${fieldName}_nomRVal`]: nomRVal,
                            [`${fieldName}_effRVal`]: nomRVal,
                        },
                        fieldName,
                    })
                );
            }
        }

        // ***************************************
        // 3.4. CONDITIONAL - Delete pre-edited library code from model codes
        // April 2024 update: This was causing problems, and there are too many risks associated with deleting codes in this way
        // ***************************************
        if (forceNewCodeRef && addToLibrary) {
            // const otherComponentsWithCode =
            //     getMatchCodeRefs(components, setCodeRef, editingCodeRef).filter(
            //         (path) => !path.includes(fieldAccessor)
            //     ) || [];
            // //If isLibCode == undefined AND there are other components in the model using that code, do NOT remove the code from the model.
            // // if (!(!isEmpty(otherComponentsWithCode) && isLibCode == null)) {
            // if (isEmpty(otherComponentsWithCode) || isLibCode) {
            //     // console.log("editingCodeRef was removed from the model", otherComponentsWithCode, isLibCode);
            //     modelFormChange("modelData.codes", {
            //         ...Object.keys(modelCodes).reduce((acc, curr) => {
            //             return curr === editingCodeRef ? acc : { ...acc, [curr]: modelCodes[curr] };
            //         }, {}),
            //         [setCodeRef]: code,
            //     });
            // } else {
            //     // console.log("not deleting editingCodeRef", isEmpty(otherComponentsWithCode), isLibCode);
            // }
            // modelFormChange("modelData.codes", {
            //     ...Object.keys(modelCodes).reduce((acc, curr) => {
            //         return curr === editingCodeRef ? acc : { ...acc, [curr]: modelCodes[curr] };
            //     }, {}),
            //     [setCodeRef]: code,
            // });
        }
    }

    // ***************************************
    // 4. If in code library or "add to code library" is selected, do code library things
    // ***************************************
    if (addToLibrary || isCodeLibrary) {
        await dispatch(
            addToCodeLib(
                uid,
                {
                    ...rest,
                    nominalRValue: nomRVal,
                    label,
                    codeType: codeTypeLabel,
                    componentType: componentCodeType,
                    value,
                    warningType,
                    codeRef: setCodeRef,
                },
                componentCodeType,
                setCodeRef //Overwrite codeRef if needed here
            )
        )
            .then(() => {
                //If we're overwriting an existing code, delete the old one from the library
                if (forceNewCodeRef) {
                    dispatch(deleteCode(uid, editingCodeRef, componentCodeType));
                }
            })
            .catch(() => {});
    }
};

export const windowCodeLogic = async ({
    componentCodeType = "Window",
    componentId,
    code,
    codeType, // "S" or "U"
    isCodeLibrary = false,
    isEditing = false,
    editingCodeRef = "",
    dispatch,
    getWindowRValue,
    modelFormChange,
    currentFormChange,
    fieldAccessor,
    components,
    modelCodes,
    addToLibrary = false,
    addToCodeLib,
    uid,
    deleteCode,
    updateUpgradeCodes,
    setAsModelDefault = false,
    selectedComponents = [],
    currentPackage,
    selectedUpgrade,
}) => {
    const { codeRef, value, nominalRValue = null, label, isLibCode, ...rest } = code;
    const codeTypeLabel = codeType === "U" ? "UserDefined" : "Standard";

    //selectedComponents will look like: "wall.wall0.subcomponents.window.window0005"
    //fieldAccessor will look like: "modelData.components.wall.wall0.subcomponents.window.window0005"

    // ***************************************
    // 1. Create and change code ref
    // If we are updating a library code (either isLibCode===TRUE && !isCodeLibrary or isEditing===TRUE && isCodeLibrary===TRUE)
    // Then we must create a new codeRef
    // ***************************************
    const newCodeRef = `${componentCodeType}-${codeType}-${moment().format("YYYYMMDDHHmmssSS")}`;
    const forceNewCodeRef = (isLibCode && !isCodeLibrary) || (isEditing && isCodeLibrary) || isEditing;
    const setCodeRef = forceNewCodeRef ? newCodeRef : editingCodeRef || newCodeRef;

    // ***************************************
    // 2. Fetch rValue
    // ***************************************
    let rVal = 0;
    let windowEr = 0;
    let windowShgc = 0;
    const rValueComponent = isCodeLibrary ? "codeLib" : componentId;
    const rValueCalcfieldId = isCodeLibrary ? editingCodeRef || newCodeRef : "windowInsType";

    //Fetch rval, er, shgc
    await dispatch(
        getWindowRValue({
            codeString: value,
            codeType: componentCodeType,
            componentId: rValueComponent,
            fieldId: rValueCalcfieldId,
        })
    ).then(({ rVal: newRVal, windowEr: newWindowEr, windowShgc: newWindowShgc }) => {
        rVal = newRVal;
        windowEr = newWindowEr;
        windowShgc = newWindowShgc;
    });

    // ***************************************
    // 3. If in model, do model things
    // ***************************************
    if (!isCodeLibrary) {
        const code = {
            ...rest,
            nominalRValue: rVal,
            label,
            value,
            codeRef: setCodeRef,
            er: windowEr,
            shgc: windowShgc,
        };

        //Add to upgrade codes if in upgrade library
        if (currentPackage && selectedUpgrade) {
            const currentUpgrade = currentPackage.upgrades[selectedUpgrade];

            dispatch(
                updateUpgrade(selectedUpgrade, {
                    ...currentUpgrade,
                    codes: currentUpgrade
                        ? { ...currentUpgrade.codes, [code.codeRef]: code }
                        : { [code.codeRef]: code },
                })
            );
        }

        modelFormChange(`modelData.codes.${setCodeRef}`, code);

        const fieldValue = {
            codeLabel: label,
            codeRef: setCodeRef,
        };

        // Update model "window type" field in window
        currentFormChange(`${fieldAccessor}.windowType`, fieldValue);
        currentFormChange(`${fieldAccessor}.windowType_nomRVal`, rVal);
        currentFormChange(`${fieldAccessor}.windowType_effRVal`, rVal);
        currentFormChange(`${fieldAccessor}.er`, windowEr);
        currentFormChange(`${fieldAccessor}.shgc`, windowShgc);

        if (setAsModelDefault) {
            const defaultValue = {
                windowType: fieldValue,
                er: windowEr,
                shgc: windowShgc,
                windowType_nomRVal: rVal,
                windowType_effRVal: rVal,
            };
            modelFormChange("modelData.defaultCodes.Window", defaultValue);
        }

        if (!isEmpty(selectedComponents)) {
            selectedComponents.forEach((path) => {
                modelFormChange(`modelData.components.${path}.windowType`, fieldValue);
                modelFormChange(`modelData.components.${path}.windowType_effRVal`, rVal);
                modelFormChange(`modelData.components.${path}.windowType_nomRVal`, rVal);
                modelFormChange(`modelData.components.${path}.er`, windowEr);
                modelFormChange(`modelData.components.${path}.shgc`, windowShgc);
            });
        }

        // Nov 22, 2022 --> Changing this logic to align with how H2k handles editing codes (see standard codes logic)
        if (isEditing && isLibCode) {
            //Updating all codes in the model with the same codeRef if we're editing a code library code
            const accessorsToUpdate = getMatchingWindowCodeRefs(
                components,
                editingCodeRef,
                fieldAccessor.split("modelData.components.")[1]
            );
            if (!isEmpty(accessorsToUpdate)) {
                accessorsToUpdate.forEach((path) => {
                    modelFormChange(`${path}.windowType`, fieldValue);
                    modelFormChange(`${path}.windowType_effRVal`, rVal);
                    modelFormChange(`${path}.windowType_nomRVal`, rVal);
                    modelFormChange(`${path}.er`, windowEr);
                    modelFormChange(`${path}.shgc`, windowShgc);
                });
            }

            // Upgrades
            const fieldName = fieldCodeTypeMap[componentCodeType];
            dispatch(
                updateUpgradeCodes({
                    oldCodeRef: editingCodeRef,
                    newCodeRef,
                    values: {
                        [`${fieldName}`]: fieldValue,
                        [`${fieldName}_nomRVal`]: rVal,
                        [`${fieldName}_effRVal`]: rVal,
                        er: windowEr,
                        shgc: windowShgc,
                    },
                    fieldName,
                })
            );
        }

        if (forceNewCodeRef && addToLibrary) {
            //Must delete from modelCodes here at the end, after updating every other codeRef
            // const otherComponentsWithCode =
            //     getMatchingWindowCodeRefs(
            //         components,
            //         editingCodeRef,
            //         fieldAccessor.split("modelData.components.")[1]
            //     ).filter((path) => !path.includes(fieldAccessor.split("modelData.components.")[1])) || [];
            // if (isEmpty(otherComponentsWithCode) || isLibCode) {
            //     modelFormChange("modelData.codes", {
            //         ...Object.keys(modelCodes).reduce((acc, curr) => {
            //             return curr === editingCodeRef ? acc : { ...acc, [curr]: modelCodes[curr] };
            //         }, {}),
            //         [setCodeRef]: code,
            //     });
            // }
            // modelFormChange("modelData.codes", {
            //     ...Object.keys(modelCodes).reduce((acc, curr) => {
            //         return curr === editingCodeRef ? acc : { ...acc, [curr]: modelCodes[curr] };
            //     }, {}),
            //     [setCodeRef]: code,
            // });
        }

        // ***************************************
        // 4. If in code library or "add to code library" is selected, do code library things
        // ***************************************
        if (addToLibrary || isCodeLibrary) {
            await dispatch(
                addToCodeLib(
                    uid,
                    {
                        ...rest,
                        nominalRValue: rVal,
                        label,
                        description: label,
                        codeType: codeTypeLabel,
                        componentType: componentCodeType,
                        value,
                        er: windowEr,
                        shgc: windowShgc,
                        codeRef: setCodeRef,
                    },
                    componentCodeType,
                    setCodeRef
                )
            )
                .then(() => {
                    //If we're overwriting an existing code, delete the old one
                    if (forceNewCodeRef) {
                        dispatch(deleteCode(uid, editingCodeRef, componentCodeType));
                    }
                })
                .catch(() => {});
        }
    }

    // ***************************************
    // 4. If in code library or "add to code library" is selected, do code library things
    // ***************************************
    if (addToLibrary || isCodeLibrary) {
        await dispatch(
            addToCodeLib(
                uid,
                {
                    ...rest,
                    nominalRValue: rVal,
                    rVal,
                    windowEr,
                    windowShgc,
                    label,
                    codeType: codeTypeLabel,
                    componentType: componentCodeType,
                    value,
                    codeRef: setCodeRef,
                },
                componentCodeType,
                setCodeRef //Overwrite codeRef if needed here
            )
        )
            .then(() => {
                //If we're overwriting an existing code, delete the old one from the library
                if (forceNewCodeRef) {
                    dispatch(deleteCode(uid, editingCodeRef, componentCodeType));
                }
            })
            .catch(() => {});
    }
};

export const lintelCodeLogic = async ({
    componentCodeType,
    code,
    codeType, // "S" or "U"
    isCodeLibrary = false,
    isEditing = false,
    editingCodeRef = "",
    dispatch,
    modelFormChange,
    currentFormChange,
    fieldAccessor,
    components,
    modelCodes,
    addToLibrary = false,
    addToCodeLib,
    uid,
    deleteCode,
    updateUpgradeCodes,
    currentPackage,
    selectedUpgrade,
}) => {
    const { codeRef, value, nominalRValue = null, label, isLibCode, ...rest } = code;
    const codeTypeLabel = codeType === "U" ? "UserDefined" : "Standard";

    // ***************************************
    // 1. Create and change code ref
    // If we are updating a library code (either isLibCode===TRUE && !isCodeLibrary or isEditing===TRUE && isCodeLibrary===TRUE)
    // Then we must create a new codeRef
    // ***************************************
    const newCodeRef = `${componentCodeType}-${codeType}-${moment().format("YYYYMMDDHHmmssSS")}`;
    const forceNewCodeRef = (isLibCode && !isCodeLibrary) || (isEditing && isCodeLibrary) || isEditing;
    const setCodeRef = forceNewCodeRef ? newCodeRef : editingCodeRef || newCodeRef;

    // ***************************************
    // 2. If in model, do model things
    // ***************************************
    if (!isCodeLibrary) {
        const code = { ...rest, nominalRValue: 0, label, value, codeRef: setCodeRef };

        //Add to upgrade codes if in upgrade library
        if (currentPackage && selectedUpgrade) {
            const currentUpgrade = currentPackage.upgrades[selectedUpgrade];

            dispatch(
                updateUpgrade(selectedUpgrade, {
                    ...currentUpgrade,
                    codes: currentUpgrade
                        ? { ...currentUpgrade.codes, [code.codeRef]: code }
                        : { [code.codeRef]: code },
                })
            );
        }

        modelFormChange(`modelData.codes.${setCodeRef}`, code);

        const fieldValue = {
            codeLabel: label,
            codeRef: setCodeRef,
        };

        // Update model "lintel type" field in wall
        currentFormChange(fieldAccessor, fieldValue);

        //If it is a code library code we must update all other Walls with the same reference
        if (isEditing && isLibCode) {
            const accessorMatches = getMatchCodeRefs(components, setCodeRef, editingCodeRef);
            if (!isEmpty(accessorMatches)) {
                accessorMatches.forEach((path) => {
                    if (!path.includes(fieldAccessor)) {
                        modelFormChange(path, fieldValue);
                    }
                });
            }

            // Upgrades (LINTELS)
            const fieldName = fieldCodeTypeMap[componentCodeType];
            dispatch(
                updateUpgradeCodes({
                    oldCodeRef: editingCodeRef,
                    newCodeRef,
                    values: {
                        [`${fieldName}`]: fieldValue,
                    },
                    fieldName,
                })
            );
        }

        if (forceNewCodeRef && addToLibrary) {
            //Must delete from modelCodes here at the end, after updating every other codeRef
            // const otherComponentsWithCode =
            //     getMatchCodeRefs(components, setCodeRef, editingCodeRef).filter(
            //         (path) => !path.includes(fieldAccessor)
            //     ) || [];
            // if (isEmpty(otherComponentsWithCode) || isLibCode) {
            //     modelFormChange("modelData.codes", {
            //         ...Object.keys(modelCodes).reduce((acc, curr) => {
            //             return curr === editingCodeRef ? acc : { ...acc, [curr]: modelCodes[curr] };
            //         }, {}),
            //         [setCodeRef]: code,
            //     });
            // }
            // modelFormChange("modelData.codes", {
            //     ...Object.keys(modelCodes).reduce((acc, curr) => {
            //         return curr === editingCodeRef ? acc : { ...acc, [curr]: modelCodes[curr] };
            //     }, {}),
            //     [setCodeRef]: code,
            // });
        }
    }

    if (addToLibrary || isCodeLibrary) {
        await dispatch(
            addToCodeLib(
                uid,
                {
                    ...rest,
                    label,
                    nominalRValue: 0,
                    codeType: codeTypeLabel,
                    componentType: componentCodeType,
                    value,
                    codeRef: setCodeRef,
                },
                componentCodeType,
                setCodeRef
            )
        )
            .then(() => {
                //If we're overwriting an existing code, delete the old one
                if (forceNewCodeRef) {
                    dispatch(deleteCode(uid, editingCodeRef, componentCodeType));
                }
            })
            .catch(() => {});
    }
};
