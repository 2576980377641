import React, { useCallback, useEffect, useRef, useState } from "react";
import classes from "../style.module.scss";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { isEmpty } from "lodash";
import { DashboardTable } from "components/Dashboard/Table";
import Loading from "components/Loading";
import { actions as modelActions } from "features/Model/_ducks";
import { actions as enclosureActions } from "features/Model/Enclosure/_ducks";
import { actions as resultsActions } from "features/Model/Review/Results/_ducks";
import { actions as upgradeActions } from "features/Model/Upgrades/_ducks";
import { clearDrawingData } from "features/Model/DrawingNew/_ducks/actions";
import { fetchDrawingData } from "features/Model/DrawingNew/_ducks/thunk";
import MultiSelect from "components/Input/MultiSelect";
import FilterIcon from "assets/images/icons/Filter.svg";
import Button from "components/Button";
import { getProvName } from "utils/weather";
import Card from "components/Card";

const { clearModelData, fetchModelData, modelLoading, setExportErrors } = modelActions;
const { clearEnclosureState } = enclosureActions;
const { resetResults } = resultsActions;
const { clearUpgradeData } = upgradeActions;

const Models = ({ history, commModelDir = {}, selectModel }) => {
    const modelRef = useRef(null);
    const scrollContainerRef = useRef(null);
    const [selectedModel, setSelectedModel] = useState("");

    /* Loading state and cursor position for loading spinner */
    const [cursorPosition, setCursorPosition] = useState({ top: 0, left: 0 });
    const [isLoading, setIsLoading] = useState(false);

    const [unfilteredModels, setUnFilteredModels] = useState([]);
    const [filteredModels, setFilteredModels] = useState([]);

    // Options for filters
    const [locationOpts, setLocationOpts] = useState([]);
    const [userOpts, setUserOpts] = useState([]);
    const [modelOpts, setModelOpts] = useState([]);

    // List of selected filters
    const [selectedLocs, setSelectedLocs] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedModels, setSelectedModels] = useState([]);

    // Flag to determine if filters were updated
    const [newFilter, setNewFilter] = useState(false);

    // Get cursor position for loading spinner
    const onMouseMove = useCallback((e) => {
        const top = e.pageY - window.scrollY + 10;
        const left = e.pageX - window.scrollX + 10;

        setCursorPosition({ top, left });
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modelRef.current && !modelRef.current.contains(event.target)) {
                setSelectedModel("");
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const { models: singleModels = {} } = commModelDir;
        const unfiltered = !isEmpty(singleModels)
            ? Object.entries(singleModels)
                  .sort(([aKey, aValues], [bKey, bValues]) => aValues.name.localeCompare(bValues.name))
                  .map(([key, el]) => [key, { ...el, type: "model" }])
            : [];

        fetchFilterOptions(singleModels);
        setUnFilteredModels(unfiltered);
        setFilteredModels(unfiltered);
    }, [commModelDir]);

    // Alphabetical sort function for label
    const orderByLabel = (a, b) => a.label.localeCompare(b.label);

    const fetchFilterOptions = (allModels) => {
        let locations = [];
        let users = [];
        let models = [];

        Object.entries(allModels).forEach(([key, model]) => {
            if (!locations.includes(model.provTerr)) locations.push(model.provTerr);

            if (!users.includes(model.userName)) users.push(model.userName);

            models.push({ key, ...model });
        });

        setLocationOpts(
            locations
                .map((id) => ({
                    value: id,
                    label: getProvName(id).toUpperCase(),
                }))
                .sort(orderByLabel)
        );

        setUserOpts(
            users
                .map((fullName, index) => ({
                    value: `User${index}`, // user array index
                    label: fullName, // full user name
                }))
                .sort(orderByLabel)
        );

        setModelOpts(
            models
                .map((model) => ({
                    value: model.key, // model ID
                    label: model.name, // model name
                }))
                .sort(orderByLabel)
        );
    };

    // Clear filters
    const handleClear = () => {
        // Remove selected filters
        setSelectedLocs([]);
        setSelectedUsers([]);
        setSelectedModels([]);

        // Reset filtered models list and disable apply button
        setFilteredModels(unfilteredModels);
        setNewFilter(false);
    };

    // Apply filters
    const handleApply = () => {
        const filteredData = unfilteredModels.filter(([key, model]) => {
            const selectedUserNames = userOpts
                .filter((opt) => selectedUsers.includes(opt.value))
                .map((opt) => opt.label);
            const matchLocation = isEmpty(selectedLocs) || selectedLocs.includes(model.provTerr);
            const matchUser = isEmpty(selectedUsers) || selectedUserNames.includes(model.userName);
            const matchModel = isEmpty(selectedModels) || selectedModels.includes(key);
            return matchLocation && matchUser && matchModel;
        });

        // Update filtered models list and disable button after applying
        setFilteredModels(filteredData);
        setNewFilter(false);
    };

    // Update selected filter lists
    const handleChange = (type, value) => {
        if (type === "location") setSelectedLocs(value);
        else if (type === "user") setSelectedUsers(value);
        else if (type === "model") setSelectedModels(value);

        setNewFilter(true);
    };

    /* Selecting a Model */
    const isSelected = (id) => {
        return id === selectedModel;
    };

    const onClick = async (event, type, key, index) => {
        event.preventDefault();

        if (!selectedModel || selectedModel !== key) {
            setSelectedModel(key);
        }

        if (selectedModel && selectedModel === key && type === "model") {
            setIsLoading(true);
            await selectModel(key);
        }
    };

    return (
        <>
            <div
                className={classes.contentContainer}
                onMouseMove={onMouseMove}
                style={{ pointerEvents: isLoading ? "none" : "auto" }}
                ref={scrollContainerRef}
            >
                <div style={{ position: "fixed", pointerEvents: "none", ...cursorPosition }}>
                    {isLoading && <Loading className={classes.smallerSpinner} subClassName={classes.smallSpinner} />}
                </div>
                <div className={classes.filters}>
                    <img src={FilterIcon} alt="filter" />
                    <MultiSelect
                        hideLabel
                        dropDownWidth="max-content"
                        className={classes.test}
                        forcedLabel="Location"
                        options={locationOpts}
                        input={{
                            value: selectedLocs,
                            onChange: (value) => handleChange("location", value),
                        }}
                    />
                    <MultiSelect
                        hideLabel
                        dropDownWidth="max-content"
                        className={classes.test}
                        forcedLabel="User"
                        options={userOpts}
                        input={{
                            value: selectedUsers,
                            onChange: (value) => handleChange("user", value),
                        }}
                    />
                    <MultiSelect
                        hideLabel
                        dropDownWidth="max-content"
                        className={classes.test}
                        forcedLabel="Models"
                        options={modelOpts}
                        input={{
                            value: selectedModels,
                            onChange: (value) => handleChange("model", value),
                        }}
                    />
                    <Button small disabled={!newFilter} onClick={() => handleApply()}>
                        Apply
                    </Button>
                    <Button small type="white" onClick={handleClear}>
                        Clear
                    </Button>
                </div>
                <h2 className={classes.title}>House Models</h2>
                <DashboardTable
                    type="chbaRenoCommModelDir"
                    folderItemRef={modelRef}
                    allFoldersItems={filteredModels}
                    selection={{
                        onClick,
                        isSelected,
                    }}
                    actions={{}}
                    paginate
                />
                {isEmpty(filteredModels) && <Card>No community models match the filters you applied</Card>}
            </div>
        </>
    );
};

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = (dispatch, { history }) => ({
    selectModel: async (modelId) => {
        await Promise.all([
            dispatch(clearModelData()),
            dispatch(clearDrawingData()),
            dispatch(resetResults()),
            dispatch(clearUpgradeData()),
            dispatch(clearEnclosureState()),
            dispatch(setExportErrors([])),
            dispatch(modelLoading()),
            dispatch(fetchModelData(modelId)),
            dispatch(fetchDrawingData(modelId)),
        ]).then(() => history.push(`/models/${modelId}`));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(Models);
