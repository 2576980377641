import React, { useState, useEffect, useCallback } from "react";
import { DragOverlay } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { createPortal } from "react-dom";
import AddComponent from "../AddComponent";
import ComponentType from "./ComponentType";
import Droppable from "components/DragAndDrop/Droppable";

import { getAllowedCategories } from "utils/enclosure";

import classes from "./style.module.scss";

const NewComponentList = React.memo(
    ({
        name,
        change,
        newestComponent,
        catId,
        options,
        clearNewComponent,
        defaultParams,
        combinedView = false,
        componentsObject,
        catComponents,
        activeId,
        isDragging,
        allowedCategories,
        draggingType,
    }) => {
        const [items, setItems] = useState([]);

        useEffect(() => {
            if (!isDragging) {
                setItems(catComponents);
            }
        }, [isDragging, catComponents]);

        const lastComponentDisplayOrder = items.reduce(
            ({ displayOrder: prevDisplay = 0 }, { displayOrder: currentDisplay = 0 }) =>
                prevDisplay > currentDisplay ? prevDisplay : currentDisplay,
            0
        );

        const compIndex = items.findIndex(({ id }) => id === activeId);

        return (
            <>
                <div className={classes.storeyHeader}>
                    {/* The type of component, IE fountdations, first storeys */}
                    <h3>
                        {name} {combinedView && "HOT2000 Components"}
                    </h3>
                    <AddComponent
                        change={change}
                        catId={catId}
                        options={options}
                        newComponentDisplay={lastComponentDisplayOrder + 1}
                        defaultParams={defaultParams}
                        components={items}
                    />
                </div>

                <div
                    // droppableId={catId}
                    datatype="category"
                >
                    <SortableContext
                        items={items}
                        strategy={verticalListSortingStrategy}
                        disabled={!isDragging ? false : !allowedCategories.includes(catId)}
                        id={catId}
                    >
                        <div className={`${classes.componentList} `}>
                            {items.length === 0 &&
                                (allowedCategories.includes(catId) ? (
                                    <Droppable id={catId}>
                                        <p className={classes.noComponents}>
                                            <em key={catId}>No components to display.</em>
                                        </p>
                                    </Droppable>
                                ) : (
                                    <p className={classes.noComponents}>
                                        <em key={catId}>No components to display.</em>
                                    </p>
                                ))}
                            <div>
                                {items.map(({ id, componentId, componentType, componentLabel }, index) => {
                                    return (
                                        <ComponentType
                                            key={id}
                                            id={id}
                                            index={index + 1}
                                            activeId={activeId}
                                            componentId={componentId}
                                            type={componentType}
                                            change={change}
                                            isNewest={newestComponent === id}
                                            catId={catId}
                                            allowedCategories={allowedCategories}
                                            clearNewComponent={clearNewComponent}
                                            componentsObject={componentsObject}
                                            destination={""}
                                            activeIndex={compIndex}
                                            label={componentLabel}
                                            draggableId={`${componentType}.${id}`}
                                            typeDrop={"category"}
                                            isDragging={isDragging}
                                            draggingType={draggingType}
                                        />
                                    );
                                })}
                                {createPortal(
                                    <DragOverlay>
                                        {activeId && compIndex >= 0 ? (
                                            <div style={{ height: "52px" }}>
                                                <ComponentType
                                                    id={activeId}
                                                    activeId={activeId}
                                                    clone={true}
                                                    label={items[compIndex].componentLabel}
                                                    type={items[compIndex].componentType}
                                                    componentId={items[compIndex].componentId}
                                                    items={items}
                                                    allowedCategories={allowedCategories}
                                                    destination={{}}
                                                    isDragging={isDragging}
                                                    parentAccessor={""}
                                                    typeDrop={"category"}
                                                    draggingType={draggingType}
                                                    componentsObject={componentsObject}
                                                    change={change}
                                                />
                                            </div>
                                        ) : null}
                                    </DragOverlay>,
                                    document.body
                                )}
                            </div>
                        </div>
                    </SortableContext>
                </div>
            </>
        );
    }
);

export default NewComponentList;
