import React from "react";
import classes from "../style.module.scss";
import sharedClasses from "features/Model/sharedStyles.module.scss";
import { Field } from "redux-form";
import { required, maxLength, getValidation, getDecimalPlaces } from "utils/fieldValidation";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { getOptions, getFirstOption, getBaseUnits, getUnitOptions } from "utils/fields";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import InputWithUnits from "components/Input/InputWithUnits";
import Select from "components/Input/Select";
import SelectWithInput from "components/Input/SelectWithInput";
import Button from "components/Button";
import Checkbox from "components/Input/Checkbox";
import Accordion from "components/Accordion";

const lightingValidation = getValidation("ersReducedOpLighting");
const dryerValidation = getValidation("ersReducedOpDryer");
const washerValidation = getValidation("ersReducedOpWasher");
const dishWasherValidation = getValidation("ersReducedOpDishwasher");
const fridgeValidation = getValidation("ersReducedOpRefrigerator");
const rangeValidation = getValidation("ersReducedOpRange");

export default React.memo(
    ({ accessor, dryerUnits, washerUnits, dishWasherUnits, fridgeUnits, rangeUnits, modelUnits, change }) => {
        return (
            <div>
                <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                    <Field
                        component={Select}
                        name={`${accessor}.reducedLighting`}
                        options={getOptions({ fieldKey: "elecLightingDailyConsumption" })}
                        label="Types of Lighting Present"
                        placeholder="Choose Lighting"
                        validate={lightingValidation}
                    />
                    <Field
                        component={Checkbox}
                        name={`${accessor}.isEStarNewHomes`}
                        label="Energy Star for New Homes"
                        large
                        className={classes.bottomCheckBox}
                        type="checkbox"
                    />
                </InputRow>
                <h4 style={{ marginBottom: "1.25rem", marginTop: "1.75rem" }}>
                    Appliance Rated Annual Energy Consumption
                </h4>
                <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.appliances.dryer`}
                        label="Dryer"
                        placeholder={0.0}
                        validate={dryerValidation}
                        decimals={getDecimalPlaces("ersReducedOpDryer")}
                        change={change}
                        units={{
                            base: getBaseUnits("ersReducedOpDryer", modelUnits),
                            options: getUnitOptions("energy"),
                            selected: dryerUnits,
                            unitType: "energy",
                            accessor: `${accessor}.appliances.dryer_u`,
                        }}
                    />
                    <Field
                        component={Checkbox}
                        name={`${accessor}.appliances.isDryerEStar`}
                        label="Energy Star"
                        large
                        className={classes.bottomCheckBox}
                        type="checkbox"
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.appliances.washer`}
                        label="Clothes Washer"
                        placeholder={0.0}
                        validate={washerValidation}
                        decimals={getDecimalPlaces("ersReducedOpWasher")}
                        change={change}
                        units={{
                            base: getBaseUnits("ersReducedOpWasher", modelUnits),
                            options: getUnitOptions("energy"),
                            selected: washerUnits,
                            unitType: "energy",
                            accessor: `${accessor}.appliances.washer_u`,
                        }}
                    />
                    <Field
                        component={Checkbox}
                        name={`${accessor}.appliances.isWasherEStar`}
                        label="Energy Star"
                        large
                        className={classes.bottomCheckBox}
                        type="checkbox"
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.appliances.dishWasher`}
                        label="Dish Washer"
                        placeholder={0.0}
                        validate={dishWasherValidation}
                        decimals={getDecimalPlaces("ersReducedOpDishwasher")}
                        change={change}
                        units={{
                            base: getBaseUnits("ersReducedOpDishwasher", modelUnits),
                            options: getUnitOptions("energy"),
                            selected: dishWasherUnits,
                            unitType: "energy",
                            accessor: `${accessor}.appliances.dishWasher_u`,
                        }}
                    />
                    <Field
                        component={Checkbox}
                        name={`${accessor}.appliances.isDishWasherEStar`}
                        label="Energy Star"
                        large
                        className={classes.bottomCheckBox}
                        type="checkbox"
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.appliances.refrigerator`}
                        label="Refrigerator"
                        placeholder={0.0}
                        validate={fridgeValidation}
                        decimals={getDecimalPlaces("ersReducedOpRefrigerator")}
                        change={change}
                        units={{
                            base: getBaseUnits("ersReducedOpRefrigerator", modelUnits),
                            options: getUnitOptions("energy"),
                            selected: fridgeUnits,
                            unitType: "energy",
                            accessor: `${accessor}.appliances.refrigerator_u`,
                        }}
                    />
                    <Field
                        component={Checkbox}
                        name={`${accessor}.appliances.isRefrigeratorEStar`}
                        label="Energy Star"
                        large
                        className={classes.bottomCheckBox}
                        type="checkbox"
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.appliances.range`}
                        label="Range"
                        placeholder={0.0}
                        validate={rangeValidation}
                        decimals={getDecimalPlaces("ersReducedOpRange")}
                        change={change}
                        units={{
                            base: getBaseUnits("ersReducedOpRange", modelUnits),
                            options: getUnitOptions("energy"),
                            selected: rangeUnits,
                            unitType: "energy",
                            accessor: `${accessor}.appliances.range_u`,
                        }}
                    />
                </InputRow>
                <h4 style={{ marginBottom: "1.25rem", marginTop: "1.75rem" }}>Hot Water</h4>
                <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                    <Field
                        component={Checkbox}
                        name={`${accessor}.hotWater.reducedFlowFaucets`}
                        label="Reduced flow bathroom faucets"
                        large
                        className={classes.bottomCheckBox}
                        type="checkbox"
                    />
                    <Field
                        component={Checkbox}
                        name={`${accessor}.hotWater.reducedFlowShower`}
                        label="Reduced flow shower heads"
                        large
                        className={classes.bottomCheckBox}
                        type="checkbox"
                    />
                </InputRow>
            </div>
        );
    }
);
