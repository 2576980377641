import React, { useState, useEffect } from "react";

import Button from "components/Button";
import { mixpanel } from "components/Mixpanel";
import ToggleMulti from "components/ToggleMulti";
import DataEntryMode from "../Enclosure/Takeoff/components/DataEntryMode";

import DrawingIcon from "assets/images/icons/JSX/Drawing";
import Save from "assets/images/icons/JSX/save";

import { ReactComponent as Checklist } from "assets/images/icons/Icon-Checklist.svg";
import SettingsIcon from "assets/images/icons/JSX/Settings";
import { ReactComponent as PhotoGalleryIcon } from "assets/images/icons/ImageGallery.svg";
import { ReactComponent as ResetIcon } from "assets/images/icons/Icon-Reset-blue.svg";

import classes from "./style.module.scss";

const TopButtons = ({
    submitting = false,
    toggleDrawing,
    handleSubmit,
    invalid = false,
    saveDrawingData,
    hasChecklist = false,
    hasUnchecked = false,
    toggleChecklist,
    contentOpen = false,
    toggleContentOpen,
    toggleSettings,
    dataEntryMode = "",
    changeDataentryMode,
    saveTimeout,
    setSaveTimeout,
    isDataChanged,
    toggleNewDrawingOpen,
    fetchDrawingData,
    modelId,
    readOnly,
    isNewSnapshot,
    toggleImageGallery,
    fetchModelData,
    isSyncing,
}) => {
    // const [mode, changeMode] = useState("Classic");

    // useEffect(() => {
    //     if (isDataChanged) {
    //         clearTimeout(saveTimeout);

    //         setSaveTimeout(setTimeout(() => handleSubmit(), 60000));
    //     } else {
    //         clearTimeout(saveTimeout);
    //     }

    //     return () => clearTimeout(saveTimeout);
    // }, [isDataChanged]);

    useEffect(() => {
        if (isDataChanged) {
            const unloadCallback = (event) => {
                event.preventDefault();
                event.returnValue = "";
                return "";
            };

            window.addEventListener("beforeunload", unloadCallback);
            return () => window.removeEventListener("beforeunload", unloadCallback);
        }
    }, [isDataChanged]);

    return (
        <div className={classes.buttons}>
            <DataEntryMode value={dataEntryMode} onChange={(val) => changeDataentryMode(val)} />
            {hasChecklist && (
                <div data-for="drawingTip" data-tip="Notes generated from homeowner inputs">
                    <Button
                        smallPadding
                        type="emptyBg"
                        icon={Checklist}
                        onClick={async () => {
                            mixpanel.track("Checklist Open");
                            toggleChecklist(true);
                        }}
                        className={classes.checklistButton}
                    >
                        {hasUnchecked && <span className={classes.alertDot}></span>} Checklist
                    </Button>
                </div>
            )}
            <div data-for="drawingTip" data-tip="Build your model using Volta SNAP's drawing capture.">
                <Button
                    smallPadding
                    type="emptyBg"
                    icon={DrawingIcon}
                    onClick={async () => {
                        clearTimeout(saveTimeout);

                        if (dataEntryMode.toLowerCase() === "hot2000") await changeDataentryMode("combined");

                        await fetchDrawingData(modelId);
                        await handleSubmit();
                        mixpanel.track("Drawing Open");
                        toggleNewDrawingOpen(true);
                    }}
                    className={classes.drawing}
                />
            </div>
            {/* TODO: update copy for tool tip */}
            <div data-for="photoGalleryTip" data-tip="Photo Gallery">
                <Button
                    smallPadding
                    type="emptyBg"
                    icon={PhotoGalleryIcon}
                    onClick={async () => {
                        clearTimeout(saveTimeout);

                        toggleImageGallery();

                        await handleSubmit();
                        mixpanel.track("Photo Gallery Open");
                    }}
                    className={classes.drawing}
                />
            </div>
            <div data-for="drawingTip" data-tip="Change your models settings">
                <Button
                    smallPadding
                    type="emptyBg"
                    icon={SettingsIcon}
                    onClick={async () => {
                        mixpanel.track("Model Settings Open");
                        toggleSettings(true);
                    }}
                    className={classes.settingsButton}
                ></Button>
            </div>
            <div
                className={classes.toggleContent}
                data-for="openToggleTip"
                data-tip={`<span style="text-align:center"><span style="display:block;">Toggle all expanding content for pdf/print.</span><em style="display:block;">(Opening all content will likely slow down the application)</em></span>`}
                data-html
            >
                <ToggleMulti
                    className={classes.toggle}
                    active={contentOpen ? "yes" : "no"}
                    options={[
                        {
                            id: "no",
                            label: "Close",
                            onClick: toggleContentOpen,
                        },
                        {
                            id: "yes",
                            label: "Open",
                            onClick: toggleContentOpen,
                        },
                    ]}
                />
            </div>
            <div
                className={classes.saveWrapper}
                data-for="saveTip"
                data-tip={(() => {
                    if (readOnly) {
                        return "Saving disabled in read-only mode";
                    }
                    if (invalid) {
                        return "Please resolve invalid fields";
                    } else if (submitting) {
                        return "Saving...";
                    } else if (!isDataChanged) {
                        return "There are no changes to save";
                    }
                })()}
            >
                <Button
                    style={{ minWidth: "9.625rem" }}
                    smallPadding
                    icon={() => <Save strokeClass={classes.saveIconStroke} />}
                    onClick={async () => {
                        clearTimeout(saveTimeout);
                        // await saveDrawingData();

                        //saveDrawingData handled in an action and cannot yet be disabled at the source, like handleSubmit
                        if (!readOnly) {
                            // await saveDrawingData();
                        }

                        mixpanel.track("Save Model");
                        handleSubmit();
                    }}
                    disabled={submitting || invalid || !isDataChanged || readOnly}
                >
                    {submitting ? "Saving..." : readOnly ? "Read-only" : "Save Changes"}
                </Button>
            </div>
            <div data-for="syncTip" data-tip={"Sync the model will earase all changes!"}>
                {(isNewSnapshot || isSyncing) && (
                    <Button
                        className={isSyncing ? `${classes.syncDrawing}` : ""}
                        type="hollow"
                        style={{ width: "fit-content", minWidth: "unset", marginLeft: "0.5rem" }}
                        icon={ResetIcon}
                        smallPadding
                        onClick={async () => {
                            fetchModelData();
                        }}
                        // disabled={submitting || invalid || !isDataChanged || readOnly}
                    />
                )}
            </div>

            <style>
                {`
              .MuiInputBase-root::before {
                border-bottom: none !important;
              }
              .MuiInput-underline.Mui-focused:after {
                border-bottom: none !important;
              }
              .Mui-selected {
                background-color: white !important;
                font-weight: bold
              }
              .MuiListItem-button:hover {
                background-color: #F0F8FD !important;
                color: #0049C6 !important;
              }
              .MuiList-root {
                padding-top: 0 !important;
                padding-bottom: 0 !important;
              }
              `}
            </style>
        </div>
    );
};

export default TopButtons;
