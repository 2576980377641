import { connect } from "react-redux";
import Canvas from "./";
import { actions } from "features/Model/Drawing/_ducks";
import isEmpty from "lodash/isEmpty";

const {
    setScale,
    setAction,
    setActiveImage,
    setActiveTool,
    setDisabledTools,
    setMessage,
    setActiveComponent,
    addComponent,
    removeComponent,
    updateComponent,
    updatePolygon,
    addLine,
    updateLine,
    removeLine,
    toggleImageProcessing,
    updateStage,
} = actions;

const mapStateToProps = ({
    model: {
        modelFiles = [],
        modelData: {
            uiSettings: { primaryUnits } = {},
            defaultCodes: defaultParams = {},
        } = {},
    },
    drawing: {
        imageData = {},
        activeImage = "",
        activeComponent = "",
        processingImages = false,
        action = {},
        message = {},
        tools: { active: activeTool = "", disabled: disabledTools = [] } = {},
        stage: { scale = 1, coords = {}, size = {} },
    },
    form: {
        model: {
            values: {
                modelData: { defaultCodes: newDefaultParams = {} } = {},
            } = {},
        } = {},
    } = {},
}) => {
    const numImagesWithScales = Object.values(imageData).filter(
        ({ scale = {} }) => !isEmpty(scale)
    ).length;
    const numImages = modelFiles.filter(
        ({ contentType = "" }) => contentType === "image/jpeg"
    ).length;

    return {
        images: modelFiles
            .filter(({ contentType = "" }) => contentType === "image/jpeg")
            .map(({ fileName, ...rest }) => ({
                fileName: fileName.split(".jpg")[0],
                ...rest,
            }))
            .sort(({ fileName: fileA = "" }, { fileName: fileB = "" }) => {
                if (fileA.toLowerCase() < fileB.toLowerCase()) {
                    return -1;
                }
                if (fileA.toLowerCase() > fileB.toLowerCase()) {
                    return 1;
                }
                return 0;
            }),
        units: primaryUnits === "Metric" ? "m" : "ft",
        imageData,
        primaryUnits,
        activeImage,
        activeComponent,
        missingScales: numImagesWithScales < numImages,
        message,
        activeTool,
        disabledTools,
        processingImages,
        defaultParams: !isEmpty(newDefaultParams)
            ? newDefaultParams
            : defaultParams,
        stageScale: scale,
        stageCoords: coords,
        canvasSize: size,
    };
};

const mapDispatchToProps = (dispatch) => ({
    setScale: ({ image, scale }) => dispatch(setScale({ image, scale })),
    setAction: ({ id, meta = {} }) => dispatch(setAction({ id, meta })),
    setActiveImage: (image) => dispatch(setActiveImage({ activeImage: image })),
    setActiveTool: (tool) => dispatch(setActiveTool({ activeTool: tool })),
    setDisabledTools: (disabledTools) =>
        dispatch(setDisabledTools({ disabledTools })),
    setMessage: ({ content, fill = "#646F81" }) =>
        dispatch(setMessage({ content, fill })),
    setActiveComponent: (component) =>
        dispatch(setActiveComponent({ activeComponent: component })),
    addComponent: ({ image, component }) =>
        dispatch(addComponent({ image, component })),
    addLine: ({ image, line }) => dispatch(addLine({ image, line })),
    removeComponent: ({ image, componentId }) =>
        dispatch(removeComponent({ image, componentId })),
    updateComponent: ({ image, componentId, updates }) =>
        dispatch(updateComponent({ image, componentId, updates })),
    updatePolygon: ({ image, componentId, updates }) =>
        dispatch(updatePolygon({ image, componentId, updates })),
    updateLine: ({ image, id, updates }) =>
        dispatch(updateLine({ image, id, updates })),
    removeLine: ({ image, lineId }) => dispatch(removeLine({ image, lineId })),
    toggleImageProcessing: (processingImages) =>
        dispatch(toggleImageProcessing(processingImages)),
    updateStage: (updates) => dispatch(updateStage(updates)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Canvas);
