import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import GeneralResults from './';
import {actions} from "../_ducks";

const { changePackage } = actions;

const mapStateToProps = ({
    model:{
        modelDetails:{
            lastCliRun:{
                cliId={}
            }={}
        }={}
    }={},
    upgrades:{
        packages={}
    }={},
    results:{
        packageResults={},
        selectedPackage="base",
    }={},
    form
}) => {

    const selector = formValueSelector('model');
    const { id:weatherProvTerrId } = selector({form}, 'modelData.location.weather.region') || 0;
    // const weatherProvTerrId = 6

    //Eventually we'll have a separate set of data for each selected package, 
    //but here we just populate with the same data no matter the selected package

    return {
        resultsPackages:cliId,
        selectedPackage,
        packageInfo:packages,
        packageResultsData:packageResults,
        weatherProvTerrId
    }
};

const mapDispatchToProps = (dispatch) => ({
    changePackage: (pkg) => dispatch(changePackage(pkg)),});

export default connect(mapStateToProps, mapDispatchToProps)(GeneralResults);
