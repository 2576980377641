import onEf2010 from "./hourly/ON-EF-2010";
import onEf2011 from "./hourly/ON-EF-2011";
import onEf2012 from "./hourly/ON-EF-2012";
import onEf2013 from "./hourly/ON-EF-2013";
import onEf2014 from "./hourly/ON-EF-2014";
import onEf2015 from "./hourly/ON-EF-2015";
import onEf2016 from "./hourly/ON-EF-2016";
import onEf2017 from "./hourly/ON-EF-2017";
import onEf2018 from "./hourly/ON-EF-2018";
import onEf2019 from "./hourly/ON-EF-2019";
import onEf2020 from "./hourly/ON-EF-2020";
import onEf2021 from "./hourly/ON-EF-2021";
import onEf2022 from "./hourly/ON-EF-2022";
import onEf2023 from "./hourly/ON-EF-2023";

export const getHourlyEmissions = ({ year, type = "hefFf" }) => {
    year = Math.max(2010, Math.min(2023, year));

    return {
        2010: onEf2010,
        2011: onEf2011,
        2012: onEf2012,
        2013: onEf2013,
        2014: onEf2014,
        2015: onEf2015,
        2016: onEf2016,
        2017: onEf2017,
        2018: onEf2018,
        2019: onEf2019,
        2020: onEf2020,
        2021: onEf2021,
        2022: onEf2022,
        2023: onEf2023,
    }[year][type];
};
