import React, { useCallback } from 'react';
import classes from 'features/Model/Enclosure/UserDefinedCodes/style.module.scss';
import InputRow from 'components/Input/Row';
import InputWithUnits from 'components/Input/InputWithUnits';
import Select from 'components/Input/Select';
import { Field } from 'redux-form';
import { getOptions, getFirstOption, getBaseUnits, getUnitOptions } from 'utils/fields';
import { getValidation, getDecimalPlaces } from 'utils/fieldValidation';
import { getCavityResistivity } from 'utils/enclosure/codeLayers';
import Accordion from 'components/Accordion';
import AccordionActions from "../../AccordionActions";

const layerValidation = getValidation('udefContInsLayer');
const categoryValidation = getValidation('udefContInsMaterialCat');
const typeValidation = getValidation('udefContInsMaterialType');
const resistivityValidation = getValidation('udefContInsResistivity');
const thicknessValidation = getValidation('udefContInsThickness');
const rValueValidation = getValidation('udefContInsRValue');


export default React.memo(({
    change,
    accessor,
    materialCatId,
    materialTypeId,
    resistivityValue,
    resistivityUnits,
    thicknessUnits,
    rValue,
    rValueUnits,
    modelUnits,
    layer,
    isDragging= false,
    dragHandleProps,
    dragActive= false,
    insulationLayers,
    id,
}) => {
    const materialTypeOptions = getOptions({fieldKey:'udefContInsMaterialType', indKey: materialCatId}) || [];

    const {
        label:materialLabel=""
    } = materialTypeOptions.find(({ value:{ id=0 }={} }) => id === materialTypeId) || {};

    const deleteLayer = useCallback(() => {
        const { [id]:_, ...remainingLayers } = insulationLayers;
        change('uDefCode.layers.continuousInsulation', remainingLayers);
    },[id, insulationLayers, change]);

    //ADD USER DEFINED DEPENDENCIES
    return (
        <div>
            <Accordion
                className={classes.accordion}
                heading={
                    <span className={classes.accordionHeader}>
                        <span className={classes.accordionPreTitle}>Continuous Insulation</span>
                        <span className={classes.accordionTitle}>{materialLabel}</span>
                    </span>
                }
                isDragging={isDragging}
                dragActive={dragActive}
                number={layer}
                hoverActions={<AccordionActions dragHandleProps={dragHandleProps} accessor={accessor} deleteLayer={deleteLayer} />}
            >
                <InputRow gridTemplate="1fr">
                    <Field
                        component={Select}
                        name={`${accessor}.materialCategory`}
                        options={getOptions({fieldKey:'udefContInsMaterialCat'})}
                        label="Material Category"
                        placeholder="Choose Material Category"
                        validate={categoryValidation}
                        onChange={({id})=>{
                            if (id === 0) {
                                change(`${accessor}.materialType`, {id:null})
                            } else {
                                change(
                                    `${accessor}.materialType`,
                                    getFirstOption({indKey:id, depKey:'udefContInsMaterialType'})
                                );
                            }
                        }}
                        info={'Includes any continuous insulation that is uninterrupted by, for example, a framing layer.'}
                    />
                </InputRow>
                <InputRow gridTemplate="2fr 1fr">
                    <Field
                        component={Select}
                        name={`${accessor}.materialType`}
                        options={materialTypeOptions}
                        disabled={materialCatId === 0}
                        label="Material Type"
                        placeholder="Choose Material Type"
                        validate={typeValidation}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.resistivity`}
                        label="Resistivity"
                        placeholder={0.00}
                        disabled={materialCatId !== 0}
                        validate={resistivityValidation}
                        setTouched={true}
                        decimals={getDecimalPlaces('udefContInsResistivity')}
                        change={change}
                        setValue={materialTypeId == null ? resistivityValue : getCavityResistivity(materialTypeId)}
                        units={{
                            base:getBaseUnits('udefContInsResistivity', modelUnits),
                            options:getUnitOptions('thermalResistivity'),
                            selected:resistivityUnits,
                            unitType:'thermalResistivity',
                            accessor:`${accessor}.resistivity_u`
                        }}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.thickness`}
                        label="Thickness"
                        placeholder={0.00}
                        validate={thicknessValidation}
                        setTouched={true}
                        decimals={getDecimalPlaces('udefContInsThickness')}
                        change={change}
                        units={{
                            base:getBaseUnits('udefContInsThickness', modelUnits),
                            options:getUnitOptions('length'),
                            selected:thicknessUnits,
                            unitType:'length',
                            accessor:`${accessor}.thickness_u`
                        }}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.rValue`}
                        label="R-Value"
                        placeholder={0.00}
                        disabled={true}
                        setValue={rValue}
                        validate={rValueValidation}
                        setTouched={true}
                        decimals={getDecimalPlaces('udefContInsRValue')}
                        change={change}
                        units={{
                            base:getBaseUnits('udefContInsRValue', modelUnits),
                            options:getUnitOptions('thermalResistance'),
                            selected:rValueUnits,
                            unitType:'thermalResistance',
                            accessor:`${accessor}.rValue_u`
                        }}
                    />
                </InputRow>
            </Accordion>
        </div>
    )
});