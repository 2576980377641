import React from 'react';
import classes from './style.module.scss';
import CompassOuter from 'assets/images/JSX/CompassOuter';
import CompassInner from 'assets/images/JSX/CompassInner';

const defaultOptions = {
    0:180,
    1:135,
    2:90,
    3:45,
    4:0,
    5:-45,
    6:-90,
    7:-135,
};

export default React.memo(({
    facingId=0,
    rotationOptions=defaultOptions,
    disabled=false
}) => (
    <div className={`${classes.compass} ${disabled ? classes.disabled : ''}`}>
        <CompassOuter className={classes.outer} />
        <CompassInner
            className={classes.inner}
            style={{transform:`translate(-50%, -50%) rotate(${rotationOptions[facingId]}deg)`}}
        />
    </div>
))