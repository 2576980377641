import React from "react";
import style from "./style.module.scss";
import classNames from "classnames";
import { useUpdatedRef } from "utils/hooks";
import { isEqual } from "lodash";
import Tooltip from "components/Tooltip";

const ToggleMulti = React.memo(({ options = [], active = "", className, disabled: toggleDisabled }) => {
    const [activeRef, updateActiveRef] = useUpdatedRef();
    const { offsetWidth: activeWidth = 32, offsetLeft: activeLeft = 4 } = activeRef || {};

    const classes = classNames(style.toggleMulti, {
        [className]: className,
        [style.disabled]: toggleDisabled,
    });

    return (
        <div className={classes}>
            <div className={style.switch} style={{ left: activeLeft, width: activeWidth }}></div>
            {options.map(({ id, label, icon: Icon, tip, onClick, disabled, className, activeClass }) => {
                const isActive = active === id;
                const optionClasses = classNames(style.toggleOption, {
                    [style.active]: isActive,
                    [style.disabled]: disabled || toggleDisabled,
                    [className]: className,
                    [activeClass]: activeClass && isActive,
                });
                return (
                    <div
                        key={id}
                        ref={isActive ? updateActiveRef : null}
                        className={optionClasses}
                        onClick={disabled ? () => {} : onClick}
                        data-for={`toggleTip-${id}`}
                        data-tip={tip}
                    >
                        {Icon && (
                            <div className={style.icon}>
                                <Icon />
                            </div>
                        )}
                        {typeof label === "string" ? label && <span className={style.labelText}>{label}</span> : label}
                        {tip && <Tooltip id={`toggleTip-${id}`} proximity={8} delay />}
                    </div>
                );
            })}
        </div>
    );
}, isEqual);

export default ToggleMulti;
