import { connect } from 'react-redux';
import { reduxForm, formValueSelector, reset } from 'redux-form';
import WindowLayers from 'features/Model/Enclosure/UserDefinedCodes/WindowLayers';
import uDefWindowCodeTemplate from 'features/Model/Enclosure/Templates/windowUDef.json';
import { idIfyDate } from "utils";
import { actions as userActions } from 'store/users';
import { actions as enclosureActions } from 'features/Model/Enclosure/_ducks';
import isEmpty from 'lodash/isEmpty';

const { addToCodeLib } = userActions;
const { setInitCode } = enclosureActions;

const mapStateToProps = (
    {
        form,
        enclosure:{
            initCodeValues:{
                windowUDef={}
            }={}
        }={},
        user:{
            uid,
        },
    },
    {
        fieldAccessor,
        drawingFormChange,
        modelFormChange,
        componentType,
        isCodeLibrary=false,
        componentId,
        handleUdefSave,
        drawingSave
    }
) => {
    const selector = formValueSelector('uDefCodeWindow');
    const modelSelector = formValueSelector('model');

    const {
        windowLegacy: windowLegacyLayers = {},
        window: windowOverallLayers = {},
    } = selector({form}, 'uDefCode.layers') || {};

    const legacy = Object.keys(windowLegacyLayers).map((key) => ({
        ...windowLegacyLayers[key],
        id: key,
        type:"windowLegacy",
    }));

    const overall = Object.keys(windowOverallLayers).map((key) => ({
        ...windowOverallLayers[key],
        id: key,
        type:"windowOverall",
    }));

    const codeLayers = [
        ...legacy,
        ...overall,
    ];

    // const modelCodes = modelSelector({form}, 'modelData.codes') || {};
    const { codeRef:editingCodeRef=null, isLibCode=false } = windowUDef;
    
    return {
        componentType,
        initialValues:{
            uDefCode: isEmpty(windowUDef) ? uDefWindowCodeTemplate : windowUDef,
            addToLibrary: isEmpty(windowUDef) ? true : isLibCode,
        },
        codeLayers,
        numLayers: codeLayers.length,
        modelFormChange,
        fieldAccessor,
        editingCodeRef,
        isLibCode,
        uid,
        isCodeLibrary,
        drawingFormChange,
        componentId,
        handleUdefSave,
        drawingSave
    }
};

const mergeProps = (state, dispatch, own) => ({
    ...state,
    ...dispatch,
    ...own,
});

const onSubmit = async(
    form,
    dispatch,
    {
        editingCodeRef,
        modelFormChange,
        uid,
        componentType,
        isCodeLibrary,
        drawingFormChange,
        handleUdefSave,
        drawingSave
    }
) => {
    const { uDefCode={}, addToLibrary=false } = form;
    const { label="" } = uDefCode;

    //Close drawer and reset code type
    handleUdefSave()

    // 1. Create and change code ref
    const newCodeRef = `Window-U-${idIfyDate()}`;
    const setCodeRef = editingCodeRef || newCodeRef;

    // TODO: 2. Fetch rValue
    const rVal = 0;
    const windowEr = 0;
    const windowShgc = 0;

    // ***************************************
    // 3. If in model, do model things
    // ***************************************
    if (!isCodeLibrary) {
        const code = {
            ...uDefCode,
            codeRef:setCodeRef,
            nominalInsulation:rVal, // Change when we get value
            rValue:rVal, // Change when we get value
            er:windowEr,
            shgc:windowShgc
        };

        modelFormChange(`modelData.codes.${setCodeRef}`, code);
        
        const fieldValue = {
            codeLabel:label,
            codeRef:setCodeRef,
        };

        // Update window specs
        drawingFormChange('windowType', fieldValue);
        drawingFormChange('windowType_nomRVal', rVal);
        drawingFormChange('windowType_effRVal', rVal);
        drawingFormChange('er', windowEr);
        drawingFormChange('shgc', windowShgc);
    }

    // ***************************************
    // 4. If in code library or "add to code library" is selected, do code library things
    // ***************************************
    if (addToLibrary || isCodeLibrary) {
        await dispatch(addToCodeLib(
            uid,
            {
                ...uDefCode,
                nominalRValue:0,
                label,
                codeType:"User Defined",
                componentType:componentType,
                er:windowEr,
                shgc:windowShgc,
                codeRef:setCodeRef,
            },
            componentType,
            setCodeRef
        ));

    }

    drawingSave();

    // Clear wall code defaults for next time
    dispatch(setInitCode({}, 'windowUDef'));
    // Reset form
    dispatch(reset('uDefCodeWindow'));
}

const mapDispatchToProps = dispatch => ({});

const form = reduxForm({
    form:"uDefCodeWindow",
    enableReinitialize: true,
    onSubmit:onSubmit
})(WindowLayers);


export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(form);