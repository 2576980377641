import { connect } from "react-redux";
import { reduxForm, formValueSelector, reset } from "redux-form";
import SelectorDrawer from "./";
import slabCodeTemplate from "features/Model/Enclosure/Templates/slabCode.json";
import { actions as userActions } from "store/users";
import { actions as enclosureActions } from "features/Model/Enclosure/_ducks";
import { actions as upgradeActions } from "features/Model/Upgrades/_ducks";
import isEmpty from "lodash/isEmpty";
import { standardCodeLogic } from "utils/codes";

const { addToCodeLib, deleteCode } = userActions;
const { setInitCode, getRValue } = enclosureActions;
const { updateUpgradeCodes } = upgradeActions;

const mapStateToProps = (
    {
        form,
        model: { modelId },
        user: { uid },
        enclosure: { initCodeValues: { slabInsType = {} } = {} } = {},
        upgradeLibrary: { currentPackage, selectedUpgrade },
    },
    { fieldAccessor, modelFormChange, currentFormChange, change, isCodeLibrary = false, componentId }
) => {
    const selector = formValueSelector("slabCode");
    const { value: framingValue } = selector({ form }, "slabCode.layers.framing") || {};
    const { value: spacingValue } = selector({ form }, "slabCode.layers.spacing") || {};
    const { value: insulationValue } = selector({ form }, "slabCode.layers.slabInsulation") || {};
    const { value: interiorFinishValue } = selector({ form }, "slabCode.layers.interiorFinish") || {};
    const { value: sheathingValue } = selector({ form }, "slabCode.layers.sheathing") || {};

    const slabLabel = selector({ form }, "slabCode.label") || "";

    const slabValue = `${framingValue}${spacingValue}${insulationValue}${interiorFinishValue}${sheathingValue}`;

    const { codeRef: editingCodeRef = null, isLibCode = false } = slabInsType;

    const modelSelector = formValueSelector("model");
    const modelCodes = modelSelector({ form }, "modelData.codes") || {};
    const components = modelSelector({ form }, "modelData.components") || {};

    return {
        slabLabel,
        fieldAccessor,
        modelFormChange,
        currentFormChange,
        initialValues: {
            slabCode: isEmpty(slabInsType) ? slabCodeTemplate : slabInsType,
            addToLibrary: isLibCode,
        },
        slabValue,
        modelId,
        uid,
        change,
        editingCodeRef,
        isEditing: !!editingCodeRef,
        isLibCode,
        isCodeLibrary,
        componentId,
        modelCodes,
        components,
        currentPackage,
        selectedUpgrade,
    };
};

const mapDispatchToProps = (dispatch) => ({
    clearForm: () => dispatch(reset("slabCode")),
    clearInitCode: () => dispatch(setInitCode({}, "slabInsType")),
});

const mergeProps = (state, dispatch, own) => ({
    ...state,
    ...dispatch,
    ...own,
});

const onSubmit = async (
    form,
    dispatch,
    {
        toggleOpen,
        uid,
        fieldAccessor,
        modelFormChange,
        currentFormChange,
        editingCodeRef,
        isCodeLibrary,
        componentId,
        isEditing,
        modelCodes,
        components,
        currentPackage,
        selectedUpgrade,
    }
) => {
    const { slabCode = {}, addToLibrary = false, selectedComponents = [] } = form;

    toggleOpen(false);

    // Run standard code logic
    standardCodeLogic({
        componentCodeType: "FloorsAdded",
        componentId,
        code: slabCode,
        formInputName: "slabInsType",
        codeType: "S",
        isCodeLibrary,
        isEditing,
        editingCodeRef,
        dispatch,
        getRValue,
        modelFormChange,
        currentFormChange,
        fieldAccessor,
        components,
        modelCodes,
        addToLibrary,
        addToCodeLib,
        uid,
        deleteCode,
        updateUpgradeCodes,
        selectedComponents,
        currentPackage,
        selectedUpgrade,
    });

    // Clear basement code defaults for next time
    dispatch(setInitCode({}, "slabInsType"));
    // Reset form
    dispatch(reset("slabCode"));
};

const form = reduxForm({
    form: "slabCode",
    enableReinitialize: true,
    onSubmit: onSubmit,
})(SelectorDrawer);

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(form);
