import types from "./types";

import { updateObject } from "utils";

export const initialState = {
    communityId: null,
    communityData: {},
    modelDir: {},
    loading: false,
    error: null,
    isDataChanged: false,
    communityDataToCompare: {},
    modelDirToCompare: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.COMMUNITY_LOADING:
            return startCommunityLoading(state, action);
        case types.SET_COMMUNITY_ERROR:
            return setCommunityError(state, action);
        case types.SET_COMMUNITY_DATA:
            return communityInit(state, action);
        case types.CLEAR_COMMUNITY_DATA:
            return clearCommunityData(state, action);
        default:
            return state;
    }
};

const startCommunityLoading = (state, action) => updateObject(state, { loading: true });

const setCommunityError = (state, action) => updateObject(state, { loading: false, error: action.error });

const communityInit = (state, action) => {
    return (
        updateObject(state, {
            communityId: action.communityId,
            error: false,
            loading: false,
            ...action.community,
            communityDataToCompare: action.community.communityData,
            modelDirToCompare: action.community.modelDir,
            modelDataToCompare: action.community.modelData,
        })
    );
};

const clearCommunityData = (state, action) => updateObject(state, initialState);