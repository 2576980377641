import { connect } from "react-redux";
import { actions } from "store/users";
import Account from "./";

const mapStateToProps = ({ user: { uid } }) => {
    return {
        uid,
    };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Account);
