import folderTypes from "./types";

const initialState = {
    error: null,
    isLoading: false,
};

const folderReducer = (state = initialState, action) => {
    switch (action.type) {
        case folderTypes.DELETE_FOLDER_START:
        case folderTypes.CREATE_FOLDER_START:
        case folderTypes.RENAME_FOLDER_START:
        case folderTypes.MOVE_FOLDER_START:
        case folderTypes.BATCH_FOLDER_DELETE_START:
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        case folderTypes.DELETE_FOLDER_SUCCESS:
        case folderTypes.CREATE_FOLDER_SUCCESS:
        case folderTypes.RENAME_FOLDER_SUCCESS:
        case folderTypes.MOVE_FOLDER_SUCCESS:
        case folderTypes.BATCH_FOLDER_DELETE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: null,
            };
        case folderTypes.BATCH_FOLDER_DELETE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default folderReducer;
