import React from "react";

import styles from "./styles.module.scss";

const selectStyle = (type, selected, disabled = false) =>
    ({
        general: `${styles.generalType} ${selected ? styles.generalTypeSelected : ""}`,
        tag: `${styles.tagType} ${selected ? styles.tagTypeGeneralSelected : ""} ${
            disabled ? styles.tagTypeDisabled : ""
        }`,
    }[type]);

const SidebarSelectors = ({ type, Icon, text, number, isDisabled, onSelect, selected = false }) => {
    return (
        <div
            className={`${styles.sidebarSelectorsContainer} ${selectStyle(type, selected, isDisabled)}`}
            onClick={onSelect}
        >
            <div className={styles.imageWrapper}>{Icon && <Icon />}</div>
            <span>{`${text} ${number !== 0 && number !== undefined ? ` (${number})` : ""}`}</span>
        </div>
    );
};

export default SidebarSelectors;
