import React from 'react';
import classes from './style.module.scss';
import { Field } from 'redux-form';
import { getOptions, getBaseUnits, getUnitOptions } from 'utils/fields';
import {  getValidation, getDecimalPlaces } from 'utils/fieldValidation';
import InputRow from 'components/Input/Row';
import Select from 'components/Input/Select';
import InputWithUnits from 'components/Input/InputWithUnits';
import Checkbox from 'components/Input/Checkbox';

const fanModeValidation = getValidation('fanPumpCoolingMode');
const fanPowerValidation = getValidation('fanPumpCoolingPower');
const fanFlowValidation = getValidation('fanPumpCoolingFlow');

export default ({
    accessor,
    isCooling,
    isCoolingCapCalculated,
    isFanPowerCalculated,
    powerUnits,
    fanFlowUnits,
    modelUnits,
    change,
}) => {

    return (
        <>
            <h4 style={{marginBottom:"1.25rem", marginTop:"1.75rem"}}>Fans &amp; Pumps</h4>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Field
                    component={Select}
                    name={`${accessor}.fansAndPumps.mode`}
                    options={getOptions({ fieldKey: 'fanPumpCoolingMode' })}
                    label="Mode"
                    placeholder="Choose Mode"
                    validate={fanModeValidation}
                    disabled={!isCooling}
                    info={'Leave as the default value (Auto).'}
                />
                <Field
                    component={Select}
                    name={`${accessor}.fansAndPumps.power.isCalculated`}
                    options={[
                        {
                            label:"Calculated",
                            value: true
                        },
                        {
                            label:"User Specified",
                            value: false
                        },
                    ]}
                    label="Power Type"
                    placeholder="Choose Input Type"
                    disabled={!isCooling || isCoolingCapCalculated}
                    info={'The capacity type for the cooling system must be set to "User Specified" to unlock this field'}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.fansAndPumps.power.value`}
                    label="Power"
                    placeholder="0.00"
                    validate={fanPowerValidation}
                    decimals={getDecimalPlaces('fanPumpCoolingPower')}
                    change={change}
                    disabled={!isCooling || isFanPowerCalculated || isCoolingCapCalculated}
                    units={{
                        base:getBaseUnits('fanPumpCoolingPower', modelUnits),
                        options:getUnitOptions('power'),
                        selected:powerUnits,
                        unitType:'power',
                        accessor:`${accessor}.fansAndPumps.power_u`
                    }}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.fansAndPumps.flowRate`}
                    label="Indoor Fan Flow Rate"
                    placeholder="0.00"
                    validate={fanFlowValidation}
                    decimals={getDecimalPlaces('fanPumpCoolingFlow')}
                    change={change}
                    disabled={!isCooling || isFanPowerCalculated || isCoolingCapCalculated}
                    units={{
                        base:getBaseUnits('fanPumpCoolingFlow', modelUnits),
                        options:getUnitOptions('airFlow'),
                        selected:fanFlowUnits,
                        unitType:'airFlow',
                        accessor:`${accessor}.fansAndPumps.flowRate_u`
                    }}
                />
            </InputRow>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Field
                    component={Checkbox}
                    name={`${accessor}.fansAndPumps.hasEnergyEfficientMotor`}
                    label="Energy Efficient Motor"
                    large
                    className={classes.checkbox}
                    type="checkbox"
                    disabled={!isCooling}
                />
            </InputRow>
        </>
    )
};