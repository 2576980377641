import React, { useEffect } from 'react';
import classes from './style.module.scss';
import sharedClasses from 'features/Model/sharedStyles.module.scss';
import { Field } from 'redux-form';
import { getOptions, getBaseUnits, getUnitOptions } from 'utils/fields';
import { getValidation, getDecimalPlaces } from 'utils/fieldValidation';
import InputRow from 'components/Input/Row';
import Input from 'components/Input';
import InputWithUnits from 'components/Input/InputWithUnits';
import Select from 'components/Input/Select';
import Actions from '../Actions/container';
import Accordion from 'components/Accordion';
import Checkbox from 'components/Input/Checkbox';

const equipType1Validation = getValidation('opening1EquipType');
const equipType2Validation = getValidation('opening2EquipType');
const equipType3Validation = getValidation('opening3EquipType');
const flueDiameterValidation = getValidation('opening1FlueDiameter');
const numberOpeningsValidation = getValidation('opening1Number');


export default ({
    accessor,
    parentAccessor,
    isMURB,
    opening1Type,
    opening2Type,
    opening3Type,
    flue1Units,
    flue2Units,
    flue3Units,
    modelUnits,
    change,
    contentOpen,
}) => {
    useEffect(() => {
        // Clear MURB fields when component not rendered to avoid opening1Number errors
        if (!isMURB) {
            change(`${accessor}.openings.opening0.numOpenings`, 0);
            change(`${accessor}.openings.opening1.numOpenings`, 0);
            change(`${accessor}.openings.opening2.numOpenings`, 0);
        }
    }, [isMURB]);
    
    return (
        <>
            <Accordion
                className={sharedClasses.accordion}
                heading={<span className={sharedClasses.heading}>Additional Openings</span>}
                large
                hoverActions={
                    <Actions
                        label="Additional Openings"
                        type="additionalOpenings"
                        accessor={accessor}
                        change={change}
                        parentAccessor={parentAccessor}
                    />
                }
                forceOpen={contentOpen}
            >
                <p style={{marginBottom:"1.25rem"}}>This section is not to be used to model supplementary heating systems unless all five available supplementary systems have already been modelled.</p>
                <InputRow gridTemplate="1fr 1fr 1fr 1fr ">
                    <Field
                        component={Select}
                        name={`${accessor}.openings.opening0.type`}
                        options={getOptions({ fieldKey: 'opening1EquipType' })}
                        label="Equipment Type"
                        placeholder="Choose Equipment Type"
                        validate={equipType1Validation}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.openings.opening0.flueDiameter`}
                        label="Flue Diameter"
                        placeholder="0.00"
                        validate={flueDiameterValidation}
                        decimals={getDecimalPlaces('opening1FlueDiameter')}
                        change={change}
                        units={{
                            base:getBaseUnits('opening1FlueDiameter', modelUnits),
                            options:getUnitOptions('length'),
                            selected:flue1Units,
                            unitType:'length',
                            accessor:`${accessor}.openings.opening0.flueDiameter_u`
                        }}
                        disabled={opening1Type === 0}
                        reset={opening1Type === 0}
                    />
                    <Field
                        component={Checkbox}
                        name={`${accessor}.openings.opening0.damperClosed`}
                        label="Is Damper Closed?"
                        large
                        className={classes.checkbox}
                        type="checkbox"
                        disabled={opening1Type === 0}
                        reset={opening1Type === 0}
                    />
                    {
                        isMURB && 
                            <Field
                                component={Input}
                                type="number"
                                name={`${accessor}.openings.opening0.numOpenings`}
                                label="Number of Openings"
                                placeholder="0"
                                validate={numberOpeningsValidation}
                                decimals={getDecimalPlaces('opening1Number')}
                                disabled={opening1Type === 0}
                                reset={opening1Type === 0}
                            />
                    }
                </InputRow>
                <InputRow gridTemplate="1fr 1fr 1fr 1fr ">
                    <Field
                        component={Select}
                        name={`${accessor}.openings.opening1.type`}
                        options={getOptions({ fieldKey: 'opening2EquipType' })}
                        label="Equipment Type"
                        placeholder="Choose Equipment Type"
                        validate={equipType2Validation}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.openings.opening1.flueDiameter`}
                        label="Flue Diameter"
                        placeholder="0.00"
                        validate={flueDiameterValidation}
                        decimals={getDecimalPlaces('opening2FlueDiameter')}
                        change={change}
                        units={{
                            base:getBaseUnits('opening2FlueDiameter', modelUnits),
                            options:getUnitOptions('length'),
                            selected:flue2Units,
                            unitType:'length',
                            accessor:`${accessor}.openings.opening1.flueDiameter_u`
                        }}
                        disabled={opening2Type === 0}
                        reset={opening2Type === 0}
                    />
                    <Field
                        component={Checkbox}
                        name={`${accessor}.openings.opening1.damperClosed`}
                        label="Is Damper Closed?"
                        large
                        className={classes.checkbox}
                        type="checkbox"
                        disabled={opening2Type === 0}
                        reset={opening2Type === 0}
                    />
                    {
                        isMURB && 
                            <Field
                                component={Input}
                                type="number"
                                name={`${accessor}.openings.opening1.numOpenings`}
                                label="Number of Openings"
                                placeholder="0"
                                validate={numberOpeningsValidation}
                                decimals={getDecimalPlaces('opening2Number')}
                                disabled={opening2Type === 0}
                                reset={opening2Type === 0}
                            />
                    }
                </InputRow>
                <InputRow gridTemplate="1fr 1fr 1fr 1fr ">
                    <Field
                        component={Select}
                        name={`${accessor}.openings.opening2.type`}
                        options={getOptions({ fieldKey: 'opening3EquipType' })}
                        label="Equipment Type"
                        placeholder="Choose Equipment Type"
                        validate={equipType3Validation}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.openings.opening2.flueDiameter`}
                        label="Flue Diameter"
                        placeholder="0.00"
                        validate={flueDiameterValidation}
                        decimals={getDecimalPlaces('opening3FlueDiameter')}
                        change={change}
                        units={{
                            base:getBaseUnits('opening3FlueDiameter', modelUnits),
                            options:getUnitOptions('length'),
                            selected:flue3Units,
                            unitType:'length',
                            accessor:`${accessor}.openings.opening2.flueDiameter_u`
                        }}
                        disabled={opening3Type === 0}
                        reset={opening3Type === 0}
                    />
                    <Field
                        component={Checkbox}
                        name={`${accessor}.openings.opening2.damperClosed`}
                        label="Is Damper Closed?"
                        large
                        className={classes.checkbox}
                        type="checkbox"
                        disabled={opening3Type === 0}
                        reset={opening3Type === 0}
                    />
                    {
                        isMURB && 
                            <Field
                                component={Input}
                                type="number"
                                name={`${accessor}.openings.opening2.numOpenings`}
                                label="Number of Openings"
                                placeholder="0"
                                validate={numberOpeningsValidation}
                                decimals={getDecimalPlaces('opening3Number')}
                                disabled={opening3Type === 0}
                                reset={opening3Type === 0}
                            />
                    }
                </InputRow>
                
            </Accordion>
        </>
    )
};