import React from "react";

import convertUnit from "utils/convertUnit";

import classes from "../style.module.scss";

const DisplayPolygonInfo = ({ conversionFactor, displayUnits, polygon }) => {
    const { area = 0, perimeter = 0, takeoff } = polygon;

    const isWindow = takeoff?.takeoffField === "window";

    const convertedPerimeter = convertUnit({
        value: perimeter * conversionFactor,
        type: "length",
        inputUnit: "m",
        outputUnit: displayUnits,
    });

    const convertedArea = convertUnit({
        value: area * Math.pow(conversionFactor, 2),
        type: "area",
        inputUnit: "m2",
        outputUnit: `${displayUnits}2`,
    });

    return (
        <div className={classes.componentInfo}>
            <h5 className={classes.componentTitle}>Polygon - {polygon.name}</h5>
            <div>
                <span className={classes.label}>Perimeter:</span>{" "}
                <span className={classes.value}>
                    {convertedPerimeter.toFixed(4)} {isWindow ? "mm" : displayUnits}
                </span>
            </div>
            <div>
                <span className={classes.label}>Area:</span>{" "}
                <span className={classes.value}>
                    {convertedArea.toFixed(4)} {isWindow ? "mm" : displayUnits}&sup2;
                </span>
            </div>
        </div>
    );
};

export default DisplayPolygonInfo;
