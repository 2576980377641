import React, { useEffect, useState } from "react";
import { Field } from "redux-form";
import { isEqual } from "lodash";

import { getValidation, required, maxLength, getDecimalPlaces } from "utils/fieldValidation";
import { getOptions, getBaseUnits, getUnitOptions } from "utils/fields";

import Accordion from "components/Accordion";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import InputWithUnits from "components/Input/InputWithUnits";
import Select from "components/Input/Select";
import Compass from "components/Compass";
import Measurements from "./Measurements/container";
import Construction from "./Construction/container";
import WindowStats from "./WindowStats/container";
import ComponentHeading from "../ComponentHeading";

import Actions from "../Actions/container";

import classes from "./style.module.scss";
import sharedClasses from "../style.module.scss";

import WindowIcon from "assets/images/components/Window.svg"; //* *//

const charLim32 = maxLength(32);
const windowLabelValidation = [required, charLim32, ...getValidation("windowLabel")];
const orientationValidation = getValidation("windowDirection");
const numIdenticalValidation = getValidation("windowNumber");
const shutterValidation = getValidation("windowShutterRVal");
const curtainValidation = getValidation("windowCurtainShading");

export default React.memo(
    ({
        accessor,
        label = "",
        change,
        componentId,
        isNew = false,
        isRecentDuplicate = false,
        parentPath = "",
        isDragging,
        dragHandleProps,
        windowFacingId,
        parentFacingId,
        dragActive,
        clearNewComponent = () => "",
        isDrawingComponent,
        drawingLines,
        updateDrawingChanges,
        numIdentical,
        shutterUnits,
        curtainUnits,
        modelUnits,
        drawingComponent,
        drawingImage,
        contentOpen,
        isSubComponent,
        dropPosition,
    }) => {
        const isDisableWindowDir = [1, 2, 3, 4, 5, 6, 7, 8].includes(parentFacingId);
        const [nameDialogOpen, toggleNameDialog] = useState(false);
        useEffect(() => {
            updateDrawingChanges(componentId, {
                type: "window",
                drawingComponent,
                drawingImage,
                numIdentical,
            });
        }, [numIdentical]);

        return (
            <div
                className={`${classes.window} ${sharedClasses.component} ${isSubComponent && "subComponent"} ${
                    dropPosition === "after"
                        ? sharedClasses.activeAfter
                        : dropPosition === "before"
                        ? sharedClasses.activeBefore
                        : ""
                }  `}
            >
                <span className={sharedClasses.componentRef}></span>
                <Accordion
                    className={sharedClasses.accordion}
                    heading={
                        <ComponentHeading
                            label={label}
                            componentId={componentId}
                            isDrawingComponent={isDrawingComponent}
                            icon={WindowIcon}
                            toggleNameDialog={toggleNameDialog}
                            isSubComponent={isSubComponent}
                        />
                    }
                    initOpenState={isNew && !isRecentDuplicate}
                    isNew={isNew}
                    id={componentId}
                    isDragging={isDragging}
                    toggleNew={() => clearNewComponent(componentId)}
                    dragActive={dragActive}
                    hoverActions={
                        <Actions
                            nameField={{
                                name: `${accessor}.label`,
                                validate: windowLabelValidation,
                                current: label,
                                change,
                            }}
                            label="Window"
                            type="window"
                            parentPath={parentPath}
                            componentId={componentId}
                            dragHandleProps={dragHandleProps}
                            drawingLines={drawingLines}
                            change={change}
                            nameDialogOpen={nameDialogOpen}
                            toggleNameDialog={toggleNameDialog}
                        />
                    }
                    stats={() => <WindowStats accessor={accessor} />}
                    forceOpen={isDragging ? false : contentOpen}
                >
                    <InputRow gridTemplate="40% 23.25% 1fr">
                        <div className={classes.orientation}>
                            <Compass
                                facingId={windowFacingId}
                                disabled={isDisableWindowDir}
                                rotationOptions={{
                                    0: 180,
                                    1: 135,
                                    2: 90,
                                    3: 45,
                                    4: 0,
                                    5: -45,
                                    6: -90,
                                    7: -135,
                                }}
                            />
                            <Field
                                component={Select}
                                name={`${accessor}.facingDirection`}
                                options={getOptions({ fieldKey: "windowDirection" })}
                                label="Facing Direction"
                                placeholder="Choose Facing Direction"
                                validate={orientationValidation}
                                disabled={isDisableWindowDir}
                            />
                        </div>
                        <Field
                            component={Input}
                            type="number"
                            name={`${accessor}.numIdentical`}
                            label="Number of Identical Windows"
                            placeholder={0}
                            validate={numIdenticalValidation}
                            decimals={getDecimalPlaces("windowNumber")}
                            info={
                                "In addition to size and construction details, identical windows must all face the same direction."
                            }
                        />
                    </InputRow>
                    <h4>Measurements</h4>
                    <Measurements
                        id={componentId}
                        accessor={accessor}
                        change={change}
                        isDrawingComponent={isDrawingComponent}
                    />
                    <h4>Construction</h4>
                    <Construction accessor={accessor} change={change} id={componentId} />
                    <InputRow gridTemplate="1fr 1fr 1fr">
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.shading.shutterRValue`}
                            label="Shutter R-Value"
                            placeholder={0.0}
                            quantity
                            validate={shutterValidation}
                            decimals={getDecimalPlaces("windowShutterRVal")}
                            change={change}
                            units={{
                                base: getBaseUnits("windowShutterRVal", modelUnits),
                                options: getUnitOptions("thermalResistance"),
                                selected: shutterUnits,
                                unitType: "thermalResistance",
                                accessor: `${accessor}.shading.shutterRValue_u`,
                            }}
                            info={"Use the default value (0)"}
                        />
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.shading.curtainShading`}
                            label="Curtain Shading"
                            placeholder="0"
                            quantity
                            validate={curtainValidation}
                            decimals={getDecimalPlaces("windowCurtainShading")}
                            change={change}
                            units={{
                                base: getBaseUnits("windowCurtainShading", modelUnits),
                                options: getUnitOptions("fraction"),
                                selected: curtainUnits,
                                unitType: "fraction",
                                accessor: `${accessor}.shading.curtainShading_u`,
                            }}
                            info={"Use the default value (1)"}
                        />
                    </InputRow>
                </Accordion>
            </div>
        );
    },
    isEqual
);
