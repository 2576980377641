import React, { useState, useEffect, useRef } from "react";
import { Field } from "redux-form";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import InputWithUnits from "components/Input/InputWithUnits";
import { getValidation, getDecimalPlaces, required } from "utils/fieldValidation";
import { getBaseUnits, getUnitOptions } from "utils/fields";
import Checkbox from "components/Input/Checkbox";
import classes from "./style.module.scss";

const exposedSurfaceValidation = [required, ...getValidation("crawlspaceExpSurfacePerimeter")];
const lengthValidation = getValidation("crawlspaceLength");
const widthValidation = getValidation("crawlspaceWidth");
const perimeterValidation = getValidation("crawlspacePerimeter");
const areaValidation = getValidation("crawlspaceArea");

export default React.memo(
    ({
        accessor,
        expPerimeterValue,
        totCrawlspacePerimeter,
        expPerimeterUnits,
        isRectangular,
        modelUnits,
        lengthUnits,
        widthUnits,
        perimeterValue,
        minPerimeter,
        minPerimeterString,
        perimeterUnits,
        areaUnits,
        hasMultiFoundation,
        isDetached,
        change,
        isDrawingComponent,
        crawlspaceFloorLengthEquation,
        crawlspaceFloorWidthEquation,
        crawlspaceFloorPerimeterEquation,
        crawlspaceFloorAreaEquation,
        crawlspaceExposedPerimeterEquation,
    }) => {
        return (
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.floor.measurements.length`}
                    label="Floor Length"
                    placeholder="0.00"
                    validate={lengthValidation}
                    decimals={getDecimalPlaces("crawlspaceLength")}
                    change={change}
                    hideField={!isRectangular}
                    units={{
                        base: getBaseUnits("crawlspaceLength", modelUnits),
                        options: getUnitOptions("length"),
                        selected: lengthUnits,
                        unitType: "length",
                        accessor: `${accessor}.floor.measurements.length_u`,
                    }}
                    withEquation
                    equation={crawlspaceFloorLengthEquation}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.floor.measurements.width`}
                    label="Floor Width"
                    placeholder="0.00"
                    validate={widthValidation}
                    decimals={getDecimalPlaces("crawlspaceWidth")}
                    change={change}
                    hideField={!isRectangular}
                    units={{
                        base: getBaseUnits("crawlspaceWidth", modelUnits),
                        options: getUnitOptions("length"),
                        selected: widthUnits,
                        unitType: "length",
                        accessor: `${accessor}.floor.measurements.width_u`,
                    }}
                    withEquation
                    equation={crawlspaceFloorWidthEquation}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.floor.measurements.perimeter`}
                    label="Floor Perimeter"
                    placeholder="0.00"
                    validate={perimeterValidation}
                    decimals={getDecimalPlaces("crawlspacePerimeter")}
                    change={change}
                    disabled={isDrawingComponent}
                    isDrawing={isDrawingComponent}
                    hideField={isRectangular}
                    units={{
                        base: getBaseUnits("crawlspacePerimeter", modelUnits),
                        options: getUnitOptions("length"),
                        selected: perimeterUnits,
                        unitType: "length",
                        accessor: `${accessor}.floor.measurements.perimeter_u`,
                    }}
                    withEquation
                    equation={crawlspaceFloorPerimeterEquation}
                    warning={
                        !isRectangular && perimeterValue < minPerimeter
                            ? `minFoundationPerimeter-${minPerimeterString}`
                            : ""
                    }
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.floor.measurements.area`}
                    label="Floor Area"
                    placeholder="0.00"
                    validate={areaValidation}
                    decimals={getDecimalPlaces("crawlspaceArea")}
                    change={change}
                    disabled={isDrawingComponent}
                    isDrawing={isDrawingComponent}
                    hideField={isRectangular}
                    units={{
                        base: getBaseUnits("crawlspaceArea", modelUnits),
                        options: getUnitOptions("area"),
                        selected: areaUnits,
                        unitType: "area",
                        accessor: `${accessor}.floor.measurements.area_u`,
                    }}
                    withEquation
                    equation={crawlspaceFloorAreaEquation}
                />
                <Field
                    component={Checkbox}
                    name={`${accessor}.floor.measurements.isRectangular`}
                    label="Is Crawlspace Floor Rectangular"
                    type="checkbox"
                    disabled={isDrawingComponent}
                    isDrawing={isDrawingComponent}
                    large
                    className={classes.isRectangular}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.exposedSurfacePerimeter`}
                    label="Exposed Surfaces Perimeter"
                    placeholder="0.00"
                    validate={exposedSurfaceValidation}
                    decimals={getDecimalPlaces("crawlspaceExpSurfacePerimeter")}
                    setValue={!hasMultiFoundation && isDetached ? totCrawlspacePerimeter : null}
                    disabled={!hasMultiFoundation && isDetached}
                    change={change}
                    units={{
                        base: getBaseUnits("crawlspaceExpSurfacePerimeter", modelUnits),
                        options: getUnitOptions("length"),
                        selected: expPerimeterUnits,
                        unitType: "length",
                        accessor: `${accessor}.exposedSurfacePerimeter_u`,
                    }}
                    withEquation
                    equation={crawlspaceExposedPerimeterEquation}
                    info={
                        "Used only when there are multiple foundations present. Enter the total perimeter of this foundation component that is not attached to any other foundation components."
                    }
                    warning={expPerimeterValue > totCrawlspacePerimeter ? "largeExpFoundationPerimeter" : ""}
                />
            </InputRow>
        );
    }
);
