const subComponentList = [
    {
        type: "ponywall",
        name: "Pony wall",
    },
    {
        type: "garagewall",
        name: "Garage wall",
    },
];

export const slabComponentList = [
    {
        type: "garagewall",
        name: "Garage wall",
    },
];

export default subComponentList;
