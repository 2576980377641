import React from "react";
import classes from "../style.module.scss";
import { CloseDialog } from "components/Dialog";
import Button from "components/Button";
import Dialog from "components/Dialog";
import Input from "components/Input";

const RenameDialog = ({
    toRename,
    newFolderName,
    setNewFolderName,
    onClick,
    close,
    folderLoading,
}) => (
    <Dialog open={!!toRename}>
        <CloseDialog closeActions={() => close()} />
        <div className={classes.dialog}>
            <h3>Enter New Folder Name</h3>
            <Input
                label={"New Folder Name"}
                placeholder="Type new folder name..."
                disabled={folderLoading}
                input={{
                    value: newFolderName,
                    onChange: (value) => setNewFolderName(value),
                }}
            />
            <div className={classes.footer}>
                <Button type="hollow" onClick={() => close()}>
                    Cancel
                </Button>
                <Button onClick={onClick} type="">
                    {folderLoading ? "Renaming..." : "Rename"}
                </Button>
            </div>
        </div>
    </Dialog>
);

export default RenameDialog;
