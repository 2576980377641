import React, { useCallback, useState, useEffect } from "react";
import { isEmpty } from "lodash";
import sharedClasses from "features/Model/sharedStyles.module.scss";
import classes from "./style.module.scss";
import Button from "components/Button";
import MultiSelect from "components/Input/MultiSelect";

import Accordion from "components/Accordion";
import ModelShare from "assets/images/icons/JSX/ModelShare";
import Checkbox from "components/Input/Checkbox";

const FileSharing = ({
    change,
    uid,
    organization,
    fetchUserOrganization,
    modelName = "",
    sharedWith,
    owner,
    modelId,
    shareModelFile,
}) => {
    const [selectedUserList, setSelectedUserList] = useState([]);
    const [sharePermissionAcknowledged, setSharePermissionAcknowledged] = useState(false);
    const [sharingLoading, setSharingLoading] = useState(false);

    const { uid: ownerId } = owner;

    let multiSelectOptions = [
        // {
        //     label: "Test Option",
        //     value: "testValue",
        //     disabled: false,
        // },
    ];

    useEffect(() => {
        if (uid && organization?.memberOrgIds && !organization.details) {
            // console.log("uid/org", uid, organization);
            //only handling a single org at the moment
            fetchUserOrganization(uid, Object.keys(organization.memberOrgIds)[0]);
        }
    }, [uid, organization]);

    const { memberOrgIds = {}, details = {} } = organization;

    const { id: primaryMemberOrgId = "", name: primaryMemberOrgName = "" } =
        memberOrgIds?.[Object.keys(memberOrgIds)[0]];

    const { [primaryMemberOrgId]: { members = {} } = {} } = details;

    multiSelectOptions = [
        ...multiSelectOptions,
        ...(!isEmpty(Object.values(members))
            ? Object.values(members)
                  .sort(({ name: nameA = "" }, { name: nameB = "" }) => {
                      if (nameA.toLowerCase() < nameB.toLowerCase()) {
                          return -1;
                      }
                      if (nameA.toLowerCase() > nameB.toLowerCase()) {
                          return 1;
                      }
                      return 0;
                  })
                  .map((val) => ({
                      label: `${val.name}${
                          val.uid === ownerId ? " (owner)" : sharedWith.includes(val.uid) ? " (shared)" : ""
                      }`,
                      value: val.uid,
                      disabled: val.uid === ownerId,
                  }))
            : []),
    ];

    const handleShareClick = useCallback(async () => {
        setSharingLoading(true);
        await shareModelFile(modelId, uid, primaryMemberOrgId, selectedUserList);
        setSharingLoading(false);
        setSelectedUserList([]);
    }, [selectedUserList, ownerId, primaryMemberOrgId, selectedUserList]);

    return (
        <div className={classes.accordionContainer}>
            <Accordion
                className={sharedClasses.accordion}
                heading={
                    <div className={sharedClasses.accordionIconHeading}>
                        Organizational File Sharing <ModelShare />
                    </div>
                }
                large
            >
                <div>
                    <div className={classes.orgHeader}>
                        <p>
                            Your organization: <strong>{primaryMemberOrgName || "None"}</strong>
                        </p>
                        <p>
                            File name: <strong>{modelName || "None"}</strong>
                        </p>
                    </div>
                    <MultiSelect
                        label={"Select members of your organization that you would like to share this file with:"}
                        options={multiSelectOptions}
                        input={{
                            value: selectedUserList,
                            onChange: (value) => setSelectedUserList(value),
                        }}
                        hasSelectAll={true}
                    />
                    <div className={classes.sharingConfirmationText}>
                        <Checkbox
                            name="permissionAcknowledged"
                            label="I have recieved permission from all relevant parties to share this file and its content with the selected individuals"
                            large
                            input={{
                                value: sharePermissionAcknowledged,
                                onChange: () => setSharePermissionAcknowledged(!sharePermissionAcknowledged),
                            }}
                        />
                    </div>
                    <div className={classes.shareButtonContainer}>
                        <Button
                            className={classes.shareButton}
                            type="default"
                            onClick={handleShareClick}
                            disabled={!sharePermissionAcknowledged || isEmpty(selectedUserList) || sharingLoading}
                        >
                            {sharingLoading ? "Sharing..." : "Share File"}
                        </Button>
                    </div>
                </div>
            </Accordion>
        </div>
    );
};

export default FileSharing;
