import React, { useState, useCallback } from "react";
import classes from "./style.module.scss";
import { NavLink } from "react-router-dom";
import CreateSquare from "assets/images/icons/JSX/create-square";
import Houses from "assets/images/icons/JSX/HousesIcon";
import ParametricIcon from "assets/images/icons/JSX/ParametricIcon";
import CodeLib from "assets/images/icons/JSX/CodeLibAlt";
import Upgrades from "assets/images/icons/JSX/Upgrades";
import { ReactComponent as GenerateIcon } from "assets/images/icons/Icon-Generate-Report.svg";
// import { ReactComponent as ParametricIcon } from "assets/images/icons/Icon-Parametric-Analysis.svg";
import logo from "assets/images/logo.svg";
import Dialog, { CloseDialog } from "components/Dialog";
import Star from "assets/images/icons/JSX/Star";
import Button from "components/Button";
import ArrowLeft from "assets/images/icons/arrow-left.svg";
import LogoWhite from "assets/images/volta-logo-white.svg";
import CommunitiesIcon from "assets/images/icons/JSX/CommunitiesIcon";

const FavouriteSystems = ({ open, toggleDialog }) => (
    <Dialog
        open={open}
        classes={{
            paperClass: classes.dialogPaper,
        }}
    >
        <CloseDialog closeActions={() => toggleDialog(false)} />
        <h2>Coming Soon!</h2>
        <p>
            The <strong>Favourite Systems</strong> feature will allow users to save individual mechanical systems or
            packages of systems. This feature requires only minor adjustments to the software and will be implemented
            very soon.
        </p>
    </Dialog>
);

const UnSavedChanges = ({ open, history, toggleDialog, eventTarget, isCollapsed }) => (
    <Dialog
        open={open}
        classes={{
            paperClass: classes.dialogPaper,
        }}
    >
        <CloseDialog closeActions={() => toggleDialog(false)} />
        <h2>You have unsaved changes</h2>
        <p>Any unsaved changes will be lost.</p>
        <div className={classes.buttons}>
            <Button
                onClick={() => {
                    history.push(eventTarget);
                    toggleDialog(false);
                }}
                type="hollow"
            >
                Leave without Saving
            </Button>
            <Button onClick={() => toggleDialog(false)}>Stay on this Page</Button>
        </div>
    </Dialog>
);

const Navigation = ({
    history,
    dataChanged,
    modelName,
    ENVIROCENTRE_REPORT,
    CBAT_INPUT,
    CHBA_NZ_ADMIN,
    VOLTA_DEV,
    isCollapsed,
    isCollapsedDone,
    setIsCollapsed,
    setIsCollapsedDone,
    upgradeLibChanged,
}) => {
    const [systemsDialog, toggleSystems] = useState(false);
    const [unsavedDialog, toggleUnsavedDialog] = useState(false);
    const [eventTarget, changeEventTarget] = useState("");
    const [currentLink, setCurrentLink] = useState("");

    const isModel = window.location.pathname.includes("/models/");

    const checkModelChanges = useCallback(
        (event, href) => {
            if (
                (dataChanged && window.location.pathname.includes("/models/")) ||
                (upgradeLibChanged && window.location.pathname.includes("/upgrade-library"))
            ) {
                event.preventDefault();
                changeEventTarget(href);
                toggleUnsavedDialog(true);
            } else {
                setCurrentLink(href);
            }
        },
        [dataChanged, upgradeLibChanged]
    );

    const handleCollapsed = (e) => {
        e.preventDefault();

        setIsCollapsed(!isCollapsed);

        if (isCollapsed) {
            setIsCollapsedDone(false);
        }

        if (!isCollapsed) {
            setTimeout(() => {
                setIsCollapsedDone(true);
            }, 150);
        }
    };

    return (
        <div
            className={`${classes.sideBar} ${isCollapsed && classes.sideBarCollapsed} ${
                isCollapsedDone && classes.sideBarCollapsedDone
            }`}
        >
            <div
                className={classes.logoWrapper}
                onClick={(event) => {
                    if (dataChanged && window.location.pathname.includes("/models/")) {
                        toggleUnsavedDialog(true);
                    } else {
                        history.push("/dashboard");
                    }
                }}
            >
                {!isCollapsed ? (
                    <img src={logo} alt="Volta Snap Logo" />
                ) : (
                    <img className={classes.logoWhite} src={LogoWhite} alt="Volta Snap Logo" />
                )}
            </div>
            <ul className={classes.mainNav}>
                <li className={`${classes.create} && ${isCollapsedDone && classes.listItemCollapsed}`}>
                    <NavLink
                        onClick={(event) => checkModelChanges(event, "/create")}
                        activeClassName={classes.active}
                        exact
                        to="/create"
                    >
                        <CreateSquare /> {!isCollapsedDone ? "Create new model" : ""}
                    </NavLink>
                </li>
                <li className={`${isCollapsedDone && classes.listItemCollapsed}`}>
                    <NavLink
                        className={`${window.location.pathname.includes("/models/") && classes.active}`}
                        onClick={(event) => checkModelChanges(event, "/dashboard")}
                        activeClassName={classes.active}
                        to="/dashboard"
                    >
                        <Houses /> <p style={{ padding: "0" }}>{!isCollapsedDone && "House Models"}</p>
                    </NavLink>
                </li>
                <li className={`${isCollapsedDone && classes.listItemCollapsed}`}>
                    <NavLink
                        onClick={(event) => checkModelChanges(event, "/code-library")}
                        activeClassName={classes.active}
                        to="/code-library"
                    >
                        <CodeLib /> {!isCollapsedDone ? "Code Library" : ""}
                    </NavLink>
                </li>
                <li className={`${isCollapsedDone && classes.listItemCollapsed}`}>
                    <NavLink
                        onClick={(event) => checkModelChanges(event, "/upgrade-library")}
                        activeClassName={classes.active}
                        to="/upgrade-library"
                    >
                        <Upgrades /> {!isCollapsedDone ? "Upgrade Library" : ""}
                    </NavLink>
                </li>
                <li className={`${isCollapsedDone && classes.listItemCollapsed}`}>
                    <span onClick={() => toggleSystems(true)}>
                        <Star fill="#fff" /> {!isCollapsedDone ? "Favourite Systems" : ""}
                    </span>
                </li>
                {ENVIROCENTRE_REPORT && (
                    <li className={`${classes.generate} ${isCollapsedDone && classes.listItemCollapsed}`}>
                        <NavLink
                            activeClassName={classes.active}
                            onClick={(event) => checkModelChanges(event, "/generate-report")}
                            to="/generate-report"
                        >
                            <GenerateIcon /> {!isCollapsedDone ? "Report Generator" : ""}
                        </NavLink>
                    </li>
                )}
                {CBAT_INPUT && (
                    <li className={classes.generate}>
                        <NavLink
                            activeClassName={classes.activeParametric}
                            onClick={(event) => checkModelChanges(event, "/parametric-analysis")}
                            to="/parametric-analysis"
                        >
                            <ParametricIcon /> {!isCollapsedDone ? "Parametric Analysis" : ""}
                        </NavLink>
                    </li>
                )}
                {(CHBA_NZ_ADMIN || VOLTA_DEV) && (
                    <li className={`${isCollapsedDone && classes.listItemCollapsed}`}>
                        <NavLink
                            className={`${window.location.pathname.includes("/communities/") && classes.active}`}
                            activeClassName={classes.active}
                            onClick={(event) => checkModelChanges(event, "/communities/dashboard")}
                            to="/communities/dashboard"
                        >
                            <CommunitiesIcon /> {!isCollapsedDone ? "Communities" : ""}
                        </NavLink>
                    </li>
                )}
            </ul>
            <FavouriteSystems open={systemsDialog} toggleDialog={toggleSystems} />
            <UnSavedChanges
                open={unsavedDialog}
                eventTarget={eventTarget}
                history={history}
                toggleDialog={toggleUnsavedDialog}
            />
            <div className={classes.collapse}>
                {!isCollapsedDone && <div className={classes.versionText}>v0.2.5</div>}

                <div
                    // large
                    className={`${classes.collapseButton} ${isCollapsed && classes.rotated} `}
                    type="slate"
                    onClick={(e) => handleCollapsed(e)}
                >
                    <img src={ArrowLeft} />
                </div>
            </div>
        </div>
    );
};

export default Navigation;
