import React, { useRef, useEffect, useState, useMemo } from "react";
import { isEqual } from "lodash";
import { DragOverlay } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";

import { getValidation, required, maxLength } from "utils/fieldValidation";

import Accordion from "components/Accordion";
import Measurements from "./Measurements/container";
import Construction from "./Construction/container";
import Actions from "../Actions/container";
import CeilingStats from "./CeilingStats/container";
import Window from "../Window/container";
import ComponentHeading from "../ComponentHeading";
import Droppable from "components/DragAndDrop/Droppable";
import ComponentType from "../ComponentType";

import classes from "./style.module.scss";
import sharedClasses from "../style.module.scss";

import CeilingIcon from "assets/images/components/Ceiling.svg";

const charLim32 = maxLength(32);

const ceilingLabelValidation = [required, charLim32, ...getValidation("ceilingLabel")];

const MemoizedActions = ({
    nameField,
    label,
    type,
    parentPath,
    componentId,
    dragHandleProps,
    newComponentDisplay,
    drawingLines,
    change,
    nameDialogOpen,
    toggleNameDialog,
}) =>
    useMemo(() => {
        return (
            <Actions
                nameField={nameField}
                label={label}
                type={type}
                parentPath={parentPath}
                componentId={componentId}
                dragHandleProps={dragHandleProps}
                newComponentDisplay={newComponentDisplay}
                drawingLines={drawingLines}
                change={change}
                nameDialogOpen={nameDialogOpen}
                toggleNameDialog={toggleNameDialog}
            />
        );
    }, [nameField, label, type, parentPath, componentId, dragHandleProps, newComponentDisplay, drawingLines, change]);

export default React.memo(
    ({
        accessor,
        label = "",
        change,
        componentId,
        isNew = false,
        isRecentDuplicate = false,
        subComponents,
        parentPath = "",
        newestComponent,
        dragHandleProps,
        allowedCategories,
        dragActive,
        clearNewComponent,
        isDrawingComponent,
        drawingLines,
        contentOpen = false,
        activeId,
        isDragging,
        mapSubComponents,
        subCompObj,
        dropPosition,
    }) => {
        const ref = useRef(null);

        const [nameDialogOpen, toggleNameDialog] = useState(false);
        const [items, setItems] = useState([]);

        useEffect(() => {
            if (!isDragging) setItems(mapSubComponents);
        }, [isDragging, mapSubComponents]);

        useEffect(() => {
            if (isNew && !isRecentDuplicate) {
                ref.current.scrollIntoView();
            }
        }, [isNew, isRecentDuplicate]);

        const lastComponentDisplayOrder = items.reduce(
            ({ displayOrder: prevDisplay }, { displayOrder: currentDisplay }) =>
                prevDisplay > currentDisplay ? prevDisplay : currentDisplay,
            0
        );

        const activeSubIndex = activeId ? items.findIndex(({ id }) => id === activeId) : -1;

        return (
            <div
                className={`${classes.ceiling}  ${
                    dropPosition === "after"
                        ? sharedClasses.activeAfter
                        : dropPosition === "before"
                        ? sharedClasses.activeBefore
                        : ""
                } `}
            >
                <span ref={ref} className={sharedClasses.componentRef}></span>
                <Accordion
                    className={sharedClasses.accordion}
                    heading={
                        <ComponentHeading
                            label={label}
                            componentId={componentId}
                            isDrawingComponent={isDrawingComponent}
                            icon={CeilingIcon}
                            toggleNameDialog={toggleNameDialog}
                        />
                    }
                    large
                    id={componentId}
                    initOpenState={isNew && !isRecentDuplicate}
                    isNew={isNew}
                    isDragging={isDragging}
                    dragActive={dragActive}
                    toggleNew={() => clearNewComponent(componentId)}
                    hoverActions={
                        <Actions
                            nameField={{
                                name: `${accessor}.label`,
                                validate: ceilingLabelValidation,
                                current: label,
                                change,
                            }}
                            label="Ceiling"
                            type="ceiling"
                            parentPath={parentPath}
                            componentId={componentId}
                            dragHandleProps={dragHandleProps}
                            newComponentDisplay={lastComponentDisplayOrder + 1}
                            drawingLines={drawingLines}
                            change={change}
                            nameDialogOpen={nameDialogOpen}
                            toggleNameDialog={toggleNameDialog}
                        />
                    }
                    stats={() => <CeilingStats accessor={accessor} />}
                    forceOpen={contentOpen}
                >
                    <h4>Measurements</h4>
                    <Measurements accessor={accessor} change={change} isDrawingComponent={isDrawingComponent} />
                    <h4>Construction</h4>
                    <Construction accessor={accessor} change={change} id={componentId} />
                </Accordion>
                <SortableContext
                    items={items}
                    strategy={verticalListSortingStrategy}
                    id={`ceiling.${componentId}`}
                    disabled={!isDragging ? false : !allowedCategories.includes("ceiling")}
                >
                    <div
                        className={`${sharedClasses.subComponents} ${
                            items.length === 0 && sharedClasses.noSubComponents
                        } ${dragActive ? sharedClasses.dragActive : ""}`}
                    >
                        {items.length === 0 && (
                            <span className={sharedClasses.noSubcomponents}>
                                &nbsp;
                                {allowedCategories.includes("ceiling") && (
                                    <Droppable id={`ceiling.${componentId}`}></Droppable>
                                )}
                            </span>
                        )}
                        {items.map(({ id, componentId: subComponentId, componentType }, index) => (
                            <div key={subComponentId} className={classes.draggableComponent}>
                                <ComponentType
                                    key={id}
                                    id={id}
                                    index={index + 1}
                                    activeIndex={activeSubIndex}
                                    activeId={activeId}
                                    draggableId={`ceiling.${componentId}.subcomponents.${componentType}.${subComponentId}`}
                                    typeDrop={"component"}
                                    isDragging={isDragging}
                                    componentId={componentId}
                                    type={componentType}
                                    change={change}
                                    parentAccessor={accessor}
                                    parentPath={`${parentPath}.ceiling.${componentId}.subcomponents`}
                                    isNewest={newestComponent === subComponentId}
                                    // isDragging={snapshot.isDragging}
                                    clearNewComponent={clearNewComponent}
                                />
                            </div>
                        ))}
                    </div>
                </SortableContext>
                <DragOverlay>
                    {activeId && activeSubIndex >= 0 ? (
                        <ComponentType
                            id={activeId}
                            activeId={activeId}
                            clone={true}
                            label={items[activeSubIndex].componentLabel}
                            type={items[activeSubIndex].componentType}
                            componentId={items[activeSubIndex].componentId}
                            parentAccessor={accessor}
                            parentPath={`${parentPath}.ceiling.${componentId}.subcomponents`}
                            items={items}
                            allowedCategories={[]}
                            destination={{}}
                            isDragging={isDragging}
                            typeDrop={"component"}
                        />
                    ) : null}
                </DragOverlay>
            </div>
        );
    },
    isEqual
);
