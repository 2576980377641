import types from "./types";
import { updateObject } from "utils";

const initialState = {
    initCodeValues: {},
    drawingChanges: {},
    calculatingRValue: {},
    codeDrawer: {},
    compositeRCalc: {},
    foundationConfig: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.SET_INIT_CODE:
            return setInitialCode(state, action);
        case types.UPDATE_DRAWING_CHANGES:
            return updateDrawingChanges(state, action);
        case types.CLEAR_ENCLOSURE_STATE:
            return clearState(state, action);
        case types.CACLULATING_R_VALUE:
            return calculatingRValue(state, action);
        case types.CLEAR_INIT_CODE:
            return clearInitCode(state, action);
        case types.UPDATE_CODE_DRAWER:
            return updateCodeDrawer(state, action);
        case types.UPDATE_COMPOSITE_CALC:
            return updateCompositeCalc(state, action);
        case types.CLEAR_COMPOSITE_CALC:
            return clearCompositeCalc(state, action);
        case types.UPDATE_FOUNDATION_CONFIG:
            return updateFoundationConfig(state, action);
        case types.CLEAR_FOUNDATION_CONFIG:
            return clearFoundationConfig(state, action);

        default:
            return state;
    }
};

const clearState = (state, action) => updateObject(state, initialState);

const updateDrawingChanges = (state, action) => {
    const { [action.componentId]: stateComponentData } = state.drawingChanges;

    return updateObject(state, {
        drawingChanges: updateObject(state.drawingChanges, {
            [action.componentId]: updateObject(stateComponentData, action.updates),
        }),
    });
};

const setInitialCode = (state, action) => {
    const { initCodeValues = {} } = state;

    return updateObject(state, {
        initCodeValues: updateObject(initCodeValues, {
            [action.codeType]: action.initCodeValues,
        }),
    });
};

const clearInitCode = (state, action) => {
    return updateObject(state, {
        initCodeValues: {},
    });
};

const calculatingRValue = (state, action) => {
    const { calculatingRValue = {} } = state;
    const { [action.componentId]: component = {} } = calculatingRValue;

    return updateObject(state, {
        calculatingRValue: updateObject(calculatingRValue, {
            [action.componentId]: updateObject(component, {
                [action.fieldId]: action.calculating,
            }),
        }),
    });
};

const updateCodeDrawer = (state, action) => {
    const { codeDrawer = {} } = state;
    return updateObject(state, {
        codeDrawer: updateObject(codeDrawer, action.updates),
    });
};

const updateCompositeCalc = (state, action) =>
    updateObject(state, {
        compositeRCalc: {
            ...state.compositeRCalc,
            ...action.updates,
        },
    });

const clearCompositeCalc = (state, action) =>
    updateObject(state, {
        compositeRCalc: {},
    });

const updateFoundationConfig = (state, action) =>
    updateObject(state, {
        foundationConfig: {
            ...state.foundationConfig,
            ...action.updates,
        },
    });

const clearFoundationConfig = (state, action) =>
    updateObject(state, {
        foundationConfig: {},
    });
