const SET_INIT_CODE = "SET_INIT_CODE";
const CLEAR_INIT_CODE = "CLEAR_INIT_CODE";
const UPDATE_DRAWING_CHANGES = "UPDATE_DRAWING_CHANGES";
const CLEAR_ENCLOSURE_STATE = "CLEAR_ENCLOSURE_STATE";
const CACLULATING_R_VALUE = "CACLULATING_R_VALUE";
const UPDATE_CODE_DRAWER = "UPDATE_CODE_DRAWER";
const UPDATE_COMPOSITE_CALC = "UPDATE_COMPOSITE_CALC";
const CLEAR_COMPOSITE_CALC = "CLEAR_COMPOSITE_CALC";
const UPDATE_FOUNDATION_CONFIG = "UPDATE_FOUNDATION_CONFIG";
const CLEAR_FOUNDATION_CONFIG = "CLEAR_FOUNDATION_CONFIG";
const COUNT_BUILDING_SURFACE_AREA = "COUNT_BUILDING_SURFACE_AREA";

export default {
  SET_INIT_CODE,
  CLEAR_INIT_CODE,
  UPDATE_DRAWING_CHANGES,
  CLEAR_ENCLOSURE_STATE,
  CACLULATING_R_VALUE,
  UPDATE_CODE_DRAWER,
  UPDATE_COMPOSITE_CALC,
  CLEAR_COMPOSITE_CALC,
  UPDATE_FOUNDATION_CONFIG,
  CLEAR_FOUNDATION_CONFIG,
  COUNT_BUILDING_SURFACE_AREA,
};
