import types from "./types";
import { firestore, USERS_COLL, ORG_COLL, fieldValue } from "_firebase";
import moment from "moment";

const getUser = (uid) => firestore.doc(`${USERS_COLL}/${uid}`).get();

const getUserDir = (uid) => firestore.doc(`${USERS_COLL}/${uid}`).collection("modelDir").doc("directory").get();

const getUserCommDir = (uid) => firestore.doc(`${USERS_COLL}/${uid}`).collection("commDir").doc("directory").get();

const getBatchDir = (uid) => firestore.doc(`${USERS_COLL}/${uid}`).collection("batchDir").doc("directory").get();

const getUserOrg = (orgId) => firestore.doc(`${ORG_COLL}/${orgId}`).get();

const getUserCodeLib = (uid) => firestore.collection(`${USERS_COLL}/${uid}/codeLib`).get();

const getUserCodeLibOverflow = (uid, codeType) =>
    firestore.collection(`${USERS_COLL}/${uid}/codeLib/${codeType}/overflowCodes`).get();

const sendCodeToLib = (uid, code, componentType, codeRef, lastDocId = "") =>
    firestore
        .doc(`${USERS_COLL}/${uid}/codeLib/${componentType}${lastDocId !== "" ? "/overflowCodes/" + lastDocId : ""}`)
        .update({
            [`codes.${codeRef}`]: code,
        });

const deleteFromCodeLib = ({ uid, componentType, codeRef }) =>
    firestore
        .doc(`${USERS_COLL}/${uid}/codeLib/${componentType}`)
        .update({
            [`codes.${codeRef}`]: fieldValue.delete(),
        })
        .catch((err) => console.log("err", err));

const updateUserData = (uid, updates) => {
    return firestore.doc(`${USERS_COLL}/${uid}`).update(updates);
};

const updateUserMetaInFirebase = (uid, updates) => {
    return firestore.doc(`${USERS_COLL}/${uid}`).update({
        userMeta: updates,
    });
};

const setUserData = (userData) => ({
    type: types.GET_USER_DATA,
    userData,
});

const setUserDir = (modelDir) => ({
    type: types.GET_USER_DIR,
    modelDir,
});

const setUserCommDir = (commDir) => ({
    type: types.GET_USER_COMM_DIR,
    commDir,
});

const setBatchDir = (batchDir) => ({
    type: types.GET_USER_BATCH_DIR,
    batchDir,
});

const setUserOrg = (orgId, organizationData) => ({
    type: types.GET_USER_ORG,
    orgId,
    organizationData,
});

const removeFromDir = (modelId) => ({
    type: types.REMOVE_FROM_DIR,
    modelId,
});

const setUserCodeLib = (codeLib) => ({
    type: types.GET_USER_CODE_LIB,
    codeLib,
});

const userStart = () => ({
    type: types.GET_USER_DATA_START,
});

const userFail = (error) => ({
    type: types.GET_USER_DATA_FAIL,
    error,
});

const updateUser = (data) => ({
    type: types.UPDATE_USER,
    data,
});

const updateUserMeta = (data) => ({
    type: types.UPDATE_USER_META,
    data,
});

const updateUserPreferences = (data) => ({
    type: types.UPDATE_USER_PREFERENCES,
    data,
});

const toggleDirLoading = (modelDirLoading) => ({
    type: types.TOGGLE_DIR_LOADING,
    modelDirLoading,
});

const toggleCommDirLoading = (commDirLoading) => ({
    type: types.TOGGLE_COMM_DIR_LOADING,
    commDirLoading,
});

const toggleBatchDirLoading = (batchDirLoading) => ({
    type: types.TOGGLE_BATCH_DIR_LOADING,
    batchDirLoading,
});

const toggleOrganizationLoading = (batchDirLoading) => ({
    type: types.TOGGLE_ORG_LOADING,
    batchDirLoading,
});

const setCodeInLib = (code, componentType, codeRef) => ({
    type: types.SET_CODE_IN_LIB,
    code,
    componentType,
    codeRef,
});

const removeCodeFromLib = (componentType, codeRef) => ({
    type: types.REMOVE_CODE_FROM_LIB,
    componentType,
    codeRef,
});

const fetchUserData = (uid) => (dispatch) => {
    if (!uid) return null;

    return getUser(uid)
        .then((data) => {
            dispatch(setUserData(data.data()));
            return data.data();
        })
        .catch((error) => dispatch(userFail("There was a problem fetching user data")));
};

const fetchUserDir =
    (uid, skipLoading = false) =>
    (dispatch) => {
        if (!uid) return null;

        if (!skipLoading) dispatch(toggleDirLoading(true));

        return getUserDir(uid)
            .then((data) => {
                return dispatch(setUserDir(data.data()));
            })
            .then((data) => dispatch(toggleDirLoading(false)))
            .catch(() => dispatch(userFail("There was a problem fetching user directory")));
    };

const fetchUserCommDir =
    (uid, skipLoading = false) =>
    (dispatch) => {
        if (!uid) return null;

        if (!skipLoading) dispatch(toggleCommDirLoading(true));

        return getUserCommDir(uid)
            .then((data) => {
                return dispatch(setUserCommDir(data.data()));
            })
            .then((data) => dispatch(toggleCommDirLoading(false)))
            .catch(() => dispatch(userFail("There was a problem fetching user's community directory")));
    };

const fetchUserBatchDir = (uid) => (dispatch) => {
    if (!uid) return null;

    dispatch(toggleBatchDirLoading(true));
    return getBatchDir(uid)
        .then((data) => dispatch(setBatchDir(data.data())))
        .then((data) => dispatch(toggleBatchDirLoading(false)))
        .catch(() => dispatch(userFail("There was a problem fetching user's batch directory")));
};

const fetchUserOrganization = (uid, orgId) => (dispatch) => {
    if (!uid) return null;
    if (!orgId) return null;

    // console.log("in fetchUserOrganization", uid, orgId);

    dispatch(toggleOrganizationLoading(true));
    return getUserOrg(orgId)
        .then((data) => dispatch(setUserOrg(orgId, data.data())))
        .then((data) => dispatch(toggleOrganizationLoading(false)))
        .catch((err) => dispatch(userFail("There was a problem fetching user's organization information")));
};

const fetchUserCodeLib = (uid) => (dispatch) => {
    if (!uid) return null;

    let codeLibObj = {};

    return getUserCodeLib(uid)
        .then(({ docs }) => {
            const docObj = docs.reduce(
                (prev, current) => ({
                    ...prev,
                    [current.id]: current.data(),
                }),
                {}
            );

            codeLibObj = docObj;
            return {};
        })
        .then(() => {
            return Promise.all(
                Object.keys(codeLibObj).map(async (codeType) => {
                    const codeLibOverflowSnapshot = await getUserCodeLibOverflow(uid, codeType);
                    return codeLibOverflowSnapshot.docs.map((doc) => {
                        const { codes } = doc.data() || {};

                        codeLibObj[codeType].codes = {
                            ...codeLibObj[codeType].codes,
                            ...codes,
                        };

                        return {};
                    });
                })
            );
        })
        .then(() => {
            dispatch(setUserCodeLib(codeLibObj));
        })
        .catch((err) => dispatch("There was a problem fetching user code library"));
};

const addToCodeLib = (uid, code, componentType, codeRef) => (dispatch) => {
    if (!uid) return null;

    const lastEdited = moment().format("LLLL");
    const lastEditedBy = uid;

    const updatedCode = { ...code, lastEdited, lastEditedBy };

    return getUserCodeLibOverflow(uid, componentType)
        .then((snapshot) => {
            let lastDocId = "";
            snapshot.docs.forEach((doc) => {
                const { codes = {} } = doc.data();
                if (Object.keys(codes).length < 10) {
                    lastDocId = doc.id;
                }
            });

            return lastDocId;
        })
        .then((lastDocId) => {
            return sendCodeToLib(uid, updatedCode, componentType, codeRef, lastDocId);
        })
        .then(() => dispatch(setCodeInLib(updatedCode, componentType, codeRef)))
        .catch(() => dispatch(userFail("There was a problem saving code to library")));
};

const deleteCode = (uid, codeRef, componentType) => (dispatch) => {
    if (!uid) return null;

    return deleteFromCodeLib({ uid, componentType, codeRef })
        .then(() => dispatch(removeCodeFromLib(componentType, codeRef)))
        .catch(() => dispatch(userFail("There was a problem removing code from library")));
};

const addUserTestingInfo =
    ({ uid, name, userMeta = {} }) =>
    (dispatch) => {
        if (!uid) return null;

        return updateUserData(uid, { name, userMeta })
            .then(() => dispatch(updateUser({ name })))
            .then(() => dispatch(updateUserMeta(userMeta)));
    };

const addUserPreferences =
    ({ uid, name, userMeta = {}, userPreferences = {} }) =>
    (dispatch, getState) => {
        if (!uid) return null;

        const newUserMeta = {
            ...getState().user.userMeta,
            ...userMeta,
        };

        const newUserPreferences = {
            ...getState().user.userPreferences,
            ...userPreferences,
        };

        return updateUserData(uid, {
            name,
            userMeta: newUserMeta,
            userPreferences: newUserPreferences,
        })
            .then(() => dispatch(updateUser({ name })))
            .then(() => dispatch(updateUserMeta(newUserMeta)))
            .then(() => dispatch(updateUserPreferences(newUserPreferences)));
    };

const saveUserMeta =
    ({ uid, userMeta = {} }) =>
    (dispatch, getState) => {
        if (!uid) return null;

        const newUserMeta = {
            ...getState().user.userMeta,
            ...userMeta,
        };

        return updateUserMetaInFirebase(uid, newUserMeta).then(() => dispatch(updateUserMeta(newUserMeta)));
    };

export default {
    fetchUserData,
    fetchUserDir,
    fetchUserCommDir,
    fetchUserBatchDir,
    fetchUserOrganization,
    fetchUserCodeLib,
    userFail,
    userStart,
    addToCodeLib,
    removeFromDir,
    addUserTestingInfo,
    deleteCode,
    saveUserMeta,
    updateUserData,
    addUserPreferences,
};
