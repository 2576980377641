import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import RadiantHeating from './';

const mapStateToProps = (
    {
        form,
        model:{
            contentOpen=false,
        }={}
    },
    {
        accessor,
        formName="model"
    }
) => {
    const selector = formValueSelector(formName);
    const modelSelector = formValueSelector('model');

    const modelUnits = modelSelector({form}, `modelData.uiSettings.primaryUnits`) || {};
    
    const atticTempUnits = selector({form}, `${accessor}.attic.temperature_u`);
    const atticAreaUnits = selector({form}, `${accessor}.attic.area_u`);
    const flatRoofTempUnits = selector({form}, `${accessor}.flatRoof.temperature_u`);
    const flatRoofAreaUnits = selector({form}, `${accessor}.flatRoof.area_u`);
    const crawlspaceTempUnits = selector({form}, `${accessor}.aboveCrawlspace.temperature_u`);
    const crawlspaceAreaUnits = selector({form}, `${accessor}.aboveCrawlspace.area_u`);
    const slabTempUnits = selector({form}, `${accessor}.slabOnGrade.temperature_u`);
    const slabAreaUnits = selector({form}, `${accessor}.slabOnGrade.area_u`);
    const basementFloorTempUnits = selector({form}, `${accessor}.aboveBasementFloor.temperature_u`);
    const basementFloorAreaUnits = selector({form}, `${accessor}.aboveBasementFloor.area_u`);
    const basementTempUnits = selector({form}, `${accessor}.basement.temperature_u`);
    const basementAreaUnits = selector({form}, `${accessor}.basement.area_u`);

    return {
        accessor,
        atticTempUnits,
        atticAreaUnits,
        flatRoofTempUnits,
        flatRoofAreaUnits,
        crawlspaceTempUnits,
        crawlspaceAreaUnits,
        slabTempUnits,
        slabAreaUnits,
        basementFloorTempUnits,
        basementFloorAreaUnits,
        basementTempUnits,
        basementAreaUnits,
        modelUnits,
        contentOpen
    }
};

const mapDispatchToProps = () => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RadiantHeating);