import React from "react";

export default () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M22 2L2 22" stroke="black" strokeWidth="2" />
        <rect
            x="1.5"
            y="19.5"
            width="3"
            height="3"
            rx="1.5"
            fill="black"
            stroke="black"
            strokeWidth="2"
        />
        <rect
            x="19.5"
            y="1.5"
            width="3"
            height="3"
            rx="1.5"
            fill="black"
            stroke="black"
            strokeWidth="2"
        />
    </svg>
);
