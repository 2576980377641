import React, { useEffect } from "react";
import { Field } from "redux-form";
import Button from "components/Button";
import classes from "./style.module.scss";
import netZeroClasses from "../style.module.scss";
import Export from "assets/images/icons/JSX/Export";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import InputWithUnits from "components/Input/InputWithUnits";
import WorkbookCheckbox from "components/Input/WorkbookCheckbox";
import DayPicker from "components/Input/DayPicker";
import moment from "moment";
import Delete from "assets/images/icons/JSX/Delete";
import { getBaseUnits, getUnitOptions } from "utils/fields";
import { getValidation } from "utils/fieldValidation";

const chbaNzTargetCompletionDateValidation = getValidation("chbaNzTargetCompletionDate");

const RoofMountedPVReady = ({
    accessor,
    change,
    roofSections,
    modelUnits,
    workspaceAttic,
    workspacePanel,
    currentDeclAddress = "",
    declarationAddress = "",
}) => {
    const dateId = moment.now();

    useEffect(() => {
        if (declarationAddress !== "" && currentDeclAddress === "") {
            change(`${accessor}.declaration.address`, declarationAddress);
        }
    }, [declarationAddress]);

    const addSection = (event) => {
        event.stopPropagation();
        if (Object.values(roofSections).length < 5) {
            change(`${accessor}.roofSectionSpecs.sections`, {
                ...roofSections,
                [`section-${dateId}`]: {
                    tiltAngle: 0,
                    azimuth: 0,
                    availableArea: 0,
                },
            });
        }
    };

    const deleteSection = (key) => {
        const { [key]: toRemove, ...sectionsToKeep } = roofSections;
        change(`${accessor}.roofSectionSpecs.sections`, sectionsToKeep);
    };

    return (
        <>
            <div className={classes.topRow}>
                <div>
                    <h3>Roof Section specifications</h3>
                </div>
                {/* <Button smallPadding icon={Export} className={`${netZeroClasses.exportButton} ${classes.exportButton}`}>
                    <span>Export Net Zero Workbook</span>
                </Button> */}
            </div>
            <div className={classes.topRow}>
                <div className={classes.inputColumn}>
                    {Object.keys(roofSections).map((id) => {
                        const availableAreaUnits = roofSections[id]?.availableArea_u;
                        return (
                            <InputRow key={id} gridTemplate="1fr 1fr 1fr 0.3fr">
                                <Field
                                    component={InputWithUnits}
                                    name={`${accessor}.roofSectionSpecs.sections.${id}.tiltAngle`}
                                    type="number"
                                    label="Tilt angle"
                                    change={change}
                                    units={{
                                        base: ["°"],
                                        options: ["°"],
                                        selected: ["°"],
                                        unitType: "angle",
                                        accessor: `${accessor}.roofSectionSpecs.tiltAngle_u`,
                                    }}
                                />
                                <Field
                                    component={InputWithUnits}
                                    name={`${accessor}.roofSectionSpecs.sections.${id}.azimuth`}
                                    type="number"
                                    label="Azimuth"
                                    change={change}
                                    units={{
                                        base: ["°"],
                                        options: ["°"],
                                        selected: ["°"],
                                        unitType: "angle",
                                        accessor: `${accessor}.roofSectionSpecs.azimuth_u`,
                                    }}
                                />
                                <Field
                                    component={InputWithUnits}
                                    name={`${accessor}.roofSectionSpecs.sections.${id}.availableArea`}
                                    type="number"
                                    label="Available area"
                                    change={change}
                                    units={{
                                        base: getBaseUnits("availableArea", modelUnits),
                                        options: getUnitOptions("area"),
                                        selected: availableAreaUnits,
                                        unitType: "area",
                                        accessor: `${accessor}.roofSectionSpecs.sections.${id}.availableArea_u`,
                                    }}
                                />
                                <div className={classes.delete} onClick={() => deleteSection(id)} type="red">
                                    <span data-tip="Delete" data-for={`delete-${id}`}>
                                        <Delete />
                                    </span>
                                </div>
                            </InputRow>
                        );
                    })}
                    <Button className={classes.addSectionButton} onClick={addSection}>
                        Add Roof Section
                    </Button>
                    <Field
                        className={classes.netZeroCheckbox}
                        component={WorkbookCheckbox}
                        name={`${accessor}.roofSectionSpecs.consultedMunicipality`}
                        label="Consulted with municipality to determine needs for ridge setbacks and / or pathway access"
                        type="checkbox"
                        checked
                    />
                    <Field
                        className={classes.netZeroCheckbox}
                        component={WorkbookCheckbox}
                        name={`${accessor}.roofSectionSpecs.solarReadyTrusses`}
                        label="Roof structure designed and constructed with Solar-Ready Trusses™ or equivalent and 
                        roof structure design will support additional loads of at least 0.17kPa or 3.5psf associated with PV system"
                        type="checkbox"
                    />
                </div>
            </div>
            <div className={classes.inputSection}>
                <div>
                    <h3>PV Conduit and Utility Connection Conduits</h3>
                    <div className={classes.inputColumn}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={WorkbookCheckbox}
                            name={`${accessor}.conduits.minOneConduit`}
                            label={`Minimum one PV conduit, sized 2.5 cm (1") if metallic or 5.1 cm 
                        (2") if non-metallic, run from the attic or roof to the electrical panel location`}
                            type="checkbox"
                        />
                        <Field
                            className={classes.netZeroCheckbox}
                            component={WorkbookCheckbox}
                            name={`${accessor}.conduits.twoConduits`}
                            label={`Two utility connection conduits, sized 3.2 cm (1 1/4) run from the 
                        indoor electrical panel to the outdoor utility meter and back`}
                            type="checkbox"
                        />
                    </div>
                </div>
                <div>
                    <h3>Installation and Termination of PV Conduit and Utility Connection Conduits</h3>
                    <div className={classes.inputColumn}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={WorkbookCheckbox}
                            name={`${accessor}.installTermConduits.installedWithinEnvelope`}
                            label="PV conduit installed entirely within the building envelope 
                        (except for section terminating above the roof if applicable)"
                            type="checkbox"
                        />
                        <Field
                            className={classes.netZeroCheckbox}
                            component={WorkbookCheckbox}
                            name={`${accessor}.installTermConduits.pullRopeInstalled`}
                            label="PV conduit with bends / elbows greater than 45 degrees requires 
                        pull-rope installed (Check if pull-rope installed)"
                            type="checkbox"
                        />
                        <Field
                            className={classes.netZeroCheckbox}
                            component={WorkbookCheckbox}
                            name={`${accessor}.installTermConduits.panelTerminationWorkspace`}
                            label="Electrical panel termination workspace provided, conduit sealed and capped"
                            type="checkbox"
                        />
                        <Field
                            className={classes.netZeroCheckbox}
                            component={WorkbookCheckbox}
                            name={`${accessor}.installTermConduits.utilityCappedSealed`}
                            label="Utility connection conduits capped and sealed"
                            type="checkbox"
                        />
                    </div>
                </div>
                <div>
                    <h3>Check one of the following</h3>
                    <div className={classes.inputColumn}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={WorkbookCheckbox}
                            name={`${accessor}.installTermConduits.workspace.atticTermination`}
                            label="Attic termination workspace provided, conduit sealed and capped"
                            type="checkbox"
                            setValue={workspacePanel != null ? !workspacePanel : null}
                            round
                        />
                        <Field
                            className={classes.netZeroCheckbox}
                            component={WorkbookCheckbox}
                            name={`${accessor}.installTermConduits.workspace.panelTermination`}
                            label="Electrical panel termination workspace provided,
                        conduit sealed and capped"
                            type="checkbox"
                            setValue={workspaceAttic != null ? !workspaceAttic : null}
                            round
                        />
                    </div>
                </div>
                <div>
                    <h3>Space / Electrical Panel Rating / Breaker Slot / Network Communications</h3>
                    <div className={classes.inputColumn}>
                        <InputRow gridTemplate="1fr 3fr" className={classes.netZeroField}>
                            <Field
                                component={InputWithUnits}
                                name={`${accessor}.miscellaneous.panelRatedAmps`}
                                type="number"
                                label="Panel rated amps for net-metering"
                                change={change}
                                units={{
                                    base: ["A"],
                                    options: ["A"],
                                    selected: ["A"],
                                    unitType: "current",
                                    accessor: `${accessor}.miscellaneous.panelRatedAmps_u`,
                                }}
                            />
                        </InputRow>
                        <Field
                            // className={classes.netZeroCheckbox}
                            component={WorkbookCheckbox}
                            name={`${accessor}.miscellaneous.wallSpace`}
                            label="Designated wall space near electrical panel provided for PV hardware"
                            type="checkbox"
                        />
                        <Field
                            className={classes.netZeroCheckbox}
                            component={WorkbookCheckbox}
                            name={`${accessor}.miscellaneous.doublePoleSlot`}
                            label="Available double pole slot at bottom of electrical panel for PV breaker"
                            type="checkbox"
                        />
                        <Field
                            className={classes.netZeroCheckbox}
                            component={WorkbookCheckbox}
                            name={`${accessor}.miscellaneous.wiredNetworkAvailable`}
                            label="Wired network communications available (Check if network 
                        jack provided at designated PV wall space)"
                            type="checkbox"
                        />
                    </div>
                </div>
                <div>
                    <h3>Code Compliance</h3>
                    <div className={classes.inputColumn}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={WorkbookCheckbox}
                            name={`${accessor}.codeCompliance.inspectionsPassed`}
                            label="Electrical Safety Code and Building Code inspections passed"
                            type="checkbox"
                        />
                    </div>
                </div>
                <div>
                    <h3>Identification of Components</h3>
                    <div className={classes.inputColumn}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={WorkbookCheckbox}
                            name={`${accessor}.identificationOfComponents.pvReadyDocsIncluded`}
                            label="Copies of the PV Ready documentation have been 
                        included in the home-buyer's information package, 
                        filed with the local building permits office, 
                        and affixed to the designated PV wall space on-site"
                            type="checkbox"
                        />
                    </div>
                </div>
            </div>
            <div>
                <div className={classes.confirmationHeader}>
                    <h3>Declaration, Name & Signature</h3>
                    <p>
                        I hereby confirm that the PV Ready upgrades have been installed in this house according to
                        Section II of NRCan's Photovoltaic Ready Guidelines.
                    </p>
                </div>
                <InputRow gridTemplate="65.92% 31.85%">
                    <Field
                        component={Input}
                        name={`${accessor}.declaration.address`}
                        type="text"
                        label="Net Zero Home Address, City, Province, Postal Code"
                    />
                    <Field
                        component={Input}
                        name={`${accessor}.declaration.signature`}
                        type="text"
                        label="Signature (print only)"
                        disabled
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr 1fr">
                    <Field component={Input} name={`${accessor}.declaration.name`} type="text" label="Name" />
                    <Field
                        component={Input}
                        name={`${accessor}.declaration.companyName`}
                        type="text"
                        label="Company Name"
                    />
                    <Field
                        component={DayPicker}
                        formatting="YYYY-MM-DD"
                        name={`${accessor}.declaration.date`}
                        label="Date of Commissioning"
                        validate={chbaNzTargetCompletionDateValidation}
                        disabledDays={{ before: null, after: null }}
                    />
                </InputRow>
            </div>
            {/* <div className={classes.buttonRow}>
                <Button>Export Net Zero Workbook</Button>
            </div> */}
        </>
    );
};

export default RoofMountedPVReady;
