export let generalTags = {
    all: {
        text: "All Images",
        id: "all",
    },
    untagged: {
        text: "Untagged",
        id: "untagged",
        number: 0,
    },
};

// Tax roll number
// Exterior Elevations (windows and doors) (3)
// Exterior wall (3)
// Basement wall (3)
// Crawl space (1)
// Header (1)
// Exposed floor
// Attic (2)
// Cathedral & flat ceilings
// Mechanical systems (6)
// Air course heat pump and central air conditioner (2)
// Drain water heat recovery system
// Solar domestic water heating system
// PV solar panel
// Atypical energy loads
// Solar Ready
// Toilets
// Additions / renovations
// Blower door
// Wind turbines
// Half storey
// Vermiculite exposed to the interior environment
