import React from "react";
import { pure } from "recompose";
import classes from "./style.module.scss";

const typeClasses = (type) =>
    ({
        hollow: classes.hollow,
        red: classes.red,
        white: classes.white,
        highlight: classes.highlight,
        emptyBg: classes.emptyBg,
        slate: classes.slate,
        lightGrey: classes.lightGrey,
        default: "",
    }[type || "default"]);

export default pure(
    ({
        children,
        type = "",
        onClick,
        disabled = false,
        large = false,
        small = false,
        className,
        smallPadding = false,
        icon: Icon,
        style = {},
        ...props
    }) => (
        <button
            style={style}
            className={`${classes.button} ${type ? typeClasses(type) : ""} ${large ? classes.large : ""} ${
                small ? classes.small : ""
            } ${className ? className : ""} ${Icon ? classes.hasIcon : ""} ${children ? classes.hasChildren : ""} ${
                smallPadding ? classes.smallPadding : ""
            }`}
            onClick={onClick}
            disabled={disabled}
            {...props}
        >
            <span>
                {Icon && <Icon />} {children}
            </span>
        </button>
    )
);
