import React, { useRef, useState } from "react";
import { Menu, MenuItem, Button } from "@material-ui/core";
import SnapButton from "components/Button";

import TSelect from "./components/TSelect/Tselect";
import TakeOffFoundation from "./containers/TakeOffFoundation";
import TakeoffStorey from "features/Model/Enclosure/Takeoff/containers/TakeOffStorey";
import TTable from "./TTable/TTable";
import Drawer from "components/Drawer";

import withTakeoff from "./withTakeOff";
import { capitalize } from "utils";
import { isEmpty } from "lodash";
import convertUnit from "utils/convertUnit";
import IDGenerator from "./TakeoffData/IDGenerator";
import Checkbox from "components/Input/Checkbox";

const processWindowTableInput = (window, ind, modelUnits) => {
    let { label = "", direction = "", width = 0, height = 0, overhang = 0, headerHeight = 0, qty = 1 } = window || {};

    let title = label;
    if (["S", "SE", "E", "NE", "N", "NW", "W", "SW"].includes(direction)) {
        title = `${title}_${direction}`;
    }

    if (modelUnits !== "Metric") {
        //Need to store metric values
        width = convertUnit({
            value: parseFloat(width),
            type: "length",
            inputUnit: "in",
            outputUnit: "mm",
        });
        height = convertUnit({
            value: parseFloat(height),
            type: "length",
            inputUnit: "in",
            outputUnit: "mm",
        });
        overhang = convertUnit({
            value: parseFloat(overhang),
            type: "length",
            inputUnit: "ft",
            outputUnit: "m",
        });
        headerHeight = convertUnit({
            value: parseFloat(headerHeight),
            type: "length",
            inputUnit: "ft",
            outputUnit: "m",
        });
    } else {
        width = parseFloat(width);
        height = parseFloat(height);
        overhang = parseFloat(overhang);
        headerHeight = parseFloat(headerHeight);
    }

    return {
        id: IDGenerator(`toff-table-${ind}`),
        title: { value: title, canEdit: false },
        width: { value: parseFloat(width.toFixed(4)), canEdit: false },
        height: { value: parseFloat(height.toFixed(4)), canEdit: false },
        ovWidth: { value: parseFloat(overhang.toFixed(4)), canEdit: false },
        ovHeight: { value: parseFloat(headerHeight.toFixed(4)), canEdit: false },
        qty: { value: Math.max(parseInt(qty), 1), canEdit: false },
    };
};

const getBlankRow = () => ({
    id: 0,
    title: { value: "Paste window data..." },
    width: { value: 0 },
    height: { value: 0 },
    ovWidth: { value: 0 },
    ovHeight: { value: 0 },
    qty: { value: 0 },
});

const Takeoff = ({
    id,
    takeoffState,
    dispatch,
    mode,
    toKey,
    name,
    handleAddTakeoff,
    foundationOptions,
    getFoundationName,
    translateComponent,
    formModelData,
    countBuildingSurfaceArea,
    addMultiRowTableComponent,
    modelUnits,
    // setDimensions,
    state,
}) => {
    const anchorRef = useRef();
    const [openMenu, setOpenMenu] = useState(false);
    const [windowsDrawer, setWindowsDrawer] = useState({
        parent: "",
        isOpen: false,
    });
    const [pastedWindows, setPastedWindows] = useState([]);
    const [windowTableData, setWindowTableData] = useState([getBlankRow()]);
    const [pastedWindowsTextArea, setPastedWindowsTextArea] = useState("");
    const [disableWindowImport, setDisableWindowImport] = useState(true);
    const [overwriteWindows, toggleOverwriteWindows] = useState(false);

    const cellName = {
        0: "label",
        1: "direction",
        2: "width",
        3: "height",
        4: "overhang",
        5: "headerHeight",
        6: "qty",
    };

    const handlePaste = (e) =>
        e.clipboardData.items[0].getAsString((text) => {
            setPastedWindows([]);
            setPastedWindowsTextArea(text);

            //Check that import contains at least 5 tabs
            if (text.split("\t").length < 5) {
                setPastedWindows([]);
                setWindowTableData([getBlankRow()]);
                setDisableWindowImport(true);
                return;
            }

            let lines = text.trimEnd().split("\n");

            const mappedLines = lines.map((line) => {
                const row = line.split("\t");
                const mapped = row.map((item, index) => ({
                    [cellName[index]]: item,
                }));
                const object = Object.assign({}, ...mapped);

                return object;
            });

            setPastedWindows(mappedLines);

            if (isEmpty(mappedLines)) {
                setPastedWindows([]);
                setWindowTableData([getBlankRow()]);
                setDisableWindowImport(true);
                return;
            }

            //Convert into format required for table inputs
            const tableData = mappedLines.map((window, ind) => processWindowTableInput(window, ind, modelUnits));

            setWindowTableData(tableData);
            setDisableWindowImport(false);
        });

    //must filter out "canEdit" before writing to state so that it can be later edited
    const handleWindowImport = () => {
        addMultiRowTableComponent(
            id,
            windowTableData.reduce((cache, window) => {
                return [
                    ...cache,
                    Object.keys(window).reduce((cache2, key) => {
                        if (key === "id") {
                            return {
                                ...cache2,
                                [key]: window[key],
                            };
                        }

                        const { canEdit, ...rest } = window[key] || {};
                        return {
                            ...cache2,
                            [key]: rest,
                        };
                    }, {}),
                ];
            }, []),
            overwriteWindows,
            false
        );
    };

    const handleWindowDrawerClose = () => {
        setWindowsDrawer({ parent: "", isOpen: false });
        setPastedWindows([]);
        setPastedWindowsTextArea("");
        setWindowTableData([getBlankRow()]);
        setDisableWindowImport(true);
    };

    //! This should NEVER happen. But should it happen, it allows the comp to fail independantly
    if (!mode) {
        return null;
    } else {
        return (
            <div className="takeoff-container" key={toKey}>
                <div className="takeoff-section-title-container">
                    <h3 className="takeoff-section-title">{name}</h3>
                    <div className="foundation takeoff-selection-div" style={{ justifyContent: "space-between" }}>
                        {name.toLowerCase() === "foundation" && "foundation" in takeoffState && (
                            <TSelect
                                style={{ marginTop: "1.93px" }}
                                value={takeoffState.foundation.compType}
                                onChange={handleAddTakeoff}
                                options={foundationOptions.filter((v) => v.value !== takeoffState.foundation.compType)}
                                getName={getFoundationName}
                                top={33}
                            />
                        )}
                        <Button
                            className="convert-takeoff-btn"
                            style={{ marginLeft: "auto" }}
                            onClick={() => {
                                if (name.toLowerCase() === "foundation" && "foundation" in takeoffState) {
                                    //"foundation", null

                                    translateComponent("foundation", null, formModelData);
                                    countBuildingSurfaceArea("foundation");
                                    // setDimensions("foundation", formModelData);
                                } else {
                                    //id, ""

                                    countBuildingSurfaceArea(id);
                                    translateComponent(id, "", formModelData);
                                    // setDimensions(id, formModelData);
                                }
                            }}
                        >
                            Convert Takeoff
                        </Button>
                    </div>
                </div>
                {/* <div className="takeoff-divider"> </div> */}

                <div className="component-wrapper">
                    {name.toLowerCase() === "foundation" && "foundation" in takeoffState ? (
                        <TakeOffFoundation
                            takeoffState={takeoffState}
                            dispatch={dispatch}
                            translateComponent={translateComponent}
                            modelUnits={modelUnits}
                            openWindowsDrawer={() =>
                                setWindowsDrawer({
                                    parent: "foundation",
                                    isOpen: true,
                                })
                            }
                        />
                    ) : (
                        <TakeoffStorey
                            takeoffState={takeoffState}
                            dispatch={dispatch}
                            storeyType={id}
                            translateComponent={translateComponent}
                            title={name}
                            modelUnits={modelUnits}
                            openWindowsDrawer={() => setWindowsDrawer({ parent: id, isOpen: true })}
                        />
                    )}
                </div>
                <Drawer
                    open={windowsDrawer.isOpen}
                    close={handleWindowDrawerClose}
                    title={`Window Table Inputs`}
                    subtitle={
                        windowsDrawer.parent === "foundation"
                            ? "Foundation"
                            : `${capitalize(windowsDrawer.parent)} Storey`
                    }
                >
                    <div>
                        <div className="window-table-instruction-text">
                            <p>Paste tabular window data matching the format of the table shown below.</p>
                            <p>
                                Exclude any column headers from the pasted data. Make sure that columns are in the
                                correct order and all units match the table headers.
                            </p>
                            <p>
                                Column order: Label, Direction, Width, Height, Overhang Width, Header Height, Count
                                (Optional).
                            </p>
                        </div>
                        <div style={{ marginTop: "2rem" }}>
                            <textarea
                                type="text"
                                style={{
                                    width: "100%",
                                    border: "1px solid grey",
                                    height: "150px",
                                    resize: "none",
                                }}
                                placeholder="Paste windows here..."
                                value={pastedWindowsTextArea}
                                onChange={(e) => {}}
                                onPaste={(e) => handlePaste(e)}
                            />
                        </div>

                        <TTable
                            dataset={windowTableData}
                            addTableComponent={() => {}}
                            removeTableComponent={() => {}}
                            editTableComponent={() => {}}
                            modelUnits={modelUnits}
                            hideAddRow
                        />
                        <Checkbox
                            label="Overwrite current window table?"
                            input={{
                                value: overwriteWindows,
                                onChange: () => {
                                    toggleOverwriteWindows(!overwriteWindows);
                                },
                            }}
                        />
                        <div className="drawer-buttons">
                            <SnapButton large type="hollow" onClick={handleWindowDrawerClose}>
                                Cancel
                            </SnapButton>
                            <SnapButton
                                large
                                onClick={() => {
                                    handleWindowImport();
                                    handleWindowDrawerClose();
                                }}
                                disabled={disableWindowImport}
                            >
                                Confirm Import
                            </SnapButton>
                        </div>
                    </div>
                </Drawer>
                <Menu
                    open={openMenu}
                    onClose={() => setOpenMenu(false)}
                    keepMounted={true}
                    anchorEl={anchorRef.current}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{
                        horizontal: "center",
                        vertical: "center",
                    }}
                >
                    <MenuItem
                        onClick={() => {
                            handleAddTakeoff("basement");
                            setOpenMenu(false);
                        }}
                    >
                        {" "}
                        Basement{" "}
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            handleAddTakeoff("walkout");
                            setOpenMenu(false);
                        }}
                    >
                        {" "}
                        Walkout{" "}
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            handleAddTakeoff("crawlspace");
                            setOpenMenu(false);
                        }}
                    >
                        {" "}
                        Crawlspace{" "}
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            handleAddTakeoff("slab");
                            setOpenMenu(false);
                        }}
                    >
                        {" "}
                        Slab-on-grade{" "}
                    </MenuItem>
                    {/* <MenuItem
                        onClick={() => {
                            handleAddTakeoff("basementCrawlspace");
                            setOpenMenu(false);
                        }}
                    >
                        {" "}
                        Basement-Crawlspace
                    </MenuItem> */}
                </Menu>
                <style>
                    {`
      .takeoff-container {
        display: flex;
        flex-flow: column nowrap;
        width: 100%;
        margin-top: 50px;     
      }

      .takeoff-section-title {
        
      }

      .takeoff-divider {
        margin: 16px 0 0 0;
        border: 0.5px solid #E1EAF0;
      }
      .takeoff-selection-div {
        display: flex;
        flex-flow: row nowrap;
        height: 32px;
        align-items: center;
        flex: 1;
      }
      .add-takeoff-btn {
        margin-left: auto;
        cursor: pointer;
      }
      .add-takeoff-btn svg {
        margin-bottom: -1px;
        margin-left: 5px;
      }    
      .component-wrapper {
        margin-top: 24px;
      }   

      .convert-takeoff-btn {
        font-weight: 700;
        font-size: 13px;
        line-height: 19px;
        color: #262E3F;
        text-transform: none;
        font-family: 'Noto Sans TC';
      }

      .takeoff-section-title-container {
        display: flex;
        align-items: center;
        gap: 1.5rem;
        background-color: #EFF4F7;
        margin-right: -2rem;
        margin-left: -2rem;
        padding-right: 2rem;
        padding-left: 2rem;
        padding-top: 15px;
        padding-bottom: 15px;
      }

      .window-table-instruction-text {
        font-size: 14px;
        

        >p {
            color: #262e3f;
            margin-bottom: 1rem;
        }
      }

      .drawer-buttons {
        display: grid;
        grid-template-columns: 12.5rem 12.5rem;
        column-gap: 1.25rem;
        align-items: center;
        justify-content: center;
        margin-top: 2.5rem;
        margin-bottom: 3.5rem;
      }
    `}
                </style>
            </div>
        );
    }
};

export default withTakeoff(Takeoff);
