import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import AdditionalOpenings from './';

const mapStateToProps = (
    {
        form,
        model:{
            contentOpen=false,
        }={}
    },
    {
        accessor,
        formName="model"
    }
) => {
    const selector = formValueSelector(formName);
    const modelSelector = formValueSelector('model');

    const modelUnits = modelSelector({form}, `modelData.uiSettings.primaryUnits`) || {};
    const { id:buildingTypeId } = modelSelector({form}, `modelData.specifications.buildingType`) || {}; 
    
    const isMURB = buildingTypeId !== 0; 

    const { id:opening1Type } = selector({form}, `${accessor}.openings.opening0.type`) || {};
    const { id:opening2Type } = selector({form}, `${accessor}.openings.opening1.type`) || {};
    const { id:opening3Type } = selector({form}, `${accessor}.openings.opening2.type`) || {};

    const flue1Units = selector({form}, `${accessor}.openings.opening0.flueDiameter_u`);
    const flue2Units = selector({form}, `${accessor}.openings.opening1.flueDiameter_u`);
    const flue3Units = selector({form}, `${accessor}.openings.opening2.flueDiameter_u`);
    

    return {
        accessor,
        isMURB,
        opening1Type,
        opening2Type,
        opening3Type,
        flue1Units,
        flue2Units,
        flue3Units,
        modelUnits,
        contentOpen
    }
};

const mapDispatchToProps = () => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdditionalOpenings);