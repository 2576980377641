import React, { useState } from "react";
import classes from "./style.module.scss";
import { Field } from "redux-form";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import Select from "components/Input/Select";
import DayPicker from "components/Input/DayPicker";
import { getValidation } from "utils/fieldValidation";
import { getOptions } from "utils/fields";
import Checkbox from "components/Input/Checkbox";
import Button from "components/Button";
import Dialog from "components/Dialog";
import { CloseDialog } from "components/Dialog";

const chbaNzRenovatorNameValidation = getValidation("chbaNzRenovatorFirstName");
const chbaNzRenovatorCompanyValidation = getValidation("chbaNzRenovatorCompany");
const chbaNzRenovatorEmailValidation = getValidation("chbaNzRenovatorEmail");
const chbaNzRenovatorPhoneNumberValidation = getValidation("chbaNzRenovatorPhoneNumber");
const chbaNzTargetLabelValidation = getValidation("chbaNzTargetLabel");
const chbaNzTargetCompletionDateValidation = getValidation("chbaNzTargetCompletionDate");
const chbaNzFinalRenoCostValidation = getValidation("chbaNzFinalRenoCost");

export default React.memo(({ accessor, change, chbaLabelId, handleFileTag }) => {
    const [tagDialogOpen, toggleTagDialog] = useState(false);
    const [tagging, toggleTagging] = useState(false);

    const chbaLabelOptions = getOptions({ fieldKey: "chbaNzTargetLabel" });

    const selectedLabel = chbaLabelOptions.find((opt) => opt?.value?.id === chbaLabelId)?.label || "";

    return (
        <>
            <InputRow gridTemplate="1fr 1fr 1fr">
                <Field
                    component={Select}
                    type="number"
                    name={`${accessor}.targetLabel`}
                    label="Target CHBA Label"
                    placeholder={"Select a Target Label"}
                    options={chbaLabelOptions}
                    validate={chbaNzTargetLabelValidation}
                />
                <div></div>
                <Button
                    className={classes.tagButton}
                    smallPadding
                    disabled={chbaLabelId == null}
                    onClick={() => toggleTagDialog(true)}
                >
                    Tag File
                </Button>
            </InputRow>
            <InputRow gridTemplate="1fr 1fr 1fr">
                <Field
                    component={Input}
                    type="text"
                    name={`${accessor}.renovatorName`}
                    label="Renovator Name"
                    placeholder=""
                    validate={chbaNzRenovatorNameValidation}
                    change={change}
                />
                <Field
                    component={Input}
                    type="text"
                    name={`${accessor}.renovatorEmail`}
                    label="Email"
                    placeholder=""
                    validate={chbaNzRenovatorEmailValidation}
                    change={change}
                />
                <Field
                    component={Input}
                    type="text"
                    name={`${accessor}.renovatorPhone`}
                    label="Phone Number"
                    placeholder=""
                    validate={chbaNzRenovatorPhoneNumberValidation}
                    change={change}
                />
            </InputRow>
            <InputRow gridTemplate="1fr 1fr 1fr">
                <Field
                    component={Input}
                    type="text"
                    name={`${accessor}.renovatorCompany`}
                    label="Company Name"
                    placeholder=""
                    validate={chbaNzRenovatorCompanyValidation}
                    change={change}
                />
            </InputRow>
            <InputRow gridTemplate="1fr 1fr 1fr">
                <Field
                    component={DayPicker}
                    formatting="YYYY-MM-DD"
                    name={`${accessor}.targetCompletionDate`}
                    label="Target Completion Date"
                    validate={chbaNzTargetCompletionDateValidation}
                    disabledDays={{ before: null, after: null }}
                />
                <div></div>
                <Field
                    component={Input}
                    name={`${accessor}.totalRenovationCost`}
                    label="Final Renovation Cost"
                    type="number"
                    decimals={2}
                    validate={chbaNzFinalRenoCostValidation}
                />
            </InputRow>
            <Dialog open={tagDialogOpen}>
                <CloseDialog closeActions={() => toggleTagDialog(false)} />
                <div className={classes.dialog}>
                    <h3>
                        Are you sure you want to tag this file as a <strong>CHBA {selectedLabel}</strong> file?
                    </h3>
                    <div className={classes.tagDisclaimer}>
                        <h4>
                            Tagging this file will share it (read-only access) with the following CHBA Net Zero
                            Renovation project administrators and project support staff:
                        </h4>
                        <ul>
                            <li>CHBA National - Project Team</li>
                            <li>Building Knowledge Canada - Lead Consultant</li>
                            <li>Volta SNAP Administrators</li>
                        </ul>
                    </div>
                    <div className={classes.footer}>
                        <Button type="hollow" onClick={() => toggleTagDialog(false)}>
                            Cancel
                        </Button>
                        <Button
                            onClick={async () => {
                                toggleTagging(true);
                                await handleFileTag();
                                toggleTagDialog(false);
                                toggleTagging(false);
                            }}
                            disabled={tagging}
                        >
                            {tagging ? "Tagging..." : "Proceed"}
                        </Button>
                    </div>
                </div>
            </Dialog>
        </>
    );
});
