export const getContinuousResistivity = (type) =>
    ({
        0: 0.00173,
        1: 0.0061,
        2: 0.00866,
        3: 0.0095,
        4: 0.0077,
        5: 0.0165,
        6: 0.015,
        7: 0.00965,
        8: 0.0085,
        9: 0.016,
        10: 0.0063,
        11: 0.00076,
        12: 0.00113,
        13: 0.00151,
        14: 0.00058,
        15: 0.0013,
        16: 0.0069,
        17: 0.001105,
        18: 0.000897,
        19: 0.001357,
        20: 0.001105,
        21: 0.002857,
        22: 0.002684,
        23: 0.00693,
        24: 0.0009,
        25: 0.0009,
        26: 0.00058,
        27: 0.0004,
        28: 0.0003,
        29: 0.0004,
        30: 0.01417,
        31: 0.0125,
        32: 0.015,
        33: 0.007,
        34: 0.009473,
        35: 0.010909,
        36: 0.010909,
        37: 0.011,
        38: 0.033684,
        39: 0.025,
        40: 0.0005,
        41: 0.0012,
        42: 0.029134,
        43: 0.017323,
        44: 0.00467,
        45: 0.00056,
        46: 0.00632,
        47: 0.003105,
        48: 0.00018,
        49: 0.0081,
        50: 0.0081,
        51: 0.0062,
    }[type]);

export const getCavityResistivity = (type) =>
    ({
        0: 0.022031,
        1: 0.02,
        2: 0.0236,
        3: 0.019775,
        4: 0.023708,
        5: 0.02764,
        6: 0.023857,
        7: 0.023158,
        8: 0.027643,
        9: 0.030214,
        10: 0.027697,
        11: 0.022824,
        12: 0.024324,
        13: 0.02126,
        14: 0.022656,
        15: 0.0243,
        16: 0.0217,
        17: 0.0243,
        18: 0.023071,
        19: 0.02514,
        20: 0.025233,
        21: 0.023467,
        22: 0.01875,
        23: 0.01875,
        24: 0.0237,
        25: 0.0253,
        26: 0.0144,
        27: 0.0231,
        28: 0.0169,
        29: 0.0085,
        30: 0.01005,
        31: 0.017,
        32: 0.0208,
        33: 0.0298,
        34: 0.03,
        35: 0.0257,
        36: 0.0277,
        37: 0.0347,
        38: 0.03818,
        39: 0.0305,
        40: 0.0194,
        41: 0.0182,
        42: 0.0257,
        43: 0.036,
        44: 0.024,
        45: 0.02615,
        46: 0.0201,
        47: 0.02496,
    }[type]);

export const getFrameResistivity = (type) =>
    ({
        0: 0.0056,
        1: 0.0055,
        2: 0.0063,
        3: 0.0063,
        4: 0.00655,
        5: 0.0085,
        6: 0.0102,
    }[type]);
