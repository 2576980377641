export const getSValidations = (input, type, field = "", property = "") => {
    switch (type) {
        case "string": {
            if (input.length > 0) {
                return {
                    err: false,
                    msg: "",
                };
            } else {
                return {
                    err: true,
                    msg: "Must have at least one character",
                };
            }
        }
        case "count": {
            if (property === "numGables") {
                if (input >= 0 && input <= 2) {
                    return {
                        err: false,
                        msg: "",
                    };
                } else {
                    return {
                        err: true,
                        msg: "Must be between 0 and 2",
                    };
                }
            } else {
                if (input >= 0 && input < 33) {
                    return {
                        err: false,
                        msg: "",
                    };
                } else {
                    return {
                        err: true,
                        msg: "Must be a number between 0 and 32",
                    };
                }
            }
        }
        default:
            if ((input) => 0 && input < 9999.99) {
                return {
                    err: false,
                    msg: "",
                };
            } else {
                return {
                    err: true,
                    msg: "Must be a number between 0 and 9999",
                };
            }
    }
};
