import React, { useEffect, useState, useRef, useCallback } from "react";
import classes from "./style.module.scss";
import { Stage, Layer } from "react-konva";
import { useOutsideClickHook } from "utils/outsideClick";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import MessageBar from "./MessageBar";
import Loading from "components/Loading";
import { getDrawingComponent, getUpdatedComponent } from "features/Model/Drawing/drawingHelpers.js";
import convertUnit from "utils/convertUnit";
import { getDecimalPlaces } from "utils/fieldValidation";
import { getBaseUnits } from "utils/fields";
import {
    newShape,
    newTransformer,
    getCursorPosition,
    attachAnchors,
    removeAnchors,
    calcPolygonValues,
    setBoundary,
    setLineBoundary,
    handleAnchorDrag,
} from "utils/drawing/shapes";
import { registeredKeys } from "utils/drawing/helpers";
import Konva from "konva";
import { getComponentTemplate } from "utils/enclosure";
import { isEqual } from "lodash";

const messages = ({ page }) => ({
    scalesNotSet: <span>Some pages are missing scales. Click a disabled image to set it's scale.</span>,
    settingScale: <span>Setting scale for {page}</span>,
});

const Canvas = React.memo(
    ({
        stage,
        layer,
        canvas,
        activeTool,
        setActiveTool,
        images,
        stageScale = 1,
        stageCoords,
        canvasSize,
        imageData,
        activeImage,
        activeComponent,
        missingScales,
        primaryUnits,
        setAction,
        setDisabledTools,
        units,
        setMessage,
        message: { content: message, fill: messageFill },
        setActiveImage,
        disabledTools,
        setScale,
        setActiveComponent,
        addComponent,
        addLine,
        removeComponent,
        updateComponent,
        updatePolygon,
        updateLine,
        removeLine,
        processingImages,
        toggleImageProcessing,
        defaultParams,
        updateStage,
    }) => {
        const prevImage = useRef(activeImage);
        const [keysDown, toggleKeyDown] = useState([]);
        const [contextMenu, toggleMenu] = useState({
            show: false,
            coords: {
                x: 0,
                y: 0,
            },
        });
        const [activeOutline, changeActiveOutline] = useState("");

        /** -- Canvas Functions -- **/
        const updateSize = () =>
            updateStage({
                size: {
                    width: window.innerWidth - 512,
                    height: window.innerHeight - 73,
                },
            });

        const resetCanvas = () => {
            updateStage({ scale: 1, coords: { x: 0, y: 0 } });
            toggleMenu({
                ...contextMenu,
                show: false,
            });
        };

        const menuRef = useOutsideClickHook(() =>
            toggleMenu({
                ...contextMenu,
                show: false,
            })
        );

        const handleMenu = useCallback((event) => {
            event.evt.preventDefault();
            toggleMenu({
                show: true,
                coords: {
                    x: event.evt.x,
                    y: event.evt.y,
                },
            });
        }, []);
        /** -- End Canvas Functions -- **/

        /** -- General Functions -- **/
        const setDefaultMessage = async () => {
            if (missingScales) {
                await setMessage({
                    content: messages({ setMessage }).scalesNotSet,
                });
            }
        };

        const reset = async (tool) => {
            if (tool === "measure") {
                await setDefaultMessage();
                await setDisabledTools([]);
            }
            await setActiveTool("");
            setAction({
                id: "",
                meta: {},
            });
        };

        const activateComponent = (shape) => {
            const clickedId = shape.getId();
            const clickedImage = shape.getAttr("image");
            const page = stage.current.findOne(`#${clickedImage}`).getAttr("page");

            // console.log(clickedImage);

            setActiveTool("");
            setActiveComponent(clickedId);
            setActiveImage(clickedImage);

            if (shape.name() === "outline" && !activeOutline) {
                setAction({
                    id: "polygonFields",
                    meta: {
                        shape: shape.getParent(),
                    },
                });
            }

            if (shape.name() === "outline" && activeOutline) {
                setAction({
                    id: "polygonSet",
                    meta: {
                        cancel: () => {
                            reset();
                            setActiveComponent("");
                            changeActiveOutline("");
                            shape.getParent().destroy();
                        },
                        onSet: () => {
                            setActiveTool("");
                            changeActiveOutline("");
                            stage.current.draw();
                        },
                        shape,
                    },
                });
            }

            if (shape.name() === "line") {
                setAction({
                    id: "lineActions",
                    meta: {
                        shape: shape.getParent(),
                    },
                });
            }

            if (shape.name() === "measure") {
                setAction({
                    id: "setScale",
                    meta: {
                        page,
                        image: activeImage,
                        onSet: () => {
                            setActiveComponent("");
                        },
                    },
                });
            }

            // Polygons and Lines
            if (shape.name() === "outline" || shape.name() === "line" || shape.name() === "measure") {
                // attach anchors
                const anchors = attachAnchors(clickedId, shape, clickedImage, stage.current);
                anchors.map((anchor) => shape.getParent().add(anchor));

                // handle shape
                // shape.getParent().moveToTop();
                shape.getParent().setAttr("draggable", true);
                stage.current.container().style.cursor = "grab";
                return;
            }

            // Windows and Doors
            const transformer = newTransformer(clickedId);
            transformer.nodes([shape]);
            layer.current.add(transformer);

            setAction({
                id: "componentActions",
                meta: {
                    shape,
                    transformer,
                },
            });
            shape.setAttr("draggable", true);
            // shape.moveToTop();
            stage.current.container().style.cursor = "grab";
            // transformer.moveToTop();
        };

        const deactivateComponent = (component) => {
            //  may need to adjust this
            if (!component || activeOutline) {
                return;
            }

            const shape = stage.current.findOne(`#${component}`);
            const shapeId = shape.getAttr("id");

            // Polygons and Lines
            if (shape.name() === "outline" || shape.name() === "line" || shape.name() === "measure") {
                // Remove anchors
                removeAnchors(shape.getParent(), stage.current);

                shape.getParent().setAttr("draggable", false);
                return;
            }

            // Windows and Doors
            const transformer = stage.current.findOne(`#transformer_${shapeId}`);
            if (transformer) {
                transformer.destroy();
            }
            shape.setAttr("draggable", false);
        };
        /** -- End General Functions -- **/

        /** -- Scale Functions -- **/
        const removeInvalidScales = (image) =>
            Object.keys(imageData).map(async (key) => {
                if (key === image) {
                    return false;
                }

                const { scale: { input = 0 } = {} } = imageData[key] || {};

                if (!input) {
                    await setScale({ image: key, scale: {} });
                    const scaleGroup = stage.current.find(`#group_measure_${key}`);
                    scaleGroup.map((scale) => scale.destroy());
                }

                return true;
            });

        const scaleCancel = async (image) => {
            await setActiveImage("");
            reset("measure");
            setActiveComponent("");
        };

        const handleScaleChange = useCallback(
            ({ points = [], image = "", id = "", stage: newStage, x = 0, y = 0 }) => {
                const newLength = Math.hypot(points[2] - points[0], points[3] - points[1]);
                const page = images.findIndex(({ fileName }) => fileName === image) + 1;
                const imageShape = newStage.findOne(`#${image}`);
                const group = newStage.findOne(`#group_${id}`);
                const stageCoords = { x: newStage.x(), y: newStage.y() };

                group.dragBoundFunc((pos) => setLineBoundary(pos, imageShape, points, stageCoords, newStage.scaleX()));
                setActiveImage(image);

                setScale({
                    image,
                    scale: {
                        px: newLength,
                        points,
                        x,
                        y,
                    },
                });
                setAction({
                    id: "setScale",
                    meta: {
                        page,
                        image,
                        cancel: () => {
                            // TODO: pass less unnecessary meta by using state
                            // console.log("CANCEL FIRE");
                            scaleCancel(image);
                        },
                        onSet: () => {
                            reset("measure");
                            setActiveComponent("");
                        },
                    },
                });
            },
            [images, units, stage]
        );

        const handleActiveScale = (image, page) => {
            setActiveComponent(`measure_${image}`);
            return setAction({
                id: "setScale",
                meta: {
                    page,
                    image,
                    onSet: () => {
                        setActiveComponent("");
                    },
                },
            });
        };

        const newScale = (image, page, x, y) => {
            const defaultPxLength = 100;
            const shape = newShape({
                shape: activeTool,
                id: `measure_${image}`,
                points: [0, 0, defaultPxLength, 0],
                image,
                x,
                y,
                shapeChangeHandler: shapeChangeHandler[activeTool],
                stage: stage.current,
                selected: true,
            });
            setActiveComponent(`measure_${image}`);
            setScale({
                image,
                scale: {
                    px: defaultPxLength,
                    input: 0,
                    units,
                    displayUnits: units,
                    points: [0, 0, defaultPxLength, 0],
                    x,
                    y,
                },
            });
            setAction({
                id: "setScale",
                meta: {
                    page,
                    image,
                    cancel: async () => {
                        await setScale({ image, scale: {} });
                        scaleCancel(image);
                        shape.destroy();
                    },
                    onSet: () => {
                        reset("measure");
                        setActiveComponent("");
                    },
                },
            });
            layer.current.add(shape);
        };
        /** -- End Scale Functions -- **/

        /** -- Windows and Doors Handler Functions -- **/
        const handleComponentChange = async ({ image = "", componentId = "", height = 1, width = 1, x = 0, y = 0 }) => {
            const imageShape = stage.current.findOne(`#${image}`);
            const shape = stage.current.findOne(`#${componentId}`);
            const stageCoords = { x: stage.current.x(), y: stage.current.y() };

            shape.dragBoundFunc((pos) =>
                setBoundary(pos, imageShape, width, height, stageCoords, stage.current.scaleX())
            );
            updateComponent({
                image,
                componentId,
                updates: { height, width, x, y },
            });
        };

        const newComponent = (image, x, y) => {
            const timeStamp = moment().format("YYYYMMDDHHmmssSS");
            const newId = `${activeTool}-${timeStamp}`;
            const fullId = `${activeTool}_${image}_${moment().format("YYYYMMDDHHmmssSS")}`;
            const shape = newShape({
                shape: activeTool,
                id: fullId,
                image,
                x,
                y,
                width: 60,
                height: activeTool === "door" ? 100 : 60,
                shapeChangeHandler: shapeChangeHandler[activeTool],
                stage: stage.current,
                selected: true,
            });
            layer.current.add(shape);
            const { [activeImage]: { components = {} } = {} } = imageData;
            const existingWindowCount = Object.keys(components).filter((key) => key.startsWith("window_")).length;
            const existingDoorCount = Object.keys(components).filter((key) => key.startsWith("door_")).length;

            const transformer = newTransformer(fullId);
            transformer.nodes([shape]);
            layer.current.add(transformer);

            setActiveComponent(fullId);

            let templateDefaults = {};

            // For windows only
            const {
                measurements: { headerHeight = 0, overhangWidth = 0 } = {},
                windowType = {},
                shgc = 0,
                er = 0,
                numIdentical = 0,
            } = getComponentTemplate("window");

            if (activeTool === "window") {
                templateDefaults = {
                    measurements: {
                        headerHeight,
                        overhangWidth,
                    },
                    windowType,
                    shgc,
                    er,
                    numIdentical,
                };
            }

            const { window: compDefaults = {} } = defaultParams;
            // End for windows only

            // For doors only
            const { doorType = {} } = getComponentTemplate("door");

            if (activeTool === "door") {
                templateDefaults = {
                    doorType,
                };
            }
            // End for doors only

            addComponent({
                image,
                component: {
                    componentName:
                        activeTool === "window" ? `Window ${existingWindowCount + 1}` : `Door ${existingDoorCount + 1}`,
                    type: activeTool,
                    id: fullId,
                    height: activeTool === "door" ? 100 : 60,
                    width: 60,
                    x,
                    y,
                    componentModelId: newId,
                    ...templateDefaults,
                    ...(activeTool === "window" ? compDefaults : {}),
                },
            });

            setAction({
                id: "componentActions",
                meta: {
                    shape,
                    transformer,
                    cancel: () => {
                        setActiveComponent("");
                        removeComponent({ image, componentId: fullId });
                        shape.destroy();
                        transformer.destroy();
                        setAction({
                            id: "",
                            meta: {},
                        });
                    },
                    onSet: () => {},
                },
            });
        };
        /** -- End Component Handler Functions -- **/

        /** -- Outline Handler Functions -- **/
        const newOutline = (image, x, y) => {
            const fullId = `${activeTool}_${image}_${moment().format("YYYYMMDDHHmmssSS")}`;
            // if active component is an outline, add points, otherwise create new shape
            const shape = newShape({
                shape: activeTool,
                id: fullId,
                image,
                x,
                y,
                defaultPxLength: 100,
                shapeChangeHandler: shapeChangeHandler["outline"],
                stage: stage.current,
                selected: true,
            });

            changeActiveOutline(fullId);
            setActiveComponent(fullId);
            layer.current.add(shape);

            setAction({
                id: "polygonSet",
                meta: {
                    cancel: () => {
                        reset();
                        setActiveComponent("");
                        changeActiveOutline("");
                        removeComponent({ image, componentId: fullId });
                        shape.destroy();
                    },
                },
            });
        };

        const continueOutline = (image, x, y, target) => {
            const outlineGroup = layer.current.findOne((node) => {
                return node.getId() === `group_${activeOutline}`;
            });
            const outline = outlineGroup.findOne((node) => {
                return node.getId() === activeOutline;
            });
            const oldPoints = outline.points();
            const groupX = outlineGroup.x();
            const groupY = outlineGroup.y();
            const newPoints = [...oldPoints, x - groupX, y - groupY];
            outline.points(newPoints);

            removeAnchors(outlineGroup, stage.current);

            const anchors = attachAnchors(activeOutline, outline, image, stage.current);
            anchors.map((anchor) => outlineGroup.add(anchor));

            setAction({
                id: "polygonSet",
                meta: {
                    cancel: () => {
                        reset();
                        setActiveComponent("");
                        changeActiveOutline("");
                        outlineGroup.destroy();
                    },
                    onSet: () => {
                        setActiveTool("");
                        changeActiveOutline("");
                        stage.current.draw();
                    },
                    shape: outline,
                },
            });
        };

        const handleOutlineChange = async ({ points = [], id = "", image = "", shape, x = 0, y = 0 }) => {
            const { perimeter, area } = calcPolygonValues({ points });
            const group = shape.getParent();
            const stageCoords = { x: stage.current.x(), y: stage.current.y() };
            const imageShape = stage.current.findOne(`#${image}`);

            group.dragBoundFunc((pos) => setLineBoundary(pos, imageShape, points, stageCoords, stage.current.scaleX()));
            updatePolygon({
                image,
                componentId: id,
                updates: { perimeter, area, points, x, y },
            });
        };
        /** -- End Outline Handler Functions -- **/

        /** -- Line Handler Functions -- **/
        const newLine = (image, x, y) => {
            const fullId = `${activeTool}_${image}_${moment().format("YYYYMMDDHHmmssSS")}`;
            const defaultPxLength = 100;
            const shape = newShape({
                shape: activeTool,
                id: fullId,
                image,
                x,
                y,
                points: [0, 0, defaultPxLength, 0],
                shapeChangeHandler: shapeChangeHandler["line"],
                stage: stage.current,
                selected: true,
            });
            layer.current.add(shape);
            const { [activeImage]: { lines = {} } = {} } = imageData;

            setActiveComponent(fullId);
            setAction({
                id: "lineActions",
                meta: {
                    cancel: () => {
                        setActiveComponent("");
                        removeLine({ image, lineId: fullId });
                        shape.destroy();
                        setAction({ id: "" });
                    },
                    shape,
                },
            });

            addLine({
                image,
                line: {
                    lineName: `Line ${Object.keys(lines).length + 1}`,
                    id: fullId,
                    x,
                    y,
                    points: [0, 0, defaultPxLength, 0],
                    length: defaultPxLength,
                },
            });
        };

        const updateDrawingComponent = async (drawingComponent, length, conversionFactor, fieldKey, path) => {
            const {
                type = "",
                image = "",
                componentData = {},
                otherComponents = {},
                drawingShapeId,
                componentModelId,
            } = drawingComponent;

            const calculatedLength = convertUnit({
                value: length * conversionFactor,
                type: "length",
                inputUnit: "m",
                outputUnit: getBaseUnits(fieldKey, primaryUnits).trueBase,
            });

            const fieldValue = parseFloat(calculatedLength.toFixed(getDecimalPlaces(fieldKey)));

            if (type === "component") {
                const pathArray = path.split(".");
                const updatedComponent = getUpdatedComponent(componentData, pathArray, fieldValue);

                await updateComponent({
                    image,
                    componentId: drawingShapeId,
                    updates: updatedComponent,
                });
            }

            if (type === "polygonComponent") {
                const pathArray = path.split(".");
                const updatedComponent = getUpdatedComponent(componentData, pathArray, fieldValue);

                await updatePolygon({
                    image,
                    componentId: drawingShapeId, // this is wrong
                    updates: {
                        components: {
                            ...otherComponents,
                            [componentModelId]: updatedComponent,
                        },
                    },
                });
            }
        };

        const handleLineChange = ({ points = [], id = "", image = "", x = 0, y = 0 }) => {
            const newLength = Math.hypot(points[2] - points[0], points[3] - points[1]);
            const imageShape = stage.current.findOne(`#${image}`);
            const group = stage.current.findOne(`#group_${id}`);
            const stageCoords = { x: stage.current.x(), y: stage.current.y() };

            const {
                [image]: {
                    lines: {
                        [id]: {
                            attachTo: { componentId: selectedComponentId } = {},
                            changeField: { path = "", key: fieldKey = "" } = {},
                            aboveGradeHeight,
                            length,
                        } = {},
                    } = {},
                    scale: { input = 1, px = 100 } = {},
                } = {},
            } = imageData;

            group.dragBoundFunc((pos) => setLineBoundary(pos, imageShape, points, stageCoords, stage.current.scaleX()));
            updateLine({
                image,
                id,
                updates: { length: newLength, points: points, x, y },
            });

            if (!aboveGradeHeight && selectedComponentId) {
                const drawingComponent = getDrawingComponent(imageData, selectedComponentId);
                const conversionFactor = input / px;

                updateDrawingComponent(drawingComponent, length, conversionFactor, fieldKey, path);
            }
        };
        /** -- End Line Handler Functions -- **/

        /** -- Stage Handler Functions -- **/
        const shapeChangeHandler = {
            measure: handleScaleChange,
            door: handleComponentChange,
            window: handleComponentChange,
            outline: handleOutlineChange,
            line: handleLineChange,
            default: () => null,
        };

        // *** Keep for later reference on how to move shape with cursor ***
        // const stageMove = useCallback((event) => {
        //     if (!activeOutline) {
        //         return;
        //     }

        //     const targetName = event.target.getAttr('name');

        //     const { x, y } = getCursorPosition(event);
        //     const outline = layer.current.findOne(node => {
        //         return node.getId() === activeOutline;
        //     });
        //     const oldPoints = outline.points();
        //     const newPoints = [...oldPoints, x, y];

        //     if (
        //         event.target.getClassName() === 'Stage' ||
        //         targetName === 'overlay' ||
        //         (targetName === 'drawing' && event.target.getId() !== activeImage)
        //     ) {
        //         return stage.current.draw();
        //     }

        //     outline.points(newPoints);
        //     stage.current.draw();
        //     outline.points(oldPoints);
        // },[activeOutline, stage, layer]);

        const stageMove = useCallback(
            (event) => {
                const targetName = event.target.getAttr("name");
                const targetClassName = event.target.getClassName();
                const shape = targetClassName === "Group" ? event.target.findOne("Line") : event.target;
                const shapeId = shape.getAttr("id");

                if (targetName === "overlay") {
                    stage.current.container().style.cursor = "pointer";
                    return;
                }

                if (targetName === "anchor") {
                    stage.current.container().style.cursor = "move";
                    return;
                }

                if (activeTool && ["Rect", "Line", "Group"].includes(targetClassName)) {
                    stage.current.container().style.cursor = "crosshair";
                }

                if (targetClassName === "Stage" && !activeTool) {
                    stage.current.container().style.cursor = "default";
                }

                if (
                    ["Rect", "Line", "Group"].includes(targetClassName) &&
                    targetName !== "drawing" &&
                    !activeTool &&
                    shapeId !== activeComponent
                ) {
                    stage.current.container().style.cursor = "pointer";
                }

                if (
                    ["Rect", "Line", "Group"].includes(targetClassName) &&
                    targetName !== "drawing" &&
                    !activeTool &&
                    shapeId === activeComponent
                ) {
                    stage.current.container().style.cursor = "grab";
                }

                if (targetName === "drawing" && !activeTool) {
                    stage.current.container().style.cursor = "default";
                }
            },
            [activeTool, stage, activeComponent]
        );

        const stageDragMove = useCallback(
            (event) => {
                const eventTarget = event.target;
                const targetId = eventTarget.getAttr("id");
                const targetName = eventTarget.getAttr("name");
                const targetShapeType = eventTarget.getAttr("shapeType");
                const targetShapeId = eventTarget.getAttr("shapeId");
                const shape =
                    targetName === "anchor"
                        ? stage.current.findOne(`#${targetShapeId}`)
                        : stage.current.findOne(`#${targetId}`); //update for group

                if (targetName === "anchor") {
                    handleAnchorDrag({
                        shapeType: targetShapeType,
                        shape,
                        anchor: eventTarget,
                        keysDown,
                    });
                    stage.current.container().style.cursor = "move";
                }
            },
            [stage, keysDown]
        );

        const stageDragStart = useCallback(
            (event) => {
                stage.current.container().style.cursor = "grabbing";
                const eventTarget = event.target;
                const targetClass = eventTarget.getClassName();
                const shape = targetClass === "Group" ? eventTarget.findOne("Line") : eventTarget;
                const shapeId = shape.getAttr("id");
                const shapeImage = eventTarget.getAttr("image");
                const shapeName = shape.getAttr("name");

                // Outline / Polygon - not sure why we need this on drag start?
                if (targetClass === "Group") {
                    const x = eventTarget.x();
                    const y = eventTarget.y();
                    const points = shape.points();

                    if (shapeName === "outline") {
                        handleOutlineChange({
                            points,
                            id: shapeId,
                            image: shapeImage,
                            shape,
                            x,
                            y,
                        });
                    }

                    if (shapeName === "line") {
                        handleLineChange({
                            points,
                            id: shapeId,
                            image: shapeImage,
                            x,
                            y,
                        });
                    }
                }

                // Window / Door
                if (shapeName === "window" || shapeName === "door") {
                    const shapeId = shape.getAttr("id");
                    const height = shape.getAttr("height") * shape.getAttr("scaleY");
                    const width = shape.getAttr("width") * shape.getAttr("scaleX");
                    const x = shape.getAttr("x");
                    const y = shape.getAttr("y");

                    handleComponentChange({
                        componentId: shapeId,
                        image: shapeImage,
                        height,
                        width,
                        x,
                        y,
                    });
                }

                if (!shape || !["window", "door", "outline", "line", "measure"].includes(shape.getAttr("name"))) {
                    return;
                }

                // Only deactivate if is not active already
                if (activeComponent !== shapeId) {
                    deactivateComponent(activeComponent);
                }

                if (shape.name() === "measure") {
                    setActiveComponent(shapeId);
                    return;
                }
            },
            [activateComponent, stage, activeComponent, deactivateComponent]
        );

        const stageDragEnd = useCallback(
            (event) => {
                const eventTarget = event.target;
                const targetClass = eventTarget.getClassName();
                const shapeImage = eventTarget.getAttr("image");
                const shape =
                    targetClass === "Group"
                        ? eventTarget.findOne("Line")
                        : eventTarget.name() === "anchor"
                        ? stage.current.findOne(`#${eventTarget.getAttr("shapeId")}`)
                        : eventTarget;
                const shapeName = shape && shape.getAttr("name");
                const newX = event.currentTarget.attrs.x;
                const newY = event.currentTarget.attrs.y;
                updateStage({ coords: { x: newX, y: newY } });
                stageMove(event);

                // Outline / Polygon / Line
                if (targetClass === "Group") {
                    const shapeId = shape.getAttr("id");
                    const x = eventTarget.x();
                    const y = eventTarget.y();
                    const points = shape.points();

                    if (shapeName === "outline") {
                        handleOutlineChange({
                            points,
                            id: shapeId,
                            image: shapeImage,
                            shape,
                            x,
                            y,
                        });
                    }

                    if (shapeName === "line") {
                        handleLineChange({
                            points,
                            id: shapeId,
                            image: shapeImage,
                            x,
                            y,
                        });
                    }
                }

                // Window / Door
                if (shapeName === "window" || shapeName === "door") {
                    const shapeId = shape.getAttr("id");
                    const height = shape.getAttr("height") * shape.getAttr("scaleY");
                    const width = shape.getAttr("width") * shape.getAttr("scaleX");
                    const x = shape.getAttr("x");
                    const y = shape.getAttr("y");

                    handleComponentChange({
                        componentId: shapeId,
                        image: shapeImage,
                        height,
                        width,
                        x,
                        y,
                    });
                }

                // Anchor points
                if (eventTarget.name() === "anchor") {
                    const shapeType = eventTarget.getAttr("shapeType");
                    const shapeId = eventTarget.getAttr("shapeId");
                    const group = stage.current.findOne(`#group_${shapeId}`);
                    const shapePoints = shape.points();
                    const shapeX = group.x();
                    const shapeY = group.y();

                    if (shapeType === "line") {
                        handleLineChange({
                            points: shapePoints,
                            id: shapeId,
                            image: shapeImage,
                            stage: stage.current,
                            x: shapeX,
                            y: shapeY,
                        });
                    }

                    if (shapeType === "outline") {
                        handleOutlineChange({
                            points: shapePoints,
                            id: shapeId,
                            image: shapeImage,
                            shape,
                            stage: stage.current,
                            x: shapeX,
                            y: shapeY,
                        });
                    }

                    if (shapeType === "measure") {
                        handleScaleChange({
                            points: shapePoints,
                            image: shapeImage,
                            id: shapeId,
                            stage: stage.current,
                            x: shapeX,
                            y: shapeY,
                        });
                    }
                }
            },
            [activeTool, stageMove, imageData, stage]
        );

        const handleCurrentImages = (id) => {
            if (!id) {
                return;
            }

            const currentActiveImage = stage.current.findOne(`#${id}`);
            const currentActiveOverlay = stage.current.findOne(`#overlay_${id}`);
            const currentActiveText = stage.current.findOne(`#text_${id}`);

            const { [id]: { scale: { input: scaleInput = 0 } = {} } = {} } = imageData;

            if (currentActiveImage) {
                currentActiveImage.setAttr("stroke", "transparent");
            }

            if (currentActiveText) {
                currentActiveText.setAttr("fill", "#646F81");
                currentActiveText.setAttr("fontStyle", "normal");
            }

            if (currentActiveOverlay && !scaleInput) {
                currentActiveOverlay.moveToTop();
            }
        };

        const activateImage = (id) => {
            if (!id) {
                return;
            }

            const newActiveImage = stage.current.findOne(`#${id}`);
            const newActiveText = stage.current.findOne(`#text_${id}`);

            const { [id]: { scale: { input: scaleInput = 0 } = {} } = {} } = imageData;

            if (scaleInput) {
                setDisabledTools([]);
            }

            newActiveText.setAttr("fill", "#0066B1");
            newActiveText.setAttr("fontStyle", "bold");
            newActiveImage.setAttr("stroke", "#0066B1");
        };

        const handleImageClick = useCallback(
            (id) => {
                setActiveImage(id);
                if (activeTool === "measure") {
                    setActiveTool("");
                }
            },
            [activeImage, imageData, stage, setActiveImage]
        );

        const handleOverlayClick = useCallback(
            async (overlay) => {
                const page = overlay.getAttr("page");
                const id = overlay.getId();
                const imageId = id.split("overlay_")[1];

                // console.log(overlay.getId());

                // Remove all invalid scales from other images
                removeInvalidScales(imageId);

                // await setMessage({
                //     content:messages({ page:`Page ${page}` }).settingScale,
                //     fill:'#0066B1',
                // });
                await setActiveTool("measure");
                await setDisabledTools(["window", "door", "line", "outline"]);
                await setAction({
                    id: "settingScale",
                    meta: {
                        page: `Page ${page}`,
                        cancel: () => {
                            reset("measure");
                            setActiveImage("");
                        },
                    },
                });
                overlay.moveToBottom();
                stage.current.container().style.cursor = "crosshair";
                setActiveImage(imageId);
            },
            [setAction, setActiveTool, imageData, stage, activeImage, activateImage]
        );

        const handleStageClick = useCallback(
            (event) => {
                const targetName = event.target.getAttr("name");
                const targetId = event.target.getId();
                const { image, page = 0 } = event.target.attrs || {};
                // GET REAL PAGE
                const { scale: currentScale = {} } = imageData[image] || {};

                // 1. HANDLE THE CLICKING OF ELEMENTS

                // Remove all invalid scales from other images
                // TODO: move this so it's not always called?
                removeInvalidScales(image);

                // Deactivate active component
                deactivateComponent(activeComponent);

                // Outside image click
                if (!targetName && !activeOutline) {
                    setActiveImage("");
                    setActiveComponent("");
                    setAction({ id: "" });
                }

                // Door or window click when no tool is active
                if (["window", "door", "outline", "line", "measure"].includes(targetName) && !activeTool) {
                    activateComponent(event.target);
                }

                // If click is on a drawing
                if (targetName === "drawing") {
                    handleImageClick(targetId);
                }

                // If click is on an overlay
                if (targetName === "overlay") {
                    handleOverlayClick(event.target);
                }

                // If click is on a drawing, but there are no tools active
                if (targetName === "drawing" && !activeTool) {
                    setAction({ id: "" });
                }

                // If click is on a drawing and there's an active tool, toggle it off
                if (targetName === "drawing" && activeTool && activeTool !== "outline") {
                    setActiveTool("");
                }

                // If click is not on a drawing or there is no active tool, we're done here
                if (event.target.getClassName() === "Stage" || targetName === "overlay" || activeTool === "") {
                    return;
                }

                activateImage(image);

                // 2. HANDLE THE ADDING OF COMPONENTS

                // Shape defaults
                const { x, y } = getCursorPosition(event);

                // New Windows and Doors
                if (["window", "door"].includes(activeTool)) {
                    setActiveComponent(""); // not sure this is needed
                    newComponent(image, x, y);
                }

                // New Outlines
                if (activeTool === "outline" && !activeOutline) {
                    newOutline(image, x, y);
                }

                // Continued Outlines
                if (activeTool === "outline" && activeOutline) {
                    continueOutline(image, x, y, event.target);
                }

                // New Lines
                if (activeTool === "line") {
                    newLine(image, x, y);
                }

                // Handle already active scale
                if (activeTool === "measure" && !isEmpty(currentScale)) {
                    setActiveComponent(""); // not sure needed
                    const { input = 0 } = currentScale;

                    if (input) {
                        // If there is a valid scale set, toggle to this scale
                        return handleActiveScale(image, page);
                    }

                    // If Scale isn't valid yet, simply delete and redraw
                    const oldScaleGroup = stage.current.find(`#group_measure_${image}`);
                    oldScaleGroup.map((scale) => scale.destroy());
                }

                if (activeTool === "measure") {
                    setActiveComponent(""); // not sure needed
                    newScale(image, page, x, y);
                }

                stage.current.draw();
            },
            [activeTool, activeComponent, layer, imageData, shapeChangeHandler]
        );

        const handleKeydown = useCallback(
            (event) => {
                if (event.keyCode === 27) {
                    // Escape key
                    reset(activeTool);
                    setActiveImage("");
                    //removeInvalidScales('');
                }
                //TODO: figure out command + for zoom
            },
            [activeTool, imageData]
        );
        /** -- End Stage Handler Functions -- **/

        const initShapes = () => {
            Object.keys(imageData).map((image) => {
                const { scale = {}, polygons = {}, lines = {}, components = {} } = imageData[image] || {};

                // Place Scales
                if (!isEmpty(scale)) {
                    const { points: scalePoints, x: scaleX, y: scaleY } = scale;

                    const scaleShape = newShape({
                        shape: "measure",
                        id: `measure_${image}`,
                        image,
                        shapeChangeHandler: shapeChangeHandler["measure"],
                        stage: stage.current,
                        points: scalePoints,
                        x: scaleX,
                        y: scaleY,
                    });

                    layer.current.add(scaleShape);
                }

                // Place windows and doors
                Object.keys(components).map((component) => {
                    const { id, type, x, y, width, height } = components[component] || {};

                    const shape = newShape({
                        shape: type,
                        id,
                        image,
                        x,
                        y,
                        width,
                        height,
                        shapeChangeHandler: shapeChangeHandler[type],
                        stage: stage.current,
                    });
                    layer.current.add(shape);
                });

                // Place lines
                Object.keys(lines).map((line) => {
                    const { id, points = [], x, y } = lines[line] || {};

                    const lineShape = newShape({
                        shape: "line",
                        id,
                        image,
                        x,
                        y,
                        points,
                        shapeChangeHandler: shapeChangeHandler["line"],
                        stage: stage.current,
                    });
                    layer.current.add(lineShape);
                });

                // Place polygons
                Object.keys(polygons).map((poly) => {
                    const { id, x, y, points } = polygons[poly] || {};

                    const outlineShape = newShape({
                        shape: "builtOutline",
                        id,
                        image,
                        x,
                        y,
                        points,
                        shapeChangeHandler: shapeChangeHandler["outline"],
                        stage: stage.current,
                    });
                    layer.current.add(outlineShape);
                });

                stage.current.draw();
            });
        };

        const initImages = () => {
            if (images.length === 0) {
                return;
            }

            let xCount = 10;
            toggleImageProcessing(true);

            return images.reduce((prevPromise, image, index) => {
                const { fileName, fileURI = "", signedURL = "" } = image;
                const { scale: imageScale = {} } = imageData[fileName] || {};
                const imageObj = new Image();

                return prevPromise.then(() => {
                    return new Promise((resolve, reject) => {
                        const x = xCount;
                        imageObj.onload = () => {
                            const imageShape = new Konva.Rect({
                                x: x,
                                y: 25,
                                width: imageObj.width,
                                height: imageObj.height,
                                name: "drawing",
                                fillPatternImage: imageObj,
                                id: fileName,
                                image: fileName,
                                page: index + 1,
                                shadowBlur: 10,
                                shadowOpacity: 0.1,
                                stroke: "transparent",
                            });
                            const imageText = new Konva.Text({
                                text: `Page ${index + 1}`,
                                x: x,
                                y: stageScale >= 2.5 ? 18.75 : 7.5 * stageScale,
                                fontFamily: "Noto Sans TC",
                                name: "text",
                                id: `text_${fileName}`,
                                image: fileName,
                                fontSize: stageScale >= 0.75 ? 12 / stageScale : 18,
                                fill: "#646F81",
                                fontStyle: "normal",
                            });

                            // Need to do this in case drawer gets toggled closed before these promises are resolved
                            if (layer.current) {
                                layer.current.add(imageShape);
                                layer.current.add(imageText);
                            }

                            if (isEmpty(imageScale)) {
                                const overlay = new Konva.Rect({
                                    x: x,
                                    y: 25,
                                    width: imageObj.width,
                                    height: imageObj.height,
                                    image: fileName,
                                    name: "overlay",
                                    page: index + 1,
                                    id: `overlay_${fileName}`,
                                    fill: "rgba(38, 47, 63, 0.8)",
                                });

                                if (layer.current) {
                                    layer.current.add(overlay);
                                }
                            }

                            if (stage.current) {
                                stage.current.draw();
                            }
                            xCount = index === 0 ? imageObj.width + 20 : xCount + imageObj.width + 10;

                            if (index === images.length - 1) {
                                toggleImageProcessing(false);
                                initShapes();
                            }

                            return resolve();
                        };
                        imageObj.src = signedURL; //fileURI;
                    });
                });
            }, Promise.resolve());
        };

        const toggleKeyPress = (event) => {
            if (event.repeat) {
                return;
            }

            const type = event.type;
            const key = event.key;

            if ((type !== "keydown" && type !== "keyup") || (type === "keydown" && !registeredKeys.includes(key))) {
                return;
            }

            if (type === "keydown") {
                toggleKeyDown((currentKeys) => [...currentKeys, key]);
            }

            if (type === "keyup") {
                toggleKeyDown((currentKeys) => {
                    if (currentKeys.length > 0) {
                        return currentKeys.filter((k) => k !== key);
                    } else {
                        return [];
                    }
                });
            }
        };

        useEffect(() => {
            updateSize();
            window.addEventListener("resize", updateSize);
            setDefaultMessage();
            initImages();

            document.addEventListener("keydown", toggleKeyPress);
            document.addEventListener("keyup", toggleKeyPress);

            return () => {
                document.removeEventListener("keydown", toggleKeyPress);
                document.removeEventListener("keyup", toggleKeyPress);
            };
        }, []);

        useEffect(() => {
            if (prevImage.current !== activeImage) {
                handleCurrentImages(prevImage.current);
            }
            activateImage(activeImage);
            prevImage.current = activeImage;
        }, [activeImage]);

        return (
            <div className={classes.canvas} ref={canvas}>
                <MessageBar message={message} setMessage={setMessage} fill={messageFill} />
                {processingImages && (
                    <div className={classes.loadingImages}>
                        <Loading className={classes.loading} />
                        <p>Loading Images...</p>
                    </div>
                )}
                <Stage
                    ref={stage}
                    className={`${classes.stage}`}
                    width={canvasSize.width}
                    height={canvasSize.height}
                    scale={{
                        x: stageScale,
                        y: stageScale,
                    }}
                    draggable
                    x={stageCoords.x}
                    y={stageCoords.y}
                    onClick={handleStageClick}
                    onMouseMove={stageMove}
                    onDragStart={stageDragStart}
                    onDragMove={stageDragMove}
                    onDragEnd={stageDragEnd}
                >
                    <Layer ref={layer} />
                </Stage>
                {contextMenu.show && (
                    <ul
                        ref={menuRef}
                        className={classes.contextMenu}
                        style={{
                            top: contextMenu.coords.y,
                            left: contextMenu.coords.x,
                        }}
                    >
                        <li onClick={resetCanvas}>Reset Canvas Position</li>
                    </ul>
                )}
            </div>
        );
    },
    isEqual
);

export default Canvas;
