import types from "./types";
import { firestore, MODEL_COLL } from "_firebase";
import { isEmpty, cloneDeep } from "lodash";
//const upgrades = await firestore.doc(`${MODEL_COLL}/${modelId}`).collection('modelData').doc('upgrades').get();

import { mergeObjects } from "utils/objects";

const clearUpgradeData = () => ({
    type: types.CLEAR_UPGRADE_DATA,
});

const toggleUpgradesLoading = (isLoading) => ({
    type: types.TOGGLE_UPGRADES_LOADING,
    isLoading,
});

const setUpgradesError = (error) => ({
    type: types.SET_UPGRADES_ERROR,
    error,
});

const updateUpgradePackage = ({ packageId = "", packageUpdates = {} }) => ({
    type: types.UPDATE_UPGRADE_PACKAGE,
    packageId,
    packageUpdates,
});

const deleteUpgradePackage = ({ packageId = "" }) => ({
    type: types.DELETE_UPGRADE_PACKAGE,
    packageId,
});

const setUpgradePackages = ({ packages = {} }) => ({
    type: types.SET_UPGRADE_PACKAGES,
    packages,
});

const setInitUpgradePackages = ({ packages = {} }) => ({
    type: types.SET_INIT_UPGRADE_PACKAGES,
    packages,
});

const updatePackageUpgrades = ({ packageId, upgrades = {}, dateTime }) => ({
    type: types.UPDATE_PACKAGE_UPGRADES,
    packageId,
    upgrades,
    dateTime,
});

const deletePackageUpgrade = ({ packageId, upgradeId }) => ({
    type: types.DELETE_PACKAGE_UPGRADE,
    packageId,
    upgradeId,
});

// !TODO PACKAGES GET BLANK WHEN SAVING AFTER DELING A PACKAGE
const saveUpgrades = () => async (dispatch, getState) => {
    const { model: { modelId } = {}, upgrades: { packages = {}, initPackages = {}, initPackagesSnapshot = {} } = {} } =
        getState();

    //Only don't save if both current and init are empty. Otherwise, packages may be empty if we had packages and deleted them
    if (!modelId || (isEmpty(packages) && isEmpty(initPackages))) {
        return;
    }

    const newPackages = mergeObjects(packages, initPackages, initPackagesSnapshot);

    await firestore.doc(`${MODEL_COLL}/${modelId}`).collection("modelData").doc("upgrades").set(newPackages);

    dispatch(setInitUpgradePackages({ packages: newPackages }));
    dispatch(setUpgradePackages({ packages: newPackages }));
};

const getUpgrades =
    ({ modelId }) =>
    async (dispatch) => {
        dispatch(toggleUpgradesLoading(true));
        const upgrades = await firestore.doc(`${MODEL_COLL}/${modelId}`).collection("modelData").doc("upgrades").get();
        const packages = upgrades.data();

        dispatch(setInitUpgradePackages({ packages }));
        dispatch(setUpgradePackages({ packages }));
        dispatch(toggleUpgradesLoading(false));
    };

const updateUpgradeCodes =
    ({ newCodeRef, oldCodeRef, values = {}, fieldName }) =>
    (dispatch, getState) => {
        const { upgrades: { packages = {} } = {} } = getState();

        if (isEmpty(packages)) {
            //TODO: LAUREN - In this case there are no upgrades. Can I just put a return statement like this?
            return;
        }

        //If we get to this point, we assume that there are upgrades present

        // - Go into each upgrade in each package
        const updatedPackages = cloneDeep(packages);
        Object.keys(packages).forEach((packageKey) => {
            const { upgrades = {} } = packages[packageKey];
            // - In each upgrade, search fields for the code type with the oldCodeRef value
            Object.keys(upgrades).forEach((upgradeKey) => {
                const {
                    fields: {
                        [fieldName]: { codeRef = "" } = {},
                        wall: {
                            [fieldName]: { codeRef: foundationWallCodeRef = "" } = {},
                            ponyWall: { [fieldName]: { codeRef: ponyWallCodeRef = "" } = {} } = {},
                            ponyWall = {},
                        } = {},
                        wall = {},
                        floor: { [fieldName]: { codeRef: foundationFloorCodeRef = "" } = {} } = {},
                        floor = {},
                    } = {},
                    fields = {},
                } = upgrades[upgradeKey];

                // - If found, update this field and its RValues to be the new ones coming in
                // - Values will be an obj with the fields that need changed - can spread them into the root of the fields obj
                if (codeRef === oldCodeRef) {
                    //Typical flat component
                    updatedPackages[packageKey].upgrades[upgradeKey].fields = {
                        ...fields,
                        ...values,
                    };
                } else if (foundationWallCodeRef === oldCodeRef) {
                    //Spread changes into foundation wall
                    updatedPackages[packageKey].upgrades[upgradeKey].fields.wall = { ...wall, ...values };
                } else if (foundationFloorCodeRef === oldCodeRef) {
                    //Spread changes into foundation floor
                    updatedPackages[packageKey].upgrades[upgradeKey].fields.floor = { ...floor, ...values };
                } else if (ponyWallCodeRef === oldCodeRef) {
                    //Spread changes into foundation ponywall
                    updatedPackages[packageKey].upgrades[upgradeKey].fields.wall.ponyWall = { ...ponyWall, ...values };
                }
            });
        });

        dispatch(setUpgradePackages({ packages: updatedPackages }));
    };

const reorderUpgradePackages = (packages = []) => ({
    type: types.UPDATE_UPGRADE_PACKAGE_ORDER,
    packages,
});

const updateUpgradeCost = ({ packageId, upgradeId, upgradeType, cost, items }) => ({
    type: types.UPDATE_UPGRADE_COST,
    packageId,
    upgradeId,
    upgradeType,
    cost,
    items,
});

const updatePackageOperatingConditions = ({ packageId, operatingConditions }) => ({
    type: types.UPDATE_PACKAGE_OPERATING_CONDITIONS,
    packageId,
    operatingConditions,
});

const updatePackageBaseCase = ({ packageId, relativeBaseCase }) => ({
    type: types.UPDATE_PACKAGE_BASE_CASE,
    packageId,
    relativeBaseCase,
});

const updateUpgradeSnapshot = (upgrades) => ({
    type: types.UPDATE_UPGRADE_SNAPSHOT_SUCCESS,
    upgrades,
});

export default {
    clearUpgradeData,
    toggleUpgradesLoading,
    setUpgradesError,
    updateUpgradePackage,
    setInitUpgradePackages,
    saveUpgrades,
    getUpgrades,
    setUpgradePackages,
    deleteUpgradePackage,
    updatePackageUpgrades,
    deletePackageUpgrade,
    updateUpgradeCodes,
    reorderUpgradePackages,
    updateUpgradeCost,
    updatePackageOperatingConditions,
    updatePackageBaseCase,
    updateUpgradeSnapshot,
};
