import { types } from "features/Model/_ducks";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    foundationOptions,
    getFoundationName,
} from "./TakeoffData/FondationOptions";
import { isEmpty } from "lodash";

const withTakeoff = (Component) => (props) => {
    const { mode, id, index, name, formModelData } = props;

    const { uiSettings: { primaryUnits: modelUnits } = {} } = formModelData;

    const state = useSelector((state) => state);
    const takeoffState = state.model.modelTakeoff;

    const dispatch = useDispatch();
    let key = `${id} - ${index}`;

    //? In case you need to add a new takeoff component in the future, but not currently used
    const handleAddTakeoff = (compType = "") => {
        dispatch({
            type: types.ADD_TAKEOFF_COMP,
            comp: id,
            compType: compType || name,
        });
    };

    useEffect(() => {
        if (!takeoffState[id]) {
            if (id === "foundation" && isEmpty(takeoffState[id])) {
                handleAddTakeoff("basement");
            }
        }

        if (id !== "foundation" && isEmpty(takeoffState[id])) {
            handleAddTakeoff();
        }
    }, [id]); //? If you have the latest version of React, you may get an eslint warning here. I recommend ignoring it.

    const translateComponent = (
        componentType,
        componentSubtype,
        formModelData
    ) => {
        dispatch({
            type: "TRANSLATE_COMPONENT",
            componentType: componentType,
            componentSubtype: componentSubtype,
            formModelData,
        });
    };

    const countBuildingSurfaceArea = (componentType) => {
        dispatch({
            type: types.COUNT_BUILDING_SURFACE_AREA,
            componentType: componentType,
        });
    };

    const setDimensions = (componentType, formModelData) => {
        dispatch({
            type: types.SET_DIMENSIONS,
            componentType: componentType,
            formModelData: formModelData,
        });
    };

    const addMultiRowTableComponent = (parent, component, overwrite) => {
        dispatch({
            type: "ADD_TOFF_TABLE_MULTI_ROW",
            data: {
                parent,
                component,
                overwrite,
            },
        });
    };

    const TakeoffActions = {
        id,
        mode,
        toKey: key,
        name,
        state,
        takeoffState,
        formModelData,
        dispatch,
        handleAddTakeoff,
        countBuildingSurfaceArea,
        foundationOptions,
        getFoundationName,
        translateComponent,
        setDimensions,
        addMultiRowTableComponent,
        modelUnits,
    };

    return <Component {...TakeoffActions} props={props} />;
};

export default withTakeoff;
