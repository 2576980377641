import { connect } from "react-redux";
import { reduxForm, formValueSelector } from "redux-form";
import moment from "moment";
import Create from "./";
import getModelTemplates from "utils/getModelTemplates";
import { getOptions, getFirstOption } from "utils/fields";
import { actions as modelActions } from "features/Model/_ducks";
import { actions as resultsActions } from "features/Model/Review/Results/_ducks";
import { actions as upgradeActions } from "features/Model/Upgrades/_ducks";
import { clearDrawingData } from "features/Model/DrawingNew/_ducks/actions";

import { getDefaultF280Option } from "utils/weather";

import { actions as userActions } from "store/users";
import { mixpanel } from "components/Mixpanel";

const {
    fetchModelData,
    createModel,
    modelLoading,
    setExportErrors,
    clearModelData,
} = modelActions;
const { resetResults } = resultsActions;
const { clearUpgradeData } = upgradeActions;
const { fetchUserDir } = userActions;

const mapStateToProps = (
    {
        form,
        user: {
            units = "Metric",
            modelDir: { folders = {} } = {},
            uid,
            name: username,
            error = null,
            loading = false,
            userMeta: {
                company = "",
                role = "",
                experienceLevel = "",
                agreedToTerms = false,
            } = {},
            userPreferences: {
                userPhone = "",
                eaId = "",
                companyPhone = "",
                province: { id: provinceId } = {},
                preferredUnits = "",
                preferredProgramType = "",
            } = {},
        },
    },
    { history }
) => {
    const selector = formValueSelector("create");
    const selectedTemplate = selector({ form }, "template") || "default-v11.12";
    const modelId = selector({ form }, "modelId") || "";

    // console.log("create form", form);
    // Override some defaults
    let defaultTemplate = getModelTemplates(selectedTemplate);
    // if (selectedTemplate === "default-v11.9") {
    //     defaultTemplate = defaultTemplateV9;
    // } else {
    //     defaultTemplate = defaultTemplateV10;
    // }

    defaultTemplate.general.file.evaluationDate = moment().format("YYYY-MM-DD");
    defaultTemplate.general.file.userNameEnteredBy = username;
    defaultTemplate.general.file.userTelephone = userPhone;
    defaultTemplate.general.file.companyTelephone = companyPhone;
    defaultTemplate.general.file.userCompany = company;
    defaultTemplate.general.file.fileID = eaId;

    defaultTemplate.program.class = preferredProgramType;
    defaultTemplate.uiSettings.primaryUnits = preferredUnits || units;

    if (provinceId != null) {
        const provinceOpts = getOptions({
            fieldKey: "weatherRegion",
            version: "v11-11",
        });
        const selectedProvTerr =
            provinceOpts.find((el) => el.value.id === provinceId) || {};

        defaultTemplate.general.client.clientProvince = selectedProvTerr.label;

        defaultTemplate.location.weather.region = { id: provinceId };
        const locObj = getFirstOption({
            indKey: provinceId,
            depKey: "weatherLocation",
            version: "v11-11",
        });

        defaultTemplate.location.weather.location = locObj;

        defaultTemplate.location.weather.f280.location.id =
            getDefaultF280Option(locObj.id);
    }

    return {
        uid,
        units,
        folders,
        createError: error,
        loading,
        modelId,
        initialValues: {
            owner: {
                uid,
                name: username,
            },
            template: selectedTemplate,
            modelData: defaultTemplate,
        },
        history,
    };
};

const mapDispatchToProps = (dispatch) => ({
    fetchUserDir: (uid) => dispatch(fetchUserDir(uid)),
});

const mergeProps = (state, dispatch, own) => ({
    ...state,
    ...dispatch,
    ...own,
});

const onSubmit = async (form, dispatch, { history, uid }) => {
    const {
        modelId: generatedId = "",
        modelData: { uiSettings: { primaryUnits } = {} } = {},
        parentFolderId = null,
    } = form;

    const modelId =
        generatedId ||
        (await dispatch(
            createModel(
                {
                    ...form,
                    parentFolderId,
                    createdAt: moment().format(),
                    sharedWith: [uid],
                },
                uid,
                parentFolderId
            )
        ).then((id) => id));

    await dispatch(clearModelData());
    await dispatch(clearDrawingData());
    await dispatch(resetResults());
    await dispatch(clearUpgradeData());
    await dispatch(setExportErrors([]));
    await dispatch(modelLoading());
    await dispatch(fetchModelData(modelId));

    mixpanel.track("Create Model", {
        "Primary Units": primaryUnits,
    });
    return history.push(`/models/${modelId}`);
};

const form = reduxForm({
    form: "create",
    enableReinitialize: true,
    onSubmit,
})(Create);

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(form);
