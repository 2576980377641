import React from "react";
import classes from "../style.module.scss";
import Chart from "react-google-charts";
import classNames from "classnames";
import Select from "components/Input/Select";
import LoadingDots from "components/LoadingDots";
import HourlyAnalysis from "../../HourlyAnalysis/container";

import { getNetTotalEnergyConsumption } from "utils/results";

export const pieChartTooltip = (title, value, unit, percentage) => {
    return `<div style="min-width:140px; padding: 10px 12px;">
        <p style="font-weight:700; margin-bottom: 0; line-height: 16px;">${title}</p>
        <p>${value} ${unit} <span style="margin-left:4px; color:#646f81;">(${percentage})</span></p>
    </div>`;
};

export const getPercentage = (value, total) => `${((value / total) * 100).toFixed(2)}%`;

export const PieChart = ({ data, title, isEmpty = false }) => (
    <div className={classes.pieChart}>
        <h3>{title}</h3>
        {isEmpty ? (
            <div className={classes.pieChartEmpty}>Net Zero</div>
        ) : (
            <Chart
                width={"100%"}
                chartType="PieChart"
                loader={LoadingDots}
                data={data}
                options={{
                    chartArea: {
                        top: 40,
                        left: 0,
                        width: "95%",
                        height: "75%",
                    },
                    //pieSliceText: "none",
                    sliceVisibilityThreshold: 0.01,
                    pieSliceBorderColor: "none",
                    tooltip: {
                        //text: "Amount of energy",
                        showColorCode: true,
                        textStyle: { fontName: "Noto Sans TC", fontSize: 12 },
                        isHtml: true,
                    },
                    legend: {
                        position: "right",
                        alignment: "center",
                        textStyle: { fontName: "Noto Sans TC", color: "#7B7B7B", fontSize: 12 },
                    },

                    colors: ["#0049c6", "#f58020", "#FFB22D", "#18c1ad", "#92a4ad"],
                }}
            />
        )}
    </div>
);

export const ChartTable = ({ title = "", rows = [] }) => (
    <ul>
        <li>{title}</li>
        {rows.map(({ label, value }) => (
            <li key={label}>
                <strong>{label}</strong>
                <span>{value}</span>
            </li>
        ))}
    </ul>
);

export const Stat = ({ label = "", value = 0, unit = "", decimals = 2, big = false }) => (
    <div className={classNames(classes.stat, { [classes.bigStat]: big })}>
        <p>{label}</p>
        <span>
            <strong>{typeof value === "number" ? value.toFixed(decimals).replace("-0.00", "0.00") : value}</strong>{" "}
            {unit && unit}
        </span>
    </div>
);

const GeneralResults = ({
    resultsPackages,
    selectedPackage,
    packageInfo,
    changePackage,
    packageResultsData = {},
    weatherProvTerrId,
}) => {
    const caseKey = selectedPackage === "base" ? "generalBaseCase" : "generalAllUpgrades";
    const {
        [selectedPackage]: {
            resultsData: {
                [caseKey]: {
                    Annual: {
                        Consumption: {
                            _attributes: { total: totalConsumption = 0 } = {},
                            Electrical: { _attributes: { spaceCooling: totalCooling = 0 } = {} } = {},
                            SpaceHeating: { _attributes: { total: totalHeating = 0 } = {} } = {},
                        } = {},
                    } = {},
                    resultsSummary: {
                        endUseChart: {
                            baseloads = 0,
                            spaceCooling = 0,
                            ventilation = 0,
                            hotWater = 0,
                            spaceHeating = 0,
                        } = {},
                        fuelTypeChart: { naturalGas = 0, electricity = 0, oil = 0, propane = 0, wood = 0 } = {},
                        emissionsChart: {
                            naturalGas: naturalGasEmissions = 0,
                            electricity: electricityEmissions = 0,
                            propane: propaneEmissions = 0,
                            wood: woodEmissions = 0,
                            oil: oilEmissions = 0,
                            pv: pvEmissions = 0,
                        } = {},
                        annualElectricity: totalElectricty = 0,
                        annualNaturalGas: totalNaturalGas = 0,
                        annualOil: totalOil = 0,
                        annualPropane: totalPropane = 0,
                        annualWood: totalWood = 0,
                        annualEmissions: totalEmissions = 0,
                    } = {},
                    hourlyElectricalLoadkWh: electricalLoad = [],
                } = {},
            } = {},
        } = {},
    } = packageResultsData;

    const maxHourlyLoad = Object.keys(packageResultsData).reduce((max, currPackageKey) => {
        const {
            resultsData: {
                generalBaseCase: { hourlyElectricalLoadkWh: baseElecLoad = [] } = {},
                generalAllUpgrades: { hourlyElectricalLoadkWh: upElecLoad = [] } = {},
            } = {},
        } = packageResultsData[currPackageKey] || {};

        return Math.max(max, ...baseElecLoad, ...upElecLoad);
    }, 0);

    const totalChartConsumption = baseloads + spaceCooling + ventilation + hotWater + spaceHeating;
    const totalChartFuelType = naturalGas + electricity + oil + propane + wood;
    const totalChartEmissions =
        naturalGasEmissions + electricityEmissions + propaneEmissions + woodEmissions + oilEmissions;

    const {
        zeroFloorTotal,
        net: netEnergyConsumption,
        gross: grossEnergyConsumption,
        grossElectricity: totalGrossElectricity = 0,
        grossNaturalGas: totalGrossNaturalGas = 0,
        grossPropane: totalGrossPropane = 0,
        grossOil: totalGrossOil = 0,
        grossWood: totalGrossWood = 0,
        pvProduction = 0,
    } = getNetTotalEnergyConsumption(packageResultsData?.[selectedPackage]?.resultsData?.[caseKey] || {});

    return (
        <div className={classes.resultsContainer}>
            <div className={classes.selectionWrapper}>
                <Select
                    className={classes.selection}
                    name="packageSelect"
                    label="Upgrade Package"
                    input={{
                        value: selectedPackage,
                        onChange: (value) => {
                            changePackage(value);
                        },
                    }}
                    options={[
                        {
                            value: "base",
                            label: "Base Case",
                        },
                        ...Object.keys(resultsPackages)
                            .filter((packageId) => packageInfo[packageId])
                            .map((packageId) => ({
                                value: packageId,
                                label: packageInfo[packageId].name,
                            })),
                    ]}
                />
            </div>
            <div
                className={`${classes.row} ${classes.totals} ${classes.lastTotals}`}
                style={{ gridTemplateColumns: "1fr 1fr 1fr 1fr" }}
            >
                <Stat label="Total Annual Energy Consumption" value={zeroFloorTotal} unit="GJ" decimals={2} big />
                <Stat label="Annual Heating Consumption" value={totalHeating} unit="GJ" decimals={2} />
                <Stat label="Annual Cooling Consumption" value={totalCooling} unit="GJ" decimals={2} />
                <Stat label="Annual Emissions" value={totalEmissions} unit="tCO₂" decimals={2} />
            </div>
            <div className={`${classes.row} ${classes.charts}`} style={{ gridTemplateColumns: "1fr 1fr 1fr" }}>
                <PieChart
                    title="Energy end use breakdown"
                    data={[
                        ["Energy type", "Actual", { type: "string", role: "tooltip", p: { html: true } }],
                        [
                            "Space Heating",
                            parseFloat(spaceHeating.toFixed(2)),
                            pieChartTooltip(
                                "Space Heating",
                                parseFloat(spaceHeating.toFixed(2)),
                                "GJ",
                                getPercentage(spaceHeating, totalChartConsumption)
                            ),
                        ],
                        [
                            "Baseloads",
                            parseFloat(baseloads.toFixed(2)),
                            pieChartTooltip(
                                "Baseloads",
                                parseFloat(baseloads.toFixed(2)),
                                "GJ",
                                getPercentage(baseloads, totalChartConsumption)
                            ),
                        ],
                        [
                            "Hot Water",
                            parseFloat(hotWater.toFixed(2)),
                            pieChartTooltip(
                                "Hot Water",
                                parseFloat(hotWater.toFixed(2)),
                                "GJ",
                                getPercentage(hotWater, totalChartConsumption)
                            ),
                        ],
                        [
                            "Space Cooling",
                            parseFloat(spaceCooling.toFixed(2)),
                            pieChartTooltip(
                                "Space Cooling",
                                parseFloat(spaceCooling.toFixed(2)),
                                "GJ",
                                getPercentage(spaceCooling, totalChartConsumption)
                            ),
                        ],
                        [
                            "Ventilation",
                            parseFloat(ventilation.toFixed(2)),
                            pieChartTooltip(
                                "Ventilation",
                                parseFloat(ventilation.toFixed(2)),
                                "GJ",
                                getPercentage(ventilation, totalChartConsumption)
                            ),
                        ],
                    ]}
                />
                <PieChart
                    title="Energy fuel type breakdown (gross)"
                    data={[
                        ["Fuel type", "Actual", { type: "string", role: "tooltip", p: { html: true } }],
                        [
                            "Electricity",
                            parseFloat(totalGrossElectricity.toFixed(2)),
                            pieChartTooltip(
                                "Electricity",
                                parseFloat(totalGrossElectricity.toFixed(2)),
                                "GJ",
                                getPercentage(totalGrossElectricity, totalChartFuelType)
                            ),
                        ],
                        [
                            "Natural Gas",
                            parseFloat(totalGrossNaturalGas.toFixed(2)),
                            pieChartTooltip(
                                "Natural Gas",
                                parseFloat(totalGrossNaturalGas.toFixed(2)),
                                "GJ",
                                getPercentage(totalGrossNaturalGas, totalChartFuelType)
                            ),
                        ],
                        [
                            "Oil",
                            parseFloat(totalGrossOil.toFixed(2)),
                            pieChartTooltip(
                                "Oil",
                                parseFloat(totalGrossOil.toFixed(2)),
                                "GJ",
                                getPercentage(totalGrossOil, totalChartFuelType)
                            ),
                        ],
                        [
                            "Propane",
                            parseFloat(totalGrossPropane.toFixed(2)),
                            pieChartTooltip(
                                "Propane",
                                parseFloat(totalGrossPropane.toFixed(2)),
                                "GJ",
                                getPercentage(totalGrossPropane, totalChartFuelType)
                            ),
                        ],
                        [
                            "Wood",
                            parseFloat(totalGrossWood.toFixed(2)),
                            pieChartTooltip(
                                "Wood",
                                parseFloat(totalGrossWood.toFixed(2)),
                                "GJ",
                                getPercentage(totalGrossWood, totalChartFuelType)
                            ),
                        ],
                    ]}
                    isEmpty={[
                        totalGrossElectricity,
                        totalGrossNaturalGas,
                        totalGrossPropane,
                        totalGrossOil,
                        totalGrossWood,
                    ].every((el) => el < 0.001)}
                />
                <PieChart
                    title="Emissions breakdown"
                    data={[
                        ["Fuel type", "Actual", { type: "string", role: "tooltip", p: { html: true } }],
                        [
                            "Electricity",
                            parseFloat(electricityEmissions.toFixed(2)),
                            pieChartTooltip(
                                "Electricity",
                                parseFloat(electricityEmissions.toFixed(2)),
                                "tCO₂",
                                getPercentage(electricityEmissions, totalChartEmissions)
                            ),
                        ],
                        [
                            "Natural Gas",
                            parseFloat(naturalGasEmissions.toFixed(2)),
                            pieChartTooltip(
                                "Natural Gas",
                                parseFloat(naturalGasEmissions.toFixed(2)),
                                "tCO₂",
                                getPercentage(naturalGasEmissions, totalChartEmissions)
                            ),
                        ],
                        [
                            "Oil",
                            parseFloat(oilEmissions.toFixed(2)),
                            pieChartTooltip(
                                "Oil",
                                parseFloat(oilEmissions.toFixed(2)),
                                "tCO₂",
                                getPercentage(oilEmissions, totalChartEmissions)
                            ),
                        ],
                        [
                            "Propane",
                            parseFloat(propaneEmissions.toFixed(2)),
                            pieChartTooltip(
                                "Propane",
                                parseFloat(propaneEmissions.toFixed(2)),
                                "tCO₂",
                                getPercentage(propaneEmissions, totalChartEmissions)
                            ),
                        ],
                        [
                            "Wood",
                            parseFloat(woodEmissions.toFixed(2)),
                            pieChartTooltip(
                                "Wood",
                                parseFloat(woodEmissions.toFixed(2)),
                                "tCO₂",
                                getPercentage(woodEmissions, totalChartEmissions)
                            ),
                        ],
                    ]}
                    isEmpty={[
                        electricityEmissions,
                        naturalGasEmissions,
                        oilEmissions,
                        propaneEmissions,
                        woodEmissions,
                    ].every((el) => el < 0.001)}
                />
            </div>
            {/* <div className={classes.chartBreakdownToggle}>See breakdown values <ArrowIcon /></div> */}
            <div className={`${classes.row} ${classes.chartBreakdown}`} style={{ gridTemplateColumns: "1fr 1fr 1fr" }}>
                <ChartTable
                    title="Energy end use"
                    rows={[
                        {
                            label: "Space Heating",
                            value: `${parseFloat(spaceHeating).toFixed(2)} GJ`,
                        },
                        {
                            label: "Baseloads",
                            value: `${parseFloat(baseloads).toFixed(2)} GJ`,
                        },
                        {
                            label: "Hot Water",
                            value: `${parseFloat(hotWater).toFixed(2)} GJ`,
                        },
                        {
                            label: "Space Cooling",
                            value: `${parseFloat(spaceCooling).toFixed(2)} GJ`,
                        },
                        {
                            label: "Ventilation",
                            value: `${parseFloat(ventilation).toFixed(2)} GJ`,
                        },
                    ]}
                />
                <ChartTable
                    title="Energy fuel type"
                    rows={[
                        {
                            label: "Electricity",
                            value: `${parseFloat(electricity.toFixed(2))} GJ`,
                        },
                        {
                            label: "Natural Gas",
                            value: `${parseFloat(naturalGas.toFixed(2))} GJ`,
                        },
                        {
                            label: "Oil",
                            value: `${parseFloat(oil.toFixed(2))} GJ`,
                        },
                        {
                            label: "Propane",
                            value: `${parseFloat(propane.toFixed(2))} GJ`,
                        },
                        {
                            label: "Wood",
                            value: `${parseFloat(wood.toFixed(2))} GJ`,
                        },
                        {
                            label: "Solar PV (saved)",
                            value: `${parseFloat(pvProduction.toFixed(2))} GJ`,
                        },
                    ]}
                />
                <ChartTable
                    title="Emissions"
                    rows={[
                        {
                            label: "Electricity",
                            value: `${parseFloat(electricityEmissions.toFixed(2))} tCO₂`,
                        },
                        {
                            label: "Natural Gas",
                            value: `${parseFloat(naturalGasEmissions.toFixed(2))} tCO₂`,
                        },
                        {
                            label: "Oil",
                            value: `${parseFloat(oilEmissions.toFixed(2))} tCO₂`,
                        },
                        {
                            label: "Propane",
                            value: `${parseFloat(propaneEmissions.toFixed(2))} tCO₂`,
                        },
                        {
                            label: "Wood",
                            value: `${parseFloat(woodEmissions.toFixed(2))} tCO₂`,
                        },
                        {
                            label: "Solar PV (saved)",
                            value: `${parseFloat(pvEmissions.toFixed(2))} tCO₂`,
                        },
                    ]}
                />
            </div>
            <h3 style={{ marginBottom: "1.5rem" }}>Annual Fuel Consumption</h3>
            <div
                className={`${classes.row} ${classes.consumption}`}
                style={{ gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr", marginBottom: 0 }}
            >
                {!!totalElectricty && <Stat label="Electricity" value={totalElectricty} decimals={2} unit="kWh" />}
                {!!totalNaturalGas && <Stat label="Natural Gas" value={totalNaturalGas} decimals={2} unit="m³" />}
                {!!totalOil && <Stat label="Oil" value={totalOil} decimals={2} unit="Litres" />}
                {!!totalPropane && <Stat label="Propane" value={totalPropane} decimals={2} unit="Litres" />}
                {!!totalWood && <Stat label="Wood" value={totalWood} decimals={2} unit="kg" />}
            </div>
            <HourlyAnalysis
                electricalLoad={electricalLoad}
                weatherProvTerrId={weatherProvTerrId}
                maxHourlyLoad={maxHourlyLoad}
                upgradePackageId={selectedPackage}
                resultsCase={caseKey}
            />
        </div>
    );
};

export default GeneralResults;
