import { connect } from "react-redux";
import { reduxForm, formValueSelector } from "redux-form";
import Create from "./";
import { actions as modelActions } from "features/Model/_ducks";
import { actions as resultsActions } from "features/Model/Review/Results/_ducks";
import { actions as createActions } from "features/CreateModel/_ducks";
import { actions as upgradeActions } from "features/Model/Upgrades/_ducks";
import { clearDrawingData } from "features/Model/DrawingNew/_ducks/actions";
import { mixpanel } from "components/Mixpanel";

const { fetchModelData, modelLoading, setExportErrors, clearModelData } =
    modelActions;
const { resetResults } = resultsActions;
const {
    validateMiniAudit,
    toggleAuditIdValid,
    setValidationError,
    createModelFromAudit,
} = createActions;
const { clearUpgradeData } = upgradeActions;

const mapStateToProps = (
    {
        form,
        user: {
            units = "Metric",
            folders = [],
            uid,
            name: username,
            error = null,
            loading = false,
        },
        create: {
            auditIdIsValid = false,
            validationError = "",
            isValidating = false,
        } = {},
    },
    { history }
) => {
    const selector = formValueSelector("createMiniAudit");
    const miniAuditId = selector({ form }, "miniAuditId") || "";

    return {
        uid,
        units,
        folders,
        createError: error,
        loading,
        miniAuditId,
        validationError,
        history,
        auditIdIsValid,
        isValidating,
    };
};

const mapDispatchToProps = (dispatch) => ({
    validateMiniAudit: (miniAuditId) =>
        dispatch(validateMiniAudit(miniAuditId)),
    toggleAuditIdValid: (valid) => dispatch(toggleAuditIdValid(valid)),
    setValidationError: (error) => dispatch(setValidationError(error)),
    createModelFromAudit: ({ miniAuditId = "", uid = "", name }) =>
        dispatch(createModelFromAudit({ miniAuditId, uid, name })),
});

const mergeProps = (state, dispatch, own) => ({
    ...state,
    ...dispatch,
    ...own,
});

const onSubmit = async (form, dispatch, { history, uid }) => {
    const { miniAuditId, name } = form;

    const modelId = await dispatch(
        createModelFromAudit(
            {
                miniAuditId,
                uid,
                name,
            },
            uid
        )
    ).then((modelId) => modelId);

    await dispatch(clearModelData());
    await dispatch(clearDrawingData());
    await dispatch(resetResults());
    await dispatch(clearUpgradeData());
    await dispatch(setExportErrors([]));
    await dispatch(modelLoading());
    await dispatch(fetchModelData(modelId));

    mixpanel.track("Create Model", {});
    return history.push(`/models/${modelId}`);
};

const form = reduxForm({
    form: "createMiniAudit",
    enableReinitialize: true,
    onSubmit,
})(Create);

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(form);
