import React, { useEffect, useState, useRef, useCallback } from "react";
import isEmpty from "lodash/isEmpty";
import { useHistory, useLocation, Redirect } from "react-router";
import ReactDOM from "react-dom";

import { useOutsideClickHook } from "utils/outsideClick";
// !TODO BUG WITH NAMING
import Button from "components/Button";
import Loading from "components/Loading";
import Dropdown from "components/Dropdown";
import Model from "./Model";
import Folder from "./Folder";
import CreateDialog from "./CreateDialog";
import DeleteDialog from "./DeleteDialog";
import DuplicateDialog from "./DuplicateDialog";
import DeleteMultipleFilesDialog from "features/Dashboard/DeleteMultipleFilesDialog";
import OrgRequestDialog from "./OrgRequestDialog";
import NestedPath from "./NestedPath";
import RenameDialog from "./RenameDialog";
import SelectFolder from "./SelectFolder";
import ComponentListImage from "components/Draggable/DraggableImage/ComponentListImage";
import ExportSummaryDialog from "./ExportSummaryDialog";

import dashboardTemp from "assets/images/dashboard_temp.jpg";
import AddIcon from "assets/images/icons/JSX/Add";
import OpenIcon from "assets/images/icons/JSX/Open";
import HouseImage from "assets/images/house-structure.svg";
import SectionHeading from "components/SectionHeading";
import CodeLibIcon from "assets/images/icons/JSX/CodeLib";
import HouseIcon from "assets/images/icons/JSX/House";
import SharedModel from "assets/images/icons/JSX/SharedModel";

import classes from "./style.module.scss";
import GoBack from "./GoBack";
import { getBatchModelSummary } from "utils/results/api";

const csvHeader =
    `Model Name,Upgrade Package,Region,HDD,AG Floor Area (m2),BG Floor Area (m2),Volume(m3),ACH,` +
    `Total Consumption (GJ),Electricity (GJ),Natural Gas (GJ),Propane (GJ),Oil (GJ),Wood (GJ),PV (GJ),` +
    `Baseloads (GJ),Total Space Heating (GJ),Primary Space Heating (GJ),Secondary Space Heating (GJ),Total Hot Water (GJ),Primary Hot Water (GJ),Secondary Hot Water (GJ),Space Cooling (GJ),Ventilation (GJ),` +
    `NBC Energy Tier,Gross Heat Loss Improvement (%),EUI (GJ/m2),TEDI (kWh/m2),MEUI (kWh/m2),` +
    `Heat Loss Comfort Assessment,Gross Heat Loss(GJ),Auxiliary Heating (MJ),HOT2000 Heat Loss (W),HOT2000 Heat Gain (GJ),F280 Heat Loss (W),F280 Heat Gain (W),` +
    `Total Operational Emissions (t/y),Operational Carbon Intensity (t/y/m2),NBC Proposed Emissions (t/y),NBC Reference Emissions (t/y),NBC Emissions Level,Electricity EF (g/kWh),Natural Gas EF (g/m3),`;

const Dashboard = ({
    name,
    modelDir,
    selectModel,
    uid,
    email,
    fetchUserDir,
    fetchUserOrganization,
    error,
    deleteModel,
    removeSharedModelFromDir,
    duplicateModel,
    modelDirLoading,
    createFolder,
    folderLoading,
    deleteFolder,
    renameFolder,
    moveFolder,
    moveModel,
    modelLoading,
    batchFolderRemove,
    batchDeleteModels,
    organization,
    organizationRequested,
    saveUserMeta,
    requestVoltaSnapOrganization,
}) => {
    const { pathname } = useLocation();
    const history = useHistory();

    const [toDelete, toggleToDelete] = useState("");
    const [toRemoveSharedModel, toggleToRemoveSharedModel] = useState("");
    const [newFolderName, setNewFolderName] = useState("");
    const [organizationName, setOrganizationName] = useState("");
    const [folderToDelete, setFolderToDelete] = useState("");
    const [folderName, setFolderName] = useState("");
    const [duplicateName, setDuplicateName] = useState("");
    const [folderToRename, setFolderToRename] = useState("");
    const [folderToMove, setFolderToMove] = useState("");
    const [modelToMove, setModelToMove] = useState("");
    const [modelToDuplicate, setModelToDuplicate] = useState("");
    const [deleting, toggleDeleting] = useState(false);
    const [isCreateOpen, setIsCreateOpen] = useState(false);
    const [toCreate, setToCreate] = useState(false);
    const [reqOrgOpen, setReqOrgOpen] = useState(false);
    const [duplicateLoading, setDuplicateLoading] = useState(false);
    const [selectedFolderModel, setSelectedFolderModel] = useState("");
    const [selectedModelsFolders, setSelectedModelsFolders] = useState([]);
    const [folderModelsToDelete, setFolderModelsToDelete] = useState([]);
    const [dragLock, toggleDragLock] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [dropTo, setDropTo] = useState("");
    const [isDropInProcess, setIsDropInProcess] = useState(false);
    const [cursorPosition, setCursorPosition] = useState({ top: 0, left: 0 });
    const [isLoading, setIsLoading] = useState(true);
    const [isMultipleDeleting, setIsMultipleDeleting] = useState(false);
    const [orgRequestLoading, setOrgRequestLoading] = useState(false);
    const [modelsToSummarize, setModelsToSummarize] = useState([]);
    const [exportLoading, setExportLoading] = useState(false);

    const createRef = useOutsideClickHook(() => setIsCreateOpen(false));
    const scrollContainerRef = useRef(null);
    const folderModelRef = useRef(null);

    const path = pathname.split("/").filter((str) => str !== "" && str !== "dashboard") || [];

    const currentFolderId = path[path.length - 1] || null;

    const onMouseMove = useCallback((e) => {
        const { pageY, pageX } = e;

        const top = pageY - window.scrollY + 10;
        const left = pageX - window.scrollX + 10;

        setCursorPosition({ top, left });
    }, []);

    useEffect(() => {
        if (uid) {
            fetchUserDir(uid, false);
        }
    }, [uid]);

    useEffect(() => {
        if (uid && organization?.memberOrgIds && !organization.details) {
            // console.log("uid/org", uid, organization);
            //only handling a single org at the moment
            fetchUserOrganization(uid, Object.keys(organization.memberOrgIds)[0]);
        }
    }, [uid, organization]);

    useEffect(() => {
        // Update loading state once modelDir has loaded
        if (!isEmpty(modelDir)) {
            setIsLoading(false);
        }
    }, [modelDir]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (folderModelRef.current && !folderModelRef.current.contains(event.target)) {
                setSelectedFolderModel("");
                setSelectedModelsFolders([]);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleMouseMove = (e) => {
            onMouseMove(e);
        };

        if (!isLoading) return;

        window.addEventListener("mousemove", handleMouseMove);
        window.addEventListener("wheel", handleMouseMove);

        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
            window.removeEventListener("wheel", handleMouseMove);
        };
    }, [isLoading, onMouseMove]);

    const [firstName = ""] = name.split(" ");
    const { models: singleModels = {}, folders = {} } = modelDir;

    const sendOrganizationRequest = async () => {
        saveUserMeta({ uid, userMeta: { organizationRequested: true } });

        return await requestVoltaSnapOrganization({ uid, userName: name, userEmail: email, orgName: organizationName });
    };

    const startDelete = (event, modelId) => {
        event.stopPropagation();
        toggleToDelete(modelId);
    };

    const startFolderDelete = (event, folderId) => {
        event.stopPropagation();
        setFolderToDelete(folderId);
    };

    const startSharedModelRemove = (event, modelId) => {
        event.stopPropagation();
        toggleToRemoveSharedModel(modelId);
    };

    const filteredFolders = !isEmpty(folders)
        ? Object.entries(folders)
              .filter(([key, { parentFolderId }]) => parentFolderId === currentFolderId)
              .sort(([aKey, aValues], [bKey, bValues]) => aValues.name.localeCompare(bValues.name))
              .map(([key, el]) => [key, { ...el, type: "folder" }])
        : [];

    const filteredModels = !isEmpty(singleModels)
        ? Object.entries(singleModels)
              .filter(([key, { parentFolderId }]) =>
                  parentFolderId ? parentFolderId === currentFolderId : currentFolderId ? false : true
              )
              .sort(([aKey, aValues], [bKey, bValues]) => aValues.name.localeCompare(bValues.name))
              .map(([key, el]) => [key, { ...el, type: "model" }])
        : [];

    let createNewOptions = [
        {
            label: "New Model",
            onClick: () => {
                history.push(`/create${currentFolderId ? `?folderId=${currentFolderId}` : ""}`);
                setIsCreateOpen(false);
            },
        },
    ];

    if (path.length <= 6) {
        createNewOptions = [
            ...createNewOptions,
            {
                label: "New Folder",
                onClick: () => {
                    setToCreate(true);
                    setIsCreateOpen(false);
                },
            },
        ];
    }

    const allFoldersModels = [...filteredFolders, ...filteredModels];

    const onClick = async (event, type, key, index) => {
        event.preventDefault();

        // Pressed shift, multi select items from selected to clicked
        if (event.shiftKey && !event.ctrlKey && !event.metaKey) {
            multipleModelFolderSelect(event, index);

            return;
        }

        // Pressed ctrl and clicked element is not in multiselected items so select item
        if (
            (event.ctrlKey || event.metaKey) &&
            !selectedModelsFolders.includes(key) &&
            selectedFolderModel &&
            selectedModelsFolders
        ) {
            setSelectedModelsFolders([...selectedModelsFolders, key]);
            checkDraggability([...selectedModelsFolders, key]);

            return;
        }

        // Pressed ctrl and clicked element is already in multiselected items so deselect item
        if (
            (event.ctrlKey || event.metaKey) &&
            selectedModelsFolders.includes(key) &&
            selectedFolderModel &&
            selectedModelsFolders
        ) {
            const newFoldersAndModels = selectedModelsFolders.filter((id) => id !== key);

            // Update selectedFolderModel if it's being removed from multiSelectedItems so item is deselected
            if (selectedFolderModel === key) {
                setSelectedFolderModel(newFoldersAndModels[0]);
            }

            setSelectedModelsFolders(newFoldersAndModels);
            checkDraggability(newFoldersAndModels);

            return;
        }

        // Select first item
        if (!selectedFolderModel || selectedFolderModel !== key) {
            setSelectedFolderModel(key);

            multipleModelFolderSelect(event, index);
        }

        // Open selected folder when clicked if no other items are selected, otherwise deselect the rest
        if (selectedFolderModel && selectedFolderModel === key && type === "folder") {
            if (selectedModelsFolders.length > 1) {
                setSelectedFolderModel(key);
                setSelectedModelsFolders([key]);
                checkDraggability([key]);
            } else {
                history.push(`${pathname === "/" ? "/dashboard" : pathname}/${key}`);

                setSelectedFolderModel("");
            }
        }

        // Open selected item when clicked if no other items are selected, otherwise deselect the rest
        if (selectedFolderModel && selectedFolderModel === key && type === "model") {
            if (selectedModelsFolders.length > 1) {
                setSelectedFolderModel(key);
                setSelectedModelsFolders([key]);
                checkDraggability([key]);
            } else {
                setIsLoading(true);

                await selectModel(key);
            }

            // setSelectedFolderModel(""); // causes memory leak from updating react state after dismounting
        }
    };

    const multipleModelFolderSelect = (event, index) => {
        event.preventDefault();
        var selectedItems = [];

        if (!event.shiftKey && (selectedModelsFolders.length <= 1 || selectedModelsFolders.length > 1)) {
            // setSelectedModelsFolders([allFoldersModels[index][0]]);
            selectedItems = [allFoldersModels[index][0]];
        }

        if (event.shiftKey && selectedModelsFolders.length === 0) {
            // setSelectedModelsFolders([allFoldersModels[index][0]]);
            selectedItems = [allFoldersModels[index][0]];
        }

        if (event.shiftKey && selectedModelsFolders.length > 0) {
            // setSelectedFolderModel("");

            const firstIndex = allFoldersModels.findIndex(([key, el]) => key == selectedModelsFolders[0]);
            const startIndex =
                selectedModelsFolders.length > 1 && firstIndex >= index
                    ? allFoldersModels.findIndex(
                          ([key, el]) => key == selectedModelsFolders[selectedModelsFolders.length - 1]
                      )
                    : firstIndex;
            const endIndex = selectedModelsFolders.includes(allFoldersModels[index][0]) ? index : index;

            const selectedModels =
                endIndex < startIndex
                    ? allFoldersModels.slice(endIndex, startIndex + 1)
                    : allFoldersModels.slice(startIndex, endIndex + 1);

            // setSelectedModelsFolders(selectedModels.map(([key, _]) => key));
            selectedItems = selectedModels.map(([key, _]) => key);
        }

        setSelectedModelsFolders(selectedItems);
        checkDraggability(selectedItems);
    };

    const checkDraggability = (selectedItems) => {
        for (let itemId of selectedItems) {
            const index = allFoldersModels.findIndex(([key, el]) => key == itemId);
            const [key, el] = allFoldersModels[index];
            if (el.sharedWithUser) {
                toggleDragLock(true);
                return;
            }
        }
        toggleDragLock(false);
    };

    const onDragEnd = async (event) => {
        event.preventDefault();

        setCursorPosition({ top: event.clientY, left: event.clientX - 250 });

        setIsDragging(false);

        if (dropTo !== "") {
            setIsLoading(true);
            setIsDropInProcess(true);
        } else return;

        if (selectedFolderModel && selectedModelsFolders.length <= 1) {
            const modelFolderToMove = allFoldersModels.find(([key, el]) => key === selectedFolderModel);

            const { type } = modelFolderToMove[1];

            if (type === "model") await moveModel(modelFolderToMove[0], uid, dropTo, true);

            if (type === "folder") await moveFolder(modelFolderToMove[0], uid, dropTo, true);
        }

        if (!selectedModelsFolders.includes(dropTo) && selectedModelsFolders.length > 1 && dropTo !== "") {
            await Promise.all(
                selectedModelsFolders.map((folderModelId) => {
                    const modelFolderToMove = allFoldersModels.find(([key, el]) => key === folderModelId);

                    const { type } = modelFolderToMove[1];

                    if (type === "model") return moveModel(modelFolderToMove[0], uid, dropTo, true);

                    if (type === "folder") return moveFolder(modelFolderToMove[0], uid, dropTo, true);
                })
            );
        }

        await fetchUserDir(uid, true);

        setIsLoading(false);
        setIsDropInProcess(false);
        setDropTo("");
        setSelectedFolderModel("");
        if (selectedModelsFolders.length > 1) setSelectedModelsFolders([]);
    };

    const onDragStart = (event, draggableId, folderName, type) => {
        setIsDragging(true);

        event.dataTransfer.effectAllowed = "move";
        // event.preventDefault();

        var selectedItems = selectedModelsFolders;

        // Reselect items if dragging starts on an unselected item
        if (!selectedModelsFolders.includes(draggableId)) {
            setSelectedFolderModel(draggableId);
            setSelectedModelsFolders([draggableId]);
            checkDraggability([draggableId]);
            selectedItems = [draggableId];
        }

        const image = (
            <ComponentListImage componentLabel={folderName} componentType={type} multipleSelect={selectedItems} />
        );

        let ghost = document.createElement("div");

        ghost.style.transform = "translate(-10000px, -10000px)";
        ghost.style.position = "absolute";
        document.body.appendChild(ghost);
        event.dataTransfer.setDragImage(ghost, 0, 0);

        ReactDOM.render(image, ghost);
    };
    const onDragUpdate = (event, dropToId, type) => {
        if (!isDragging) return;

        event.preventDefault();

        if (dropToId === dropTo) return;

        if (type === "folder") {
            if (!selectedModelsFolders.includes(dropToId) && selectedModelsFolders > 0) {
                setDropTo(dropToId);
            }

            if (selectedFolderModel !== dropToId && !selectedModelsFolders.includes(dropToId)) {
                setDropTo(dropToId);
            }
        }

        if (type === "model" && dropTo !== "") {
            setDropTo("");
        }
    };

    const handleMultipleDelete = async () => {
        setIsMultipleDeleting(true);

        let foldersBatchDelete = [];
        let modelsBatchToDelete = [];

        folderModelsToDelete.forEach(async (folderModel) => {
            const modelFolder = allFoldersModels.find(([key, {}]) => key === folderModel);

            const modelFolderId = modelFolder[0];
            const modelFolderType = modelFolder[1].type;

            if (modelFolderType === "folder") {
                foldersBatchDelete.push(modelFolderId);
            }

            if (modelFolderType === "model") {
                modelsBatchToDelete.push(modelFolderId);
            }
        });

        await batchFolderRemove(foldersBatchDelete, uid);
        await batchDeleteModels(modelsBatchToDelete, uid);

        await fetchUserDir(uid, false);

        setIsMultipleDeleting(false);
        setSelectedModelsFolders([]);
        setFolderModelsToDelete([]);
    };

    const handleBatchSummaryExport = async () => {
        setExportLoading(true);

        // await exportModelSummary({ modelIds: modelsToSummarize });
        try {
            const { data: { modelResultsSummary } = {} } = await getBatchModelSummary({ modelIds: modelsToSummarize });

            if (!isEmpty(modelResultsSummary)) {
                const formattedResults = modelResultsSummary.reduce((cache, curr) => {
                    const { modelSummary: { modelId = "", cliId = "", modelName = "" } = {}, resultsSummary = {} } =
                        curr;

                    const baseResKey = Object.keys(resultsSummary)[0] || "";

                    const { [baseResKey]: { baseResultsDetails = {} } = {} } = resultsSummary;

                    let resultsObj = {};
                    if (!Object.keys(cliId).includes("default")) {
                        resultsObj = Object.keys(cliId).reduce((acc, pkgId) => {
                            const { [pkgId]: { upgradeResultsDetails = {} } = {} } = resultsSummary;
                            return {
                                ...acc,
                                [`${modelId}-${pkgId}`]: {
                                    modelName,
                                    ...upgradeResultsDetails,
                                },
                            };
                        }, {});
                    }

                    return {
                        ...cache,
                        [`${modelId}-base`]: {
                            modelName,
                            upgradePackageName: "",
                            ...baseResultsDetails,
                        },
                        ...resultsObj,
                    };
                }, {});

                const csv = Object.keys(formattedResults).reduce((cache, curr, i) => {
                    if (isEmpty(formattedResults[curr])) {
                        return cache;
                    }
                    const {
                        modelName = "",
                        upgradePackageName = "",
                        provTerrCode = "",
                        hdd = "",
                        agFloorArea = "",
                        bgFloorArea = "",
                        totalVolume = "",
                        airChangeRate50Pa = "",
                        annualEnergyConsumption = "",
                        grossElectricity = "",
                        grossNaturalGas = "",
                        grossPropane = "",
                        grossOil = "",
                        grossWood = "",
                        pvProduction = "",
                        baseload = "",
                        totalSpaceHeating = "",
                        primarySpaceHeating = "",
                        secondarySpaceHeating = "",
                        totalHotWater = "",
                        primaryHotWater = "",
                        secondaryHotWater = "",
                        spaceCooling = "",
                        ventilation = "",
                        nbcEnergyTier = "",
                        grossHeatLossImprovementVsRef = "",
                        eui = "",
                        tedi = "",
                        meui = "",
                        //Heat Loss
                        heatlossComfort = "",
                        grossHeating = "",
                        auxiliaryEnergy = "",
                        designHeatLossRate = "",
                        designCoolLossRate = "",
                        f280HeatLoss = "",
                        f280HeatGain = "",
                        //Carbon
                        totalOpEmissions_tCO2 = "",
                        opCarbonIntensity_tCO2m2 = "",
                        nbcProposedEmissions = "",
                        nbcRefEmissions = "",
                        nbcEmissionsLevel = "",
                        //Emission Factors
                        elecEf = "",
                        ngEf = "",
                    } = formattedResults[curr];

                    return (
                        `${cache}\r\n` +
                        `${modelName},${upgradePackageName},${provTerrCode},${hdd},${agFloorArea},${bgFloorArea},${totalVolume},${airChangeRate50Pa},` +
                        `${annualEnergyConsumption},${grossElectricity},${grossNaturalGas},${grossPropane},${grossOil},${grossWood},${pvProduction},` +
                        `${baseload},${totalSpaceHeating},${primarySpaceHeating},${secondarySpaceHeating},${totalHotWater},${primaryHotWater},${secondaryHotWater},${spaceCooling},${ventilation},` +
                        `${nbcEnergyTier},${grossHeatLossImprovementVsRef},${eui},${tedi},${meui},` +
                        `${heatlossComfort},${grossHeating},${auxiliaryEnergy},${designHeatLossRate},${designCoolLossRate},${f280HeatLoss},${f280HeatGain},` +
                        `${totalOpEmissions_tCO2},${opCarbonIntensity_tCO2m2},${nbcProposedEmissions},${nbcRefEmissions},${nbcEmissionsLevel},${elecEf},${ngEf},`
                    );
                }, csvHeader);

                let cb = new Blob([csv], { type: "text/csv" });

                let url = window.URL.createObjectURL(cb);
                let anchor = document.createElement("a");
                anchor.href = url;
                anchor.download = `model summary.csv`;

                anchor.click();
                window.URL.revokeObjectURL(url);
                anchor.remove();
            }
        } catch (err) {
            console.log("err", err);
        }

        setModelsToSummarize([]);
        setExportLoading(false);
    };

    if (!isLoading && path.length > 0 && !folders[path[0]]) {
        return <Redirect to="/404-page-not-found" />;
    }

    return (
        <div
            className={classes.dashboard}
            onMouseMove={onMouseMove}
            style={{ pointerEvents: isLoading ? "none" : "auto" }}
            ref={scrollContainerRef}
        >
            <div style={{ position: "fixed", pointerEvents: "none", ...cursorPosition }}>
                {isLoading && <Loading className={classes.smallerSpinner} subClassName={classes.smallSpinner} />}
            </div>

            <div className={classes.dashboardHead} style={{ backgroundImage: `url(${dashboardTemp})` }}>
                <img src={HouseImage} alt="House drawing" />
                <div className={classes.text}>
                    <h1>Hi {firstName},</h1>
                    <p>Welcome to your Volta SNAP dashboard</p>
                    <div className={classes.buttonContainer}>
                        <Button
                            icon={AddIcon}
                            type="white"
                            large
                            className={classes.button}
                            onClick={() =>
                                history.push(`/create${currentFolderId ? `?folderId=${currentFolderId}` : ""}`)
                            }
                        >
                            Create New Model
                        </Button>
                        {!organization?.memberOrgIds && (
                            <Button
                                icon={SharedModel}
                                type="white"
                                large
                                onClick={() => setReqOrgOpen(true)}
                                disabled={organizationRequested}
                                className={classes.reqButton}
                            >
                                {organizationRequested ? "Org. Request Pending" : "Request an Organization"}
                            </Button>
                        )}
                    </div>
                </div>
            </div>
            {modelDirLoading && <Loading className={classes.loading} message="Loading House Models" />}
            {!modelDirLoading && isEmpty(singleModels) && isEmpty(folders) && (
                <div className={classes.dashboardEmpty}>
                    {/* Empty directory */}
                    <SectionHeading
                        title="Looks like you are a new user!"
                        subtitle="Get started by uploading your code library or diving straight in and creating your first house model."
                        className={classes.emptyHeader}
                    />
                    <div className={classes.boxes}>
                        <div className={classes.box}>
                            <CodeLibIcon />
                            <h3>Upload your Code Library</h3>
                            <p>
                                Long-time HOT2000 user? Upload your code library to bring Volta SNAP up to speed with
                                all your favourite component assemblies.
                            </p>
                            <Button onClick={() => history.push("/code-library")}>Upload Your Code Library</Button>
                        </div>
                        <div className={classes.box}>
                            <HouseIcon />
                            {/* in this folder */}
                            <h3>Create your first House Model </h3>
                            <p>
                                Get started on your first model! Have your building's construction PDFs ready to upload
                                and get ready to start drawing!
                            </p>
                            <Button onClick={() => history.push("/create")}>Create New Model</Button>
                        </div>
                    </div>
                </div>
            )}
            {!modelDirLoading && !isEmpty(singleModels) && (
                <div className={classes.dashboardBody}>
                    {error && <p>{error}</p>}
                    <div className={classes.nestedPathsContainer}>
                        {path.length > 2 && <GoBack path={path} history={history} folders={folders} />}
                        <NestedPath
                            path={path}
                            setFolderToDelete={(folderId) => setFolderToDelete(folderId)}
                            setFolderToRename={(folderId) => setFolderToRename(folderId)}
                            folders={folders}
                            setFolderToMove={(folderId) => setFolderToMove(folderId)}
                            onDragOver={onDragUpdate}
                            setModelsToSummarize={() => setModelsToSummarize(filteredModels.map((el) => el[0]))}
                        />
                    </div>
                    <div className={classes.tableHead}>
                        <div>Name</div>
                        <div>Address</div>
                        <div>Last Modified</div>
                        <div className={classes.createNew} ref={createRef}>
                            <span onClick={() => setIsCreateOpen(!isCreateOpen)}>
                                Create <AddIcon />
                            </span>
                            <Dropdown
                                className={classes.createDropdown}
                                open={isCreateOpen}
                                options={createNewOptions}
                            />
                        </div>
                    </div>

                    <ul style={{ userSelect: "none" }} ref={folderModelRef}>
                        {allFoldersModels.map(
                            (
                                [
                                    key,
                                    {
                                        name,
                                        address = "",
                                        complete,
                                        lastEdited,
                                        tags,
                                        sharedWithUser = false,
                                        type,
                                        disableDelete = false,
                                    },
                                ],
                                index
                            ) => (
                                <div
                                    key={key}
                                    draggable
                                    onDragStart={(event) => onDragStart(event, key, name, type)}
                                    onDragOver={(event) => {
                                        if (!dragLock) {
                                            event.stopPropagation();
                                            onDragUpdate(event, key, type);
                                        }
                                    }}
                                    onDragEnd={(event) => onDragEnd(event)}
                                    onDragLeave={(event) => {
                                        event.preventDefault();
                                        event.stopPropagation();

                                        setDropTo("");
                                    }}
                                    className={classes.draggableModelFolder}
                                    onClick={(event) => onClick(event, type, key, index)}
                                >
                                    {type === "folder" ? (
                                        <>
                                            <Folder
                                                key={key}
                                                folderId={key}
                                                name={name}
                                                sharedWithUser={sharedWithUser}
                                                disableDelete={disableDelete}
                                                startDelete={startFolderDelete}
                                                lastEdited={lastEdited}
                                                pathname={pathname === "/" ? "/dashboard" : pathname}
                                                setFolderToRename={() => setFolderToRename(key)}
                                                setFolderToMove={(folderId) => setFolderToMove(folderId)}
                                                tags={tags}
                                                setSelectedFolderModel={setSelectedFolderModel}
                                                selectedFolderModel={selectedFolderModel}
                                                isSelected={selectedModelsFolders.includes(key)}
                                                isDragging={isDragging}
                                                combineWith={
                                                    dropTo === key &&
                                                    !selectedModelsFolders.includes(key) &&
                                                    selectedFolderModel !== key
                                                }
                                                isDropInProcess={
                                                    isDropInProcess &&
                                                    (selectedModelsFolders.includes(key) || key === selectedFolderModel)
                                                }
                                                selectedModelsFolders={selectedModelsFolders}
                                                setFolderModelsToDelete={setFolderModelsToDelete}
                                                disableMultiDelete={dragLock}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <Model
                                                key={key}
                                                modelId={key}
                                                selectModel={selectModel}
                                                name={name}
                                                address={address}
                                                lastEdited={lastEdited}
                                                startDelete={startDelete}
                                                startSharedModelRemove={startSharedModelRemove}
                                                tags={tags}
                                                setModelToMove={(modelId) => setModelToMove(modelId)}
                                                setModelToDuplicate={(modelId) => setModelToDuplicate(modelId)}
                                                setDuplicateName={(name) => setDuplicateName(name)}
                                                sharedWithUser={sharedWithUser}
                                                setSelectedFolderModel={setSelectedFolderModel}
                                                selectedFolderModel={selectedFolderModel}
                                                isSelected={selectedModelsFolders.includes(key)}
                                                isDragging={isDragging}
                                                isDropInProcess={
                                                    isDropInProcess &&
                                                    (selectedModelsFolders.includes(key) || key === selectedFolderModel)
                                                }
                                                selectedModelsFolders={selectedModelsFolders}
                                                setFolderModelsToDelete={setFolderModelsToDelete}
                                                disableMultiDelete={dragLock}
                                            />
                                        </>
                                    )}
                                </div>
                            )
                        )}
                    </ul>
                </div>
            )}
            {!modelDirLoading && isEmpty(filteredModels) && isEmpty(filteredFolders) && currentFolderId != null && (
                <div className={classes.dashboardEmpty}>
                    <div className={classes.singleBox}>
                        <div></div>
                        <div className={classes.box}>
                            <HouseIcon />
                            {/* in this folder */}
                            <h3>Create your first House Model in this folder</h3>
                            <Button
                                onClick={() =>
                                    history.push(`/create${currentFolderId ? `?folderId=${currentFolderId}` : ""}`)
                                }
                            >
                                Create New Model
                            </Button>
                        </div>
                        <div></div>
                    </div>
                </div>
            )}
            {toDelete && (
                <DeleteDialog
                    singleModels={singleModels}
                    toDelete={toDelete}
                    deleting={deleting}
                    onClick={async () => {
                        toggleDeleting(true);
                        await deleteModel({ modelId: toDelete, uid });
                        toggleDeleting(false);
                        toggleToDelete("");
                    }}
                    toggleToDelete={() => toggleToDelete("")}
                />
            )}
            {toRemoveSharedModel && (
                <DeleteDialog
                    singleModels={singleModels}
                    toDelete={toRemoveSharedModel}
                    deleting={deleting}
                    onClick={async () => {
                        toggleDeleting(true);
                        await removeSharedModelFromDir({ modelId: toRemoveSharedModel, uid });
                        toggleDeleting(false);
                        toggleToRemoveSharedModel("");
                    }}
                    toggleToDelete={() => toggleToRemoveSharedModel("")}
                    isSharedModel={true}
                />
            )}
            {folderToDelete && (
                <DeleteDialog
                    singleModels={folders}
                    toDelete={folderToDelete}
                    deleting={folderLoading}
                    onClick={async () => {
                        const index = path.findIndex((el) => el === folderToDelete);

                        if (index !== -1) {
                            if (index === 0) history.push("/dashboard");

                            if (index > 0) history.push(`/dashboard/${path.slice(0, index).join("/").toString()}`);
                        }

                        await deleteFolder(folderToDelete, uid);
                        setFolderToDelete("");
                    }}
                    toggleToDelete={() => setFolderToDelete("")}
                    isFolder
                />
            )}
            {folderModelsToDelete.length > 1 && (
                <DeleteMultipleFilesDialog
                    l
                    filesToDelete={folderModelsToDelete}
                    toggleToDelete={() => setFolderModelsToDelete([])}
                    onClick={() => handleMultipleDelete()}
                    deleting={isMultipleDeleting}
                />
            )}
            <DuplicateDialog
                modelToDuplicate={modelToDuplicate}
                newDuplicateName={duplicateName}
                setDuplicateName={(newVal) => setDuplicateName(newVal)}
                onClick={async () => {
                    setDuplicateLoading(true);
                    await duplicateModel(modelToDuplicate, uid, currentFolderId, duplicateName);
                    setModelToDuplicate("");
                    setDuplicateLoading(false);
                }}
                close={() => setModelToDuplicate("")}
                duplicateLoading={duplicateLoading}
            />
            <CreateDialog
                toCreate={toCreate}
                newFolderName={folderName}
                setNewFolderName={(newVal) => setFolderName(newVal)}
                onClick={async () => {
                    await createFolder(uid, folderName, currentFolderId);
                    setToCreate(false);
                    setFolderName("");
                }}
                close={() => setToCreate(false)}
                folderLoading={folderLoading}
            />
            <OrgRequestDialog
                open={reqOrgOpen}
                organizationName={organizationName}
                setOrganizationName={setOrganizationName}
                onClick={async () => {
                    setOrgRequestLoading(true);
                    await sendOrganizationRequest(uid, organizationName);
                    setReqOrgOpen(false);
                    setOrganizationName("");
                    setOrgRequestLoading(false);
                }}
                close={() => setReqOrgOpen(false)}
                orgRequestLoading={orgRequestLoading}
            />
            <RenameDialog
                toRename={folderToRename}
                newFolderName={newFolderName}
                onClick={async () => {
                    await renameFolder(folderToRename, uid, newFolderName);
                    setNewFolderName("");
                    setFolderToRename("");
                }}
                setNewFolderName={(val) => setNewFolderName(val)}
                close={() => setFolderToRename("")}
                folderLoading={folderLoading}
            />
            <ExportSummaryDialog
                modelIds={modelsToSummarize}
                close={() => setModelsToSummarize([])}
                exportLoading={exportLoading}
                onClick={handleBatchSummaryExport}
            />
            <SelectFolder
                folderToMove={folderToMove}
                modelToMove={modelToMove}
                close={() => {
                    setFolderToMove("");
                    setModelToMove("");
                }}
                folders={folders}
                models={singleModels}
                moveFolder={(moveToId) => {
                    moveFolder(folderToMove, uid, moveToId);

                    setSelectedFolderModel("");
                    if (selectedModelsFolders.length > 1) setSelectedModelsFolders([]);
                }}
                moveModel={(moveToId) => {
                    moveModel(modelToMove, uid, moveToId);

                    setSelectedFolderModel("");
                    if (selectedModelsFolders.length > 1) setSelectedModelsFolders([]);
                }}
                folderLoading={folderLoading}
                modelLoading={modelLoading}
                history={history}
            />
        </div>
    );
};

export default Dashboard;
