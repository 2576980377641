import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import Measurements from "./";
import { actions } from "features/Model/Enclosure/_ducks";
import isEmpty from "lodash/isEmpty";

const { updateDrawingChanges } = actions;

const mapStateToProps = ({ form }, { accessor }) => {
    const selector = formValueSelector("model");
    const windowHeight = selector({ form }, `${accessor}.measurements.height`);
    const windowWidth = selector({ form }, `${accessor}.measurements.width`);
    const overhangWidth = selector({ form }, `${accessor}.measurements.overhangWidth`);
    const overhangWidthDrawing = selector({ form }, `${accessor}.measurements.overhangWidth_drawingRef`) || {};
    const headerHeight = selector({ form }, `${accessor}.measurements.headerHeight`);
    const headerHeightDrawing = selector({ form }, `${accessor}.measurements.headerHeight_drawingRef`) || {};
    const heightUnits = selector({ form }, `${accessor}.measurements.height_u`);
    const widthUnits = selector({ form }, `${accessor}.measurements.width_u`);
    const overhangUnits = selector({ form }, `${accessor}.measurements.overhangWidth_u`);
    const headerUnits = selector({ form }, `${accessor}.measurements.headerHeight_u`);
    const areaUnits = selector({ form }, `${accessor}.measurements.area_u`);
    const modelUnits = selector({ form }, `modelData.uiSettings.primaryUnits`);
    const { component: drawingComponent = "", image: drawingImage } = selector({ form }, `${accessor}.drawing`) || {};

    const windowWidthEquation = selector({ form }, `${accessor}.measurements.width_eqn`);
    const windowHeightEquation = selector({ form }, `${accessor}.measurements.height_eqn`);
    const windowHeaderHeightEquation = selector({ form }, `${accessor}.measurements.headerHeight_eqn`);
    const windowOverhangWidthEquation = selector({ form }, `${accessor}.measurements.overhangWidth_eqn`);

    return {
        accessor,
        windowHeight,
        windowWidth,
        overhangWidth,
        headerHeight,
        modelUnits,
        heightUnits,
        widthUnits,
        overhangUnits,
        headerUnits,
        areaUnits,
        overhangWidthDrawing: !isEmpty(overhangWidthDrawing),
        headerHeightDrawing: !isEmpty(headerHeightDrawing),
        drawingComponent,
        drawingImage,
        windowWidthEquation,
        windowHeightEquation,
        windowHeaderHeightEquation,
        windowOverhangWidthEquation,
    };
};

const mapDispatchToProps = (dispatch) => ({
    updateDrawingChanges: (componentId, updates) => dispatch(updateDrawingChanges({ componentId, updates })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Measurements);
