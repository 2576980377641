import React from 'react';
import sharedClasses from '../../style.module.scss';
import Tooltip from 'components/Tooltip';

export default React.memo(({
    codeLabel,
    rValue,
    height,
    width,
    windowNumIdentical
}) => (
    <div className={sharedClasses.stats} style={{gridTemplateColumns:'1.5fr 1fr 1fr 1fr 0.5fr'}}>
        <span
            data-for="window-stats"
            data-tip={`<span><strong>Type:</strong> ${codeLabel}</span>`}
            data-html
            className={sharedClasses.clipType}
        >
            <strong>Type:</strong> {codeLabel}
        </span>
        <span
            data-for="window-stats"
            data-tip={`<span><strong>Eff. R-Value:</strong> ${rValue}</span>`}
            data-html
        >
            <strong>R:</strong> {rValue}
        </span>
        <span
            data-for="window-stats"
            data-tip={`<span><strong>Width:</strong> ${width}</span>`}
            data-html
        >
            <strong>W:</strong> {width}
        </span>
        <span
            data-for="window-stats"
            data-tip={`<span><strong>Height:</strong> ${height}</span>`}
            data-html
        >
            <strong>H:</strong> {height}
        </span>
        <span
            data-for="window-stats"
            data-tip={`<span><strong>Number of Identical Windows:</strong> ${windowNumIdentical}</span>`}
            data-html
        >
            <strong>No.</strong> {windowNumIdentical}
        </span>
        <Tooltip id="window-stats" />
    </div>
));