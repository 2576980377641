import React, { useState, useEffect } from 'react';
import classes from './style.module.scss';
import sharedClasses from 'features/Model/sharedStyles.module.scss';
import { Field } from 'redux-form';
import { maxLength } from 'utils/fieldValidation';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import InputRow from 'components/Input/Row';
import Input from 'components/Input';
import Add from 'assets/images/icons/JSX/Add';
import Tooltip from 'components/Tooltip';
import Drawer from 'components/Drawer';
import Button from 'components/Button';
import Requirements from './Requirements/container';
import Delete from 'assets/images/icons/JSX/Delete';

const charLim20 = maxLength(20);
const charLim10000 = maxLength(10000);

const Info = ({
    accessor,
    handleDelete,
    id,
    change,
}) => {

    return (
        <>
            <InputRow gridTemplate="15% 1fr auto">
                <Field
                    component={Input}
                    name={`${accessor}.id`}
                    type="text"
                    label="ID"
                    placeholder="Enter ID"
                    validate={charLim20}
                    hideLabel
                />
                <Field
                    className={classes.valueField}
                    component={Input}
                    name={`${accessor}.value`}
                    label="Text"
                    placeholder="Enter description"
                    validate={charLim10000}
                    hideLabel
                />
                <div className={classes.delete} onClick={()=>handleDelete(id)} type="red">
                    <span data-tip="Delete" data-for={`delete-${id}`}><Delete /></span>
                </div>
                <Tooltip id={`delete-${id}`} delay />
            </InputRow>
        </>
    )
};



export default React.memo(({
    accessor,
    h2kInfo,
    modelUnits,
    change
}) => {
    const [open, toggleOpen] = useState(false);
    const [newId, setNewId] = useState('Info. ');
    const [newValue, setNewValue] = useState('');

    useEffect(() => {
        if (Array.isArray(h2kInfo)) {
            change('modelData.information.h2kInfo', {});
        }
    },[])

    const handleCreateInfoItem = () => {
        change(`modelData.information.h2kInfo.info-${moment().format('YYYYMMDDHHmmssSS')}`, {id: newId, value: newValue});
        setNewId('Info. ');
        setNewValue('');
    };

    const handleInfoDelete = (id) => {
        const cutInfoObj = Object.entries(h2kInfo).reduce((acc, [key, value]) => {
            if (key !== id || value.id === 'voltasnap') {
                return {
                    ...acc,
                    [key]: value
                }
            }
            return acc
        }, {});

        change('modelData.information.h2kInfo', cutInfoObj);
    };
    

    return (

            <div className={sharedClasses.section}>
                <div className={classes.infoHeader}>
                    <div>
                        <h4>Add new information</h4>
                        <p>Enter NRCan and other partner communication requirements.</p>
                    </div>
                    <Button className={classes.programRequirements} icon={Add} type="hollow" onClick={()=>toggleOpen(true)}>
                        Add Program Requirements
                    </Button>
                </div>
                <InputRow gridTemplate="15% 1fr auto">
                    <Input
                        name={`newInfoId`}
                        type="text"
                        label="ID"
                        placeholder="Enter ID"
                        validate={charLim20}
                        input={{
                            onChange:((value) => {
                                setNewId(value);
                            }),
                            value:newId
                        }}
                    />
                    <Input
                        className={classes.valueField}
                        name={`newInfoValue`}
                        label="Text"
                        placeholder="Enter description"
                        validate={charLim10000}
                        input={{
                            onChange:((value) => {
                                setNewValue(value);
                            }),
                            value:newValue
                        }}
                    />
                    <div className={classes.addInfo}>
                        <Button
                            small
                            onClick={() => handleCreateInfoItem()}
                            icon={Add}
                        >
                            Add Info
                        </Button>
                    </div>
                </InputRow>
                {
                    !isEmpty(h2kInfo) && Object.keys(h2kInfo).length >= 1 ?
                        <div className={classes.infoItems}>
                            {Object.keys(h2kInfo)
                                .filter(id => id !== "newId" && id !== "newValue")
                                .map((id) => {
                                if (h2kInfo[id].id !== 'voltasnap') {
                                    return (
                                        <Info 
                                            accessor={`${accessor}.h2kInfo.${id}`} 
                                            change={change} 
                                            key={id}
                                            id={id}
                                            handleDelete={handleInfoDelete}
                                        />
                                    )
                                }
                            })}
                        </div> :
                            <h5>No items to show. Get started by clicking "Add Info" below.</h5>
                }
                <Tooltip id="infoHeader" />
                <Drawer
                    open={open}
                    close={()=>toggleOpen(false)}
                    preTitle="Requirements"
                    title="Information Field Requirements"
                    subtitle={'Some Info "ID" fields have specific reporting requirements. Those that are listed in the HOT2000 User Guide are summarized below, and can be added to your list.'}
                >
                    <Requirements accessor={accessor} change={change} />
                    <Button
                        className={classes.close}
                        large
                        type="hollow"
                        onClick={()=>toggleOpen(false)}
                    >
                        Close
                    </Button>
                </Drawer>
            </div>

)})