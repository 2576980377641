import { connect } from "react-redux";
import { reduxForm, formValueSelector, reset } from "redux-form";
import StandardCode from "./";
import wallCodeTemplate from "features/Model/Enclosure/Templates/wallCode.json";
import { actions as userActions } from "store/users";
import { actions as enclosureActions } from "features/Model/Enclosure/_ducks";
import { actions as upgradeActions } from "features/Model/Upgrades/_ducks";
import isEmpty from "lodash/isEmpty";
import { standardCodeLogic } from "utils/codes";

const { addToCodeLib, deleteCode } = userActions;
const { setInitCode, getRValue } = enclosureActions;
const { updateUpgradeCodes } = upgradeActions;

const mapStateToProps = (
    {
        form,
        model: { modelId },
        user: { uid },
        enclosure: { initCodeValues: { wallInsType = {} } = {} } = {},
        upgradeLibrary: { currentPackage, selectedUpgrade },
    },
    { fieldAccessor, modelFormChange, currentFormChange, toggleOpen, isCodeLibrary = false, componentId }
) => {
    const selector = formValueSelector("wallCode");
    const { id: wallStructureId, value: wallStructureValue = 0 } =
        selector({ form }, "wallCode.layers.structureType") || {};
    const { value: wallTypeSizeValue } = selector({ form }, "wallCode.layers.componentTypeSize") || {};
    const { id: wallSpacingId, value: wallSpacingValue } = selector({ form }, "wallCode.layers.spacing") || {};
    const { value: wallFramingValue } = selector({ form }, "wallCode.layers.framing") || {};
    const { value: wallIns1Value } = selector({ form }, "wallCode.layers.insulationLayer1") || {};
    const { value: wallIns2Value } = selector({ form }, "wallCode.layers.insulationLayer2") || {};
    const { value: wallInteriorValue } = selector({ form }, "wallCode.layers.interior") || {};
    const { value: wallSheathingValue } = selector({ form }, "wallCode.layers.sheathing") || {};
    const { value: wallExteriorValue } = selector({ form }, "wallCode.layers.exterior") || {};
    const { value: wallStudsValue } = selector({ form }, "wallCode.layers.studsCornerIntersection") || {};
    const wallLabel = selector({ form }, "wallCode.label") || "";

    const frameSpacingValue = wallSpacingId === null ? wallFramingValue : wallSpacingValue;

    const wallValue = `1${wallStructureValue
        .toString()
        .substr(
            -1
        )}${wallTypeSizeValue}${frameSpacingValue}${wallIns1Value}${wallIns2Value}${wallInteriorValue}${wallSheathingValue}${wallExteriorValue}${wallStudsValue}`;

    const { codeRef: editingCodeRef = null, isLibCode = false } = wallInsType;

    const modelSelector = formValueSelector("model");
    const modelCodes = modelSelector({ form }, "modelData.codes") || {};
    const components = modelSelector({ form }, "modelData.components") || {};

    return {
        wallLabel,
        fieldAccessor,
        modelFormChange,
        currentFormChange,
        initialValues: {
            wallCode: isEmpty(wallInsType) ? wallCodeTemplate : wallInsType,
            addToLibrary: isLibCode,
        },
        wallStructureId,
        wallTypeSizeValue,
        isSolid: parseFloat(wallStructureId) === 4,
        isPanel: parseFloat(wallStructureId) === 5,
        isFloorHeader: parseFloat(wallStructureId) === 6,
        makeSpacingDefault: ["5", "6", "7", "8", "9", "A", "B", "C"].includes(wallTypeSizeValue),
        makeInsulationDefault: ["6", "7", "8", "9", "A", "B", "C"].includes(wallTypeSizeValue),
        wallValue,
        modelId,
        uid,
        editingCodeRef,
        isEditing: !!editingCodeRef,
        isLibCode,
        wallSpacingId,
        toggleOpen,
        isCodeLibrary,
        isUpgradeComponent: fieldAccessor.includes("fields."),
        componentId,
        modelCodes,
        components,
        currentPackage,
        selectedUpgrade,
    };
};

const mapDispatchToProps = (dispatch) => ({
    clearForm: () => dispatch(reset("wallCode")),
    clearInitCode: () => dispatch(setInitCode({}, "wallInsType")),
});

const mergeProps = (state, dispatch, own) => ({
    ...state,
    ...dispatch,
    ...own,
});

const onSubmit = async (
    form,
    dispatch,
    {
        toggleOpen,
        uid,
        fieldAccessor,
        modelFormChange,
        currentFormChange,
        editingCodeRef,
        isCodeLibrary,
        componentId,
        isEditing,
        modelCodes,
        components,
        currentPackage,
        selectedUpgrade,
    }
) => {
    const { wallCode = {}, addToLibrary = false, setAsModelDefault = false, selectedComponents = [] } = form;

    toggleOpen(false);

    // Run standard code logic
    standardCodeLogic({
        componentCodeType: "Wall",
        componentId,
        code: wallCode,
        formInputName: "wallInsType",
        codeType: "S",
        isCodeLibrary,
        isEditing,
        editingCodeRef,
        dispatch,
        getRValue,
        modelFormChange,
        currentFormChange,
        fieldAccessor,
        components,
        modelCodes,
        addToLibrary,
        addToCodeLib,
        uid,
        deleteCode,
        updateUpgradeCodes,
        setAsModelDefault,
        selectedComponents,
        currentPackage,
        selectedUpgrade,
    });

    // Clear wall code defaults for next time
    dispatch(setInitCode({}, "wallInsType"));
    // Reset form
    dispatch(reset("wallCode"));
};

const form = reduxForm({
    form: "wallCode",
    enableReinitialize: true,
    onSubmit: onSubmit,
})(StandardCode);

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(form);
