import React from 'react';

export default ({className}) => (
    <svg className={className} width="46px" height="46px" viewBox="0 0 46 46" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="Model---General" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="General---Open" transform="translate(-872.000000, -492.000000)" stroke="#39393C" strokeWidth="2">
                <g id="Group-15-Copy-3" transform="translate(863.000000, 481.000000)">
                    <g id="Group-13" transform="translate(0.000000, 2.000000)">
                        <g id="Group" transform="translate(32.000000, 32.000000) rotate(-225.000000) translate(-32.000000, -32.000000) translate(10.000000, 10.000000)">
                            <g id="Group-10" transform="translate(0.000000, 0.000000)">
                                <path d="M3.85114163e-12,22 C3.85114163e-12,34.1502645 9.8497355,44 22,44 C34.1502645,44 44,34.1502645 44,22 C44,9.8497355 34.1502645,3.86403174e-12 22,3.86179977e-12 C9.8497355,3.8595678e-12 3.85824706e-12,9.8497355 3.85114163e-12,22 Z M36.908168,7.09183203 L34.875236,9.12476403 M9.27207794,34.7279221 L7.09183203,36.908168 M36.908168,36.908168 L34.7279221,34.7279221 M9.27207794,9.27207794 L7.09183203,7.09183203" id="Combined-Shape"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)