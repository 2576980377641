import { DashboardAPI } from "utils/api";

export const loginUserAPI = async ({ email = "", password = "" }) => {
    return DashboardAPI({
        method: "POST",
        url: "/loginUser",
        data: {
            email: email,
            password: password,
        },
    }); //.catch((err) => console.log(err));
};

export const changeUserPassword = async ({ oldPassword = "", newPassword = "" }) => {
    return DashboardAPI({
        method: "POST",
        url: "/changeUserPassword",
        data: {
            oldPassword,
            newPassword,
        },
    }).catch((err) => {
        return { status: 500, err, data: {} };
    });
};
