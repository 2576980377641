import React from "react";
import classes from "./style.module.scss";

const LoadingDots = ({
    style: styles,
    color = "#646f81",
    small = false,
    className
}) => (
    <span
        style={styles && styles}
        className={`${classes.ThreeDots} ${small &&
            classes.smallDots} ${className}`}
    >
        <span style={{ backgroundColor: color }}></span>
        <span style={{ backgroundColor: color }}></span>
        <span style={{ backgroundColor: color }}></span>
    </span>
);

export default LoadingDots;