//TODO : Select>Search is not currently supported. Can be made to work it would be good to 
//TODO:  first understand the desired/intended behavior.
//TODO : Make scrolling behavior not having to rely on magic numbers.

export const onFocusSelect = (e) => {
    let targetChild = _getTargetChild(e.target.children,"style_selectInput")

    if (targetChild) {
        targetChild.classList.add("manual-focus")
    }
}

export const onBlurSelect = (e,toggleOpen,search=false) => {
    if (search) {
        return; //This function makes it so that we can't manually click on the search bar, so we can't fire it here
    }
    let targetChild = _getTargetChild(e.target.children,"style_selectInput");

    if (targetChild) {
        targetChild.classList.remove("manual-focus")
    }
    toggleOpen(false)
}

export const onKSelect = (e, open, toggleOpen, search) => {

    if (e.keyCode === 13) { //? enter
        toggleOpen(false)
    } else if ((e.keyCode === 40) || (e.keyCode === 38)) {//? up and down arrow
        e.preventDefault()
        if (!open) {
            toggleOpen(true)
        } else {
            let dd = _getTargetChild(e.target.children, "style_dropDown")
            let optionList = dd.children[0]
            const options = _getSelectedOption(optionList.children)
            if (!options) {
                return
            }
            const {selectedOption, index} = options
            

            if (e.keyCode === 40) { //Down 
                if (dd.children[0].children[index +1]) {
                    dd.children[0].children[index +1].click()
                    dd.children[0].scrollBy(0,30)
                    toggleOpen(true)
                }
            }

            if (e.keyCode === 38) { //Up
                if (dd.children[0].children[index-1]) {
                    dd.children[0].children[index-1].click()
                    toggleOpen(true)
                    dd.children[0].scrollBy(0,-30)
                }
            }
        }
    } else if (e.keyCode === 9 && search) {
        //If tab is pressed and we're dealing with a search drop-down we want to close and deactivate the field
        onBlurSelect(e, toggleOpen);
    }
}



//? Private methods
const _getTargetChild = (children, targetClass) => {
    for (let i = 0; i < children.length; i++) {
        if (children[i].className) {
            if (children[i].className.includes(targetClass)) {
                return children[i]
            }
        }
    }
}

const _getSelectedOption = (options) => {
    for (let i = 0; i < options.length; i++) {
        
        if (options[i].className) {
            if (options[i].className.includes("selected")) {
                return {
                    selectedOption : options[i],
                    index: i
                }
            }
        }
    }
}