import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import FloorHeader from './';
import { getBaseUnits, unitLabels } from 'utils/fields';
import convertUnit from 'utils/convertUnit';

const mapStateToProps = (
    {
        form
    },
    {
        accessor
    }
) => {
    const selector = formValueSelector('model');
    const floorHeaderHeight = selector({form}, `${accessor}.measurements.height`) || {};
    const floorHeaderPerimeter = selector({form}, `${accessor}.measurements.perimeter`) || {};
    const floorHeaderArea = selector({form}, `${accessor}.measurements.area`) || {};
    const { codeLabel: floorHeaderLabel } = selector({form}, `${accessor}.floorHeaderInsType`) || {};
    const floorHeaderNomRVal = selector({form}, `${accessor}.floorHeaderInsType_nomRVal`) || 0;
    const floorHeaderEffRVal = selector({form}, `${accessor}.floorHeaderInsType_effRVal`) || 0;

    const heightUnits = selector({form}, `${accessor}.measurements.height_u`);
    const perimeterUnits = selector({form}, `${accessor}.measurements.perimeter_u`);
    const areaUnits = selector({form}, `${accessor}.measurements.area_u`);
    const rValUnits = selector({form}, `${accessor}.floorHeaderInsType_u`);
    const modelUnits = selector({form}, `modelData.uiSettings.primaryUnits`);

    const { trueBase:heightBase, displayBase:heightDisplay } = getBaseUnits('floorHeaderHeight', modelUnits);
    const { trueBase:perimeterBase, displayBase:perimeterDisplay } = getBaseUnits('floorHeaderPerimeter', modelUnits);
    const { trueBase:areaBase, displayBase:areaDisplay } = getBaseUnits('floorHeaderArea', modelUnits);
    const { trueBase:rValBase, displayBase:rValDisplay } = getBaseUnits('floorHeaderInsType', modelUnits);

    const heightU = heightUnits || heightDisplay || heightBase;
    const perimeterU = perimeterUnits || perimeterDisplay || perimeterBase;
    const areaU = areaUnits || areaDisplay || areaBase;
    const rValU = rValUnits || rValDisplay || rValBase;
    
    return {
        codeLabel:floorHeaderLabel,
        rValue:`${parseFloat(convertUnit({
            value:floorHeaderLabel === 'User Specified' ? floorHeaderEffRVal : floorHeaderNomRVal,
            type:'thermalResistance',
            inputUnit:rValBase,
            outputUnit:rValU,
        })).toFixed(1)}${rValU}`,
        height:`${parseFloat(convertUnit({
            value:floorHeaderHeight,
            type:'length',
            inputUnit:heightBase,
            outputUnit:heightU,
        })).toFixed(2)}${heightU}`,
        perimeter:`${parseFloat(convertUnit({
            value:floorHeaderPerimeter,
            type:'length',
            inputUnit:perimeterBase,
            outputUnit:perimeterU,
        })).toFixed(1)}${perimeterU}`,
        area:`${parseFloat(convertUnit({
            value:floorHeaderArea,
            type:'area',
            inputUnit:areaBase,
            outputUnit:areaU,
        })).toFixed(1)}${unitLabels(areaU)}`,
    }
};

const mapDispatchToProps = () => ({});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FloorHeader);