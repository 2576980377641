import React from "react";
import classes from "./style.module.scss";

const EmissionsToggle = (
{    handleRadioClick,
    handleChangeEmissionFactor,
    check1,
    check2}
) => {
    return (
        <div className={classes.upInToggle}>
            <input
                onClick={()=>handleRadioClick()}
                onChange={() => handleChangeEmissionFactor("hefFf")}
                type="radio"
                id="switch_left"
                className={classes.switch_left}
                name="switch_2"
                value="hefFf"
                checked={check1}
            />
            <label for="switch_left">
                <b>Fossil fuel-only</b>
            </label>
            <input
                style={{paddingRight:200}}
                onClick={() =>handleRadioClick()}
                onChange={() => handleChangeEmissionFactor("hefLca")}
                type="radio"
                id="switch_right"
                className={classes.switch_right}
                name="switch_2"
                value="hefLca"
                checked={check2}
            />
            <label for="switch_right">
                <b>Life Cycle</b>
            </label>
        </div>
    );
};

export default EmissionsToggle;
