import { genEnvirocentreNZReport } from "utils/reportGen/api";

const generatePdf = (formData) => dispatch => {
    return genEnvirocentreNZReport(formData)
        .then(({data}) => data)
        .catch((err) => {
            console.log(err)
        });
}

export default {
    generatePdf
}