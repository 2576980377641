import React, { useEffect, useState, useRef, useCallback } from "react";
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableSortLabel,
    TableBody,
    TablePagination,
    TableFooter,
    Collapse,
} from "@material-ui/core";
import LoadingDots from "components/LoadingDots";
import classNames from "classnames";

const descendingComparator = (a, b) => {
    if (!b && !a) {
        return 0;
    }

    const { value: av } = a || {};
    const { value: bv } = b || {};

    if (av === bv) {
        return 0;
    } else if (!av) {
        return 1;
    } else if (!bv) {
        return -1;
    }

    return bv < av ? -1 : 1;
};

const getComparator = (order, orderBy) => {
    return (a, b) => order * descendingComparator(a[orderBy], b[orderBy]);
};

const stableSort = (array, comparator) => {
    return array
        .map((el, index) => [el, index])
        .sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        })
        .map((el) => el[0]);
};

const MaterialUiTable = ({
    headCells=[],
    rows=[],
    paginate = false,
    defaultRowsPerPage = 10,
    rowsPerPageOptions = [10, 25, 50, 100],
    defaultSortProp = "",
    className,
    headClassName
}) => {
    const [order, setOrder] = useState(null);
    const [orderBy, setOrderBy] = useState(null);

    //Pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

    useEffect(() => {
        if (defaultSortProp) {
            setOrder(1);
            setOrderBy(defaultSortProp);
        }
    }, [defaultSortProp]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 1;
        setOrder(isAsc ? -1 : 1);
        setOrderBy(property);
    };

    const orderFunc =
        headCells[orderBy] && headCells[orderBy].sort
            ? headCells[orderBy].sort(order, orderBy)
            : getComparator(order, orderBy);

    const sortedRows = stableSort(rows, orderFunc);

    const paginatedRows =
        paginate && rowsPerPage > 0
            ? sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : sortedRows;

    return (
        <Table className={className}>
            <TableHead className={headClassName}>
                <TableRow>
                    {headCells.map(({ id, label="", width = "auto", align="right", sortable=false, className }, index) => (
                        <TableCell
                            align={align}
                            className={classNames({[className]:className, noLabel: !label })}
                            width={width}
                            key={`head-${index}`}
                            padding="none"
                        >
                            {sortable ? (
                                <TableSortLabel
                                    active={orderBy === id}
                                    direction={orderBy === id && order === 1 ? "desc" : "asc"}
                                    onClick={(event) => handleRequestSort(event, id)}
                                >
                                    {label}
                                </TableSortLabel>
                            ) : (
                                <span className="label">{label}</span>
                            )}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {paginatedRows.map((row, index) => (
                    <TableRow className={row.rowClassName} key={`row-${index}`}>
                        {headCells.map(({ id="", width = "auto", label, align="right" }) => (
                            <TableCell
                                key={`row-${index}-${id}`}
                                align={align}
                                className={classNames({[row[id]?.className]:row[id]?.className, noLabel: !label })}
                                width={width}
                                style={{
                                    ...row[id]?.verticalAlign ? { verticalAlign:row[id]?.verticalAlign } : {}
                                 }}
                            >
                                {row[id]?.isLoading ? <LoadingDots className={`loadingDots ${align === "right" ? "rightAlign" : ""}`} /> : <span className="MuiTableCell-root--inner">{row[id]?.content}</span>}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
            {paginate && rows.length > rowsPerPage && (
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={rowsPerPageOptions}
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            SelectProps={{
                                inputProps: {
                                    "aria-label": "rows per page",
                                },
                            }}
                        />
                    </TableRow>
                </TableFooter>
            )}
        </Table>
    )
};

export default MaterialUiTable;