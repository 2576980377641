import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectDidUserSawNewDrawing } from "../_ducks/selector";
import { setDidUserSawNewDrawing } from "../_ducks/actions";

import Dialog, { CloseDialog } from "components/Dialog";
import Button from "components/Button";

import { didUserSawNewDrawingContainer, buttonLetsGo } from "./style.module.scss";

const DidUserSawNewDrawing = ({ didUserSawNewDrawing, setDidUserSawNewDrawing }) => {
    return (
        <Dialog open={!didUserSawNewDrawing}>
            <CloseDialog closeActions={() => setDidUserSawNewDrawing(true)} />
            <div className={didUserSawNewDrawingContainer}>
                <h3>Welcome to the new and improved Drawing Capture Tool!</h3>
                <p>
                    We've made some changes to the Drawing Capture tool, adding new features and improving performance
                    and user experience.
                </p>
                <p>
                    You will need to recreate shapes and annotations for drawings uploaded using older versions of the
                    tool. However, any HOT2000 components or other measurements will still be present in your model.
                </p>
                <Button className={buttonLetsGo} type="hollow" onClick={() => setDidUserSawNewDrawing(true)}>
                    Continue
                </Button>
            </div>
        </Dialog>
    );
};

const mapStateToProps = createStructuredSelector({
    didUserSawNewDrawing: selectDidUserSawNewDrawing,
});

const mapDispatchToProps = (dispatch) => ({
    setDidUserSawNewDrawing: (bool) => dispatch(setDidUserSawNewDrawing(bool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DidUserSawNewDrawing);
