import React from "react";
import SectionHeading from "components/SectionHeading";
import classes from "../style.module.scss";

export default () => (
    <div>
        <SectionHeading title="Quick Start Guide" subtitle="A guide to creating your first model." />
        <div className={classes.contentWrapper}>
            <p>
                Click <strong>Create New Model</strong>, give your model a name and click <strong>Create</strong> to
                begin the modelling process. Move through the tabs from left to right and follow the steps below to
                complete your model.
            </p>
            <ol>
                <li>
                    Define your building type and weather data in the <strong>General</strong> tab. Enter any other
                    required model file information.
                </li>
                <li>
                    Adjust the number of storeys in the <strong>Enclosure</strong> tab. Click the{" "}
                    <strong>Drawing</strong> button towards the top right of the screen and upload a PDF of your
                    building construction drawing.
                </li>
                <li>
                    Mock-up your drawing in the <strong>Drawing Capture</strong> tool. Start by setting the{" "}
                    <strong>Scale</strong> for an image by finding the longest measurement on a page, aligning the scale
                    with the measurement, and entering the number of meters of feet.
                </li>
                <li>
                    Next, identify floor areas and create base components (walls, ceilings, basements, etc.) with the{" "}
                    <strong>Polygon</strong> tool. Draw an outline around a floor plan that represents the perimeter of
                    either a section of heated floor area, a component, or both. Apply that polygon's area to either the
                    above or below-grade heated floor area, or add components that share that polygon's area or
                    perimeter. Always <strong>Set</strong> your components to apply changes.
                </li>
                <li>
                    Use the <strong>Line</strong> tool to grab extra dimensions for the components you just created. For
                    example, if you created a basement component using a polygon, use the line tool on an elevation to
                    acquire the basement wall height and depth below grade.
                </li>
                <li>
                    Use the <strong>Window</strong> and <strong>Door</strong> tools to identify those components.
                    Remember, windows and doors will not be applied to your model if they are not attached to a parent
                    component.
                </li>
                <li>
                    <strong>Save your drawing</strong> and return to the <strong>Enclosure tab</strong>. Review your
                    components and sort them as needed. Check the <strong>Heated Floor Area</strong> fields in the{" "}
                    <strong>Geometry Summary</strong> at the top of the page, and calculate the{" "}
                    <strong>Total House Volume</strong> by applying height multipliers to floor areas.
                </li>
                <li>
                    Enter blower door test results or specify an airtightness level in the <strong>Airtightness</strong>{" "}
                    tab.
                </li>
                <li>
                    Define your house's mechanical systems in the <strong>Mechanicals</strong> tab. You can use the
                    searchable <strong>Energy Star Manufacturer and Model</strong> fields to automatically populate
                    certain systems.
                </li>
                <li>
                    Add any program-specific information in the <strong>Additional</strong> tab.
                </li>
                <li>
                    If applicable, specify an SB-12 compliance package in the <strong>Review</strong> tab and compare
                    your model.
                </li>
                <li>
                    When you're finished, click <strong>Save &amp; Export</strong> to export your .h2k file. If you are
                    notified of any potential errors, check the indicated field to make sure that the data has been
                    captured correctly.
                </li>
            </ol>
        </div>
    </div>
);
