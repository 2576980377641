import { connect } from 'react-redux';
import Login from './';
import { auth, signInWithToken } from '_firebase';
import { authErrors } from 'utils/firebase';
import { actions as userActions } from 'store/users';
import { loginUserAPI } from 'utils/auth/api';
import { mixpanel } from 'components/Mixpanel';

const { fetchUserData, userStart, userFail, fetchUserCodeLib, fetchUserDir } = userActions;

const mapStateToProps = ({
    user: {
        error,
    }
}) => ({
    error
});

const mapDispatchToProps = (dispatch) => ({
    loginUser: async (event, loginForm) => {
        event.preventDefault();
        try {
            await loginUserAPI({ email: loginForm.email.value, password: loginForm.password.value })
                .then(async ({data}) => {
                    const { token:customToken = '', user = {} } = data;
                    const { uid = '' } = user

                    await auth.signInWithCustomToken(customToken).catch(() => {});
                    await dispatch(userStart());
                    await dispatch(fetchUserCodeLib(uid));
                    await dispatch(fetchUserDir(uid));

                    mixpanel.track("Log In");

                    return dispatch(fetchUserData(uid)).then(
                        async ({ email, name, company, userMeta: { role, experienceLevel } = {} }) => {
                            await mixpanel.people.set({
                                $email: email,
                                $name: name,
                                Company: company,
                                Role: role,
                                "Expierience Level": experienceLevel,
                            });
                            mixpanel.identify(uid);
                        },
                    );;
                });
                
        } catch (error) {
            return dispatch(userFail(authErrors(error)))
        }
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);