import React from 'react';
import classes from './style.module.scss';
import { Drawer } from '@material-ui/core';
import CloseIcon from 'assets/images/icons/JSX/Close';
import classNames from "classnames";

const getClasses = ({ rootClass='', paperClass='' }) => ({
    paper: `${classes.drawerPaper} ${paperClass}`
});

const DrawerComponent = ({
    children,
    preTitle,
    preIcon:PreIcon,
    title='',
    subtitle='',
    classes:drawerClasses={},
    headerClassName,
    bodyClass='',
    close,
    topBarColor,
    introColor,
    whiteHeaderText=false,
    icon:Icon,
    noCloseButton,
    ...props
}) => (
    <Drawer
        anchor="right"
        classes={getClasses(drawerClasses)}
        onClose={close}
        {...props}
    >
        
        {
            (!noCloseButton && close) &&
                <div className={classNames(classes.close, {
                    [classes.hasPreTitle]:preTitle,
                    [classes.whiteHeaderText]:whiteHeaderText,
                })} onClick={close}>
                    <CloseIcon />
                </div>
        }
        <div className={classes.drawerHeader}>
            {
                preTitle &&
                    <div className={classes.preTitle} style={{backgroundColor:topBarColor || "#0066B1"}}>
                        {PreIcon && <PreIcon />}
                        <p>{preTitle}</p>
                    </div>
            }
            {
                title &&
                    <div className={classNames(classes.intro, {
                        [headerClassName]:headerClassName,
                        [classes.whiteHeaderText]:whiteHeaderText,
                    })} style={{backgroundColor:introColor || "#f6f8f9"}}>
                        {Icon && <Icon />}
                        <h2>{title}</h2>
                        {subtitle && <p className={classes.subtitle}>{subtitle}</p>}
                    </div>
            }
        </div>
        <div className={`${classes.drawerBody} ${bodyClass && bodyClass}`}>
            {children}
        </div>
    </Drawer>
);

export default DrawerComponent;