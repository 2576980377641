import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import FansAndPumps from './';


const mapStateToProps = (
    {
        form
    },
    {
        accessor,
        formName="model"
    }
) => {
    const selector = formValueSelector(formName);
    const modelSelector = formValueSelector('model');

    const modelUnits = modelSelector({form}, `modelData.uiSettings.primaryUnits`) || {};

    const currentPrimarySystem = Object.keys(selector({form}, `${accessor}.system`) || [])[0];
    const { id: functionId } = selector({form}, `${accessor}.system.${currentPrimarySystem}.function`) || {};

    let isCooling = false;
    if (isCooling = currentPrimarySystem === 'airConditioning') {
        isCooling = true;
    } else if (['airHeatPump', 'waterHeatPump', 'groundHeatPump'].includes(currentPrimarySystem) && functionId === 1) {
        isCooling = true;
    }

    const { id: capacityId } = selector({form}, `${accessor}.system.${currentPrimarySystem}.specifications.outputCapacity`) || {};
    const isCoolingCapCalculated = capacityId === 0 || capacityId === 2;

    const isFanPowerCalculated = selector({form}, `${accessor}.fansAndPumps.power.isCalculated`);
    const { id: fanPowerModeId } = selector({form}, `${accessor}.fansAndPumps.mode`) || {};

    const powerUnits = selector({form}, `${accessor}.fansAndPumps.power_u`);
    const fanFlowUnits = selector({form}, `${accessor}.fansAndPumps.flowRate_u`);

    return {
        accessor,
        currentPrimarySystem,
        isCooling,
        isCoolingCapCalculated,
        isFanPowerCalculated,
        fanPowerModeId,
        powerUnits,
        fanFlowUnits,
        modelUnits
    }
};

const mapDispatchToProps = () => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FansAndPumps);