import React from "react";
import classes from "../style.module.scss";
import { useHistory, useLocation } from "react-router";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserCommDir, selectUserCommDirLoading } from "store/users/selectors";
import { actions as userActions } from "store/users";
import { actions as communityActions } from "features/Community/_ducks";
import DashboardLayout from "features/Dashboards";
import Loading from "components/Loading";
import { CreateFirstComm } from "components/Dashboard/Actions/CreateFirstDialog";
import { selectCommunityLoading } from "features/Community/_ducks/selectors";

const { fetchCommunityData, clearCommunityData, communityLoading } = communityActions;
const { fetchUserCommDir } = userActions;

const CommunitiesDashboard = ({
    fetchUserCommDir,
    commDir,
    commDirLoading,
    communityLoading,
    selectCommunity,
    deleteCommunity,
    batchDeleteComms,
    duplicateCommunity,
    moveCommunity
}) => {
    const { pathname } = useLocation();
    const history = useHistory();

    const path = pathname.split("/").filter((str) => ["", "dashboard", "communities"].includes(str) === false) || [];
    const currentFolderId = path[path.length - 1] || null;

    const { communities: singleCommunities = {}, folders = {} } = commDir;

    const filteredFolders = !isEmpty(folders)
        ? Object.entries(folders)
            .filter(([key, { parentFolderId }]) => parentFolderId === currentFolderId)
            .sort(([aKey, aValues], [bKey, bValues]) => aValues.name.localeCompare(bValues.name))
            .map(([key, el]) => [key, { ...el, type: "folder" }])
        : [];

    const filteredCommunities = !isEmpty(singleCommunities)
        ? Object.entries(singleCommunities)
            .filter(([key, { parentFolderId }]) =>
                parentFolderId ? parentFolderId === currentFolderId : currentFolderId ? false : true
            )
            .sort(([aKey, aValues], [bKey, bValues]) => aValues.name.localeCompare(bValues.name))
            .map(([key, el]) => [key, { ...el, type: "community" }])
        : [];

    const allFoldersCommunities = [...filteredFolders, ...filteredCommunities];

    const emptyFolderDialog = !commDirLoading && isEmpty(filteredCommunities) && isEmpty(filteredFolders) && (
        <CreateFirstComm />
    );

    return (
        <DashboardLayout
            dashboardType="commDir"
            rootDir={"Communities"}
            rootPath={`/communities/dashboard`}
            dir={{ items: singleCommunities, folders }}
            fetchDir={fetchUserCommDir}
            dirLoading={commDirLoading}
            itemType={"community"}
            allFoldersItems={allFoldersCommunities}
            itemLoading={communityLoading}
            selectItem={selectCommunity}
            deleteItem={deleteCommunity}
            batchDeleteItems={batchDeleteComms}
            duplicateItem={duplicateCommunity}
            moveItem={moveCommunity}
            emptyFolderDialog={emptyFolderDialog}
        >
            {commDirLoading && <Loading className={classes.loading} message="Loading Communities" />}
            {/* New user msg? empty folders and communities */}
        </DashboardLayout>
    );
}

const mapStateToProps = createStructuredSelector({
    commDir: selectUserCommDir,  
    commDirLoading: selectUserCommDirLoading,
    communityLoading: selectCommunityLoading
});

const mapDispatchToProps = (dispatch, { history }) => ({
    fetchUserCommDir: (uid, skipLoading) => dispatch(fetchUserCommDir(uid, skipLoading)),
    selectCommunity: async (communityId) => {
        await Promise.all([
            dispatch(clearCommunityData()),
            dispatch(communityLoading()),
            dispatch(fetchCommunityData(communityId)),
        ]).then(() => history.push(`/communities/${communityId}`));
    },
    deleteCommunity: async ({ communityId, uid }) => {
        // await dispatch(deleteCommunity({ communityId, uid }));
        // return dispatch(removeFromDir(communityId));
    },
    moveCommunity: async (communityId, uid, folderId, skipLoading) => {
        // return dispatch(moveCommunity(communityId, uid, folderId, skipLoading))
    },
    duplicateCommunity: async (communityId, uid, folderId, duplicateName) => {
        // return dispatch(duplicateCommunity(communityId, uid, folderId, duplicateName))
    },
    // batchDeleteComms: async (modelsToDeleteIds, uid) => dispatch(batchDeleteComms(modelsToDeleteIds, uid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CommunitiesDashboard);