import React from "react";
import classes from "../style.module.scss";
import Dialog from "components/Dialog";
import { CloseDialog } from "components/Dialog";
import Button from "components/Button";

const DeleteMultipleFilesDialog = ({ filesToDelete, toggleToDelete, onClick, deleting }) => {
    return (
        <Dialog open={filesToDelete.length > 1}>
            <CloseDialog closeActions={() => toggleToDelete()} />
            <div className={classes.dialog}>
                <h3>
                    Are you sure you want to delete <strong>{filesToDelete.length} files</strong>?
                </h3>

                <h4>
                    Deleting a folder will move all of its nested models and sub-folders to the root level Model
                    Directory.
                </h4>

                <div className={classes.footer}>
                    <Button type="hollow" onClick={() => toggleToDelete()}>
                        Cancel
                    </Button>
                    <Button onClick={() => onClick()} type="red" disabled={deleting}>
                        {deleting ? "Deleting..." : "Delete"}
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};
export default DeleteMultipleFilesDialog;
