import React, { useEffect } from 'react';
import classes from './style.module.scss';
import { Field } from 'redux-form';
import InputRow from 'components/Input/Row';
import InputWithUnits from 'components/Input/InputWithUnits';
import { required, getValidation, getDecimalPlaces } from 'utils/fieldValidation';
import Select from 'components/Input/Select';
import { getOptions, getBaseUnits, getUnitOptions } from 'utils/fields';
import Checkbox from 'components/Input/Checkbox';

const doorTypeValidation = [ required, ...getValidation('doorType') ];
const eStarValidation = getValidation('doorEStar');

export default React.memo(({
    accessor,
    change,
    id,
    modelUnits,
    rValUnits,
    doorRValue,
    doorTypeId,
    updateDrawingChanges,
    enerStar,
    drawingComponent,
    drawingImage,
    isUpgrade=false,
}) => {

    useEffect(() => {
        if (!isUpgrade) {
            updateDrawingChanges(id, {
                type:'door',
                energyStar:enerStar,
                drawingComponent,
                drawingImage
            });
        }
    },[enerStar, id]);

    return (
        <>
            <InputRow gridTemplate="31.85% 23.25% 23.25%">
                <Field
                    component={Select}
                    name={`${accessor}.doorType`}
                    options={[
                        ...getOptions({fieldKey: 'doorType'}),
                        {
                            label:"User Specified",
                            value:{
                                id:7,
                                value:doorRValue, //need to get rValue for this to match
                            }
                        },
                    ]}
                    label="Door Type"
                    placeholder="Choose Door Type"
                    validate={doorTypeValidation}
                    onChange={(value)=>{
                        updateDrawingChanges(id, {
                            type:'door',
                            doorType:value,
                            drawingComponent,
                            drawingImage
                        })
                    }}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.doorType.value`}
                    label="R-Value"
                    placeholder={0.00}
                    disabled={doorTypeId !== 7}
                    validate={doorTypeValidation}
                    setTouched={true}
                    decimals={getDecimalPlaces('doorType')}
                    change={change}
                    setValue={doorTypeId !== 7 ? doorRValue : null}
                    units={{
                        base:getBaseUnits('doorType', modelUnits),
                        options:getUnitOptions('thermalResistance'),
                        selected:rValUnits,
                        unitType:'thermalResistance',
                        accessor:`${accessor}.doorType_u`
                    }}
                />
                <Field
                    component={Checkbox}
                    name={`${accessor}.energyStar`}
                    label="Energy Star"
                    large
                    className={classes.enerStar}
                    type="checkbox"
                />
            </InputRow>
        </>
    )
})