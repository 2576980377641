import React from "react";
import { useDroppable } from "@dnd-kit/core";

export default function Droppable({ id, children }) {
    const { isOver, setNodeRef } = useDroppable({
        id: id,
    });
    const style = {
        background: isOver ? "#0049c6" : "transparent",
        height: "2px",
        width: "100%",
        // marginTop: "2px",
    };

    return (
        <div style={{ height: isOver ? "2px" : "0", transition: "all 0.2s ease-in-out" }}>
            <div ref={setNodeRef} style={style}></div>
            {children}
        </div>
    );
}
