import React, { useCallback } from 'react';
import classes from 'features/Model/Enclosure/UserDefinedCodes/style.module.scss';
import InputRow from 'components/Input/Row';
import InputWithUnits from 'components/Input/InputWithUnits';
import Select from 'components/Input/Select';
import { Field } from 'redux-form';
import { getOptions, getFirstOption, getBaseUnits, getUnitOptions } from 'utils/fields';
import { getValidation, getDecimalPlaces } from 'utils/fieldValidation';
import { getCavityResistivity, getFrameResistivity } from 'utils/enclosure/codeLayers';
import Accordion from 'components/Accordion';
import AccordionActions from "../../AccordionActions";

const layerValidation = getValidation('udefStrappingLayer');

const strappingCategoryValidation = getValidation('udefStrappingMaterialCat');
const strappingTypeValidation = getValidation('udefStrappingMaterialType');
const strappingResistivityValidation = getValidation('udefStrappingResistivity');
const strappingThicknessValidation = getValidation('udefStrappingThickness');
const strappingRValueValidation = getValidation('udefStrappingRVal');
const strappingWidthValidation = getValidation('udefStrappingWidth');
const strappingSpacingValidation = getValidation('udefStrappingSpacing');

const cavityCategoryValidation = getValidation('udefStrappingCavityMaterialCat');
const cavityTypeValidation = getValidation('udefStrappingCavityMaterialType');
const cavityResistivityValidation = getValidation('udefStrappingCavityResistivity');
const cavityThicknessValidation = getValidation('udefStrappingCavityThickness');
const cavityRValueValidation = getValidation('udefStrappingCavityRVal');


export default React.memo(({
    change,
    accessor,
    strappingMaterialCatId,
    strappingMaterialTypeId,
    strappingThicknessValue,
    strappingSpacingValue,
    strappingWidthValue,
    strappingResistivityValue,
    strappingResistivityUnits,
    strappingThicknessUnits,
    strappingRValue,
    strappingRValueUnits,
    strappingWidthUnits,
    strappingSpacingUnits,
    cavityMaterialCatId,
    cavityMaterialTypeId,
    cavityResistivityValue,
    cavityResistivityUnits,
    cavityThicknessUnits,
    cavityRValue,
    cavityRValueUnits,
    modelUnits,
    isDragging= false,
    dragHandleProps,
    dragActive= false,
    layer,
    strappingLayers,
    id
}) => {
    const materialTypeOptions = getOptions({fieldKey:'udefStrappingMaterialType', indKey: strappingMaterialCatId}) || [];

    const {
        label=""
    } = materialTypeOptions.find(({ value:{ id=0 }={} }) => id === strappingMaterialTypeId) || {};

    const deleteLayer = useCallback(() => {
        const { [id]:_, ...remainingLayers }  = strappingLayers;
        change('uDefCode.layers.strapping', remainingLayers);
    },[id, strappingLayers, change]);

    const { displayBase:baseRValUnits="" } = getBaseUnits('udefStrappingRVal', modelUnits);
    const rValueUnits = strappingRValueUnits || baseRValUnits;
    const materialLabel = label || `User Defined: ${strappingRValue} ${rValueUnits}`;

    //ADD USER DEFINED DEPENDENCIES
    return (
        <div>
            <Accordion
                className={classes.accordion}
                heading={
                    <span className={classes.accordionHeader}>
                        <span className={classes.accordionPreTitle}>Strapping</span>
                        <span className={classes.accordionTitle}>{materialLabel}</span>
                    </span>
                }
                number={layer}
                isDragging={isDragging}
                dragActive={dragActive}
                hoverActions={<AccordionActions dragHandleProps={dragHandleProps} accessor={accessor} deleteLayer={deleteLayer} />}
            >
                <h4>Strapping</h4>
                <InputRow gridTemplate="1fr">
                    <Field
                        component={Select}
                        name={`${accessor}.materialCategory`}
                        options={getOptions({fieldKey:'udefStrappingMaterialCat'})}
                        label="Material Category"
                        placeholder="Choose Material Category"
                        validate={strappingCategoryValidation}
                        onChange={({id})=>{
                            if (id === 0) {
                                change(`${accessor}.materialType`, { id: null })
                            } else {
                                change(`${accessor}.materialType`, { id: 0 });
                            }
                        }}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Select}
                        name={`${accessor}.materialType`}
                        options={getOptions({fieldKey:'udefStrappingMaterialType', indKey: strappingMaterialCatId})}
                        disabled={strappingMaterialCatId === 0}
                        label="Material Type"
                        placeholder="Choose Material Type"
                        validate={strappingTypeValidation}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.resistivity`}
                        label="Resistivity"
                        placeholder={0.00}
                        disabled={strappingMaterialCatId !== 0}
                        validate={strappingResistivityValidation}
                        setTouched={true}
                        decimals={getDecimalPlaces('udefStrappingResistivity')}
                        change={change}
                        setValue={strappingMaterialTypeId == null ? strappingResistivityValue : getFrameResistivity(strappingMaterialTypeId)}
                        units={{
                            base:getBaseUnits('udefStrappingResistivity', modelUnits),
                            options:getUnitOptions('thermalResistivity'),
                            selected:strappingResistivityUnits,
                            unitType:'thermalResistivity',
                            accessor:`${accessor}.resistivity_u`
                        }}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.thickness`}
                        label="Thickness"
                        placeholder={0.00}
                        validate={strappingThicknessValidation}
                        setTouched={true}
                        decimals={getDecimalPlaces('udefStrappingThickness')}
                        change={change}
                        units={{
                            base:getBaseUnits('udefStrappingThickness', modelUnits),
                            options:getUnitOptions('length'),
                            selected:strappingThicknessUnits,
                            unitType:'length',
                            accessor:`${accessor}.thickness_u`
                        }}
                        warning={strappingThicknessValue === 0 ? 'strappingZero' : ''}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.rValue`}
                        label="R-Value"
                        placeholder={0.00}
                        disabled={true}
                        setValue={strappingRValue}
                        validate={strappingRValueValidation}
                        setTouched={true}
                        decimals={getDecimalPlaces('udefStrappingRVal')}
                        change={change}
                        units={{
                            base:getBaseUnits('udefStrappingRVal', modelUnits),
                            options:getUnitOptions('thermalResistance'),
                            selected:strappingRValueUnits,
                            unitType:'thermalResistance',
                            accessor:`${accessor}.rValue_u`
                        }}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.width`}
                        label="Width"
                        placeholder={0.00}
                        validate={strappingWidthValidation}
                        setTouched={true}
                        decimals={getDecimalPlaces('udefStrappingWidth')}
                        change={change}
                        units={{
                            base:getBaseUnits('udefStrappingWidth', modelUnits),
                            options:getUnitOptions('length'),
                            selected:strappingWidthUnits,
                            unitType:'length',
                            accessor:`${accessor}.width_u`
                        }}
                        warning={strappingWidthValue === 0 ? 'strappingZero' : ''}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.spacing`}
                        label="Spacing"
                        placeholder={0.00}
                        validate={strappingSpacingValidation}
                        setTouched={true}
                        decimals={getDecimalPlaces('udefStrappingSpacing')}
                        change={change}
                        units={{
                            base:getBaseUnits('udefStrappingSpacing', modelUnits),
                            options:getUnitOptions('length'),
                            selected:strappingSpacingUnits,
                            unitType:'length',
                            accessor:`${accessor}.spacing_u`
                        }}
                        warning={strappingSpacingValue === 0 ? 'strappingZero' : ''}
                    />
                </InputRow>
                <h4>Cavity Insulation</h4>
                <InputRow gridTemplate="1fr">
                    <Field
                        component={Select}
                        name={`${accessor}.cavityInsulation.materialCategory`}
                        options={getOptions({fieldKey:'udefStrappingCavityMaterialCat'})}
                        label="Material Category"
                        placeholder="Choose Material Category"
                        validate={cavityCategoryValidation}
                        onChange={({id})=>{
                            if (id === 0) {
                                change(`${accessor}.cavityInsulation.materialType`, { id: null })
                            } else {
                                change(
                                    `${accessor}.cavityInsulation.materialType`, 
                                    getFirstOption({indKey:id, depKey:'udefStrappingCavityMaterialType'})
                                );
                            }
                        }}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Select}
                        name={`${accessor}.cavityInsulation.materialType`}
                        options={getOptions({fieldKey:'udefStrappingCavityMaterialType', indKey: cavityMaterialCatId})}
                        disabled={cavityMaterialCatId === 0}
                        label="Material Type"
                        placeholder="Choose Material Type"
                        validate={cavityTypeValidation}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.cavityInsulation.resistivity`}
                        label="Resistivity"
                        placeholder={0.00}
                        disabled={cavityMaterialCatId !== 0}
                        validate={cavityResistivityValidation}
                        setTouched={true}
                        decimals={getDecimalPlaces('udefStrappingCavityResistivity')}
                        change={change}
                        setValue={cavityMaterialTypeId == null ? cavityResistivityValue : getCavityResistivity(cavityMaterialTypeId)}
                        units={{
                            base:getBaseUnits('udefStrappingCavityResistivity', modelUnits),
                            options:getUnitOptions('thermalResistivity'),
                            selected:cavityResistivityUnits,
                            unitType:'thermalResistivity',
                            accessor:`${accessor}.cavityInsulation.resistivity_u`
                        }}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.cavityInsulation.thickness`}
                        label="Thickness"
                        placeholder={0.00}
                        validate={cavityThicknessValidation}
                        setTouched={true}
                        decimals={getDecimalPlaces('udefStrappingCavityThickness')}
                        change={change}
                        units={{
                            base:getBaseUnits('udefStrappingCavityThickness', modelUnits),
                            options:getUnitOptions('length'),
                            selected:cavityThicknessUnits,
                            unitType:'length',
                            accessor:`${accessor}.cavityInsulation.thickness_u`
                        }}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.cavityInsulation.rValue`}
                        label="R-Value"
                        placeholder={0.00}
                        disabled={true}
                        setValue={cavityRValue}
                        validate={cavityRValueValidation}
                        setTouched={true}
                        decimals={getDecimalPlaces('udefStrappingCavityRVal')}
                        change={change}
                        units={{
                            base:getBaseUnits('udefStrappingCavityRVal', modelUnits),
                            options:getUnitOptions('thermalResistance'),
                            selected:cavityRValueUnits,
                            unitType:'thermalResistance',
                            accessor:`${accessor}.cavityInsulation.rValue_u`
                        }}
                    />
                </InputRow>
            </Accordion>
        </div>
    )
});