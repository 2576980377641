import React from "react";

export default () => (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.339 5.04492L12.7137 5.47148L15.9563 9.16311L15.5807 9.99308H14.3762L14.3763 12.877L14.3763 13.377H13.8763H10.8H10.3L10.3 12.8771L10.2999 9.99308H9.07842L8.70361 9.16214L11.9632 5.47051L12.339 5.04492ZM10.1869 8.99308H10.7999H11.2998L11.2999 9.49307L11.2999 12.377H13.3763L13.3762 9.4931L13.3762 8.99308H13.8762H14.476L12.3371 6.55798L10.1869 8.99308Z"
            fill="#65676A"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.55632 1.37996H3.1272C3.05665 1.3823 3.00018 1.44023 3.00018 1.51135V3.40574C3.00018 3.47834 3.05904 3.5372 3.13164 3.5372H7.55188C7.62449 3.5372 7.68335 3.47834 7.68335 3.40574V1.51135C7.68335 1.44023 7.62687 1.3823 7.55632 1.37996ZM7.56492 0.379956L7.55188 0.379883H3.13164L3.1186 0.379956H2.76774C2.0195 0.379956 1.34128 0.683844 0.851393 1.17373C0.361505 1.66362 0.0576172 2.34184 0.0576172 3.09008V10.6676C0.0576172 11.4159 0.361505 12.0941 0.851393 12.584C1.34128 13.0739 2.0195 13.3778 2.76774 13.3778H9.0786V12.3769H3.13164C3.05904 12.3769 3.00018 12.3181 3.00018 12.2455V7.33947C3.00018 7.26686 3.05904 7.208 3.13164 7.208H8.74415L9.59689 6.21254C9.56328 6.20954 9.52924 6.208 9.49485 6.208H3.13164C2.50675 6.208 2.00018 6.71458 2.00018 7.33947V12.1964C1.8362 12.1139 1.68727 12.0056 1.5585 11.8769C1.24848 11.5669 1.05762 11.14 1.05762 10.6676V3.09008C1.05762 2.6177 1.24848 2.19086 1.5585 1.88084C1.68727 1.75207 1.8362 1.64385 2.00018 1.56133V3.40574C2.00018 4.03063 2.50675 4.5372 3.13164 4.5372H7.55188C8.17677 4.5372 8.68335 4.03063 8.68335 3.40574V1.51135C8.68335 1.4669 8.68078 1.42306 8.6758 1.37996H9.1479L11.5878 3.88844L12.2411 3.12585L9.7175 0.531337L9.57025 0.379956H9.35908H7.56492ZM7.74128 8.37871H4.09526V9.37871H7.42947L7.2391 8.96494L7.74128 8.37871ZM7.81827 10.2238H4.09526V11.2238H8.27837L7.81827 10.2238Z"
            fill="#65676A"
        />
    </svg>
);
