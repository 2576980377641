import React from 'react';
import sharedClasses from '../../style.module.scss';
import Tooltip from 'components/Tooltip';

export default React.memo(({
    codeLabel,
    rValue,
    height,
    perimeter,
    area
}) => (
    <div className={sharedClasses.stats} style={{gridTemplateColumns:'2fr 1fr'}}>
        <span
            data-for="wall-stats"
            data-tip={`<span><strong>Type:</strong> ${codeLabel}</span>`}
            data-html
            className={sharedClasses.clipType}
        >
            <strong>Type:</strong> {codeLabel}
        </span>
        <span
            data-for="wall-stats"
            data-tip={`<span><strong>${codeLabel === 'User Specified' ? 'Eff.' : 'Nom.'} R-Value:</strong> ${rValue}</span>`}
            data-html
        >
            <strong>R:</strong> {rValue}
        </span>
        {/* <span><strong>A:</strong> {area}</span> */}
        <Tooltip id="wall-stats" />
    </div>
));