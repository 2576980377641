import IDGenerator from "../../../TakeoffData/IDGenerator";

export const storeySubComponentsFactory = (type, storeyFields) => {
    switch (type.toLowerCase()) {
        case "exposedfloor": {
            const { id = IDGenerator("ef"), length = 3.16, area = 10, unit = "m" } = storeyFields || {};

            return {
                type: "exposedfloor",
                name: "Exposed floor",
                id: id,
                fields: [
                    {
                        name: "Length",
                        field: "length",
                        value: length,
                        fieldKey: "takeoffLength",
                        type: "number",
                        // unit,
                    },
                    {
                        name: "Area",
                        field: "area",
                        value: area,
                        fieldKey: "takeoffArea",
                        type: "number",
                        // unit: `${unit}2`,
                    },
                ],
            };
        }
        case "bufferedwall": {
            const {
                id = IDGenerator("bf"),
                perimeter = 12,
                corners = 3,
                intersections = 2,
                unit = "m",
            } = storeyFields || {};

            return {
                type: "bufferedwall",
                name: "Buffered wall",
                id: id,
                fields: [
                    {
                        name: "Int. Perimeter",
                        field: "intPerimeter",
                        value: perimeter,
                        fieldKey: "takeoffLength",
                        type: "number",
                        // unit,
                    },
                    {
                        name: "Corners",
                        field: "corners",
                        value: corners,
                        fieldKey: "takeoffCount",
                        type: "number",
                    },
                    {
                        name: "Intersections",
                        field: "intersections",
                        value: intersections,
                        fieldKey: "takeoffCount",
                        type: "number",
                    },
                ],
            };
        }
        case "atticceiling": {
            const { id = IDGenerator("ac"), area = 25, eaveLength = 20, unit = "m" } = storeyFields || {};
            return {
                type: "atticceiling",
                name: "Attic ceiling",
                id: id,
                fields: [
                    {
                        name: "Area",
                        field: "area",
                        value: area,
                        fieldKey: "takeoffArea",
                        type: "number",
                        // unit: `${unit}2`,
                    },
                    {
                        name: "Eave Length",
                        field: "eaveLength",
                        value: eaveLength,
                        fieldKey: "takeoffLength",
                        type: "number",
                        // unit,
                    },
                ],
            };
        }
        case "cathedralceiling": {
            const {
                id = IDGenerator("cc"),
                width = 5,
                length = 15,
                height = 0.83,
                numGables = 2,
                unit = "m",
                ceilingHeightUnits = "m",
            } = storeyFields || {};

            return {
                type: "cathedralceiling",
                name: "Cathedral ceiling",
                id: id,
                fields: [
                    {
                        name: "Width",
                        field: "width",
                        value: width,
                        fieldKey: "takeoffLength",
                        type: "number",
                        // unit,
                    },
                    {
                        name: "Length",
                        field: "length",
                        value: length,
                        fieldKey: "takeoffLength",
                        type: "number",
                        // unit,
                    },
                    {
                        name: "Height",
                        field: "height",
                        value: height,
                        fieldKey: "takeoffLength",
                        type: "number",
                        // unit: ceilingHeightUnits,
                    },
                    {
                        name: "Exposed Walls",
                        field: "numGables",
                        value: Math.max(0, Math.min(2, numGables)),
                        fieldKey: "takeoffCount",
                        type: "number",
                    },
                ],
            };
        }
        case "slopedceiling": {
            const {
                id = IDGenerator("sc"),
                width = 5,
                length = 15,
                height = 0.83,
                unit = "m",
                ceilingHeightUnits = "m",
            } = storeyFields || {};

            return {
                type: "slopedceiling",
                name: "Sloped ceiling",
                id,
                fields: [
                    {
                        name: "Width",
                        field: "width",
                        value: width,
                        fieldKey: "takeoffLength",
                        type: "number",
                        // unit,
                    },
                    {
                        name: "Length",
                        field: "length",
                        value: length,
                        fieldKey: "takeoffLength",
                        type: "number",
                        // unit,
                    },
                    {
                        name: "Height",
                        field: "height",
                        value: height,
                        fieldKey: "takeoffLength",
                        type: "number",
                        // unit: ceilingHeightUnits,
                    },
                ],
            };
        }
        case "raisedceiling": {
            const {
                id = IDGenerator("rc"),
                length = 15,
                height = 0.83,
                area = 75,
                perimeter = 40,
                unit = "m",
                ceilingHeightUnits = "m",
            } = storeyFields || {};

            return {
                type: "raisedceiling",
                name: "Raised ceiling",
                id,
                fields: [
                    {
                        name: "Height",
                        field: "height",
                        value: height,
                        fieldKey: "takeoffLength",
                        type: "number",
                        // unit: ceilingHeightUnits,
                    },
                    {
                        name: "Area",
                        field: "area",
                        value: area,
                        fieldKey: "takeoffArea",
                        type: "number",
                        // unit: `${unit}2`,
                    },
                    {
                        name: "Perimeter",
                        field: "perimeter",
                        value: perimeter,
                        fieldKey: "takeoffLength",
                        type: "number",
                        // unit,
                    },
                    {
                        name: "Base Length",
                        field: "baseLength",
                        value: length,
                        fieldKey: "takeoffLength",
                        type: "number",
                        // unit,
                    },
                ],
            };
        }
        default:
            break; //Again, you may want to crash here
    }
};

export const storeyTableComponentsFactory = (type = "") => {
    switch (type) {
        default:
            return {
                title: "",
                width: 0,
                height: 0,
                overhangWidth: 0,
                qty: 0,
            };
    }
};

export const getStoreySubcomponentsUnits = (type) => {
    switch (type.toLowerCase()) {
        case "pitch": {
            return {
                options: [],
                unitType: "deg",
            };
        }
        case "area": {
            return {
                options: ["m2", "ft2"],
                unitType: "area",
            };
        }
        case "numgables": {
            return {
                options: [],
                unitType: "count",
            };
        }
        case "corners":
        case "intersections": {
            return {
                options: [],
                unitType: "count",
            };
        }
        default:
            return {
                options: ["m", "ft"],
                unitType: "length",
            };
    }
};

export const getStoreyTablecomponentsUnits = (key) => {
    switch (key.toLowerCase()) {
        case "title":
            return {
                options: [],
                selected: null,
                unitType: "string",
            };
        case "qty":
            return {
                options: [],
                selected: "count",
                unitType: "count",
            };
        default:
            return {
                options: ["m", "ft"],
                selected: "m",
                unitType: "length",
            };
    }
};
