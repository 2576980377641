import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import Window from './';
import { getBaseUnits, unitLabels } from 'utils/fields';
import convertUnit from 'utils/convertUnit';

const mapStateToProps = (
    {
        form
    },
    {
        accessor
    }
) => {
    const selector = formValueSelector('model');
    const windowHeight = selector({form}, `${accessor}.measurements.height`) || {};
    const windowWidth = selector({form}, `${accessor}.measurements.width`) || {};
    const windowArea = selector({form}, `${accessor}.measurements.area`) || {};
    const { codeLabel: windowLabel } = selector({form}, `${accessor}.windowType`) || {};
    const windowRVal = selector({form}, `${accessor}.windowType_effRVal`) || 0;
    const windowNumIdentical = selector({form}, `${accessor}.numIdentical`) || 1;

    const heightUnits = selector({form}, `${accessor}.measurements.height_u`);
    const widthUnits = selector({form}, `${accessor}.measurements.width_u`);
    const areaUnits = selector({form}, `${accessor}.measurements.area_u`);
    const rValUnits = selector({form}, `${accessor}.windowType_effRVal_u`);
    const modelUnits = selector({form}, `modelData.uiSettings.primaryUnits`);

    const { trueBase:heightBase, displayBase:heightDisplay } = getBaseUnits('windowHeight', modelUnits);
    const { trueBase:widthBase, displayBase:widthDisplay } = getBaseUnits('windowWidth', modelUnits);
    const { trueBase:areaBase, displayBase:areaDisplay } = getBaseUnits('windowArea', modelUnits);
    const { trueBase:rValBase, displayBase:rValDisplay } = getBaseUnits('windowInsType', modelUnits);

    const heightU = heightUnits || heightDisplay || heightBase;
    const widthU = widthUnits || widthDisplay || widthBase;
    const areaU = areaUnits || areaDisplay || areaBase;
    const rValU = rValUnits || rValDisplay || rValBase;
    
    return {
        height:`${parseFloat(convertUnit({
            value:windowHeight,
            type:'length',
            inputUnit:heightBase,
            outputUnit:heightU,
        })).toFixed(0)}${heightU}`,
        width:`${parseFloat(convertUnit({
            value:windowWidth,
            type:'length',
            inputUnit:widthBase,
            outputUnit:widthU,
        })).toFixed(0)}${widthU}`,
        area:`${parseFloat(convertUnit({
            value:windowArea,
            type:'area',
            inputUnit:areaBase,
            outputUnit:areaU,
        })).toFixed(1)}${unitLabels(areaU)}`,
        codeLabel:windowLabel,
        rValue:`${parseFloat(convertUnit({
            value:windowRVal,
            type:'thermalResistance',
            inputUnit:rValBase,
            outputUnit:rValU,
        })).toFixed(1)}${rValU}`,
        windowNumIdentical
    }
};

const mapDispatchToProps = () => ({});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Window);