import React from "react";
import sharedClasses from "../style.module.scss";
import DrawingIcon from "assets/images/icons/JSX/Drawing";
import Tooltip from "components/Tooltip";
import Edit from "assets/images/icons/JSX/Edit";

const ComponentHeading = ({ label, componentId, isDrawingComponent, icon: Icon, toggleNameDialog, isSubComponent }) => (
    <>
        <span className={`${sharedClasses.heading} ${isSubComponent && "subComponent"}`}>
            <img src={Icon} alt="Wall icon" />
            <p className={`${isSubComponent && "subComponent"}`}>
                {label}
                <span
                    style={{ marginLeft: 8 }}
                    onClick={() => {
                        toggleNameDialog(true);
                    }}
                >
                    {/* <Edit fill="#1D5ECD" className="notoggle"/>  */}
                    <Edit fill="#646f81" className="notoggle" />
                </span>
            </p>
            {isDrawingComponent && (
                <span
                    className={sharedClasses.drawingIcon}
                    data-tip="<span style='display:block;width:100%;text-align:center;'>This component was created using the <strong>Drawing Capture</strong> tool.</span>"
                    data-html
                    data-for={`headingTip_${componentId}`}
                >
                    <DrawingIcon />
                </span>
            )}
        </span>
        <Tooltip id={`headingTip_${componentId}`} />
    </>
);

export default ComponentHeading;
