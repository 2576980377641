import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import classes from "./style.module.scss";

import { selectChbaCheckList } from "features/Model/ProjectPathChecklist/_ducks/selectors";

import ChbaChecklistItemInput from "components/ChbaChecklist/ChbaChecklistItemInput";
import DragAndDropField from "components/DragAndDropField";
import ModelTags from "components/ModelTags";
import ChecklistLinkOut from "components/ChbaChecklist/ChecklistLinkOut";
import ChecklistDownload from "components/ChbaChecklist/ChecklistDownload";
import ViewFile from "components/ViewFile";
import Subtitle from "../Subtitle";
import ArrowDown from "assets/images/icons/JSX/arrow-down";
import ArrowDownBlue from "assets/images/icons/JSX/arrow-down-blue";

const ChbaChecklistItem = ({
    label,
    subtitle,
    name,
    chbaCheckList = {}, //The checklist from the state
    isLast = false,
    modelId,
    setShowPreview,
    handleSave,
    phasesTemplate = {},
}) => {
    const [openComment, setOpenComment] = useState(false);
    const [hasText, setHasText] = useState(false);

    const { items: chbaCheckListItems } = chbaCheckList || {};

    const getValueFromState = (name, pathExt) => {
        const splitNameArray = name.split(".");
        if (splitNameArray.length === 1) {
            return chbaCheckListItems?.[splitNameArray[0]];
        } else if (splitNameArray.length === 2) {
            return chbaCheckListItems?.[splitNameArray[0]]?.[splitNameArray[1]];
        } else if (splitNameArray.length === 3) {
            return chbaCheckListItems?.[splitNameArray[0]]?.[splitNameArray[1]]?.[splitNameArray[2]];
        }
    };

    const getValueFromTemplate = (name, pathExt) => {
        const splitNameArray = name.split(".");
        if (splitNameArray.length === 1) {
            return phasesTemplate[splitNameArray[0]];
        } else if (splitNameArray.length === 2) {
            return phasesTemplate[splitNameArray[0]][splitNameArray[1]];
        } else if (splitNameArray.length === 3) {
            return phasesTemplate[splitNameArray[0]][splitNameArray[1]][splitNameArray[2]];
        }
    };

    //From template
    const withFileUpload = getValueFromTemplate(`${name}.withFileUpload`) || false;
    const withCostInput = getValueFromTemplate(`${name}.withCostInput`) || false;
    const allowedFileTypes = getValueFromTemplate(`${name}.allowedFileTypes`) || [];
    const minimumFilesRequired = getValueFromTemplate(`${name}.minimumFilesRequired`) || 0;
    const withForm = getValueFromTemplate(`${name}.withForm`) || false;
    const formTitle = getValueFromTemplate(`${name}.formTitle`) || "";
    const tags = getValueFromTemplate(`${name}.tags`) || [];
    const externalLink = getValueFromTemplate(`${name}.externalLink`) || "";
    const externalLinkTitle = getValueFromTemplate(`${name}.externalLinkTitle`) || "";
    const { url: downloadLink, fileName } =
        getValueFromTemplate(`${name}.downloadLink`) || getValueFromTemplate(`${name}.viewFile`) || {};
    const viewFile = getValueFromTemplate(`${name}.viewFile`) || []; //Files that we have pre-uploaded

    //User uploaded files
    const checkListFiles = getValueFromState(`${name}.files`) || [];
    const checkListForm = getValueFromState(`${name}.form`) || "";

    useEffect(() => {
        if (checkListForm !== "") {
            setHasText(true);
        } else {
            setHasText(false);
        }
    }, [checkListForm]);

    return (
        <div style={{ marginBottom: !isLast ? "32px" : 0 }}>
            {/* every item will contain a checkbox at the top */}
            <div className={classes.checkboxContainer}>
                <ChbaChecklistItemInput
                    component="checkbox"
                    name={`${name}.checkboxValue`}
                    input={{ value: getValueFromState(`${name}.checkboxValue`) || false }}
                    isDisabled={minimumFilesRequired === 0 ? false : checkListFiles.length < minimumFilesRequired}
                    howMuchFilesLeft={minimumFilesRequired - checkListFiles.length}
                />

                <div className={classes.checkboxLabel}>
                    <div>{label}</div>
                    <Subtitle>{subtitle}</Subtitle>
                </div>
                {tags.length > 0 && (
                    //If more than 4 tags, use flex 1, otherwise flex unset
                    <div className={classes.tagsContainer}>
                        {tags.map((tag, index) => (
                            <ModelTags key={index} tag={tag} />
                        ))}
                    </div>
                )}
            </div>
            {withCostInput && (
                <ChbaChecklistItemInput
                    component="costInput"
                    formTitle={formTitle}
                    name={`${name}.totalCost`}
                    input={{ value: getValueFromState(`${name}.totalCost`) || 0.0 }}
                />
            )}
            {withFileUpload && (
                <DragAndDropField
                    name={`${name}.files`}
                    input={{ value: getValueFromState(`${name}.files`) || "" }}
                    allowedFileTypes={allowedFileTypes}
                    checkListFiles={checkListFiles}
                    modelId={modelId}
                    withForm={withForm}
                    setShowPreview={setShowPreview}
                    handleSave={handleSave}
                    projectPathSection={name}
                />
            )}
            {externalLink && <ChecklistLinkOut link={externalLink} title={externalLinkTitle} />}
            {downloadLink && <ChecklistDownload url={downloadLink} filename={fileName} hasLinkOut={!!externalLink} />}
            {viewFile && (
                <div className={classes.viewFileContainer}>
                    {viewFile.map((link, ind) => (
                        <ViewFile key={ind} url={link.url} filename={link.fileName} />
                    ))}
                </div>
            )}
            {withForm && (
                <>
                    <div
                        className={`${classes.commentHeader} ${openComment && classes.openComment} ${
                            hasText && classes.hasText
                        }`}
                        onClick={() => {
                            setOpenComment(!openComment);
                        }}
                    >
                        <Subtitle>Comments</Subtitle>
                        <div className={classes.arrow}>{hasText ? <ArrowDownBlue /> : <ArrowDown />}</div>
                    </div>
                    {openComment && (
                        <ChbaChecklistItemInput
                            component="paragraphInput"
                            formTitle={formTitle}
                            name={`${name}.form`}
                            input={{ value: getValueFromState(`${name}.form`) || "" }}
                        />
                    )}
                </>
            )}
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    chbaCheckList: selectChbaCheckList,
});

export default connect(mapStateToProps, null)(ChbaChecklistItem);
