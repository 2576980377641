import React from "react";
import classNames from "classnames";
import EyeOpen from "assets/images/icons/JSX/Eye-Open";
import EyeClosed from "assets/images/icons/JSX/Eye-Closed";
import classes from "./ToggleVisibility.module.scss";

const ToggleVisibility = ({
    label = "Hide",
    isHide = false,
    onClick,
    className,
}) => (
    <div
        onClick={onClick}
        className={classNames(classes.toggleVisibility, className)}
    >
        {isHide ? (
            <>
                <EyeClosed className={classes.eyeIconClosed} /> {label}
            </>
        ) : (
            <>
                <EyeOpen className={classes.eyeIcon} /> {label}
            </>
        )}
    </div>
);

export default ToggleVisibility;
