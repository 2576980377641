import React from "react";

import { dropdownMenu } from "./style.module.scss";

const DropdownMenu = ({ top = 0, isOpen, children, ref, style = {} }) => {
    return (
        <div
            style={{
                top: `${top}px`,
                visibility: isOpen ? "visible" : "hidden",
                opacity: isOpen ? "1" : "0",
                left: 0,
                ...style,
            }}
            className={dropdownMenu}
            ref={ref}
        >
            {isOpen && children}
        </div>
    );
};

export default DropdownMenu;
