import React, { useEffect, useState } from "react";

import { Field } from "redux-form";

import classes from "./style.module.scss";
import sharedClasses from "features/Model/sharedStyles.module.scss";

import Input from "components/Input";
import Select from "components/Input/Select";

import InputRow from "components/Input/Row";
import Button from "components/Button";
import moment from "moment";
import Accordion from "components/Accordion";
import Actions from "../Actions/container";

const Grant = ({ accessor, grants, change }) => {
    // const [deletePressed, setDeletePressed] = useState(false);

    const addGrant = () => {
        change(`${accessor}`, {
            ...grants,
            [`${moment().format("YYYYMMDDHHmmssSS")}`]: {
                amount: null,
                grantType: "",
                note: "",
            },
        });
    };

    const removeGrant = (grant) => {
        const { [grant]: removedObj, ...remainingGrantObj } = grants;
        change(`${accessor}`, {
            ...remainingGrantObj,
        });
    };

    // need to connect a function to the "Add Upgrade" button that removes empty grants from the object

    return (
        <>
            {Object.keys(grants).map((grant, ind) => {
                const grantAmount = grants[grant]?.amount || 0;
                const hasGrantAmount = !!grantAmount;
                const label = `Grant${hasGrantAmount ? ": $" + grantAmount.toFixed(2) : ""}`;

                return (
                    <Accordion
                        key={`${grant}-${ind}`}
                        className={sharedClasses.accordion}
                        heading={<span>{label}</span>}
                        large
                        // forceOpen={ind + 1 === Object.keys(grants).length && deletePressed === false}
                        hoverActions={
                            <Actions
                                accessor={accessor}
                                change={change}
                                handleDelete={() => removeGrant(grant)}
                                type="grant"
                            />
                        }
                    >
                        <div>
                            <InputRow gridTemplate="1fr 2fr">
                                <Field
                                    component={Input}
                                    name={`${accessor}.${grant}.amount`}
                                    label={`Grant Amount ($)`}
                                    type="number"
                                />
                                <Field
                                    component={Input}
                                    name={`${accessor}.${grant}.description`}
                                    label={`Description/Program`}
                                    type="text"
                                    disabled={!hasGrantAmount}
                                />
                            </InputRow>
                        </div>
                    </Accordion>
                );
            })}
            <Button
                onClick={() => {
                    addGrant();
                }}
            >
                Add New Grant
            </Button>
        </>
    );
};

export default Grant;
