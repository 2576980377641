import React from "react";

import classes from "./styles.module.scss";

import WallIcon from "assets/images/components/Wall.svg";
import ExpFloorIcon from "assets/images/components/ExpFloor.svg";
import CeilingIcon from "assets/images/components/Ceiling.svg";
import BasementIcon from "assets/images/components/Foundation-Basement.svg";
import CrawlspaceIcon from "assets/images/components/Foundation-Crawlspace.svg";
import SlabIcon from "assets/images/components/Foundation-Slab.svg";
import WindowIcon from "assets/images/components/Window.svg";
import DoorIcon from "assets/images/components/Door.svg";
import FloorHeaderIcon from "assets/images/components/FloorHeader.svg";
import ModelIcon from "assets/images/icons/model.svg";
import FolderIcon from "assets/images/icons/folder.svg";
import CommunityIcon from "assets/images/icons/community.svg";
import CHBACommunityIcon from "assets/images/icons/communityCHBA.svg";

const ComponentListImage = ({ componentLabel, componentType, multipleSelect = [] }) => {
    const componentIcon = {
        wall: WallIcon,
        expFloor: ExpFloorIcon,
        ceiling: CeilingIcon,
        basement: BasementIcon,
        crawlspace: CrawlspaceIcon,
        slab: SlabIcon,
        window: WindowIcon,
        door: DoorIcon,
        floorHeader: FloorHeaderIcon,
        model: ModelIcon,
        folder: FolderIcon,
        community: componentLabel.includes("CHBA") ? CHBACommunityIcon : CommunityIcon,
    };
    
    return (
        <div className={classes.componentListImageContainer}>
            <img src={componentIcon[componentType] || ""} alt="component icon" />
            <span className={classes.componentListText}>{componentLabel}</span>
            {multipleSelect.length > 1 && (
                <div className={classes.componentListImageMultiSelect}>
                    <span>{multipleSelect.length}</span>
                </div>
            )}
        </div>
    );
};

export default ComponentListImage;
