import React from "react";
import classes from "../../style.module.scss";
import classNames from "classnames";
import { fileShareFolderIds } from "utils/fileSharing/index";
import DashboardItem from "..";

const Folder = ({
    // ref,
    folderId,
    name,
    disableActions = false,
    sharedWithUser = false,
    startDelete,
    // pathname,
    setFolderToRename,
    setFolderToMove,
    tags = [],
    // setSelectedFolderModel={setSelectedFolderModel}
    // selectedFolderModel={selectedFolderModel}
    isSelected,
    isDragging,
    combineWith,
    isDropInProcess,
    setFolderItemsToDelete,
    multiSelectedItems,
    details, // { totalModels, region, lastEdited }
    ...props
}) => {
    const isSpecialFolder = fileShareFolderIds.includes(folderId);

    return (
        <DashboardItem
            // ref={ref}
            className={classNames({
                [classes.selectedItem]: isSelected,
                [classes.itemDragging]: isDragging && isSelected,
                [classes.isDropInProcess]: isDropInProcess,
                [classes.itemCombine]: combineWith
            })}
            itemId={folderId}
            name={name}
            sharedWithUser={sharedWithUser}
            itemType={"folder"}
            details={details}
            tags={tags}
            actions={disableActions ? null :
                [
                    {
                        label: "Rename Folder",
                        onClick: () => {
                            setFolderToRename();
                        },
                    },
                    {
                        label: "Move Folder",
                        onClick: () => {
                            setFolderToMove(folderId);
                        },
                    },
                    ...(isSpecialFolder
                        ? []
                        : [
                            {
                                label: "Delete Folder",
                                onClick: (e) => {
                                    startDelete(e, folderId);
                                },
                            },
                        ]),
                    {
                        label: `Delete ${multiSelectedItems.length} files`,
                        shouldShow: multiSelectedItems.length > 1,
                        onClick: () => setFolderItemsToDelete(multiSelectedItems),
                    },
                ]}
            {...props}
        />
    );
};

export default Folder;
