import { connect } from 'react-redux';
import Actions from './';
import { formValueSelector } from 'redux-form';


const mapStateToProps = (
    {
        form
    }, 
    {
        accessor,
        formName="model"
    }
) => {
    const selector = formValueSelector(formName);
    const systemsAccessor = formName === "upgradeDrawer" ? "fields.pvSystems" : "modelData.generation.pvSystems";
    const pvSystems = selector({form}, systemsAccessor) || {};

    return {
        accessor,
        pvSystems,
    }
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Actions);