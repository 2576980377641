import React from 'react';
import SectionHeading from 'components/SectionHeading';
import classes from '../style.module.scss';

export default () => (
    <div>
        <SectionHeading
            title="Contact Us"
            subtitle="The Volta SNAP team would be happy to hear from you."
        />
        <div className={classes.contentWrapper}>
            <p>Have questions or feedback? Send us an email at <a href="mailto:info@voltaresearch.org" target="_blank" rel="noopener noreferrer">info@voltaresearch.org</a></p>
            <p>Want to get more involved? Ask to join our user testing <strong>Slack channel</strong>.</p>
        </div>
    </div>
)