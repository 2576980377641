import types from "./types";
import { updateObject } from "utils";
import isEmpty from "lodash/isEmpty";

const initialState = {
    imageData: {},
    previousRefs: {},
    toDelete: [],
    toDetach: [],
    toRemoveFromFloor: [],
    activeImage: "",
    activeComponent: "",
    action: {},
    message: {},
    tools: {},
    saving: false,
    error: "",
    processingImages: false,
    loadingData: false,
    stashedState: {},
    stage: {
        scale:1,
        coords: { x:0, y:0 },
        size: {
            width:window.innerWidth - 512,
            height:window.innerHeight - 73
        },
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.SET_SCALE:
            return setImageScale(state, action);
        case types.SET_ACTION:
            return setAction(state, action);
        case types.SET_ACTIVE_IMAGE:
            return setActiveImage(state, action);
        case types.SET_ACTIVE_COMPONENT:
            return setActiveComponent(state, action);
        case types.SET_ACTIVE_TOOL:
            return setActiveTool(state, action);
        case types.SET_DISABLED_TOOLS:
            return setDisabledTools(state, action);
        case types.SET_MESSAGE:
            return setMessage(state, action);
        case types.ADD_COMPONENT:
            return addComponent(state, action);
        case types.ATTACH_COMPONENT:
            return attachComponent(state, action);
        case types.REMOVE_COMPONENT:
            return removeComponent(state, action);
        case types.UPDATE_COMPONENT:
            return updateComponent(state, action);
        case types.ADD_POLYGON:
            return addPolygon(state, action);
        case types.UPDATE_POLYGON:
            return updatePolygon(state, action);
        case types.UPDATE_POLYGON_COMPONENT:
            return updatePolygonComponent(state, action);
        case types.REMOVE_POLYGON:
            return removePolygon(state, action);
        case types.ADD_LINE:
            return addLine(state, action);
        case types.UPDATE_LINE:
            return updateLine(state, action);
        case types.REMOVE_LINE:
            return removeLine(state, action);
        case types.TOGGLE_SAVING:
            return toggleSaving(state, action);
        case types.SET_DRAWING_DATA:
            return setDrawingData(state, action);
        case types.UPDATE_PREVIOUS_REFS:
            return updatePrevRefs(state, action);
        case types.UPDATE_COMPONENTS_TO_DELETE:
            return updateComponentsToDelete(state, action);
        case types.REMOVE_POLYGON_COMPONENT:
            return removePolygonComponent(state, action);
        case types.UPDATE_FIELDS_TO_DETACH:
            return updateFieldsToDetach(state, action);
        case types.UPDATE_REMOVE_FROM_FLOOR:
            return updateToRemoveFromFloor(state, action);
        case types.CLEAR_DRAWING_DATA:
            return clearDrawingData(state, action);
        case types.TOGGLE_IMAGE_PROCESSING:
            return toggleImageProcessing(state, action);
        case types.TOGGLE_DATA_LOADING:
            return toggleDataLoading(state, action);
        case types.RESET_DRAWING:
            return resetDrawing(state, action);
        case types.UPDATE_STAGE:
            return updateStage(state, action);
        default:
            return state;
    }
};

const resetDrawing = (state, action) =>
    updateObject(state, {
        ...initialState,
        imageData: state.stashedState,
        stashedState: state.stashedState,
    });

const toggleDataLoading = (state, action) =>
    updateObject(state, {
        loadingData: action.loadingData,
    });

const toggleImageProcessing = (state, action) =>
    updateObject(state, {
        processingImages: action.processingImages,
    });

const updateFieldsToDetach = (state, action) => {
    return updateObject(state, {
        toDetach: [...state.toDetach, ...action.toDetach],
    });
};

const updateToRemoveFromFloor = (state, action) => {
    return updateObject(state, {
        toRemoveFromFloor: [
            ...state.toRemoveFromFloor,
            ...action.toRemoveFromFloor,
        ],
    });
};

const updateComponentsToDelete = (state, action) => {
    return updateObject(state, {
        toDelete: [...state.toDelete, ...action.toDelete],
    });
};

const updatePrevRefs = (state, action) => {
    const { components = {}, lines = {}, polygons = {} } = action.refs || {};

    const {
        components: stateComponents = {},
        lines: stateLines = {},
        polygons: statePolygons = {},
    } = state.previousRefs;

    return updateObject(state, {
        previousRefs: updateObject(state.previousRefs, {
            components: updateObject(stateComponents, components),
            lines: updateObject(stateLines, lines),
            polygons: updateObject(statePolygons, polygons),
        }),
    });
};

const clearDrawingData = (state, action) => updateObject(state, initialState);
const setDrawingData = (state, action) => {
    return updateObject(state, {
        imageData: action.data,
        stashedState: action.data,
    });
};

const toggleSaving = (state, action) =>
    updateObject(state, {
        saving: action.saving,
    });

const setImageScale = (state, action) => {
    const { [action.image]: stateImage = {} } = state.imageData || {};
    const { scale: stateScale } = stateImage;

    return updateObject(state, {
        imageData: updateObject(state.imageData, {
            [action.image]: updateObject(stateImage, {
                scale: isEmpty(action.scale) ? action.scale : updateObject(stateScale, action.scale),
            }),
        }),
    });
};

const setActiveImage = (state, action) =>
    updateObject(state, {
        activeImage: action.activeImage,
    });

const setActiveComponent = (state, action) =>
    updateObject(state, {
        activeComponent: action.activeComponent,
    });

const setActiveTool = (state, action) =>
    updateObject(state, {
        tools: updateObject(state.tools, {
            active: action.activeTool,
        }),
    });

const setDisabledTools = (state, action) =>
    updateObject(state, {
        tools: updateObject(state.tools, {
            disabled: action.disabledTools,
        }),
    });

const setMessage = (state, action) =>
    updateObject(state, {
        message: {
            content: action.content,
            fill: action.fill,
        },
    });

const setAction = (state, action) =>
    updateObject(state, {
        action: {
            id: action.id,
            meta: action.meta,
        },
    });

const addComponent = (state, action) => {
    const { imageData: { [action.image]: image = {} } = {} } = state;
    const { components: stateComponents = {} } = image;

    return updateObject(state, {
        imageData: updateObject(state.imageData, {
            [action.image]: updateObject(image, {
                components: updateObject(stateComponents, {
                    [action.component.id]: action.component,
                }),
            }),
        }),
    });
};

const addPolygon = (state, action) => {
    const { imageData: { [action.image]: image = {} } = {} } = state;
    const { polygons: statePolygons = {} } = image;

    return updateObject(state, {
        imageData: updateObject(state.imageData, {
            [action.image]: updateObject(image, {
                polygons: updateObject(statePolygons, {
                    [action.polygon.id]: action.polygon,
                }),
            }),
        }),
    });
};

const addLine = (state, action) => {
    const { imageData: { [action.image]: image = {} } = {} } = state;
    const { lines: stateLines = {} } = image;

    return updateObject(state, {
        imageData: updateObject(state.imageData, {
            [action.image]: updateObject(image, {
                lines: updateObject(stateLines, {
                    [action.line.id]: action.line,
                }),
            }),
        }),
    });
};

const attachComponent = (state, action) => {
    const { imageData: { [action.image]: image = {} } = {} } = state;
    const { components = {} } = image;
    const { [action.componentId]: component = {} } = components;

    return updateObject(state, {
        imageData: updateObject(state.imageData, {
            [action.image]: updateObject(image, {
                components: updateObject(components, {
                    [action.componentId]: {
                        ...component,
                        attachTo: action.attachTo,
                        componentName: action.componentName,
                        ...action.fields,
                    },
                }),
            }),
        }),
    });
};

const removeComponent = (state, action) => {
    const { imageData: { [action.image]: image = {} } = {} } = state;
    const { components = {} } = image;
    const {
        [action.componentId]: component,
        ...remainingComponents
    } = components;

    return updateObject(state, {
        imageData: updateObject(state.imageData, {
            [action.image]: updateObject(image, {
                components: remainingComponents,
            }),
        }),
    });
};

const removeLine = (state, action) => {
    const { imageData: { [action.image]: image = {} } = {} } = state;
    const { lines = {} } = image;
    const { [action.lineId]: line, ...remainingLines } = lines;

    return updateObject(state, {
        imageData: updateObject(state.imageData, {
            [action.image]: updateObject(image, {
                lines: remainingLines,
            }),
        }),
    });
};

const removePolygon = (state, action) => {
    const { imageData: { [action.image]: image = {} } = {} } = state;
    const { polygons = {} } = image;
    const { [action.polygonId]: polygon, ...remainingPolygons } = polygons;

    return updateObject(state, {
        imageData: updateObject(state.imageData, {
            [action.image]: updateObject(image, {
                polygons: remainingPolygons,
            }),
        }),
    });
};

const removePolygonComponent = (state, action) => {
    const { imageData: { [action.image]: image = {} } = {} } = state;
    const { polygons = {} } = image;
    const { [action.polygonId]: polygon } = polygons;
    const { components = {} } = polygon;
    const {
        [action.componentId]: component,
        ...remainingComponents
    } = components;

    return updateObject(state, {
        imageData: updateObject(state.imageData, {
            [action.image]: updateObject(image, {
                polygons: updateObject(polygons, {
                    [action.polygonId]: updateObject(polygon, {
                        components: remainingComponents,
                    }),
                }),
            }),
        }),
    });
};

const updateComponent = (state, action) => {
    const { imageData: { [action.image]: image = {} } = {} } = state;
    const { components = {} } = image;
    const { [action.componentId]: component = {} } = components;

    return updateObject(state, {
        imageData: updateObject(state.imageData, {
            [action.image]: updateObject(image, {
                components: updateObject(components, {
                    [action.componentId]: {
                        ...component,
                        ...action.updates,
                    },
                }),
            }),
        }),
    });
};

const updatePolygon = (state, action) => {
    const { imageData: { [action.image]: image = {} } = {} } = state;
    const { polygons = {} } = image;
    const { [action.componentId]: polygon = {} } = polygons;

    return updateObject(state, {
        imageData: updateObject(state.imageData, {
            [action.image]: updateObject(image, {
                polygons: updateObject(polygons, {
                    [action.componentId]: {
                        ...polygon,
                        ...action.updates,
                    },
                }),
            }),
        }),
    });
};

const updatePolygonComponent = (state, action) => {
    const { imageData: { [action.image]: image = {} } = {} } = state;
    const { polygons = {} } = image;
    const { [action.polygonId]: polygon = {} } = polygons;
    const { components = {} } = polygon;
    const { [action.componentId]: component } = components;

    return updateObject(state, {
        imageData: updateObject(state.imageData, {
            [action.image]: updateObject(image, {
                polygons: updateObject(polygons, {
                    [action.polygonId]: updateObject(polygon, {
                        components: updateObject(components, {
                            [action.componentId]: {
                                ...component,
                                ...action.updates,
                            },
                        }),
                    }),
                }),
            }),
        }),
    });
};

const updateLine = (state, action) => {
    const { imageData: { [action.image]: image = {} } = {} } = state;
    const { lines = {} } = image;
    const { [action.id]: line = {} } = lines;

    return updateObject(state, {
        imageData: updateObject(state.imageData, {
            [action.image]: updateObject(image, {
                lines: updateObject(lines, {
                    [action.id]: {
                        ...line,
                        ...action.updates,
                    },
                }),
            }),
        }),
    });
};

const updateStage = (state, action) => {
    return updateObject(state, {
        stage: updateObject(state.stage, action.updates)
    })
}