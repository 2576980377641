import { DashboardAPI } from "utils/api";

export const tagModelFile = async ({ modelId, uid, tag = "", taggingType = "" }) => {
    return DashboardAPI({
        method: "POST",
        url: "/tagModelFile",
        data: {
            modelId,
            uid,
            tag,
            taggingType,
        },
    });
};

export const removeTagFromModelFile = async ({ modelId, uid, tag = "", taggingType = "" }) => {
    return DashboardAPI({
        method: "POST",
        url: "/removeTagFromModelFile",
        data: {
            modelId,
            uid,
            tag,
            taggingType,
        },
    });
};
