import React from "react";

export default ({ fill }) => (
    <svg
        width="16px"
        height="16px"
        viewBox="0 0 16 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g id="Group-3" transform="translate(-1.500000, 0.500000)" fill={fill || "#65676A"} fillRule="nonzero">
            <path
                d="M10.2857143,1.33140074 L10.2857143,12.9028293 L7.71428571,17.2925325 L5.14285714,12.9028293 L5.14285714,1.33140074 L10.2857143,1.33140074 Z M9.22658824,2.38976471 L6.20152941,2.38976471 L6.20152941,12.6169412 L7.71352941,15.1972941 L9.22658824,12.6148235 L9.22658824,2.38976471 Z"
                id="Rectangle"
                transform="translate(7.714286, 9.311967) rotate(-315.000000) translate(-7.714286, -9.311967) "
            ></path>
            <path
                d="M16.3833952,1.28571429 L16.3833952,5.14285714 L11.240538,5.14285714 L11.240538,1.28571429 L16.3833952,1.28571429 Z M15.3243529,2.34423529 L12.2992941,2.34423529 L12.2992941,4.08388235 L15.3243529,4.08388235 L15.3243529,2.34423529 Z"
                id="Rectangle"
                transform="translate(13.811967, 3.214286) rotate(-315.000000) translate(-13.811967, -3.214286) "
            ></path>
            <path
                d="M11.8377087,1.97425788 L11.8377087,13.5456865 L6.69485158,13.5456865 L6.69485158,1.97425788 L11.8377087,1.97425788 Z M10.7788235,3.03247059 L7.75270588,3.03247059 L7.75270588,12.4867059 L10.7788235,12.4867059 L10.7788235,3.03247059 Z"
                id="Rectangle"
                transform="translate(9.266280, 7.759972) rotate(-315.000000) translate(-9.266280, -7.759972) "
            ></path>
        </g>
    </svg>
);
