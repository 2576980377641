import React from "react";

import ButtonTips from "./components/ButtonTip";

import styles from "./styles.module.scss";

const Tips = () => {
    return (
        <div className={styles.tipsContainer}>
            <ButtonTips btnToPress="I" btnInfo="to show information about all shapes." />
            <ButtonTips btnToPress="S" btnInfo="to snap to lines." />
        </div>
    );
};

export default Tips;
