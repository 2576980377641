import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import Construction from "./";
import { actions } from "features/Model/Enclosure/_ducks";

const { setInitCode, updateCodeDrawer } = actions;

const mapStateToProps = (
    {
        form,
        user: {
            codeLib: {
                Ceiling: { codes: ceilingCodes = {} } = {},
                CeilingFlat: { codes: ceilingFlatCodes = {} } = {},
            } = {},
        },
        upgradeLibrary: { currentPackage = {} },
        enclosure: { calculatingRValue = {} } = {},
    },
    { accessor, formName = "model", id, selectedUpgrade, componentAccessor = accessor }
) => {
    const selector = formValueSelector(formName);
    const modelSelector = formValueSelector("model");

    const modelCodes =
        currentPackage?.upgrades?.[selectedUpgrade]?.codes || modelSelector({ form }, "modelData.codes") || {};
    const modelUnits = modelSelector({ form }, `modelData.uiSettings.primaryUnits`) || {};
    const programMode = modelSelector({ form }, `modelData.program.class`) || "";
    const { id: yearBuiltId, value: yearBuiltValue } =
        modelSelector({ form }, `modelData.specifications.yearBuilt`) || {};

    const { codeRef: selectedCodeRef } = selector({ form }, `${accessor}.ceilingInsType`) || {};
    const selectedNomRValue = selector({ form }, `${accessor}.ceilingInsType_nomRVal`) || 0;
    const ceilingCodeWarning = selector({ form }, `${accessor}.ceilingInsType_warning`) || "";
    const rValUnits = selector({ form }, `${accessor}.ceilingInsType_u`);
    const { [id]: { ceilingInsType: isCalculatingRValue = false } = {} } = calculatingRValue;

    //always get from parent
    const { id: constructTypeId } = modelSelector({ form }, `${componentAccessor}.constructionType`) || {};

    return {
        id,
        accessor,
        libCeilingCodes: ceilingCodes,
        libCeilingFlatCodes: ceilingFlatCodes,
        modelCodes,
        selectedCodeRef,
        constructTypeId,
        modelUnits,
        rValUnits,
        selectedNomRValue,
        ceilingCodeWarning,
        isCalculatingRValue,
        isErsAndUSpec:
            selectedCodeRef === "UserSpecified" && (programMode.includes("ERS") || programMode.includes("ONrh")),
        isRecentVintage: [9, 10].includes(yearBuiltId) || yearBuiltValue >= 1990,
    };
};

const mergeProps = (state, dispatch, own) => ({
    ...state,
    ...dispatch,
    ...own,
});

const mapDispatchToProps = (dispatch) => ({
    setInitCode: async (code, type) => {
        return dispatch(setInitCode(code, type));
    },
    updateCodeDrawer: (updates) => dispatch(updateCodeDrawer({ updates })),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Construction);
