import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import Window from './';
import { actions } from 'features/Model/Enclosure/_ducks';

const { updateDrawingChanges } = actions;

const mapStateToProps = ({
    form,
    model:{
        newestComponents=[],
        recentDuplicates=[],
        contentOpen=false,
    }={}
}, {
    id,
    parentAccessor
}) => {
    const accessor = `${parentAccessor}.subcomponents.window.${id}`;

    const selector = formValueSelector('model');
    const label = selector({form}, `${accessor}.label`) || '';
    const { id:windowFacingId } = selector({form}, `${accessor}.facingDirection`) || {};
    const { id:parentFacingId } = selector({form}, `${parentAccessor}.facingDirection`) || {};
    const { component:drawingComponent='', image:drawingImage } = selector({form}, `${accessor}.drawing`) || {};
    const numIdentical = selector({form}, `${accessor}.numIdentical`);

    const shutterUnits = selector({form}, `${accessor}.shading.shutterRValue_u`);
    const curtainUnits = selector({form}, `${accessor}.shading.curtainShading_u`);

    const overhangWidthDrawing = selector({form}, `${accessor}.measurements.overhangWidth_drawingRef`) || '';
    const headerHeightDrawing = selector({form}, `${accessor}.measurements.headerHeight_drawingRef`) || '';

    const drawingLines = [
        ...overhangWidthDrawing ? [ overhangWidthDrawing ] : [],
        ...headerHeightDrawing ? [ headerHeightDrawing ] : [],
    ];

    const modelUnits = selector({form}, `modelData.uiSettings.primaryUnits`) || {};

    return {
        label,
        accessor,
        componentId:id,
        windowFacingId,
        parentFacingId,
        isNew:newestComponents.includes(id),
        isRecentDuplicate:recentDuplicates.includes(id),
        isDrawingComponent:!!drawingComponent,
        drawingLines,
        numIdentical,
        shutterUnits,
        curtainUnits,
        drawingComponent,
        drawingImage,
        modelUnits,
        contentOpen
    };
};

const mapDispatchToProps = (dispatch) => ({
    updateDrawingChanges:(componentId, updates) => dispatch(updateDrawingChanges({ componentId, updates }))
});

export default connect(mapStateToProps, mapDispatchToProps)(Window);