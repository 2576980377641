import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectAllImages, selectLoading } from "store/imageGallery/selectors";

import {
    filterByTagSelection,
    sortByFirstUploaded,
    sortByLastUploaded,
    sortByNameAZ,
    sortByNameZA,
    sortByStatus,
} from "features/Model/ImageGallery/utils/functions";

import ImagesHeader from "../ImagesHeader";
import Divider from "features/Model/ImageGallery/components/Divider";
import FileDragAndDrop from "features/Model/ImageGallery/components/FileDragAndDrop";
import ImageView from "features/Model/ImageGallery/components/ImageView";
import Loading from "components/Loading";

import styles from "./styles.module.scss";

const sortFunction = {
    lastUploaded: (a, b) => sortByLastUploaded(a, b),
    firstUploaded: (a, b) => sortByFirstUploaded(a, b),
    aToZ: (a, b) => sortByNameAZ(a, b),
    zToA: (a, b) => sortByNameZA(a, b),
    status: (a, b) => sortByStatus(a, b),
};

const Images = ({ allImages, isLoading, selectedTag, searchFieldValue, isSavingModel, isClosing }) => {
    const [sortBy, setSortBy] = useState("lastUploaded");
    const [allImagesLocal, setAllImagesLocal] = useState([]);

    useEffect(() => {
        if (isLoading) return;

        setAllImagesLocal(allImages);

        return () => setAllImagesLocal([]);
    }, [isLoading, allImages]);

    return (
        <div className={styles.imagesContainer}>
            <ImagesHeader sortBy={sortBy} setSortBy={setSortBy} />
            <Divider marginY={16} />
            <FileDragAndDrop allImages={allImagesLocal} />
            {(isLoading || isSavingModel) && !isClosing ? (
                <div style={{ display: "flex", justifyContent: "center", marginTop: "35px" }}>
                    <Loading />
                </div>
            ) : (
                <div style={{ marginTop: "35px", display: "flex", flexDirection: "column", gap: "50px" }}>
                    {allImagesLocal
                        .sort(sortFunction[sortBy])
                        .filter((img) => filterByTagSelection(img, selectedTag))
                        .filter(
                            ({ name, tags, status }) =>
                                name.toLowerCase().includes(searchFieldValue.toLowerCase()) ||
                                // tags.map((t) => t.text.toLowerCase()).includes(searchFieldValue.toLowerCase())
                                tags
                                    .map((t) => t.text.toLowerCase())
                                    .join(" ")
                                    .includes(searchFieldValue.toLowerCase()) ||
                                status.replace("_", "-").toLowerCase().includes(searchFieldValue.toLowerCase())
                        )
                        .map((image) => (
                            <ImageView
                                sortBy={sortBy}
                                key={image.id}
                                allImages={allImages}
                                fileUrl={image.fileUrl}
                                filename={image.name}
                                imageId={image.id}
                                status={image.status}
                                notes={image.notes}
                                tags={image.tags}
                                isDeleting={image.isDeleting}
                                metadata={image.metadata}
                            />
                        ))}
                </div>
            )}
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    allImages: selectAllImages,
    isLoading: selectLoading,
});

export default connect(mapStateToProps)(Images);
