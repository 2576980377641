import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import EvaluationProgram from './';


const mapStateToProps = (
    {
        form
    },
    {
        accessor
    }
) => {
    const selector = formValueSelector('model');
    const programType = selector({form}, `modelData.program.class`);
    const modelUnits = selector({form}, `modelData.uiSettings.primaryUnits`) || {};

    const vermiculiteValue = selector({form}, `${accessor}.options.main.vermiculitePresence`) || {};
    const isApplyHoc = selector({form}, `${accessor}.options.main.applyHouseholdOperatingConditions`);
    const isAtypicalLoads = selector({form}, `${accessor}.options.main.atypicalElectricalLoads`);
    const isWaterConservation = selector({form}, `${accessor}.options.main.waterConservation`);
    const isApplyRoc = selector({form}, `${accessor}.options.main.applyReducedOperatingConditions`);
    const isRefHouse = selector({form}, `${accessor}.options.main.referenceHouse`);
    const isGreenerHomes = selector({form}, `${accessor}.options.main.greenerHomesActive`);
    const { major: h2kMajor, minor: h2kMinor} = selector({form}, 'modelData.h2kVersion') || {};
    const programSectionsPresent = Object.keys(selector({form}, `${accessor}.options`) || {});

    return {
        accessor,
        programType,
        vermiculiteValue,
        isApplyHoc,
        isAtypicalLoads,
        isWaterConservation,
        isApplyRoc,
        isRefHouse,
        isGreenerHomes,
        h2kMinor,
        programSectionsPresent,
        modelUnits
    }
};

const mapDispatchToProps = () => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EvaluationProgram);