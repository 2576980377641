import SectionHeading from "components/SectionHeading";
import classes from "./style.module.scss";
import React, { useCallback, useState, useRef } from "react";
import { getOptions } from "utils/fields";
import { Field } from "redux-form";
import { maxLength } from "utils/fieldValidation";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import Select from "components/Input/Select";
import Button from "components/Button";

const charLim200 = maxLength(200);
const charLimPostalCode = maxLength(7);

export default ({ handleSubmit, initialValues }) => {
    const [dataChanged, setDataChanged] = useState(false);
    const [submitBtnLabel, setSubmitBtnLabel] = useState("Save Changes");

    const handleSave = () => {
        handleSubmit();
        setSubmitBtnLabel("Saving...");
        setTimeout(() => {
            setSubmitBtnLabel("Saved");
        }, 500);
        setDataChanged(false);
    };

    const inputRef = useRef("");

    const handleChange = useCallback(
        (fieldName, value) => {
            if (value !== initialValues[fieldName]) {
                setDataChanged(true);
                setSubmitBtnLabel("Save Changes");
            }
        },
        [initialValues]
    );

    return (
        <div>
            <SectionHeading
                title="User Preferences"
                subtitle="Enter values to be automatically applied to all new models"
            />
            <div className={classes.contentWrapper}>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Input}
                        name="firstName"
                        type="text"
                        label="First Name"
                        ref={inputRef}
                        validate={[charLim200]}
                        onChange={handleChange.bind(this, "firstName")}
                    />
                    <Field
                        component={Input}
                        name="lastName"
                        type="text"
                        label="Last Name"
                        validate={[charLim200]}
                        onChange={handleChange.bind(this, "lastName")}
                    />
                    <Field
                        component={Input}
                        name="userPhone"
                        type="text"
                        label="Personal Phone"
                        validate={[charLim200]}
                        onChange={handleChange.bind(this, "userPhone")}
                    />
                    <Field
                        component={Input}
                        name="eaId"
                        type="text"
                        label="EA ID"
                        validate={[charLim200]}
                        onChange={handleChange.bind(this, "eaId")}
                    />
                    <Field
                        component={Input}
                        name="company"
                        type="text"
                        label="User Company"
                        validate={[charLim200]}
                        onChange={handleChange.bind(this, "company")}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Input}
                        name="companyCity"
                        type="text"
                        label="Company City"
                        validate={[charLim200]}
                        onChange={handleChange.bind(this, "companyCity")}
                    />
                    <Field
                        component={Select}
                        name="province"
                        type="text"
                        label="Province"
                        options={getOptions({ fieldKey: "weatherRegion", version: "v11-11" })}
                        validate={[charLim200]}
                        onChange={handleChange.bind(this, "province")}
                    />
                    <Field
                        component={Input}
                        name="companyAddress"
                        type="text"
                        label="Company Street Address"
                        validate={[charLim200]}
                        onChange={handleChange.bind(this, "companyAddress")}
                    />
                    <Field
                        component={Input}
                        name="companyPostalCode"
                        type="text"
                        label="Company Postal Code"
                        validate={[charLimPostalCode]}
                        onChange={handleChange.bind(this, "companyPostalCode")}
                    />
                    <Field
                        component={Input}
                        name="companyPhone"
                        type="text"
                        label="Company Phone"
                        validate={[charLim200]}
                        onChange={handleChange.bind(this, "companyPhone")}
                    />
                    <Field
                        component={Input}
                        name="companyFax"
                        type="text"
                        label="Company Fax"
                        validate={[charLim200]}
                        onChange={handleChange.bind(this, "companyFax")}
                    />
                </InputRow>

                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Select}
                        name="preferredUnits"
                        type="text"
                        label="Primary Display Units"
                        options={[
                            {
                                label: "Metric",
                                value: "Metric",
                            },
                            {
                                label: "Imperial",
                                value: "Imperial",
                            },
                            {
                                label: "US",
                                value: "US",
                            },
                        ]}
                        validate={[charLim200]}
                        onChange={handleChange.bind(this, "preferredUnits")}
                    />
                    <Field
                        component={Select}
                        name="preferredProgramType"
                        options={[
                            {
                                label: "General",
                                value: "",
                            },
                            {
                                label: "EnerGuide Rating System",
                                value: "ca.nrcan.gc.OEE.ERS.ErsProgram",
                            },
                            {
                                label: "Ontario Reference House",
                                value: "ca.nrcan.gc.OEE.ONrh.OnProgram",
                            },
                        ]}
                        label="Program Type"
                        onChange={handleChange.bind(this, "preferredProgramType")}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Button
                        style={{ marginTop: "1.1rem" }}
                        onClick={() => {
                            handleSave();
                        }}
                        disabled={!dataChanged}
                    >
                        {submitBtnLabel}
                    </Button>
                </InputRow>
            </div>
        </div>
    );
};
