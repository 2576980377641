import React from "react";

import { Field } from "redux-form";

import classes from "./style.module.scss";
import sharedClasses from "features/Model/sharedStyles.module.scss";

import Input from "components/Input";
import InputRow from "components/Input/Row";
import Button from "components/Button";
import moment from "moment";
import Accordion from "components/Accordion";
import Actions from "../Actions/container";

const Loan = ({ accessor, loans, change }) => {
    const addLoan = () => {
        change(`${accessor}`, {
            ...loans,
            [`${moment().format("YYYYMMDDHHmmssSS")}`]: {
                amount: null,
                rate: null,
                term: null,
                adminFee: null,
            },
        });
    };

    const removeLoan = (loan) => {
        const { [loan]: removedObj, ...remainingLoanObj } = loans;
        change(`${accessor}`, {
            ...remainingLoanObj,
        });
    };

    return (
        <>
            {Object.keys(loans).map((loan, ind) => {
                const loanAmount = loans[loan]?.amount || 0;
                const hasLoanAmount = !!loanAmount;
                const label = `Loan${hasLoanAmount ? ": $" + loanAmount.toFixed(2) : ""}`;

                return (
                    <Accordion
                        key={`${loan}-${ind}`}
                        className={sharedClasses.accordion}
                        heading={<span>{label}</span>}
                        large
                        hoverActions={
                            <Actions
                                accessor={accessor}
                                change={change}
                                handleDelete={() => removeLoan(loan)}
                                type="loan"
                            />
                        }
                    >
                        <div>
                            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                                <Field
                                    component={Input}
                                    name={`${accessor}.${loan}.amount`}
                                    label={`Loan Amount ($)`}
                                    type="number"
                                />
                                <Field
                                    component={Input}
                                    name={`${accessor}.${loan}.rate`}
                                    label={`Interest Rate (%)`}
                                    type="number"
                                    disabled={!hasLoanAmount}
                                />
                                <Field
                                    component={Input}
                                    name={`${accessor}.${loan}.term`}
                                    label={`Loan Term (yrs)`}
                                    type="number"
                                    disabled={!hasLoanAmount}
                                />
                                <Field
                                    component={Input}
                                    name={`${accessor}.${loan}.adminFee`}
                                    label={`Admin Fee (%)`}
                                    type="number"
                                    disabled={!hasLoanAmount}
                                />
                            </InputRow>
                        </div>
                    </Accordion>
                );
            })}
            <Button
                onClick={() => {
                    addLoan();
                }}
            >
                Add New Loan
            </Button>
        </>
    );
};

export default Loan;
