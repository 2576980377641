const convertValue = (targetUnit, { unit, value }) => {
    //This has been disabled
    const floatVal = parseFloat(value);

    return floatVal;
};

const getSubCompValueAndUnits = (fields, propertyName = "") => {
    return fields.find((field) => field.field === propertyName) || { value: 0 };
};

const calculateCeilingsArea = (components, targetUnit) => {
    //area to be added to wall surface area
    let result = 0;

    components.forEach((component) => {
        if (component.type === "cathedralceiling") {
            const gableFactor = convertValue(targetUnit, getSubCompValueAndUnits(component.fields, "numGables")) / 2;
            result +=
                gableFactor *
                convertValue(targetUnit, getSubCompValueAndUnits(component.fields, "width")) * //width
                convertValue(targetUnit, getSubCompValueAndUnits(component.fields, "height")); //height
        } else if (component.type === "slopedceiling") {
            result +=
                convertValue(targetUnit, getSubCompValueAndUnits(component.fields, "width")) * //width
                convertValue(targetUnit, getSubCompValueAndUnits(component.fields, "height")); //height
        } else if (component.type === "raisedceiling") {
            result +=
                convertValue(targetUnit, getSubCompValueAndUnits(component.fields, "height")) * //height
                convertValue(targetUnit, getSubCompValueAndUnits(component.fields, "perimeter")); //perimeter
        }
    });

    return Math.max(result, 0);
};

export const calculateCeilingsPerimeter = (components, targetUnit, wallHeight) => {
    const ceilingArea = calculateCeilingsArea(components, targetUnit) || 0;

    if (wallHeight !== 0) {
        return ceilingArea / wallHeight.value;
    } else {
        return 0;
    }
};

export const calculateBufferedWallPerimeter = (components) => {
    let bufferedWallPerimeter = 0;

    components.forEach((component) => {
        if (component.type === "bufferedwall") {
            bufferedWallPerimeter += convertValue("m", getSubCompValueAndUnits(component.fields, "intPerimeter")); //width
        }
    });

    return bufferedWallPerimeter;
};

const calculateAdditionalEnclosedVolume = (components, targetUnit) => {
    let result = 0; //? To avoid multiplying by 0 in case the user has not added a ceiling.

    components.forEach((component) => {
        if (component.type === "cathedralceiling") {
            result +=
                (convertValue(targetUnit, getSubCompValueAndUnits(component.fields, "width")) * //width
                    convertValue(targetUnit, getSubCompValueAndUnits(component.fields, "length")) * //length
                    convertValue(targetUnit, getSubCompValueAndUnits(component.fields, "height"))) /
                2; //height
        } else if (component.type === "slopedceiling") {
            result +=
                convertValue(targetUnit, getSubCompValueAndUnits(component.fields, "width")) * //width
                convertValue(targetUnit, getSubCompValueAndUnits(component.fields, "height")) * //height
                (convertValue(targetUnit, getSubCompValueAndUnits(component.fields, "length")) / 2); //length
        } else if (component.type === "raisedceiling") {
            result +=
                convertValue(targetUnit, getSubCompValueAndUnits(component.fields, "height")) * //height
                convertValue(targetUnit, getSubCompValueAndUnits(component.fields, "area")); //area
        }
    });

    return result;
};

const calculateFloorHeaderVolume = (storeyType) => {
    const {
        floorHeaderHeight: { value: fhValue = 0 } = {},
        floorPlanArea: { value: areaValue = 0 },
    } = storeyType;

    const addedVolume = fhValue * areaValue;

    return isNaN(addedVolume) ? 0 : Math.max(0, addedVolume);
};

export const getStoreyInputs = (storeyType) => {
    if (!storeyType) {
        return [];
    } else {
        return [
            {
                label: "Floor Plan Area",
                type: "number",
                property: "floorPlanArea",
                fieldKey: "takeoffArea",
                decimals: 2,
                stateValue: storeyType.floorPlanArea.value,
                stateUnit: storeyType.floorPlanArea.unit,
                units: {
                    options: ["m2", "ft2"],
                    selected: storeyType.floorPlanArea.unit,
                    unitType: "area",
                },
            },
            {
                label: "Floor Plan Perimeter",
                type: "number",
                property: "floorPlanPerimeter",
                fieldKey: "takeoffLength",
                decimals: 2,
                stateValue: storeyType.floorPlanPerimeter.value,
                stateUnit: storeyType.floorPlanPerimeter.unit,
                units: {
                    options: ["m", "ft"],
                    selected: storeyType.floorPlanPerimeter.unit,
                    unitType: "length",
                },
            },
            {
                label: "Interior Wall Length",
                type: "number",
                property: "interiorWallLength",
                fieldKey: "takeoffLength",
                decimals: 2,
                stateValue: storeyType.interiorWallLength.value,
                stateUnit: storeyType.interiorWallLength.unit,
                units: {
                    options: ["m", "ft"],
                    selected: storeyType.interiorWallLength.unit,
                    unitType: "length",
                },
            },
            {
                label: "Wall Height",
                type: "number",
                property: "wallHeight",
                fieldKey: "takeoffLength",
                decimals: 2,
                stateValue: storeyType.wallHeight.value,
                stateUnit: storeyType.wallHeight.unit,
                units: {
                    options: ["m", "ft"],
                    selected: storeyType.wallHeight.unit,
                    unitType: "length",
                },
            },
            {
                label: "Corners",
                type: "number",
                property: "corners",
                fieldKey: "takeoffCount",
                decimals: 0,
                stateValue: storeyType.corners.value,
                stateUnit: storeyType.corners.unit,
                units: {
                    options: [],
                    selected: storeyType.corners.unit,
                    unitType: "count",
                },
            },
            {
                label: "Intersections",
                type: "number",
                property: "intersections",
                fieldKey: "takeoffCount",
                decimals: 0,
                stateValue: storeyType.intersections.value,
                stateUnit: storeyType.intersections.unit,
                units: {
                    options: [],
                    selected: storeyType.intersections.unit,
                    unitType: "count",
                },
            },
            {
                label: "Floor Header Height",
                type: "number",
                property: "floorHeaderHeight",
                fieldKey: "takeoffLength",
                decimals: 2,
                stateValue: storeyType?.floorHeaderHeight?.value,
                stateUnit: storeyType?.floorHeaderHeight?.unit || "",
                units: {
                    options: ["m", "ft"],
                    selected: storeyType?.floorHeaderHeight?.unit || "",
                    unitType: "length",
                },
            },
            {
                label: "Wall Surface Area",
                type: "number",
                property: "wallSurfaceArea",
                fieldKey: "takeoffArea",
                decimals: 2,
                stateValue:
                    convertValue(storeyType.wallSurfaceArea.unit, {
                        unit: storeyType.floorPlanPerimeter.unit,
                        value: storeyType.floorPlanPerimeter.value,
                    }) *
                        convertValue(storeyType.wallSurfaceArea.unit, {
                            unit: storeyType.wallHeight.unit,
                            value: storeyType.wallHeight.value,
                        }) +
                    calculateCeilingsArea(storeyType.components, storeyType.volume.unit) +
                    calculateFloorHeaderVolume(storeyType),
                stateUnit: storeyType.wallSurfaceArea.unit,
                disabled: true,
                units: {
                    options: ["m2", "ft2"],
                    selected: storeyType.wallSurfaceArea.unit,
                    unitType: "area",
                },
            },
            {
                label: "Volume",
                type: "number",
                property: "volume",
                fieldKey: "takeoffVolume",
                decimals: 2,
                stateValue:
                    convertValue(storeyType.volume.unit, {
                        unit: storeyType.floorPlanArea.unit,
                        value: storeyType.floorPlanArea.value,
                    }) *
                        convertValue(storeyType.volume.unit, {
                            unit: storeyType.wallHeight.unit,
                            value: storeyType.wallHeight.value,
                        }) +
                    calculateAdditionalEnclosedVolume(storeyType.components, storeyType.volume.unit),
                stateUnit: storeyType.volume.unit,
                disabled: true,
                units: {
                    options: ["m3", "ft3"],
                    selected: storeyType.volume.unit,
                    unitType: "volume",
                },
            },
            {
                label: "Equivalent Wall Perimeter",
                type: "number",
                property: "equivalentPerimeter",
                fieldKey: "takeoffLength",
                decimals: 2,
                stateValue: Math.max(
                    0,
                    storeyType.floorPlanPerimeter.value +
                        calculateCeilingsPerimeter(
                            storeyType.components,
                            storeyType.equivalentPerimeter.unit,
                            storeyType.wallHeight
                        ) -
                        calculateBufferedWallPerimeter(storeyType.components) -
                        storeyType.interiorWallLength.value
                ),
                stateUnit: storeyType.floorPlanPerimeter.unit,
                disabled: true,
                units: {
                    options: ["m", "ft"],
                    selected: storeyType.floorPlanPerimeter.unit,
                    unitType: "length",
                },
            },
        ];
    }
};
