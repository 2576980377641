import conversions from "./settings/unitConversions.json";

export default ({ value = 0, type = "", inputUnit = "", outputUnit = "" }) => {
    const { [type]: { [inputUnit]: { [outputUnit]: { scale = NaN, offset = NaN } = {} } = {} } = {} } = conversions;

    //TODO: Return value properly
    if (isNaN(scale) || isNaN(offset)) {
        return value;
    }

    if (inputUnit === outputUnit) return value;

    return scale * value + offset;
};
