import React, { useState, useRef, useCallback, useEffect } from 'react';
import classes from './style.module.scss';
import ModalFullScreen, { CloseModal } from 'components/ModalFullScreen';
import Actions from './Actions/container';
import Components from './Components/container';
import Tools from './Tools/container';
import Canvas from './Canvas/container';
import Upload from './Upload/container';
import Info from './Info/container';
import House from 'assets/images/icons/JSX/House';
import Button from 'components/Button';
import Save from 'assets/images/icons/JSX/save';
import OpenIcon from 'assets/images/icons/JSX/OpenDrawer';
import DetailsDrawer from 'components/DetailsDrawer';
import Details from './Details';
import Dialog, { CloseDialog } from 'components/Dialog';
import { isEqual } from 'lodash';

const Zoom = ({
    scale,
    handleScaleChange
}) => (
    <div className={classes.zoom}>
        <span className={scale <= 0.25 ? classes.disable : ''} onClick={()=>handleScaleChange(scale - 0.25)}></span>
        <span>{parseInt(scale*100)}%</span>
        <span className={scale >= 5 ? classes.disable : ''} onClick={()=>handleScaleChange(scale + 0.25)}></span>
    </div>
);

export default React.memo(({
    open=false,
    toggleOpen,
    modelName='',
    hasFiles,
    activeTool,
    saveDrawing,
    saving,
    reset,
    modelChange,
    updateStage,
    stageScale,
    stageCoords,
    canvasSize
}) => {
    const stage = useRef();
    const layer = useRef();
    const canvas = useRef();
    
    const [closeDialogOpen, toggleCloseDialog] = useState(false);
    const [detailsOpen, toggleDetailsOpen] = useState(false);

    const updateText = (newScale) => {
        const text = stage.current.find(".text");
        text.map(shape => {
            shape.setAttr('fontSize', newScale >= 0.75 ? 12/newScale : 18);
            shape.setAttr('y', newScale >= 2.5 ? 18.75 : 7.5*newScale);
        });
    };

    const handleScaleChange = useCallback((newScale) => {
        const newXCoord = (canvasSize.width / 2) - (((canvasSize.width / 2) - stageCoords.x) / stageScale) * newScale;
        const newYCoord = (canvasSize.height / 2) - (((canvasSize.height / 2) - stageCoords.y) / stageScale) * newScale;

        updateStage({ scale:newScale, coords:{ x:newXCoord, y:newYCoord } })
        updateText(newScale);
    }, [canvasSize.width, canvasSize.height, stageScale, stageCoords.x, stageCoords.y, updateStage]);

    return (
        <ModalFullScreen
            open={open}
        >
            <CloseModal closeActions={() => toggleCloseDialog(true)} />
            <div className={classes.drawingModal}>
                <div className={classes.heading}>
                    <div className={classes.text}>
                        <h2>Drawing Capture</h2>
                        <h3><House /> {modelName}</h3>
                    </div>
                    <span className={classes.details} onClick={()=>toggleDetailsOpen(true)}>
                        Drawing Details <OpenIcon />
                    </span>
                    <Zoom scale={stageScale} handleScaleChange={handleScaleChange} />
                    <Button
                        icon={()=><Save strokeClass={classes.saveIconStroke} />}
                        smallPadding
                        onClick={saveDrawing}
                        disabled={saving}
                    >
                        {saving ? "Processing..." : "Save Drawing"}
                    </Button>
                </div>
                <div className={classes.body}>
                    <div className={classes.rightBar}>
                        <Tools />
                        <Components stage={stage} layer={layer} />
                    </div>
                    {
                        hasFiles ?
                            <Canvas
                                open={open}
                                stage={stage}
                                layer={layer}
                                canvas={canvas}
                            /> :
                                <Upload />
                    }
                    <div className={classes.leftBar}>
                        <Info layer={layer} />
                        <Actions activeTool={activeTool} modelChange={modelChange} stage={stage.current} />
                    </div>
                </div>
            </div>
            <DetailsDrawer
                open={detailsOpen}
                close={()=>toggleDetailsOpen(false)}
                title="Drawing Details"
            >
                <Details />
                <div className={classes.buttons}>
                    <Button
                        large
                        type="hollow"
                        onClick={()=>toggleDetailsOpen(false)}
                    >
                        Close
                    </Button>
                </div>
                <span></span>
            </DetailsDrawer>
            <Dialog
                open={closeDialogOpen}
            >
                <CloseDialog
                    closeActions={()=>toggleCloseDialog(false)}
                />
                <div className={classes.dialog}>
                    <h3>Are you sure you want to close the <strong>Drawing Capture</strong>?</h3>
                    <p>All unsaved changes will be lost.</p>
                    <div className={classes.footer}>
                        <Button
                            type="hollow"
                            onClick={()=>toggleCloseDialog(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={ async ()=>{
                                await reset();
                                toggleCloseDialog(false);
                                toggleOpen(false);
                            }}
                            type="red"
                        >
                            Yes
                        </Button>
                    </div>
                </div>
            </Dialog>
        </ModalFullScreen>
    )
}, isEqual)