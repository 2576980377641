import React from 'react';
import classes from './style.module.scss';
import sharedClasses from '../style.module.scss';

export default () => {
    return (
        <>
            <p style={{marginBottom:"1.25rem", marginTop:"1rem"}} >
                When a building is sufficiently airtight that the air leakage of the dwelling unit is less than or equal
                to one of the targets below, certain requirements are premitted to be substituted.
            </p>
        </>
    )
}