import defaultTemplateV11_9 from "features/CreateModel/Templates/default.json";
import defaultTemplateV11_10 from "features/CreateModel/Templates/default-v11-10.json";
import defaultTemplateV11_11 from "features/CreateModel/Templates/default-v11-11.json";
import defaultTemplateV11_12 from "features/CreateModel/Templates/default-v11-12.json";

export default (selection) => {
    return {
        ["default-v11.12"]: defaultTemplateV11_12, //no significant changes
        ["default-v11.11"]: defaultTemplateV11_11,
        ["default-v11.10"]: defaultTemplateV11_10,
        ["default-v11.9"]: defaultTemplateV11_9,
    }[selection];
};
