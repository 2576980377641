import { DashboardAPI } from "utils/api";

export const genEnvirocentreNZReport = async (formData) => {
    return DashboardAPI({
        method: "POST",
        url: "/genEnvirocentreNZReport",
        file: formData,
        headers: {
            "content-type": "multipart/form-data",
        },
        responseType: "text",
    }).catch((err) => console.log(err));
};
