import { isEmpty } from "lodash";

import { phases } from "features/Model/ProjectPathChecklist/_ducks/reducers";

const sortAlphaNum = (a, b) => a.localeCompare(b, "en", { numeric: true });

const phasesArray = Object.keys(phases).sort(sortAlphaNum);

const sortedPhasesArray = phasesArray.reduce((acc, item, ind) => {
    return [
        ...acc,
        {
            [`${item}`]: phases[`${item}`],
        },
    ];
}, []);

const getCheckedItems = (phaseKey, chbaCheckList) =>
    !isEmpty(chbaCheckList.items)
        ? Object.values(chbaCheckList.items[phaseKey]).reduce((acc, item, ind, array) => {
              if (item.checkboxValue === true) {
                  return acc + 1;
              } else if (item.checkboxValue === false) {
                  return acc;
              }
          }, 0)
        : [];

//returns first set of uninterrupted finished phases
//[1a,1b,1c,2,null,4,5,6,null,...] returns a length of 4 (for [1a,1b,1c,2])
export const finishedPhases = (chbaCheckList) => {
    return sortedPhasesArray
        .reduce((acc, phase, ind) => {
            const phaseKey = Object.keys(phase)[0];
            const phaseValue = Object.values(phase)[0];
            const checkedItems = getCheckedItems(phaseKey, chbaCheckList);
            const totalItems = Object.keys(phaseValue).length;
            const percentage = Math.floor((checkedItems / totalItems) * 100);

            if (ind > 0 && percentage === 100) {
                return [...acc, acc[ind - 1] == null ? null : phaseKey];
            }
            if (percentage === 100) {
                return [...acc, phaseKey];
            }

            return [...acc, null];
        }, [])
        .filter((el) => el != null).length;
};

export const totalPhases = sortedPhasesArray.length;

export const phaseMap = {
    0: "0",
    1: "1a",
    2: "1b",
    3: "1c",
    4: "2",
    5: "3",
    6: "4",
    7: "5",
    8: "6",
    9: "7",
    10: "8",
    11: "9",
    12: "10",
    13: "11",
    14: "12",
    15: "13",
};
