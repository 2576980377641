import { connect, useSelector } from "react-redux";
import { formValueSelector } from "redux-form";
import isEqual from "lodash/isEqual";

import { selectCurrentUpgradePackage, selectCurrentPackageToCompare } from "store/upgradeLibrary/selectors";

import Navigation from "./";

const mapStateToProps = ({
    model: {
        modelDetails = {},
        modelData = {},
        modelDataToCompare,
        modelDetailsToCompare,
        modelTakeoffToCompare,
        modelTakeoff,
        linkedBaselineToCompare,
        linkedBaseline
    } = {},
    upgrades: { packages: upgradePackages = {}, initPackages: initUpgradePackages = {} } = {},
    user: { userPermissions: { ENVIROCENTRE_REPORT = false, CBAT_INPUT = false, CHBA_NZ_ADMIN = false, VOLTA_DEV = false } = {} } = {},
    upgradeLibrary: { currentPackage, currentPackageToCompare },
    form,
}) => {
    const selector = formValueSelector("model");

    const formModelData = selector({ form }, "modelData");
    const formModelDetails = selector({ form }, "modelDetails");

    const { name = "" } = modelDetails;

    return {
        modelName: name,
        dataChanged: !!formModelData && (
            !isEqual(formModelData, modelDataToCompare) ||
            !isEqual({ ...modelDetailsToCompare, lastEdited: {} }, { ...formModelDetails, lastEdited: {} }) ||
            !isEqual(upgradePackages, initUpgradePackages) ||
            !isEqual(modelTakeoffToCompare, modelTakeoff) ||
            !isEqual(linkedBaselineToCompare, linkedBaseline)
        ),
        upgradeLibChanged: !isEqual(currentPackage, currentPackageToCompare),
        ENVIROCENTRE_REPORT,
        CBAT_INPUT,
        CHBA_NZ_ADMIN,
        VOLTA_DEV,
    };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
