import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import Measurements from "./";
import isEmpty from "lodash/isEmpty";

const mapStateToProps = ({ form }, { accessor }) => {
    const selector = formValueSelector("model");
    const wallHeight = selector({ form }, `${accessor}.measurements.height`) || {};
    const wallHeightDrawing = selector({ form }, `${accessor}.measurements.height_drawingRef`) || {};
    const wallPerimeter = selector({ form }, `${accessor}.measurements.perimeter`) || {};
    const heightUnits = selector({ form }, `${accessor}.measurements.height_u`);
    const perimeterUnits = selector({ form }, `${accessor}.measurements.perimeter_u`);
    const areaUnits = selector({ form }, `${accessor}.measurements.area_u`);
    const modelUnits = selector({ form }, `modelData.uiSettings.primaryUnits`) || {};

    const wallHeightEquation = selector({ form }, `${accessor}.measurements.height_eqn`);
    const wallPerimeterEquation = selector({ form }, `${accessor}.measurements.perimeter_eqn`);

    return {
        accessor,
        wallHeight,
        wallPerimeter,
        modelUnits,
        heightUnits,
        perimeterUnits,
        areaUnits,
        wallHeightDrawing: !isEmpty(wallHeightDrawing),
        wallHeightEquation,
        wallPerimeterEquation,
    };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Measurements);
