import { connect } from 'react-redux';
import Register from './';
import { auth, generateUser } from '_firebase';
import { authErrors } from 'utils/firebase';
import { actions as userActions } from 'store/users';

const { fetchUserData, userFail, userStart, fetchUserCodeLib } = userActions;

const mapStateToProps = ({
    user: {
        error,
    }
}) => ({
    error
});

const mapDispatchToProps = (dispatch, { history } ) => ({
    createUser: async (event, regForm) => {
        event.preventDefault();
        try {
            const { user } = await auth.createUserWithEmailAndPassword(regForm.email.value, regForm.password.value);
            await generateUser(user, {
                name:regForm.name.value,
                modelDir: {
                    folders: {},
                    models: []
                },
                commDir: {
                    folders: {},
                    communities: []
                }})
                .then(async ({ uid }) => {
                    await dispatch(userStart());
                    await dispatch(fetchUserData(uid));
                    await dispatch(fetchUserCodeLib(uid));
                    history.push('/');
                });
        } catch (error) {
            return dispatch(userFail(authErrors(error)))
        }
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Register);