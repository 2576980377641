import { getBaseUnits } from "utils/fields";
import convertUnit from "utils/convertUnit";
import { getLabel, getCodeLayerLabel, getEffRValLabel } from "utils/dcfExport/details";
import { isEmpty } from "lodash";

export const getEnvelopeData = ({ components = {}, codes = {}, dimensions = {}, modelUnits = "Metric" }) => {
    const a1 = "Measurement System";
    const b1 = modelUnits;

    const { aboveGradeIntFloorArea: { total: totalAgFloorArea = 0 } = {} } = dimensions;
    const { wall = {}, ceiling = {}, expFloor = {} } = components;

    const ceilingResults = Object.keys(ceiling).map((ceilingKey, index) => {
        const {
            label = "",
            measurements: { area, length, heelHeight, slope: { value: slopeValue } = {} } = {},
            ceilingInsType: { codeRef = "", codeLabel = "" } = {},
            ceilingInsType_effRVal = 0,
            constructionType: { id: constructionTypeId } = {},
        } = ceiling[ceilingKey];
        const isFlat = [2, 3].includes(constructionTypeId);

        const {
            [codeRef]: {
                layers: {
                    structureType: { id: structureTypeId } = {},
                    componentTypeSize: { id: componentTypeSizeId } = {},
                    spacing: { id: spacingId } = {},
                    framing: { id: framingId } = {},
                    insulationLayer1: { id: insulationLayer1Id } = {},
                    insulationLayer2: { id: insulationLayer2Id } = {},
                    interior: { id: interiorId } = {},
                } = {},
            } = {},
        } = codes;

        return {
            // data: ceiling[ceilingKey],
            componentId: ceilingKey,
            id: index + 1,
            label,
            length: convertUnit({
                value: length,
                type: "length",
                inputUnit: getBaseUnits("ceilingLength", modelUnits).trueBase,
                outputUnit: getBaseUnits("ceilingLength", modelUnits).displayBase,
            }).toFixed(2),
            area: convertUnit({
                value: area,
                type: "area",
                inputUnit: getBaseUnits("ceilingArea", modelUnits).trueBase,
                outputUnit: getBaseUnits("ceilingArea", modelUnits).displayBase,
            }).toFixed(2),
            heelHeight: convertUnit({
                value: heelHeight,
                type: "length",
                inputUnit: getBaseUnits("ceilingHeelHeight", modelUnits).trueBase,
                outputUnit: getBaseUnits("ceilingHeelHeight", modelUnits).displayBase,
            }).toFixed(2),
            roofSlope: convertUnit({
                value: slopeValue,
                type: "angle",
                inputUnit: getBaseUnits("ceilingSlope", modelUnits).trueBase,
                outputUnit: getBaseUnits("ceilingSlope", modelUnits).displayBase,
            }).toFixed(2),
            constructionType: getLabel("ceilingConstructionType", constructionTypeId),
            ceilingType: codeRef === "UserSpecified" ? getEffRValLabel(ceilingInsType_effRVal, modelUnits) : codeLabel,
            structureType: getCodeLayerLabel("csCeilingStructureType", structureTypeId),
            componentTypeSize: getCodeLayerLabel(
                isFlat ? "csCeilingFlatComponentTypeSize" : "csCeilingComponentTypeSize",
                componentTypeSizeId,
                structureTypeId
            ),
            spacing: getCodeLayerLabel("csCeilingFramingSpacing", spacingId, structureTypeId),
            framing: getCodeLayerLabel("csCeilingFramingSpacing", framingId, structureTypeId),
            insLayer1: getCodeLayerLabel(
                isFlat ? "csCeilingFlatInsLayer1" : "csCeilingInsLayer1",
                insulationLayer1Id,
                structureTypeId
            ),
            insLayer2: getCodeLayerLabel("csCeilingInsLayer2", insulationLayer2Id),
            intFinish: getCodeLayerLabel("csCeilingInterior", interiorId),
        };
    });

    const ceilingObjectData = !isEmpty(ceilingResults)
        ? ceilingResults.map((ceiling) => {
              return {
                  [`${a1}`]: ceiling.id || " ",
                  [`${b1}`]: ceiling.label || " ",
                  C: ceiling.length || " ",
                  D: ceiling.area || " ",
                  E: ceiling.heelHeight || " ",
                  F: ceiling.roofSlope || " ",
                  G: ceiling.constructionType || " ",
                  H: ceiling.ceilingType || " ",
                  I: ceiling.structureType || " ",
                  J: ceiling.componentTypeSize || " ",
                  K: ceiling.spacing || " ",
                  L: ceiling.framing || " ",
                  M: ceiling.insLayer1 || " ",
                  N: ceiling.insLayer2 || " ",
                  O: ceiling.intFinish || " ",
              };
          })
        : [
              {
                  [`${a1}`]: "-",
                  [`${b1}`]: "-",
                  C: "-",
                  D: "-",
                  E: "-",
                  F: "-",
                  G: "-",
                  H: "-",
                  I: "-",
                  J: "-",
                  K: "-",
                  L: "-",
                  M: "-",
                  N: "-",
                  O: "-",
              },
          ];

    const wallResults = Object.keys(wall).map((wallKey, index) => {
        const {
            label = "",
            measurements: { corners, intersections, height, perimeter } = {},
            wallInsType: { codeRef = "", codeLabel = "" } = {},
            wallInsType_effRVal = 0,
            lintelInsType: { codeRef: lintelCodeRef = "", codeLabel: lintelCodeLabel = "" } = {},
            adjacentEnclosedSpace,
        } = wall[wallKey] || {};

        const {
            [codeRef]: {
                layers: {
                    structureType: { id: structureTypeId } = {},
                    componentTypeSize: { id: componentTypeSizeId } = {},
                    spacing: { id: spacingId } = {},
                    framing: { id: framingId } = {},
                    insulationLayer1: { id: insulationLayer1Id } = {},
                    insulationLayer2: { id: insulationLayer2Id } = {},
                    interior: { id: interiorId } = {},
                    sheathing: { id: sheathingId } = {},
                    exterior: { id: exteriorId } = {},
                    studsCornerIntersection: { id: studsCornerIntersectionId } = {},
                } = {},
            } = {},
            [lintelCodeRef]: {
                layers: {
                    lintelType: { id: lintelTypeId } = {},
                    lintelMaterial: { id: lintelMaterialId } = {},
                    lintelIns: { id: lintelInsId } = {},
                } = {},
            } = {},
        } = codes;

        return {
            // data: wall[wallKey],
            componentId: wallKey,
            id: index + 1,
            label,
            height: convertUnit({
                value: height,
                type: "length",
                inputUnit: getBaseUnits("wallHeight", modelUnits).trueBase,
                outputUnit: getBaseUnits("wallHeight", modelUnits).displayBase,
            }).toFixed(2),
            perimeter: convertUnit({
                value: perimeter,
                type: "length",
                inputUnit: getBaseUnits("wallPerimeter", modelUnits).trueBase,
                outputUnit: getBaseUnits("wallPerimeter", modelUnits).displayBase,
            }).toFixed(2),
            corners,
            intersections,
            enclosedSpace: adjacentEnclosedSpace ? "Yes" : "No",
            wallInsType: codeRef === "UserSpecified" ? getEffRValLabel(wallInsType_effRVal, modelUnits) : codeLabel,
            structureType: getCodeLayerLabel("csWallStructureType", structureTypeId),
            componentTypeSize: getCodeLayerLabel("csWallComponentTypeSize", componentTypeSizeId, structureTypeId),
            spacing: getCodeLayerLabel("csWallFramingSpacing", spacingId, structureTypeId),
            framing: getCodeLayerLabel("csWallFramingSpacing", framingId, structureTypeId),
            insLayer1: getCodeLayerLabel("csWallInsLayer1", insulationLayer1Id, structureTypeId),
            insLayer2: getCodeLayerLabel("csWallInsLayer2", insulationLayer2Id),
            intFinish: getCodeLayerLabel("csWallInterior", interiorId),
            sheathing: getCodeLayerLabel("csWallSheathing", sheathingId),
            extFinish: getCodeLayerLabel("csWallExterior", exteriorId),
            studsCorner: getCodeLayerLabel("csWallStudsCornersInt", studsCornerIntersectionId),
            lintelType: getCodeLayerLabel("csLintelType", lintelTypeId),
            lintelMaterial: getCodeLayerLabel("csLintelMaterial", lintelMaterialId),
            insulation: getCodeLayerLabel("csLintelInsulation", lintelInsId),
        };
    });

    const wallObjectData = !isEmpty(wallResults)
        ? wallResults.map((wall) => {
              return {
                  [`${a1}`]: wall.id || " ",
                  [`${b1}`]: wall.label || " ",
                  C: wall.height || " ",
                  D: wall.perimeter || " ",
                  E: wall.corners || " ",
                  F: wall.intersections || " ",
                  G: wall.enclosedSpace || " ",
                  H: wall.wallInsType || " ",
                  I: wall.structureType || " ",
                  J: wall.componentTypeSize || " ",
                  K: wall.spacing || " ",
                  L: wall.framing || " ",
                  M: wall.insLayer1 || " ",
                  N: wall.insLayer2 || " ",
                  O: wall.intFinish || " ",
                  P: wall.sheathing || " ",
                  Q: wall.extFinish || " ",
                  R: wall.studsCorner || " ",
                  S: wall.lintelType || " ",
                  T: wall.lintelMaterial || " ",
                  U: wall.insulation || " ",
              };
          })
        : [
              {
                  [`${a1}`]: "-",
                  [`${b1}`]: "-",
                  C: "-",
                  D: "-",
                  E: "-",
                  F: "-",
                  G: "-",
                  H: "-",
                  I: "-",
                  J: "-",
                  K: "-",
                  L: "-",
                  M: "-",
                  N: "-",
                  O: "-",
                  P: "-",
                  Q: "-",
                  R: "-",
                  S: "-",
                  T: "-",
                  U: "-",
              },
          ];

    //Floor headers only appear on walls, so we only loop through walls
    let floorHeaderInd = 0;
    const floorHeaderResults = Object.keys(wall).reduce((cache, wallKey) => {
        const { label: wallLabel = "", subcomponents: { floorHeader = {} } = {} } = wall[wallKey];
        if (isEmpty(floorHeader)) {
            return cache;
        }

        return [
            ...cache,
            ...Object.keys(floorHeader).map((floorHeaderKey) => {
                const {
                    label,
                    measurements: { height, perimeter } = {},
                    floorHeaderInsType: { codeRef = "", codeLabel = "" },
                    floorHeaderInsType_effRVal,
                } = floorHeader[floorHeaderKey];

                const {
                    [codeRef]: {
                        layers: {
                            insulationLayer1: { id: insulationLayer1Id } = {},
                            insulationLayer2: { id: insulationLayer2Id } = {},
                            sheathing: { id: sheathingId } = {},
                            exterior: { id: exteriorId } = {},
                        } = {},
                    } = {},
                } = codes;

                floorHeaderInd += 1;
                return {
                    id: floorHeaderInd,
                    componentId: floorHeaderKey,
                    label: label,
                    height: convertUnit({
                        value: height,
                        type: "length",
                        inputUnit: getBaseUnits("floorHeaderHeight", modelUnits).trueBase,
                        outputUnit: getBaseUnits("floorHeaderHeight", modelUnits).displayBase,
                    }).toFixed(2),
                    perimeter: convertUnit({
                        value: perimeter,
                        type: "length",
                        inputUnit: getBaseUnits("floorHeaderPerimeter", modelUnits).trueBase,
                        outputUnit: getBaseUnits("floorHeaderPerimeter", modelUnits).displayBase,
                    }).toFixed(2),
                    location: wallLabel,
                    floorHeaderInsType:
                        codeRef === "UserSpecified"
                            ? getEffRValLabel(floorHeaderInsType_effRVal, modelUnits)
                            : codeLabel,
                    insLayer1: getCodeLayerLabel("csFloorHeaderInsLayer1", insulationLayer1Id) || "",
                    insLayer2: getCodeLayerLabel("csFloorHeaderInsLayer2", insulationLayer2Id) || "",
                    sheathing: getCodeLayerLabel("csFloorHeaderSheathing", sheathingId) || "",
                    extFinish: getCodeLayerLabel("csFloorHeaderExterior", exteriorId) || "",
                };
            }),
        ];
    }, []);

    const floorHeaderObjectData = !isEmpty(floorHeaderResults)
        ? floorHeaderResults.map((floorHeader) => {
              return {
                  [`${a1}`]: floorHeader.id || " ",
                  [`${b1}`]: floorHeader.label || " ",
                  C: floorHeader.height || " ",
                  D: floorHeader.perimeter || " ",
                  E: floorHeader.location || " ",
                  F: floorHeader.floorHeaderInsType || " ",
                  G: floorHeader.insLayer1 || " ",
                  H: floorHeader.insLayer2 || " ",
                  I: floorHeader.sheathing || " ",
                  J: floorHeader.extFinish || " ",
              };
          })
        : [
              {
                  [`${a1}`]: "-",
                  [`${b1}`]: "-",
                  C: "-",
                  D: "-",
                  E: "-",
                  F: "-",
                  G: "-",
                  H: "-",
                  I: "-",
                  J: "-",
              },
          ];

    // exposed floor
    const expFloorResults = Object.keys(expFloor).map((expFloorKey, ind) => {
        const {
            label = "",
            measurements: { length, area } = {},
            expFloorInsType: { codeRef = "", codeLabel = "" } = {},
            expFloorInsType_effRVal = 0,
            adjacentEnclosedSpace,
        } = expFloor[expFloorKey];

        const {
            [codeRef]: {
                layers: {
                    structureType: { id: structureTypeId } = {},
                    componentTypeSize: { id: componentTypeSizeId } = {},
                    spacing: { id: spacingId } = {},
                    framing: { id: framingId } = {},
                    insulationLayer1: { id: insulationLayer1Id } = {},
                    insulationLayer2: { id: insulationLayer2Id } = {},
                    interior: { id: interiorId } = {},
                    sheathing: { id: sheathingId } = {},
                    exterior: { id: exteriorId } = {},
                    dropFraming: { id: dropFramingId } = {},
                } = {},
            } = {},
        } = codes;

        return {
            id: ind + 1,
            label,
            length: convertUnit({
                value: length,
                type: "length",
                inputUnit: getBaseUnits("expFloorLength", modelUnits).trueBase,
                outputUnit: getBaseUnits("expFloorLength", modelUnits).displayBase,
            }).toFixed(2),
            area: convertUnit({
                value: area,
                type: "area",
                inputUnit: getBaseUnits("expFloorArea", modelUnits).trueBase,
                outputUnit: getBaseUnits("expFloorArea", modelUnits).displayBase,
            }).toFixed(2),
            enclosedSpace: adjacentEnclosedSpace ? "Yes" : "No",
            expFloorInsType:
                codeRef === "UserSpecified" ? getEffRValLabel(expFloorInsType_effRVal, modelUnits) : codeLabel,
            structureType: getCodeLayerLabel("csExpFloorStructureType", structureTypeId),
            componentTypeSize: getCodeLayerLabel("csExpFloorComponentTypeSize", componentTypeSizeId, structureTypeId),
            spacing: getCodeLayerLabel("csExpFloorFramingSpacing", spacingId, structureTypeId),
            framing: getCodeLayerLabel("csExpFloorFramingSpacing", framingId, structureTypeId),
            insLayer1: getCodeLayerLabel("csExpFloorInsLayer1", insulationLayer1Id, structureTypeId),
            insLayer2: getCodeLayerLabel("csExpFloorInsLayer2", insulationLayer2Id),
            intFinish: getCodeLayerLabel("csExpFloorInterior", interiorId),
            sheathing: getCodeLayerLabel("csExpFloorSheathing", sheathingId),
            extFinish: getCodeLayerLabel("csExpFloorExterior", exteriorId),
            dropFraming: getCodeLayerLabel("csExpFloorDropFraming", dropFramingId),
        };
    });

    const expFloorObjectData = !isEmpty(expFloorResults)
        ? expFloorResults.map((expFloor) => {
              return {
                  [`${a1}`]: expFloor.id || " ",
                  [`${b1}`]: expFloor.label || " ",
                  C: expFloor.length || " ",
                  D: expFloor.area || " ",
                  E: expFloor.enclosedSpace || " ",
                  F: expFloor.expFloorInsType || " ",
                  G: expFloor.structureType || " ",
                  H: expFloor.componentTypeSize || " ",
                  I: expFloor.spacing || " ",
                  J: expFloor.framing || " ",
                  K: expFloor.insLayer1 || " ",
                  L: expFloor.insLayer2 || " ",
                  M: expFloor.intFinish || " ",
                  N: expFloor.sheathing || " ",
                  O: expFloor.extFinish || " ",
                  P: expFloor.dropFraming || " ",
              };
          })
        : [
              {
                  [`${a1}`]: "-",
                  [`${b1}`]: "-",
                  C: "-",
                  D: "-",
                  E: "-",
                  F: "-",
                  G: "-",
                  H: "-",
                  I: "-",
                  J: "-",
                  K: "-",
                  L: "-",
                  M: "-",
                  N: "-",
                  O: "-",
                  P: "-",
              },
          ];

    const spacing = [
        {
            [`${a1}`]: "",
        },
        {
            [`${a1}`]: "",
        },
        {
            [`${a1}`]: "",
        },
    ];

    return [
        // each object is a row indcating the column and its value
        {
            [`${a1}`]: `Above Grade Heated Floor Area (${getBaseUnits("agHeatedFloorArea", modelUnits).displayBase})`,
            [`${b1}`]: parseFloat(
                convertUnit({
                    value: totalAgFloorArea,
                    type: "area",
                    inputUnit: getBaseUnits("agHeatedFloorArea", modelUnits).trueBase,
                    outputUnit: getBaseUnits("agHeatedFloorArea", modelUnits).displayBase,
                }).toFixed(2)
            ),
        },
        {
            [`${a1}`]: "",
        },
        {
            [`${a1}`]: "",
        },
        // ceilings
        {
            [`${a1}`]: "Ceilings",
        },
        {
            [`${a1}`]: "ID",
            [`${b1}`]: "Label",
            C: `Length (${getBaseUnits("ceilingLength", modelUnits).displayBase})`,
            D: `Area (${getBaseUnits("basementArea", modelUnits).displayBase})`,
            E: `Heel Height (${getBaseUnits("ceilingHeelHeight", modelUnits).displayBase})`,
            F: `Roof Slope (rise/run)`,
            G: "Construction Type",
            H: `Ceiling Type`,
            I: `Structure Type $`,
            J: `Component type/size $`,
            K: "Spacing $",
            L: "Framing $",
            M: "Insulation Layer 1 $",
            N: "Insulation Layer 1 $",
            O: "Interior Finish $",
        },
        ...ceilingObjectData,
        ...spacing,
        // walls
        {
            [`${a1}`]: "Walls",
        },
        {
            [`${a1}`]: "ID",
            [`${b1}`]: "Label",
            C: `Height (${getBaseUnits("wallHeight", modelUnits).displayBase})`,
            D: `Perimeter (${getBaseUnits("wallPerimeter", modelUnits).displayBase})`,
            E: `Corners`,
            F: `Intersections`,
            G: "Adjacent to enclosed unconditioned space",
            H: "Wall Type",
            I: `Structure Type $`, // change unit based on global units
            J: `Component Type/Size $`,
            K: `Spacing $`,
            L: `Framing (for solid structure type only) $`,
            M: "Insulation Layer 1 $",
            N: "Insulation Layer 2 $",
            O: "Interior Finish $",
            P: "Sheathing $",
            Q: "Exterior Finish $",
            R: "# of studs in corner (if known) $",
            S: "Lintel Type (if known) $",
            T: "Lintel Material $",
            U: "Lintel Insulation $",
        },
        ...wallObjectData,
        ...spacing,
        // Floors Headers
        {
            [`${a1}`]: "Floor Headers",
        },
        {
            [`${a1}`]: "ID",
            [`${b1}`]: "Label",
            C: `Height (${getBaseUnits("floorHeaderHeight", modelUnits).displayBase})`,
            D: `Perimeter (${getBaseUnits("floorHeaderPerimeter", modelUnits).displayBase})`,
            E: "location",
            F: "Floor Header Type",
            G: "Insulation Layers 1 $",
            H: "Insulation Layers 1 $",
            I: "Sheathing $",
            J: "Exterior Finish $",
        },
        ...floorHeaderObjectData,
        ...spacing,
        // exp floors
        {
            [`${a1}`]: "Exposed Floors",
        },
        {
            [`${a1}`]: "ID",
            [`${b1}`]: "Label",
            C: `Length (${getBaseUnits("expFloorLength", modelUnits).displayBase})`,
            D: `Area (${getBaseUnits("expFloorArea", modelUnits).displayBase})`,
            E: "Adjacent to enclosed unconditioned space",
            F: "Exposed Floor Type",
            G: `Structure Type $`, // change unit based on global units
            H: `Component Type/Size $`,
            I: `Spacing $`,
            J: `Framing (for solid structure type only) $`,
            K: "Insulation Layer 1 $",
            L: "Insulation Layer 2 $",
            M: "Interior Finish $",
            N: "Sheathing $",
            O: "Exterior Finish $",
            P: "Drop Framing $",
        },
        ...expFloorObjectData,
        ...spacing,
    ];
};
