import React, { useEffect, useState } from "react";
import classes from "./style.module.scss";
import InfoTooltip from "components/InfoTooltip";
import Units from "components/Input/InputWithUnits/Units";
import convertUnit from "utils/convertUnit";
import DrawingIcon from "assets/images/icons/JSX/Drawing";
import Tooltip from "components/Tooltip";
import EditTooltip from "components/Input/EditTooltip";
import mixpanel from "mixpanel-browser";
import LoadingDots from "components/LoadingDots";
import WarningTooltip from "components/WarningTooltip";
import classNames from "classnames";

//TODO: Rewrite the units functionality to be able to use it outside of forms
//? unit is composed of:
//? options,

//TODO: make tooltip more unique
const Tinput = ({
    label,
    input = {},
    meta = {},
    type,
    property,
    inputType = "num",
    className,
    large,
    placeholder = "",
    hideLabel,
    autoComplete = "off",
    info = "",
    disabled = false,
    canReset = false,
    parentError = false,
    quantity = false,
    setValue,
    decimals = 0,
    hideField,
    change: unitChange,
    units: {
        base: { trueBase = "m", displayBase = "m" } = {},
        options = [], // an array of options IE ['m2', 'ft2']
        selected = "",
        unitType, // length || area**2 || volume**3 || ...
    } = {},
    noUnit = false,
    validation = false,
    handleError = false,
    isDrawing = false,
    isDrawingTip = "",
    editToggle,
    onUnitChange = () => "",
    isLoading = false,
    warning = "",
}) => {
    const [selectedUnit, changeSelectedUnit] = useState(selected || displayBase || trueBase);
    const [displayValue, changeDisplayValue] = useState("");
    const [focused, toggleFocused] = useState(false);
    const [err, setErr] = useState({
        err: false,
        msg: "",
    });

    useEffect(() => {
        if (handleError) {
            handleError(err);
        }
    }, [err.err]); //? This may give you a warning. Please ignore it unless you decide to update the project's React version to the latest.

    useEffect(() => {
        if (disabled) {
            onChange(Number(input?.value || 0).toFixed(4)); //This makes sure that disabled (calculated) values are updated in the state
        }

        if (canReset) {
            if (input.value === 0) {
                changeDisplayValue(0);
            } else if (input.value === "") {
                changeDisplayValue("");
            }
        }
    }, [input.value]); //? This may give you a warning. Please ignore it unless you decide to update the project's React version to the latest.

    const { invalid = false, error = "" } = meta;
    // 'value' here represents the trueValue
    const { value, onChange, name = "" } = input;

    const mainClasses = [
        classes.inputField,
        className && className,
        large && classes.large,
        quantity && classes.hasQuantity,
        (parentError || invalid) && classes.invalid,
        warning && classes.warning,
        hideField && classes.hideField,
    ].filter((c) => c);

    const handleSetValue = () => {
        if (inputType === "num") {
            const newDisplayValue = parseFloat(
                convertUnit({
                    value: parseFloat(setValue),
                    type: unitType,
                    inputUnit: trueBase,
                    outputUnit: selectedUnit,
                }).toFixed(decimals)
            );

            changeDisplayValue(newDisplayValue);
            onChange(parseFloat(parseFloat(setValue).toFixed(decimals + 2)));
        } else {
            changeDisplayValue(setValue);
        }
    };

    // Handles any change made to the display field
    const handleChange = (e) => {
        const { err, msg } = validation(e.target.value, unitType, type, property);
        setErr({ err: err, msg: msg });

        if (inputType === "num") {
            const newDisplayValue = parseFloat(e.target.value);
            changeDisplayValue(e.target.value);

            const newTrueValue = parseFloat(
                convertUnit({
                    value: newDisplayValue,
                    type: unitType,
                    inputUnit: selectedUnit,
                    outputUnit: trueBase,
                }).toFixed(decimals + 2)
            );

            changeDisplayValue(parseFloat(newDisplayValue));
            onChange(newTrueValue);
        } else {
            onChange(e.target.value);
            changeDisplayValue(e.target.value);
        }
    };

    // Handles what happens when the user leaves the display field
    const handleBlur = () => {
        if (inputType === "num") {
            const val = isNaN(value) ? 0 : value;
            const dispVal = isNaN(value) ? 0 : displayValue;

            const newTrueValue = parseFloat(parseFloat(val).toFixed(decimals + 2));
            const newDisplayValue = parseFloat(parseFloat(dispVal).toFixed(decimals));

            toggleFocused(false);
            onChange(newTrueValue);
            changeDisplayValue(newDisplayValue);

            if (setValue || setValue === 0) {
                handleSetValue();
            }
        } else {
            changeDisplayValue(value);
        }
    };

    // Handles a new unit selection
    const handleUnitChange = (unit) => {
        if (inputType === "num") {
            const newDisplayValue = parseFloat(
                convertUnit({
                    value: value, //using true value here to prevent loss of info when changing units back and forth
                    type: unitType,
                    inputUnit: trueBase,
                    outputUnit: unit,
                }).toFixed(decimals)
            );

            const newTrueBaseValue = parseFloat(
                convertUnit({
                    value: value,
                    type: unitType,
                    inputUnit: trueBase,
                    outputUnit: trueBase,
                }).toFixed(decimals + 2)
            );

            changeDisplayValue(parseFloat(newDisplayValue));
            changeSelectedUnit(unit);

            // Changes the unit field in the form
            // unitChange(accessor, unit);
            onUnitChange(newTrueBaseValue, unit);
        }
    };

    useEffect(() => {
        // Get the right display value when component is initialized
        if (inputType === "num") {
            const newDisplayValue = parseFloat(
                convertUnit({
                    value: value,
                    type: unitType,
                    inputUnit: trueBase,
                    outputUnit: selectedUnit,
                }).toFixed(decimals)
            );

            if (value || value === 0) {
                changeDisplayValue(newDisplayValue);
            }
        }
    }, [value]);

    useEffect(() => {
        // If value is manually set, do shtuff
        if ((setValue || setValue === 0) && !focused) {
            handleSetValue();
        }
    }, [setValue]); //? This may give you a warning. Please ignore it unless you decide to update the project's React version to the latest.

    return (
        <div className={mainClasses.join(" ")}>
            <label className={`${hideLabel && "invisible"} ${isDrawing && classes.isDrawing}`} htmlFor={name}>
                {label}{" "}
                {isDrawing && (
                    <>
                        <span
                            data-html
                            data-for={`${name}_drawingTip`}
                            data-tip={`<span style="display:block;max-width:10.5rem;text-align:center;">${
                                isDrawingTip
                                    ? isDrawingTip
                                    : "This field can only be edited in the <strong>Drawing Capture</strong> tool."
                            }</span>`}
                        >
                            <DrawingIcon />
                        </span>
                        <Tooltip id={`${name}_drawingTip`} />
                    </>
                )}
            </label>
            {info && <InfoTooltip className={classes.infoTooltip} info={info} />}
            {warning && (
                <WarningTooltip
                    className={classNames(classes.warningTooltip, {
                        [classes.pushLeft]: editToggle || info,
                    })}
                    warning={warning}
                />
            )}
            {editToggle && (
                <EditTooltip
                    onClick={() => {
                        editToggle();
                        mixpanel.track("Summary Calculation Open", {
                            "Field Name": label,
                        });
                    }}
                    name={name}
                    label={label}
                />
            )}
            {isLoading && (
                <div className={classes.loading}>
                    <LoadingDots />
                </div>
            )}
            {!isLoading && (
                <div className={classes.inputWithUnit}>
                    <input
                        id={`${name}-front`}
                        name={`${name}-front`}
                        type={type}
                        placeholder={placeholder}
                        autoComplete={autoComplete}
                        disabled={disabled}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onFocus={() => toggleFocused(true)}
                        value={displayValue}
                        style={{
                            borderTopRightRadius:
                                unitType === "count" || unitType === "string" || unitType === "deg" ? "0.375rem" : "0",
                            borderBottomRightRadius:
                                unitType === "count" || unitType === "string" || unitType === "deg" ? "0.375rem" : "0",
                        }}
                    />
                    <input {...input} id={name} name={name} type="hidden" />
                    {unitType !== "count" && unitType !== "string" && unitType !== "deg" && (
                        <Units selectedUnit={selectedUnit} unitOptions={options} changeSelected={handleUnitChange} />
                    )}
                </div>
            )}
            {invalid && error && <span className={classes.errorMessage}>{error}</span>}
            {err.err && <span className={classes.errorMessage}>{err.msg}</span>}
        </div>
    );
};

export default Tinput;
