import React from "react";
import classes from "./style.module.scss";
import classNames from "classnames";
import { pure } from "recompose";
import { Dialog, Grow } from "@material-ui/core";

export const CloseDialog = ({ closeActions, className }) => (
    <div className={classNames(classes.close, { [className]: className })} onClick={() => closeActions()}>
        <div>
            <span>close</span>
        </div>
    </div>
);

const getClasses = ({ rootClass = "", paperClass = "" }) => ({
    root: `${classes.dialogRoot} ${rootClass ? rootClass : ""}`,
    paper: `${classes.paper} ${paperClass ? paperClass : ""}`,
});

export default pure(({ children, classes = {}, ...props }) => (
    <Dialog
        onKeyDown={(event) => {
            if (props.actionkey && props.closeActions) {
                if (event.key === props.actionkey) {
                    props.closeActions();
                }
            }
        }}
        classes={getClasses(classes)}
        TransitionComponent={Grow}
        transitionDuration={450}
        {...props}
    >
        {children}
    </Dialog>
));
