import React from "react";
import withTable from "./withTable";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    IconButton,
} from "@material-ui/core";
import { newRowInputs } from "./newRowInputs";
import Tinput from "../components/TSelect/Tinput";
import { getTValidations } from "./tableValidations";
import Delete from "assets/images/icons/JSX/Delete";
import TinputCell from "./TInputCell";
import { getBaseUnits } from "utils/fields";

//TODO: Address display unit bug in table
const TTable = ({
    tId,
    newRow,
    removeTableComponent,
    handleCellChange,
    addNewRow,
    handleUnitChange,
    editTableComponent,
    rowError,
    dataset = [],
    modelUnits,
    hideAddRow = false,
}) => {
    const renderCell = (cell, index, dataRow) => {
        if (cell[0] !== "id") {
            return (
                <TinputCell
                    cell={cell}
                    dataRow={dataRow}
                    editTableComponent={editTableComponent}
                    key={index}
                    modelUnits={modelUnits}
                />
            );
        } else {
            return null;
        }
    };

    const rightWindowOrder = {
        title: null,
        width: null,
        height: null,
        ovWidth: null,
        ovHeight: null,
        qty: null,
    };

    return (
        <div className="takeoff-table-container">
            <TableContainer component={Paper} className={"tt-container"}>
                <Table className="takeoff-table">
                    <TableHead>
                        <TableRow>
                            <TableCell
                                className={"tt-cell tt-head-cell tt-paddingLeft"}
                                align={"left"}
                                padding={"none"}
                                size={"small"}
                            >
                                {" Window label/side "}
                            </TableCell>
                            <TableCell
                                className={"tt-cell tt-head-cell tt-paddingRight--small"}
                                align={"right"}
                                padding={"none"}
                                size={"small"}
                            >
                                {` Width (${modelUnits === "Metric" ? "mm" : "in"})`}
                            </TableCell>
                            <TableCell
                                className={"tt-cell tt-head-cell tt-paddingRight--small"}
                                align={"right"}
                                padding={"none"}
                                size={"small"}
                            >
                                {` Height (${modelUnits === "Metric" ? "mm" : "in"})`}
                            </TableCell>
                            <TableCell
                                className={"tt-cell tt-head-cell tt-paddingRight--small"}
                                align={"right"}
                                padding={"none"}
                                size={"small"}
                            >
                                {` Overhang width (${modelUnits === "Metric" ? "m" : "ft"})`}
                            </TableCell>
                            <TableCell
                                className={"tt-cell tt-head-cell tt-paddingRight--small"}
                                align={"right"}
                                padding={"none"}
                                size={"small"}
                            >
                                {` Header height (${modelUnits === "Metric" ? "m" : "ft"})`}
                            </TableCell>
                            <TableCell
                                className={"tt-cell tt-head-cell tt-paddingRight--small"}
                                align={"right"}
                                padding={"none"}
                                size={"small"}
                            >
                                {" Qty "}
                            </TableCell>
                            <TableCell
                                className={`tt-cell tt-head-cell-btn ${
                                    hideAddRow && "tt-head-cell-btn-hide"
                                } tt-paddingRight--small`}
                                align={"right"}
                                padding={"none"}
                                size={"small"}
                            >
                                {" "}
                            </TableCell>
                            {/* For the delete icon */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataset &&
                            dataset.map((dataRow, index) => {
                                return (
                                    <TableRow
                                        style={{
                                            backgroundColor: (index + 1) % 2 === 0 ? "#FFFFFF" : "#F6F8F9",
                                        }}
                                        key={index}
                                    >
                                        {Object.entries(Object.assign(rightWindowOrder, dataRow)).map((cell, index) => {
                                            return renderCell(cell, index, dataRow);
                                        })}

                                        {hideAddRow ? (
                                            <TableCell
                                                className={"tt-hide-cell"}
                                                size={"small"}
                                                padding={"none"}
                                            ></TableCell>
                                        ) : (
                                            <TableCell
                                                className={"tt-cell tt-rightRadius tt-paddingRight"}
                                                padding={"none"}
                                                size={"medium"}
                                                align={"right"}
                                            >
                                                <IconButton onClick={() => removeTableComponent(dataRow.id)}>
                                                    <Delete />
                                                </IconButton>
                                            </TableCell>
                                        )}
                                    </TableRow>
                                );
                            })}

                        {!hideAddRow && (
                            <TableRow className={"tt-row"}>
                                {newRowInputs.map((input, index) => {
                                    return (
                                        <TableCell className={"tt-row"} padding={"none"} size={"small"} key={index}>
                                            <Tinput
                                                className={`${input.key === "title" && "table-tinput-title"} ${
                                                    input.key === "qty" && "table-tinput-qty"
                                                } 
              ${input.key !== "qty" && input.key !== "title" && "table-tinput"} 
              tt-input`}
                                                key={`table-input-${tId}-${index}`}
                                                inputType={input.key === "title" ? "string" : "num"}
                                                type={input.type}
                                                property={input.key}
                                                decimals={2}
                                                canReset={true}
                                                placeholder={input.key === "title" ? "Enter window description" : ""}
                                                validation={getTValidations}
                                                input={{
                                                    value: newRow[input.key].value,
                                                    onChange: (e) => handleCellChange(input.key, e),
                                                }}
                                                units={{
                                                    base: getBaseUnits(input.fieldKey, modelUnits),
                                                    options: input.units.options,
                                                    selected: newRow[input.key].unit,
                                                    unitType: input.units.unitType,
                                                }}
                                                onUnitChange={(value, unit) => handleUnitChange(input.key, value, unit)}
                                            />
                                        </TableCell>
                                    );
                                })}
                                <TableCell
                                    className={"tt-row table-tinput-btn"}
                                    padding={"none"}
                                    size={"small"}
                                    align={"right"}
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => addNewRow()}
                                        disabled={rowError}
                                    >
                                        {" Add "}
                                    </Button>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <style>
                {`
      .takeoff-table-container {
        margin-top: 30px;
        width: 100% !important;
      }

      .table-tinput-title {
        width: 200px !important;
      }
      .table-tinput {
        width: 175px !important;
      }
      .table-tinput-qty {
        width: 110px !important;
      }
      .table-tinput-btn { 
        width: 75px !important;
      }
      .tt-row {
        height: 52px !important;
      }
      .tt-input {
        height: 36px !important;
        padding: 0px !important;
        margin: 0px !important;
      }
      .tt-head-row {
        background-color: #FFFFFF !important;
      }
      .tt-head-cell, .tt-head-cell-btn {
        background-color: #FFFFFF !important;
        border-right: none !important;
        border-left: none !important;
        font-size: 14px;       
      }
      .tt-head-cell-btn {
        width: 72px !important;
      }
      .tt-head-cell-btn-hide {
        width: 4px !important;
      }
      .MuiTableCell-root {
        padding: 0px !important;
        border: none !important;     
      }
      .tt-container {
        box-shadow: none !important;
        padding-bottom: 20px !important;
      }
      .tt-paddingLeft {
        padding-left: 15px !important;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      .tt-rightRadius {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      .tt-paddingRight {
        padding-right: 15px !important;
      }

      .tt-paddingRight--small {
        padding-right: 11px !important;
      }
      .tt-hide-cell {
        width: 4px !important;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    `}
            </style>
        </div>
    );
};

export default withTable(TTable);
