import { createSelector } from "reselect";

const selectImageGallery = (state) => state.imageGallery;

export const selectIsImageGalleryOpen = createSelector(
    [selectImageGallery],
    (imageGallery) => imageGallery.isImageGalleryOpen
);

export const selectUploadingImages = createSelector(
    [selectImageGallery],
    (imageGallery) => imageGallery.uploadingImages
);

export const selectAllImages = createSelector([selectImageGallery], (imageGallery) => imageGallery.allImages);

export const selectLoading = createSelector([selectImageGallery], (imageGallery) => imageGallery.loading);

export const selectError = createSelector([selectImageGallery], (imageGallery) => imageGallery.error);

export const selectAllTags = createSelector([selectImageGallery], (imageGallery) => imageGallery.tags);
