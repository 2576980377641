import React, { useEffect, useRef } from "react";
import globalStyles from "components/globalStyles.module.scss";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import Select from "components/Input/Select";
import Checkbox from "components/Input/Checkbox";
import { Field } from "redux-form";
import Button from "components/Button";
import { getOptions } from "utils/fields";
import { required, maxLength, getValidation } from "utils/fieldValidation";
import Tooltip from "components/Tooltip";

const charLim32 = maxLength(32);
const labelValidation = [required, charLim32];
const typeValidation = getValidation("csLintelType");
const materialValidation = getValidation("csLintelMaterial");
const insValidation = getValidation("csLintelInsulation");

export default React.memo(
    ({
        open = false,
        toggleOpen,
        submitting,
        handleSubmit,
        invalid,
        isEditing,
        isLibCode,
        clearInitCode,
        lintelValue,
        lintelLabel,
        isCodeLibrary = false,
    }) => {
        const valueRef = useRef(null);

        useEffect(() => {
            valueRef.current = lintelValue;
        }, [lintelValue, lintelLabel]);

        return (
            <div className={globalStyles.standardCodeWrapper}>
                <h3>Standard Code</h3>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Input}
                        type="text"
                        name={`lintelCode.label`}
                        label="Code Label"
                        placeholder="Code Label"
                        validate={labelValidation}
                        setValue={lintelLabel.toString() === valueRef.current ? lintelValue : null}
                    />
                    <Field
                        component={Input}
                        type="text"
                        name={`lintelCode.value`}
                        label="Internal Code"
                        placeholder="###########"
                        disabled={true}
                        setValue={lintelValue}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Select}
                        name={`lintelCode.layers.lintelType`}
                        label="Lintel Type"
                        placeholder="Choose Lintel Type"
                        options={getOptions({ fieldKey: "csLintelType" })}
                        validate={typeValidation}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Select}
                        name={`lintelCode.layers.lintelMaterial`}
                        label="Material"
                        placeholder="Choose Material"
                        options={getOptions({ fieldKey: "csLintelMaterial" })}
                        validate={materialValidation}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Select}
                        name={`lintelCode.layers.lintelIns`}
                        label="Insulation"
                        placeholder="Choose Insulation"
                        options={getOptions({ fieldKey: "csLintelInsulation" })}
                        validate={insValidation}
                    />
                </InputRow>
                <div style={{ borderBottom: "1px solid #e1eaf0", marginTop: "1.5rem", marginBottom: "1.5rem" }}></div>
                {!isCodeLibrary && (
                    <InputRow gridTemplate="auto 1fr">
                        <div data-tip="Code will automatically be updated in library" data-for="lintelCodeLibTip">
                            <Field
                                component={Checkbox}
                                name={"addToLibrary"}
                                label={isLibCode ? "Update code in library" : "Add to code library"}
                                large
                                disabled={isLibCode}
                                type="checkbox"
                            />
                        </div>
                    </InputRow>
                )}
                <div className={globalStyles.buttons}>
                    <Button
                        large
                        type="hollow"
                        onClick={() => {
                            clearInitCode();
                            toggleOpen(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button large onClick={handleSubmit} disabled={submitting || invalid}>
                        {submitting ? "Saving Code..." : "Save Code"}
                    </Button>
                </div>
                {
                    // isEditing && <p data-for="lintelDeleteCodeTip" data-tip="Deletes code from model" className={classes.delete}><Delete /> Delete Code</p>
                }
                <Tooltip id="lintelCodeLibTip" hide={!isLibCode} />
                <Tooltip id="lintelDeleteCodeTip" delay />
            </div>
        );
    }
);
