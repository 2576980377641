import React, { useEffect, useRef, useCallback } from "react";
import globalStyles from "components/globalStyles.module.scss";
import Drawer from "components/Drawer";
import wallCrossSection from "assets/images/Wall-Cross-Section.svg";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import Select from "components/Input/Select";
import MultiSelect from "components/Input/MultiSelect";
import Checkbox from "components/Input/Checkbox";
import { Field } from "redux-form";
import Button from "components/Button";
import { getOptions, getFirstOption, getDefaultOption } from "utils/fields";
import { required, maxLength, getValidation } from "utils/fieldValidation";
import { getAllSubcomponents } from "utils/enclosure/components";
import Tooltip from "components/Tooltip";

const charLim32 = maxLength(32);

const labelValidation = [required, charLim32];
const structureValidation = getValidation("csFloorHeaderStructureType");
const sheathingValidation = getValidation("csFloorHeaderSheathing");
const exteriorValidation = getValidation("csFloorHeaderExterior");
const ins1Validation = getValidation("csFloorHeaderInsLayer1");
const ins2Validation = getValidation("csFloorHeaderInsLayer2");

const codeSort = (a, b) => {
    if (a.label < b.label) {
        return -1;
    }
    if (a.label > b.label) {
        return 1;
    }
    return 0;
};

export default React.memo(
    ({
        open = false,
        toggleOpen,
        floorHeaderLabel,
        floorHeaderStructureId,
        floorHeaderValue,
        submitting,
        handleSubmit,
        change,
        invalid,
        isEditing,
        isLibCode,
        clearInitCode,
        isCodeLibrary = false,
        isUpgradeComponent = false,
        fieldAccessor,
        components,
    }) => {
        const valueRef = useRef(null);

        useEffect(() => {
            valueRef.current = floorHeaderValue;
        }, [floorHeaderValue, floorHeaderLabel]);

        let availableComponents = getAllSubcomponents("floorHeader", components, fieldAccessor).sort(codeSort);

        return (
            <div className={globalStyles.standardCodeWrapper}>
                <h3>Standard Code</h3>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Input}
                        type="text"
                        name={`floorHeaderCode.label`}
                        label="Code Label"
                        placeholder="Code Label"
                        validate={labelValidation}
                        setValue={floorHeaderLabel.toString() === valueRef.current ? floorHeaderValue : null}
                    />
                    <Field
                        component={Input}
                        type="text"
                        name={`floorHeaderCode.value`}
                        label="Internal Code"
                        placeholder="###########"
                        disabled={true}
                        setValue={floorHeaderValue}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Select}
                        name={`floorHeaderCode.layers.structureType`}
                        label="Structure Type"
                        placeholder="Choose Structure Type"
                        options={getOptions({ fieldKey: "csFloorHeaderStructureType" })}
                        validate={structureValidation}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Select}
                        name={`floorHeaderCode.layers.sheathing`}
                        label="Sheathing"
                        placeholder="Choose Sheathing"
                        options={getOptions({ fieldKey: "csFloorHeaderSheathing" })}
                        validate={sheathingValidation}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Select}
                        name={`floorHeaderCode.layers.exterior`}
                        label="Exterior"
                        placeholder="Choose Exterior"
                        options={getOptions({ fieldKey: "csFloorHeaderExterior" })}
                        validate={exteriorValidation}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Select}
                        name={`floorHeaderCode.layers.insulationLayer1`}
                        label="Framing Cavity Insulation (Layer 1)"
                        placeholder="Choose Insulation"
                        options={getOptions({ fieldKey: "csFloorHeaderInsLayer1" })}
                        validate={ins1Validation}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Select}
                        name={`floorHeaderCode.layers.insulationLayer2`}
                        label="Continuous Layer of Insulation (Layer 2)"
                        placeholder="Choose Insulation"
                        options={getOptions({ fieldKey: "csFloorHeaderInsLayer2" })}
                        validate={ins2Validation}
                    />
                </InputRow>
                <div style={{ borderBottom: "1px solid #e1eaf0", marginTop: "1.5rem", marginBottom: "1.5rem" }}></div>
                {!isCodeLibrary && !isUpgradeComponent && (
                    <InputRow gridTemplate="1fr">
                        <Field
                            component={MultiSelect}
                            name={`selectedComponents`}
                            label={`Apply to multiple floor header components`}
                            options={availableComponents.map(({ disabled = false, label = "", ...rest }) => ({
                                ...rest,
                                disabled,
                                label: disabled ? `${label} (current component)` : label,
                            }))}
                            hasSelectAll
                        />
                    </InputRow>
                )}
                {!isCodeLibrary && (
                    <InputRow gridTemplate="1fr 1fr">
                        <div data-tip="Code will automatically be updated in library" data-for="codeLibTip">
                            <Field
                                component={Checkbox}
                                name={"addToLibrary"}
                                label={isLibCode ? "Update code in library" : "Add to code library"}
                                large
                                disabled={isLibCode}
                                type="checkbox"
                            />
                        </div>
                        <Field
                            component={Checkbox}
                            name={"setAsModelDefault"}
                            label={"Set as model default"}
                            large
                            type="checkbox"
                        />
                    </InputRow>
                )}
                <div className={globalStyles.buttons}>
                    <Button
                        large
                        type="hollow"
                        onClick={() => {
                            clearInitCode();
                            toggleOpen(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button large onClick={handleSubmit} disabled={submitting || invalid}>
                        {submitting ? "Saving Code..." : "Save Code"}
                    </Button>
                </div>
                <div style={{ paddingTop: "7.5rem" }}></div>
                <Tooltip id="codeLibTip" hide={!isLibCode} />
            </div>
        );
    }
);
