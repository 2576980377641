import { Parser } from 'expr-eval';

// Checks validity of equation and parses
export const parseEquation = (input) => {
    // Remove equal sign before parsing equation
    const equation = input.replace('=', '');

    // Give blank equations a value of 0.00
    if (equation === "") {
        return 0.00;
    }

    // Return result if equation is valid, otherwise return null
    try {
        return Parser.evaluate(equation);
    }
    catch (err) {
        return null;
    }
}

// Checks if string is an equation, valid or invalid
export const isEquation = (input) => {
    return input.startsWith("=");
}