import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import isEmpty from 'lodash/isEmpty';
import Ventilator from './';

const mapStateToProps = (
    {
        form,
        model:{
            contentOpen=false,
        }={}
    },
    {
        accessor,
        formName='model'
    }
) => {
    const selector = formValueSelector(formName);
    const modelSelector = formValueSelector('model');

    const label = selector({form}, `${accessor}.label`) || {};
    const {id:ventTypeId} = selector({form}, `${accessor}.type`) || {};
    const supplyFlowUnits = selector({form}, `${accessor}.supplyFlowrate_u`);
    const exhaustFlowUnits = selector({form}, `${accessor}.exhaustFlowrate_u`);
    const fanPowerUnits = selector({form}, `${accessor}.fanPower1_u`);
    const isDefaultFanpower = selector({form}, `${accessor}.isDefaultFanpower`);

    const { id: operationScheduleId, value: operationScheduleValue} = selector({form}, `${accessor}.operationSchedule`) || {};
    const operationScheduleUnits = selector({form}, `${accessor}.operationSchedule_u`);

    const modelUnits = modelSelector({form}, `modelData.uiSettings.primaryUnits`) || {};

    return {
        accessor,
        label,
        ventTypeId,
        supplyFlowUnits,
        exhaustFlowUnits,
        fanPowerUnits,
        isDefaultFanpower,
        operationScheduleId,
        operationScheduleValue,
        operationScheduleUnits,
        modelUnits,
        contentOpen
    }
};

const mapDispatchToProps = () => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Ventilator);