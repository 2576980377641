import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import EnerGuideResults from "./";
import { actions } from "../_ducks";

const { changePackage, changeCase } = actions;

const mapStateToProps = ({
    results: { packageResults = {}, selectedPackage = "base", selectedCase = "socBaseCase" } = {},
    model: { modelDetails: { lastCliRun: { cliId = {} } = {} } = {} } = {},
    upgrades: { packages = {} } = {},
    form,
}) => {
    const selector = formValueSelector("model");
    const { id: weatherProvTerrId } = selector({ form }, "modelData.location.weather.region") || {};

    const agFloorAreaTotalValue = selector({ form }, "modelData.dimensions.aboveGradeIntFloorArea.total") || 0;
    const bgFloorAreaTotalValue = selector({ form }, "modelData.dimensions.belowGradeIntFloorArea.total") || 0;

    //Get reference House key if ORH mode
    const { [selectedPackage]: { resultsData = {} } = {} } = packageResults || {};

    let refHouseKey = "ersReference"; //If run in ERS mode this will be the key
    let onrhTitle = "ERS Ref.";
    Object.keys(resultsData).forEach((key) => {
        if (key.includes("onrh") && key.includes("BaseCase")) {
            refHouseKey = key;
            onrhTitle = key.split("onrh")[1].split("BaseCase")[0].toUpperCase();
            onrhTitle = onrhTitle.includes("Z") ? onrhTitle.split("Z").join(" Z") : onrhTitle;
        }
    });

    return {
        packageResultsData: packageResults,
        resultsPackages: cliId,
        packageInfo: packages,
        selectedPackage,
        selectedCase,
        refHouseKey,
        onrhTitle,
        weatherProvTerrId,
        totalFloorArea: agFloorAreaTotalValue + bgFloorAreaTotalValue,
    };
};

const mapDispatchToProps = (dispatch) => ({
    changePackage: (pkg) => dispatch(changePackage(pkg)),
    changeCase: (cse) => dispatch(changeCase(cse)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EnerGuideResults);
