import { connect } from "react-redux";
import moment from "moment";

import { mixpanel } from "components/Mixpanel";

import { generateDrawing } from "../_ducks/thunk";

import Upload from "./";

const mapStateToProps = ({
    model: {
        modelId,
        fileUpload: {
            isUploading = false,
            progress = 0,
            success = false,
            uploadSuccess = false,
            isGenerating = false,
            error = "",
        } = {},
    },
    user: { uid },
}) => ({
    modelId,
    uid,
    isUploading,
    isGenerating,
    progress,
    success,
    uploadSuccess,
    error,
});

const mapDispatchToProps = (dispatch) => ({
    generateImages: async ({ modelId, uid, file }) => {
        const fileName = `Drawing-${moment().format("YYYYMMDDHHmmssSS")}`;
        await dispatch(generateDrawing({ modelId, uid, file, fileName }));
        mixpanel.track("Drawing Upload");
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(Upload);
