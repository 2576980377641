import React from "react";
import SectionHeading from "components/SectionHeading";
import classes from "../style.module.scss";

import SettingsIcon from "assets/images/icons/JSX/Settings";

export default () => (
    <div>
        <SectionHeading title="Product Updates" subtitle="Check here for the latest updates and new releases." />
        <div className={classes.contentWrapper}>
            <div className={classes.row}>
                <h3>Update 0.2.5</h3>
                <p>
                    Added in-app "Organizations" with file and upgrade package sharing, detailed costing inputs and
                    more:
                </p>
                <ul>
                    <li>
                        Added in-app organizations and supporting capabilities.
                        <ul>
                            <li>
                                Request an organization for yourself and your colleagues via the request button on the
                                main dashboard.
                            </li>
                            <li>Share files with read & write permissions with colleagues within your organization.</li>
                            <li>
                                Save upgrade packages to your organization, allowing your colleagues to access a shared
                                library of upgrade packages. Saved packages can be used for a variety of purposes. For
                                example, a net zero package for a given climate zone, or a code package used in the
                                upgrades table for comparison purposes.
                            </li>
                            <li>
                                Non-file owners can choose to remove shared files from their dashboard, while the file
                                owner must delete its data.
                            </li>
                            <li>
                                Shared files support simultaneous editing by different users. Users are notified when
                                changes have been made to their file since it was last loaded and are provided with the
                                option to either merge their changes or discard their changes and resync the file with
                                the database.
                            </li>
                        </ul>
                    </li>

                    <li>
                        Added detailed costing and financial inputs for upgrades.
                        <ul>
                            <li>
                                When adding any type of upgrade, use the "Upgrade Costs" section at the bottom of the
                                drawer to enter different cost line items associated with that upgrade measure. Users
                                have the flexibility to define costs based on a variety of line item options.
                            </li>
                            <ul>
                                <li>
                                    Line items allow the entry of "bulk" costs (e.g. total material cost, total labour
                                    cost, overall cost), cost per unit (e.g. cost per square foot of component), tax,
                                    and rebates.
                                </li>
                            </ul>
                            <li>
                                When these costs are saved as a part of an upgrade package, those tied to the model
                                (e.g. cost per square foot of attic) will update accordingly when applied to a new
                                model.
                            </li>
                            <li>
                                Added inputs for Grants, Loans, and Financial Valuation Parameters to the upgrades
                                table.
                            </li>
                            <li>
                                Added Total Cost of Ownership and NPV metrics to the results section of the upgrades
                                table.
                            </li>
                        </ul>
                    </li>

                    <li>
                        Added a new method of flagging model errors.
                        <ul>
                            <li>
                                Users can now flag a model with an error for review by our developers via the Model
                                Settings drawer: <SettingsIcon />
                            </li>
                            <li>
                                This allows all our developers to group together and triage review requests, adding
                                redundancy and removing potential bottlenecks.
                            </li>
                        </ul>
                    </li>

                    <li>
                        Added additional building code (NBC) metrics to the upgrades table.
                        <ul>
                            <li>
                                For NBC 2020 performance compliance, the upgrade table now displays the achieved
                                performance tier as well as the metrics used to determine compliance (overall percent
                                improvement, heat loss reduction, and peak cooling validation).
                            </li>
                            <li>
                                Includes operational carbon metrics to be included in the 2025 NBC (PCF 2004), including
                                the proposed operational emissions and resulting compliance level.
                            </li>
                            <li>
                                Includes compliance tier achieved under the Energy Use Intensity compliance path to be
                                included in the 2025 NBC (PCF 1869).
                            </li>
                        </ul>
                    </li>

                    <li>
                        Import blower door test data files (.bld) to automatically populate the air leakage test data
                        section of the file.
                        <ul>
                            <li>Added air leakage test data to the DCF export.</li>
                        </ul>
                    </li>
                    <li>
                        Allow users to input NLR targets to calculate the resulting ACH and include NLR in the upgrade
                        table label when specified.
                    </li>
                    <li>
                        Added the image gallery.
                        <ul>
                            <li>
                                Allows users to upload photos of their home assessment and tag them based on a variety
                                of pre-configured and custom tags
                            </li>
                            <li>
                                Helps users track photos required by ERS procedures based on the content of their file.
                            </li>
                            <li>
                                Supports the forthcoming integration of the mobile app, allowing photos taken on a
                                user's mobile device to be uploaded with the rest of their assessment data.
                            </li>
                            <li>Supports case study creation for CHBA Net Zero Renovation projects.</li>
                        </ul>
                    </li>
                    <li>
                        Background work and first "community" prototype for a subset of users (CHBA Net Zero Renovation
                        projects).
                        <ul>
                            <li>Multi-model summary screen with filters</li>
                            <li>Multi-model results summary screen with filters.</li>
                        </ul>
                    </li>

                    <li>
                        Other bug fixes and improvements:
                        <ul>
                            <li>
                                A double click is now required to close the upgrade drawer to prevent unintentional
                                discarding of changes without saving.
                            </li>
                            <li>
                                Updated the UI around upgrade drawer confirmation buttons so that they are always
                                visible.
                            </li>
                            <li>Updated tab UI to make the selected tab more clear.</li>
                            <li>Added the ability to delete multiple files at once from the main dashboard.</li>
                            <li>
                                Added lazy loading for drop-down menus to prevent performance issues around components
                                and "Type" fields. Component accordions and type fields should no longer lag when
                                opening for users with large code libraries.
                            </li>
                            <li>Added a 404 page.</li>
                        </ul>
                    </li>
                </ul>
            </div>

            <div className={classes.row}>
                <h3>Update 0.2.4</h3>
                <p>
                    Refactored the Drawing Capture Tool, added the ability to type equations in fields, link the upgrade
                    table to other models, and more:
                </p>
                <ul>
                    <li>
                        Refactored the Drawing Capture Tool.
                        <ul>
                            <li>
                                Completely rebuilt the drawing tool to refine functionality and increase performance.
                                Any existing drawings will still be present in models, but shapes will need to be
                                rebuilt (linked HOT2000 components are unaffected).
                            </li>
                            <li>
                                Generalized available shapes and added the "Multi-point Line" tool to handle partial
                                perimeters.
                            </li>
                            <li>
                                Hold "s" and click when drawing shapes to snap the point to the nearest indicated line.
                            </li>
                            <li>
                                With a shape selected, hold Shift and click near a line to add a new point to an
                                existing shape, or hold Shift and click a point to delete it from the shape.
                            </li>
                            <li>Hold "i" to show all shape information at once on the screen.</li>
                            <li>
                                Improved the flow around setting the scale of the drawing. Scales can now be applied to
                                multiple pages at once.
                            </li>
                            <li>
                                View drawing pages individually and switch between pages via their thumbnails in the
                                left-hand navigation pane.
                            </li>
                            <li>Scroll zoom.</li>
                            <li>
                                Measurements from the drawing tool are now decoupled from HOT2000 components.
                                Measurements pass in one direction from the Drawing Capture Tool to Takeoff fields to
                                HOT2000 components. Measurements acquired at one stage no longer "lock" measurements at
                                a subsequent stage.
                            </li>
                        </ul>
                    </li>
                    <li>
                        Added the ability to type equations directly into fields.
                        <ul>
                            <li>
                                Added the ability to enter numeric values via equation inputs directly into fields by
                                beginning typing with the equals (=) symbol.
                            </li>
                            <li>
                                Supported operations include addition (+), subtraction (-), multiplication (*), division
                                (/), and exponents (^), and the order of operations can be controlled with brackets.
                            </li>
                            <li>
                                This capability is currently only applied to measurement fields in the enclosure tab. If
                                you believe any fields should have this ability added, let us know.
                            </li>
                        </ul>
                    </li>
                    <li>
                        Added the ability to link a separate model as the base case in the upgrades table ("linked base
                        case").
                        <ul>
                            <li>
                                Select a different model to act as the base case/existing home in the upgrades table to
                                compare results for retrofits where housing geometry changes.
                            </li>
                            <li>
                                Pre-retrofit files should be selected as the linked base case for post-retrofit files.
                            </li>
                            <li>The selected linked base case model must contain a complete set of results.</li>
                        </ul>
                    </li>
                    <li>
                        Create HOT2000 window components by pasting tabular window data.
                        <ul>
                            <li>
                                This feature is now available when entering data in HOT2000 mode by clicking "..." on a
                                component header followed by Window Table in the add components section of the
                                drop-down.
                            </li>
                        </ul>
                    </li>
                    <li>
                        Utility bill inputs.
                        <ul>
                            <li>Allows entering of pre- and post-retrofit utility bills in the Review tab.</li>
                            <li>
                                Supports future features aimed at estimating real-world consumption characteristics.
                            </li>
                        </ul>
                    </li>
                    <li>
                        Added the ability to export .h2k files with upgrades applied to the base file and/or include
                        results in the exported file.
                    </li>
                    <li>
                        Added NLR and NLA metrics to the Airtightness tab, along with the calculated building enclosure
                        area used to perform these calculations.
                    </li>
                    <li>
                        CSA F280-12 report format updates to better align with industry and submission requirements.
                    </li>
                    <li>
                        CHBA-specific features:
                        <ul>
                            <li>CHBA Net Zero Renovation project roadmap.</li>
                            <li>Indoor air quality section added to Net Zero Workbook.</li>
                        </ul>
                    </li>
                    <li>
                        General bug fixes and improvements, including:
                        <ul>
                            <li>
                                Fixed issue that would result in imperial units sometimes rounding by very small
                                amounts.
                            </li>
                            <li>
                                Fixed a bug that would sometimes accidentally delete code information from a model,
                                resulting in the creation of corrupt h2k files.
                            </li>
                            <li>
                                Added warnings to catch potential simulation issues when a basement's subcomponent area
                                exceeds its pony wall area, if applicable.
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>

            <div className={classes.row}>
                <h3>Update 0.2.3</h3>
                <p>
                    Added the upgrade package library, house rotation, HOT2000 v11.12 support, achieved CSA F280-12
                    compliance, and more:
                </p>
                <ul>
                    <li>
                        Added support for HOT2000 v11.12.
                        <ul>
                            <li>
                                Version 11.11 and 11.12 files can now be simulated using our more robust HOT2000
                                simulation engine service, which is less prone to errors and experiences less downtime.
                            </li>
                        </ul>
                    </li>

                    <li>
                        Save upgrades and apply them to any house file through saved upgrade packages.
                        <ul>
                            <li>
                                Save upgrade packages from within a model by clicking "..." next to the upgrade package
                                name.
                            </li>
                            <li>
                                Apply saved upgrade packages to another file through the Add New Package dialog box.
                            </li>
                            <li>
                                Manage your saved upgrades from the Upgrade Library, accessible in the left-hand
                                navigation bar.
                            </li>
                        </ul>
                    </li>

                    <li>
                        Added new metrics to the Upgrades Table.
                        <ul>
                            <li>
                                For simulations in ERS mode, click the drop-down menu in the simulation row divider
                                (next to "All Simulation Results") to view the energy, emissions, or cost-saving
                                contribution of each individual upgrade.
                            </li>
                            <li>
                                Added the following metrics to the simulation section: Gross Heat Loss Improvement, EUI,
                                TEDI, MEUI.
                            </li>
                        </ul>
                    </li>

                    <li>
                        Added the ability to rotate all of the windows on your home via the Rotate Home button in the
                        Enclosure Tab.
                    </li>

                    <li>
                        Added the ability to apply any code to a subset of HOT2000 components.
                        <ul>
                            <li>
                                When creating or editing a code via the code drawer, use the new multi-select drop-down
                                menu to apply the code to multiple components.
                            </li>
                        </ul>
                    </li>

                    <li>
                        Minor adjustments to the CSA F280-12 heat loss and heat gain calculation to achieve whole-home
                        calculation verification.
                    </li>

                    <li>
                        Added CHBA Net Zero project-specific features.
                        <ul>
                            <li>File tagging and sharing with CHBA Project Administrators.</li>
                        </ul>
                    </li>
                </ul>
            </div>

            <div className={classes.row}>
                <h3>Update 0.2.2</h3>
                <p>
                    Added a data collection form (DCF) export, F280 summary page and report export, tabular window
                    inputs, and collapsable side bar:
                </p>
                <ul>
                    <li>Data collection forms (.xlsx) can now be exported in the Review / Export to H2k tab.</li>
                    <ul>
                        <li>Blower door test data not yet supported.</li>
                    </ul>

                    <li>
                        View a dedicated summary page for CSA F280-12 calculations in the Review tab.
                        <ul>
                            <li>Toggle the upgrade package to include in the summary view.</li>
                            <li>
                                View heat loss / gain by building component, and see the calculated effective R-value
                                used in the calculation.
                            </li>
                            <li>Download a PDF summary report.</li>
                        </ul>
                    </li>

                    <li>
                        Set the data entry mode to Takeoff or Combined and copy and paste tabular window data (TSV) from
                        excel to automatically extract all window measurements.
                    </li>
                    <ul>
                        <li>
                            Each window should be represented by a row and column headers and units should match the
                            specifications in the window table input drawer.
                        </li>
                        <li>Choose whether to overwrite or append to windows that may already be specified.</li>
                        <li>
                            Click "Convert Takeoff" to apply windows to the main wall associated with a given storey.
                        </li>
                    </ul>
                    <li>Collapse the side navigation bar to utilize more space on smaller screens.</li>
                </ul>
            </div>

            <div className={classes.row}>
                <h3>Update 0.2.1</h3>
                <p>Dashboard folders, duplicate models, and CHBA Net Zero Renovation project-specific support:</p>
                <ul>
                    <li>Added folders to the house model dashboard:</li>
                    <ul>
                        <li>Create, name, move, and delete folders.</li>
                        <li>Move models into folders, and duplicate models.</li>
                        <li>
                            When deleting a folder, all of its contents will be moved to the root level model directory.
                        </li>
                    </ul>

                    <li>
                        Added the CHBA Net Zero Project Registration Workbook to the review tab (only accessible to CHBA
                        Net Zero qualified EAs).
                    </li>

                    <li>Other improvements include:</li>
                    <ul>
                        <li>
                            Modified the CSV download for hourly calculations to separate electricity consumption by
                            end-use (heating, cooling, hot water, ventilation, base loads, generation).
                        </li>
                        <li>
                            Fixed a bug in the hourly calculation procedure that could inadvertently lead to
                            overestimating hourly electrical heat pump loads.
                        </li>
                        <li>
                            Fixed a bug that caused the total energy consumption display to not properly show the net
                            annual energy consumption for net zero cases that are not all-electric.
                        </li>
                        <li>
                            Added PV production (GJ and kWh) rows to the Upgrades table and displaying gross electricity
                            consumption in the Fuel Consumption and Production section of the Upgrades table for greater
                            transparency.
                        </li>
                        <li>
                            Added the ability to override indoor set points for F280-12 design heat loss and gain
                            calculations. Note that using temperature set point overrides will produce results that are
                            not compliant with CSA F280-12. This feature should only be used for exploratory purposes at
                            the discretion of an experienced energy advisor.
                        </li>
                    </ul>
                </ul>
            </div>

            <div className={classes.row}>
                <h3>Update 0.2.0</h3>
                <p>New geometry inputs, model defaults, and CSA F280-12 whole-home calculations:</p>
                <ul>
                    <li>Added new geometry "Takeoff" inputs:</li>
                    <ul>
                        <li>Enter geometry information in a simpler manner than directly into HOT2000 components.</li>
                        <li>
                            Switch between three view/data entry modes:
                            <ul>
                                <li>HOT2000: View only HOT2000 components</li>
                                <li>Takeoff: View only Takeoff fields and components</li>
                                <li>Combined: View both HOT2000 and Takeoff components together</li>
                            </ul>
                        </li>
                        <li>
                            Automatically generate HOT2000 components from Takeoff inputs by clicking the "Convert
                            Takeoff" button, and automatically calculate heated floor areas, house volume, and the
                            height of highest ceiling.
                        </li>
                        <li>
                            Add "Takeoff components" to account for complex geometries and associated surface areas and
                            volumes, such as cathedral and sloped ceilings or buffered walls.{" "}
                        </li>
                        <li>
                            Add windows via simplified table inputs. When naming your window, use the format
                            "windowname_SE" to define the facing direction as South East.
                        </li>
                    </ul>

                    <li>
                        Added "Model Default" inputs, accessible via the Model Settings (gear) icon: <SettingsIcon />
                    </li>
                    <ul>
                        <li>
                            Define default component construction types for any HOT2000 component type. When creating
                            new components (either by creating a HOT2000 component from scratch, or clicking "Convert
                            Takeoff"), they will be pre-populated with the default construction type.
                        </li>
                    </ul>

                    <li>Added CSA F280-12 heat loss and heat gain calculations (BETA):</li>
                    <ul>
                        <li>
                            Through the Upgrades table interface, view the CSA F280-12-compliant design heat loss and
                            heat gain for a home as built or designed, and compare changes to peak loads across
                            different upgrade packages.
                        </li>
                        <li>
                            The calculation procedure is supported by an effective R-value calculator in the back-end
                            that calculates component effective R-value compliant with both CSA F280-12 and the 2020
                            National Building Code.
                        </li>
                        <li>
                            At the time of release (April 2023), the official software validation procedure was not yet
                            published. However, the calculation performed in Volta SNAP has been validated against the
                            preliminary test cases and was shown to be compliant.
                        </li>
                    </ul>

                    <li>Other improvements include:</li>
                    <ul>
                        <li>Export the Upgrades table in PDF format (ledger or two 8.5" x 11" pages).</li>
                        <li>The ability to change passwords in-app.</li>
                        <li>General bug fixes and improvements.</li>
                    </ul>
                </ul>
            </div>

            <div className={classes.row}>
                <h3>Update 0.1.9</h3>
                <p>Added upgrade package comparison table and hourly load calculations. Major changes include:</p>
                <ul>
                    <li>Added upgrade package table:</li>
                    <ul>
                        <li>
                            Define upgrades for up to 10 "packages"/"pathways" in one place and quickly compare them
                            through the new table view.
                        </li>
                        <li>
                            Enter your own upgrade label or use an automatically generated label to identify upgrades.
                        </li>
                        <li>Simulate directly in the upgrades table to directly compare results.</li>
                        <li>
                            Optionally include costs for each upgrade line item and view operational costs and simple
                            payback by filling in local fuel costs in the General tab.
                        </li>
                        <li>
                            Hover over a row label and click the eye icon to select rows to hide, and click the "Hide
                            Rows"/"Show Rows" button at the top of the table to toggle hidden rows on and off.
                        </li>
                    </ul>
                    <li>Perform hourly electrical load calculations:</li>
                    <ul>
                        <li>
                            Estimate hourly electrical loads for simulations run on homes in British Columbia, Alberta,
                            Ontario, and Nova Scotia (remaining Provinces and Territories coming soon).
                        </li>
                        <li>
                            Added the ability to specify performance curve data (COP vs ambient temperature) for air
                            source heat pumps to produce more accurate hourly electrical load estimates.
                        </li>
                        <li>
                            Calculate electrical utility costs using time-of-use (TOU) and/or tiered pricing where
                            applicable. Compare the results of both for jurisdictions that allow the choice between TOU
                            and tiered pricing.
                        </li>
                        <li>
                            Estimate hourly emissions from electricity consumption in Ontario using{" "}
                            <a rel="noopener noreferrer" href="https://www.ieso.ca/power-data" target="_blank">
                                IESO grid generation data
                            </a>
                            . Select between emission factors (life cycle or fossil fuel-only) for the last 10 years.
                        </li>
                        <li>
                            Download a CSV file of hourly loads of emissions (where applicable) to perform your own
                            analysis offline.
                        </li>
                    </ul>

                    <li>Added user preferences:</li>
                    <ul>
                        <li>Click your name in the upper left to access user preferences.</li>
                        <li>Enter values to be automatically applied to every new model you create.</li>
                    </ul>

                    <li>General quality of life improvements:</li>
                    <ul>
                        <li>
                            Tab between fields (including drop-down menus) and use arrow keys/enter to select values.
                            Note that arrow keys cannot be used in drop-down menus with search functionality, but these
                            fields can still be accessed via tab.
                        </li>
                        <li>
                            Added default component names, and allow users to press enter to close dialogue boxes (for
                            example, when naming components).
                        </li>
                        <li>
                            Changed "edit code" behaviour to mirror HOT2000. Editing a code library (favourite) code
                            will update all instances of that code in the active model. Editing a non-code library code
                            will now only modify the code in the component targeted.
                        </li>
                        <li>
                            Added building code selection in the General tab to facilitate future improvements and
                            features that tie into building code requirements.
                        </li>
                        <li>
                            Added minimum ventilation rate field to the Enclosure tab. The value is based on the number
                            of bedrooms and the selected building code.
                        </li>
                        <li>Fixed a bug where some fields wouldn't show errors when invalid.</li>
                    </ul>
                </ul>
            </div>
            <div className={classes.row}>
                <h3>Update 0.1.8</h3>
                <p>Added temporary solution to print all inputs:</p>
                <ul>
                    <li>Modified page styles to remove the side and top bar when printing webpage (crtl+P or ⌘+P).</li>
                    <li>
                        Added open/close button to expand or condense all components within tabs to print content within
                        each tab. Stacks subtabs (Mechanical tab) and expands all accordion components (General,
                        Enclosure, Airtightness tabs).
                        <ul>
                            <li>
                                Note that due to the amount of content that must be rendered at once, browsers will
                                likely experience performance issues when expanding/opening all components, particularly
                                in the Enclosure tab. If the page appears frozen, the browser is working on rendering
                                all of the content, and the open/close button should not be repeatedly toggled.
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div className={classes.row}>
                <h3>Update 0.1.7</h3>
                <p>Added simulations and upgrades. Major changes include:</p>
                <ul>
                    <li>
                        In-app HOT2000 simulations:
                        <ul>
                            <li>
                                Simulations can be run in General, ERS, and ORH modes. For ERS and ORH modes, users must
                                confirm that they are certified energy advisors who are permitted to run the energy
                                advisor version of HOT2000.
                            </li>
                            <li>
                                Multiple upgrade packages can be simulated at once (max 10), and results between
                                packages and operating conditions can be toggled via drop-down selections.
                            </li>
                            <li>
                                Please note that the stability and response time of the h2k-cloud simulation engine is
                                continuously being improved. We will continue to roll out enhanced error messaging
                                around in-app simulations.
                            </li>
                            <li>When exporting a file, results are not appended (in development).</li>
                            <li>Simulations can only be run for version 11.11 files and onward.</li>
                        </ul>
                    </li>
                    <li>
                        Upgrades and upgrade packages:
                        <ul>
                            <li>
                                Upgrade packages allow users to model multiple sets of upgrades that are not possible
                                within a single .h2k file, allowing users to analyze performance associated with various
                                changes to the same component or system type. This will enable upgrades to be
                                investigated without making multiple copies of an h2k file.
                            </li>
                            <li>Files can be exported with a single upgrade package included.</li>
                        </ul>
                    </li>
                    <li>
                        Tab navigation changes:
                        <ul>
                            <li>Replaced the Additional tab with the Upgrades tab.</li>
                            <li>Moved the Temperature and Base Loads sub-tabs to the Mechanicals tab. </li>
                            <li>Moved the Program and Information sub-tabs to the Review Tab.</li>
                        </ul>
                    </li>
                    <li>Added a variety of QA warnings throughout both the front- and back-end of the app.</li>
                    <li>
                        Added the basement composite R-value calculator drawer, accessed through the pencil icon when
                        foundation wall fields are set to User Specified.
                    </li>
                    <li>
                        Added a drawer to select foundation insulation configuration options for all foundation types.
                    </li>
                </ul>
            </div>
            <div className={classes.row}>
                <h3>Update 0.1.6</h3>
                <p>General bug fixes and improvements, including:</p>
                <ul>
                    <li>Implemented login session timeout.</li>
                    <li>
                        Fixed decimal inconsistency with AC efficiency field, updated heat pump water heater default EFs
                        to comply with workaround.
                    </li>
                    <li>Modified underlying drawing functions to improve app speed.</li>
                    <li>Thicker line hitbox for selecting and dragging lines and scales.</li>
                    <li>
                        Added "active" and "inactive" shapes, where active shapes are indicated by the presence of
                        anchor points.
                    </li>
                    <li>
                        Shapes must now be activated (single click) before they can be dragged or resized to prevent
                        unintentionally altering shapes when dragging throughout the canvas.
                    </li>
                    <li>
                        Added initial "snapping" functionality when holding the Shift key:
                        <ul>
                            <li>
                                Holding shift while dragging a polygon anchor will align it vertically or horizontally
                                to the nearest point(s), which can be used to achieve right angles in polygons.
                            </li>
                            <li>
                                Holding shift while dragging a line or scale anchor point will snap the line to the
                                nearest 15˚ increment (0˚, 15˚, 30˚, 45˚, 60˚, 75˚, 90˚) in all four circle quadrants.
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div className={classes.row}>
                <h3>Update 0.1.5</h3>
                <p>
                    Added User Defined codes, the Code Library, and refactored overall data structure. Major changes
                    include:
                </p>
                <ul>
                    <li>
                        Added the Code Library. Users can now upload and manage their building assembly codes. When
                        importing a new or more recent code library, older codes within Volta SNAP will be overwritten
                        with newer incoming codes based on matching code labels.
                    </li>
                    <li>
                        Added User Defined codes. Users can now create more detailed codes directly in a model or from
                        the code library.
                    </li>
                    <li>
                        Refactored the way "type" fields (e.g. wall type, window type) are stored in the data structure.
                        This will make the way that data is passed to and from HOT2000 more robust and minimize display
                        issues causing "type" fields to sometimes appear empty after importing a file.
                    </li>
                    <li>Clarified language around nominal and effective R-Values.</li>
                    <li>
                        Added "Number of Identical Windows" fields to all windows, including in the drawing. Note that
                        changing this number does not create another drawing element.
                    </li>
                    <li>Updated window to wall ratio calculation to properly account for duplicate windows.</li>
                    <li>Fixed a bug that sometimes caused importing files with certain types of upgrades to fail.</li>
                    <li>
                        Fixed an issue that prevented User Specified R-values from displaying in the code compliance
                        table.
                    </li>
                    <li>
                        Fixed bugs associated with the "Depressurization Limit" and "Depressurization Test Status"
                        fields that could cause them to import and export improperly.
                    </li>
                    <li>
                        Modified the review table to display DHW COPs when a heat pump is modelled. Note this only
                        applies when "Heat Pump" or "Integrated Heat Pump" is selected under "Tank Type", since
                        "Tankless Heat Pumps" use EF as their efficiency input.
                    </li>
                    <li>
                        Added more robust error notices directly on fields and when exporting files, highlighting issues
                        that will produce calculation errors.
                    </li>
                    <li>
                        Changed compressed insulation warnings to highlight code fields with an exclamation rather than
                        produce a pop-up.
                    </li>
                    <li>
                        Added a warning when a user clicks to exit a model without saving (e.g. when the user clicks to
                        go to the dashboard).
                    </li>
                </ul>
            </div>
            <div className={classes.row}>
                <h3>Update 0.1.4</h3>
                <p>Added HOT2000 Version 11.11 Compatibility. Major changes include:</p>
                <ul>
                    <li>Greener Homes fields added to Program section.</li>
                    <li>Changed "Unguarded" to "Guarded" in Airtightness section.</li>
                </ul>
            </div>
            <div className={classes.row}>
                <h3>Update 0.1.3</h3>
                <p>General bug fixes and improvements, including:</p>
                <ul>
                    <li>Fixed a bug that sometimes caused polygons and their components to duplicate.</li>
                    <li>
                        Fixed a bug that would prevent the Actions pane from properly clearing, making it appear as
                        though a polygon's information was lost.
                    </li>
                    <li>Fixed a bug that could cause unassigned scales to become inaccessible.</li>
                    <li>
                        Fixed a bug that caused ceiling R-values for the following three Framing Cavity Insulation Layer
                        options to underestimate nominal R-values:
                        <ul>
                            <li>RSI 7.04 @ 300 mm (R 40 @ 11.8") batt</li>
                            <li>RSI 6.16 @ 267 mm (R 35 @ 10.5") batt</li>
                            <li>RSI 10.57 (R 60) Blown cellulose</li>
                        </ul>
                    </li>
                    <li>
                        Added the option "8 / 12" to the ceiling slope field. Note that in HOT2000, this will be
                        represented as a User Specified field with a value of 0.667.
                    </li>
                    <li>Converted Weather Region and Weather Location into searchable fields.</li>
                    <li>
                        Updated floor headers' default perimeter to be equal to the perimeter of the parent component.
                    </li>
                    <li>
                        Adjusted values shown in enclosure component collapsed views and added tooltips (hover state
                        information).
                    </li>
                    <li>Added collapsed view information to all foundation components.</li>
                    <li>Changed Presence of Vermiculite field to No Vermiculite by default.</li>
                    <li>Fixed a bug that would sometimes cause lintel codes to produce corrupt files.</li>
                </ul>
            </div>
            <div className={classes.row}>
                <h3>Update 0.1.2</h3>
                <p>General bug fixes and improvements, including:</p>
                <ul>
                    <li>
                        Removed the need to "Set" drawing components after making changes. Note that you still have to
                        click "Set Anchors" when you have finished creating a polygon component, and you still have to
                        save your drawing before exiting.
                    </li>
                    <li>Clicking "Page" in the elements pane now moves the view to that page of the drawing.</li>
                    <li>Minor iconography updates.</li>
                    <li>
                        Fixed a bug that sometimes caused the "Window Type" field in the drawing view to appear blank.
                    </li>
                    <li>Fixed a bug that sometimes caused duplicate window components to appear.</li>
                    <li>Fixed a bug that caused the polygon tool to toggle off after every anchor drop.</li>
                </ul>
            </div>
            <div className={classes.row}>
                <h3>Update 0.1.1</h3>
                <p>General bug fixes and improvements, including:</p>
                <ul>
                    <li>
                        Drawing close button now warns users before exiting to prevent unintentionally exiting the
                        drawing capture tool without saving changes.
                    </li>
                    <li>
                        Drawing tools now toggle off after each use to prevent users from unintentionally adding
                        multiple lines, windows, etc.
                    </li>
                    <li>
                        Default building components (Basement - 1, Ceiling - 1, Wall - 1, Floor - 1) are no longer
                        automatically populated when creating a new file.
                    </li>
                    <li>
                        Airtightness tab updates: wording altered for clarity, window airtightness fields moved to
                        "Other Factors" accordion, depressurization test status now allows inputs below 0.
                    </li>
                    <li>
                        Fixed an issue that would cause some window code fields (fill type and spacer type) to reset to
                        their default values when editing a window code.
                    </li>
                    <li>
                        Decimal requirements for some fields altered to be consistent with value imported from HOT2000.
                    </li>
                </ul>
            </div>
            <div className={classes.row}>
                <h3>Update 0.1.0</h3>
                <p>
                    This is our first live deployment, providing access to our project partners for user testing. This
                    deployment includes:
                </p>
                <ul>
                    <li>The core modelling interface</li>
                    <li>The ability to import and export .h2k files</li>
                    <li>The Drawing Capture tool</li>
                    <li>Compatibility with HOT2000 versions 11.9 and 11.10</li>
                    <li>Calculator drawers for heated floor areas and house volume</li>
                    <li>The integration of the Energy Star database for applicable mechanical systems</li>
                    <li>The Ontario Building Code compliance summary table</li>
                </ul>
            </div>
        </div>
    </div>
);
