import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Layout from './';

const mapStateToProps = ({}, { history } ) => ({
    history,
});
const mapDispatchToProps = () => ({});

export default compose(
    withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(Layout);