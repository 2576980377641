export const tagsTypes = {
    CHBA_NZ: {
        name: "CHBA NZ",
        color: "#68A324",
    },
    CHBA_NZ_READY: {
        name: "CHBA NZ Ready",
        color: "#68A324",
    },
    CHBA_NZ_RENO: {
        name: "CHBA NZ Reno",
        color: "#038851",
    },
    CHBA_NZ_READY_RENO: {
        name: "CHBA NZ Ready Reno",
        color: "#038851",
    },
    RETROFIT_BATCH: {
        name: "Retrofit",
        color: "#038851",
    },
    UNTAGGED: {
        name: "Untagged",
        color: "#646f81",
    },
    NL: { name: "NL", color: "#646f81" },
    PE: { name: "PE", color: "#646f81" },
    NS: { name: "NS", color: "#646f81" },
    NB: { name: "NB", color: "#646f81" },
    QC: { name: "QC", color: "#646f81" },
    ON: { name: "ON", color: "#646f81" },
    MB: { name: "MB", color: "#646f81" },
    SK: { name: "SK", color: "#646f81" },
    AB: { name: "AB", color: "#646f81" },
    BC: { name: "BC", color: "#646f81" },
    YT: { name: "YT", color: "#646f81" },
    NT: { name: "NT", color: "#646f81" },
    NU: { name: "NU", color: "#646f81" },
    MUN: { name: "MUN", color: "#D95328" },
    SO: { name: "SO", color: "#003BA0" },
    REA: { name: "REA", color: "#038851" },
    RENO: { name: "RENO", color: "#22A495" },
    HOM: { name: "HOM", color: "#68A324" },
    NZEA: {name: "NZEA", color: "#a324a3"}
};
