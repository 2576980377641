import { capitalize } from "utils";
// Icons
import WallIcon from "assets/images/components/Wall.svg";
import ExpFloorIcon from "assets/images/components/ExpFloor.svg";
import CeilingIcon from "assets/images/components/Ceiling.svg";
import BasementIcon from "assets/images/components/Foundation-Basement.svg";
import CrawlspaceIcon from "assets/images/components/Foundation-Crawlspace.svg";
import SlabIcon from "assets/images/components/Foundation-Slab.svg";
import DoorIcon from "assets/images/components/Door.svg";
import WindowIcon from "assets/images/components/Window.svg";
import FloorHeaderIcon from "assets/images/components/FloorHeader.svg";
// Templates
import wallTemplate from "features/Model/Enclosure/Templates/wall.json";
import ceilingTemplate from "features/Model/Enclosure/Templates/ceiling.json";
import crawlspaceTemplate from "features/Model/Enclosure/Templates/crawlspace.json";
import basementTemplate from "features/Model/Enclosure/Templates/basement.json";
import expFloorTemplate from "features/Model/Enclosure/Templates/expFloor.json";
import slabTemplate from "features/Model/Enclosure/Templates/slab.json";
import doorTemplate from "features/Model/Enclosure/Templates/door.json";
import windowTemplate from "features/Model/Enclosure/Templates/window.json";
import floorHeaderTemplate from "features/Model/Enclosure/Templates/floorHeader.json";
import contMedTemplate from "features/Model/Enclosure/Templates/continuousMedium.json";
import contInsTemplate from "features/Model/Enclosure/Templates/continuousInsulation.json";
import woodFramingTemplate from "features/Model/Enclosure/Templates/woodFraming.json";
import steelFramingTemplate from "features/Model/Enclosure/Templates/steelFraming.json";
import strappingTemplate from "features/Model/Enclosure/Templates/strapping.json";
import lintelUDefTemplate from "features/Model/Enclosure/Templates/lintelUDef.json";
import windowUDefTemplate from "features/Model/Enclosure/Templates/windowUDefLayer.json";
import windowLegacyUDefTemplate from "features/Model/Enclosure/Templates/windowLegacyUDefLayer.json";

const storeyFloorOptions = [
    {
        value: "ceiling",
        label: "Ceiling",
    },
    {
        value: "expFloor",
        label: "Exposed Floor",
    },
    {
        value: "wall",
        label: "Wall",
    },
];

const foundationOptions = [
    {
        value: "basement",
        label: "Basement",
    },
    {
        value: "crawlspace",
        label: "Crawlspace",
    },
    {
        value: "slab",
        label: "Slab-on-grade",
    },
    {
        value: "walkout",
        label: "Walkout",
    },
];

const foundation = {
    id: "foundation",
    name: "Foundation",
    componentOptions: foundationOptions,
};

const first = {
    id: "first",
    name: "First Storey",
    componentOptions: storeyFloorOptions,
};

const second = {
    id: "second",
    name: "Second Storey",
    componentOptions: storeyFloorOptions,
};

const other = {
    id: "other",
    name: "Other",
    componentOptions: [...storeyFloorOptions, ...foundationOptions],
};

export const getAllowedCategories = (componentType) =>
    ({
        wall: ["first", "second", "third", "upper", "lower", "other"],
        ceiling: ["first", "second", "third", "upper", "lower", "other"],
        expFloor: ["first", "second", "third", "upper", "lower", "other"],
        basement: ["foundation", "other"],
        crawlspace: ["foundation", "other"],
        slab: ["foundation", "other"],
        door: ["wall", "basement", "crawlspace"],
        window: ["wall", "ceiling", "door", "basement", "crawlspace"],
        floorHeader: ["wall", "basement", "crawlspace"],
    }[componentType]);

export const categories = (id) => {
    if (id === 2 || id === 3) {
        return [foundation, first, second, other];
    }

    if (id === 4) {
        return [
            foundation,
            first,
            second,
            {
                id: "third",
                name: "Third Storey",
                componentOptions: storeyFloorOptions,
            },
            other,
        ];
    }

    if (id === 5) {
        return [
            foundation,
            {
                id: "lower",
                name: "Lower",
                componentOptions: storeyFloorOptions,
            },
            {
                id: "upper",
                name: "Upper",
                componentOptions: storeyFloorOptions,
            },
            other,
        ];
    }

    if (id === 6) {
        return [
            foundation,
            {
                id: "upper",
                name: "Upper",
                componentOptions: storeyFloorOptions,
            },
            other,
        ];
    }

    return [foundation, first, other];
};

export const getComponentName = (id) => {
    switch (id) {
        case "expFloor":
            return "Exposed Floor";
        case "slab":
            return "Slab-on-grade";
        case "floorHeader":
            return "Floor Header";
        default:
            return capitalize(id);
    }
};

export const getComponentIcon = (id) =>
    ({
        wall: WallIcon,
        expFloor: ExpFloorIcon,
        ceiling: CeilingIcon,
        basement: BasementIcon,
        crawlspace: CrawlspaceIcon,
        slab: SlabIcon,
        door: DoorIcon,
        window: WindowIcon,
        floorHeader: FloorHeaderIcon,
    }[id]);

export const getComponentTemplate = (id) =>
    ({
        wall: wallTemplate,
        expFloor: expFloorTemplate,
        ceiling: ceilingTemplate,
        basement: basementTemplate,
        crawlspace: crawlspaceTemplate,
        slab: slabTemplate,
        door: doorTemplate,
        window: windowTemplate,
        floorHeader: floorHeaderTemplate,
    }[id]);

export const getLayerTemplate = (id) =>
    ({
        continuousMedium: contMedTemplate,
        continuousInsulation: contInsTemplate,
        woodFraming: woodFramingTemplate,
        steelFraming: steelFramingTemplate,
        strapping: strappingTemplate,
        lintel: lintelUDefTemplate,
        window: windowUDefTemplate,
        windowLegacy: windowLegacyUDefTemplate,
    }[id]);

export const getComponentSubOptions = (id) =>
    ({
        wall: ["door", "window", "floorHeader"],
        door: ["window"],
        ceiling: ["window"],
        basement: ["door", "window", "floorHeader"],
        crawlspace: ["door", "window", "floorHeader"],
    }[id]);
