import moment from "moment";
import { DashboardAPI } from "utils/api";

export const getCliResults = async ({ cliId, modelId }) => {
    return DashboardAPI({
        method: "GET",
        url: "/getCliResults",
        query: {
            cliId,
            modelId,
        },
    });
};

export const getBatchModelSummary = async ({ modelIds }) => {
    return DashboardAPI({
        method: "POST",
        url: "/getBatchModelSummary",
        data: {
            modelIds,
        },
    });
};

export const sendH2kSimulation = async ({ modelId, userId, packages = [] }) => {
    return DashboardAPI({
        method: "POST",
        url: "/prepareSingleH2kSimulation",
        data: {
            houseModelId: modelId,
            userId,
            packages,
        },
    });
};

export const runH2kAzureSimulation = async ({ modelId, userId, packages = [] }) => {
    return DashboardAPI({
        method: "POST",
        url: "/runH2kAzureSimulation",
        data: `data=${JSON.stringify({
            modelId,
            userId,
            packages,
            callTime: moment().utc().format("YYYYMMDDHHmmssSS"),
        })}`,
        // data: {
        //     modelId,
        //     userId,
        //     packages,
        // },
        contentType: "application/x-www-form-urlencoded",
        preStringified: true,
    });
};

export const getDetailedHourlyResults = async ({ modelId, cliId, resultsCase, upgradePackageId = "" }) => {
    return DashboardAPI({
        method: "GET",
        url: "/getDetailedHourlyResults",
        query: {
            modelId,
            cliId,
            upgradePackageId,
            resultsCase,
        },
    });
};

export const getF280SummaryData = async ({ modelId, cliId, upgradePackageId = "" }) => {
    return DashboardAPI({
        method: "GET",
        url: "/getF280SummaryData",
        query: {
            modelId,
            cliId,
            upgradePackageId,
            returnType: "CSA_F280_D_REPORT",
        },
    });
};
