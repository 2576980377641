import { useState, useEffect, useCallback, useRef } from "react";

/**
 * Match url of pathname and hash in order to have toggle state functionality
 *
 * @param {object} location can use history.location of router
 * @param {string} location.hash Hash of url string to match
 * @param {string} location.pathname Pathname of url string to match
 *
 * @param {string} isOpenTrigger What to match the url string with
 * @param {string} pathMatch What to match the pathnamestring with
 *
 * @return {boolean} toggle state
 *
 * @example
 *
 * const isOpen = useUrlToggle({ pathname, hash }, '#open_trigger', '/urlmatch')
 */

export const useUrlToggle = (location, isOpenTrigger, pathMatch) => {
    const [isOpen, toggleOpen] = useState();

    useEffect(() => {
        if (
            location.hash.split("=")[0] === isOpenTrigger &&
            location.pathname.indexOf(pathMatch) > 0
        ) {
            return toggleOpen(true);
        }

        return toggleOpen(false);
    }, [location, isOpenTrigger, pathMatch]);

    return isOpen;
};

/**
 *
 * @param {Promise} request Request to fetch
 *
 * @return {Array.<{isLoading: Boolean, hasError: Boolean}>} - Returns array with properties isLoading[0] and hasError[1]
 */
export const useFetch = (request) => {
    const [isLoading, toggleLoading] = useState(false);
    const [hasError, toggleError] = useState(false);

    useEffect(() => {
        toggleLoading(true);
        toggleError(false);

        request()
            .catch((e) => {
                toggleError(true);
            })
            .finally(() => {
                toggleLoading(false);
            });
    }, [request]);
    // see if data updates on change

    return [isLoading, hasError];
};

export const useUpdatedRef = () => {
    const [domNode, setNode] = useState(null);

    const onRefChange = useCallback((node) => {
        setNode(node);
    }, []);

    return [domNode, onRefChange];
};

export default function useComponentVisible(initialIsVisible) {
    const [isComponentVisible, setIsComponentVisible] =
        useState(initialIsVisible);
    const ref = useRef(null);

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsComponentVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    return { ref, isComponentVisible, setIsComponentVisible };
}
