import React from 'react';
import classes from './style.module.scss';
import Info from 'assets/images/icons/JSX/info';
import Tooltip from "components/Tooltip";
import uniqid from 'uniqid';

export default ({ info, className }) => {
    const id = uniqid("tooltip-");
    return (
        <div
            className={`${classes.info} ${className && className}`}
            data-html
            data-tip={`<span style="display:block;max-width:14rem;">${info}</span>`}
            data-for={id}
        >
            <Info />
            <Tooltip id={id} />
        </div>
    )
}