import React, { useState } from "react";
import moment from "moment";
import { isEmpty } from "lodash";

import { useOutsideClickHook } from "utils/outsideClick";

import Tooltip from "components/Tooltip";
import Dropdown from "components/Dropdown";
import ModelTags from "components/ModelTags";

import classes from "../style.module.scss";

import ModelIcon from "assets/images/icons/model.svg";
import SharedModelIcon from "assets/images/icons/Shared-model.svg";
import ThreeDots from "assets/images/icons/JSX/ThreeDots";

const Model = ({
    modelId,
    selectModel,
    address,
    name,
    lastEdited,
    startDelete,
    startSharedModelRemove,
    tags = [],
    setModelToMove,
    setModelToDuplicate,
    setDuplicateName,
    sharedWithUser = false,
    setSelectedFolderModel,
    selectedFolderModel,
    multipleModelFolderSelect,
    isSelected,
    ref,
    isDragging,
    isDropInProcess,
    selectedModelsFolders,
    setFolderModelsToDelete,
    disableMultiDelete = false,
    ...props
}) => {
    const [subMenu, toggleSubMenu] = useState(false);

    const subMenuRef = useOutsideClickHook(() => toggleSubMenu(false));

    // const onClick = async (event) => {
    //     event.preventDefault();

    //     if (event.shiftKey) {
    //         multipleModelFolderSelect(event);

    //         return;
    //     }

    //     if (!selectedFolderModel || selectedFolderModel !== modelId) {
    //         setSelectedFolderModel(modelId);

    //         multipleModelFolderSelect(event);
    //     }

    //     if (selectedFolderModel && selectedFolderModel === modelId) {
    //         await selectModel(modelId);

    //         setSelectedFolderModel("");
    //     }
    // };

    return (
        <li
            ref={ref}
            className={`${classes.modelFolder} ${
                selectedFolderModel === modelId || isSelected ? classes.selectedModelFolder : ""
            } ${isDragging && (isSelected || modelId === selectedFolderModel) ? classes.modelFolderDragging : ""} ${
                isDropInProcess ? classes.isDropInProcess : ""
            }`}
            style={{
                padding: !isEmpty(tags) && "0.5rem 1rem",
                pointerEvents: "none",
            }}
            {...props}
        >
            <div className={classes.modelFolderTitle}>
                <img src={sharedWithUser ? SharedModelIcon : ModelIcon} alt="model icon" /> {name || ""}
                {tags.map((tag, i) => (
                    <ModelTags key={i} tag={tag} />
                ))}
            </div>
            <div className={classes.address}>{address}</div>
            <div className={classes.lastEdited}>{moment(lastEdited).format("MMM DD, YYYY")}</div>
            <div className={classes.actions} ref={subMenuRef}>
                {/* <div className={classes.dupe} data-tip="Duplicate Model" data-for={`actions-${modelId}`}>
                <Duplicate />
            </div> */}

                <div
                    onClick={(event) => {
                        event.stopPropagation();
                        toggleSubMenu(!subMenu);
                    }}
                    className={classes.delete}
                    style={{ pointerEvents: "auto" }}
                >
                    <ThreeDots />
                    <Dropdown
                        className={classes.subMenu}
                        open={subMenu}
                        options={[
                            {
                                label: "Duplicate Model",
                                onClick: () => {
                                    setModelToDuplicate(modelId);
                                    setDuplicateName(`${name} (1)`);
                                    toggleSubMenu(false);
                                },
                            },
                            {
                                label: "Move Model",
                                onClick: () => {
                                    setModelToMove(modelId);
                                    toggleSubMenu(false);
                                },
                            },
                            ...(!sharedWithUser
                                ? [
                                      {
                                          label: "Delete Model",
                                          onClick: (e) => {
                                              startDelete(e, modelId);
                                              toggleSubMenu(false);
                                          },
                                      },
                                      {
                                          label: `Delete ${selectedModelsFolders.length} files`,
                                          shouldShow: selectedModelsFolders.length > 1,
                                          disable: disableMultiDelete,
                                          onClick: () => setFolderModelsToDelete(selectedModelsFolders),
                                      },
                                  ]
                                : [
                                      {
                                          label: "Remove Model",
                                          onClick: (e) => {
                                              startSharedModelRemove(e, modelId);
                                              toggleSubMenu(false);
                                          },
                                      },
                                  ]),
                        ]}
                    />
                </div>
            </div>
            <Tooltip id={`actions-${modelId}`} delay />
        </li>
    );
};

export default Model;
