export const getWalkoutGeometry = ({
    totalFloorArea,
    exposedPerimeter, //total walkout perimeter - shared wall perimeter
    totalWallHeight,
    buriedWalkoutDepth = totalWallHeight,
    shallowWalkoutDepth = 0,
    slopedGradeWallLength,
    upperGradeLength = 0,
    walkoutSlabLength = 0,
    totalPonyWallArea = 0,
}) => {
    //All inputs assumed to be in metric
    //Case of a walkout with a party wall is not yet handled
    const walkoutWidth = totalFloorArea / slopedGradeWallLength;

    const netSlopedLength = slopedGradeWallLength - walkoutSlabLength - upperGradeLength;
    const bgWalkoutArea =
        2 *
            (upperGradeLength * buriedWalkoutDepth +
                0.5 * (buriedWalkoutDepth + shallowWalkoutDepth) * netSlopedLength +
                walkoutSlabLength * shallowWalkoutDepth) +
        buriedWalkoutDepth * walkoutWidth +
        shallowWalkoutDepth * walkoutWidth;

    const totAgWalkoutArea = exposedPerimeter * totalWallHeight - bgWalkoutArea; //AG Wall area, incl. pony wall
    const agWalkoutArea = exposedPerimeter * totalWallHeight - bgWalkoutArea - totalPonyWallArea; //AG Concrete wall area

    const slabArea = walkoutWidth * walkoutSlabLength;
    const slabExpPerimeter = walkoutWidth + 2 * walkoutSlabLength;
    const slabPerimeter = 2 * (walkoutWidth + walkoutSlabLength);

    const basementArea = totalFloorArea - slabArea;
    const basementExpPerimeter = walkoutWidth + 2 * (slopedGradeWallLength - walkoutSlabLength);
    const basementPerimeter = 2 * (walkoutWidth + (slopedGradeWallLength - walkoutSlabLength));

    const avgBsmtWallHeight = (exposedPerimeter * totalWallHeight) / basementExpPerimeter; //Need the total height, of the wall which includes the pony wall portion
    const avgBelowGradeDepth = bgWalkoutArea / basementExpPerimeter;

    return {
        agWalkoutArea,
        totAgWalkoutArea,
        bgWalkoutArea,
        avgBsmtWallHeight,
        avgBelowGradeDepth,
        slabArea,
        slabExpPerimeter,
        slabPerimeter,
        basementArea,
        basementExpPerimeter,
        basementPerimeter,
    };
};

export const getTotalPonyWallArea = (ponyWallComponents = []) => {
    return ponyWallComponents.reduce((sum, { height1 = 0, height2 = 0, perimeter = 0 }) => {
        return sum + 0.5 * (height1 + height2) * perimeter;
    }, 0);
};

export const calcBuildingSurfaceArea = (components = {}, includesCrawlspaceVolume = false) => {
    const { basement = {}, slab = {}, crawlspace = {}, wall = {}, ceiling = {}, expFloor = {} } = components || {};

    let totalSurfaceArea = 0;

    Object.values(basement).forEach((comp) => {
        const {
            wall: { measurements: { height = 0 } = {} } = {},
            floor: { measurements: { isRectangular, width, length, area, perimeter } = {} } = {},
            subcomponents: { floorHeader = {} } = {},
        } = comp;
        let floorPerimeter = perimeter;
        let floorArea = area;
        if (isRectangular) {
            floorPerimeter = 2 * (width + length);
            floorArea = width * length;
        }

        const wallArea = height * floorPerimeter;

        totalSurfaceArea = totalSurfaceArea + wallArea + floorArea;

        Object.values(floorHeader).forEach((fhComp) => {
            const { measurements: { height: fhHeight, perimeter: fhPerimeter } = {} } = fhComp;
            totalSurfaceArea = totalSurfaceArea + fhHeight * fhPerimeter;
        });
    });

    if (includesCrawlspaceVolume) {
        Object.values(crawlspace).forEach((comp) => {
            const {
                wall: { measurements: { height = 0 } = {} } = {},
                floor: { measurements: { isRectangular, width, length, area, perimeter } = {} } = {},
                subcomponents: { floorHeader = {} } = {},
            } = comp;
            let floorPerimeter = perimeter;
            let floorArea = area;
            if (isRectangular) {
                floorPerimeter = 2 * (width + length);
                floorArea = width * length;
            }

            const wallArea = height * floorPerimeter;

            totalSurfaceArea = totalSurfaceArea + wallArea + floorArea;

            Object.values(floorHeader).forEach((fhComp) => {
                const { measurements: { height: fhHeight, perimeter: fhPerimeter } = {} } = fhComp;
                totalSurfaceArea = totalSurfaceArea + fhHeight * fhPerimeter;
            });
        });
    }

    Object.values(slab).forEach((comp) => {
        const { floor: { measurements: { isRectangular, width, length, area } = {} } = {} } = comp;
        let floorArea = area;
        if (isRectangular) {
            floorArea = width * length;
        }

        totalSurfaceArea = totalSurfaceArea + floorArea;
    });

    Object.values(wall).forEach((comp) => {
        const { measurements: { height, perimeter } = {}, subcomponents: { floorHeader = {} } = {} } = comp;

        totalSurfaceArea = totalSurfaceArea + height * perimeter;

        Object.values(floorHeader).forEach((fhComp) => {
            const { measurements: { height: fhHeight, perimeter: fhPerimeter } = {} } = fhComp;
            totalSurfaceArea = totalSurfaceArea + fhHeight * fhPerimeter;
        });
    });

    Object.values(ceiling).forEach((comp) => {
        const { measurements: { area } = {} } = comp;

        totalSurfaceArea = totalSurfaceArea + area;
    });

    Object.values(expFloor).forEach((comp) => {
        const { measurements: { area } = {} } = comp;

        totalSurfaceArea = totalSurfaceArea + area;
    });

    return totalSurfaceArea;
};
