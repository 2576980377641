/*
    .doc(`${USERS_COLL}/${uid}`)
    .collection("imageGallery")
 */
import { firestore, MODEL_COLL, storageRef } from "_firebase";

import { getTags } from "features/Model/ImageGallery/utils/functions";
import { mergeArrayOfObjects } from "utils/objects";

import { REQUIRED_TAGS } from "features/Model/ImageGallery/utils/constants";

import { updateChecklistItems } from "features/Model/ProjectPathChecklist/_ducks/actions";

import {
    getImageGalleryStart,
    getImageGallerySuccess,
    getImageGalleryError,
    addImageDataStart,
    addImageDataSuccess,
    addImageDataError,
    deleteImageDataStart,
    deleteImageDataError,
    deleteImageDataSuccess,
    saveImageDataStart,
    saveImageDataSuccess,
    saveImageDataError,
    setTags,
} from "store/imageGallery/actions";

import { chunkArray } from "utils/imageGallery";

export const getImageGallery = (modelId, uid) => async (dispatch) => {
    try {
        dispatch(getImageGalleryStart());

        // Without deep cloning, the original object will be modified and all custom tags will be moved between models
        const deepCloneRequiredTags = JSON.parse(JSON.stringify(REQUIRED_TAGS));

        const listRef = storageRef.child(`${uid}/${modelId}/modelImages`);
        const listRes = await listRef.listAll();
        const filesToCheck = await Promise.all(
            listRes.items.map((item) => {
                return item.getMetadata().then((metadata) => metadata.name);
            })
        );

        // Splitting filesToCheck into chunks of 10 for Firestore 'in' query limitation
        const fileChunks = chunkArray(filesToCheck, 10);
        const docSnapshotPromises = fileChunks.map((chunk) =>
            firestore.doc(`${MODEL_COLL}/${modelId}`).collection("imageGallery").where("id", "in", chunk).get()
        );

        const docSnapshots = await Promise.all(docSnapshotPromises);
        let existingFiles = new Set();
        docSnapshots.forEach((snapshot) => snapshot.forEach((doc) => existingFiles.add(doc.id)));

        let writeBatch = firestore.batch();
        let newFiles = [];

        for (const file of filesToCheck.filter((f) => !existingFiles.has(f))) {
            const fileRefToCheck = listRes.items.find((toCheck) => toCheck.name === file);
            const fileUrl = await fileRefToCheck.getDownloadURL();

            const fileToAdd = {
                uploadedDate: new Date().getTime() / 1000,
                tags: [],
                notes: "",
                status: "",
                name: "",
                id: file,
                fileUrl,
            };

            const newDocRef = firestore.doc(`${MODEL_COLL}/${modelId}`).collection("imageGallery").doc(fileToAdd.id);

            writeBatch.set(newDocRef, fileToAdd);
            newFiles.push(fileToAdd);
        }

        // Commit the batch write
        await writeBatch.commit();

        const returnImagesSnapshot = await firestore.doc(`${MODEL_COLL}/${modelId}`).collection("imageGallery").get();

        const returnImages = returnImagesSnapshot.docs.map((doc) => doc.data());

        const allTags = getTags(returnImages, deepCloneRequiredTags);

        dispatch(setTags(allTags));
        dispatch(getImageGallerySuccess(returnImages));
    } catch (err) {
        console.log(err);
        dispatch(getImageGalleryError(err));
    }
};

export const addImageData = (modelId, uid, imageToAdd) => async (dispatch) => {
    try {
        dispatch(addImageDataStart());

        await firestore
            .doc(`${MODEL_COLL}/${modelId}`)
            .collection("imageGallery")
            .doc(imageToAdd.id)
            .set({ ...imageToAdd });

        dispatch(addImageDataSuccess(imageToAdd));
    } catch (err) {
        console.log(err);
        dispatch(addImageDataError(err));
    }
};

export const deleteImageData = (modelId, uid, images, idToDelete) => async (dispatch, getState) => {
    try {
        dispatch(deleteImageDataStart());

        const imageToDelete = images.find((image) => image.id === idToDelete);

        const deleteRef = storageRef.child(`${uid}/${modelId}/modelImages/${idToDelete}`);

        await deleteRef.delete();
        await firestore.doc(`${MODEL_COLL}/${modelId}`).collection("imageGallery").doc(idToDelete).delete();

        const newImages = images.filter((image) => image.id !== idToDelete);

        if (imageToDelete.isFromCheckList) {
            const { chbaChecklist: { items: chbaCheckListItems } = {} } = getState();

            const splitNameArray = imageToDelete.checkListPath.split(".");

            const valueFromState =
                splitNameArray.length === 1
                    ? chbaCheckListItems?.[splitNameArray[0]]
                    : splitNameArray.length === 2
                    ? chbaCheckListItems?.[splitNameArray[0]]?.[splitNameArray[1]]
                    : splitNameArray.length === 3
                    ? chbaCheckListItems?.[splitNameArray[0]]?.[splitNameArray[1]]?.[splitNameArray[2]]
                    : [];

            const newRoadMapImages = valueFromState.filter((image) => image.id !== idToDelete);

            dispatch(updateChecklistItems({ path: imageToDelete.checkListPath, value: newRoadMapImages }));
        }

        dispatch(deleteImageDataSuccess(newImages));
    } catch (error) {
        dispatch(deleteImageDataError(error.message));
    }
};

export const saveImageGallery = (modelId, images) => async (dispatch, getState) => {
    try {
        dispatch(saveImageDataStart());

        const {
            imageGallery: { imageGalleryToCompare = [], imageGallerySnapshot = [] },
        } = getState();

        const mergedImages = mergeArrayOfObjects(images, imageGalleryToCompare, imageGallerySnapshot);

        // console.log(images, imageGalleryToCompare, imageGallerySnapshot);
        // console.log("mergedImages", mergedImages);

        const batch = firestore.batch();

        mergedImages.forEach((image) => {
            const docRef = firestore.doc(`${MODEL_COLL}/${modelId}`).collection("imageGallery").doc(image.id); // Creating a document reference
            batch.set(docRef, image); // Add set operation to batch
        });

        // Commit the batch
        await batch.commit();

        dispatch(saveImageDataSuccess());
    } catch (error) {
        dispatch(saveImageDataError(error.message));
    }
};
