import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import Measurements from "./";

const mapStateToProps = ({ form }, { accessor }) => {
    const selector = formValueSelector("model");
    const doorHeight = selector({ form }, `${accessor}.measurements.height`) || {};
    const doorWidth = selector({ form }, `${accessor}.measurements.width`) || {};
    const heightUnits = selector({ form }, `${accessor}.measurements.height_u`);
    const widthUnits = selector({ form }, `${accessor}.measurements.width_u`);
    const areaUnits = selector({ form }, `${accessor}.measurements.area_u`);
    const modelUnits = selector({ form }, `modelData.uiSettings.primaryUnits`) || {};

    const doorHeightEquation = selector({ form }, `${accessor}.measurements.height_eqn`);
    const doorWidthEquation = selector({ form }, `${accessor}.measurements.width_eqn`);

    return {
        accessor,
        doorHeight,
        doorWidth,
        modelUnits,
        heightUnits,
        widthUnits,
        areaUnits,
        doorWidthEquation,
        doorHeightEquation,
    };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Measurements);
