import React, { useEffect, useCallback } from "react";
import classes from "./style.module.scss";
import WindowIcon from "assets/images/components/JSX/Window-Simple";
import DoorIcon from "assets/images/components/JSX/Rectangle";
import Line from "assets/images/components/JSX/Line";
import Polygon from "assets/images/components/JSX/Polygon";
import Tooltip from "components/Tooltip";

export default ({
    activeTool,
    setActiveTool,
    toolClick,
    disabledTools = [],
}) => {
    const handleToolToggle = (tool) => {
        if (activeTool === tool) {
            return setActiveTool("");
        }
        setActiveTool(tool);
        //toolClick();
    };

    return (
        <div className={classes.tools}>
            <h3>Drawing Tools</h3>
            <div className={classes.topRow}>
                <div
                    className={`${classes.tool} ${
                        activeTool === "window" ? classes.active : ""
                    } ${
                        disabledTools.includes("window") ? classes.disabled : ""
                    }`}
                    style={{
                        backgroundColor: activeTool === "window" && "#62BCF8",
                    }}
                    data-tip="Window"
                    onClick={() => handleToolToggle("window")}
                >
                    <WindowIcon />
                </div>
                <div
                    className={`${classes.tool} ${
                        activeTool === "door" ? classes.active : ""
                    } ${
                        disabledTools.includes("door") ? classes.disabled : ""
                    }`}
                    style={{
                        backgroundColor: activeTool === "door" && "#18C1AD",
                    }}
                    data-tip="Door"
                    onClick={() => handleToolToggle("door")}
                >
                    <DoorIcon />
                </div>
                <div
                    className={`${classes.tool} ${
                        activeTool === "line" ? classes.active : ""
                    } ${
                        disabledTools.includes("line") ? classes.disabled : ""
                    }`}
                    style={{
                        backgroundColor: activeTool === "line" && "#F5547C",
                    }}
                    data-tip="Line"
                    onClick={() => handleToolToggle("line")}
                >
                    <Line />
                </div>
                <div
                    className={`${classes.tool} ${
                        activeTool === "outline" ? classes.active : ""
                    } ${
                        disabledTools.includes("outline")
                            ? classes.disabled
                            : ""
                    }`}
                    style={{
                        backgroundColor: activeTool === "outline" && "#FF8C1D",
                    }}
                    data-tip="Polygon"
                    onClick={() => handleToolToggle("outline")}
                >
                    <Polygon />
                </div>
            </div>
            <div
                className={`${classes.scale} ${
                    activeTool === "measure" ? classes.active : ""
                } ${disabledTools.includes("measure") ? classes.disabled : ""}`}
                onClick={() => {
                    if (!disabledTools.includes("measure")) {
                        handleToolToggle("measure");
                    } else {
                    }
                }}
            >
                Set Scale
            </div>
            <Tooltip for="tools" />
        </div>
    );
};
