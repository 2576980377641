import moment from "moment";

export const provinceDetails = {
    5: {
        //ON
        hasTiered: true,
        hasTOU: true,
        name: "Ontario",
        winterMonths: [0, 1, 2, 3, 10, 11],
        winterHours: {
            offPeak: [0, 1, 2, 3, 4, 5, 6, 19, 20, 21, 22, 23],
            midPeak: [11, 12, 13, 14, 15, 16],
            onPeak: [7, 8, 9, 10, 11, 17, 18],
        },
        summerHours: {
            offPeak: [0, 1, 2, 3, 4, 5, 6, 19, 20, 21, 22, 23],
            midPeak: [7, 8, 9, 10, 17, 18],
            onPeak: [11, 12, 13, 14, 15, 16],
        },
        winterTierThreshold: 1000,
        summerTierThreshold: 600,
        energyWebsite: "https://www.oeb.ca/consumer-information-and-protection/electricity-rates",
    },
    8: {
        //NS
        hasTiered: false,
        hasTOU: true,
        name: "Nova Scotia",
        winterMonths: [0, 1, 2, 10, 11],
        winterHours: {
            offPeak: [0, 1, 2, 3, 4, 5, 6, 22, 23],
            midPeak: [12, 13, 14, 15],
            onPeak: [7, 8, 9, 10, 11, 16, 17, 18, 19, 20, 21],
        },
        summerHours: {
            offPeak: [0, 1, 2, 3, 4, 5, 6, 22, 23],
            midPeak: [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21],
        },
        energyWebsite: "https://www.nspower.ca/about-us/electricity/rates-tariffs/domestic-tod/time-of-day-rates",
    },
    2: {
        //AB
        hasTiered: true,
        hasTOU: false,
        name: "Alberta",
        winterMonths: [0, 1, 2, 3, 10, 11],
        winterTierThreshold: 0,
        summerTierThreshold: 0,
        energyWebsite: "https://www.auc.ab.ca/current-electricity-rates-and-terms-and-conditions/",
    },
    1: {
        //BC
        hasTiered: true,
        hasTOU: false,
        name: "British Columbia",
        winterMonths: [0, 1, 2, 3, 10, 11],
        winterTierThreshold: 1350,
        summerTierThreshold: 1350,
        energyWebsite:
            "https://app.bchydro.com/accounts-billing/rates-energy-use/electricity-rates/residential-rates.html#:~:text=Energy%20Charge&text=%240.0950%20per%20kWh%20for%20first,(22.1918%20kWh%20per%20day).&text=%240.1408%20per%20kWh%20over%20the%201%2C350%20Step%201%20threshold.",
    },
    6: {
        //PQ
        hasTiered: true,
        hasTOU: false,
        name: "Quebec",
        winterMonths: [0, 1, 2, 3, 10, 11],
        winterTierThreshold: 1200,
        summerTierThreshold: 1200,
    },
};

export const monthInfo = [
    { name: "january", startHour: 0, hours: 744, days: 31, num: 1 },
    { name: "february", startHour: 744, hours: 672, days: 28, num: 2 },
    { name: "march", startHour: 1416, hours: 744, days: 31, num: 3 },
    { name: "april", startHour: 2160, hours: 720, days: 30, num: 4 },
    { name: "may", startHour: 2880, hours: 744, days: 31, num: 5 },
    { name: "june", startHour: 3624, hours: 720, days: 30, num: 6 },
    { name: "july", startHour: 4344, hours: 744, days: 31, num: 7 },
    { name: "august", startHour: 5088, hours: 744, days: 31, num: 8 },
    { name: "september", startHour: 5832, hours: 720, days: 30, num: 9 },
    { name: "october", startHour: 6552, hours: 744, days: 31, num: 10 },
    { name: "november", startHour: 7296, hours: 720, days: 30, num: 11 },
    { name: "december", startHour: 8016, hours: 744, days: 31, num: 12 },
];

export const calculateEmissions = (load, factors) => factors.map((hourlyLoad, ind) => hourlyLoad * load[ind]);

export const getEnergyWebsite = (weatherProvTerrId) => {
    if (Object.keys(provinceDetails).includes(weatherProvTerrId.toString())) {
        return provinceDetails[weatherProvTerrId].energyWebsite || "";
    }
    return "";
};

export const getDailyDataByYear = (electricalLoad) => {
    const dailyDataByYear = [];
    const chunkSize = 24;
    for (let i = 0; i < electricalLoad.length; i += chunkSize) {
        const chunk = electricalLoad.slice(i, i + chunkSize);
        dailyDataByYear.push(chunk);
    }
    return dailyDataByYear;
};

export const getDailyDataByMonth = (dailyDataByYear) => {
    return monthInfo.reduce((cache, mo) => {
        return [...cache, dailyDataByYear.slice(mo.startHour / 24, mo.startHour / 24 + mo.days)];
    }, []);
};

export const getDailyConsumptionByMonth = (dailyDataByMonth) => {
    return monthInfo.reduce((moCache, month, moInd) => {
        return [
            ...moCache,
            // dayConsumption is the array of 24 hour data
            dailyDataByMonth[moInd].reduce((dayCache, dayConsumption, dayInd) => {
                return [
                    ...dayCache,
                    {
                        dayConsumption: dayConsumption,
                        momentData: moment([2022, month.num - 1, dayInd + 1]),
                        dayOfWeek: moment([2022, month.num - 1, dayInd + 1]).day(),
                    },
                ];
            }, []),
        ];
    }, []);
};

export const calcTOUCostEstimate = (weatherProvTerrId, electricityRateSet, dailyConsumptionByMonth) => {
    if (weatherProvTerrId === 5 || weatherProvTerrId === 8) {
        const {
            winterHours = {},
            summerHours = {},
            winterMonths = [],
        } = provinceDetails[weatherProvTerrId] || provinceDetails[5];

        const winterWeekday = [];
        for (let i = 0; i < 24; i++) {
            const currentPeriod = Object.keys(winterHours).find((key) => {
                if (winterHours[key].includes(i) === true) {
                    return key;
                }
            });
            winterWeekday.push(electricityRateSet[currentPeriod] || 0);
        }

        const summerWeekday = [];
        for (let i = 0; i < 24; i++) {
            const currentPeriod = Object.keys(summerHours).find((key) => {
                if (summerHours[key].includes(i) === true) {
                    return key;
                }
            });
            summerWeekday.push(electricityRateSet[currentPeriod]);
        }

        return dailyConsumptionByMonth.reduce((annTotal, month, moInd) => {
            const priceArray = winterMonths.includes(moInd) ? winterWeekday : summerWeekday;
            // for each day in month, do this
            return (
                annTotal +
                month.reduce((monthlyTotal, dayObj) => {
                    if ([0, 6].includes(dayObj.dayOfWeek)) {
                        return (
                            monthlyTotal +
                            dayObj.dayConsumption.reduce((dailyTotal, hourData) => {
                                return dailyTotal + hourData * electricityRateSet.offPeak;
                            }, 0)
                        );
                    }
                    return (
                        monthlyTotal +
                        dayObj.dayConsumption.reduce((dailyTotal, hourData, hourInd) => {
                            return dailyTotal + priceArray[hourInd] * hourData;
                        }, 0)
                    );
                }, 0)
            );
        }, 0);
    }
};

export const calcTotalKwhByMonth = (dailyConsumptionByMonth) => {
    const totalKwhByMonth = new Array(12).fill(0);
    dailyConsumptionByMonth.map((month, moInd) => {
        month.map((dayObj) => {
            dayObj.dayConsumption.map((hourData) => {
                totalKwhByMonth[moInd] += hourData;
            });
        });
    });
    return totalKwhByMonth;
};

export const calcTieredCost = (dailyConsumptionByMonth, electricityRateSet, weatherProvTerrId = "") => {
    if (Object.keys(provinceDetails).includes(weatherProvTerrId.toString())) {
        const {
            winterMonths = [],
            winterTierThreshold = 1000,
            summerTierThreshold = 1000,
        } = provinceDetails[weatherProvTerrId] || {};

        return calcTotalKwhByMonth(dailyConsumptionByMonth).reduce((totalCost, month, moInd) => {
            const tierSet = winterMonths.includes(moInd) ? winterTierThreshold : summerTierThreshold;
            return totalCost + tierSet * electricityRateSet.tier1 + (month - tierSet) * electricityRateSet.tier2;
        }, 0);
    }

    return 0;
};
