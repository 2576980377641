import { connect } from "react-redux";
import Actions from "./";
import { actions as drawingActions } from "features/Model/Drawing/_ducks";
import { actions as upgradeActions } from "features/Model/Upgrades/_ducks";
import { formValueSelector, getFormSyncErrors, unregisterField } from "redux-form";
import { isEmpty, unset, pick } from "lodash";

const { updateComponent, updatePolygonComponent, removeComponent, removePolygonComponent, removeLine } = drawingActions;
const { deletePackageUpgrade } = upgradeActions;

const mapStateToProps = ({ model: { modelId } = {}, form }, { componentId, parentPath, type, nameField }) => {
    const selector = formValueSelector("model");
    const components = selector({ form }, "modelData.components") || {};
    const modelUnits = selector({ form }, `modelData.uiSettings.primaryUnits`);
    const componentData = selector({ form }, `modelData.components${parentPath}.${type}.${componentId}`) || {};
    const defaultParams = selector({ form }, "modelData.defaultCodes") || {};
    const syncErrors = getFormSyncErrors("model")({ form });

    const { subcomponents: { window = {}, door = {}, floorHeader = {} } = {} } = componentData;

    const subCompCounts = {
        window: Object.keys(window).length || 0,
        door: Object.keys(door).length || 0,
        floorHeader: Object.keys(floorHeader).length || 0,
    };

    const { name } = nameField;
    const namePathArray = name.split(".");
    const nameError = namePathArray.reduce((cache, key) => cache[key] || "", syncErrors);

    const currentLabel = componentData.label || "";

    return {
        modelId,
        componentId,
        parentPath,
        currentLabel,
        subCompCounts,
        type,
        componentData,
        components,
        defaultParams,
        nameField,
        nameError,
        modelUnits,
    };
};

const mapDispatchToProps = (dispatch) => ({
    deleteComponent: async ({
        change,
        modelId,
        type,
        parentPath = "",
        componentId,
        components = {},
        drawing = {},
        upgradePackages = {},
    }) => {
        const {
            thisComponent: { drawingComponent, drawingImage } = {},
            subComponents: drawingSubComponents = {},
            drawingLines = [],
        } = drawing;

        // Remove drawing walls/ceilings/basements
        if (drawingComponent && (type === "wall" || type === "ceiling" || type === "basement")) {
            await dispatch(
                removePolygonComponent({
                    image: drawingImage,
                    polygonId: drawingComponent,
                    componentId,
                })
            );
        }

        if (drawingComponent && (type === "door" || type === "window")) {
            await dispatch(
                removeComponent({
                    image: drawingImage,
                    componentId: drawingComponent,
                })
            );
        }

        // Remove drawing windows/doors
        if (!isEmpty(drawingSubComponents)) {
            await Promise.all(
                Object.keys(drawingSubComponents).map(async (key) => {
                    const { drawing: { component: subComponent, image: subComponentImage } = {} } =
                        drawingSubComponents[key] || {};

                    // this shouldn't happen, but just in case
                    if (!subComponent) {
                        return true;
                    }

                    await dispatch(
                        removeLine({
                            image: subComponentImage,
                            lineId: subComponent,
                        })
                    );

                    // Can only be windows and doors
                    return dispatch(
                        updateComponent({
                            image: subComponentImage,
                            componentId: subComponent,
                            updates: { attachTo: {} },
                        })
                    );
                })
            );
        }

        // Delete related lines
        if (drawingLines.length > 0) {
            await Promise.all(
                drawingLines.map(async ({ component: lineComponent, image: lineImage }) =>
                    dispatch(
                        removeLine({
                            image: lineImage,
                            lineId: lineComponent,
                        })
                    )
                )
            );
        }

        // Remove upgrades from packages
        if (!isEmpty(upgradePackages)) {
            Object.keys(upgradePackages).map((packageId) => {
                const { upgradeId } = upgradePackages[packageId] || {};
                dispatch(deletePackageUpgrade({ packageId, upgradeId }));
            });
        }

        if (parentPath === "") {
            change("modelData.components", (fieldValue, allValues) => {
                //parentPath is an empty string if dealing with a root level component
                //Otherwise would take the form of ".wall.wall0001.subcomponents" or ".wall.wall0001.subcomponents.door.door0001.subcomponents"
                const { components = {} } = allValues.modelData || {};

                let path = `${parentPath}.${type}.${componentId}`; //Note that we might need to strip a leading "." if parentPath !== ""
                path = path.slice(0, 1) === "." ? path.slice(1) : path;

                unset(components, path);

                //Check one level up to see if we need to clear away that component entirely
                const levelUpPath = path.split(`.${componentId}`)[0];
                const leftoverComp = pick(components, levelUpPath)[type];

                if (isEmpty(leftoverComp)) {
                    unset(components, levelUpPath);
                }

                return components;
            });
        } else {
            change(`modelData.components${parentPath}`, (fieldValue, allValues) => {
                const path = `${type}.${componentId}`;

                unset(fieldValue, path);

                const levelUpPath = path.split(`.${componentId}`)[0];
                const leftoverComp = pick(fieldValue, levelUpPath)[type];

                if (isEmpty(leftoverComp)) {
                    unset(fieldValue, levelUpPath);
                }

                return fieldValue;
            });

            // change(`modelData.components${parentPath}.${type}.${componentId}`, {});
        }
        // unregisterField("model", `modelData.components${parentPath}.${type}.${componentId}`);
    },
    renameComponent: async ({ componentId, modelId, field, value, type, drawingComponent, drawingImage, change }) => {
        if (drawingComponent && (type === "window" || type === "door")) {
            await dispatch(
                updateComponent({
                    image: drawingImage,
                    componentId: drawingComponent,
                    updates: {
                        componentName: value,
                    },
                })
            );
        }
        if (drawingComponent && (type === "wall" || type === "ceiling" || type === "basement")) {
            await dispatch(
                updatePolygonComponent({
                    image: drawingImage,
                    componentId: componentId,
                    polygonId: drawingComponent,
                    updates: {
                        label: value,
                    },
                })
            );
        }
        change(`modelData.${field.split("modelData.")[1]}`, value);
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(Actions);

// function deletePropertyPath (obj, path) {

//     if (!obj || !path) {
//       return;
//     }

//     if (typeof path === 'string') {
//       path = path.split('.');
//     }

//     for (var i = 0; i < path.length - 1; i++) {

//       obj = obj[path[i]];

//       if (typeof obj === 'undefined') {
//         return;
//       }
//     }

//     delete obj[path.pop()];
//   };

//   var tenants = {
//     'first': {
//         'name': 'first',
//         'expired': 1
//     },
//     'second': {
//         'name': 'second'
//     }
// }

// var property = 'first.expired';
// deletePropertyPath(tenants, property);
