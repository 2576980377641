import React from "react";

import Dialog from "components/Dialog";
import { CloseDialog } from "components/Dialog";
import Button from "components/Button";

import classes from "../style.module.scss";

const getProcessingText = (deleting, isSharedModel = false) => {
    if (isSharedModel) {
        return deleting ? "Removing..." : "Remove";
    }

    return deleting ? "Deleting..." : "Delete";
};

const DeleteDialog = ({
    singleModels,
    toDelete,
    onClick,
    deleting,
    toggleToDelete,
    isFolder = false,
    isSharedModel = false,
}) => (
    <Dialog open={!!toDelete}>
        <CloseDialog closeActions={() => toggleToDelete()} />
        <div className={classes.dialog}>
            {isSharedModel ? (
                <>
                    <h3>
                        Are you sure you want to remove the shared model{" "}
                        <strong>{singleModels[toDelete] && singleModels[toDelete].name}</strong> from your directory?
                    </h3>
                    <h3>Only the model owner can delete its data.</h3>
                </>
            ) : (
                <h3>
                    Are you sure you want to delete{" "}
                    <strong>{singleModels[toDelete] && singleModels[toDelete].name}</strong>?
                </h3>
            )}
            {isFolder && (
                <h4>
                    Deleting a folder will move all of its nested models and sub-folders to the root level Model
                    Directory.
                </h4>
            )}

            <div className={classes.footer}>
                <Button type="hollow" onClick={() => toggleToDelete()}>
                    Cancel
                </Button>
                <Button onClick={() => onClick()} type="red" disabled={deleting}>
                    {getProcessingText(deleting, isSharedModel)}
                </Button>
            </div>
        </div>
    </Dialog>
);

export default DeleteDialog;
