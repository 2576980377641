import React, { useState, useRef } from "react";

import ModalFullScreen, { CloseModal } from "components/ModalFullScreen";
import Button from "components/Button";
import DetailsDrawer from "components/DetailsDrawer";
import Details from "./Details";
import Tools from "./Tools";
import Components from "./Components";
import Info from "./Info";
import Actions from "./Actions/container";
import Upload from "./Upload/container";
import Canvas from "./Canvas/container";
import Zoom from "./Zoom";
import DidUserSawNewDrawing from "./DidUserSawNewDrawing";
import CopyScaleDialog from "./CopyScaleDialog";
import CloseConfirmation from "./CloseConfirmation";
import Tips from "./Tips";

import House from "assets/images/icons/JSX/House";
import OpenIcon from "assets/images/icons/JSX/OpenDrawer";
import Save from "assets/images/icons/JSX/save";
import { ReactComponent as ResetIcon } from "assets/images/icons/Icon-Reset-blue.svg";

import {
    drawingModal,
    heading,
    details,
    text,
    saveIconStroke,
    body,
    rightBar,
    leftBar,
    buttons,
    syncDrawing,
} from "./style.module.scss";

const DrawingNew = React.memo(
    ({
        open,
        uid,
        modelName = "",
        toggleOpen,
        saveDrawingData,
        saving,
        modelFiles,
        modelId,
        modelSave,
        setMessage,
        isNewSnapshot,
        changesMade,
        fetchDrawingData,
        isUploading,
    }) => {
        const stage = useRef();
        const layer = useRef();
        const cursorLayer = useRef();
        const canvas = useRef();

        const [closeDialogOpen, toggleCloseDialog] = useState(false);
        const [detailsOpen, toggleDetailsOpen] = useState(false);
        const [isSaving, setIsSaving] = useState(false);
        const [isSyncing, setIsSyncing] = useState(false);
        const [canUpdate, setCanUpdate] = useState(false);

        const images = modelFiles
            .filter(({ contentType = "" }) => contentType === "image/jpeg")
            .map(({ fileName, ...rest }) => ({
                fileName: fileName.split(".jpg")[0],
                ...rest,
            }))
            .sort(({ fileName: fileA = "" }, { fileName: fileB = "" }) =>
                fileA.toLowerCase().localeCompare(fileB.toLowerCase())
            );

        return (
            <ModalFullScreen open={open}>
                <CloseModal closeActions={() => toggleCloseDialog(true)} />
                <div className={drawingModal}>
                    <div className={heading}>
                        <div className={text}>
                            <h2>Drawing Capture</h2>
                            <h3>
                                <House /> {modelName}
                            </h3>
                        </div>
                        <span className={details} onClick={() => toggleDetailsOpen(true)}>
                            Drawing Details <OpenIcon />
                        </span>
                        <Zoom />
                        <Button
                            icon={() => <Save strokeClass={saveIconStroke} />}
                            smallPadding
                            onClick={async () => {
                                setIsSaving(true);
                                await saveDrawingData(modelId);
                                await modelSave();

                                setMessage({
                                    message: "Drawing saved successfully",
                                    type: "success",
                                    anchorOrigin: {
                                        vertical: "top",
                                        horizontal: "right",
                                    },
                                    autoHideDuration: 2000,
                                    isOpen: true,
                                });
                                setCanUpdate(true);
                                setIsSaving(false);
                            }}
                            disabled={saving || !changesMade || isSyncing || isSaving}
                        >
                            {saving ? "Processing..." : "Save Drawing"}
                        </Button>
                        <Button
                            className={isSyncing ? `${syncDrawing}` : ""}
                            type="hollow"
                            style={{ width: "fit-content", minWidth: "unset", marginLeft: "0.5rem" }}
                            icon={ResetIcon}
                            smallPadding
                            onClick={async () => {
                                setIsSyncing(true);

                                await fetchDrawingData(modelId);

                                setCanUpdate(true);
                                setIsSyncing(false);
                            }}
                            disabled={!isNewSnapshot || isSyncing || isSaving}
                        />
                    </div>
                    <div className={body}>
                        <div className={rightBar}>
                            <Tools images={images} layer={layer} stage={stage} />
                            <Tips />
                            <Components stage={stage} layer={layer} images={images} />
                        </div>
                        {modelFiles.length > 0 ? (
                            <Canvas
                                uid={uid}
                                modelId={modelId}
                                open={open}
                                stage={stage}
                                layer={layer}
                                canvas={canvas}
                                cursorLayer={cursorLayer}
                                saving={isSaving}
                                isNewSnapshot={isNewSnapshot && !isSaving}
                                canUpdate={canUpdate}
                                setCanUpdate={setCanUpdate}
                                isUploading={isUploading}
                            />
                        ) : (
                            <Upload />
                        )}
                        <div className={leftBar}>
                            <Info layer={layer} images={images} />
                            <Actions images={images} />
                        </div>
                    </div>
                </div>
                <DetailsDrawer open={detailsOpen} close={() => toggleDetailsOpen(false)} title="Drawing Details">
                    <Details />
                    <div className={buttons}>
                        <Button large type="hollow" onClick={() => toggleDetailsOpen(false)}>
                            Close
                        </Button>
                    </div>
                </DetailsDrawer>
                <DidUserSawNewDrawing />
                <CopyScaleDialog images={images} />
                <CloseConfirmation
                    toggleOpen={toggleOpen}
                    closeDialogOpen={closeDialogOpen}
                    toggleCloseDialog={toggleCloseDialog}
                    saveDrawingData={() => saveDrawingData(modelId)}
                    modelSave={modelSave}
                />
            </ModalFullScreen>
        );
    }
);

export default DrawingNew;
