

export const modalComponentNameBuilder = (value,components) => {
  let name = _getName(value) 
  let counter = 0

  components.forEach(comp => {
    if (comp.componentType === value)
    counter++
  })

  if (!counter) {
    return name
  } else {
    return `${name} (${counter})`
  }
}

const _getName = value => {

  switch (value) {
      case 'expFloor': return 'Exposed Floor';
      case 'slab': return 'Slab-on-grade';
      case 'floorHeader': return 'Floor Header';
      default: return value.charAt(0).toUpperCase() + value.slice(1)
  }
};