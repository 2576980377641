import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import WindowLegacy from './';


const mapStateToProps = (
    {
        form,
    },
    {
        accessor
    }
) => {
    const selector = formValueSelector('uDefCodeWindow');
    const modelSelector = formValueSelector('model');

    const centreGlassUnits = selector({form}, `${accessor}.rsiValues.centreOfGlass_u`);
    const edgeGlassUnits = selector({form}, `${accessor}.rsiValues.edgeOfGlass_u`);
    const frameRValUnits = selector({form}, `${accessor}.rsiValues.frame_u`);
    const legacyLayers = selector({form}, 'uDefCode.layers.windowLegacy');

    const frameHeightUnits = selector({form}, `${accessor}.frameHeight_u`);
    const modelUnits = modelSelector({form}, `modelData.uiSettings.primaryUnits`) || {};

    return {
        accessor,
        centreGlassUnits,
        edgeGlassUnits,
        frameRValUnits,
        frameHeightUnits,
        modelUnits,
        legacyLayers,
    }
};

const mapDispatchToProps = () => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WindowLegacy);