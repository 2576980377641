import Rectangular from 'assets/images/planShapes/Rectangular.svg';
import TShape from 'assets/images/planShapes/TShape.svg';
import LShape from 'assets/images/planShapes/LShape.svg';
import SevenToEight from 'assets/images/planShapes/7-8.svg';
import FiveToSix from 'assets/images/planShapes/5-6.svg';
import NineToTen from 'assets/images/planShapes/9-10.svg';
import ElevenPlus from 'assets/images/planShapes/11.svg';

export const PlanIcon = key => ({
    0:{
        img:Rectangular,
        alt:"Rectangular shape"
    },
    1:{
        img:TShape,
        alt:"T-Shape"
    },
    2:{
        img:LShape,
        alt:"L-Shape"
    },
    3:{
        img:FiveToSix,
        alt:"5-6 corners"
    },
    4:{
        img:SevenToEight,
        alt:"7-8 corners"
    },
    5:{
        img:NineToTen,
        alt:"9-10 corners"
    },
    6:{
        img:ElevenPlus,
        alt:"11 or more corners"
    },
}[key || 0])