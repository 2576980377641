// import { v4 as uuidv4 } from "uuid";
import types from "./types";

import { foundationFactory, storeyFactory } from "../Enclosure/Takeoff/TakeoffData/TakeoffFactories";
import IDGenerator from "../Enclosure/Takeoff/TakeoffData/IDGenerator";
import {
    foundationTranslator,
    storeyTranslator,
    translateGarageWalls,
} from "../Enclosure/Takeoff/components/utils/componentTranslator";

import convertUnit from "utils/convertUnit";
import { updateObject, capitalize } from "utils";
import { capFirstLetter } from "utils/generalUtils/generalUtils";
import cleanComponents from "utils/cleanComponents";
import { isEmpty, cloneDeep } from "lodash";

export const initialState = {
    modelId: null,
    modelData: {},
    modelDetails: {},
    modelTakeoff: {
        errors: {},
    },
    modelFiles: [],
    fileUpload: {},
    loading: false,
    error: null,
    newestComponents: [],
    recentDuplicates: [],
    review: {},
    checklist: {},
    contentOpen: false,
    dataEntryMode: "Hot2000",
    isDataChanged: false,
    // foundationSurfaceArea: { value: 0 },
    buildingSurfaceArea: {
        foundationSurfaceArea: {
            value: 0,
        },
        aboveGradeStoreysSurfaceArea: {
            value: 0,
        },
    },
    saveTimeout: null,
    modelDataToCompare: {},
    modelDetailsToCompare: {},
    modelTakeoffToCompare: {},
    linkedBaselineToCompare: {},
    linkedBaseline: {},
    lastSnapshot: {},
    isSnapshotUpdating: false,
    isSharingModel: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.MODEL_LOADING:
            return startModelLoading(state, action);
        case types.MODEL_LOADING_END:
            return endModelLoading(state, action);
        case types.SET_MODEL_ERROR:
            return setModelError(state, action);
        case types.SET_MISSING_CODES:
            return setMissingCodes(state, action);
        case types.SET_EXPORT_ERROR:
            return setExportError(state, action);
        case types.SET_MODEL_DATA:
            return modelInit(state, action);
        case types.SET_MODEL_INFO:
            return setPreRetrofitModelData(state, action);
        case types.SET_MISSING_RESULTS:
            return setMissingResults(state, action);
        case types.CLEAR_MODEL_DATA:
            return clearModelData(state, action);
        case types.SET_MODEL_CODE:
            return setModelCode(state, action);
        case types.SET_MODEL_COMPONENT:
            return setModelComponent(state, action);
        case types.CLEAR_NEW_COMPONENTS:
            return clearNewestComponents(state, action);
        case types.SET_MODEL_FILES:
            return setModelFiles(state, action);
        case types.SET_FILE_UPLOAD_STATUS:
            return setFileUploadStatus(state, action);
        case types.UPDATE_CHECKLIST:
            return updateChecklistItem(state, action);
        case types.UPDATE_MODEL_DETAILS:
            return updateModelDetails(state, action);
        case types.TOGGLE_CONTENT_OPEN:
            return toggleContenOpen(state, action);
        case types.MOVE_MODEL_START:
            return {
                ...state,
                loading: true,
            };
        case types.MOVE_MODEL_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.DUPLICATE_MODEL_START:
            return {
                ...state,
                loading: true,
            };
        case types.DUPLICATE_MODEL_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.ADD_MODEL_TAG_START:
            return addModelTag(state, action);
        case types.REMOVE_MODEL_TAG_START:
            return removeModelTag(state, action);
        case types.SHARE_FILE_ORG_ERROR:
        case types.SHARE_FILE_ORG_START:
            return {
                ...state,
                isSharingModel: true,
            };
        case types.SHARE_FILE_ORG_SUCCESS:
            return shareModelFile(state, action);
        case types.TOGGLE_SHARE_FILE_ORG_LOADING:
            return {
                ...state,
                isSharingModel: false,
            };
        case types.FLAG_MODEL_ERROR_START:
            return flagModelError(state, action);

        case types.ADD_MODEL_CHBA_COMMUNITY:
            return addModelToChbaCommunity(state, action);
        case types.CHANGE_DATAENTRY_MODE:
            return changeDataentryMode(state, action);
        case types.ADD_TAKEOFF_COMP:
            return addTakeoffComp(state, action);
        case types.REMOVE_TAKEOFF_COMP:
            return removeTakeoffComp(state, action);
        case types.UPDATE_TAKEOFF_COMPONENT:
            return updateTakeoffComponent(state, action);
        case types.TRANSLATE_COMPONENT:
            return translateComponent(state, action);
        case types.SET_TAKEOFF_ERRORS:
            return setTakeoffErrors(state, action);
        case types.REMOVE_TAKEOFF_SUBCOMP:
            return removeTakeoffSubcomponent(state, action);
        case types.ADD_TAKEOFF_SUBCOMP:
            return addTakeoffSubcomponent(state, action);
        case types.EDIT_TAKEOFF_SUBCOMP:
            return editTakeoffSubcomponent(state, action);
        case types.DUPLICATE_TAKEOFF_SUBCOMP:
            return duplicateTakeoffSubcomponent(state, action);
        case types.ADD_TOFF_TABLE_COMP:
            return addToffTableComp(state, action);
        case types.ADD_TOFF_TABLE_MULTI_ROW:
            return addToffTableMultiRow(state, action);
        case types.EDIT_TOFF_TABLE_COMP:
            return editToffTableComp(state, action);
        case types.REMOVE_TOFF_TABLE_COMP:
            return removeToffTableComp(state, action);
        case types.COUNT_BUILDING_SURFACE_AREA:
            return countBuildingSurfaceArea(state, action);
        case types.SET_DIMENSIONS:
            return setDimensions(state, action);
        case types.UPDATE_SPECIFICATIONS:
            return updateSpecifications(state, action);
        case types.SET_SAVE_TIMEOUT:
            return setSaveTimeout(state, action);
        case types.ROTATE_WINDOWS:
            return rotateHome(state, action);
        case types.UPDATE_MODEL_SNAPSHOT_START: {
            return {
                ...state,
                isSnapshotUpdating: true,
            };
        }
        case types.UPDATE_MODEL_SNAPSHOT_SUCCESS:
            return {
                ...state,
                isSnapshotUpdating: false,
            };
        case types.UPDATE_MODEL_SNAPSHOT:
            return {
                ...state,

                lastSnapshot: { ...action.payload },
            };
        default:
            return state;
    }
};

const updateChecklistItem = (state, action) => {
    const checklist = state.checklist || {};
    const { list = {} } = checklist;
    const { [action.id]: checklistItem = {} } = list;
    return updateObject(state, {
        checklist: updateObject(checklist, {
            list: updateObject(list, {
                [action.id]: updateObject(checklistItem, action.updates),
            }),
        }),
    });
};

const setFileUploadStatus = (state, action) => {
    return updateObject(state, {
        fileUpload: updateObject(state.fileUpload, action.statusUpdates),
    });
};

const startModelLoading = (state, action) => updateObject(state, { loading: true });

const endModelLoading = (state, action) => updateObject(state, { loading: false, error: false });

const setModelError = (state, action) => updateObject(state, { loading: false, error: action.error });

const setMissingCodes = (state, action) => updateObject(state, { missingCodes: action?.missingCodes || {} });

const clearModelData = (state, action) => updateObject(state, initialState);

const addModelToChbaCommunity = (state, action) => updateObject(state, { communityUpdate: true });

const setModelCode = (state, action) => {
    const { modelData = {} } = state;
    const { codes = {} } = modelData;

    return updateObject(state, {
        modelData: updateObject(modelData, {
            codes: updateObject(codes, {
                [action.codeRef]: action.code,
            }),
        }),
    });
};

const setModelComponent = (state, action) => {
    const { recentDuplicates, newestComponents } = state;
    const componentForDuplicate = action.isDuplicate ? [action.componentId] : [];

    return updateObject(state, {
        newestComponents: [...newestComponents, action.componentId],
        recentDuplicates: [...recentDuplicates, ...componentForDuplicate],
    });
};

const setExportError = (state, action) => {
    const { review } = state;
    return updateObject(state, {
        review: updateObject(review, {
            exportErrors: action.errors,
            location: action.location || "",
            proceedFunc: action.proceedFunc || null,
        }),
    });
};

const clearNewestComponents = (state, action) => {
    const { newestComponents = {} } = state;
    return updateObject(state, {
        newestComponents: [...newestComponents.pop(action.id)],
    });
};

const setModelFiles = (state, action) => {
    return updateObject(state, {
        modelFiles: action.files,
    });
};

const updateModelDetails = (state, action) => {
    return updateObject(state, {
        modelDetails: updateObject(state.modelDetails, action.modelDetails),
    });
};

const setPreRetrofitModelData = (state, action) => {
    return updateObject(state, {
        ...state,
        linkedBaseline: {
            ...state.linkedBaseline,
            selectedModelId: action.modelId || "",
            data: action.model || {},
        },
    });
};

const setMissingResults = (state, action) => {
    return updateObject(state, {
        ...state,
        linkedBaseline: {
            ...state.linkedBaseline,
            hasResults: action.hasResults,
        },
    });
};

// linkedBaseline needs to be passed here
// the data to compare is what checks for changed data (enable save button)
const modelInit = (state, action) => {
    return action.isSaving
        ? updateObject(state, {
              modelId: action.modelId,
              error: false,
              loading: false,
              ...action.model, //Not having this here breaks the saving when simulations are run. It seems like it was adding during autosave but it wont work
              modelDataToCompare: cloneDeep(action.model.modelData),
              modelDetailsToCompare: cloneDeep(action.model.modelDetails),
              modelTakeoffToCompare: cloneDeep(action.model.modelTakeoff),
              linkedBaselineToCompare: cloneDeep(action.model.linkedBaseline),
          })
        : updateObject(state, {
              modelId: action.modelId,
              error: false,
              loading: false,
              ...action.model,
              modelDataToCompare: cloneDeep(action.model.modelData),
              modelDetailsToCompare: cloneDeep(action.model.modelDetails),
              modelTakeoffToCompare: cloneDeep(action.model.modelTakeoff),
              linkedBaselineToCompare: cloneDeep(action.model.linkedBaseline),
          });
};

const toggleContenOpen = (state, action) =>
    updateObject(state, {
        contentOpen: !state.contentOpen,
    });

const addModelTag = (state, action) => {
    const { modelDetails: { tags: existingTags = [] } = {} } = state;

    return updateObject(state, {
        modelDetails: updateObject(state.modelDetails, {
            tags: [...existingTags, action.tag],
        }),
    });
};

const removeModelTag = (state, action) => {
    const { modelDetails: { tags: existingTags = [] } = {} } = state;

    const remainingTags = existingTags.filter((el) => el !== action.tag) || [];

    return updateObject(state, {
        modelDetails: updateObject(state.modelDetails, {
            tags: remainingTags,
        }),
    });
};

const shareModelFile = (state, action) => {
    const { payload: { userList = [] } = {} } = action;

    return updateObject(state, {
        modelDetails: updateObject(state.modelDetails, {
            sharedWith: [...state.modelDetails.sharedWith, ...userList],
        }),
        modelDetailsToCompare: updateObject(state.modelDetailsToCompare, {
            sharedWith: [...state.modelDetailsToCompare.sharedWith, ...userList],
        }),
    });

    // return updateObject(state, {
    //     modelDetails: updateObject(state.modelDetails, {
    //         sharedWith: [...state.modelDetails.sharedWith, ...userList],
    //     }),
    // });
};

const flagModelError = (state, action) => {
    return updateObject(state, {
        modelDetails: updateObject(state.modelDetails, { modelErrorSubmitted: true }),
    });
};

const changeDataentryMode = (state, action) => {
    return {
        ...state,
        dataEntryMode: action.mode,
    };
};

const addTakeoffComp = (state, action) => {
    if (action.comp === "foundation") {
        return foundationFactory(state, action);
    } else {
        return storeyFactory(state, action);
    }
};

const updateTakeoffComponent = (state, action) => {
    const { origin, value, unit, component = "foundation" } = action.data;
    return {
        ...state,
        modelTakeoff: {
            ...state.modelTakeoff,
            [component]: {
                ...state.modelTakeoff[component],
                [origin]: {
                    value: !isNaN(parseFloat(value)) ? parseFloat(value) : value,
                    unit: unit,
                },
            },
        },
    };
};

const removeTakeoffComp = (state, action) => {
    return {
        ...state,
    };
};

const setTakeoffErrors = (state, action) => {
    const { origin, error, message } = action.data;

    if (!error) {
        if (state.modelTakeoff.errors[origin]) {
            let newState = { ...state };
            delete state.modelTakeoff.errors[origin];
            return { ...newState };
        } else {
            return {
                ...state,
            };
        }
    } else {
        return {
            ...state,
            modelTakeoff: {
                ...state.modelTakeoff,
                errors: {
                    ...state.modelTakeoff.errors,

                    [origin]: {
                        error: error,
                        message: message,
                    },
                },
            },
        };
    }
};

const removeTakeoffSubcomponent = (state, action) => {
    const { parent, id } = action.data;
    const newArray = [];
    state.modelTakeoff[parent].components.forEach((item) => {
        if (item.id !== id) {
            newArray.push(item);
        }
    });

    return {
        ...state,
        isDataChanged: true,
        modelTakeoff: {
            ...state.modelTakeoff,
            [parent]: {
                ...state.modelTakeoff[parent],
                components: newArray,
            },
        },
    };
};

const addTakeoffSubcomponent = (state, action) => {
    const { parent, component } = action.data;
    const newArray = JSON.parse(JSON.stringify(state.modelTakeoff[parent].components));
    newArray.push(component);

    return {
        ...state,
        isDataChanged: true,
        modelTakeoff: {
            ...state.modelTakeoff,
            [parent]: {
                ...state.modelTakeoff[parent],
                components: newArray,
            },
        },
    };
};

const editTakeoffSubcomponent = (state, action) => {
    const { parent, id, field, value, unit } = action.data;
    const newArray = [];

    state.modelTakeoff[parent].components.forEach((item) => {
        if (item.id !== id) {
            newArray.push(item);
        } else {
            let modItem = item;
            modItem.fields.forEach((subfield, idx) => {
                if (subfield.field === field) {
                    subfield.value = value;
                    subfield.unit = unit;
                }
            });
            newArray.push(modItem);
        }
    });

    return {
        ...state,
        isDataChanged: true,
        modelTakeoff: {
            ...state.modelTakeoff,
            [parent]: {
                ...state.modelTakeoff[parent],
                components: newArray,
            },
        },
    };
};

const duplicateTakeoffSubcomponent = (state, action) => {
    const { parent, id } = action.data;

    const newArray = [];

    state.modelTakeoff[parent].components.forEach((item) => {
        if (item.id !== id) {
            newArray.push(item);
        } else {
            newArray.push(item);
            let dupItem = { ...item };
            dupItem.id = IDGenerator("dup");
            newArray.push(dupItem);
        }
    });

    return {
        ...state,
        isDataChanged: true,
        modelTakeoff: {
            ...state.modelTakeoff,
            [parent]: {
                ...state.modelTakeoff[parent],
                components: newArray,
            },
        },
    };
};

const addToffTableComp = (state, action) => {
    const { parent, component } = action.data;
    const newArray = state.modelTakeoff[parent].tableComponents;
    newArray.push(component);

    return {
        ...state,
        isDataChanged: true,
        modelTakeoff: {
            ...state.modelTakeoff,
            [parent]: {
                ...state.modelTakeoff[parent],
                tableComponents: newArray,
            },
        },
    };
};

const addToffTableMultiRow = (state, action) => {
    const { parent, component = [], overwrite = false } = action.data;
    const newArray = state?.modelTakeoff?.[parent]?.tableComponents || [];

    return {
        ...state,
        isDataChanged: true,
        modelTakeoff: {
            ...state.modelTakeoff,
            [parent]: {
                ...state.modelTakeoff[parent],
                tableComponents: overwrite ? component : [...newArray, ...component],
            },
        },
    };
};

const editToffTableComp = (state, action) => {
    const { parent, id, field, value, unit } = action.data;

    const newArray = [];

    state.modelTakeoff[parent].tableComponents.forEach((item) => {
        if (item.id !== id) {
            newArray.push(item);
        } else {
            let modItem = item;
            modItem[field].value = value;
            modItem[field].unit = unit;
            newArray.push(modItem);
        }
    });

    return {
        ...state,
        isDataChanged: true,
        modelTakeoff: {
            ...state.modelTakeoff,
            [parent]: {
                ...state.modelTakeoff[parent],
                tableComponents: newArray,
            },
        },
    };
};

const removeToffTableComp = (state, action) => {
    const { parent, id } = action.data;
    const newArray = [];
    state.modelTakeoff[parent].tableComponents.forEach((item) => {
        if (item.id !== id) {
            newArray.push(item);
        }
    });

    return {
        ...state,
        isDataChanged: true,
        modelTakeoff: {
            ...state.modelTakeoff,
            [parent]: {
                ...state.modelTakeoff[parent],
                tableComponents: newArray,
            },
        },
    };
};

const countBuildingSurfaceArea = (state, action) => {
    const { modelTakeoff, buildingSurfaceArea } = state;

    let newBuildingArea = buildingSurfaceArea;

    const modelTakeoffKeys = Object.keys(modelTakeoff);

    for (let i = 0; i < modelTakeoffKeys.length; i++) {
        const component = modelTakeoff[modelTakeoffKeys[i]];
        const componentType = component.compType;

        if (
            componentType === "crawlspace" ||
            componentType === "basement" ||
            componentType === "walkout" ||
            componentType === "basementcrawlspace"
        ) {
            let totalPonyWallArea = 0;

            if (component.components.length > 0) {
                // tkoffPonyWallPerimeter * (tkoffPonyWallHeight1 + tkoffPonyWallHeight2) / 2	(for each pony wall)
                const ponyWalls = component.components.filter(({ type }) => type === "ponywall");

                if (ponyWalls.length > 0) {
                    for (let index = 0; index < ponyWalls.length; index++) {
                        const wall = ponyWalls[index];

                        const pwHeight1 = getSubCompValue(wall.fields, "height1");
                        const pwHeight2 = getSubCompValue(wall.fields, "height2");
                        const pwHeightPerimeter = getSubCompValue(wall.fields, "perimeter");

                        totalPonyWallArea += (pwHeightPerimeter * (pwHeight1 + pwHeight2)) / 2;
                    }
                }
            }

            switch (componentType) {
                case "crawlspace":
                case "basement":
                case "walkout":
                    newBuildingArea = {
                        ...newBuildingArea,
                        foundationSurfaceArea: {
                            value: component.totalPerimeter.value * component.agHeight.value,
                            // totalPonyWallArea,
                        },
                    };
                    break;
                case "basementcrawlspace": {
                    // tkoffCrwlspPerimeter * tkoffCrwlspAgWallHeight + tkoffBsmtPerimeter * tkoffBsmtAgWallHeight
                    const tkoffBsmtAgWallSurfArea = component.totalPerimeter.value * component.agHeight.value;

                    const tkoffCrwlspAgWallSurfArea =
                        component.crawltotalPerimeter.value * component.crawlagHeight.value;

                    newBuildingArea = {
                        ...newBuildingArea,
                        foundationSurfaceArea: {
                            value: tkoffBsmtAgWallSurfArea + tkoffCrwlspAgWallSurfArea + totalPonyWallArea,
                        },
                    };

                    break;
                }
                default:
                    newBuildingArea = {
                        ...newBuildingArea,
                    };
            }
        } else if (componentType === "first" || componentType === "second" || componentType === "third") {
            //tkoffAgFloorPlanPerimeter + (additionalWallArea / tkoffAgWallHeight)

            let additionalWallArea = 0;

            if (component.components.length > 0) {
                let cathCeilingsWallArea = 0;
                let slopedCeilingsWallArea = 0;
                let raisedCeilingsWallArea = 0;

                //No attic wall surface area
                const cathCeilings = component.components.filter(({ type }) => type === "cathedralceiling");
                const slopedCeilings = component.components.filter(({ type }) => type === "slopedceiling");
                const raisedCeilings = component.components.filter(({ type }) => type === "raisedceiling");

                if (cathCeilings.length > 0) {
                    for (let index = 0; index < cathCeilings.length; index++) {
                        const ceiling = cathCeilings[index];
                        //tkoffCathCeilWidth * tkoffCathCeilHeight

                        const cathCeilingNumGables = getSubCompValue(ceiling.fields, "numGables");
                        const cathCeilingWidth = getSubCompValue(ceiling.fields, "width");
                        const cathCeilingHeight = getSubCompValue(ceiling.fields, "height");

                        cathCeilingsWallArea += (cathCeilingNumGables / 2) * (cathCeilingWidth * cathCeilingHeight);
                    }
                }

                if (slopedCeilings.length > 0) {
                    for (let index = 0; index < slopedCeilings.length; index++) {
                        const ceiling = slopedCeilings[index];
                        //tkoffSlopedCeilWidth * tkoffSlopedCeilHeight

                        const slopedCeilingWidth = getSubCompValue(ceiling.fields, "width");
                        const slopedCeilingHeight = getSubCompValue(ceiling.fields, "height");

                        slopedCeilingsWallArea += slopedCeilingWidth * slopedCeilingHeight;
                    }
                }

                if (raisedCeilings.length > 0) {
                    for (let index = 0; index < raisedCeilings.length; index++) {
                        const ceiling = raisedCeilings[index];
                        //tkoffRaisedCeilHeight * tkoffRaisedCeilPerimeter
                        const raisedCeilingPerimeter = getSubCompValue(ceiling.fields, "perimeter");
                        const raisedCeilingHeight = getSubCompValue(ceiling.fields, "height");
                        raisedCeilingsWallArea += raisedCeilingHeight * raisedCeilingPerimeter;
                    }
                }

                additionalWallArea = cathCeilingsWallArea + slopedCeilingsWallArea + raisedCeilingsWallArea;
            }

            const tkoffAgEqPerimeter =
                component.floorPlanPerimeter.value + additionalWallArea / component.wallHeight.value;

            //tkoffAgEqPerimeter * tkoffAgWallHeight

            newBuildingArea = {
                ...newBuildingArea,
                aboveGradeStoreysSurfaceArea: {
                    value: tkoffAgEqPerimeter * component.wallHeight.value,
                },
            };
        }
    }

    return { ...state, buildingSurfaceArea: newBuildingArea };
};

const getSubCompValue = (fields, propertyName = "") => {
    const { value = 0 } = fields.find((field) => field.field === propertyName) || {};

    return value;
};

const translateComponent = (state, action) => {
    // const { modelData } = state;
    const { componentType, formModelData: modelData = {} } = action; //need to get modelData here because it's the latest from the form
    const { modelTakeoff } = state;

    const modelTakeoffKeys = Object.keys(modelTakeoff);

    const { components: componentData, defaultCodes = {} } = modelData;

    const cleanedComps = cleanComponents(componentData);
    // const cleanedComps = state.modelData.components

    let newState = state;

    newState.modelData = {
        ...modelData,
        components: cleanedComps,
    };

    const component = modelTakeoff[componentType];

    //? Foundations
    if (componentType === "foundation") {
        const foundationType = component.compType.toLowerCase();

        switch (foundationType) {
            case "slab":
            case "slab-on-grade": {
                const { slab, wall } = foundationTranslator(component, defaultCodes);
                const garagewalls = translateGarageWalls(component, defaultCodes);

                newState = {
                    ...newState,
                    modelData: {
                        ...newState.modelData,
                        components: {
                            ...newState.modelData.components,
                            wall: {
                                ...newState.modelData.components.garagewalls,
                                ...garagewalls,
                                // wall,
                            },
                            slab: {
                                ...newState.modelData.components.slab,
                                takeoffSlab: slab,
                            },
                        },
                    },
                };

                break;
            }

            case "basementcrawlspace": {
                const { basement, crawlspace } = foundationTranslator(component, defaultCodes);
                const garagewalls = translateGarageWalls(component, defaultCodes);

                newState = {
                    ...newState,
                    modelData: {
                        ...newState.modelData,
                        components: {
                            ...newState.modelData.components,
                            wall: {
                                ...newState.modelData.components.wall,
                                ...garagewalls,
                            },
                            basement: {
                                ...newState.modelData.components.basement,
                                takeoffBasementBC: basement,
                            },
                            crawlspace: {
                                ...newState.modelData.components.crawlspace,
                                takeoffCrawlspaceBC: crawlspace,
                            },
                        },
                    },
                };

                break;
            }
            case "walkout": {
                const { basement, slab } = foundationTranslator(component, defaultCodes);
                const garagewalls = translateGarageWalls(component, defaultCodes);

                newState = {
                    ...newState,
                    modelData: {
                        ...newState.modelData,
                        components: {
                            ...newState.modelData.components,
                            wall: {
                                ...newState.modelData.components.wall,
                                ...garagewalls,
                            },
                            basement: {
                                ...newState.modelData.components.basement,
                                takeoffBasementW: basement,
                            },
                            slab: {
                                ...newState.modelData.components.slab,
                                takeoffSlabW: slab,
                            },
                        },
                    },
                };

                break;
            }

            case "crawlspace": {
                const translatedComponent = foundationTranslator(component, defaultCodes);
                const garagewalls = translateGarageWalls(component, defaultCodes);

                newState = {
                    ...newState,
                    modelData: {
                        ...newState.modelData,
                        components: {
                            ...newState.modelData.components,
                            wall: {
                                ...newState.modelData.components.wall,
                                ...garagewalls,
                            },
                            crawlspace: {
                                ...newState.modelData.components.crawlspace,
                                takeoffCrawlspace: translatedComponent,
                            },
                        },
                    },
                };
                break;
            }

            default: {
                // basement

                const translatedComponent = foundationTranslator(component, defaultCodes);
                const garagewalls = translateGarageWalls(component, defaultCodes);

                newState = {
                    ...newState,
                    modelData: {
                        ...newState.modelData,
                        components: {
                            ...newState.modelData.components,
                            wall: {
                                ...newState.modelData.components.wall,
                                ...garagewalls,
                            },
                            basement: {
                                ...newState.modelData.components.basement,
                                takeoffBasement: translatedComponent,
                            },
                        },
                    },
                };
            }
        }
    } else {
        //? STOREYS
        const translatedStorey = storeyTranslator(component, defaultCodes);
        const { takeoffWall, walls, ceilings, expFloors } = translatedStorey;

        newState = {
            ...newState,
            isDataChanged: true,
            modelData: {
                ...newState.modelData,
                components: {
                    ...newState.modelData.components,
                    wall: {
                        ...newState.modelData.components.wall,
                        [`takeoffWall-${component.compType}`]: {
                            ...takeoffWall,
                        },
                        ...walls,
                    },
                    ceiling: {
                        ...newState.modelData.components.ceiling,
                        ...ceilings,
                    },
                    expFloor: {
                        ...newState.modelData.components.expFloor,
                        ...expFloors,
                    },
                },
            },
        };
    }

    newState.modelData.components = cleanComponents(newState.modelData.components);

    ///// START SET DIMENSIONS ********
    //Must be in the same function so they both act on the same form data

    const {
        dimensions: {
            aboveGradeIntFloorArea: { items: existingAgItems = {} } = {},
            belowGradeIntFloorArea: { items: existingBgItems = {} } = {},
            volume: { items: existingVolumeItems = {} } = {},
        } = {},
    } = newState.modelData;

    // let totalHouseVolume = existingHouseVolume;
    let totalHouseVolumeItems = { ...existingVolumeItems };

    let aboveGradeIntFloorAreaItems = { ...existingAgItems };
    let belowGradeIntFloorAreaItems = { ...existingBgItems };
    //Height of Highest Ceiling
    let heightOfHighestCeiling = 0;
    let tkoffAgWallHeight = 0;
    //max(tkoffCathCeilHeight, tkoffSlopedCeilHeight, tkoffRaisedCeilHeight)
    let highestStorey = [];

    let additionalEnclosedVolume = 0;
    let inclCrawlVolume = false;

    //overwrite ag/bg for case we're not
    //Below Grade Heated Floor Area
    if (componentType === "foundation") {
        const foundationType = component.compType;

        switch (foundationType.toLowerCase()) {
            case "basement":
                totalHouseVolumeItems = {
                    ...totalHouseVolumeItems,
                    [componentType]: {
                        enabled: true,
                        value: parseFloat(component.volume.value.toFixed(2)),
                        description: "Basement volume",
                    },
                };

                newState.modelData = {
                    ...newState.modelData,
                    dimensions: {
                        ...newState.modelData.dimensions,
                        belowGradeIntFloorArea: {
                            items: {
                                ...belowGradeIntFloorAreaItems,
                                [`${componentType}`]: {
                                    enabled: true,
                                    value: parseFloat(component.area.value.toFixed(2)),
                                    description: "Basement",
                                },
                            },
                        },
                    },
                };

                newState.modelData = {
                    ...newState.modelData,
                    dimensions: {
                        ...newState.modelData.dimensions,
                        belowGradeIntFloorArea: {
                            ...newState.modelData.dimensions.belowGradeIntFloorArea,
                            total: Object.values(newState.modelData.dimensions.belowGradeIntFloorArea.items).reduce(
                                (sum, { value }) => sum + value,
                                0
                            ),
                        },
                    },
                };

                break;

            case "walkout":
                totalHouseVolumeItems = {
                    ...totalHouseVolumeItems,
                    [componentType]: {
                        enabled: true,
                        value: parseFloat(component.volume.value.toFixed(2)),
                        description: "Walkout volume",
                    },
                };

                newState.modelData = {
                    ...newState.modelData,
                    dimensions: {
                        ...newState.modelData.dimensions,
                        belowGradeIntFloorArea: {
                            items: {
                                ...belowGradeIntFloorAreaItems,
                                [componentType]: {
                                    enabled: true,
                                    value: parseFloat(component.area.value.toFixed(2)),
                                    description: "Walkout",
                                },
                            },
                        },
                    },
                };

                newState.modelData = {
                    ...newState.modelData,
                    dimensions: {
                        ...newState.modelData.dimensions,
                        belowGradeIntFloorArea: {
                            ...newState.modelData.dimensions.belowGradeIntFloorArea,
                            total: Object.values(newState.modelData.dimensions.belowGradeIntFloorArea.items).reduce(
                                (sum, { value }) => sum + value,
                                0
                            ),
                        },
                    },
                };

                break;
            case "crawlspace":
                totalHouseVolumeItems = {
                    ...totalHouseVolumeItems,
                    [componentType]: {
                        enabled: true,
                        value: parseFloat(
                            (component?.includeCrawlspaceVolume?.value ? Number(component.volume.value) : 0).toFixed(2)
                        ),
                        description: "Crawlspace volume",
                    },
                };

                inclCrawlVolume = component?.includeCrawlspaceVolume?.value || false;

                newState.modelData = {
                    ...newState.modelData,
                    dimensions: {
                        ...newState.modelData.dimensions,
                        belowGradeIntFloorArea: {
                            items: {
                                ...belowGradeIntFloorAreaItems,
                                [componentType]: {
                                    enabled: true,
                                    value: parseFloat(
                                        (component?.includeCrawlspaceVolume?.value
                                            ? Number(component.area.value)
                                            : 0
                                        ).toFixed(2)
                                    ),
                                    description: "Crawlspace",
                                },
                            },
                        },
                    },
                };

                newState.modelData = {
                    ...newState.modelData,
                    dimensions: {
                        ...newState.modelData.dimensions,
                        belowGradeIntFloorArea: {
                            ...newState.modelData.dimensions.belowGradeIntFloorArea,
                            total: Object.values(newState.modelData.dimensions.belowGradeIntFloorArea.items).reduce(
                                (sum, { value }) => sum + value,
                                0
                            ),
                        },
                    },
                };

                break;
            case "basementcrawlspace":
                //NOT UP TO DATE
                newState.modelData = {
                    ...state.newState.modelData,
                    dimensions: {
                        ...state.newState.modelData.dimensions,
                        belowGradeIntFloorArea: {
                            items: {
                                [foundationType.toLowerCase()]: {
                                    enabled: true,
                                    value: parseFloat(component.area.value.toFixed(2)),
                                    description: "Basement",
                                },
                                [`${foundationType.toLowerCase()} - 2`]: {
                                    enabled: true,
                                    value: parseFloat(component.crawlarea.value.toFixed(2)),
                                    description: "Crawlspace",
                                },
                            },
                            total:
                                parseFloat(component.area.value.toFixed(2)) +
                                parseFloat(component.crawlarea.value.toFixed(2)),
                        },
                    },
                };
                break;
            default:
                break;
        }
    } else {
        //Get highest ceiling once here for the foundation
        tkoffAgWallHeight += modelTakeoff?.foundation?.agHeight?.value || 0;
        tkoffAgWallHeight += modelTakeoff?.foundation?.floorHeaderHeight?.value || 0;

        //height of the wall on the current component
        tkoffAgWallHeight += component.wallHeight.value;
        tkoffAgWallHeight += component?.floorHeaderHeight?.value || 0;

        const { foundation, [componentType]: currentKey, other, ...restKeys } = modelTakeoff;

        //Getting heights of components that are not the target of the current Convert Takeoff button press
        if (!isEmpty(restKeys)) {
            Object.keys(restKeys).forEach((storeyKey) => {
                const {
                    components: subComps = [],
                    wallHeight: { value: wHeight = 0 } = {},
                    floorHeaderHeight: { value: fhHeight = 0 } = {},
                } = modelTakeoff[storeyKey];
                tkoffAgWallHeight += wHeight + fhHeight;

                if (subComps.length > 0) {
                    subComps.forEach((subComp) => {
                        if (["cathedralceiling", "slopedceiling", "raisedceiling"].includes(subComp.type)) {
                            const ceilHeight = getSubCompValue(subComp.fields, "height");
                            highestStorey = [...highestStorey, ceilHeight];
                        }
                    });
                }
            });
        }

        if (component.components.length > 0) {
            const cathCeilings = component.components.filter(({ type }) => type === "cathedralceiling");
            const slopedCeilings = component.components.filter(({ type }) => type === "slopedceiling");
            const raisedCeilings = component.components.filter(({ type }) => type === "raisedceiling");

            if (cathCeilings.length > 0) {
                for (let index = 0; index < cathCeilings.length; index++) {
                    const ceiling = cathCeilings[index];

                    const cathWidth = getSubCompValue(ceiling.fields, "width");
                    const cathHeight = getSubCompValue(ceiling.fields, "height");
                    const cathLength = getSubCompValue(ceiling.fields, "length");

                    const cathTotalVolume = (1 / 2) * cathWidth * cathHeight * cathLength;

                    highestStorey = [...highestStorey, cathHeight];

                    additionalEnclosedVolume += cathTotalVolume;
                }
            }

            if (slopedCeilings.length > 0) {
                for (let index = 0; index < slopedCeilings.length; index++) {
                    const ceiling = slopedCeilings[index];

                    const slopedCeilingWidth = getSubCompValue(ceiling.fields, "width");
                    const slopedCeilingHeight = getSubCompValue(ceiling.fields, "height");
                    const slopedCeilingLength = getSubCompValue(ceiling.fields, "length");

                    const slopedTotal = (1 / 2) * slopedCeilingHeight * slopedCeilingWidth * slopedCeilingLength;

                    highestStorey = [...highestStorey, slopedCeilingHeight];

                    additionalEnclosedVolume += slopedTotal;
                }
            }

            if (raisedCeilings.length > 0) {
                for (let index = 0; index < raisedCeilings.length; index++) {
                    const ceiling = raisedCeilings[index];

                    const raisedCeilingArea = getSubCompValue(ceiling.fields, "area");
                    const raisedCeilingHeight = getSubCompValue(ceiling.fields, "height");

                    const raisedTotal = raisedCeilingArea * raisedCeilingHeight;

                    highestStorey = [...highestStorey, raisedCeilingHeight];

                    additionalEnclosedVolume += raisedTotal;
                }
            }
        }

        aboveGradeIntFloorAreaItems = {
            ...aboveGradeIntFloorAreaItems,
            [component.compType]: {
                enabled: true,
                description: `${capFirstLetter(component.compType)} storey area`,
                value: parseFloat(component.floorPlanArea.value.toFixed(2)),
            },
        };

        heightOfHighestCeiling += tkoffAgWallHeight + (highestStorey.length > 0 ? Math.max(...highestStorey) : 0);

        newState.modelData = {
            ...newState.modelData,
            dimensions: {
                ...newState.modelData.dimensions,
                aboveGradeIntFloorArea: {
                    items: aboveGradeIntFloorAreaItems,
                    total: Object.values(aboveGradeIntFloorAreaItems).reduce((sum, { value }) => sum + value, 0),
                },
                highestCeiling: {
                    ...newState.modelData.dimensions.highestCeiling,
                    total: heightOfHighestCeiling,
                },
            },
        };

        totalHouseVolumeItems = {
            ...totalHouseVolumeItems,
            [componentType]: {
                enabled: true,
                description: `${capitalize(componentType)} storey volume`,
                value: Number(component.volume.value),
            },
        };
    }

    // const volumeItemsKeys = Object.keys(totalHouseVolumeItems);

    // for (let i = 0; i < volumeItemsKeys.length; i++) {
    //     const compVal = totalHouseVolumeItems[volumeItemsKeys[i]].value;

    //     totalHouseVolume += compVal;
    // }

    const newTotalVolume = Object.values(totalHouseVolumeItems).reduce((sum, { value }) => sum + value, 0);

    newState.modelData = {
        ...newState.modelData,
        dimensions: {
            ...newState.modelData.dimensions,
            volume: {
                items: {
                    ...totalHouseVolumeItems,
                },
                total: newTotalVolume,
                includeCrawlspaceVolume: inclCrawlVolume,
            },
        },
    };

    const ach = newState?.modelData?.airTightness?.blowerTest?.airChangeRate50Pa || 1;

    const newEla = calcEla(newTotalVolume, ach);

    newState.modelData.airTightness.blowerTest.eqLeakageArea = newEla;

    return { ...newState };
};

const calcEla = (houseVolume, ach) => {
    const slope = 0.344224 * houseVolume - 0.0054;
    const intercept = 0.043961 * houseVolume + 0.026977;

    return parseFloat((slope * ach + intercept).toFixed(2));
};

const setDimensions = (state, action) => {
    const { modelTakeoff } = state;

    //NO LONGER USED

    return { ...state };
};

//Fixes bug when you choose amount of storeys/plan shape/bedrooms and it resets
//after user presses "convert takeoff" button
const updateSpecifications = (state, action) => {
    const { specificationType, value, formModelData: modelData = {} } = action.payload;

    switch (action.payload.specificationType) {
        case "numStoreys":
        case "planShape":
            return {
                ...state,
                modelData: {
                    ...modelData,
                    specifications: {
                        ...modelData.specifications,
                        [specificationType]: { id: value },
                    },
                },
            };
        case "minBedroomVentilation":
            return {
                ...state,
                modelData: {
                    ...modelData,
                    ventilation: {
                        ...modelData.ventilation,
                        bedrooms: value,
                        rooms: {
                            ...modelData.ventilation.rooms,
                            bedrooms: value,
                        },
                    },
                },
            };
        default:
            return {
                ...state,
                modelData,
            };
    }
};

const setSaveTimeout = (state, action) => ({
    ...state,
    saveTimeout: action.payload,
});

const rotateHome = (state, action) => {
    const { windows, newHomeDirection, formModelData: modelData = {} } = action.payload;

    const { id } = newHomeDirection;

    let newState = state;

    newState.modelData = {
        ...modelData,
    };

    newState.modelData.specifications.facingDirection.id = id;

    const windowsObject = windows.reduce((prev, w) => ({ ...prev, [w.componentId]: w }), {});

    const { components = {} } = newState.modelData;
    const componentsKeys = Object.keys(components);

    for (let i = 0; i < componentsKeys.length; i++) {
        const compKey = componentsKeys[i];

        if (!["wall", "ceiling", "basement", "crawlspace"].includes(compKey)) continue;

        const currentComponents = components[compKey];

        if (isEmpty(currentComponents)) continue;

        const currentComponentsKeys = Object.keys(currentComponents);

        for (let j = 0; j < currentComponentsKeys.length; j++) {
            const curCompKey = currentComponentsKeys[j];

            const { subcomponents } = currentComponents[curCompKey];

            if (isEmpty(subcomponents)) continue;

            const { window = {} } = subcomponents;

            if (isEmpty(window)) continue;

            const subWindowsKeys = Object.keys(window);

            if (isEmpty(subWindowsKeys)) continue;

            for (let w = 0; w < subWindowsKeys.length; w++) {
                const wKey = subWindowsKeys[w];

                if (!window[wKey] || !windowsObject[wKey]) continue;

                components[compKey][curCompKey].subcomponents.window[wKey].facingDirection.id =
                    windowsObject[wKey]?.newDirectionId;
            }
        }
    }

    return {
        ...newState,
        modelData: {
            ...modelData,
        },
    };
};
