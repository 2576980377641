
import React, { useCallback, useRef, useState } from "react";
import classes from "../style.module.scss";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Loading from "components/Loading";

const Upgrades = ({
    history,
    selectModel,
}) => {
    const scrollContainerRef = useRef(null);

    /* Loading state and cursor position for loading spinner */
    const [cursorPosition, setCursorPosition] = useState({ top: 0, left: 0 });
    const [isLoading, setIsLoading] = useState(false);

    // Get cursor position for loading spinner
    const onMouseMove = useCallback((e) => {
        const top = e.pageY - window.scrollY + 10;
        const left = e.pageX - window.scrollX + 10;

        setCursorPosition({ top, left });
    }, []);

    return (
        <>
            <div 
                className={classes.contentContainer}
                onMouseMove={onMouseMove}
                style={{ pointerEvents: isLoading ? "none" : "auto" }}
                ref={scrollContainerRef}
            >
                <div style={{ position: "fixed", pointerEvents: "none", ...cursorPosition }}>
                    {isLoading && <Loading className={classes.smallerSpinner} subClassName={classes.smallSpinner} />}
                </div>
                Upgrades
            </div>
        </>
    );
}

const mapStateToProps = createStructuredSelector({
});

const mapDispatchToProps = (dispatch, { history }) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Upgrades);