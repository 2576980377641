import React from "react";
import mixpanel from "mixpanel-browser";

const token = 'ae159b62c43c8f2518b2bfa8ebf55429';// process.env.REACT_APP_MIXPANEL_TOKEN;

mixpanel.init(token);

export { mixpanel };

export default (Component) => (props) => {
    if (!token) console.warn("No mixpanel token is set.");

    return <Component _mixpanel={mixpanel} {...props} />;
};
