import { connect } from 'react-redux';
import Polygon from './';
import { actions } from 'features/Model/Drawing/_ducks';

const { addPolygon, setAction } = actions;

const mapStateToProps = ({
    drawing:{
        activeComponent='',
        activeImage='',
        imageData={}
    },
}) => {
    const { [activeImage]:{ polygons={} }={} } = imageData;

    //TODO: turn into selector

    const polygonCount = Object.keys(polygons).length;

    return {
        activeComponent,
        activeImage,
        polygonCount
    }
};

const mapDispatchToProps = (dispatch) => ({
    addPolygon:(image, polygon) => dispatch(addPolygon({image, polygon})),
    setAction:({id, meta}) => dispatch(setAction({id, meta})),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Polygon);