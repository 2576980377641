import React, { Fragment, useState } from "react";
import classes from "./styles.module.scss";
import { Link } from "react-router-dom";
import { useOutsideClickHook } from "utils/outsideClick";
import GoBack from "./GoBack";
import Dropdown from "components/Dropdown";
import ThreeDots from "assets/images/icons/JSX/ThreeDots";
import nestedArrow from "assets/images/icons/nestedArrow.svg";

const NestedPath = ({ rootDir, rootDirPath, path, folders, setFolderToDelete, setFolderToRename, setFolderToMove, onDragOver }) => {
    const [isFolderOptionsOpen, setIsFolderOptionsOpen] = useState(false);

    const folderOptionsRef = useOutsideClickHook(() => setIsFolderOptionsOpen(false));

    const isLastFolder = (index) => path.length - 1 === index;
    const isNotRootDir = path.length > 0;

    const colors = {
        black: "#262e3f",
        grey: "#65676a"
    }

    return (
        <div className={classes.nestedPathsContainer}>
            {path.length > 2 && <GoBack rootDir={rootDir} rootDirPath={rootDirPath} path={path} folders={folders} />}
            <div className={classes.nestedPathsWrapper}>
                <div
                    className={classes.pathTextContainer}
                    onDragOver={(event) => {
                        event.preventDefault();
                        onDragOver(event, null, "folder");
                    }}
                >
                    <Link
                        to={rootDirPath}
                        className={classes.pathText}
                        style={{ color: isNotRootDir ? colors.grey : colors.black }}
                    >
                        {rootDir}
                    </Link>
                </div>
                {isNotRootDir &&
                    path.map((id, index) => (
                        <Fragment key={index}>
                            <img src={nestedArrow} alt={nestedArrow} style={{ marginTop: "6px" }} />
                            <div
                                className={classes.pathTextContainer}
                                ref={isLastFolder(index) ? folderOptionsRef : null}
                                onClick={() => isLastFolder(index) && setIsFolderOptionsOpen(!isFolderOptionsOpen)}
                                onDragOver={(event) => {
                                    event.preventDefault();
                                    onDragOver(event, id, "folder");
                                }}
                            >
                                <Link
                                    className={classes.pathText}
                                    to={`${rootDirPath}/${path
                                        .slice(0, index + 1)
                                        .join("/")
                                        .toString()}`}
                                    style={{ color: isLastFolder(index) ? colors.black : colors.grey }}
                                >
                                    {folders[id].name}
                                </Link>
                                {isLastFolder(index) && (
                                    <div>
                                        <ThreeDots />
                                        <Dropdown
                                            className={classes.pathTextDropdown}
                                            open={isFolderOptionsOpen}
                                            options={[
                                                {
                                                    label: "Rename",
                                                    onClick: () => {
                                                        setFolderToRename(id);
                                                        setIsFolderOptionsOpen(false);
                                                    },
                                                },
                                                {
                                                    label: "Move Folder to",
                                                    onClick: () => {
                                                        setIsFolderOptionsOpen(false);
                                                        setFolderToMove(id);
                                                    },
                                                },
                                                {
                                                    label: "Delete",
                                                    onClick: () => {
                                                        setFolderToDelete(id);
                                                        setIsFolderOptionsOpen(false);
                                                    },
                                                },
                                            ]}
                                        />
                                    </div>
                                )}
                            </div>
                        </Fragment>
                    ))}
            </div>
        </div>
    );
};

export default NestedPath;
