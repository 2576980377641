import React, { useState, useEffect } from "react";
import { isEqual } from "lodash";
import { Field } from "redux-form";
import { DragOverlay } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";

import { getValidation, required, maxLength } from "utils/fieldValidation";

import Accordion from "components/Accordion";
import InputRow from "components/Input/Row";
import Window from "../Window/container";
import Actions from "../Actions/container";
import Checkbox from "components/Input/Checkbox";
import Measurements from "./Measurements/container";
import Construction from "./Construction/container";
import DoorStats from "./DoorStats/container";
import ComponentHeading from "../ComponentHeading";
import Droppable from "components/DragAndDrop/Droppable";
import ComponentType from "../ComponentType";

import classes from "./style.module.scss";
import sharedClasses from "../style.module.scss";

import DoorIcon from "assets/images/components/Door.svg"; //* *//

const charLim32 = maxLength(32);
const doorLabelValidation = [required, charLim32, ...getValidation("doorLabel")];

export default React.memo(
    ({
        accessor,
        label = "",
        change,
        componentId,
        isNew = false,
        isRecentDuplicate = false,
        subComponents,
        parentPath = "",
        parentAdjacent,
        dragHandleProps,
        allowedCategories,
        dragActive,
        clearNewComponent,
        isDrawingComponent,
        contentOpen,
        activeId,
        isDragging,
        subCompObj,
        dropPosition,
        mapSubComponents,
    }) => {
        const [nameDialogOpen, toggleNameDialog] = useState(false);
        const [items, setItems] = useState([]);

        useEffect(() => {
            if (!isDragging) setItems(mapSubComponents);
        }, [isDragging, mapSubComponents]);

        const activeSubIndex = activeId ? items.findIndex(({ id }) => id === activeId) : -1;

        return (
            <SortableContext
                items={items}
                strategy={verticalListSortingStrategy}
                id={`${parentPath.substring(1)}.door.${componentId}`}
                disabled={!isDragging ? false : !allowedCategories.includes("door")}
            >
                <div
                    className={`${items.length === 0 && classes.noSubComponents} ${
                        dropPosition === "after"
                            ? sharedClasses.activeAfter
                            : dropPosition === "before"
                            ? sharedClasses.activeBefore
                            : ""
                    }`}
                >
                    <Accordion
                        className={sharedClasses.accordion}
                        heading={
                            <ComponentHeading
                                label={label}
                                componentId={componentId}
                                isDrawingComponent={isDrawingComponent}
                                icon={DoorIcon}
                                toggleNameDialog={toggleNameDialog}
                            />
                        }
                        initOpenState={isNew && !isRecentDuplicate}
                        isNew={isNew}
                        isDragging={isDragging}
                        id={componentId}
                        dragActive={dragActive}
                        toggleNew={() => clearNewComponent(componentId)}
                        hoverActions={
                            <Actions
                                nameField={{
                                    name: `${accessor}.label`,
                                    validate: doorLabelValidation,
                                    current: label,
                                    change,
                                }}
                                label="Door"
                                type="door"
                                parentPath={parentPath}
                                componentId={componentId}
                                dragHandleProps={dragHandleProps}
                                change={change}
                                nameDialogOpen={nameDialogOpen}
                                toggleNameDialog={toggleNameDialog}
                            />
                        }
                        stats={() => <DoorStats accessor={accessor} />}
                        forceOpen={contentOpen}
                    >
                        <h4>Measurements</h4>
                        <Measurements accessor={accessor} isDrawingComponent={isDrawingComponent} change={change} />
                        <h4>Construction</h4>
                        <Construction accessor={accessor} change={change} id={componentId} />
                        <InputRow lastRow>
                            <Field
                                component={Checkbox}
                                name={`${accessor}.adjacentEnclosedSpace`}
                                label="Adjacent to enclosed unconditioned space"
                                large
                                disabled={true}
                                input={{
                                    value: parentAdjacent,
                                }}
                                type="checkbox"
                            />
                        </InputRow>
                    </Accordion>

                    <div
                        className={`${sharedClasses.subComponents} ${
                            items.length === 0 && sharedClasses.noSubComponents
                        } ${dragActive ? sharedClasses.dragActive : ""}`}
                    >
                        {items.length === 0 && (
                            <span className={sharedClasses.noSubcomponents}>
                                &nbsp;
                                {allowedCategories.includes("door") && (
                                    <Droppable id={`${parentPath.substring(1)}.door.${componentId}`}></Droppable>
                                )}
                            </span>
                        )}
                        {items.map(({ id, componentId: subComponentId, componentType }, index) => (
                            <div key={subComponentId} className={classes.draggableComponent}>
                                <ComponentType
                                    key={id}
                                    id={id}
                                    index={index + 1}
                                    activeIndex={activeSubIndex}
                                    activeId={activeId}
                                    componentId={componentId}
                                    draggableId={`${parentPath.substring(
                                        1
                                    )}.door.${componentId}.subcomponents.${componentType}.${subComponentId}`}
                                    typeDrop={"subcomponent"}
                                    type={componentType}
                                    change={change}
                                    parentAccessor={accessor}
                                    parentPath={`${parentPath}.door.${componentId}.subcomponents`}
                                    isDragging={isDragging}
                                    dragActive={dragActive}
                                    clearNewComponent={clearNewComponent}
                                    allowedCategories={allowedCategories}
                                />
                            </div>
                        ))}
                    </div>

                    <DragOverlay>
                        {activeId && activeSubIndex >= 0 ? (
                            <ComponentType
                                id={activeId}
                                activeId={activeId}
                                countSubComponents={items.length}
                                clone={true}
                                label={items[activeSubIndex].componentLabel}
                                type={items[activeSubIndex].componentType}
                                componentId={items[activeSubIndex].componentId}
                                parentAccessor={accessor}
                                parentPath={`${parentPath}.door.${componentId}.subcomponents`}
                                items={items}
                                allowedCategories={[]}
                                destination={{}}
                                isDragging={isDragging}
                                typeDrop={"subcomponent"}
                            />
                        ) : null}
                    </DragOverlay>
                </div>
            </SortableContext>
        );
    },
    isEqual
);
