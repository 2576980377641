const SET_MODEL_DATA = "SET_MODEL_DATA";
const SET_MODEL_INFO = "SET_MODEL_INFO";
const SET_MISSING_RESULTS = "SET_MISSING_RESULTS";
const MODEL_LOADING = "CREATE_MODEL_START";
const SET_MODEL_ERROR = "SET_MODEL_ERROR";
const SET_MISSING_CODES = "SET_MISSING_CODES";
const CLEAR_MODEL_DATA = "CLEAR_MODEL_DATA";
const UPDATE_MODEL = "UPDATE_MODEL";
const MODEL_LOADING_END = "MODEL_LOADING_END";
const SET_MODEL_CODE = "SET_MODEL_CODE";
const SET_MODEL_COMPONENT = "SET_MODEL_COMPONENT";
const SET_MODEL_FILES = "SET_MODEL_FILES";
const CLEAR_NEW_COMPONENTS = "CLEAR_NEW_COMPONENTS";
const SET_EXPORT_ERROR = "SET_EXPORT_ERROR";
const SET_FILE_UPLOAD_STATUS = "SET_FILE_UPLOAD_STATUS";
const UPDATE_CHECKLIST = "UPDATE_CHECKLIST";
const UPDATE_MODEL_DETAILS = "UPDATE_MODEL_DETAILS";
const TOGGLE_CONTENT_OPEN = "TOGGLE_CONTENT_OPEN";

const MOVE_MODEL_START = "MOVE_MODEL_START";
const MOVE_MODEL_SUCCESS = "MOVE_MODEL_SUCCESS";
const MOVE_MODEL_ERROR = "MOVE_MODEL_ERROR";

const BATCH_DELETE_MODELS_START = "BATCH_DELETE_MODELS_START";
const BATCH_DELETE_MODELS_SUCCESS = "BATCH_DELETE_MODELS_SUCCESS";
const BATCH_DELETE_MODELS_ERROR = "BATCH_DELETE_MODELS_ERROR";

const DUPLICATE_MODEL_START = "DUPLICATE_MODEL_START";
const DUPLICATE_MODEL_SUCCESS = "DUPLICATE_MODEL_SUCCESS";
const DUPLICATE_MODEL_ERROR = "DUPLICATE_MODEL_ERROR";

const ADD_MODEL_TAG_START = "ADD_MODEL_TAG_START";
const ADD_MODEL_TAG_SUCCESS = "ADD_MODEL_TAG_SUCCESS";
const ADD_MODEL_TAG_ERROR = "ADD_MODEL_TAG_ERROR";
const REMOVE_MODEL_TAG_START = "REMOVE_MODEL_TAG_START";
const REMOVE_MODEL_TAG_SUCCESS = "REMOVE_MODEL_TAG_SUCCESS";
const REMOVE_MODEL_TAG_ERROR = "REMOVE_MODEL_TAG_ERROR";

const SHARE_FILE_ORG_START = "SHARE_FILE_ORG_START";
const SHARE_FILE_ORG_SUCCESS = "SHARE_FILE_ORG_SUCCESS";
const SHARE_FILE_ORG_ERROR = "SHARE_FILE_ORG_ERROR";
const TOGGLE_SHARE_FILE_ORG_LOADING = "TOGGLE_SHARE_FILE_ORG_LOADING";

const FLAG_MODEL_ERROR_START = "FLAG_MODEL_ERROR_START";
const FLAG_MODEL_ERROR_SUCCESS = "FLAG_MODEL_ERROR_SUCCESS";
const FLAG_MODEL_ERROR_ERROR = "FLAG_MODEL_ERROR_ERROR";

const ADD_MODEL_CHBA_COMMUNITY = "ADD_MODEL_CHBA_COMMUNITY";
const ADD_MODEL_CHBA_COMMUNITY_SUCCESS = "ADD_MODEL_CHBA_COMMUNITY_SUCCESS";
const ADD_MODEL_CHBA_COMMUNITY_ERROR = "ADD_MODEL_CHBA_COMMUNITY_ERROR";

const CHANGE_DATAENTRY_MODE = "CHANGE_DATAENTRY_MODE";
const ADD_TAKEOFF_COMP = "ADD_TAKEOFF_COMP";
const REMOVE_TAKEOFF_COMP = "REMOVE_TAKEOFF_COMP";
const CHANGE_TAKEOFF_FOUNDATION = "CHANGE_TAKEOFF_FOUNDATION";
const UPDATE_TAKEOFF_COMPONENT = "UPDATE_TAKEOFF_COMPONENT";
const SET_TAKEOFF_ERRORS = "SET_TAKEOFF_ERRORS";
const TRANSLATE_COMPONENT = "TRANSLATE_COMPONENT";
const ADD_TAKEOFF_SUBCOMP = "ADD_TAKEOFF_SUBCOMP";
const EDIT_TAKEOFF_SUBCOMP = "EDIT_TAKEOFF_SUBCOMP";
const REMOVE_TAKEOFF_SUBCOMP = "REMOVE_TAKEOFF_SUBCOMP";
const DUPLICATE_TAKEOFF_SUBCOMP = "DUPLICATE_TAKEOFF_SUBCOMP";
const ADD_TOFF_TABLE_COMP = "ADD_TOFF_TABLE_COMP";
const ADD_TOFF_TABLE_MULTI_ROW = "ADD_TOFF_TABLE_MULTI_ROW";
const EDIT_TOFF_TABLE_COMP = "EDIT_TOFF_TABLE_COMP";
const REMOVE_TOFF_TABLE_COMP = "REMOVE_TOFF_TABLE_COMP";
const SET_IS_DATA_CHANGED = "SET_IS_DATA_CHANGED";
const COUNT_BUILDING_SURFACE_AREA = "COUNT_BUILDING_SURFACE_AREA";
const SET_DIMENSIONS = "SET_DIMENSIONS";
const UPDATE_SPECIFICATIONS = "UPDATE_SPECIFICATIONS";
const SET_SAVE_TIMEOUT = "SET_SAVE_TIMEOUT";
const ROTATE_WINDOWS = "ROTATE_WINDOWS";

const UPDATE_MODEL_SNAPSHOT = "UPDATE_MODEL_SNAPSHOT";
const UPDATE_MODEL_SNAPSHOT_START = "UPDATE_MODEL_SNAPSHOT_START";
const UPDATE_MODEL_SNAPSHOT_SUCCESS = "UPDATE_MODEL_SNAPSHOT_SUCCESS";

export default {
    SET_MODEL_DATA,
    SET_MODEL_INFO,
    SET_MISSING_RESULTS,
    MODEL_LOADING,
    SET_MODEL_ERROR,
    SET_MISSING_CODES,
    CLEAR_MODEL_DATA,
    UPDATE_MODEL,
    MODEL_LOADING_END,
    SET_MODEL_CODE,
    SET_MODEL_COMPONENT,
    SET_MODEL_FILES,
    CLEAR_NEW_COMPONENTS,
    SET_EXPORT_ERROR,
    SET_FILE_UPLOAD_STATUS,
    UPDATE_CHECKLIST,
    UPDATE_MODEL_DETAILS,
    TOGGLE_CONTENT_OPEN,

    MOVE_MODEL_START,
    MOVE_MODEL_SUCCESS,
    MOVE_MODEL_ERROR,
    DUPLICATE_MODEL_START,
    DUPLICATE_MODEL_SUCCESS,
    DUPLICATE_MODEL_ERROR,
    ADD_MODEL_TAG_START,
    ADD_MODEL_TAG_SUCCESS,
    ADD_MODEL_TAG_ERROR,
    REMOVE_MODEL_TAG_START,
    REMOVE_MODEL_TAG_SUCCESS,
    REMOVE_MODEL_TAG_ERROR,

    SHARE_FILE_ORG_START,
    SHARE_FILE_ORG_SUCCESS,
    SHARE_FILE_ORG_ERROR,
    TOGGLE_SHARE_FILE_ORG_LOADING,

    FLAG_MODEL_ERROR_START,
    FLAG_MODEL_ERROR_SUCCESS,
    FLAG_MODEL_ERROR_ERROR,

    ADD_MODEL_CHBA_COMMUNITY,
    ADD_MODEL_CHBA_COMMUNITY_SUCCESS,
    ADD_MODEL_CHBA_COMMUNITY_ERROR,

    CHANGE_DATAENTRY_MODE,
    ADD_TAKEOFF_COMP,
    REMOVE_TAKEOFF_COMP,
    CHANGE_TAKEOFF_FOUNDATION,
    UPDATE_TAKEOFF_COMPONENT,
    SET_TAKEOFF_ERRORS,
    TRANSLATE_COMPONENT,
    ADD_TAKEOFF_SUBCOMP,
    EDIT_TAKEOFF_SUBCOMP,
    REMOVE_TAKEOFF_SUBCOMP,
    DUPLICATE_TAKEOFF_SUBCOMP,
    ADD_TOFF_TABLE_COMP,
    ADD_TOFF_TABLE_MULTI_ROW,
    EDIT_TOFF_TABLE_COMP,
    REMOVE_TOFF_TABLE_COMP,
    SET_IS_DATA_CHANGED,
    COUNT_BUILDING_SURFACE_AREA,
    SET_DIMENSIONS,
    UPDATE_SPECIFICATIONS,
    SET_SAVE_TIMEOUT,
    ROTATE_WINDOWS,
    BATCH_DELETE_MODELS_START,
    BATCH_DELETE_MODELS_SUCCESS,
    BATCH_DELETE_MODELS_ERROR,

    UPDATE_MODEL_SNAPSHOT,
    UPDATE_MODEL_SNAPSHOT_START,
    UPDATE_MODEL_SNAPSHOT_SUCCESS,
};
