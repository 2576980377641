import React from 'react';
import sharedClasses from 'features/Model/sharedStyles.module.scss';
import { Field } from 'redux-form';
import { getBaseUnits, getUnitOptions } from 'utils/fields';
import { getValidation, getDecimalPlaces } from 'utils/fieldValidation';
import InputRow from 'components/Input/Row';
import InputWithUnits from 'components/Input/InputWithUnits';
import Actions from '../Actions/container';
import Accordion from 'components/Accordion';

const atticTempValidation = getValidation('radiantAtticTemp');
const flatRoofTempValidation = getValidation('radiantFlatRoofTemp');
const crawlspaceTempValidation = getValidation('radiantCrawlspaceTemp');
const slabTempValidation = getValidation('radiantSlabTemp');
const basementFloorTempValidation = getValidation('radiantBasementFloorTemp');
const basementTempValidation = getValidation('radiantBasementTemp');
const atticAreaValidation = getValidation('radiantAtticArea');
const flatRoofAreaValidation = getValidation('radiantFlatRoofArea');
const crawlspaceAreaValidation = getValidation('radiantCrawlspaceArea');
const slabAreaValidation = getValidation('radiantSlabArea');
const basementFloorAreaValidation = getValidation('radiantBasementFloorArea');
const basementAreaValidation = getValidation('radiantBasementArea');

export default ({
    accessor,
    parentAccessor,
    atticTempUnits,
    atticAreaUnits,
    flatRoofTempUnits,
    flatRoofAreaUnits,
    crawlspaceTempUnits,
    crawlspaceAreaUnits,
    slabTempUnits,
    slabAreaUnits,
    basementFloorTempUnits,
    basementFloorAreaUnits,
    basementTempUnits,
    basementAreaUnits,
    modelUnits,
    change,
    contentOpen,
}) => {
    
    return (
        <>
            <Accordion
                className={sharedClasses.accordion}
                heading={<span className={sharedClasses.heading}>Radiant Heating</span>}
                large
                hoverActions={
                    <Actions
                        label="Radiant Heating"
                        type="radiantHeating"
                        accessor={accessor}
                        change={change}
                        parentAccessor={parentAccessor}
                    />
                }
                forceOpen={contentOpen}
            >
                <p style={{marginBottom:"1.25rem"}}>This section should be used to model the radiant heating provided by the <strong>primary</strong> heating system.</p>
                <InputRow gridTemplate="1fr 1fr 1fr">
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.attic.temperature`}
                        label="Attic Ceiling Temperature"
                        placeholder="0.00"
                        validate={atticTempValidation}
                        decimals={getDecimalPlaces('radiantAtticTemp')}
                        change={change}
                        units={{
                            base:getBaseUnits('radiantAtticTemp', modelUnits),
                            options:getUnitOptions('temperature'),
                            selected:atticTempUnits,
                            unitType:'temperature',
                            accessor:`${accessor}.attic.temperature_u`
                        }}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.attic.area`}
                        label="Attic Ceiling % of Total Area"
                        placeholder="0.00"
                        validate={atticAreaValidation}
                        decimals={getDecimalPlaces('radiantAtticArea')}
                        change={change}
                        units={{
                            base:getBaseUnits('radiantAtticArea', modelUnits),
                            options:getUnitOptions('fraction'),
                            selected:atticAreaUnits,
                            unitType:'fraction',
                            accessor:`${accessor}.attic.area_u`
                        }}
                        info={"Specify the percentage of the component's area that has radiant heating"}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr 1fr">
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.flatRoof.temperature`}
                        label="Flat Roof Temperature"
                        placeholder="0.00"
                        validate={flatRoofTempValidation}
                        decimals={getDecimalPlaces('radiantFlatRoofTemp')}
                        change={change}
                        units={{
                            base:getBaseUnits('radiantFlatRoofTemp', modelUnits),
                            options:getUnitOptions('temperature'),
                            selected:flatRoofTempUnits,
                            unitType:'temperature',
                            accessor:`${accessor}.flatRoof.temperature_u`
                        }}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.flatRoof.area`}
                        label="Flat Roof % of Total Area"
                        placeholder="0.00"
                        validate={flatRoofAreaValidation}
                        decimals={getDecimalPlaces('radiantFlatRoofArea')}
                        change={change}
                        units={{
                            base:getBaseUnits('radiantFlatRoofArea', modelUnits),
                            options:getUnitOptions('fraction'),
                            selected:flatRoofAreaUnits,
                            unitType:'fraction',
                            accessor:`${accessor}.flatRoof.area_u`
                        }}
                        info={"Specify the percentage of the component's area that has radiant heating"}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr 1fr">
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.aboveCrawlspace.temperature`}
                        label="Floor Above Crawlspace Temperature"
                        placeholder="0.00"
                        validate={crawlspaceTempValidation}
                        decimals={getDecimalPlaces('radiantCrawlspaceTemp')}
                        change={change}
                        units={{
                            base:getBaseUnits('radiantCrawlspaceTemp', modelUnits),
                            options:getUnitOptions('temperature'),
                            selected:crawlspaceTempUnits,
                            unitType:'temperature',
                            accessor:`${accessor}.aboveCrawlspace.temperature_u`
                        }}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.aboveCrawlspace.area`}
                        label="Floor Above Crawlspace % of Total Area"
                        placeholder="0.00"
                        validate={crawlspaceAreaValidation}
                        decimals={getDecimalPlaces('radiantCrawlspaceArea')}
                        change={change}
                        units={{
                            base:getBaseUnits('radiantCrawlspaceArea', modelUnits),
                            options:getUnitOptions('fraction'),
                            selected:crawlspaceAreaUnits,
                            unitType:'fraction',
                            accessor:`${accessor}.aboveCrawlspace.area_u`
                        }}
                        info={"Specify the percentage of the component's area that has radiant heating"}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr 1fr">
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.slabOnGrade.temperature`}
                        label="Slab-on-grade Temperature"
                        placeholder="0.00"
                        validate={slabTempValidation}
                        decimals={getDecimalPlaces('radiantSlabTemp')}
                        change={change}
                        units={{
                            base:getBaseUnits('radiantSlabTemp', modelUnits),
                            options:getUnitOptions('temperature'),
                            selected:slabTempUnits,
                            unitType:'temperature',
                            accessor:`${accessor}.slabOnGrade.temperature_u`
                        }}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.slabOnGrade.area`}
                        label="Slab-on-grade % of Total Area"
                        placeholder="0.00"
                        validate={slabAreaValidation}
                        decimals={getDecimalPlaces('radiantSlabArea')}
                        change={change}
                        units={{
                            base:getBaseUnits('radiantSlabArea', modelUnits),
                            options:getUnitOptions('fraction'),
                            selected:slabAreaUnits,
                            unitType:'fraction',
                            accessor:`${accessor}.slabOnGrade.area_u`
                        }}
                        info={"Specify the percentage of the component's area that has radiant heating"}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr 1fr">
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.aboveBasementFloor.temperature`}
                        label="Floor Above Basement Temperature"
                        placeholder="0.00"
                        validate={basementFloorTempValidation}
                        decimals={getDecimalPlaces('radiantBasementFloorTemp')}
                        change={change}
                        units={{
                            base:getBaseUnits('radiantBasementFloorTemp', modelUnits),
                            options:getUnitOptions('temperature'),
                            selected:basementFloorTempUnits,
                            unitType:'temperature',
                            accessor:`${accessor}.aboveBasementFloor.temperature_u`
                        }}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.aboveBasementFloor.area`}
                        label="Floor Above Basement % of Total Area"
                        placeholder="0.00"
                        validate={basementFloorAreaValidation}
                        decimals={getDecimalPlaces('radiantBasementFloorArea')}
                        change={change}
                        units={{
                            base:getBaseUnits('radiantBasementFloorArea', modelUnits),
                            options:getUnitOptions('fraction'),
                            selected:basementFloorAreaUnits,
                            unitType:'fraction',
                            accessor:`${accessor}.aboveBasementFloor.area_u`
                        }}
                        info={"Specify the percentage of the component's area that has radiant heating"}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr 1fr">
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.basement.temperature`}
                        label="Basement Temperature"
                        placeholder="0.00"
                        validate={basementTempValidation}
                        decimals={getDecimalPlaces('radiantBasementTemp')}
                        change={change}
                        units={{
                            base:getBaseUnits('radiantBasementTemp', modelUnits),
                            options:getUnitOptions('temperature'),
                            selected:basementTempUnits,
                            unitType:'temperature',
                            accessor:`${accessor}.basement.temperature_u`
                        }}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.basement.area`}
                        label="Basement % of Total Area"
                        placeholder="0.00"
                        validate={basementAreaValidation}
                        decimals={getDecimalPlaces('radiantBasementArea')}
                        change={change}
                        units={{
                            base:getBaseUnits('radiantBasementArea', modelUnits),
                            options:getUnitOptions('fraction'),
                            selected:basementAreaUnits,
                            unitType:'fraction',
                            accessor:`${accessor}.basement.area_u`
                        }}
                        info={"Specify the percentage of the component's area that has radiant heating"}
                    />
                </InputRow>
            </Accordion>
        </>
    )
};