const { getNetTotalEnergyConsumption } = require("utils/results");

//Overall % improvement calculated w/o baseloads
//AEC is without baseloads [GJ/y]
//meui w/o baseloads, as normal [kWh/m2/y]
//teui, same as EUI [GJ/m2/year]
const chbaNzAlternateComplianceTargets = {
    climateZone4: {
        above300m3: { overallPercentImprovement: 60, aec: 14, meui: 25, teui: 0.2 },
        below300m3: { overallPercentImprovement: 55, aec: 13, meui: 30, teui: 0.23 },
    },
    climateZone5: {
        above300m3: { overallPercentImprovement: 60, aec: 16, meui: 27, teui: 0.21 },
        below300m3: { overallPercentImprovement: 55, aec: 15, meui: 32, teui: 0.24 },
    },
    climateZone6: {
        above300m3: { overallPercentImprovement: 60, aec: 18, meui: 29, teui: 0.22 },
        below300m3: { overallPercentImprovement: 55, aec: 17, meui: 34, teui: 0.25 },
    },
    climateZone7a7b8: {
        above300m3: { overallPercentImprovement: 60, aec: 20, meui: 31, teui: 0.23 },
        below300m3: { overallPercentImprovement: 55, aec: 19, meui: 36, teui: 0.26 },
    },
};

export const getChbaNzAlternateCompliance = (
    propResults = {},
    refResults = {},
    totalHouseVolume = 500,
    totalFloorArea = 0,
    hdd,
    houseTypeId
) => {
    let climateZone = "climateZone7a7b8";
    if (hdd < 3000) {
        climateZone = "climateZone4";
    } else if (hdd <= 3999) {
        climateZone = "climateZone5";
    } else if (hdd <= 4999) {
        climateZone = "climateZone6";
    }

    const volumeCategory = totalHouseVolume >= 300 ? "above300m3" : "below300m3";

    const {
        Annual: { Consumption: { Electrical: { _attributes: { baseload: propBaseload = 0 } = {} } = {} } = {} } = {},
    } = propResults;

    const {
        Annual: { Consumption: { Electrical: { _attributes: { baseload: refBaseload = 0 } = {} } = {} } = {} } = {},
    } = refResults;

    const { annualEnergyConsumption: proposedAec = 0 } = getNetTotalEnergyConsumption(propResults);
    const { annualEnergyConsumption: refAec = 0 } = getNetTotalEnergyConsumption(refResults);

    const propNoBaseloadAec = proposedAec - propBaseload;
    const refNoBaseloadAec = refAec - refBaseload;

    const overallPercentDiff = (100 * (refNoBaseloadAec - propNoBaseloadAec)) / refNoBaseloadAec;

    const propMeui = (Math.max(0, propNoBaseloadAec) * (1 / 0.0036)) / totalFloorArea;

    const propEui = proposedAec / totalFloorArea;

    const complianceTargets = chbaNzAlternateComplianceTargets[climateZone][volumeCategory];

    const targetsPassed = {
        overallPercentImprovement: overallPercentDiff >= complianceTargets.overallPercentImprovement,
        aec: propNoBaseloadAec <= complianceTargets.aec,
        meui: propMeui <= complianceTargets.meui,
        teui: propEui <= complianceTargets.teui,
    };

    let passed = Object.values(targetsPassed).some((el) => el);

    const isDetached = [0, 6].includes(houseTypeId);

    const isAttached = [1, 2, 3, 4, 5, 7].includes(houseTypeId);

    const isMurbUnit = [8, 9, 10, 11, 12, 13].includes(houseTypeId);

    const volumeIneligibility =
        (isDetached && totalHouseVolume > 600) ||
        (isAttached && totalHouseVolume > 450) ||
        (isMurbUnit && totalHouseVolume > 600);

    return {
        passed,
        targetsPassed,
        volumeIneligibility,
    };
};
