import React from "react";

export default () => (
    <svg
        width="16px"
        height="16px"
        viewBox="0 0 16 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g fill="#39393C" fillRule="nonzero">
            <path
                d="M8,0 C12.418278,0 16,3.581722 16,8 C16,12.418278 12.418278,16 8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,0 8,0 Z M8,1.5 C4.41014913,1.5 1.5,4.41014913 1.5,8 C1.5,11.5898509 4.41014913,14.5 8,14.5 C11.5898509,14.5 14.5,11.5898509 14.5,8 C14.5,4.41014913 11.5898509,1.5 8,1.5 Z M7.98221093,10.0482846 C8.52096569,10.0482846 8.91740788,10.4752224 8.91740788,11.0241423 C8.91740788,11.5730623 8.52096569,12 7.98221093,12 C7.45362135,12 7.05717916,11.5730623 7.05717916,11.0241423 C7.05717916,10.4752224 7.45362135,10.0482846 7.98221093,10.0482846 Z M8.13468869,4 C9.3545108,4 10.249047,4.63024142 10.249047,5.93138501 C10.249047,7.37484117 8.48030496,7.86277001 8.63278272,9.29606099 L8.63278272,9.29606099 L7.33163914,9.29606099 C7.08767471,7.65946633 8.81575604,7.00889454 8.81575604,6.03303685 C8.81575604,5.52477764 8.48030496,5.19949174 7.95171537,5.19949174 C7.50444727,5.19949174 7.14866582,5.43329098 6.82337992,5.76874206 L6.82337992,5.76874206 L6,5.01651842 C6.52858958,4.41677255 7.25031766,4 8.13468869,4 Z"
                id="Oval-Copy"
            ></path>
        </g>
    </svg>
);
