import React from "react";

import { CloseDialog } from "components/Dialog";
import Button from "components/Button";
import Dialog from "components/Dialog";
import Input from "components/Input";

import classes from "../style.module.scss";

const DuplicateDialog = ({
    close,
    modelToDuplicate,
    newDuplicateName,
    setDuplicateName,
    onClick,
    duplicateLoading,
}) => (
    <Dialog open={!!modelToDuplicate}>
        <CloseDialog closeActions={() => close()} />
        <div className={classes.dialog}>
            <h2>Duplicate Model</h2>
            <Input
                label={"Model Name"}
                placeholder="Type model name..."
                disabled={duplicateLoading}
                input={{
                    value: newDuplicateName,
                    onChange: (value) => setDuplicateName(value),
                }}
            />
            <div className={classes.footer}>
                <Button type="hollow" onClick={() => close()}>
                    Cancel
                </Button>
                <Button onClick={onClick} type="" disabled={newDuplicateName === ""}>
                    {duplicateLoading ? "Duplicating..." : "Duplicate"}
                </Button>
            </div>
        </div>
    </Dialog>
);

export default DuplicateDialog;
