import { connect } from 'react-redux';
import CodeLibrary from './';
import { actions as userActions } from 'store/users';

const { fetchUserCodeLib, saveUserMeta } = userActions;

const mapStateToProps = ({
    user: {
        uid,
        userMeta:{
            rValueUnits="rsi"
        }={}
    }
}) => ({
    uid,
    rValueUnits
});

const mapDispatchToProps = (dispatch) => ({
    fetchUserCodeLib: (uid) => dispatch(fetchUserCodeLib(uid)),
    saveUserMeta: (uid, userMeta) => dispatch(saveUserMeta({ uid, userMeta }))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CodeLibrary);