import React, { useState, useEffect } from 'react';
import sharedClasses from 'features/Model/sharedStyles.module.scss';
import classes from './style.module.scss';
import { Field } from 'redux-form';
import isEmpty from 'lodash/isEmpty';
import Button from 'components/Button';
import Table from "components/Table";
import Select from 'components/Input/Select';
import Arrow from 'assets/images/icons/JSX/arrow-down';
import Drawer from 'components/Drawer';
import AirtightnessDetails from './AirtightnessDetails';

const createDataRow = (compType, modelDataTable, standardDataTable, standardOpts) => {
    const {
        complianceHeatingType = 'spaceHeatingAbove92',
        compPackage = 'A1'
    } = standardOpts;

    const selectedStandard = standardDataTable[complianceHeatingType][compPackage] || {};
    //Component name labels
    const compMap = {
        ceiling: {
            label: 'Ceilings with Attic Space',
            modelKey: 'ceiling.main.nomRVal',
            stdKey: 'ceilingAttic.nomValue',
            metric: 'RSI (R)',
            conversion: 5.67826
        },
        ceilingFlat: {
            label: 'Ceilings without Attic Space',
            modelKey: 'ceilingFlat.main.nomRVal',
            stdKey: 'ceilingNoAttic.nomValue',
            metric: 'RSI (R)',
            conversion: 5.67826
        },
        expFloor: {
            label: 'Exposed Floors',
            modelKey: 'expFloor.main.nomRVal',
            stdKey: 'expFloor.nomValue',
            metric: 'RSI (R)',
            conversion: 5.67826
        },
        wall: {
            label: 'Walls',
            modelKey: 'wall.main.nomRVal',
            stdKey: 'wall.nomValue',
            metric: 'RSI (R)',
            conversion: 5.67826
        },
        basementWall: {
            label: 'Basement Walls',
            modelKey: 'basementWall.main.nomRVal',
            stdKey: 'basementWall.nomValue',
            metric: 'RSI (R)',
            conversion: 5.67826
        },
        slabBelowGrade: {
            label: 'Slab Below Grade',
            modelKey: 'slabBelowGrade.main.nomRVal',
            stdKey: 'slabBelowGrade.nomValue',
            metric: 'RSI (R)',
            conversion: 5.67826
        },
        window: {
            label: 'Windows and Sliding Glass Doors',
            modelKey: 'windows.main.uValue',
            stdKey: 'windows.uValue',
            metric: 'U-Value'
        }
    }

    //Grab modelled value from table
    let modelledVal = compMap[compType].modelKey.split('.').reduce((acc,curr) => {
        return acc[curr] || {};
    }, modelDataTable) || 0;    
    
    //Convert to display string
    let modelledDisplay = '-'
    if (typeof modelledVal === 'number' & !Number.isNaN(modelledVal)) {
        modelledDisplay = modelledVal.toFixed(2);
        if (compMap[compType].metric === 'RSI (R)') {
            modelledDisplay = `${modelledVal} (R${(modelledVal * 5.67826).toFixed(0)})`
        }
    } else {
        modelledVal = 0
        modelledDisplay = '-';
    }

    //Grab the standard value from the compliance object
    let stdVal = compMap[compType].stdKey.split('.').reduce((acc,curr) => {
        return acc[curr] || {};
    }, selectedStandard);

    //Check for continuous insulation requirement
    let stdContinuousVal = `${compMap[compType].stdKey.split('.nomValue')[0]}.continuous`.split('.').reduce((acc,curr) => {
        return acc[curr] || {};
    }, selectedStandard);

    //Check for notes
    let stdNotes = `${compMap[compType].stdKey.split('.nomValue')[0]}.notes`.split('.').reduce((acc,curr) => {
        return acc[curr] || {};
    }, selectedStandard);

    //Convert to display string. Must check a variety of cases
    let stdDisplay = '-'
    if ((typeof stdVal === 'number' & !Number.isNaN(stdVal)) && (typeof stdContinuousVal === 'number' & !Number.isNaN(stdContinuousVal))) {
        stdDisplay = stdVal.toFixed(2);
        if (compMap[compType].metric === 'RSI (R)') {
            stdDisplay = `${stdVal} + ${stdContinuousVal} ci (R${(stdVal * 5.67826).toFixed(0)} + ${(stdContinuousVal * 5.67826).toFixed(0)} ci)`
        }
    } else if ((typeof stdVal === 'number' & !Number.isNaN(stdVal))) {
        stdDisplay = stdVal.toFixed(2);
        if (compMap[compType].metric === 'RSI (R)') {
            stdDisplay = `${stdVal} (R${(stdVal * 5.67826).toFixed(0)})`
        }
    } else if ((typeof stdContinuousVal === 'number' & !Number.isNaN(stdContinuousVal))) {
        stdDisplay = `${stdContinuousVal} ci (R${(stdContinuousVal * 5.67826).toFixed(0)} ci)`
    } else {
        stdDisplay = '-';
        stdVal = 0;
    }

    //Add notes if applicable
    if (!isEmpty(stdNotes)) {
        stdDisplay = stdDisplay + `, ${stdNotes}`;
    }


    return { 
        compName: compMap[compType].label, 
        metric: compMap[compType].metric, 
        modelledVal, 
        modelledDisplay,
        stdVal, 
        stdDisplay,
    }
}

const createAirtightnessRow = (modelled, standardDataTable, standardOpts) => {
    const {
        complianceHeatingType = '',
        compPackage = '',
        houseType = 'detached'
    } = standardOpts;

    if (!complianceHeatingType || !compPackage){
        return { 
            compName: 'Airtightness Target for Substitutions', 
            metric: 'ACH50', 
            modelledVal: modelled,
            modelledDisplay: `${modelled}`, 
            stdVal:0,
            stdDisplay:'-'
        }
    }

    const selectedStandard = standardDataTable[complianceHeatingType][compPackage] || {};

    let stdVal = 0;
    let stdDisplay = '-'
    if (!isEmpty(selectedStandard)) {
        stdVal = selectedStandard.airtightness[houseType].ach50;
        stdDisplay = `${stdVal.toFixed(2)}`;
    }
    
    return { 
        compName: 'Airtightness Target for Substitutions', 
        metric: 'ACH50', 
        modelledVal: modelled,
        modelledDisplay: `${modelled.toFixed(2)}`, 
        stdVal,
        stdDisplay
    }
}

const createSpaceHeatingRow = (heatingTableData, standardDataTable, standardOpts) => {
    if (isEmpty(heatingTableData)) {
        return { 
            compName: 'Space Heating Equipment', 
            metric: '%', 
            modelledVal: 0,
            modelledDisplay: '-', 
            stdVal: 0,
            stdDisplay: '-'
        }
    }

    const {
        complianceHeatingType = '',
        compPackage = ''
    } = standardOpts;

    const selectedStandard = standardDataTable[complianceHeatingType][compPackage] || {};

    if (heatingTableData.type === 'electricHeatPump') {
        let stdVal = 0;
        let stdDisplay = '-'
        if (!isEmpty(selectedStandard)) {
            stdVal = selectedStandard.spaceHeating.hspf;
            stdDisplay = stdVal != null ? stdVal.toFixed(1) : '-';
        }
        return {
            compName: 'Space Heating Equipment Efficiency (Heat Pump)', 
            metric: 'HSPF', 
            modelledVal: Math.round(heatingTableData.heatPumpHspf, 2),
            modelledDisplay: `${heatingTableData.heatPumpHspf.toFixed(1)}${heatingTableData.isCop ? '*' : ''}`, 
            stdVal,
            stdDisplay,
        }

    } else if (heatingTableData.type === 'electric') {
        return {
            compName: 'Space Heating Equipment Efficiency (Electric, non-heat pump)', 
            metric: '%', 
            modelledVal: Math.round(heatingTableData.heatingEff, 0),
            modelledDisplay: heatingTableData.heatingEff.toFixed(0), 
            stdVal: 0,
            stdDisplay: '-',
        }
    }

    //If neither case triggers then it's a gas appliance
    let stdVal = 0;
    let stdDisplay = '-'
    if (!isEmpty(selectedStandard)) {
        stdVal = selectedStandard.spaceHeating.afue;
        stdDisplay = stdVal != null ? stdVal.toFixed(0) : '-';
    }

    return { 
        compName: `Space Heating Equipment Efficiency (Combustion Appliance)`, 
        metric: 'AFUE', 
        modelledVal: Math.round(heatingTableData.heatingEff, 0),
        modelledDisplay: `${heatingTableData.heatingEff.toFixed(0)}${heatingTableData.isAFUE ? '' : '*'}`, 
        stdVal,
        stdDisplay,
    }
    
};

const createHrvRow = (hrvTableData={}, standardDataTable, standardOpts) => {
    if (isEmpty(hrvTableData) || !hrvTableData.hrvPresent) {
        return { 
            compName: 'HRV', 
            metric: '%', 
            modelledVal: 0,
            modelledDisplay: '-', 
            stdVal: 0,
            stdDisplay: '-'
        }
    }

    const {
        complianceHeatingType = '',
        compPackage = ''
    } = standardOpts;

    const selectedStandard = standardDataTable[complianceHeatingType][compPackage] || {};

    let stdVal = 0;
    let stdDisplay = '-'
    if (!isEmpty(selectedStandard)) {
        stdVal = selectedStandard.hrv.sre;
        stdDisplay = stdVal != null ? stdVal.toFixed(0) : '-';
    }

    return { 
        compName: 'HRV', 
        metric: '%', 
        modelledVal: Math.round(hrvTableData.hrvEff, 0),
        modelledDisplay: hrvTableData.hrvEff.toFixed(0), 
        stdVal,
        stdDisplay
    }

};

const createDhwRow = (dhwTableData={}, standardDataTable, standardOpts) => {
    if (isEmpty(dhwTableData) || !dhwTableData.dhwPresent) {
        return { 
            compName: 'Domestic Hot Water', 
            metric: 'EF', 
            modelledVal: 0,
            modelledDisplay: '-', 
            stdVal: 0,
            stdDisplay: '-'
        }
    }

    const {
        complianceHeatingType = '',
        compPackage = ''
    } = standardOpts;

    const selectedStandard = standardDataTable[complianceHeatingType][compPackage] || {};

    let stdVal = 0;
    let stdDisplay = '-'
    if (!isEmpty(selectedStandard)) {
        stdVal = selectedStandard.domesticHotWater.ef;
        stdDisplay = stdVal != null ? stdVal.toFixed(2) : '-';
    }

    if (dhwTableData.heatPumpCOP) {
        return { 
            compName: 'Domestic Hot Water', 
            metric: 'EF', 
            modelledVal: Math.round(dhwTableData.efValue, 2),
            modelledDisplay: `${dhwTableData.heatPumpCOP.toFixed(2)} (COP)`, 
            stdVal,
            stdDisplay
        }
    }

    return { 
        compName: 'Domestic Hot Water', 
        metric: 'EF', 
        modelledVal: Math.round(dhwTableData.efValue, 2),
        modelledDisplay: dhwTableData.efValue.toFixed(2), 
        stdVal,
        stdDisplay
    }
};

const getCompPackageLabel = (spaceHeatingType) => {
    switch (spaceHeatingType) {
        case 'spaceHeatingAbove92':
            return 'Space Heating Equipment with AFUE ≥ 92%';

        case 'spaceHeatingBelow92':
            return 'Space Heating Equipment with 84% ≤ AFUE < 92%';

        case 'electric':
            return 'Electric Space Heating';

        case 'electricHeatPump':
            return 'Electric Space Heating (Heat Pump)';

        default:
            return 'Error determining heating system';
    }
}

export default ({
    houseType,
    modelDataTable,
    obcClimateZoneData,
    airtightness50Pa,
    complianceHeatingType,
    mechTableData,
    selectedSb12Package,
    change,
}) => {
    const [open, toggleOpen] = useState(false);

    useEffect(() => {
        const {highLevel:{windowToWallRatio=0}={}} = modelDataTable;
        change(`modelData.dimensions.windowToWall.value`, parseFloat((100*windowToWallRatio).toFixed(3)));
    },[]);

    //Get package options
    const packageOptions = Object.keys(obcClimateZoneData[complianceHeatingType]).map(el => ({label: el, value: el}));

    //Build Rows
    const rows = [
        createDataRow('ceiling', modelDataTable, obcClimateZoneData, {complianceHeatingType,compPackage:selectedSb12Package}),
        createDataRow('ceilingFlat', modelDataTable, obcClimateZoneData, {complianceHeatingType,compPackage:selectedSb12Package}),
        createDataRow('expFloor', modelDataTable, obcClimateZoneData, {complianceHeatingType,compPackage:selectedSb12Package}),
        createDataRow('wall', modelDataTable, obcClimateZoneData, {complianceHeatingType,compPackage:selectedSb12Package}),
        createDataRow('basementWall', modelDataTable, obcClimateZoneData, {complianceHeatingType,compPackage:selectedSb12Package}),
        createDataRow('slabBelowGrade', modelDataTable, obcClimateZoneData, {complianceHeatingType,compPackage:selectedSb12Package}),
        createDataRow('window', modelDataTable, obcClimateZoneData, {complianceHeatingType,compPackage:selectedSb12Package}),
        createSpaceHeatingRow(mechTableData.heating, obcClimateZoneData, {complianceHeatingType,compPackage:selectedSb12Package}),
        createHrvRow(mechTableData.hrv, obcClimateZoneData, {complianceHeatingType,compPackage:selectedSb12Package}),
        createDhwRow(mechTableData.dhw, obcClimateZoneData, {complianceHeatingType,compPackage:selectedSb12Package}),
        createAirtightnessRow(airtightness50Pa, obcClimateZoneData, {complianceHeatingType,compPackage:selectedSb12Package,houseType}),
    ];

    const isSteadyState = mechTableData.heating.isAFUE === false;
    const isCop = mechTableData.heating.isCop === true;

    const getSpecialRow = (compName) => {
        if (compName === 'Airtightness Target for Substitutions') {
            return (
                <div className={classes.airtightnessDetails}>
                    <p>
                        Airtightness Target for Substitutions
                        <span onClick={()=>toggleOpen(true)}>See Details <Arrow /></span>
                    </p>
                </div>
            )
        } else {
            return compName;
        }
    };

    return (
        <>
            <div className={classes.complianceHeading}>
                <div>
                    <h4>Ontario Building Code SB-12 Compliance</h4>
                    <p>Select an <strong>SB-12 compliance package</strong> to compare to your model specification in the table below.</p>
                </div>
                <Field
                    className={classes.packageSelect}
                    component={Select}
                    name={'modelData.compliance.sb12.selectedPackage'}
                    options={[
                        {
                            label:"None",
                            value:""
                        },
                        ...packageOptions
                    ]}
                    label="SB-12 Compliance Package"
                    placeholder="Choose Compliance Package"
                />
            </div>

            <Table
                className={classes.summaryTable}
                headCells={[
                    { id:"component", label:"Component", align:"left"},
                    { id:"metric", label:"Metric", width:"130"},
                    { id:"package", label:`Compliance Package: ${selectedSb12Package || '-'}`, width:"300"},
                    { id:"modelled", label:"Modelled"},
                ]}
                rows={rows.map((row) => ({
                    component:{
                        value:row.compName,
                        content:row.compName,
                    },
                    metric:{
                        value:row.metric,
                        content:row.metric,
                    },
                    package:{
                        value:row.stdDisplay,
                        content:row.stdDisplay,
                    },
                    modelled:{
                        value:row.modelledDisplay,
                        content:row.modelledDisplay,
                    },
                }))}
            />
            {
                isCop ? 
                <>
                    <p>*The heat pump heating performance was defined using the COP. The modelled HSPF was estimated based on the given COP, and may not reflect the actual value.</p>
                </> : isSteadyState ? 
                <>
                    <p>*The heating system efficiency provided is the steady state efficiency, not the AFUE.</p>
                </> : <></>
            }
            <Drawer
                open={open}
                close={()=>toggleOpen(false)}
                preTitle="Code Compliance"
                title="Airtightness Substitutions"
                subtitle="SB-12 Section 3.1.1.4."
            >
                <AirtightnessDetails />
                <div className={classes.buttons}>
                    <Button
                        large
                        type="hollow"
                        onClick={()=>toggleOpen(false)}
                    >
                        Close
                    </Button>
                </div>
            </Drawer>
        </>
    )
}

