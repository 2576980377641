import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import { Field } from "redux-form";
import { getBaseUnits, unitLabels } from "utils/fields";
import { getValidation, getDecimalPlaces, maxLength } from "utils/fieldValidation";
import convertUnit from "utils/convertUnit";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import InputWithUnits from "components/Input/InputWithUnits";
import Input from "components/Input";
import Select from "components/Input/Select";
import Button from "components/Button";
import Delete from "assets/images/icons/JSX/Delete";
import Drawing from "assets/images/icons/JSX/Drawing";
import Tooltip from "components/Tooltip";
import Checkbox from "components/Input/Checkbox";
import InfoTooltip from "components/InfoTooltip";
import { mixpanel } from "components/Mixpanel";

const agFloorAreaValidation = getValidation("agHeatedFloorArea");
const bgFloorAreaValidation = getValidation("bgHeatedFloorArea");
const commonFloorAreaValidation = getValidation("commonHeatedFloorArea");
const nonResFloorAreaValidation = getValidation("nonResHeatedFloorArea");
const volumeValidation = getValidation("totalHouseVolume");

const charLim200 = maxLength(200);

const Line = ({
    description = "",
    value = 0,
    selectedUnit,
    unitType,
    trueBase,
    isTotal = false,
    id,
    handleDeleteItem,
    isDrawing = false,
}) => {
    const newDisplayValue = parseFloat(
        convertUnit({
            value: parseFloat(value),
            type: unitType,
            inputUnit: trueBase,
            outputUnit: selectedUnit,
        })
    ).toFixed(2);

    return (
        <div className={classes.lineItem}>
            <span className={classes.lineName}>{description}</span>
            <span className={classes.value}>{`${newDisplayValue} ${unitLabels(selectedUnit)}`}</span>
            {!isTotal && !isDrawing && (
                <span className={classes.delete} onClick={() => handleDeleteItem(id)} data-for={id} data-tip="Remove">
                    <Delete />
                </span>
            )}
            {!isTotal && isDrawing && (
                <span
                    className={classes.drawing}
                    data-for={id}
                    data-tip="<span style='display:block;max-width:12rem'>This value may be edited in the <strong>Drawing Capture</strong> tool.</span>"
                    data-html
                >
                    <Drawing />
                </span>
            )}
            <Tooltip id={id} delay={!isDrawing} />
        </div>
    );
};

export default ({
    accessor,
    fieldKey,
    lineItemValues,
    lineItemTotal,
    lineItemUnits,
    modelUnits,
    change,
    floorAreaItems,
    hasFloorItems,
    drawerName,
}) => {
    const [itemValue, toggleItemValue] = useState(0);
    const [itemDescription, toggleItemDescription] = useState("");
    const [itemOperation, toggleItemOperation] = useState(true);
    const [useFloor, toggleUseFloor] = useState(false);

    const lengthUnits = lineItemUnits === "ft3" || (modelUnits !== "Metric" && !lineItemUnits) ? "ft" : "m";
    const areaUnits = lineItemUnits === "ft3" || (modelUnits !== "Metric" && !lineItemUnits) ? "ft2" : "m2";

    useEffect(() => {
        let newTotal = 0;
        if (!isEmpty(lineItemValues)) {
            newTotal = Object.values(lineItemValues).reduce((acc, curr) => acc + curr.value, 0);
        }

        if (newTotal.toFixed(2) !== lineItemTotal.toFixed(2)) {
            change(`${accessor}.total`, newTotal >= 0 ? parseFloat(newTotal.toFixed(4)) : 0);
        }
    }, [lineItemValues, lineItemTotal]);

    const handleAddItem = () => {
        if (itemDescription && itemValue > 0) {
            change(`${accessor}.items.item${moment().format("YYYYMMDDHHmmssSS")}`, {
                enabled: true,
                description: itemDescription,
                value: itemOperation ? Math.abs(itemValue) : -1 * Math.abs(itemValue),
            });
            toggleItemDescription("");
            toggleItemValue(0);
            mixpanel.track("Add Summary Item", {
                "Drawer Name": drawerName,
            });
        }
    };

    const handleAddItemByFloorArea = () => {
        if (itemDescription && itemValue > 0) {
            const areaDisplayValue = parseFloat(
                convertUnit({
                    value: floorAreaItems[itemDescription].value,
                    type: "area",
                    inputUnit: "m2",
                    outputUnit: areaUnits,
                })
            ).toFixed(2);

            const heightDisplayValue = parseFloat(
                convertUnit({
                    value: itemValue,
                    type: "length",
                    inputUnit: "m",
                    outputUnit: lengthUnits,
                })
            ).toFixed(2);

            change(`${accessor}.items.item${moment().format("YYYYMMDDHHmmssSS")}`, {
                enabled: true,
                description: `${floorAreaItems[itemDescription].description}: ${areaDisplayValue} ${unitLabels(
                    areaUnits
                )} at ${heightDisplayValue} ${unitLabels(lengthUnits)}`,
                value: itemOperation
                    ? Math.abs(itemValue * floorAreaItems[itemDescription].value)
                    : -1 * Math.abs(itemValue * floorAreaItems[itemDescription].value),
            });
            toggleItemDescription("");
            toggleItemValue(0);
        }
    };

    const handleDeleteItem = (id) => {
        const filteredItems = Object.entries(lineItemValues).reduce((acc, [key, val]) => {
            if (key === id) {
                return acc;
            }
            return {
                ...acc,
                [key]: val,
            };
        }, {});

        change(`${accessor}.items`, filteredItems);
    };

    const getUnitType = {
        agHeatedFloorArea: "area",
        bgHeatedFloorArea: "area",
        commonHeatedFloorArea: "area",
        nonResHeatedFloorArea: "area",
        totalHouseVolume: "volume",
    }[fieldKey];

    const selectValidation = {
        agHeatedFloorArea: agFloorAreaValidation,
        bgHeatedFloorArea: bgFloorAreaValidation,
        commonHeatedFloorArea: commonFloorAreaValidation,
        nonResHeatedFloorArea: nonResFloorAreaValidation,
        totalHouseVolume: volumeValidation,
    }[fieldKey];

    const floorAreaOpts = () =>
        Object.entries(floorAreaItems).reduce((acc, [key, item]) => {
            //Note this is only called if getUnitType === 'volume'
            if (item == null) return acc;

            const itemDisplayValue = parseFloat(
                convertUnit({
                    value: parseFloat(item.value),
                    type: "area",
                    inputUnit: "m2",
                    outputUnit: areaUnits,
                })
            ).toFixed(2);

            return [
                ...acc,
                {
                    label: `${item.description}: ${Math.abs(itemDisplayValue)} ${unitLabels(areaUnits)}`,
                    value: key,
                },
            ];
        }, []);

    return (
        <div className={classes.lineItems}>
            <h3>Calculation Summary</h3>
            <div>
                {isEmpty(lineItemValues) ? (
                    <>
                        <p>No items found. Enter a value and description below and click "Add" to get started.</p>
                    </>
                ) : (
                    Object.entries(lineItemValues).map(([key, value]) => (
                        <Line
                            description={value.description}
                            value={value.value}
                            selectedUnit={lineItemUnits || getBaseUnits(fieldKey, modelUnits).displayBase}
                            unitType={getUnitType}
                            trueBase={getBaseUnits(fieldKey, modelUnits).trueBase}
                            key={key}
                            id={key}
                            handleDeleteItem={handleDeleteItem}
                            isDrawing={value.isDrawing}
                        />
                    ))
                )}
            </div>
            <div className={classes.total}>
                <Line
                    accessor={accessor}
                    description={"Total"}
                    value={lineItemTotal}
                    selectedUnit={lineItemUnits || getBaseUnits(fieldKey, modelUnits).displayBase}
                    unitType={getUnitType}
                    trueBase={getBaseUnits(fieldKey, modelUnits).trueBase}
                    change={change}
                    isTotal={true}
                />
            </div>
            <div className={classes.adjustCalc}>
                <span className={classes.adjustCalcHeader}>
                    <h4>Adjust Calculation</h4>
                    <InfoTooltip
                        className={classes.info}
                        info="Enter a description and value, and choose whether to add to or subtract from the overall total."
                    />
                </span>
                {getUnitType === "volume" && hasFloorItems && (
                    <span className={classes.adjustToggleWrapper}>
                        <Checkbox
                            className={classes.adjustToggle}
                            name="adjustCalc"
                            label="Calculate using floor area and height"
                            input={{
                                onChange: (event) => toggleUseFloor(!useFloor),
                                value: useFloor,
                            }}
                        />
                        <InfoTooltip
                            className={classes.info}
                            info="Multiply a heated floor area segment by a height and apply the resulting value to the overall house volume."
                        />
                    </span>
                )}
                {!useFloor && (
                    <div className={classes.calcRow}>
                        <Input
                            name={"itemDescription"}
                            label="Description"
                            type="text"
                            placeholder="Enter Description"
                            validate={charLim200}
                            input={{
                                onChange: (value) => {
                                    toggleItemDescription(value);
                                },
                                value: itemDescription,
                            }}
                        />
                        <Field
                            component={Select}
                            name={"itemOperation"}
                            options={[
                                {
                                    label: "+",
                                    value: true,
                                },
                                {
                                    label: "-",
                                    value: false,
                                },
                            ]}
                            label="Operation"
                            placeholder="+"
                            input={{
                                onChange: (value) => {
                                    toggleItemOperation(value);
                                },
                                value: itemOperation,
                            }}
                        />
                        <InputWithUnits
                            type="number"
                            name={"itemValue"}
                            label="Value"
                            placeholder="0.00"
                            validate={selectValidation}
                            decimals={2}
                            change={change}
                            setValue={itemValue}
                            units={{
                                base: getBaseUnits(fieldKey, modelUnits),
                                options: [lineItemUnits],
                                selected: lineItemUnits,
                                unitType: getUnitType,
                                accessor: `${accessor}_u`,
                            }}
                            input={{
                                onChange: (value) => {
                                    toggleItemValue(value);
                                },
                                value: itemValue,
                            }}
                            className={classes.valueInput}
                        />
                        <Button onClick={() => handleAddItem()} disabled={!itemDescription || !(itemValue > 0)}>
                            Add
                        </Button>
                    </div>
                )}
                {getUnitType === "volume" && hasFloorItems && useFloor && (
                    <div className={classes.calcRow}>
                        <Select
                            name={"itemDescription"}
                            type="text"
                            label="Floor Area Item"
                            placeholder="Select Floor Area Item"
                            validate={charLim200}
                            options={floorAreaOpts()}
                            input={{
                                onChange: (value) => {
                                    toggleItemDescription(value);
                                },
                                value: itemDescription,
                            }}
                        />
                        <Field
                            component={Select}
                            name={"itemOperation"}
                            options={[
                                {
                                    label: "+",
                                    value: true,
                                },
                                {
                                    label: "-",
                                    value: false,
                                },
                            ]}
                            label="Operation"
                            placeholder="+"
                            input={{
                                onChange: (value) => {
                                    toggleItemOperation(value);
                                },
                                value: itemOperation,
                            }}
                        />
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={"itemValue"}
                            label="Height"
                            placeholder="0.00"
                            validate={selectValidation}
                            decimals={2}
                            change={change}
                            setValue={itemValue}
                            className={classes.valueInput}
                            units={{
                                base: getBaseUnits("agHeightHighestCeiling", modelUnits),
                                options: [lengthUnits],
                                selected: [lengthUnits],
                                unitType: "length",
                                accessor: `${accessor}_u`,
                            }}
                            input={{
                                onChange: (value) => {
                                    toggleItemValue(value);
                                },
                                value: itemValue,
                            }}
                        />
                        <Button
                            onClick={() => handleAddItemByFloorArea()}
                            disabled={!itemDescription || !(itemValue > 0)}
                        >
                            Add
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};
