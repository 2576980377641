import React from 'react';
import moment from 'moment';
import InputRow from 'components/Input/Row';
import Button from 'components/Button';
import classes from './style.module.scss';

export default React.memo(({
    h2kInfo,
    change
}) => {
    const handleAddInfoItem = ({id = '', value = ''}) => {
        change(`modelData.information.h2kInfo.info-${moment().format('YYYYMMDDHHmmssSS')}`, {id, value});
    };

    return (
        <div className={classes.requirements}>
            <InputRow gridTemplate="1fr 2fr 4fr">
                <h5>Info ID</h5>
                <h5>Required Entry</h5>
                <h5>Description of Use</h5>
            </InputRow>
            <InputRow gridTemplate="1fr 2fr 3fr 1fr">
                <p>Info. 2</p>
                <p>Program Identifier or NPI (files not linked to a partner program) Exception: Quebec</p>
                <p>To be used when a file pertains to a specific partner program. Reference the Program Identifier List on the <b>members-only resource center for specific program identifiers</b>.</p>
                <Button small onClick={()=>handleAddInfoItem({id: 'Info. 2', value: ''})}>
                    Add
                </Button>
            </InputRow>
            <InputRow gridTemplate="1fr 2fr 3fr 1fr">
                <p>Info. 5</p>
                <p>EHR</p>
                <p>To be used when an <b>emergency heating replacement policy</b> is used by a partner program.</p>
                <Button small onClick={()=>handleAddInfoItem({id: 'Info. 5', value: 'EHR'})}>
                    Add
                </Button>
            </InputRow>
            <InputRow gridTemplate="1fr 2fr 3fr 1fr">
                <p>Info. 5</p>
                <p>NEEP</p>
                <p>To be used when modelling a <b>cold climate air source heat pump</b> (CCASHP).</p>
                <Button small onClick={()=>handleAddInfoItem({id: 'Info. 5', value: 'NEEP'})}>
                    Add
                </Button>
            </InputRow>
            <InputRow gridTemplate="1fr 2fr 3fr 1fr">
                <p>Info. 6</p>
                <p>Air source heat pump manufacturer name, AHRI number and evaporator/condenser coil model numbers</p>
                <p>To be used when <b>more than one air source heat pump</b> is modelled.</p>
                <Button small onClick={()=>handleAddInfoItem({id: 'Info. 6', value: ''})}>
                    Add
                </Button>
            </InputRow>
            <InputRow gridTemplate="1fr 2fr 3fr 1fr">
                <p>Info. 6</p>
                <p>NEEA</p>
                <p>To be used when modelling a <b>heat pump water heater</b> that extracts heat from outside air.</p>
                <Button small onClick={()=>handleAddInfoItem({id: 'Info. 6', value: 'NEEA'})}>
                    Add
                </Button>
            </InputRow>
            <InputRow gridTemplate="1fr 2fr 3fr 1fr">
                <p>Info. 8</p>
                <p>CODECO</p>
                <p>To be used for all <b>P</b> and <b>N</b> files submitted for <b>building code compliance</b> purposes.</p>
                <Button small onClick={()=>handleAddInfoItem({id: 'Info. 8', value: 'CODECO'})}>
                    Add
                </Button>
            </InputRow>
            <InputRow gridTemplate="1fr 2fr 3fr 1fr">
                <p>Info. 9</p>
                <p>Double</p>
                <p>To be used to indicate that a file is a <b>duplicate baseline file</b>.</p>
                <Button small onClick={()=>handleAddInfoItem({id: 'Info. 9', value: 'Double'})}>
                    Add
                </Button>
            </InputRow>
            <InputRow gridTemplate="1fr 2fr 3fr 1fr">
                <p>Workaround</p>
                <p>IMS was modelled</p>
                <p>To be used when an <b>integrated mechanical system (IMS)</b> was modelled.</p>
                <Button small onClick={()=>handleAddInfoItem({id: 'Workaround', value: 'IMS was modelled'})}>
                    Add
                </Button>
            </InputRow>
            <InputRow gridTemplate="1fr 2fr 3fr 1fr">
                <p>Workaround</p>
                <p>Desuperheater was modelled</p>
                <p>To be used when a <b>desuperheater</b> was modelled.</p>
                <Button small small onClick={()=>handleAddInfoItem({id: 'Workaround', value: 'Desuperheater was modelled'})}>
                    Add
                </Button>
            </InputRow>
        </div>
    )
})