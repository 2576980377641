import React from 'react';
import { NoticeCard } from 'components/Card';
import ModelIcon from "assets/images/icons/model.svg";
import CommunityIcon from "assets/images/icons/community.svg";

export default ({
    children,
    className,
    color="#fff",
    icon,
}) => (
    <NoticeCard
        className={className}
        color={color}
        title="404 Page Not Found"
        icon={icon}
    >
        {children}
    </NoticeCard>
);

export const PageRestricted = ({ }) => (
    <NoticeCard
        title="This page could not be accessed"
    >
        <p>You may not have permission to view its content.</p>
        <p>If this problem persists please contact the software administrators.</p>
    </NoticeCard>
);

export const ModelRestricted = ({ }) => (
    <NoticeCard
        title="The data for this model could not be loaded"
        icon={ModelIcon}
    >
        <p>It may have been deleted, or you may not have permission to view its content.</p>
        <p>If this problem persists please contact the software administrators.</p>
    </NoticeCard>
);

export const CommunityRestricted = ({ }) => (
    <NoticeCard
        title="The data for this community could not be loaded"
        icon={CommunityIcon}
    >
        <p>It may have been deleted, or you may not have permission to view its content.</p>
        <p>If this problem persists please contact the software administrators.</p>
    </NoticeCard>
);