import { connect } from 'react-redux';
import { reduxForm, formValueSelector, reset } from 'redux-form';
import StandardLayers from 'features/Model/Enclosure/UserDefinedCodes/StandardLayers';
import uDefCodeTemplate from 'features/Model/Enclosure/Templates/standardUDefCode.json';
import { idIfyDate } from "utils";
import { actions as userActions } from 'store/users';
import { actions as enclosureActions } from 'features/Model/Enclosure/_ducks';
import isEmpty from 'lodash/isEmpty';
import { getNominalRValueUDef } from "utils/enclosure/getNominalRValue";

const { addToCodeLib } = userActions;
const { setInitCode } = enclosureActions;

const layerSort = (a, b) => {
    if(a.layer < b.layer) { return -1; }
    if(a.layer > b.layer) { return 1; }
    return 0;
};

const mapStateToProps = (
    {
        form,
        enclosure:{
            initCodeValues:{
                udefStandard={}
            }={}
        }={},
        user:{
            uid,
        },
    },
    {
        drawingFormChange,
        componentId,
        fieldAccessor,
        modelFormChange,
        componentType,
        handleUdefSave,
        drawingSave
    }
) => {
    const selector = formValueSelector('uDefCode');
    // const modelSelector = formValueSelector('model');

    const {
        continuousMedium: continuousMediumLayers = {},
        continuousInsulation: continuousInsulationLayers = {},
        woodFraming: woodFramingLayers = {},
        steelFraming: steelFramingLayers = {},
        strapping: strappingLayers = {},
    } = selector({form}, 'uDefCode.layers') || {};

    const medium = Object.keys(continuousMediumLayers).map((key) => ({
        ...continuousMediumLayers[key],
        id: key,
        type:"continuousMedium",
    }));

    const insulation = Object.keys(continuousInsulationLayers).map((key) => ({
        ...continuousInsulationLayers[key],
        id: key,
        type:"continuousInsulation",
    }));

    const woodFraming = Object.keys(woodFramingLayers).map((key) => ({
        ...woodFramingLayers[key],
        id: key,
        type:"woodFraming",
    }));

    const steelFraming = Object.keys(steelFramingLayers).map((key) => ({
        ...steelFramingLayers[key],
        id: key,
        type:"steelFraming",
    }));

    const strapping =  Object.keys(strappingLayers).map((key) => ({
        ...strappingLayers[key],
        id: key,
        type:"strapping",
    }));

    const codeLayers = [
        ...medium,
        ...insulation,
        ...woodFraming,
        ...steelFraming,
        ...strapping,
    ]
    .sort(layerSort);

    // const modelCodes = modelSelector({form}, 'modelData.codes') || {};
    const { codeRef:editingCodeRef=null, isLibCode=false } = udefStandard;

    return {
        componentType,
        initialValues:{
            uDefCode: isEmpty(udefStandard) ? uDefCodeTemplate : udefStandard,
            addToLibrary: isEmpty(udefStandard) ? true : isLibCode,
        },
        codeLayers,
        numLayers: codeLayers.length,
        modelFormChange,
        fieldAccessor,
        editingCodeRef,
        isLibCode,
        uid,
        drawingFormChange,
        componentId,
        handleUdefSave,
        drawingSave
    }
};

const mergeProps = (state, dispatch, own) => ({
    ...state,
    ...dispatch,
    ...own,
});

const onSubmit = async(
    form,
    dispatch,
    {
        editingCodeRef,
        modelFormChange,
        uid,
        componentType,
        drawingFormChange,
        fieldAccessor,
        handleUdefSave,
        drawingSave
    }
) => {
    const { uDefCode={}, addToLibrary=false } = form;
    const { label="" } = uDefCode;


    //Close drawer. Do this in the beginning to speed up the process. 
    //Affected fields are not editable, so there should be no chance of conflict.
    //Must add loading state to r-value field (@ fieldAccessor)
    handleUdefSave();

    // ***************************************
    // 1. Create and change code ref
    // ***************************************
    const newCodeRef = `${componentType}-U-${idIfyDate()}`;
    const setCodeRef = editingCodeRef || newCodeRef;

    // ***************************************
    // 2. Fetch rValue
    // ***************************************
    const nomRValue = getNominalRValueUDef(uDefCode);

    const code = {
        ...uDefCode,
        codeRef:setCodeRef,
        nominalRValue:nomRValue,
    };

    // ***************************************
    // 3. If code value is already in model, update using existing ref
    // ***************************************
    modelFormChange(`modelData.codes.${setCodeRef}`, code);

    // ***************************************
    // 4. Do drawing things
    // ***************************************
    const fieldValue = {
        codeLabel:label,
        codeRef:setCodeRef,
    };

    const warningType = 'none';
    //Add warning tag to field
    if (warningType === 'compression') {
        drawingFormChange(`${fieldAccessor}_warning`, 'compression');
    } else {
        drawingFormChange(`${fieldAccessor}_warning`, '');
    }
    
    // Update model "wall type" field in wall
    drawingFormChange(fieldAccessor, fieldValue);
    drawingFormChange(`${fieldAccessor}_nomRVal`, nomRValue);
    drawingFormChange(`${fieldAccessor}_effRVal`, nomRValue);

    if (addToLibrary) {
        await dispatch(addToCodeLib(
            uid,
            {
                ...uDefCode,
                nominalRValue:nomRValue,
                label,
                codeType:"User Defined",
                componentType:componentType,
                codeRef:setCodeRef,
            },
            componentType,
            setCodeRef
        ));
    }

    drawingSave();

    // Clear udef code defaults for next time
    dispatch(setInitCode({}, 'udefStandard'));
    // Reset form
    dispatch(reset('uDefCode'));
}

const mapDispatchToProps = dispatch => ({});

const form = reduxForm({
    form:"uDefCode",
    enableReinitialize: true,
    onSubmit:onSubmit
})(StandardLayers);


export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(form);