import React, { useCallback, useEffect, useState } from "react";
import { MODEL_COLL } from "_firebase";
import globalStyles from "components/globalStyles.module.scss";
import classes from "../style.module.scss";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import InputFile from "components/Input/FileUpload";
import Checkbox from "components/Input/Checkbox";
import { isEmpty, isEqual } from "lodash";

const RetrofitBatchEcms = ({ ecmInputMap, setEcmInputMap, disabledFields, params, setParams }) => {
    return (
        <div className={classes.ecmListContainer}>
            <InputRow gridTemplate="1fr 1fr">
                {/* Attic */}
                <div className={classes.checkboxContainer}>
                    <h4 style={{ marginBottom: "0.5rem" }}>Ceilings with Attic</h4>
                    <Checkbox
                        name="atticNoChange"
                        label="No Change"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-AtticCeilings"]: {
                                        ...prevState["Opt-AtticCeilings"],
                                        ["None;Env-1NC"]: !prevState["Opt-AtticCeilings"]["None;Env-1NC"],
                                    },
                                })),
                            value: ecmInputMap["Opt-AtticCeilings"]["None;Env-1NC"],
                        }}
                        disabled={disabledFields["None;Env-1NC"]}
                    />
                    <Checkbox
                        name="atticAddR20"
                        label="Add R-20 Blown-in Insulation"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-AtticCeilings"]: {
                                        ...prevState["Opt-AtticCeilings"],
                                        ["atticAddUSpec-R20"]: !prevState["Opt-AtticCeilings"]["atticAddUSpec-R20"],
                                    },
                                })),
                            value: ecmInputMap["Opt-AtticCeilings"]["atticAddUSpec-R20"],
                        }}
                        disabled={disabledFields["atticAddUSpec-R20"]}
                    />
                    <Checkbox
                        name="atticAddR30"
                        label="Add R-30 Blown-in Insulation"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-AtticCeilings"]: {
                                        ...prevState["Opt-AtticCeilings"],
                                        ["atticAddUSpec-R30"]: !prevState["Opt-AtticCeilings"]["atticAddUSpec-R30"],
                                    },
                                })),
                            value: ecmInputMap["Opt-AtticCeilings"]["atticAddUSpec-R30"],
                        }}
                        disabled={disabledFields["atticAddUSpec-R30"]}
                    />
                    <Checkbox
                        name="atticAddR40"
                        label="Add R-40 Blown-in Insulation"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-AtticCeilings"]: {
                                        ...prevState["Opt-AtticCeilings"],
                                        ["atticAddUSpec-R40"]: !prevState["Opt-AtticCeilings"]["atticAddUSpec-R40"],
                                    },
                                })),
                            value: ecmInputMap["Opt-AtticCeilings"]["atticAddUSpec-R40"],
                        }}
                        disabled={disabledFields["atticAddUSpec-R40"]}
                    />
                    <div className={classes.ceilingMirrorContainer}>
                        <div>
                            <Checkbox
                                name="mirrorFlatCeilings"
                                label="Apply to Flat Ceilings"
                                input={{
                                    onChange: () =>
                                        setParams((prevState) => ({
                                            ...prevState,
                                            ["mirrorAtticCbatEcms"]: {
                                                ...prevState["mirrorAtticCbatEcms"],
                                                ["FlatCeilings"]: !prevState["mirrorAtticCbatEcms"]["FlatCeilings"],
                                            },
                                        })),
                                    value: params["mirrorAtticCbatEcms"]["FlatCeilings"],
                                }}
                            />
                        </div>
                        <div>
                            <Checkbox
                                name="mirrorCathCeilings"
                                label="Apply to Cathedral Ceilings"
                                input={{
                                    onChange: () =>
                                        setParams((prevState) => ({
                                            ...prevState,
                                            ["mirrorAtticCbatEcms"]: {
                                                ...prevState["mirrorAtticCbatEcms"],
                                                ["CathCeilings"]: !prevState["mirrorAtticCbatEcms"]["CathCeilings"],
                                            },
                                        })),
                                    value: params["mirrorAtticCbatEcms"]["CathCeilings"],
                                }}
                            />
                        </div>
                    </div>
                </div>
                {/* Airtightness */}
                <div className={classes.checkboxContainer}>
                    <h5 style={{ marginBottom: "0.5rem" }}>Airtightness</h5>
                    <Checkbox
                        name="achNoChange"
                        label="No Change"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-ACH"]: {
                                        ...prevState["Opt-ACH"],
                                        ["None;Env-5NC"]: !prevState["Opt-ACH"]["None;Env-5NC"],
                                    },
                                })),
                            value: ecmInputMap["Opt-ACH"]["None;Env-5NC"],
                        }}
                        disabled={disabledFields["None;Env-5NC"]}
                    />
                    <Checkbox
                        name="ach25p"
                        label="Reduce Air Leakage by 25%"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-ACH"]: {
                                        ...prevState["Opt-ACH"],
                                        ["airTightnessPercent-25"]: !prevState["Opt-ACH"]["airTightnessPercent-25"],
                                    },
                                })),
                            value: ecmInputMap["Opt-ACH"]["airTightnessPercent-25"],
                        }}
                        disabled={disabledFields["airTightnessPercent-25"]}
                    />
                    <Checkbox
                        name="ach50p"
                        label="Reduce Air Leakage by 50%"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-ACH"]: {
                                        ...prevState["Opt-ACH"],
                                        ["airTightnessPercent-50"]: !prevState["Opt-ACH"]["airTightnessPercent-50"],
                                    },
                                })),
                            value: ecmInputMap["Opt-ACH"]["airTightnessPercent-50"],
                        }}
                        disabled={disabledFields["airTightnessPercent-50"]}
                    />
                    <Checkbox
                        name="ach75p"
                        label="Reduce Air Leakage by 75%"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-ACH"]: {
                                        ...prevState["Opt-ACH"],
                                        ["airTightnessPercent-75"]: !prevState["Opt-ACH"]["airTightnessPercent-75"],
                                    },
                                })),
                            value: ecmInputMap["Opt-ACH"]["airTightnessPercent-75"],
                        }}
                        disabled={disabledFields["airTightnessPercent-75"]}
                    />
                    <Checkbox
                        name="ach2-5"
                        label="Reduce Air Leakage to 2.5 ACH50"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-ACH"]: {
                                        ...prevState["Opt-ACH"],
                                        ["airSealTo-2.5"]: !prevState["Opt-ACH"]["airSealTo-2.5"],
                                    },
                                })),
                            value: ecmInputMap["Opt-ACH"]["airSealTo-2.5"],
                        }}
                        disabled={disabledFields["airSealTo-2.5"]}
                    />
                    <Checkbox
                        name="ach1-5"
                        label="Reduce Air Leakage to 1.5 ACH50"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-ACH"]: {
                                        ...prevState["Opt-ACH"],
                                        ["airSealTo-1.5"]: !prevState["Opt-ACH"]["airSealTo-1.5"],
                                    },
                                })),
                            value: ecmInputMap["Opt-ACH"]["airSealTo-1.5"],
                        }}
                        disabled={disabledFields["airSealTo-1.5"]}
                    />
                </div>
            </InputRow>
            <InputRow gridTemplate="1fr 1fr">
                {/* Wall */}
                <div className={classes.checkboxContainer}>
                    <h4 style={{ marginBottom: "0.5rem" }}>Above Grade Walls</h4>
                    <Checkbox
                        name="wallNoChange"
                        label="No Change"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-AboveGradeWall"]: {
                                        ...prevState["Opt-AboveGradeWall"],
                                        ["None;Env-2NC"]: !prevState["Opt-AboveGradeWall"]["None;Env-2NC"],
                                    },
                                })),
                            value: ecmInputMap["Opt-AboveGradeWall"]["None;Env-2NC"],
                        }}
                        disabled={disabledFields["None;Env-2NC"]}
                    />
                    <Checkbox
                        name="wallAddR10"
                        label="Add R-10 Rigid Insulation to Exterior"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-AboveGradeWall"]: {
                                        ...prevState["Opt-AboveGradeWall"],
                                        ["wallAddUSpec-R10"]: !prevState["Opt-AboveGradeWall"]["wallAddUSpec-R10"],
                                    },
                                })),
                            value: ecmInputMap["Opt-AboveGradeWall"]["wallAddUSpec-R10"],
                        }}
                        disabled={disabledFields["wallAddUSpec-R10"]}
                    />
                    <Checkbox
                        name="wallAddR20"
                        label="Add R-20 Rigid Insulation to Exterior"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-AboveGradeWall"]: {
                                        ...prevState["Opt-AboveGradeWall"],
                                        ["wallAddUSpec-R20"]: !prevState["Opt-AboveGradeWall"]["wallAddUSpec-R20"],
                                    },
                                })),
                            value: ecmInputMap["Opt-AboveGradeWall"]["wallAddUSpec-R20"],
                        }}
                        disabled={disabledFields["wallAddUSpec-R20"]}
                    />
                    <Checkbox
                        name="wallAddR30"
                        label="Add R-30 Rigid Insulation to Exterior"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-AboveGradeWall"]: {
                                        ...prevState["Opt-AboveGradeWall"],
                                        ["wallAddUSpec-R30"]: !prevState["Opt-AboveGradeWall"]["wallAddUSpec-R30"],
                                    },
                                })),
                            value: ecmInputMap["Opt-AboveGradeWall"]["wallAddUSpec-R30"],
                        }}
                        disabled={disabledFields["wallAddUSpec-R30"]}
                    />
                    <Checkbox
                        name="wallAdd7-5"
                        label={`Fill Empty 4" Wall Cavities (Batt/Blown, R-7.5 Eff.)`}
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-AboveGradeWall"]: {
                                        ...prevState["Opt-AboveGradeWall"],
                                        ["wallAddUSpec-R7.5"]: !prevState["Opt-AboveGradeWall"]["wallAddUSpec-R7.5"],
                                    },
                                })),
                            value: ecmInputMap["Opt-AboveGradeWall"]["wallAddUSpec-R7.5"],
                        }}
                        disabled={disabledFields["wallAddUSpec-R7.5"]}
                    />
                    <Checkbox
                        name="wallAdd17-5"
                        label={`Fill Empty 4" Wall Cavities (Batt/Blown, R-7.5 Eff.) + R-10 Exterior Rigid Insulation (R-17.5 Eff.)`}
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-AboveGradeWall"]: {
                                        ...prevState["Opt-AboveGradeWall"],
                                        ["wallAddUSpec-R17.5"]: !prevState["Opt-AboveGradeWall"]["wallAddUSpec-R17.5"],
                                    },
                                })),
                            value: ecmInputMap["Opt-AboveGradeWall"]["wallAddUSpec-R17.5"],
                        }}
                        disabled={disabledFields["wallAddUSpec-R17.5"]}
                    />
                    <Checkbox
                        name="wallAdd27-5"
                        label={`Fill Empty 4" Wall Cavities (Batt/Blown, R-7.5 Eff.) + R-20 Exterior Rigid Insulation (R-27.5 Eff.)`}
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-AboveGradeWall"]: {
                                        ...prevState["Opt-AboveGradeWall"],
                                        ["wallAddUSpec-R27.5"]: !prevState["Opt-AboveGradeWall"]["wallAddUSpec-R27.5"],
                                    },
                                })),
                            value: ecmInputMap["Opt-AboveGradeWall"]["wallAddUSpec-R27.5"],
                        }}
                        disabled={disabledFields["wallAddUSpec-R27.5"]}
                    />
                    <Checkbox
                        name="wallAdd37-5"
                        label={`Fill Empty 4" Wall Cavities (Batt/Blown, R-7.5 Eff.) + R-30 Exterior Rigid Insulation (R-37.5 Eff.)`}
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-AboveGradeWall"]: {
                                        ...prevState["Opt-AboveGradeWall"],
                                        ["wallAddUSpec-R37.5"]: !prevState["Opt-AboveGradeWall"]["wallAddUSpec-R37.5"],
                                    },
                                })),
                            value: ecmInputMap["Opt-AboveGradeWall"]["wallAddUSpec-R37.5"],
                        }}
                        disabled={disabledFields["wallAddUSpec-R37.5"]}
                    />
                </div>
                {/* Windows */}
                <div className={classes.checkboxContainer}>
                    <h5 style={{ marginBottom: "0.5rem" }}>Windows</h5>
                    <Checkbox
                        name="windowNoChange"
                        label="No Change"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-Windows"]: {
                                        ...prevState["Opt-Windows"],
                                        ["None;Env-4NC"]: !prevState["Opt-Windows"]["None;Env-4NC"],
                                    },
                                })),
                            value: ecmInputMap["Opt-Windows"]["None;Env-4NC"],
                        }}
                        disabled={disabledFields["None;Env-4NC"]}
                    />

                    <Checkbox
                        name="windowDG_MG_1-6"
                        label="Double Glazed, Medium Gain, Low-e, Argon, Ins. Spacer, Vinyl Frame (U=1.6, SHGC=0.3)"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-Windows"]: {
                                        ...prevState["Opt-Windows"],
                                        ["2g-MG-u1.6"]: !prevState["Opt-Windows"]["2g-MG-u1.6"],
                                    },
                                })),
                            value: ecmInputMap["Opt-Windows"]["2g-MG-u1.6"],
                        }}
                        disabled={disabledFields["2g-MG-u1.6"]}
                    />
                    <Checkbox
                        name="windowDG_LG_1-6"
                        label="Double Glazed, Low Gain, Low-e, Argon, Ins. Spacer, Vinyl Frame (U=1.6, SHGC=0.2)"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-Windows"]: {
                                        ...prevState["Opt-Windows"],
                                        ["2g-LG-u1.6"]: !prevState["Opt-Windows"]["2g-LG-u1.6"],
                                    },
                                })),
                            value: ecmInputMap["Opt-Windows"]["2g-LG-u1.6"],
                        }}
                        disabled={disabledFields["2g-LG-u1.6"]}
                    />
                    <Checkbox
                        name="windowTG_MG_1-05"
                        label="Triple Glazed, Medium Gain, Low-e, Argon, Super Spacer, Vinyl Frame (U=1.05, SHGC=0.3)"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-Windows"]: {
                                        ...prevState["Opt-Windows"],
                                        ["3g-MG-u1.05"]: !prevState["Opt-Windows"]["3g-MG-u1.05"],
                                    },
                                })),
                            value: ecmInputMap["Opt-Windows"]["3g-MG-u1.05"],
                        }}
                        disabled={disabledFields["3g-MG-u1.05"]}
                    />
                    <Checkbox
                        name="windowTG_LG_1-05"
                        label="Triple Glazed, Low Gain, Low-e, Argon, Super Spacer, Vinyl Frame (U=1.05, SHGC=0.2)"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-Windows"]: {
                                        ...prevState["Opt-Windows"],
                                        ["3g-LG-u1.05"]: !prevState["Opt-Windows"]["3g-LG-u1.05"],
                                    },
                                })),
                            value: ecmInputMap["Opt-Windows"]["3g-LG-u1.05"],
                        }}
                        disabled={disabledFields["3g-LG-u1.05"]}
                    />
                    <Checkbox
                        name="windowTG_MG_0-8"
                        label="Triple Glazed, Medium Gain, Low-e, Krypton, Super Spacer, Vinyl Frame (U=0.8, SHGC=0.3)"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-Windows"]: {
                                        ...prevState["Opt-Windows"],
                                        ["3g-MG-u0.8"]: !prevState["Opt-Windows"]["3g-MG-u0.8"],
                                    },
                                })),
                            value: ecmInputMap["Opt-Windows"]["3g-MG-u0.8"],
                        }}
                        disabled={disabledFields["3g-MG-u0.8"]}
                    />
                    <Checkbox
                        name="windowTG_LG_0-8"
                        label="Triple Glazed, Low Gain, Low-e, Krypton, Super Spacer, Vinyl Frame (U=0.8, SHGC=0.2)"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-Windows"]: {
                                        ...prevState["Opt-Windows"],
                                        ["3g-LG-u0.8"]: !prevState["Opt-Windows"]["3g-LG-u0.8"],
                                    },
                                })),
                            value: ecmInputMap["Opt-Windows"]["3g-LG-u0.8"],
                        }}
                        disabled={disabledFields["3g-LG-u0.8"]}
                    />
                </div>
            </InputRow>
            <InputRow gridTemplate="1fr 1fr">
                {/* Exterior Foundation Wall */}
                <div className={classes.checkboxContainer}>
                    <h5 style={{ marginBottom: "0.5rem" }}>Exterior Foundation Wall</h5>
                    <Checkbox
                        name="bsmtWallNoChange"
                        label="No Change"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-FoundationWallIntIns"]: {
                                        ...prevState["Opt-FoundationWallIntIns"],
                                        ["None;Env-3NC"]: !prevState["Opt-FoundationWallIntIns"]["None;Env-3NC"],
                                    },
                                })),
                            value: ecmInputMap["Opt-FoundationWallIntIns"]["None;Env-3NC"],
                        }}
                        disabled={disabledFields["None;Env-3NC"]}
                    />
                    <Checkbox
                        name="bsmtWallAddR10"
                        label={`Add Interior Framed and Insulated 4" Deep Wall (R-10 Effective)`}
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-FoundationWallIntIns"]: {
                                        ...prevState["Opt-FoundationWallIntIns"],
                                        ["fndWallAddUSpec-R10"]:
                                            !prevState["Opt-FoundationWallIntIns"]["fndWallAddUSpec-R10"],
                                    },
                                })),
                            value: ecmInputMap["Opt-FoundationWallIntIns"]["fndWallAddUSpec-R10"],
                        }}
                        disabled={disabledFields["fndWallAddUSpec-R10"]}
                    />
                    <Checkbox
                        name="bsmtWallAddR17"
                        label={`Add Interior Framed and Insulated 6" Deep Wall (R-17 Effective)`}
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-FoundationWallIntIns"]: {
                                        ...prevState["Opt-FoundationWallIntIns"],
                                        ["fndWallAddUSpec-R17"]:
                                            !prevState["Opt-FoundationWallIntIns"]["fndWallAddUSpec-R17"],
                                    },
                                })),
                            value: ecmInputMap["Opt-FoundationWallIntIns"]["fndWallAddUSpec-R17"],
                        }}
                        disabled={disabledFields["fndWallAddUSpec-R17"]}
                    />
                    <Checkbox
                        name="bsmtWallAddR20"
                        label={`Add Rigid Insulation to Exterior of Foundation Wall (R-20)`}
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-FoundationWallIntIns"]: {
                                        ...prevState["Opt-FoundationWallIntIns"],
                                        ["fndWallAddUSpec-R20"]:
                                            !prevState["Opt-FoundationWallIntIns"]["fndWallAddUSpec-R20"],
                                    },
                                })),
                            value: ecmInputMap["Opt-FoundationWallIntIns"]["fndWallAddUSpec-R20"],
                        }}
                        disabled={disabledFields["fndWallAddUSpec-R20"]}
                    />
                    <Checkbox
                        name="bsmtWallAddR30"
                        label={`Add Interior Framed and Insulated 4" Deep Wall AND Add Rigid Insulation to Exterior of Foundation Wall (R-30 Effective)`}
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-FoundationWallIntIns"]: {
                                        ...prevState["Opt-FoundationWallIntIns"],
                                        ["fndWallAddUSpec-R30"]:
                                            !prevState["Opt-FoundationWallIntIns"]["fndWallAddUSpec-R30"],
                                    },
                                })),
                            value: ecmInputMap["Opt-FoundationWallIntIns"]["fndWallAddUSpec-R30"],
                        }}
                        disabled={disabledFields["fndWallAddUSpec-R30"]}
                    />
                </div>
                <div></div>
            </InputRow>
            <InputRow gridTemplate="1fr 1fr">
                {/* Heating and Cooling */}
                <div className={classes.checkboxContainer}>
                    <h5 style={{ marginBottom: "0.5rem" }}>Heating and Cooling Systems</h5>
                    <Checkbox
                        name="hvacNoChange"
                        label="No Change"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-Heating-Cooling"]: {
                                        ...prevState["Opt-Heating-Cooling"],
                                        ["None;Mec-1NC"]: !prevState["Opt-Heating-Cooling"]["None;Mec-1NC"],
                                    },
                                })),
                            value: ecmInputMap["Opt-Heating-Cooling"]["None;Mec-1NC"],
                        }}
                        disabled={disabledFields["None;Mec-1NC"]}
                    />
                    <Checkbox
                        name="hvacCondGasFurnace"
                        label="Condensing Natural Gas Furnace (95% Eff.)"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-Heating-Cooling"]: {
                                        ...prevState["Opt-Heating-Cooling"],
                                        ["condGasFurnace-95eff"]:
                                            !prevState["Opt-Heating-Cooling"]["condGasFurnace-95eff"],
                                    },
                                })),
                            value: ecmInputMap["Opt-Heating-Cooling"]["condGasFurnace-95eff"],
                        }}
                        disabled={disabledFields["condGasFurnace-95eff"]}
                    />
                    <Checkbox
                        name="hvacHybridExistingFurnace"
                        label="Hybrid Heating: ASHP (2.5 COP) and Existing Gas Furnace"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-Heating-Cooling"]: {
                                        ...prevState["Opt-Heating-Cooling"],
                                        ["hybridHeatExFurnace-ASHP2.5COP"]:
                                            !prevState["Opt-Heating-Cooling"]["hybridHeatExFurnace-ASHP2.5COP"],
                                    },
                                })),
                            value: ecmInputMap["Opt-Heating-Cooling"]["hybridHeatExFurnace-ASHP2.5COP"],
                        }}
                        disabled={disabledFields["hybridHeatExFurnace-ASHP2.5COP"]}
                    />
                    <Checkbox
                        name="hvacHybridNewFurnace"
                        label="Hybrid Heating: ASHP (2.5 COP) and New Condensing Gas Furnace"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-Heating-Cooling"]: {
                                        ...prevState["Opt-Heating-Cooling"],
                                        ["hybridHeatCondFurnace-ASHP2.5COP"]:
                                            !prevState["Opt-Heating-Cooling"]["hybridHeatCondFurnace-ASHP2.5COP"],
                                    },
                                })),
                            value: ecmInputMap["Opt-Heating-Cooling"]["hybridHeatCondFurnace-ASHP2.5COP"],
                        }}
                        disabled={disabledFields["hybridHeatCondFurnace-ASHP2.5COP"]}
                    />
                    <Checkbox
                        name="hvacAllElectricHeatPump"
                        label="ASHP (2.5 COP) and Supplemental Electric Heating"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-Heating-Cooling"]: {
                                        ...prevState["Opt-Heating-Cooling"],
                                        ["elecHeating-ASHP2.5COP"]:
                                            !prevState["Opt-Heating-Cooling"]["elecHeating-ASHP2.5COP"],
                                    },
                                })),
                            value: ecmInputMap["Opt-Heating-Cooling"]["elecHeating-ASHP2.5COP"],
                        }}
                        disabled={disabledFields["elecHeating-ASHP2.5COP"]}
                    />
                    <Checkbox
                        name="hvacAllElectricCcHeatPump"
                        label="CCASHP (3.8 COP) and Supplemental Electric Heating"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-Heating-Cooling"]: {
                                        ...prevState["Opt-Heating-Cooling"],
                                        ["elecHeating-CCASHP3.8COP"]:
                                            !prevState["Opt-Heating-Cooling"]["elecHeating-CCASHP3.8COP"],
                                    },
                                })),
                            value: ecmInputMap["Opt-Heating-Cooling"]["elecHeating-CCASHP3.8COP"],
                        }}
                        disabled={disabledFields["elecHeating-CCASHP3.8COP"]}
                    />
                </div>
                {/* DHW */}
                <div className={classes.checkboxContainer}>
                    <h5 style={{ marginBottom: "0.5rem" }}>Domestic Hot Water System</h5>
                    <Checkbox
                        name="dhwNoChange"
                        label="No Change"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-DHWSystem"]: {
                                        ...prevState["Opt-DHWSystem"],
                                        ["None;Mec-3NC"]: !prevState["Opt-DHWSystem"]["None;Mec-3NC"],
                                    },
                                })),
                            value: ecmInputMap["Opt-DHWSystem"]["None;Mec-3NC"],
                        }}
                        disabled={disabledFields["None;Mec-3NC"]}
                    />
                    <Checkbox
                        name="dhwGasStorage"
                        label="Natural Gas Powervent Tank (EF=0.67)"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-DHWSystem"]: {
                                        ...prevState["Opt-DHWSystem"],
                                        ["dhwGasStorage-EF0.67"]: !prevState["Opt-DHWSystem"]["dhwGasStorage-EF0.67"],
                                    },
                                })),
                            value: ecmInputMap["Opt-DHWSystem"]["dhwGasStorage-EF0.67"],
                        }}
                        disabled={disabledFields["dhwGasStorage-EF0.67"]}
                    />
                    <Checkbox
                        name="dhwGasInst"
                        label="Natural Gas Tankless (EF=0.94)"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-DHWSystem"]: {
                                        ...prevState["Opt-DHWSystem"],
                                        ["dhwGasInst-EF0.94"]: !prevState["Opt-DHWSystem"]["dhwGasInst-EF0.94"],
                                    },
                                })),
                            value: ecmInputMap["Opt-DHWSystem"]["dhwGasInst-EF0.94"],
                        }}
                        disabled={disabledFields["dhwGasInst-EF0.94"]}
                    />
                    <Checkbox
                        name="dhwElecTank"
                        label="Electric Storage Tank (EF=0.94)"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-DHWSystem"]: {
                                        ...prevState["Opt-DHWSystem"],
                                        ["dhwElecStorage-EF0.92"]: !prevState["Opt-DHWSystem"]["dhwElecStorage-EF0.92"],
                                    },
                                })),
                            value: ecmInputMap["Opt-DHWSystem"]["dhwElecStorage-EF0.92"],
                        }}
                        disabled={disabledFields["dhwElecStorage-EF0.92"]}
                    />
                    <Checkbox
                        name="dhwHpwh"
                        label="Heat Pump Water Heater (EF=3.5)"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-DHWSystem"]: {
                                        ...prevState["Opt-DHWSystem"],
                                        ["dhwHPWH-EF3.5"]: !prevState["Opt-DHWSystem"]["dhwHPWH-EF3.5"],
                                    },
                                })),
                            value: ecmInputMap["Opt-DHWSystem"]["dhwHPWH-EF3.5"],
                        }}
                        disabled={disabledFields["dhwHPWH-EF3.5"]}
                    />
                </div>
            </InputRow>
            <InputRow gridTemplate="1fr 1fr">
                {/* Ventilation */}
                <div className={classes.checkboxContainer}>
                    <h5 style={{ marginBottom: "0.5rem" }}>Mechanical Ventilation</h5>
                    <Checkbox
                        name="ventNoChange"
                        label="No Change"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-VentSystem"]: {
                                        ...prevState["Opt-VentSystem"],
                                        ["None;Mec-2NC"]: !prevState["Opt-VentSystem"]["None;Mec-2NC"],
                                    },
                                })),
                            value: ecmInputMap["Opt-VentSystem"]["None;Mec-2NC"],
                        }}
                        disabled={disabledFields["None;Mec-2NC"]}
                    />
                    <Checkbox
                        name="ventHRV60"
                        label="60% HRV"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-VentSystem"]: {
                                        ...prevState["Opt-VentSystem"],
                                        ["hrv-SRE60"]: !prevState["Opt-VentSystem"]["hrv-SRE60"],
                                    },
                                })),
                            value: ecmInputMap["Opt-VentSystem"]["hrv-SRE60"],
                        }}
                        disabled={disabledFields["hrv-SRE60"]}
                    />
                    <Checkbox
                        name="ventHRB78"
                        label="78% HRV"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-VentSystem"]: {
                                        ...prevState["Opt-VentSystem"],
                                        ["hrv-SRE78"]: !prevState["Opt-VentSystem"]["hrv-SRE78"],
                                    },
                                })),
                            value: ecmInputMap["Opt-VentSystem"]["hrv-SRE78"],
                        }}
                        disabled={disabledFields["hrv-SRE78"]}
                    />
                </div>
                {/* DWHR */}
                <div className={classes.checkboxContainer}>
                    <h5 style={{ marginBottom: "0.5rem" }}>Drain Water Heat Recovery</h5>
                    <Checkbox
                        name="noDwhr"
                        label="No Change"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-DWHR"]: {
                                        ...prevState["Opt-DWHR"],
                                        ["None;Mec-4NC"]: !prevState["Opt-DWHR"]["None;Mec-4NC"],
                                    },
                                })),
                            value: ecmInputMap["Opt-DWHR"]["None;Mec-4NC"],
                        }}
                        disabled={disabledFields["None;Mec-4NC"]}
                    />
                    <Checkbox
                        name="dwhr55"
                        label="DWHR (55% Eff.) Connected to DHW System Only"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-DWHR"]: {
                                        ...prevState["Opt-DWHR"],
                                        ["dwhr-55eff"]: !prevState["Opt-DWHR"]["dwhr-55eff"],
                                    },
                                })),
                            value: ecmInputMap["Opt-DWHR"]["dwhr-55eff"],
                        }}
                        disabled={disabledFields["dwhr-55eff"]}
                    />
                </div>
            </InputRow>
            <div className={classes.textWrapper}>
                <p>All remaining categories only contain one ECM option and may not be toggled off.</p>
            </div>
        </div>
    );
};
export default RetrofitBatchEcms;
