import React, { useState } from 'react';
import classes from './style.module.scss';
import { DropDown } from 'components/Input/Select';
import Add from 'assets/images/icons/JSX/Add';
import { useOutsideClickHook } from 'utils/outsideClick';
import { getHeatingSystemTemplate } from 'utils/mechanicals';
import moment from 'moment';

export default ({
    accessor,
    isMURB,
    hasRadiant,
    hasAdditionalOpenings,
    hasMultipleSystems,
    numSupplSystems,
    change
}) => {
    const [open, toggleOpen] = useState(false);

    const ref = useOutsideClickHook(() => toggleOpen(false));

    const handleAddSystem = ({sysType}) => {
        // const template = getVentilationTemplate(sysType);

        if (sysType === 'radiantHeating') {
            change(`${accessor}.radiantHeating` ,getHeatingSystemTemplate('radiantHeating'));

        } else if (sysType === 'additionalOpenings') {
            change(`${accessor}.additionalOpenings` ,getHeatingSystemTemplate('additionalOpenings'));

        } else if (sysType === 'supplSystems') {
            change(
                `${accessor}.supplSystems.systems.system${moment().format('YYYYMMDDHHmmssSS')}`,
                getHeatingSystemTemplate('supplSystems')
            );

        } else if (sysType === 'multipleSystems') {
            // change(
            //     `${accessor}.multipleSystems.systems.multiSys${moment().format('YYYYMMDDHHmmssSS')}`,
            //     getHeatingSystemTemplate('multipleSystems')
            // );

        }

    }

    const auxHeatingOpts = [
        (!hasRadiant) && { value: 'radiantHeating', label: 'Radiant Heating' },
        (!hasAdditionalOpenings) && { value: 'additionalOpenings', label: 'Additional Openings' },
        (numSupplSystems <= 5) && { value: 'supplSystems', label: 'Supplementary Heating System' },
        (isMURB && !hasMultipleSystems && false) && { value: 'multipleSystems', label: 'Multiple Heating Systems' },
    ].filter(value => Object.keys(value).length !== 0);

    const allAuxHeatingApplied = auxHeatingOpts.length === 0;

    return (
        <>
            <div className={classes.addComponent} ref={ref}>
                <div
                    className={`${classes.addText} ${open && classes.open}`}
                    onClick={()=>toggleOpen(!open)}
                >
                    {allAuxHeatingApplied ? 'Maximum number of systems reached' :'Add Auxiliary Heating System'} <Add />
                </div>
                {
                    !allAuxHeatingApplied && <DropDown
                        className={classes.dropDown}
                        options={auxHeatingOpts}
                        open={open}
                        toggleOpen={toggleOpen}
                        onChange={(value)=>{
                            handleAddSystem({sysType: value});
                        }}
                    />
                }
            </div>
        </>
    )
}