import React from 'react';
import classes from './style.module.scss';
import sharedClasses from 'features/Model/sharedStyles.module.scss';
import { Field } from 'redux-form';
import { required, maxLength, getValidation, getDecimalPlaces } from 'utils/fieldValidation';
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { getOptions, getFirstOption, getBaseUnits, getUnitOptions } from 'utils/fields';
import InputRow from 'components/Input/Row';
import Input from 'components/Input';
import InputWithUnits from 'components/Input/InputWithUnits';
import Select from 'components/Input/Select';
import SelectWithInput from 'components/Input/SelectWithInput';
import Button from 'components/Button';
import Checkbox from 'components/Input/Checkbox';

const dryerEnergySourceValidation = getValidation('elecDryerEnergySource');
const dryerLocationValidation = getValidation('elecDryerLocation');
const dryerRatedValuesValidation = getValidation('elecDryerRatedValue');
const percentDryerLoadsValidation = getValidation('elecDryerPercentLoadsDried');
const stoveEnergySourceValidation = getValidation('elecStoveEnergySource');
const stoveRatedValuesValidation = getValidation('elecStoveRatedValue');
const fridgeRatedValuesValidation = getValidation('elecFridgeRatedValue');
const intLightingValidation = getValidation('elecLightingDailyConsumption');
const extUseValidation = getValidation('elecExteriorUse');
const otherLoadsValidation = getValidation('elecOtherLoad');


export default React.memo(({
    accessor,
    isDryerInstalled,
    dryerRatedId,
    stoveRatedId,
    fridgeRatedId,
    intLightingId,
    dryerEnergyRatedValue,
    stoveRatedValue,
    fridgeRatedValue,
    intLightingValue,
    dryerEnergyRatedUnits,
    percentDryerLoadsUnits,
    stoveRatedUnits,
    fridgeRatedUnits,
    intLightingUnits,
    extUseUnits,
    otherLoadsUnits,
    modelUnits,
    change,
    isUpgrade=false
}) => (
    <div>
        <h4 style={{marginBottom:"1.25rem"}}>Appliances</h4>
        <h5 style={{marginBottom:"0.75rem", marginTop:"1.5rem"}}>Dryer</h5>
        <InputRow gridTemplate="1fr 1fr 1fr">
            <Field
                component={Select}
                name={`${accessor}.dryer.ratedValue`}
                options={[
                    {
                        label:"Default",
                        value:{
                            id:0,
                            value: 916
                        }
                    },
                    {
                        label:"User Specified",
                        value:{
                            id:1,
                            value: dryerEnergyRatedValue
                        }
                    }
                ]}
                label="Rated Values"
                placeholder="Choose Rate Values"
                validate={dryerRatedValuesValidation}
                disabled={!isDryerInstalled}
            />
            <Field
                component={InputWithUnits}
                type="number"
                name={`${accessor}.dryer.ratedValue.value`}
                label="Rated Annual Energy Consumption"
                placeholder={0.00}
                validate={dryerRatedValuesValidation}
                decimals={getDecimalPlaces('elecDryerRatedValue')}
                change={change}
                setValue={dryerEnergyRatedValue}
                disabled={dryerRatedId !== 1 || !isDryerInstalled}
                units={{
                    base:getBaseUnits('elecDryerRatedValue', modelUnits),
                    options:getUnitOptions('energy'),
                    selected:dryerEnergyRatedUnits,
                    unitType:'energy',
                    accessor:`${accessor}.dryer.ratedValue_u`
                }}
            />
            <Field
                component={Select}
                name={`${accessor}.dryer.energySource`}
                options={getOptions({fieldKey: 'elecDryerEnergySource'})}
                label="Energy Source"
                placeholder="Choose Energy Source"
                validate={dryerEnergySourceValidation}
                disabled={!isDryerInstalled}
            />
        </InputRow>
        <InputRow gridTemplate="1fr 1fr 1fr">
            <Field
                component={Select}
                name={`${accessor}.dryer.location`}
                options={getOptions({fieldKey: 'elecDryerLocation'})}
                label="Dryer Location"
                placeholder="Choose Dryer Location"
                validate={dryerLocationValidation}
                disabled={!isDryerInstalled}
            />
            <Field
                component={InputWithUnits}
                type="number"
                name={`${accessor}.dryer.percentageOfWasherLoads`}
                label="Percentage of Washer Loads Dried"
                placeholder={0.00}
                validate={percentDryerLoadsValidation}
                decimals={getDecimalPlaces('elecDryerPercentLoadsDried')}
                change={change}
                disabled={!isDryerInstalled}
                units={{
                    base:getBaseUnits('elecDryerPercentLoadsDried', modelUnits),
                    options:getUnitOptions('fraction'),
                    selected:percentDryerLoadsUnits,
                    unitType:'fraction',
                    accessor:`${accessor}.dryer.percentageOfWasherLoads_u`
                }}
            />
        </InputRow>
        <h5 style={{marginBottom:"0.75rem", marginTop:"1.5rem"}}>Stove</h5>
        <InputRow gridTemplate="1fr 1fr 1fr">
            <Field
                component={Select}
                name={`${accessor}.stove.ratedValue`}
                options={[
                    {
                        label:"Default",
                        value:{
                            id:0,
                            value: 565
                        }
                    },
                    {
                        label:"User Specified",
                        value:{
                            id:1,
                            value: stoveRatedValue
                        }
                    }
                ]}
                label="Rated Values"
                placeholder="Choose Rate Values"
                validate={stoveRatedValuesValidation}
            />
            <Field
                component={InputWithUnits}
                type="number"
                name={`${accessor}.stove.ratedValue.value`}
                label="Rated Annual Energy Consumption"
                placeholder={0.00}
                validate={stoveRatedValuesValidation}
                decimals={getDecimalPlaces('elecStoveRatedValue')}
                change={change}
                setValue={stoveRatedValue}
                disabled={stoveRatedId !== 1}
                units={{
                    base:getBaseUnits('elecStoveRatedValue', modelUnits),
                    options:getUnitOptions('energy'),
                    selected:stoveRatedUnits,
                    unitType:'energy',
                    accessor:`${accessor}.stove.ratedValue_u`
                }}
            />
            <Field
                component={Select}
                name={`${accessor}.stove.energySource`}
                options={getOptions({fieldKey: 'elecStoveEnergySource'})}
                label="Energy Source"
                placeholder="Choose Energy Source"
                validate={stoveEnergySourceValidation}
            />
        </InputRow>
        <h5 style={{marginBottom:"0.75rem", marginTop:"1.5rem"}}>Refrigerator</h5>
        <InputRow gridTemplate="1fr 1fr 1fr">
            <Field
                component={Select}
                name={`${accessor}.refrigerator.ratedValue`}
                options={[
                    {
                        label:"Default",
                        value:{
                            id:0,
                            value: 639
                        }
                    },
                    {
                        label:"User Specified",
                        value:{
                            id:1,
                            value: fridgeRatedValue
                        }
                    }
                ]}
                label="Rated Values"
                placeholder="Choose Rate Values"
                validate={fridgeRatedValuesValidation}
            />
            <Field
                component={InputWithUnits}
                type="number"
                name={`${accessor}.refrigerator.ratedValue.value`}
                label="Rated Annual Energy Consumption"
                placeholder={0.00}
                validate={fridgeRatedValuesValidation}
                decimals={getDecimalPlaces('elecFridgeRatedValue')}
                change={change}
                setValue={fridgeRatedValue}
                disabled={fridgeRatedId !== 1}
                units={{
                    base:getBaseUnits('elecFridgeRatedValue', modelUnits),
                    options:getUnitOptions('energy'),
                    selected:fridgeRatedUnits,
                    unitType:'energy',
                    accessor:`${accessor}.refrigerator.ratedValue_u`
                }}
            />
        </InputRow>
        <h5 style={{marginBottom:"0.75rem", marginTop:"1.5rem"}}>Interior Lighting</h5>
        <InputRow gridTemplate="1fr 1fr 1fr">
            <Field
                component={Select}
                name={`${accessor}.interiorLighting.dailyConsumption`}
                options={[
                    ...getOptions({fieldKey: 'elecLightingDailyConsumption'}),
                    {
                        label:"User Specified",
                        value:{
                            id:3,
                            value: intLightingValue
                        }
                    }
                ]}
                label="Types of Lighting Present"
                placeholder="Choose Lighting"
                validate={intLightingValidation}
            />
            <Field
                component={InputWithUnits}
                type="number"
                name={`${accessor}.interiorLighting.dailyConsumption.value`}
                label="Daily Interior Lighting Consumption"
                placeholder={0.00}
                validate={intLightingValidation}
                decimals={getDecimalPlaces('elecLightingDailyConsumption')}
                change={change}
                setValue={intLightingValue}
                disabled={intLightingId !== 3}
                units={{
                    base:getBaseUnits('elecLightingDailyConsumption', modelUnits),
                    options:getUnitOptions('energy'),
                    selected:intLightingUnits,
                    unitType:'energy',
                    accessor:`${accessor}.interiorLighting.dailyConsumption_u`
                }}
            />
        </InputRow>
        <h5 style={{marginBottom:"0.75rem", marginTop:"1.5rem"}}>Miscellaneous</h5>
        <InputRow gridTemplate="1fr 1fr 1fr">
            <Field
                component={InputWithUnits}
                type="number"
                name={`${accessor}.averageExteriorUse`}
                label="Average Daily Exterior Use"
                placeholder={0.00}
                validate={extUseValidation}
                decimals={getDecimalPlaces('elecExteriorUse')}
                change={change}
                units={{
                    base:getBaseUnits('elecExteriorUse', modelUnits),
                    options:getUnitOptions('energy'),
                    selected:extUseUnits,
                    unitType:'energy',
                    accessor:`${accessor}.averageExteriorUse_u`
                }}
            />
            <Field
                component={InputWithUnits}
                type="number"
                name={`${accessor}.otherLoad`}
                label="Other Daily Electrical Loads"
                placeholder={0.00}
                validate={otherLoadsValidation}
                decimals={getDecimalPlaces('elecOtherLoad')}
                change={change}
                units={{
                    base:getBaseUnits('elecOtherLoad', modelUnits),
                    options:getUnitOptions('energy'),
                    selected:otherLoadsUnits,
                    unitType:'energy',
                    accessor:`${accessor}.otherLoad_u`
                }}
            />
            <Field
                component={Checkbox}
                name={`${accessor}.hasEnergyMonitoringSystem`}
                label="Energy Monitoring System?"
                large
                className={classes.bottomCheckBox}
                type="checkbox"
            />
        </InputRow>
    </div>
))