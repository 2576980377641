import { connect } from "react-redux";
import Actions from "./";
import { formValueSelector } from "redux-form";

const mapStateToProps = ({ form }, { accessor, formName = "model" }) => {
    // const selector = formValueSelector(formName);

    return {
        accessor,
    };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Actions);
