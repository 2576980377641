import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectIsImageGalleryOpen } from "store/imageGallery/selectors";
import { selectModelId } from "features/Model/_ducks/selectors";
import { selectUserUid } from "store/users/selectors";

import { getImageGallery } from "store/imageGallery/thunk";

import ModalFullScreen from "components/ModalFullScreen";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import Images from "./components/Images";

import styles from "./styles.module.scss";

const ImageGallery = ({ isOpen, modelId, uid, getImageGallery, handleSave, isSavingModel }) => {
    const [searchFieldValue, setSearchFieldValue] = useState("");
    const [selectedTag, setSelectedTag] = useState("all");
    const [isClosing, setIsClosing] = useState(false);

    useEffect(() => {
        if (!isOpen || isSavingModel || isClosing) return;

        getImageGallery(modelId, uid);
    }, [isOpen, isSavingModel]);

    return (
        <ModalFullScreen open={isOpen}>
            <Header
                searchFieldValue={searchFieldValue}
                setSearchFieldValue={setSearchFieldValue}
                handleSave={handleSave}
                setIsClosing={setIsClosing}
            />
            <div className={styles.imageGalleryContainer}>
                <Sidebar
                    selectedTag={selectedTag}
                    setSelectedTag={setSelectedTag}
                    isSavingModel={isSavingModel}
                    isClosing={isClosing}
                />
                <Images
                    selectedTag={selectedTag}
                    searchFieldValue={searchFieldValue}
                    isSavingModel={isSavingModel}
                    isClosing={isClosing}
                />
            </div>
        </ModalFullScreen>
    );
};

const mapStateToProps = createStructuredSelector({
    isOpen: selectIsImageGalleryOpen,
    modelId: selectModelId,
    uid: selectUserUid,
});

const mapDispatchToProps = (dispatch) => ({
    getImageGallery: (modelId, uid) => dispatch(getImageGallery(modelId, uid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageGallery);
