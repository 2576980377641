import React, { useState } from 'react';
import classes from './style.module.scss';
import DropDown from 'components/Dropdown';
import Delete from 'assets/images/icons/JSX/Delete';
import Dialog, { CloseDialog } from 'components/Dialog';
import Button from 'components/Button';
import { useOutsideClickHook } from 'utils/outsideClick';

const getSystemLabel = (type) => {
    switch (type) {
        case 'airHeatPump':
            return 'Air Source Heat Pump';
        
        case 'airConditioning':
            return 'Air Conditioning';
        
        case 'waterHeatPump':
            return 'Water Source Heat Pump';
        
        case 'groundHeatPump':
            return 'Ground Source Heat Pump';

        default:
            return 'Cooling System';
    }
}

export default React.memo(({
    type,
    change,
    accessor
}) => {
    const [menuOpen, toggleMenuOpen] = useState(false);    
    const [deleteDialogOpen, toggleDeleteDialog] = useState(false);

    const ref = useOutsideClickHook(() => toggleMenuOpen(false));

    const handleDelete = () => {
        change(`${accessor}.cooling.system`, {});
    }

    return (
        <div className={classes.actions} ref={ref}>
            <div className={classes.menu}>
                <div className={classes.dots} onClick={()=>toggleMenuOpen(!menuOpen)}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <DropDown
                    className={classes.dropDown}
                    open={menuOpen}
                    toggleOpen={toggleMenuOpen}
                    options={[
                        {
                            label:<span><Delete /> Delete</span>,
                            className:classes.delete,
                            onClick:()=>toggleDeleteDialog(true)
                        }
                    ]}
                />
            </div>
            <Dialog
                open={deleteDialogOpen}
            >
                <CloseDialog
                    closeActions={()=>toggleDeleteDialog(false)}
                />
                <div className={classes.dialog}>
                    <h3>{`Are you sure you want to delete this ${getSystemLabel(type)}`}</h3>
                    <div className={classes.footer}>
                        <Button
                            type="hollow"
                            onClick={()=>toggleDeleteDialog(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => handleDelete()}
                            type="red"
                        >
                            Delete
                        </Button>
                    </div>
                </div>
            </Dialog>
        </div>
    )
})