import React from "react";

export default ({ fill = "#0049c6" }) => (
    <svg
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="7"
        viewBox="0 0 10 7"
    >
        <path
            id="icon-arrow-down-a"
            d="M5.71039026,6.71749978 C5.74411163,6.68385445 7.07945375,5.33558639 9.71641665,2.67269562 C10.094347,2.29391617 10.0947058,1.62895905 9.71641665,1.25 C9.33812751,0.871579753 8.7250444,0.771220527 8.34639652,1.14999998 L5,4.5 L1.65324372,1.14999998 C1.27495458,0.771400128 0.661871472,0.871220551 0.283402964,1.25 C0.0945274472,1.43965913 -0.000358733417,1.72170596 4.50590656e-09,1.96973556 C-0.000358733417,2.21794476 0.0945274472,2.46615396 0.283761702,2.65509468 C2.93220421,5.32959968 4.27340572,6.68373471 4.30736625,6.71749978 C4.50072599,6.91111014 4.74637229,7.00432329 5,6.99983326 C5.25326897,7.00450289 5.51703052,6.91111014 5.71039026,6.71749978 Z"
        ></path>
    </svg>
);
