import React from "react";

export default () => (
    <svg
        fill="#979797"
        width="18px"
        height="18px"
        viewBox="0 0 18 18"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <path
            d="M18,0 L18,18 L13,18 L13,16.013 L16.0028763,16.0133982 L16.0028763,1.99227476 L13,1.992 L13,0 L18,0 Z M5,0 L5,1.992 L1.99790764,1.99227476 L1.99790764,16.0133982 L5,16.013 L5,18 L0,18 L0,0 L5,0 Z M8.79904306,12.0724269 C9.55980861,12.0724269 10.1196172,12.7128335 10.1196172,13.5362135 C10.1196172,14.3595934 9.55980861,15 8.79904306,15 C8.05263158,15 7.49282297,14.3595934 7.49282297,13.5362135 C7.49282297,12.7128335 8.05263158,12.0724269 8.79904306,12.0724269 Z M9.01435407,3 C10.7368421,3 12,3.94536213 12,5.89707751 C12,6.88945362 11.4753289,7.58073909 10.9276658,8.2454656 L10.6296757,8.60705982 C10.0902362,9.27120394 9.61901914,9.95870394 9.71770335,10.9440915 L9.71770335,10.9440915 L7.88038278,10.9440915 C7.53588517,8.48919949 9.97607656,7.5133418 9.97607656,6.04955527 C9.97607656,5.28716645 9.50239234,4.79923761 8.75598086,4.79923761 C8.12440191,4.79923761 7.62200957,5.14993647 7.16267943,5.65311309 L7.16267943,5.65311309 L6,4.52477764 C6.74641148,3.62515883 7.76555024,3 9.01435407,3 Z"
            id="Rectangle"
            fillRule="nonzero"
        ></path>
    </svg>
);
