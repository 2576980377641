import { connect } from 'react-redux';
import LineActions from './';
import { actions } from 'features/Model/Drawing/_ducks';
import { reduxForm, formValueSelector } from 'redux-form';
import { getDrawingLineFieldOptions } from 'utils/fields';
import convertUnit from 'utils/convertUnit';
import { getDecimalPlaces } from 'utils/fieldValidation';
import { getBaseUnits } from 'utils/fields';
import isEmpty from 'lodash/isEmpty';
import { getDrawingComponent, getUpdatedComponent } from 'features/Model/Drawing/drawingHelpers.js';

const { removeLine, setAction, setActiveComponent, updateLine, updateComponent, updatePolygon, updateFieldsToDetach } = actions;

const mapStateToProps = ({
    model:{
        modelData:{
            components:componentCats={},
            uiSettings:{
                primaryUnits
            }={},
        }={} 
    }={},
    drawing:{
        activeComponent='',
        activeImage='',
        imageData={}
    },
    form
}) => {
    const selector = formValueSelector('drawingLine');
    const selectedComponent = selector({form}, "attachTo") || {};
    const aboveGradeHeight = selector({form}, "aboveGradeHeight") || false;
    const lineName = selector({form}, "lineName") || '';

    const {
        componentType='',
        componentId:selectedComponentId=''
    } = selectedComponent;

    const {
        [activeImage]:{
            lines={},
            scale:{
                input=1,
                px=100,
                displayUnits
            }={}
        }={}
    } = imageData;

    const {
        [activeComponent]:lineData={}
    } = lines;

    const {
        changeField:{
            fieldRef:savedFieldRef='',
            key:savedFieldKey='',
        }={},
        attachTo:{
            componentType:savedComponentType='',
        }={},
        aboveGradeHeightRef:savedAboveGradeHeightRef
    } = lineData;

    const linesInDrawing = Object.keys(lines).map(line => {
        const {
            attachTo:{
                componentId='',
                componentType=''
            }={},
            changeField:{
                key:fieldKey=''
            }={}
        } = lines[line] || {};

        return {
            componentId,
            componentType,
            fieldKey
        }
    });

    const componentOptions = Object.keys(componentCats)
        .reduce((cache, key) => {
            if (key === 'slab' || key === 'walkout') {
                return cache;
            }
            // get id and location
            const components = componentCats[key] || {};
            const componentArray = Object.keys(components).reduce((cache, componentId) => {
                const { label='', category='', subcomponents={} } = components[componentId] || {};

                const subcomponentArray = Object.keys(subcomponents).reduce((subCache, subCat) => {
                    if (subCat === 'door') {
                        return subCache;
                    }

                    const subcomponentCat = subcomponents[subCat] || {};

                    const subcomponentsInCat = Object.keys(subcomponentCat).map(subId => {
                        const { label:subLabel='' } = subcomponentCat[subId] || {};
    
                        return {
                            label:subLabel,
                            value:{
                                componentType:subCat,
                                componentId:subId,
                                category:'',
                                path:`${key}.${componentId}.subcomponents.${subCat}.${subId}`,
                                isSubComponent:true
                            }
                        };
                    });

                    return [
                        ...subCache,
                        ...subcomponentsInCat
                    ]
                },[])
                

                return [
                    ...cache,
                    {
                        label,
                        value:{
                            componentType:key,
                            componentId,
                            category:'',
                            path:`${key}.${componentId}`,
                            isSubComponent:false
                        }
                    },
                    ...subcomponentArray
                ]
            },[])

            return [
                ...cache,
                ...componentArray
            ]
        },[]);

    //Get unsaved options from drawing
    const newComponentOptions = Object.keys(imageData)
        .filter((key) => key.includes('image_Drawing'))
        .reduce((cache, imageKey) => {
            const {
                polygons = {},
                components: subcomponents = {}
            } = imageData[imageKey];

            
            const polyComponentArray = Object.values(polygons).reduce((pCache, poly) => {
                const {
                    components = {}
                } = poly;
                if (!isEmpty(components)) {
                    return [
                        ...pCache,
                        ...Object.entries(components).reduce((cCache, [componentId, comp]) => {
                            const { label='', category='' } = comp;
                            const componentType = componentId.split('-')[0];
                            return [
                                ...cCache,
                                {
                                    label,
                                    value: {
                                        componentType,
                                        componentId,
                                        category:'',
                                        path:`${componentType}.${componentId}`,
                                        isSubComponent:false
                                    }
                                }
                            ]
                        }, [])
                    ]
                }

                return pCache;
            },[]);

            const subcomponentArray = Object.values(subcomponents).reduce((subCache, subComp) => {
                const { 
                    type = '', 
                    componentName:subLabel='', 
                    componentModelId='', 
                    attachTo: {componentType:attachedCompType='', componentId:attachedCompId=''} = {} 
                } = subComp;
                if (type === 'door' || attachedCompType === '' || attachedCompId === '') {
                    return subCache;
                }

                return [
                    ...subCache,
                    {
                        label:subLabel,
                        value:{
                            componentType:type,
                            componentId:componentModelId,
                            category:'',
                            path:`${attachedCompType}.${attachedCompId}.subcomponents.${type}.${componentModelId}`,
                            isSubComponent:true
                        }
                    }
                ];
            },[]);


            return [
                ...cache,
                ...polyComponentArray,
                ...subcomponentArray
            ]
            
        },[])

    //Get unique options, prioritizing model
    const componentFlags = {};
    const uniqueCompOptions = [...componentOptions, ...newComponentOptions].filter((comp) => {
        const { value: { componentId='' } = {} } = comp;
        if (componentFlags[componentId]) {
            return false;
        }
        componentFlags[componentId] = true;
        return true
    });

    const lineFieldOptions = getDrawingLineFieldOptions(componentType).filter(option => {
        const { value={} } = option || {};
        const { key='' } = value;

        // if anything in linesInDrawing has the selected component id, filter out those fields
        return linesInDrawing.every(({
            componentId='',
            fieldKey=''
        }) => {
            if (componentId !== selectedComponentId) {
                return true;
            }
            if (savedFieldKey === fieldKey) {
                return true;
            }
            return fieldKey !== key;
        })
    }).map(option => {
        const { value={} } = option || {};
        const { key='' } = value;

        const fieldRef = savedFieldRef && 
            (savedComponentType === componentType) &&
            (savedFieldKey === key) ?
                { fieldRef:savedFieldRef } :
                    {};
        return {
            ...option,
            value:{
                ...value,
                ...fieldRef
            }
        }
    });

    return {
        initialValues:lineData,
        activeComponent,
        activeImage,
        componentOptions:uniqueCompOptions,
        selectedComponent,
        lineFieldOptions,
        aboveGradeHeight,
        savedFieldRef,
        savedAboveGradeHeightRef,
        drawingComponent:getDrawingComponent(imageData, selectedComponentId),
        conversionFactor: input / px,
        primaryUnits,
        lineName
    }
};

const mapDispatchToProps = (dispatch) => ({
    removeLine:({image, lineId}) => dispatch(removeLine({image, lineId})),
    setAction:({id, meta={}}) => dispatch(setAction({id, meta})),
    setActiveComponent:(component) => dispatch(setActiveComponent({activeComponent:component})),
    updateFieldsToDetach:(components) => dispatch(updateFieldsToDetach(components)),
});

const mergeProps = (state, dispatch, own) => ({
    ...state,
    ...dispatch,
    ...own,
});

const onSubmit = async (
    form,
    dispatch,
    {
        activeComponent,
        activeImage,
        onSet,
        drawingComponent={},
        conversionFactor,
        primaryUnits
    }
) => {
    const data = {
        updates:form,
        image:activeImage,
        id:activeComponent,
    }

    const {
        changeField:{
            path='',
            key:fieldKey=''
        }={},
        aboveGradeHeight,
        length
    } = form;

    if (!isEmpty(drawingComponent) && !aboveGradeHeight && path) {
        const {
            type='',
            image='',
            componentData={},
            otherComponents={}
        } = drawingComponent;

        const {
            drawingShapeId,
            componentModelId,
        } = componentData;

        const calculatedLength = convertUnit({
            value:length*conversionFactor,
            type:'length',
            inputUnit:'m',
            outputUnit: getBaseUnits(fieldKey, primaryUnits).trueBase
        });

        const fieldValue = parseFloat(calculatedLength.toFixed(getDecimalPlaces(fieldKey)));

        if (type === "component") {
            const pathArray = path.split('.');
            const updatedComponent = getUpdatedComponent(componentData, pathArray, fieldValue);

            await updateComponent({
                image,
                componentId:drawingShapeId,
                updates:updatedComponent
            });
        }

        if (type === "polygonComponent") {
            const pathArray = path.split('.');
            const updatedComponent = getUpdatedComponent(componentData, pathArray, fieldValue);

            await updatePolygon({
                image,
                componentId:drawingShapeId, // this is wrong
                updates:{
                    components:{
                        ...otherComponents,
                        [componentModelId]:updatedComponent,
                    }
                }
            });
        }
    }

    await dispatch(updateLine(data));

    if (onSet) {
        onSet();
    }
}

const form = reduxForm({
    form:"drawingLine",
    enableReinitialize: true,
    onSubmit
})(LineActions);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(form);