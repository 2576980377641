const SET_COMMUNITY_DATA = "SET_COMMUNITY_DATA";
const CLEAR_COMMUNITY_DATA = "CLEAR_COMMUNITY_DATA";
const COMMUNITY_LOADING = "COMMUNITY_LOADING";
const SET_COMMUNITY_ERROR = "SET_COMMUNITY_ERROR";

export default {
    SET_COMMUNITY_DATA,
    CLEAR_COMMUNITY_DATA,
    COMMUNITY_LOADING,
    SET_COMMUNITY_ERROR
}