import React, { useState } from "react";
import { connect } from "react-redux";

import { changeUpgradePackageName } from "store/upgradeLibrary/thunk";

import Dialog, { CloseDialog } from "components/Dialog";
import Button from "components/Button";
import Input from "components/Input";

import classes from "./styles.module.scss";

const PackageNameChangeDialog = ({
    handleClose,
    toUpdatePackage,
    changeUpgradePackageName,
    uid,
    saveIntoOrganization,
    orgId,
}) => {
    const [newName, setNewName] = useState("");
    const [changing, toggleChanging] = useState(false);

    const { packageId = "", packageName = "" } = toUpdatePackage;

    const handleUpdate = async () => {
        toggleChanging(true);
        await changeUpgradePackageName(uid, packageId, newName, saveIntoOrganization, orgId);

        setNewName("");
        handleClose();
        toggleChanging(false);
    };

    return (
        <Dialog open={!!packageId}>
            <CloseDialog
                closeActions={() => {
                    handleClose();
                    setNewName("");
                }}
            />
            <div className={classes.packageNameChangeContainer}>
                <h3>
                    Rename <strong>{packageName}</strong>?
                </h3>
                <Input
                    hideLabel
                    // className={classes.inputWrapper}
                    disabled={changing}
                    placeholder={"Enter new name"}
                    input={{
                        onChange: (value) => setNewName(value),
                        value: newName,
                    }}
                />
                <div className={classes.footer}>
                    <Button
                        type="hollow"
                        onClick={() => {
                            handleClose();
                            setNewName("");
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            handleUpdate();
                        }}
                        // type=""
                        disabled={changing}
                    >
                        {changing ? "Changing..." : "Change"}
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

const mapDispatchToProps = (dispatch) => ({
    changeUpgradePackageName: (uid, packageId, newName, saveIntoOrganization, orgId) =>
        dispatch(changeUpgradePackageName(uid, packageId, newName, saveIntoOrganization, orgId)),
});

export default connect(null, mapDispatchToProps)(PackageNameChangeDialog);
