import { connect } from 'react-redux';
import Actions from './';
import { formValueSelector } from 'redux-form';


const mapStateToProps = (
    {
        form
    }, 
    {
        accessor,
        formName='model',
        isUpgrade=false,
    }
) => {
    const selector = formValueSelector(formName);
    const modelSelector = formValueSelector('model');

    const baseAccessor = accessor.includes('modelData') ? 'modelData.ventilation' : 'fields';
    
    const wholeHouseVentilators = selector({form}, `${baseAccessor}.wholeHouseSystems.ventilators`) || {};
    const supplementalVentilators = selector({form}, `${baseAccessor}.supplementalSystems.ventilators`) || {};

    return {
        accessor,
        wholeHouseVentilators,
        supplementalVentilators,
    }
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Actions);