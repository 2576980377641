import React from "react";
import DashboardTableHeader from "./Header";
import DashboardTableBody from "./Body";

// key must match the property in the model/folder/community object
// define "actions" for three dots beside each item
const columnHeadings = {
    modelDir: {
        name: "Name",
        lastEdited: "Last Modified",
        actions: "New",
    },
    commDir: {
        name: "Name",
        totalModels: "Total size",
        region: "Region",
        lastEdited: "Last Modified",
        // actions: "New"
    },
    chbaRenoCommModelDir: {
        name: "Name",
        userName: "User",
        provTerr: "Province",
        currentPhase: "Current Phase",
        lastEdited: "Last Modified",
        targetCompletionDate: "Target Completion",
    },
    commModelDir: {
        name: "Name",
        duplicates: "Duplicates",
        countDirection: "Count & Direction",
        status: "Status",
        lastEdited: "Last Modified",
        actions: "Add",
    },
};

export const DashboardTable = ({
    uid,
    type,
    folderLoading,
    createFolder,
    folderItemRef,
    allFoldersItems,
    dragAndDrop,
    selection,
    actions,
    paginate,
}) => {
    const isRenoComm = type === "chbaRenoCommModelDir";
    const columns = columnHeadings[type];

    return (
        <>
            <DashboardTableHeader
                uid={uid}
                isRenoComm={isRenoComm}
                columns={columns}
                folderLoading={folderLoading}
                createFolder={createFolder}
            />
            <DashboardTableBody
                isRenoComm={isRenoComm}
                columns={columns}
                folderItemRef={folderItemRef}
                allFoldersItems={allFoldersItems}
                dragAndDrop={dragAndDrop}
                selection={selection}
                actions={actions}
                paginate={paginate}
            />
        </>
    );
};
