import React, { useState, useEffect } from 'react';
import classes from './style.module.scss';
import ArrowDown from 'assets/images/icons/JSX/arrow-down';
import { Collapse } from '@material-ui/core';
import { isEqual } from 'lodash';
import classNames from "classnames";

const ChbaChecklistAccordion = React.memo(({
    children,
    heading,
    id='',
    hoverActions=null,
    className,
    headerClassName,
    large=false,
    stats:Stats,
    initOpenState=false,
    isDragging,
    isNew=false,
    dragActive=false,
    toggleNew=()=>'',
    disabled=false,
    number,
    forceOpen = false,
    phaseKey,
    setCurrentPhase
}) => {
    const [open, toggleOpen] = useState(initOpenState);
    const [highlight, toggleHighlight] = useState(isNew);

    const containerClasses = [
        classes.accordion,
        open && classes.open,
        className && className,
        large && classes.large,
        isDragging && classes.dragging,
        highlight && classes.highlight,
        dragActive && classes.dragActive,
        disabled && classes.disabled,
    ].filter(clss => clss);

    useEffect(() => {
        if (forceOpen) {
            toggleOpen(true);
        } else {
            toggleOpen(initOpenState);
        }
    }, [forceOpen])

    return (
        <div
            className={containerClasses.join(' ')}
            onClick={()=>{
                toggleHighlight(false);
                if (isNew) {
                    toggleNew()
                }
            }}
            >
            <div className={classNames(classes.header, { [classes.hasNumber]:number, [headerClassName]:headerClassName })}>
                {number && <span className={classes.number}>{number}</span>}
                <div             
                    className={classes.heading}
                    onClick={(e)=>{
                        if (e.target.tagName !== "svg") {
                          toggleOpen(!open);
                        }    
                    }}
                >                  
                  {heading}                 
                </div>
                
                {Stats && <div className={classes.stats}>{typeof Stats === "function" ? <Stats /> : Stats}</div>}
                {hoverActions && <div className={classes.hoverActions}>{hoverActions}</div>}
                <div
                    className={classes.toggle}
                    onClick={()=>{
                        toggleOpen(!open);
                    }}
                >
                    <ArrowDown />
                </div>
            </div>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <div className={classes.body}>
                    {children}
                </div>
            </Collapse>
        </div>
    )
}, isEqual);

export default ChbaChecklistAccordion;