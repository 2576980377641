import React from "react";
import classes from "./style.module.scss";
import sharedClasses from "../style.module.scss";

export default () => {
    return (
        <>
            <p>
                Use the Airtightness Tab to input information about the building's air infiltration data. It is
                analogous to the Natural Air Infiltration screen in HOT2000.
            </p>
            <h3>What is different from HOT2000?</h3>
            <p>
                <strong>Notable fields that have moved:</strong>
            </p>
            <ul>
                <li>Total House Volume → Enclosure Tab</li>
                <li>Above Grade Height of Highest Ceiling → Enclosure Tab</li>
            </ul>
            <h3>Known Issues and Limitations</h3>
            <ul>
                <li>
                    When air leakage test data inputs are used to enter blower door test data, the correct air change
                    rate and equivalent leakage area for the home will be updated the next time a simulation is run, or
                    when the file is imported into HOT2000 for the first time.
                </li>
                <li>
                    When air leakage test data is entered that requires the specification of multiple zone volumes, the
                    overall volume of the home will be updated to equal the sum of the zone volumes to prevent HOT2000
                    calculation errors.
                </li>
            </ul>
        </>
    );
};
