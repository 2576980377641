import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import Measurements from "./";
import isEmpty from "lodash/isEmpty";

const mapStateToProps = ({ form }, { accessor }) => {
    const selector = formValueSelector("model");
    const lengthUnits = selector({ form }, `${accessor}.measurements.length_u`);
    const lengthDrawing = selector({ form }, `${accessor}.measurements.length_drawingRef`) || {};
    const areaUnits = selector({ form }, `${accessor}.measurements.area_u`);
    const heelHeightUnits = selector({ form }, `${accessor}.measurements.heelHeight_u`);
    const modelUnits = selector({ form }, `modelData.uiSettings.primaryUnits`) || {};
    const { id: slopeId } = selector({ form }, `${accessor}.measurements.slope`) || {};

    const ceilingLengthEquation = selector({ form }, `${accessor}.measurements.length_eqn`);
    const ceilingAreaEquation = selector({ form }, `${accessor}.measurements.area_eqn`);
    const ceilingHeelHeightEquation = selector({ form }, `${accessor}.measurements.heelHeight_eqn`);

    return {
        accessor,
        slopeId,
        modelUnits,
        lengthUnits,
        areaUnits,
        heelHeightUnits,
        lengthDrawing: !isEmpty(lengthDrawing),
        ceilingLengthEquation,
        ceilingAreaEquation,
        ceilingHeelHeightEquation,
    };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Measurements);
