import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import EvaluationProgram from "./";

const mapStateToProps = ({ form }, { accessor, formName = "model" }) => {
    const selector = formValueSelector(formName);
    const modelSelector = formValueSelector("model");

    const { id: dryerRatedId, value: dryerEnergyRatedValue } = selector({ form }, `${accessor}.dryer.ratedValue`) || {};
    const { id: stoveRatedId, value: stoveRatedValue } = selector({ form }, `${accessor}.stove.ratedValue`) || {};
    const { id: fridgeRatedId, value: fridgeRatedValue } =
        selector({ form }, `${accessor}.refrigerator.ratedValue`) || {};
    const { id: intLightingId, value: intLightingValue } =
        selector({ form }, `${accessor}.interiorLighting.dailyConsumption`) || {};

    const dryerEnergyRatedUnits = selector({ form }, `${accessor}.dryer.ratedValue_u`);
    const percentDryerLoadsUnits = selector({ form }, `${accessor}.dryer.percentageOfWasherLoads_u`);
    const stoveRatedUnits = selector({ form }, `${accessor}.stove.ratedValue_u`);
    const fridgeRatedUnits = selector({ form }, `${accessor}.refrigerator.ratedValue_u`);
    const intLightingUnits = selector({ form }, `${accessor}.interiorLighting.dailyConsumption_u`);
    const extUseUnits = selector({ form }, `${accessor}.averageExteriorUse_u`);
    const otherLoadsUnits = selector({ form }, `${accessor}.otherLoad_u`);
    const modelUnits = modelSelector({ form }, `modelData.uiSettings.primaryUnits`) || {};

    const suppleVentilators = modelSelector({ form }, `modelData.ventilation.supplementalSystems`) || {};
    const isDryerInstalled = Object.keys(suppleVentilators).includes("dryer");

    return {
        accessor,
        isDryerInstalled,
        dryerRatedId,
        stoveRatedId,
        fridgeRatedId,
        intLightingId,
        dryerEnergyRatedValue,
        stoveRatedValue,
        fridgeRatedValue,
        intLightingValue,
        dryerEnergyRatedUnits,
        percentDryerLoadsUnits,
        stoveRatedUnits,
        fridgeRatedUnits,
        intLightingUnits,
        extUseUnits,
        otherLoadsUnits,
        modelUnits,
    };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EvaluationProgram);
