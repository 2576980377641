import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import Baseboards from './';

const mapStateToProps = (
    {
        form
    },
    {
        accessor,
        formName="model",
    }
) => {
    const selector = formValueSelector(formName);
    const modelSelector = formValueSelector('model');

    const { id: baseboardCapacityId, value: baseboardCapacityValue } = selector({form}, `${accessor}.system.baseboards.specifications.outputCapacity`) || {};
    const baseboardCapacityUnits = selector({form}, `${accessor}.system.baseboards.specifications.outputCapacity_u`);
    
    const modelUnits = modelSelector({form}, `modelData.uiSettings.primaryUnits`) || {};

    return {
        accessor,
        baseboardCapacityId,
        baseboardCapacityValue,
        baseboardCapacityUnits,
        modelUnits
    }
};

const mapDispatchToProps = () => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Baseboards);