import React from 'react';
import classes from '../style.module.scss';
import sharedClasses from 'features/Model/sharedStyles.module.scss';
import { Field } from 'redux-form';
import { required, maxLength, getValidation, getDecimalPlaces } from 'utils/fieldValidation';
import InputRow from 'components/Input/Row';
import Input from 'components/Input';
import Checkbox from 'components/Input/Checkbox';

const totalACValidation = getValidation('ersRoomACCount');
const eStarACValidation = getValidation('ersRoomACEStar');

export default React.memo(({
    accessor,
    isPortableAC,
    isMURB,
    modelUnits,
    change
}) => {

    return (
        <div>
            <h4 style={{marginBottom:"1.25rem"}}>Indicate Presence of:</h4>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Field
                    component={Checkbox}
                    name={`${accessor}.portableAirConditioning`}
                    label="Room Air Conditioners"
                    large
                    className={classes.bottomCheckBox}
                    type="checkbox"
                />
                <Field
                    component={Input}
                    type="number"
                    name={`${accessor}.roomACtotalUnits`}
                    label="Total Portable AC Units"
                    placeholder={0.00}
                    validate={totalACValidation}
                    decimals={getDecimalPlaces('ersRoomACCount')}
                    disabled={!isPortableAC}
                />
                <Field
                    component={Input}
                    type="number"
                    name={`${accessor}.roomACEStarUnits`}
                    label="Energy Star AC Units"
                    placeholder={0.00}
                    validate={eStarACValidation}
                    decimals={getDecimalPlaces('ersRoomACEStar')}
                    disabled={!isPortableAC}
                />
            </InputRow>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr" style={{marginTop:'2rem', paddingTop:'2rem', borderTop:'1px solid #E1EAF0'}}>
                <Field
                    component={Checkbox}
                    name={`${accessor}.deIcingCables`}
                    label="De-icing Cables"
                    large
                    className={classes.bottomCheckBox}
                    type="checkbox"
                />
                <Field
                    component={Checkbox}
                    name={`${accessor}.evChargingStation`}
                    label="EV Charging Station"
                    large
                    className={classes.bottomCheckBox}
                    type="checkbox"
                />
                <Field
                    component={Checkbox}
                    name={`${accessor}.exteriorLighting`}
                    label="Extensive Exterior Lighting"
                    large
                    className={classes.bottomCheckBox}
                    type="checkbox"
                />
                <Field
                    component={Checkbox}
                    name={`${accessor}.heatedGarage`}
                    label="Heated Garage"
                    large
                    className={classes.bottomCheckBox}
                    type="checkbox"
                />
            </InputRow>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Field
                    component={Checkbox}
                    name={`${accessor}.hotTub`}
                    label="Hot Tub"
                    large
                    className={classes.bottomCheckBox}
                    type="checkbox"
                />
                <Field
                    component={Checkbox}
                    name={`${accessor}.mixedUse`}
                    label="Non-residential Occupancy"
                    large
                    className={classes.bottomCheckBox}
                    type="checkbox"
                />
                <Field
                    component={Checkbox}
                    name={`${accessor}.outdoorGasAppliances`}
                    label="Outdoor Gas Appliances"
                    large
                    className={classes.bottomCheckBox}
                    type="checkbox"
                />
                <Field
                    component={Checkbox}
                    name={`${accessor}.pool`}
                    label="Swimming Pool"
                    large
                    className={classes.bottomCheckBox}
                    type="checkbox"
                />
            </InputRow>
            {
                isMURB ? 
                    <>
                        <h4 style={{marginBottom:"1.25rem", marginTop:"1.75rem"}}>MURB-Specific Features</h4>
                        <InputRow gridTemplate="1fr 1fr 1fr 1fr 1fr">
                            <Field
                                component={Checkbox}
                                name={`${accessor}.commonSpace`}
                                label="Common Space"
                                large
                                className={classes.bottomCheckBox}
                                type="checkbox"
                            />
                            <Field
                                component={Checkbox}
                                name={`${accessor}.elevator`}
                                label="Elevator"
                                large
                                className={classes.bottomCheckBox}
                                type="checkbox"
                            />
                            <Field
                                component={Checkbox}
                                name={`${accessor}.fitnessRoom`}
                                label="Fitness Room"
                                large
                                className={classes.bottomCheckBox}
                                type="checkbox"
                            />
                            <Field
                                component={Checkbox}
                                name={`${accessor}.snowmeltSystem`}
                                label="Snowmelt System"
                                large
                                className={classes.bottomCheckBox}
                                type="checkbox"
                            />
                            <Field
                                component={Checkbox}
                                name={`${accessor}.unheatedGarage`}
                                label="Unheated Garage"
                                large
                                className={classes.bottomCheckBox}
                                type="checkbox"
                            />
                        </InputRow>
                    </> : <></>
            }
        </div>
    )
})