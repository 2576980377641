import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import LineItems from './';

const mapStateToProps = (
    {
        form,
    },
    {
        accessor
    }
) => {
    const selector = formValueSelector('model');
    const lineItemValues = selector({form}, `${accessor}.items`) || {};
    const lineItemTotal = selector({form}, `${accessor}.total`);
    const lineItemUnits = selector({form}, `${accessor}_u`);
    const modelUnits = selector({form}, `modelData.uiSettings.primaryUnits`) || {};

    const aboveGradeItems = selector({form}, 'modelData.dimensions.aboveGradeIntFloorArea.items') || {};
    const belowGradeItems = selector({form}, 'modelData.dimensions.belowGradeIntFloorArea.items') || {};

    const floorAreaItems = {...aboveGradeItems, ...belowGradeItems};

    return {
        accessor,
        lineItemValues,
        lineItemTotal,
        lineItemUnits,
        modelUnits,
        floorAreaItems,
    }
};

const mapDispatchToProps = () => ({});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LineItems);