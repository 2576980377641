import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import App from "components/App";
import { actions as userActions } from "store/users";
import { mixpanel } from "components/Mixpanel";

const { fetchUserData, userStart } = userActions;

const mapStateToProps = ({ user: { loading, lastActivity, uid: loadedUid } }) => ({
    loading,
    lastActivity,
    loadedUid,
});
const mapDispatchToProps = (dispatch) => ({
    fetchUserData: (userId) => {
        dispatch(userStart());

        return dispatch(fetchUserData(userId)).then(
            async ({ email, name, company, userMeta: { role, experienceLevel } = {} }) => {
                await mixpanel.people.set({
                    $email: email,
                    $name: name,
                    Company: company,
                    Role: role,
                    "Expierience Level": experienceLevel,
                });
                mixpanel.identify(userId);
            },
        );
    },
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(App);
