import React, { useCallback, useState } from "react";
import classes from "./style.module.scss";
import sharedClasses from "features/Model/sharedStyles.module.scss";
import { Field, unregisterField } from "redux-form";
import { getOptions, getFirstOption, getBaseUnits, getUnitOptions } from "utils/fields";
import { getModuleSpecs } from "utils/mechanicals";
import { required, maxLength, getValidation, getDecimalPlaces } from "utils/fieldValidation";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import InputWithUnits from "components/Input/InputWithUnits";
import Select from "components/Input/Select";
import Accordion from "components/Accordion";
import Actions from "../Actions/container";
import Button from "components/Button";
import { getSolarAzimuth } from "utils/weather/api";

const charLim40 = maxLength(40);
const arrayAreaValidation = getValidation("solarArrayArea");
const arraySlopeValidation = getValidation("solarArraySlope");
const arrayAzimuthValidation = getValidation("solarArrayAzimuth");
const moduleTypeValidation = getValidation("solarModuleType");
const moduleEffValidation = getValidation("solarModuleEff");
const moduleNoctValidation = getValidation("solarModuleNOCT");
const moduleTempCoeffValidation = getValidation("solarModuleTempCoeff");
const miscLossesValidation = getValidation("solarMiscLosses");
const inverterEffValidation = getValidation("solarInverterEff");
const otherPowerLossesValidation = getValidation("solarOtherPowerLosses");
const gridAbsorptionValidation = getValidation("solarGridAbsorptionRate");

const arrayCapValidation = getValidation("solarArrayCapacity");
const arrayLatValidation = getValidation("solarArrayLatitude");
const arrayLonValidation = getValidation("solarArrayLongitude");
const arrayCompassDirValidation = getValidation("solarArrayCompassDir");
const arrayDeclValidation = getValidation("solarArrayDeclination");
const moduleCountValidation = getValidation("solarModuleCount");
const moduleWidthValidation = getValidation("solarModuleWidth");
const moduleHeightValidation = getValidation("solarModuleHeight");
const moduleCapValidation = getValidation("solarModuleCapacity");
const postalCodeValidation = getValidation("clientPostalCode");
const addressValidation = getValidation("clientStreetAddress");
const cityValidation = getValidation("clientCity");

export default ({
    accessor,
    systemLabel,
    manufacturer,
    model,
    sysNum,
    moduleCountValue,
    moduleWidthValue,
    moduleHeightValue,
    arrayAreaValue,
    moduleCapacityValue,
    arrayCapacityValue,
    compassDirValue,
    latitudeValue,
    longitudeValue,
    postalCodeValue,
    clientAddressValue,
    clientCityValue,
    azimuthValue,
    declinationValue,
    arrayAreaUnits,
    moduleWidthUnits,
    moduleHeightUnits,
    moduleCapacityUnits,
    arrayCapacityUnits,
    moduleTypeId,
    moduleEffValue,
    moduleEffUnits,
    moduleNoctValue,
    moduleNoctUnits,
    moduleTempCoeffValue,
    moduleTempCoeffUnits,
    modelUnits,
    change,
    contentOpen,
    formName,
    isUpgrade = false,
    setAzimSubmitting,
    azimSubmitting,
}) => {
    const [azimuthInputType, setAzimuthInputType] = useState("latLon");

    const handleGetAzimuth = useCallback(async () => {
        setAzimSubmitting(true);
        let { data: { declination, h2kAzimuth, latitude, longitude } = {} } =
            (await getSolarAzimuth({
                lat: latitudeValue,
                lon: longitudeValue,
                compassDir: compassDirValue,
                searchType: azimuthInputType,
                postalCode:
                    azimuthInputType === "address" ? `${clientAddressValue} ${clientCityValue}` : postalCodeValue,
            })) || {};

        if (h2kAzimuth > 180) {
            h2kAzimuth = h2kAzimuth - 360;
        }

        change(`${accessor}.array.azimuth`, parseFloat(h2kAzimuth.toFixed(1)));
        change(`${accessor}.array.latitude`, parseFloat(latitude.toFixed(2)));
        change(`${accessor}.array.longitude`, parseFloat(longitude.toFixed(2)));
        change(`${accessor}.array.declination`, parseFloat(declination.toFixed(1)));

        setAzimSubmitting(false);
    }, [
        change,
        azimuthInputType,
        setAzimSubmitting,
        compassDirValue,
        latitudeValue,
        longitudeValue,
        postalCodeValue,
        clientAddressValue,
        clientCityValue,
    ]);

    const createLabel = () => {
        if (systemLabel) {
            return systemLabel;
        } else if (manufacturer && !model) {
            return `${manufacturer}`;
        } else if (manufacturer && model) {
            return `${manufacturer} | ${model}`;
        } else if (!manufacturer && model) {
            return `${model}`;
        } else {
            return `Solar PV Array ${sysNum}`;
        }
    };

    const disableSubmit =
        (azimuthInputType === "latLon" && (!latitudeValue || !longitudeValue)) ||
        (azimuthInputType === "postalCode" && !postalCodeValue) ||
        (azimuthInputType === "address" && (!clientAddressValue || !clientCityValue)) ||
        !compassDirValue;

    return (
        <Accordion
            className={sharedClasses.accordion}
            heading={<span className={sharedClasses.heading}>{createLabel()}</span>}
            large
            hoverActions={<Actions accessor={accessor} change={change} formName={formName} isUpgrade={isUpgrade} />}
            forceOpen={contentOpen}
        >
            <InputRow gridTemplate="1fr 1fr 1fr">
                <Field
                    component={Input}
                    name={`${accessor}.manufacturer`}
                    type="text"
                    label="Manufacturer"
                    placeholder=""
                    validate={charLim40}
                />
                <Field
                    component={Input}
                    name={`${accessor}.model`}
                    type="text"
                    label="Model"
                    placeholder=""
                    validate={charLim40}
                />
            </InputRow>
            <h4>Array Details</h4>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Field
                    component={Input}
                    type="number"
                    name={`${accessor}.array.count`}
                    label="Module Count"
                    placeholder={0}
                    validate={moduleCountValidation}
                    decimals={getDecimalPlaces("solarModuleCount")}
                    change={change}
                    onChange={(value) => {
                        const newTotalCap = value * moduleCapacityValue;
                        if (!isNaN(newTotalCap)) {
                            change(`${accessor}.array.capacity`, newTotalCap / 1000);
                        }

                        const newArraySize = value * moduleWidthValue * moduleHeightValue;
                        if (!isNaN(newArraySize)) {
                            change(`${accessor}.array.area`, newArraySize);
                        }
                    }}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.array.width`}
                    label="Module Width"
                    placeholder={0.0}
                    validate={moduleWidthValidation}
                    decimals={getDecimalPlaces("solarModuleWidth")}
                    change={change}
                    units={{
                        base: getBaseUnits("solarModuleWidth", modelUnits),
                        options: getUnitOptions("length"),
                        selected: moduleWidthUnits,
                        unitType: "length",
                        accessor: `${accessor}.array.width_u`,
                    }}
                    onChange={(value) => {
                        const newArraySize = value * moduleCountValue * moduleHeightValue;
                        if (!isNaN(newArraySize)) {
                            change(`${accessor}.array.area`, newArraySize);
                        }
                    }}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.array.height`}
                    label="Module Height"
                    placeholder={0.0}
                    validate={moduleHeightValidation}
                    decimals={getDecimalPlaces("solarModuleHeight")}
                    change={change}
                    units={{
                        base: getBaseUnits("solarModuleHeight", modelUnits),
                        options: getUnitOptions("length"),
                        selected: moduleHeightUnits,
                        unitType: "length",
                        accessor: `${accessor}.array.height_u`,
                    }}
                    onChange={(value) => {
                        const newArraySize = value * moduleCountValue * moduleWidthValue;
                        if (!isNaN(newArraySize)) {
                            change(`${accessor}.array.area`, newArraySize);
                        }
                    }}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.array.area`}
                    label="Array Area"
                    placeholder={0.0}
                    validate={arrayAreaValidation}
                    decimals={getDecimalPlaces("solarArrayArea")}
                    change={change}
                    setValue={arrayAreaValue}
                    units={{
                        base: getBaseUnits("solarArrayArea", modelUnits),
                        options: getUnitOptions("area"),
                        selected: arrayAreaUnits,
                        unitType: "area",
                        accessor: `${accessor}.array.area_u`,
                    }}
                />
            </InputRow>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.array.moduleCapacity`}
                    label="Single Module Pmax"
                    placeholder={0.0}
                    validate={moduleCapValidation}
                    decimals={getDecimalPlaces("solarModuleCapacity")}
                    change={change}
                    units={{
                        base: getBaseUnits("solarModuleCapacity", modelUnits),
                        options: ["kW", "W"],
                        selected: moduleCapacityUnits,
                        unitType: "power",
                        accessor: `${accessor}.array.moduleCapacity_u`,
                    }}
                    onChange={(value) => {
                        const newTotalCap = value * moduleCountValue;
                        if (!isNaN(newTotalCap)) {
                            change(`${accessor}.array.capacity`, newTotalCap / 1000);
                        }
                    }}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.array.capacity`}
                    label="Array Capacity"
                    placeholder={0.0}
                    validate={arrayCapValidation}
                    decimals={getDecimalPlaces("solarArrayCapacity")}
                    change={change}
                    setValue={arrayCapacityValue}
                    disabled={true}
                    units={{
                        base: getBaseUnits("solarArrayCapacity", modelUnits),
                        options: ["kW", "W"],
                        selected: arrayCapacityUnits,
                        unitType: "power",
                        accessor: `${accessor}.array.capacity_u`,
                    }}
                />
            </InputRow>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Select
                    name="azimuthInputType"
                    label="Azimuth Input Method"
                    placeholder="Choose Input Method"
                    options={[
                        {
                            label: "Lat/Lon",
                            value: "latLon",
                        },
                        {
                            label: "Postal Code",
                            value: "postalCode",
                        },
                        {
                            label: "Address",
                            value: "address",
                        },
                    ]}
                    disabled={false}
                    input={{
                        onChange: (value) => {
                            setAzimuthInputType(value);
                        },
                        value: azimuthInputType,
                    }}
                />
                {azimuthInputType === "latLon" && (
                    <>
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.array.latitude`}
                            label="Latitude"
                            placeholder={0.0}
                            validate={arrayLatValidation}
                            decimals={getDecimalPlaces("solarArrayLatitude")}
                            change={change}
                            units={{
                                base: getBaseUnits("solarArrayLatitude", modelUnits),
                                options: ["°"],
                                selected: "°",
                                unitType: "length",
                                accessor: `${accessor}.array.latitude_u`,
                            }}
                        />
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.array.longitude`}
                            label="Longitude"
                            placeholder={0.0}
                            validate={arrayLonValidation}
                            decimals={getDecimalPlaces("solarArrayLongitude")}
                            change={change}
                            units={{
                                base: getBaseUnits("solarArrayLongitude", modelUnits),
                                options: ["°"],
                                selected: "°",
                                unitType: "length",
                                accessor: `${accessor}.array.longitude_u`,
                            }}
                            info="Western longitudes are indicated by negative numbers."
                        />
                    </>
                )}
                {azimuthInputType === "postalCode" && (
                    <>
                        <Field
                            component={Input}
                            type="text"
                            name={"modelData.general.client.clientPostalCode"}
                            label="Postal Code"
                            placeholder=""
                            validate={postalCodeValidation}
                            change={change}
                            info="This field is linked to the Client input in the General tab."
                        />
                        <div></div>
                    </>
                )}
                {azimuthInputType === "address" && (
                    <>
                        <Field
                            component={Input}
                            type="text"
                            name={"modelData.general.client.clientStreetAddress"}
                            label="Street Address"
                            placeholder=""
                            validate={addressValidation}
                            change={change}
                            info="This field is linked to the Client input in the General tab."
                        />
                        <Field
                            component={Input}
                            type="text"
                            name={"modelData.general.client.clientCity"}
                            label="City"
                            placeholder=""
                            validate={cityValidation}
                            change={change}
                            info="This field is linked to the Client input in the General tab."
                        />
                    </>
                )}
                <Button
                    className={classes.getAzimuthButton}
                    onClick={handleGetAzimuth}
                    disabled={azimSubmitting || disableSubmit}
                >
                    Get HOT2000 Azimuth
                </Button>
            </InputRow>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.array.slope`}
                    label="Slope"
                    placeholder={0.0}
                    validate={arraySlopeValidation}
                    decimals={getDecimalPlaces("solarArraySlope")}
                    change={change}
                    units={{
                        base: getBaseUnits("solarArraySlope", modelUnits),
                        options: ["°"],
                        selected: "°",
                        unitType: "length",
                        accessor: `${accessor}.array.slope_u`,
                    }}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.array.compassDir`}
                    label="Array Compass Direction"
                    placeholder={0.0}
                    validate={arrayCompassDirValidation}
                    decimals={getDecimalPlaces("solarArrayCompassDir")}
                    change={change}
                    units={{
                        base: getBaseUnits("solarArrayCompassDir", modelUnits),
                        options: ["°"],
                        selected: "°",
                        unitType: "length",
                        accessor: `${accessor}.array.compassDir_u`,
                    }}
                    info={"North = 0°,\nEast = 90°,\nSouth = 180°,\nWest = 270°"}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.array.azimuth`}
                    label="HOT2000 Azimuth"
                    placeholder={0.0}
                    validate={arrayAzimuthValidation}
                    decimals={getDecimalPlaces("solarArrayAzimuth")}
                    change={change}
                    setValue={azimuthValue}
                    units={{
                        base: getBaseUnits("solarArrayAzimuth", modelUnits),
                        options: ["°"],
                        selected: "°",
                        unitType: "length",
                        accessor: `${accessor}.array.azimuth_u`,
                    }}
                    info={"South = 0°,\nEast = 90°,\nWest = -90°,\nNorth = 180°"}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.array.declination`}
                    label="Declination"
                    placeholder={0.0}
                    validate={arrayDeclValidation}
                    decimals={getDecimalPlaces("solarArrayDeclination")}
                    change={change}
                    setValue={declinationValue}
                    units={{
                        base: getBaseUnits("solarArrayDeclination", modelUnits),
                        options: ["°"],
                        selected: "°",
                        unitType: "length",
                        accessor: `${accessor}.array.declination_u`,
                    }}
                    info="Negative values indicate westerly, positive indicate easterly."
                />
            </InputRow>
            <h4>Module Specifications</h4>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Field
                    component={Select}
                    name={`${accessor}.module.type`}
                    options={[
                        ...getOptions({ fieldKey: "solarModuleType" }),
                        {
                            label: "User Specified",
                            value: {
                                id: 5,
                            },
                        },
                    ]}
                    label="Module Type"
                    placeholder="Choose Module Type"
                    validate={moduleTypeValidation}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.module.efficiency`}
                    label="Efficiency"
                    placeholder={0.0}
                    validate={moduleEffValidation}
                    decimals={getDecimalPlaces("solarModuleEff")}
                    change={change}
                    units={{
                        base: getBaseUnits("solarModuleEff", modelUnits),
                        options: getUnitOptions("fraction"),
                        selected: moduleEffUnits,
                        unitType: "fraction",
                        accessor: `${accessor}.module.efficiency_u`,
                    }}
                    disabled={moduleTypeId !== 5}
                    setValue={moduleTypeId !== 5 ? getModuleSpecs(moduleTypeId).eff : moduleEffValue}
                    info="At reference temperature of 25˚C / 77˚F"
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.module.cellTemperature`}
                    label="NOCT"
                    placeholder={0.0}
                    validate={moduleNoctValidation}
                    decimals={getDecimalPlaces("solarModuleNOCT")}
                    change={change}
                    units={{
                        base: getBaseUnits("solarModuleNOCT", modelUnits),
                        options: getUnitOptions("temperature"),
                        selected: moduleNoctUnits,
                        unitType: "temperature",
                        accessor: `${accessor}.module.cellTemperature_u`,
                    }}
                    disabled={moduleTypeId !== 5}
                    setValue={moduleTypeId !== 5 ? getModuleSpecs(moduleTypeId).noct : moduleNoctValue}
                    info={"Nominal Operating Cell Temperature"}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.module.coefficientOfEfficiency`}
                    label="Temperature Coefficient"
                    placeholder={0.0}
                    validate={moduleTempCoeffValidation}
                    decimals={getDecimalPlaces("solarModuleTempCoeff")}
                    change={change}
                    units={{
                        base: getBaseUnits("solarModuleTempCoeff", modelUnits),
                        options: getUnitOptions("tempCoeff"),
                        selected: moduleTempCoeffUnits,
                        unitType: "tempCoeff",
                        accessor: `${accessor}.module.coefficientOfEfficiency_u`,
                    }}
                    disabled={moduleTypeId !== 5}
                    setValue={moduleTypeId !== 5 ? getModuleSpecs(moduleTypeId).tempCoeff : moduleTempCoeffValue}
                />
            </InputRow>
            <h4>Losses and Efficiencies</h4>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.effAndLosses.miscLosses`}
                    label="Misc Array Losses"
                    placeholder={0.0}
                    validate={miscLossesValidation}
                    decimals={getDecimalPlaces("solarMiscLosses")}
                    change={change}
                    units={{
                        base: getBaseUnits("solarMiscLosses", modelUnits),
                        options: ["%"],
                        selected: ["%"],
                        unitType: "fraction",
                        accessor: `${accessor}.effAndLosses.miscLosses_u`,
                    }}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.effAndLosses.inverterEfficiency`}
                    label="Inverter Efficiency"
                    placeholder={0.0}
                    validate={inverterEffValidation}
                    decimals={getDecimalPlaces("solarInverterEff")}
                    change={change}
                    units={{
                        base: getBaseUnits("solarInverterEff", modelUnits),
                        options: ["%"],
                        selected: ["%"],
                        unitType: "fraction",
                        accessor: `${accessor}.effAndLosses.inverterEfficiency_u`,
                    }}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.effAndLosses.otherPowerLosses`}
                    label="Other Losses"
                    placeholder={0.0}
                    validate={otherPowerLossesValidation}
                    decimals={getDecimalPlaces("solarOtherPowerLosses")}
                    change={change}
                    units={{
                        base: getBaseUnits("solarOtherPowerLosses", modelUnits),
                        options: ["%"],
                        selected: ["%"],
                        unitType: "fraction",
                        accessor: `${accessor}.effAndLosses.otherPowerLosses_u`,
                    }}
                    info={"Other Power Conditioning Losses"}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.effAndLosses.gridAbsorptionRate`}
                    label="Grid Absorption Rate"
                    placeholder={0.0}
                    validate={gridAbsorptionValidation}
                    decimals={getDecimalPlaces("solarGridAbsorptionRate")}
                    change={change}
                    units={{
                        base: getBaseUnits("solarGridAbsorptionRate", modelUnits),
                        options: ["%"],
                        selected: ["%"],
                        unitType: "fraction",
                        accessor: `${accessor}.effAndLosses.gridAbsorptionRate_u`,
                    }}
                />
            </InputRow>
        </Accordion>
    );
};
