import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import FurnaceBoiler from "./";

const mapStateToProps = ({ form }, { accessor, formName = "model" }) => {
    const selector = formValueSelector(formName);
    const modelSelector = formValueSelector("model");

    const modelUnits = modelSelector({ form }, `modelData.uiSettings.primaryUnits`) || {};
    const { id: yearBuiltId, value: yearBuiltValue } =
        modelSelector({ form }, `modelData.specifications.yearBuilt`) || {};

    const currentPrimarySystem = Object.keys(selector({ form }, `${accessor}.system`) || {})[0];

    //accessor looks like: modelData.heatingCooling.heating OR fields.heating

    const coolingAccessor = `${accessor.slice(0, -8)}.cooling.system`;
    const coolingSystem = selector({ form }, coolingAccessor) || {};
    const coolingSystemType = Object.keys(coolingSystem)[0] || "";
    const disableDualFuel = coolingSystemType.includes("HeatPump");

    const { id: energySourceId } = selector({ form }, `${accessor}.system.${currentPrimarySystem}.energySource`) || {};
    const { id: equipTypeId } = selector({ form }, `${accessor}.system.${currentPrimarySystem}.equipmentType`) || {};
    const isDualFuel = selector({ form }, `${accessor}.system.${currentPrimarySystem}.isBiEnergy`);
    const { id: capacityId, value: capacityValue } =
        selector({ form }, `${accessor}.system.${currentPrimarySystem}.specifications.outputCapacity`) || {};
    const effValue = selector({ form }, `${accessor}.system.${currentPrimarySystem}.specifications.efficiency`) || 0;
    const isSteadyState =
        selector({ form }, `${accessor}.system.${currentPrimarySystem}.specifications.isSteadyState`) || false;
    const pilotLightValue = selector({ form }, `${accessor}.system.${currentPrimarySystem}.specifications.pilotLight`);
    const flueDiameterValue = selector(
        { form },
        `${accessor}.system.${currentPrimarySystem}.specifications.flueDiameter`
    );

    const capacityUnits = selector(
        { form },
        `${accessor}.system.${currentPrimarySystem}.specifications.outputCapacity_u`
    );
    const pilotUnits = selector({ form }, `${accessor}.system.${currentPrimarySystem}.specifications.pilotLight_u`);
    const flueUnits = selector({ form }, `${accessor}.system.${currentPrimarySystem}.specifications.flueDiameter_u`);
    const switchoverTempUnits = selector({ form }, `${accessor}.system.${currentPrimarySystem}.switchoverTemp_u`);

    return {
        accessor,
        currentPrimarySystem,
        energySourceId,
        equipTypeId,
        isDualFuel,
        effValue,
        isSteadyState,
        switchoverTempUnits,
        capacityId,
        capacityValue,
        capacityUnits,
        pilotLightValue,
        flueDiameterValue,
        pilotUnits,
        flueUnits,
        modelUnits,
        isRecentVintage: [9, 10].includes(yearBuiltId) || yearBuiltValue > 1987,
        disableDualFuel,
    };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FurnaceBoiler);
