import React, { useState, useCallback } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import uniqid from "uniqid";
import moment from "moment";
import { useHistory } from "react-router";

import { saveUpdateUpgradePackage } from "store/upgradeLibrary/thunk";
import { selectCodeLib } from "store/users/selectors";

import Dialog, { CloseDialog } from "components/Dialog";
import Button from "components/Button";
import Input from "components/Input";

import classes from "./styles.module.scss";

const PackageNameDialog = ({
    open,
    toggleOpen,
    selected,
    numberOfPackages = 0,
    uid,
    saveUpdateUpgradePackage,
    codeLib,
}) => {
    const history = useHistory();

    const [packageName, updatePackageName] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const handleSave = useCallback(async () => {
        setIsLoading(true);

        let dateObj = {};
        let uiObj = {};

        const packageId = uniqid("package-");

        dateObj = {
            created: moment().format("LLLL"),
            lastEdited: moment().format("LLLL"),
        };

        uiObj = { ui: { displayOrder: numberOfPackages } };

        await saveUpdateUpgradePackage({
            uid,
            upgradePackage: { name: packageName, ...dateObj, ...uiObj, upgrades: {} },
            upgradePackageId: packageId,
            saveAsNew: false,
            withCodes: true,
            codes: codeLib,
            isCodeLibrary: true,
        });

        toggleOpen(false);
        updatePackageName("");
        history.push(`/upgrade-library/${packageId}`);
        setIsLoading(false);
    }, [selected, packageName, toggleOpen, numberOfPackages]);

    const handleClose = useCallback(() => {
        toggleOpen(false);
        updatePackageName("");
    }, [toggleOpen]);

    return (
        <Dialog
            open={open}
            classes={{
                rootClass: "",
                paperClass: classes.dialogPaper,
            }}
        >
            <CloseDialog closeActions={handleClose} />
            <div className={classes.packageDialogContent}>
                <h2>Add Upgrade Package</h2>
                <div className={classes.inputButtonWrapper}>
                    <Input
                        type="text"
                        name={"packageName"}
                        label={`Create New Package`}
                        placeholder="Upgrade package name"
                        //validate={validate}
                        hideLabel
                        input={{
                            value: packageName,
                            onChange: (val) => updatePackageName(val),
                        }}
                    />
                    <span data-tip="Name field is required" data-for="dialogTip">
                        <Button
                            onClick={handleSave}
                            disabled={!packageName || isLoading}
                            style={{ height: "36px", width: "6.0625rem" }}
                        >
                            {isLoading ? "Creating..." : "Create"}
                        </Button>
                    </span>
                </div>
            </div>
        </Dialog>
    );
};

const mapStateToProps = createStructuredSelector({
    codeLib: selectCodeLib,
});

const mapDispatchToProps = (dispatch) => ({
    saveUpdateUpgradePackage: ({
        uid,
        upgradePackage,
        upgradePackageId: packageId,
        saveAsNew,
        withCodes,
        isCodeLibrary,
        codes,
    }) =>
        dispatch(
            saveUpdateUpgradePackage({
                uid,
                upgradePackage,
                upgradePackageId: packageId,
                saveAsNew,
                withCodes,
                isCodeLibrary,
                codes,
            })
        ),
});

export default connect(mapStateToProps, mapDispatchToProps)(PackageNameDialog);
