export const SORT_BY_OPTIONS = [
    {
        label: "Last Uploaded",
        value: "lastUploaded",
    },
    {
        label: "First Uploaded",
        value: "firstUploaded",
    },
    {
        label: "A to Z",
        value: "aToZ",
    },
    {
        label: "Z to A",
        value: "zToA",
    },
    {
        label: "Status",
        value: "status",
    },
];

export const REQUIRED_TAGS = [
    {
        text: "Tax roll number",
        id: "taxRollNumber",
        requiredAmountOfImages: 1,
        isInModel: true,
    },
    {
        text: "Exterior Elevations",
        id: "exteriorElevations",
        requiredAmountOfImages: 4,
        isInModel: true,
    },
    {
        text: "Exterior wall",
        id: "exteriorWall",
        requiredAmountOfImages: 4,
        isInModel: true,
    },
    {
        text: "Basement wall",
        id: "basementWall",
        requiredAmountOfImages: 1,
        isInModel: false,
    },
    {
        text: "Crawlspace",
        id: "crawlSpace",
        requiredAmountOfImages: 1,
        isInModel: false,
    },
    {
        text: "Header",
        id: "header",
        requiredAmountOfImages: 1,
        isInModel: false,
    },
    {
        text: "Exposed floor",
        id: "exposedFloor",
        requiredAmountOfImages: 1,
        isInModel: false,
    },
    {
        text: "Attic",
        id: "attic",
        requiredAmountOfImages: 1,
        isInModel: false,
    },
    {
        text: "Cathedral & flat ceilings",
        id: "cathedral&FlatCeilings",
        requiredAmountOfImages: 1,
        isInModel: false,
    },
    {
        text: "Mechanical systems",
        id: "mechanicalSystems",
        requiredAmountOfImages: 1,
        isInModel: true,
    },
    {
        text: "ASHP and Central AC",
        id: "ashpAndCentralAc",
        requiredAmountOfImages: 1,
        isInModel: false,
    },
    {
        text: "DWHR",
        id: "dwhr",
        requiredAmountOfImages: 1,
        isInModel: false,
    },
    {
        text: "Solar DHW",
        id: "solarDHW",
        requiredAmountOfImages: 1,
        isInModel: false,
    },
    {
        text: "PV solar panel",
        id: "pvSolarPanel",
        requiredAmountOfImages: 1,
        isInModel: false,
    },
    {
        text: "Atypical energy loads",
        id: "atypicalEnergyLoads",
        requiredAmountOfImages: 1,
        isInModel: false,
    },
    {
        text: "Solar Ready",
        id: "solarReady",
        requiredAmountOfImages: 1,
        isInModel: true,
    },
    {
        text: "Toilets",
        id: "toilets",
        requiredAmountOfImages: 1,
        isInModel: true,
    },
    {
        text: "Additions / renovations",
        id: "additionsRenovations",
        requiredAmountOfImages: 1,
        isInModel: true,
    },
    {
        text: "Blower door",
        id: "blowerDoor",
        requiredAmountOfImages: 1,
        isInModel: false,
    },
    {
        text: "Wind turbines",
        id: "windTurbines",
        requiredAmountOfImages: 1,
        isInModel: false,
    },
    {
        text: "Half storey",
        id: "halfStorey",
        requiredAmountOfImages: 1,
        isInModel: false,
    },
    {
        text: "Exposed Vermiculite",
        id: "exposedVermiculite",
        requiredAmountOfImages: 1,
        isInModel: false,
    },
];
