import React from "react";

export default () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.585786 8.62705C-0.195262 7.85581 -0.195262 6.60539 0.585786 5.83415L5.53553 0.946591C6.31658 0.175355 7.58291 0.175355 8.36396 0.946591L23.2132 15.6093C23.9942 16.3805 23.9942 17.6309 23.2132 18.4022L18.2635 23.2897C17.4824 24.061 16.2161 24.061 15.435 23.2897L0.585786 8.62705ZM1.64645 6.88149L6.59619 1.99393C6.79145 1.80112 7.10804 1.80112 7.3033 1.99393L8.54053 3.21561L5.35852 6.35764L6.41918 7.40498L9.60119 4.26295L11.369 6.00851L9.60116 7.75409L10.6618 8.80143L12.4296 7.05584L14.1974 8.8014L11.0154 11.9434L12.076 12.9908L15.258 9.84873L17.0258 11.5943L15.258 13.3399L16.3187 14.3872L18.0865 12.6416L19.8542 14.3872L16.6722 17.5292L17.7329 18.5765L20.9149 15.4345L22.1525 16.6566C22.3478 16.8494 22.3478 17.162 22.1525 17.3548L17.2028 22.2424C17.0075 22.4352 16.6909 22.4352 16.4957 22.2424L1.64645 7.57971C1.45118 7.3869 1.45118 7.0743 1.64645 6.88149Z"
            fill="black"
        />
    </svg>
);
