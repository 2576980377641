import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import resultsClasses from "../Results/style.module.scss";
import sharedClasses from "features/Model/sharedStyles.module.scss";
import { Field } from "redux-form";
import { maxLength } from "utils/fieldValidation";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import LoadingDots from "components/LoadingDots";
import SummaryTable from "./SummaryTable";

import Export from "assets/images/icons/JSX/Export";
import Tooltip from "components/Tooltip";
import Drawer from "components/Drawer";
import Select from "components/Input/Select";
import Button from "components/Button";
import Delete from "assets/images/icons/JSX/Delete";
import Exclamation from "assets/images/icons/JSX/Exlamation";
import ExportF280PDF from "./ExportF280PDF";

import { getF280SummaryData } from "utils/results/api";
import { Stat } from "../Results/General";

import WallIcon from "assets/images/components/Wall.svg";
import ExpFloorIcon from "assets/images/components/ExpFloor.svg";
import CeilingFlatIcon from "assets/images/components/Ceiling.svg";
import CeilingAtticIcon from "assets/images/components/CeilingAttic.svg";
import BasementIcon from "assets/images/components/Foundation-Basement.svg";
import CrawlspaceIcon from "assets/images/components/Foundation-Crawlspace.svg";
import SlabIcon from "assets/images/components/Foundation-Slab.svg";
import DoorIcon from "assets/images/components/DoorBG.svg";
import WindowIcon from "assets/images/components/WindowBG.svg";
import FloorHeaderIcon from "assets/images/components/FloorHeader.svg";
import ComponentHeading from "features/Model/Enclosure/ComponentList/ComponentHeading";

const charLim20 = maxLength(20);
const charLim10000 = maxLength(10000);

const summaryOrder = {
    basement: {
        title: (
            <div className={classes.componentHeading}>
                <img src={BasementIcon} alt="Wall icon" />
                {""}
                <p>{" Basement"}</p>
            </div>
        ),
    },
    ponyWall: {
        title: (
            <div className={classes.componentHeading}>
                <img src={BasementIcon} alt="Wall icon" />
                {""}
                <p>{" Basement Pony Wall"}</p>
            </div>
        ),
    },
    crawlspaceWall: {
        title: (
            <div className={classes.componentHeading}>
                <img src={CrawlspaceIcon} alt="Wall icon" />
                {""}
                <p>{" Crawl Space Wall (Crawl space included in heated volume)"}</p>
            </div>
        ),
    },
    crawlspaceFloorsAbove: {
        title: (
            <div className={classes.componentHeading}>
                <img src={CrawlspaceIcon} alt="Wall icon" />
                {""}
                <p>{" Floors Above Crawl Space (Crawl space excluded from heated volume)"}</p>
            </div>
        ),
    },
    slab: {
        title: (
            <div className={classes.componentHeading}>
                <img src={SlabIcon} alt="Wall icon" />
                {""}
                <p>{" Slab-on-grade"}</p>
            </div>
        ),
    },

    wall: {
        title: (
            <div className={classes.componentHeading}>
                <img src={WallIcon} alt="Wall icon" />
                {""}
                <p>{" Walls"}</p>
            </div>
        ),
    },
    ceiling: {
        title: (
            <div className={classes.componentHeading}>
                <img src={CeilingAtticIcon} alt="Wall icon" />
                {""}
                <p>{" Ceilings"}</p>
            </div>
        ),
    },
    expFloor: {
        title: (
            <div className={classes.componentHeading}>
                <img src={ExpFloorIcon} alt="Wall icon" />
                {""}
                <p>{" Exposed Floors"}</p>
            </div>
        ),
    },

    floorHeader: {
        title: (
            <div className={classes.componentHeading}>
                <img src={FloorHeaderIcon} alt="Wall icon" />
                {""}
                <p>{" Floor Headers"}</p>
            </div>
        ),
    },
    window: {
        title: (
            <div className={classes.componentHeading}>
                <img src={WindowIcon} alt="Wall icon" />
                {""}
                <p>{" Windows"}</p>
            </div>
        ),
    },
    door: {
        title: (
            <div className={classes.componentHeading}>
                <img src={DoorIcon} alt="Wall icon" />
                {""}
                <p>{" Doors"}</p>
            </div>
        ),
    },
};

const filterByStorey = (summaryComponents, category) => {
    return Object.keys(summaryComponents).reduce((cache, key) => {
        if (
            summaryComponents[key].category === category ||
            (!summaryComponents[key].category && category === "other")
        ) {
            return {
                ...cache,
                [key]: summaryComponents[key],
            };
        }
        return cache;
    }, {});
};

const ComponentSummaries = ({ storeyCategories, summaryComponents, displayUnits }) => {
    return (
        <div>
            {storeyCategories.map((storeyCat, ind) => {
                const componentsInCategory = Object.keys(summaryOrder).map((compType, ind2) => {
                    const filteredComps = filterByStorey(summaryComponents[compType], storeyCat.id);
                    if (!isEmpty(filteredComps)) {
                        return (
                            <div style={{ marginBottom: "0.875rem" }} key={`${ind}-${ind2}`}>
                                {summaryOrder[compType]?.title || <></>}
                                <SummaryTable
                                    compSummary={filteredComps}
                                    compType={compType}
                                    displayUnits={displayUnits}
                                />
                            </div>
                        );
                    }
                });

                const emptyCategory = componentsInCategory.every((el) => !el);

                return (
                    <div key={ind}>
                        <h4 style={{ marginBottom: "1rem" }}>{storeyCat.name}</h4>
                        {emptyCategory ? (
                            <div className={classes.noComponentsContainer}>
                                <span>No components to display in this category.</span>
                            </div>
                        ) : (
                            componentsInCategory
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default React.memo(
    ({
        getResults,
        hasResults,
        cliId,
        clidIds,
        loading,
        error,
        completedAt,
        modelUnits,
        resultsPackages,
        selectedPackage,
        packageInfo,
        changePackage,
        packageResultsData = {},
        change,
        modelId,
        readOnly,
        modelSave,
        saveDrawingData,
        history,
        upgradeExportOpts,
        simulationRunning,
        storeyCategories,
        name,
        notes,
        calcPerformedBy,
    }) => {
        const [exporting, toggleExporting] = useState(false);
        const [upgradeSelected, toggleUpgradeSelected] = useState("");
        const [exportUnits, toggleExportUnits] = useState("");
        const [isPdfExportOpen, setIsPdfExportOpen] = useState(false);
        const [exportData, setExportData] = useState(false);

        const displayUnits = exportUnits || modelUnits || "Metric";

        const caseKey = upgradeSelected === "" ? "generalBaseCase" : "generalAllUpgrades";
        const { [upgradeSelected || "base"]: { resultsData: { [caseKey]: { f280Results = {} } = {} } = {} } = {} } =
            packageResultsData;

        const {
            coolingOverrideSetPoint,
            heatLoss,
            heatingOverrideSetPoint,
            nanLabels = [],
            latHeatGain,
            sensHeatGain,
            summaryDetails: {
                components: summaryComponents = {},
                otherMajor: otherMajorContributions = {},
                otherMinor: otherMinorContributions = {},
            } = {},
        } = f280Results || {};

        const nanComponentMessage = isEmpty(nanLabels)
            ? null
            : `Some components have produced calculation errors. Please check the inputs for the following component(s) and make corrections where required, ensuring no fields are left blank: ${nanLabels.join(
                  ", "
              )}`;

        const handleExport = async () => {
            toggleExporting(true);
            //handled in an action and cannot yet be disabled at the source, like handleSubmit
            // if (!readOnly) {
            //     await saveDrawingData();
            // }

            await modelSave();

            const { data: returnData = {} } = await getF280SummaryData({
                modelId,
                upgradePackageId: upgradeSelected,
            });

            setIsPdfExportOpen(true);
            setExportData(returnData);

            toggleExporting(false);
        };

        useEffect(() => {
            if (!hasResults && Object.keys(clidIds).length > 0) {
                const runIds = Object.values(clidIds).map(({ runId = "" }) => runId);
                // Get most recent cli results
                getResults({ cliIds: runIds, modelId });
            }
        }, [clidIds, hasResults, modelId]);

        const nonZeroDuctPipeLosses = Object.values(otherMinorContributions).reduce(
            (sum, curr) => sum + curr.heatLoss + curr.heatGain,
            0
        );

        return (
            <div className={sharedClasses.section}>
                <div>
                    {loading && !simulationRunning ? (
                        <LoadingDots className={classes.dots} />
                    ) : hasResults ? (
                        <div className={classes.summaryHeader}>
                            <div>
                                <h4>CSA F280-12 Calculation Summary</h4>
                                <p>View the details of the heat loss and heat gain summary.</p>
                            </div>
                        </div>
                    ) : (
                        <div className={resultsClasses.intro}>
                            <p>
                                No results to display. Once ready, click Run New Simulation to see your model's
                                performance (v11.11 onward).
                            </p>
                        </div>
                    )}
                </div>
                {cliId && hasResults && !error && (
                    <>
                        {(coolingOverrideSetPoint != null || heatingOverrideSetPoint != null) && (
                            <div className={classes.setPointNotice}>
                                <p>
                                    The results below have been generated using indoor set points that do not align with
                                    CSA F280-12. Visit the Mechanicals / Temperatures tab to revert the set points used
                                    for calculations. (Heating set point used: {heatingOverrideSetPoint || "22"}˚C.
                                    Cooling set point used: {coolingOverrideSetPoint || "24"}˚C)
                                </p>
                            </div>
                        )}
                        {nanComponentMessage && (
                            <div className={classes.setPointNotice}>
                                <p>{nanComponentMessage}</p>
                            </div>
                        )}
                        <div className={classes.export}>
                            <Select
                                className={classes.selectUpgrade}
                                name="exportIncluded"
                                options={[
                                    {
                                        value: "",
                                        label: "None",
                                    },
                                    ...upgradeExportOpts,
                                ]}
                                label="Upgrade Package" // Included in Export"
                                placeholder="None"
                                input={{
                                    value: upgradeSelected,
                                    onChange: (value) => toggleUpgradeSelected(value),
                                }}
                                large
                            />
                            <Select
                                name="exportUnits"
                                options={[
                                    {
                                        value: "",
                                        label: "Same as Model",
                                    },
                                    {
                                        value: "Metric",
                                        label: "Metric (W, RSI)",
                                    },
                                    {
                                        value: "Imperial",
                                        label: "Imperial (Btu/h, R)",
                                    },
                                ]}
                                label="Units" // for Export"
                                input={{
                                    value: exportUnits,
                                    onChange: (value) => toggleExportUnits(value),
                                }}
                                large
                            />
                            <div></div>
                            <Button
                                smallPadding
                                onClick={handleExport}
                                disabled={exporting || !isEmpty(nanLabels)}
                                icon={Export}
                                className={classes.exportButton}
                            >
                                {exporting ? "Downloading..." : "Download F280 Report"}
                            </Button>
                        </div>
                        <h4
                            style={{
                                marginTop: "1.875rem",
                                marginBottom: "1rem",
                            }}
                        >
                            Heat Loss/Gain Summary
                        </h4>
                        <div
                            className={`${resultsClasses.row} ${resultsClasses.totals}`}
                            style={{ gridTemplateColumns: "1fr 1fr 1fr" }}
                        >
                            <Stat
                                label="Design Heat Loss"
                                value={(displayUnits === "Metric" ? heatLoss : heatLoss * 3.41) || "N/A"}
                                unit={displayUnits === "Metric" ? "W" : "Btu/h"}
                                decimals={0}
                                big
                            />
                            <Stat
                                label="Design Heat Gain (Sensible + Latent)"
                                value={(displayUnits === "Metric" ? latHeatGain : latHeatGain * 3.41) || "N/A"}
                                unit={displayUnits === "Metric" ? "W" : "Btu/h"}
                                decimals={0}
                                big
                            />
                            <Stat
                                label="Design Heat Gain (Sensible)"
                                value={(displayUnits === "Metric" ? sensHeatGain : sensHeatGain * 3.41) || "N/A"}
                                unit={displayUnits === "Metric" ? "W" : "Btu/h"}
                                decimals={0}
                            />
                        </div>
                        {isEmpty(summaryComponents) && (
                            <div className={classes.setPointNotice}>
                                <p>
                                    Re-run your simulation to generate a breakdown of heat loss and gain by building
                                    component.
                                </p>
                            </div>
                        )}
                        {!isEmpty(summaryComponents) && (
                            <ComponentSummaries
                                storeyCategories={storeyCategories}
                                summaryComponents={summaryComponents}
                                displayUnits={displayUnits}
                                classes={classes}
                            />
                        )}
                        {!isEmpty(otherMajorContributions) && (
                            <div style={{ marginBottom: "0.875rem" }}>
                                <h4
                                    style={{
                                        marginTop: "1.5rem",
                                        marginBottom: "0.875rem",
                                    }}
                                >
                                    Other Contributions to Heat Loss and Gain
                                </h4>
                                <SummaryTable
                                    compSummary={otherMajorContributions}
                                    compType="otherMajor"
                                    displayUnits={displayUnits}
                                />
                            </div>
                        )}

                        {nonZeroDuctPipeLosses > 0 && (
                            <div style={{ marginBottom: "0.875rem" }}>
                                <h4
                                    style={{
                                        marginTop: "1.5rem",
                                        marginBottom: "0.875rem",
                                    }}
                                >
                                    Distribution System Losses
                                </h4>
                                <SummaryTable
                                    compSummary={otherMinorContributions}
                                    compType="otherMinor"
                                    displayUnits={displayUnits}
                                />
                            </div>
                        )}
                    </>
                )}
                <ExportF280PDF
                    isPdfExportOpen={isPdfExportOpen}
                    handleClose={() => {
                        setIsPdfExportOpen(false);
                        setExportData({});
                    }}
                    displayData={exportData}
                    name={name}
                    notes={notes}
                    calcPerformedBy={calcPerformedBy}
                    heatLossValue={displayUnits === "Metric" ? heatLoss : heatLoss * 3.41}
                    latHeatGainValue={displayUnits === "Metric" ? latHeatGain : latHeatGain * 3.41}
                    heatUnit={displayUnits === "Metric" ? "W" : "Btu/h"}
                />
            </div>
        );
    }
);

// otherMajor: {
//     airLeakage: {
//         heatLoss: airLeakHeatLoss,
//         heatGain: airLeakHeatGain,
//     },
//     mechVentilation: {
//         heatLoss: mechVentHeatLoss,
//         heatGain: mechVentHeatGain,
//     },
//     occupantGains: {
//         heatLoss: 0,
//         heatGain: occupantGains,
//     },
//     baseloadIntGains: {
//         heatLoss: 0,
//         heatGain: baseloadIntGains,
//     },
// },
// otherMinor: {
//     atticDuctHeatLoss: {
//         heatLoss: atticDuctHeatLoss,
//         heatGain: atticDuctHeatGain,
//     },
//     crawlspaceDuctHeatLoss: {
//         heatLoss: crawlspaceDuctHeatLoss,
//         heatGain: crawlspaceDuctHeatGain,
//     },
//     basementDuctHeatLoss: {
//         heatLoss: basementDuctHeatLoss,
//         heatGain: basementDuctHeatGain,
//     },
//     slabDuctHeatLoss: {
//         heatLoss: slabDuctHeatLoss,
//         heatGain: slabDuctHeatGain,
//     },
//     pipeHeatLoss: {
//         heatLoss: pipeHeatLoss,
//         heatGain: 0,
//     },
