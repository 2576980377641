import React, { useState } from "react";
import {
    DndContext,
    closestCenter,
    DragOverlay,
    MouseSensor,
    useSensor,
    useSensors,
    MeasuringStrategy,
    PointerSensor,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";

import { categories, getAllowedCategories } from "utils/enclosure";
import { onDragEnd } from "utils/dragAndDrop";

import { renderCompView } from "./withStoreys.jsx";

const measuringConfig = {
    dragOverlay: {
        strategy: MeasuringStrategy.Always,
    },
};

const mapComponents = (components, destination) =>
    Object.entries(components)
        .reduce((prev, [componentType, components]) => {
            if (components == null) {
                return prev;
            } else {
                const mappedComponents = Object.entries(components).map(([key, val]) => {
                    if (val === null) {
                        return {};
                    } else {
                        const { displayOrder, category, label = "" } = val;
                        return {
                            componentId: key,
                            componentType,
                            displayOrder,
                            category,
                            label,
                        };
                    }
                });

                return [...prev, ...mappedComponents];
            }
        }, [])
        .sort((a, b) => {
            if (a.displayOrder !== b.displayOrder) {
                return a.displayOrder - b.displayOrder;
            }
            if (a.label !== b.label) {
                if (a.label.toLowerCase() < b.label.toLowerCase()) {
                    return -1;
                }
                if (a.label.toLowerCase() > b.label.toLowerCase()) {
                    return 1;
                }
                return 0;
            }
            return 0;
        });

const Storeys = ({ storeysId, change, components = {}, updateDrawingChanges, dataEntryMode, model, formModelData }) => {
    const [activeId, setActiveId] = useState("");
    const [isDragging, setIsDragging] = useState(false);
    const [allowedCategories, setAllowedCategories] = useState([]);
    const [draggingType, setDraggingType] = useState("");

    const storeyCategories = categories(storeysId);

    const getCategories = (draggableId) => {
        const subComponentType = draggableId.split(".")[draggableId.split(".").length - 2];

        if (subComponentType !== undefined) {
            return getAllowedCategories(subComponentType) || [];
        }

        const [componentType, _] = draggableId.split(".");

        return getAllowedCategories(componentType) || [];
    };

    const handleDragStart = (event) => {
        const { active } = event;

        document.body.style.cursor = "grabbing";

        const {
            data: {
                current: { draggableId, type },
            },
        } = active;

        setAllowedCategories(getCategories(draggableId));

        setIsDragging(true);
        setActiveId(active.id);
        setDraggingType(type);
    };

    const handleDragEnd = (event) => {
        const { active, over } = event;

        document.body.style.cursor = "default";

        const {
            data: {
                current: {
                    draggableId,
                    type,
                    sortable: { containerId, index: sourceIndex },
                },
            },
        } = active;

        const {
            data: {
                current: {
                    sortable: { containerId: droppableIdIdDestination, index: destinationIndex, items } = {},
                } = {},
            } = {},
        } = over || {};

        if (over) {
            if (active.id !== over.id) {
                onDragEnd(
                    change,
                    {
                        droppableId: over.data.current ? droppableIdIdDestination : over.id,
                        index: over.data.current ? destinationIndex : 0,
                    },
                    {
                        droppableId: active.data.current ? containerId : active.id,
                        index: over.data.current
                            ? containerId !== droppableIdIdDestination
                                ? items.length
                                : sourceIndex
                            : 0,
                    },
                    draggableId,
                    type,
                    components
                );
            }
        }

        setActiveId("");
        setIsDragging(false);
        setAllowedCategories([]);
        setDraggingType("");
    };

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 1,
            },
        }),
        useSensor(MouseSensor, {
            // Require the mouse to move by 1 pixel before activating
            activationConstraint: {
                distance: 1,
                // delay: 250,
                // tolerance: 0,
            },
        })
    );

    return (
        <>
            {dataEntryMode.toLowerCase() === "takeoff" ? (
                storeyCategories.map(({ id, name, componentOptions }, index) =>
                    //? This component is logic-heavy enough to add rendering logic for two different
                    //? Components. To improve readability, I'm outsourcing it to the withComponent
                    //? Besides that, the way h2k mode view works should remain intact.
                    renderCompView(
                        dataEntryMode,
                        index,
                        {
                            id: id,
                            name: name,
                            componentOptions: componentOptions,
                        },
                        {
                            change: change,
                            storeysId,
                            activeId,
                            isDragging,
                            allowedCategories,
                            draggingType,
                            storeyCategories: storeyCategories,
                        },
                        model,
                        formModelData
                    )
                )
            ) : (
                <DndContext
                    // collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                    onDragStart={handleDragStart}
                    measuring={measuringConfig}
                    sensors={sensors}
                    modifiers={[restrictToVerticalAxis]}
                    onDragOver={(event) => {
                        // console.log("event", event);
                    }}
                >
                    {storeyCategories.map(({ id, name, componentOptions }, index) =>
                        //? This component is logic-heavy enough to add rendering logic for two different
                        //? Components. To improve readability, I'm outsourcing it to the withComponent
                        //? Besides that, the way h2k mode view works should remain intact.
                        renderCompView(
                            dataEntryMode,
                            index,
                            {
                                id: id,
                                name: name,
                                componentOptions: componentOptions,
                            },
                            {
                                change: change,
                                storeysId,
                                allowedCategories: allowedCategories,
                                storeyCategories: storeyCategories,
                                activeId,
                                isDragging,
                                draggingType,
                            },
                            model,
                            formModelData,
                            mapComponents(components)
                        )
                    )}
                </DndContext>
            )}
        </>
    );
};

export default Storeys;
