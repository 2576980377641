import React, { useState } from "react";

import classes from "./style.module.scss";
import Table from "components/Table";
import { Field } from "redux-form";
import Input from "components/Input";
import Select from "components/Input/Select";
import { getMonth, getYear, months, monthList, days, dayIndex, rows } from "utils/utilityData/utilityData";
import globalStyles from "components/globalStyles.module.scss";

const TableInput = (selectedFuelType, selectedTableType, cellType, ind, accessor, firstMonth, firstYear, change) => {
    return cellType === "year" ? (
        <Field
            component={Input}
            type="number"
            name={`${accessor}.${selectedTableType}.${selectedFuelType}.row${ind}.${cellType}`}
            change={change}
            setValue={getYear(ind, firstMonth, firstYear)}
        />
    ) : cellType === "fuelUse" ? (
        <Field
            component={Input}
            type="number"
            name={`${accessor}.${selectedTableType}.${selectedFuelType}.row${ind}.${cellType}`}
            change={change}
        />
    ) : cellType === "fuelCost" ? (
        <Field
            component={Input}
            type="number"
            name={`${accessor}.${selectedTableType}.${selectedFuelType}.row${ind}.${cellType}`}
            change={change}
        />
    ) : cellType === "notes" ? (
        <Field
            component={Input}
            type="text"
            name={`${accessor}.${selectedTableType}.${selectedFuelType}.row${ind}.${cellType}`}
            change={change}
        />
    ) : cellType === "month" ? (
        <Field
            component={Select}
            name={`${accessor}.${selectedTableType}.${selectedFuelType}.row${ind}.${cellType}`}
            change={change}
            options={months}
            disabled={ind !== 0}
            onChange={(month) => {
                rows.map((row, rowInd) => {
                    change(`${accessor}.${selectedTableType}.${selectedFuelType}.row${rowInd}.billingStartDate`, 1);
                    if (monthList.indexOf(month) + rowInd > 11) {
                        change(
                            `${accessor}.${selectedTableType}.${selectedFuelType}.row${rowInd}.${cellType}`,
                            `${monthList[monthList.indexOf(month) + rowInd - 12]}`
                        );
                    } else {
                        change(
                            `${accessor}.${selectedTableType}.${selectedFuelType}.row${rowInd}.${cellType}`,
                            monthList[monthList.indexOf(month) + rowInd]
                        );
                    }
                });
            }}
        />
    ) : cellType === "billingStartDate" ? (
        <Field
            component={Select}
            name={`${accessor}.${selectedTableType}.${selectedFuelType}.row${ind}.${cellType}`}
            change={change}
            search
            options={days[getMonth(ind, firstMonth)]}
            emptyMessage="Select a month."
            onChange={(day) => {
                rows.map((row, rowInd) => {
                    const rowMonth = getMonth(rowInd, firstMonth);
                    const isLastDay = day > dayIndex[rowMonth];
                    change(
                        `${accessor}.${selectedTableType}.${selectedFuelType}.row${rowInd}.${cellType}`,
                        isLastDay ? dayIndex[rowMonth] : day
                    );
                });
            }}
        />
    ) : (
        <></>
    );
};

const fuelTypeLabels = {
    electricity: { label: "Electricity", unit: "kWh" },
    naturalGas: { label: "Natural Gas", unit: "m3" },
    oil: { label: "Oil", unit: "L" },
    propane: { label: "Propane", unit: "L" },
    woodPellets: { label: "Wood (Pellet)", unit: "kg" },
    woodCord: { label: "Wood (Cord)", unit: "cord" },
};

const UtilityData = ({ accessor, utilityData = {}, change }) => {
    const [selectedTableType, setSelectedTableType] = useState("preUpgrade");
    const [selectedFuelType, setSelectedFuelType] = useState("electricity");

    const { [selectedFuelType]: { label: fuelTypeLabel = "", unit: fuelTypeUnit = "" } = {} } = fuelTypeLabels;

    const {
        [selectedTableType]: {
            [selectedFuelType]: { row0: { month: firstMonth = "", year: firstYear = "" } = {} } = {},
        } = {},
    } = utilityData;

    return (
        <div style={{ marginLeft: "1rem", marginRight: "1rem" }}>
            <div className={classes.header}>
                <Select
                    className={`${globalStyles.typeSelect} ${classes.upgradeSelect}`}
                    name={"fuelType"}
                    label="Fuel Type"
                    options={[
                        {
                            label: "Electricity",
                            value: "electricity",
                        },
                        {
                            label: "Natural Gas",
                            value: "naturalGas",
                        },
                        {
                            label: "Oil",
                            value: "oil",
                        },
                        {
                            label: "Propane",
                            value: "propane",
                        },
                        {
                            label: "Wood (Pellets)",
                            value: "woodPellets",
                        },
                        {
                            label: "Wood (Cord)",
                            value: "woodCord",
                        },
                    ]}
                    disabled={false}
                    setValue={selectedFuelType}
                    input={{
                        onChange: (value) => {
                            setSelectedFuelType(value);
                        },
                        value: selectedFuelType,
                    }}
                />
                <Select
                    className={`${globalStyles.typeSelect} ${classes.upgradeSelect} ${classes.upgradeSelectMiddle}`}
                    name={"tableType"}
                    label="Pre or Post Upgrade"
                    options={[
                        {
                            label: "Pre-Upgrade",
                            value: "preUpgrade",
                        },
                        {
                            label: "Post-Upgrade",
                            value: "postUpgrade",
                        },
                    ]}
                    disabled={false}
                    setValue={selectedTableType}
                    input={{
                        onChange: (value) => {
                            setSelectedTableType(value);
                        },
                        value: selectedTableType,
                    }}
                />
            </div>
            <Table
                // key={tableInd}
                className={classes.summaryTable}
                headCells={[
                    {
                        id: "year",
                        label: "Year",
                        align: "left",
                        width: "130",
                    },
                    {
                        id: "month",
                        label: "Month",
                        align: "left",
                        width: "130",
                    },
                    {
                        id: "billingStartDate",
                        label: "Billing Start Date",
                        align: "left",
                        width: "130",
                    },
                    {
                        id: "fuelUse",
                        label: `Total ${fuelTypeLabel} Use (${fuelTypeUnit})`,
                        align: "left",
                        width: "240",
                    },
                    {
                        id: "fuelCost",
                        label: `Total ${fuelTypeLabel} Cost ($)`,
                        align: "left",
                        width: "240",
                    },
                    {
                        id: "notes",
                        label: "Notes",
                        align: "left",
                        // width: "auto",
                        width: "240",
                    },
                ]}
                rows={rows.map(({ year, month, billingStartDate, fuelUse, fuelCost, notes }, ind) => ({
                    // these are the columns and their input
                    year: {
                        value: year,
                        content: TableInput(
                            selectedFuelType,
                            selectedTableType,
                            "year",
                            ind,
                            accessor,
                            firstMonth,
                            firstYear,
                            change
                        ),
                    },
                    month: {
                        value: month,
                        content: TableInput(
                            selectedFuelType,
                            selectedTableType,
                            "month",
                            ind,
                            accessor,
                            firstMonth,
                            firstYear,
                            change
                        ),
                    },
                    billingStartDate: {
                        value: billingStartDate,
                        content: TableInput(
                            selectedFuelType,
                            selectedTableType,
                            "billingStartDate",
                            ind,
                            accessor,
                            firstMonth,
                            firstYear,
                            change
                        ),
                    },
                    fuelUse: {
                        value: fuelUse,
                        content: TableInput(
                            selectedFuelType,
                            selectedTableType,
                            "fuelUse",
                            ind,
                            accessor,
                            firstMonth,
                            firstYear,
                            change
                        ),
                    },
                    fuelCost: {
                        value: fuelCost,
                        content: TableInput(
                            selectedFuelType,
                            selectedTableType,
                            "fuelCost",
                            ind,
                            accessor,
                            firstMonth,
                            firstYear,
                            change
                        ),
                    },
                    notes: {
                        value: notes,
                        content: TableInput(
                            selectedFuelType,
                            selectedTableType,
                            "notes",
                            ind,
                            accessor,
                            firstMonth,
                            firstYear,
                            change
                        ),
                    },
                }))}
            />
        </div>
    );
};
// };

export default UtilityData;
