import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectModelId } from "features/Model/_ducks/selectors";

import { selectAllImages } from "store/imageGallery/selectors";

import { saveImageGallery } from "store/imageGallery/thunk";

import { toggleImageGallery, resetImageGallety } from "store/imageGallery/actions";

import Input from "components/Input";

import styles from "./styles.module.scss";

import SearchIcon from "assets/images/icons/SearchIcon.svg";
import CloseIcon from "assets/images/icons/Close.svg";

const Header = ({
    searchFieldValue,
    setSearchFieldValue,
    toggleImageGallery,
    saveImageGallery,
    allImages,
    modelId,
    handleSave,
    setIsClosing,
    resetImageGallety,
}) => {
    return (
        <div className={styles.imageGalleryHeaderContainer}>
            <div>
                <h3 className={styles.headerTitle}>Photo Gallery</h3>
            </div>
            <div className={styles.searchCloseWrapper}>
                <Input
                    placeholder="Search (Name, tag, status)"
                    leftIcon={SearchIcon}
                    className={styles.searchInput}
                    style={{ minWidth: "388px" }}
                    input={{ value: searchFieldValue, onChange: (value) => setSearchFieldValue(value) }}
                />
                <div
                    className={styles.closeIcon}
                    onClick={async () => {
                        document.body.style.cursor = "progress";

                        setIsClosing(true);

                        await saveImageGallery(modelId, allImages);
                        await handleSave();

                        toggleImageGallery();
                        setSearchFieldValue("");
                        resetImageGallety();
                        setIsClosing(false);

                        document.body.style.cursor = "default";
                    }}
                >
                    <img src={CloseIcon} />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    modelId: selectModelId,
    allImages: selectAllImages,
});

const mapDispatchToProps = (dispatch) => ({
    toggleImageGallery: () => dispatch(toggleImageGallery()),
    saveImageGallery: (modelId, images) => dispatch(saveImageGallery(modelId, images)),
    resetImageGallety: () => dispatch(resetImageGallety()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
