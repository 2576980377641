import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import NetZeroWorkbook from "./";

const mapStateToProps = ({ form }, { accessor, formName = "model" }) => {
    const selector = formValueSelector(formName);

    return {
        accessor,
        formName,
    };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(NetZeroWorkbook);
