import React, { useState, useContext, useEffect } from 'react';
import { Redirect } from "react-router";
import classes from '../style.module.scss';
import Button from 'components/Button';
import Input from 'components/Input';
import { Link } from 'react-router-dom';
import { checkValidity } from 'utils/fields';
import { updateObject } from 'utils';
import { AuthContext } from 'features/Auth';
import VoltaLogo from 'assets/images/logo-color.svg';

export default ({
    loginUser,
    error
}) => {
    const [valid, setValid] = useState(false);
    const [processing, toggleProcessing] = useState(false);

    useEffect(() => {
        toggleProcessing(false);
    },[error]);

    const [loginForm, setLoginForm] = useState({
        email: {
            name:'email',
            value:'',
            type:'email',
            label:'Email Address',
            placeholder:'Your Email',
            valid:false,
            validation: {
                required:true
            },
            validMessage:'',
            touched:false
        },
        password: {
            name:'password',
            value:'',
            type:'password',
            label:'Password',
            placeholder:'Your Password',
            valid:false,
            validation: {
                required:true
            },
            validMessage:'',
            touched:false
        },
    });

    const { user } = useContext(AuthContext);

    if (!!user) return <Redirect to="/" />;

    const onChangeHandler = (value, fieldName) => {
        const { isValid, message } = checkValidity(loginForm[fieldName].label, value, loginForm[fieldName].validation);

        const updatedControls = updateObject(loginForm, {
            [fieldName]: updateObject(loginForm[fieldName], {
                value,
                valid:isValid,
                validMessage:message,
                touched:true
            })
        });

        setValid(Object.values(updatedControls).every(({valid}) => valid))
        setLoginForm(updatedControls);
    }

    return (
        <div className={classes.authWrapper}>
            <div className={classes.formWrapper}>
                <div className={classes.top}>
                    <img src={VoltaLogo} alt="Volta Snap logo in colour" />
                    <h2>Login</h2>
                    {/* TODO: Prohibit login from google if no user exists in database, currently it creates a new auth user */}
                    {/* <Button type='hollow' onClick={() => signInWithGoogle()}>
                        Log in With Google
                    </Button> */}
                </div>
                <form className={classes.form}>
                    {
                        Object.values(loginForm).map(({ name, type, label, valid, placeholder, validMessage, touched }) => (
                            <Input
                                key={name}
                                large 
                                name={name}
                                type={type} 
                                label={label}
                                placeholder={placeholder}
                                invalid={!valid}
                                validMessage={validMessage}
                                touched={touched}    
                                className={classes.input}
                                input={{
                                    onChange:value => onChangeHandler(value, name)
                                }}
                            />
                        ))
                    }
                    <div>
                        <Button
                            disabled={!valid || processing}
                            type="submit"
                            onClick={(event) => {
                                toggleProcessing(true);
                                return loginUser(event, loginForm);
                            }}
                        >
                            {processing ? "Logging In..." : "Log In"}
                        </Button>
                    </div>
                    {error !== null && <p className={classes.error}>{error}</p>}
                    <p>Don't have an account? <Link to='/register'>Sign Up.</Link></p>
                </form>
            </div>
        </div>
    )
};
