import React from 'react';
import sharedClasses from 'features/Model/sharedStyles.module.scss';
import { Field } from 'redux-form';
import { required, maxLength, getValidation, getDecimalPlaces } from 'utils/fieldValidation';
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { getOptions, getFirstOption, getBaseUnits, getUnitOptions } from 'utils/fields';
import InputRow from 'components/Input/Row';
import Input from 'components/Input';
import InputWithUnits from 'components/Input/InputWithUnits';

const charLim100000 = maxLength(100000);


export default React.memo(({
    accessor,
    modelUnits,
    change
}) => {
    return (
        <div>
            <InputRow>
                <Field
                    component={Input}
                    name={`${accessor}.airSeal`}
                    type="text"
                    label="Air Seal"
                    placeholder=""
                    validate={charLim100000}
                />
            </InputRow>
            <InputRow>
                <Field
                    component={Input}
                    name={`${accessor}.mainWalls`}
                    type="text"
                    label="Main Walls"
                    placeholder=""
                    validate={charLim100000}
                />
            </InputRow>
            <InputRow>
                <Field
                    component={Input}
                    name={`${accessor}.ceilings`}
                    type="text"
                    label="Ceilings"
                    placeholder=""
                    validate={charLim100000}
                />
            </InputRow>
            <InputRow>
                <Field
                    component={Input}
                    name={`${accessor}.ceilingsCathedral`}
                    type="text"
                    label="Cathedral and Flat Ceilings"
                    placeholder=""
                    validate={charLim100000}
                />
            </InputRow>
            <InputRow>
                <Field
                    component={Input}
                    name={`${accessor}.exposedFloors`}
                    type="text"
                    label="Exposed Floors"
                    placeholder=""
                    validate={charLim100000}
                />
            </InputRow>
            <InputRow>
                <Field
                    component={Input}
                    name={`${accessor}.foundation`}
                    type="text"
                    label="Foundation"
                    placeholder=""
                    validate={charLim100000}
                />
            </InputRow>
            <InputRow>
                <Field
                    component={Input}
                    name={`${accessor}.windows`}
                    type="text"
                    label="Windows"
                    placeholder=""
                    validate={charLim100000}
                />
            </InputRow>
            <InputRow>
                <Field
                    component={Input}
                    name={`${accessor}.doors`}
                    type="text"
                    label="Doors"
                    placeholder=""
                    validate={charLim100000}
                />
            </InputRow>
            <InputRow>
                <Field
                    component={Input}
                    name={`${accessor}.ventilationSystem`}
                    type="text"
                    label="Ventilation System"
                    placeholder=""
                    validate={charLim100000}
                />
            </InputRow>
            <InputRow>
                <Field
                    component={Input}
                    name={`${accessor}.hotWaterSystem`}
                    type="text"
                    label="Hot Water System"
                    placeholder=""
                    validate={charLim100000}
                />
            </InputRow>
            <InputRow>
                <Field
                    component={Input}
                    name={`${accessor}.heatingSystem`}
                    type="text"
                    label="Heating System"
                    placeholder=""
                    validate={charLim100000}
                />
            </InputRow>
            <InputRow>
                <Field
                    component={Input}
                    name={`${accessor}.coolingSystem`}
                    type="text"
                    label="Cooling System"
                    placeholder=""
                    validate={charLim100000}
                />
            </InputRow>
            <InputRow>
                <Field
                    component={Input}
                    name={`${accessor}.renewables`}
                    type="text"
                    label="Renewables"
                    placeholder=""
                    validate={charLim100000}
                />
            </InputRow>
            <InputRow>
                <Field
                    component={Input}
                    name={`${accessor}.waterConservation`}
                    type="text"
                    label="Water Conservation"
                    placeholder=""
                    validate={charLim100000}
                />
            </InputRow>
            <InputRow>
                <Field
                    component={Input}
                    name={`${accessor}.additionalComments`}
                    type="text"
                    label="Additional Comments"
                    placeholder=""
                    validate={charLim100000}
                />
            </InputRow>        
        </div>
    )
})