import React from "react";
import classes from "./style.module.scss";
import sharedClasses from "features/Model/sharedStyles.module.scss";
import { Field } from "redux-form";
import InputRow from "components/Input/Row";
import Button from "components/Button";
import Checkbox from "components/Input/Checkbox";
import Accordion from "components/Accordion";
import Summary from "./Summary/container";
import WaterUsage from "./WaterUsage/container";
import ElectricalUsage from "./ElectricalUsage/container";

import baseLoads from "../Templates/baseLoads/baseLoads.json";

export default React.memo(({ accessor, modelUnits, change, contentOpen }) => (
    <div className={sharedClasses.section}>
        <InputRow gridTemplate="1fr 2fr 1fr 1fr">
            <Button
                style={{ minWidth: "9.625rem" }}
                smallPadding
                onClick={() => {
                    change("modelData.baseLoads", baseLoads);
                }}
                disabled={false}
            >
                Restore Defaults
            </Button>
            <Field
                component={Checkbox}
                name={`${accessor}.advanced.enabled`}
                label="User Specified Electrical and Water Usage"
                large
                className={classes.bottomCheckBox}
                type="checkbox"
            />
        </InputRow>
        <Summary accessor={accessor} change={change} />
        <Accordion
            className={sharedClasses.accordion}
            heading={<span className={sharedClasses.heading}>Water Usage</span>}
            large
            forceOpen={contentOpen}
        >
            <WaterUsage accessor={`${accessor}.waterUsage`} change={change} />
        </Accordion>
        <Accordion
            className={sharedClasses.accordion}
            heading={<span className={sharedClasses.heading}>Electrical Usage</span>}
            large
            forceOpen={contentOpen}
        >
            <ElectricalUsage accessor={`${accessor}.electricalUsage`} change={change} />
        </Accordion>
    </div>
));
