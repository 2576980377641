import React, { useState, useRef } from "react";
import { Card, CardHeader, IconButton, Avatar } from "@material-ui/core";

import Tinput from "../TSelect/Tinput";
import DropdownMenu from "components/DropdownMenu";
import DropdownItem from "components/DropdownMenu/DropdownItem";

import { takeoffIconsFactory } from "../utils/TakeoffIconsFactory";
import { getFValidations } from "features/Model/Enclosure/Takeoff/containers/TakeOffFoundation/utils/foundValidations";
import { getFoundationSubcomponentsUnits } from "features/Model/Enclosure/Takeoff/containers/TakeOffFoundation/utils/FoundationSubcomponentsFactory";
import useComponentVisible from "utils/hooks";

import { toffCard, toffInputs, cardInput, MuiCardHeaderAction, actionContainer } from "./style.module.scss";

import ThreeDots from "assets/images/icons/JSX/ThreeDots";
import { getBaseUnits } from "utils/fields";

const TCompCard = ({
    className,
    component,
    onDelete,
    onDuplicate,
    index = 0,
    onError,
    onChange,
    getValidations = getFValidations,
    getSubcomponentUnits = getFoundationSubcomponentsUnits,
    compList = [],
    modelUnits,
}) => {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    const anchorRef = useRef();
    const [ranKey, setRanKey] = useState(null);
    if (!ranKey) {
        setRanKey(Math.random()); //? If you experiment weird behaviors on this component, try changing the key for a non-random one.
    }
    const getIndex = (index, type) => {
        let result = 0;
        for (let i = 0; i < index; i++) {
            if (compList[i].type === type) {
                result++;
            }
        }
        if (result === 0) {
            return "";
        } else {
            return result;
        }
    };

    return (
        <Card className={`${toffCard} ${className}`}>
            <CardHeader
                style={{
                    padding: 0,
                }}
                classes={{ action: MuiCardHeaderAction }}
                action={
                    <div className={`${actionContainer}`} ref={ref}>
                        <IconButton
                            aria-label="settings"
                            ref={anchorRef}
                            onClick={(e) => setIsComponentVisible(true)}
                            style={{
                                borderRadius: "2px",
                                padding: "12px",
                            }}
                        >
                            <ThreeDots />
                        </IconButton>
                        <DropdownMenu
                            isOpen={isComponentVisible}
                            top={29}
                            style={{
                                width: "fit-content",
                            }}
                        >
                            <DropdownItem
                                key={1}
                                onClick={() => {
                                    onDuplicate(component.id);
                                    setIsComponentVisible(false);
                                }}
                                text={"Duplicate"}
                            />
                            <DropdownItem
                                key={2}
                                onClick={() => {
                                    onDelete(component.id);
                                    setIsComponentVisible(false);
                                }}
                                text={"Delete"}
                            />
                        </DropdownMenu>
                    </div>
                }
                avatar={
                    <Avatar
                        variant="square"
                        style={{
                            backgroundColor: "transparent",
                            margin: 0,
                            padding: 0,
                            width: 23,
                            height: 20.5,
                        }}
                    >
                        {takeoffIconsFactory(component.type)}
                    </Avatar>
                }
                title={`${component.name} ${getIndex(index, component.type)}`}
            />
            <div className={`${toffInputs}`}>
                {component.fields.map((field, index) => {
                    let units = getSubcomponentUnits(field.field);
                    units.selected = field.unit;

                    return (
                        <Tinput
                            className={`${cardInput}`}
                            key={`tcard-input-${ranKey}-${index}`}
                            label={field.name}
                            type={field.type}
                            property={field.field}
                            decimals={field.fieldKey === "takeoffCount" ? 0 : 2}
                            validation={getValidations}
                            handleError={(err) => onError(component.id, err)}
                            disabled={field.disabled}
                            input={{
                                value: field.value,
                                onChange: (e) => onChange(component.id, field.field, e, field.unit),
                            }}
                            units={{
                                ...units,
                                base: getBaseUnits(field.fieldKey, modelUnits),
                            }}
                            onUnitChange={(value, unit) => {
                                onChange(component.id, field.field, value, unit);
                            }}
                        />
                    );
                })}
            </div>
        </Card>
    );
};

export default TCompCard;
