import React, { useState } from "react";

import { useOutsideClickHook } from "utils/outsideClick";

import Dropdown from "components/Dropdown";

import classes from "../style.module.scss";

import goBack from "assets/images/icons/goBack.svg";

const GoBack = ({ path, history, folders }) => {
    const [isGoBackOpen, setIsGoBackOpen] = useState(false);

    const goBackRef = useOutsideClickHook(() => setIsGoBackOpen(false));

    return (
        <div className={classes.goBackIconContainer}>
            <div
                className={classes.goBackIcon}
                onClick={() => setIsGoBackOpen(!isGoBackOpen)}
                ref={goBackRef}
            >
                <img src={goBack} alt={goBack} />
            </div>
            <Dropdown
                className={classes.goBackIconDropdown}
                open={isGoBackOpen}
                options={[
                    {
                        label: "Model Directory",
                        onClick: () => history.push(`/dashboard`),
                    },
                    ...path
                        .filter((id, index) => index !== path.length - 1)
                        .map((id, index) =>
                            path.length - 1 !== index
                                ? {
                                      label: folders[id].name,
                                      onClick: () =>
                                          history.push(
                                              `/dashboard/${path
                                                  .slice(0, index + 1)
                                                  .join("/")
                                                  .toString()}`
                                          ),
                                  }
                                : null
                        ),
                ]}
            />
        </div>
    );
};

export default GoBack;
