import React, { useEffect, useRef, useCallback } from "react";
import globalStyles from "components/globalStyles.module.scss";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import Select from "components/Input/Select";
import Checkbox from "components/Input/Checkbox";
import { Field } from "redux-form";
import Button from "components/Button";
import { getOptions, getFirstOption, getDefaultOption } from "utils/fields";
import { required, maxLength, getValidation } from "utils/fieldValidation";
import Tooltip from "components/Tooltip";

const charLim32 = maxLength(32);

const labelValidation = [required, charLim32];
const interiorValidation = getValidation("csWallInterior");
const structureValidation = getValidation("csWallStructureType");
const typeSizeValidation = getValidation("csWallComponentTypeSize");
const sheathingValidation = getValidation("csWallSheathing");
const framingSpacingValidation = getValidation("csWallFramingSpacing");
const exteriorValidation = getValidation("csWallExterior");
const ins1Validation = getValidation("csWallInsLayer1");
const ins2Validation = getValidation("csWallInsLayer2");
const studsValidation = getValidation("csWallStudsCornersInt");

export default React.memo(
    ({
        open = false,
        toggleOpen,
        crawlWallLabel,
        crawlWallStructureId,
        isSolid,
        isPanel,
        makeSpacingDefault,
        makeInsulationDefault,
        crawlWallValue,
        submitting,
        handleSubmit,
        change,
        invalid,
        isEditing,
        isLibCode,
        clearInitCode,
        crawlWallSpacingId,
    }) => {
        const valueRef = useRef(null);

        const handleStuctureChange = useCallback(
            ({ id, value }) => {
                change(
                    "ponyWallCode.layers.insulationLayer1",
                    getFirstOption({ indKey: id, depKey: "csWallInsLayer1" })
                );
                change(
                    "ponyWallCode.layers.componentTypeSize",
                    getFirstOption({ indKey: id, depKey: "csWallComponentTypeSize" })
                );

                // Use "Framing" when set to Solid, use Spacing otherwise
                if (parseFloat(id) === 4) {
                    //set spacing to null and grab first option for framing
                    change("ponyWallCode.layers.spacing", { id: null });
                    change(
                        "ponyWallCode.layers.framing",
                        getFirstOption({
                            indKey: id,
                            depKey: "csWallFramingSpacing",
                        })
                    );
                } else {
                    //set framing to null and grab first option for spacing
                    change("ponyWallCode.layers.framing", { id: null });

                    // Only grab default spacing id if not set
                    if (crawlWallSpacingId === null) {
                        change("ponyWallCode.layers.spacing", getDefaultOption("csWallFramingSpacing"));
                    }
                }

                // Panel
                if (parseFloat(id) === 5) {
                    change("ponyWallCode.layers.insulationLayer1", getDefaultOption("csWallInsLayer1"));
                    change("ponyWallCode.layers.insulationLayer2", getDefaultOption("csWallInsLayer2"));
                    change("ponyWallCode.layers.spacing", getDefaultOption("csWallFramingSpacing"));
                    change("ponyWallCode.layers.studsCornerIntersection", getDefaultOption("csWallStudsCornersInt"));
                }
            },
            [crawlWallStructureId, change, crawlWallSpacingId]
        );

        useEffect(() => {
            valueRef.current = crawlWallValue;
        }, [crawlWallValue, crawlWallLabel]);

        //removes floor header from opts
        const excludeValues = ["8"];
        const crawlWallStructureOpts = getOptions({ fieldKey: "csWallStructureType" }).filter(
            (opt) => !excludeValues.includes(opt.value.value)
        );

        return (
            <div className={globalStyles.standardCodeWrapper}>
                <h3>Standard Code</h3>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Input}
                        type="text"
                        name={`ponyWallCode.label`}
                        label="Code Label"
                        placeholder="Code Label"
                        validate={labelValidation}
                        setValue={crawlWallLabel.toString() === valueRef.current ? crawlWallValue : null}
                    />
                    <Field
                        component={Input}
                        type="text"
                        name={`ponyWallCode.value`}
                        label="Internal Code"
                        placeholder="###########"
                        disabled={true}
                        setValue={crawlWallValue}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Select}
                        name={`ponyWallCode.layers.structureType`}
                        label="Structure Type"
                        placeholder="Choose Structure Type"
                        options={crawlWallStructureOpts}
                        validate={structureValidation}
                        onChange={handleStuctureChange}
                    />
                    <Field
                        component={Select}
                        name={`ponyWallCode.layers.interior`}
                        label="Interior"
                        placeholder="Choose Interior"
                        options={getOptions({ fieldKey: "csWallInterior" })}
                        validate={interiorValidation}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Select}
                        name={`ponyWallCode.layers.componentTypeSize`}
                        label="Component Type / Size"
                        placeholder="Choose Type / Size"
                        options={getOptions({ fieldKey: "csWallComponentTypeSize", indKey: crawlWallStructureId })}
                        validate={typeSizeValidation}
                        onChange={({ value = "" }) => {
                            if (["6", "7", "8", "9", "A", "B", "C"].includes(value)) {
                                change("ponyWallCode.layers.insulationLayer1", getDefaultOption("csWallInsLayer1"));
                                change("ponyWallCode.layers.insulationLayer2", getDefaultOption("csWallInsLayer2"));
                            }
                            if (["5", "6", "7", "8", "9", "A", "B", "C"].includes(value)) {
                                change("ponyWallCode.layers.framing", { id: 0, value: "0" });
                            }
                        }}
                    />
                    <Field
                        component={Select}
                        name={`ponyWallCode.layers.sheathing`}
                        label="Sheathing"
                        placeholder="Choose Sheathing"
                        options={getOptions({ fieldKey: "csWallSheathing" })}
                        validate={sheathingValidation}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    {isSolid ? (
                        <Field
                            component={Select}
                            name={`ponyWallCode.layers.framing`}
                            label="Framing"
                            placeholder="Choose Framing"
                            options={getOptions({ fieldKey: "csWallFramingSpacing", indKey: crawlWallStructureId })}
                            validate={framingSpacingValidation}
                            disabled={makeSpacingDefault}
                        />
                    ) : (
                        <Field
                            component={Select}
                            name={`ponyWallCode.layers.spacing`}
                            label="Spacing"
                            placeholder="Choose Spacing"
                            options={getOptions({ fieldKey: "csWallFramingSpacing", indKey: crawlWallStructureId })}
                            validate={framingSpacingValidation}
                            disabled={isPanel}
                        />
                    )}
                    <Field
                        component={Select}
                        name={`ponyWallCode.layers.exterior`}
                        label="Exterior"
                        placeholder="Choose Exterior"
                        options={getOptions({ fieldKey: "csWallExterior" })}
                        validate={exteriorValidation}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Select}
                        name={`ponyWallCode.layers.insulationLayer1`}
                        label="Framing Cavity Insulation (Layer 1)"
                        placeholder="Choose Insulation"
                        options={getOptions({ fieldKey: "csWallInsLayer1", indKey: crawlWallStructureId })}
                        validate={ins1Validation}
                        disabled={isPanel || makeInsulationDefault}
                    />
                    <Field
                        component={Select}
                        name={`ponyWallCode.layers.studsCornerIntersection`}
                        label="Studs at Corners and Intersections"
                        placeholder="Choose"
                        options={getOptions({ fieldKey: "csWallStudsCornersInt" })}
                        validate={studsValidation}
                        disabled={isPanel}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Select}
                        name={`ponyWallCode.layers.insulationLayer2`}
                        label="Continuous Layer of Insulation (Layer 2)"
                        placeholder="Choose Insulation"
                        options={getOptions({ fieldKey: "csWallInsLayer2" })}
                        validate={ins2Validation}
                        disabled={isPanel || makeInsulationDefault}
                    />
                </InputRow>
                <div style={{ borderBottom: "1px solid #e1eaf0", marginTop: "1.5rem", marginBottom: "1.5rem" }}></div>

                <InputRow gridTemplate="auto 1fr">
                    <div data-tip="Code will automatically be updated in library" data-for="codeLibTip">
                        <Field
                            component={Checkbox}
                            name={"addToLibrary"}
                            label={isLibCode ? "Update code in library" : "Add to code library"}
                            large
                            disabled={isLibCode}
                            type="checkbox"
                        />
                    </div>
                </InputRow>

                <div className={globalStyles.buttons}>
                    <Button
                        large
                        type="hollow"
                        onClick={() => {
                            clearInitCode();
                            toggleOpen(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button large onClick={handleSubmit} disabled={submitting || invalid}>
                        {submitting ? "Saving Code..." : "Save Code"}
                    </Button>
                </div>
                <Tooltip id="codeLibTip" hide={!isLibCode} />
            </div>
        );
    }
);
