import React from "react";

export default () => (
    <svg
        width="28"
        height="24"
        viewBox="0 0 28 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M3 3V2.25H2.25V3H3ZM14.4654 3L14.9404 2.41953L14.7332 2.25H14.4654V3ZM3 21H2.25V21.75H3V21ZM25 21V21.75H25.75V21H25ZM25 11.6192H25.75V11.2638L25.4749 11.0387L25 11.6192ZM3 3.75H14.4654V2.25H3V3.75ZM3.75 21V3H2.25V21H3.75ZM25 20.25H3V21.75H25V20.25ZM24.25 11.6192V21H25.75V11.6192H24.25ZM25.4749 11.0387L14.9404 2.41953L13.9905 3.58047L24.5251 12.1996L25.4749 11.0387Z"
            fill="black"
        />
        <rect
            x="1.5"
            y="1.5"
            width="3"
            height="3"
            rx="1.5"
            fill="black"
            stroke="black"
            strokeWidth="2"
        />
        <rect
            x="1.5"
            y="19.5"
            width="3"
            height="3"
            rx="1.5"
            fill="black"
            stroke="black"
            strokeWidth="2"
        />
        <rect
            x="23.5"
            y="19.5"
            width="3"
            height="3"
            rx="1.5"
            fill="black"
            stroke="black"
            strokeWidth="2"
        />
        <rect
            x="12.5"
            y="1.5"
            width="3"
            height="3"
            rx="1.5"
            fill="black"
            stroke="black"
            strokeWidth="2"
        />
        <rect
            x="23.5"
            y="10.5"
            width="3"
            height="3"
            rx="1.5"
            fill="black"
            stroke="black"
            strokeWidth="2"
        />
    </svg>
);
