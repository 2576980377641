import React from 'react';

export default () => (
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="Search-/-Light-Grey" fill="#939597" fillRule="nonzero">
            <g id="Remove">
                <path d="M6.5,0 C10.0898509,0 13,2.91014913 13,6.5 C13,8.11486875 12.4111063,9.59219401 11.4363556,10.7289391 L15.8284271,15.1213203 L15.1213203,15.8284271 L10.7289391,11.4363556 C9.59219401,12.4111063 8.11486875,13 6.5,13 C2.91014913,13 0,10.0898509 0,6.5 C0,2.91014913 2.91014913,0 6.5,0 Z M6.5,1 C3.46243388,1 1,3.46243388 1,6.5 C1,9.53756612 3.46243388,12 6.5,12 C9.53756612,12 12,9.53756612 12,6.5 C12,3.46243388 9.53756612,1 6.5,1 Z" id="Oval-Copy"></path>
            </g>
        </g>
    </svg>
)