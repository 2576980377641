import React from "react";

import convertUnit from "utils/convertUnit";

import classes from "../style.module.scss";

const DisplayLineInfo = ({ conversionFactor, displayUnits, line }) => {
    const { totalLength = 0 } = line;

    const convertedLength = convertUnit({
        value: totalLength * conversionFactor,
        type: "length",
        inputUnit: "m",
        outputUnit: displayUnits,
    });

    return (
        <div className={classes.componentInfo}>
            <h5 className={classes.componentTitle}>Line - {line.name}</h5>
            <div>
                <span className={classes.label}>Length:</span>{" "}
                <span className={classes.value}>
                    {convertedLength.toFixed(4)} {displayUnits}
                </span>
            </div>
        </div>
    );
};

export default DisplayLineInfo;
