import React from 'react';
import EditIcon from 'assets/images/icons/JSX/Edit';
import classes from './style.module.scss';
import Tooltip from 'components/Tooltip';

export default ({
    name,
    label,
    onClick
}) => (
    <>
        <span className={classes.edit} data-for={`${name}_editTip`} data-tip={`Edit ${label}`} onClick={onClick}>
            <EditIcon />
        </span>
        <Tooltip id={`${name}_editTip`} />
    </>
)