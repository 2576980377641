import React, { useState, useEffect, useRef } from "react";
import classes from "./style.module.scss";
import sharedClasses from "../style.module.scss";
import ArrowDown from "assets/images/icons/JSX/arrow-down";
import { useOutsideClickHook } from "utils/outsideClick";
import InfoTooltip from "components/InfoTooltip";
import isEqual from "lodash/isEqual";
import DropdownSearch from "components/Dropdown/DropdownSearch";
import LoadingDots from "components/LoadingDots";
import WarningTooltip from "components/WarningTooltip";
import NonH2kParamTooltip from "components/NonH2kParamTooltip";
import { onFocusSelect, onBlurSelect, onKSelect } from "./withSelect";
import { VariableSizeList as List } from "react-window";
import SharedUpgrade from "assets/images/icons/Shared-model-blue.svg";

export const DropDown = ({
    className,
    options,
    selected = {},
    onChange,
    open,
    toggleOpen,
    search = false,
    searchPlaceholder,
    footer: Footer,
    dropDownWidth,
    emptyMessage = "No options meet the requirements.",
}) => {
    const listRef = useRef();

    const [filter, changeFilter] = useState("");
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const filteredOptions = options.filter(({ label = "" }) => {
        if (!search) {
            return true;
        }
        return label.toString().toLowerCase().includes(filter.toLowerCase());
    });

    const classNames = [classes.dropDown, open && classes.open, className && className].filter((clss) => clss);

    const getItemSize = (index) => {
        const { labelTag } = filteredOptions[index];

        if (screenWidth < 1480) {
            return labelTag ? 56 : 35;
        }

        return 36;
    };

    useEffect(() => {
        if (listRef.current) {
            listRef.current.resetAfterIndex(0);
        }
    }, [filteredOptions]);

    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const Row = ({ index, style }) => {
        const {
            value,
            label,
            labelIcon: Icon = null,
            labelActionComponent: ActionComponent = null,
            labelTag = "",
            disabled = false,
        } = filteredOptions[index];

        const { isFromOrg = false } = value || {};

        const rowClassNames = [
            classes.option,
            isEqual(selected, value) && classes.selected,
            disabled && classes.disabled,
        ].filter((c) => c);

        return (
            <li
                key={index}
                className={rowClassNames.join(" ")}
                style={{ ...style, width: "97%", zIndex: 5 }}
                onClick={() => {
                    if (disabled) {
                        return;
                    }
                    onChange(value);
                    toggleOpen(false);
                }}
            >
                {Icon && <Icon />}
                <div className={classes.label}>
                    {isFromOrg ? (
                        <div style={{ display: "flex", alignItems: "center", gap: "15px" }} key={value.id}>
                            {label} <img style={{ margin: 0, height: "20px", width: "20px" }} src={SharedUpgrade} />
                        </div>
                    ) : (
                        label
                    )}
                    {labelTag && <span className={classes.labelTag}>{labelTag}</span>}
                </div>
                {ActionComponent && <ActionComponent />}
            </li>
        );
    };

    return (
        <div className={classNames.join(" ")} style={{ width: dropDownWidth }}>
            {search && (
                <DropdownSearch
                    className={classes.selectFieldSearch}
                    changeFilter={changeFilter}
                    filter={filter}
                    placeholder={searchPlaceholder}
                />
            )}
            {filteredOptions.length > 0 ? (
                <ul>
                    <List
                        height={Math.min(182, filteredOptions.length * 35)}
                        itemCount={filteredOptions.length}
                        itemSize={getItemSize}
                        width="100%"
                        ref={listRef}
                        style={{ overflowX: "hidden", margin: "0.5rem", width: "97%" }}
                    >
                        {Row}
                    </List>
                </ul>
            ) : (
                <div className={classes.emptyFilter}>
                    <span>{emptyMessage}</span>
                </div>
            )}
            {Footer && <Footer />}
        </div>
    );
};

const Select = ({
    name,
    label,
    input,
    className,
    large,
    hideLabel = false,
    options = [],
    disabled = false,
    placeholder = "Select",
    updateSelected,
    info = "",
    meta = {},
    forcedLabel = "",
    parentError = false,
    search = false,
    searchPlaceholder = "",
    footer = null,
    dropDownWidth = "100%",
    isLoading = false,
    warning = "",
    nonH2kParam = "",
    emptyMessage,
}) => {
    const { invalid = false, error = "", touched = false } = meta;
    const classNames = [
        classes.selectField,
        sharedClasses.inputField,
        className && className,
        large && sharedClasses.large,
        disabled && classes.disabled,
        hideLabel && classes.labelHidden,
        (parentError || invalid) && sharedClasses.invalid,
        warning && classes.warning,
    ].filter((c) => c);

    const { value, onChange } = input;
    const [open, toggleOpen] = useState(false);
    const [selected, toggleSelected] = useState("");
    const ref = useOutsideClickHook(() => toggleOpen(false));

    const handleChange = (newValue) => {
        if (updateSelected) {
            updateSelected(newValue);
        }
        onChange(newValue);
    };

    useEffect(() => {
        if (forcedLabel) {
            return toggleSelected(forcedLabel);
        }
        const { label: selectedLabel } = options.find(({ value: val }) => isEqual(val, value)) || {};
        toggleSelected(selectedLabel);
    }, [value, options]);

    return (
        <div
            ref={ref}
            className={classNames.join(" ") + " prevent-outline"}
            tabIndex="0"
            onFocus={(e) => {
                onFocusSelect(e);
            }}
            onBlur={(e) => {
                // onBlurSelect(e, toggleOpen, search);
            }}
            onKeyDown={(e) => {
                onKSelect(e, open, toggleOpen, search);
            }}
        >
            <label className={hideLabel ? "invisible" : ""} htmlFor={name}>
                {label}
            </label>
            {isLoading && (
                <div className={classes.loading}>
                    <LoadingDots />
                </div>
            )}
            {info && <InfoTooltip className={sharedClasses.infoTooltip} info={info} />}
            {warning && (
                <WarningTooltip
                    className={`${sharedClasses.warningTooltip} ${info && sharedClasses.pushLeft}`}
                    warning={warning}
                />
            )}
            {nonH2kParam && (
                <NonH2kParamTooltip
                    className={`${classes.nonH2kParamTooltip} ${warning && classes.pushLeft}`}
                    nonH2kParam={nonH2kParam}
                />
            )}
            {!isLoading && (
                <div
                    className={`${sharedClasses.selectInput} ${classes.value} ${open && classes.open} ${
                        disabled && `${classes.disabled} ${sharedClasses.disabled}`
                    }`}
                    onClick={() => !disabled && toggleOpen(!open)}
                >
                    {selected ? (
                        <span className={classes.selected}>{selected}</span>
                    ) : (
                        <span className={classes.placholder}>{placeholder}</span>
                    )}{" "}
                    <ArrowDown />
                </div>
            )}
            {!isLoading && (
                <DropDown
                    className={classes.dropDown}
                    options={options}
                    selected={value}
                    onChange={handleChange}
                    open={open}
                    toggleOpen={toggleOpen}
                    search={search}
                    searchPlaceholder={searchPlaceholder}
                    footer={footer}
                    dropDownWidth={dropDownWidth}
                    emptyMessage={emptyMessage}
                />
            )}
            {invalid && error && typeof error === "string" && (
                <span className={sharedClasses.errorMessage}>{error}</span>
            )}

            {/* Due to the way the manual-scroll is handled, sass won't work so it needs an in-comp style */}
            <style>
                {`
                .prevent-outline:focus { outline: none !important; }
                .manual-focus {border: 1px solid #62bcf8 !important;}
                `}
            </style>
        </div>
    );
};

export default Select;
