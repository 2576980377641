import React, { useState, useEffect } from "react";

import Tooltip from "components/Tooltip";

import classes from "./style.module.scss";

import WorkbookCheck from "assets/images/icons/WorkbookCheck.svg";

export default ({ value, onChange, isDisabled, howMuchFilesLeft = 0 }) => {
    return (
        <label
            className={classes.checkboxWrapper}
            data-for="checkboxTip"
            data-tip={`${howMuchFilesLeft} more files must be uploaded to complete this item`}
        >
            <div
                className={`${classes.checkbox}`}
                data-for={`checkbox_tip`}
                style={{ pointerEvents: isDisabled ? "none" : "auto", cursor: "pointer" }}
            >
                {value && <img style={{ borderRadius: "0.25rem" }} src={WorkbookCheck} alt="Checkmark" />}
                <input value={value} className={classes.checkboxInput} type="checkbox" onChange={onChange} />
            </div>
            <Tooltip id={"checkboxTip"} hide={howMuchFilesLeft <= 0} />
        </label>
    );
};
