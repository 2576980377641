import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import Slab from './';
import { getBaseUnits, unitLabels, getOptions } from 'utils/fields';
import convertUnit from 'utils/convertUnit';

const mapStateToProps = (
    {
        form
    },
    {
        accessor
    }
) => {
    const selector = formValueSelector('model');
    let { 
        codeLabel: slabInsLabel = '', 
        value: slabInsValue = 0,
        id: slabInsId,
        codeRef: slabCodeRef = ''
    } = selector({form}, `${accessor}.floor.slabInsType`) || {};

    const slabInsNomRVal = selector({form}, `${accessor}.floor.slabInsType_nomRVal`) || 0;
    const slabInsEffRVal = selector({form}, `${accessor}.floor.slabInsType_effRVal`) || 0;


    const slabRValUnits = selector({form}, `${accessor}.floor.slabInsType_u`);
    const modelUnits = selector({form}, `modelData.uiSettings.primaryUnits`);

    const { trueBase:slabRValBase, displayBase:slabRValDisplay } = getBaseUnits('slabInsType', modelUnits);

    const slabRValU = slabRValUnits || slabRValDisplay || slabRValBase;

    if (!slabInsLabel) {
        slabInsLabel = getOptions({fieldKey: 'slabInsType'}).reduce((acc, curr) => {
            if (curr.value.id === slabInsId) {
                return curr.label
            } else {
                return acc
            }
        }, '')
    }
    
    return {
        slabInsLabel:slabInsLabel || 'None',
        slabCodeRef: slabCodeRef || '',
        slabRValue:`${parseFloat(convertUnit({
            value:(slabCodeRef.includes('FloorsAdded') ? slabInsNomRVal : slabInsEffRVal || slabInsValue || 0),
            type:'thermalResistance',
            inputUnit:slabRValBase,
            outputUnit:slabRValU,
        })).toFixed(1)}${slabRValU}`,
    }
};

const mapDispatchToProps = () => ({});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Slab);