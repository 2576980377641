import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import GroundMountedPVReady from "./";

const mapStateToProps = ({ form }, { accessor, formName = "model" }) => {
    const selector = formValueSelector(formName);

    const arraySites = selector({ form }, `${accessor}.arraySites.sites`) || {};

    const tenCmNonMetallic = selector({ form }, `${accessor}.installTermConduits.10cmNonMetallic`) || null;
    const metallicNonMetallic = selector({ form }, `${accessor}.installTermConduits.metallicNonMetallic`) || null;
    const currentDeclAddress = selector({ form }, `${accessor}.declaration.address`) || "";

    return {
        accessor,
        formName,
        arraySites,
        tenCmNonMetallic,
        metallicNonMetallic,
        currentDeclAddress,
    };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(GroundMountedPVReady);
