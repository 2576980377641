import React, { useState } from "react";

import Dialog, { CloseDialog } from "components/Dialog";
import Button from "components/Button";

import classes from "./styles.module.scss";

const PackageDeleteDialog = ({
    toDeletePackage,
    toggleToDelete,
    handleDelete,
}) => {
    const [deleting, toggleDeleting] = useState(false);

    const { packageId = "", packageName = "" } = toDeletePackage;

    return (
        <Dialog open={!!packageId}>
            <CloseDialog closeActions={() => toggleToDelete()} />
            <div className={classes.dialog}>
                <h3>
                    Are you sure you want to delete{" "}
                    <strong>{packageName}</strong>?
                </h3>
                <div className={classes.footer}>
                    <Button type="hollow" onClick={() => toggleToDelete()}>
                        Cancel
                    </Button>
                    <Button
                        onClick={async () => {
                            toggleDeleting(true);
                            await handleDelete();
                            toggleDeleting(false);
                        }}
                        type="red"
                        disabled={deleting}
                    >
                        {deleting ? "Deleting..." : "Delete"}
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default PackageDeleteDialog;
