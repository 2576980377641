import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import Enclosure from "./";

import { updateSpecifications } from "../_ducks/actions";
import { actions as modelActions } from "features/Model/_ducks";

const mapStateToProps = ({ form, model }, { accessor }) => {
    const selector = formValueSelector("model");
    const { buildingSurfaceArea } = model;

    const totalHouseVolume = selector({ form }, `${accessor}.volume.total`) || 0;
    const agFloorAreaTotalValue = selector({ form }, `${accessor}.aboveGradeIntFloorArea.total`) || 0;
    const bgFloorAreaTotalValue = selector({ form }, `${accessor}.belowGradeIntFloorArea.total`) || 0;
    const components = selector({ form }, `modelData.components`) || {};
    const formModelData = selector({ form }, "modelData") || {};

    const { id: storeysId } = selector({ form }, "modelData.specifications.numStoreys") || {};
    const { id: planId } = selector({ form }, "modelData.specifications.planShape") || {};
    const windowWallRatio = selector({ form }, "modelData.dimensions.windowToWall.value");
    const modelUnits = selector({ form }, `modelData.uiSettings.primaryUnits`) || {};
    const minVentilationValue = selector({ form }, `modelData.ventilation.requirements.minBedroomVentilation`) || 0;
    const minVentilationUnits = selector({ form }, `modelData.ventilation.requirements.minBedroomVentilation_u`);
    const bedroomsEntered = selector({ form }, "modelData.ventilation.rooms.bedrooms");
    const buildingCodeEntered = selector({ form }, `modelData.specifications.buildingCode.type`);

    const vSA =
        totalHouseVolume /
        (buildingSurfaceArea.foundationSurfaceArea.value + buildingSurfaceArea.aboveGradeStoreysSurfaceArea.value);

    const wholeHouseHrv = selector({ form }, "modelData.ventilation.wholeHouseSystems.hrv") || {};
    const wholeHouseVentilators = selector({ form }, "modelData.ventilation.wholeHouseSystems.ventilators") || {};

    const pvcFromWholeHouseSystems = selector({ form }, "modelData.ventilation.pvcFromWholeHouseSystems") || false;

    let totalWholeHouseSupply = 0;
    totalWholeHouseSupply += wholeHouseHrv.supplyFlowrate || 0;
    totalWholeHouseSupply += Object.values(wholeHouseVentilators).reduce(
        (acc, curr) => acc + (curr.supplyFlowrate || 0),
        0
    );

    let totalWholeHouseExhaust = 0;
    totalWholeHouseExhaust += wholeHouseHrv.exhaustFlowrate || 0;
    totalWholeHouseExhaust += Object.values(wholeHouseVentilators).reduce(
        (acc, curr) => acc + (curr.exhaustFlowrate || 0),
        0
    );

    const hasWholeHouseSystem = totalWholeHouseExhaust + totalWholeHouseSupply > 0;

    return {
        storeysId,
        planId,
        windowWallRatio,
        modelUnits,
        minVentilationValue,
        minVentilationUnits,
        bedroomsEntered,
        buildingCodeEntered,
        components,
        vSA,
        formModelData,
        floorArea: agFloorAreaTotalValue + bgFloorAreaTotalValue,
        hasWholeHouseSystem,
        pvcFromWholeHouseSystems,
    };
};

const mapDispatchToProps = (dispatch) => ({
    //   countFoundationSurfaceArea: (componentType) =>
    //     dispatch(actions.countFoundationSurfaceArea(componentType)),
    updateSpecifications: (specificationType, value, formModelData) =>
        dispatch(updateSpecifications(specificationType, value, formModelData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Enclosure);
