import React, { useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import classes from "./style.module.scss";

import {
    selectIsCopyScaleOpen,
    selectCurrentAction,
    selectActiveImageIndex,
    selectImageData,
} from "../_ducks/selector";
import { toggleIsCopyScaleOpen, setScale, setMessage } from "../_ducks/actions";

import Dialog, { CloseDialog } from "components/Dialog";
import Button from "components/Button";
import { default as MultiSelect } from "components/Input/MultiSelect";

const CopyScaleDialog = ({
    images,
    isCopyScaleOpen,
    currentAction,
    toggleIsCopyScaleOpen,
    setScale,
    activeImageIndex,
    imageData,
    setMessage,
}) => {
    const [selectedPages, setSelectedPages] = useState([]);

    const { meta } = currentAction || {};

    const multiSelectOptions =
        images &&
        images
            .map((image, index) => ({
                value: image.fileName,
                label: `Page ${index + 1}${index === activeImageIndex ? " (Current Page)" : ""}`,
                disabled:
                    index === activeImageIndex ||
                    imageData[image.fileName]?.scale?.copiedFrom ||
                    imageData[image.fileName]?.scale?.isSet,
                image: image.signedURL,
            }))
            .filter(({ disabled }) => !disabled);

    const copyScale = () => {
        const { scale } = imageData[meta.image] || {};

        for (let i = 0; i < selectedPages.length; i++) {
            const page = selectedPages[i];

            setScale({
                image: page,
                scale: {
                    ...scale,
                    copiedFrom: meta.image,
                    copiedFromName: `Page ${activeImageIndex + 1}`,
                },
            });
        }

        meta.onSet();
        toggleIsCopyScaleOpen(false);

        setMessage({
            message:
                selectedPages.length > 0
                    ? `Scale set successfully and copied to ${selectedPages.length} page(s)`
                    : `Scale set successfully`,
            type: "success",
            anchorOrigin: {
                vertical: "top",
                horizontal: "right",
            },
            autoHideDuration: 2000,
            isOpen: true,
        });

        setSelectedPages([]);
    };

    return (
        <Dialog open={isCopyScaleOpen} style={{}}>
            <div
                style={{
                    height: "20rem",
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    overflow: "hidden",
                }}
            >
                <CloseDialog
                    closeActions={() => {
                        meta.onSet();
                        toggleIsCopyScaleOpen(false);
                        setMessage({
                            message: `Scale set successfully`,
                            type: "success",
                            anchorOrigin: {
                                vertical: "top",
                                horizontal: "right",
                            },
                            autoHideDuration: 2000,
                            isOpen: true,
                        });
                    }}
                />
                <h3>Apply scale from Page {activeImageIndex + 1} to multiple pages</h3>
                <MultiSelect
                    label={"Select any additional page(s) you would like to apply this scale to:"}
                    options={multiSelectOptions}
                    input={{
                        value: selectedPages,
                        onChange: (value) => setSelectedPages(value),
                    }}
                    hasSelectAll={true}
                />
                <div
                    style={{
                        display: "flex",
                        gap: "1rem",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        marginTop: "auto",
                    }}
                >
                    {/* <Button
                        type="red"
                        onClick={() => {
                            meta.onSet();
                            toggleIsCopyScaleOpen(false);
                            setMessage({
                                message: `Scale set successfully`,
                                type: "success",
                                anchorOrigin: {
                                    vertical: "top",
                                    horizontal: "right",
                                },
                                autoHideDuration: 2000,
                                isOpen: true,
                            });
                        }}
                    >
                        Cancel
                    </Button> */}
                    <Button
                        type={selectedPages.length === 0 ? "hollow" : "default"}
                        onClick={() => copyScale()}
                        className={classes.copyScaleButton}
                    >
                        {selectedPages.length === 0 ? "Continue without selections" : "Apply scale to selected page(s)"}
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

const mapStateToProps = createStructuredSelector({
    isCopyScaleOpen: selectIsCopyScaleOpen,
    currentAction: selectCurrentAction,
    activeImageIndex: selectActiveImageIndex,
    imageData: selectImageData,
});

const mapDispatchToProps = (dispatch) => ({
    toggleIsCopyScaleOpen: (bool) => dispatch(toggleIsCopyScaleOpen(bool)),
    setScale: ({ image, scale }) => dispatch(setScale({ image, scale })),
    setMessage: ({ message, type, anchorOrigin, autoHideDuration, isOpen, direction }) =>
        dispatch(
            setMessage({
                message,
                type,
                anchorOrigin,
                autoHideDuration,
                isOpen,
                direction,
            })
        ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CopyScaleDialog);
