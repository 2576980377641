import React from 'react';
import sharedClasses from '../../style.module.scss';
import Tooltip from 'components/Tooltip';

export default React.memo(({
    length,
    floorArea,
    codeLabel,
    rValue,
}) => (
    <div className={sharedClasses.stats} style={{gridTemplateColumns:'2fr 1fr 1fr'}}>
        <span
            data-for="floor-stats"
            data-tip={`<span><strong>Type:</strong> ${codeLabel}</span>`}
            data-html
            className={sharedClasses.clipType}
        >
            <strong>Type:</strong> {codeLabel}
        </span>
        <span
            data-for="floor-stats"
            data-tip={`<span><strong>${codeLabel === 'User Specified' ? 'Eff.' : 'Nom.'} R-Value:</strong> ${rValue}</span>`}
            data-html
        >
            <strong>R:</strong> {rValue}
        </span>
        <span
            data-for="floor-stats"
            data-tip={`<span><strong>Floor Area:</strong> ${floorArea}</span>`}
            data-html
        >
            <strong>FA:</strong> {floorArea}
        </span>
        {/* <div></div>
        <span><strong>L:</strong> {length}</span>
        <span><strong>FA:</strong> {floorArea}</span> */}
        <Tooltip id="floor-stats" />
    </div>
));