import React, { useEffect, useCallback } from 'react';
import classes from '../../../style.module.scss';
import { Field } from 'redux-form';
import Select from 'components/Input/Select';
import InputWithUnits from 'components/Input/InputWithUnits';
import Input from 'components/Input';
import Checkbox from 'components/Input/Checkbox';
import { required, maxLength, getValidation, getDecimalPlaces } from 'utils/fieldValidation';
import { getBaseUnits, getUnitOptions } from 'utils/fields';
import convertUnit from 'utils/convertUnit';
import { isEmpty, isEqual } from 'lodash';
import {
    wallTypeLibCodesMap,
    wallTypeModelCodesMap,
    CodeFooter
} from 'features/Model/Enclosure/ComponentList/Wall/Construction';

const charLim100 = maxLength(100);
const heightValidation = getValidation('wallHeight');
const wallCornerValidation = getValidation('wallCorner');
const wallIntersectionValidation = getValidation('wallIntersection');
const wallTypeValidation = getValidation('wallInsType');

const Wall = React.memo(({
    componentId,
    primaryUnits,
    imageUnits,
    perimeter=0,
    conversionFactor,
    change,
    modelCodes,
    libCodes,
    selectedRValue,
    selectedCodeRef,
    rValUnits,
    wallHeight,
    fieldsWithLinesAttached,
    setInitCode,
    modelChange,
    saveToState,
    isCalculatingRValue,
    wallCodeWarning,
    updateCodeDrawer
}) => {
    const calculatedPerimeter = parseFloat(convertUnit({
        value:perimeter*conversionFactor,
        type:'length',
        inputUnit:'m',
        outputUnit:getBaseUnits('perimeter',primaryUnits).trueBase
    }).toFixed(2));

    const handleTypeChange = useCallback(({ codeRef = '' })=>{
        if (codeRef === 'UserSpecified') {
            change(`components.${componentId}.wallInsType_warning`, '');
            return
        }
    
        const modelMatch = modelCodes[codeRef] || {};
        if (!isEmpty(modelMatch)) {
            change(`components.${componentId}.wallInsType_nomRVal`, modelMatch.nominalRValue || 0);
            change(`components.${componentId}.wallInsType_effRVal`, modelMatch.nominalRValue || 0);
            change(`components.${componentId}.wallInsType_warning`, modelMatch.warningType || '');
        } else {
            const {nominalRValue = 0, warningType = ''} = libCodes[codeRef] || {};
            change(`components.${componentId}.wallInsType_nomRVal`, nominalRValue || 0);
            change(`components.${componentId}.wallInsType_effRVal`, nominalRValue || 0);
            change(`components.${componentId}.wallInsType_warning`, warningType || '');
        }
    },[libCodes,modelCodes,change,componentId]);

    const handleEditModelWallCode = useCallback(async (event, codeRef, codeType) => {
        event.stopPropagation(); //Don't let click event bubble up to parent
        const initCodeType = codeType === "U" ? "udefStandard" : "wallInsType";
        const code = modelCodes[codeRef];
        await setInitCode(code, initCodeType);
        updateCodeDrawer({
            isOpen:true,
            codeName: "Wall",
            isEditing: true,
            isDrawing: true,
            componentType: "Wall",
            codeType,
            fieldAccessor: `components.${componentId}.wallInsType`,
            componentId,
            id: codeRef,
            modelFormChange: modelChange,
            drawingFormChange: change,
            drawingSave: saveToState,
            lastEdited: code.lastEdited || ''
        });
    }, [change, componentId, modelChange, modelCodes, setInitCode, updateCodeDrawer, saveToState]);

    const handleEditLibWallCode = useCallback(async (event, codeRef, codeType) => {
        event.stopPropagation(); //Don't let click event bubble up to parent
        const initCodeType = codeType === "U" ? "udefStandard" : "wallInsType";
        const code = {...libCodes[codeRef], codeRef:codeRef, isLibCode:true };
        await setInitCode(code, initCodeType);
        updateCodeDrawer({
            isOpen:true,
            codeName: "Wall",
            isEditing: true,
            isDrawing: true,
            componentType: "Wall",
            codeType,
            fieldAccessor: `components.${componentId}.wallInsType`,
            componentId,
            id: codeRef,
            modelFormChange: modelChange,
            drawingFormChange: change,
            drawingSave: saveToState,
            lastEdited: code.lastEdited || ''
        });
    }, [change, componentId, libCodes, setInitCode, updateCodeDrawer, modelChange, saveToState]);

    useEffect(() => {
        if ((selectedCodeRef || '').includes('Wall')) {
            modelChange(`stashedCodes.Wall.${componentId}`, selectedCodeRef);
        }
    },[componentId, selectedCodeRef]);

    useEffect(()=>{
        change(
            `components.${componentId}.measurements.area`,
            parseFloat((calculatedPerimeter*wallHeight).toFixed(2))
        );
    },[perimeter, wallHeight]);

    return (
        <div>
            <Field
                className={classes.inputMarginBottom}
                component={Input}
                type="text"
                label="Wall Name*"
                name={`components.${componentId}.label`}
                validate={[required, charLim100]}
                placeholder="Example: Main Wall"
                onChange={saveToState}
            />
            <Field
                className={classes.inputMarginBottom}
                component={InputWithUnits}
                type="number"
                name={`components.${componentId}.measurements.perimeter`}
                label="Perimeter"
                placeholder="0.00"
                decimals={getDecimalPlaces('wallPerimeter')}
                disabled={true}
                change={()=>''}
                setValue={calculatedPerimeter}
                units={{
                    base:getBaseUnits('wallPerimeter', primaryUnits),
                    options:[imageUnits],
                    selected:imageUnits,
                    unitType:'length',
                    accessor:`components.${componentId}.measurements.perimeter_u`
                }}
                info="Calculated based on polygon shape and the set scale."
                onChange={saveToState}
                onUnitChange={saveToState}
            />
            <Field
                className={classes.inputMarginBottom}
                component={InputWithUnits}
                type="number"
                name={`components.${componentId}.measurements.height`}
                label="Interior Wall Height"
                placeholder="0.00"
                validate={heightValidation}
                decimals={getDecimalPlaces('wallHeight')}
                disabled={fieldsWithLinesAttached.includes('wallHeight')}
                isDrawing={fieldsWithLinesAttached.includes('wallHeight')}
                isDrawingTip="This field is associated with a Line in the Drawing Capture. To detach, delete the Line or use it for another field."
                change={change}
                units={{
                    base:getBaseUnits('wallHeight', primaryUnits),
                    options:getUnitOptions('length'),
                    selected:imageUnits,
                    unitType:'length',
                    accessor:`components.${componentId}.measurements.height_u`
                }}
                onChange={saveToState}
                onUnitChange={saveToState}
            />
            <Field
                className={classes.inputMarginBottom}
                component={Select}
                type="number"
                name={`components.${componentId}.wallInsType`}
                options={[
                    {
                        label:"User Specified",
                        value:{
                            codeLabel:"User Specified",
                            codeRef:"UserSpecified",
                        }
                    },
                    ...wallTypeModelCodesMap(modelCodes, libCodes, handleEditModelWallCode),
                    ...wallTypeLibCodesMap(libCodes, null),
                ]}
                label="Wall Type"
                placeholder="Choose Wall Type"
                searchPlaceholder="Search Wall Codes"
                search={true}
                footer={()=><CodeFooter
                    toggleDrawer={(value) =>
                        updateCodeDrawer({
                            isOpen:value,
                            codeName: "Wall",
                            isEditing: false,
                            isDrawing: true,
                            componentType: "Wall",
                            codeType: "S",
                            fieldAccessor: `components.${componentId}.wallInsType`,
                            componentId,
                            modelFormChange: modelChange,
                            drawingFormChange: change,
                            drawingSave: saveToState
                        })
                    }
                    setInitCode={setInitCode}
                    codeType="wallInsType"
                />}
                isLoading={isCalculatingRValue}
                onChange={(values) => {
                    handleTypeChange(values);
                    saveToState(values);
                }}
            />
            <Field
                className={classes.inputMarginBottom}
                component={InputWithUnits}
                type="number"
                name={`components.${componentId}.wallInsType_nomRVal`}
                label={selectedCodeRef === "UserSpecified" ? "Effective R-Value" : "Nominal R-Value"}
                placeholder={0.00}
                disabled={selectedCodeRef !== "UserSpecified"}
                validate={wallTypeValidation}
                setTouched={true}
                decimals={getDecimalPlaces('wallInsType')}
                change={change}
                setValue={selectedCodeRef !== "UserSpecified" ? selectedRValue : selectedRValue}
                units={{
                    base:getBaseUnits('wallInsType', primaryUnits),
                    options:getUnitOptions('thermalResistance'),
                    selected:rValUnits,
                    unitType:'thermalResistance',
                    accessor:`components.${componentId}.wallInsType_u`
                }}
                onChange={(value) => {
                    if (selectedCodeRef === "UserSpecified") {
                        change(`components.${componentId}.wallInsType_nomRVal`, value)
                        change(`components.${componentId}.wallInsType_effRVal`, value)
                    }
                    saveToState();
                }}
                onUnitChange={saveToState}
                isLoading={isCalculatingRValue}
                warning={wallCodeWarning}
            />
            <div style={{display:'grid',gridTemplateColumns:'1fr 1fr', gridGap:'1.25rem', marginBottom:'1rem'}}>
                <Field
                    component={Input}
                    type="number"
                    name={`components.${componentId}.measurements.corners`}
                    label="Corners"
                    placeholder="0"
                    quantity
                    validate={wallCornerValidation}
                    onChange={saveToState}
                />
                <Field
                    component={Input}
                    type="number"
                    name={`components.${componentId}.measurements.intersections`}
                    label="Intersections"
                    placeholder="0"
                    quantity
                    validate={wallIntersectionValidation}
                    onChange={saveToState}
                />
            </div>
            <Field
                className={classes.inputMarginBottom}
                component={Checkbox}
                name={`components.${componentId}.adjacentEnclosedSpace`}
                label="Adjacent to enclosed unconditioned space"
                type="checkbox"
                onChange={saveToState}
            />
        </div>
    )
}, isEqual);

export default Wall;