import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import FuelPrices from "./";

const mapStateToProps = ({ form }, { accessor }) => {
    // const selector = formValueSelector('model');

    return {
        accessor,
    };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FuelPrices);
