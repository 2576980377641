import React, { useState } from "react";
import { useHistory } from "react-router";
import { useOutsideClickHook } from "utils/outsideClick";
import classes from "../styles.module.scss";
import Dropdown from "components/Dropdown";
import goBack from "assets/images/icons/goBack.svg";

const GoBack = ({ rootDir, rootDirPath, path, folders }) => {
    const [isGoBackOpen, setIsGoBackOpen] = useState(false);

    const history = useHistory();
    const goBackRef = useOutsideClickHook(() => setIsGoBackOpen(false));

    return (
        <div className={classes.goBackIconContainer}>
            <div
                className={classes.goBackIcon}
                onClick={() => setIsGoBackOpen(!isGoBackOpen)}
                ref={goBackRef}
            >
                <img src={goBack} alt={goBack} />
            </div>
            <Dropdown
                className={classes.goBackIconDropdown}
                open={isGoBackOpen}
                options={[
                    {
                        label: rootDir,
                        onClick: () => history.push(`${rootDirPath}`),
                    },
                    ...path
                        .filter((id, index) => index !== path.length - 1)
                        .map((id, index) =>
                            path.length - 1 !== index
                                ? {
                                      label: folders[id].name,
                                      onClick: () =>
                                          history.push(
                                              `${rootDirPath}/${path
                                                  .slice(0, index + 1)
                                                  .join("/")
                                                  .toString()}`
                                          ),
                                  }
                                : null
                        ),
                ]}
            />
        </div>
    );
};

export default GoBack;
