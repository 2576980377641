import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useOutsideClickHook } from "utils/outsideClick";
import { selectUpgradePackages } from "../../_ducks/selectors";
import { selectSaveUpgradePackageLabels } from "store/upgradeLibrary/selectors";
import Delete from "assets/images/icons/JSX/Delete";
import MenuDots from "components/MenuDots";
import DropDown from "components/Dropdown";
import classes from "../UpgradesTable.module.scss";
import Edit from "assets/images/icons/JSX/Edit";
import Duplicate from "assets/images/icons/JSX/Duplicate";
import SaveUpgrade from "assets/images/icons/JSX/SaveUpgrade";
import ArrowDown from "assets/images/icons/JSX/arrow-down";
import Tooltip from "components/Tooltip";
import uniqid from "uniqid";
import { saveUpdateUpgradePackage } from "store/upgradeLibrary/thunk";
import { actions as upgradeActions } from "../../_ducks";
import { selectUsersOrganization } from "store/users/selectors";
import { isEmpty } from "lodash";

const { reorderUpgradePackages } = upgradeActions;

const UpgradeHead = ({
    packageId,
    name,
    togglePackageEdit,
    duplicateUpgrade,
    deletePackage,
    showCost,
    packageIds,
    uid,
    codes,
    fetchLabels,
}) => {
    const [open, toggleOpen] = useState(false);
    const [isSavingUpgrade, setIsSavingUpgrade] = useState(false);

    const ref = useOutsideClickHook(() => toggleOpen(false));

    const dispatch = useDispatch();

    const upgradePackages = useSelector(selectUpgradePackages);
    const selectLabels = useSelector(selectSaveUpgradePackageLabels);
    const userOrganization = useSelector(selectUsersOrganization);

    const orgId = !isEmpty(userOrganization) ? Object.keys(userOrganization.memberOrgIds)[0] : null;

    const savedUserIds = selectLabels ? selectLabels.upgradeLibMap.map(({ id }) => id) : [];
    const savedOrgIds = selectLabels && orgId ? selectLabels.orgUpgradeLibMap.map(({ id }) => id) : [];

    const handleEditClick = () => {
        toggleOpen(false);
        togglePackageEdit();
    };

    const handleDuplicateClick = () => {
        toggleOpen(false);
        duplicateUpgrade(packageId, name);
    };

    const handleDeleteClick = () => {
        toggleOpen(false);
        deletePackage();
    };

    const handleSaveUpdatePackage = async (saveAsNew) => {
        if (isSavingUpgrade) return;

        setIsSavingUpgrade(true);

        await dispatch(
            saveUpdateUpgradePackage({
                uid,
                upgradePackage: JSON.parse(JSON.stringify(upgradePackages[packageId])),
                upgradePackageId: saveAsNew ? uniqid("package-") : packageId,
                saveAsNew,
                codes,
                saveIntoOrganization: false,
                organizationId: orgId,
            })
        );

        await fetchLabels(uid, orgId);

        setIsSavingUpgrade(false);
        toggleOpen(false);
    };

    const handleSaveUpdateOrganizationPackage = async (saveAsNew) => {
        if (isSavingUpgrade) return;

        setIsSavingUpgrade(true);

        await dispatch(
            saveUpdateUpgradePackage({
                uid,
                upgradePackage: JSON.parse(JSON.stringify(upgradePackages[packageId])),
                upgradePackageId: saveAsNew ? uniqid("package-") : packageId,
                saveAsNew,
                codes,
                saveIntoOrganization: true,
                organizationId: orgId,
            })
        );

        await fetchLabels(uid, orgId);

        setIsSavingUpgrade(false);
        toggleOpen(false);
    };

    const handleSort = (amount) => {
        const packageIndex = packageIds.indexOf(packageId);
        let reOrderedPackages = [...packageIds];

        const from = reOrderedPackages.splice(packageIndex, 1)[0];
        reOrderedPackages.splice(packageIndex + amount, 0, from);

        dispatch(reorderUpgradePackages(reOrderedPackages));
    };

    return (
        <span className={classes.upgradeHead}>
            <span className={classes.upgradeHeadLabel}>{name}</span>
            <div ref={ref} className={classes.upgradeHeadMenuWrapper}>
                <MenuDots className={classes.upgradeHeadMenu} onClick={() => toggleOpen(true)} />
                <DropDown
                    className={classes.upgradeHeadDropdown}
                    open={open}
                    toggleOpen={() => toggleOpen(!open)}
                    options={[
                        {
                            label: (
                                <>
                                    <Edit /> Edit package name and priorities
                                </>
                            ),
                            className: classes.dropdownLabel,
                            onClick: handleEditClick,
                        },
                        {
                            label: (
                                <>
                                    <Duplicate /> Duplicate package
                                </>
                            ),
                            className: classes.dropdownLabel,
                            onClick: handleDuplicateClick,
                        },
                        {
                            label: (
                                <>
                                    <SaveUpgrade />
                                    {isSavingUpgrade ? "Saving..." : "Save upgrade package"}
                                </>
                            ),
                            className:
                                savedUserIds.includes(packageId) || savedOrgIds.includes(packageId)
                                    ? classes.noDropdownLabel
                                    : classes.dropdownLabel,
                            onClick: () => handleSaveUpdatePackage(false),
                        },
                        {
                            label: (
                                <>
                                    <SaveUpgrade /> {isSavingUpgrade ? "Saving..." : "Save upgrade package (org)"}
                                </>
                            ),
                            className:
                                savedOrgIds.includes(packageId) || savedUserIds.includes(packageId) || !orgId
                                    ? classes.noDropdownLabel
                                    : classes.dropdownLabel,
                            onClick: () => handleSaveUpdateOrganizationPackage(false),
                        },
                        {
                            label: (
                                <>
                                    <SaveUpgrade />
                                    {isSavingUpgrade ? "Saving..." : "Edit saved upgrade package"}
                                </>
                            ),
                            className:
                                !savedUserIds.includes(packageId) && !savedOrgIds.includes(packageId)
                                    ? classes.noDropdownLabel
                                    : classes.dropdownLabel,
                            onClick: () =>
                                savedOrgIds.includes(packageId)
                                    ? handleSaveUpdateOrganizationPackage(false)
                                    : handleSaveUpdatePackage(false),
                        },
                        {
                            label: (
                                <>
                                    <SaveUpgrade /> {isSavingUpgrade ? "Saving..." : "Save as new  upgrade package"}
                                </>
                            ),
                            className:
                                !savedUserIds.includes(packageId) && !savedOrgIds.includes(packageId)
                                    ? classes.noDropdownLabel
                                    : classes.dropdownLabel,
                            onClick: () =>
                                savedOrgIds.includes(packageId)
                                    ? handleSaveUpdateOrganizationPackage(true)
                                    : handleSaveUpdatePackage(true),
                        },
                        {
                            label: (
                                <>
                                    <Delete /> Delete package
                                </>
                            ),
                            className: `${classes.dropdownLabel} ${classes.deleteLabel}`,
                            onClick: handleDeleteClick,
                        },
                    ]}
                />
            </div>
            <div className={classes.upgradeHeadSortWrapper}>
                <button
                    onClick={() => handleSort(-1)}
                    className="normalize-button"
                    data-for={`${packageId}-headCell`}
                    data-tip="Move left"
                    data-place="bottom"
                >
                    <ArrowDown />
                </button>
                <button
                    onClick={() => handleSort(1)}
                    className="normalize-button"
                    data-for={`${packageId}-headCell`}
                    data-tip="Move right"
                    data-place="bottom"
                >
                    <ArrowDown />
                </button>
            </div>
            {showCost && <span className={classes.upgradeHeadCost}>Cost</span>} <Tooltip id={`${packageId}-headCell`} />
        </span>
    );
};

export default UpgradeHead;
