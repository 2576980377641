import React from "react";
import classes from "./style.module.scss";
import sharedClasses from "features/Model/sharedStyles.module.scss";
import { Field } from "redux-form";
import { getValidation, getDecimalPlaces } from "utils/fieldValidation";
import { getOptions } from "utils/fields";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import Select from "components/Input/Select";
import Button from "components/Button";
import Checkbox from "components/Input/Checkbox";

const thermalMassValidation = getValidation("thermalMass");
const intWindowShadingValidation = getValidation("interiorWindowShading");
const massFracValidation = getValidation("effectiveMassFraction");
const soilValidation = getValidation("soilCondition");
const waterLevelValidation = getValidation("waterLevel");
const wallColourValidation = getValidation("wallColour");
const roofColourValidation = getValidation("roofColour");

export default React.memo(
    ({
        accessor,
        modelUnits,
        isDefaultRoofInputs,
        h2kVersion,
        h2kMinor,
        wallColourId,
        roofColourId,
        wallColourValue,
        roofColourValue,
        change,
    }) => (
        <>
            <InputRow gridTemplate="2fr 1fr 2fr 1fr">
                <Field
                    component={Select}
                    name={`${accessor}.thermalMass`}
                    options={getOptions({ fieldKey: "thermalMass" })}
                    label="Thermal Mass"
                    placeholder="Choose Thermal Mass"
                    validate={thermalMassValidation}
                />
                <Field
                    component={Input}
                    type="text"
                    name={`${accessor}.effectiveMassFraction`}
                    label="Effective Mass Fraction"
                    placeholder="0.00"
                    validate={massFracValidation}
                    change={change}
                />
                <Field
                    component={Select}
                    name={`${accessor}.interiorWindowShading`}
                    options={getOptions({ fieldKey: "interiorWindowShading" })}
                    label="Interior Window Shading"
                    placeholder="None"
                    validate={intWindowShadingValidation}
                    nonH2kParam="f280IntWindowShading"
                />
            </InputRow>
            <InputRow gridTemplate="2fr 2fr 1fr 1fr">
                <Field
                    component={Select}
                    name={`${accessor}.soilCondition`}
                    options={getOptions({ fieldKey: "soilCondition" })}
                    label="Foundation Soil Condition"
                    placeholder="Choose Soil Conductivity"
                    validate={soilValidation}
                    info={
                        'If unsure, select "Normal". If a slab is constructed on permafrost, this should be recorded. However, if a house in a permafrost area is built on piers, select "Normal"'
                    }
                />
                <Field
                    component={Select}
                    name={`${accessor}.waterLevel`}
                    options={getOptions({ fieldKey: "waterLevel" })}
                    label="Water Table Level"
                    placeholder="Choose Water Table Level"
                    validate={waterLevelValidation}
                    info={'Unless official documentation states otherwise, use the default value of "Normal".'}
                />
            </InputRow>
            <InputRow gridTemplate="2fr 1fr 2fr 1fr">
                <Field
                    component={Select}
                    name={`${accessor}.wallColour`}
                    options={[
                        {
                            label: "User Specified",
                            value: {
                                id: 0,
                                value: wallColourValue, //need to get rValue for this to match
                            },
                        },
                        ...getOptions({ fieldKey: "wallColour" }),
                    ]}
                    label="Wall Colour"
                    placeholder="Choose Wall Colour"
                    validate={wallColourValidation}
                    info={
                        "Only model the exterior wall colour if over 50% of the wall is uninsulated. Note that log homes are not considered uninsulated."
                    }
                />
                <Field
                    component={Input}
                    type="number"
                    name={`${accessor}.wallColour.value`}
                    label="Absorptance"
                    placeholder={0.0}
                    disabled={wallColourId !== 0}
                    validate={wallColourValidation}
                    setTouched={true}
                    decimals={getDecimalPlaces("wallColour")}
                    change={change}
                    setValue={wallColourId !== 0 ? wallColourValue : null}
                />
                <Field
                    component={Select}
                    name={`${accessor}.roofColour`}
                    options={[
                        {
                            label: "User Specified",
                            value: {
                                id: 0,
                                value: roofColourValue, //need to get rValue for this to match
                            },
                        },
                        ...getOptions({ fieldKey: "roofColour" }),
                    ]}
                    label="Roof Colour"
                    placeholder="Choose Roof Colour"
                    validate={roofColourValidation}
                />
                <Field
                    component={Input}
                    type="number"
                    name={`${accessor}.roofColour.value`}
                    label="Absorptance"
                    placeholder={0.0}
                    disabled={roofColourId !== 0}
                    validate={roofColourValidation}
                    setTouched={true}
                    decimals={getDecimalPlaces("roofColour")}
                    change={change}
                    setValue={roofColourId !== 0 ? roofColourValue : null}
                />
            </InputRow>
            <InputRow gridTemplate="48.88% 1fr">
                <Field
                    component={Checkbox}
                    name={`${accessor}.isMixedUse`}
                    label="Is Mixed Use?"
                    large
                    className={classes.checkbox}
                    type="checkbox"
                />
                <div className={classes.roofInputsWrapper}>
                    <Field
                        component={Checkbox}
                        name={`${accessor}.isDefaultRoofCavity`}
                        label="Default Roof Cavity Inputs"
                        large
                        className={classes.checkbox}
                        type="checkbox"
                        disabled={true}
                    />
                    <Button disabled={true} className={classes.roofButton}>
                        Modify Roof Cavity Inputs
                    </Button>
                </div>
            </InputRow>
            {h2kMinor >= 11 && (
                <InputRow gridTemplate="2fr 1fr 2fr 1fr">
                    <Field
                        component={Checkbox}
                        name={`${accessor}.eligibleForNBC`}
                        label="Eligible for NBC Compliance"
                        large
                        className={classes.checkbox}
                        type="checkbox"
                    />
                </InputRow>
            )}
        </>
    )
);
