import React, { useState } from "react";
import { connect } from "react-redux";

import { resetDrawing } from "../_ducks/actions";
import Dialog, { CloseDialog } from "components/Dialog";
import Button from "components/Button";

import classes from "../style.module.scss";

const CloseConfirmation = ({
    toggleCloseDialog,
    closeDialogOpen,
    toggleOpen,
    resetDrawing,
    saveDrawingData,
    modelSave,
}) => {
    const [isLoading, setIsLoading] = useState(false);

    return (
        <Dialog open={closeDialogOpen}>
            <CloseDialog closeActions={() => toggleCloseDialog(false)} />
            <div className={classes.dialog}>
                <h3>
                    Are you sure you want to close the <strong>Drawing Capture</strong> tool?
                </h3>
                <p>All unsaved changes will be lost.</p>
                <div className={classes.footer}>
                    <Button
                        type="red"
                        onClick={() => {
                            toggleCloseDialog(false);
                            toggleOpen(false);
                        }}
                        disabled={isLoading}
                    >
                        Exit without Saving
                    </Button>
                    <Button
                        disabled={isLoading}
                        onClick={async () => {
                            setIsLoading(true);

                            await saveDrawingData();
                            await modelSave();
                            // await resetDrawing();

                            setIsLoading(false);
                            toggleCloseDialog(false);
                            toggleOpen(false);
                        }}
                        type="default"
                    >
                        {isLoading ? "Saving..." : "Save and Exit"}
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

const mapDispatchToProps = (dispatch) => ({
    resetDrawing: () => dispatch(resetDrawing()),
});

export default connect(null, mapDispatchToProps)(CloseConfirmation);
