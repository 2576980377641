import React, { useState } from "react";
import classes from "../style.module.scss";
import moment from "moment";
import { isEmpty } from "lodash";
import { useOutsideClickHook } from "utils/outsideClick";
import Dropdown from "components/Dropdown";
import ModelTags from "components/ModelTags";
import ThreeDots from "assets/images/icons/JSX/ThreeDots";
import Tooltip from "components/Tooltip";
import ProjectChecklistProgress from "components/ProjectChecklistProgress";
import { phaseTitles, totalPhases } from "features/Model/ProjectPathChecklist/templates/chbaRoadmapPhases";
import Community from "./Community";
import CHBACommunityIcon from "assets/images/icons/communityCHBA.svg";
import CommunityIcon from "assets/images/icons/community.svg";
import Folder from "./Folder";
import FolderIcon from "assets/images/icons/folder.svg";
import SharedFolderIcon from "assets/images/icons/Shared-folder.svg";
import Model from "./Model";
import ModelIcon from "assets/images/icons/model.svg";
import CHBAModelIcon from "assets/images/icons/modelCHBA.svg";
import SharedModelIcon from "assets/images/icons/Shared-model.svg";

export { Community, Folder, Model };

const DashboardItem = ({
    ref,
    className,
    itemId,
    name,
    sharedWithUser = false,
    itemType,
    details, // Optional Fields { lastEdited, totalModels, provTerr, userName, currentPhase, targetCompletionDate }
    tags = [],
    actions,
    ...props
}) => {
    const [subMenu, toggleSubMenu] = useState(false);
    const subMenuRef = useOutsideClickHook(() => toggleSubMenu(false));

    const progress = (phase) => Math.round((phase / totalPhases) * 100);

    const icon = {
        folder: <img src={sharedWithUser ? SharedFolderIcon : FolderIcon} alt="Folder icon" />,
        model: <img src={sharedWithUser ? SharedModelIcon : ModelIcon} alt="model icon" />,
        CHBAModel: <img src={CHBAModelIcon} alt="CHBA model icon" />,
        community: <img src={sharedWithUser ? CHBACommunityIcon : CommunityIcon} alt="community icon" />,
    };

    return (
        <li
            ref={ref}
            className={`${classes.item} ${className}`}
            style={{
                padding: !isEmpty(tags) && "0.5rem 1rem",
                pointerEvents: "none",
            }}
            {...props}
        >
            <div className={classes.itemTitle}>
                {icon[itemType]} {name || ""}
                {itemType !== "CHBAModel" && tags.map((tag, i) => <ModelTags key={i} tag={tag} />)}
            </div>

            {Object.entries(details).map(([key, elem]) => {
                if (["currentPhase"].includes(key)) {
                    return (
                        <div className={classes.phase} key={key}>
                            <span>
                                <ProjectChecklistProgress
                                    progress={progress(elem)}
                                    color={"#18c1ad"}
                                    radius={6}
                                    strokeWidth={"4px"}
                                />
                            </span>
                            <p>{elem === 0 ? "0. Roadmap not started" : phaseTitles[elem - 1]}</p>
                        </div>
                    );
                }
                if (["userName", "provTerr"].includes(key)) {
                    return (
                        <div className={classes.elementText} key={key}>
                            {elem}
                        </div>
                    );
                }
                if (["lastEdited", "targetCompletionDate"].includes(key)) {
                    return (
                        <div className={classes.lastEdited} key={key}>
                            {elem === "" ? "N/A" : moment(elem).format("MMM DD, YYYY")}
                        </div>
                    );
                }
                return <div key={key}>{elem}</div>;
            })}

            <div className={classes.actions} ref={subMenuRef}>
                {actions && (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                            toggleSubMenu(!subMenu);
                        }}
                        className={classes.delete}
                        style={{ pointerEvents: "auto" }}
                    >
                        <ThreeDots />
                        <Dropdown
                            className={classes.subMenu}
                            open={subMenu}
                            options={actions.map(({ label, shouldShow, onClick }) => ({
                                label: label,
                                shouldShow: shouldShow,
                                onClick: (e) => {
                                    onClick(e);
                                    toggleSubMenu(false);
                                },
                            }))}
                        />
                    </div>
                )}
            </div>
            <Tooltip id={`actions-${itemId}`} delay />
        </li>
    );
};

export default DashboardItem;
