import React, { useCallback } from "react";
import classes from "./style.module.scss";
import classNames from "classnames";
import Drawer from "components/Drawer";
import { ReactComponent as ChecklistIcon } from "assets/images/icons/Icon-Checklist.svg";
import moment from "moment";
import { checklistTagMap } from "utils/checklist";
import Button from "components/Button";
import Checkbox from "components/Input/Checkbox";

const contentSortOrder = {
    title:0,
    subtitle:1,
    text:2,
}

const contentSort = (a, b) => contentSortOrder[b.type] > contentSortOrder[a.type] ? -1
    : contentSortOrder[b.type] < contentSortOrder[a.type] ? 1 : 0;

const Text = ({text}) => (
    <p className={classes.text}>{text}</p>
);

const Title = ({title}) => (
    <p className={classes.title}>{title}</p>
);

const SubTitle = ({subtitle}) => (
    <p className={classes.subtitle}>{subtitle}</p>
);

const contentType = {
    text:Text,
    title:Title,
    subtitle:SubTitle
};

const Checklist = ({
    open=false,
    toggleOpen,
    list={},
    updateChecklistItem,
}) => {
    const handleClose = useCallback(() => {
        toggleOpen();
    }, [toggleOpen]);

    // Ideas
    // - Filters at the top
    // - Group by month, etc


    return (
        <Drawer
            open={open}
            title="Checklist"
            subtitle="Verify information from Preliminary Assessment"
            close={handleClose}
            icon={ChecklistIcon}
        >
            <div className={classes.checklist}>
                {Object.keys(list)
                .sort((a, b) => {
                    // both addressed
                    if (list[a].addressed && list[b].addressed) {
                        return moment(list[b].created).isBefore(list[a].created) ? -1 : 1;
                    }

                    // only b addressed
                    if (list[b].addressed) {
                        return -1;
                    }

                    if (list[a].addressed) {
                        return 1;
                    }

                    // neither addressed
                    return moment(list[b].created).isBefore(list[a].created) ? -1 : 1;
                })
                .map((id, ind) => {
                    const { 
                        created="",
                        addressed="", 
                        dismissed="",
                        tags=[],
                        content=[],
                     } = list[id];

                    return (
                        <div className={classNames(classes.item, { [classes.addressed]:!!addressed })} key={ind}>
                            <div className={classes.itemHeader}>
                                <span className={classes.date}>{moment(created).format("MMM DD, YYYY")}</span>
                                <div className={classes.tags}>
                                    {tags.map((id, ind) => (
                                        <span className={classes.tag} key={ind}>{checklistTagMap[id]?.name}</span>
                                    ))}
                                </div>
                            </div>
                            <div className={classes.content}>
                                {content.sort(contentSort).map(({ type, ...props }, index) => {
                                    const Content = contentType[type] || (() => null);
                                    return (
                                        <Content key={`${type}-${index}`} type={type} {...props} />
                                    )
                                })}
                            </div>
                            <div className={classes.itemFooter}>
                                <Checkbox
                                    input={{
                                        value:!!addressed,
                                        onChange:() => {
                                            updateChecklistItem(id, { addressed: !addressed ? moment().format("LLLL") : "" })
                                        },
                                    }}
                                    label="Addressed"
                                    name={`${id}`}
                                />
                                {/* <Button small>Addressed</Button> */}
                            </div>
                        </div>
                    )
                })}
            </div>
        </Drawer>
    )
};

export default Checklist;