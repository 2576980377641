import React, { useCallback, useEffect, useState } from "react";
import classes from "./style.module.scss";
import sharedClasses from "features/Model/sharedStyles.module.scss";
import { Field } from "redux-form";
import { getDefaultOption, getOptions } from "utils/fields";
import { getValidation, getDecimalPlaces } from "utils/fieldValidation";
import { getBaseUnits, getUnitOptions } from "utils/fields";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import InputWithUnits from "components/Input/InputWithUnits";
import Select from "components/Input/Select";
import TestDataPoints from "./TestDataPoints/container";
import Tabs from "components/Tabs";
import Button from "components/Button";
import ToggleMulti from "components/ToggleMulti";
import { mixpanel } from "components/Mixpanel";

const testTypeValidation = getValidation("airTestType");
const outsideTempValidation = getValidation("airTestOutsideTemp");
const barometricPressureValidation = getValidation("airTestBarometricPressure");

let tabList = [
    { name: "Test 1/Equip 1", testKey: "test1Equip1", url: "" },
    { name: "Test 1/Equip 2", testKey: "test1Equip2", url: "&subtab=test2" },
    { name: "Test 2/Equip 1", testKey: "test2Equip1", url: "&subtab=test3" },
    { name: "Test 2/Equip 2", testKey: "test2Equip2", url: "&subtab=test4" },
    { name: "Test 3/Equip 1", testKey: "test3Equip1", url: "&subtab=test5" },
];

const testTypeTabIndex = {
    0: [tabList[0]],
    1: [tabList[0], tabList[1]],
    2: [tabList[0], tabList[2]],
    3: [tabList[0], tabList[2], tabList[4]],
    4: [tabList[0], tabList[1], tabList[2], tabList[3]],
};

export default ({
    accessor,
    modelId,
    modelUnits,
    airTightnessTypeId,
    change,
    history,
    isUpgrade = false,
    outsideTempValue,
    outsideTempUnits,
    barometricPressureUnits,
    contentOpen,
    testTypeId = 0,
}) => {
    //this split().join() handles the initial case when the search string is "?tab=test-data", which confuses the second level of subtabs
    const initialTab = window.location.search.split("?tab=test-data").join("");
    const [activeTab, changeActiveTab] = useState(initialTab);

    const [tabsPresent, setTabsPresent] = useState(testTypeTabIndex[testTypeId] || [tabList[0]]);

    const handleTestTypeChange = (value) => {
        setTabsPresent(testTypeTabIndex[value.id]);
        //Navigate to first tab
        history.push(`/models/${modelId}/airtightness?tab=test-data`);
        changeActiveTab("");
    };

    // 'clear data' button should also clear header values

    // useEffect(()=>{
    //     console.log('rerendering testdata')
    // },[uploadedData])

    return (
        <>
            <div className={classes.airtightness}>
                <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                    <Field
                        component={Select}
                        name={`${accessor}.blowerTest.isCgsbTest`}
                        options={[
                            {
                                label: "CGSB Test",
                                value: true,
                            },
                            {
                                label: "As operated",
                                value: false,
                            },
                        ]}
                        label="Test Conditions"
                        placeholder="Choose Test Conditions"
                        disabled={airTightnessTypeId !== 0}
                    />

                    <Field
                        component={Select}
                        name={`modelData.airTightness.testData.testType`}
                        options={getOptions({ fieldKey: "airTestType" })}
                        label="Test Type"
                        placeholder="Choose Test Type"
                        validate={testTypeValidation}
                        onChange={handleTestTypeChange}
                        warning={testTypeId !== 0 ? "blowerDoorVolumeSync" : ""}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`modelData.airTightness.testData.outsideTemp`}
                        label="Outside Temperature"
                        validate={outsideTempValidation}
                        decimals={getDecimalPlaces("airTestOutsideTemp")}
                        change={change}
                        setValue={outsideTempValue}
                        units={{
                            base: getBaseUnits("airTestOutsideTemp", modelUnits),
                            options: getUnitOptions("temperature"),
                            selected: outsideTempUnits,
                            unitType: "temperature",
                            accessor: `modelData.airTightness.testData.outsideTemp_u`,
                        }}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`modelData.airTightness.testData.barometricPressure`}
                        label="Barometric Pressure"
                        validate={barometricPressureValidation}
                        decimals={getDecimalPlaces("airTestBarometricPressure")}
                        units={{
                            base: getBaseUnits("airTestBarometricPressure", modelUnits),
                            options: getUnitOptions("pressure"),
                            selected: barometricPressureUnits,
                            unitType: "pressure",
                            accessor: `modelData.airTightness.testData.barometricPressure_u`,
                        }}
                    />
                </InputRow>
                <Tabs
                    type="subTabs"
                    initialTab={""}
                    tabActive={activeTab || ""}
                    onChange={(e) => {
                        history.push(`/models/${modelId}/airtightness?tab=test-data${e}`);
                        mixpanel.track("AirTightness Tab Change", {
                            Tab: e,
                        });

                        return changeActiveTab(e);
                    }}
                    tabs={tabsPresent}
                    className={classes.tabs}
                    stack={contentOpen}
                >
                    {tabsPresent.map((tabData, ind) => {
                        return (
                            <TestDataPoints
                                key={ind}
                                change={change}
                                accessor={`${accessor}.testData.tests.${tabData.testKey}`}
                                testTypeId={testTypeId}
                                testName={tabData.name}
                            />
                        );
                    })}
                </Tabs>
            </div>
        </>
    );
};
