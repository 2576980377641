import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { finishedPhases, totalPhases, phaseMap } from "utils/chbaRoadMap";

import { selectChbaCheckList } from "features/Model/ProjectPathChecklist/_ducks/selectors";

import classes from "./style.module.scss";

const ChecklistDrawerButton = ({ onClick, chbaCheckList }) => {
    return (
        <button
            onClick={() => {
                onClick();
            }}
            className={`${classes.checklistDrawer}`}
        >
            <div className={classes.drawerButtonText}>
                <p>{`CHBA NZ Reno (${phaseMap[finishedPhases(chbaCheckList)] || 0}/${totalPhases - 2})`}</p>
            </div>
        </button>
    );
};

// export default ChecklistDrawerButton

const mapStateToProps = createStructuredSelector({
    chbaCheckList: selectChbaCheckList,
});

export default connect(mapStateToProps)(ChecklistDrawerButton);
