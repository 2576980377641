import React from "react";
import classes from "./style.module.scss";
import sharedClasses from "../style.module.scss";

export default () => {
    return (
        <>
            <p>
                The Review tab allows users to simulate their HOT2000 model, export their .h2k file, specify program
                information and view other summary details about their model.
            </p>
            <p>
                <strong>HOT2000 Results.</strong> HOT2000 simulations can be run directly in Volta SNAP, and multiple
                upgrade scenarios can be analyzed at once. The HOT2000 Results sub-tab provides detailed results
                associated with a HOT2000 simulation. In addition to HOT2000 results, this sub-tab provides estimates of
                the hourly electrical loads associated with a simulation. Upgrade packages details can be managed and
                compared in the Upgrades tab.
            </p>
            <p>
                <strong>Model and DCF Exporting.</strong> Once data collection is complete, users can export their model
                as a .h2k file (currently compatible with HOT2000 v11.9, v11.10, v11.11 and v11.12). When clicking the
                Save and Export button, all of the relevant data is converted into the .h2k file format. The conversion
                process also scans the file for any potential sources of error that may produce a corrupt file that
                HOT2000 may be unable to open. When the process finds an error it will overwrite that field with a
                default value (if possible) so that the user can still open the file in HOT2000, if desired. However, if
                errors are flagged, we recommend checking those fields that were identified, and ensuring that the
                values have been entered properly. If a file does not open in HOT2000, please contact support at
                info@voltaresearch.org.
            </p>
            <p>
                An excel-based data collection form (DCF) can also be exported, which provides a human-readable summary
                of .h2k file's inputs.
            </p>
            <p>
                <strong>CSA F280-12 Summary.</strong> The CSA F280-12 summary sub-tab allows users to their overall
                design heat loss and heat gain as well as details for each component. Effective R-values used for F280
                calculations must be calculated using the “parallel path” method, and therefore differ from those
                produced by HOT2000. The F280 summary report can also be downloaded from this sub-tab.
            </p>
        </>
    );
};
