import React, { useEffect, useRef, useCallback } from "react";
import globalStyles from "components/globalStyles.module.scss";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import Select from "components/Input/Select";
import Checkbox from "components/Input/Checkbox";
import { Field } from "redux-form";
import Button from "components/Button";
import { getOptions, getFirstOption, getDefaultOption } from "utils/fields";
import { required, maxLength, getValidation } from "utils/fieldValidation";
import Tooltip from "components/Tooltip";

const charLim32 = maxLength(32);

const labelValidation = [required, charLim32];
const framingValidation = getValidation("csBasementWallFraming");
const spacingValidation = getValidation("csBasementWallSpacing");
const studsValidation = getValidation("csBasementWallStudsCornersInt");
const framingInsValidation = getValidation("csBasementWallFramingIns");
const extraInsValidation = getValidation("csBasementWallExtraIns");
const interiorValidation = getValidation("csBasementWallFinish");

export default React.memo(
    ({
        toggleOpen,
        basementWallLabel,
        framingId,
        noFraming,
        basementWallValue,
        submitting,
        handleSubmit,
        change,
        invalid,
        isLibCode,
        clearInitCode,
        isCodeLibrary,
    }) => {
        const valueRef = useRef(null);

        const handleFramingChange = useCallback(
            ({ id, value }) => {
                // Handle condition when framing is set to none (0)
                if (id === 0) {
                    change("basementWallCode.layers.spacing", getDefaultOption("csBasementWallSpacing"));
                    change(
                        "basementWallCode.layers.studsCornerIntersection",
                        getDefaultOption("csBasementWallStudsCornersInt")
                    );
                    change(
                        "basementWallCode.layers.insulationFramingLayer",
                        getDefaultOption("csBasementWallFramingIns")
                    );
                }
            },
            [framingId, change]
        );

        useEffect(() => {
            valueRef.current = basementWallValue;
        }, [basementWallValue, basementWallLabel]);

        return (
            <div className={globalStyles.standardCodeWrapper}>
                <h3>Standard Basement Wall Code</h3>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Input}
                        type="text"
                        name={`basementWallCode.label`}
                        label="Code Label"
                        placeholder="Code Label"
                        validate={labelValidation}
                        setValue={basementWallLabel.toString() === valueRef.current ? basementWallValue : null}
                    />
                    <Field
                        component={Input}
                        type="text"
                        name={`basementWallCode.value`}
                        label="Internal Code"
                        placeholder="###########"
                        disabled={true}
                        setValue={basementWallValue}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Select}
                        name={`basementWallCode.layers.framing`}
                        label="Framing"
                        placeholder="Choose Framing"
                        options={getOptions({ fieldKey: "csBasementWallFraming" })}
                        validate={framingValidation}
                        onChange={handleFramingChange}
                    />
                    <Field
                        component={Select}
                        name={`basementWallCode.layers.interiorFinish`}
                        label="Interior Finish"
                        placeholder="Choose Interior Finish"
                        options={getOptions({ fieldKey: "csBasementWallFinish" })}
                        validate={interiorValidation}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Select}
                        name={`basementWallCode.layers.spacing`}
                        label="Spacing"
                        placeholder="Choose Spacing"
                        options={getOptions({ fieldKey: "csBasementWallSpacing" })}
                        validate={spacingValidation}
                        disabled={noFraming}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Select}
                        name={`basementWallCode.layers.studsCornerIntersection`}
                        label="Studs at Corners and Intersections"
                        placeholder="Choose Studs at Corners and Intersections"
                        options={getOptions({ fieldKey: "csBasementWallStudsCornersInt" })}
                        validate={studsValidation}
                        disabled={noFraming}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Select}
                        name={`basementWallCode.layers.insulationFramingLayer`}
                        label="Insulation in Framing Layer"
                        placeholder="Choose Insulation in Framing Layer"
                        options={getOptions({ fieldKey: "csBasementWallFramingIns" })}
                        validate={framingInsValidation}
                        disabled={noFraming}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Select}
                        name={`basementWallCode.layers.extraInsulationLayer`}
                        label="Extra Insulation Layer"
                        placeholder="Choose Extra Insulation Layer"
                        options={getOptions({ fieldKey: "csBasementWallExtraIns" })}
                        validate={extraInsValidation}
                    />
                </InputRow>
                <div style={{ borderBottom: "1px solid #e1eaf0", marginTop: "1.5rem", marginBottom: "1.5rem" }}></div>

                {!isCodeLibrary && (
                    <InputRow gridTemplate="1fr 1fr">
                        <div data-tip="Code will automatically be updated in library" data-for="codeLibTip">
                            <Field
                                component={Checkbox}
                                name={"addToLibrary"}
                                label={isLibCode ? "Update code in library" : "Add to code library"}
                                large
                                disabled={isLibCode}
                                type="checkbox"
                            />
                        </div>
                        <Field
                            component={Checkbox}
                            name={"setAsModelDefault"}
                            label={"Set as model default"}
                            large
                            type="checkbox"
                        />
                    </InputRow>
                )}
                <div className={globalStyles.buttons}>
                    <Button
                        large
                        type="hollow"
                        onClick={() => {
                            clearInitCode();
                            toggleOpen(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button large onClick={handleSubmit} disabled={submitting || invalid}>
                        {submitting ? "Saving Code..." : "Save Code"}
                    </Button>
                </div>
                <Tooltip id="codeLibTip" hide={!isLibCode} />
            </div>
        );
    }
);
