import React, { useState, useEffect, useRef } from "react";
import { Field } from "redux-form";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import InputWithUnits from "components/Input/InputWithUnits";
import { getValidation, getDecimalPlaces, required } from "utils/fieldValidation";
import { getBaseUnits, getUnitOptions } from "utils/fields";
import Checkbox from "components/Input/Checkbox";
import classes from "./style.module.scss";

const exposedSurfaceValidation = [required, ...getValidation("basementExpSurfacePerimeter")];
const lengthValidation = getValidation("basementLength");
const widthValidation = getValidation("basementWidth");
const perimeterValidation = getValidation("basementPerimeter");
const areaValidation = getValidation("basementArea");

export default React.memo(
    ({
        accessor,
        isRectangular,
        expPerimeterValue,
        totBasementPerimeter,
        expPerimeterUnits,
        modelUnits,
        lengthUnits,
        widthUnits,
        perimeterValue,
        minPerimeter,
        minPerimeterString,
        perimeterUnits,
        areaUnits,
        hasMultiFoundation,
        isDetached,
        change,
        isDrawingComponent,
        floorLengthEquation,
        floorWidthEquation,
        floorPerimeterEquation,
        floorAreaEquation,
        exposedPerimeterEquation,
    }) => {
        return (
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.floor.measurements.length`}
                    label="Floor Length"
                    placeholder="Enter Value"
                    validate={lengthValidation}
                    decimals={getDecimalPlaces("basementLength")}
                    change={change}
                    hideField={!isRectangular}
                    units={{
                        base: getBaseUnits("basementLength", modelUnits),
                        options: getUnitOptions("length"),
                        selected: lengthUnits,
                        unitType: "length",
                        accessor: `${accessor}.floor.measurements.length_u`,
                    }}
                    withEquation
                    equation={floorLengthEquation}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.floor.measurements.width`}
                    label="Floor Width"
                    placeholder="Enter Value"
                    validate={widthValidation}
                    decimals={getDecimalPlaces("basementWidth")}
                    change={change}
                    hideField={!isRectangular}
                    units={{
                        base: getBaseUnits("basementWidth", modelUnits),
                        options: getUnitOptions("length"),
                        selected: widthUnits,
                        unitType: "length",
                        accessor: `${accessor}.floor.measurements.width_u`,
                    }}
                    withEquation
                    equation={floorWidthEquation}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.floor.measurements.perimeter`}
                    label="Floor Perimeter"
                    placeholder="Enter Value"
                    validate={perimeterValidation}
                    decimals={getDecimalPlaces("basementPerimeter")}
                    change={change}
                    disabled={isDrawingComponent}
                    isDrawing={isDrawingComponent}
                    hideField={isRectangular}
                    units={{
                        base: getBaseUnits("basementPerimeter", modelUnits),
                        options: getUnitOptions("length"),
                        selected: perimeterUnits,
                        unitType: "length",
                        accessor: `${accessor}.floor.measurements.perimeter_u`,
                    }}
                    warning={
                        !isRectangular && perimeterValue < minPerimeter
                            ? `minFoundationPerimeter-${minPerimeterString}`
                            : ""
                    }
                    withEquation
                    equation={floorPerimeterEquation}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.floor.measurements.area`}
                    label="Floor Area"
                    placeholder="Enter Value"
                    validate={areaValidation}
                    disabled={isDrawingComponent}
                    isDrawing={isDrawingComponent}
                    decimals={getDecimalPlaces("basementArea")}
                    change={change}
                    hideField={isRectangular}
                    units={{
                        base: getBaseUnits("basementArea", modelUnits),
                        options: getUnitOptions("area"),
                        selected: areaUnits,
                        unitType: "area",
                        accessor: `${accessor}.floor.measurements.area_u`,
                    }}
                    withEquation
                    equation={floorAreaEquation}
                />
                <Field
                    component={Checkbox}
                    name={`${accessor}.floor.measurements.isRectangular`}
                    label="Is Basement Floor Rectangular"
                    type="checkbox"
                    disabled={isDrawingComponent}
                    isDrawing={isDrawingComponent}
                    large
                    className={classes.isRectangular}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.exposedSurfacePerimeter`}
                    label="Exposed Surfaces Perimeter"
                    placeholder="Enter Value"
                    validate={exposedSurfaceValidation}
                    decimals={getDecimalPlaces("basementExpSurfacePerimeter")}
                    setValue={!hasMultiFoundation && isDetached ? totBasementPerimeter : null}
                    disabled={!hasMultiFoundation && isDetached}
                    change={change}
                    units={{
                        base: getBaseUnits("basementExpSurfacePerimeter", modelUnits),
                        options: getUnitOptions("length"),
                        selected: expPerimeterUnits,
                        unitType: "length",
                        accessor: `${accessor}.exposedSurfacePerimeter_u`,
                    }}
                    info={
                        "Used only when there are multiple foundations present. Enter the total perimeter of this foundation component that is not attached to any other foundation components."
                    }
                    warning={expPerimeterValue > totBasementPerimeter ? "largeExpFoundationPerimeter" : ""}
                    withEquation
                    equation={exposedPerimeterEquation}
                />
            </InputRow>
        );
    }
);
