import { connect } from 'react-redux';
import { reduxForm, formValueSelector, reset } from 'redux-form';
import StandardCode from 'features/Model/Enclosure/ComponentList/Window/Construction/StandardCode';
import windowCodeTemplate from 'features/Model/Enclosure/Templates/windowCode.json';
import { actions as userActions } from 'store/users';
import { actions as enclosureActions } from 'features/Model/Enclosure/_ducks';
import moment from 'moment';
// import { getStandardRVal } from 'utils/enclosure/api';
import isEmpty from 'lodash/isEmpty';
const { addToCodeLib } = userActions;
const { setInitCode, getWindowRValue } = enclosureActions;

const mapStateToProps = (
    {
        form,
        model:{
            modelId,
        },
        user:{
            uid,
            codeLib:{
                Window:{
                    codes:windowCodeLib={}
                }={}
            }={}
        },
        enclosure:{
            initCodeValues:{
                windowType={}
            }={}
        }={}
    },
    {
        drawingFormChange,
        modelFormChange,
        componentId,
        toggleOpen,
        isCodeLibrary=false
    }
) => {
    const selector = formValueSelector('drawingWindowCode');
    const modelSelector = formValueSelector('model');
    const { id:windowGlazingTypeId, value:windowGlazingTypeValue } = selector({form}, 'windowCode.layers.glazingType') || {};
    const { value:windowCoatingsTintsValue } = selector({form}, 'windowCode.layers.coatingsTints') || {};
    const { value:windowFillTypeValue } = selector({form}, 'windowCode.layers.fillType') || {};
    const { value:windowSpacerTypeValue } = selector({form}, 'windowCode.layers.spacerType') || {};
    const { value:windowTypeValue } = selector({form}, 'windowCode.layers.windowType') || {};
    const { value:windowFrameMatValue } = selector({form}, 'windowCode.layers.frameMaterial') || {};
    const windowLabel = selector({form}, 'windowCode.label') || '';
    const modelCodes = modelSelector({form}, 'modelData.codes') || {};
    // const components = modelSelector({form}, 'modelData.components') || {};

    const windowValue = `${windowGlazingTypeValue}${windowCoatingsTintsValue}${windowFillTypeValue}${windowSpacerTypeValue}${windowTypeValue}${windowFrameMatValue}`;

    const { codeRef:editingCodeRef=null, isLibCode=false } = windowType;

    return {
        windowLabel,
        drawingFormChange,
        initialValues:{
            windowCode: isEmpty(windowType) ? windowCodeTemplate : windowType,
            addToLibrary:isLibCode
        },
        windowGlazingTypeId,
        windowGlazingTypeValue,
        windowSpacerTypeValue,
        windowTypeValue,
        isSingleGlazing:parseFloat(windowGlazingTypeId) === 0,
        makeSpacerFillDefault:windowGlazingTypeValue === '1',
        windowValue,
        modelId,
        uid,
        modelCodes,
        windowCodeLib,
        editingCodeRef,
        isEditing:!!editingCodeRef,
        isLibCode,
        modelFormChange,
        componentId,
        toggleOpen,
        isCodeLibrary,
        hideUpdateAll:true
    }
};

const mapDispatchToProps = ( dispatch ) => ({
    clearForm: () => dispatch(reset('drawingWindowCode')),
    clearInitCode: () => dispatch(setInitCode({}, 'windowType'))
});

const mergeProps = (state, dispatch, own) => ({
    ...state,
    ...dispatch,
    ...own,
});

const onSubmit = async(
    form,
    dispatch,
    {
        toggleOpen,
        uid,
        drawingFormChange,
        editingCodeRef,
        componentId,
        isCodeLibrary,
        modelFormChange,
    }
) => {
    const { windowCode={}, addToLibrary=false, setAsModelDefault=false } = form;
    const { codeRef, value, nominalRValue=null, label, isLibCode, ...rest } = windowCode;

    toggleOpen(false);

    // ***************************************
    // 1. Create and change code ref
    // ***************************************
    const newCodeRef = `Window-S-${moment().format('YYYYMMDDHHmmssSS')}`;
    const setCodeRef = editingCodeRef || newCodeRef;

    // ***************************************
    // 2. Fetch rValue
    // ***************************************
    let rVal = 0;
    let windowEr = 0;
    let windowShgc = 0;

    //Fetch rval, er, shgc
    await dispatch(getWindowRValue({
        codeString: value,
        codeType: 'Window',
        componentId:componentId,
        fieldId:'windowInsType'
    }))
        .then(({
            rVal:newRVal,
            windowEr:newWindowEr,
            windowShgc:newWindowShgc
        }) => {
            rVal = newRVal;
            windowEr = newWindowEr;
            windowShgc = newWindowShgc;
        });

    // ***************************************
    // 3. If in model, do model things
    // ***************************************
    if (!isCodeLibrary) {
        const code = { ...rest, nominalRValue:rVal, label, value, codeRef:setCodeRef, er:windowEr, shgc:windowShgc};

        modelFormChange(`modelData.codes.${setCodeRef}`, code);

        const fieldValue = {
            codeLabel:label,
            codeRef:setCodeRef,
        };
        
        if (setAsModelDefault) {
            const defaultValue = {windowType: fieldValue, er: windowEr, shgc: windowShgc, windowType_nomRVal:rVal, windowType_effRVal:rVal}
            modelFormChange('modelData.defaultCodes.window', defaultValue);
        }
    
        // Update model "window type" field in window
        drawingFormChange('windowType', fieldValue);
        drawingFormChange('windowType_nomRVal', rVal);
        drawingFormChange('windowType_effRVal', rVal);
        drawingFormChange('er', windowEr);
        drawingFormChange('shgc', windowShgc);
    }

    // ***************************************
    // 4. If in code library or "add to code library" is selected, do code library things
    // ***************************************
    if (addToLibrary || isCodeLibrary) {
        await dispatch(addToCodeLib(
            uid,
            {
                ...rest,
                nominalRValue:rVal,
                label,
                codeType:"Standard",
                componentType:"Window",
                value,
                er:windowEr,
                shgc:windowShgc
            },
            "Window",
            setCodeRef
        ));
    }


    // Clear window code defaults for next time
    dispatch(setInitCode({}, 'windowType'));
    // Reset form
    dispatch(reset('drawingWindowCode'));

};

const form = reduxForm({
    form:"drawingWindowCode",
    enableReinitialize: true,
    onSubmit:onSubmit
})(StandardCode);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(form);