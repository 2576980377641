import { connect } from 'react-redux';
import CodeActions from './';
import { actions } from "store/users";
import { actions as enclosureActions } from 'features/Model/Enclosure/_ducks';

const { deleteCode } = actions;
const { setInitCode } = enclosureActions;

const mapStateToProps = ({
    user: {
        codeLib={},
        uid
    }
}, { type, id }) => {
    const {
        [type]:{
            codes:{
                [id]:code={}
            }={}
        }={}
    } = codeLib;

    const {
        componentType="",
    } = code;

    const [ standardCode ] = id.split(`${type}-U-`);

    return {
        type,
        id,
        uid,
        code:{
            codeRef:id,
            ...code
        },
        componentType,
        codeType:standardCode ? "S" : "U"
    };
};

const mapDispatchToProps = (dispatch) => ({
    deleteCode: (type, id, uid) => dispatch(deleteCode(uid, id, type)),
    setInitCode: (code, type) => {
        return dispatch(setInitCode(code, type));
    },
});

const mergeProps = (state, dispatch, own) => {
    const { type, id, uid } = state;
    const { deleteCode, setInitCode } = dispatch;
    
    return {
        ...state,
        ...dispatch,
        ...own,
        deleteCode: () => deleteCode(type, id, uid),
        setInitCode: (code, type) => setInitCode(code, type)
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(CodeActions);