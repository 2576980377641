import React, { useState, useEffect, useRef } from "react";
import { Field } from "redux-form";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import InputWithUnits from "components/Input/InputWithUnits";
import { getValidation, getDecimalPlaces, required } from "utils/fieldValidation";
import { getBaseUnits, getUnitOptions } from "utils/fields";
import Checkbox from "components/Input/Checkbox";
import classes from "./style.module.scss";

const exposedSurfaceValidation = [required, ...getValidation("slabExpSurfacePerimeter")];
const lengthValidation = getValidation("slabLength");
const widthValidation = getValidation("slabWidth");
const perimeterValidation = getValidation("slabPerimeter");
const areaValidation = getValidation("slabArea");

export default React.memo(
    ({
        accessor,
        expPerimeterValue,
        totSlabPerimeter,
        expPerimeterUnits,
        isRectangular,
        modelUnits,
        lengthUnits,
        widthUnits,
        perimeterValue,
        minPerimeter,
        minPerimeterString,
        perimeterUnits,
        areaUnits,
        hasMultiFoundation,
        isDetached,
        change,
        isDrawingComponent,
        slabFloorLengthEquation,
        slabFloorWidthEquation,
        slabFloorPerimeterEquation,
        slabFloorAreaEquation,
        slabFloorExposedPerimeterEquation,
    }) => {
        return (
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.floor.measurements.length`}
                    label="Floor Length"
                    placeholder="0.00"
                    validate={lengthValidation}
                    decimals={getDecimalPlaces("slabLength")}
                    change={change}
                    hideField={!isRectangular}
                    units={{
                        base: getBaseUnits("slabLength", modelUnits),
                        options: getUnitOptions("length"),
                        selected: lengthUnits,
                        unitType: "length",
                        accessor: `${accessor}.floor.measurements.length_u`,
                    }}
                    withEquation
                    equation={slabFloorLengthEquation}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.floor.measurements.width`}
                    label="Floor Width"
                    placeholder="0.00"
                    validate={widthValidation}
                    decimals={getDecimalPlaces("slabWidth")}
                    change={change}
                    hideField={!isRectangular}
                    units={{
                        base: getBaseUnits("slabWidth", modelUnits),
                        options: getUnitOptions("length"),
                        selected: widthUnits,
                        unitType: "length",
                        accessor: `${accessor}.floor.measurements.width_u`,
                    }}
                    withEquation
                    equation={slabFloorWidthEquation}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.floor.measurements.perimeter`}
                    label="Floor Perimeter"
                    placeholder="0.00"
                    validate={perimeterValidation}
                    decimals={getDecimalPlaces("slabPerimeter")}
                    change={change}
                    disabled={isDrawingComponent}
                    isDrawing={isDrawingComponent}
                    hideField={isRectangular}
                    units={{
                        base: getBaseUnits("slabPerimeter", modelUnits),
                        options: getUnitOptions("length"),
                        selected: perimeterUnits,
                        unitType: "length",
                        accessor: `${accessor}.floor.measurements.perimeter_u`,
                    }}
                    withEquation
                    equation={slabFloorPerimeterEquation}
                    warning={
                        !isRectangular && perimeterValue < minPerimeter
                            ? `minFoundationPerimeter-${minPerimeterString}`
                            : ""
                    }
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.floor.measurements.area`}
                    label="Floor Area"
                    placeholder="0.00"
                    validate={areaValidation}
                    decimals={getDecimalPlaces("slabArea")}
                    change={change}
                    disabled={isDrawingComponent}
                    isDrawing={isDrawingComponent}
                    hideField={isRectangular}
                    units={{
                        base: getBaseUnits("slabArea", modelUnits),
                        options: getUnitOptions("area"),
                        selected: areaUnits,
                        unitType: "area",
                        accessor: `${accessor}.floor.measurements.area_u`,
                    }}
                    withEquation
                    equation={slabFloorAreaEquation}
                />
                <Field
                    component={Checkbox}
                    name={`${accessor}.floor.measurements.isRectangular`}
                    label="Is Slab Floor Rectangular"
                    type="checkbox"
                    disabled={isDrawingComponent}
                    isDrawing={isDrawingComponent}
                    large
                    className={classes.isRectangular}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.exposedSurfacePerimeter`}
                    label="Exposed Surfaces Perimeter"
                    placeholder="0.00"
                    validate={exposedSurfaceValidation}
                    decimals={getDecimalPlaces("slabExpSurfacePerimeter")}
                    setValue={!hasMultiFoundation && isDetached ? totSlabPerimeter : null}
                    disabled={!hasMultiFoundation && isDetached}
                    change={change}
                    units={{
                        base: getBaseUnits("slabExpSurfacePerimeter", modelUnits),
                        options: getUnitOptions("length"),
                        selected: expPerimeterUnits,
                        unitType: "length",
                        accessor: `${accessor}.exposedSurfacePerimeter_u`,
                    }}
                    withEquation
                    equation={slabFloorExposedPerimeterEquation}
                    info={
                        "Used only when there are multiple foundations present. Enter the total perimeter of this foundation component that is not attached to any other foundation components."
                    }
                    warning={expPerimeterValue > totSlabPerimeter ? "largeExpFoundationPerimeter" : ""}
                />
            </InputRow>
        );
    }
);
