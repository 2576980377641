import React, { useState } from "react";
import { importCodeLibFile } from "utils/h2kinout/api";
import classes from "./style.module.scss";
import { mixpanel } from "components/Mixpanel";
import Button from "components/Button";
import { ReactComponent as Upload } from "assets/images/icons/Upload.svg";
import Dialog, { CloseDialog } from "components/Dialog";
import { useDropzone } from 'react-dropzone';
import classNames from "classnames";

const CodeLibUpload = ({
    label,
    handleUpload,
    accept=''
}) => {
    const [fileName, changeFileName] = useState('');
    const [importState, updateImportState] = useState('');
    const [dialogOpen, toggleOpen] = useState(false);
    const [error, setError] = useState("");

    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps,
        isDragActive,
        open,
    } = useDropzone({
        multiple:false,
        maxSize: 1073741824,
        accept,
        noClick:true
    });

    const clearStates = () => {
        updateImportState("");
        changeFileName("");
        setError("");
    };

    const closeDialog = () => {
        toggleOpen(false);
        setTimeout(() => {
            clearStates();
        }, 500);
    };

    const handleLibUpload = (e) => {
        updateImportState('importing');
        const formData = new FormData();
        const fileName = acceptedFiles[0].name || '';
        formData.append('modelFile', acceptedFiles[0]);
        formData.append('name', JSON.stringify({
            name: acceptedFiles[0].name.split('.')[0],
            notes: 'Insert notes for imported code library here',
            templateID: 'CodeLibImport',
            templateName: 'CodeLibImport',
        }));

        importCodeLibFile(formData)
            .then((data) => {
                handleUpload(data.data);
                updateImportState('success');
                changeFileName(fileName);
            })
            .catch((err)=>{
                handleUpload({});
                updateImportState('failed');
                setError(err);
            });

        mixpanel.track("Code Library Upload");
    }

    return (
        <>
            <Button
                className={classes.uploadButton}
                smallPadding
                type="emptyBg"
                icon={Upload}
                onClick={() => toggleOpen(true)}
            >
                {label}
            </Button>
            <Dialog open={dialogOpen}>
                <CloseDialog closeActions={closeDialog} />
                <div className={classes.dialogContent}>
                    <Upload />
                    <h2>Upload Your Code Library</h2>
                    {fileName ?
                        <div className={classes.uploadComplete}>
                            <p><strong>Upload complete!</strong><br /> These codes are now available in your code library.</p>
                            <Button type="hollow" onClick={closeDialog}>
                                Close
                            </Button>
                        </div> :
                        <>
                            <p>You can usually find your Code Library at<br /> <span>C:\Your HOT2000 Directory\StdLibs\<strong>codeLib.cod</strong></span></p>
                            <div
                                className={classNames(classes.dropzone, {
                                    [classes.active]:isDragActive,
                                    [classes.filesUploaded]:acceptedFiles.length > 0,
                                    [classes.importing]:importState === "importing"
                                })}
                                {...getRootProps()}
                            >
                                <input {...getInputProps()} />
                                {acceptedFiles.length === 0 &&
                                    <h3>{isDragActive ? "Drop your file here..." : "Drag and drop your Code Library file"}</h3>
                                }
                                {fileRejections.length > 0 &&
                                    <>
                                        {fileRejections.map(({ errors }) => errors.map(e => 
                                            <p className={classes.error} key={e.code}>{e.message}</p>    
                                        ))}
                                        <Button
                                            type="slate"
                                            onClick={open}
                                        >
                                            Upload another file
                                        </Button>
                                    </>
                                }
                                {acceptedFiles.length > 0 && importState === "failed" && error &&
                                    <>
                                        <p className={classes.error}>{error}</p>   
                                        <Button
                                            type="slate"
                                            onClick={open}
                                        >
                                            Upload another file
                                        </Button>
                                    </>
                                }
                                {acceptedFiles.length === 0 && fileRejections.length === 0 &&
                                    <>
                                        <p className={classes.initialText}>Maxiumum file size is 1 GB.</p>
                                        <Button
                                            type="slate"
                                            onClick={open}
                                        >
                                            Browse Files
                                        </Button>
                                    </>
                                }
                                {acceptedFiles.length > 0 &&
                                    <>
                                        {acceptedFiles.map((file, index) => <p className={classes.filePath} key={index}>{file.path}</p>)}
                                        <p className={classes.uploadDiff} onClick={open}>Choose a different file</p>
                                    </>
                                }
                            </div>
                            <p className={classes.warningText}><em>*Any existing code with a matching label will be overwritten.</em></p>
                            <div className={classes.footer}>
                            <Button
                                type="hollow"
                                disabled={importState === 'importing'}
                                onClick={closeDialog}
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={handleLibUpload}
                                disabled={importState === 'importing' || error}
                            >
                                {importState === 'importing' ? "Uploading..." : "Upload Now"}
                            </Button>
                        </div>
                        </>
                    }
                </div>
            </Dialog>
        </>
    )
};

export default CodeLibUpload;