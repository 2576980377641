import isEmpty from 'lodash/isEmpty';

export const getAllWindowAccessors = (components, accessorIgnore = '') => {
    const { wall = {}, ceiling = {}, basement = {}, crawlspace = {} } = components;

    const windowIgnore = accessorIgnore.split('.window.')[1];

    let accessorsToUpdate = [];
    if (!isEmpty(wall)) {
        Object.entries(wall).forEach(([wallKey, wallObj]) => {
            const { subcomponents: { window = {}, door = {} } = {} } = wallObj;

            if (!isEmpty(window)) {
                Object.keys(window).forEach((windowKey) => {
                    if (windowKey !== windowIgnore) {
                        accessorsToUpdate = [
                            ...accessorsToUpdate,
                            `modelData.components.wall.${wallKey}.subcomponents.window.${windowKey}`,
                        ];
                    }
                });
            }

            if (!isEmpty(door)) {
                Object.keys(door).forEach((doorKey) => {
                    const { subcomponents: { window: doorWindow = {} } = {} } = door[doorKey];
                    if (!isEmpty(doorWindow)) {
                        Object.keys(doorWindow).forEach((windowKey) => {
                            if (windowKey !== windowIgnore) {
                                accessorsToUpdate = [
                                    ...accessorsToUpdate,
                                    `modelData.components.wall.${wallKey}.subcomponents.door.${doorKey}.subcomponents.window.${windowKey}`,
                                ];
                            }
                        });
                    }
                });
            }
        });
    }

    if (!isEmpty(ceiling)) {
        Object.entries(ceiling).forEach(([ceilingKey, ceilingObj]) => {
            const { subcomponents: { window = {} } = {} } = ceilingObj;

            if (!isEmpty(window)) {
                Object.keys(window).forEach((windowKey) => {
                    if (windowKey !== windowIgnore) {
                        accessorsToUpdate = [
                            ...accessorsToUpdate,
                            `modelData.components.ceiling.${ceilingKey}.subcomponents.window.${windowKey}`,
                        ];
                    }
                });
            }
        });
    }

    if (!isEmpty(basement)) {
        Object.entries(basement).forEach(([basementKey, basementObj]) => {
            const { subcomponents: { window = {}, door = {} } = {} } = basementObj;

            if (!isEmpty(window)) {
                Object.keys(window).forEach((windowKey) => {
                    if (windowKey !== windowIgnore) {
                        accessorsToUpdate = [
                            ...accessorsToUpdate,
                            `modelData.components.basement.${basementKey}.subcomponents.window.${windowKey}`,
                        ];
                    }
                });
            }

            if (!isEmpty(door)) {
                Object.keys(door).forEach((doorKey) => {
                    const { subcomponents: { window: doorWindow = {} } = {} } = door[doorKey];
                    if (!isEmpty(doorWindow)) {
                        Object.keys(doorWindow).forEach((windowKey) => {
                            if (windowKey !== windowIgnore) {
                                accessorsToUpdate = [
                                    ...accessorsToUpdate,
                                    `modelData.components.basement.${basementKey}.subcomponents.door.${doorKey}.subcomponents.window.${windowKey}`,
                                ];
                            }
                        });
                    }
                });
            }
        });
    }

    if (!isEmpty(crawlspace)) {
        Object.entries(crawlspace).forEach(([crawlspaceKey, crawlspaceObj]) => {
            const { subcomponents: { window = {}, door = {} } = {} } = crawlspaceObj;

            if (!isEmpty(window)) {
                Object.keys(window).forEach((windowKey) => {
                    if (windowKey !== windowIgnore) {
                        accessorsToUpdate = [
                            ...accessorsToUpdate,
                            `modelData.components.crawlspace.${crawlspaceKey}.subcomponents.window.${windowKey}`,
                        ];
                    }
                });
            }

            if (!isEmpty(door)) {
                Object.keys(door).forEach((doorKey) => {
                    const { subcomponents: { window: doorWindow = {} } = {} } = door[doorKey];
                    if (!isEmpty(doorWindow)) {
                        Object.keys(doorWindow).forEach((windowKey) => {
                            if (windowKey !== windowIgnore) {
                                accessorsToUpdate = [
                                    ...accessorsToUpdate,
                                    `modelData.components.crawlspace.${crawlspaceKey}.subcomponents.door.${doorKey}.subcomponents.window.${windowKey}`,
                                ];
                            }
                        });
                    }
                });
            }
        });
    }

    return accessorsToUpdate;
};

export const getMatchingWindowCodeRefs = (components, editingCodeRef, accessorIgnore = '') => {
    const { wall = {}, ceiling = {}, basement = {}, crawlspace = {} } = components;

    const windowIgnore = accessorIgnore.split('.window.')[1];

    let accessorsToUpdate = [];
    if (!isEmpty(wall)) {
        Object.entries(wall).forEach(([wallKey, wallObj]) => {
            const { subcomponents: { window = {}, door = {} } = {} } = wallObj;

            if (!isEmpty(window)) {
                Object.keys(window).forEach((windowKey) => {
                    const { windowType: { codeRef: windowCodeRef = '' } = {} } = window[windowKey];

                    if (windowKey !== windowIgnore && windowCodeRef === editingCodeRef) {
                        accessorsToUpdate = [
                            ...accessorsToUpdate,
                            `modelData.components.wall.${wallKey}.subcomponents.window.${windowKey}`,
                        ];
                    }
                });
            }

            if (!isEmpty(door)) {
                Object.keys(door).forEach((doorKey) => {
                    const { subcomponents: { window: doorWindow = {} } = {} } = door[doorKey];
                    if (!isEmpty(doorWindow)) {
                        Object.keys(doorWindow).forEach((windowKey) => {
                            const { windowType: { codeRef: windowCodeRef = '' } = {} } = doorWindow[windowKey];

                            if (windowKey !== windowIgnore && windowCodeRef === editingCodeRef) {
                                accessorsToUpdate = [
                                    ...accessorsToUpdate,
                                    `modelData.components.wall.${wallKey}.subcomponents.door.${doorKey}.subcomponents.window.${windowKey}`,
                                ];
                            }
                        });
                    }
                });
            }
        });
    }

    if (!isEmpty(ceiling)) {
        Object.entries(ceiling).forEach(([ceilingKey, ceilingObj]) => {
            const { subcomponents: { window = {} } = {} } = ceilingObj;

            if (!isEmpty(window)) {
                Object.keys(window).forEach((windowKey) => {
                    const { windowType: { codeRef: windowCodeRef = '' } = {} } = window[windowKey];

                    if (windowKey !== windowIgnore && windowCodeRef === editingCodeRef) {
                        accessorsToUpdate = [
                            ...accessorsToUpdate,
                            `modelData.components.ceiling.${ceilingKey}.subcomponents.window.${windowKey}`,
                        ];
                    }
                });
            }
        });
    }

    if (!isEmpty(basement)) {
        Object.entries(basement).forEach(([basementKey, basementObj]) => {
            const { subcomponents: { window = {}, door = {} } = {} } = basementObj;

            if (!isEmpty(window)) {
                Object.keys(window).forEach((windowKey) => {
                    const { windowType: { codeRef: windowCodeRef = '' } = {} } = window[windowKey];

                    if (windowKey !== windowIgnore && windowCodeRef === editingCodeRef) {
                        accessorsToUpdate = [
                            ...accessorsToUpdate,
                            `modelData.components.basement.${basementKey}.subcomponents.window.${windowKey}`,
                        ];
                    }
                });
            }

            if (!isEmpty(door)) {
                Object.keys(door).forEach((doorKey) => {
                    const { subcomponents: { window: doorWindow = {} } = {} } = door[doorKey];
                    if (!isEmpty(doorWindow)) {
                        Object.keys(doorWindow).forEach((windowKey) => {
                            const { windowType: { codeRef: windowCodeRef = '' } = {} } = doorWindow[windowKey];

                            if (windowKey !== windowIgnore && windowCodeRef === editingCodeRef) {
                                accessorsToUpdate = [
                                    ...accessorsToUpdate,
                                    `modelData.components.basement.${basementKey}.subcomponents.door.${doorKey}.subcomponents.window.${windowKey}`,
                                ];
                            }
                        });
                    }
                });
            }
        });
    }

    if (!isEmpty(crawlspace)) {
        Object.entries(crawlspace).forEach(([crawlspaceKey, crawlspaceObj]) => {
            const { subcomponents: { window = {}, door = {} } = {} } = crawlspaceObj;

            if (!isEmpty(window)) {
                Object.keys(window).forEach((windowKey) => {
                    const { windowType: { codeRef: windowCodeRef = '' } = {} } = window[windowKey];

                    if (windowKey !== windowIgnore && windowCodeRef === editingCodeRef) {
                        accessorsToUpdate = [
                            ...accessorsToUpdate,
                            `modelData.components.crawlspace.${crawlspaceKey}.subcomponents.window.${windowKey}`,
                        ];
                    }
                });
            }

            if (!isEmpty(door)) {
                Object.keys(door).forEach((doorKey) => {
                    const { subcomponents: { window: doorWindow = {} } = {} } = door[doorKey];
                    if (!isEmpty(doorWindow)) {
                        Object.keys(doorWindow).forEach((windowKey) => {
                            const { windowType: { codeRef: windowCodeRef = '' } = {} } = doorWindow[windowKey];

                            if (windowKey !== windowIgnore && windowCodeRef === editingCodeRef) {
                                accessorsToUpdate = [
                                    ...accessorsToUpdate,
                                    `modelData.components.crawlspace.${crawlspaceKey}.subcomponents.door.${doorKey}.subcomponents.window.${windowKey}`,
                                ];
                            }
                        });
                    }
                });
            }
        });
    }

    return accessorsToUpdate;
};
