import { cloneDeep } from "lodash";

export const capFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

//Replaces undefined in a nested object so that it can be saved in firebase
//Not using this at the moment
export const removeUndefined = (obj) => {
    return cleanObj(cloneDeep(obj));
};

const cleanObj = (object) => {
    Object.entries(object).forEach(([k, v]) => {
        if (v && typeof v === "object") {
            cleanObj(v);
        }
        if ((v && typeof v === "object" && !Object.keys(v).length) || typeof v === "undefined") {
            // console.log("undefined found", v, object[k]);
            if (Array.isArray(object)) {
                object[k] = null;
            } else {
                object[k] = null;
            }
        }
    });
    return object;
};
