import React, { useState } from "react";
import sharedClasses from "features/Model/sharedStyles.module.scss";
import classes from "./style.module.scss";
import Select from "components/Input/Select";
import Subtabs from "components/Subtabs";
import { mixpanel } from "components/Mixpanel";
import General from "./General/container.js";
import PVSystemCommissioning from "./PVSystemCommissioning/container.js";
import RoofMountedPVReady from "./RoofMountedPVReady/container.js";
import GroundMountedPVReady from "./GroundMountedPVReady/container.js";
import Technical from "./Technical/container.js";
import VerificationChecklist from "./VerificationChecklist/container.js";
import IndoorAirQuality from "./IndoorAirQuality/container.js";
import { useEffect } from "react";
import { buildDeclarationAddress, netZeroWorkbookTemplate } from "./Templates/netZeroWorkbook";
import { useLocation } from "react-router-dom";

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const NetZeroWorkbook = ({
    history,
    modelId,
    change,
    noNetZeroWorkbook,
    changeActiveTab,
    fileID,
    userTelephone,
    userCompany,
    userEmail,
    userNameEnteredBy,
    renovatorName,
    renovatorPhone,
    renovatorEmail,
    renovatorCompany,
    clientCity,
    clientStreetAddress,
    clientProvince,
    clientPostalCode,
    totalHouseVolume,
}) => {
    let query = useQuery();

    // const initialTab = window.location.search;
    const [activeNzwTab, changeActiveNzwTab] = useState(`&subtab=${query.get("subtab")}`);

    // use change function with useEffect with dependent variable as the form object

    useEffect(() => {
        if (noNetZeroWorkbook) {
            change(
                `modelData.nzWorkbook`,
                netZeroWorkbookTemplate({
                    fileID,
                    userTelephone,
                    userCompany,
                    userEmail,
                    userNameEnteredBy,
                    renovatorName,
                    renovatorPhone,
                    renovatorEmail,
                    renovatorCompany,
                    clientCity,
                    clientStreetAddress,
                    clientProvince,
                    clientPostalCode,
                    totalHouseVolume,
                })
            );
        }
    }, [noNetZeroWorkbook]);

    const declarationAddress = buildDeclarationAddress({
        address: clientStreetAddress,
        city: clientCity,
        prov: clientProvince,
        postalCode: clientPostalCode,
    });

    return (
        <Subtabs
            type="subTabs"
            initialTab={activeNzwTab || ""}
            tabActive={activeNzwTab || ""}
            onChange={(e) => {
                history.push(`/models/${modelId}/review?tab=workbook${e}`);
                mixpanel.track("Review Tab Change", {
                    Tab: e,
                });
                changeActiveTab(`?tab=workbook${e}`);
                return changeActiveNzwTab(e);
            }}
            tabs={[
                { name: "General", url: "&subtab=general" },
                { name: "Technical", url: "&subtab=technical" },
                {
                    name: "PV System Commissioning",
                    url: "&subtab=commissioning",
                },
                { name: "Roof Mounted PV Ready", url: "&subtab=roofpv" },
                { name: "Ground Mounted PV Ready", url: "&subtab=groundpv" },
                { name: "Verification Checklist", url: "&subtab=verification" },
                { name: "IAQ Checklist", url: "&subtab=indoorairquality" },
            ]}
            className={classes.tabs}
        >
            <div className={`${sharedClasses.section} ${classes.review}`}>
                <General accessor={`modelData.nzWorkbook.general`} change={change} />
            </div>
            <div className={`${sharedClasses.section} ${classes.review}`}>
                <Technical accessor={`modelData.nzWorkbook.technical`} change={change} />
            </div>
            <div className={`${sharedClasses.section} ${classes.review}`}>
                <PVSystemCommissioning
                    accessor={`modelData.nzWorkbook.pvSystemCommissioning`}
                    change={change}
                    declarationAddress={declarationAddress}
                />
            </div>
            <div className={`${sharedClasses.section} ${classes.review}`}>
                <RoofMountedPVReady
                    accessor={`modelData.nzWorkbook.roofMountedPV`}
                    change={change}
                    declarationAddress={declarationAddress}
                />
            </div>
            <div className={`${sharedClasses.section} ${classes.review}`}>
                <GroundMountedPVReady
                    accessor={`modelData.nzWorkbook.groundMountedPV`}
                    change={change}
                    declarationAddress={declarationAddress}
                />
            </div>
            <div className={`${sharedClasses.section} ${classes.export}`}>
                <VerificationChecklist accessor={`modelData.nzWorkbook.verificationChecklist`} change={change} />
            </div>
            <div className={`${sharedClasses.section} ${classes.review}`}>
                <IndoorAirQuality accessor={`modelData.nzWorkbook.indoorAirQuality`} change={change} />
            </div>
        </Subtabs>
    );
};

export default NetZeroWorkbook;
