import React from "react";
import classes from "./style.module.scss";
import classNames from "classnames";

export const ErrorBar = ({ className, message }) => {
    return (
        <div className={classNames(`${classes.bar} ${classes.errorBar}`, { [className]:className })}>
            <p>{message}</p>
        </div>
    )
}