import React, { useState } from 'react';
import classes from './style.module.scss';
import SectionHeading from 'components/SectionHeading';
import Tabs from "components/Tabs";
import { CloseDialog } from "components/Dialog";
import Card from 'components/Card';

import Basic from "./Basic/container";
import MiniAudit from "./MiniAudit/container";
import Options from "./Options";

export default ({
    loading,
    history
}) => {
    const [ intialPath, initialTab ] = window.location.pathname.split("create");
    const [tab, changeTab] = useState(initialTab);

    if (loading) {
        return "Creating Model!";
    }


    return (
        <div className={classes.create}>
            <SectionHeading
                className={classes.createHeading}
                title="Create New Model"
                subtitle="Provide some basic information about your model to get things started."
            />
            <CloseDialog
                closeActions={() => history.push("/dashboard")}
                className={classes.close}
            />
            <Card className={classes.card}>
                <Tabs
                    initialTab={initialTab || '/'}
                    tabActive={tab || '/'}
                    tabs={[
                        { "name": "Standard", url:"/", minWidth:"10rem", className:classes.tab },
                        { "name": "Preliminary Assessment", url:"/assessment", minWidth:"10rem", className:classes.tab }
                    ]}
                    onChange={(e) => {
                        history.push(`/create${e}`);
                        return changeTab(e);
                    }}
                >
                    <Basic history={history} />
                    <MiniAudit history={history} />
                </Tabs>
            </Card>
        </div>
    )
};