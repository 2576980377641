import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import Review from "./";
import { actions } from "features/Model/_ducks";
import { saveDrawingData } from "../DrawingNew/_ducks/thunk";

import { runH2kAzureSimulation } from "utils/results/api";

const { setExportErrors } = actions;

//Old
// import { actions as drawingActions } from "features/Model/Drawing/_ducks";
// const { saveDrawingData } = drawingActions;

const mapStateToProps = ({
    model: {
        modelId,
        modelDetails: { name: modelName },
        review: { exportErrors = [], location: errorLocation, proceedFunc: errorProceedFunc } = {},
    },
    upgrades: { packages = {} } = {},
    user: { nonErsUser = false, uid: userId, userPermissions: { CHBA_NZEA = false } = {} } = {},
    form,
}) => {
    const selector = formValueSelector("model");

    const components = selector({ form }, "modelData.components") || {};
    const heatingCooling = selector({ form }, "modelData.heatingCooling") || {};
    const domesticHotWater = selector({ form }, "modelData.domesticHotWater") || {};
    const generation = selector({ form }, "modelData.generation") || {};
    const ventilation = selector({ form }, "modelData.ventilation") || {};
    const temperatures = selector({ form }, "modelData.temperatures") || {};
    const codes = selector({ form }, "modelData.codes") || {};
    const { major: h2kMajor, minor: h2kMinor } = selector({ form }, "modelData.h2kVersion") || {};
    const heatingDegreeDays = selector({ form }, "modelData.location.weather.location.value");
    const windowWallRatio = selector({ form }, "modelData.dimensions.windowToWall.value");
    const programType = selector({ form }, `modelData.program.class`) || "";
    const { id: weatherProvTerrId } = selector({ form }, "modelData.location.weather.region") || {};
    const modelUnits = selector({ form }, `modelData.uiSettings.primaryUnits`) || {};

    return {
        modelId,
        components,
        heatingCooling,
        domesticHotWater,
        generation,
        ventilation,
        temperatures,
        codes,
        modelUnits,
        modelName,
        h2kVersion: `v${h2kMajor}-${h2kMinor}`,
        exportErrors,
        errorLocation,
        errorProceedFunc,
        heatingDegreeDays,
        windowWallRatio,
        programType,
        nonErsUser,
        weatherProvTerrId,
        packages,
        CHBA_NZEA,
        runH2kAzureSimulation,
        userId,
    };
};

const mapDispatchToProps = (dispatch) => ({
    setExportErrors: (errors, location, proceedFunc) => dispatch(setExportErrors(errors, location, proceedFunc)),
    saveDrawingData: (data) => dispatch(saveDrawingData(data)),
});

const mergeProps = (state, dispatch, own) => {
    const { saveDrawingData, setExportErrors } = dispatch;

    const { components: modelComponents, modelId } = state;

    return {
        ...state,
        ...dispatch,
        ...own,
        saveDrawingData: () => saveDrawingData(modelId),
        setExportErrors: (errors, location, proceedFunc) => setExportErrors(errors, location, proceedFunc),
    };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Review);
