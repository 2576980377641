import { connect } from 'react-redux';
import Canvas from './';
import { actions } from 'features/Model/Drawing/_ducks';

const { setActiveTool } = actions;

const mapStateToProps = ({
    drawing:{
        tools:{
            active:activeTool='',
            disabled:disabledTools=[]
        }={}
    }
}) => {
    return {
        disabledTools,
        activeTool
    }
};

const mapDispatchToProps = dispatch => ({
    setActiveTool:(tool) => dispatch(setActiveTool({activeTool:tool})),
});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Canvas);