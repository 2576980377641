// Templates
import applyHocTemplate from "features/Model/Review/Templates/applyHoc.json";
import atypicalLoadsTemplate from "features/Model/Review/Templates/atypicalLoads.json";
import waterConservationTemplate from "features/Model/Review/Templates/waterConservation.json";
import applyRocTemplate from "features/Model/Review/Templates/applyRoc.json";
import refHouseTemplate from "features/Model/Review/Templates/refHouse.json";
import greenerHomesTemplate from "features/Model/Review/Templates/greenerHomes.json";

export const getProgramTemplate = (id) =>
    ({
        householdOpConditions: applyHocTemplate,
        atypicalElecLoads: atypicalLoadsTemplate,
        waterConservation: waterConservationTemplate,
        reducedOperating: applyRocTemplate,
        refHouse: refHouseTemplate,
        greenerHomes: greenerHomesTemplate,
    }[id]);

export const programTypes = {
    "ca.nrcan.gc.OEE.ERS.ErsProgram": "ers",
    "ca.nrcan.gc.OEE.ERS2020NBC.ErsProgram": "ers2020Nbc",
    "ca.nrcan.gc.OEE.ONrh.OnProgram": "orh",
};

export const getProgramType = (programClass) => {
    let programType = "";
    if (Object.keys(programTypes).includes(programClass)) {
        programType = programTypes[programClass];
    }

    return programType;
};
