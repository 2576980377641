export const getNominalRValueUDef = (code) => {
    const {
        codeRef = "",
        layers: { continuousInsulation = {}, woodFraming = {}, steelFraming = {}, strapping = {} } = {},
    } = code;
    //continuousMedium and continuousInsulation do not contribute to nomRVal in h2k
    //However, this doesn't make sense to users, so we'll take the R-value from continuous insulation

    if (!codeRef.includes("-U-")) {
        return 0;
    }

    const nomRVal = Object.values({ ...woodFraming, ...steelFraming, ...strapping }).reduce((sumRVal, layer) => {
        const { cavityInsulation: { thickness = 0, resistivity = 0 } = {} } = layer;

        return sumRVal + thickness * resistivity;
    }, 0);

    const contInsNomRVal = Object.values(continuousInsulation).reduce((sumRVal, layer) => {
        const { thickness = 0, resistivity = 0 } = layer;
        return sumRVal + thickness * resistivity;
    }, 0);

    const totalNomRVal = nomRVal + contInsNomRVal;

    return parseFloat(totalNomRVal.toFixed(2));
};
