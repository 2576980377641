import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import Basement from './';
import { getBaseUnits, unitLabels, getOptions } from 'utils/fields';
import convertUnit from 'utils/convertUnit';

const mapStateToProps = (
    {
        form
    },
    {
        accessor
    }
) => {
    const selector = formValueSelector('model');
    const { codeLabel: intInsLabel = '' } = selector({form}, `${accessor}.wall.intAddedInsType`) || {};
    const intInsNomRVal = selector({form}, `${accessor}.wall.intAddedInsType_nomRVal`) || 0;
    const intInsEffRVal = selector({form}, `${accessor}.wall.intAddedInsType_effRVal`) || 0;
    
    let { 
        codeLabel: slabInsLabel = '', 
        value: slabInsValue = 0,
        id: slabInsId,
        codeRef: slabCodeRef = ''
    } = selector({form}, `${accessor}.floor.slabInsType`) || {};
    const slabInsNomRVal = selector({form}, `${accessor}.floor.slabInsType_nomRVal`) || 0;
    const slabInsEffRVal = selector({form}, `${accessor}.floor.slabInsType_effRVal`) || 0;

    const intRValUnits = selector({form}, `${accessor}.wall.intAddedInsType_u`);
    const slabRValUnits = selector({form}, `${accessor}.floor.slabInsType_u`);
    const modelUnits = selector({form}, `modelData.uiSettings.primaryUnits`);

    const { trueBase:intRValBase, displayBase:intRValDisplay } = getBaseUnits('basementIntAddedIns', modelUnits);
    const { trueBase:slabRValBase, displayBase:slabRValDisplay } = getBaseUnits('basementSlabInsType', modelUnits);

    const intRValU = intRValUnits || intRValDisplay || intRValBase;
    const slabRValU = slabRValUnits || slabRValDisplay || slabRValBase;

    if (!slabInsLabel) {
        slabInsLabel = getOptions({fieldKey: 'basementSlabInsType'}).reduce((acc, curr) => {
            if (curr.value.id === slabInsId) {
                return curr.label
            } else {
                return acc
            }
        }, '')
    }


    return {
        intInsLabel:intInsLabel || 'None',
        slabInsLabel:slabInsLabel || 'None',
        slabCodeRef: slabCodeRef || '',
        intRValue:`${parseFloat(convertUnit({
            value:intInsLabel === 'User Specified' ? intInsEffRVal : intInsNomRVal,
            type:'thermalResistance',
            inputUnit:intRValBase,
            outputUnit:intRValU,
        })).toFixed(1)}${intRValU}`,
        slabRValue:`${parseFloat(convertUnit({
            value:(slabCodeRef.includes('FloorsAdded') ? slabInsNomRVal : slabInsEffRVal || slabInsValue || 0),
            type:'thermalResistance',
            inputUnit:slabRValBase,
            outputUnit:slabRValU,
        })).toFixed(1)}${slabRValU}`,
    }
};

const mapDispatchToProps = () => ({});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Basement);