import { isBoolean } from "lodash";

export default (type) => {
    if (isBoolean(type)) {
        return "This parameter is not required by HOT2000, but is used to perform additional calculations that may provide additional results.";
    }

    let value = "";
    if (type.includes("-")) {
        [type, value] = type.split("-");
    }

    return {
        f280Weather: "This weather location selection is used to perform CSA F280-12 calculations.",
        f280IntWindowShading:
            "This parameter is used in CSA F280-12 heat gain calculations and is applied to all windows.",
        f280Default: "This parameter is used in CSA F280-12 calculations and does not impact HOT2000 results.",
        f280BedroomCount:
            "This parameter is used by CSA F280-12 calculations to determine the number of occupants (1 + bedroom count).",
        f280MinVentilationRate:
            "Unless otherwise specified in the Ventilation / Whole-House Systems section, this parameter is used as the principle ventilation capacity (PVC) for CSA F80-12 calculations.",
        f280MinVentilationRateNoWholeHouse:
            "No whole-house system is specified. CSA F280-12 calculations will not consider mechanical ventilation heat loss and gain.",
        f280MinVentilationRateUsePVC:
            "A whole-house system is specified and this value will be used as the principle ventilation capacity (PVC) for CSA F80-12 calculations to determine mechanical ventilation heat losses and gains. This is the recommended approach when accounting for mechanical ventilation.",
        f280MinVentilationRateUseOverride:
            "This value is being overridden by the whole house system ventilation rate, which will be used to determine CSA F280-12 mechanical ventilation heat losses and gains. This is not the recommended approach, and should only be used when accompanied by ventilation system design.",
        f280Helpful:
            "Defaults should be used for ERS compliance. However, modifying this parameter may produce more accurate CSA F280-12 results.",
        f280DuctLosses:
            "Use this field to define portions of the home served by ducts passing through unconditioned spaces or adjacent to the perimeter of slab-on-grade foundations for CSA F280-12 calculations.",
        f280HydronicLosses:
            "Use this field to define portions of the home served by heat distribution piping passing through unconditioned spaces or adjacent to the perimeter of slab-on-grade foundations for CSA F280-12 calculations.",
        default:
            "This parameter is not required by HOT2000, but is used to perform additional calculations that may provide additional results.",
    }[type || "default"];
};
