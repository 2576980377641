import React from "react";

export default () => (
    <svg
        width="29"
        height="22"
        viewBox="0 0 29 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.6039 8.10567L21.8444 7.21912L21.085 8.10567L15.585 14.5262L16.3444 16.1767L18.3442 16.1768V20.6275V21.6275H19.3442H24.3442H25.3442V20.6275V16.1767H27.3444L28.1039 14.5262L22.6039 8.10567ZM23.3449 15.1771V14.1767H25.171L21.8444 10.2934L18.5177 14.1768L20.3449 14.1768L20.3449 16.1768H20.3442V19.6275H23.3442V15.1771H23.3449Z"
            fill="#F0F8FD"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.8444 7.21912L15.585 14.5262L16.3444 16.1767L18.3444 16.1768V21.6271H25.3444V16.1767H27.3444L28.1039 14.5262L21.8444 7.21912ZM23.3449 14.1767H25.171L21.8444 10.2934L18.5177 14.1768L20.3449 14.1768L20.3444 19.6271H23.3444L23.3449 14.1767Z"
            fill="#F0F8FD"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.744 0L11.35 0.461747L19.6088 6.75413L16.9854 9.8166V7.26969L10.744 2.51436L4.50259 7.26969V19.5675H16.3439V21.5675H3.50259H2.50259V20.5675V8.7935L1.21209 9.77675L0 8.18589L10.1379 0.461747L10.744 0Z"
            fill="#F0F8FD"
        />
    </svg>
);
