import React from 'react';

export default () => (
    <svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="Top-Bar" transform="translate(-1235.000000, -17.000000)" fill="#E6772B">
            <g id="Group" transform="translate(1131.000000, 17.000000)">
                <g id="Group-2" transform="translate(104.000000, 0.000000)">
                    <path d="M13,0 C20.1797017,0 26,5.82029825 26,13 C26,20.1797017 20.1797017,26 13,26 C5.82029825,26 0,20.1797017 0,13 C0,5.82029825 5.82029825,0 13,0 Z M13,6 C10.7685586,6 8.953125,7.81543359 8.953125,10.046875 C8.953125,11.4290469 9.64981641,12.6513945 10.7102891,13.3817734 C9.72017188,13.7234336 8.8126875,14.2878359 8.05026172,15.0502344 C6.72813672,16.3723867 6,18.1302344 6,20 L7.09375,20 C7.09375,16.7432773 9.74327734,14.09375 13,14.09375 C16.2567227,14.09375 18.90625,16.7432773 18.90625,20 L20,20 C20,18.1302344 19.2718633,16.3723867 17.9497656,15.0502344 C17.1873398,14.2878359 16.2798555,13.7234336 15.2897383,13.3817734 C16.3501836,12.6513945 17.046875,11.4290469 17.046875,10.046875 C17.046875,7.81543359 15.2314414,6 13,6 Z M13,7.09375 C14.6283477,7.09375 15.953125,8.4185 15.953125,10.046875 C15.953125,11.67525 14.6283477,13 13,13 C11.3716523,13 10.046875,11.67525 10.046875,10.046875 C10.046875,8.4185 11.3716523,7.09375 13,7.09375 Z" id="Oval-Copy-5"></path>
                </g>
            </g>
        </g>
    </svg>
)