import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import isEmpty from 'lodash/isEmpty';
import Combo from './';

const mapStateToProps = (
    {
        form
    },
    {
        accessor,
        formName="model"
    }
) => {
    const selector = formValueSelector(formName);
    const modelSelector = formValueSelector('model');

    const modelUnits = modelSelector({form}, `modelData.uiSettings.primaryUnits`) || {};
    const isDwhr = !isEmpty(modelSelector({form}, `modelData.domesticHotWater.primarySystem.drainWaterHeatRecovery`) || {});

    const { id: energySourceId } = selector({form}, `${accessor}.system.combo.energySource`) || {};
    const { id: equipTypeId } = selector({form}, `${accessor}.system.combo.equipmentType`) || {};
    const { id: capacityId, value: capacityValue } = selector({form}, `${accessor}.system.combo.specifications.outputCapacity`) || {};
    const effValue = selector({form}, `${accessor}.system.combo.specifications.efficiency`);
    const pilotLightValue = selector({form}, `${accessor}.system.combo.specifications.pilotLight`);
    const flueDiameterValue = selector({form}, `${accessor}.system.combo.specifications.flueDiameter`);
    const isEfDefaults = selector({form}, `${accessor}.system.combo.tankAndPump.energyFactor.useDefaults`);
    const efValue = selector({form}, `${accessor}.system.combo.tankAndPump.energyFactor.value`);

    const { id: tankVolumeId, value: tankVolumeValue } = selector({form}, `${accessor}.system.combo.tankAndPump.tankCapacity`) || {};
    const isCircPumpCalculated = selector({form}, `${accessor}.system.combo.tankAndPump.circulationPump.isCalculated`) || {};

    const capacityUnits = selector({form}, `${accessor}.system.combo.specifications.outputCapacity_u`);
    const pilotUnits = selector({form}, `${accessor}.system.combo.specifications.pilotLight_u`);
    const flueUnits = selector({form}, `${accessor}.system.combo.specifications.flueDiameter_u`);
    const tankVolumeUnits = selector({form}, `${accessor}.system.combo.tankAndPump.tankCapacity_u`);
    const circPumpPowerUnits = selector({form}, `${accessor}.system.combo.tankAndPump.circulationPump_u`);

    return {
        accessor,
        isDwhr,
        energySourceId,
        equipTypeId,
        effValue,
        capacityId,
        capacityValue,
        capacityUnits,
        pilotLightValue,
        flueDiameterValue,
        tankVolumeId,
        tankVolumeValue,
        isEfDefaults,
        efValue,
        isCircPumpCalculated,
        pilotUnits,
        flueUnits,
        tankVolumeUnits,
        circPumpPowerUnits,
        modelUnits
    }
};

const mapDispatchToProps = () => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Combo);