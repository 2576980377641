const GET_CHBANZ_CHECKLIST_START = "GET_CHBANZ_CHECKLIST_START";
const GET_CHBANZ_CHECKLIST_SUCCESS = "GET_CHBANZ_CHECKLIST_SUCCESS";
const GET_CHBANZ_CHECKLIST_ERROR = "GET_CHBANZ_CHECKLIST_ERROR";
const SAVE_CHECKLIST_ITEMS = "SAVE_CHECKLIST_ITEMS";
const UPDATE_CHECKLIST_ITEMS = "UPDATE_CHECKLIST_ITEMS";
const SAVE_CHECKLIST_SUCCESS = "SAVE_CHECKLIST_SUCCESS";

export default {
    SAVE_CHECKLIST_ITEMS,
    UPDATE_CHECKLIST_ITEMS,
    GET_CHBANZ_CHECKLIST_START,
    GET_CHBANZ_CHECKLIST_SUCCESS,
    GET_CHBANZ_CHECKLIST_ERROR,
    SAVE_CHECKLIST_SUCCESS,
};
