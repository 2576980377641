import React, { useState } from "react";
import { foundationSubComponentsFactory } from "./utils/FoundationSubcomponentsFactory";
import { getFValidations } from "./utils/foundValidations";

const withTakeoffFoundation = (Component) => (props) => {
    const { takeoffState, dispatch, translateComponent, modelUnits, openWindowsDrawer } = props;
    const [errors, setErrors] = useState({});

    const changeFoundationValue = (origin, value, unit = "") => {
        dispatch({
            type: "UPDATE_TAKEOFF_COMPONENT",
            data: {
                origin: origin,
                value: value,
                unit: unit,
            },
        });
    };

    const inputValidation = (input, origin) => {
        getFValidations(input, origin);
    };

    const handleError = (origin, error) => {
        const { err } = error;

        if (err) {
            let newErrors = errors;
            errors[origin] = true;
            setErrors(newErrors);
        } else if (!err) {
            if (errors[origin]) {
                let newErrors = errors;
                delete errors[origin];
                setErrors(newErrors);
            }
        }
    };

    const removeSubComponent = (id) => {
        dispatch({
            type: "REMOVE_TAKEOFF_SUBCOMP",
            data: {
                parent: "foundation",
                id: id,
            },
        });

        if (errors[id]) {
            let newErrors = errors;
            delete errors[id];
            setErrors(newErrors);
        }
    };

    const duplicateSubComponent = (id) => {
        dispatch({
            type: "DUPLICATE_TAKEOFF_SUBCOMP",
            data: {
                parent: "foundation",
                id: id,
            },
        });
    };

    const addSubComponent = (type) => {
        dispatch({
            type: "ADD_TAKEOFF_SUBCOMP",
            data: {
                parent: "foundation",
                component: foundationSubComponentsFactory(type),
            },
        });
    };

    const editSubComponent = (id, field, value, unit = "") => {
        dispatch({
            type: "EDIT_TAKEOFF_SUBCOMP",
            data: {
                parent: "foundation",
                id: id,
                field: field,
                value: value,
                unit: unit,
            },
        });
    };

    const addTableComponent = (component) => {
        dispatch({
            type: "ADD_TOFF_TABLE_COMP",
            data: {
                parent: "foundation",
                component: { ...component },
            },
        });
    };

    const removeTableComponent = (id) => {
        dispatch({
            type: "REMOVE_TOFF_TABLE_COMP",
            data: {
                parent: "foundation",
                id: id,
            },
        });
    };

    const editTableComponent = (id, field, value, unit = "") => {
        dispatch({
            type: "EDIT_TOFF_TABLE_COMP",
            data: {
                parent: "foundation",
                id: id,
                field: field,
                value: value,
                unit: unit,
            },
        });
    };

    const takeoffFoundationActions = {
        takeoffState,
        dispatch,
        changeFoundationValue,
        getFValidations,
        inputValidation,
        handleError,
        removeSubComponent,
        addSubComponent,
        editSubComponent,
        duplicateSubComponent,
        addTableComponent,
        removeTableComponent,
        editTableComponent,
        translateComponent,
        errors,
        modelUnits,
        openWindowsDrawer,
    };

    return <Component {...takeoffFoundationActions} />;
};

export default withTakeoffFoundation;
