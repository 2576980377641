export const getNetTotalEnergyConsumption = (results = {}) => {
    //a particular results case is already assumed to have been selected
    const {
        Annual: {
            Consumption: {
                _attributes: { total: totalEnergyConsumption = 0 } = {},
                Electrical: {
                    _attributes: { baseload = 0, spaceHeating = 0, spaceCooling = 0, ventilation = 0 } = {},
                    HotWater: { _attributes: { dhw: elecDhw = 0 } = {} } = {},
                } = {},
                NaturalGas: { _attributes: { total: totalNaturalGas = 0 } = {} } = {},
                Oil: { _attributes: { total: totalOil = 0 } = {} } = {},
                Propane: { _attributes: { total: totalPropane = 0 } = {} } = {},
                Wood: { _attributes: { total: totalWood = 0 } = {} } = {},
                SpaceHeating: { _attributes: { total: totalSpaceHeating = 0 } = {} } = {},
                HotWater: { _attributes: { total: totalHotWater = 0 } = {} } = {},
            } = {},
        } = {},
        Monthly: { Load: { PhotoVoltaicAvailable: { _attributes: monthlyPv = {} } = {} } = {} } = {},
    } = results;

    const electricityConsumption = baseload + spaceHeating + spaceCooling + ventilation + elecDhw;

    const grossEnergyConsumption = electricityConsumption + totalNaturalGas + totalOil + totalPropane + totalWood;
    const aec = totalSpaceHeating + totalHotWater + baseload + spaceCooling + ventilation;
    const netEnergyConsumption = aec - Object.values(monthlyPv).reduce((sum, val) => sum + val, 0);

    return {
        zeroFloorTotal: Math.max(0, netEnergyConsumption),
        annualEnergyConsumption: aec,
        net: netEnergyConsumption,
        gross: grossEnergyConsumption,
        grossElectricity: electricityConsumption,
        grossNaturalGas: totalNaturalGas,
        grossPropane: totalPropane,
        grossOil: totalOil,
        grossWood: totalWood,
        elecNoBaseloads: electricityConsumption - baseload,
        pvProduction: Object.values(monthlyPv).reduce((sum, val) => sum + val, 0),
        aecNbc936: totalSpaceHeating + totalHotWater + spaceCooling + ventilation, //Annual energy consumption as viewed by the code
    };
};
