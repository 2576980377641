import React from 'react';

export default () => (
    <svg width="29px" height="22px" viewBox="0 0 29 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="Side-Bar-/-Open" transform="translate(-29.000000, -177.000000)" fill="#F0F8FD" fillRule="nonzero">
            <g id="Main-Side-Bar" transform="translate(-1.000000, 0.000000)">
                <g id="Nav-Items" transform="translate(0.000000, 86.000000)">
                    <g id="Group-Copy" transform="translate(30.000000, 90.000000)">
                        <path d="M11.137931,0.993838716 L21.8819053,9.17972386 L20.6698189,10.7705873 L19.3789568,9.78683872 L19.3793103,22.5613625 L2.89655172,22.5613625 L2.89595678,9.78683872 L1.60604322,10.7705873 L0.393956785,9.17972386 L11.137931,0.993838716 Z M17.8965514,1 L28.6308306,9.22130321 L27.459915,10.7513391 L26.1034483,9.72835652 L26.1034483,22.5268797 L21.2758621,22.5268797 L21.2758621,12.6024421 L24.2968534,8.63615421 L16.1031052,2.39779878 L17.8965514,1 Z M11.137,3.508 L4.89595678,8.26283872 L4.896,20.561 L17.379,20.561 L17.3789568,8.26283872 L11.137,3.508 Z" id="Path"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)