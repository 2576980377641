import React from 'react';
import classes from './style.module.scss';
import { Field } from 'redux-form';
import InputRow from 'components/Input/Row';
import Input from 'components/Input';
import { getValidation } from 'utils/fieldValidation';
import { getOptions } from 'utils/fields';
import Checkbox from 'components/Input/Checkbox';

const firstNameValidation = getValidation('clientFirstName');
const lastNameValidation = getValidation('clientLastName');
const phoneValidation = getValidation('clientTelephone');
const addressValidation = getValidation('clientStreetAddress');
const unitNumValidation = getValidation('clientUnitNumber');
const cityValidation = getValidation('clientCity');
const provinceValidation = getValidation('clientProvince');
const postalCodeValidation = getValidation('clientPostalCode');
const mailNameValidation = getValidation('clientMailName');
const mailAddressValidation = getValidation('clientMailStreetAddress');
const mailUnitValidation = getValidation('clientMailUnitNumber');
const mailCityValidation = getValidation('clientMailCity');
const mailProvValidation = getValidation('clientMailProvince');
const mailPostalCodeValidation = getValidation('clientMailPostalCode');

//TODO: Check %'s on third and fourth input row
export default React.memo(({
    accessor,
    selectedProvTerrId,
    h2kVersion,
    isSameMailingAddress,
    isDiffMailingAddress,
    change
}) => {

    const provinceOpts = getOptions({fieldKey:'weatherRegion', version: h2kVersion})
    const selectedProvTerr = provinceOpts.find(el => el.value.id === selectedProvTerrId) || {}

    return (
        <>
            <InputRow gridTemplate="1fr 1fr 1fr">
                <Field
                    component={Input}
                    type="text"
                    name={`${accessor}.clientFirstName`}
                    label="First Name"
                    placeholder=""
                    validate={firstNameValidation}
                    change={change}
                />
                <Field
                    component={Input}
                    type="text"
                    name={`${accessor}.clientLastName`}
                    label="Last Name"
                    placeholder=""
                    validate={lastNameValidation}
                    change={change}
                />
                <Field
                    component={Input}
                    type="text"
                    name={`${accessor}.clientTelephone`}
                    label="Telephone"
                    placeholder=""
                    validate={phoneValidation}
                    change={change}
                />
            </InputRow>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr 1fr">
                <Field
                    component={Input}
                    type="text"
                    name={`${accessor}.clientStreetAddress`}
                    label="Street Address"
                    placeholder=""
                    validate={addressValidation}
                    change={change}
                />
                <Field
                    component={Input}
                    type="text"
                    name={`${accessor}.clientUnitNumber`}
                    label="Unit Number"
                    placeholder=""
                    validate={unitNumValidation}
                    change={change}
                    info={'Do not enter a hyphen to indicate a range of units. For example, when modelling a MURB, enter in the format "1 to 21", or "2 to 20, even".'}
                />
                <Field
                    component={Input}
                    type="text"
                    name={`${accessor}.clientCity`}
                    label="City"
                    placeholder=""
                    validate={cityValidation}
                    change={change}
                />
                <Field
                    component={Input}
                    type="text"
                    name={`${accessor}.clientProvince`}
                    setValue={selectedProvTerr.label || 'Select Weather Region'}
                    label="Province or Territory"
                    placeholder="Select Province or Territory"
                    validate={provinceValidation}
                    change={change}
                    disabled={true}
                />
                <Field
                    component={Input}
                    type="text"
                    name={`${accessor}.clientPostalCode`}
                    label="Postal Code"
                    placeholder=""
                    validate={postalCodeValidation}
                    change={change}
                />
            </InputRow>
            <InputRow>
                <Field
                    component={Checkbox}
                    name={`${accessor}.differentMailingInfo`}
                    label="Mailing address different from above"
                    setValue={true}
                    large
                    className={classes.checkbox}
                    type="checkbox"
                    onChange={() => {
                        change(`${accessor}.sameMailingInfo`, isDiffMailingAddress)
                    }}
                />
            </InputRow>
            <>
                {
                isDiffMailingAddress ?
                    <>
                        <h5>Client Mailing Information</h5>
                        <InputRow gridTemplate="1fr 1fr 1fr">
                            <Field
                                component={Input}
                                type="text"
                                name={`${accessor}.clientMailName`}
                                label="Name"
                                placeholder=""
                                validate={mailNameValidation}
                                change={change}
                            />
                            <Field
                                component={Input}
                                type="text"
                                name={`${accessor}.clientMailStreetAddress`}
                                label="Street Address"
                                placeholder=""
                                validate={mailAddressValidation}
                                change={change}
                            />
                            <Field
                                component={Input}
                                type="text"
                                name={`${accessor}.clientMailUnitNumber`}
                                label="Unit Number"
                                placeholder=""
                                validate={mailUnitValidation}
                                change={change}
                                info={'Do not enter a hyphen to indicate a range of units. For example, when modelling a MURB, enter in the format "1 to 21", or "2 to 20, even".'}
                            />
                        </InputRow>
                        <InputRow gridTemplate="1fr 1fr 1fr">
                            <Field
                                component={Input}
                                type="text"
                                name={`${accessor}.clientMailCity`}
                                label="City"
                                placeholder=""
                                validate={mailCityValidation}
                                change={change}
                            />
                            <Field
                                component={Input}
                                type="text"
                                name={`${accessor}.clientMailProvince`}
                                label="Province or Territory"
                                placeholder=""
                                validate={mailProvValidation}
                                change={change}
                            />
                            <Field
                                component={Input}
                                type="text"
                                name={`${accessor}.clientMailPostalCode`}
                                label="Postal Code"
                                placeholder=""
                                validate={mailPostalCodeValidation}
                                change={change}
                            />
                        </InputRow> 
                    </> : <></>
                }
            </>
        </>
    );
})