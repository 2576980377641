import React, { useState } from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { isEmpty } from "lodash";
import { Table, TableHead, TableBody, TableRow, TableCell } from "@material-ui/core";

import { actions } from "features/Model/_ducks";

import Dialog, { CloseDialog } from "components/Dialog";
import Compass from "components/Compass";
import Select from "components/Input/Select";
import Button from "components/Button";

import { getWindowResults } from "utils/dcfExport/doorWindowData";
import { getOptions } from "utils/fields";

import classes from "./styles.module.scss";

const { rotateHome } = actions;

const RotateHouseDialog = ({
    isDialogOpen,
    closeDialog,
    facingDirection,
    components,
    modelUnits,
    codes,
    rotateHome,
    formModelData,
}) => {
    const { id: currentDirection } = facingDirection;

    const currentDirectionValue = getOptions({
        fieldKey: "facingDirection",
    })?.find((direction) => direction.value.id === currentDirection).value;

    const [newDirection, setNewDirection] = useState(currentDirectionValue);

    const dirOrder = ["South", "Southeast", "East", "Northeast", "North", "Northwest", "West", "Southwest"];

    const directionId = {
        South: 0,
        Southeast: 1,
        East: 2,
        Northeast: 3,
        North: 4,
        Northwest: 5,
        West: 6,
        Southwest: 7,
    };

    let windowInd = 0;

    let { windowResults = [] } = ["wall", "ceiling", "basement", "crawlspace"].reduce(
        (rootCache, parentCompType) => {
            const { [parentCompType]: parentComps = {} } = components;

            if (isEmpty(parentComps)) {
                return rootCache;
            }

            const { windowResults = [] } = Object.keys(parentComps).reduce(
                (parentCache, parentKey) => {
                    const { label: parentLabel = "", subcomponents: { window = {}, door = {} } = {} } =
                        parentComps[parentKey];

                    const windowResults = Object.keys(window).map((windowKey) => {
                        windowInd += 1;
                        return {
                            ...getWindowResults(window[windowKey], codes, modelUnits),
                            id: windowInd,
                            componentId: windowKey,
                            location: parentLabel,
                        };
                    });

                    return {
                        windowResults: [
                            ...(parentCache?.windowResults || []),
                            ...windowResults.sort(({ orientation: dirA }, { orientation: dirB }) => {
                                return dirOrder.indexOf(dirA) - dirOrder.indexOf(dirB);
                            }),
                        ],
                    };
                },
                { windowResults: [] }
            );

            return {
                windowResults: [...(rootCache.windowResults || []), ...windowResults],
            };
        },
        { windowResults: [] }
    );

    let north = [];
    let northWest = [];
    let west = [];
    let east = [];
    let southWest = [];
    let south = [];
    let southEast = [];
    let northEast = [];

    windowResults.reduce((_, currentW) => {
        if (!isDialogOpen) return;

        if (currentW.orientation.toLowerCase() === "north") north = [...north, currentW];

        if (currentW.orientation.toLowerCase() === "northwest") northWest = [...northWest, currentW];

        if (currentW.orientation.toLowerCase() === "west") west = [...west, currentW];

        if (currentW.orientation.toLowerCase() === "east") east = [...east, currentW];

        if (currentW.orientation.toLowerCase() === "southwest") southWest = [...southWest, currentW];

        if (currentW.orientation.toLowerCase() === "south") south = [...south, currentW];

        if (currentW.orientation.toLowerCase() === "southeast") southEast = [...southEast, currentW];

        if (currentW.orientation.toLowerCase() === "northeast") northEast = [...northEast, currentW];
    }, windowResults[0]);

    let newNorth = [];
    let newNorthWest = [];
    let newWest = [];
    let newEast = [];
    let newSouthWest = [];
    let newSouth = [];
    let newSouthEast = [];
    let newNorthEast = [];

    windowResults.reduce((_, currentW) => {
        if (newDirection.id === currentDirectionValue.id) return;

        let newIndex = 0;

        const currentWindowDirectionId = directionId[currentW.orientation];

        const moveIndex = currentDirectionValue.id - newDirection.id;

        if (currentDirectionValue.id > newDirection.id) {
            newIndex = currentWindowDirectionId - Math.abs(moveIndex);

            if (newIndex < 0) {
                newIndex = 8 - Math.abs(newIndex);
            }
        }

        if (currentDirectionValue.id < newDirection.id) {
            newIndex = currentWindowDirectionId + Math.abs(moveIndex);

            if (newIndex > 7) {
                newIndex = currentWindowDirectionId + Math.abs(moveIndex) - 8;
            }
        }

        currentW.orientation = getOptions({
            fieldKey: "facingDirection",
        })?.find((direction) => direction.value.id === newIndex)?.label;

        currentW.newDirectionId = newIndex;

        if (currentW.orientation.toLowerCase() === "north") newNorth = [...newNorth, currentW];

        if (currentW.orientation.toLowerCase() === "northwest") newNorthWest = [...newNorthWest, currentW];

        if (currentW.orientation.toLowerCase() === "west") newWest = [...newWest, currentW];

        if (currentW.orientation.toLowerCase() === "east") newEast = [...newEast, currentW];

        if (currentW.orientation.toLowerCase() === "southwest") newSouthWest = [...newSouthWest, currentW];

        if (currentW.orientation.toLowerCase() === "south") newSouth = [...newSouth, currentW];

        if (currentW.orientation.toLowerCase() === "southeast") newSouthEast = [...newSouthEast, currentW];

        if (currentW.orientation.toLowerCase() === "northeast") newNorthEast = [...newNorthEast, currentW];
    }, windowResults[0]);

    return (
        <Dialog
            open={isDialogOpen}
            style={{ padding: "0" }}
            classes={{ paperClass: classes.dialogContainer }}
            onClose={closeDialog}
        >
            <CloseDialog closeActions={() => closeDialog()} />
            <div className={classes.rotateHouseDialogContainer}>
                <h2 className={classes.rotateHouseTitle}>Rotate Home</h2>
                <div className={classes.orientationWrapper}>
                    <div className={classes.orientation}>
                        <Compass
                            facingId={currentDirection}
                            rotationOptions={{
                                0: 180,
                                1: 135,
                                2: 90,
                                3: 45,
                                4: 0,
                                5: -45,
                                6: -90,
                                7: -135,
                            }}
                        />
                        <Select
                            className={classes.orientationSelect}
                            label="Current Facing Direction"
                            // placeholder="Choose Facing Direction"
                            disabled={true}
                            options={getOptions({
                                fieldKey: "facingDirection",
                            })}
                            input={{
                                value: getOptions({
                                    fieldKey: "facingDirection",
                                })?.find((direction) => direction.value.id === currentDirection).value,
                            }}
                        />
                    </div>
                    <div className={classes.orientation}>
                        <Compass
                            facingId={newDirection.id}
                            rotationOptions={{
                                0: 180,
                                1: 135,
                                2: 90,
                                3: 45,
                                4: 0,
                                5: -45,
                                6: -90,
                                7: -135,
                            }}
                        />
                        <Select
                            className={classes.orientationSelect}
                            label="New Facing Direction"
                            placeholder="Choose New Direction"
                            options={getOptions({
                                fieldKey: "facingDirection",
                            })}
                            input={{
                                value: newDirection,
                                onChange: (val) => setNewDirection(val),
                            }}
                        />
                    </div>
                </div>
                <div className={classes.divider}></div>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Side</TableCell>
                            <TableCell
                                align="right"
                                style={{
                                    paddingRight: "1.25rem",
                                    paddingBottom: "0.5rem",
                                    paddingTop: "0.5rem",
                                    borderLeft: "none",
                                }}
                            >
                                Current Area
                            </TableCell>
                            <TableCell
                                align="right"
                                style={{
                                    paddingRight: "1.25rem",
                                    paddingBottom: "0.5rem",
                                    paddingTop: "0.5rem",
                                    borderLeft: "none",
                                }}
                            >
                                New Area
                            </TableCell>
                            <TableCell
                                align="right"
                                style={{
                                    paddingRight: "1.25rem",
                                    paddingBottom: "0.5rem",
                                    paddingTop: "0.5rem",
                                    borderLeft: "none",
                                }}
                            >
                                Windows
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>South</TableCell>
                            <TableCell align="right">
                                {south.reduce((acc, { area, numIdentical }) => acc + area * numIdentical, 0).toFixed(2)}
                            </TableCell>
                            <TableCell align="right">
                                {" "}
                                {newSouth
                                    .reduce((acc, { area, numIdentical }) => acc + area * numIdentical, 0)
                                    .toFixed(2)}
                            </TableCell>
                            <TableCell align="right">
                                {newDirection.id === currentDirectionValue.id
                                    ? south.reduce((acc, { numIdentical = 1 }) => acc + numIdentical, 0)
                                    : newSouth.reduce((acc, { numIdentical = 1 }) => acc + numIdentical, 0)}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>South-West</TableCell>
                            <TableCell align="right">
                                {southWest
                                    .reduce((acc, { area, numIdentical }) => acc + area * numIdentical, 0)
                                    .toFixed(2)}
                            </TableCell>
                            <TableCell align="right">
                                {" "}
                                {newSouthWest
                                    .reduce((acc, { area, numIdentical }) => acc + area * numIdentical, 0)
                                    .toFixed(2)}
                            </TableCell>
                            <TableCell align="right">
                                {newDirection.id === currentDirectionValue.id
                                    ? southWest.reduce((acc, { numIdentical = 1 }) => acc + numIdentical, 0)
                                    : newSouthWest.reduce((acc, { numIdentical = 1 }) => acc + numIdentical, 0)}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>West</TableCell>
                            <TableCell align="right">
                                {west.reduce((acc, { area, numIdentical }) => acc + area * numIdentical, 0).toFixed(2)}
                            </TableCell>
                            <TableCell align="right">
                                {" "}
                                {newWest
                                    .reduce((acc, { area, numIdentical }) => acc + area * numIdentical, 0)
                                    .toFixed(2)}
                            </TableCell>
                            <TableCell align="right">
                                {" "}
                                {newDirection.id === currentDirectionValue.id
                                    ? west.reduce((acc, { numIdentical = 1 }) => acc + numIdentical, 0)
                                    : newWest.reduce((acc, { numIdentical = 1 }) => acc + numIdentical, 0)}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>North-West</TableCell>
                            <TableCell align="right">
                                {northWest
                                    .reduce((acc, { area, numIdentical }) => acc + area * numIdentical, 0)
                                    .toFixed(2)}
                            </TableCell>
                            <TableCell align="right">
                                {" "}
                                {newNorthWest
                                    .reduce((acc, { area, numIdentical }) => acc + area * numIdentical, 0)
                                    .toFixed(2)}
                            </TableCell>
                            <TableCell align="right">
                                {newDirection.id === currentDirectionValue.id
                                    ? northWest.reduce((acc, { numIdentical = 1 }) => acc + numIdentical, 0)
                                    : newNorthWest.reduce((acc, { numIdentical = 1 }) => acc + numIdentical, 0)}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>North</TableCell>
                            <TableCell align="right">
                                {north.reduce((acc, { area, numIdentical }) => acc + area * numIdentical, 0).toFixed(2)}
                            </TableCell>
                            <TableCell align="right">
                                {" "}
                                {newNorth
                                    .reduce((acc, { area, numIdentical }) => acc + area * numIdentical, 0)
                                    .toFixed(2)}
                            </TableCell>
                            <TableCell align="right">
                                {" "}
                                {newDirection.id === currentDirectionValue.id
                                    ? north.reduce((acc, { numIdentical = 1 }) => acc + numIdentical, 0)
                                    : newNorth.reduce((acc, { numIdentical = 1 }) => acc + numIdentical, 0)}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>North-East</TableCell>
                            <TableCell align="right">
                                {northEast
                                    .reduce((acc, { area, numIdentical }) => acc + area * numIdentical, 0)
                                    .toFixed(2)}
                            </TableCell>
                            <TableCell align="right">
                                {" "}
                                {newNorthEast
                                    .reduce((acc, { area, numIdentical }) => acc + area * numIdentical, 0)
                                    .toFixed(2)}
                            </TableCell>
                            <TableCell align="right">
                                {newDirection.id === currentDirectionValue.id
                                    ? northEast.reduce((acc, { numIdentical = 1 }) => acc + numIdentical, 0)
                                    : newNorthEast.reduce((acc, { numIdentical = 1 }) => acc + numIdentical, 0)}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>East</TableCell>
                            <TableCell align="right">
                                {east.reduce((acc, { area, numIdentical }) => acc + area * numIdentical, 0).toFixed(2)}
                            </TableCell>
                            <TableCell align="right">
                                {" "}
                                {newEast
                                    .reduce((acc, { area, numIdentical }) => acc + area * numIdentical, 0)
                                    .toFixed(2)}
                            </TableCell>
                            <TableCell align="right">
                                {" "}
                                {newDirection.id === currentDirectionValue.id
                                    ? east.reduce((acc, { numIdentical = 1 }) => acc + numIdentical, 0)
                                    : newEast.reduce((acc, { numIdentical = 1 }) => acc + numIdentical, 0)}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>South-East</TableCell>
                            <TableCell align="right">
                                {southEast
                                    .reduce((acc, { area, numIdentical }) => acc + area * numIdentical, 0)
                                    .toFixed(2)}
                            </TableCell>
                            <TableCell align="right">
                                {" "}
                                {newSouthEast
                                    .reduce((acc, { area, numIdentical }) => acc + area * numIdentical, 0)
                                    .toFixed(2)}
                            </TableCell>
                            <TableCell align="right">
                                {newDirection.id === currentDirectionValue.id
                                    ? southEast.reduce((acc, { numIdentical = 1 }) => acc + numIdentical, 0)
                                    : newSouthEast.reduce((acc, { numIdentical = 1 }) => acc + numIdentical, 0)}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <div className={classes.buttonsRow}>
                    <Button type="hollow" style={{ width: "144px" }} onClick={() => closeDialog()}>
                        Cancel
                    </Button>
                    <Button
                        type={"primary"}
                        disabled={newDirection.id === currentDirectionValue.id}
                        onClick={async () => {
                            await rotateHome(
                                [
                                    ...newNorth,
                                    ...newNorthWest,
                                    ...newWest,
                                    ...newEast,
                                    ...newSouthWest,
                                    ...newSouth,
                                    ...newSouthEast,
                                    ...newNorthEast,
                                ],
                                newDirection,
                                formModelData
                            );
                            closeDialog();
                        }}
                    >
                        Confirm Rotation
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

const mapStateToProps = ({
    form,
    // model: {
    //     modelData: {
    //         components,
    //         specifications: { facingDirection },
    //         primaryUnits,
    //         codes,
    //     },
    // },
}) => {
    const selector = formValueSelector("model");
    const formModelData = selector({ form }, "modelData") || {};

    //Have to grab this info from formModelData, because data grabbed from state will only be from the most recent save/init cycle
    const {
        components = {},
        codes = {},
        specifications: { facingDirection } = {},
        uiSettings: { primaryUnits: modelUnits = "Metric" } = {},
    } = formModelData || {};

    return { facingDirection, components, modelUnits, codes, formModelData };
};

const mapDispatchToProps = (dispatch) => ({
    rotateHome: (windows, newDirection, formModelData) => dispatch(rotateHome(windows, newDirection, formModelData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RotateHouseDialog);
