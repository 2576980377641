import React from 'react';

export default ({fill="#0066B1"}) => (
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Group">
                <circle id="Oval-Copy" stroke={fill} cx="8" cy="8" r="7.5" fill="transparent"></circle>
                <path d="M8.052,5.305 C8.26,5.305 8.4485,5.23133333 8.6175,5.084 C8.7865,4.93666667 8.871,4.75466667 8.871,4.538 C8.871,4.32133333 8.7865,4.13716667 8.6175,3.9855 C8.4485,3.83383333 8.26,3.758 8.052,3.758 C7.83533333,3.758 7.64033333,3.83383333 7.467,3.9855 C7.29366667,4.13716667 7.207,4.32133333 7.207,4.538 C7.207,4.75466667 7.29366667,4.93666667 7.467,5.084 C7.64033333,5.23133333 7.83533333,5.305 8.052,5.305 Z M8.728,12 L8.728,6.501 L7.272,6.501 L7.272,12 L8.728,12 Z" id="i" fill={fill} fillRule="nonzero"></path>
            </g>
        </g>
    </svg>
)