/*
Created new Drag And Drop component for file upload because previous works too close with Roadmap.
TODO: in future write general drag and drop component
 */

import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { v4 as uuidv4 } from "uuid";
import { isEmpty } from "lodash";
import { storageRef } from "_firebase";

import { selectUserUid } from "store/users/selectors";
import { selectModelId } from "features/Model/_ducks/selectors";

import { imageRead } from "features/Model/ImageGallery/utils/functions";

import { addImageData } from "store/imageGallery/thunk";

import UploadingFile from "features/Model/ImageGallery/components/FileDragAndDrop/UploadingFile";

import styles from "./styles.module.scss";

import UploadIcon from "assets/images/icons/uploadSvg.svg";

const FileDragAndDrop = ({ modelId, uid, addImageData, allImages }) => {
    const ref = useRef(null);

    const [isOverInput, setIsOverInput] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadingFiles, setUploadingFiles] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        if (!ref.current) return;

        ref.current.addEventListener("dragenter", onDragEnter);
        ref.current.addEventListener("dragleave", onDragLeave);

        return () => {
            ref.current.removeEventListener("dragenter", onDragEnter);
            ref.current.removeEventListener("dragleave", onDragLeave);
        };
    }, []);

    const onDragEnter = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (event.target !== ref.current) {
            setIsOverInput(true);
            setErrorMessage(null);
        }
    };

    const onDragLeave = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (event.target !== ref.current) {
            setIsOverInput(false);
        }
    };

    const handleFileUpload = async (event, isDropdown) => {
        event.preventDefault();
        event.stopPropagation();

        if (isUploading) return;

        document.body.style.cursor = "progress";

        setIsUploading(true);

        const files = Object.values(!isDropdown ? event.target.files : event.dataTransfer.files);

        const allFilesNames = allImages.map((img) => img.name);

        if (!files || files.length === 0) {
            setIsUploading(false);

            return;
        }

        if (files.some((file) => !["image/jpeg", "image/jpg", "image/png"].includes(file.type))) {
            setErrorMessage("Wrong file type.");
            setIsUploading(false);

            setTimeout(() => setErrorMessage(null), 3000);

            document.body.style.cursor = "default";
            return;
        }

        const updatedFiles = await Promise.all(
            files.map((file) =>
                imageRead(file).then(() => ({
                    id: uuidv4(),
                    name: allFilesNames.includes(file.name) ? `${file.name} (copy)` : file.name,
                    type: file.type,
                    progress: 0,
                    objectUrl: URL.createObjectURL(file),
                    fileToUpload: file,
                    isDeleting: false,
                    size: file.size,
                    metadata: !isEmpty(file.exifdata)
                        ? file.exifdata.DateTimeOriginal && file.exifdata.Orientation
                            ? {
                                  dateTimeOriginal: file.exifdata.DateTimeOriginal
                                      ? file.exifdata.DateTimeOriginal
                                      : null,
                                  orientation: file.exifdata.Orientation ? file.exifdata.Orientation : null,
                              }
                            : null
                        : null,
                }))
            )
        );

        const uploadPromises = [];

        setUploadingFiles(updatedFiles);

        // Checking size
        for (let i = 0; i < updatedFiles.length; i++) {
            const file = updatedFiles[i];

            if (file.size / 1024 > 1024) {
                setErrorMessage("Please upload file(s) smaller than 1MB.");
                setIsUploading(false);
                setUploadingFiles([]);

                setTimeout(() => setErrorMessage(null), 3000);

                document.body.style.cursor = "default";

                return;
            }
        }

        for (let i = 0; i < updatedFiles.length; i++) {
            const file = updatedFiles[i];

            // Create a storage reference
            const filePath = `${uid}/${modelId}/modelImages/${file.id}`;

            const uploadTask = storageRef.child(filePath).put(file.fileToUpload);

            uploadPromises.push(uploadTask);

            updatedFiles[i].cancel = () => {
                uploadTask.cancel();
                updatedFiles[i].isCancelled = true;

                const updatedNewFiles = updatedFiles.slice();

                setUploadingFiles(updatedNewFiles);
            };

            uploadTask.on(
                "state_changed",
                async (snapshot) => {
                    updatedFiles[i].progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);

                    const updatedNewFiles = updatedFiles.slice();

                    setUploadingFiles(updatedNewFiles);
                },
                null,
                () => {
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        updatedFiles[i].progress = 100;
                        updatedFiles[i].isUploading = false;
                        updatedFiles[i].fileUrl = downloadURL;
                        updatedFiles[i].fileToUpload = null;
                        updatedFiles[i].objectUrl = null;
                        updatedFiles[i].cancel = null;

                        updatedFiles[i].uploadedDate = new Date().getTime() / 1000;
                        updatedFiles[i].tags = [];
                        updatedFiles[i].notes = "";
                        updatedFiles[i].status = "";
                        updatedFiles[i].isFromCheckList = false;
                        updatedFiles[i].checkListPath = null;

                        addImageData(modelId, uid, updatedFiles[i]);
                    });
                }
            );
        }

        Promise.allSettled(uploadPromises).finally(() => {
            document.body.style.cursor = "default";

            setIsUploading(false);
            setUploadingFiles([]);
        });
    };

    return (
        <div>
            <div
                ref={ref}
                className={`${styles.dragAndDropInputContainer}`}
                style={{
                    border: `1px ${isOverInput ? "solid" : "dashed"} ${errorMessage ? "#c21818" : "#62bcf8"}`,
                    background: errorMessage ? "rgba(194, 24, 24, 0.1)" : "#fff",
                }}
                onDrop={(event) => handleFileUpload(event, true)}
            >
                <div className={styles.dragAndDropTextContainer}>
                    <div className={styles.textWithImageWrapper}>
                        {!errorMessage && <img src={UploadIcon} alt={"Upload icon"} />}
                        {errorMessage ? (
                            <span className={styles.mainText} style={{ color: errorMessage ? "#c21818" : "#3b3b3b" }}>
                                {errorMessage}
                            </span>
                        ) : (
                            <span className={styles.mainText}>
                                {" "}
                                Drag and drop or <span className={styles.blueText}>browse files</span>
                            </span>
                        )}
                    </div>
                    {!errorMessage && <span className={styles.subText}>(.jpg, .jpeg, .png)</span>}
                </div>
                <input
                    type={"file"}
                    accept="image/png, image/jpeg"
                    multiple
                    className={`${styles.dragAndDropInput} ${isUploading ? styles.uploadingFiles : ""}`}
                    disabled={isUploading}
                    onChange={(event) => handleFileUpload(event, false)}
                />
            </div>
            {uploadingFiles && (
                <div style={{ marginTop: "15px" }}>
                    {uploadingFiles.map(({ name, progress, cancel, id, isCancelled }, index) => (
                        <UploadingFile
                            key={id}
                            fileName={name}
                            progress={progress}
                            cancel={isCancelled || progress === 100 ? () => {} : cancel}
                            isCancelled={isCancelled}
                            isFirst={index === 0}
                            isComplete={!isCancelled && progress === 100}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    modelId: selectModelId,
    uid: selectUserUid,
});

const mapDispatchToProps = (dispatch) => ({
    addImageData: (modelId, uid, imageToAdd) => dispatch(addImageData(modelId, uid, imageToAdd)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FileDragAndDrop);
