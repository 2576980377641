import React from 'react';
import sharedClasses from '../../style.module.scss';
import Tooltip from 'components/Tooltip';

export default React.memo(({
    intInsLabel,
    slabInsLabel,
    slabCodeRef,
    intRValue,
    slabRValue,
}) => (
    <div className={sharedClasses.stats} style={{gridTemplateColumns:'1.5fr 1fr 1.5fr 1fr'}}>
        <span
            data-for="crawlspace-stats"
            data-tip={`<span><strong>Wall Insulation:</strong> ${intInsLabel}</span>`}
            data-html
            className={sharedClasses.clipType}
        >
            <strong>Wall:</strong> {intInsLabel}
        </span>
        <span
            data-for="crawlspace-stats"
            data-tip={`<span><strong>${intInsLabel === 'User Specified' ? 'Eff.' : 'Nom.'}R-Value:</strong> ${intRValue}</span>`}
            data-html
        >
            <strong>R:</strong> {intRValue}
        </span>
        <span
            data-for="crawlspace-stats"
            data-tip={`<span><strong>Slab Insulation:</strong> ${slabInsLabel}</span>`}
            data-html
            className={sharedClasses.clipType}
        >
            <strong>Slab:</strong> {slabInsLabel}
        </span>
        <span
            data-for="crawlspace-stats"
            data-tip={`<span><strong>${slabCodeRef.includes('FloorsAdded-') ? 'Nom.' : 'Eff.'} R-Value:</strong> ${slabRValue}</span>`}
            data-html
        >
            <strong>R:</strong> {slabRValue}
        </span>
        {/* <span><strong>A:</strong> {area}</span> */}
        <Tooltip id="crawlspace-stats" />
    </div>
));