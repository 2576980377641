import React, { useState } from "react";
import classes from "../style.module.scss";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import { Field } from "redux-form";
import Button from "components/Button";
import MultiSelect from "components/Input/MultiSelect";
import { required, maxLength } from "utils/fieldValidation";
import AddLayer from "../AddLayer";
import isEmpty from "lodash/isEmpty";
import EmptyState from "components/EmptyState";
import ContinuousMedium from "./ContinuousMedium/container";
import ContinuousInsulation from "./ContinuousInsulation/container";
import WoodFraming from "./WoodFraming/container";
import SteelFraming from "./SteelFraming/container";
import Strapping from "./Strapping/container";
import Checkbox from "components/Input/Checkbox";
import { Droppable, Draggable, DragDropContext } from "react-beautiful-dnd";
import Tooltip from "components/Tooltip";
import Info from "assets/images/icons/JSX/info";
import { getAllComponents, getAllSubcomponents } from "utils/enclosure/components";

const charLim32 = maxLength(32);

const labelValidation = [required, charLim32];
const descriptionValidation = [maxLength(100)];

const getLayerType = {
    continuousMedium: ContinuousMedium,
    continuousInsulation: ContinuousInsulation,
    woodFraming: WoodFraming,
    steelFraming: SteelFraming,
    strapping: Strapping,
};

const codeSort = (a, b) => {
    if (a.label < b.label) {
        return -1;
    }
    if (a.label > b.label) {
        return 1;
    }
    return 0;
};

const agCodeCompMap = {
    Wall: "wall",
    FloorHeader: "floorHeader",
    Floor: "expFloor",
    Ceiling: "ceiling",
    CeilingFlat: "ceiling-flat",
};

const agCodeCompLabelMap = {
    Wall: "wall",
    FloorHeader: "floor header",
    Floor: "exposed floor",
    Ceiling: "ceiling",
    CeilingFlat: "flat ceiling",
};

const bgCodeCompMap = {
    BasementWall: "basement",
    CrawlspaceWall: "crawlspace",
    Lintel: "wall",
    FloorsAbove: "basement",
    FloorsAdded: "slab",
};

const codeCompMap = {
    ...agCodeCompMap,
    ...bgCodeCompMap,
};

export default React.memo(
    ({
        change,
        componentType,
        codeLayers,
        numLayers,
        handleSubmit,
        submitting,
        invalid,
        isLibCode = false,
        handleClose,
        isCodeLibrary,
        isUpgradeComponent = false,
        fieldAccessor,
        components,
    }) => {
        const [dragActive, toggleDragActive] = useState(false);

        const onDragStart = () => {};
        const onDragEnd = (result) => {
            const { destination, source, draggableId } = result;
            const { index: destinationIndex } = destination;
            const { index: sourceIndex } = source;
            const newDraggableIndex = destinationIndex + 1;

            const [type, id] = draggableId.split(".");

            codeLayers.map((layer, index) => {
                const { id: layerId = "", type: layerType = "" } = layer;
                let layerIndex = index + 1;

                // Dragged layer
                if (id === layerId) {
                    change(`uDefCode.layers.${type}.${id}.layer`, newDraggableIndex);
                    return true;
                }

                // Layers with unchanged indexes: outside of the source/destination before and after dragging
                if (
                    (layerIndex <= sourceIndex && layerIndex < newDraggableIndex) ||
                    (layerIndex > sourceIndex && layerIndex > newDraggableIndex)
                ) {
                    change(`uDefCode.layers.${layerType}.${layerId}.layer`, layerIndex);
                    return true;
                }

                // Layers that were less than source, but greater than destination (ie. draggable was put in front)
                if (layerIndex <= sourceIndex && layerIndex >= newDraggableIndex) {
                    layerIndex = layerIndex + 1;
                }

                // Layers that were greater than source, but were less than destination (ie. draggable was put behind)
                if (layerIndex > sourceIndex && layerIndex <= newDraggableIndex) {
                    layerIndex = layerIndex - 1;
                }

                change(`uDefCode.layers.${layerType}.${layerId}.layer`, layerIndex);
            });
        };

        const compType = codeCompMap[componentType] || "wall";
        const availableComponents =
            compType === "floorHeader"
                ? getAllSubcomponents("floorHeader", components, fieldAccessor).sort(codeSort)
                : getAllComponents(compType, components, fieldAccessor).sort(codeSort);

        return (
            <div className={classes.uDefCodeWrapper}>
                <h3>User Defined Code</h3>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Input}
                        type="text"
                        name={`uDefCode.label`}
                        label="User Defined Code Label"
                        placeholder="Enter Code Label"
                        validate={labelValidation}
                    />
                    <Field
                        component={Input}
                        type="text"
                        name={`uDefCode.description`}
                        label="Description"
                        placeholder="Enter Code Description"
                        validate={descriptionValidation}
                    />
                </InputRow>
                <div className={`${classes.title} ${classes.layers}`}>
                    <h4>
                        Layers{""}
                        <div
                            className={classes.layerTooltip}
                            data-for="layersTooltip"
                            data-html
                            data-tip={`<span style="display:block;max-width:18rem;">It is best practice to arrange layers from exterior (lowest layer) to interior (highest layer)</span>`}
                        >
                            <Info />
                        </div>
                    </h4>
                    <Tooltip id="layersTooltip" />
                    <AddLayer
                        formName="uDefCode"
                        change={change}
                        numLayers={numLayers}
                        componentType={componentType}
                        options={[
                            {
                                value: "continuousMedium",
                                label: "Continuous Medium",
                            },
                            {
                                value: "continuousInsulation",
                                label: "Continuous Insulation",
                            },
                            {
                                value: "woodFraming",
                                label: "Wood Framing",
                            },
                            {
                                value: "steelFraming",
                                label: "Steel Framing",
                            },
                            {
                                value: "strapping",
                                label: "Strapping",
                            },
                        ]}
                    />
                </div>
                <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
                    <Droppable droppableId="standard-code" type="standard-code">
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                className={`${classes.droppableArea} ${
                                    snapshot.isDraggingOver ? classes.draggedOver : ""
                                }`}
                                {...provided.droppableProps}
                            >
                                {isEmpty(codeLayers) && <EmptyState title="No layers to display" style={{}} />}
                                {codeLayers.map((layer, ind) => {
                                    const { id, type, ...rest } = layer;
                                    const LayerType = getLayerType[type];

                                    return (
                                        <Draggable draggableId={`${type}.${id}`} index={ind} key={id}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    className={`${classes.draggableComponent} ${
                                                        snapshot.isDraggingOver ? classes.draggedOver : ""
                                                    }`}
                                                >
                                                    <LayerType
                                                        type={type}
                                                        key={ind}
                                                        id={id}
                                                        value={rest}
                                                        change={change}
                                                        isDragging={snapshot.isDragging}
                                                        dragHandleProps={provided.dragHandleProps}
                                                        dragActive={dragActive}
                                                        accessor={`uDefCode.layers.${type}.${id}`}
                                                        componentType={componentType}
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>

                <div style={{ borderBottom: "1px solid #e1eaf0", marginTop: "1.5rem", marginBottom: "1.5rem" }}></div>
                {!isCodeLibrary && Object.keys(agCodeCompMap).includes(componentType) && !isUpgradeComponent && (
                    <InputRow gridTemplate="1fr">
                        <Field
                            component={MultiSelect}
                            name={`selectedComponents`}
                            label={`Apply to multiple ${agCodeCompLabelMap[componentType]} components`}
                            options={availableComponents.map(({ disabled = false, label = "", ...rest }) => ({
                                ...rest,
                                disabled,
                                label: disabled ? `${label} (current component)` : label,
                            }))}
                            hasSelectAll
                        />
                    </InputRow>
                )}
                {!isCodeLibrary && (
                    <InputRow gridTemplate="1fr 1fr" style={{ marginTop: "1rem" }}>
                        <div data-tip="Code will automatically be updated in library" data-for="codeLibTip">
                            <Field
                                component={Checkbox}
                                name={"addToLibrary"}
                                label={isLibCode ? "Update code in library" : "Add to code library"}
                                large
                                disabled={isLibCode}
                                type="checkbox"
                            />
                        </div>
                    </InputRow>
                )}
                {!isCodeLibrary && (
                    <InputRow gridTemplate="1fr 1fr">
                        <Field
                            component={Checkbox}
                            name={"setAsModelDefault"}
                            label={"Set as model default"}
                            large
                            type="checkbox"
                        />
                    </InputRow>
                )}
                <div className={classes.buttons}>
                    <Button large type="hollow" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button large onClick={handleSubmit} disabled={submitting || invalid}>
                        {submitting ? "Saving Code..." : "Save Code"}
                    </Button>
                </div>
                <div style={{ paddingTop: "7.5rem" }}></div>
                <Tooltip id="codeLibTip" hide={!isLibCode} />
            </div>
        );
    }
);
