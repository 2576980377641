import React from "react";
import { Field } from "redux-form";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import SelectWithInput from "components/Input/SelectWithInput";
import Select from "components/Input/Select";
import { getOptions } from "utils/fields";
import InputWithUnits from "components/Input/InputWithUnits";
import { getValidation, getDecimalPlaces } from "utils/fieldValidation";
import { getBaseUnits, getUnitOptions } from "utils/fields";

const lengthValidation = getValidation("ceilingLength");
const areaValidation = getValidation("ceilingArea");
const slopeValidation = getValidation("ceilingSlope");
const heelHeightValidation = getValidation("ceilingHeelHeight");

export default React.memo(
    ({
        accessor,
        modelUnits,
        lengthUnits,
        areaUnits,
        heelHeightUnits,
        change,
        isDrawingComponent,
        lengthDrawing,
        ceilingLengthEquation,
        ceilingAreaEquation,
        ceilingHeelHeightEquation,
    }) => (
        <>
            <InputRow gridTemplate="1fr 1fr 1fr">
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.measurements.length`}
                    label="Ceiling Length"
                    placeholder="0.00"
                    validate={lengthValidation}
                    decimals={getDecimalPlaces("ceilingLength")}
                    change={change}
                    disabled={lengthDrawing}
                    isDrawing={lengthDrawing}
                    units={{
                        base: getBaseUnits("ceilingLength", modelUnits),
                        options: getUnitOptions("length"),
                        selected: lengthUnits,
                        unitType: "length",
                        accessor: `${accessor}.measurements.length_u`,
                    }}
                    withEquation
                    equation={ceilingLengthEquation}
                    info={
                        "If the Construction type selected is cathedral or flat, enter the base length (perpendicular to the joists) of just one side of the ceiling. If the construction type selected is attic/gable, attic/hip, or scissor, enter the total length of the eave along which there may be compression of the insulation."
                    }
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.measurements.area`}
                    label="Ceiling Area"
                    placeholder="0.00"
                    disabled={isDrawingComponent}
                    isDrawing={isDrawingComponent}
                    validate={areaValidation}
                    decimals={getDecimalPlaces("ceilingArea")}
                    change={change}
                    units={{
                        base: getBaseUnits("ceilingArea", modelUnits),
                        options: getUnitOptions("area"),
                        selected: areaUnits,
                        unitType: "area",
                        accessor: `${accessor}.measurements.area_u`,
                    }}
                    withEquation
                    equation={ceilingAreaEquation}
                    info={
                        "Enter the interior ceiling surface area, not the roof area. For sloped ceilings, this is the actual surface area of the ceiling, not the horizontal projection of the area."
                    }
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.measurements.heelHeight`}
                    label="Heel Height"
                    placeholder="0.00"
                    validate={heelHeightValidation}
                    decimals={getDecimalPlaces("ceilingHeelHeight")}
                    change={change}
                    units={{
                        base: getBaseUnits("ceilingHeelHeight", modelUnits),
                        options: getUnitOptions("length"),
                        selected: heelHeightUnits,
                        unitType: "length",
                        accessor: `${accessor}.measurements.heelHeight_u`,
                    }}
                    withEquation
                    equation={ceilingHeelHeightEquation}
                    info={
                        "Enter a value of 0.1 m (0.33 ft) for houses built before 1990 and 0.13 m (0.43 ft) for houses built from 1990 onward. Use a more precise value if house plan measurements are available."
                    }
                />
            </InputRow>
            <InputRow gridTemplate="1fr 1fr 1fr">
                <Field
                    component={SelectWithInput}
                    inputData={{
                        triggerValue: 0,
                        inputLabel: "Slope",
                        inputType: "number",
                        inputPlaceholder: "",
                    }}
                    name={`${accessor}.measurements.slope`}
                    options={getOptions({ fieldKey: "ceilingSlope" })}
                    label="Ceiling Slope"
                    placeholder="Choose Ceiling Slope"
                    validate={slopeValidation}
                    info={"Use the default value (4/12) unless a more precise value is known."}
                />
            </InputRow>
        </>
    )
);
