import React, { useState } from "react";
import classes from "../style.module.scss";
import Chart from "react-google-charts";
import { pieChartTooltip, getPercentage, PieChart, ChartTable, Stat } from "../General";
import Select from "components/Input/Select";
import HourlyAnalysis from "../../HourlyAnalysis/container";

import LoadingDots from "components/LoadingDots";
import { getNetTotalEnergyConsumption } from "utils/results";
import { getNIREmissions } from "utils/emissions/getEmissionFactors";

export const barChartTooltip = (title, value, unit, percentage) => {
    return `<div style="min-width:140px; padding: 10px 12px;">
        <p style="font-weight:700; margin-bottom: 0; line-height: 16px;">${title}</p>
        <p>${value} ${unit} <span style="margin-left:4px; color:#646f81;">(${percentage})</span></p>
    </div>`;
};

const EnerGuideResults = ({
    resultsPackages = {},
    packageResultsData = {},
    packageInfo,
    selectedPackage,
    selectedCase,
    changePackage,
    changeCase,
    refHouseKey,
    onrhTitle,
    weatherProvTerrId,
    totalFloorArea,
}) => {
    let cases = [
        {
            value: "socBaseCase",
            upgradesValue: "socAllUpgrades",
            label: "Standard Operating Conditions",
        },
        {
            value: "ersReference",
            upgradesValue: "ersReference",
            label: "ERS Reference House",
        },
        {
            value: "generalBaseCase",
            upgradesValue: "generalAllUpgrades",
            label: "General Mode",
        },
        {
            value: "hcvBaseCase",
            upgradesValue: "hcvAllUpgrades",
            label: "Continous Schedule Ventilation",
        },
        {
            value: "hocBaseCase",
            upgradesValue: "hocAllUpgrades",
            label: "Household Operating Conditions",
        },
        {
            value: "rocBaseCase",
            upgradesValue: "rocAllUpgrades",
            label: "Reduced Operating Conditions",
        },
        {
            value: "userHouseBaseCase",
            upgradesValue: "userHouseBaseCase",
            label: "General Mode",
        },
    ];

    if (!onrhTitle.includes("ERS")) {
        cases = cases.map((opt) => {
            if (opt.value === "ersReference") {
                return {
                    value: refHouseKey,
                    upgradesValue: refHouseKey,
                    label: `ON Reference House (${onrhTitle})`,
                };
            } else {
                return opt;
            }
        });
    }

    const {
        [selectedPackage]: {
            modelData: { program: { results: { tsv: { Qtot: qTot = 0, Qwarn: qWarn = 0 } = {} } = {} } = {} } = {},
            resultsData: {
                [selectedCase]: {
                    Annual: {
                        AirChangeRate: { _attributes: { natural: natAirChangeRate = 0 } = {} } = {},
                        Consumption: {
                            _attributes: { total: totalConsumption = 0 } = {},
                            Electrical: {
                                _attributes: {
                                    spaceCooling: totalCooling = 0,
                                    ventilation: totalVent = 0,
                                    baseload: totalBaseload = 0,
                                } = {},
                            } = {},
                            SpaceHeating: { _attributes: { total: totalHeating = 0 } = {} } = {},
                            HotWater: { _attributes: { total: totalHotWater = 0 } = {} } = {},
                        } = {},
                    } = {},
                    Monthly: {
                        Load: { PhotoVoltaicAvailable: { _attributes: monthlyPV = {} } = {} } = {},
                        SolarHotWaterEnergyContribution: { _attributes: monthlySolarHW = {} } = {},
                    } = {},
                    resultsSummary: {
                        endUseChart: {
                            baseloads = 0,
                            spaceCooling = 0,
                            ventilation = 0,
                            hotWater = 0,
                            spaceHeating = 0,
                        } = {},
                        fuelTypeChart: { naturalGas = 0, electricity = 0, oil = 0, propane = 0, wood = 0 } = {},
                        // emissionsChart: {
                        //     naturalGas: naturalGasEmissions = 0,
                        //     electricity: electricityEmissions = 0,
                        //     propane: propaneEmissions = 0,
                        //     wood: woodEmissions = 0,
                        //     oil: oilEmissions = 0,
                        //     pv: pvEmissions = 0,
                        // } = {},
                        annualElectricity: totalElectricty = 0,
                        annualNaturalGas: totalNaturalGas = 0,
                        annualOil: totalOil = 0,
                        annualPropane: totalPropane = 0,
                        annualWood: totalWood = 0,
                        annualEmissions: totalEmissions = 0,
                    } = {},
                    hourlyElectricalLoadkWh: electricalLoad = [],
                } = {},
                [refHouseKey]: {
                    Annual: {
                        Consumption: {
                            _attributes: { total: refTotalConsumption = 0 } = {},
                            Electrical: {
                                _attributes: {
                                    spaceCooling: refTotalCooling = 0,
                                    ventilation: refTotalVent = 0,
                                    baseload: refTotalBaseload = 0,
                                } = {},
                            } = {},
                            SpaceHeating: { _attributes: { total: refTotalHeating = 0 } = {} } = {},
                            HotWater: { _attributes: { total: refTotalHotWater = 0 } = {} } = {},
                        } = {},
                    } = {},
                    Monthly: {
                        Load: { PhotoVoltaicAvailable: { _attributes: refMonthlyPV = {} } = {} } = {},
                        SolarHotWaterEnergyContribution: { _attributes: refMonthlySolarHW = {} } = {},
                    } = {},
                } = {},
                socBaseCase: {
                    Annual: {
                        Consumption: {
                            _attributes: { total: socTotalConsumption = 0 } = {},
                            Electrical: {
                                _attributes: {
                                    spaceCooling: socTotalCooling = 0,
                                    ventilation: socTotalVent = 0,
                                    baseload: socTotalBaseload = 0,
                                } = {},
                            } = {},
                            SpaceHeating: { _attributes: { total: socTotalHeating = 0 } = {} } = {},
                            HotWater: { _attributes: { total: socTotalHotWater = 0 } = {} } = {},
                        } = {},
                    } = {},
                    Monthly: {
                        Load: { PhotoVoltaicAvailable: { _attributes: socMonthlyPV = {} } = {} } = {},
                        SolarHotWaterEnergyContribution: { _attributes: socMonthlySolarHW = {} } = {},
                    } = {},
                } = {},
                generalBaseCase: {
                    Annual: {
                        Consumption: {
                            _attributes: { total: generalTotalConsumption = 0 } = {},
                            Electrical: {
                                _attributes: {
                                    spaceCooling: genTotalCooling = 0,
                                    ventilation: genTotalVent = 0,
                                    baseload: genTotalBaseload = 0,
                                } = {},
                            } = {},
                            SpaceHeating: { _attributes: { total: genTotalHeating = 0 } = {} } = {},
                            HotWater: { _attributes: { total: genTotalHotWater = 0 } = {} } = {},
                        } = {},
                    },
                    Monthly: {
                        Load: { PhotoVoltaicAvailable: { _attributes: genMonthlyPV = {} } = {} } = {},
                        SolarHotWaterEnergyContribution: { _attributes: genMonthlySolarHW = {} } = {},
                    } = {},
                } = {},
            } = {},
        } = {},
    } = packageResultsData;

    const totalChartConsumption = baseloads + spaceCooling + ventilation + hotWater + spaceHeating;
    const totalChartFuelType = naturalGas + electricity + oil + propane + wood;

    const currentCaseAEC = totalCooling + totalVent + totalBaseload + totalHeating + totalHotWater;
    const socAEC = socTotalCooling + socTotalVent + socTotalBaseload + socTotalHeating + socTotalHotWater;
    const refAEC = refTotalCooling + refTotalVent + refTotalBaseload + refTotalHeating + refTotalHotWater;
    const genAEC = genTotalCooling + genTotalVent + genTotalBaseload + genTotalHeating + genTotalHotWater;

    const currentCaseAEP = [...Object.values(monthlySolarHW), ...Object.values(monthlyPV)].reduce(
        (sum, curr) => sum + curr,
        0
    );
    const socAEP = [...Object.values(socMonthlySolarHW), ...Object.values(socMonthlyPV)].reduce(
        (sum, curr) => sum + curr,
        0
    );
    const refAEP = [...Object.values(refMonthlySolarHW), ...Object.values(refMonthlyPV)].reduce(
        (sum, curr) => sum + curr,
        0
    );
    const genAEP = [...Object.values(genMonthlySolarHW), ...Object.values(genMonthlyPV)].reduce(
        (sum, curr) => sum + curr,
        0
    );

    const netConsumption = Math.max(0, currentCaseAEC - currentCaseAEP);
    const refNetConsumption = refAEC - refAEP;
    const socNetConsumption = socAEC - socAEP;
    const generalNetConsumption = genAEC - genAEP;

    const percDiffRefHouse = ((netConsumption - refNetConsumption) / refNetConsumption) * 100;

    const {
        zeroFloorTotal,
        net: netEnergyConsumption,
        gross: grossEnergyConsumption,
        grossElectricity: totalGrossElectricity = 0,
        grossNaturalGas: totalGrossNaturalGas = 0,
        grossPropane: totalGrossPropane = 0,
        grossOil: totalGrossOil = 0,
        grossWood: totalGrossWood = 0,
        pvProduction = 0,
        annualEnergyConsumption = 0,
    } = getNetTotalEnergyConsumption(packageResultsData?.[selectedPackage]?.resultsData?.[selectedCase] || {});

    const {
        totalEmissionsTCO2,
        totalElecEmissionsTCO2,
        totalNgEmissionsTCO2,
        totalOilEmissionsTCO2,
        totalPropaneEmissionsTCO2,
        totalWoodEmissionsTCO2,
        totalPvEmissionsTCO2,
    } = getNIREmissions(packageResultsData?.[selectedPackage]?.resultsData?.[selectedCase] || {}, weatherProvTerrId);

    const totalChartEmissions =
        totalElecEmissionsTCO2 +
        totalNgEmissionsTCO2 +
        totalOilEmissionsTCO2 +
        totalPropaneEmissionsTCO2 +
        totalWoodEmissionsTCO2;

    const energyUseIntensity = annualEnergyConsumption / totalFloorArea;

    const maxHourlyLoad = Object.keys(packageResultsData).reduce((max, currPackageKey) => {
        const {
            resultsData: {
                socBaseCase: { hourlyElectricalLoadkWh: baseElecLoad = [] } = {},
                socAllUpgrades: { hourlyElectricalLoadkWh: upElecLoad = [] } = {},
            } = {},
        } = packageResultsData[currPackageKey] || {};

        return Math.max(max, ...baseElecLoad, ...upElecLoad);
    }, 0);

    return (
        <div className={classes.resultsContainer}>
            <div className={classes.selectionWrapper}>
                <Select
                    className={classes.selection}
                    name="packageSelect"
                    label="Upgrade Package"
                    input={{
                        value: selectedPackage,
                        onChange: (value) => {
                            changePackage(value);
                            if (value === "base") {
                                const selectedCaseData =
                                    cases.find(({ upgradesValue }) => upgradesValue === selectedCase) || {};
                                const { value: altValue = "" } = selectedCaseData;
                                if (altValue) {
                                    changeCase(altValue);
                                }
                            } else {
                                const selectedCaseData =
                                    cases.find(({ value: baseValue }) => baseValue === selectedCase) || {};
                                const { upgradesValue: altValue = "" } = selectedCaseData;
                                if (altValue) {
                                    changeCase(altValue);
                                }
                            }
                        },
                    }}
                    options={[
                        {
                            value: "base",
                            label: "Base Case",
                        },
                        ...Object.keys(resultsPackages)
                            .filter((packageId) => packageInfo[packageId])
                            .map((packageId) => ({
                                value: packageId,
                                label: packageInfo[packageId].name,
                            })),
                    ]}
                />
                <Select
                    className={classes.selection}
                    name="caseSelect"
                    label="Operating Conditions"
                    input={{
                        value: selectedCase,
                        onChange: (value) => changeCase(value),
                    }}
                    options={cases.map(({ value = "", upgradesValue = "", label = "" }) => ({
                        value: selectedPackage !== "base" ? upgradesValue : value,
                        label,
                    }))}
                />
            </div>
            <div className={`${classes.row} ${classes.totals}`} style={{ gridTemplateColumns: "1fr 1fr 1fr" }}>
                <Stat
                    label={selectedCase === "socBaseCase" ? "Rating" : "Total Annual Energy Consumption"}
                    value={netConsumption}
                    decimals={2}
                    unit="GJ/y"
                    big
                />
                <Stat
                    label={
                        onrhTitle.includes("ERS") ? "Reference House" : `Reference House (ON Ref House ${onrhTitle})`
                    }
                    value={refNetConsumption}
                    unit="GJ/y"
                    decimals={2}
                />
                <Stat
                    label={percDiffRefHouse < 0 ? "% Lower Than Ref. House" : "% Higher Than Ref. House"}
                    value={Math.abs(percDiffRefHouse)}
                    decimals={2}
                    unit="%"
                />
            </div>
            <div
                className={`${classes.row} ${classes.totals} ${classes.lastTotals}`}
                style={{ gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr" }}
            >
                <Stat label="Energy Use Intensity" value={energyUseIntensity} decimals={2} unit="GJ/m²/y" />
                <Stat label="Greenhouse Gases (NIR 2024)" value={totalEmissionsTCO2} decimals={2} unit="tCO₂/y" />
                <Stat label="Nat. ACH (1)" value={natAirChangeRate} decimals={2} />
                <Stat label="Total ventilation (Qtot, SOC)" value={qTot} decimals={2} unit="L/s" />
                <Stat label="Min. Ventilation (Qwarn, SOC)" value={qWarn} decimals={2} unit="L/s" />
            </div>
            <div className={`${classes.row} ${classes.charts}`} style={{ gridTemplateColumns: "1fr 1fr 1fr" }}>
                <PieChart
                    title="Energy end use breakdown"
                    data={[
                        ["Energy type", "Actual", { type: "string", role: "tooltip", p: { html: true } }],
                        [
                            "Space Heating",
                            parseFloat(spaceHeating.toFixed(2)),
                            pieChartTooltip(
                                "Space Heating",
                                parseFloat(spaceHeating.toFixed(2)),
                                "GJ",
                                getPercentage(spaceHeating, totalChartConsumption)
                            ),
                        ],
                        [
                            "Baseloads",
                            parseFloat(baseloads.toFixed(2)),
                            pieChartTooltip(
                                "Baseloads",
                                parseFloat(baseloads.toFixed(2)),
                                "GJ",
                                getPercentage(baseloads, totalChartConsumption)
                            ),
                        ],
                        [
                            "Hot Water",
                            parseFloat(hotWater.toFixed(2)),
                            pieChartTooltip(
                                "Hot Water",
                                parseFloat(hotWater.toFixed(2)),
                                "GJ",
                                getPercentage(hotWater, totalChartConsumption)
                            ),
                        ],
                        [
                            "Space Cooling",
                            parseFloat(spaceCooling.toFixed(2)),
                            pieChartTooltip(
                                "Space Cooling",
                                parseFloat(spaceCooling.toFixed(2)),
                                "GJ",
                                getPercentage(spaceCooling, totalChartConsumption)
                            ),
                        ],
                        [
                            "Ventilation",
                            parseFloat(ventilation.toFixed(2)),
                            pieChartTooltip(
                                "Ventilation",
                                parseFloat(ventilation.toFixed(2)),
                                "GJ",
                                getPercentage(ventilation, totalChartConsumption)
                            ),
                        ],
                    ]}
                />
                <PieChart
                    title="Energy fuel type breakdown (gross)"
                    data={[
                        ["Fuel type", "Actual", { type: "string", role: "tooltip", p: { html: true } }],
                        [
                            "Electricity",
                            parseFloat(totalGrossElectricity.toFixed(2)),
                            pieChartTooltip(
                                "Electricity",
                                parseFloat(totalGrossElectricity.toFixed(2)),
                                "GJ",
                                getPercentage(totalGrossElectricity, totalChartFuelType)
                            ),
                        ],
                        [
                            "Natural Gas",
                            parseFloat(totalGrossNaturalGas.toFixed(2)),
                            pieChartTooltip(
                                "Natural Gas",
                                parseFloat(totalGrossNaturalGas.toFixed(2)),
                                "GJ",
                                getPercentage(totalGrossNaturalGas, totalChartFuelType)
                            ),
                        ],
                        [
                            "Oil",
                            parseFloat(totalGrossOil.toFixed(2)),
                            pieChartTooltip(
                                "Oil",
                                parseFloat(totalGrossOil.toFixed(2)),
                                "GJ",
                                getPercentage(totalGrossOil, totalChartFuelType)
                            ),
                        ],
                        [
                            "Propane",
                            parseFloat(totalGrossPropane.toFixed(2)),
                            pieChartTooltip(
                                "Propane",
                                parseFloat(totalGrossPropane.toFixed(2)),
                                "GJ",
                                getPercentage(totalGrossPropane, totalChartFuelType)
                            ),
                        ],
                        [
                            "Wood",
                            parseFloat(totalGrossWood.toFixed(2)),
                            pieChartTooltip(
                                "Wood",
                                parseFloat(totalGrossWood.toFixed(2)),
                                "GJ",
                                getPercentage(totalGrossWood, totalChartFuelType)
                            ),
                        ],
                    ]}
                    isEmpty={[
                        totalGrossElectricity,
                        totalGrossNaturalGas,
                        totalGrossPropane,
                        totalGrossOil,
                        totalGrossWood,
                    ].every((el) => el < 0.001)}
                />
                <PieChart
                    title="Emissions breakdown (gross)"
                    data={[
                        ["Fuel type", "Actual", { type: "string", role: "tooltip", p: { html: true } }],
                        [
                            "Electricity",
                            parseFloat(totalElecEmissionsTCO2.toFixed(2)),
                            pieChartTooltip(
                                "Electricity",
                                parseFloat(totalElecEmissionsTCO2.toFixed(2)),
                                "tCO₂",
                                getPercentage(totalElecEmissionsTCO2, totalChartEmissions)
                            ),
                        ],
                        [
                            "Natural Gas",
                            parseFloat(totalNgEmissionsTCO2.toFixed(2)),
                            pieChartTooltip(
                                "Natural Gas",
                                parseFloat(totalNgEmissionsTCO2.toFixed(2)),
                                "tCO₂",
                                getPercentage(totalNgEmissionsTCO2, totalChartEmissions)
                            ),
                        ],
                        [
                            "Oil",
                            parseFloat(totalOilEmissionsTCO2.toFixed(2)),
                            pieChartTooltip(
                                "Oil",
                                parseFloat(totalOilEmissionsTCO2.toFixed(2)),
                                "tCO₂",
                                getPercentage(totalOilEmissionsTCO2, totalChartEmissions)
                            ),
                        ],
                        [
                            "Propane",
                            parseFloat(totalPropaneEmissionsTCO2.toFixed(2)),
                            pieChartTooltip(
                                "Propane",
                                parseFloat(totalPropaneEmissionsTCO2.toFixed(2)),
                                "tCO₂",
                                getPercentage(totalPropaneEmissionsTCO2, totalChartEmissions)
                            ),
                        ],
                        [
                            "Wood",
                            parseFloat(totalWoodEmissionsTCO2.toFixed(2)),
                            pieChartTooltip(
                                "Wood",
                                parseFloat(totalWoodEmissionsTCO2.toFixed(2)),
                                "tCO₂",
                                getPercentage(totalWoodEmissionsTCO2, totalChartEmissions)
                            ),
                        ],
                    ]}
                    isEmpty={[
                        totalElecEmissionsTCO2,
                        totalNgEmissionsTCO2,
                        totalOilEmissionsTCO2,
                        totalPropaneEmissionsTCO2,
                        totalWoodEmissionsTCO2,
                    ].every((el) => el < 0.001)}
                />
            </div>
            <div className={`${classes.row} ${classes.chartBreakdown}`} style={{ gridTemplateColumns: "1fr 1fr 1fr" }}>
                <ChartTable
                    title="Energy end use"
                    rows={[
                        {
                            label: "Space Heating",
                            value: `${parseFloat(spaceHeating).toFixed(2)} GJ`,
                        },
                        {
                            label: "Baseloads",
                            value: `${parseFloat(baseloads).toFixed(2)} GJ`,
                        },
                        {
                            label: "Hot Water",
                            value: `${parseFloat(hotWater).toFixed(2)} GJ`,
                        },
                        {
                            label: "Space Cooling",
                            value: `${parseFloat(spaceCooling).toFixed(2)} GJ`,
                        },
                        {
                            label: "Ventilation",
                            value: `${parseFloat(ventilation).toFixed(2)} GJ`,
                        },
                    ]}
                />
                <ChartTable
                    title="Energy fuel type"
                    rows={[
                        {
                            label: "Electricity",
                            value: `${parseFloat(totalGrossElectricity.toFixed(2))} GJ`,
                        },
                        {
                            label: "Natural Gas",
                            value: `${parseFloat(totalGrossNaturalGas.toFixed(2))} GJ`,
                        },
                        {
                            label: "Oil",
                            value: `${parseFloat(totalGrossOil.toFixed(2))} GJ`,
                        },
                        {
                            label: "Propane",
                            value: `${parseFloat(totalGrossPropane.toFixed(2))} GJ`,
                        },
                        {
                            label: "Wood",
                            value: `${parseFloat(totalGrossWood.toFixed(2))} GJ`,
                        },
                        {
                            label: "Solar PV (saved)",
                            value: `${parseFloat(pvProduction.toFixed(2))} GJ`,
                        },
                    ]}
                />
                <ChartTable
                    title="Emissions"
                    rows={[
                        {
                            label: "Electricity",
                            value: `${parseFloat(totalElecEmissionsTCO2.toFixed(2))} tCO₂`,
                        },
                        {
                            label: "Natural Gas",
                            value: `${parseFloat(totalNgEmissionsTCO2.toFixed(2))} tCO₂`,
                        },
                        {
                            label: "Oil",
                            value: `${parseFloat(totalOilEmissionsTCO2.toFixed(2))} tCO₂`,
                        },
                        {
                            label: "Propane",
                            value: `${parseFloat(totalPropaneEmissionsTCO2.toFixed(2))} tCO₂`,
                        },
                        {
                            label: "Wood",
                            value: `${parseFloat(totalWoodEmissionsTCO2.toFixed(2))} tCO₂`,
                        },
                        {
                            label: "Solar PV (saved)",
                            value: `${parseFloat(totalPvEmissionsTCO2.toFixed(2))} tCO₂`,
                        },
                    ]}
                />
            </div>
            {/* <div className={`${classes.row} ${classes.barChart}`}>
                <Chart
                    width={"100%"}
                    height={"300px"}
                    chartType="Bar"
                    loader={LoadingDots}
                    data={[
                        ["Case", "AEC (GJ/y)", "AEP (GJ/y)", "Net (GJ/y)"],
                        ["ERS Reference House", refAEC, refAEP, refNetConsumption],
                        ["General Mode", genAEC, genAEP, generalNetConsumption],
                        ["Standard Operating Conditions", socAEC, socAEP, socNetConsumption],
                    ]}
                    options={{
                        bar: {
                            groupWidth: "35%"
                        },
                        legend: { position: 'none' },
                        hAxis: { textPosition: 'none' },
                        colors: ["#0049c6", "#f58020", "#FFB22D", "#18c1ad", "#92a4ad"],
                        tooltip: {
                            textStyle: { fontName: "Noto Sans TC", fontSize: 12 },
                        },
                    }}
                />
            </div> */}
            <h3 style={{ marginBottom: "1.5rem" }}>Annual Fuel Consumption</h3>
            <div
                className={`${classes.row} ${classes.consumption}`}
                style={{ gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr", marginBottom: 0 }}
            >
                {!!totalElectricty && <Stat label="Electricity" value={totalElectricty} decimals={2} unit="kWh" />}
                {!!totalNaturalGas && <Stat label="Natural Gas" value={totalNaturalGas} decimals={2} unit="m³" />}
                {!!totalOil && <Stat label="Oil" value={totalOil} decimals={2} unit="Litres" />}
                {!!totalPropane && <Stat label="Propane" value={totalPropane} decimals={2} unit="Litres" />}
                {!!totalWood && <Stat label="Wood" value={totalWood} decimals={2} unit="kg" />}
            </div>
            <HourlyAnalysis
                electricalLoad={electricalLoad}
                weatherProvTerrId={weatherProvTerrId}
                maxHourlyLoad={maxHourlyLoad}
                upgradePackageId={selectedPackage}
                resultsCase={selectedCase}
            />
        </div>
    );
};

export default EnerGuideResults;
