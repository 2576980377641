import React, { useEffect, useState } from "react";

import { Field } from "redux-form";

import classes from "./style.module.scss";
import sharedClasses from "features/Model/sharedStyles.module.scss";

import Input from "components/Input";

import InputRow from "components/Input/Row";
import { getValidation } from "utils/fieldValidation";

const discountRateValidation = getValidation("npvDiscountRate");
const projectDurationValidation = getValidation("npvProjectDuration");

const FinancialValuation = ({ accessor, change }) => {
    return (
        <div>
            <InputRow gridTemplate="1fr 1fr">
                <Field
                    component={Input}
                    name={`${accessor}.financialValuation.projectDuration`}
                    label={`Evaluation Period (years)`}
                    type="number"
                    info={"A blank field will use a duration of 20 years"}
                    validate={projectDurationValidation}
                />
                <Field
                    component={Input}
                    name={`${accessor}.financialValuation.discountRate`}
                    label={`Discount Rate (%)`}
                    type="number"
                    info={
                        "A blank field will use the default of 10%. Values between 0 - 100% are accepted, but values lower than 20% are recommended."
                    }
                    validate={discountRateValidation}
                />
            </InputRow>
        </div>
    );
};

export default FinancialValuation;
