import React from 'react';
import classes from './style.module.scss';
import Input from 'components/Input';
import CloseIcon from 'assets/images/icons/JSX/Close';
import SearchIcon from 'assets/images/icons/JSX/Search';

export default ({
    disabled=false,
    filter,
    changeFilter,
    placeholder="Search",
    className
}) => {
    return (
        <div className={`${classes.dropdownSearch} ${className ? className : ''}`}>
            <Input
                hideLabel
                className={classes.inputWrapper}
                disabled={disabled}
                placeholder={placeholder}
                input={{
                    onChange:(value) => changeFilter(value),
                    value:filter
                }}
            />
            {filter ? (
                <div
                    onClick={() => changeFilter("")}
                    className={`${classes.indicator} ${classes.close}`}
                >
                    <CloseIcon />
                </div>
            ) : (
                <div onClick={() => changeFilter("")} className={classes.indicator}>
                    <SearchIcon />
                </div>
            )}
        </div>
    )
}