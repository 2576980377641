import React from "react";
import moment from "moment";

import logo from "./logo-color.png";
import logoSVG from "assets/images/logo-color.svg";
import HVACDCLogo from "./HVACDCLogo.png";

import "./styles.css";

const F280FirstPage = ({
    heatLossValue,
    latHeatGainValue,
    heatUnit,
    name,
    inputFieldsData,
    displayData,
    calcPerformedBy,
}) => {
    const { issuedForTheUseOf, projectNo, lot, site, notes, additionalDocsList, disgnSummary, roomByRoomResults } =
        inputFieldsData;

    const { buildingLocation = {}, calculationBasedOn = {} } = displayData || {};

    const { cityProvince = "", postalCode = "", address = "" } = buildingLocation || {};

    const { units = "" } = calculationBasedOn || {};

    const {
        cityProvince: userCityProvince,
        company,
        companyAddress,
        companyFax,
        companyPhone,
        companyPostalCode,
        email,
        userName,
    } = calcPerformedBy || {};

    return (
        <>
            <div className="body">
                <div className="titleContainer">
                    <div className="titleWrapper">
                        <div className="titleSubtitleWrapper">
                            <h1 className="titleWrapperH1">CSA STANDARD F280-12 COMPLIANCE</h1>
                            <span className="subtitle">
                                NBC 2015: 9.33.5.1.; 9.36.3.2. & 9.36.5.15; NBC 2020; 9.33.5.1.; 9.36.3.2.; 9.36.5.15
                                (5); 9.36.8.9. (1)
                            </span>
                        </div>
                        <div className="rightSubContainer">
                            <span className="titleWrapperSpan">
                                CSA-F280-M12 <br />
                                Standard Form No. 1
                            </span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="cell cellFullFlex  paddingRight" style={{ paddingBottom: "18px" }}>
                        <div className="nameContainer">
                            <p className="regText">These documents issued for the use of</p>
                            <div className="inputName">
                                <span className="nameContainerSpan">{issuedForTheUseOf}</span>
                            </div>
                        </div>
                        <p className="regText">
                            and may not be used by any other persons without authorization. Documents for permit and/or
                            construction are signed in red.
                        </p>
                        <span className="smallNumberFields">1</span>
                    </div>
                    <div className="cell" style={{ padding: 0, borderLeft: "2px solid black", width: "150px" }}>
                        <div className="projectNumber">
                            <span className="projectNumberSpan">PROJECT #</span>
                            <p className="projectNumberP">{projectNo}</p>
                        </div>
                        <span className="smallNumberFields">2</span>
                    </div>
                </div>
                <div className="smallTitle">
                    <h2>BUILDING LOCATION</h2>
                </div>
                <div className="row">
                    <div className="cellSmallPadding cellFullFlex borderRight">
                        <div className="cellTextContainer">
                            <span>Model:</span>
                            <p>{name}</p>
                        </div>
                        <span className="smallNumberFields">3</span>
                    </div>
                    <div className="cellSmallPadding cellFullFlex">
                        <div className="cellTextContainer">
                            <span>Site:</span>
                            <p>{site}</p>
                        </div>
                        <span className="smallNumberFields">6</span>
                    </div>
                </div>
                <div className="row">
                    <div className="cellSmallPadding cellFullFlex borderRight">
                        <div className="cellTextContainer">
                            <span>Address:</span>
                            <p>{address}</p>
                        </div>
                        <span className="smallNumberFields">4</span>
                    </div>
                    <div className="cellSmallPadding cellFullFlex">
                        <div className="cellTextContainer">
                            <span>Lot:</span>
                            <p>{lot}</p>
                        </div>
                        <span className="smallNumberFields">7</span>
                    </div>
                </div>
                <div className="row">
                    <div className="cellSmallPadding cellFullFlex borderRight">
                        <div className="cellTextContainer">
                            <span>City & Province:</span>
                            <p>{cityProvince}</p>
                        </div>
                        <span className="smallNumberFields">5</span>
                    </div>
                    <div className="cellSmallPadding cellFullFlex">
                        <div className="cellTextContainer">
                            <span>Postal code:</span>
                            <p>{postalCode}</p>
                        </div>
                        <span className="smallNumberFields">8</span>
                    </div>
                </div>
                <div className="smallTitle">
                    <h2>COMPLIANCE</h2>
                    <span className="titleSupText">(See page 2 for input summary)</span>
                </div>
                <div className="row">
                    <div className="cellSmallPadding cellFullFlex borderRight">
                        <div className="cellTextContainer cellTextContainerSmall cellFullFlex cellFlexCenter">
                            <span style={{ width: "130px" }}>Submittal is for:</span>
                            <div className="selectionBoxContainer">
                                <div className="selectionBoxWrapper">
                                    <div className="boxContainer">
                                        <span className="boxX">X</span>
                                    </div>
                                    <span className="boxText">Whole house</span>
                                </div>
                                <div className="selectionBoxWrapper">
                                    <div className="boxContainer">
                                        <span className="boxX"></span>
                                    </div>
                                    <span className="boxText">Room by Room</span>
                                </div>
                            </div>
                        </div>
                        <span className="smallNumberFields">9</span>
                    </div>
                    <div className="cellSmallPadding cellFullFlex">
                        <div className="cellTextContainer cellTextContainerSmall cellFullFlex cellFlexCenter">
                            <span style={{ width: "130px", textAlign: "center" }}>Units:</span>
                            <div className="selectionBoxContainer">
                                <div className="selectionBoxWrapper">
                                    <div className="boxContainer">
                                        <span className="boxX">{units === "Metric" ? "X" : ""}</span>
                                    </div>
                                    <span className="boxText">Metric</span>
                                </div>
                                <div className="selectionBoxWrapper">
                                    <div className="boxContainer">
                                        <span className="boxX">{units === "Imperial" ? "X" : ""}</span>
                                    </div>
                                    <span className="boxText">Imperial</span>
                                </div>
                            </div>
                        </div>
                        <span className="smallNumberFields">b</span>
                    </div>
                </div>
                <div className="smallTitle">
                    <h2>HEATING</h2>
                </div>
                <div className="cellTextContainer  cellFullFlex" style={{ height: "auto", flexDirection: "column" }}>
                    <div className="textWithBoxContainer">
                        <span className="regText textWithBox">Minimum Heating Capacity:</span>
                        <div className="boxWithTextWrapper">
                            <div className="rectangle">
                                <span className="heatText">{parseFloat(heatLossValue).toFixed(0)}</span>
                            </div>
                            <span className="cellTextContainerSmall">{heatUnit}</span>
                        </div>
                        <span className="cellTextContainerSmall">(total building heat loss as per 5.2.7)</span>
                        <span className="smallNumberFields" style={{ right: "-10px" }}>
                            c
                        </span>
                    </div>
                    <div className="textRowWithNumber" style={{ marginBottom: "0.6rem" }}>
                        <span style={{ alignSelf: "flex-end" }}>5.3.1</span>
                        <span>
                            The total heat output capacity of all heating systems installed in a building shall not be
                            less than 100% of the total building heat loss as determined in Clause 5.2.7.
                        </span>
                    </div>
                    <div className="textRowWithNumber bottomBorder" style={{ paddingBottom: "0.4rem" }}>
                        <span style={{ alignSelf: "flex-end" }}>5.3.2</span>
                        <span>
                            The combined heating delivery of the heating systems that serve a room or space shall not 
                            be less than 100% of the space heat loss , as determined in Clause 5.2.6. (If room by room 
                            submittal, see page 2 for individual space heating requirements)
                        </span>
                    </div>
                </div>

                <div className="smallTitle">
                    <h2>COOLING</h2>
                </div>
                <div className="cellTextContainer  cellFullFlex" style={{ height: "auto", flexDirection: "column" }}>
                    <div className="textWithBoxContainer" style={{ margin: "0.6rem 0" }}>
                        <span className="regText textWithBox" style={{ marginRight: "auto", marginLeft: "5px" }}>
                            Cooling Capacity:
                        </span>
                        <div className="boxesContainer">
                            <div className="boxWithTextWrapper">
                                <span className="cellTextContainerSmall">Nominal=</span>
                                <div className="rectangle" style={{ width: "100px" }}>
                                    <span className="heatText">{parseFloat(latHeatGainValue).toFixed(0)}</span>
                                </div>
                                <span className="cellTextContainerSmall">{heatUnit}</span>
                                <span className="smallNumberFields" style={{ right: "15px" }}>
                                    d
                                </span>
                            </div>
                            <div className="boxWithTextWrapper">
                                <span className="cellTextContainerSmall">Minimum=</span>
                                <div className="rectangle" style={{ width: "100px" }}>
                                    <span className="heatText">
                                        {parseFloat(parseFloat(latHeatGainValue) * 0.8).toFixed(0)}
                                    </span>
                                </div>
                                <span className="cellTextContainerSmall">{heatUnit}</span>
                                <span className="smallNumberFields" style={{ right: "15px" }}>
                                    e
                                </span>
                            </div>
                            <div className="boxWithTextWrapper">
                                <span className="cellTextContainerSmall">Maximum=</span>
                                <div className="rectangle" style={{ width: "100px" }}>
                                    <span className="heatText">
                                        {parseFloat(parseFloat(latHeatGainValue) * 1.25).toFixed(0)}
                                    </span>
                                </div>
                                <span className="cellTextContainerSmall">{heatUnit}</span>
                                <span className="smallNumberFields" style={{ right: "15px" }}>
                                    f
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="textRowWithNumber" style={{ marginBottom: "0.6rem" }}>
                        <span style={{ alignSelf: "flex-end" }}>6.3.2</span>
                        <span>
                            Except as provided in Clause 6.3.3., the cooling system capacity shall not be less than 80%
                            of the nominal cooling capacity for the building, as determined in Clause 6.3.1. In no case
                            shall it be less than the nominal cooling capacity of the building minus 1800 W (0.51 tons)
                        </span>
                    </div>
                    <div className="textRowWithNumber" style={{ paddingBottom: "0.4rem" }}>
                        <span style={{ alignSelf: "flex-end" }}>6.3.3</span>
                        <span>
                            Where the cooling system is added to an existing heating system, it's capacity in Watts
                            shall not exceed 18 times the capacity of the air-handling capacity of the existing system
                            in L/s. (Cooling capacity in Tons not more than 1.0 per 400 CFM of air handling capacity)
                        </span>
                    </div>
                    <div className="textRowWithNumber" style={{ marginBottom: "0.6rem" }}>
                        <span style={{ alignSelf: "flex-end" }}>6.3.4</span>
                        <span>
                            Except for ground-source and water source heat pumps used for cooling, and as permitted in
                            Clause 6.3.5, the installed cooling capacity shall not exceed 125% of the nominal cooling
                            capacity for the building, as determined in Clause 6.3.1.
                        </span>
                    </div>
                    <div className="textRowWithNumber bottomBorder" style={{ paddingBottom: "0.4rem" }}>
                        <span style={{ alignSelf: "flex-end" }}>6.3.5</span>
                        <span>
                            If the nominal cooling system capacity for the building, as determined in Clause 6.3.1. is
                            less than 6,000 W (1.7 tons), the installed cooling system capacity may exceed the nominal
                            cooling system capacity for the building by up to 1750 W (0.49 tons).
                        </span>
                    </div>
                </div>
                <div className="smallTitle">
                    <h2>ATTACHED DOCUMENTS</h2>
                </div>
                <div className="row">
                    <div className="cellSmallPadding cellTextContainerSmall  cellFullFlex borderRight">
                        <div
                            className="selectionBoxContainer"
                            style={{ justifyContent: "space-around", height: "100%" }}
                        >
                            <div className="selectionBoxWrapper" style={{ flex: "unset" }}>
                                <div className="boxContainer">
                                    <span className="boxX">{disgnSummary ? "X" : ""}</span>
                                </div>
                                <span className="boxText">Design Summary</span>
                                <span className="smallNumberFields" style={{ bottom: "-4px", right: "-14px" }}>
                                    g
                                </span>
                            </div>
                            <div className="selectionBoxWrapper" style={{ flex: "unset" }}>
                                <div className="boxContainer">
                                    <span className="boxX">{roomByRoomResults ? "X" : ""}</span>
                                </div>
                                <span className="boxText">Room by Room Results</span>
                                <span className="smallNumberFields" style={{ bottom: "-4px", right: "-14px" }}>
                                    h
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="cellSmallPadding cellFullFlex ">
                        <div className="cellTextContainer">
                            <span>Other:</span>
                            <p>{!!additionalDocsList ? additionalDocsList : ""}</p>
                        </div>
                        <span className="smallNumberFields">i</span>
                    </div>
                </div>
                <div className="row">
                    <div className="cellSmallPadding cellFullFlex ">
                        <div className="cellTextContainer">
                            <span>Other:</span>
                            {/* <p>{!!additionalDocsList ? additionalDocsList : ""}</p> */}
                        </div>
                        <span className="smallNumberFields">i</span>
                    </div>
                </div>
                <div className="row">
                    <div className="cellSmallPadding cellFullFlex ">
                        <div className="cellTextContainer" style={{ height: "unset" }}>
                            <span style={{ minWidth: "50px" }}>Notes:</span>
                            <div style={{ flex: "1" }}>
                                <p
                                    style={{
                                        widows: "100%",
                                        height: "30px",
                                        borderBottom: "2px solid black",
                                    }}
                                >
                                    {!!notes ? notes : ""}
                                </p>
                                <p style={{ widows: "100%", height: "30px" }}></p>
                            </div>
                            <span className="smallNumberFields">j</span>
                        </div>
                    </div>
                </div>
                <div className="smallTitle">
                    <h2>CALCULATIONS PERFORMED BY</h2>
                </div>
                <div className="row">
                    <div className="columnA">
                        <div className="cellSmallPadding cellFullFlex borderBottom">
                            <div className="cellTextContainer">
                                <span>Name:</span>
                                <p>{userName}</p>
                            </div>
                            <span className="smallNumberFields">55</span>
                        </div>
                        <div className="cellSmallPadding cellFullFlex borderBottom">
                            <div className="cellTextContainer">
                                <span>Company:</span>
                                <p>{company}</p>
                            </div>
                            <span className="smallNumberFields">56</span>
                        </div>
                        <div className="cellSmallPadding cellFullFlex borderBottom">
                            <div className="cellTextContainer">
                                <span>Address:</span>
                                <p>{companyAddress}</p>
                            </div>
                            <span className="smallNumberFields">57</span>
                        </div>
                        <div className="cellSmallPadding cellFullFlex borderBottom">
                            <div className="cellTextContainer">
                                <span>City & Province:</span>
                                <p>{userCityProvince}</p>
                            </div>
                            <span className="smallNumberFields">58</span>
                        </div>
                        <div className="cellSmallPadding cellFullFlex borderBottom">
                            <div className="cellTextContainer">
                                <span>Postal code:</span>
                                <p>{companyPostalCode}</p>
                            </div>
                            <span className="smallNumberFields">59</span>
                        </div>
                        <div className="cellSmallPadding cellFullFlex borderBottom">
                            <div className="cellTextContainer">
                                <span>Telephone:</span>
                                <p>{companyPhone}</p>
                            </div>
                            <span className="smallNumberFields">60</span>
                        </div>
                        <div className="cellSmallPadding cellFullFlex borderBottom">
                            <div className="cellTextContainer">
                                <span>Fax:</span>
                                <p>{companyFax}</p>
                            </div>
                            <span className="smallNumberFields">61</span>
                        </div>
                        <div className="cellSmallPadding cellFullFlex">
                            <div className="cellTextContainer">
                                <span style={{ minWidth: "30px" }}>E-mail:</span>
                                <p>{email}</p>
                            </div>
                            <span className="smallNumberFields">62</span>
                        </div>
                    </div>
                    <div className="columnA borderRight stampBox">
                        {" "}
                        <span className="smallNumberFields">68</span>
                    </div>
                    <div className="columnA" style={{ border: "none" }}>
                        <div className="cellSmallPadding cellFullFlex" style={{ borderBottom: "2px solid" }}>
                            <div className="" style={{ height: "102px", fontSize: "0.8rem", padding: "10px 11px" }}>
                                <span style={{ lineHeight: "20px" }}>
                                    I,{" "}
                                    <span
                                        style={{
                                            borderBottom: "2px solid black",
                                            color: "#1e449a",
                                            fontWeight: "700",
                                            marginBottom: "2px",
                                        }}
                                    >
                                        {userName}
                                    </span>{" "}
                                    have reviewed and take responsibility for the design work described in this document
                                    & I am qualified in the appropriate categories.
                                </span>
                            </div>
                            <span className="smallNumberFields">63</span>
                        </div>
                        <div className="cellSmallPadding cellFullFlex borderBottom">
                            <div className="cellTextContainer">
                                <span>
                                    Accreditation<br></br> Reference 1
                                </span>
                                <p></p>
                            </div>
                            <span className="smallNumberFields">64</span>
                        </div>
                        <div className="cellSmallPadding cellFullFlex borderBottom">
                            <div className="cellTextContainer">
                                <span>
                                    Accreditation<br></br> Reference 2
                                </span>
                                <p></p>
                            </div>
                            <span className="smallNumberFields">65</span>
                        </div>
                        <div className="cellSmallPadding cellFullFlex borderBottom">
                            <div className="cellTextContainer">
                                <span>
                                    Issued for:<br></br> (date)
                                </span>
                                <p></p>
                            </div>
                            <span className="smallNumberFields">66</span>
                        </div>
                        <div className="cellSmallPadding cellFullFlex borderBottom">
                            <div className="cellTextContainer">
                                <span>
                                    Issued for:<br></br> (date)
                                </span>
                                <p></p>
                            </div>
                            <span className="smallNumberFields">67</span>
                        </div>
                        <div className="cellSmallPadding cellFullFlex">
                            <div className="cellTextContainer">
                                {" "}
                                <div
                                    className=""
                                    style={{
                                        fontWeight: "700",
                                        display: "flex",
                                        alignItems: "center",
                                        textAlign: "center",
                                        gap: "10px",
                                        fontSize: "0.9rem",
                                        justifyContent: "center",
                                    }}
                                >
                                    <span>Page:</span>
                                    <span> 1</span> <span>of</span>
                                    <span>2</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row noBorderBottom">
                    <div
                        style={{
                            flex: "2 1 4.7%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "relative",
                            padding: "10px 0",
                        }}
                    >
                        <img src={logoSVG} />
                        <span
                            style={{
                                position: "absolute",
                                bottom: "2px",
                                right: 0,
                                left: 0,
                                margin: "0 auto",
                                textAlign: "center",
                                fontSize: "0.6rem",
                            }}
                        >
                            Version 0.2.3. Contact: info@voltaresearch.org
                        </span>
                        <span className="smallNumberFields">69</span>
                    </div>
                    <div
                        style={{
                            flex: "1",
                            borderLeft: "2px solid",
                            paddingLeft: "1px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "10px",
                        }}
                    >
                        <img src={HVACDCLogo} />
                    </div>
                </div>
            </div>
            <div style={{ height: "110px" }}></div>
        </>
    );
};

export default F280FirstPage;
