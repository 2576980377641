import axios from "axios";
import qs from "querystring";

//Import auth token
import { auth, getUserToken } from "_firebase";
import config from "_firebase/firebaseconfig.json";

const REACT_APP_DASHBOARD_API_URI = config.REACT_APP_DASHBOARD_API_URI;

var api = axios.create({
    baseURL: REACT_APP_DASHBOARD_API_URI,
    timeout: 9 * 60000,
});

const request = (api) => {
    return async ({ url, data, method, headers, file, query, contentType, preStringified = false, ...rest }) => {
        const formatMethod = method ? method.toLowerCase() : "get";
        const token = url === "/loginUser" ? "" : await getUserToken();
        const queryString = query ? `?${qs.stringify(query)}` : "";

        const dataToSend = preStringified ? data : JSON.stringify(data);

        return api({
            method: formatMethod,
            url: url + queryString,
            data: file ? file : dataToSend,
            headers: {
                Authorization: token ? `Bearer ${token}` : "",
                "content-type": contentType ? contentType : "application/json",
                ...headers,
            },
            ...rest,
        }).then((data) => {
            return data;
        });
    };
};

const DashboardAPI = request(api);
export { DashboardAPI, request };
