import React from "react";
import { Field } from "redux-form";
import classes from "./style.module.scss";
import ChbaRadio from "components/Input/ChbaRadio";
import Tooltip from "components/Tooltip";
import InfoTooltip from "components/InfoTooltip";

import { iaqTemplate } from "../Templates/iaqChecklist";

const IndoorAirQuality = ({ accessor, iaqChecklistContent }) => {
    const optionalPointCount = Object.keys(iaqChecklistContent?.optional || {}).reduce((sum, currKey) => {
        const checklistValue = iaqChecklistContent.optional[currKey] || "none";
        if (checklistValue !== "none") {
            return sum + iaqTemplate[currKey].points;
        }
        return sum;
    }, 0);

    return (
        <>
            <div className={classes.topRow}>
                <div className={classes.checklistDescription}>
                    <h3>Indoor Air Quality Checklist</h3>
                    <p>
                        This checklist shall be filled out upon completion of each Net Zero or Net Zero Ready Home by
                        the Qualified Net Zero Builder/Renovator with the Qualified Net Zero Energy Advisor. As per the
                        Qualified Net Zero Participant Agreement and Attestations, each participant must attest that the
                        homes they submit for labelling meet all of the Program Requirements. Additional detail for each
                        IAQ measure can be found in the notes in column A.
                    </p>
                </div>
            </div>
            {/* Mandatory */}
            <div className={classes.checklistSection}>
                <div className={classes.sectionHeader} gridTemplate="5fr 2fr">
                    <div className={classes.checklistTitleRow}>
                        <p>Mandatory IAQ Requirements</p>
                    </div>
                    <div className={classes.checkboxHeaders}>
                        <p>Builder/Renovator</p>
                        <p>EA</p>
                    </div>
                </div>
                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <div
                            data-html
                            data-tip={`<span style="display:block;max-width:14rem;">${iaqTemplate.airLeakage.tooltip}</span>`}
                            data-for={"iaq-tip-airLeakage"}
                            className={classes.checklistSectionNumber}
                        >
                            <p>{iaqTemplate.airLeakage.section}</p>
                        </div>

                        <p>{iaqTemplate.airLeakage.text}</p>
                        <Tooltip id={"iaq-tip-airLeakage"} />
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={ChbaRadio}
                            name={`${accessor}.mandatory.airLeakage`}
                            type="checkbox"
                            // setValue={input}
                        />
                    </div>
                </div>
                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <div
                            data-html
                            data-tip={`<span style="display:block;max-width:14rem;">${iaqTemplate.slabInsulation.tooltip}</span>`}
                            data-for={"iaq-tip-slabInsulation"}
                            className={classes.checklistSectionNumber}
                        >
                            <p>{iaqTemplate.slabInsulation.section}</p>
                        </div>

                        <p>{iaqTemplate.slabInsulation.text}</p>
                        <Tooltip id={"iaq-tip-slabInsulation"} />
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={ChbaRadio}
                            name={`${accessor}.mandatory.slabInsulation`}
                            type="checkbox"
                        />
                    </div>
                </div>

                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <div
                            data-html
                            data-tip={`<span style="display:block;max-width:14rem;">${iaqTemplate.unventedCombustion.tooltip}</span>`}
                            data-for={"iaq-tip-unventedCombustion"}
                            className={classes.checklistSectionNumber}
                        >
                            <p>{iaqTemplate.unventedCombustion.section}</p>
                        </div>

                        <p>{iaqTemplate.unventedCombustion.text}</p>
                        <Tooltip id={"iaq-tip-unventedCombustion"} />
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={ChbaRadio}
                            name={`${accessor}.mandatory.unventedCombustion`}
                            type="checkbox"
                        />
                    </div>
                </div>

                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <div
                            data-html
                            data-tip={`<span style="display:block;max-width:14rem;">${iaqTemplate.hrvInstalled.tooltip}</span>`}
                            data-for={"iaq-tip-hrvInstalled"}
                            className={classes.checklistSectionNumber}
                        >
                            <p>{iaqTemplate.hrvInstalled.section}</p>
                        </div>

                        <p>{iaqTemplate.hrvInstalled.text}</p>
                        <Tooltip id={"iaq-tip-hrvInstalled"} />
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={ChbaRadio}
                            name={`${accessor}.mandatory.hrvInstalled`}
                            type="checkbox"
                            // setValue={input}
                        />
                    </div>
                </div>

                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <div
                            data-html
                            data-tip={`<span style="display:block;max-width:14rem;">${iaqTemplate.ductsSealed.tooltip}</span>`}
                            data-for={"iaq-tip-ductsSealed"}
                            className={classes.checklistSectionNumber}
                        >
                            <p>{iaqTemplate.ductsSealed.section}</p>
                        </div>

                        <p>{iaqTemplate.ductsSealed.text}</p>
                        <Tooltip id={"iaq-tip-ductsSealed"} />
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={ChbaRadio}
                            name={`${accessor}.mandatory.ductsSealed`}
                            type="checkbox"
                            // setValue={input}
                        />
                    </div>
                </div>

                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <div
                            // data-html
                            // data-tip={`<span style="display:block;max-width:14rem;">${iaqTemplate.airFiltersMerv8.tooltip}</span>`}
                            // data-for={"iaq-tip-airFiltersMerv8"}
                            className={classes.checklistSectionNumber}
                        >
                            <InfoTooltip className={classes.infoIcon} info={iaqTemplate.airFiltersMerv8.tooltip} />
                            {/* <p>{iaqTemplate.airFiltersMerv8.section}</p> */}
                        </div>

                        <p>{iaqTemplate.airFiltersMerv8.text}</p>
                        {/* <Tooltip id={"iaq-tip-airFiltersMerv8"} /> */}
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={ChbaRadio}
                            name={`${accessor}.mandatory.airFiltersMerv8`}
                            type="checkbox"
                        />
                    </div>
                </div>

                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <div
                            // data-html
                            // data-tip={`<span style="display:block;max-width:14rem;">${iaqTemplate.lowVocIntPaints.tooltip}</span>`}
                            // data-for={"iaq-tip-lowVocIntPaints"}
                            className={classes.checklistSectionNumber}
                        >
                            <InfoTooltip className={classes.infoIcon} info={iaqTemplate.lowVocIntPaints.tooltip} />
                        </div>

                        <p>{iaqTemplate.lowVocIntPaints.text}</p>
                        <Tooltip id={"iaq-tip-lowVocIntPaints"} />
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={ChbaRadio}
                            name={`${accessor}.mandatory.lowVocIntPaints`}
                            type="checkbox"
                            // setValue={input}
                        />
                    </div>
                </div>
            </div>

            <div className={`${classes.topRow} ${classes.optionalHeader}`}>
                <div className={classes.checklistDescription}>
                    <h3>Select an additional 6 points from the Optional Picklist below.</h3>
                    <p>Unless otherwise noted, all options are worth one (1) point.</p>
                    <p>
                        Note: Net Zero and Net Zero Ready Renovations using an entirely exterior approach are only
                        required to meet the Mandatory IAQ requirements shown above.
                    </p>
                </div>
            </div>

            {/* Optional */}
            <div className={classes.checklistSection}>
                <div className={classes.sectionHeader} gridTemplate="5fr 2fr">
                    <div className={classes.checklistTitleRow}>
                        <p>Optional Picklist</p>
                        <div className={classes.progress}>{`(${Math.min(6, optionalPointCount)}/6)`}</div>
                    </div>
                    <div className={classes.checkboxHeaders}>
                        <p>Builder/Renovator</p>
                        <p>EA</p>
                    </div>
                </div>
                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <div
                            data-html
                            data-tip={`<span style="display:block;max-width:14rem;">${iaqTemplate.lowVocIntAdhesives.tooltip}</span>`}
                            data-for={"iaq-tip-lowVocIntAdhesives"}
                            className={classes.checklistSectionNumber}
                        >
                            <p>{iaqTemplate.lowVocIntAdhesives.section}</p>
                        </div>

                        <p>{iaqTemplate.lowVocIntAdhesives.text}</p>
                        <Tooltip id={"iaq-tip-lowVocIntAdhesives"} />
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={ChbaRadio}
                            name={`${accessor}.optional.lowVocIntAdhesives`}
                            type="checkbox"
                            // setValue={input}
                        />
                    </div>
                </div>

                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <div
                            data-html
                            data-tip={`<span style="display:block;max-width:14rem;">${iaqTemplate.naturalIntCoatings.tooltip}</span>`}
                            data-for={"iaq-tip-naturalIntCoatings"}
                            className={classes.checklistSectionNumber}
                        >
                            <p>{iaqTemplate.naturalIntCoatings.section}</p>
                        </div>

                        <p>{iaqTemplate.naturalIntCoatings.text}</p>
                        <Tooltip id={"iaq-tip-naturalIntCoatings"} />
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={ChbaRadio}
                            name={`${accessor}.optional.naturalIntCoatings`}
                            type="checkbox"
                            // setValue={input}
                        />
                    </div>
                </div>

                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <div
                            data-html
                            data-tip={`<span style="display:block;max-width:14rem;">${iaqTemplate.woodSheeting.tooltip}</span>`}
                            data-for={"iaq-tip-woodSheeting"}
                            className={classes.checklistSectionNumber}
                        >
                            <p>{iaqTemplate.woodSheeting.section}</p>
                        </div>

                        <p>{iaqTemplate.woodSheeting.text}</p>
                        <Tooltip id={"iaq-tip-woodSheeting"} />
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={ChbaRadio}
                            name={`${accessor}.optional.woodSheeting`}
                            type="checkbox"
                            // setValue={input}
                        />
                    </div>
                </div>

                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <div
                            data-html
                            data-tip={`<span style="display:block;max-width:14rem;">${iaqTemplate.lowVocInsulation.tooltip}</span>`}
                            data-for={"iaq-tip-lowVocInsulation"}
                            className={classes.checklistSectionNumber}
                        >
                            <p>{iaqTemplate.lowVocInsulation.section}</p>
                        </div>

                        <p>{iaqTemplate.lowVocInsulation.text}</p>
                        <Tooltip id={"iaq-tip-lowVocInsulation"} />
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={ChbaRadio}
                            name={`${accessor}.optional.lowVocInsulation`}
                            type="checkbox"
                            // setValue={input}
                        />
                    </div>
                </div>

                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <div
                            data-html
                            data-tip={`<span style="display:block;max-width:14rem;">${iaqTemplate.certifiedCarpeting.tooltip}</span>`}
                            data-for={"iaq-tip-certifiedCarpeting"}
                            className={classes.checklistSectionNumber}
                        >
                            <p>{iaqTemplate.certifiedCarpeting.section}</p>
                        </div>

                        <p>{iaqTemplate.certifiedCarpeting.text}</p>
                        <Tooltip id={"iaq-tip-certifiedCarpeting"} />
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={ChbaRadio}
                            name={`${accessor}.optional.certifiedCarpeting`}
                            type="checkbox"
                            // setValue={input}
                        />
                    </div>
                </div>

                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <div
                            data-html
                            data-tip={`<span style="display:block;max-width:14rem;">${iaqTemplate.lowVocFlooring.tooltip}</span>`}
                            data-for={"iaq-tip-lowVocFlooring"}
                            className={classes.checklistSectionNumber}
                        >
                            <p>{iaqTemplate.lowVocFlooring.section}</p>
                        </div>

                        <p>{iaqTemplate.lowVocFlooring.text}</p>
                        <Tooltip id={"iaq-tip-lowVocFlooring"} />
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={ChbaRadio}
                            name={`${accessor}.optional.lowVocFlooring`}
                            type="checkbox"
                            // setValue={input}
                        />
                    </div>
                </div>

                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <div
                            data-html
                            data-tip={`<span style="display:block;max-width:14rem;">${iaqTemplate.lowVocGypsum.tooltip}</span>`}
                            data-for={"iaq-tip-lowVocGypsum"}
                            className={classes.checklistSectionNumber}
                        >
                            <p>{iaqTemplate.lowVocGypsum.section}</p>
                        </div>

                        <p>{iaqTemplate.lowVocGypsum.text}</p>
                        <Tooltip id={"iaq-tip-lowVocGypsum"} />
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={ChbaRadio}
                            name={`${accessor}.optional.lowVocGypsum`}
                            type="checkbox"
                            // setValue={input}
                        />
                    </div>
                </div>

                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <div
                            data-html
                            data-tip={`<span style="display:block;max-width:14rem;">${iaqTemplate.noGasCooking.tooltip}</span>`}
                            data-for={"iaq-tip-noGasCooking"}
                            className={classes.checklistSectionNumber}
                        >
                            <p>{iaqTemplate.noGasCooking.section}</p>
                        </div>

                        <p>{iaqTemplate.noGasCooking.text}</p>
                        <Tooltip id={"iaq-tip-noGasCooking"} />
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={ChbaRadio}
                            name={`${accessor}.optional.noGasCooking`}
                            type="checkbox"
                            // setValue={input}
                        />
                    </div>
                </div>

                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <div
                            data-html
                            data-tip={`<span style="display:block;max-width:14rem;">${iaqTemplate.noFuelFireplaces.tooltip}</span>`}
                            data-for={"iaq-tip-noFuelFireplaces"}
                            className={classes.checklistSectionNumber}
                        >
                            <p>{iaqTemplate.noFuelFireplaces.section}</p>
                        </div>

                        <p>{iaqTemplate.noFuelFireplaces.text}</p>
                        <Tooltip id={"iaq-tip-noFuelFireplaces"} />
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={ChbaRadio}
                            name={`${accessor}.optional.noFuelFireplaces`}
                            type="checkbox"
                            // setValue={input}
                        />
                    </div>
                </div>

                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <div
                            data-html
                            data-tip={`<span style="display:block;max-width:14rem;">${iaqTemplate.ventsSealedConstruction.tooltip}</span>`}
                            data-for={"iaq-tip-ventsSealedConstruction"}
                            className={classes.checklistSectionNumber}
                        >
                            <p>{iaqTemplate.ventsSealedConstruction.section}</p>
                        </div>

                        <p>{iaqTemplate.ventsSealedConstruction.text}</p>
                        <Tooltip id={"iaq-tip-ventsSealedConstruction"} />
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={ChbaRadio}
                            name={`${accessor}.optional.ventsSealedConstruction`}
                            type="checkbox"
                            // setValue={input}
                        />
                    </div>
                </div>

                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <div
                            data-html
                            data-tip={`<span style="display:block;max-width:14rem;">${iaqTemplate.ventsVacuumedConstruction.tooltip}</span>`}
                            data-for={"iaq-tip-ventsVacuumedConstruction"}
                            className={classes.checklistSectionNumber}
                        >
                            <p>{iaqTemplate.ventsVacuumedConstruction.section}</p>
                        </div>

                        <p>{iaqTemplate.ventsVacuumedConstruction.text}</p>
                        <Tooltip id={"iaq-tip-ventsVacuumedConstruction"} />
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={ChbaRadio}
                            name={`${accessor}.optional.ventsVacuumedConstruction`}
                            type="checkbox"
                            // setValue={input}
                        />
                    </div>
                </div>

                {/* Special composite section */}
                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <div
                            data-html
                            data-tip={`<span style="display:block;max-width:14rem;">${iaqTemplate.circulatedAirFiltration.tooltip}</span>`}
                            data-for={"iaq-tip-circulatedAirFiltration"}
                            className={classes.checklistSectionNumber}
                        >
                            <p>{iaqTemplate.circulatedAirFiltration.section}</p>
                        </div>

                        <p>{iaqTemplate.circulatedAirFiltration.text}</p>
                        <Tooltip id={"iaq-tip-circulatedAirFiltration"} />
                    </div>
                    <div className={classes.checklistInner}></div>
                </div>
                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <div className={`${classes.checklistSectionNumber} ${classes.blankSectionNumber}`}>
                            <p> </p>
                        </div>
                        <p>{iaqTemplate.circulatedAirFiltrationMerv10.text}</p>
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={ChbaRadio}
                            name={`${accessor}.optional.circulatedAirFiltrationMerv10`}
                            type="checkbox"
                        />
                    </div>
                </div>
                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <div className={`${classes.checklistSectionNumber} ${classes.blankSectionNumber}`}>
                            <p> </p>
                        </div>
                        <p>{iaqTemplate.circulatedAirFiltrationMerv13.text}</p>
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={ChbaRadio}
                            name={`${accessor}.optional.circulatedAirFiltrationMerv13`}
                            type="checkbox"
                        />
                    </div>
                </div>
                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <div className={`${classes.checklistSectionNumber} ${classes.blankSectionNumber}`}>
                            <p> </p>
                        </div>
                        <p>{iaqTemplate.circulatedAirFiltrationHepa.text}</p>
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={ChbaRadio}
                            name={`${accessor}.optional.circulatedAirFiltrationHepa`}
                            type="checkbox"
                        />
                    </div>
                </div>

                {/* Special composite section */}
                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <div
                            data-html
                            data-tip={`<span style="display:block;max-width:14rem;">${iaqTemplate.estarAirPurifier.tooltip}</span>`}
                            data-for={"iaq-tip-estarAirPurifier"}
                            className={classes.checklistSectionNumber}
                        >
                            <p>{iaqTemplate.estarAirPurifier.section}</p>
                        </div>

                        <p>{iaqTemplate.estarAirPurifier.text}</p>
                        <Tooltip id={"iaq-tip-estarAirPurifier"} />
                    </div>
                    <div className={classes.checklistInner}></div>
                </div>
                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <div className={`${classes.checklistSectionNumber} ${classes.blankSectionNumber}`}>
                            <p> </p>
                        </div>
                        <p>{iaqTemplate.estarAirPurifierLessHalf.text}</p>
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={ChbaRadio}
                            name={`${accessor}.optional.estarAirPurifierLessHalf`}
                            type="checkbox"
                        />
                    </div>
                </div>
                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <div className={`${classes.checklistSectionNumber} ${classes.blankSectionNumber}`}>
                            <p> </p>
                        </div>
                        <p>{iaqTemplate.estarAirPurifierOverHalf.text}</p>
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={ChbaRadio}
                            name={`${accessor}.optional.estarAirPurifierOverHalf`}
                            type="checkbox"
                        />
                    </div>
                </div>

                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <div
                            data-html
                            data-tip={`<span style="display:block;max-width:14rem;">${iaqTemplate.supplyAirTested.tooltip}</span>`}
                            data-for={"iaq-tip-supplyAirTested"}
                            className={classes.checklistSectionNumber}
                        >
                            <p>{iaqTemplate.supplyAirTested.section}</p>
                        </div>

                        <p>{iaqTemplate.supplyAirTested.text}</p>
                        <Tooltip id={"iaq-tip-supplyAirTested"} />
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={ChbaRadio}
                            name={`${accessor}.optional.supplyAirTested`}
                            type="checkbox"
                            // setValue={input}
                        />
                    </div>
                </div>

                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <div
                            data-html
                            data-tip={`<span style="display:block;max-width:14rem;">${iaqTemplate.estarRangeHood.tooltip}</span>`}
                            data-for={"iaq-tip-estarRangeHood"}
                            className={classes.checklistSectionNumber}
                        >
                            <p>{iaqTemplate.estarRangeHood.section}</p>
                        </div>

                        <p>{iaqTemplate.estarRangeHood.text}</p>
                        <Tooltip id={"iaq-tip-estarRangeHood"} />
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={ChbaRadio}
                            name={`${accessor}.optional.estarRangeHood`}
                            type="checkbox"
                            // setValue={input}
                        />
                    </div>
                </div>

                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <div
                            data-html
                            data-tip={`<span style="display:block;max-width:14rem;">${iaqTemplate.multiZonedHvac.tooltip}</span>`}
                            data-for={"iaq-tip-multiZonedHvac"}
                            className={classes.checklistSectionNumber}
                        >
                            <p>{iaqTemplate.multiZonedHvac.section}</p>
                        </div>

                        <p>{iaqTemplate.multiZonedHvac.text}</p>
                        <Tooltip id={"iaq-tip-multiZonedHvac"} />
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={ChbaRadio}
                            name={`${accessor}.optional.multiZonedHvac`}
                            type="checkbox"
                            // setValue={input}
                        />
                    </div>
                </div>

                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <div
                            data-html
                            data-tip={`<span style="display:block;max-width:14rem;">${iaqTemplate.permanentDehumidification.tooltip}</span>`}
                            data-for={"iaq-tip-permanentDehumidification"}
                            className={classes.checklistSectionNumber}
                        >
                            <p>{iaqTemplate.permanentDehumidification.section}</p>
                        </div>

                        <p>{iaqTemplate.permanentDehumidification.text}</p>
                        <Tooltip id={"iaq-tip-permanentDehumidification"} />
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={ChbaRadio}
                            name={`${accessor}.optional.permanentDehumidification`}
                            type="checkbox"
                            // setValue={input}
                        />
                    </div>
                </div>

                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <div
                            data-html
                            data-tip={`<span style="display:block;max-width:14rem;">${iaqTemplate.ervInstalled.tooltip}</span>`}
                            data-for={"iaq-tip-ervInstalled"}
                            className={classes.checklistSectionNumber}
                        >
                            <p>{iaqTemplate.ervInstalled.section}</p>
                        </div>

                        <p>{iaqTemplate.ervInstalled.text}</p>
                        <Tooltip id={"iaq-tip-ervInstalled"} />
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={ChbaRadio}
                            name={`${accessor}.optional.ervInstalled`}
                            type="checkbox"
                            // setValue={input}
                        />
                    </div>
                </div>

                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <div
                            data-html
                            data-tip={`<span style="display:block;max-width:14rem;">${iaqTemplate.estarStandaloneDehumidification.tooltip}</span>`}
                            data-for={"iaq-tip-estarStandaloneDehumidification"}
                            className={classes.checklistSectionNumber}
                        >
                            <p>{iaqTemplate.estarStandaloneDehumidification.section}</p>
                        </div>

                        <p>{iaqTemplate.estarStandaloneDehumidification.text}</p>
                        <Tooltip id={"iaq-tip-estarStandaloneDehumidification"} />
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={ChbaRadio}
                            name={`${accessor}.optional.estarStandaloneDehumidification`}
                            type="checkbox"
                            // setValue={input}
                        />
                    </div>
                </div>

                {/* Special composite section */}
                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <div
                            data-html
                            data-tip={`<span style="display:block;max-width:14rem;">${iaqTemplate.radonMitigation.tooltip}</span>`}
                            data-for={"iaq-tip-radonMitigation"}
                            className={classes.checklistSectionNumber}
                        >
                            <p>{iaqTemplate.radonMitigation.section}</p>
                        </div>

                        <p>Radon mitigation system installed (see note):</p>
                        <Tooltip id={"iaq-tip-radonMitigation"} />
                    </div>
                    <div className={classes.checklistInner}></div>
                </div>
                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <div className={`${classes.checklistSectionNumber} ${classes.blankSectionNumber}`}>
                            <p> </p>
                        </div>

                        <p>{iaqTemplate.radonMitigationPassive.text}</p>
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={ChbaRadio}
                            name={`${accessor}.optional.radonMitigationPassive`}
                            type="checkbox"
                        />
                    </div>
                </div>
                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <div className={`${classes.checklistSectionNumber} ${classes.blankSectionNumber}`}>
                            <p> </p>
                        </div>
                        <p>{iaqTemplate.radonMitigationActive.text}</p>
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={ChbaRadio}
                            name={`${accessor}.optional.radonMitigationActive`}
                            type="checkbox"
                        />
                    </div>
                </div>

                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <div
                            data-html
                            data-tip={`<span style="display:block;max-width:14rem;">${iaqTemplate.iaqMonitoring.tooltip}</span>`}
                            data-for={"iaq-tip-iaqMonitoring"}
                            className={classes.checklistSectionNumber}
                        >
                            <p>{iaqTemplate.iaqMonitoring.section}</p>
                        </div>

                        <p>{iaqTemplate.iaqMonitoring.text}</p>
                        <Tooltip id={"iaq-tip-iaqMonitoring"} />
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={ChbaRadio}
                            name={`${accessor}.optional.iaqMonitoring`}
                            type="checkbox"
                            // setValue={input}
                        />
                    </div>
                </div>
            </div>

            <div className={classes.topRow}>
                <div></div>
                {/* <Button smallPadding icon={Export} className={netZeroClasses.exportButton}>
                    <span>Export Net Zero Workbook </span>
                </Button> */}
            </div>
        </>
    );
};

export default IndoorAirQuality;
