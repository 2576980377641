import React from 'react';
import classes from '../style.module.scss';
import isEqual from 'lodash/isEqual';
import Door from './Door/container';
import Window from './Window/container';

export default React.memo(({
    cancel,
    onSet,
    type,
    shape,
    transformer,
    modelChange
}) => {
    return (
        <div className={classes.fields}>
            {
                type === 'door' &&
                    <Door
                        cancel={cancel}
                        onSet={onSet}
                        shape={shape}
                        transformer={transformer}
                        modelChange={modelChange}
                    />
            }
            {
                type === 'window' &&
                    <Window
                        cancel={cancel}
                        onSet={onSet}
                        shape={shape}
                        transformer={transformer}
                        modelChange={modelChange}
                    />
            }
        </div>
    )
},isEqual)