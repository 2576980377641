import React from 'react';

export default () => (
    <svg width="28px" height="23px" viewBox="0 0 28 23" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="Side-Bar-/-Open" transform="translate(-30.000000, -229.000000)" fill="#F0F8FD" fillRule="nonzero">
            <g id="Main-Side-Bar" transform="translate(-1.000000, 0.000000)">
                <g id="Nav-Items" transform="translate(0.000000, 86.000000)">
                    <g id="Group-5" transform="translate(31.000000, 91.000000)">
                        <g id="Group" transform="translate(0.000000, 52.000000)">
                            <path d="M10.75,0 L12.7,3 L28,3 L28,23 L0,23 L0,3 L2.3,3 L4.25,0 L10.75,0 Z M9.665,2 L5.334,2 L3.38537209,5 L2,5 L2,21 L26,21 L26,5 L11.6146279,5 L9.665,2 Z M6.5,15.5 C7.32842712,15.5 8,16.1715729 8,17 C8,17.8284271 7.32842712,18.5 6.5,18.5 C5.67157288,18.5 5,17.8284271 5,17 C5,16.1715729 5.67157288,15.5 6.5,15.5 Z M23,16 L23,18 L10,18 L10,16 L23,16 Z M6.5,11.5 C7.32842712,11.5 8,12.1715729 8,13 C8,13.8284271 7.32842712,14.5 6.5,14.5 C5.67157288,14.5 5,13.8284271 5,13 C5,12.1715729 5.67157288,11.5 6.5,11.5 Z M23,12 L23,14 L10,14 L10,12 L23,12 Z M6.5,7.5 C7.32842712,7.5 8,8.17157288 8,9 C8,9.82842712 7.32842712,10.5 6.5,10.5 C5.67157288,10.5 5,9.82842712 5,9 C5,8.17157288 5.67157288,7.5 6.5,7.5 Z M23,8 L23,10 L10,10 L10,8 L23,8 Z" id="Combined-Shape"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)