import React from "react";
import classes from "./style.module.scss";
import sharedClasses from "../style.module.scss";

export default () => {
    return (
        <>
            <p>
                Use the Enclosure Tab to input information about the construction details of your building. In the
                enclosure tab, building envelope components (basements, walls, ceilings, etc.) can be added, deleted,
                and edited. This tab organizes components by house storey and allows users to quickly review high-level
                component details at a glance. You can also access building geometry details at the top of the tab.
            </p>
            <h3>What is different from HOT2000?</h3>
            <p>
                <strong>Data entry modes and Takeoff Inputs. </strong>Building geometry can be defined in two main ways,
                either using traditional HOT2000 components, or by using the "Takeoff" inputs accessed by changing the
                data entry mode to "Takeoff" or "Combined". Takeoff inputs are organized by storey of the home, and each
                storey contains three main sections:
            </p>
            <ul>
                <li>
                    <strong>Common takeoff fields.</strong> House geometry input fields that are commonly required to
                    define a given storey of a home (e.g. floor plan area, total perimeter)
                </li>
                <li>
                    <strong>Optional takeoff components.</strong> Click the "Add Takeoff Component" button to add
                    optional features to that storey of the home (e.g. basement pony walls, cathedral ceilings, etc.)
                </li>
                <li>
                    <strong>Window inputs.</strong> Enter window data manually via inputs organized in a table, or open
                    the "Window Table Inputs" drawer to copy and paste tabular window data
                </li>
            </ul>
            <p>
                Once a takeoff section has been fully defined, click "Convert Takeoff" in the section header to convert
                all of the geometry inputs into HOT2000 components. Note that the data entry mode must be set to either
                "HOT2000" or "Combined" to view HOT2000 components. Additionally, when the Convert Takeoff function
                calculates the floor area, volume, and height of highest ceiling associated with a given storey of the
                home, automatically compiling heights, areas, and volumes to calculate overall values for the home.
            </p>
            <p>
                <strong>Model Settings and Defaults.</strong> Click the gear icon at the top of the screen to edit
                settings for your model and define default construction types for all of your components. If a default
                type is defined for a component, every time a new component of that type is created it will be
                pre-populated with the specified default type. This also applies when creating HOT2000 components via
                the Convert Takeoff button.
            </p>
            <p>
                <strong>Component Organization.</strong> You can organize envelope components based on building storey.
                Storey sections are automatically updated based on the selection made in the Storeys drop-down menu. The
                &#34;Other&#34; category is always present and can be used for any envelope components that do not fit
                cleanly into a single storey category. You can freely drag-and-drop components between categories by
                clicking and dragging the arrow icon on the component header. Note that foundation components can only
                be added to the &#34;Foundation&#34; category and cannot be moved into other storeys.
            </p>
            <p>
                When importing an h2k file, only foundation components are sorted into the Foundation category. All
                other components must me manually sorted.
            </p>
            <p>
                <strong>Geometry Summary Fields.</strong> At the top of the Enclosure Tab page are four fields that
                summarize key features of the building.
            </p>
            <p>
                The three leftmost fields, Above and Below Grade Heated Floor Area and Total House Volume, are handled
                similarly. Users can click the edit icon to view a breakdown of each of these parameters in the
                Calculator drawer. From this drawer, users can add or subtract sections of floor area or volume. If you
                are inputting data via takeoff inputs, the convert takeoff function will automatically update these
                fields.
            </p>
            <p>
                Additional volume sections can be added manually as known volumes (m3 or ft3), or as sections of floor
                area multiplied by a ceiling height.
            </p>
            <p>
                The Window-to-Wall Ratio for the building is displayed on the right of the geometry summary section.
                This field is not editable but is automatically updated when new components are added to the model.
            </p>
            <p>
                <strong>Summary Stats.</strong> All building envelope components are collapsible. Their details can be
                adjusted when expanded, and key, high-level information is displayed when collapsed. This allows users
                to quickly browse through many components at once and access key information without needing to expand
                the component.
            </p>
            <p>
                <strong>Unit Conversions.</strong> While the overall display unit system can be modified through the{" "}
                Primary Display Units field, any unit-based field can be freely changed between other common units for
                that type of measurement. For example, most length fields can freely change between mm, cm, m, in, and
                ft. In some cases, fields have their options restricted for simplicity (heated floor areas cannot be
                defined in terms of mm², for example).
            </p>
            <p>
                <strong>Apply Codes to Multiple Components.</strong> When creating or editing a code, use the "apply to
                multiple components" multi-select drop-down menu to apply that code to one, all, or a subset of
                components of the same type.
            </p>
            <h3>Known Issues and Limitations</h3>
            <ul>
                <li>
                    All R-values associated with code selectors, aside from window R-values, are nominal values. Windows
                    display the effective R-values for a 500mm x 500mm window as determined by HOT2000. Take careful
                    note of the R-value field label, as this will tell you whether the nominal or effective R-value is
                    displayed.
                </li>
            </ul>
        </>
    );
};
