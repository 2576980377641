import React, { useState, useRef, useEffect } from "react";
import IDGenerator from "../TakeoffData/IDGenerator";

const withTable =
    (Component) =>
    ({ addTableComponent, dataset, removeTableComponent, editTableComponent, modelUnits, hideAddRow }) => {
        const tId = useRef(Math.random());
        const initialRowState = {
            title: {
                value: "", //? This window value is there so the row starts with a valid input. Maybe you don't want that, so feel free to change it to ''
                // unit: "count",
            },
            width: {
                value: 0,
                // unit: "m",
            },
            height: {
                value: 0,
                // unit: "m",
            },
            ovWidth: {
                value: 0,
                // unit: "m",
            },
            ovHeight: {
                value: 0,
                // unit: "m",
            },
            qty: {
                value: 1,
                // unit: "count",
            },
        };

        const [rowError, setRowError] = useState(true);
        const [newRow, setNewRow] = useState(initialRowState);

        useEffect(() => {
            let error = false;
            for (const key in newRow) {
                if (key === "title") {
                    if (newRow[key].value === "") {
                        error = true;
                    }
                } else if (key === "qty") {
                    if (newRow[key].value === 0 || isNaN(newRow[key].value)) {
                        error = true;
                    }
                } else if (key === "ovHeight" || key === "ovWidth") {
                    if (newRow[key].value < -30 || newRow[key].value > 30) {
                        error = true;
                    }
                } else {
                    if (newRow[key].value <= 0 || newRow[key].value > 50000) {
                        error = true;
                    }
                }
            }
            setRowError(error);
        }, [newRow]);

        const handleCellChange = (key, value) => {
            let row = newRow;
            row[key].value = value;
            setNewRow({ ...row });
        };

        const handleUnitChange = (key, value, unit) => {
            let row = newRow;
            row[key].value = value;
            row[key].unit = unit;
            setNewRow({ ...row });
        };

        const addNewRow = () => {
            let row = newRow;
            row.id = IDGenerator("toff-table");
            addTableComponent(row);
            setNewRow(initialRowState);
        };

        const tableActions = {
            tId,
            newRow,
            setNewRow,
            handleCellChange,
            addNewRow,
            handleUnitChange,
            rowError,
            dataset,
            removeTableComponent,
            editTableComponent,
            modelUnits,
            hideAddRow,
        };

        return <Component {...tableActions} />;
    };

export default withTable;
