import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import F280Summary from "./";
import { actions as resultsActions } from "../Results/_ducks";
import { categories } from "utils/enclosure";
import { getOptions } from "utils/fields";

const { getResults } = resultsActions;

const mapStateToProps = ({
    model: {
        modelId,
        modelDetails: { name, notes, simulationRunning = false, lastCliRun: { cliId = {} } = {} } = {},
    } = {},
    upgrades: { packages = {} } = {},
    results: { packageResults = {}, selectedPackage = "base", loading = false, error = false } = {},
    user: {
        uid,
        name: userName = "",
        email = "",
        userMeta: { company = "", role = "", experienceLevel = "", agreedToTerms = false } = {},
        userPreferences: {
            userPhone = "",
            eaId = "",
            companyPhone = "",
            province: { id: provinceId } = {},
            preferredUnits = "",
            preferredProgramType = "",
            companyAddress = "",
            companyCity = "",
            companyPostalCode = "",
            companyFax = "",
        } = {},
    } = {},
    form,
}) => {
    const selector = formValueSelector("model");

    const { default: { runId: defaultId = "" } = {}, ...rest } = cliId;
    const id = defaultId || rest[Object.keys(rest)[0]]?.runId;

    const { base: { metadata: { mode, completedAt = "" } = {} } = {} } = packageResults || {};

    const modelUnits = selector({ form }, "modelData.uiSettings.primaryUnits") || {};

    const { id: numStoreysId } = selector({ form }, "modelData.specifications.numStoreys") || {};

    const storeyCategories = categories(numStoreysId);

    let cityProvinceLabel = "";
    if (companyCity != "") {
        cityProvinceLabel = companyCity;
    }
    if (provinceId != null) {
        const provLabel =
            getOptions({ fieldKey: "weatherRegion", version: "v11-11" }).find((opt) => opt?.value?.id === provinceId)
                ?.label || "";

        cityProvinceLabel = `${cityProvinceLabel}${cityProvinceLabel !== "" ? ", " : ""}${provLabel}`;
    }

    const calcPerformedBy = {
        userName,
        company,
        companyAddress,
        cityProvince: cityProvinceLabel,
        companyPostalCode,
        companyPhone,
        companyFax,
        email,
    };

    return {
        modelId,
        name,
        notes,
        modelUnits,
        resultsPackages: cliId,
        selectedPackage,
        packageInfo: packages,
        packageResultsData: packageResults,
        hasResults: !!mode,
        loading,
        error,
        cliId: id,
        clidIds: cliId,
        completedAt,
        simulationRunning,
        storeyCategories,
        calcPerformedBy,
    };
};

const mapDispatchToProps = (dispatch) => ({
    getResults: ({ cliIds, modelId }) => dispatch(getResults({ cliIds, modelId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(F280Summary);
