import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { setActiveImageIndex } from "../_ducks/actions";

import { selectActiveImageIndex, selectImageData } from "../_ducks/selector";

import { components, heading } from "./style.module.scss";

import ListComponents from "./ListComponents";

const Components = ({
    imageData,
    activeImageIndex,
    setActiveImageIndex,
    images,
    stage,
    layer,
}) => {
    const currentImage =
        images.length > 0 ? images[activeImageIndex].fileName : null;
    const currentImageData = currentImage ? imageData[currentImage] : null;

    return (
        <div className={components}>
            <div className={heading}>
                <h3>Elements</h3>
            </div>
            <div>
                {images &&
                    images.map((image, index) => (
                        <ListComponents
                            key={index}
                            setActivePage={(i) => setActiveImageIndex(i)}
                            activePageIndex={activeImageIndex}
                            pageIndex={index}
                            currentImageData={currentImageData}
                            currentImage={currentImage}
                            stage={stage}
                            layer={layer}
                            imageUrl={image.signedURL}
                        />
                    ))}
            </div>
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    activeImageIndex: selectActiveImageIndex,
    imageData: selectImageData,
});

const mapDispatchToProps = (dispatch) => ({
    setActiveImageIndex: (index) => dispatch(setActiveImageIndex(index)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Components);
