import types from "./types";
import { getStandardRVal } from "utils/enclosure/api";

const setInitCode = (initCodeValues, codeType) => ({
  type: types.SET_INIT_CODE,
  initCodeValues,
  codeType,
});

const clearInitCode = () => ({
  type: types.CLEAR_INIT_CODE,
});

const updateDrawingChanges = ({ componentId, updates }) => ({
  type: types.UPDATE_DRAWING_CHANGES,
  componentId,
  updates,
});

const clearEnclosureState = () => ({
  type: types.CLEAR_ENCLOSURE_STATE,
});

const calculatingRValue = ({ componentId, fieldId, calculating }) => ({
  type: types.CACLULATING_R_VALUE,
  componentId,
  fieldId,
  calculating,
});

const updateCodeDrawer = ({ updates }) => ({
  type: types.UPDATE_CODE_DRAWER,
  updates,
});

const getRValue =
  ({ codeString, codeType, componentId, fieldId }) =>
  async (dispatch) => {
    await dispatch(
      calculatingRValue({ componentId, fieldId, calculating: true })
    );
    return getStandardRVal({ codeString, codeType }).then(
      ({
        data: {
          rval: { nominalRVal = 0 } = {},
          warning: { type = "" } = {},
        } = {},
      } = {}) => {
        dispatch(
          calculatingRValue({ componentId, fieldId, calculating: false })
        );
        return {
          rVal: parseFloat(nominalRVal.toFixed(2)),
          warningType: type,
        };
      }
    );
  };

const getWindowRValue =
  ({ codeString, codeType, componentId, fieldId }) =>
  async (dispatch) => {
    await dispatch(
      calculatingRValue({ componentId, fieldId, calculating: true })
    );
    return getStandardRVal({ codeString, codeType }).then(
      ({
        data: {
          rval: { rValue = 0, er = 0, shgc = 0 } = {},
          warning: { english: enWindowWarning = "" } = {},
        } = {},
      } = {}) => {
        dispatch(
          calculatingRValue({ componentId, fieldId, calculating: false })
        );
        return {
          rVal: parseFloat(rValue.toFixed(4)),
          windowEr: parseFloat(er.toFixed(4)),
          windowShgc: parseFloat(shgc.toFixed(4)),
        };
      }
    );
  };

const updateCompositeCalc = (updates) => ({
  type: types.UPDATE_COMPOSITE_CALC,
  updates,
});

const clearCompositeCalc = (updates) => ({
  type: types.CLEAR_COMPOSITE_CALC,
});

const updateFoundationConfig = (updates) => ({
  type: types.UPDATE_FOUNDATION_CONFIG,
  updates,
});

const clearFoundationConfig = (updates) => ({
  type: types.CLEAR_FOUNDATION_CONFIG,
});

export default {
  setInitCode,
  updateDrawingChanges,
  clearEnclosureState,
  calculatingRValue,
  getRValue,
  getWindowRValue,
  clearInitCode,
  updateCodeDrawer,
  updateCompositeCalc,
  clearCompositeCalc,
  updateFoundationConfig,
  clearFoundationConfig,
};
