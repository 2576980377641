import React, { useState, useCallback } from "react";
import classes from "./style.module.scss";
import sharedClasses from "features/Model/sharedStyles.module.scss";
import { Field } from "redux-form";
import { required, requiredId, maxLength, getValidation, getDecimalPlaces } from "utils/fieldValidation";
import { CSSTransition, SwitchTransition } from "react-transition-group";

import { getOptions, getFirstOption, getBaseUnits, getUnitOptions } from "utils/fields";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import InputWithUnits from "components/Input/InputWithUnits";
import Select from "components/Input/Select";
import SelectWithInput from "components/Input/SelectWithInput";
import DayPicker from "components/Input/DayPicker";
import { BuildingIcon } from "utils/thumbnails/buildingTypes";
import Compass from "components/Compass";
import Accordion from "components/Accordion";
import HouseFileInfo from "./HouseFileInfo/container";
import ClientInfo from "./ClientInfo/container";
import ChbaNzFile from "./ChbaNzFile/container";
import Specifications from "./Specifications/container";
import FuelPrices from "./FuelPrices/container";
import MurbSpecs from "./MurbSpecs/container";
import Button from "components/Button";
import Details from "./Details";
import TabHeader from "components/TabHeader";
import DetailsDrawer from "components/DetailsDrawer";
import OpenIcon from "assets/images/icons/JSX/Open";
import ModelTags from "components/ModelTags";
import { isEqual, isEmpty } from "lodash";
import { getMinVentRate } from "utils/buildingCode";
import {
    getDefaultF280Option,
    getF280WeatherOpts,
    getF280DesignHeatingTemp,
    getF280DesignCoolingTemp,
} from "utils/weather";

import { getProgramType } from "utils/evaluationProgram";

const charLim100 = maxLength(100);
const yearBuiltValidation = [required, ...getValidation("yearBuilt")];
const evaluationDateValidation = getValidation("evaluationDate");
const uiUnitsValidation = getValidation("uiUnits");
const versionMinorValidation = getValidation("h2kVersionMinor");
const programClassValidation = getValidation("programClass");
const buildingTypeValidation = getValidation("buildingType");
const orientationValidation = getValidation("facingDirection");
const buildingSubTypeValidation = getValidation("buildingSubType");
const baseRegionValidation = getValidation("weatherRegion");
const v11_10_RegionValidation = getValidation("weatherRegion", "v11-10");
const baseLocationValidation = getValidation("weatherLocation");
const v11_10_locationValidation = getValidation("weatherLocation", "v11-10");
const wthLibValidation = getValidation("weatherLibrary");
const depthOfFrostValidation = getValidation("depthOfFrostline");

export default React.memo(
    ({
        modelId,
        uid,
        modelSave,
        buildingTypeId,
        buildingSubTypeId,
        facingId,
        change,
        weatherProvTerrId,
        f280LocationId,
        depthOfFrostUnits,
        modelUnits,
        h2kVersion,
        h2kMinor,
        programClass = "",
        vermiculiteValue,
        bedroomsEntered,
        floorArea,
        f280Warning,
        contentOpen,
        CHBA_NZEA,
        selectedChbaLabelType,
        addTagToModelFile,
        readOnly,
        nonErsUser,
        murbUnitCount,
    }) => {
        const [open, toggleOpen] = useState(false);

        const handleVersionChange = useCallback(
            (value) => {
                //where value is the value that we're changing to, h2kMinor is what we're coming from
                if ([10, 11, 12].includes(h2kMinor) && value === 9) {
                    //From new to old
                    change("modelData.location.weather.region", {});
                    change("modelData.location.weather.location", {});
                    change("modelData.location.weather.library", "Wth110.dir");
                } else if ([10, 11, 12].includes(value) && h2kMinor === 9) {
                    //From old to new
                    change("modelData.location.weather.region", {});
                    change("modelData.location.weather.location", {});
                    change("modelData.location.weather.library", "Wth2020.dir");
                }

                if (h2kMinor === 12 && programClass.includes("ERS2020NBC")) {
                    change("modelData.program.class", "ca.nrcan.gc.OEE.ERS.ErsProgram");
                    change("modelData.program.type", "ers");
                }
            },
            [h2kVersion, h2kMinor, change]
        );

        const handleBuildingTypeChange = useCallback(
            ({ id }) => {
                change(
                    "modelData.specifications.buildingSubType",
                    getFirstOption({ indKey: id, depKey: "buildingSubType" })
                );

                //Handle General MURB Section
                if (id === 2) {
                    //Whole building
                    change("modelData.specifications.murbSpecs.numDwellingUnits", 2);
                    change("modelData.specifications.murbSpecs.numNonResUnits", 0);
                    change("modelData.specifications.murbSpecs.numUnitsVisited", 1);
                } else if (id === 1) {
                    //Single unit murb
                    change("modelData.specifications.murbSpecs.numStoreysInBuilding", 1);
                    change("modelData.specifications.murbSpecs.numDwellingUnits", 1);
                    change("modelData.specifications.murbSpecs.numNonResUnits", 1);
                } else {
                    //House
                    change("modelData.specifications.murbSpecs", {});
                }

                //Handle DHW MURB section
                if (id === 2) {
                    //Whole building MURB, must add section to DHW
                    change(`modelData.domesticHotWater.murb`, {
                        murbNumDwhrSystems: {
                            lowEfficiency: 0,
                            highEfficiency: 0,
                        },
                        murbNumHotWaterSystems: {
                            eStarInstantaneousCondensing: 0,
                            eStarInstantaneous: 0,
                            condensing: 0,
                            instantaneous: 0,
                        },
                    });

                    if (h2kMinor >= 11) {
                        change(`modelData.domesticHotWater.murb.murbNumHotWaterSystems.heatPumpWaterHeater`, 0);
                    }
                } else {
                    change(`modelData.domesticHotWater.murb`, {});
                }
            },
            [buildingTypeId, h2kMinor]
        );

        const handleBuildingCodeChange = (value) => {
            change(
                "modelData.ventilation.requirements.minBedroomVentilation",
                getMinVentRate(value, bedroomsEntered, floorArea)
            );
        };

        const regionValidation = h2kVersion === "v11-9" ? baseRegionValidation : v11_10_RegionValidation;
        const locationValidation = h2kVersion === "v11-9" ? baseLocationValidation : v11_10_locationValidation;

        let weatherLibOpts = [{}];
        if (h2kVersion === "v11-9") {
            weatherLibOpts = [
                {
                    value: "Wth110.dir",
                    label: "Wth110.dir",
                },
            ];
        } else {
            //H2k version > 11.9
            weatherLibOpts = [
                {
                    value: "Wth2020.dir",
                    label: "Wth2020.dir",
                },
            ];
        }

        return (
            <>
                <TabHeader
                    title="General Information"
                    description="High-level information about your model, including HOT2000 Version, Building Type, and Weather Data."
                    detailsTrigger={() => toggleOpen(true)}
                    detailsText="General Tab Details"
                />
                <div className={sharedClasses.section}>
                    <DetailsDrawer open={open} close={() => toggleOpen(false)} title="General Tab Details">
                        <Details />
                        <div className={classes.buttons}>
                            <Button large type="hollow" onClick={() => toggleOpen(false)}>
                                Close
                            </Button>
                        </div>
                        <span></span>
                    </DetailsDrawer>
                    <InputRow gridTemplate="66% 31.85%">
                        <Field
                            component={Input}
                            name="modelDetails.name"
                            type="text"
                            label="Model Name"
                            placeholder="Name Your Model"
                            validate={[required, charLim100]}
                        />
                        <Field
                            component={Select}
                            name="modelData.h2kVersion.minor"
                            options={[
                                {
                                    value: 9,
                                    label: "v11.9",
                                },
                                {
                                    value: 10,
                                    label: "v11.10",
                                },
                                {
                                    value: 11,
                                    label: "v11.11",
                                },
                                {
                                    value: 12,
                                    label: "v11.12",
                                },
                            ]}
                            label="HOT2000 Version"
                            placeholder="Choose Compatible HOT2000 Version"
                            validate={versionMinorValidation}
                            onChange={handleVersionChange}
                        />
                    </InputRow>
                    <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                        <Field
                            component={DayPicker}
                            formatting="YYYY-MM-DD"
                            name="modelData.general.file.evaluationDate"
                            label="Evaluation Date"
                            validate={evaluationDateValidation}
                            info={"Enter the evaluation date, not the date of data entry into the software."}
                        />
                        <Field
                            component={Select}
                            name="modelData.uiSettings.primaryUnits"
                            options={[
                                {
                                    value: "Metric",
                                    label: "Metric",
                                },
                                {
                                    value: "Imperial",
                                    label: "Imperial",
                                },
                                {
                                    value: "US",
                                    label: "US",
                                },
                            ]}
                            label="Primary Display Units"
                            placeholder="Choose Display Units"
                            validate={uiUnitsValidation}
                            info={
                                "This field will change the overall display units for your model, but you will still be able to change the units for individual fields."
                            }
                        />
                        <Field
                            component={Select}
                            name="modelData.specifications.buildingCode.type"
                            options={[
                                {
                                    value: "NBC",
                                    label: "NBC",
                                },
                                {
                                    value: "OBC",
                                    label: "OBC",
                                },
                                {
                                    value: "BCBC",
                                    label: "BCBC",
                                },
                            ]}
                            label="Building Code"
                            placeholder="Choose Your Building Code"
                            onChange={handleBuildingCodeChange}
                            info="Currently only used to determine the minimum ventilation rate. More features coming soon!"
                        />
                        <Field
                            component={Select}
                            name="modelData.program.class"
                            options={[
                                {
                                    value: "",
                                    label: "General",
                                },
                                {
                                    value: "ca.nrcan.gc.OEE.ERS.ErsProgram",
                                    label: "EnerGuide Rating System",
                                },
                                ...(h2kMinor >= 12
                                    ? [
                                          {
                                              value: "ca.nrcan.gc.OEE.ERS2020NBC.ErsProgram",
                                              label: "EnerGuide Rating System 2020 NBC",
                                          },
                                      ]
                                    : []),
                                {
                                    value: "ca.nrcan.gc.OEE.ONrh.OnProgram",
                                    label: "Ontario Reference House",
                                },
                            ]}
                            label="Program Type"
                            placeholder="None"
                            validate={programClassValidation}
                            onChange={(value) => {
                                const programType = getProgramType(value);
                                change("modelData.program.type", programType);

                                if (programType !== "" && isEmpty(vermiculiteValue)) {
                                    change(`modelData.program.options.main.vermiculitePresence`, { id: 0 });
                                }
                            }}
                            warning={nonErsUser && programClass !== "" ? "nonErsUserProgramSelection" : ""}
                        />
                    </InputRow>
                    <div className={classes.buildingType}>
                        <SwitchTransition mode="out-in">
                            <CSSTransition
                                key={buildingSubTypeId}
                                classNames={{
                                    enter: classes.imageEnter,
                                    enterActive: classes.imageEnterActive,
                                    exit: classes.imageExit,
                                    exitActive: classes.imageExitActive,
                                }}
                                timeout={300}
                            >
                                <div className={classes.buildingDiagram}>
                                    <img
                                        src={BuildingIcon(buildingSubTypeId).img}
                                        alt={BuildingIcon(buildingSubTypeId).alt}
                                    />
                                </div>
                            </CSSTransition>
                        </SwitchTransition>
                        <div className={classes.buildingFields}>
                            <InputRow gridTemplate="1fr 1fr">
                                <Field
                                    component={Select}
                                    name="modelData.specifications.buildingType"
                                    options={getOptions({
                                        fieldKey: "buildingType",
                                    })}
                                    label="Building Type"
                                    placeholder="Choose Building Type"
                                    onChange={handleBuildingTypeChange}
                                    validate={buildingTypeValidation}
                                    warning={buildingTypeId !== 0 && murbUnitCount < 2 ? "murbUnitMismatch" : null}
                                />
                                <div className={classes.orientation}>
                                    <Compass facingId={facingId} />
                                    <Field
                                        component={Select}
                                        name="modelData.specifications.facingDirection"
                                        options={getOptions({
                                            fieldKey: "facingDirection",
                                        })}
                                        label="Facing Direction"
                                        placeholder="Choose Facing Direction"
                                        validate={orientationValidation}
                                        info={"The direction that the front face of the house is oriented towards."}
                                    />
                                </div>
                            </InputRow>
                            <InputRow gridTemplate="1fr 1fr" lastRow>
                                <Field
                                    component={Select}
                                    name="modelData.specifications.buildingSubType"
                                    options={getOptions({
                                        fieldKey: "buildingSubType",
                                        indKey: buildingTypeId,
                                    })}
                                    label="Building Sub-Type"
                                    placeholder="Choose Building Sub-Type"
                                    validate={buildingSubTypeValidation}
                                />
                                <Field
                                    component={SelectWithInput}
                                    inputData={{
                                        triggerValue: 0,
                                        inputLabel: "Year",
                                        inputType: "number",
                                        inputPlaceholder: "YYYY",
                                    }}
                                    name="modelData.specifications.yearBuilt"
                                    options={getOptions({
                                        fieldKey: "yearBuilt",
                                    })}
                                    label="Year Built"
                                    placeholder="Choose Year Built"
                                    validate={yearBuiltValidation}
                                />
                            </InputRow>
                        </div>
                    </div>
                    <div className={classes.locationInfo}>
                        <h3 style={{ marginBottom: "1rem", marginTop: "1.875rem" }}>
                            Weather and Location Information
                        </h3>
                        <a
                            rel="noopener noreferrer"
                            href="https://nrcan-rncan.maps.arcgis.com/apps/webappviewer/index.html?id=1b218b42e6cd4c1e866003fce58e7256"
                            target="_blank"
                        >
                            See the climate map <OpenIcon />
                        </a>
                    </div>
                    <InputRow gridTemplate="1fr 1fr 1fr">
                        <Field
                            component={Select}
                            name="modelData.location.weather.region"
                            options={getOptions({ fieldKey: "weatherRegion", version: h2kVersion })}
                            label="Province or Territory"
                            placeholder="Choose Province or Territory"
                            validate={[requiredId, ...regionValidation]}
                            search={true}
                            onChange={({ id }) => {
                                if (id === 5) {
                                    change("modelData.specifications.buildingCode.type", "OBC");
                                } else if (id === 1) {
                                    change("modelData.specifications.buildingCode.type", "BCBC");
                                } else {
                                    change("modelData.specifications.buildingCode.type", "NBC");
                                }
                                change("modelData.location.weather.location", {});
                            }}
                            warning={
                                programClass.includes("ONrh") && weatherProvTerrId !== 5
                                    ? "orhModeLocationMismatch"
                                    : ""
                            }
                        />
                        <Field
                            component={Select}
                            name="modelData.location.weather.location"
                            options={getOptions({
                                fieldKey: "weatherLocation",
                                indKey: weatherProvTerrId,
                                version: h2kVersion,
                            })}
                            label="Location"
                            placeholder="Choose Location"
                            validate={[requiredId, ...locationValidation]}
                            search={true}
                            onChange={({ id }) =>
                                change("modelData.location.weather.f280.location.id", getDefaultF280Option(id))
                            }
                        />
                        <Field
                            component={Input}
                            type="number"
                            name={"modelData.location.weather.location.value"}
                            label="Heating Degree Days"
                            placeholder={0.0}
                            validate={locationValidation}
                            decimals={getDecimalPlaces("weatherLocation")}
                            disabled={true}
                        />
                    </InputRow>
                    <InputRow gridTemplate="66% 31.85%">
                        <Field
                            component={Select}
                            name="modelData.location.weather.library"
                            options={weatherLibOpts}
                            label="Weather Library"
                            placeholder="Choose Weather Library"
                            validate={wthLibValidation}
                        />
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={"modelData.location.weather.depthOfFrostline"}
                            label="Depth of Frostline"
                            placeholder="0.00"
                            validate={depthOfFrostValidation}
                            decimals={getDecimalPlaces("depthOfFrostline")}
                            change={change}
                            units={{
                                base: getBaseUnits("depthOfFrostline", modelUnits),
                                options: getUnitOptions("length"),
                                selected: depthOfFrostUnits,
                                unitType: "length",
                                accessor: "modelData.location.weather.depthOfFrostline_u",
                            }}
                        />
                    </InputRow>
                    <InputRow gridTemplate="1fr 1fr 1fr">
                        <Field
                            component={Select}
                            type="number"
                            name={"modelData.location.weather.f280.location"}
                            label="CSA F280-12 Design Conditions"
                            placeholder={"Select a F280 Design Conditions"}
                            options={getF280WeatherOpts(weatherProvTerrId)}
                            nonH2kParam="f280Weather"
                            search={true}
                            warning={f280Warning}
                            onChange={() => change("modelData.location.weather.f280.location_warning", "")}
                        />
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={"modelData.location.weather.f280.dhdbt"}
                            label="Heating Design Temperature"
                            placeholder={0.0}
                            setValue={getF280DesignHeatingTemp(f280LocationId)}
                            decimals={getDecimalPlaces("weatherLocation")}
                            disabled={true}
                            units={{
                                base: getBaseUnits("ashpTempCutoff", modelUnits),
                                options: getUnitOptions("temperature"),
                                selected: "C",
                                unitType: "temperature",
                                accessor: "modelData.location.weather.f280.dhdbt_u",
                            }}
                        />
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={"modelData.location.weather.f280.dcdbt"}
                            label="Cooling Design Temperature"
                            placeholder={0.0}
                            setValue={getF280DesignCoolingTemp(f280LocationId)}
                            decimals={getDecimalPlaces("weatherLocation")}
                            disabled={true}
                            units={{
                                base: getBaseUnits("ashpTempCutoff", modelUnits),
                                options: getUnitOptions("temperature"),
                                selected: "C",
                                unitType: "temperature",
                                accessor: "modelData.location.weather.f280.dcdbt_u",
                            }}
                        />
                        {/* <Field
                        component={Input}
                        type="number"
                        name={'modelData.location.weather.f280.hdd'}
                        label="HDD"
                        placeholder={0.00}
                        setValue={3520}
                        decimals={getDecimalPlaces('weatherLocation')}
                        disabled={true}
                    /> */}
                    </InputRow>
                    <Accordion
                        className={sharedClasses.accordion}
                        heading={<span className={sharedClasses.heading}>House File Information</span>}
                        large
                        forceOpen={contentOpen}
                    >
                        <HouseFileInfo accessor={"modelData.general.file"} change={change} />
                    </Accordion>
                    <Accordion
                        className={sharedClasses.accordion}
                        heading={<span className={sharedClasses.heading}>Client Information</span>}
                        large
                        forceOpen={contentOpen}
                    >
                        <ClientInfo accessor={"modelData.general.client"} change={change} />
                    </Accordion>
                    {CHBA_NZEA && (
                        <Accordion
                            className={sharedClasses.accordion}
                            heading={
                                <span className={sharedClasses.heading}>
                                    <div className={classes.chbaHeading}>
                                        CHBA Net Zero File Details
                                        {selectedChbaLabelType && <ModelTags tag={selectedChbaLabelType} />}
                                    </div>
                                </span>
                            }
                            large
                            forceOpen={contentOpen}
                        >
                            <ChbaNzFile
                                accessor={"modelData.chbaNz.file"}
                                change={change}
                                handleFileTag={async () => {
                                    await modelSave();
                                    await addTagToModelFile(modelId, uid, selectedChbaLabelType, "CHBA_NZ");
                                }}
                                selectedChbaLabelType={selectedChbaLabelType}
                            />
                        </Accordion>
                    )}
                    <Accordion
                        className={sharedClasses.accordion}
                        heading={<span className={sharedClasses.heading}>Specifications</span>}
                        large
                        forceOpen={contentOpen}
                    >
                        <Specifications accessor={"modelData.specifications"} change={change} />
                    </Accordion>
                    <Accordion
                        className={sharedClasses.accordion}
                        heading={<span className={sharedClasses.heading}>Fuel Prices</span>}
                        large
                        forceOpen={contentOpen}
                    >
                        <FuelPrices accessor={"modelData.location.fuelPrices"} change={change} />
                    </Accordion>
                    {buildingTypeId !== 0 && (
                        <>
                            <Accordion
                                className={sharedClasses.accordion}
                                heading={<span className={sharedClasses.heading}>MURB Unit Details</span>}
                                large
                                forceOpen={contentOpen}
                            >
                                <MurbSpecs accessor={"modelData.specifications"} change={change} />
                            </Accordion>
                        </>
                    )}
                </div>
            </>
        );
    },
    isEqual
);
