import React from "react";
import WorkbookCheckbox from "components/Input/WorkbookCheckbox";
import { Field } from "redux-form";
import Button from "components/Button";
import classes from "./style.module.scss";
import netZeroClasses from "../style.module.scss";
import Export from "assets/images/icons/JSX/Export";
import InputRow from "components/Input/Row";
import Input from "components/Input";

import NZArrowDown from "assets/images/icons/NZArrowDown.svg";
import { getValidation } from "utils/fieldValidation";

const General = ({ accessor, change, isNetZeroReady, wbCompare }) => {
    // const [netZeroReady, setNetZeroReady] = useState(false);

    const handleCheckboxToggle = () => {
        change(`${accessor}.netZeroHome.isNetZeroReady`, !isNetZeroReady);
    };

    return (
        <>
            <div className={classes.topRow}>
                <div className={classes.inputColumn}>
                    <div className={classes.checkboxToggle} onClick={handleCheckboxToggle}>
                        <h3>{`Net Zero ${isNetZeroReady ? "Ready" : ""} Home`}</h3>
                        <img src={NZArrowDown} alt="Thermometer and sun" />
                    </div>
                    <Field
                        className={classes.netZeroCheckbox}
                        component={WorkbookCheckbox}
                        name={`${accessor}.netZeroHome.preRenovationFile`}
                        label="1 pre-renovation HOT2000 file"
                        type="checkbox"
                    />
                    <Field
                        className={classes.netZeroCheckbox}
                        component={WorkbookCheckbox}
                        name={`${accessor}.netZeroHome.finalFileWithRenewableToZeroGJ`}
                        label={
                            <p>
                                1 final HOT2000 file modelled <strong>WITH</strong> the renewable energy system to 0 GJ
                            </p>
                        }
                        type="checkbox"
                    />
                    {isNetZeroReady && (
                        <Field
                            className={classes.netZeroCheckbox}
                            component={WorkbookCheckbox}
                            name={`${accessor}.netZeroHome.finalFileWithoutRenewableToZeroGJ`}
                            label={
                                <p>
                                    1 final HOT2000 file modelled <strong>WITHOUT</strong> the renewable energy system
                                </p>
                            }
                            type="checkbox"
                        />
                    )}
                    <Field
                        className={classes.netZeroCheckbox}
                        component={WorkbookCheckbox}
                        name={`${accessor}.netZeroHome.completedProjectRegWorkbook`}
                        label="Completed Project Registration Workbook - Renovation (this workbook)"
                        type="checkbox"
                    />
                    {isNetZeroReady && (
                        <Field
                            className={classes.netZeroCheckbox}
                            component={WorkbookCheckbox}
                            name={`${accessor}.netZeroHome.PhotovalicSysDesign`}
                            label="Photovoltaic System Design (recommended)"
                            type="checkbox"
                        />
                    )}
                </div>
                {/* <Button
                    smallPadding
                    icon={Export}
                    className={netZeroClasses.exportButton}
                >
                    <span>
                        Export Net Zero Workbook{" "}
                    </span>
                </Button> */}
            </div>
            <div>
                <h3>Site Information</h3>
                <InputRow gridTemplate="1fr 1fr 1fr">
                    <Field
                        component={Input}
                        name={`${accessor}.siteInformation.nzrLabelNumber`}
                        type="text"
                        label="NZ/r Label Number"
                        change={change}
                    />
                    <Field
                        component={Input}
                        name={`${accessor}.siteInformation.ersFileNumber`}
                        type="text"
                        label="ERS File Number"
                        change={change}
                        setValue={wbCompare.fileID.wbData ? null : wbCompare.fileID.modelData}
                    />
                    <Field
                        component={Input}
                        name={`${accessor}.siteInformation.siteAddress`}
                        type="text"
                        label="Site Address"
                        change={change}
                        setValue={wbCompare.clientStreetAddress.wbData ? null : wbCompare.clientStreetAddress.modelData}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr 1fr">
                    <Field
                        component={Input}
                        name={`${accessor}.siteInformation.city`}
                        type="text"
                        label="City"
                        change={change}
                        setValue={wbCompare.clientCity.wbData ? null : wbCompare.clientCity.modelData}
                    />
                    <Field
                        component={Input}
                        name={`${accessor}.siteInformation.provinceTerr`}
                        type="text"
                        label="Province/Territory"
                        change={change}
                        setValue={wbCompare.clientProvince.wbData ? null : wbCompare.clientProvince.modelData}
                    />
                    <Field
                        component={Input}
                        name={`${accessor}.siteInformation.programVersion`}
                        type="text"
                        label="Program Version"
                        change={change}
                    />
                </InputRow>
            </div>
            <div>
                <h3>Participant Information</h3>
                <h4>Renovator</h4>
                <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                    <Field
                        component={Input}
                        name={`${accessor}.participant.renovator.name`}
                        type="text"
                        label="Name"
                        change={change}
                        setValue={wbCompare.renovatorName.wbData ? null : wbCompare.renovatorName.modelData}
                    />
                    <Field
                        component={Input}
                        name={`${accessor}.participant.renovator.email`}
                        type="text"
                        label="Email Address"
                        change={change}
                        setValue={wbCompare.renovatorEmail.wbData ? null : wbCompare.renovatorEmail.modelData}
                    />
                    <Field
                        component={Input}
                        name={`${accessor}.participant.renovator.phone`}
                        type="text"
                        label="Phone Number"
                        change={change}
                        setValue={wbCompare.renovatorPhone.wbData ? null : wbCompare.renovatorPhone.modelData}
                    />
                    <Field
                        component={Input}
                        name={`${accessor}.participant.renovator.company`}
                        type="text"
                        label="Company"
                        change={change}
                        setValue={wbCompare.renovatorCompany.wbData ? null : wbCompare.renovatorCompany.modelData}
                    />
                </InputRow>
                <h4>Energy Advisor</h4>
                <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                    <Field
                        component={Input}
                        name={`${accessor}.participant.ea.name`}
                        type="text"
                        label="Name"
                        change={change}
                        setValue={wbCompare.userNameEnteredBy.wbData ? null : wbCompare.userNameEnteredBy.modelData}
                    />
                    <Field
                        component={Input}
                        name={`${accessor}.participant.ea.email`}
                        type="text"
                        label="Email Address"
                        change={change}
                        setValue={wbCompare.userEmail.wbData ? null : wbCompare.userEmail.modelData}
                    />
                    <Field
                        component={Input}
                        name={`${accessor}.participant.ea.phone`}
                        type="text"
                        label="Phone Number"
                        change={change}
                        setValue={wbCompare.userTelephone.wbData ? null : wbCompare.userTelephone.modelData}
                    />
                    <Field
                        component={Input}
                        name={`${accessor}.participant.ea.company`}
                        type="text"
                        label="Service Organization"
                        change={change}
                        setValue={wbCompare.userCompany.wbData ? null : wbCompare.userCompany.modelData}
                    />
                </InputRow>
            </div>
        </>
    );
};

export default General;
