import React from 'react';
import classes from './style.module.scss';

export default ({
    children,
    className,
    color="#fff"
}) => (
    <div style={{backgroundColor:color}} className={`${classes.card} ${className ? className : ''}`}>
        {children}
    </div>
);

export const NoticeCard = ({
    children,
    className,
    color="#fff",
    title,
    icon,
}) => {
    return (
        <div style={{backgroundColor:color}} className={`${classes.card} ${classes.notice} ${className ? className : ''}`}>
            {icon && <img src={icon} alt="Notice Icon" />}
            <h1 className={classes.title}>{title}</h1>
            {children}
        </div>
    )
}