import React, { useEffect, useState } from "react";
import classes from "../TableInputWithUnits/style.module.scss";
import InfoTooltip from "components/InfoTooltip";
import Units from "./Units";
import convertUnit from "utils/convertUnit";
import DrawingIcon from "assets/images/icons/JSX/Drawing";
import Tooltip from "components/Tooltip";
import EditTooltip from "../EditTooltip";
import { mixpanel } from "components/Mixpanel";
import LoadingDots from "components/LoadingDots";
import WarningTooltip from "components/WarningTooltip";
import classNames from "classnames";

//TODO: make tooltip more unique
const InputWithUnits = ({
    label, 
    input={},
    meta={}, 
    type, 
    className, 
    large, 
    placeholder='',
    hideLabel,
    autoComplete = 'off',
    info='',
    disabled=false,
    parentError=false,
    quantity=false,
    setValue,
    setUnits = '',
    decimals=0,
    hideField,
    change:unitChange,
    units:{
        base:{
            trueBase='m',
            displayBase='m'
        }={},
        options=[],
        selected='',
        unitType,
        accessor
    }={},
    isDrawing=false,
    isDrawingTip='',
    editToggle,
    onUnitChange=()=>'',
    isLoading = false,
    warning=""
}) => {
    const [selectedUnit, changeSelectedUnit] = useState(setUnits || selected || displayBase || trueBase);
    const [displayValue, changeDisplayValue] = useState('');
    const [focused, toggleFocused] = useState(false);

    const { invalid=false, error='' } = meta;
    // 'value' here represents the trueValue
    const { value, onChange, name='' } = input;

    const mainClasses = [
        classes.inputField,
        className && className,
        large && classes.large,
        quantity && classes.hasQuantity,
        (parentError || invalid) && classes.invalid,
        warning && classes.warning,
        hideField && classes.hideField
    ].filter(c => c);

    const handleSetValue = () => {
        const newDisplayValue = parseFloat(convertUnit({
            value:parseFloat(setValue),
            type:unitType,
            inputUnit:trueBase,
            outputUnit:selectedUnit,
        })).toFixed(decimals);

        changeDisplayValue(newDisplayValue);
        onChange(parseFloat(parseFloat(setValue).toFixed(decimals + 2)));
    };

    // Handles any change made to the display field
    const handleChange = (e) => {
        const newDisplayValue = parseFloat(e.target.value);
        changeDisplayValue(e.target.value);

        const newTrueValue = parseFloat(convertUnit({
            value:newDisplayValue,
            type:unitType,
            inputUnit:selectedUnit,
            outputUnit:trueBase,
        }).toFixed(decimals + 2));

        onChange(newTrueValue);
    };

    // Handles what happens when the user leaves the display field
    const handleBlur = () => {
        const newTrueValue = parseFloat(parseFloat(value).toFixed(decimals + 2));
        const newDisplayValue = parseFloat(parseFloat(displayValue).toFixed(decimals));

        toggleFocused(false);
        onChange(newTrueValue);
        changeDisplayValue(newDisplayValue);

        if (setValue || setValue === 0) {
            handleSetValue();
        }
    };

    // Handles a new unit selection
    const handleUnitChange = (unit) => {
        const newDisplayValue = parseFloat(convertUnit({
            value: value, //Always using value here so we don't lose decimals
            type:unitType,
            inputUnit: trueBase,
            outputUnit:unit,
        })).toFixed(decimals);

        changeDisplayValue(parseFloat(newDisplayValue));
        changeSelectedUnit(unit);

        // Changes the unit field in the form
        unitChange(accessor, unit);
        onUnitChange(unit);
    };

    useEffect(()=>{
        // Get the right display value when component is initialized
        const newDisplayValue = parseFloat(convertUnit({
            value:value,
            type:unitType,
            inputUnit:trueBase,
            outputUnit:selectedUnit,
        })).toFixed(decimals);

        if (value || value === 0) {
            changeDisplayValue(newDisplayValue);
        }
    },[]);


    useEffect(()=>{
        // If value is manually set, do shtuff
        if ((setValue || setValue === 0) && !focused) {
            handleSetValue();
        }
    },[setValue]);

    useEffect(()=>{
        // If units is manually set, do shtuff
        if ((setUnits) && !focused) {
            changeSelectedUnit(setUnits)
            if (value || value === 0) {
                const newDisplayValue = parseFloat(convertUnit({
                    value:value,
                    type:unitType,
                    inputUnit:trueBase,
                    outputUnit:setUnits,
                })).toFixed(decimals);
                changeDisplayValue(newDisplayValue);
            }
        }
    },[setUnits]);


    return (
        <div className={mainClasses.join(' ')}>
            <label className={`${hideLabel && 'invisible'} ${isDrawing && classes.isDrawing}`} htmlFor={name}>
                {label} {isDrawing && <>
                    <span
                    data-html
                    data-for={`${name}_drawingTip`}
                    data-tip={`<span style="display:block;max-width:10.5rem;text-align:center;">${isDrawingTip ? isDrawingTip : 'This field can only be edited in the <strong>Drawing Capture</strong> tool.'}</span>`}
                    >
                        <DrawingIcon />
                    </span>
                    <Tooltip id={`${name}_drawingTip`} />
                </>}
            </label>
            {
                info && <InfoTooltip className={classes.infoTooltip} info={info} />
            }
            {
                warning &&
                    <WarningTooltip
                        className={classNames(classes.warningTooltip, {
                            [classes.pushLeft]:editToggle || info
                        })}
                        warning={warning}
                    />
            }
            {
                editToggle &&
                    <EditTooltip
                        onClick={() => {
                            editToggle();
                            mixpanel.track("Summary Calculation Open", {
                                "Field Name": label
                            });
                        }}
                        name={name}
                        label={label}
                    />
            }
            {isLoading && <div className={classes.loading}><LoadingDots /></div>}
            {!isLoading &&
                <div className={classes.inputWithUnit}>
                    <input
                        id={`${name}-front`}
                        name={`${name}-front`}
                        type={type}
                        placeholder={placeholder}
                        autoComplete={autoComplete}
                        disabled={disabled}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onFocus={()=>toggleFocused(true)}
                        value={displayValue}
                    />
                    <input
                        {...input}
                        id={name}
                        name={name}
                        type="hidden"
                    />
                    {/* <Units
                        selectedUnit={selectedUnit}
                        unitOptions={options}
                        changeSelected={handleUnitChange}
                    /> */}
                </div>
            }
            {invalid && error && <span className={classes.errorMessage}>{error}</span>}
        </div>
    )
};

export default InputWithUnits;