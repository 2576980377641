import { connect } from "react-redux";
import ErrorFlagging from "./";

import { actions as modelActions } from "features/Model/_ducks";

const { flagModelError } = modelActions;

const mapStateToProps = (
    {
        form,
        user: { uid, userPermissions: { VOLTA_DEV = false } = {} } = {},
        model: {
            modelDetails: { sharedWith = [], name, owner, errorDetails = {}, modelErrorSubmitted = false } = {},
            modelId,
        } = {},
    },
    { formName = "model" }
) => {
    // const selector = formValueSelector("model");
    // const modelSelector = formValueSelector("model");

    return {
        uid,
        modelName: name,
        sharedWith,
        modelId,
        owner,
        VOLTA_DEV,
        errorDetails,
        modelErrorSubmitted,
    };
};

const mergeProps = (state, dispatch, own) => ({
    ...state,
    ...dispatch,
    ...own,
});

const mapDispatchToProps = (dispatch) => ({
    flagModelError: async (modelId, uid, userMessage) => dispatch(flagModelError(modelId, uid, userMessage)),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ErrorFlagging);
