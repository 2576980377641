import React, { useEffect, useState } from 'react';
import { auth } from '_firebase';
import Loading from 'components/Loading';

export const AuthContext = React.createContext({ user:null });

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [pending, setPending] = useState(true);

    useEffect(() => {
        auth.onAuthStateChanged(userAuth => {
            setUser(userAuth);
            setPending(false);
        })
    },[])

    if(pending){
        return <Loading />
    }

    return (
        <AuthContext.Provider value={{user}}>
            {children}
        </AuthContext.Provider>
    )
}