import { connect } from 'react-redux';
import CodeList from './';
import { actions as enclosureActions } from 'features/Model/Enclosure/_ducks';
const { clearInitCode, setInitCode } = enclosureActions;

const mapStateToProps = ({
    user: {
        codeLib={}
    },
    enclosure:{
        calculatingRValue:{
            codeLib:calculatingRValue={}
        }={}
    }={}
}, { type }) => {
    const {
        codes={}
    } = codeLib[type] || {};

    const totalCount = Object.keys(codes).length;
    const standardCount = Object.keys(codes).filter(code => code.split(`${type}-U`)[0]).length;
    const uDefCount = Object.keys(codes).filter(code => !code.split(`${type}-U`)[0]).length;
    const addingNewCode = Object.keys(calculatingRValue).some(key => key.includes(type) && calculatingRValue[key])
        && Object.keys(codes).every(key => !calculatingRValue[key]);

    return {
        codes,
        totalCount,
        standardCount,
        uDefCount,
        calculatingRValue,
        addingNewCode
    }
};

const mapDispatchToProps = (dispatch) => ({
    clearInitCode: () => {
        dispatch(clearInitCode());
    },
    setInitCode: (code, type) => {
        return dispatch(setInitCode(code, type));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CodeList);