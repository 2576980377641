import React, { useCallback } from 'react';
import classes from 'features/Model/Enclosure/UserDefinedCodes/style.module.scss';
import InputRow from 'components/Input/Row';
import Input from 'components/Input';
import InputWithUnits from 'components/Input/InputWithUnits';
import Select from 'components/Input/Select';
import { Field } from 'redux-form';
import { getOptions, getBaseUnits, getUnitOptions } from 'utils/fields';
import { getValidation, getDecimalPlaces } from 'utils/fieldValidation';
import Accordion from 'components/Accordion';
import AccordionActions from "../../AccordionActions";

const typeValidation = getValidation('udefWindowLegacyType');
const centreGlassValidation = getValidation('udefWindowLegacyCentreOfGlassRVal');
const edgeGlassValidation = getValidation('udefWindowLegacyEdgeOfGlassRVal');
const frameRValValidation = getValidation('udefWindowLegacyFrameRVal');
const frameHeightValidation = getValidation('udefWindowLegacyFrameHeight');
const shgcValidation = getValidation('udefWindowLegacySHGC');

export default React.memo(({
    change,
    accessor,
    centreGlassUnits,
    edgeGlassUnits,
    frameRValUnits,
    frameHeightUnits,
    modelUnits,
    legacyLayers,
    id,
}) => {

    const deleteLayer = useCallback(() => {
        const { [id]:_, ...remainingLayers } = legacyLayers;
        change('uDefCode.layers.windowLegacy', remainingLayers);
    },[id, legacyLayers, change]);
    
    return (
        <div>
            <Accordion
                className={classes.accordion}
                initOpenState={true}
                heading={
                    <span className={classes.accordionHeader}>
                        <span className={classes.accordionTitle}>Legacy Inputs</span>
                    </span>
                }
                hoverActions={<AccordionActions accessor={accessor} deleteLayer={deleteLayer} />}
            >
                <InputRow gridTemplate="1fr">
                    <Field
                        component={Select}
                        name={`${accessor}.type`}
                        options={getOptions({fieldKey:'udefWindowLegacyType'})}
                        label="Window Type"
                        placeholder="Choose Window Type"
                        validate={typeValidation}
                    />
                </InputRow>
                <h4>R-Values</h4>
                <InputRow gridTemplate="1fr 1fr 1fr">
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.rsiValues.centreOfGlass`}
                        label="Centre of Glass"
                        placeholder={0.000000}
                        validate={centreGlassValidation}
                        setTouched={true}
                        decimals={getDecimalPlaces('udefWindowLegacyCentreOfGlassRVal')}
                        change={change}
                        units={{
                            base:getBaseUnits('udefWindowLegacyCentreOfGlassRVal', modelUnits),
                            options:getUnitOptions('thermalResistance'),
                            selected:centreGlassUnits,
                            unitType:'thermalResistance',
                            accessor:`${accessor}.rsiValues.centreOfGlass_u`
                        }}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.rsiValues.edgeOfGlass`}
                        label="Edge of Glass"
                        placeholder={0.000000}
                        validate={edgeGlassValidation}
                        setTouched={true}
                        decimals={getDecimalPlaces('udefWindowLegacyEdgeOfGlassRVal')}
                        change={change}
                        units={{
                            base:getBaseUnits('udefWindowLegacyEdgeOfGlassRVal', modelUnits),
                            options:getUnitOptions('thermalResistance'),
                            selected:edgeGlassUnits,
                            unitType:'thermalResistance',
                            accessor:`${accessor}.rsiValues.edgeOfGlass_u`
                        }}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.rsiValues.frame`}
                        label="Frame"
                        placeholder={0.000000}
                        validate={frameRValValidation}
                        setTouched={true}
                        decimals={getDecimalPlaces('udefWindowLegacyFrameRVal')}
                        change={change}
                        units={{
                            base:getBaseUnits('udefWindowLegacyFrameRVal', modelUnits),
                            options:getUnitOptions('thermalResistance'),
                            selected:frameRValUnits,
                            unitType:'thermalResistance',
                            accessor:`${accessor}.rsiValues.frame_u`
                        }}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr 1fr">
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.frameHeight`}
                        label="Frame Height"
                        placeholder={0.00}
                        validate={frameHeightValidation}
                        setTouched={true}
                        decimals={getDecimalPlaces('udefWindowLegacyFrameHeight')}
                        change={change}
                        units={{
                            base:getBaseUnits('udefWindowLegacyFrameHeight', modelUnits),
                            options:getUnitOptions('length'),
                            selected:frameHeightUnits,
                            unitType:'length',
                            accessor:`${accessor}.frameHeight_u`
                        }}
                    />
                    <Field
                        component={Input}
                        type="number"
                        name={`${accessor}.shgc`}
                        label="SHGC"
                        placeholder={0.00}
                        validate={shgcValidation}
                        decimals={getDecimalPlaces('udefWindowLegacySHGC')}
                    />
                </InputRow>
            </Accordion>
        </div>
    )
});