export const getDrawingComponent = (imageData, componentId) =>
    Object.keys(imageData).reduce((cache, image) => {
        const { components = {}, polygons = {} } = imageData[image] || {};

        const windowOrWall = Object.keys(components)
            .filter((c) => {
                const { componentModelId = '' } = components[c] || {};

                return componentId && componentModelId === componentId;
            })
            .reduce((cache, c) => {
                const { componentModelId = '' } = components[c] || {};
                return {
                    drawingShapeId: c,
                    componentModelId,
                    type: 'component',
                    componentData: components[c],
                    image,
                };
            }, {});

        const polygonComponent = Object.keys(polygons).reduce((cache, poly) => {
            const { components = {} } = polygons[poly] || {};
            const polyComponent = Object.keys(components)
                .filter((c) => componentId && c === componentId)
                .reduce(
                    (cache, c) => ({
                        componentModelId: c,
                        drawingShapeId: poly,
                        type: 'polygonComponent',
                        componentData: components[c],
                        otherComponents: components,
                        image,
                    }),
                    {}
                );
            return {
                ...cache,
                ...polyComponent,
            };
        }, {});

        return {
            ...cache,
            ...windowOrWall,
            ...polygonComponent,
        };
    }, {});

export const getUpdatedComponent = (componentData, pathArray, fieldValue) => {
    const newComponent = pathArray.reduce((cache, key, index) => {
        if (index === pathArray.length - 1) {
            cache[key] = fieldValue;
            return cache;
        }
        return cache[key];
    }, componentData);

    return componentData;
};

export const buildImageData = ({ imageData, linesToDetach, lineFieldsToUpdate, prepComponents }) => {
    const newImageData = Object.keys(imageData).reduce((cache, image) => {
        const { lines = {}, components = {}, polygons = {}, scale = {} } = imageData[image];

        const newLines = Object.keys(lines).reduce((lineCache, line) => {
            let lineData = lines[line];

            if (!linesToDetach[line] && !lineFieldsToUpdate[line]) {
                return lineCache;
            }

            if (linesToDetach[line]) {
                lineData = {
                    ...lineData,
                    attachTo: {},
                    changeField: {},
                };
            }

            if (lineFieldsToUpdate[line]) {
                const { drawingReduxUpdates: { updates = {} } = {} } = lineFieldsToUpdate[line];

                lineData = {
                    ...lineData,
                    ...updates,
                };
            }

            return {
                ...lineCache,
                [line]: lineData,
            };
        }, lines);

        const newComponents = Object.keys(components).reduce((componentCache, component) => {
            const { attachTo = {}, type = '' } = components[component];

            const { componentType: parentType = '', componentId: parentId = '', modelRef = '' } = attachTo;

            if (modelRef) {
                return componentCache;
            }

            const { componentModelId = '' } = prepComponents[component] || {};
            const newModelRef = `${parentType}.${parentId}.subcomponents.${type}.${componentModelId}`;

            const newComponentData = {
                ...components[component],
                attachTo: {
                    ...attachTo,
                    modelRef: newModelRef,
                },
            };

            return {
                ...componentCache,
                [component]: newComponentData,
            };
        }, components);

        return {
            ...cache,
            [image]: {
                lines: newLines,
                components: newComponents,
                polygons,
                scale,
            },
        };
    }, {});

    return newImageData;
};
