import React from "react";
import classes from "./style.module.scss";

const MenuDots = ({ className, isOpen, onClick }) => {
    return (
        <span className={`${classes.menuDots} ${className}`} onClick={onClick}>
            <span></span>
            <span></span>
            <span></span>
        </span>
    );
};

export default MenuDots;
