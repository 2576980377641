import types from "./types";

export const saveUpdateUpgradePackageStart = () => ({
    type: types.SAVE_UPDATE_UPGRADE_PACKAGE_START,
});

export const saveUpdateUpgradePackageSuccess = (upgradePackage) => ({
    type: types.SAVE_UPDATE_UPGRADE_PACKAGE_SUCCESS,
    payload: { upgradePackage },
});

export const saveUpdateUpgradePackageError = (error) => ({
    type: types.SAVE_UPDATE_UPGRADE_PACKAGE_ERROR,
    payload: error,
});

export const fetchUpgradeLabelsStart = () => ({
    type: types.FETCH_UPGRADE_LABELS_START,
});

export const fetchUpgradeLabelsSuccess = (upgradeLabels) => ({
    type: types.FETCH_UPGRADE_LABELS_SUCCESS,
    payload: upgradeLabels,
});

export const fetchUpgradeLabelsError = (error) => ({
    type: types.FETCH_UPGRADE_LABELS_ERROR,
    payload: error,
});

export const fetchUpgradePackageStart = () => ({
    type: types.FETCH_UPGRADE_PACKAGE_START,
});

export const fetchUpgradePackageSuccess = (upgradePackage) => ({
    type: types.FETCH_UPGRADE_PACKAGE_SUCCESS,
    payload: upgradePackage,
});

export const fetchUpgradePackageError = (error) => ({
    type: types.FETCH_UPGRADE_PACKAGE_ERROR,
    payload: error,
});

export const deleteUpgradePackageStart = () => ({
    type: types.DELETE_UPGRADE_PACKAGE_START,
});

export const deleteUpgradePackageSuccess = (upgradePackage) => ({
    type: types.DELETE_UPGRADE_PACKAGE_SUCCESS,
});

export const deleteUpgradePackageError = (error) => ({
    type: types.DELETE_UPGRADE_PACKAGE_ERROR,
    payload: error,
});

export const changeUpgradePackageNameStart = () => ({
    type: types.CHANGE_NAME_UPGRADE_PACKAGE_START,
});

export const changeUpgradePackageNameSuccess = (updatedPackage) => ({
    type: types.CHANGE_NAME_UPGRADE_PACKAGE_SUCCESS,
    payload: updatedPackage,
});

export const changeUpgradePackageNameError = (err) => ({
    type: types.CHANGE_NAME_UPGRADE_PACKAGE_ERROR,
    payload: err,
});

export const duplicateUpgradePackageStart = () => ({
    type: types.DUPLICATE_UPGRADE_PACKAGE_START,
});

export const duplicateUpgradePackageSuccess = () => ({
    type: types.DUPLICATE_UPGRADE_PACKAGE_SUCCESS,
});

export const duplicateUpgradePackageError = () => ({
    type: types.DUPLICATE_UPGRADE_PACKAGE_ERROR,
});

export const updateUpgrade = (upgradeId, upgrade) => ({
    type: types.UPDATE_UPGRADE,
    payload: { upgradeId, upgrade },
});

export const deleteUpgrade = (upgradeId) => ({
    type: types.DELETE_UPGRADE,
    payload: upgradeId,
});

export const resetUpgradeLibrary = () => ({
    type: types.RESET_UPGRADE_LIBRARY,
});

export const selectUpgrade = (upgradeId) => ({
    type: types.SELECT_UPGRADE,
    payload: upgradeId,
});
