import React, { useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectImageData, selectActiveComponent } from "../../_ducks/selector";
import { setPolygon, setMessage } from "../../_ducks/actions";

import { selectOptionsForDropdown, foundationTypes, storeysTypes } from "../../_ducks/utils";
import IDGenerator from "features/Model/Enclosure/Takeoff/TakeoffData/IDGenerator";

import { getDecimalPlaces } from "utils/fieldValidation";
import { getBaseUnits, getUnitOptions } from "utils/fields";

import Button from "components/Button";
import Input from "components/Input";
import Select from "components/Input/Select";
import InputWithUnits from "components/Input/InputWithUnits";

import {
    cancelButton,
    polygonButtons,
    buttonRow,
    saveButton,
    inputMarginBottom,
    explainText,
} from "../style.module.scss";

const SetPolygon = ({
    cancel,
    onSet,
    imageData,
    image,
    activeComponent,
    foundation,
    storeys,
    primaryUnits,
    deleteShape,
    setPolygon,
    setMessage,
}) => {
    const { polygons } = imageData[image];
    const activePolygon = polygons[activeComponent];

    const {
        id,
        foundationStorey = "",
        takeoffField = "",
        window = {
            title: activePolygon?.name || "",
            ovHeightUnits: primaryUnits,
            ovHeight: 0,
            ovWidthUnits: primaryUnits,
            ovWidth: 0,
            quantity: 1,
        },
    } = activePolygon.takeoff || {};

    const {
        ovHeightUnits = primaryUnits,
        ovHeight = 0,
        ovWidthUnits = primaryUnits,
        ovWidth = 0,
        quantity = 1,
    } = window;

    const polygonPoints = activePolygon?.points?.length / 2;

    const foundationStoreySelectArr = [
        foundation.compType,
        ...storeysTypes.filter((storey) => Object.keys(storeys).includes(storey)),
    ];

    const foundationStoreySelect = foundationStoreySelectArr.map((val, index) => ({
        value: val.toLowerCase(),
        label:
            index === 0
                ? `Foundation - ${val.charAt(0).toUpperCase() + val.slice(1)}`
                : `${val.charAt(0).toUpperCase() + val.slice(1)}${
                      index === foundationStoreySelectArr.length - 1 ? "" : " Storey"
                  }`,
    }));

    const takeoffFieldOptions =
        selectOptionsForDropdown({
            shapeType: "polygon",
            foundation: foundationTypes.includes(foundationStorey) ? foundationStorey : "",
            storey: Object.keys(storeys).includes(foundationStorey) ? foundationStorey : "",
        }) || [];

    const withIdTakeoffFields = ["pony wall (elevation)", "window", "exposed floor"];

    const idGeneratorOption = {
        window: "toff-table",
        "pony wall (elevation)": "pw",
        "exposed floor": "ef",
    };

    return (
        <div>
            {!activePolygon.isSet ? (
                <>
                    <p>
                        Click anywhere on the active page to place polygon's points. A polygon must have at least 3
                        points
                    </p>
                    <div className={polygonButtons}>
                        <Button smallPadding onClick={cancel} type="red" className={cancelButton}>
                            Cancel
                        </Button>
                        <Button
                            smallPadding
                            onClick={() => {
                                if (polygonPoints < 3)
                                    return setMessage({
                                        message: "A polygon must have at least 3 points",
                                        type: "warning",
                                        anchorOrigin: {
                                            vertical: "top",
                                            horizontal: "right",
                                        },
                                        autoHideDuration: 2000,
                                        isOpen: true,
                                    });

                                onSet();
                            }}
                            type="hollow"
                            className={saveButton}
                        >
                            Set polygon
                        </Button>
                    </div>
                </>
            ) : (
                <>
                    <div>
                        <Input
                            className={inputMarginBottom}
                            label={"Polygon's Name"}
                            input={{
                                value: activePolygon.name,
                                onChange: (e) =>
                                    setPolygon(image, {
                                        ...activePolygon,
                                        name: e,
                                        takeoff: {
                                            ...activePolygon.takeoff,
                                            window: {
                                                ...window,
                                                title: e,
                                            },
                                        },
                                    }),
                            }}
                        />
                        <Select
                            className={inputMarginBottom}
                            label={"Select Foundation or Storey"}
                            options={foundationStoreySelect}
                            input={{
                                value: foundationStorey,
                                onChange: (e) =>
                                    setPolygon(image, {
                                        ...activePolygon,
                                        takeoff: {
                                            ...activePolygon,
                                            foundationStorey: e,
                                            takeoffField: "",
                                        },
                                    }),
                            }}
                        />
                        <Select
                            className={takeoffField === "window" && inputMarginBottom}
                            label={"Select Takeoff Field"}
                            disabled={!foundationStorey}
                            options={takeoffFieldOptions?.map((val) => ({
                                value: val.toLowerCase(),
                                label: val,
                            }))}
                            input={{
                                value: takeoffField,
                                onChange: (e) => {
                                    const newId = withIdTakeoffFields.includes(takeoffField)
                                        ? !id
                                            ? IDGenerator(idGeneratorOption[takeoffField])
                                            : id
                                        : "";

                                    setPolygon(image, {
                                        ...activePolygon,
                                        takeoff: {
                                            ...activePolygon.takeoff,
                                            id: newId,
                                            takeoffField: e,
                                        },
                                    });
                                },
                            }}
                        />
                        <div className={explainText}>
                            <p>Shift + Click near the polygon's perimeter to add a new point.</p>
                            <p>Shift + Click a point to delete it.</p>
                        </div>
                        {takeoffField === "window" && (
                            <>
                                <InputWithUnits
                                    className={inputMarginBottom}
                                    label={"Header Height"}
                                    type="number"
                                    decimals={getDecimalPlaces("windowHeaderHeight")}
                                    units={{
                                        base: getBaseUnits("windowHeaderHeight", primaryUnits),
                                        options: getUnitOptions("length"),
                                        selected: ovHeightUnits,
                                        unitType: "length",
                                    }}
                                    input={{
                                        onChange: (value) =>
                                            setPolygon(image, {
                                                ...activePolygon,
                                                takeoff: {
                                                    ...activePolygon.takeoff,
                                                    window: {
                                                        ...activePolygon.takeoff.window,
                                                        ovHeight: value,
                                                    },
                                                },
                                            }),
                                        value: activePolygon.takeoff?.window?.ovHeight,
                                    }}
                                    setValue={ovHeight}
                                    change={(value, unit) =>
                                        setPolygon(image, {
                                            ...activePolygon,
                                            takeoff: {
                                                ...activePolygon.takeoff,
                                                window: {
                                                    ...activePolygon.takeoff.window,
                                                    ovHeightUnits: unit,
                                                },
                                            },
                                        })
                                    }
                                />
                                <InputWithUnits
                                    className={inputMarginBottom}
                                    label={"Overhang Width"}
                                    type="number"
                                    decimals={getDecimalPlaces("windowOverhangWidth")}
                                    units={{
                                        base: getBaseUnits("windowOverhangWidth", primaryUnits),
                                        options: getUnitOptions("length"),
                                        selected: ovWidthUnits,
                                        unitType: "length",
                                    }}
                                    input={{
                                        onChange: (value) =>
                                            setPolygon(image, {
                                                ...activePolygon,
                                                takeoff: {
                                                    ...activePolygon.takeoff,
                                                    window: {
                                                        ...activePolygon.takeoff.window,
                                                        ovWidth: value,
                                                    },
                                                },
                                            }),
                                        value: ovWidth,
                                    }}
                                    setValue={ovWidth}
                                    change={(value, unit) => {
                                        setPolygon(image, {
                                            ...activePolygon,
                                            takeoff: {
                                                ...activePolygon.takeoff,
                                                window: {
                                                    ...activePolygon.takeoff.window,
                                                    ovWidthUnits: unit,
                                                },
                                            },
                                        });
                                    }}
                                />
                                <Input
                                    label={"Quantity"}
                                    type="number"
                                    input={{
                                        onChange: (value) =>
                                            setPolygon(image, {
                                                ...activePolygon,
                                                takeoff: {
                                                    ...activePolygon.takeoff,
                                                    window: {
                                                        ...activePolygon.takeoff.window,
                                                        quantity: value,
                                                    },
                                                },
                                            }),
                                        value: quantity,
                                    }}
                                />
                            </>
                        )}
                    </div>
                    <div className={`${buttonRow} `}>
                        <Button smallPadding onClick={deleteShape} type="red" className={cancelButton}>
                            Delete
                        </Button>

                        {/* <Button
                            smallPadding
                            onClick={() => {
                                const newId = withIdTakeoffFields.includes(takeoffField)
                                    ? !id
                                        ? IDGenerator(idGeneratorOption[takeoffField])
                                        : id
                                    : "";

                                setPolygon(image, {
                                    ...activePolygon,
                                    name: newName,
                                    takeoff: {
                                        ...polygonTakeoff,
                                        id: newId,
                                        window: {
                                            ...window,
                                            title: newName,
                                        },
                                    },
                                });

                                setPolygonTakeoff({
                                    ...polygonTakeoff,
                                    id: newId,
                                    window: {
                                        ...window,
                                        title: newName,
                                    },
                                });

                                setMessage({
                                    message: "Polygon was updated successfully",
                                    type: "success",
                                    anchorOrigin: {
                                        vertical: "top",
                                        horizontal: "right",
                                    },
                                    autoHideDuration: 2000,
                                    isOpen: true,
                                });
                            }}
                            className={saveButton}
                        >
                            Save Polygon
                        </Button> */}
                    </div>
                </>
            )}
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    imageData: selectImageData,
    activeComponent: selectActiveComponent,
});

const mapDispatchToProps = (dispatch) => ({
    setPolygon: (image, polygon) => dispatch(setPolygon(image, polygon)),
    setMessage: ({ message, type, anchorOrigin, autoHideDuration, isOpen }) =>
        dispatch(
            setMessage({
                message,
                type,
                anchorOrigin,
                autoHideDuration,
                isOpen,
            })
        ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SetPolygon);
