import React, { useCallback } from 'react';
import classes from 'features/Model/Enclosure/UserDefinedCodes/style.module.scss';
import InputRow from 'components/Input/Row';
import Input from 'components/Input';
import InputWithUnits from 'components/Input/InputWithUnits';
import Select from 'components/Input/Select';
import Checkbox from 'components/Input/Checkbox';
import { Field } from 'redux-form';
import { getOptions, getFirstOption, getBaseUnits, getUnitOptions } from 'utils/fields';
import { getValidation, getDecimalPlaces } from 'utils/fieldValidation';
import { getCavityResistivity } from 'utils/enclosure/codeLayers';
import Accordion from 'components/Accordion';
import AccordionActions from "../../AccordionActions";

const layerValidation = getValidation('udefSteelFrameLayer');

const studsCornerValidation = getValidation('udefSteelFrameStudsCorner');
const studsIntWallValidation = getValidation('udefSteelFrameStudsIntWall');
const topBottomPlatesValidation = getValidation('udefSteelFrameTopBottomPlates');
const ridgeBoardValidation = getValidation('udefSteelFrameRidgeBoardWidth');
const blockingValidation = getValidation('udefSteelFrameBlockingWidth');

const framingGaugeValidation = getValidation('udefSteelFrameGauge');

const framingThicknessValidation = getValidation('udefSteelFrameThickness');
const framingWidthValidation = getValidation('udefSteelFrameWidth');
const framingSpacingValidation = getValidation('udefSteelFrameSpacing');

const cavityCategoryValidation = getValidation('udefSteelFrameCavityMaterialCat');
const cavityTypeValidation = getValidation('udefSteelFrameCavityMaterialType');
const cavityResistivityValidation = getValidation('udefSteelFrameCavityResistivity');
const cavityThicknessValidation = getValidation('udefSteelFrameCavityThickness');
const cavityRValueValidation = getValidation('udefSteelFrameCavityRVal');


export default React.memo(({
    change,
    accessor,
    componentType,
    steelGaugeId,
    steelGaugeValue,
    steelGaugeUnits,
    framingThicknessUnits,
    framingWidthUnits,
    framingSpacingUnits,
    cavityMaterialCatId,
    cavityMaterialTypeId,
    cavityResistivityValue,
    cavityResistivityUnits,
    cavityThicknessUnits,
    cavityRValue,
    cavityRValueUnits,
    modelUnits,
    isDragging= false,
    dragHandleProps,
    dragActive= false,
    layer,
    steelFramingLayers,
    id
}) => {

    const standardFraming = ['Wall','BasementWall','CrawlspaceWall','FloorHeader'].includes(componentType);
    const floorFraming = ['Floor','FloorsAbove'].includes(componentType);
    const materialTypeOptions = getOptions({fieldKey:'udefSteelFrameCavityMaterialType', indKey: cavityMaterialCatId}) || [];

    const {
        label=""
    } = materialTypeOptions.find(({ value:{ id=0 }={} }) => id === cavityMaterialTypeId) || {};

    const deleteLayer = useCallback(() => {
        const { [id]:_, ...remainingLayers }  = steelFramingLayers;
        change('uDefCode.layers.steelFraming', remainingLayers);
    },[id, steelFramingLayers, change]);

    const { displayBase:baseRValUnits="" } = getBaseUnits('udefSteelFrameCavityRVal', modelUnits);
    const rValueUnits = cavityRValueUnits || baseRValUnits;
    const materialLabel = label || `User Defined: ${cavityRValue} ${rValueUnits}`;

    //ADD USER DEFINED DEPENDENCIES
    return (
        <div>
            <Accordion
                className={classes.accordion}
                heading={
                    <span className={classes.accordionHeader}>
                        <span className={classes.accordionPreTitle}>Steel Framing</span>
                        <span className={classes.accordionTitle}>{materialLabel}</span>
                    </span>
                }
                number={layer}
                isDragging={isDragging}
                dragActive={dragActive}
                hoverActions={<AccordionActions dragHandleProps={dragHandleProps} accessor={accessor} deleteLayer={deleteLayer} />}
            >
                {
                    standardFraming &&
                    <>
                        <InputRow gridTemplate="1fr 1fr">
                                <Field
                                    component={Select}
                                    name={`${accessor}.isPrimary`}
                                    options={[
                                        {
                                            label: 'Primary',
                                            value: true
                                        },
                                        {
                                            label: 'Secondary',
                                            value: false
                                        }
                                    ]}
                                    label="Frame Type"
                                    placeholder="Choose Frame Type"
                                />
                        </InputRow>
                        <InputRow gridTemplate="1fr 1fr">
                            <Field
                                component={Input}
                                type="number"
                                name={`${accessor}.studsPerCorner`}
                                label="Studs per Corner"
                                placeholder="0"
                                quantity
                                validate={studsCornerValidation}
                            />
                            <Field
                                component={Input}
                                type="number"
                                name={`${accessor}.studsPerInteriorWall`}
                                label="Studs per Interior Intersection"
                                placeholder="0"
                                quantity
                                validate={studsIntWallValidation}
                                info={'The number of studs used when interior walls meets exterior walls.'}
                            />
                        </InputRow>
                        <InputRow gridTemplate="1fr 1fr">
                            <Field
                                component={Input}
                                type="number"
                                name={`${accessor}.topAndBottomPlates`}
                                label="Top & Bottom Plates"
                                placeholder="0"
                                quantity
                                validate={topBottomPlatesValidation}
                            />
                            <Field
                                component={Checkbox}
                                name={`${accessor}.doubleStudsOnWindows`}
                                label="Double Studs on Windows"
                                large
                                className={classes.bottomCheckBox}
                                type="checkbox"
                            />
                        </InputRow>
                    </>

                }
                {
                    !standardFraming && !floorFraming &&
                    <InputRow gridTemplate="1fr 1fr">
                        <Field
                            component={Input}
                            type="number"
                            name={`${accessor}.ridgeBoardWidth`}
                            label="Ridge Board Width"
                            placeholder="0"
                            quantity
                            validate={ridgeBoardValidation}
                        />
                        <Field
                            component={Input}
                            type="number"
                            name={`${accessor}.blockingWidth`}
                            label="Blocking Width"
                            placeholder="0"
                            quantity
                            validate={blockingValidation}
                        />
                    </InputRow>
                }
                <h4>Framing</h4>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Select}
                        name={`${accessor}.framing.steelGauge`}
                        options={[
                            {
                                label:"User Specified",
                                value:{
                                    id:0,
                                    value:steelGaugeValue, //need to get rValue for this to match
                                }
                            },
                            ...getOptions({fieldKey:'udefSteelFrameGauge'})
                        ]}
                        label="Steel Gauge"
                        placeholder="Choose Steel Gauge"
                        validate={framingGaugeValidation}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.framing.steelGauge.value`}
                        label="Steel Gauge Thickness"
                        placeholder={0.00}
                        disabled={steelGaugeId !== 0}
                        validate={framingGaugeValidation}
                        setTouched={true}
                        decimals={getDecimalPlaces('udefSteelFrameGauge')}
                        change={change}
                        setValue={steelGaugeValue}
                        units={{
                            base:getBaseUnits('udefSteelFrameGauge', modelUnits),
                            options:getUnitOptions('thermalRe'),
                            selected:steelGaugeUnits,
                            unitType:'length',
                            accessor:`${accessor}.framing.steelGauge_u`
                        }}
                        warning={steelGaugeId === 0 && steelGaugeValue === 0 ? 'steelGaugeZero' : ''}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.framing.thickness`}
                        label="Thickness"
                        placeholder={0.00}
                        validate={framingThicknessValidation}
                        setTouched={true}
                        decimals={getDecimalPlaces('udefSteelFrameThickness')}
                        change={change}
                        units={{
                            base:getBaseUnits('udefSteelFrameThickness', modelUnits),
                            options:getUnitOptions('length'),
                            selected:framingThicknessUnits,
                            unitType:'length',
                            accessor:`${accessor}.framing.thickness_u`
                        }}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.framing.width`}
                        label="Width"
                        placeholder={0.00}
                        validate={framingWidthValidation}
                        setTouched={true}
                        decimals={getDecimalPlaces('udefSteelFrameWidth')}
                        change={change}
                        units={{
                            base:getBaseUnits('udefSteelFrameWidth', modelUnits),
                            options:getUnitOptions('length'),
                            selected:framingWidthUnits,
                            unitType:'length',
                            accessor:`${accessor}.framing.width_u`
                        }}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.framing.spacing`}
                        label="Spacing"
                        placeholder={0.00}
                        validate={framingSpacingValidation}
                        setTouched={true}
                        decimals={getDecimalPlaces('udefSteelFrameSpacing')}
                        change={change}
                        units={{
                            base:getBaseUnits('udefSteelFrameSpacing', modelUnits),
                            options:getUnitOptions('length'),
                            selected:framingSpacingUnits,
                            unitType:'length',
                            accessor:`${accessor}.framing.spacing_u`
                        }}
                    />
                </InputRow>
                <h4>Cavity Insulation</h4>
                <InputRow gridTemplate="1fr">
                    <Field
                        component={Select}
                        name={`${accessor}.cavityInsulation.materialCategory`}
                        options={getOptions({fieldKey:'udefSteelFrameCavityMaterialCat'})}
                        label="Material Category"
                        placeholder="Choose Material Category"
                        validate={cavityCategoryValidation}
                        onChange={({id})=>{
                            if (id === 0) {
                                change(`${accessor}.cavityInsulation.materialType`, { id: null })
                            } else {
                                change(
                                    `${accessor}.cavityInsulation.materialType`, 
                                    getFirstOption({indKey:id, depKey:'udefSteelFrameCavityMaterialType'})
                                );
                            }
                        }}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Select}
                        name={`${accessor}.cavityInsulation.materialType`}
                        options={getOptions({fieldKey:'udefSteelFrameCavityMaterialType', indKey: cavityMaterialCatId})}
                        disabled={cavityMaterialCatId === 0}
                        label="Material Type"
                        placeholder="Choose Material Type"
                        validate={cavityTypeValidation}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.cavityInsulation.resistivity`}
                        label="Resistivity"
                        placeholder={0.00}
                        disabled={cavityMaterialCatId !== 0}
                        validate={cavityResistivityValidation}
                        setTouched={true}
                        decimals={getDecimalPlaces('udefSteelFrameCavityResistivity')}
                        change={change}
                        setValue={cavityMaterialTypeId == null ? cavityResistivityValue : getCavityResistivity(cavityMaterialTypeId)}
                        units={{
                            base:getBaseUnits('udefSteelFrameCavityResistivity', modelUnits),
                            options:getUnitOptions('thermalResistivity'),
                            selected:cavityResistivityUnits,
                            unitType:'thermalResistivity',
                            accessor:`${accessor}.cavityInsulation.resistivity_u`
                        }}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.cavityInsulation.thickness`}
                        label="Thickness"
                        placeholder={0.00}
                        validate={cavityThicknessValidation}
                        setTouched={true}
                        decimals={getDecimalPlaces('udefSteelFrameCavityThickness')}
                        change={change}
                        units={{
                            base:getBaseUnits('udefSteelFrameCavityThickness', modelUnits),
                            options:getUnitOptions('length'),
                            selected:cavityThicknessUnits,
                            unitType:'length',
                            accessor:`${accessor}.cavityInsulation.thickness_u`
                        }}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.cavityInsulation.rValue`}
                        label="R-Value"
                        placeholder={0.00}
                        disabled={true}
                        setValue={cavityRValue}
                        validate={cavityRValueValidation}
                        setTouched={true}
                        decimals={getDecimalPlaces('udefSteelFrameCavityRVal')}
                        change={change}
                        units={{
                            base:getBaseUnits('udefSteelFrameCavityRVal', modelUnits),
                            options:getUnitOptions('thermalResistance'),
                            selected:cavityRValueUnits,
                            unitType:'thermalResistance',
                            accessor:`${accessor}.cavityInsulation.rValue_u`
                        }}
                    />
                </InputRow>
            </Accordion>
        </div>
    )
});