import Konva from "konva";
import moment from "moment";

import {
    newShape,
    removeAnchors,
    attachAnchors,
    calcPolygonValues,
    calcRectangleValues,
    newTransformer,
    getMultiPointLineLength,
} from "utils/drawing/shapes";

import convertUnit from "utils/convertUnit";
import { isEmpty } from "lodash";

export const getDrawingComponent = (imageData, componentId) =>
    Object.keys(imageData).reduce((cache, image) => {
        const { components = {}, polygons = {} } = imageData[image] || {};

        const windowOrWall = Object.keys(components)
            .filter((c) => {
                const { componentModelId = "" } = components[c] || {};

                return componentId && componentModelId === componentId;
            })
            .reduce((cache, c) => {
                const { componentModelId = "" } = components[c] || {};
                return {
                    drawingShapeId: c,
                    componentModelId,
                    type: "component",
                    componentData: components[c],
                    image,
                };
            }, {});

        const polygonComponent = Object.keys(polygons).reduce((cache, poly) => {
            const { components = {} } = polygons[poly] || {};
            const polyComponent = Object.keys(components)
                .filter((c) => componentId && c === componentId)
                .reduce(
                    (cache, c) => ({
                        componentModelId: c,
                        drawingShapeId: poly,
                        type: "polygonComponent",
                        componentData: components[c],
                        otherComponents: components,
                        image,
                    }),
                    {}
                );
            return {
                ...cache,
                ...polyComponent,
            };
        }, {});

        return {
            ...cache,
            ...windowOrWall,
            ...polygonComponent,
        };
    }, {});

export const getUpdatedComponent = (componentData, pathArray, fieldValue) => {
    const newComponent = pathArray.reduce((cache, key, index) => {
        if (index === pathArray.length - 1) {
            cache[key] = fieldValue;
            return cache;
        }
        return cache[key];
    }, componentData);

    return componentData;
};

export const buildImageData = ({ imageData, linesToDetach, lineFieldsToUpdate, prepComponents }) => {
    const newImageData = Object.keys(imageData).reduce((cache, image) => {
        const { lines = {}, components = {}, polygons = {}, scale = {} } = imageData[image];

        const newLines = Object.keys(lines).reduce((lineCache, line) => {
            let lineData = lines[line];

            if (!linesToDetach[line] && !lineFieldsToUpdate[line]) {
                return lineCache;
            }

            if (linesToDetach[line]) {
                lineData = {
                    ...lineData,
                    attachTo: {},
                    changeField: {},
                };
            }

            if (lineFieldsToUpdate[line]) {
                const { drawingReduxUpdates: { updates = {} } = {} } = lineFieldsToUpdate[line];

                lineData = {
                    ...lineData,
                    ...updates,
                };
            }

            return {
                ...lineCache,
                [line]: lineData,
            };
        }, lines);

        const newComponents = Object.keys(components).reduce((componentCache, component) => {
            const { attachTo = {}, type = "" } = components[component];

            const { componentType: parentType = "", componentId: parentId = "", modelRef = "" } = attachTo;

            if (modelRef) {
                return componentCache;
            }

            const { componentModelId = "" } = prepComponents[component] || {};
            const newModelRef = `${parentType}.${parentId}.subcomponents.${type}.${componentModelId}`;

            const newComponentData = {
                ...components[component],
                attachTo: {
                    ...attachTo,
                    modelRef: newModelRef,
                },
            };

            return {
                ...componentCache,
                [component]: newComponentData,
            };
        }, components);

        return {
            ...cache,
            [image]: {
                lines: newLines,
                components: newComponents,
                polygons,
                scale,
            },
        };
    }, {});

    return newImageData;
};

export const closestPointOnLineSegment = (px, py, x1, y1, x2, y2) => {
    const lineLengthSquared = (x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1);
    if (lineLengthSquared === 0) {
        return { x: x1, y: y1 };
    }

    const t = Math.max(0, Math.min(1, ((px - x1) * (x2 - x1) + (py - y1) * (y2 - y1)) / lineLengthSquared));
    const projectionX = x1 + t * (x2 - x1);
    const projectionY = y1 + t * (y2 - y1);

    return { x: projectionX, y: projectionY };
};

export const checkForSnap = (mousePos, snapLines, setSnapPoint) => {
    let closestDistance = 10; // Adjust this as necessary
    let closestSnapPoint = null;

    if (!snapLines) return;

    snapLines.forEach((line) => {
        const closestPoint = closestPointOnLineSegment(
            mousePos.x,
            mousePos.y,
            line.start.x,
            line.start.y,
            line.end.x,
            line.end.y
        );
        const distance = Math.sqrt(Math.pow(closestPoint.x - mousePos.x, 2) + Math.pow(closestPoint.y - mousePos.y, 2));

        if (distance < closestDistance) {
            closestDistance = distance;
            closestSnapPoint = closestPoint;
        }
    });

    setSnapPoint(closestSnapPoint ? { x: closestSnapPoint.x, y: closestSnapPoint.y } : null);
};

const infoSquareText = (text, x, y, width = 100) =>
    new Konva.Text({
        x: x - 13,
        y: y - 65,
        text,
        fontSize: 12,
        fontFamily: "Noto Sans TC",
        fill: "#000",
        id: "info_square",
        width,
        padding: 20,
        align: "left",
    });

const infoSquareRect = (x, y, text, heightOffset = 20, width = 75, stroke = "#000") =>
    new Konva.Rect({
        x: x,
        y: y - 50,
        stroke,
        strokeWidth: 1,
        fill: "rgba(255, 255, 255, 0.85)",
        width,
        height: text.height() - heightOffset,
        shadowColor: "black",
        id: "info_square",
        shadowBlur: 10,
        shadowOffsetX: 5,
        shadowOffsetY: 5,
        shadowOpacity: 0.1,
    });

const findPolygonCenter = (points) => {
    // Parse points into coordinate pairs
    let coordinates = [];
    for (let i = 0; i < points.length; i += 2) {
        coordinates.push([points[i], points[i + 1]]);
    }

    // Calculate centroid using shoelace formula
    let area = 0;
    let centerX = 0;
    let centerY = 0;
    let n = coordinates.length;

    for (let i = 0; i < n; i++) {
        let j = (i + 1) % n;
        let factor = coordinates[i][0] * coordinates[j][1] - coordinates[j][0] * coordinates[i][1];
        area += factor;
        centerX += (coordinates[i][0] + coordinates[j][0]) * factor;
        centerY += (coordinates[i][1] + coordinates[j][1]) * factor;
    }

    area /= 2;
    centerX /= 6 * area;
    centerY /= 6 * area;

    return [centerX, centerY];
};

const checkDistance = (x1, y1, id, allPoints) => {
    let closestDistance = 30;
    let closestPoint = null;

    if (!allPoints) return;

    allPoints.forEach((point) => {
        const distance = Math.sqrt(Math.pow(point.x - x1, 2) + Math.pow(point.y - y1, 2));

        if (distance < closestDistance && point.id !== id) {
            closestDistance = distance;
            closestPoint = point;
        }
    });

    return closestPoint;
};

// Check if point is on left or right or top or bottom from the closest point, should return "RIGHT", "LEFT", "TOP", "BOTTOM
const checkWherePointIs = (x1, y1, x2, y2) => {
    let direction = "";

    if (y1 < y2) {
        direction = "BOTTOM";
    }

    if (y1 > y2) {
        direction = "TOP";
    }

    return direction;
};

export const generateInfoSquare = (stage, layer, shapes) => {
    let allPoints = [];

    if (!shapes) return;

    const shapesKeys = Object.keys(shapes);

    const displayUnits = shapes["scale"].displayUnits;

    const conversionFactor = shapes["scale"].input / shapes["scale"].px;

    for (let i = 0; i < shapesKeys.length; i++) {
        const shapeKey = shapesKeys[i];

        if (shapeKey === "scale") {
            const { displayUnits, input, x, y, id, copiedFrom } = shapes[shapeKey];

            const findId = !copiedFrom ? id : copiedFrom;

            allPoints.push({
                shapeKey,
                x: x,
                y: y,
                id,
                input,
                displayUnits,
                copiedFrom,
            });
        }

        if (shapeKey === "rectangles") {
            const rectShapes = shapes[shapeKey];

            const rectShapesKeys = Object.keys(rectShapes);

            for (let r = 0; r < rectShapesKeys.length; r++) {
                const rectKey = rectShapesKeys[r];

                const { x, y, area, perimeter, height, width, name, id } = rectShapes[rectKey];

                allPoints.push({ x, y, area, perimeter, height, width, name, id, shapeKey });
            }
        }

        if (shapeKey === "multiPointLines") {
            const multiPointShapes = shapes[shapeKey];

            const multiPointShapesKeys = Object.keys(multiPointShapes);

            for (let m = 0; m < multiPointShapesKeys.length; m++) {
                const rectKey = multiPointShapesKeys[m];

                const { x, y, id, name, totalLength } = multiPointShapes[rectKey];

                allPoints.push({
                    x: x + stage.current.find(`#${id}`)[0].points()[0],
                    y: y + stage.current.find(`#${id}`)[0].points()[1],
                    id,
                    name,
                    totalLength,
                    shapeKey,
                });
            }
        }

        if (shapeKey === "lines") {
            const linesShapes = shapes[shapeKey];

            const linesShapesKeys = Object.keys(linesShapes);

            for (let m = 0; m < linesShapesKeys.length; m++) {
                const rectKey = linesShapesKeys[m];

                const { x, y, name, totalLength, points, id, shapeKey } = linesShapes[rectKey];

                allPoints.push({
                    x: x + points[0] || 0 + points[0],
                    y: y + points[1] || 0 + points[1],
                    name,
                    totalLength,
                    points,
                    id,
                });
            }
        }

        if (shapeKey === "polygons") {
            const polygonShapes = shapes[shapeKey];

            const polygonShapesKeys = Object.keys(polygonShapes);

            for (let p = 0; p < polygonShapesKeys.length; p++) {
                const polygonKey = polygonShapesKeys[p];

                const { x, y, name, area, perimeter, points, id } = polygonShapes[polygonKey];

                const absolutePoints = points.map((point, index) => {
                    if (index % 2 === 0) {
                        return point + x;
                    } else {
                        return point + y;
                    }
                });

                // should return left corner of polygon points [x, y]
                const mostLeftPoint = absolutePoints.reduce(
                    (acc, point, index) => {
                        if (index % 2 === 0) {
                            if (point < acc[0]) {
                                acc[0] = point;
                            }
                        } else {
                            if (point < acc[1]) {
                                acc[1] = point;
                            }
                        }

                        return acc;
                    },
                    [absolutePoints[0], absolutePoints[1]]
                );

                allPoints.push({
                    id,
                    shapeKey,
                    x: mostLeftPoint[0],
                    y: mostLeftPoint[1],
                    name,
                    area,
                    perimeter,
                    points,
                });
            }
        }
    }

    allPoints.forEach((point) => {
        if (point.shapeKey === "scale") {
            const { displayUnits, input, x, y, id, copiedFrom } = point;

            const findId = !copiedFrom ? id : copiedFrom;
            const points = stage.current.find(`#${findId}`)[0]?.points() || [];

            if (isEmpty(points)) return;

            const textBoxCoords = !copiedFrom ? [x + points[0], y + points[1]] : [16, 84]; //Top corner if copied

            const text = infoSquareText(`Scale:\n${input}${displayUnits}`, textBoxCoords[0], textBoxCoords[1]);

            // points[0]
            const rect = infoSquareRect(textBoxCoords[0], textBoxCoords[1], text, 20, 75, "#0066B1");

            if (layer.current && stage.current) {
                layer.current.add(rect);
                layer.current.add(text);

                stage.current.draw();
            }
        }

        if (point.shapeKey === "polygons") {
            let { x, y, name, area, perimeter, id } = point;

            let newY = y;

            const closestPoint = checkDistance(x, y, id, allPoints);

            if (closestPoint) {
                const direction = checkWherePointIs(x, y, closestPoint.x, closestPoint.y);

                if (direction === "TOP") {
                    newY += 20;
                }

                if (direction === "BOTTOM") {
                    newY -= 30;
                }
            }

            const convertedPerimeter = convertUnit({
                value: perimeter * conversionFactor,
                type: "length",
                inputUnit: "m",
                outputUnit: displayUnits,
            });

            const convertedArea = convertUnit({
                value: area * Math.pow(conversionFactor, 2),
                type: "area",
                inputUnit: "m2",
                outputUnit: `${displayUnits}2`,
            });

            const text = infoSquareText(
                `${name}:\nArea: ${convertedArea.toFixed(2)}${displayUnits}²\nPerimeter: ${convertedPerimeter.toFixed(
                    2
                )}${displayUnits}`,
                x + -50,
                newY,
                150
            );

            const rect = infoSquareRect(x - 50, newY, text, 20, 150, "#FF8C1D");

            if (layer.current && stage.current) {
                layer.current.add(rect);
                layer.current.add(text);

                stage.current.draw();
            }
        }

        if (point.shapeKey === "lines") {
            const { x, y, name, totalLength, points, id } = point;

            let newX = x;

            const closestPoint = checkDistance(x, y, id, allPoints);

            if (closestPoint) {
                const direction = checkWherePointIs(x, y, closestPoint.x, closestPoint.y);

                if (direction === "TOP") {
                    newX += 30;
                }

                if (direction === "BOTTOM") {
                    newX += 30;
                }
            }

            const convertedLength = convertUnit({
                value: totalLength * conversionFactor,
                type: "length",
                inputUnit: "m",
                outputUnit: `${displayUnits}`,
            });

            const text = infoSquareText(
                `${name}:\nLength: ${convertedLength.toFixed(2)}${displayUnits}`,
                newX + points[0] || 0 + points[0],
                y + points[1] || 0 + points[1],
                150
            );

            const rect = infoSquareRect(
                x + points[0] || 0 + points[0],
                y + points[1] || 0 + points[1],
                text,
                20,
                150,
                "#F5547C"
            );

            if (layer.current && stage.current) {
                layer.current.add(rect);
                layer.current.add(text);

                stage.current.draw();
            }
        }

        if (point.shapeKey === "multiPointLines") {
            const { x, y, id, name, totalLength } = point;

            let newX = x;

            const closestPoint = checkDistance(x, y, id, allPoints);

            if (closestPoint) {
                const direction = checkWherePointIs(x, y, closestPoint.x, closestPoint.y);

                if (direction === "TOP") {
                    newX += 30;
                }

                if (direction === "BOTTOM") {
                    newX += 30;
                }
            }

            const convertedTotalLength = convertUnit({
                value: totalLength * Math.pow(conversionFactor, 2),
                type: "length",
                inputUnit: "m",
                outputUnit: `${displayUnits}`,
            });

            const text = infoSquareText(
                `${name}:\nPerimeter: ${convertedTotalLength.toFixed(2)}${displayUnits}`,
                newX + stage.current.find(`#${id}`)[0].points()[0],
                y + stage.current.find(`#${id}`)[0].points()[1],
                150
            );

            const rect = infoSquareRect(
                newX + stage.current.find(`#${id}`)[0].points()[0],
                y + stage.current.find(`#${id}`)[0].points()[1],
                text,
                20,
                150,
                "rgb(24, 193, 173)"
            );

            if (layer.current && stage.current) {
                layer.current.add(rect);
                layer.current.add(text);

                stage.current.draw();
            }
        }

        if (point.shapeKey === "rectangles") {
            const { x, y, area, perimeter, height, width, name, id } = point;

            let newY = y;

            const closestPoint = checkDistance(x, y, id, allPoints);

            if (closestPoint) {
                const direction = checkWherePointIs(x, y, closestPoint.x, closestPoint.y);

                if (direction === "TOP") {
                    newY += 20;
                }

                if (direction === "BOTTOM") {
                    newY -= 50;
                }
            }

            const convertedHeight = convertUnit({
                value: height * conversionFactor,
                type: "drawingScale",
                inputUnit: "m",
                outputUnit: displayUnits,
            });

            const convertedWidth = convertUnit({
                value: width * conversionFactor,
                type: "drawingScale",
                inputUnit: "m",
                outputUnit: displayUnits,
            });

            const convertedPerimeter = convertUnit({
                value: perimeter * conversionFactor,
                type: "length",
                inputUnit: "m",
                outputUnit: displayUnits,
            });

            const convertedArea = convertUnit({
                value: area * Math.pow(conversionFactor, 2),
                type: "area",
                inputUnit: "m2",
                outputUnit: `${displayUnits}2`,
            });

            const text = infoSquareText(
                `${name}:\nArea: ${convertedArea.toFixed(2)}${displayUnits}²\nPerimeter: ${convertedPerimeter.toFixed(
                    2
                )}${displayUnits}\nHeight: ${convertedHeight.toFixed(
                    2
                )}${displayUnits}\nWidth: ${convertedWidth.toFixed(2)}${displayUnits}`,
                x,
                newY,
                150
            );

            const rect = infoSquareRect(x, newY, text, 20, 150, "#62BCF8");

            if (layer.current && stage.current) {
                layer.current.add(rect);
                layer.current.add(text);

                stage.current.draw();
            }
        }
    });
};

export const removeInfoSquares = (stage, layer) => {
    if (!stage.current || !layer.current) return;

    const allShapes = stage.current.find("#info_square");

    for (let i = 0; i < allShapes.length; i++) {
        const shape = allShapes[i];

        shape.destroy();
    }

    stage.current.draw();
};

/* ----------------- Shapes START ----------------- */
export const newMeasure = ({
    image,
    x,
    y,
    activeTool,
    stage,
    layer,
    setMessage,
    setScale,
    primaryUnits,
    setAction,
    isSnapMode,
    snapPoint,
    setDefaultMessage,
    setActiveComponent,
}) => {
    const fullId = `${activeTool}_${image}`;

    x = isSnapMode && snapPoint ? snapPoint.x : x;
    y = isSnapMode && snapPoint ? snapPoint.y : y;

    const shape = newShape({
        shape: activeTool,
        id: fullId,
        image,
        x,
        y,
        points: [0, 0],
        stage: stage.current,
        selected: true,
    });

    layer.current.add(shape);

    setMessage({
        message: "Place the second scale point.",
        type: "info",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
        autoHideDuration: null,
        isOpen: true,
        direction: "up",
    });

    setScale({
        image,
        scale: {
            id: fullId,
            px: 0,
            input: 0,
            units: "m",
            displayUnits: primaryUnits,
            points: [0, 0],
            isSet: false,
            x,
            y,
        },
    });

    setAction({
        id: "setScale",
        meta: {
            image,
            cancel: () => {
                // setActiveTool("");
                // setAction({ id: null, meta: {} });
                setScale({
                    image,
                    scale: {},
                });
                setDefaultMessage();
                setActiveComponent("");

                shape.destroy();
                stage.current.draw();
            },
        },
    });

    setActiveComponent(fullId);
};

export const continueNewMeasure = ({
    image,
    x,
    y,
    imageData,
    isSnapMode,
    snapPoint,
    activeComponent,
    setScale,
    primaryUnits,
    setMessage,
    setAction,
    setDefaultMessage,
    stage,
    setActiveTool,
    setActiveComponent,
    layer,
}) => {
    const { scale } = imageData[image];

    x = isSnapMode && snapPoint ? snapPoint.x : x;
    y = isSnapMode && snapPoint ? snapPoint.y : y;

    const measureGroup = layer.current.findOne((node) => node.getId() === `group_${activeComponent}`);
    const measure = measureGroup.findOne((node) => node.getId() === activeComponent);

    const oldPoints = measure.points();
    const groupX = measureGroup.x();
    const groupY = measureGroup.y();
    const newPoints = [...oldPoints, x - groupX, y - groupY];

    measure.points(newPoints);

    removeAnchors(measureGroup, stage.current);

    const anchors = attachAnchors(activeComponent, measure, image, stage.current);

    setScale({
        image,
        scale: {
            ...scale,
            px: Math.hypot(newPoints[0] - newPoints[2], newPoints[1] - newPoints[3]),
            input: 0,
            units: "m",
            displayUnits: primaryUnits,
            points: [...newPoints],
            isSet: false,
        },
    });

    anchors.map((anchor) => measureGroup.add(anchor));

    setMessage({
        message: "Type in the measurement.",
        type: "info",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
        autoHideDuration: null,
        isOpen: true,
        direction: "up",
    });

    setAction({
        id: "setScale",
        meta: {
            image,
            cancel: () => {
                // setActiveTool("");
                // setAction({ id: null, meta: {} });
                setScale({
                    image,
                    scale: {},
                });
                // setActiveComponent("");
                setDefaultMessage();

                measureGroup.destroy();
                stage.current.draw();
            },
            onSet: () => {
                setActiveTool("");

                setAction({
                    id: "setScale",
                    meta: {
                        image,
                        deactivate: () => {
                            removeAnchors(measureGroup, stage.current);
                            setActiveComponent("");
                            setAction({ id: null, meta: {} });

                            stage.current.draw();
                        },
                    },
                });

                stage.current.draw();
            },
        },
    });
};

export const handleMeasureChange = ({ shapePoints, currentScale, image, x, y, imageData, setScale }) => {
    currentScale.px = Math.hypot(shapePoints[0] - shapePoints[2], shapePoints[1] - shapePoints[3]);

    setScale({
        image,
        scale: {
            ...currentScale,
            points: shapePoints,
        },
    });

    const imageDataArr = Object.keys(imageData);

    if (imageDataArr.length > 1) {
        for (let i = 0; i < imageDataArr.length; i++) {
            const imgScale = imageData[imageDataArr[i]].scale;

            if (!imgScale) continue;

            if (!imgScale.copiedFrom) continue;

            if (imgScale.copiedFrom !== image) continue;

            setScale({
                image: imageDataArr[i],
                scale: {
                    ...currentScale,
                    points: shapePoints,

                    copiedFrom: imgScale.copiedFrom,
                },
            });
        }
    }
};

export const newPolygon = ({
    image,
    x,
    y,
    imageData,
    activeTool,
    isSnapMode,
    snapPoint,
    stage,
    layer,
    setPolygon,
    setAction,
    setActiveComponent,
    removePolygon,
    setActiveTool,
}) => {
    const { polygons = {} } = imageData[image] || {};

    const fullId = `${activeTool}_${image}_${moment().format("YYYYMMDDHHmmssSS")}`;

    x = isSnapMode && snapPoint ? snapPoint.x : x;
    y = isSnapMode && snapPoint ? snapPoint.y : y;

    // if active component is an outline, add points, otherwise create new shape
    const shape = newShape({
        shape: activeTool,
        id: fullId,
        image,
        x,
        y,
        defaultPxLength: 100,
        stage: stage.current,
        selected: true,
    });

    shape.moveToTop();

    // changeActiveOutline(fullId);
    setActiveComponent(fullId);

    if (layer.current) layer.current.add(shape);

    setPolygon(image, {
        id: fullId,
        shape: "polygon",
        takeoffSection: "",
        points: [0, 0],
        name: `Polygon ${Object.keys(polygons).length + 1}`,
        isSet: false,
        x,
        y,
        order: -1,
    });

    setAction({
        id: "setPolygon",
        meta: {
            image,
            cancel: () => {
                setActiveTool("");
                setAction({ id: null, meta: {} });
                setActiveComponent("");
                removePolygon(image, fullId);

                shape.destroy();
                stage.current.draw();
            },
        },
    });
};

export const continuePolygon = ({
    image,
    x,
    y,
    imageData,
    isSnapMode,
    snapPoint,
    activeComponent,
    stage,
    layer,
    setActiveTool,
    setAction,
    removePolygon,
    setPolygon,
    setActiveComponent,
    deactivateComponent,
}) => {
    const { polygons = {} } = imageData[image] || {};

    x = isSnapMode && snapPoint ? snapPoint.x : x;
    y = isSnapMode && snapPoint ? snapPoint.y : y;

    const activePolygon = polygons[activeComponent];

    const polygonGroup = layer.current.findOne((node) => node.getId() === `group_${activeComponent}`);
    const polygon = polygonGroup.findOne((node) => node.getId() === activeComponent);

    const oldPoints = polygon.points();
    const groupX = polygonGroup.x();
    const groupY = polygonGroup.y();
    const newPoints = [...oldPoints, x - groupX, y - groupY];

    polygon.points(newPoints);

    removeAnchors(polygonGroup, stage.current);

    const anchors = attachAnchors(activeComponent, polygon, image, stage.current);

    anchors.map((anchor) => polygonGroup.add(anchor));

    setPolygon(image, { ...activePolygon, points: newPoints });

    setAction({
        id: "setPolygon",
        meta: {
            image,
            cancel: () => {
                setActiveTool("");
                setAction({ id: null, meta: {} });
                setActiveComponent("");
                removePolygon(image, activeComponent);

                polygonGroup.destroy();
                stage.current.draw();
            },
            onSet: () => {
                const { perimeter, area } = calcPolygonValues({
                    points: newPoints,
                });

                setActiveTool("");

                setPolygon(image, {
                    ...activePolygon,
                    points: newPoints,
                    perimeter,
                    area,
                    isSet: true,
                });

                setAction({
                    id: "setPolygon",
                    meta: {
                        image,
                        deactivate: () => {
                            setAction({ id: null, meta: {} });
                            setActiveComponent("");
                            removeAnchors(polygonGroup);

                            polygonGroup.getParent().setAttr("draggable", false);

                            stage.current.draw();
                        },
                        deleteShape: () => {
                            deactivateComponent(activePolygon.id);
                            setAction({ id: null, meta: {} });
                            setActiveComponent("");
                            removePolygon(image, activePolygon.id);

                            polygonGroup.destroy();

                            stage.current.draw();
                        },
                    },
                });

                stage.current.draw();
            },
        },
    });
};

export const editPolygonPoints = ({
    image,
    x,
    y,
    isAnchor,
    attrs,
    imageData,
    activeComponent,
    setPolygon,
    stage,
    layer,
}) => {
    const { polygons = {} } = imageData[image] || {};

    const activePolygon = polygons[activeComponent];

    const polygonGroup = layer.current.findOne((node) => node.getId() === `group_${activeComponent}`);
    const polygon = polygonGroup.findOne((node) => node.getId() === activeComponent);

    const oldPoints = polygon.points();
    const groupX = polygonGroup.x();
    const groupY = polygonGroup.y();

    let newPoints = [...oldPoints];

    if (isAnchor) {
        const { x } = attrs;

        const index = newPoints.indexOf(x);

        if (index > -1 && newPoints.length / 2 > 3) {
            newPoints.splice(index, 2);
        }
    } else {
        const newX = x - groupX;
        const newY = y - groupY;

        let shortestDistance = 0;
        let shortestIndex = 0;

        for (let i = 0; i < newPoints.length; i += 2) {
            const x1 = newPoints[i];
            const y1 = newPoints[i + 1];
            const x2 = newPoints[(i + 2) % newPoints.length];
            const y2 = newPoints[(i + 3) % newPoints.length];

            const dx = x2 - x1;
            const dy = y2 - y1;

            let t = ((newX - x1) * dx + (newY - y1) * dy) / (dx * dx + dy * dy);

            let closestX, closestY;

            if (t < 0) {
                closestX = x1;
                closestY = y1;
            } else if (t > 1) {
                closestX = x2;
                closestY = y2;
            } else {
                closestX = x1 + t * dx;
                closestY = y1 + t * dy;
            }

            const distance = Math.hypot(newX - closestX, newY - closestY);

            if (i === 0 || distance < shortestDistance) {
                shortestDistance = distance;
                shortestIndex = i;
            }
        }

        newPoints.splice(shortestIndex + 2, 0, newX, newY);
    }

    polygon.points(newPoints);

    const { perimeter, area } = calcPolygonValues({
        points: newPoints,
    });

    const anchors = attachAnchors(activeComponent, polygon, image, stage.current);

    removeAnchors(polygonGroup);
    anchors.map((anchor) => polygonGroup.add(anchor));

    stage.current.draw();

    setPolygon(image, { ...activePolygon, points: newPoints, perimeter, area });
};

export const handlePolygonChange = ({ points = [], id = "", image = "", x = 0, y = 0, setPolygon, imageData }) => {
    const { polygons = {} } = imageData[image] || {};

    const polygon = polygons[id];

    const { perimeter, area } = calcPolygonValues({ points });

    setPolygon(image, {
        ...polygon,
        x,
        y,
        points,
        perimeter,
        area,
    });
};

export const handleRectangleChange = ({ id, image, height, width, x, y, setRectangle }) => {
    const { perimeter, area } = calcRectangleValues(height, width);

    setRectangle(image, {
        id,
        x,
        y,
        height,
        width,
        perimeter,
        area,
    });
};

export const newRectangle = ({
    image,
    x,
    y,
    imageData,
    isSnapMode,
    snapPoint,
    stage,
    layer,
    setAction,
    setRectangle,
    removeRectangle,
    setActiveComponent,
    activeTool,
}) => {
    const { rectangles = {} } = imageData[image] || {};

    const fullId = `${activeTool}_${image}_${moment().format("YYYYMMDDHHmmssSS")}`;

    x = isSnapMode && snapPoint ? snapPoint.x : x;
    y = isSnapMode && snapPoint ? snapPoint.y : y;

    const shape = newShape({
        shape: activeTool,
        id: fullId,
        image,
        x,
        y,
        width: 60,
        height: 100,
        stage: stage.current,
        selected: true,
        shapeChangeHandler: ({ id, image, height, width, x, y, stage }) =>
            handleRectangleChange({ id, image, height, width, x, y, stage, setRectangle }),
    });

    layer.current.add(shape);

    const transformer = newTransformer(fullId);
    transformer.nodes([shape]);

    layer.current.add(transformer);

    setActiveComponent(fullId);

    const { perimeter, area } = calcRectangleValues(100, 60);

    setRectangle(image, {
        id: fullId,
        shape: "rectangle",
        componentType: "",
        takeoffSection: "",
        width: 60,
        x,
        y,
        height: 100,
        name: `Rectangle ${Object.keys(rectangles).length + 1}`,
        perimeter,
        area,
        order: -1,
    });

    shape.moveToTop();

    setAction({
        id: "setRectangle",
        meta: {
            image,
            deleteShape: () => {
                removeRectangle(image, fullId);
                setActiveComponent("");
                setAction({ id: null, meta: {} });

                shape.destroy();
                transformer.destroy();

                stage.current.draw();
            },
            deactivate: () => {
                setActiveComponent("");
                setAction({ id: null, meta: {} });

                transformer.destroy();

                stage.current.draw();
            },
        },
    });
};

export const newLine = ({
    image,
    x,
    y,
    imageData,
    isSnapMode,
    snapPoint,
    stage,
    layer,
    setLine,
    setAction,
    setActiveComponent,
    activeTool,
    removeLine,
    setActiveTool,
}) => {
    const { lines = {} } = imageData[image] || {};

    x = isSnapMode && snapPoint ? snapPoint.x : x;
    y = isSnapMode && snapPoint ? snapPoint.y : y;

    const fullId = `${activeTool}_${image}_${moment().format("YYYYMMDDHHmmssSS")}`;

    const shape = newShape({
        shape: activeTool,
        id: fullId,
        image,
        x,
        y,
        points: [0, 0],
        stage: stage.current,
        selected: true,
    });

    layer.current.add(shape);

    setActiveComponent(fullId);

    setLine(image, {
        name: `Line ${Object.keys(lines).length + 1}`,
        id: fullId,
        shape: "line",
        isSet: false,
        x,
        y,
        points: [0, 0],
        length: 0,
        takeoffSection: "",
        order: -1,
    });

    shape.moveToTop();

    setAction({
        id: "setLine",
        meta: {
            image,
            cancel: () => {
                setActiveTool("");
                setAction({ id: null, meta: {} });
                setActiveComponent("");
                removeLine(image, fullId);

                shape.destroy();
                stage.current.draw();
            },
        },
    });
};

export const continueNewLine = ({
    image,
    x,
    y,
    imageData,
    isSnapMode,
    snapPoint,
    activeComponent,
    stage,
    layer,
    setAction,
    setActiveComponent,
    setLine,
    setActiveTool,
    removeLine,
}) => {
    const { lines = {} } = imageData[image] || {};

    x = isSnapMode && snapPoint ? snapPoint.x : x;
    y = isSnapMode && snapPoint ? snapPoint.y : y;

    const activeLine = lines[activeComponent];

    const lineGroup = layer.current.findOne((node) => node.getId() === `group_${activeComponent}`);
    const line = lineGroup.findOne((node) => node.getId() === activeComponent);

    const oldPoints = line.points();
    const groupX = lineGroup.x();
    const groupY = lineGroup.y();

    const newPoints = [...oldPoints, x - groupX, y - groupY];

    line.points(newPoints);

    const anchors = attachAnchors(activeComponent, line, image, stage.current);

    anchors.map((anchor) => lineGroup.add(anchor));

    const totalLength = getMultiPointLineLength(newPoints);

    setLine(image, {
        ...activeLine,
        points: newPoints,
        isSet: true,
        totalLength,
    });

    setActiveTool("");

    setAction({
        id: "setLine",
        meta: {
            image,
            deleteShape: () => {
                deactivateComponent(activeLine.id);
                setAction({ id: null, meta: {} });
                removeLine(image, activeLine.id);
                setActiveComponent("");

                lineGroup.destroy();

                stage.current.draw();
            },
            deactivate: () => {
                removeAnchors(lineGroup);

                setActiveComponent("");
                setAction({ id: null, meta: {} });

                stage.current.draw();
            },
        },
    });
};

export const handleLineChange = ({ points = [], id = "", image = "", x = 0, y = 0, imageData, setLine }) => {
    const { lines = {} } = imageData[image] || {};

    const line = lines[id];

    const totalLength = getMultiPointLineLength(points);

    setLine(image, {
        ...line,
        x,
        y,
        points,
        totalLength,
    });
};

export const newMultiPoint = ({
    image,
    x,
    y,
    imageData,
    activeTool,
    isSnapMode,
    snapPoint,
    stage,
    setActiveComponent,
    setAction,
    layer,
    removeMultiPoint,
    setMultiPointLine,
    setActiveTool,
}) => {
    const { multiPointLines = {} } = ({} = imageData[image] || {});

    const fullId = `${activeTool}_${image}_${moment().format("YYYYMMDDHHmmssSS")}`;

    x = isSnapMode && snapPoint ? snapPoint.x : x;
    y = isSnapMode && snapPoint ? snapPoint.y : y;

    const shape = newShape({
        shape: activeTool,
        id: fullId,
        image,
        x,
        y,
        defaultPxLength: 100,
        stage: stage.current,
        selected: true,
    });

    setActiveComponent(fullId);

    if (layer.current) layer.current.add(shape);

    setMultiPointLine(image, {
        name: `Multi Point Line ${Object.keys(multiPointLines).length + 1}`,
        shape: "multiPointLine",
        id: fullId,
        x,
        y,
        points: [0, 0],
        takeoffSection: "",
        isSet: false,
        order: -1,
    });

    shape.moveToTop();

    setAction({
        id: "setMultiPointLine",
        meta: {
            image,
            cancel: () => {
                setActiveTool("");
                setAction({ id: null, meta: {} });
                setActiveComponent("");
                removeMultiPoint(image, fullId);

                shape.destroy();

                stage.current.draw();
            },
        },
    });
};

export const continueMultiPoint = ({
    image,
    x,
    y,
    imageData,
    isSnapMode,
    snapPoint,
    activeComponent,
    stage,
    layer,
    setAction,
    setActiveComponent,
    setMultiPointLine,
    setActiveTool,
    removeMultiPoint,
}) => {
    const { multiPointLines = {} } = imageData[image] || {};

    x = isSnapMode && snapPoint ? snapPoint.x : x;
    y = isSnapMode && snapPoint ? snapPoint.y : y;

    const activeMultiPointLine = multiPointLines[activeComponent];

    const muiltiPointGroup = layer.current.findOne((node) => node.getId() === `group_${activeComponent}`);
    const multiPointLine = muiltiPointGroup.findOne((node) => node.getId() === activeComponent);

    const oldPoints = multiPointLine.points();
    const groupX = muiltiPointGroup.x();
    const groupY = muiltiPointGroup.y();
    const newPoints = [...oldPoints, x - groupX, y - groupY];

    multiPointLine.points(newPoints);

    removeAnchors(muiltiPointGroup, stage.current);

    const anchors = attachAnchors(activeComponent, multiPointLine, image, stage.current);

    anchors.map((anchor) => muiltiPointGroup.add(anchor));

    setMultiPointLine(image, {
        ...activeMultiPointLine,
        points: newPoints,
    });

    setAction({
        id: "setMultiPointLine",
        meta: {
            image,
            cancel: () => {
                setActiveTool("");
                setAction({ id: null, meta: {} });

                removeMultiPoint(image, activeMultiPointLine.id);
                setActiveComponent("");

                muiltiPointGroup.destroy();

                stage.current.draw();
            },
            onSet: () => {
                const totalLength = getMultiPointLineLength(newPoints);

                setActiveTool("");

                setMultiPointLine(image, {
                    ...activeMultiPointLine,
                    points: newPoints,
                    isSet: true,
                    totalLength,
                });

                setAction({
                    id: "setMultiPointLine",
                    meta: {
                        image,
                        deleteShape: () => {
                            removeMultiPoint(image, activeMultiPointLine.id);
                            setActiveComponent("");
                            setAction({ id: null, meta: {} });

                            muiltiPointGroup.destroy();

                            stage.current.draw();
                        },
                        deactivate: () => {
                            removeAnchors(muiltiPointGroup);

                            setActiveComponent("");
                            setAction({ id: null, meta: {} });

                            stage.current.draw();
                        },
                    },
                });
            },
        },
    });
};

export const editMultiPoints = ({
    image,
    x,
    y,
    isAnchor,
    attrs,
    imageData,
    activeComponent,
    setMultiPointLine,
    stage,
    layer,
}) => {
    const { multiPointLines = {} } = imageData[image] || {};

    const activeMultiPointLine = multiPointLines[activeComponent];

    const muiltiPointGroup = layer.current.findOne((node) => node.getId() === `group_${activeComponent}`);
    const multiPointLine = muiltiPointGroup.findOne((node) => node.getId() === activeComponent);

    const oldPoints = multiPointLine.points();
    const groupX = muiltiPointGroup.x();
    const groupY = muiltiPointGroup.y();

    let newPoints = [...oldPoints];

    if (isAnchor) {
        const { x } = attrs;

        const index = newPoints.indexOf(x);

        if (index > -1 && newPoints.length / 2 > 2) {
            newPoints.splice(index, 2);
        }
    } else {
        const newX = x - groupX;
        const newY = y - groupY;

        let shortestDistance = Infinity;
        let shortestIndex = 0;

        const x1 = newPoints[0];
        const y1 = newPoints[1];
        const x2 = newPoints[newPoints.length - 2];
        const y2 = newPoints[newPoints.length - 1];

        const distanceToStart = Math.hypot(newX - x1, newY - y1);
        const distanceToEnd = Math.hypot(newX - x2, newY - y2);

        if (distanceToStart < shortestDistance) {
            shortestDistance = distanceToStart;
            shortestIndex = -2;
        }

        if (distanceToEnd < shortestDistance) {
            shortestDistance = distanceToEnd;
            shortestIndex = newPoints.length;
        }

        for (let i = 0; i < newPoints.length; i += 2) {
            const x1 = newPoints[i];
            const y1 = newPoints[i + 1];
            const x2 = newPoints[(i + 2) % newPoints.length];
            const y2 = newPoints[(i + 3) % newPoints.length];

            const dx = x2 - x1;
            const dy = y2 - y1;

            let t = ((newX - x1) * dx + (newY - y1) * dy) / (dx * dx + dy * dy);

            let closestX, closestY;

            if (t < 0) {
                closestX = x1;
                closestY = y1;
            } else if (t > 1) {
                closestX = x2;
                closestY = y2;
            } else {
                closestX = x1 + t * dx;
                closestY = y1 + t * dy;
            }

            const distance = Math.hypot(newX - closestX, newY - closestY);

            if (distance < shortestDistance) {
                shortestDistance = distance;
                shortestIndex = i;
            }
        }

        if (shortestIndex === -2) {
            newPoints.unshift(newX, newY);
        } else if (shortestIndex === newPoints.length) {
            newPoints.push(newX, newY);
        } else {
            newPoints.splice(shortestIndex + 2, 0, newX, newY);
        }
    }

    multiPointLine.points(newPoints);

    const totalLength = getMultiPointLineLength(newPoints);

    removeAnchors(muiltiPointGroup);

    const anchors = attachAnchors(activeComponent, multiPointLine, image, stage.current);

    anchors.map((anchor) => muiltiPointGroup.add(anchor));

    setMultiPointLine(image, {
        ...activeMultiPointLine,
        points: newPoints,
        totalLength,
    });
};

export const handleMultiPointLineChange = ({
    points = [],
    id = "",
    image = "",
    x = 0,
    y = 0,
    imageData,
    setMultiPointLine,
}) => {
    const { multiPointLines = {} } = imageData[image] || {};

    const multiPointLine = multiPointLines[id];

    const totalLength = getMultiPointLineLength(points);

    setMultiPointLine(image, {
        ...multiPointLine,
        x,
        y,
        points,
        totalLength,
    });
};

/* ----------------- Shapes END ----------------- */

/* ----------------- Stage START ----------------- */
export const initShapes = async ({ imageData, images, stage, layer, activeImageIndex, setRectangle }) => {
    const image = images[activeImageIndex].fileName;

    const { scale = {}, polygons = {}, lines = {}, rectangles = {}, multiPointLines = {} } = imageData[image] || {};

    // Place Scales
    if (scale.isSet && layer.current) {
        const { points, x, y, copiedFrom } = scale;

        const x1 = points[0];
        const y1 = points[1];
        const x2 = points[2];
        const y2 = points[3];

        const newX1 = x1 !== 0 ? 0 : x1;
        const newY1 = y1 !== 0 ? 0 : y1;
        const newX2 = x1 !== 0 ? x2 - x1 : x2;
        const newY2 = y1 !== 0 ? y2 - y1 : y2;

        const newLengthForCopied = Math.sqrt((newX2 - newX1) ** 2 + (newY2 - newY1) ** 2);

        const scaleShape = newShape({
            shape: "measure",
            id: !copiedFrom ? `measure_${image}` : copiedFrom,
            image,
            stage: stage.current,
            points: !copiedFrom ? points : [newX1, newY1, newLengthForCopied, 0],
            x: !copiedFrom ? x : 15,
            y: !copiedFrom ? y : 30,
            listening: !copiedFrom,
        });

        layer.current.add(scaleShape);
    }

    const shapesToAdd = [
        ...Object.values(polygons).map((poly) => ({ ...poly, type: "polygon" })),
        ...Object.values(rectangles).map((rect) => ({ ...rect, type: "rectangle" })),
        ...Object.values(multiPointLines).map((multiLine) => ({ ...multiLine, type: "multiPointLine" })),
        ...Object.values(lines).map((line) => ({ ...line, type: "line" })),
    ]
        .map((shape, index) => ({ ...shape, order: shape.order || shape.order === 0 ? shape.order : index }))
        .sort((a, b) => b.order - a.order);

    shapesToAdd.forEach((shapeToAdd) => {
        if (shapeToAdd.type === "polygon") {
            const { id, points, x, y, area } = shapeToAdd || {};
            const shape = newShape({
                shape: "builtPolygon",
                id,
                image,
                x,
                y,
                points,
                stage: stage.current,
            });

            layer.current.add(shape);
        }

        if (shapeToAdd.type === "rectangle") {
            const { id, x, y, width, height } = shapeToAdd || {};

            const shape = newShape({
                shape: "rectangle",
                id,
                image,
                x,
                y,
                width,
                height,
                stage: stage.current,
                shapeChangeHandler: ({ id, image, height, width, x, y, stage }) =>
                    handleRectangleChange({ id, image, height, width, x, y, stage, setRectangle }),
            });

            layer.current.add(shape);
        }

        if (shapeToAdd.type === "line") {
            const { id, points = [], x, y } = shapeToAdd || {};

            const shape = newShape({
                shape: "line",
                id,
                image,
                x,
                y,
                points,
                stage: stage.current,
            });

            layer.current.add(shape);
        }

        if (shapeToAdd.type === "multiPointLine") {
            const { id, points = [], x, y } = shapeToAdd || {};

            const shape = newShape({
                shape: "builtMultiPointLine",
                id,
                image,
                x,
                y,
                points,
                stage: stage.current,
            });

            layer.current.add(shape);
        }
    });

    stage.current.draw();
};

export const initImages = async ({
    images,
    activeImageIndex,
    currentPageIndex,
    setCurrentPageIndex,
    layer,
    stage,
    stageScale,
    imageData,
    setSnapLines,
    setMessage,
    toggleImageProcessing,
    setRectangle,
    cursorLayer,
    isFromSaving = false,
}) => {
    if (images.length === 0) {
        return;
    }

    toggleImageProcessing(true);

    if (isFromSaving) {
        layer.current.destroyChildren();
        stage.current.draw();

        setCurrentPageIndex(activeImageIndex);
    }

    if (activeImageIndex !== currentPageIndex) {
        layer.current.destroyChildren();
        stage.current.draw();

        setCurrentPageIndex(activeImageIndex);
    }

    const image = images[activeImageIndex];

    const { fileName, signedURL = "", linePoints } = image;
    const { scale = {} } = imageData[fileName] || {};
    const imageObj = new Image();

    if (linePoints) {
        setSnapLines(linePoints);
    }

    imageObj.onload = async () => {
        const imageShape = new Konva.Rect({
            x: 10,
            y: 25,
            width: imageObj.width,
            height: imageObj.height,
            name: "drawing",
            fillPatternImage: imageObj,
            id: fileName,
            image: fileName,
            page: activeImageIndex + 1,
            shadowBlur: 10,
            shadowOpacity: 0.1,
            stroke: "transparent",
        }).cache({ pixelRatio: 0.9 });

        const imageText = new Konva.Text({
            text: `Page ${activeImageIndex + 1}`,
            x: 10,
            y: stageScale >= 2.5 ? 18.75 : 7.5 * stageScale,
            fontFamily: "Noto Sans TC",
            name: "text",
            id: `text_${fileName}`,
            image: fileName,
            fontSize: stageScale >= 0.75 ? 12 / stageScale : 18,
            fill: "#646F81",
            fontStyle: "normal",
        });

        if (layer.current) {
            layer.current.add(imageShape);
            layer.current.add(imageText);
        }

        // if (isTurningOffInfo === false) {
        //     const response = await fetch(
        //         `https://firebasestorage.googleapis.com/v0/b/volta-snap-processing.appspot.com/o/${fileName}.png?alt=media&token=e2cf7d09-0950-48fa-8cc3-b5d19989f887`
        //     );

        //     const imageBlob = await response.blob();

        //     const formData = new FormData();
        //     // formData.append("file", imageBlob, "image.jpg");

        //     formData.append("image", imageBlob, "image.jpg");

        //     // http://127.0.0.1:5001/volta-snap-processing/northamerica-northeast1/flask_server
        //     // https://northamerica-northeast1-volta-snap-processing.cloudfunctions.net/process_image
        //     const res = await axios
        //         .post(
        //             "https://northamerica-northeast1-volta-snap-processing.cloudfunctions.net/process_image",
        //             formData,
        //             { headers: { "Content-Type": "multipart/form-data" } }
        //         )
        //         .catch((err) => {
        //
        //         });

        //     setSnapLines(res.data.line_points);
        // }

        if (!scale.isSet) {
            const overlay = new Konva.Rect({
                x: 10,
                y: 25,
                width: imageObj.width,
                height: imageObj.height,
                image: fileName,
                name: "overlay",
                page: activeImageIndex + 1,
                id: `overlay_${fileName}`,
                fill: "rgba(38, 47, 63, 0.8)",
            });

            if (layer.current) {
                await layer.current.add(overlay);
                setMessage({
                    message: "This page is missing its scale. Click on the image to start setting the scale.",
                    type: "warning",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                    },
                    autoHideDuration: null,
                    isOpen: true,
                    direction: "up",
                });
            }
        } else {
            if (!isFromSaving)
                setMessage({
                    message: "",
                    type: "",
                });
        }

        if (stage.current) {
            await stage.current.draw();
        }

        await initShapes({ images, imageData, stage, layer, activeImageIndex, setRectangle });

        toggleImageProcessing(false);
    };
    imageObj.src = signedURL; //fileURI;

    // if (cursorLayer.current) {
    //     cursorLayer.current.draw();
    // }
};

export const deactivateComponent = ({
    clickedId,
    stage,
    activeTool,
    setAction,
    setActiveComponent,
    activeComponent,
    imageData,
}) => {
    if (
        (!activeComponent && !clickedId) ||
        activeTool === "polygon" ||
        activeTool === "multiPointLine" ||
        activeTool === "measure" ||
        activeTool === "line"
    ) {
        return;
    }

    const currentStage = stage.current || stage;

    setAction({ id: null, meta: {} });
    setActiveComponent("");

    const shape = clickedId ? currentStage.findOne(`#${clickedId}`) : currentStage.findOne(`#${activeComponent}`);

    if (!shape) return;

    const shapeId = shape.getAttr("id");

    // Polygons and Lines
    if (
        shape.name() === "polygon" ||
        shape.name() === "line" ||
        shape.name() === "measure" ||
        shape.name() === "multiPointLine"
    ) {
        removeAnchors(shape.getParent(), currentStage);

        // if (shape.name() === "polygon" && imageData) {
        //     const { polygons = {} } = imageData || {};

        //     const polygonKeys = Object.keys(polygons);

        //     let largestPolygon = "";
        //     let largestPolygonArea = 0;

        //     for (let i = 0; i < polygonKeys.length; i++) {
        //         const { area, id } = polygons[polygonKeys[i]];

        //         //check all polygons area and the largest push back
        //         if (area > largestPolygonArea) {
        //             largestPolygon = id;
        //             largestPolygonArea = area;
        //         }
        //     }

        //     if (largestPolygon.length > 1) {
        //         const polygonShape = currentStage.findOne(`#${largestPolygon}`);

        //         polygonShape.getParent().zIndex(1);
        //     }
        // }

        shape.getParent().setAttr("draggable", false);
    }

    const transformer = currentStage.findOne(`#transformer_${shapeId}`);

    if (transformer) {
        transformer.destroy();
    }

    shape.setAttr("draggable", false);

    currentStage.draw();
};

export const activateComponent = ({
    shape,
    setAction,
    setActiveTool,
    setActiveComponent,
    layer,
    stage,
    removeLine,
    removeMultiPoint,
    removeRectangle,
    removePolygon,
    deactivateComponent,
    isFromComponents = false,
}) => {
    const clickedId = shape.getId();
    const clickedImage = shape.getAttr("image");
    const currentStage = stage.current || stage;

    setActiveTool("");
    setActiveComponent(clickedId);

    if (!shape) return;

    if (shape.name() === "measure") {
        setAction({
            id: "setScale",
            meta: {
                image: clickedImage,
                onSet: () => {
                    deactivateComponent(clickedId);
                    setAction({ id: null, meta: {} });
                    currentStage.draw();
                },
                deactivate: () => deactivateComponent(clickedId),
            },
        });
    }

    // Polygons and Lines
    if (
        shape.name() === "polygon" ||
        shape.name() === "line" ||
        shape.name() === "measure" ||
        shape.name() === "multiPointLine"
    ) {
        // attach anchors
        const anchors = attachAnchors(clickedId, shape, clickedImage, currentStage);
        anchors.map((anchor) => shape.getParent().add(anchor));

        // handle shape
        // shape.getParent().moveToTop();
        shape.getParent().setAttr("draggable", true);
    }

    if (shape.name() === "rectangle") {
        const transformer = newTransformer(clickedId);

        transformer.nodes([shape]);
        layer.current.add(transformer);
        shape.setAttr("draggable", true);
        // shape.moveToTop();

        if (currentStage.container()) {
            currentStage.container().style.cursor = "grab";
        }

        // transformer.moveToTop();

        setAction({
            id: "setRectangle",
            meta: {
                image: clickedImage,
                deleteShape: () => {
                    removeRectangle(clickedImage, clickedId);
                    setActiveComponent("");
                    setAction({ id: null, meta: {} });
                    removeAnchors(shape.getParent(), currentStage);

                    shape.destroy();
                    transformer.destroy();
                    currentStage.draw();
                },
                deactivate: () => (isFromComponents ? deactivateComponent() : deactivateComponent(clickedId)),
            },
        });
    }

    if (shape.name() === "polygon") {
        setAction({
            id: "setPolygon",
            meta: {
                image: clickedImage,
                deleteShape: () => {
                    setAction({ id: null, meta: {} });
                    setActiveComponent("");
                    removePolygon(clickedImage, clickedId);
                    removeAnchors(shape.getParent(), currentStage);

                    shape.destroy();

                    currentStage.draw();
                },
                deactivate: () => (isFromComponents ? deactivateComponent() : deactivateComponent(clickedId)),
            },
        });
    }

    if (shape.name() === "line") {
        setAction({
            id: "setLine",
            meta: {
                image: clickedImage,
                deleteShape: () => {
                    setAction({ id: null, meta: {} });
                    removeLine(clickedImage, clickedId);
                    setActiveComponent("");
                    removeAnchors(shape.getParent(), currentStage);

                    shape.destroy();

                    currentStage.draw();
                },
                deactivate: () => (isFromComponents ? deactivateComponent() : deactivateComponent(clickedId)),
            },
        });
    }

    if (shape.name() === "multiPointLine") {
        setAction({
            id: "setMultiPointLine",
            meta: {
                image: clickedImage,
                deleteShape: () => {
                    removeMultiPoint(clickedImage, clickedId);
                    setActiveComponent("");
                    setAction({ id: null, meta: {} });
                    removeAnchors(shape.getParent(), currentStage);

                    shape.destroy();

                    currentStage.draw();
                },
                deactivate: () => (isFromComponents ? deactivateComponent() : deactivateComponent(clickedId)),
            },
        });
    }

    currentStage.draw();
};
