import React from "react";
import { useDispatch } from "react-redux";

import ChbaCheckbox from "components/Input/ChbaCheckbox";

import { updateChecklistItems } from "features/Model/ProjectPathChecklist/_ducks/actions";

import ParagraphInput from "components/paragraphInput";
import CostInput from "components/ChbaChecklist/CostInput";

const ChbaChecklistItemInput = ({
    component,
    name,
    formTitle = "",
    isDisabled = false,
    input = {},
    onChange = () => {},
    howMuchFilesLeft = 0,
}) => {
    const { value: stateValue } = input;

    const dispatch = useDispatch();

    const handleChange = (val) => {
        const value = val.target.type === "checkbox" ? !stateValue : val.target.value;
        dispatch(updateChecklistItems({ path: name, value }));
        onChange(value);
    };

    const handleCostChange = (value) => {
        dispatch(updateChecklistItems({ path: name, value }));
        // onChange(value);
    };

    return component === "paragraphInput" ? (
        // <input value={value} placeholder="text goes here..." onChange={handleChange} />
        <ParagraphInput value={stateValue} onChange={handleChange} formTitle={formTitle} />
    ) : component === "checkbox" ? (
        <ChbaCheckbox
            value={stateValue}
            onChange={handleChange}
            isDisabled={isDisabled}
            howMuchFilesLeft={howMuchFilesLeft}
        />
    ) : component === "costInput" ? (
        <CostInput value={stateValue} onChange={handleCostChange} isDisabled={isDisabled} />
    ) : (
        <span></span>
    );
};

export default ChbaChecklistItemInput;
