import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { DndContext, useSensor, MouseSensor } from "@dnd-kit/core";
import { SortableContext, arrayMove } from "@dnd-kit/sortable";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";

import { Collapse } from "@material-ui/core";

import {
    selectActiveComponent,
    selectActiveTool,
    selectCurrentAction,
    selectProcessingImages,
} from "../../_ducks/selector";
import {
    setActiveComponent,
    setAction,
    removePolygon,
    removeRectangle,
    removeMultiPointLine,
    removeLine,
    setActiveTool,
    setLine,
    setPolygon,
    setRectangle,
    setMultiPointLine,
} from "../../_ducks/actions";

import { activateComponent, deactivateComponent } from "../../drawingHelpers";

import Component from "./Component";

import { page, pageContainer, arrow, active, label, open, componentList, header, folder } from "./style.module.scss";

import Arrow from "assets/images/icons/JSX/arrow-down";

const ListComponents = ({
    setActivePage,
    activePageIndex,
    pageIndex,
    currentImageData,
    activeComponent,
    currentImage,
    setActiveComponent,
    setAction,
    currentAction,
    layer,
    activeTool,
    removePolygon,
    removeRectangle,
    removeMultiPoint,
    removeLine,
    setActiveTool,
    imageUrl,
    processingImages,
    setPolygon,
    setRectangle,
    setLine,
    setMultiPointLine,
}) => {
    const [isOpen, toggleIsOpen] = useState(activePageIndex === pageIndex);
    const [allShapes, setAllShapes] = useState([]);
    const [hasMounted, setHasMounted] = useState(false);

    const mouseSensor = useSensor(MouseSensor, {
        // Require the mouse to move by 10 pixels before activating
        activationConstraint: {
            distance: 10,
        },
    });

    const editShape = {
        polygon: (shape) => setPolygon(currentImage, shape),
        rectangle: (shape) => setRectangle(currentImage, shape),
        multiPointLine: (shape) => setMultiPointLine(currentImage, shape),
        line: (shape) => setLine(currentImage, shape),
    };

    const {
        scale = { isSet: false, copiedFrom: "" },
        polygons = {},
        rectangles = {},
        multiPointLines = {},
        lines = {},
    } = currentImageData || {};

    useEffect(() => {
        if (activePageIndex === pageIndex) {
            toggleIsOpen(true);
        } else {
            toggleIsOpen(false);
        }
    }, [activePageIndex]);

    const onActivateClick = (shapeId) => {
        if (shapeId.includes("measure") && scale.copiedFrom) {
            return;
        }

        let stage;

        if (layer.current) stage = layer.current.getParent();

        const { meta = {} } = currentAction || {};

        if (meta.cancel) meta.cancel();

        const shape = stage.findOne(`#${shapeId}`);

        deactivateComponent({
            activeComponent,
            setAction,
            setActiveComponent,
            stage,
            activeTool,
            imageData: currentImageData,
        });

        activateComponent({
            setAction,
            setActiveComponent,
            setActiveTool,
            shape,
            stage,
            layer,
            removeLine,
            removeMultiPoint,
            removePolygon,
            removeRectangle,
            isFromComponents: true,
            activeComponent,
            deactivateComponent: deactivateComponent({
                activeComponent,
                setAction,
                setActiveComponent,
                stage,
                activeTool,
                imageData: currentImageData,
            }),
        });
    };

    useEffect(() => {
        setHasMounted(true);

        if (processingImages || !hasMounted) return;

        const toCheck = [
            // { ...scale, type: "scale" },
            ...Object.values(polygons).map((poly) => ({ ...poly, type: "polygon" })),
            ...Object.values(rectangles).map((rect) => ({ ...rect, type: "rectangle" })),
            ...Object.values(multiPointLines).map((multiLine) => ({ ...multiLine, type: "multiPointLine" })),
            ...Object.values(lines).map((line) => ({ ...line, type: "line" })),
        ]
            // .map((shape, index) => ({ ...shape, order: shape.order ? shape.order : index }))
            .sort((a, b) => b.order - a.order);

        // For testing
        // if (toCheck.length === allShapes.length) return;

        setAllShapes(toCheck);
    }, [currentImageData, processingImages]);

    const handleDragEnd = (event) => {
        const { active, over = {} } = event;

        if (active.id !== over?.id) {
            let stage;

            if (layer.current) stage = layer.current.getParent();

            const oldIndex = allShapes.findIndex((shape) => shape.id === active.id);
            const newIndex = allShapes.findIndex((shape) => shape.id === over?.id);

            const newShapes = arrayMove(allShapes, oldIndex, newIndex).map((shape, index) => ({
                ...shape,
                order: index,
            }));

            newShapes.forEach((shape, index) => {
                editShape[shape.type]({ ...shape, order: index });
            });

            newShapes.forEach((shape, index) => {
                const shapeInstance = shape.id.includes("rectangle")
                    ? stage.findOne(`#${shape.id}`)
                    : stage.findOne(`#${shape.id}`).getParent();

                if (shapeInstance) {
                    const newIndex = newShapes.reverse()[index].order + 2;

                    shapeInstance.zIndex(newIndex);

                    stage.draw();
                }
            });

            setAllShapes(newShapes);
        }
    };

    return (
        <ul className={page}>
            <li className={pageContainer}>
                <div
                    className={`${header} ${pageIndex === activePageIndex && active}`}
                    onClick={() => {
                        toggleIsOpen(!isOpen);

                        if (activePageIndex !== pageIndex) setActivePage(pageIndex);

                        const { meta = {} } = currentAction || {};

                        if (meta.cancel) meta.cancel();

                        setAction({ id: "", meta: {} });
                        setActiveComponent("");
                        setActiveTool("");
                    }}
                >
                    <div className={`${arrow} ${isOpen && open}`}>
                        <Arrow />
                    </div>
                    <span className={folder}>
                        {/* <Folder /> */}
                        <img width={80} src={`${imageUrl}`} alt={`thumbnail for page ${pageIndex + 1}`} />
                    </span>
                    <span className={label}>Page {pageIndex + 1}</span>
                </div>
                <Collapse in={isOpen} timeout="auto" unmountOnExit>
                    <ul className={componentList}>
                        {scale && scale.isSet && (
                            <Component
                                currentImage={currentImage}
                                activeComponent={activeComponent}
                                name={"Scale"}
                                icon={"scale"}
                                onClick={() => onActivateClick(scale.id)}
                            />
                        )}
                        <DndContext
                            modifiers={[restrictToVerticalAxis]}
                            onDragEnd={handleDragEnd}
                            sensors={[mouseSensor]}
                        >
                            <SortableContext items={allShapes}>
                                {allShapes
                                    .sort((a, b) => a.order - b.order)
                                    .map((shape) => (
                                        <Component
                                            id={shape.id}
                                            key={shape.id}
                                            currentImage={currentImage}
                                            activeComponent={activeComponent}
                                            componentId={shape.id}
                                            name={shape.name}
                                            icon={shape.type}
                                            onClick={() => onActivateClick(shape.id)}
                                        />
                                    ))}
                            </SortableContext>
                        </DndContext>
                    </ul>
                </Collapse>
            </li>
        </ul>
    );
};

const mapStateToProps = createStructuredSelector({
    activeComponent: selectActiveComponent,
    activeTool: selectActiveTool,
    currentAction: selectCurrentAction,
    processingImages: selectProcessingImages,
});

const mapDispatchToProps = (dispatch) => ({
    setActiveComponent: (component) => dispatch(setActiveComponent(component)),
    setAction: ({ id, meta }) => dispatch(setAction({ id, meta })),
    removePolygon: (image, polygonId) => dispatch(removePolygon(image, polygonId)),
    removeRectangle: (image, rectangleId) => dispatch(removeRectangle(image, rectangleId)),
    removeMultiPoint: (image, multiPointId) => dispatch(removeMultiPointLine(image, multiPointId)),
    removeLine: (image, lineId) => dispatch(removeLine(image, lineId)),
    setActiveTool: (tool) => dispatch(setActiveTool(tool)),
    setPolygon: (image, polygon) => dispatch(setPolygon(image, polygon)),
    setRectangle: (image, rectangle) => dispatch(setRectangle(image, rectangle)),
    setLine: (image, line) => dispatch(setLine(image, line)),
    setMultiPointLine: (image, multiPointLine) => dispatch(setMultiPointLine(image, multiPointLine)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListComponents);
