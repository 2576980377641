import types from "./types";

export const getChbanzChecklistStart = () => ({
    type: types.GET_CHBANZ_CHECKLIST_START,
});

export const getChbanzChecklistSuccess = ({ items = {} }) => ({
    type: types.GET_CHBANZ_CHECKLIST_SUCCESS,
    payload: items,
});

export const getChbanzChecklistError = (error) => ({
    type: types.GET_CHBANZ_CHECKLIST_ERROR,
    payload: error,
});

export const updateChecklistItems = (payload) => ({
    type: types.UPDATE_CHECKLIST_ITEMS,
    payload,
});

export const saveChecklistSuccess = (payload) => ({
    type: types.SAVE_CHECKLIST_SUCCESS,
    payload,
});
