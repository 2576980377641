import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import Temperatures from "./";

const mapStateToProps = ({ form }, { accessor }) => {
    const selector = formValueSelector("model");
    const isBasementHeated = selector({ form }, `modelData.temperatures.basement.heated`);
    const dayHeatSetPointUnits = selector({ form }, `${accessor}.mainFloors.daytimeHeatingSetPoint_u`);
    const coolingSetPointUnits = selector({ form }, `${accessor}.mainFloors.coolingSetPoint_u`);
    const nightHeatSetPointUnits = selector({ form }, `${accessor}.mainFloors.nighttimeHeatingSetPoint_u`);
    const nightSetbackUnits = selector({ form }, `${accessor}.mainFloors.nighttimeSetbackDuration_u`);
    const designHeatingUnits = selector({ form }, `${accessor}.equipment.heatingSetPoint_u`);
    const designCoolingUnits = selector({ form }, `${accessor}.equipment.coolingSetPoint_u`);
    const basementHeatingUnits = selector({ form }, `${accessor}.basement.heatingSetPoint_u`);
    const crawlspaceHeatingUnits = selector({ form }, `${accessor}.crawlspace.heatingSetPoint_u`);

    const overrideHeatSetPointUnits = selector({ form }, `${accessor}.f280Setpoints.setPointOverride.heating_u`);
    const overrideCoolSetPointUnits = selector({ form }, `${accessor}.f280Setpoints.setPointOverride.cooling_u`);

    const { id: buildingTypeId } = selector({ form }, `modelData.specifications.buildingType`) || {};

    const modelUnits = selector({ form }, "modelData.uiSettings.primaryUnits") || {};

    const isCrawlspacePresent = Object.keys(selector({ form }, `modelData.components`) || {}).includes("crawlspace");
    const isMURB = buildingTypeId !== 0;

    return {
        accessor,
        isCrawlspacePresent,
        isMURB,
        isBasementHeated,
        dayHeatSetPointUnits,
        coolingSetPointUnits,
        nightHeatSetPointUnits,
        nightSetbackUnits,
        designHeatingUnits,
        designCoolingUnits,
        basementHeatingUnits,
        crawlspaceHeatingUnits,
        overrideHeatSetPointUnits,
        overrideCoolSetPointUnits,
        modelUnits,
    };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Temperatures);
