import { connect } from "react-redux";
import { reduxForm, formValueSelector } from "redux-form";
import PackageEditDrawer from "./";
import { actions } from "../_ducks";

import { selectUpgrade } from "store/upgradeLibrary/actions";

const { updateUpgradePackage } = actions;

const mapStateToProps = ({ form, upgrades: { packages = {} } = {} }, { selectedPackage }) => {
    const modelSelector = formValueSelector("model");
    const { major: h2kMajor, minor: h2kMinor } = modelSelector({ form }, "modelData.h2kVersion") || {};

    const packageData = packages[selectedPackage] || {};
    const { upgrades = {} } = packageData;

    const windowDirections = [
        ...new Set(
            Object.values(upgrades).reduce((cache, curr) => {
                if (curr.upgradeType === "window") {
                    return [
                        ...cache,
                        ...curr.selectedComponents.reduce((cache2, windowAccessor) => {
                            return [
                                ...cache2,
                                modelSelector({ form }, `modelData.components.${windowAccessor}.facingDirection.id`),
                            ];
                        }, []),
                    ];
                }

                return cache;
            }, [])
        ),
    ];

    return {
        selectedPackage,
        initialValues: packageData,
        upgrades,
        h2kMinor,
        windowDirections,
    };
};

const mapDispatchToProps = (dispatch) => ({
    selectUpgrade: (upgradeId) => dispatch(selectUpgrade(upgradeId)),
});

const onSubmit = async (form, dispatch, { toggleOpen, reset, selectedPackage }) => {
    const { upgradeSettings = {}, name = "" } = form;

    await dispatch(
        updateUpgradePackage({
            packageId: selectedPackage,
            packageUpdates: { upgradeSettings, name },
        })
    );

    toggleOpen({});
    selectUpgrade(null);
    reset();
};

const mergeProps = (state, dispatch, own) => ({
    ...state,
    ...dispatch,
    ...own,
});

const form = reduxForm({
    form: "packageEditDrawer",
    enableReinitialize: true,
    onSubmit: onSubmit,
})(PackageEditDrawer);

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(form);
