import React from "react";

export default () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.00033 0.864258L7.69141 1.10695L0.69043 6.60695L1.3082 7.39331L2.50029 6.4568V7.69597C2.75506 7.27334 3.09662 6.90893 3.50029 6.62743V5.67119L8.00025 2.13601L12.5003 5.67225V13.5001H9.88837C9.96137 13.8217 9.99994 14.1564 9.99996 14.5001H13.0003H13.5003V14.0001V6.45807L14.6904 7.39327L15.3083 6.60699L8.30923 1.10699L8.00033 0.864258Z"
            fill="#0049c6"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 9.5C4 8.67157 4.67157 8 5.5 8C6.32843 8 7 8.67157 7 9.5C7 10.3284 6.32843 11 5.5 11C4.67157 11 4 10.3284 4 9.5ZM7.12543 11.3995C7.66077 10.941 8 10.2601 8 9.5C8 8.11929 6.88071 7 5.5 7C4.11929 7 3 8.11929 3 9.5C3 10.2601 3.33924 10.941 3.87457 11.3995C2.7601 11.985 2 13.1537 2 14.5H3C3 13.1193 4.11929 12 5.5 12C6.88071 12 8 13.1193 8 14.5H9C9 13.1537 8.2399 11.985 7.12543 11.3995Z"
            fill="#0049c6"
        />
    </svg>
);
