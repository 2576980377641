export const registeredKeys = ["Shift", "+"];

export const validAngles = [
    0,
    15,
    30,
    45,
    60,
    75,
    90,
    105,
    120,
    135,
    150,
    165,
    180,
    -0,
    -15,
    -30,
    -45,
    -60,
    -75,
    -90,
    -105,
    -120,
    -135,
    -150,
    -165,
    -180,
];
