import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import DcfExport from "./";

const mapStateToProps = ({ form, user: { email: userEmail = "" } = {} }, { accessor }) => {
    const selector = formValueSelector("model");
    const h2kInfo = selector({ form }, `modelData.information.h2kInfo`) || {};

    const modelUnits = selector({ form }, "modelData.uiSettings.primaryUnits") || {};

    const {
        general = {},
        components = {},
        ventilation = {},
        heatingCooling = {},
        specifications = {},
        location = {},
        airTightness = {},
        dimensions = {},
        generation = {},
        temperatures = {},
        domesticHotWater = {},
        codes = {},
        program = {},
    } = selector({ form }, "modelData") || {};

    return {
        accessor,
        h2kInfo,
        userEmail,
        modelUnits,
        general,
        components,
        ventilation,
        heatingCooling,
        specifications,
        location,
        airTightness,
        dimensions,
        generation,
        temperatures,
        domesticHotWater,
        program,
        codes,
    };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DcfExport);
