import { connect } from "react-redux";

import Actions from "./";

const mapStateToProps = ({
    model: {
        modelData: { uiSettings: { primaryUnits } = {} } = {},
        modelTakeoff = {},
    },
    drawing: { action: { id: actionId, meta = {} } = {} },
}) => ({
    units: primaryUnits === "Metric" ? "m" : "ft",
    actionId,
    meta,
    modelTakeoff,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Actions);
