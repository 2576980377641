import React, { useState } from "react";
import classes from "./style.module.scss";
import Dialog from "components/Dialog";
import Button from "components/Button";
import Input from "components/Input";
import InputRow from "components/Input/Row";
import { Field } from "redux-form";
import { required, maxLength } from "utils/fieldValidation";
import ModalFullScreen from "components/ModalFullScreen";
import { Fade } from "@material-ui/core";
import Logo from "assets/images/logo-color.svg";
import { CSSTransition, SwitchTransition } from "react-transition-group";

const charLim32 = maxLength(32);
const charLim200 = maxLength(200);
const charLim500 = maxLength(200);

const Transition = React.forwardRef((props, ref) => <Fade ref={ref} {...props} />);

const IntroForm = ({ invalid, changeStep }) => (
    <div className={classes.form}>
        <div className={classes.heading}>
            <h2>Hello! Welcome to Volta SNAP</h2>
            <p>Please start by telling us a little bit about yourself</p>
        </div>
        <InputRow gridTemplate="1fr 1fr">
            <Field
                component={Input}
                name="firstName"
                type="text"
                label="First Name*"
                validate={[required, charLim200]}
            />
            <Field component={Input} name="lastName" type="text" label="Last Name*" validate={[required, charLim200]} />
        </InputRow>
        <InputRow>
            <Field
                component={Input}
                name="company"
                type="text"
                label="Company Name*"
                placeholder="Example: Sudden Valley Energy Consultants"
                validate={[required, charLim500]}
            />
        </InputRow>
        <InputRow>
            <Field
                component={Input}
                name="role"
                type="text"
                label="Role in Company*"
                placeholder="Example: Energy Advisor"
                validate={[required, charLim200]}
            />
        </InputRow>
        <InputRow>
            <Field
                component={Input}
                name="experienceLevel"
                type="text"
                label="Experience level in energy modelling (in years)"
                placeholder="Example: 5 years"
                validate={[charLim32]}
            />
        </InputRow>
        <div className={classes.buttons}>
            <Button disabled={invalid} onClick={() => changeStep(2)}>
                Next
            </Button>
        </div>
    </div>
);

const TestingGoals = ({ changeStep }) => (
    <div className={classes.goals}>
        <h2>User Testing Goals</h2>
        <p>
            The ongoing development of Volta SNAP will be informed by you, the users. We strive to provide you with a
            service that evolves with the needs of its users and the industry it serves. With this user testing
            exercise, we have some goals and questions we would like you to think about while using Volta SNAP:
        </p>
        <ul>
            <li>
                What key features are missing that are needed to fully integrate Volta SNAP with your business
                activities?
            </li>
            <li>What bugs, critical or minor, have you encountered while using Volta SNAP?</li>
            <li>
                How much time do you think Volta SNAP could save you in gathering building input data? What other
                features of the app provide you and your team with value?
            </li>
            <li>
                What features, including those that may be outside of the scope of HOT2000's capabilities, would provide
                additional value?
            </li>
            <li>
                Are there any changes you would make to any of the fields? This could include changing field default
                values, making fields more accessible, hiding unimportant fields, and more.
            </li>
        </ul>
        <p>
            The Volta Research team will follow up with you periodically throughout this user testing period to gather
            feedback and implement changes. This user testing exercise will involve many iterations, and we aim to
            continuously deploy improvements to Volta SNAP over the coming months.
        </p>
        <div className={classes.buttons}>
            <Button onClick={() => changeStep(3)}>Next</Button>
        </div>
    </div>
);

const Agreement = ({ invalid, handleSubmit }) => (
    <div className={classes.agreement}>
        <h2>User Testing Agreement</h2>
        <p>
            By participating in this Volta SNAP user testing exercise, you agree to our{" "}
            <a
                href="https://drive.google.com/file/d/1cxMLeiix0V5aJe80PTMAyeJeF-eKrUKY/view?usp=sharing"
                target="_blank"
                rel="noopener noreferrer"
            >
                terms and conditions
            </a>{" "}
            and{" "}
            <a
                href="https://drive.google.com/file/d/1uCB8yeByjenPiu-GdSJN1d5mmLCTIvWp/view?usp=sharing"
                target="_blank"
                rel="noopener noreferrer"
            >
                privacy policy
            </a>
            . Some key points are summarized below:
        </p>
        <ul>
            <li>You agree not to share your login credentials with anyone.</li>
            <li>
                You agree not to reproduce, duplicate, or distribute any portions of Volta SNAP, including the sharing
                of screenshots, without the express permission of a Volta Research staff member.
            </li>
            <li>
                You acknowledge and agree to the risks of sharing data through Volta SNAP. This is not to say that our
                database is public, or is entirely unencrypted, but by using Volta SNAP in its beta form you acknowledge
                the risk that there may be flaws in its security protocols. To summarize how we protect your data, we
                utilize the server-side encryption and transport layer security built into Firebase, our database, cloud
                computing, and authentication provider (https://cloud.google.com/firestore/docs/server-side-encryption).
                To accommodate user testing, we have also implemented checks to ensure that only pre-authorized beta
                testers have access to the Volta SNAP database, and have prevented the unauthorized creation of new
                users. It should also be noted that all Volta SNAP databases and cloud computing resources are hosted
                entirely in Canada.
            </li>
            <li>
                You acknowledge that Volta SNAP may collect cookies and other user information. This information may be
                shared with third parties for the purposes of analyzing user behaviour to help better understand how
                users interact with Volta SNAP. Currently, we use Google Analytics and Mixpanel to analyze usage data.
                We may also be required to share user data to comply with applicable laws and regulations.
            </li>
        </ul>
        <p>
            By clicking "I agree", you agree to all of the above terms, including those listed in our terms and
            conditions and privacy policy.
        </p>
        <div className={classes.buttons}>
            <Button onClick={handleSubmit}>I Agree</Button>
        </div>
    </div>
);

const Info = ({ isOpen = false, invalid, handleSubmit }) => {
    const [step, changeStep] = useState(1);

    return (
        <ModalFullScreen
            open={isOpen}
            classes={{
                paperClass: classes.paper,
            }}
            TransitionComponent={Transition}
        >
            <div className={classes.testingWrapper}>
                <img className={classes.logo} src={Logo} alt="Volta SNAP" />
                <SwitchTransition mode="out-in">
                    <CSSTransition
                        key={step}
                        classNames={{
                            enter: classes.contentEnter,
                            enterActive: classes.contentEnterActive,
                            exit: classes.contentExit,
                            exitActive: classes.contentExitActive,
                        }}
                        timeout={300}
                    >
                        <div className={classes.userTesting}>
                            {step === 1 && <IntroForm invalid={invalid} changeStep={changeStep} />}
                            {step === 2 && <TestingGoals changeStep={changeStep} />}
                            {step === 3 && <Agreement handleSubmit={handleSubmit} />}
                        </div>
                    </CSSTransition>
                </SwitchTransition>
            </div>
        </ModalFullScreen>
    );
};

export default Info;
