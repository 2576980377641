import moment from "moment";

import { fieldValue, firestore, USERS_COLL } from "_firebase";

import {
    createFolderStart,
    createFolderSuccess,
    createFolderError,
    deleteFolderStart,
    deleteFolderSuccess,
    deleteFolderError,
    renameFolderStart,
    renameFolderSuccess,
    renameFolderError,
    moveFolderStart,
    moveFolderSuccess,
    moveFolderError,
    batchDeleteFolderStart,
    batchDeleteFolderSuccess,
    batchDeleteFolderError,
} from "./actions";

import { actions } from "store/users";

const { fetchUserDir } = actions;

const getUserDir = (uid) => firestore.doc(`${USERS_COLL}/${uid}`).collection("modelDir").doc("directory").get();

export const createFolder =
    (uid, newFolderName, parentFolderId = null) =>
    (dispatch) => {
        dispatch(createFolderStart());

        // Generates firebase unique IDs
        // Source - https://www.reddit.com/r/Firebase/comments/lneeaz/need_to_create_unique_id_within_cloud_function/go0fhy1/?context=8&depth=9
        const folderId = firestore.doc(`${USERS_COLL}/${uid}`).collection("modelDir").doc().id;

        return firestore
            .doc(`${USERS_COLL}/${uid}`)
            .collection("modelDir")
            .doc("directory")
            .update({
                [`folders.${folderId}`]: {
                    name: newFolderName,
                    lastEdited: moment().format(),
                    parentFolderId,
                },
            })
            .then(async () => await dispatch(fetchUserDir(uid)))
            .then(() => dispatch(createFolderSuccess()))
            .catch((err) => dispatch(createFolderError(err)));
    };

export const deleteFolder = (folderId, uid) => async (dispatch) => {
    dispatch(deleteFolderStart());

    const userDir = (await getUserDir(uid)).data();

    const folders = userDir.folders;
    const models = userDir.models;

    const foldersIds =
        Object.entries(folders)
            .filter(([key, { parentFolderId }]) => parentFolderId === folderId)
            .map(([key]) => key) || [];
    const modelsIds =
        Object.entries(models)
            .filter(([key, { parentFolderId }]) => parentFolderId === folderId)
            .map(([key]) => key) || [];

    if (foldersIds.length > 0) {
        for await (const id of foldersIds) {
            firestore
                .doc(`${USERS_COLL}/${uid}`)
                .collection("modelDir")
                .doc("directory")
                .update({
                    [`folders.${id}.parentFolderId`]: null,
                });
        }
    }

    if (modelsIds.length > 0) {
        for await (const id of modelsIds) {
            firestore
                .doc(`${USERS_COLL}/${uid}`)
                .collection("modelDir")
                .doc("directory")
                .update({
                    [`models.${id}.parentFolderId`]: null,
                });
        }
    }

    return firestore
        .doc(`${USERS_COLL}/${uid}`)
        .collection("modelDir")
        .doc("directory")
        .update({
            [`folders.${folderId}`]: fieldValue.delete(),
        })
        .then(() => dispatch(fetchUserDir(uid)))
        .then(() => dispatch(deleteFolderSuccess()))
        .catch((err) => dispatch(deleteFolderError(err)));
};

export const renameFolder = (folderId, uid, newName) => (dispatch) => {
    dispatch(renameFolderStart());

    return firestore
        .doc(`${USERS_COLL}/${uid}`)
        .collection("modelDir")
        .doc("directory")
        .update({
            [`folders.${folderId}.name`]: newName,
        })
        .then(() => dispatch(fetchUserDir(uid)))
        .then(() => dispatch(renameFolderSuccess()))
        .catch((err) => dispatch(renameFolderError(err)));
};

export const moveFolder =
    (folderId, uid, moveToId, skipFetch = false) =>
    (dispatch) => {
        dispatch(moveFolderStart());

        return firestore
            .doc(`${USERS_COLL}/${uid}`)
            .collection("modelDir")
            .doc("directory")
            .update({
                [`folders.${folderId}.parentFolderId`]: moveToId,
            })
            .then(() => (!skipFetch ? dispatch(fetchUserDir(uid)) : {}))
            .then(() => dispatch(moveFolderSuccess()))
            .catch((err) => dispatch(moveFolderError(err)));
    };

export const batchFolderRemove = (foldersToDelete, uid) => async (dispatch) => {
    try {
        const userDirSnapshot = await firestore.doc(`${USERS_COLL}/${uid}/modelDir/directory`).get();
        const userDir = userDirSnapshot.data();
        const folders = userDir.folders || {};
        const models = userDir.models || {};

        // Split folderIds into chunks of 10 or fewer IDs
        const chunks = [];
        for (let i = 0; i < foldersToDelete.length; i += 10) {
            chunks.push(foldersToDelete.slice(i, i + 10));
        }

        // Iterate over each chunk and perform batch delete operations
        for (const chunk of chunks) {
            // Create a batch
            const batch = firestore.batch();

            // Iterate over each folder ID in the chunk and add batch delete operations
            chunk.forEach((folderId) => {
                const folderRef = firestore.doc(`${USERS_COLL}/${uid}/modelDir/directory`);
                batch.update(folderRef, {
                    [`folders.${folderId}`]: fieldValue.delete(),
                });

                // Find child folders of the current folder
                const childFolders = Object.entries(folders)
                    .filter(([key, { parentFolderId }]) => parentFolderId === folderId)
                    .map(([key]) => key);

                // Add batch operations to remove parentFolderId from child folders
                childFolders.forEach((childId) => {
                    batch.update(folderRef, {
                        [`folders.${childId}.parentFolderId`]: null,
                    });
                });

                const modelsInFolder = Object.entries(models)
                    .filter(([key, { parentFolderId }]) => parentFolderId === folderId)
                    .map(([key]) => key);

                // Add batch operations to remove parentFolderId from models
                modelsInFolder.forEach((modelId) => {
                    batch.update(folderRef, {
                        [`models.${modelId}.parentFolderId`]: null,
                    });
                });
            });

            // Commit the batch
            await batch.commit();
        }

        dispatch(batchDeleteFolderSuccess());
    } catch (error) {
        console.error("Error while deleting multiple folders:", error);
        dispatch(batchDeleteFolderError(error.message));
    }
};
