import React, { useState, useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import uniqid from "uniqid";
import moment from "moment";
import { isEmpty } from "lodash";

import classes from "./style.module.scss";

import Button from "components/Button";
import Select from "components/Input/Select";
import TabHeader from "components/TabHeader";
import AddIcon from "assets/images/icons/JSX/Add";
import DownloadIcon from "assets/images/icons/JSX/Dowload";
import Dialog, { CloseDialog } from "components/Dialog";
import Input from "components/Input";
import PackageEditDrawer from "./PackageEditDrawer/container";
import ExportPDFDrawer from "./ExportPDFDrawer";
import DetailsDrawer from "components/DetailsDrawer";
import Details from "./Details";
import UpgradesTable from "./UpgradesTable/UpgradesTable";
import UpgradeDrawer from "./UpgradeDrawer/container";
import PackageDeleteDialog from "./PackageDeleteDialog/PackageDeleteDialog";
import Checkbox from "components/Input/Checkbox";
import { selectModelId, selectModelSimulationData } from "features/Model/_ducks/selectors";
import { upgradeOptionsComponents, upgradesBuilder } from "utils/upgrades";
import { validateH2kExport } from "utils/h2kinout/api";
import { selectUpgradePackages, selectUpgradePackagesIds, selectSavedUpgradePackages } from "./_ducks/selectors";
import { selectSaveUpgradePackageLabels } from "store/upgradeLibrary/selectors";
import { modelFormSelector } from "store/form/selectors";
import { ReactComponent as ResetIcon } from "assets/images/icons/Icon-Reset.svg";
import classNames from "classnames";
import { selectResultsLoading } from "../Review/Results/_ducks/selectors";
import ToggleVisibility from "components/ToggleVisibility/ToggleVisibility";
import { fetchUpgradeLabels, fetchUpgradePackage } from "store/upgradeLibrary/thunk";

import { actions as upgradeActions } from "./_ducks";
import { actions as modelActions } from "features/Model/_ducks";
import { actions as resultActions } from "features/Model/Review/Results/_ducks";
import { saveDrawingData } from "../DrawingNew/_ducks/thunk";
import { selectUsersOrganization } from "store/users/selectors";
// import { actions as userActions } from "store/users";
// const { fetchUserDir } = userActions;

const { updateUpgradePackage } = upgradeActions;

const { setExportErrors, updateModalDetails } = modelActions;
const { runSimulation, runAzureSimulation } = resultActions;

const PackageNameDialog = ({
    open,
    toggleOpen,
    selected,
    selectedName,
    toggleSelected,
    numberOfPackages = 0,
    tableRef,
    uid,
    upgradePackageIds,
    components,
    modelChange,
    dimensions,
}) => {
    const dispatch = useDispatch();

    const [packageName, updatePackageName] = useState("");
    const [selectedPackage, setSelectedPackage] = useState({});
    const [isLoadingPackage, setIsLoadingPackage] = useState(false);

    const selectedSaveUpgradePackageLabels = useSelector(selectSaveUpgradePackageLabels);
    const userOrganization = useSelector(selectUsersOrganization);

    const orgId = !isEmpty(userOrganization) ? Object.keys(userOrganization.memberOrgIds)[0] : null;

    const saveUpgradePackageLabels = selectedSaveUpgradePackageLabels
        ? [
              ...selectedSaveUpgradePackageLabels.upgradeLibMap,
              ...selectedSaveUpgradePackageLabels.orgUpgradeLibMap.map(({ id, name }) => ({
                  name,
                  id,
                  isFromOrg: true,
              })),
          ]
        : [];

    const handleSave = useCallback(async () => {
        let packageId = selected;
        let dateObj = {};
        let uiObj = {};

        // New package
        if (!selected) {
            packageId = uniqid("package-");
            dateObj = {
                created: moment().format("LLLL"),
                lastEdited: moment().format("LLLL"),
            };
            uiObj = { ui: { displayOrder: numberOfPackages } };
        }

        await dispatch(
            updateUpgradePackage({
                packageId,
                packageUpdates: {
                    name: packageName,
                    ...dateObj,
                    ...uiObj,
                },
            })
        );

        tableRef.current.scrollLeft = tableRef.current.scrollWidth;

        toggleOpen(false);
        toggleSelected("");
        updatePackageName("");
        setSelectedPackage({});
    }, [selected, packageName, toggleOpen, toggleSelected, dispatch, numberOfPackages, tableRef]);

    const handleLoad = async () => {
        setIsLoadingPackage(true);

        const packageToAdd = await dispatch(
            fetchUpgradePackage(uid, selectedPackage.id, true, selectedPackage.isFromOrg ? orgId : null)
        );

        const isPackageExists = upgradePackageIds.includes(selectedPackage.id);

        let packageId = selectedPackage.id;

        if (isPackageExists && packageToAdd.upgrades) {
            Object.keys(packageToAdd.upgrades).map((key) => ({
                [uniqid("upgrade-")]: packageToAdd.upgrades[key],
            }));

            packageId = uniqid("package-");
        }

        const updatedPackage = upgradesBuilder(packageToAdd, packageId, components, modelChange, { dimensions });

        await dispatch(
            updateUpgradePackage({
                packageId,
                packageUpdates: {
                    ...updatedPackage,
                    name: isPackageExists ? `${packageToAdd.name} (copy)` : packageToAdd.name,
                    ui: { displayOrder: numberOfPackages },
                },
            })
        );

        tableRef.current.scrollLeft = tableRef.current.scrollWidth;

        toggleOpen(false);
        toggleSelected("");
        updatePackageName("");
        setSelectedPackage({});
        setIsLoadingPackage(false);
    };

    const handleClose = useCallback(() => {
        toggleOpen(false);
        toggleSelected("");
        updatePackageName("");
        setSelectedPackage({});
    }, [toggleOpen, toggleSelected]);

    useEffect(() => {
        updatePackageName(selectedName);
    }, [selectedName]);

    useEffect(() => {
        dispatch(fetchUpgradeLabels(uid, orgId));
    }, []);

    return (
        <Dialog
            open={open}
            classes={{
                rootClass: "",
                paperClass: classes.dialogPaper,
            }}
        >
            <CloseDialog closeActions={handleClose} />
            <div className={classes.packageDialogContent}>
                <h2>Add Upgrade Package</h2>
                <div className={classes.inputButtonWrapper}>
                    <Input
                        type="text"
                        name={"packageName"}
                        label={`Create New Package`}
                        placeholder="New upgrade package name"
                        //validate={validate}
                        hideLabel
                        input={{
                            value: packageName,
                            onChange: (val) => updatePackageName(val),
                        }}
                    />
                    <span data-tip="Name field is required" data-for="dialogTip">
                        <Button
                            onClick={handleSave}
                            disabled={!packageName}
                            style={{ height: "36px", width: "6.0625rem" }}
                        >
                            {selected ? "Update" : "Create"}
                        </Button>
                    </span>
                </div>
                <div className={classes.divider}></div>
                <div className={classes.inputButtonWrapper}>
                    <Select
                        type="text"
                        name={"packageName"}
                        label={`Load Saved Package`}
                        placeholder="Select saved upgrade package"
                        //validate={validate}
                        disabled={!saveUpgradePackageLabels}
                        hideLabel
                        search
                        options={
                            saveUpgradePackageLabels
                                ? saveUpgradePackageLabels.map(({ id, name, isFromOrg }) => ({
                                      label: name,
                                      value: { name, id, isFromOrg: !!isFromOrg },
                                  }))
                                : []
                        }
                        input={{
                            value: selectedPackage,
                            onChange: (val) => setSelectedPackage(val),
                        }}
                    />
                    <span data-tip="Selected package is required" data-for="dialogTip">
                        <Button
                            onClick={handleLoad}
                            disabled={isEmpty(selectedPackage) || isLoadingPackage}
                            style={{ height: "36px", width: "6.0625rem" }}
                        >
                            {isLoadingPackage ? "Applying..." : "Apply"}
                        </Button>
                    </span>
                </div>
                {/* <div className={classes.footer}>
                    <Button type="hollow" onClick={handleClose}>
                        Cancel
                    </Button>
                    <span
                        data-tip="Name field is required"
                        data-for="dialogTip"
                    >
                        <Button onClick={handleSave} disabled={!packageName}>
                            {selected ? "Update Package" : "Create Package"}
                        </Button>
                    </span>
                </div> */}
            </div>
        </Dialog>
    );
};

export default ({ change: modelChange, modelSave, modelDir, uid, codeLib, readOnly, disableSimulation }) => {
    const dispatch = useDispatch();

    const tableRef = useRef();

    const [dialogOpen, toggleDialog] = useState(false);
    const [openDetails, toggleOpenDetails] = useState(false);
    const [selectedPackage, toggleSelectedPackage] = useState("");
    const [upgradeOpen, toggleUpgrade] = useState({});
    const [packageEditOpen, togglePackageEdit] = useState({});
    const [toDelete, toggleToDelete] = useState(false);
    const [exportPFDDrawerOpen, toggelExportPFDDrawerOpen] = useState(false);

    const upgradePackageIds = useSelector(selectUpgradePackagesIds);
    const savedUpgradePackages = useSelector(selectSavedUpgradePackages);
    const upgradePackages = useSelector(selectUpgradePackages);
    const { simulationRunning } = useSelector(selectModelSimulationData);
    const loading = useSelector(selectResultsLoading);
    const modelId = useSelector(selectModelId);

    // Form selectors
    const { major: h2kMajor, minor: h2kMinor } = useSelector(modelFormSelector)("modelData.h2kVersion");
    const components = useSelector(modelFormSelector)("modelData.components");
    const dimensions = useSelector(modelFormSelector)("modelData.dimensions");
    const { upgrades: { hideRows = false, showCost = false } = {} } =
        useSelector(modelFormSelector)("modelData.uiSettings");
    const h2kVersion = `v${h2kMajor}-${h2kMinor}`;

    const simulateDisabled =
        ["v11-9", "v11-10"].includes(h2kVersion) || simulationRunning || loading || disableSimulation;

    const handleSimulate = async () => {
        if (simulationRunning || simulateDisabled) {
            return;
        }

        //handled in an action and cannot yet be disabled at the source, like handleSubmit
        // if (!readOnly) {
        //     dispatch(saveDrawingData({ modelId, modelComponents: components }));
        // }

        await modelSave();

        dispatch(updateModalDetails({ simulationRunning: true }));
        const errorList = await validateH2kExport({ modelId, h2kVersion });

        // Not sure what to do with this ATM
        // if (exportErrors.length === 0 && errorList.length > 0) {
        //     setExportErrors(errorList, "simulation", () => actionSimulation());
        //     updateModalDetails({ simulationRunning:false });
        //     return;
        // }

        // Otherwise, continue
        dispatch(setExportErrors([]));
        // dispatch(runSimulation({ packages: upgradePackageIds }));
        dispatch(runAzureSimulation({ packages: upgradePackageIds }));
    };

    const toggleRowHide = () => modelChange("modelData.uiSettings.upgrades.hideRows", !hideRows);
    const toggleShowCost = () => modelChange("modelData.uiSettings.upgrades.showCost", !showCost);

    const duplicateUpgrade = useCallback(
        async (packageId, name) => {
            const numberOfPackages = upgradePackageIds.length;

            const dupPackageId = uniqid("package-");
            const dateObj = {
                created: moment().format("LLLL"),
                lastEdited: moment().format("LLLL"),
            };
            const uiObj = { ui: { displayOrder: numberOfPackages } };
            const initUpgrades = upgradePackages?.[packageId]?.upgrades || {};
            const upgrades = Object.keys(initUpgrades).reduce((acc, curr) => {
                const newKey = uniqid("upgrade-");
                return {
                    ...acc,
                    [newKey]: initUpgrades[curr] || {},
                };
            }, {});

            await dispatch(
                updateUpgradePackage({
                    packageId: dupPackageId,
                    packageUpdates: {
                        name: `${name} (copy)`,
                        ...dateObj,
                        ...uiObj,
                        upgrades,
                    },
                })
            );

            //Update modelData
            Object.keys(upgrades).forEach((upgradeId) => {
                const { upgradeType: upgradeH2kType, selectedComponents = [] } = upgrades[upgradeId];
                const isEnvelopeComponent = upgradeOptionsComponents.find(({ value = "" }) => value === upgradeH2kType);
                const componentReference = {
                    upgradeId,
                };

                if (isEnvelopeComponent) {
                    selectedComponents.map((path) => {
                        const componentModelPath = `modelData.components.${path}.upgradePackages.${dupPackageId}`;
                        modelChange(componentModelPath, componentReference);
                    });
                } else {
                    // System component
                    const systemPath = `modelData.${upgradeH2kType}.upgradePackages.${dupPackageId}`;
                    modelChange(systemPath, componentReference);
                }
            });

            tableRef.current.scrollLeft = tableRef.current.scrollWidth;

            toggleSelectedPackage("");
        },
        [selectedPackage, upgradePackageIds, upgradePackages]
    );

    return (
        <>
            <TabHeader
                className={classes.header}
                title="Upgrades"
                description="Define upgrade packages to investigate multiple improvement pathways. General mode simulations are recommended for rapid testing and iteration."
                additionalContent={
                    <div className={classes.additionalContent}>
                        <DetailsDrawer
                            open={openDetails}
                            close={() => toggleOpenDetails(false)}
                            title="Upgrades Tab Details"
                        >
                            <Details />
                            <div className={classes.button}>
                                <Button large type="hollow" onClick={() => toggleOpenDetails(false)}>
                                    Close
                                </Button>
                            </div>
                            <span></span>
                        </DetailsDrawer>
                        <ToggleVisibility
                            className={classes.rowVisibilityToggle}
                            onClick={toggleRowHide}
                            isHide={hideRows}
                            label={hideRows ? "Show Rows" : "Hide Rows"}
                        />
                        <Checkbox
                            label="Show Costs"
                            input={{
                                onChange: toggleShowCost,
                                value: showCost,
                            }}
                            className={classes.showCostsCheckbox}
                        />
                        <Button
                            onClick={handleSimulate}
                            type="emptyBg"
                            smallPadding
                            disabled={simulateDisabled}
                            icon={ResetIcon}
                            className={classNames(classes.simluateButton, {
                                [classes.simulateButtonRunning]: simulationRunning,
                            })}
                        >
                            {simulationRunning ? "Simulation Running" : "Run Simulation"}
                        </Button>
                        <Button
                            icon={DownloadIcon}
                            className={classes.exportPdfButton}
                            disabled={simulateDisabled}
                            type={"lightGrey"}
                            onClick={() => toggelExportPFDDrawerOpen(!exportPFDDrawerOpen)}
                        >
                            Export PDF
                        </Button>
                        <Button
                            icon={AddIcon}
                            className={classes.addButton}
                            disabled={simulateDisabled}
                            onClick={() => {
                                toggleDialog(true);
                                toggleSelectedPackage("");
                            }}
                        >
                            Add New Package
                        </Button>
                    </div>
                }
            />
            <UpgradeDrawer
                showCost={showCost}
                toggleShowCost={toggleShowCost}
                toggleOpen={toggleUpgrade}
                modelChange={modelChange}
                {...upgradeOpen}
            />
            <UpgradesTable
                ref={tableRef}
                modelChange={modelChange}
                toggleUpgradeDrawer={toggleUpgrade}
                togglePackageEdit={togglePackageEdit}
                duplicateUpgrade={duplicateUpgrade}
                toggleToDelete={toggleToDelete}
                showCost={showCost}
                handleSimulate={handleSimulate}
                uid={uid}
                codeLib={codeLib}
                modelDir={modelDir}
                simulateDisabled={simulateDisabled}
            />
            <PackageEditDrawer toggleOpen={togglePackageEdit} modelChange={modelChange} {...packageEditOpen} />
            <PackageNameDialog
                open={dialogOpen}
                toggleOpen={toggleDialog}
                selected={selectedPackage}
                selectedName={upgradePackages[selectedPackage]?.name}
                toggleSelected={toggleSelectedPackage}
                numberOfPackages={upgradePackageIds.length}
                tableRef={tableRef}
                savedUpgradePackages={savedUpgradePackages}
                upgradePackages={upgradePackages}
                upgradePackageIds={upgradePackageIds}
                uid={uid}
                components={components}
                dimensions={dimensions}
                modelChange={modelChange}
            />
            <PackageDeleteDialog toDelete={toDelete} toggleToDelete={toggleToDelete} />
            <ExportPDFDrawer
                isOpen={exportPFDDrawerOpen}
                close={() => toggelExportPFDDrawerOpen(!exportPFDDrawerOpen)}
            />
        </>
    );
};
