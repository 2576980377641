import { connect } from "react-redux";
import { formValueSelector, reduxForm } from "redux-form";
import Model from "./";
import moment from "moment";
import { isEmpty } from "lodash";
import isEqual from "lodash/isEqual";

import { actions as modelActions } from "features/Model/_ducks";
import { actions as enclosureActions } from "features/Model/Enclosure/_ducks";
import { actions as upgradesActions } from "features/Model/Upgrades/_ducks";

import { types } from "features/Model/_ducks";

import { setSaveTimeout } from "../_ducks/actions";

import { updateObject } from "utils";
import { mergeObjects } from "utils/objects";
import cleanComponents from "utils/cleanComponents";
import { saveChbaNzChecklist } from "../ProjectPathChecklist/_ducks/thunk";
import buildReviewTable from "utils/review/buildReviewTable";

const {
    updateHouseModel,
    addMissingCodeWarning,
    clearMissingCodeWarning,
    appendToChbaAdminCommunity,
    checkModelUpdates,
} = modelActions;
const { clearEnclosureState } = enclosureActions;
const { saveUpgrades } = upgradesActions;

const mapStateToProps = (
    {
        user: {
            uid,
            name: username = "",
            codeLib = {},
            modelDir = {},
            nonErsUser = false,
            userPermissions: { VOLTA_DEV = false } = {},
        } = {},
        form,
        model: {
            modelId,
            modelDetails = {},
            modelTakeoff,
            dataEntryMode,
            modelData = {},
            missingCodes = {},
            error,
            saveTimeout,
            modelDetailsToCompare,
            linkedBaseline = {},
            lastSnapshot = {},
            modelDataToCompare,
            modelTakeoffToCompare,
        } = {},
    },
    { history }
) => {
    const { name, createdAt = "", lastEdited = {} } = modelDetailsToCompare; //This is here because of name and lastEdited
    const { owner = {}, writePermission = [], modelErrorSubmitted = false } = modelDetails;

    const modelSelector = formValueSelector("model");
    const latestFormModelData = modelSelector({ form }, "modelData") || {}; //this is needed because sometimes the form is reinitialized just before saving
    const latestFormModelDetails = modelSelector({ form }, "modelDetails") || {}; //this is needed because sometimes the form is reinitialized just before saving

    const { name: ownerName = "", uid: ownerId = uid } = owner;
    const { name: editedName, datetime: editedDateTime } = lastEdited;

    const { program: { class: programClass = "" } = {} } = latestFormModelData;

    const disableSimulation = nonErsUser && programClass !== "";

    return {
        modelId,
        history,
        codeLib,
        uid,
        username,
        modelData,
        latestFormModelData,
        latestFormModelDetails,
        modelDetails,
        modelDir,
        initialValues: {
            modelData,
            modelDetails,
        },
        error,
        name,
        editedDateTime,
        editedName,
        createdAt,
        ownerId,
        readOnly: ownerId !== uid && !writePermission.includes(uid),
        ownerName,
        modelTakeoff,
        dataEntryMode,
        saveTimeout,
        missingCodes,
        clearMissingCodeWarning,
        disableSimulation,
        linkedBaseline,
        VOLTA_DEV,
        modelErrorSubmitted,
        modelDetailsToCompare,
        lastSnapshot,
        modelDataToCompare,
        modelTakeoffToCompare,
    };
};

const mapDispatchToProps = (dispatch) => ({
    setSaveTimeout: (timeoutFunc) => dispatch(setSaveTimeout(timeoutFunc)),
});

const filterCodes = ({ stashedCodes = {}, modelCodes = {}, codeLib = {} }) =>
    Object.values(stashedCodes)
        .filter((value) => !modelCodes[value])
        .reduce((prev, current) => {
            const { componentType, codeType, ...rest } = codeLib[current] || {};

            return {
                ...prev,
                [current]: {
                    codeRef: current,
                    ...rest,
                },
            };
        }, {});

//I created this because sometimes codes were making it through that were missing labels.
//I think I've prevented this at the source but this is just in case
export const cleanCodes = (codesObj) =>
    Object.keys(codesObj)
        .filter((key) => codesObj[key].label != null)
        .reduce((prev, current) => ({ ...prev, [current]: codesObj[current] }), {});

const onSubmit = async (
    { stashedCodes = {}, ...form },
    dispatch,
    {
        modelId,
        codeLib,
        uid,
        ownerId,
        username,
        modelTakeoff,
        dataEntryMode,
        latestFormModelData,
        latestFormModelDetails,
        readOnly,
        linkedBaseline,
        lastSnapshot,
        modelDetailsToCompare,
        modelDataToCompare,
        modelTakeoffToCompare,
    }
) => {
    // ***** SAVING MODEL HERE ****** //
    if (readOnly) {
        // console.log("not owner, skipped save");
        return;
    }

    // console.log('model from ModelTabs onSubmit',form)
    // console.log('linkedBaseline from ModelTabs',linkedBaseline)

    const {
        modelDetails: modelDetailsFromSnapshot,
        modelData: modelDataFromSnapshot,
        modelTakeoff: modelTakeoffFromSnapshot,
    } = lastSnapshot;

    dispatch({ type: types.UPDATE_MODEL_SNAPSHOT_START });

    const lastEdited = {
        name: username,
        uid: uid,
        datetime: moment().format(),
    };
    let modelData = latestFormModelData;
    let modelDetails = latestFormModelDetails;

    if (!isEqual({ ...modelDetailsFromSnapshot, lastEdited: {} }, { ...modelDetailsToCompare, lastEdited: {} })) {
        modelDetails = mergeObjects(form.modelDetails, modelDetailsToCompare, modelDetailsFromSnapshot);
    }

    //modelDataToCompare
    if (!isEqual(modelDataFromSnapshot, modelDataToCompare)) {
        modelData = mergeObjects(form.modelData, modelDataToCompare, modelDataFromSnapshot);
    }

    if (!isEqual(modelTakeoffToCompare, modelTakeoffFromSnapshot)) {
        modelTakeoff = mergeObjects(modelTakeoff, modelTakeoffToCompare, modelTakeoffFromSnapshot);
    }

    const { codes = {}, components = {} } = modelData;

    //Filter and map stashed codes to get library-only codes
    //Iterating over component identifiers
    const libCodes = Object.keys(stashedCodes)
        .map((component) =>
            filterCodes({
                stashedCodes: stashedCodes[component],
                modelCodes: codes,
                codeLib: codeLib[component].codes,
            })
        )
        .reduce(
            (prev, current) => ({
                ...prev,
                ...current,
            }),
            {}
        );

    // Run cleanup on components
    // const cleanedComps = cleanComponents(components);
    const { cleanedComps, codesInModel = {} } = cleanComponents(components, "codeCheck");

    const windowToWallValue = modelData.dimensions.windowToWall.value;
    const { highLevel: { windowToWallRatio = 0 } = {} } = buildReviewTable(components);
    const newWindowToWallTrimmed = parseFloat((100 * windowToWallRatio).toFixed(3));

    //     //Only update if a change has happened
    if (newWindowToWallTrimmed !== windowToWallValue && !Number.isNaN(newWindowToWallTrimmed)) {
        modelData.dimensions.windowToWall.value = newWindowToWallTrimmed;
    }

    //Add library codes and cleaned components to model
    const updatedCodes = updateObject(cleanCodes(codes), libCodes);
    const formData = updateObject(form, {
        modelData: updateObject(modelData, {
            codes: updatedCodes,
            components: cleanedComps,
        }),
        modelDetails: updateObject(modelDetails, {
            lastEdited: lastEdited,
        }),
        modelTakeoff,
        dataEntryMode,
        linkedBaseline,
    });

    //Console logs for identifying missing code issue
    let missingCodes = {};
    Object.keys(codesInModel).forEach((codeRefInModel) => {
        if (!Object.keys(formData.modelData.codes).includes(codeRefInModel)) {
            console.log(
                "A code reference was found in the model that was not saved properly to the form: ",
                codeRefInModel
            );
            // console.log("Codes in form: ", formData.modelData.codes);

            missingCodes[codeRefInModel] = codesInModel[codeRefInModel];
        }

        if (!Object.keys(updatedCodes).includes(codeRefInModel)) {
            console.log(
                "A code reference was found in the model that was not present after the codes were cleaed: ",
                codeRefInModel
            );
            // console.log("Codes after clean: ", updatedCodes);
            // console.log("Codes before clean: ", codes);
            // console.log("libCodes before merge: ", libCodes);

            missingCodes[codeRefInModel] = codesInModel[codeRefInModel];
        }
    });

    if (!isEmpty(missingCodes)) {
        dispatch(addMissingCodeWarning(missingCodes));
    }

    //CHECK FOR CHBA TAG AND APPEND TO COMMUNITY IF REQUIRED
    const { tags = [] } = modelDetails;
    //Check if the file's information needs to be updated in the community
    if (tags.some((tag) => tag.includes("CHBA_NZ"))) {
        await dispatch(appendToChbaAdminCommunity(modelId, uid));
    }

    await dispatch(clearEnclosureState());
    await dispatch(saveUpgrades());
    await dispatch(saveChbaNzChecklist());
    return dispatch(updateHouseModel(formData, modelId, uid));
};

const form = reduxForm({
    form: "model",
    enableReinitialize: true,
    onSubmit: onSubmit,
})(Model);

export default connect(mapStateToProps, mapDispatchToProps)(form);
