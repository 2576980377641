import React, { useReducer } from "react";
import classes from "./style.module.scss";
import globalStyles from 'components/globalStyles.module.scss';
import classNames from "classnames";
import Drawer from "components/Drawer";
import { getFoundationConfigOpts, getSlabConfigOpts } from "utils/fields";
import { getInsulationThumbnail } from 'utils/enclosure/basement';

import Button from 'components/Button';
import Select from 'components/Input/Select';
import InputRow from 'components/Input/Row';
import { isEmpty } from "lodash";

const NewLineText = ({text}) => {
    return text.split('\n').map((str,ind) => <li key={ind}>{str.split('- ')[1]}</li>);
};

const filterLabels = {
    "basementInsConfig":{
        field1:"Construction",
        field2:"Wall Insulation",
        field3:"Slab Insulation Location"
    },
    "slabInsConfig":{
        field1:"Insulation",
        field2:"Skirt",
        field3:"Coverage"
    },
    "crawlspaceInsConfig":{
        field1:"Insulation",
        field2:"Skirt",
        field3:"Coverage"
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case "CHANGE_FIELD":
            return {
                ...state,
                ...action.fields
            }
        case "CLEAR_REDUCER":
            return {
                filter1:"",
                filter2:"",
                filter3:"",
                config:"",
            }
        default:
            return state;
    }
};

const ConfigDrawer = ({
    open,
    clearFoundationConfig,
    fieldAccessor,
    currentFormChange,
    fieldKey
}) => {
    const initialState = {
        filter1:"",
        filter2:"",
        filter3:"",
        config:"",
    }
    const [state, dispatch] = useReducer(reducer, initialState);

    const opts = fieldKey === "basementInsConfig" ?
        getFoundationConfigOpts({fieldKey: fieldKey, returnHierarchy:true })
            : getSlabConfigOpts({fieldKey: fieldKey, returnHierarchy:true });

    const constructionOpts = Object.keys(opts).map((opt) => ({
        value:opt,
        label:opt.split("_").join(" ")
    }));
    const insulationOpts = opts[state.filter1] ? Object.keys(opts[state.filter1]).map((opt) => ({
        value:opt,
        label:opt.split("_").join(" ")
    })) : [];
    const slabLocationOpts = opts[state.filter1] && opts[state.filter1][state.filter2] ?
        Object.keys(opts[state.filter1][state.filter2]).map((opt) => ({
            value:opt,
            label:opt.split("_").join(" ")
        })) : [];
    const configOptions = slabLocationOpts.length > 0 ? 
        opts[state.filter1][state.filter2][state.filter3] : {};

    const handleCancel = () => {
        clearFoundationConfig();
        dispatch({ type:"CLEAR_REDUCER" });
    };

    const handleSave = () => {
        if (fieldKey === "basementInsConfig") {
            currentFormChange(fieldAccessor, { type:state.config });
        } else {
            currentFormChange(fieldAccessor, { type:state.config });
        }
        clearFoundationConfig();
        dispatch({ type:"CLEAR_REDUCER" });
    }

    return (
        <Drawer
            open={open}
            title="Insulation Configuration"
            subtitle="Select the configuration with the most appropriate description."
            close={handleCancel}
            classes={{
                paperClass:classes.configDrawer
            }}
            //icon={Icon}
            //introColor={introColor}
        >
            <InputRow>
                <Select
                    name="field1"
                    label={filterLabels[fieldKey].field1}
                    input={{
                        value: state.filter1,
                        onChange: (value) => dispatch({
                            type:"CHANGE_FIELD",
                            fields: {
                                filter1:value,
                                filter2:"",
                                filter3:"",
                                config: "",
                            }
                        })
                    }}
                    options={constructionOpts}
                />
            </InputRow>
            <InputRow>
                <Select
                    name="field2"
                    label={filterLabels[fieldKey].field2}
                    input={{
                        value: state.filter2,
                        onChange: (value) => dispatch({
                            type:"CHANGE_FIELD",
                            fields: {
                                filter2:value,
                                filter3:"",
                                config: "",
                            }
                        })
                    }}
                    disabled={!state.filter1}
                    options={insulationOpts}
                />
            </InputRow>
            <InputRow>
                <Select
                    name="field3"
                    label={filterLabels[fieldKey].field3}
                    input={{
                        value: state.filter3,
                        onChange: (value) => dispatch({
                            type:"CHANGE_FIELD",
                            fields: {
                                filter3:value,
                                config: "",
                            }
                        })
                    }}
                    disabled={!state.filter1 || !state.filter2}
                    options={slabLocationOpts}
                />
            </InputRow>
            {configOptions && !isEmpty(configOptions) && (
                <>
                    <h3>Configuration Results</h3>
                    <div className={classes.foundationList}>
                        {Object.keys(configOptions).map(key => {
                            const { value, text } = configOptions[key];
                            // get thumbnail? or already in returend list?
                            
                            return (
                                <div
                                    className={classNames(
                                        classes.configItem,
                                        { [classes.selected]: value === state.config }
                                    )}
                                    onClick={() => {
                                        dispatch({
                                            type:"CHANGE_FIELD",
                                            fields: {
                                                config: value,
                                            }
                                        })
                                    }}
                                >
                                    {getInsulationThumbnail(value) && (
                                        <div className={classes.thumnailWrapper}>
                                            <img className={classes.thumbnail} src={getInsulationThumbnail(value)} alt="Insulation thumbnail" />
                                        </div>
                                    )}
                                    <div>
                                        <h4>{value}</h4>
                                        <ul className={classes.insulationConfigInfo}>
                                            <NewLineText text={text} />
                                        </ul>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className={`${globalStyles.buttons} ${classes.configButtons}`} style={{ paddingBottom: "3rem" }}>
                        <Button
                            large
                            type="hollow"
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                        <Button
                            large
                            onClick={handleSave}
                            disabled={!state.config}
                        >
                            Apply Selection
                        </Button>
                    </div>
                </>
            )}
        </Drawer>
    )
};

export default ConfigDrawer;