import { getNetTotalEnergyConsumption } from "utils/results";

const tierThresholds = (totalHouseVolume) =>
    ({
        above300m3: {
            overall: { 1: 0, 2: 10, 3: 20, 4: 40, 5: 70 },
            heatLoss: { 1: -1, 2: 5, 3: 10, 4: 20, 5: 40 },
        },
        belowEqual300m3: {
            overall: { 1: 0, 2: 0, 3: 10, 4: 30, 5: 60 },
            heatLoss: { 1: -1, 2: 0, 3: 5, 4: 15, 5: 25 },
        },
    }[totalHouseVolume > 300 ? "above300m3" : "belowEqual300m3"]);

const euiGrossHeatLossTargetTier = (budget) => [
    { target: 1.0 * budget, tier: 1 },
    { target: 0.95 * budget, tier: 2 },
    { target: 0.9 * budget, tier: 3 },
    { target: 0.8 * budget, tier: 4 },
    { target: 0.6 * budget, tier: 5 },
];

const euiEnergyConsumptionTargetTier = (budget) => [
    { target: 1.0 * budget, tier: 1 },
    { target: 0.9 * budget, tier: 2 },
    { target: 0.8 * budget, tier: 3 },
    { target: 0.6 * budget, tier: 4 },
    { target: 0.3 * budget, tier: 5 },
];

const determineEuiCompliance = (value, tiers) => {
    // Check the given value against the tier targets
    let complianceTier = 0;
    for (const tier of tiers) {
        if (value <= tier.target) {
            complianceTier = tier.tier;
        }
    }

    // If the number does not meet any tier lowest tier
    return complianceTier;
};

export const getNbc2020Tier = (propResults = {}, refResults = {}, totalHouseVolume = 500) => {
    const {
        Annual: { HeatLoss: { _attributes: { total: propGrossHeatLoss } = {} } = {} } = {},
        Other: { _attributes: { designCoolLossRate: propDesignCoolLossRate = 0 } = {} } = {},
    } = propResults;

    const {
        Annual: { HeatLoss: { _attributes: { total: refGrossHeatLoss } = {} } = {} } = {},
        Other: { _attributes: { designCoolLossRate: refDesignCoolLossRate = 0 } = {} } = {},
    } = refResults;

    const { aecNbc936: proposedAec = 0 } = getNetTotalEnergyConsumption(propResults);
    const { aecNbc936: refAec = 0 } = getNetTotalEnergyConsumption(refResults);

    const overallPercentDiff = (100 * (refAec - proposedAec)) / refAec;
    const heatLossPercentDiff = (100 * (refGrossHeatLoss - propGrossHeatLoss)) / refGrossHeatLoss;

    const { overall, heatLoss } = tierThresholds(totalHouseVolume);

    let proposedOverallTier = 0;
    if (overallPercentDiff >= 0) {
        Object.keys(overall).forEach((tier) => {
            if (overallPercentDiff >= overall[tier]) {
                proposedOverallTier = parseInt(tier);
            }
        });
    }

    let proposedHeatLossTier = 0;
    if (heatLossPercentDiff >= 0) {
        Object.keys(heatLoss).forEach((tier) => {
            if (heatLossPercentDiff >= heatLoss[tier]) {
                proposedHeatLossTier = parseInt(tier);
            }
        });
    }

    const coolingValidationPassed = refDesignCoolLossRate > propDesignCoolLossRate;

    return {
        proposedOverallTier,
        proposedHeatLossTier,
        calculatedTier: Math.min(proposedOverallTier, proposedHeatLossTier),
        coolingValidationPassed,
    };
};

export const getEuiComplianceTier = (modelData, propResults) => {
    //Calculate the EUI Compliance Tier in accordance with PCF 1869

    const {
        dimensions: {
            volume: { total: totalVolume = 0 } = {},
            belowGradeIntFloorArea: { total: bgFloorArea = 0 } = {},
            aboveGradeIntFloorArea: { total: agFloorArea = 0 } = {},
        } = {},
        specifications: { buildingSubType: { id: houseTypeId } = {} } = {},
        location: { weather: { location: { value: hddValue = 0 } = {} } = {} } = {},
    } = modelData;

    const {
        Annual: { HeatLoss: { _attributes: { total: proposedGrossHeatLoss } = {} } = {} } = {},
        f280Results: { latHeatGain, coolingOverrideSetPoint } = {},
    } = propResults;

    const { aecNbc936: proposedAec = 0 } = getNetTotalEnergyConsumption(propResults);

    const proposedGrossHeatLossKWh = proposedGrossHeatLoss * (1 / 0.0036);
    const proposedEnergyKWh = proposedAec * (1 / 0.0036);

    const totalHeatedFloorArea = bgFloorArea + agFloorArea;
    const coercedHeatedFloorArea = Math.max(115, Math.min(350, totalHeatedFloorArea));

    //Determine gross heat loss compliance
    const localHeatLossFactor = 0.02 * hddValue + 32.6;
    const grossSpaceHeatLossBudget = localHeatLossFactor * coercedHeatedFloorArea; //in kWh/y
    const grossHeatLossTierTargets = euiGrossHeatLossTargetTier(grossSpaceHeatLossBudget);

    const grossHeatLossTier = determineEuiCompliance(proposedGrossHeatLossKWh, grossHeatLossTierTargets);

    //Determine energy consumption compliance
    const localEnergyFactor = 0.02 * hddValue - 12.3;

    const energyUseBudget = localEnergyFactor * coercedHeatedFloorArea + 6500;
    const energyConsumptionTierTargets = euiEnergyConsumptionTargetTier(energyUseBudget);

    const energyTier = determineEuiCompliance(proposedEnergyKWh, energyConsumptionTierTargets);

    return Math.min(grossHeatLossTier, energyTier);
};
