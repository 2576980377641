export const newRowInputs = [
    {
        key: "title",
        type: "string",
        units: {
            options: [],
            selected: null,
            unitType: "string",
        },
    },
    {
        key: "width",
        type: "number",
        fieldKey: "takeoffWindowLength",
        units: {
            options: ["mm", "in", "m", "ft"],
            // selected: "m",
            unitType: "length",
        },
    },
    {
        key: "height",
        type: "number",
        fieldKey: "takeoffWindowLength",
        units: {
            options: ["mm", "in", "m", "ft"],
            // selected: "m",
            unitType: "length",
        },
    },
    {
        key: "ovWidth",
        type: "number",
        fieldKey: "takeoffLength",
        units: {
            options: ["m", "ft"],
            // selected: "m",
            unitType: "length",
        },
    },
    {
        key: "ovHeight",
        type: "number",
        fieldKey: "takeoffLength",
        units: {
            options: ["m", "ft"],
            // selected: "m",
            unitType: "length",
        },
    },
    {
        key: "qty",
        type: "number",
        units: {
            options: [],
            selected: "count",
            unitType: "count",
        },
    },
];
