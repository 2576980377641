import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import Door from './';
import { getBaseUnits, unitLabels, getOptions } from 'utils/fields';
import convertUnit from 'utils/convertUnit';

const mapStateToProps = (
    {
        form
    },
    {
        accessor
    }
) => {
    const selector = formValueSelector('model');
    const doorHeight = selector({form}, `${accessor}.measurements.height`) || {};
    const doorWidth = selector({form}, `${accessor}.measurements.width`) || {};
    const doorArea = selector({form}, `${accessor}.measurements.area`) || {};
    const {id:doorTypeId, value:doorRValue} = selector({form}, `${accessor}.doorType`) || {};

    const heightUnits = selector({form}, `${accessor}.measurements.height_u`);
    const widthUnits = selector({form}, `${accessor}.measurements.width_u`);
    const areaUnits = selector({form}, `${accessor}.measurements.area_u`);
    const rValUnits = selector({form}, `${accessor}.doorType_u`);
    const modelUnits = selector({form}, `modelData.uiSettings.primaryUnits`);

    const { trueBase:heightBase, displayBase:heightDisplay } = getBaseUnits('doorHeight', modelUnits);
    const { trueBase:widthBase, displayBase:widthDisplay } = getBaseUnits('doorWidth', modelUnits);
    const { trueBase:areaBase, displayBase:areaDisplay } = getBaseUnits('wallArea', modelUnits);
    const { trueBase:rValBase, displayBase:rValDisplay } = getBaseUnits('doorType', modelUnits);

    const heightU = heightUnits || heightDisplay || heightBase;
    const widthU = widthUnits || widthDisplay || widthBase;
    const areaU = areaUnits || areaDisplay || areaBase;
    const rValU = rValUnits || rValDisplay || rValBase;


    const doorLabel = [
        ...getOptions({fieldKey: 'doorType'}),
        {
            label:"User Specified",
            value:{
                id:7,
                value:doorRValue,
            }
        },
    ].reduce((acc, curr) => {
        if (curr.value.id === doorTypeId) {
            return curr.label
        } else {
            return acc
        }
    }, '')
    
    return {
        doorLabel,
        rValue:`${parseFloat(convertUnit({
            value:doorRValue,
            type:'thermalResistance',
            inputUnit:rValBase,
            outputUnit:rValU,
        })).toFixed(1)}${rValU}`,
        height:`${parseFloat(convertUnit({
            value:doorHeight,
            type:'length',
            inputUnit:heightBase,
            outputUnit:heightU,
        })).toFixed(1)}${heightU}`,
        width:`${parseFloat(convertUnit({
            value:doorWidth,
            type:'length',
            inputUnit:widthBase,
            outputUnit:widthU,
        })).toFixed(1)}${widthU}`,
        area:`${parseFloat(convertUnit({
            value:doorArea,
            type:'area',
            inputUnit:areaBase,
            outputUnit:areaU,
        })).toFixed(1)}${unitLabels(areaU)}`,
    }
};

const mapDispatchToProps = () => ({});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Door);