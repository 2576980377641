import types from "./types";

import { REQUIRED_TAGS } from "features/Model/ImageGallery/utils/constants";

const initialState = {
    isImageGalleryOpen: false,
    allImages: [],
    uploadingImages: [],
    isUploading: false,
    loading: false,
    error: null,
    tags: [...REQUIRED_TAGS],
    imageGallerySnapshot: [],
    imageGalleryToCompare: [],
};

const imageGalleryReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.TOGGLE_IMAGE_GALLERY:
            return {
                ...state,
                isImageGalleryOpen: !state.isImageGalleryOpen,
            };
        case types.SET_UPLOADING_IMAGES:
            return {
                ...state,
                uploadingImages: action.payload,
            };
        case types.UPDATE_ALL_IMAGES:
            return {
                ...state,
                allImages: action.payload,
            };
        case types.SET_TAGS:
            return {
                ...state,
                tags: action.payload,
            };
        case types.ADD_NEW_TAG:
            return {
                ...state,
                tags: [...state.tags, action.payload],
            };
        case types.GET_IMAGE_GALLERY_START:
            return {
                ...state,
                loading: true,
            };
        case types.GET_IMAGE_GALLERY_SUCCESS:
            return {
                ...state,
                loading: false,
                allImages: action.payload,
                error: null,
                imageGalleryToCompare: action.payload,
            };
        case types.GET_IMAGE_GALLERY_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case types.ADD_IMAGE_DATA_SUCCESS:
            return {
                ...state,
                allImages: [...state.allImages, action.payload],
            };
        case types.ADD_IMAGE_DATA_ERROR:
            return {
                ...state,
                allImages: [...state.allImages],
                error: action.payload,
            };
        case types.SAVE_IMAGE_DATA_START:
        case types.DELETE_IMAGE_DATA_START:
            return { ...state, error: null };
        case types.DELETE_IMAGE_DATA_SUCCESS:
            return {
                ...state,
                allImages: action.payload,
            };
        case types.SAVE_IMAGE_DATA_ERROR:
        case types.DELETE_IMAGE_DATA_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        case types.SAVE_IMAGE_DATA_SUCCESS:
            return {
                ...state,
            };
        case types.RESET_IMAGE_GALLERY:
            return {
                ...initialState,
            };
        case types.UPDATE_IMAGE_GALLERY_SNAPSHOT:
            return {
                ...state,
                imageGallerySnapshot: action.payload,
            };
        default:
            return state;
    }
};

export default imageGalleryReducer;
