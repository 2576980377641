import React from "react";

import Button from "components/Button";
import DropdownMenu from "components/DropdownMenu";
import DropdownItem from "components/DropdownMenu/DropdownItem";

import useComponentVisible from "utils/hooks";

import { addTakeoffBtn } from "./style.module.scss";

import blueAdd from "assets/images/icons/blueAdd.svg";

const AddButton = ({ optionsList, onSelect }) => {
    const { ref, isComponentVisible, setIsComponentVisible } =
        useComponentVisible(false);

    return (
        <div className={addTakeoffBtn} ref={ref}>
            <Button type="hollow" onClick={() => setIsComponentVisible(true)}>
                Add Takeoff Component <img src={blueAdd} alt={blueAdd} />
            </Button>
            {optionsList && (
                <DropdownMenu isOpen={isComponentVisible} top={42}>
                    {optionsList.map((option, index) => (
                        <DropdownItem
                            key={index}
                            onClick={() => {
                                onSelect(option.type);
                                setIsComponentVisible(false);
                            }}
                            text={option.name}
                        />
                    ))}
                </DropdownMenu>
            )}
        </div>
    );
};

export default AddButton;
