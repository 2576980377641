import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import isEmpty from 'lodash/isEmpty';
import WaterGroundHeatPump from './';

const mapStateToProps = (
    {
        form,
        model:{
            contentOpen=false,
        }={}
    },
    {
        accessor,
        formName="model"
    }
) => {
    const selector = formValueSelector(formName);
    const modelSelector = formValueSelector('model');

    const modelUnits = modelSelector({form}, `modelData.uiSettings.primaryUnits`) || {};
    const { major: h2kMajor, minor: h2kMinor} = modelSelector({form}, 'modelData.h2kVersion') || {};

    const currentPrimarySystem = Object.keys(selector({form}, `${accessor}.system`) || {})[0];

    const { id: functionId } = selector({form}, `${accessor}.system.${currentPrimarySystem}.function`) || {};
    const { id: equipTypeId } = selector({form}, `${accessor}.system.${currentPrimarySystem}.equipmentType`) || {};
    const { id: capacityId, value: capacityValue } = selector({form}, `${accessor}.system.${currentPrimarySystem}.specifications.outputCapacity`) || {};
    const { id: tempCutoffId, value: tempCutoffValue } = selector({form}, `${accessor}.system.${currentPrimarySystem}.temperature.cutoffType`) || {};
    const { id: tempRatingId, value: tempRatingValue } = selector({form}, `${accessor}.system.${currentPrimarySystem}.temperature.ratingType`) || {};
    const { id: sourceTempInputId } = selector({form}, `${accessor}.system.${currentPrimarySystem}.source.use`) || {};

    const capacityUnits = selector({form}, `${accessor}.system.${currentPrimarySystem}.specifications.outputCapacity_u`);
    const crankcaseHeaterUnits = selector({form}, `${accessor}.system.${currentPrimarySystem}.crankcaseHeater_u`);
    const openableWindowAreaUnits = selector({form}, `${accessor}.system.${currentPrimarySystem}.specifications.coolingParams.openableWindowArea_u`);
    const tempCutoffUnits = selector({form}, `${accessor}.system.${currentPrimarySystem}.temperature.cutoffType_u`);
    const tempRatingUnits = selector({form}, `${accessor}.system.${currentPrimarySystem}.temperature.ratingType_u`);
    const depthUnits = selector({form}, `${accessor}.system.${currentPrimarySystem}.source.depth_u`);

    const janSourceTempUnits = selector({form}, `${accessor}.system.${currentPrimarySystem}.source.temperatures.january_u`);
    const febSourceTempUnits = selector({form}, `${accessor}.system.${currentPrimarySystem}.source.temperatures.february_u`);
    const marSourceTempUnits = selector({form}, `${accessor}.system.${currentPrimarySystem}.source.temperatures.march_u`);
    const aprSourceTempUnits = selector({form}, `${accessor}.system.${currentPrimarySystem}.source.temperatures.april_u`);
    const maySourceTempUnits = selector({form}, `${accessor}.system.${currentPrimarySystem}.source.temperatures.may_u`);
    const junSourceTempUnits = selector({form}, `${accessor}.system.${currentPrimarySystem}.source.temperatures.june_u`);
    const julSourceTempUnits = selector({form}, `${accessor}.system.${currentPrimarySystem}.source.temperatures.july_u`);
    const augSourceTempUnits = selector({form}, `${accessor}.system.${currentPrimarySystem}.source.temperatures.august_u`);
    const sepSourceTempUnits = selector({form}, `${accessor}.system.${currentPrimarySystem}.source.temperatures.september_u`);
    const octSourceTempUnits = selector({form}, `${accessor}.system.${currentPrimarySystem}.source.temperatures.october_u`);
    const novSourceTempUnits = selector({form}, `${accessor}.system.${currentPrimarySystem}.source.temperatures.november_u`);
    const decSourceTempUnits = selector({form}, `${accessor}.system.${currentPrimarySystem}.source.temperatures.december_u`);

    const areSourceTempsEmpty = isEmpty(selector({form}, `${accessor}.system.${currentPrimarySystem}.source.temperatures`) || {});

    return {
        accessor,
        currentPrimarySystem,
        functionId,
        equipTypeId,
        capacityId,
        capacityValue,
        capacityUnits,
        crankcaseHeaterUnits,
        openableWindowAreaUnits,
        tempCutoffId,
        tempCutoffValue,
        tempCutoffUnits,
        tempRatingId,
        tempRatingValue,
        tempRatingUnits,
        depthUnits,
        sourceTempInputId,
        areSourceTempsEmpty,
        janSourceTempUnits,
        febSourceTempUnits,
        marSourceTempUnits,
        aprSourceTempUnits,
        maySourceTempUnits,
        junSourceTempUnits,
        julSourceTempUnits,
        augSourceTempUnits,
        sepSourceTempUnits,
        octSourceTempUnits,
        novSourceTempUnits,
        decSourceTempUnits,
        modelUnits,
        h2kMinor,
        contentOpen
    }
};

const mapDispatchToProps = () => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WaterGroundHeatPump);