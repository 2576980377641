import React from 'react';
import classes from './style.module.scss';
import sharedClasses from 'features/Model/sharedStyles.module.scss';
import { Field } from 'redux-form';
import { getValidation, getDecimalPlaces } from 'utils/fieldValidation';
import InputRow from 'components/Input/Row';
import Input from 'components/Input';

const nonResValidation = getValidation('numNonResUnits');
const storeysValidation = getValidation('numStoreysInBuilding');
const unitsVisitedValidation = getValidation('numUnitsVisited');
const dwellingUnitsValidation = getValidation('numDwellingUnits');

//TODO: fix my layouts please :D 
export default React.memo(({
    accessor,
    isSingleMURB,
    isWholeBuildingMURB,
    modelUnits,
    change
}) => (
    <>
        <InputRow gridTemplate="1fr 1fr 1fr">
            {
                isSingleMURB ? 
                <>  
                    <Field
                        component={Input}
                        type="number"
                        name={`${accessor}.murbSpecs.numStoreysInBuilding`}
                        label="Storeys in Building"
                        placeholder={0.00}
                        validate={storeysValidation}
                        decimals={getDecimalPlaces('numStoreysInBuilding')}
                    />
                </> : <></>
            }
            <Field
                component={Input}
                type="number"
                name={`${accessor}.murbSpecs.numDwellingUnits`}
                label="Dwelling Units"
                placeholder={0.00}
                validate={dwellingUnitsValidation}
                decimals={getDecimalPlaces('numDwellingUnits')}
            />
            <Field
                component={Input}
                type="number"
                name={`${accessor}.murbSpecs.numNonResUnits`}
                label="Non-residential Units"
                placeholder={0.00}
                validate={nonResValidation}
                decimals={getDecimalPlaces('numNonResUnits')}
            />
            {
                isWholeBuildingMURB ? 
                <>  
                    <Field
                        component={Input}
                        type="number"
                        name={`${accessor}.murbSpecs.numUnitsVisited`}
                        label="Units Visited"
                        placeholder={0.00}
                        validate={unitsVisitedValidation}
                        decimals={getDecimalPlaces('numUnitsVisited')}
                    />
                </> : <></>
            }
        </InputRow>
    </>
))