import React from "react";
import classes from "./style.module.scss";
import sharedClasses from "features/Model/sharedStyles.module.scss";
import { Field } from "redux-form";
import { getOptions, getFirstOption, getBaseUnits, getUnitOptions } from "utils/fields";
import { required, maxLength, getValidation, getDecimalPlaces } from "utils/fieldValidation";
import InputRow from "components/Input/Row";
import Select from "components/Input/Select";
import Input from "components/Input";
import Accordion from "components/Accordion";
import InputWithUnits from "components/Input/InputWithUnits";
import Checkbox from "components/Input/Checkbox";
import NonH2kParam from "assets/images/icons/JSX/NonH2kParam";

const fanModeValidation = getValidation("fanPumpMode");
const fanHighPowerValidation = getValidation("fanPumpHighPower");
const fanLowPowerValidation = getValidation("fanPumpLowPower");

export default ({
    accessor,
    currentPrimarySystem,
    isHeatingCapCalculated,
    isFanPowerCalculated,
    fanPowerModeId,
    highPowerUnits,
    lowPowerUnits,
    modelUnits,
    change,
}) => {
    return (
        <>
            <h4 style={{ marginBottom: "1.25rem", marginTop: "1.75rem" }}>Fans &amp; Pumps</h4>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Field
                    component={Select}
                    name={`${accessor}.fansAndPumps.mode`}
                    options={getOptions({ fieldKey: "fanPumpMode" })}
                    label="Mode"
                    placeholder="Choose Mode"
                    validate={fanModeValidation}
                    info={"Leave as the default value (Auto)."}
                />
                <Field
                    component={Select}
                    name={`${accessor}.fansAndPumps.power.isCalculated`}
                    options={[
                        {
                            label: "Calculated",
                            value: true,
                        },
                        {
                            label: "User Specified",
                            value: false,
                        },
                    ]}
                    label="Power Type"
                    placeholder="Choose Power Input Type"
                    disabled={isHeatingCapCalculated}
                    info={
                        "Leave as the default value (Calculated). Note that the calculated value will not display in Volta SNAP, but will appear when simulating in HOT2000."
                    }
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.fansAndPumps.power.high`}
                    label="High Speed Power"
                    placeholder="0.00"
                    validate={fanHighPowerValidation}
                    decimals={getDecimalPlaces("fanPumpHighPower")}
                    change={change}
                    disabled={isFanPowerCalculated || isHeatingCapCalculated}
                    units={{
                        base: getBaseUnits("fanPumpHighPower", modelUnits),
                        options: getUnitOptions("power"),
                        selected: highPowerUnits,
                        unitType: "power",
                        accessor: `${accessor}.fansAndPumps.power.high_u`,
                    }}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.fansAndPumps.power.low`}
                    label="Low Speed Power"
                    placeholder="0.00"
                    validate={fanLowPowerValidation}
                    decimals={getDecimalPlaces("fanPumpLowPower")}
                    change={change}
                    disabled={isHeatingCapCalculated || isFanPowerCalculated || fanPowerModeId !== 3}
                    units={{
                        base: getBaseUnits("fanPumpLowPower", modelUnits),
                        options: getUnitOptions("power"),
                        selected: lowPowerUnits,
                        unitType: "power",
                        accessor: `${accessor}.fansAndPumps.power.low_u`,
                    }}
                />
            </InputRow>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Field
                    component={Checkbox}
                    name={`${accessor}.fansAndPumps.hasEnergyEfficientMotor`}
                    label="Energy Efficient Motor"
                    large
                    className={classes.checkbox}
                    type="checkbox"
                />
            </InputRow>
            <Accordion
                className={sharedClasses.accordion}
                heading={
                    <div className={sharedClasses.accordionIconHeading}>
                        Hydronic Heating System Distribution <NonH2kParam />
                    </div>
                }
                large
            >
                <div>
                    <h5 className={classes.f280Text}>
                        Use these fields to define portions of the home served by heat distribution piping passing
                        through unconditioned spaces or adjacent to the perimeter of slab-on-grade foundations for CSA
                        F280-12 calculations.
                    </h5>
                    <InputRow gridTemplate="2fr 1fr 1fr">
                        <Field
                            component={Checkbox}
                            name={`${accessor}.fansAndPumps.f280Hydronic.unconditionedSpace`}
                            label="Enable hydronic losses for F280"
                            setValue={true}
                            large
                            className={classes.checkbox}
                            type="checkbox"
                        />
                    </InputRow>
                    <InputRow gridTemplate="1fr 1fr 1fr">
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.fansAndPumps.f280Hydronic.percAreaServed`}
                            label="Approximate floor area served"
                            placeholder={0.0}
                            setTouched={true}
                            decimals={getDecimalPlaces("fanPumpLowPower")}
                            change={change}
                            units={{
                                base: getBaseUnits("generalFuelTaxRebate", modelUnits),
                                options: ["%"],
                                selected: "%",
                                unitType: "fraction",
                                accessor: `${accessor}.fansAndPumps.f280Hydronic.percAreaServed_u`,
                            }}
                        />
                        <Field
                            component={Select}
                            name={`${accessor}.fansAndPumps.f280Hydronic.circulationType`}
                            options={[
                                {
                                    label: "Gravity",
                                    value: "gravity",
                                },
                                {
                                    label: "Pumped",
                                    value: "pumped",
                                },
                                {
                                    label: "None",
                                    value: "none",
                                },
                            ]}
                            label="Type of Circulation"
                            placeholder="Choose Circulation Type"
                        />
                        <Field
                            component={Checkbox}
                            name={`${accessor}.fansAndPumps.f280Hydronic.insulatedPiping`}
                            label="Insulated piping"
                            setValue={true}
                            large
                            className={classes.checkbox}
                            type="checkbox"
                        />
                    </InputRow>
                </div>
            </Accordion>
        </>
    );
};
