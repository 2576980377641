import React, { useState } from "react";
import Button from "components/Button";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { Tooltip } from "@material-ui/core";

import { getDoorWindowData } from "utils/dcfExport/doorWindowData";
import { getEnvelopeData } from "utils/dcfExport/envelopeData";
import { getFoundationData } from "utils/dcfExport/foundationData";
import { getVentilationData } from "utils/dcfExport/ventilationData";
import { getMechanicalsData } from "utils/dcfExport/mechanicalsData";
import { getGeneralData } from "utils/dcfExport/generalData";
import { getAirTightnessData } from "utils/dcfExport/airTightnessData";
import { getProgramData } from "utils/dcfExport/programData";

export default React.memo(
    ({
        modelName,
        accessor,
        userEmail,
        modelUnits,
        readOnly,
        modelSave,
        saveDrawingData,
        fileName,
        general,
        components,
        ventilation,
        heatingCooling,
        specifications,
        location,
        airTightness,
        dimensions,
        generation,
        temperatures,
        domesticHotWater,
        program,
        codes,
        h2kVersion,
        exporting,
        exportingDcf,
        toggleDcfExporting,
        toggledcfErrorMsg,
    }) => {
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";

        const exportToExcel = async () => {
            toggleDcfExporting(true);

            try {
                //handled in an action and cannot yet be disabled at the source, like handleSubmit
                // if (!readOnly) {
                //     await saveDrawingData();
                // }

                await modelSave();
                const generalData =
                    getGeneralData({ modelName: fileName, userEmail, h2kVersion, general, specifications, location }) ||
                    {};

                const envelopeData =
                    getEnvelopeData({
                        codes,
                        modelUnits,
                        components,
                        dimensions,
                    }) || {};

                const doorWindowData = getDoorWindowData({ codes, modelUnits, components }) || {};

                const foundationData =
                    getFoundationData({
                        codes,
                        modelUnits,
                        components,
                        dimensions,
                    }) || {};

                const mechanicalsData =
                    getMechanicalsData({ modelUnits, heatingCooling, temperatures, domesticHotWater, generation }) ||
                    {};

                const ventilationData = getVentilationData({ ventilation, modelUnits }) || {};
                const airTightnessData = getAirTightnessData({ airTightness, dimensions, modelUnits }) || {};

                const programData = getProgramData({ program, modelUnits }) || {};

                const generalSheet = XLSX.utils.json_to_sheet(generalData);
                const foundationSheet = XLSX.utils.json_to_sheet(foundationData);
                const envelopeSheet = XLSX.utils.json_to_sheet(envelopeData);
                const doorWindowSheet = XLSX.utils.json_to_sheet(doorWindowData);
                const mechanicalsSheet = XLSX.utils.json_to_sheet(mechanicalsData);
                const ventilationSheet = XLSX.utils.json_to_sheet(ventilationData);
                const airTightnessSheet = XLSX.utils.json_to_sheet(airTightnessData);
                const programSheet = XLSX.utils.json_to_sheet(programData);

                const stylesA = [
                    {
                        data: foundationData,
                        sheet: foundationSheet,
                    },
                    {
                        data: envelopeData,
                        sheet: envelopeSheet,
                    },
                    {
                        data: doorWindowData,
                        sheet: doorWindowSheet,
                    },
                ];

                const stylesB = [
                    {
                        data: generalData,
                        sheet: generalSheet,
                    },
                    {
                        data: mechanicalsData,
                        sheet: mechanicalsSheet,
                    },
                    {
                        data: ventilationData,
                        sheet: ventilationSheet,
                    },
                    {
                        data: airTightnessData,
                        sheet: airTightnessSheet,
                    },
                    {
                        data: programData,
                        sheet: programSheet,
                    },
                ];

                const regularWidthRows = new Array(50);
                regularWidthRows.fill({ wch: 15 });

                const largeFontRowsA = stylesA.reduce((acc, pairing) => {
                    return [
                        ...acc,
                        pairing.data.reduce((acc, data, ind) => {
                            if (Object.values(data)[0] === "ID") {
                                return [...acc, ind + 1];
                            } else {
                                return acc;
                            }
                        }, []),
                    ];
                }, []);
                const tableHeaderRowsA = stylesA.reduce((acc, pairing) => {
                    return [
                        ...acc,
                        pairing.data.reduce((acc, data, ind) => {
                            if (Object.values(data)[0] === "ID") {
                                return [...acc, ind + 2];
                            } else {
                                return acc;
                            }
                        }, []),
                    ];
                }, []);

                stylesA.map((pairing, pairingIndex) => {
                    const letterArray = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
                    const extraLetters = letterArray.map((letter) => {
                        return `A${letter}`;
                    });
                    letterArray.push(...extraLetters);

                    let italicColumns = [];
                    let parentCompLabelRows = [];

                    pairing.data.push(""); // add an extra row to accomodate last item
                    pairing.data.map((data, ind) => {
                        letterArray.map((letter) => {
                            const cell = letter + `${ind + 1}`;
                            const cellContent = pairing.sheet[cell];
                            if (cellContent !== undefined) {
                                if (cellContent.v !== "") {
                                    if (typeof cellContent.v === "string") {
                                        if (cellContent.v.split("").includes("$")) {
                                            italicColumns.push(letter);
                                            const cellContentArray = cellContent.v.split("");
                                            cellContent.v = cellContentArray
                                                .slice(0, cellContentArray.length - 2)
                                                .join("");
                                        }
                                    }
                                }
                            }
                            if (cellContent !== undefined) {
                                if (cellContent.v !== "") {
                                    if (typeof cellContent.v === "string") {
                                        if (cellContent.v.split("").includes("&")) {
                                            const cellContentArray = cellContent.v.split("");
                                            cellContent.v = cellContentArray
                                                .slice(0, cellContentArray.length - 2)
                                                .join("");
                                            parentCompLabelRows.push(ind + 1);
                                        }
                                    }
                                }
                            }
                        });

                        letterArray.map((letter) => {
                            const cell = letter + `${ind + 1}`;
                            const cellContent = pairing.sheet[cell];
                            if (cellContent !== undefined) {
                                const isTopRowFill = ind + 1 === 1 && letter !== "A" && letter !== "B";
                                if (isTopRowFill) {
                                    cellContent.v = "";
                                }
                                if (cellContent.v !== "") {
                                    const isSectionHeader = largeFontRowsA[pairingIndex].includes(ind + 1);
                                    const isTableHeader = tableHeaderRowsA[pairingIndex].includes(ind + 1);
                                    if (isSectionHeader) {
                                        italicColumns = [];
                                    }
                                    pairing.sheet[cell].s = {
                                        font: {
                                            name: "arial",
                                            sz: isSectionHeader ? 26 : 12,
                                            bold: letter === "A" || isTableHeader,
                                            italic: italicColumns.includes(letter),
                                            color: "#F2F2F2",
                                        },
                                        border: !isSectionHeader && {
                                            top: { style: "thin", color: "#F2F2F2" },
                                            bottom: { style: "thin", color: "#F2F2F2" },
                                            left: { style: "thin", color: "#F2F2F2" },
                                            right: { style: "thin", color: "#F2F2F2" },
                                        },
                                        alignment: {
                                            wrapText: !isSectionHeader,
                                        },
                                        fill:
                                            isTableHeader && !italicColumns.includes(letter)
                                                ? {
                                                      type: "pattern",
                                                      patternType: "solid",
                                                      fgColor: { rgb: "D9D9D9" },
                                                  }
                                                : isTableHeader && italicColumns.includes(letter)
                                                ? {
                                                      type: "pattern",
                                                      patternType: "solid",
                                                      fgColor: { rgb: "A6A6A6" },
                                                  }
                                                : parentCompLabelRows.includes(ind + 1)
                                                ? {
                                                      type: "pattern",
                                                      patternType: "solid",
                                                      fgColor: { rgb: "F2F2F2" },
                                                  }
                                                : {
                                                      type: "pattern",
                                                      patternType: "none",
                                                      fgColor: { rgb: "" },
                                                  },
                                    };
                                }
                                if (cellContent.v === "-") {
                                    cellContent.v = "";
                                }
                            }
                        });

                        const wscols = [{ wch: 40 }, { wch: 20 }, ...regularWidthRows];
                        pairing.sheet["!cols"] = wscols;
                    });
                });

                const largeFontRowsB = stylesB
                    .reduce((acc, pairing) => {
                        return [
                            ...acc,
                            pairing.data.reduce((acc, data, ind) => {
                                if (Object.values(data)[0] === "---") {
                                    return [...acc, ind];
                                } else {
                                    return acc;
                                }
                            }, []),
                        ];
                    }, [])
                    .reduce((acc, sheetNumbers) => {
                        return [
                            ...acc,
                            sheetNumbers
                                .reduce((acc, number, ind, array) => {
                                    return [
                                        ...acc,
                                        [
                                            number,
                                            number - array[ind - 1] === 1 && array[ind - 1] - array[ind - 2] === 1,
                                        ],
                                    ];
                                }, [])
                                .reduce((acc, data) => {
                                    if (data[1] === true) {
                                        return [...acc, data[0] + 3];
                                    } else {
                                        return acc;
                                    }
                                }, []),
                        ];
                    }, []);

                const tableRows = stylesB.reduce((acc, pairing) => {
                    return [
                        ...acc,
                        pairing.data.reduce((acc, data, ind) => {
                            if (Object.values(data)[0] !== "?") {
                                return [...acc, ind + 2];
                            } else {
                                return acc;
                            }
                        }, []),
                    ];
                }, []);

                stylesB.map((pairing, pairingIndex) => {
                    const letterArray = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
                    const extraLetters = letterArray.map((letter) => {
                        return `A${letter}`;
                    });

                    letterArray.push(...extraLetters);

                    pairing.data.push(""); // add an extra row to accomodate last item
                    pairing.data.map((data, ind) => {
                        letterArray.map((letter) => {
                            const cell = letter + `${ind + 1}`;
                            const cellContent = pairing.sheet[cell];
                            const isTableRow = tableRows[pairingIndex].includes(ind);
                            if (cellContent !== undefined) {
                                const isTopRowFill = ind + 1 === 1 && letter !== "A" && letter !== "B";
                                if (isTopRowFill) {
                                    cellContent.v = "";
                                }
                                if (cellContent.v !== "---") {
                                    const isHeaderRow = largeFontRowsB[pairingIndex].includes(ind + 1);
                                    const isFirstColumn = letter === "A";
                                    const isLargeFontBold =
                                        largeFontRowsB[pairingIndex].includes(ind + 1) && isFirstColumn;
                                    const isTableRow = tableRows[pairingIndex].includes(ind + 1);
                                    pairing.sheet[cell].s = {
                                        font: {
                                            name: "arial",
                                            sz: isLargeFontBold ? 26 : 12,
                                            bold: isHeaderRow,
                                            color: "#F2F2F2",
                                        },
                                        border: {
                                            top: isTableRow && !isHeaderRow ? { style: "thin", color: "#F2F2F2" } : {},
                                            bottom:
                                                isTableRow && !isHeaderRow ? { style: "thin", color: "#F2F2F2" } : {},
                                            left: isTableRow && !isHeaderRow ? { style: "thin", color: "#F2F2F2" } : {},
                                            right:
                                                isTableRow && !isHeaderRow ? { style: "thin", color: "#F2F2F2" } : {},
                                        },
                                        alignment: {
                                            horizontal: !isFirstColumn && !isHeaderRow ? "right" : "",
                                            wrapText: !isLargeFontBold,
                                        },
                                    };
                                }
                                if (cellContent.v === "---") {
                                    cellContent.v = "";
                                }
                            }
                        });
                        const generalWidth = 50;
                        const mechanicalWidth = 80;
                        const ventilationWidth = 70;
                        const airTightnessWidth = 30;
                        const programInputsWidth = 70;

                        let getFirstColumnWidth = () => {
                            if (pairingIndex === 0) {
                                return generalWidth;
                            } else if (pairingIndex === 1) {
                                return mechanicalWidth;
                            } else if (pairingIndex === 2) {
                                return ventilationWidth;
                            } else if (pairingIndex === 3) {
                                return airTightnessWidth;
                            } else if (pairingIndex === 4) {
                                return programInputsWidth;
                            }
                        };
                        const wscols = [{ wch: getFirstColumnWidth() }, { wch: 30 }, ...regularWidthRows];
                        pairing.sheet["!cols"] = wscols;
                    });
                });

                const workbook = {
                    Sheets: {
                        General: generalSheet,
                        Foundation: foundationSheet,
                        Envelope: envelopeSheet,
                        "Doors & Windows": doorWindowSheet,
                        Mechanicals: mechanicalsSheet,
                        Ventilation: ventilationSheet,
                        Airtightness: airTightnessSheet,
                        "Program Inputs": programSheet,
                    },
                    SheetNames: [
                        "General",
                        "Foundation",
                        "Envelope",
                        "Doors & Windows",
                        "Mechanicals",
                        "Ventilation",
                        "Airtightness",
                        "Program Inputs",
                    ],
                };

                const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
                const data = new Blob([excelBuffer], { type: fileType });
                FileSaver.saveAs(data, fileName + fileExtension);
                toggledcfErrorMsg("");
            } catch (err) {
                toggledcfErrorMsg("An error occured while trying to export your DCF.");
                toggleDcfExporting(false);
            }

            toggleDcfExporting(false);
        };

        return (
            <>
                <Tooltip title="Export Data Collection Form">
                    <Button
                        variant="contained"
                        onClick={() => exportToExcel()}
                        color="primary"
                        style={{ cursor: "pointer", fontSize: 14 }}
                        disabled={exporting || exportingDcf}
                    >
                        Export Data Collection Form
                    </Button>
                </Tooltip>
            </>
        );
    }
);
