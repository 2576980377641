import React, { useState, useCallback } from "react";
import Table from "components/Table";
import classes from "./style.module.scss";
import globalStyles from "components/globalStyles.module.scss";
import CodeActions from "./CodeActions/container";
import Button from "components/Button";
import AddIcon from "assets/images/icons/JSX/Add";
import Drawer from "components/Drawer";
import InputRow from "components/Input/Row";
import Select from "components/Input/Select";
import { CodeDrawer } from "utils/codeLib";
import EditIcon from "assets/images/icons/JSX/Edit";
import moment from "moment";
import { standardCodeFormName, uDefCodeFormName } from "utils/codeLib";
import convertUnit from "utils/convertUnit";
import Exclamation from "assets/images/icons/JSX/Exlamation";
import getWarningText from "utils/getWarningText";
import Tooltip from "components/Tooltip";

const headCells = (type) => {
    if (type === "Window") {
        return [
            { id:"name", label:"Code Name", width:"280", align:"left", sortable:true },
            { id:"type", label:"Code Type", width: "136", sortable:true },
            { id:"rVal", label:"Eff. R-Value", width: "126", sortable:true },
            { id:"shgc", label:"SHGC", width: "126", sortable:true },
            { id: "lastEdited", label:"Last Modified", width: "132", sortable:true },
            { id:"actions", label:"", sortable:true }
        ]
    }

    return [
        { id:"name", label:"Code Name", width:"280", align:"left", sortable:true },
        { id:"type", label:"Code Type", width: "136", sortable:true },
        { id:"rVal", label:"Eff. R-Value", width: "126", sortable:true },
        { id:"nominalRVal", label:"Nom. R-Value", width: "126", sortable:true },
        { id: "lastEdited", label:"Last Modified", width: "132", sortable:true },
        { id:"actions", label:"" }
    ]
};

const rows = (
    type,
    codes,
    calculatingRValue,
    toggleComponentType,
    toggleCodeType,
    toggleOpenCreate,
    handleEditOpen,
    rValUnits="RSI"
) => {
    if (type === "Window") {
        return Object.keys(codes)
            .sort((codeA, codeB) => {
                if (codeB > codeA) {
                    return 1;
                } 
        
                if (codeB < codeA) {
                    return -1;
                } 
        
                return 0;
            })
            .map(code => {
                const [ standardCode ] = code.split(`${type}-U-`);
                const codeType = standardCode ? "S" : "U";

                const rValue = parseFloat(convertUnit({
                    value:codes[code].nominalRValue,
                    type:'thermalResistance',
                    inputUnit:"RSI",
                    outputUnit:rValUnits,
                })).toFixed(2);
    
                return {
                    name:{
                        value:codes[code].label,
                        content: (
                            <span
                                onClick={() => handleEditOpen(
                                    code,
                                    {
                                        codeRef:code,
                                        ...codes[code]
                                    },
                                    codes[code].componentType,
                                    codeType
                                )}
                                className={classes.codeName}>
                                    {codes[code].label}
                                    {codes[code].warningType && (
                                        <>
                                            <div
                                                className={classes.warning}
                                                data-tip={`<span style="display:block;max-width:16rem;">${getWarningText(codes[code].warningType)}</span>`}
                                                data-for={`tip-codelist-${code}`}
                                                data-html
                                            >
                                                <Exclamation />
                                            </div>
                                            <Tooltip id={`tip-codelist-${code}`} />
                                        </>
                                    )}
                            </span>
                        ),
                        isLoading: calculatingRValue[code],
                    },
                    type:{
                        value:standardCode ? "Standard" : "User Defined",
                        content:standardCode ? "Standard" : "User Defined",
                        isLoading: calculatingRValue[code],
                    },
                    rVal:{
                        value:rValue,
                        content:`${rValue} ${rValUnits}`,
                        isLoading: calculatingRValue[code],
                    },
                    shgc:{
                        value:codes[code].shgc,
                        content:codes[code].shgc,
                        isLoading: calculatingRValue[code],
                    },
                    lastEdited:{
                        value:codes[code].lastEdited && moment(codes[code].lastEdited).isValid()
                            ? moment(codes[code].lastEdited).format("YYYY-MM-DD")
                            : "0",
                        content: codes[code].lastEdited && moment(codes[code].lastEdited).isValid()
                            ? moment(codes[code].lastEdited).format("MMM DD, YYYY")
                            : "-",
                        isLoading: calculatingRValue[code],
                    },
                    actions:{
                        value:"",
                        content:<CodeActions
                            type={type}
                            id={code}
                            name={codes[code].label}
                            toggleComponentType={toggleComponentType}
                            toggleCodeType={toggleCodeType}
                            toggleOpenCreate={toggleOpenCreate}
                            handleEditOpen={handleEditOpen}
                        />
                    }
                }
            });
    }

    return Object.keys(codes)
        .sort((codeA, codeB) => {
            if (codeB > codeA) {
                return 1;
            } 

            if (codeB < codeA) {
                return -1;
            } 

            return 0;
        })
        .map(code => {
            const [ standardCode ] = code.split(`${type}-U-`);
            const codeType = standardCode ? "S" : "U";

            const rValue = parseFloat(convertUnit({
                value:codes[code].nominalRValue,
                type:'thermalResistance',
                inputUnit:"RSI",
                outputUnit:rValUnits,
            })).toFixed(2);

            return {
                name:{
                    value:codes[code].label,
                    content:(
                        <span
                            onClick={() => handleEditOpen(
                                code,
                                {
                                    codeRef:code,
                                    ...codes[code]
                                },
                                codes[code].componentType,
                                codeType
                            )}
                            className={classes.codeName}>
                                {codes[code].label}
                                {codes[code].warningType && (
                                    <>
                                        <div
                                            className={classes.warning}
                                            data-tip={`<span style="display:block;max-width:16rem;">${getWarningText(codes[code].warningType)}</span>`}
                                            data-for={`tip-codelist-${code}`}
                                            data-html
                                        >
                                            <Exclamation />
                                        </div>
                                        <Tooltip id={`tip-codelist-${code}`} />
                                    </>
                                )}
                        </span>
                    ),
                    isLoading: calculatingRValue[code],
                },
                type:{
                    value:standardCode ? "Standard" : "User Defined",
                    content:standardCode ? "Standard" : "User Defined",
                    isLoading: calculatingRValue[code],
                },
                rVal:{
                    value:rValue,
                    content:`${rValue} ${rValUnits}`,
                    isLoading: calculatingRValue[code],
                },
                nominalRVal:{
                    value:rValue,
                    content:`${rValue} ${rValUnits}`,
                    isLoading: calculatingRValue[code],
                },
                lastEdited:{
                    value:codes[code].lastEdited && moment(codes[code].lastEdited).isValid()
                        ? moment(codes[code].lastEdited).format("YYYY-MM-DD")
                        : "0",
                    content: codes[code].lastEdited && moment(codes[code].lastEdited).isValid()
                        ? moment(codes[code].lastEdited).format("MMM DD, YYYY")
                        : "-",
                    isLoading: calculatingRValue[code],
                },
                actions:{
                    value:"",
                    content:<CodeActions
                        type={type}
                        id={code}
                        name={codes[code].label}
                        toggleComponentType={toggleComponentType}
                        toggleCodeType={toggleCodeType}
                        toggleOpenCreate={toggleOpenCreate}
                        handleEditOpen={handleEditOpen}
                    />
                }
            }
        })
};

const emptyRow = {
    name:{
        value:"",
        isLoading: true,
    },
    rVal:{
        value:"",
        isLoading: true,
    },
    nominalRVal:{
        value:"",
        isLoading: true,
    },
    actions:{
        value:"",
    }
};

const CodeList = ({
    codes,
    name,
    totalCount,
    standardCount,
    uDefCount,
    type,
    calculatingRValue,
    clearInitCode,
    addingNewCode,
    setInitCode,
    rValueUnits
}) => {
    const [openCreate, toggleOpenCreate] = useState(false);
    const [editOpen, toggleEditOpen] = useState("");
    const [componentType, toggleComponentType] = useState(type);
    const [codeType, toggleCodeType] = useState("S");

    const handleClose = useCallback(() => {
        toggleOpenCreate(false);
        toggleCodeType("S");
        clearInitCode();
    }, [clearInitCode])

    const handleEditClose = useCallback(() => {
        toggleEditOpen("");
        toggleCodeType("S");
        clearInitCode();
    }, [clearInitCode])

    const handleEditOpen = useCallback(async (id, code, componentType, codeType) => {
        const codeName = codeType === "S" ? standardCodeFormName(componentType) : uDefCodeFormName(componentType);
        await setInitCode(code, codeName);
        toggleEditOpen(id);
        toggleComponentType(componentType);
        toggleCodeType(codeType);
    }, [setInitCode]);

    //TODO: this in all of the model code drawers - ALSO maybe it's time we make them all one??

    const handleUdefSaveEdit = useCallback(() => {
        toggleCodeType("S");
        toggleEditOpen(false);
    }, []);

    const handleUdefSaveCreate = useCallback(() => {
        toggleCodeType("S");
        toggleOpenCreate(false);
    }, []);

    return (
        <div className={classes.codeList}>
            <div className={classes.topWrapper}>
                <h2>{name} Codes</h2>
                <div className={classes.stats}>
                    <div className={`${classes.stat} ${classes.largeStat}`}>
                        {/* <img src={ClimateIcon} alt="Sun" /> */}
                        <div>
                            <span className={classes.name}>Total Codes</span>
                            <span className={classes.value}>{totalCount}</span>
                        </div>
                    </div>
                    <div className={classes.stat}>
                        {/* <img src={ClimateIcon} alt="Sun" /> */}
                        <div>
                            <span className={classes.name}>Standard Codes</span>
                            <span className={classes.value}>{standardCount}<small> / {totalCount}</small></span>
                        </div>
                    </div>
                    <div className={classes.stat}>
                        {/* <img src={ClimateIcon} alt="Sun" /> */}
                        <div>
                            <span className={classes.name}>User Defined Codes</span>
                            <span className={classes.value}>{uDefCount}<small> / {totalCount}</small></span>
                        </div>
                    </div>
                    <Button
                        className={classes.createCode}
                        icon={() => <AddIcon className={classes.createIcon} />}
                        onClick={() => {
                            toggleOpenCreate(true);
                        }}
                    >
                        Create New Code
                    </Button>
                </div>
            </div>
            <div className={classes.tableWrapper}>
                <Table
                    paginate
                    defaultRowsPerPage={25}
                    headCells={headCells(type)}
                    defaultSortProp="lastEdited"
                    rows={[
                        ...rows(
                            type,
                            codes,
                            calculatingRValue,
                            toggleComponentType,
                            toggleCodeType,
                            toggleOpenCreate,
                            handleEditOpen,
                            rValueUnits
                        ),
                        ...addingNewCode ? [ emptyRow ] : []
                    ]}
                />
                {!Object.keys(codes).length && <div className={classes.zeroState}><p>There are no {name} codes to display</p></div>}
            </div>
            <Drawer
                open={openCreate}
                title={`Create ${name} Code`}
                subtitle={`Define the details of your new ${name.toLowerCase()} code`}
                close={handleClose}
                icon={() => <EditIcon fill="#0eb0a9" />}
                introColor="rgba(24, 193, 173, 0.1)"
                headerClassName={classes.createHeader}
            >
                {
                    !["FloorsAdded","Lintel"].includes(type) &&
                        <InputRow gridTemplate="1fr" className={globalStyles.type}>
                            <Select
                                className={globalStyles.typeSelect}
                                name={'codeType'}
                                label="Code Type"
                                placeholder="Choose Code Type"
                                options={[
                                    {
                                        label: 'Standard',
                                        value: "S"
                                    },
                                    {
                                        label: 'User Defined',
                                        value: "U"
                                    }
                                ]}
                                disabled={false}
                                setValue={codeType}
                                input={{
                                    onChange:((value)=>{
                                        toggleCodeType(value);
                                    }),
                                    value:codeType
                                }}
                            />
                        </InputRow>
                }
                <CodeDrawer
                    toggleOpen={toggleOpenCreate}
                    modelFormChange={()=>{}}
                    fieldAccessor={"none"}
                    componentType={componentType}
                    codeType={codeType}
                    handleClose={handleClose}
                    isCodeLibrary
                    isFlat={type === "CeilingFlat"}
                    handleUdefSave={handleUdefSaveCreate}
                />
            </Drawer>
            <Drawer
                open={!!editOpen}
                title={`Edit ${name} Code`}
                subtitle={<span>Last Modified: {codes[editOpen] && codes[editOpen].lastEdited ? moment(codes[editOpen].lastEdited).format("MMM DD, YYYY") : "-" } 
                <br />Editing a library code will <strong>not</strong> update instances of the code already used in models.</span>}
                close={handleEditClose}
                icon={() => <EditIcon fill="#0b91ea" />}
                headerClassName={classes.editHeader}
                introColor="#f0f8fd"
            >
                <CodeDrawer
                    toggleOpen={toggleEditOpen}
                    modelFormChange={()=>{}}
                    fieldAccessor={"none"}
                    componentType={componentType}
                    codeType={codeType}
                    handleClose={handleEditClose}
                    isCodeLibrary
                    isFlat={type === "CeilingFlat"}
                    handleUdefSave={handleUdefSaveEdit}
                />
            </Drawer>
        </div>
    )
};

export default CodeList;