import React, { useState, useCallback } from "react";
import classes from "../style.module.scss";
import Edit from "assets/images/icons/JSX/Edit";
import Button from "components/Button";
import Duplicate from "assets/images/icons/JSX/Duplicate";
import Delete from "assets/images/icons/JSX/Delete";
import Tooltip from "components/Tooltip";
import { standardCodeFormName, uDefCodeFormName } from "utils/codeLib";
import { isEqual } from "lodash";
import Dialog, { CloseDialog } from "components/Dialog";

const CodeActions = React.memo(
    ({
        id,
        deleteCode,
        componentType,
        codeType,
        code = {},
        setInitCode,
        name,
        toggleComponentType,
        toggleCodeType,
        toggleOpenCreate,
        handleEditOpen,
    }) => {
        const [deleteOpen, toggleDeleteOpen] = useState(false);

        const handleDuplicate = async () => {
            const codeName =
                codeType === "S"
                    ? standardCodeFormName(componentType)
                    : uDefCodeFormName(componentType);
            const { codeRef, label, ...restOfCode } = code;

            await setInitCode(
                { label: `${label} (Duplicate)`, ...restOfCode },
                codeName
            );
            toggleOpenCreate(true);
            toggleComponentType(componentType);
            toggleCodeType(codeType);
        };

        return (
            <div className={classes.actions}>
                <div
                    data-for={`${id}-actions`}
                    data-tip="Edit Code"
                    className={classes.action}
                    onClick={() =>
                        handleEditOpen(id, code, componentType, codeType)
                    }
                >
                    <Edit />
                </div>
                <div
                    data-for={`${id}-actions`}
                    data-tip="Duplicate Code"
                    className={classes.action}
                    onClick={handleDuplicate}
                >
                    <Duplicate />
                </div>
                <div
                    data-for={`${id}-actions`}
                    data-tip="Delete Code"
                    className={classes.action}
                    onClick={() => toggleDeleteOpen(true)}
                >
                    <Delete />
                </div>
                <Tooltip delay id={`${id}-actions`} />
                <Dialog open={!!deleteOpen}>
                    <CloseDialog closeActions={() => toggleDeleteOpen(false)} />
                    <div className={classes.dialog}>
                        <h3>
                            Are you sure you want to delete{" "}
                            <strong>{name}</strong>?
                        </h3>
                        <div className={classes.footer}>
                            <Button
                                onClick={() => toggleDeleteOpen(false)}
                                type="hollow"
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={() => {
                                    deleteCode();
                                    toggleDeleteOpen(false);
                                }}
                                type="red"
                            >
                                Delete
                            </Button>
                        </div>
                    </div>
                </Dialog>
            </div>
        );
    },
    isEqual
);

export default CodeActions;
