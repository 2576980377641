import React, { useEffect, useState } from "react";
import classes from "./CostInput.module.scss";

// get cost table values from state and act on them
// read information from the 'upgrades' section in the form

const CostInput = ({ value, onChange, setValue, hasCost }) => {
    const [displayValue, changeDisplayValue] = useState(value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","));
    const [focused, toggleFocused] = useState(false);

    const handleSetValue = () => {
        if (isNaN(setValue)) {
            // Sometimes setValue is an object
            // Add decimals to field when display value is 0
            changeDisplayValue(parseFloat("0.00").toFixed(2));
        } else {
            const newDisplayValue = parseFloat(parseFloat(setValue)).toFixed(2);

            changeDisplayValue(newDisplayValue);
            onChange(parseFloat(parseFloat(setValue).toFixed(2)));
        }
    };

    const handleBlur = (e) => {
        const updatedValue = parseFloat(`${displayValue}`.replace(/[^0-9.]/g, "")).toFixed(2);
        const formattedValue = `${updatedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        changeDisplayValue(formattedValue);
        toggleFocused(false);
        onChange(parseFloat(updatedValue));
    };

    const handleChange = (e) => {
        changeDisplayValue(e.target.value.replace(/[A-Za-z!@#$%^&*()]/g, ""));
    };

    useEffect(() => {
        // If value is manually set, do shtuff
        if ((setValue || setValue === 0) && !focused) {
            handleSetValue();
        }
    }, [setValue]);

    return (
        <input
            className={classes.costInput}
            value={displayValue}
            onChange={handleChange}
            onBlur={handleBlur}
            type="text"
            disabled={hasCost}
        />
    );
};

export default CostInput;
