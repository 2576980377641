import React, { useCallback, useState } from "react";
import classes from "./style.module.scss";
import classNames from "classnames";
import { saveBase64File } from "utils/reportGen";

import SectionHeading from "components/SectionHeading";
import MultiUploadInput from 'components/Input/MultiUploadInput';
import Button from "components/Button";
import InputWithUnits from "components/Input/InputWithUnits";
import LoadingDots from "components/LoadingDots";
import { PageRestricted } from "features/Navigation/PageNotFound";

const ReportGenerator = ({
    generatePdf,
    uid,
    ENVIROCENTRE_REPORT
}) => {
    const [files, setFiles] = useState([]);
    const [netZero, setNetZero] = useState(0);
    const [generating, setGenerating] = useState(false);
    const [complete, setComplete] = useState(false);
    const [error, setError] = useState("");

    const acceptedNames = ["_easy", "_deep", "_netzero"];
    const namingMatches = Array.from(files).every(file => {
        return acceptedNames.some(name => file.name.includes(name))
    })

    const handleUpload = useCallback(async (data) => {
        setError("");
        setComplete(false);

        if (!data) {
            return;
        }

        setComplete(false);
        setFiles(data);
    }, []);

    const generate = useCallback(async () => {
        const formData = new FormData();

        if (files.length !== 3 || !namingMatches) {
            setError("You must select 3 .h2k files name as described in the instructions.");
            return;
        }

        setGenerating(true);
        setError("");
        setComplete(false);
        
        let outFileName = "report.pdf"
        Array.from(files).map(file => {
            formData.append("submit_file", file, file.name);
            if (file.name.includes("_easy.h2k")) {
                outFileName = `${file.name.split("_easy.h2k")[0]}.pdf`
            }
        });

        formData.append("metadata", JSON.stringify({ uid }));
        formData.append("inputFields", JSON.stringify({ "netzero": isNaN(netZero) ? 0 : netZero }));

        const pdfBytes = await generatePdf(formData);

        // TODO: error handling
        saveBase64File(outFileName, pdfBytes);
        setGenerating(false);
        setComplete(true);
    }, [files, generatePdf, uid, netZero, namingMatches]);

    const infoBarClasses = classNames(classes.infoBar, {
        [classes.complete]:complete,
        [classes.generating]:generating,
        [classes.error]:error,
    });

    const infoBarTextMap = {
        complete: "Your report is complete! You should see it in your downloads folder.",
        generating: "Your report is generating. Please do not reload this page.",
        error:error,
    };

    const infoBarText = generating ? infoBarTextMap["generating"] : error ? infoBarTextMap["error"] : complete ? infoBarTextMap["complete"] : ""

    if (!ENVIROCENTRE_REPORT) {
        return <PageRestricted />
    }

    return (                                                                                                   
        <div>
            <SectionHeading
                title="Future Homes Ottawa NetZero Pathways Report Generator"
                subtitle={<span style={{ maxWidth:"60rem", display:"inline-block" }}>Generate your NetZero Pathways Report by uploading three completed .h2k files and entering the building envelope thermal performance improvement (%) for the NetZero pathway.</span>}
            />
            <div className={classes.contentWrapper}>
                <div className={classes.uploadArea}>
                    <h3>Generate Report</h3>
                    <div className={classes.fileUpload}>
                        <MultiUploadInput
                            name="fileUpload"
                            id="fileUpload"
                            label="File Upload"
                            text="Please begin by uploading your three .h2k files"
                            handleUpload={handleUpload}
                            accept=".h2k,.xml"
                            disabled={generating}
                        />
                    </div>
                    <div className={classes.netZeroInput}>
                        <InputWithUnits
                            label="NetZero Building Envelope Thermal Performance Improvement (%)"
                            type="number"
                            input={{
                                value:netZero,
                                onChange:(value) => setNetZero(parseInt(value)),
                                name:"netZeroImprovement"
                            }}
                            meta={{
                                invalid:false,
                                error:"",
                            }}
                            units={{
                                base:"%",
                                options:"%",
                                selected:"%",
                                unitType:'fraction',
                                accessor:`netZeroImprovement`
                            }}
                        />
                    </div>
                    <Button onClick={generate} disabled={generating}>
                        {generating ? "Generating..." : "Generate"}
                    </Button>
                    {infoBarText && (
                        <div className={infoBarClasses}>
                            <p>
                                {generating && <LoadingDots />}
                                {infoBarText}
                            </p>
                        </div>
                    )}
                </div>
                <div className={classes.instructionsArea}>
                    <h3>Instructions</h3>
                    <p>Select three fully executed .h2k files for upload, using the following file naming convention:</p>
                    <ul>
                        <li>Easy Upgrades: "filename<strong>_easy.h2k</strong>"</li>
                        <li>Deep Upgrades: "filename<strong>_deep.h2k</strong>"</li>
                        <li>NetZero Upgrades: "filename<strong>_netzero.h2k</strong>"</li>
                    </ul>
                    <p>All proposed upgrades must be modelled as HOT2000 upgrades in each file. The "current" house is based on the baseline house in the Easy Upgrades file.</p>
                    <p>Since baseload upgrades (lighting, appliances, etc.) cannot be modelled as upgraded components, these must be specified under Reduced Operating Conditions within the Program section of the file.</p>
                    <p>The building envelope thermal performance improvement value is only applicable for the NetZero case and requires a special calculation procedure. Use the input field above to enter the calculated value as a percentage. The number input will be entered into the respective table row under the NetZero column, while the current, easy, and deep retrofit columns will be labelled as N/A.</p>
                    <p>Once your files have been selected, and the building envelope thermal performance improvement value has been input, click Generate Report to process the files and download your report.</p>
                </div>
            </div>
        </div>
    )
};


export default ReportGenerator;