import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import EvaluationProgram from "./";

const mapStateToProps = ({ form }, { accessor, formName = "model" }) => {
    const selector = formValueSelector(formName);
    const modelSelector = formValueSelector("model");

    const isClothesWasherInstalled = selector({ form }, `${accessor}.clothesWasher.isInstalled`);
    const isDishWasherInstalled = selector({ form }, `${accessor}.dishWasher.isInstalled`);

    const { numberPerOccupantPerDay: numberPerOccupantPerDayValue } =
        selector({ form }, `${accessor}.bathroomFaucets`) || {};
    const {
        id: clothesWasherRatedValuesId,
        waterConsumptionPerCycle: clothesWasherWaterValue,
        annualEnergyConsumption: clothesWasherEnergyValue,
    } = selector({ form }, `${accessor}.clothesWasher.ratedValues`) || {};
    const {
        id: dishWasherRatedValuesId,
        waterConsumptionPerCycle: dishWasherWaterValue,
        annualEnergyConsumption: dishWasherEnergyValue,
    } = selector({ form }, `${accessor}.dishWasher.ratedValues`) || {};

    const hotWaterTempUnits = selector({ form }, `${accessor}.hotWaterTemp_u`);
    const showerDurationUnits = selector({ form }, `${accessor}.shower.averageDuration_u`);
    const clothesWasherWaterUnits = selector(
        { form },
        `${accessor}.clothesWasher.ratedValues.waterConsumptionPerCycle_u`
    );
    const clothesWasherEnergyUnits = selector(
        { form },
        `${accessor}.clothesWasher.ratedValues.annualEnergyConsumption_u`
    );
    const dishWasherWaterUnits = selector({ form }, `${accessor}.dishWasher.ratedValues.waterConsumptionPerCycle_u`);
    const dishWasherEnergyUnits = selector({ form }, `${accessor}.dishWasher.ratedValues.annualEnergyConsumption_u`);
    const otherHotWaterUnits = selector({ form }, `${accessor}.otherHotWaterUse_u`);
    const modelUnits = modelSelector({ form }, `modelData.uiSettings.primaryUnits`) || {};

    return {
        accessor,
        isClothesWasherInstalled,
        isDishWasherInstalled,
        numberPerOccupantPerDayValue,
        clothesWasherRatedValuesId,
        clothesWasherWaterValue,
        clothesWasherEnergyValue,
        dishWasherWaterValue,
        dishWasherEnergyValue,
        hotWaterTempUnits,
        showerDurationUnits,
        clothesWasherWaterUnits,
        clothesWasherEnergyUnits,
        dishWasherRatedValuesId,
        dishWasherWaterUnits,
        dishWasherEnergyUnits,
        otherHotWaterUnits,
        modelUnits,
    };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EvaluationProgram);
