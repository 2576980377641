import { connect } from 'react-redux';
import Actions from './';
import { actions } from 'features/Model/Drawing/_ducks';

const {
    setScale,
} = actions;

const mapStateToProps = ({
    model:{
        modelData:{
            uiSettings:{
                primaryUnits
            }={}
        }={},
    },
    drawing:{
        action:{
            id:actionId,
            meta={}
        }={},
        imageData={},
        activeImage=''
    }
}) => {
    const { scale={} } = imageData[activeImage] || {};
    return {
        units: primaryUnits === 'Metric' ? 'm' : 'ft',
        actionId,
        meta,
        scale
    }
};

const mapDispatchToProps = dispatch => ({
    setScale:({image, scale}) =>  dispatch(setScale({image, scale})),
});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Actions);