import { connect } from 'react-redux';
import TopBar from './';

const mapStateToProps = ({
    user:{
        name='',
        modelDir:{
            models={}
        }={}
    },
    model:{
        modelId
    }
}) => {
    const { [modelId]:currentModel={} } = models;
    const { name:modelName } = currentModel;

    return {
        name,
        modelName
    }
};

const mapDispatchToProps = () => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TopBar);