import { isEmpty } from "lodash";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import General from "./";

const mapStateToProps = ({ form, user: { email: userEmail = "" } = {} }, { accessor }) => {
    const selector = formValueSelector("model");
    const isNetZeroReady = selector({ form }, `${accessor}.netZeroHome.isNetZeroReady`) || false;
    const {
        ersFileNumber = "",
        siteAddress = "",
        city = "",
        provinceTerr = "",
    } = selector({ form }, `${accessor}.siteInformation`) || {};

    const {
        company: eaCompany = "",
        name: eaName = "",
        email: eaEmail = "",
        phone: eaPhone = "",
    } = selector({ form }, `${accessor}.participant.ea`) || {};
    const {
        company: renoCompany = "",
        name: renoName = "",
        email: renoEmail = "",
        phone: renoPhone = "",
    } = selector({ form }, `${accessor}.participant.renovator`) || {};

    const {
        file: {
            fileID = "",
            userTelephone = "", //EA phone
            userCompany = "", //EA company
            userNameEnteredBy = "", //EA Name
        } = {},
        client: { clientCity = "", clientStreetAddress = "", clientProvince = "" } = {},
    } = selector({ form }, `modelData.general`) || {};

    const {
        renovatorName = "",
        renovatorPhone = "",
        renovatorEmail = "",
        renovatorCompany = "",
    } = selector({ form }, `modelData.chbaNz.file`) || {};

    const wbCompare = {
        fileID: { modelData: fileID, wbData: ersFileNumber },
        userTelephone: { modelData: userTelephone, wbData: eaPhone },
        userCompany: { modelData: userCompany, wbData: eaCompany },
        userEmail: { modelData: userEmail, wbData: eaEmail },
        userNameEnteredBy: { modelData: userNameEnteredBy, wbData: eaName },
        renovatorName: { modelData: renovatorName, wbData: renoName },
        renovatorPhone: { modelData: renovatorPhone, wbData: renoPhone },
        renovatorEmail: { modelData: renovatorEmail, wbData: renoEmail },
        renovatorCompany: { modelData: renovatorCompany, wbData: renoCompany },
        clientCity: { modelData: clientCity, wbData: city },
        clientStreetAddress: { modelData: clientStreetAddress, wbData: siteAddress },
        clientProvince: { modelData: clientProvince, wbData: provinceTerr },
    };

    return {
        accessor,
        isNetZeroReady,
        wbCompare,
    };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(General);
