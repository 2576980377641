import { connect } from "react-redux";

import { actions as modelActions } from "features/Model/_ducks";
import { actions as enclosureActions } from "features/Model/Enclosure/_ducks";
import { actions as userActions } from "store/users";
import { actions as resultsActions } from "features/Model/Review/Results/_ducks";
import { actions as upgradeActions } from "features/Model/Upgrades/_ducks";
import { clearDrawingData } from "features/Model/DrawingNew/_ducks/actions";

import { createFolder, deleteFolder, renameFolder, moveFolder, batchFolderRemove } from "store/folders/thunk";
import { fetchDrawingData } from "features/Model/DrawingNew/_ducks/thunk";

import Dashboard from "./";
import { requestVoltaSnapOrganization } from "utils/fileSharing/api";

const {
    clearModelData,
    fetchModelData,
    modelLoading,
    setExportErrors,
    deleteModel,
    moveModel,
    duplicateModel,
    batchDeleteModels,
    removeModelFromDashboard,
} = modelActions;

const { fetchUserDir, removeFromDir, fetchUserOrganization, saveUserMeta } = userActions;

const { clearEnclosureState } = enclosureActions;
const { resetResults } = resultsActions;
const { clearUpgradeData } = upgradeActions;

const mapStateToProps = ({
    user: {
        uid,
        name = "",
        email = "",
        modelDir = {},
        error,
        modelDirLoading = false,
        organization = {},
        userMeta: { organizationRequested = false } = {},
    },
    folder: { isLoading },
    model: { loading },
}) => ({
    uid,
    name,
    email,
    modelDir,
    error,
    modelDirLoading,
    folderLoading: isLoading || modelDirLoading,
    modelLoading: loading,
    organization,
    organizationRequested,
    requestVoltaSnapOrganization,
});

const mapDispatchToProps = (dispatch, { history }) => ({
    fetchUserDir: (uid, skipLoading) => dispatch(fetchUserDir(uid, skipLoading)),
    fetchUserOrganization: (uid, orgId) => dispatch(fetchUserOrganization(uid, orgId)),
    clearModelData: () => dispatch(clearModelData()),
    selectModel: async (modelId) => {
        await Promise.all([
            dispatch(clearModelData()),
            dispatch(clearDrawingData()),
            dispatch(resetResults()),
            dispatch(clearUpgradeData()),
            dispatch(clearEnclosureState()),
            dispatch(setExportErrors([])),
            dispatch(modelLoading()),
            dispatch(fetchModelData(modelId)),
            dispatch(fetchDrawingData(modelId)),
        ]).then(() => history.push(`/models/${modelId}`));
    },
    deleteModel: async ({ modelId, uid }) => {
        await dispatch(deleteModel({ modelId, uid }));
        return dispatch(removeFromDir(modelId));
    },
    removeSharedModelFromDir: async ({ modelId, uid }) => {
        await dispatch(removeModelFromDashboard({ modelId, uid }));
        return dispatch(removeFromDir(modelId));
    },
    createFolder: async (uid, newFolderName, parentFolderId) =>
        dispatch(createFolder(uid, newFolderName, parentFolderId)),
    deleteFolder: async (folderId, uid) => dispatch(deleteFolder(folderId, uid)),
    renameFolder: async (folderId, uid, newFolderName) => dispatch(renameFolder(folderId, uid, newFolderName)),
    moveFolder: async (folderId, uid, moveToId, skipLoading) =>
        dispatch(moveFolder(folderId, uid, moveToId, skipLoading)),
    moveModel: async (modelId, uid, folderId, skipLoading) => dispatch(moveModel(modelId, uid, folderId, skipLoading)),
    duplicateModel: async (modelId, uid, folderId, duplicateName) =>
        dispatch(duplicateModel(modelId, uid, folderId, duplicateName)),
    batchFolderRemove: async (foldersToDelete, uid) => dispatch(batchFolderRemove(foldersToDelete, uid)),
    batchDeleteModels: async (modelsToDeleteIds, uid) => dispatch(batchDeleteModels(modelsToDeleteIds, uid)),
    saveUserMeta: ({ uid, userMeta }) => dispatch(saveUserMeta({ uid, userMeta })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
