import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import ExpFloor from './';

const mapStateToProps = ({
    form,
    model:{
       newestComponents=[],
       recentDuplicates=[],
       contentOpen=false,
    }={} 
}, { 
   id 
}) => {
    const accessor = `modelData.components.expFloor.${id}`;

    const selector = formValueSelector('model');
    const label = selector({form}, `${accessor}.label`) || '';
    const lengthDrawing = selector({form}, `${accessor}.measurements.length_drawingRef`) || '';
    const { component:drawingComponent='' } = selector({form}, `${accessor}.drawing`) || {};

    const drawingLines = [
        ...lengthDrawing ? [ lengthDrawing ] : []
    ];

    return {
        componentId:id,
        accessor,
        label,
        isNew:newestComponents.includes(id),
        isRecentDuplicate:recentDuplicates.includes(id),
        isDrawingComponent:!!drawingComponent,
        drawingLines,
        contentOpen,
    };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ExpFloor);