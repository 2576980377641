import React, { useCallback, useEffect, useState } from "react";
import classes from "./style.module.scss";
import sharedClasses from "features/Model/sharedStyles.module.scss";

import { Field } from "redux-form";
import { getOptions } from "utils/fields";
import { getValidation, getDecimalPlaces } from "utils/fieldValidation";
import { getBaseUnits, getUnitOptions } from "utils/fields";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import InputWithUnits from "components/Input/InputWithUnits";
import Checkbox from "components/Input/Checkbox";
import Select from "components/Input/Select";
import Accordion from "components/Accordion";
import NonH2kParam from "assets/images/icons/JSX/NonH2kParam";

import Button from "components/Button";
import Details from "./Details";
import TabHeader from "components/TabHeader";
import DetailsDrawer from "components/DetailsDrawer";

import { mixpanel } from "components/Mixpanel";

import TestData from "./TestData/container";

import airTestDataTemplate from "./templates/airTestData.json";
import { isEmpty } from "lodash";
import { toHaveFormValues } from "@testing-library/jest-dom/dist/to-have-form-values";

const airtightnessTypeValidation = getValidation("airTightnessType");
const ach50Validation = getValidation("airChangeRate50Pa");
const eqLeakageAreaValidation = getValidation("eqLeakageArea");
const testPressureValidation = getValidation("blowerTestPressure");
const siteTerrainValidation = getValidation("buildingSiteTerrain");
const weatherTerrainValidation = getValidation("weatherStationTerrain");
const depresStatusValidation = getValidation("depressurizationTestStatus");
const depresResultValidation = getValidation("depressurizationTestResult");
const localShieldingFlueValidation = getValidation("localShieldingFlue");
const localShieldingWallsValidation = getValidation("localShieldingWalls");
const anemometerValidation = getValidation("weatherStationAnemometer");
const ceilingLeakageValidation = getValidation("leakageFractionsCeilings");
const wallsLeakageValidation = getValidation("leakageFractionsWalls");
const floorsLeakageValidation = getValidation("leakageFractionsFloors");
const windowTightnessValidation = getValidation("windowAirTightness");
const commonFloorsValidation = getValidation("commonAreaFloors");
const commonWallsValidation = getValidation("commonAreaWalls");
const commonCeilingsValidation = getValidation("commonAreaCeilings");
const commonTotalValidation = getValidation("commonAreaTotal");

//TODO: Eq leakage area dependencies and calculations
const calcEla = (houseVolume, ach) => {
    const slope = 0.344224 * houseVolume - 0.0054;
    const intercept = 0.043961 * houseVolume + 0.026977;

    return parseFloat((slope * ach + intercept).toFixed(2));
};

export default ({
    history,
    accessor,
    h2kMinor,
    modelUnits,
    achValue,
    eqLeakageValue,
    eqLeakageUnits,
    exhaustDeviceTestUnits,
    anemometerUnits,
    isEqLeakageAreaCalculated,
    pressureId,
    airTightnessTypeId,
    exhaustTestId,
    exhaustTestResult,
    isDefaultLeakage,
    change,
    windowTightnessId,
    windowTightnessValue,
    commonFloorsUnits,
    commonWallsUnits,
    commonCeilingsUnits,
    commonTotalUnits,
    totalCommonAreaValue,
    isMURB,
    houseVolume,
    isUpgrade = false,
    contentOpen,
    isUpgradeLibrary,
    isAirTestEnabled,
    airTestDataEmpty,
    normalizedAirLeakageRate = 0,
    achFromNlr = null,
    airtightnessInputType,
    normalizedLeakageArea = 0,
    buildingSurfaceArea = 0,
    buildingSurfaceAreaUnits,
    uploadedData,
    normalizedAirLeakageRateUnits,
    normalizedLeakageAreaUnits,
}) => {
    const [open, toggleOpen] = useState(false);
    const initialTab = window.location.search;
    const [activeTab, changeActiveTab] = useState(initialTab);
    // const [open,toggleopen] = useState(false)

    const handleAchTypeChange = useCallback(
        ({ id }) => {
            const airtightnessValue = {
                0: achValue,
                1: 10.35,
                2: 4.55,
                3: 3.57,
                4: 1.5,
            }[id];
            change(`${accessor}.blowerTest.isCgsbTest`, id !== 0);
            change(`${accessor}.blowerTest.airChangeRate50Pa`, airtightnessValue);

            if (houseVolume !== 0 && airtightnessValue !== 0) {
                change(`${accessor}.blowerTest.eqLeakageArea`, calcEla(houseVolume, airtightnessValue));
            }

            change(`${accessor}.airtightnessInputType`, "ach");
        },
        [airTightnessTypeId, achValue, houseVolume]
    );

    const handleAchChange = useCallback(() => {
        if (houseVolume !== 0 && achValue !== 0) {
            change(`${accessor}.blowerTest.eqLeakageArea`, calcEla(houseVolume, achValue));
        }
    }, [achValue, houseVolume]);

    //Check for fixed airtightness or blower door values
    const isBlowerTestValues = airTightnessTypeId === 0;

    useEffect(() => {
        if (exhaustTestResult == null || isNaN(exhaustTestResult)) {
            change(`${accessor}.exhaustDevicesTest.result`, 0);
        }
    }, [exhaustTestResult]);

    const handleIsEqCalculatedChange = useCallback(
        (e) => {
            if (e.target.value && pressureId == null) {
                change(`${accessor}.blowerTest.pressure`, { id: 0 });
            }

            if (e.target.value == "false") {
                //e.target.value is the opposite of the form value for is calculated, and it's a string
                change(`${accessor}.blowerTest.eqLeakageArea`, calcEla(houseVolume, achValue));
            }
        },
        [houseVolume, achValue, pressureId, accessor]
    );

    const handleTestDataEnabledToggle = useCallback(
        (e) => {
            //set all disabled fields to their appropriate values,
            //pre populate
            if (!isAirTestEnabled && airTestDataEmpty && !isUpgrade) {
                change("modelData.airTightness.testData", airTestDataTemplate);
            }
        },
        [isAirTestEnabled, airTestDataEmpty, isUpgrade]
    );

    const handleInputTypeChange = useCallback(
        (value) => {
            if (value === "nlr" && achFromNlr !== null && !isNaN(achFromNlr)) {
                change(`${accessor}.blowerTest.airChangeRate50Pa`, achFromNlr);
                change(`${accessor}.blowerTest.isEqLeakageAreaCalculated`, true);
            }
        },
        [achFromNlr, accessor]
    );

    return (
        <>
            {!isUpgrade && (
                <TabHeader
                    title="Airtightness"
                    description="Information about the building's air infiltration data."
                    detailsTrigger={() => toggleOpen(true)}
                    detailsText="Airtightness Details"
                />
            )}
            <div className={`${!isUpgrade && sharedClasses.section} ${classes.airtightness}`}>
                <DetailsDrawer open={open} close={() => toggleOpen(false)} title="Airtightness Tab Details">
                    <Details />
                    <div className={classes.buttons}>
                        <Button large type="hollow" onClick={() => toggleOpen(false)}>
                            Close
                        </Button>
                    </div>
                </DetailsDrawer>
                <h3 style={{ marginBottom: "1rem" }}>Blower Door Test</h3>
                <InputRow gridTemplate={isUpgrade ? "1fr 1fr 1fr" : "1fr 1fr 1fr 1fr"}>
                    <Field
                        component={Select}
                        name={`${accessor}.testType`}
                        options={getOptions({ fieldKey: "airTightnessType" })}
                        label="Air Tightness Type"
                        placeholder="Choose Air Tightness Type"
                        validate={airtightnessTypeValidation}
                        info="Defines whether blower door test results will be input, or whether a pre-defined ACH50 should be applied. Note that the test type is always assumed to be CGSB."
                        onChange={handleAchTypeChange}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.blowerTest.airChangeRate50Pa`}
                        label="Air Change Rate"
                        placeholder={0.0}
                        disabled={!isBlowerTestValues || isAirTestEnabled}
                        validate={ach50Validation}
                        setTouched={true}
                        decimals={getDecimalPlaces("airChangeRate50Pa")}
                        change={change}
                        setValue={airtightnessInputType === "nlr" ? achFromNlr : achValue}
                        units={{
                            base: getBaseUnits("airChangeRate50Pa", modelUnits),
                            options: getUnitOptions("airtightness"),
                            selected: "ACH50",
                            unitType: "airtightness",
                            accessor: `${accessor}.blowerTest.airChangeRate50Pa_u`,
                        }}
                        onChange={handleAchChange}
                        info={
                            isAirTestEnabled
                                ? "When air leakage test data is provided, this value will be calculated and updated every time a HOT2000 simulation is run."
                                : null
                        }
                    />
                    <Field
                        component={Select}
                        name={`${accessor}.blowerTest.isCgsbTest`}
                        options={[
                            {
                                label: "CGSB Test",
                                value: true,
                            },
                            {
                                label: "As operated",
                                value: false,
                            },
                        ]}
                        label="Test Type"
                        placeholder="Choose Test Type"
                        disabled={airTightnessTypeId !== 0 || isAirTestEnabled}
                        info={isAirTestEnabled && "Controlled by air leakage test data."}
                    />
                    {!isUpgrade && (
                        // <div className={classes.altdCheck}>
                        <Field
                            component={Checkbox}
                            name={`modelData.airTightness.testDataEnabled`}
                            label="Air Leakage Test Data"
                            large
                            className={classes.bottomCheckBox}
                            type="checkbox"
                            disabled={airTightnessTypeId !== 0}
                            onChange={handleTestDataEnabledToggle}
                        />
                        // </div>
                    )}
                </InputRow>
                <InputRow gridTemplate={isUpgrade ? "1fr 1fr 1fr" : "1fr 1fr 1fr 1fr"}>
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.blowerTest.eqLeakageArea`}
                        label="Equivalent Leakage Area"
                        placeholder={0.0}
                        disabled={isEqLeakageAreaCalculated || !isBlowerTestValues || isAirTestEnabled}
                        validate={eqLeakageAreaValidation}
                        setValue={eqLeakageValue}
                        setTouched={true}
                        decimals={getDecimalPlaces("eqLeakageArea")}
                        change={change}
                        units={{
                            base: getBaseUnits("eqLeakageArea", modelUnits),
                            options: getUnitOptions("area"),
                            selected: eqLeakageUnits,
                            unitType: "area",
                            accessor: `${accessor}.blowerTest.eqLeakageArea_u`,
                        }}
                        info={
                            isUpgradeLibrary
                                ? "Placeholder volume (21,000 ft3) used for ELA calculation."
                                : isAirTestEnabled
                                ? "When air leakage test data is provided, this value will be calculated and updated every time a HOT2000 simulation is run."
                                : null
                        }
                    />
                    <Field
                        component={Select}
                        name={`${accessor}.blowerTest.pressure`}
                        options={getOptions({ fieldKey: "blowerTestPressure" })}
                        label="Equivalent Leakage Area Pressure"
                        placeholder="Choose Pressure"
                        validate={testPressureValidation}
                        disabled={isEqLeakageAreaCalculated || !isBlowerTestValues || isAirTestEnabled}
                    />
                    <Field
                        component={Checkbox}
                        name={`${accessor}.blowerTest.isEqLeakageAreaCalculated`}
                        label="Calculate Equivalent Leakage Area"
                        large
                        className={classes.bottomCheckBox}
                        type="checkbox"
                        onChange={handleIsEqCalculatedChange}
                        disabled={isAirTestEnabled || airtightnessInputType === "nlr"}
                    />
                </InputRow>
                {h2kMinor == 10 && (
                    <InputRow gridTemplate="1fr 6fr">
                        <Field
                            component={Checkbox}
                            name={`${accessor}.blowerTest.unguarded`}
                            label="Is Unguarded"
                            large
                            className={classes.bottomCheckBox}
                            type="checkbox"
                        />
                    </InputRow>
                )}
                {h2kMinor >= 11 && (
                    <InputRow gridTemplate="1fr 6fr">
                        <Field
                            component={Checkbox}
                            name={`${accessor}.blowerTest.guarded`}
                            label="Is Guarded"
                            large
                            className={classes.bottomCheckBox}
                            type="checkbox"
                        />
                    </InputRow>
                )}
                <InputRow gridTemplate="1fr 1fr 1fr 1fr" style={{ alignItems: "flex-end", marginTop: "1.5rem" }}>
                    <Field
                        component={Select}
                        name={`${accessor}.airtightnessInputType`}
                        options={[
                            {
                                label: "Default (ACH)",
                                value: "ach",
                            },
                            {
                                label: "NLR Target",
                                value: "nlr",
                            },
                        ]}
                        label="Airtightness Input Type"
                        placeholder="Default (ACH)"
                        disabled={airTightnessTypeId !== 0 || isAirTestEnabled}
                        info={
                            "Use this field to specify an NLR target and calculate the resulting ACH. Ensure ELA is also calculated. HOT2000 will only evaluate the file based on the resulting ACH and ELA value."
                        }
                        onChange={handleInputTypeChange}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.normalizedAirLeakageRate`}
                        label="NLR"
                        placeholder={0.0}
                        disabled={airtightnessInputType !== "nlr" || airTightnessTypeId !== 0 || isAirTestEnabled}
                        decimals={2}
                        change={change}
                        setValue={airtightnessInputType !== "nlr" ? normalizedAirLeakageRate : null}
                        units={{
                            base: getBaseUnits("normalizedAirLeakageRate", modelUnits),
                            options: getUnitOptions("normAirLeakage"),
                            selected: normalizedAirLeakageRateUnits,
                            unitType: "normAirLeakage",
                            accessor: `${accessor}.normalizedAirLeakageRate_u`,
                        }}
                        info={"Normalized Leakage Rate (NLR @50 Pa)"}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.normalizedLeakageArea`}
                        label="NLA"
                        placeholder={0.0}
                        disabled={true}
                        decimals={2}
                        change={change}
                        setValue={normalizedLeakageArea}
                        units={{
                            base: getBaseUnits("normalizedLeakageArea", modelUnits),
                            options: getUnitOptions("normLeakageArea"),
                            selected: normalizedLeakageAreaUnits,
                            unitType: "normLeakageArea",
                            accessor: `${accessor}.normalizedLeakageArea_u`,
                        }}
                        info="Normalized Leakage Area. Based on the Equivalent Leakage Area Pressure entered in the field above (default 10Pa)."
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.buildingSurfaceArea`}
                        label="Building Enclosure Area"
                        placeholder={0.0}
                        disabled={true}
                        decimals={2}
                        change={change}
                        setValue={buildingSurfaceArea}
                        units={{
                            base: getBaseUnits("commonAreaWalls", modelUnits),
                            options: ["m2", "ft2"],
                            selected: buildingSurfaceAreaUnits,
                            unitType: "area",
                            accessor: `${accessor}.buildingSurfaceArea_u`,
                        }}
                        info="Based on HOT2000 component areas plus common areas defined in MURB Specifications below, if applicable."
                    />
                </InputRow>
                <h3 style={{ marginBottom: "1rem", marginTop: "2.25rem" }}>Exhaust Devices Test</h3>
                <InputRow gridTemplate="1fr 1fr 1fr">
                    <Field
                        component={Select}
                        name={`${accessor}.exhaustDevicesTest`}
                        options={[
                            {
                                label: "Not applicable",
                                value: {
                                    id: 0,
                                    result: 0,
                                },
                            },
                            {
                                label: "Not possible to perform test",
                                value: {
                                    id: 1,
                                    result: 0,
                                },
                            },
                            {
                                label: "Test result",
                                value: {
                                    id: 2,
                                    result: exhaustTestResult,
                                },
                            },
                        ]}
                        label="Depressurization Test Status"
                        placeholder="Choose Test Status"
                        validate={depresStatusValidation}
                        onChange={() => {
                            change(`${accessor}.exhaustDevicesTest.result`, 0);
                        }}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.exhaustDevicesTest.result`}
                        label="Test Result"
                        placeholder={0.0}
                        disabled={exhaustTestId !== 2}
                        validate={depresResultValidation}
                        setValue={exhaustTestResult}
                        setTouched={true}
                        decimals={getDecimalPlaces("depressurizationTestResult")}
                        change={change}
                        units={{
                            base: getBaseUnits("depressurizationTestResult", modelUnits),
                            options: getUnitOptions("pressure"),
                            selected: exhaustDeviceTestUnits,
                            unitType: "pressure",
                            accessor: `${accessor}.exhaustDevicesTest_u`,
                        }}
                    />
                </InputRow>
                {!isUpgrade && (
                    <>
                        <Accordion
                            className={sharedClasses.accordion}
                            // heading={<span className={sharedClasses.heading}>Other Factors</span>}
                            heading={
                                <div className={sharedClasses.accordionIconHeading}>
                                    Other Factors <NonH2kParam />
                                </div>
                            }
                            large
                            forceOpen={contentOpen}
                        >
                            <p>
                                <strong>
                                    For the purposes of EnerGuide compliance, the values in this section should not be
                                    altered.
                                </strong>
                            </p>
                            <h3 style={{ marginBottom: "1rem", marginTop: "1rem" }}>Window Tightness</h3>
                            <InputRow gridTemplate="31.85% 23.25%">
                                <Field
                                    component={Select}
                                    name="modelData.specifications.windowTightness"
                                    options={[
                                        ...getOptions({ fieldKey: "windowAirTightness" }),
                                        {
                                            label: "User Specified",
                                            value: {
                                                id: 4,
                                                value: windowTightnessValue, //need to get rValue for this to match
                                            },
                                        },
                                    ]}
                                    label="Window Airtightness"
                                    placeholder="Choose Window Airtightness"
                                    validate={windowTightnessValidation}
                                    info={
                                        "The airtightness entered here is used solely for the calculation of window ER values for documentation purposes only and is not used in heat loss calculations."
                                    }
                                />
                                <Field
                                    component={InputWithUnits}
                                    type="number"
                                    name="modelData.specifications.windowTightness.value"
                                    label="Value"
                                    placeholder={0.0}
                                    disabled={windowTightnessId !== 4}
                                    validate={windowTightnessValidation}
                                    setTouched={true}
                                    decimals={getDecimalPlaces("windowAirTightness")}
                                    change={change}
                                    setValue={windowTightnessId !== 4 ? windowTightnessValue : null}
                                    units={{
                                        base: getBaseUnits("windowAirTightness", modelUnits),
                                        options: getUnitOptions("windowTightness"),
                                        selected: ["L/s·m²"],
                                        unitType: "windowTightness",
                                        accessor: "modelData.specifications.windowTightness_u",
                                    }}
                                />
                            </InputRow>
                            <h4 style={{ marginBottom: "1rem", marginTop: "1rem" }}>Local Shielding</h4>
                            <InputRow gridTemplate="1fr 1fr 1fr">
                                <Field
                                    component={Select}
                                    name="modelData.airTightness.localShieldingWalls"
                                    options={getOptions({ fieldKey: "localShieldingWalls" })}
                                    label="Walls"
                                    placeholder="Choose Wall Shielding"
                                    validate={localShieldingWallsValidation}
                                    nonH2kParam="f280Helpful"
                                />
                                <Field
                                    component={Select}
                                    name="modelData.airTightness.localShieldingFlue"
                                    options={getOptions({ fieldKey: "localShieldingFlue" })}
                                    label="Flue"
                                    placeholder="Choose Flue Shielding"
                                    validate={localShieldingFlueValidation}
                                    nonH2kParam="f280Helpful"
                                />
                            </InputRow>
                            <h4>Building Site</h4>
                            <InputRow gridTemplate="1fr 1fr 1fr">
                                <Field
                                    component={Select}
                                    name="modelData.airTightness.buildingSiteTerrain"
                                    options={getOptions({ fieldKey: "buildingSiteTerrain" })}
                                    label="Terrain"
                                    placeholder="Choose Site Terrain"
                                    validate={siteTerrainValidation}
                                    nonH2kParam="f280Helpful"
                                />
                            </InputRow>
                            <h4>Weather Station</h4>
                            <InputRow gridTemplate="1fr 1fr 1fr">
                                <Field
                                    component={Select}
                                    name="modelData.airTightness.other.weatherStationTerrain"
                                    options={getOptions({ fieldKey: "weatherStationTerrain" })}
                                    label="Terrain"
                                    placeholder="Choose Weather Station Terrain"
                                    validate={weatherTerrainValidation}
                                />
                                <Field
                                    component={InputWithUnits}
                                    type="number"
                                    name="modelData.airTightness.other.anemometerHeight"
                                    label="Anemometer Height"
                                    placeholder="0.00"
                                    validate={anemometerValidation}
                                    decimals={getDecimalPlaces("weatherStationAnemometer")}
                                    change={change}
                                    units={{
                                        base: getBaseUnits("weatherStationAnemometer", modelUnits),
                                        options: getUnitOptions("length"),
                                        selected: anemometerUnits,
                                        unitType: "length",
                                        accessor: `modelData.airTightness.other.anemometerHeight_u`,
                                    }}
                                />
                            </InputRow>
                            <h4>Leakage Factors</h4>
                            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                                <Field
                                    component={Input}
                                    type="number"
                                    name="modelData.airTightness.other.leakageFractions.ceilings"
                                    label="Ceilings"
                                    placeholder={0.0}
                                    validate={ceilingLeakageValidation}
                                    decimals={getDecimalPlaces("leakageFractionsCeilings")}
                                    disabled={isDefaultLeakage}
                                    setValue={isDefaultLeakage ? 0.2 : null}
                                />
                                <Field
                                    component={Input}
                                    type="number"
                                    name="modelData.airTightness.other.leakageFractions.walls"
                                    label="Walls"
                                    placeholder={0.0}
                                    validate={wallsLeakageValidation}
                                    decimals={getDecimalPlaces("leakageFractionsWalls")}
                                    disabled={isDefaultLeakage}
                                    setValue={isDefaultLeakage ? 0.65 : null}
                                />
                                <Field
                                    component={Input}
                                    type="number"
                                    name="modelData.airTightness.other.leakageFractions.floors"
                                    label="Floors"
                                    placeholder={0.0}
                                    validate={floorsLeakageValidation}
                                    decimals={getDecimalPlaces("leakageFractionsFloors")}
                                    disabled={isDefaultLeakage}
                                    setValue={isDefaultLeakage ? 0.15 : null}
                                />
                                <Field
                                    component={Checkbox}
                                    name="modelData.airTightness.other.leakageFractions.useDefaults"
                                    label="Use Defaults"
                                    large
                                    className={classes.bottomCheckBox}
                                    type="checkbox"
                                />
                            </InputRow>
                        </Accordion>
                        <Accordion
                            className={sharedClasses.accordion}
                            bodyClassName={sharedClasses.testDataBody}
                            heading={<span className={sharedClasses.heading}>Air Leakage Test Data</span>}
                            large
                            forceClose={!isAirTestEnabled}
                            disabled={!isAirTestEnabled}
                        >
                            <TestData
                                accessor={accessor}
                                change={change}
                                history={history}
                                // tableData={loadedFile.data}
                            />
                        </Accordion>
                        <Accordion
                            className={sharedClasses.accordion}
                            heading={<span className={sharedClasses.heading}>MURB Specifications</span>}
                            large
                            disabled={!isMURB}
                            forceOpen={contentOpen && isMURB}
                        >
                            <h4>Areas of Common Surfaces</h4>
                            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                                <Field
                                    component={InputWithUnits}
                                    type="number"
                                    name="modelData.airTightness.commonSurfaces.floors"
                                    label="Floors"
                                    placeholder={0.0}
                                    disabled={!isMURB}
                                    validate={commonFloorsValidation}
                                    setTouched={true}
                                    decimals={getDecimalPlaces("commonAreaFloors")}
                                    change={change}
                                    units={{
                                        base: getBaseUnits("commonAreaFloors", modelUnits),
                                        options: getUnitOptions("area"),
                                        selected: commonFloorsUnits,
                                        unitType: "area",
                                        accessor: "modelData.airTightness.commonSurfaces.floors_u",
                                    }}
                                />
                                <Field
                                    component={InputWithUnits}
                                    type="number"
                                    name="modelData.airTightness.commonSurfaces.walls"
                                    label="Walls"
                                    placeholder={0.0}
                                    disabled={!isMURB}
                                    validate={commonWallsValidation}
                                    setTouched={true}
                                    decimals={getDecimalPlaces("commonAreaWalls")}
                                    change={change}
                                    units={{
                                        base: getBaseUnits("commonAreaWalls", modelUnits),
                                        options: getUnitOptions("area"),
                                        selected: commonWallsUnits,
                                        unitType: "area",
                                        accessor: "modelData.airTightness.commonSurfaces.walls_u",
                                    }}
                                />
                                <Field
                                    component={InputWithUnits}
                                    type="number"
                                    name="modelData.airTightness.commonSurfaces.ceilings"
                                    label="Ceilings"
                                    placeholder={0.0}
                                    disabled={!isMURB}
                                    validate={commonCeilingsValidation}
                                    setTouched={true}
                                    decimals={getDecimalPlaces("commonAreaCeilings")}
                                    change={change}
                                    units={{
                                        base: getBaseUnits("commonAreaCeilings", modelUnits),
                                        options: getUnitOptions("area"),
                                        selected: commonCeilingsUnits,
                                        unitType: "area",
                                        accessor: "modelData.airTightness.commonSurfaces.ceilings_u",
                                    }}
                                />
                                <Field
                                    component={InputWithUnits}
                                    type="number"
                                    name="modelData.airTightness.commonSurfaces.total"
                                    label="Total"
                                    placeholder={0.0}
                                    disabled={true}
                                    validate={commonTotalValidation}
                                    setTouched={true}
                                    decimals={getDecimalPlaces("commonAreaTotal")}
                                    setValue={totalCommonAreaValue}
                                    change={change}
                                    units={{
                                        base: getBaseUnits("commonAreaTotal", modelUnits),
                                        options: getUnitOptions("area"),
                                        selected: commonTotalUnits,
                                        unitType: "area",
                                        accessor: "modelData.airTightness.commonSurfaces.total_u",
                                    }}
                                />
                            </InputRow>
                        </Accordion>
                    </>
                )}
            </div>
        </>
    );
};
