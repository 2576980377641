import React, { useCallback, useEffect, useState } from "react";
import classes from "./style.module.scss";
import sharedClasses from "features/Model/sharedStyles.module.scss";
import { Field } from "redux-form";
import Button from "components/Button";
import Table from "components/Table";
import TableAirTest from "components/TableAirTest";
import Select from "components/Input/Select";
import TableSelect from "components/Input/TableSelect";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import TableInputWithUnits from "components/Input/TableInputWithUnits";
import AddBox from "components/AddBox";
import InputWithUnits from "components/Input/InputWithUnits";
import { getBaseUnits, getDefaultOption, getFirstOption, getUnitOptions } from "utils/fields";
import { getOptions } from "utils/fields";
import { getValidation, getDecimalPlaces } from "utils/fieldValidation";
import TableUnits from "components/Input/TableInputWithUnits/TableUnits";
import Dialog, { CloseDialog } from "components/Dialog";
import { IconButton } from "@material-ui/core";
import Delete from "assets/images/icons/JSX/Delete";
import { airTestZoneInfo } from "../../templates/airTestZoneInfo";
import { isEmpty } from "lodash";
import convertUnit from "utils/convertUnit";

const airTestFanTypeValidation = getValidation("airTestFanType");
const manometerValidation = getValidation("airTestManometer");
const initStaticPressureValidation = getValidation("airTestInitialStaticPressure");
const finalStaticPressureValidation = getValidation("airTestFinalStaticPressure");
const insideTempValidation = getValidation("airTestInsideTemp");
const housePressureValidation = getValidation("airTestHousePressure");
const fanPressureValidation = getValidation("airTestFanPressure");
const measuredFlowValidation = getValidation("airTestMeasuredFlow");
const flowRangesValidation = getValidation("airTestFlowRanges");
const initZone1StaticPressureValidation = getValidation("airTestInitialZonePressure");
const initZone2StaticPressureValidation = getValidation("airTestInitialZone2Pressure");
const finalZone1StaticPressureValidation = getValidation("airTestFinalZonePressure");
const finalZone2StaticPressureValidation = getValidation("airTestFinalZone2Pressure");

// const airTestZoneInfo = {
//     0: { test1Equip1: { zone1Pa: false, zone2Pa: false } }, //1 blower - Whole house
//     1: { test1Equip1: { zone1Pa: false, zone2Pa: false }, test1Equip2: { zone1Pa: false, zone2Pa: false } }, //2 blower - Whole house
//     2: { test1Equip1: { zone1Pa: true, zone2Pa: false }, test2Equip1: { zone1Pa: true, zone2Pa: false } }, //1 blower - Duplex
//     3: {
//         test1Equip1: { zone1Pa: true, zone2Pa: true },
//         test2Equip1: { zone1Pa: true, zone2Pa: true },
//         test3Equip1: { zone1Pa: true, zone2Pa: true },
//     }, //1 blower - Triplex
//     4: {
//         test1Equip1: { zone1Pa: true, zone2Pa: false },
//         test1Equip2: { zone1Pa: true, zone2Pa: false },
//         test2Equip1: { zone1Pa: true, zone2Pa: false },
//         test2Equip2: { zone1Pa: false, zone2Pa: false },
//     }, //2 blowers - Triplex
// };

const fanTypeIndex = {
    A: 0,
    B: 1,
    2: 2,
    3: 3,
};

const getFlowRingFromBld = (fanType, ringIndex) => {
    const flowRingMap = {
        B: {
            0: 4,
            1: 5,
            2: 6,
            3: 7,
        },
        ["3"]: {
            0: 12,
            1: 13,
            2: 14,
            3: 15,
            4: 16,
            5: 17,
        },
    };

    if (Object.keys(flowRingMap).includes(fanType)) {
        return flowRingMap[fanType]?.[ringIndex] || 0;
    }

    return 0;
};

export default ({
    accessor,
    modelUnits,
    change,
    houseVolume,
    testTypeId,
    testKey,
    isUpgrade = false,
    initStaticPressureUnits,
    finalStaticPressureUnits,
    insideTempValue,
    insideTempUnits,
    zoneHeatedVolumeUnits,
    getHousePressure,
    getFanPressure,
    getMeasuredFlow,
    housePressureUnits,
    fanPressureUnits,
    measuredFlowUnits,
    zonePressureUnits,
    zone2PressureUnits,
    fanTypeId,
    dataPoints,
    initZonePressureUnits,
    initZone2PressureUnits,
    finalZonePressureUnits,
    finalZone2PressureUnits,
    testNum,
    equipmentNum,
    initStaticPressure,
    finalStaticPressure,
    uploadedFileName,
    uploadedZoneVolume,
    uploadedZoneVolumeUnits,
}) => {
    const [dialogOpen, toggleDialog] = useState(false);

    // if testTypeId == 0, zone volume = house volume
    // otherwise, add warning that house volume will be overwritten with the sum of the zone volumes on file creation/simulation
    const disableVolume = testTypeId === 0;
    const { zone1Pa = false, zone2Pa = false } = airTestZoneInfo?.[testTypeId]?.[testKey] || {};

    //Here we will build rows using the incoming dataPoints
    const rows = [
        ...Object.entries(dataPoints)
            .map(([key, value], ind) => {
                return {
                    id: ind,
                    key,
                    keyInd: parseInt(key.split("point")[1]),
                    ...value,
                };
            })
            .sort((a, b) => a.keyInd - b.keyInd),
    ];

    useEffect(() => {
        const [num, equip] = testKey.split("test")[1].split("Equip");

        if (testNum !== parseInt(num)) {
            change(`${accessor}.testNum`, parseInt(num));
        }
        if (equipmentNum !== parseInt(equip)) {
            change(`${accessor}.equipmentNum`, parseInt(equip));
        }
    }, [testKey, testNum, equipmentNum]);

    const addRow = () => {
        //Re-map incoming data points to handle deleted rows
        const currentRowsSorted = Object.values(dataPoints)
            .sort((a, b) => a.housePressure - b.housePressure)
            .reduce((cache, values, ind) => {
                return {
                    ...cache,
                    [`point${ind}`]: values,
                };
            }, {});

        change(`${accessor}.dataPoints`, {
            ...currentRowsSorted,
            [`point${Object.keys(dataPoints).length}`]: {
                // pointNum: Object.keys(dataPoints).length,
                housePressure: 0,
                fanPressure: 0,
                measuredFlow: 0,
                flowRanges: {
                    id: 0,
                },
                zonePressure: 0,
                zone2Pressure: 0,
            },
        });
    };

    const removeTableRow = (ind) => {
        const { [`point${ind}`]: pointToRemove, ...restPoints } = dataPoints || {};

        const restRowsSorted = Object.values(restPoints)
            .sort((a, b) => a.housePressure - b.housePressure)
            .reduce((cache, values, ind) => {
                return {
                    ...cache,
                    [`point${ind}`]: values,
                };
            }, {});

        change(`${accessor}.dataPoints`, {
            ...restRowsSorted,
        });
    };

    const handleClearTableData = () => {
        change(`${accessor}.initStaticPressure`, 0);
        change(`${accessor}.finalStaticPressure`, 0);
        change(`${accessor}.dataPoints`, {});
        change(`${accessor}.uploadedFileName`, "");
        change(`${accessor}.manometerModel`, "");
    };

    const hsePressureField = (ind) => {
        return (
            <Field
                component={TableInputWithUnits}
                type="number"
                name={`${accessor}.dataPoints.point${ind}.housePressure`}
                validate={housePressureValidation}
                decimals={getDecimalPlaces("airTestHousePressure")}
                change={change}
                setValue={rows[ind].housePressure}
                setUnits={housePressureUnits}
                disabled={fileUploaded}
                units={{
                    base: getBaseUnits("airTestHousePressure", modelUnits),
                    options: ["Pa"],
                    selected: housePressureUnits,
                    unitType: "pressure",
                    accessor: `${accessor}.housePressure_u`,
                }}
            />
        );
    };

    const zone1PressureField = (ind) => {
        return (
            <Field
                component={TableInputWithUnits}
                type="number"
                name={`${accessor}.dataPoints.point${ind}.zonePressure`}
                validate={housePressureValidation}
                decimals={getDecimalPlaces("airTestHousePressure")}
                change={change}
                setValue={rows[ind].zonePressure}
                setUnits={zonePressureUnits}
                units={{
                    base: getBaseUnits("airTestHousePressure", modelUnits),
                    options: ["Pa"],
                    selected: zonePressureUnits,
                    unitType: "pressure",
                    accessor: `${accessor}.zonePressure_u`,
                }}
                disabled={!zone1Pa}
            />
        );
    };

    const zone2PressureField = (ind) => {
        return (
            <div className={classes.finalCell}>
                <Field
                    component={TableInputWithUnits}
                    type="number"
                    name={`${accessor}.dataPoints.point${ind}.zone2Pressure`}
                    validate={housePressureValidation}
                    decimals={getDecimalPlaces("airTestHousePressure")}
                    change={change}
                    setValue={rows[ind].zone2Pressure}
                    setUnits={zone2PressureUnits}
                    units={{
                        base: getBaseUnits("airTestHousePressure", modelUnits),
                        options: ["Pa"],
                        selected: zone2PressureUnits,
                        unitType: "pressure",
                        accessor: `${accessor}.zone2Pressure_u`,
                    }}
                    disabled={!zone2Pa}
                />
                {!fileUploaded && (
                    <IconButton
                        onClick={() => removeTableRow(ind)}
                        style={{ marginLeft: "0.5rem", marginRight: "-1rem" }}
                    >
                        <Delete />
                    </IconButton>
                )}
            </div>
        );
    };

    const fanPressureField = (ind) => {
        return (
            <Field
                component={TableInputWithUnits}
                type="number"
                name={`${accessor}.dataPoints.point${ind}.fanPressure`}
                placeholder="0.00"
                validate={fanPressureValidation}
                decimals={getDecimalPlaces("airTestFanPressure")}
                change={change}
                disabled={fileUploaded}
                setValue={rows[ind].fanPressure}
                // setValue={(rows[ind]?.measuredFlow || 0) === 0 ? rows[ind].fanPressure : 0}
                setUnits={fanPressureUnits}
                units={{
                    base: getBaseUnits("airTestFanPressure", modelUnits),
                    options: ["Pa"],
                    selected: fanPressureUnits,
                    unitType: "pressure",
                    accessor: `${accessor}.fanPressure_u`,
                }}
            />
        );
    };

    const measuredFlowField = (ind) => {
        return (
            <Field
                component={TableInputWithUnits}
                type="number"
                name={`${accessor}.dataPoints.point${ind}.measuredFlow`}
                placeholder="0.00"
                validate={measuredFlowValidation}
                decimals={getDecimalPlaces("airTestMeasuredFlow")}
                change={change}
                setValue={rows[ind].measuredFlow}
                // setValue={rows[ind].fanPressure === 0 ? rows[ind].measuredFlow : 0}
                setUnits={measuredFlowUnits}
                units={{
                    base: getBaseUnits("airTestMeasuredFlow", modelUnits),
                    options: getUnitOptions("airFlow"),
                    selected: measuredFlowUnits,
                    unitType: "airFlow",
                    accessor: `${accessor}.measuredFlow_u`,
                }}
                disabled={fileUploaded}
                // disabled={rows[ind].fanPressure !== 0}
            />
        );
    };

    const flowRangesField = (ind) => {
        return (
            <Field
                component={TableSelect}
                name={`${accessor}.dataPoints.point${ind}.flowRanges`}
                options={getOptions({ fieldKey: "airTestFlowRanges", indKey: fanTypeId })}
                placeholder="Flow Ranges"
                validate={flowRangesValidation}
                setValue={rows[ind].flowRanges}
            />
        );
    };

    const handleFanTypeChange = ({ id }) => {
        const firstFlowRangeOpt = getFirstOption({ indKey: id, depKey: "airTestFlowRanges" });

        Object.keys(dataPoints).forEach((pointKey) => {
            change(`${accessor}.dataPoints.${pointKey}.flowRanges`, firstFlowRangeOpt);
        });
    };

    const fileUploaded = !isEmpty(uploadedFileName);

    const handleFileChange = (e) => {
        e.preventDefault();

        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onload = (event) => {
            const parseData = event.target.result.split(/\r?\n/);

            const headerPressure = parseData.reduce((acc, line, ind) => {
                if (line.split(" ")[0].includes("D_Data") && line.split(" ")[1].includes("-1")) {
                    return [...acc, line.split(" ")[3]];
                } else {
                    return acc;
                }
            }, []);

            const headerLabels = parseData.reduce((acc, line, ind) => {
                if (line.split(" ")[0].includes("Micromanometer_Model:")) {
                    return { ...acc, manometerModel: line.split(" ")[1] };
                } else if (line.split(" ")[0].includes("Build_Fan_Model:")) {
                    return { ...acc, fanType: line.split(" ")[1] };
                } else {
                    return acc;
                }
            }, {});

            const {
                flowUnits = "cfm",
                buildingDimensionUnits = "ft",
                leakageDimensionUnits = "in",
                temperatureUnits = "F",
                indoorTemp,
                outdoorTemp,
                zoneVolume = "N/A",
            } = parseData.reduce((acc, line) => {
                if (line.split(" ")[0].includes("Flow_Units:")) {
                    return { ...acc, flowUnits: line.split(" ")[1] };
                } else if (line.split(" ")[0].includes("Building_Dimension_Units:")) {
                    return { ...acc, buildingDimensionUnits: line.split(" ")[1] };
                } else if (line.split(" ")[0].includes("Leakage_Dimension_Units:")) {
                    return { ...acc, leakageDimensionUnits: line.split(" ")[1] };
                } else if (line.split(" ")[0].includes("Temperature_Units:")) {
                    return { ...acc, temperatureUnits: line.split(" ")[1] };
                } else if (line.split(" ")[0].includes("D_Manual_Entry_Pretest_Indoor_Temperature_for_Test_1:")) {
                    return { ...acc, indoorTemp: line.split(" ")[1] };
                } else if (line.split(" ")[0].includes("D_Manual_Entry_Pretest_Outdoor_Temperature_for_Test_1:")) {
                    return { ...acc, outdoorTemp: line.split(" ")[1] };
                } else if (line.split(" ")[0] == "Volume:") {
                    return { ...acc, zoneVolume: line.split(" ")[1] };
                }
                return acc;
            }, {});

            const tableData = parseData
                .reduce((acc, line) => {
                    if (line.split(" ")[0].includes("D_Data") && !line.split(" ")[1].includes("-1")) {
                        return [
                            ...acc,
                            {
                                housePressure: line.split(" ")[3],
                                fanPressure: line.split(" ")[4],
                                measuredFlow: convertUnit({
                                    value: line.split(" ")[5],
                                    type: "airFlow",
                                    inputUnit: flowUnits,
                                    outputUnit: "L/s",
                                }),
                                flowRanges: {
                                    id: getFlowRingFromBld(headerLabels.fanType, line.split(" ")[1]),
                                },
                                zonePressure: 0,
                                zone2Pressure: 0,
                            },
                        ];
                    } else {
                        return acc;
                    }
                }, [])
                .reduce((acc, data, ind) => {
                    return { ...acc, [`point${ind}`]: data };
                }, {});

            change(
                `${accessor}.insideTemperature`,
                convertUnit({ value: indoorTemp, type: "temperature", inputUnit: temperatureUnits, outputUnit: "C" })
            );
            change(
                "modelData.airTightness.testData.outsideTemp",
                convertUnit({ value: outdoorTemp, type: "temperature", inputUnit: temperatureUnits, outputUnit: "C" })
            );

            change(
                `${accessor}.uploadedZoneVolume`,
                parseFloat(
                    convertUnit({
                        value: zoneVolume,
                        type: "volume",
                        inputUnit: `${buildingDimensionUnits}3`,
                        outputUnit: "m3",
                    }).toFixed(2)
                )
            );
            change(`${accessor}.uploadedZoneVolume_u`, `${buildingDimensionUnits}3`);
            change(`${accessor}.uploadedFileName`, file.name);
            change(`${accessor}.manometerModel`, headerLabels.manometerModel);
            change(`${accessor}.fanType.id`, fanTypeIndex[headerLabels.fanType]); // select a type with a function
            change(`${accessor}.initStaticPressure`, parseFloat(headerPressure[0]).toFixed(2));
            change(`${accessor}.finalStaticPressure`, parseFloat(headerPressure[1]).toFixed(2));
            change(`${accessor}.dataPoints`, tableData);
        };
        reader.onerror = () => {
            console.log("file error", reader.error);
        };

        reader.readAsText(file);
    };

    const handleVolumeOverwrite = useCallback(() => {
        const roundedVolume = parseFloat(uploadedZoneVolume.toFixed(2));
        change(`modelData.dimensions.volume.total`, roundedVolume);
        change(`modelData.dimensions.volume.items`, {
            itemImport: {
                description: "Total imported from blower door test data",
                enabled: true,
                value: roundedVolume,
            },
        });
    }, [uploadedZoneVolume]);

    const invalidUploadedVolume = isNaN(parseFloat(uploadedZoneVolume));

    return (
        <>
            <div className={`${!isUpgrade && sharedClasses.section} ${classes.airtightness}`}>
                <div>
                    <div
                        style={{
                            display: "flex",
                            marginBottom: "10px",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "flex-end",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "center",
                            }}
                        >
                            <label for="file-upload" className={classes.fileUpload}>
                                Upload Air Leakage Test Data (.bld)
                            </label>
                            <input id="file-upload" type="file" accept={".bld,.BLD"} onChange={handleFileChange} />
                            {fileUploaded ? (
                                <div
                                    style={{
                                        marginLeft: "1rem",
                                        marginRight: "1rem",
                                        // marginBottom: "1rem",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        fontSize: "0.875em",
                                    }}
                                >
                                    <p>Data uploaded from :{uploadedFileName}</p>
                                    <IconButton
                                        onClick={() => {
                                            change(`${accessor}.uploadedFileName`, "");
                                        }}
                                        style={{ marginLeft: "0.5rem", marginRight: "-1rem", padding: 0 }}
                                    >
                                        <Delete />
                                    </IconButton>
                                </div>
                            ) : (
                                <p style={{ marginLeft: "1rem" }}>No file uploaded</p>
                            )}
                        </div>
                        {fileUploaded && uploadedZoneVolume !== 0 && (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    alignItems: "flex-end",
                                    marginLeft: "auto",
                                    marginRight: 0,
                                    gridGap: "0.75rem",
                                }}
                            >
                                <InputWithUnits
                                    label={"Imported Zone Volume"}
                                    placeholder="N/A"
                                    disabled={true}
                                    className={classes.importedZoneVolumeField}
                                    input={{
                                        value: uploadedZoneVolume,
                                        onChange: () => {},
                                    }}
                                    decimals={2}
                                    units={{
                                        base: {
                                            trueBase: "m3",
                                            displayBase: ["Imperial", "US"].includes(modelUnits) ? "ft3" : "m3",
                                        },
                                        options: ["m3", "ft3"],
                                        selected: uploadedZoneVolumeUnits,
                                        unitType: "volume",
                                        accessor: `${accessor}.uploadedZoneVolume_u`,
                                    }}
                                    change={change}
                                />

                                <Button
                                    style={{ width: "14rem" }}
                                    disabled={
                                        (invalidUploadedVolume && !disableVolume) || houseVolume === uploadedZoneVolume
                                    }
                                    onClick={handleVolumeOverwrite}
                                >
                                    Overwrite House Volume
                                </Button>
                            </div>
                        )}
                    </div>

                    <InputRow gridTemplate="1fr 1fr 1fr">
                        <Field
                            component={Select}
                            name={`${accessor}.fanType`}
                            options={getOptions({ fieldKey: "airTestFanType" })}
                            label="Fan Type"
                            placeholder="Fan Type"
                            validate={airTestFanTypeValidation}
                            onChange={handleFanTypeChange}
                        />
                        <Field
                            component={Input}
                            name={`${accessor}.manometerModel`}
                            label="Manometer"
                            disabled={false}
                            validate={manometerValidation}
                        />
                        <Button
                            disabled={rows.length < 1}
                            // disabled={rows.length < 1}
                            style={{ marginTop: "1.1rem" }}
                            smallPadding
                            onClick={() => {
                                toggleDialog(true);
                            }}
                        >
                            Clear Table Data
                        </Button>
                    </InputRow>

                    <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.initStaticPressure`}
                            label="Initial Static Pressure"
                            placeholder="0.00"
                            validate={initStaticPressureValidation}
                            decimals={getDecimalPlaces("airTestInitialStaticPressure")}
                            change={change}
                            setValue={initStaticPressure}
                            disabled={fileUploaded}
                            units={{
                                base: getBaseUnits("airTestInitialStaticPressure", modelUnits),
                                options: ["Pa"],
                                selected: initStaticPressureUnits,
                                unitType: "pressure",
                                accessor: `${accessor}.initStaticPressure_u`,
                            }}
                        />
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.insideTemperature`}
                            label="Inside Temperature"
                            placeholder="0.00"
                            validate={insideTempValidation}
                            decimals={getDecimalPlaces("airTestInsideTemp")}
                            change={change}
                            setValue={insideTempValue}
                            units={{
                                base: getBaseUnits("airTestInsideTemp", modelUnits),
                                options: getUnitOptions("temperature"),
                                selected: insideTempUnits,
                                unitType: "temperature",
                                accessor: `${accessor}.insideTemperature_u`,
                            }}
                        />
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.initZonePressure`}
                            label="Initial Zone 1 Pressure"
                            placeholder="0.00"
                            validate={initZone1StaticPressureValidation}
                            decimals={getDecimalPlaces("airTestInitialZonePressure")}
                            change={change}
                            units={{
                                base: getBaseUnits("airTestInitialZonePressure", modelUnits),
                                options: ["Pa"],
                                selected: initZonePressureUnits,
                                unitType: "pressure",
                                accessor: `${accessor}.initZonePressure_u`,
                            }}
                            disabled={!zone1Pa}
                        />
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.initZone2Pressure`}
                            label="Initial Zone 2 Pressure"
                            placeholder="0.00"
                            validate={initZone2StaticPressureValidation}
                            decimals={getDecimalPlaces("airTestInitialZone2Pressure")}
                            change={change}
                            units={{
                                base: getBaseUnits("airTestInitialZone2Pressure", modelUnits),
                                options: ["Pa"],
                                selected: initZone2PressureUnits,
                                unitType: "pressure",
                                accessor: `${accessor}.initZone2Pressure_u`,
                            }}
                            disabled={!zone2Pa}
                        />
                    </InputRow>
                    <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.finalStaticPressure`}
                            label="Final Static Pressure"
                            placeholder="0.00"
                            validate={finalStaticPressureValidation}
                            decimals={getDecimalPlaces("airTestFinalStaticPressure")}
                            change={change}
                            setValue={finalStaticPressure}
                            disabled={fileUploaded}
                            // onChange={() => {
                            //     change(`${accessor}.uploadedFileName`, '')
                            // }}
                            units={{
                                base: getBaseUnits("airTestFinalStaticPressure", modelUnits),
                                options: ["Pa"],
                                selected: finalStaticPressureUnits,
                                unitType: "pressure",
                                accessor: `${accessor}.finalStaticPressure_u`,
                            }}
                        />
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.zoneHeatedVolume`}
                            label="Zone Heated Volume"
                            placeholder="0.00"
                            // validate={}
                            decimals={getDecimalPlaces("airTestZoneHeatedVolume")}
                            change={change}
                            units={{
                                base: getBaseUnits("airTestZoneHeatedVolume", modelUnits),
                                options: ["m3", "ft3"],
                                selected: zoneHeatedVolumeUnits,
                                unitType: "volume",
                                accessor: `${accessor}.zoneHeatedVolume_u`,
                            }}
                            disabled={disableVolume}
                            setValue={disableVolume ? houseVolume : null}
                            info={'Equal to the total house volume for "1 blower - whole home"'}
                        />
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.finalZonePressure`}
                            label="Final Zone 1 Pressure"
                            placeholder="0.00"
                            validate={finalZone1StaticPressureValidation}
                            decimals={getDecimalPlaces("airTestFinalZonePressure")}
                            change={change}
                            units={{
                                base: getBaseUnits("airTestFinalZonePressure", modelUnits),
                                options: ["Pa"],
                                selected: finalZonePressureUnits,
                                unitType: "pressure",
                                accessor: `${accessor}.finalZonePressure_u`,
                            }}
                            disabled={!zone1Pa}
                        />
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.finalZone2Pressure`}
                            label="Final Zone 2 Pressure"
                            placeholder="0.00"
                            validate={finalZone2StaticPressureValidation}
                            decimals={getDecimalPlaces("airTestFinalZone2Pressure")}
                            change={change}
                            units={{
                                base: getBaseUnits("airTestFinalZone2Pressure", modelUnits),
                                options: ["Pa"],
                                selected: finalZone2PressureUnits,
                                unitType: "pressure",
                                accessor: `${accessor}.finalZone2Pressure_u`,
                            }}
                            disabled={!zone2Pa}
                        />
                    </InputRow>
                    <Table
                        className={classes.summaryTable}
                        headCells={[
                            {
                                id: "housePressure",
                                label: (
                                    <Field
                                        component={TableUnits}
                                        change={change}
                                        label="House Pressure"
                                        units={{
                                            base: getBaseUnits("airTestHousePressure", modelUnits),
                                            options: ["Pa"],
                                            selected: housePressureUnits,
                                            unitType: "pressure",
                                            accessor: `${accessor}.housePressure_u`,
                                        }}
                                    />
                                ),
                                align: "left",
                                width: "130",
                            },
                            {
                                id: "fanPressure",
                                label: (
                                    <Field
                                        component={TableUnits}
                                        change={change}
                                        label="Fan Pressure"
                                        units={{
                                            base: getBaseUnits("airTestFanPressure", modelUnits),
                                            options: ["Pa"],
                                            selected: fanPressureUnits,
                                            unitType: "pressure",
                                            accessor: `${accessor}.fanPressure_u`,
                                        }}
                                    />
                                ),
                                align: "left",
                                width: "130",
                            },
                            {
                                id: "measuredFlow",
                                label: (
                                    <Field
                                        component={TableUnits}
                                        change={change}
                                        label="Measured Flow"
                                        units={{
                                            base: getBaseUnits("airTestMeasuredFlow", modelUnits),
                                            options: getUnitOptions("airFlow"),
                                            selected: measuredFlowUnits,
                                            unitType: "airFlow",
                                            accessor: `${accessor}.measuredFlow_u`,
                                        }}
                                    />
                                ),
                                align: "left",
                                width: "130",
                            },
                            { id: "flowRanges", label: "Flow Ranges", align: "left", width: "130" },
                            {
                                id: "zone1Pressure",
                                label: (
                                    <Field
                                        component={TableUnits}
                                        change={change}
                                        label="Zone 1 Pressure"
                                        units={{
                                            base: getBaseUnits("airTestHousePressure", modelUnits),
                                            options: ["Pa"],
                                            selected: housePressureUnits,
                                            unitType: "pressure",
                                            accessor: `${accessor}.housePressure_u`,
                                        }}
                                    />
                                ),
                                align: "left",
                                width: "130",
                            },
                            {
                                id: "zone2Pressure",
                                label: (
                                    <Field
                                        component={TableUnits}
                                        change={change}
                                        label="Zone 2 Pressure"
                                        units={{
                                            base: getBaseUnits("airTestHousePressure", modelUnits),
                                            options: ["Pa"],
                                            selected: housePressureUnits,
                                            unitType: "pressure",
                                            accessor: `${accessor}.housePressure_u`,
                                        }}
                                    />
                                ),
                                align: "left",
                                width: "130",
                            },
                            // {
                            //     id: "delete",
                            //     label: " ",
                            //     width: "10",
                            //     align: "center",
                            //     size: "small",
                            // },
                        ]}
                        rows={rows.map(
                            (
                                { housePressure, fanPressure, measuredFlow, flowRanges, zonePressure, zone2Pressure },
                                ind
                            ) => ({
                                housePressure: {
                                    value: housePressure,
                                    content: hsePressureField(ind),
                                },
                                fanPressure: {
                                    value: fanPressure,
                                    content: fanPressureField(ind),
                                },
                                measuredFlow: {
                                    value: measuredFlow,
                                    content: measuredFlowField(ind),
                                },
                                flowRanges: {
                                    value: flowRanges,
                                    content: flowRangesField(ind),
                                },
                                zone1Pressure: {
                                    value: zonePressure,
                                    content: zone1PressureField(ind),
                                },
                                zone2Pressure: {
                                    value: zone2Pressure,
                                    content: zone2PressureField(ind),
                                },
                            })
                        )}
                    />

                    {!fileUploaded && <AddBox onClick={addRow} title="Add Row" icon="" />}
                    <Dialog open={dialogOpen}>
                        <CloseDialog
                            closeActions={() => {
                                toggleDialog(false);
                            }}
                        />
                        <div className={classes.nameDialog}>
                            <h2>Are you sure you want to clear all air leakage test data?</h2>

                            <div className={classes.footer}>
                                <Button
                                    type="hollow"
                                    onClick={() => {
                                        toggleDialog(false);
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    style={{ marginLeft: "1.1rem" }}
                                    onClick={async () => {
                                        toggleDialog(false);
                                        handleClearTableData();
                                    }}
                                >
                                    Clear Table Data
                                </Button>
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>
        </>
    );
};
