import isEmpty from "lodash/isEmpty";
export default (components, returnType = "default") => {
    const { wall = {}, ceiling = {}, basement = {}, crawlspace = {}, slab = {}, expFloor = {} } = components;
    const codesInModel = {};
    const cleanedComps = {};
    // Wall
    if (!isEmpty(wall)) {
        cleanedComps.wall = Object.entries(wall).reduce((acc, [wallKey, wallComp]) => {
            const { subcomponents = {}, wallInsType: { codeRef = "", codeLabel = "" } = {} } = wallComp || {};

            if (isEmpty(wallComp)) {
                return acc;
            }

            if (!["", "UserSpecified"].includes(codeRef)) {
                codesInModel[codeRef] = codeLabel;
            }

            const cleanedSubComps = {};
            if (!isEmpty(subcomponents)) {
                const { window = {}, door = {}, floorHeader = {} } = subcomponents;

                if (!isEmpty(window)) {
                    cleanedSubComps.window = Object.entries(window).reduce((acc, [windowKey, windowObj]) => {
                        if (isEmpty(windowObj) || windowObj === null) {
                            return acc;
                        } else {
                            const { windowType: { codeRef = "", codeLabel = "" } = {} } = windowObj || {};
                            if (!["", "UserSpecified"].includes(codeRef)) {
                                codesInModel[codeRef] = codeLabel;
                            }
                            return { ...acc, [windowKey]: windowObj };
                        }
                    }, {});

                    if (isEmpty(cleanedSubComps.window)) {
                        delete cleanedSubComps["window"];
                    }
                }

                if (!isEmpty(door)) {
                    cleanedSubComps.door = Object.entries(door).reduce((acc, [doorKey, doorObj]) => {
                        if (isEmpty(doorObj) || doorObj === null) {
                            return acc;
                        } else {
                            //Door may have windows that could be null
                            const { subcomponents: { window: windowsOnDoor = {} } = {} } = doorObj;

                            const cleanedDoorSubComps = {};
                            if (!isEmpty(windowsOnDoor)) {
                                cleanedDoorSubComps.window = Object.entries(windowsOnDoor).reduce(
                                    (acc, [windowKey, windowObj]) => {
                                        if (isEmpty(windowObj) || windowObj === null) {
                                            return acc;
                                        } else {
                                            return { ...acc, [windowKey]: windowObj };
                                        }
                                    },
                                    {}
                                );

                                if (isEmpty(cleanedDoorSubComps.window)) {
                                    delete cleanedDoorSubComps["window"];
                                }
                            }

                            return {
                                ...acc,
                                [doorKey]: {
                                    ...doorObj,
                                    subcomponents: cleanedDoorSubComps,
                                },
                            };
                        }
                    }, {});

                    if (isEmpty(cleanedSubComps.door)) {
                        delete cleanedSubComps["door"];
                    }
                }

                if (!isEmpty(floorHeader)) {
                    cleanedSubComps.floorHeader = Object.entries(floorHeader).reduce(
                        (acc, [floorHeaderKey, floorHeaderObj]) => {
                            if (isEmpty(floorHeaderObj) || floorHeaderObj === null) {
                                return acc;
                            } else {
                                const { floorHeaderInsType: { codeRef = "", codeLabel = "" } = {} } =
                                    floorHeaderObj || {};
                                if (!["", "UserSpecified"].includes(codeRef)) {
                                    codesInModel[codeRef] = codeLabel;
                                }
                                return { ...acc, [floorHeaderKey]: floorHeaderObj };
                            }
                        },
                        {}
                    );

                    if (isEmpty(cleanedSubComps.floorHeader)) {
                        delete cleanedSubComps["floorHeader"];
                    }
                }
            }

            return {
                ...acc,
                [wallKey]: {
                    ...wallComp,
                    subcomponents: cleanedSubComps,
                },
            };
        }, {});
    }

    // Ceiling
    if (!isEmpty(ceiling)) {
        cleanedComps.ceiling = Object.entries(ceiling).reduce((acc, [ceilingKey, ceilingComp]) => {
            const { subcomponents = {}, ceilingInsType: { codeRef = "", codeLabel = "" } = {} } = ceilingComp;

            if (isEmpty(ceilingComp)) {
                return acc;
            }

            if (!["", "UserSpecified"].includes(codeRef)) {
                codesInModel[codeRef] = codeLabel;
            }

            const cleanedSubComps = {};
            if (!isEmpty(subcomponents)) {
                const { window = {} } = subcomponents;

                if (!isEmpty(window)) {
                    cleanedSubComps.window = Object.entries(window).reduce((acc, [windowKey, windowObj]) => {
                        if (isEmpty(windowObj) || windowObj === null) {
                            return acc;
                        } else {
                            const { windowType: { codeRef = "", codeLabel = "" } = {} } = windowObj || {};
                            if (!["", "UserSpecified"].includes(codeRef)) {
                                codesInModel[codeRef] = codeLabel;
                            }
                            return { ...acc, [windowKey]: windowObj };
                        }
                    }, {});

                    if (isEmpty(cleanedSubComps.window)) {
                        delete cleanedSubComps["window"];
                    }
                }
            }

            return {
                ...acc,
                [ceilingKey]: {
                    ...ceilingComp,
                    subcomponents: cleanedSubComps,
                },
            };
        }, {});
    }

    // Basement
    if (!isEmpty(basement)) {
        cleanedComps.basement = Object.entries(basement).reduce((acc, [basementKey, basementComp]) => {
            const {
                subcomponents = {},
                wall: { intAddedInsType: { codeRef: wallCodeRef = "", codeLabel: wallCodeLabel = "" } = {} } = {},
                floor: {
                    floorsAboveInsType: { codeRef: floorsAboveCodeRef = "", codeLabel: floorsAboveCodeLabel = "" } = {},
                } = {},
            } = basementComp;

            if (isEmpty(basementComp)) {
                return acc;
            }

            if (!["", "UserSpecified"].includes(wallCodeRef)) {
                codesInModel[wallCodeRef] = wallCodeLabel;
            }
            if (!["", "UserSpecified"].includes(floorsAboveCodeRef)) {
                codesInModel[floorsAboveCodeRef] = floorsAboveCodeLabel;
            }

            const cleanedSubComps = {};
            if (!isEmpty(subcomponents)) {
                const { window = {}, door = {}, floorHeader = {} } = subcomponents;

                if (!isEmpty(window)) {
                    cleanedSubComps.window = Object.entries(window).reduce((acc, [windowKey, windowObj]) => {
                        if (isEmpty(windowObj) || windowObj === null) {
                            return acc;
                        } else {
                            const { windowType: { codeRef = "", codeLabel = "" } = {} } = windowObj || {};
                            if (!["", "UserSpecified"].includes(codeRef)) {
                                codesInModel[codeRef] = codeLabel;
                            }
                            return { ...acc, [windowKey]: windowObj };
                        }
                    }, {});

                    if (isEmpty(cleanedSubComps.window)) {
                        delete cleanedSubComps["window"];
                    }
                }

                if (!isEmpty(door)) {
                    cleanedSubComps.door = Object.entries(door).reduce((acc, [doorKey, doorObj]) => {
                        if (isEmpty(doorObj) || doorObj === null) {
                            return acc;
                        } else {
                            //Door may have windows that could be null
                            const { subcomponents: { window: windowsOnDoor = {} } = {} } = doorObj;

                            const cleanedDoorSubComps = {};
                            if (!isEmpty(windowsOnDoor)) {
                                cleanedDoorSubComps.window = Object.entries(windowsOnDoor).reduce(
                                    (acc, [windowKey, windowObj]) => {
                                        if (isEmpty(windowObj) || windowObj === null) {
                                            return acc;
                                        } else {
                                            return { ...acc, [windowKey]: windowObj };
                                        }
                                    },
                                    {}
                                );

                                if (isEmpty(cleanedDoorSubComps.window)) {
                                    delete cleanedDoorSubComps["window"];
                                }
                            }

                            return {
                                ...acc,
                                [doorKey]: {
                                    ...doorObj,
                                    subcomponents: cleanedDoorSubComps,
                                },
                            };
                        }
                    }, {});

                    if (isEmpty(cleanedSubComps.door)) {
                        delete cleanedSubComps["door"];
                    }
                }

                if (!isEmpty(floorHeader)) {
                    cleanedSubComps.floorHeader = Object.entries(floorHeader).reduce(
                        (acc, [floorHeaderKey, floorHeaderObj]) => {
                            if (isEmpty(floorHeaderObj) || floorHeaderObj === null) {
                                return acc;
                            } else {
                                const { floorHeaderInsType: { codeRef = "", codeLabel = "" } = {} } =
                                    floorHeaderObj || {};
                                if (!["", "UserSpecified"].includes(codeRef)) {
                                    codesInModel[codeRef] = codeLabel;
                                }
                                return { ...acc, [floorHeaderKey]: floorHeaderObj };
                            }
                        },
                        {}
                    );

                    if (isEmpty(cleanedSubComps.floorHeader)) {
                        delete cleanedSubComps["floorHeader"];
                    }
                }
            }

            return {
                ...acc,
                [basementKey]: {
                    ...basementComp,
                    subcomponents: cleanedSubComps,
                },
            };
        }, {});
    }

    // Crawlspace
    if (!isEmpty(crawlspace)) {
        cleanedComps.crawlspace = Object.entries(crawlspace).reduce((acc, [crawlspaceKey, crawlspaceComp]) => {
            const {
                subcomponents = {},
                wall: { crawlspaceWallInsType: { codeRef: wallCodeRef = "", codeLabel: wallCodeLabel = "" } = {} } = {},
                floor: {
                    floorsAboveInsType: { codeRef: floorsAboveCodeRef = "", codeLabel: floorsAboveCodeLabel = "" } = {},
                } = {},
            } = crawlspaceComp;

            if (isEmpty(crawlspaceComp)) {
                return acc;
            }

            if (!["", "UserSpecified"].includes(wallCodeRef)) {
                codesInModel[wallCodeRef] = wallCodeLabel;
            }
            if (!["", "UserSpecified"].includes(floorsAboveCodeRef)) {
                codesInModel[floorsAboveCodeRef] = floorsAboveCodeLabel;
            }

            const cleanedSubComps = {};
            if (!isEmpty(subcomponents)) {
                const { window = {}, door = {}, floorHeader = {} } = subcomponents;

                if (!isEmpty(window)) {
                    cleanedSubComps.window = Object.entries(window).reduce((acc, [windowKey, windowObj]) => {
                        if (isEmpty(windowObj) || windowObj === null) {
                            return acc;
                        } else {
                            const { windowType: { codeRef = "", codeLabel = "" } = {} } = windowObj || {};
                            if (!["", "UserSpecified"].includes(codeRef)) {
                                codesInModel[codeRef] = codeLabel;
                            }
                            return { ...acc, [windowKey]: windowObj };
                        }
                    }, {});

                    if (isEmpty(cleanedSubComps.window)) {
                        delete cleanedSubComps["window"];
                    }
                }

                if (!isEmpty(door)) {
                    cleanedSubComps.door = Object.entries(door).reduce((acc, [doorKey, doorObj]) => {
                        if (isEmpty(doorObj) || doorObj === null) {
                            return acc;
                        } else {
                            //Door may have windows that could be null
                            const { subcomponents: { window: windowsOnDoor = {} } = {} } = doorObj;

                            const cleanedDoorSubComps = {};
                            if (!isEmpty(windowsOnDoor)) {
                                cleanedDoorSubComps.window = Object.entries(windowsOnDoor).reduce(
                                    (acc, [windowKey, windowObj]) => {
                                        if (isEmpty(windowObj) || windowObj === null) {
                                            return acc;
                                        } else {
                                            return { ...acc, [windowKey]: windowObj };
                                        }
                                    },
                                    {}
                                );

                                if (isEmpty(cleanedDoorSubComps.window)) {
                                    delete cleanedDoorSubComps["window"];
                                }
                            }

                            return {
                                ...acc,
                                [doorKey]: {
                                    ...doorObj,
                                    subcomponents: cleanedDoorSubComps,
                                },
                            };
                        }
                    }, {});

                    if (isEmpty(cleanedSubComps.door)) {
                        delete cleanedSubComps["door"];
                    }
                }

                if (!isEmpty(floorHeader)) {
                    cleanedSubComps.floorHeader = Object.entries(floorHeader).reduce(
                        (acc, [floorHeaderKey, floorHeaderObj]) => {
                            if (isEmpty(floorHeaderObj) || floorHeaderObj === null) {
                                return acc;
                            } else {
                                const { floorHeaderInsType: { codeRef = "", codeLabel = "" } = {} } =
                                    floorHeaderObj || {};
                                if (!["", "UserSpecified"].includes(codeRef)) {
                                    codesInModel[codeRef] = codeLabel;
                                }
                                return { ...acc, [floorHeaderKey]: floorHeaderObj };
                            }
                        },
                        {}
                    );

                    if (isEmpty(cleanedSubComps.floorHeader)) {
                        delete cleanedSubComps["floorHeader"];
                    }
                }
            }

            return {
                ...acc,
                [crawlspaceKey]: {
                    ...crawlspaceComp,
                    subcomponents: cleanedSubComps,
                },
            };
        }, {});
    }

    // Cannot hold subcomponents
    if (!isEmpty(slab)) {
        cleanedComps.slab = Object.keys(slab).reduce((cache, comp) => {
            if (isEmpty(slab[comp])) {
                return cache;
            }

            return {
                ...cache,
                [comp]: slab[comp],
            };
        }, {});
    }

    // Cannot hold subcomponents
    if (!isEmpty(expFloor)) {
        cleanedComps.expFloor = Object.keys(expFloor).reduce((cache, comp) => {
            if (isEmpty(expFloor[comp])) {
                return cache;
            }

            const { expFloorInsType: { codeRef = "", codeLabel = "" } = {} } = expFloor[comp] || {};

            if (!["", "UserSpecified"].includes(codeRef)) {
                codesInModel[codeRef] = codeLabel;
            }

            return {
                ...cache,
                [comp]: expFloor[comp],
            };
        }, {});
    }

    if (returnType === "codeCheck") {
        return { cleanedComps, codesInModel };
    }

    return cleanedComps;
};
