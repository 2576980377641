import React from 'react';
import classes from './style.module.scss';
import sharedClasses from '../style.module.scss';

export default () => {
    return (
        <>
            <h3>
                Purpose
            </h3>
            <p>
                Many energy conservation measures (ECMs) require hourly energy estimates to accurately assess their expected real-world performance, particularly as trends move towards increased electrification. 
            </p>
            <p>
                For example, on-site solar photovoltaics (PV) produce electricity that coincides with summer cooling loads. An annual analysis cannot quantify this overlap and might underestimate the reduction in peak electrical loads associated with installing solar PV. Additionally, utility grids tend to use more carbon-intensive energy sources for electricity generation on hot summer days (i.e. peaker plants). When using annually averaged emission factors to assess technologies like solar PV or heat pumps, the analysis fails to quantify the impact of electricity consumption or generation that align with the variations of carbon intensity in the grid.             </p>
            <p>
                Volta SNAP's hourly analysis aims to help users assess the real-world impact of various ECMs without requiring additional data collection. For provinces with publicly available electricity generation data, hourly emission calculations are also automatically applied.
            </p>

            <br></br>
            <h3>Hourly Electrical Loads</h3>
            <p>
                This calculation methodology (Brideau, 2020) leverages HOT2000 inputs, simulation outputs, and hourly CWEC weather files to derive hourly loads and is divided into six parts, which align with HOT2000's energy end use categories:
            </p>
            <ul>
                <li>
                    Baseloads: Leverages HOT2000's daily normalized internal gains to estimate hourly baseloads.
                </li>
                <li>
                    Ventilation: Based on the specified ventilation required for the home.
                </li>
                <li>
                    Domestic Hot Water: Leverages HOT2000's daily normalized hot water draws and estimated water main temperatures. Note that solar hot water is not yet supported.
                </li>
                <li>
                    Heating: Based on calculated conduction losses, solar gains, and internal gains.
                </li>
                <li>
                    Cooling: Based on calculated conduction losses, solar gains, and internal gains.
                </li>
                <li>
                    Solar: Based on sun position over time, module orientation and solar irradiance data from CWEC weather files.
                </li>
            </ul>
            <p>
                For all six categories, calculations ensure that the annual sums of hourly outputs match those produced by HOT2000.
            </p>
            <p>
                Users are encouraged to input detailed air source heat pump (ASHP) performance specifications under the Mechanicals tab to increase the accuracy of hourly heat pump loads. If no performance data is provided, the following assumptions are applied: 
            </p>
            <ul>
                <li>
                    ASHP Heating COP vs ambient temperature slope = 0.12 COP / ˚C
                </li>
                <li>
                    ASHP Cooling COP vs ambient temperature slope = -0.05 COP / ˚C
                </li>
            </ul>

            <br></br>
            <h3>Hourly Emissions</h3>
            <p>
                Hourly emissions are calculated based on the relative contribution of energy generation sources to an overall province's hourly electricity supply.
            </p>
            <p>
                The following generation emission factors are applied when considering only electricity generation associated with the combustion of fossil fuels, based on alignment with Canada's National Inventory Reports:
            </p>
            <ul>
                <li>Coal: 1103 gCO₂/kWh</li>
                <li>Natural Gas: 448 gCO₂/kWh</li>
            </ul>
            <p>
                When calculating the life cycle assessment (LCA) emission factor of the grid, the following emission factors are also used:
            </p>
            <ul>
                <li>Nuclear: 12 gCO₂/kWh (IPCC, 2011)</li>
                <li>Hydro: 4 gCO₂/kWh (IPCC, 2011)</li>
                <li>Wind: 11 gCO₂/kWh (IPCC, 2011)</li>
                <li>Solar: 32 gCO₂/kWh (NREL, 2012)</li>
                <li>Biofuels: 18 gCO₂/kWh (IPCC, 2011)</li>
            </ul>
            


            <br></br>
            <h3>Limitations</h3>
            <p>
                Estimates of hourly electrical loads are available for houses located in British Columbia, Alberta, Ontario, and Nova Scotia. Support for the remaining Provinces and Territories is coming soon!
            </p>
            <p>
                Estimates of hourly emissions from electricity consumption are only available for Ontario at this time.
            </p>

            <br></br>
            <h3>Support</h3>
            <p>
                Funding for this feature was generously provided by The Atmospheric Fund.
            </p>
            <p>
                The methodology used to convert HOT2000 results into hourly electrical load estimates was adapted from Natural Resources Canada's (NRCan's) Housing Technology Assessment Platform (HTAP), which is developed and maintained by CanmetENERGY Ottawa (Brideau, 2020).
            </p>

            <br></br>
            <h3>References</h3>
            <p>
                Brideau, Sebastien A. <i>"Estimating Hourly Heating and Cooling Loads from Monthly Results."</i> ASHRAE Transactions, vol. 126, no. 2, July 2020, pp. 166+.
            </p>
            <p>
                IPCC, 2011. Renewable Energy Sources and Climate Change Mitigation, Annex II Methodology. https://www.ipcc.ch/report/renewable-energy-sources-and-climate-change-mitigation/methodology/
            </p>
            <p>
                NREL, 2012. Life Cycle Greenhouse Gas Emissions of Crystalline Silicon Photovoltaic Electricity Generation, Systematic Review and Harmonization.
            </p>
        </>
    )
}