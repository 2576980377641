import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import UtilityData from "./";

const mapStateToProps = ({ model: { modelId }, form }) => {
    const selector = formValueSelector("model");
    const utilityData = selector({ form }, `modelData.utilityData`) || {};

    return {
        utilityData,
    };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UtilityData);
