import React, { useState } from 'react';
import classes from './style.module.scss';
import { DropDown } from 'components/Input/Select';
import Add from 'assets/images/icons/JSX/Add';
import { useOutsideClickHook } from 'utils/outsideClick';
import { getLayerTemplate } from 'utils/enclosure';
import moment from 'moment';

const AddLayer = ({
    numLayers,
    change,
    options=[],
    formName,
    layerLimit = 10,
    componentType
}) => {
    const [open, toggleOpen] = useState(false);

    const ref = useOutsideClickHook(() => toggleOpen(false));

    const handleAddLayer = (type, componentType) => {
        let extraDefaults = {}
        if (['Ceiling', 'CeilingFlat'].includes(componentType)) {
            extraDefaults = {
                ridgeBoardWidth: 50.8,
                blockingWidth: 0
            }
        }

        change(`${formName}.layers.${type}.${type}${moment().format('YYYYMMDDHHmmssSS')}`, {
            ...getLayerTemplate(type),
            ...extraDefaults,
            layer: numLayers + 1
        });
    }
    
    return (
        <div className={classes.addComponent} ref={ref}>
            <div
                className={`${classes.addText} ${open && classes.open} ${numLayers >= layerLimit && classes.limitReached}`}
                onClick={()=>toggleOpen(!open)}
            >
                {numLayers < layerLimit ? 'Add Layer' : 'Layer Limit Reached'} 
                {numLayers < layerLimit && <Add />}
            </div>
            {
                (numLayers < layerLimit) && <DropDown
                    className={classes.dropDown}
                    options={options}
                    open={open}
                    toggleOpen={toggleOpen}
                    onChange={(value)=>{
                        handleAddLayer(value, componentType);
                    }}
                    
                />
            }
        </div>
    )
};

export default AddLayer;