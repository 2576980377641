import types from "./types";
import { getCliResults, sendH2kSimulation, runH2kAzureSimulation } from "utils/results/api";
import { actions } from "features/Model/_ducks";
import { firestore, MODEL_COLL } from "_firebase";
import { isEmpty, isEqual } from "lodash";

const { updateModalDetails, fetchModelData, getLinkedResultsStatus } = actions;

const toggleResultsLoading = (isLoading) => ({
    type: types.TOGGLE_RESULTS_LOADING,
    isLoading,
});

const setError = (error) => ({
    type: types.SET_RESULTS_ERROR,
    error,
});

const resetResults = () => ({
    type: types.RESET_RESULTS,
});

const changePackage = (pkg) => ({
    type: types.CHANGE_PACKAGE,
    pkg,
});

const changeCase = (cse) => ({
    type: types.CHANGE_CASE,
    cse,
});

// use this function to implement linkedBaseline results to upgrades table
// cliId comes from modelDetails
const getResults =
    ({ cliIds = [], modelId }) =>
    async (dispatch) => {
        if (!cliIds.length) {
            return;
        }

        dispatch(toggleResultsLoading(true));
        return Promise.all(cliIds.map((cliId) => getCliResults({ cliId, modelId })))
            .then((results) => {
                const resultData = results.reduce((obj, { data }) => {
                    const { metadata: { upgradePackageKey = "" } = {} } = data;

                    if (!upgradePackageKey) {
                        return {
                            ...obj,
                            base: data,
                        };
                    }

                    return {
                        ...obj,
                        [upgradePackageKey]: data,
                        base: data,
                    };
                }, {});

                dispatch({
                    type: types.SET_RESULTS,
                    packageResults: resultData,
                });
                dispatch(toggleResultsLoading(false));
                dispatch(setError(false));
            })
            .catch((result) => {
                const { response: { data: { error = "" } = {} } = {} } = result;
                dispatch(setError(error || true));
                dispatch(toggleResultsLoading(false));
            });
    };

const getLinkedResults =
    ({ cliIds = [], modelId }) =>
    async (dispatch) => {
        if (!cliIds.length) {
            return;
        }
        dispatch(toggleResultsLoading(true));
        return (
            Promise.all(cliIds.map((cliId) => getCliResults({ cliId, modelId })))
                .then((results) => {
                    const resultData = results.reduce((obj, { data }) => {
                        const { metadata: { upgradePackageKey = "" } = {} } = data;
                        dispatch(getLinkedResultsStatus(true));
                        if (!upgradePackageKey) {
                            return {
                                ...obj,
                                base: data,
                            };
                        }

                        return {
                            ...obj,
                            [upgradePackageKey]: data,
                            base: data,
                        };
                    }, {});

                    dispatch({
                        type: types.SET_LINKED_RESULTS,
                        packageResults: resultData,
                    });
                    dispatch(toggleResultsLoading(false));
                    dispatch(setError(false));
                })
                // no results, communicate this error with SelectModel
                // on fetch timeout (error), set isLoading to false in SelectModel
                .catch((result) => {
                    const { response: { data: { error = "" } = {} } = {} } = result;
                    dispatch(setError(error || true));
                    dispatch(toggleResultsLoading(false));
                    dispatch({
                        type: types.SET_LINKED_RESULTS,
                        packageResults: {},
                    });
                    dispatch(getLinkedResultsStatus(false));
                })
        );
    };

const clearLinkedResults = () => async (dispatch) => {
    // console.log('CLEARING...')
    dispatch({
        type: types.SET_LINKED_RESULTS,
        packageResults: {},
    });
};

const runSimulation =
    ({ packages: selectedPackages = [] }) =>
    async (dispatch, getState) => {
        const {
            model: { modelId = "", modelDetails: { lastCliRun: { cliId: cliObj = {} } = {} } = {} } = {},
            user: { uid: userId = "" } = {},
        } = getState();

        dispatch(updateModalDetails({ simulationRunning: true }));
        await sendH2kSimulation({ modelId, userId, packages: selectedPackages }).catch(
            ({ response: { data: { error } = {} } = {} }) => {
                dispatch(updateModalDetails({ simulationRunning: false }));
                dispatch(setError(error));
                dispatch(toggleResultsLoading(false));
            }
        );

        let unSubResultsChanges = firestore.doc(`${MODEL_COLL}/${modelId}`).onSnapshot((doc) => {
            const { modelDetails: { lastCliRun = {} } = {}, isSimulationRunning } = doc.data();
            const { cliId = {} } = lastCliRun;

            const runIds = Object.values(cliId).map(({ runId = "" }) => runId);

            if (!isEmpty(cliId) && !isEqual(cliId, cliObj) && !isSimulationRunning) {
                dispatch(getResults({ cliIds: runIds, modelId })).then(() => {
                    dispatch(updateModalDetails({ simulationRunning: false, lastCliRun }));
                });
                unSubResultsChanges();
            }
        });
    };

const runAzureSimulation =
    ({ packages: selectedPackages = [] }) =>
    async (dispatch, getState) => {
        const {
            model: { modelId = "", modelDetails: { lastCliRun: { cliId: cliObj = {} } = {} } = {} } = {},
            user: { uid: userId = "" } = {},
        } = getState();

        dispatch(updateModalDetails({ simulationRunning: true }));

        try {
            const resultContainer =
                (await runH2kAzureSimulation({ modelId, userId, packages: selectedPackages })) || {};

            if (isEmpty(resultContainer)) {
                dispatch(updateModalDetails({ simulationRunning: false }));
                dispatch(setError({ error: "Result Container Empty." }));
                dispatch(toggleResultsLoading(false));
                return;
            }

            const { data: { cliId = {}, datetime = "" } = {} } = resultContainer;

            const runIds = Object.values(cliId).map(({ runId = "" }) => runId);

            if (!isEmpty(cliId) && !isEqual(cliId, cliObj)) {
                dispatch(getResults({ cliIds: runIds, modelId })).then(() => {
                    dispatch(updateModalDetails({ simulationRunning: false, lastCliRun: { cliId, datetime } }));
                    dispatch(fetchModelData(modelId));
                });
            }
        } catch (error) {
            dispatch(updateModalDetails({ simulationRunning: false }));
            dispatch(setError(error.message));
            dispatch(toggleResultsLoading(false));

            return;
        }
    };

export default {
    getResults,
    getLinkedResults,
    clearLinkedResults,
    toggleResultsLoading,
    runSimulation,
    runAzureSimulation,
    resetResults,
    changePackage,
    changeCase,
};
