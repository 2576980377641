import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import BaseLoads from './';

const mapStateToProps = (
    {
        form
    },
    {
        accessor
    }
) => {
    const selector = formValueSelector('model');
    const isOccupied = selector({form}, `${accessor}.occupancy.isOccupied`);
    const numAdults = selector({form}, `${accessor}.occupancy.numAdults`) || 0;
    const numChildren = selector({form}, `${accessor}.occupancy.numChildren`) || 0;
    const numInfants = selector({form}, `${accessor}.occupancy.numInfants`) || 0;
    const totalOccupants = numAdults + numChildren + numInfants;

    const numDwellingUnits = selector({form}, 'modelData.specifications.murbSpecs.numDwellingUnits') || 0;
    const numNonResUnits = selector({form}, 'modelData.specifications.murbSpecs.numNonResUnits') || 0;
    
    

    const isAdvUserSpec = selector({form}, `${accessor}.advanced.enabled`);
    const isAdvGasStove = selector({form}, `${accessor}.advanced.userSpecified.gasStoveEnabled`);
    const isAdvGasDryer = selector({form}, `${accessor}.advanced.userSpecified.gasDryerEnabled`);
    const advHotWaterTempValue = selector({form}, `${accessor}.advanced.userSpecified.hotWaterTemp`);
    const {value: gasStoveConsumptionValue} = selector({form}, `${accessor}.advanced.userSpecified.gasStove`) || {};
    const {value: gasDryerConsumptionValue} = selector({form}, `${accessor}.advanced.userSpecified.gasDryer`) || {};

    const atHomeAdultsUnits = selector({form}, `${accessor}.occupancy.atHomeAdults_u`);
    const atHomeChildrenUnits = selector({form}, `${accessor}.occupancy.atHomeAdults_u`);
    const atHomeInfantsUnits = selector({form}, `${accessor}.occupancy.atHomeAdults_u`);
    const basementIntGainsUnits = selector({form}, `${accessor}.basementFractionOfInternalGains_u`);
    const summaryElecAppliancesUnits = selector({form}, `${accessor}.summary.elecApplConsumption_u`);
    const summaryLightingUnits = selector({form}, `${accessor}.summary.lightingConsumption_u`);
    const summaryOtherUnits = selector({form}, `${accessor}.summary.otherElecConsumption_u`);
    const summaryExteriorUnits = selector({form}, `${accessor}.summary.exteriorUseConsumption_u`);
    const summaryHotWaterUnits = selector({form}, `${accessor}.summary.hotWaterLoad_u`);
    const advHotWaterTempUnits = selector({form}, `${accessor}.advanced.userSpecified.hotWaterTemp_u`);
    const advGasStoveUnits = selector({form}, `${accessor}.advanced.userSpecified.gasStove_u`);
    const advGasDryerUnits = selector({form}, `${accessor}.advanced.userSpecified.gasDryer_u`);
    const commonSpaceConsumptionUnits = selector({form}, `${accessor}.commonSpaceElectricalConsumption_u`);
    const modelUnits = selector({form}, `modelData.uiSettings.primaryUnits`) || {};

    const { id:buildingTypeId } = selector({form}, `modelData.specifications.buildingType`) || {}; 
    const isMURB = buildingTypeId !== 0;
    const isWholeBuildingMURB = buildingTypeId === 2;

    const totalMURBUnits = isWholeBuildingMURB ? numDwellingUnits + numNonResUnits : 1;
    
    return {
        accessor,
        isOccupied,
        totalOccupants,
        isMURB,
        isWholeBuildingMURB,
        totalMURBUnits,
        isAdvUserSpec,
        isAdvGasStove,
        isAdvGasDryer,
        advHotWaterTempValue,
        gasStoveConsumptionValue,
        gasDryerConsumptionValue,
        atHomeAdultsUnits,
        atHomeChildrenUnits,
        atHomeInfantsUnits,
        basementIntGainsUnits,
        summaryElecAppliancesUnits,
        summaryLightingUnits,
        summaryOtherUnits,
        summaryExteriorUnits,
        summaryHotWaterUnits,
        advHotWaterTempUnits,
        advGasStoveUnits,
        advGasDryerUnits,
        commonSpaceConsumptionUnits,
        modelUnits
    }
};

const mapDispatchToProps = () => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BaseLoads);