import React from "react";

import downloadLight from "assets/images/icons/downloadLight.svg";

import Button from "components/Button";

import classes from "./style.module.scss";

const ChecklistDownload = ({ url, filename, hasLinkOut }) => {
    const downloadFileFromUrl = async (url, fileName, event) => {
        event.preventDefault();

        try {
            // Fetch the file data from the URL
            const response = await fetch(url);

            // Check if the request is successful
            if (response.status === 200) {
                // Get the blob data
                const blob = await response.blob();

                // Use the download function to save the blob as a file
                const a = document.createElement("a");
                a.href = window.URL.createObjectURL(blob);
                a.download = fileName || "downloadedFile";
                document.body.appendChild(a);
                a.style.display = "none";
                a.click();
                window.URL.revokeObjectURL(a.href);
                document.body.removeChild(a);
            } else {
                console.error("Failed to download file:", response.status, response.statusText);
            }
        } catch (error) {
            console.error("Failed to fetch file:", error);
        }
    };

    return (
        <Button
            smallPadding
            onClick={(event) => downloadFileFromUrl(url, filename, event)}
            style={{ marginTop: "7px" }}
        >
            <div className={classes.downloadContainer}>
                <img src={downloadLight} alt="download" />
                <span>Download document</span>
            </div>
        </Button>
    );
};

export default ChecklistDownload;
