import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import FloorConstruction from "./";
import { actions } from "features/Model/Enclosure/_ducks";

const { setInitCode, updateCodeDrawer } = actions;

const mapStateToProps = (
    {
        form,
        user: {
            codeLib: {
                FloorsAdded: { codes: slabCodes = {} } = {},
                FloorsAbove: { codes: floorsAboveCodes = {} } = {},
            } = {},
        },
        enclosure: { calculatingRValue = {} } = {},
        upgradeLibrary: { currentPackage = {} },
    },
    { accessor, formName = "model", componentAccessor = accessor, id, selectedUpgrade }
) => {
    const selector = formValueSelector(formName);
    const modelSelector = formValueSelector("model");

    const modelCodes =
        currentPackage?.upgrades?.[selectedUpgrade]?.codes || modelSelector({ form }, "modelData.codes") || {};
    const modelUnits = modelSelector({ form }, `modelData.uiSettings.primaryUnits`) || {};
    const { type: basementInsConfig } = modelSelector({ form }, `${componentAccessor}.configuration`) || {};

    const {
        codeRef: selectedSlabCodeRef,
        id: slabPreBuiltId,
        value: slabPreBuiltValue,
    } = selector({ form }, `${accessor}.floor.slabInsType`) || {};
    const selectedSlabRValue = selector({ form }, `${accessor}.floor.slabInsType_nomRVal`) || 0;

    const slabCodeWarning = selector({ form }, `${accessor}.floor.slabInsType_warning`) || "";

    const { codeRef: selectedFloorsAboveCodeRef } = selector({ form }, `${accessor}.floor.floorsAboveInsType`) || {};
    const selectedFloorsAboveNomRValue = selector({ form }, `${accessor}.floor.floorsAboveInsType_nomRVal`) || 0;

    const floorsAboveCodeWarning = selector({ form }, `${accessor}.floor.floorsAboveInsType_warning`) || "";

    const rValUnitsSlab = selector({ form }, `${accessor}.floor.slabInsType_u`);
    const rValUnitsFloorsAbove = selector({ form }, `${accessor}.floor.floorsAboveInsType_u`);
    const {
        [id]: { floorsAboveInsType: isCalculatingRValue = false, slabInsType: isCalculatingSlabRValue = false } = {},
    } = calculatingRValue;

    return {
        id,
        accessor,
        libSlabCodes: slabCodes,
        libFloorsAboveCodes: floorsAboveCodes,
        modelCodes,
        selectedSlabCodeRef,
        selectedFloorsAboveCodeRef,
        modelUnits,
        rValUnitsSlab,
        rValUnitsFloorsAbove,
        selectedSlabRValue,
        selectedFloorsAboveNomRValue,
        slabPreBuiltId,
        slabPreBuiltValue,
        basementInsConfig,
        slabCodeWarning,
        floorsAboveCodeWarning,
        isCalculatingRValue,
        isCalculatingSlabRValue,
    };
};

const mergeProps = (state, dispatch, own) => ({
    ...state,
    ...dispatch,
    ...own,
});

const mapDispatchToProps = (dispatch) => ({
    setInitCode: async (code, type) => {
        return dispatch(setInitCode(code, type));
    },
    updateCodeDrawer: (updates) => dispatch(updateCodeDrawer({ updates })),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(FloorConstruction);
