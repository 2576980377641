import { isEmpty } from "lodash";
import { getLabel } from "./details";

export const getGeneralData = ({
    modelName = "",
    userEmail = "",
    h2kVersion,
    general = {},
    specifications = {},
    location = {},
}) => {
    const a1 = "File Name";
    const b1 = modelName;

    const spacing = [
        {
            [`${a1}`]: "---",
        },
        {
            [`${a1}`]: "---",
        },
        {
            [`${a1}`]: "---",
        },
    ];

    const { weather: { region: { id: regionId } = {}, location: { id: locationId } = {} } = {} } = location;

    const generalInfoData = [
        { [`${a1}`]: "File ID", [`${b1}`]: general?.file?.fileID || "" },
        { [`${a1}`]: "Previous File ID", [`${b1}`]: general?.file?.prevFileID || "" },
        { [`${a1}`]: "Ownership Type", [`${b1}`]: general?.file?.ownershipType.id || "" },
        { [`${a1}`]: "Property tax roll number", [`${b1}`]: general?.file?.propertyTaxNumber || "" },
        { [`${a1}`]: "On-site energy evaluation date (YYYY/MM/DD)", [`${b1}`]: general?.file?.evaluationDate || "" },
        { [`${a1}`]: "HOT2000 file created by (User Name)", [`${b1}`]: general?.file?.userNameEnteredBy || "" },
        { [`${a1}`]: "Service organization name (User company)", [`${b1}`]: general?.file?.userCompany || "" },
        { [`${a1}`]: "Service Type", [`${b1}`]: "" },
    ];

    const propertyOwnerInfo = {
        propertyOwnershipType: getLabel("ownershipType", general?.file?.ownershipType?.id),
        legalNameOfBuilder: general?.file?.builderName || "",
        homeownerName: `${general?.client?.clientFirstName || ""} ${general?.client?.clientLastName || ""}`,
        telephoneNumber: general?.client?.clientTelephone || "",
        emailAddress: "",
    };

    const propertyOwnerInfoData = [
        { [`${a1}`]: "Property ownership type", [`${b1}`]: propertyOwnerInfo.propertyOwnershipType },
        {
            [`${a1}`]: "Legal name of builder / company / organization",
            [`${b1}`]: propertyOwnerInfo.legalNameOfBuilder,
        },
        { [`${a1}`]: "Homeowner name or company representative", [`${b1}`]: propertyOwnerInfo.homeownerName },
        { [`${a1}`]: "Telephone number", [`${b1}`]: propertyOwnerInfo.telephoneNumber || "" },
        { [`${a1}`]: "Email address", [`${b1}`]: propertyOwnerInfo.emailAddress },
    ];

    const propertyAddress = {
        streetAddress: general?.client?.clientStreetAddress || "",
        city: general?.client?.clientCity || "",
        region: general?.client?.clientProvince || "",
        postalCode: general?.client?.clientPostalCode || "",
    };

    const propertyAddressData = [
        { [`${a1}`]: "Street address", [`${b1}`]: propertyAddress.streetAddress },
        { [`${a1}`]: "City", [`${b1}`]: propertyAddress.city },
        { [`${a1}`]: "Region (Province / Territory)", [`${b1}`]: propertyAddress.region },
        { [`${a1}`]: "Postal Code", [`${b1}`]: propertyAddress.postalCode },
    ];

    const mailingAddress = general?.client?.differentMailingInfo
        ? {
              sameAsAbove: "no",
              streetAddress: general?.client?.clientMailStreetAddress,
              city: general?.client?.clientMailCity,
              region: general?.client?.clientMailProvince,
              postalCode: general?.client?.clientMailPostalCode,
          }
        : {
              sameAsAbove: "yes",
              streetAddress: general?.client?.clientStreetAddress || "",
              city: general?.client?.clientCity || "",
              region: general?.client?.clientProvince || "",
              postalCode: general?.client?.clientPostalCode || "",
          };

    const mailingAddressData = [
        { [`${a1}`]: "Street Address", [`${b1}`]: mailingAddress.streetAddress },
        { [`${a1}`]: "City", [`${b1}`]: mailingAddress.city },
        { [`${a1}`]: "Region (Province / Territory)", [`${b1}`]: mailingAddress.region },
        { [`${a1}`]: "Postal Code", [`${b1}`]: mailingAddress.postalCode },
    ];

    const isMURB = specifications?.buildingType?.id !== 0;

    const { yearBuilt: { id: yearBuiltId, value: yearBuiltValue } = {} } = specifications || {};
    let yearBuiltLabel = yearBuiltValue;
    if (yearBuiltId > 0) {
        yearBuiltLabel = getLabel("yearBuilt", yearBuiltId);
    }

    const propertySpecs = {
        // data: specifications,
        buildingType: getLabel("buildingType", specifications?.buildingType?.id) || "House",
        houseType: isMURB ? "" : getLabel("buildingSubType", specifications?.buildingSubType?.id || 0) || "",
        murbType: isMURB
            ? getLabel("buildingSubType", specifications?.buildingSubType?.id, specifications?.buildingType?.id) || ""
            : "",
        numStoreys: getLabel("numStoreys", specifications?.numStoreys?.id) || "", //
        murbNumStoreys: specifications?.murbSpecs?.numStoreysInBuilding || "",
        murbNumDwellingUnits: specifications?.murbSpecs?.numDwellingUnits || "",
        murbNumNonResUnits: specifications?.murbSpecs?.numNonResUnits || "",
        numUnitsVisited: specifications?.murbSpecs?.numUnitsVisited || "",
        orientation: getLabel("facingDirection", specifications?.facingDirection?.id) || "",
        soilCondition: getLabel("soilCondition", specifications?.soilCondition?.id) || "",
        yearBuilt: yearBuiltLabel || "",
        wallColour: getLabel("wallColour", specifications?.wallColour?.id) || "",
    };

    const propertySpecsData = [
        { [`${a1}`]: "Building type", [`${b1}`]: propertySpecs.buildingType },
        { [`${a1}`]: "House type", [`${b1}`]: propertySpecs.houseType },
        { [`${a1}`]: "MURB type?", [`${b1}`]: propertySpecs.murbType },
        { [`${a1}`]: "Number of storeys", [`${b1}`]: propertySpecs.numStoreys },
        {
            [`${a1}`]: "For Multi-unit: one unit, numbers of storeys in building",
            [`${b1}`]: specifications?.buildingType?.id === 1 ? propertySpecs.murbNumStoreys || "" : "",
        },
        { [`${a1}`]: "Number of Dwelling units", [`${b1}`]: propertySpecs.murbNumDwellingUnits },
        { [`${a1}`]: "Number of Non-residential units", [`${b1}`]: propertySpecs.murbNumNonResUnits },
        {
            [`${a1}`]: "Number of Units visited",
            [`${b1}`]: specifications?.buildingType?.id === 2 ? propertySpecs.numUnitsVisited || "" : "",
        },
        { [`${a1}`]: "Front orientation", [`${b1}`]: propertySpecs.orientation },
        { [`${a1}`]: "Foundation soil condition", [`${b1}`]: propertySpecs.soilCondition },
        { [`${a1}`]: "Year built", [`${b1}`]: propertySpecs.yearBuilt },
        { [`${a1}`]: "Wall colour", [`${b1}`]: propertySpecs.wallColour },
    ];

    const weatherData = [
        { [`${a1}`]: "Weather region", [`${b1}`]: getLabel("weatherRegion", regionId, "default", h2kVersion) },
        { [`${a1}`]: "Weather location", [`${b1}`]: getLabel("weatherLocation", locationId, regionId, h2kVersion) },
    ];

    return [
        ...spacing,
        { [`${a1}`]: "General Information" },
        ...generalInfoData,
        ...spacing,
        { [`${a1}`]: "Property Owner Information" },
        ...propertyOwnerInfoData,
        ...spacing,
        { [`${a1}`]: "Property Address" },
        ...propertyAddressData,
        ...spacing,
        { [`${a1}`]: "Mailing Address" },
        ...mailingAddressData,
        ...spacing,
        { [`${a1}`]: "Property Specifications" },
        ...propertySpecsData,
        ...spacing,
        { [`${a1}`]: "Weather" },
        ...weatherData,
    ];
};
