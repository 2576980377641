import React from "react";
import _ from "lodash";

import {
    envelopePDFRowHeadings,
    hvacRowPDFHeadings,
    dhwRowHeadings,
    otherRowPDFHeadings,
    simulationRowHeadings,
    simulationF280PDFHeadings,
    simulationFuelHeadings,
    simulationCostHeadings,
    simulationHeatlossHeadings,
    getUpgradeHvacRowLabels,
    getUpgradeEnvelopeRowLabels,
    getSubComponentOptions,
    getComponentOptions,
    stripCeilings,
    stripWindowSkylights,
    getInitComponentUpgradeFields,
    getInitAirtightUpgradeFields,
    getUpgradeDhwRowLabels,
    getUpgradeOtherRowLabels,
    simulationEnergyUseHeadings,
} from "utils/upgrades";

import {
    baseDefault,
    buildRow,
    buildUpgradeCell,
    buildUpgradeRow,
    buildGraphRows,
    buildGraphContentHeadings,
    buildHorizontalBarGraph,
} from "utils/upgrades/upgradesPDF";

import UpgradesTablePDFLedger from "./UpgradesTablePDFLedger/UpgradesTablePDFLedger";
import UpgradesTablePDFLandscape from "./UpgradesTablePDFLandscape/UpgradesTablePDFLandscape";

const opCondCases = [
    {
        value: "soc",
        label: "Standard Operating Conditions",
    },
    // {
    //     value: "ersReference",
    //     upgradesValue: "ersReference",
    //     label: "ERS Reference House",
    // },
    {
        value: "hcv",
        label: "Continous Schedule Ventilation",
    },
    {
        value: "hoc",
        label: "Household Operating Conditions",
    },
    {
        value: "roc",
        label: "Reduced Operating Conditions",
    },
    {
        value: "general",
        label: "General Mode",
    },
];

const getMatchingLabel = (value, opts, defaultVal = "") => {
    const { label = defaultVal } = opts.find((el) => value === el.value) || {};
    return label;
};

const UpgradesTablePDF = ({
    pdfHeaderText,
    baseUi,
    components,
    airtightness,
    codes,
    heatingCooling,
    domesticHotWater,
    ventilation,
    generation,
    program,
    baseLoads,
    dimensions,
    fuelPrices = {},
    selectedUpgradesPackages,
    upgradePackages,
    clientData,
    upgrades,
    packageResults,
    pageFormat,
}) => {
    const { base: { metadata: { mode = "general" } = {} } = {} } = packageResults;
    const isGeneralMode = mode === "general";

    const selectedPackages = upgradePackages.filter((pkg) => _.includes(selectedUpgradesPackages, pkg.id));
    let selectedPackagesUpgrades = [];

    const upgradesKeys = Object.keys(packageResults);

    for (let i = 0; i < upgradesKeys.length; i++) {
        const key = upgradesKeys[i];

        const selectedPackagesId = selectedPackages.map((p) => p.id);

        if (key !== "base" || (!_.includes(selectedPackagesId, key) && selectedPackagesUpgrades[key]))
            delete selectedPackagesUpgrades[key];

        if (key === "base" || (_.includes(selectedPackagesId, key) && !selectedPackagesUpgrades[key]))
            selectedPackagesUpgrades[key] = packageResults[key];
    }

    const { hideRows = false, hiddenRows = {} } = upgrades || {};

    const rowFilter = ({ id }) => !hiddenRows[id];

    const packageUiSpecs = {
        base: baseUi,
        ...upgradePackages.reduce((cache, curr) => ({ ...cache, [curr.id]: { id: curr.id, ui: curr.ui } }), {}),
    };

    const { name: baseName = "" } = baseUi || {};

    const baseCaseOpts = [
        {
            value: "base",
            label: baseName || "Existing Home",
        },
        ...upgradePackages.reduce((cache, curr) => [...cache, { value: curr.id, label: curr.name }], []),
    ];

    const defaultOpCond = isGeneralMode ? "general" : "soc";

    const simulationDetailsRows = [
        {
            id: "operatingConditions",
            house: { content: "Operating Conditions" },
            base: {
                content: getMatchingLabel(packageUiSpecs?.base?.ui?.operatingConditions || defaultOpCond, opCondCases),
            },
            ...selectedPackages.reduce(
                (cache, { id }) => ({
                    ...cache,
                    [id]: {
                        content: getMatchingLabel(
                            packageUiSpecs[id]?.ui?.operatingConditions || defaultOpCond,
                            opCondCases
                        ),
                    },
                }),
                {}
            ),
        },
        {
            id: "relativeBaseCase",
            house: { content: "Relative Base Case" },
            base: { content: getMatchingLabel(packageUiSpecs?.base?.ui?.relativeBaseCase || "base", baseCaseOpts) },
            ...selectedPackages.reduce(
                (cache, { id }) => ({
                    ...cache,
                    [id]: {
                        content: getMatchingLabel(packageUiSpecs[id]?.ui?.relativeBaseCase || "base", baseCaseOpts),
                    },
                }),
                {}
            ),
        },
    ];

    // Generating rows for each section
    const envelopeRows =
        selectedPackages.length > 0
            ? envelopePDFRowHeadings.filter(rowFilter).map((obj) =>
                  buildRow({
                      ...obj,
                      hideRows,
                      hiddenRows,
                      columns: {
                          base: {
                              ...baseDefault,
                              content: getUpgradeEnvelopeRowLabels({
                                  components,
                                  codes,
                                  airtightness,
                                  rowId: obj.id,
                              })?.label,

                              // verticalAlign: "top",
                          },
                          ...selectedPackages.reduce((packagesObj, upgradePackage) => {
                              const { id, upgrades = {} } = upgradePackage;
                              const { label, upgradeIds } = getUpgradeEnvelopeRowLabels({
                                  components,
                                  codes,
                                  airtightness,
                                  rowId: obj.id,
                                  upgradePackage: {
                                      [id]: upgradePackage,
                                  },
                              });

                              let availableComponents = ["window", "door", "floorHeader"].includes(obj.upgradeType)
                                  ? getSubComponentOptions(
                                        obj.upgradeType,
                                        components,
                                        upgrades,
                                        "" // We're checking for an empty ID here
                                    )
                                  : getComponentOptions(obj.upgradeType, components, upgrades, "");

                              if (obj.id === "window" || obj.id === "skylights") {
                                  availableComponents = stripWindowSkylights(availableComponents, obj.id);
                              } else if (obj.id === "ceilingWithAttic" || obj.id === "cathedral") {
                                  availableComponents = stripCeilings(availableComponents, components, obj.id);
                              }

                              const componentsRemaining = availableComponents.some(({ disabled }) => !disabled);

                              //Include airtightness
                              let initUpgradeData = {};
                              if (componentsRemaining && availableComponents.length > 0) {
                                  initUpgradeData = getInitComponentUpgradeFields(
                                      components,
                                      availableComponents[0].value,
                                      obj.upgradeType
                                  );
                              } else if (obj.upgradeType === "airTightness") {
                                  initUpgradeData = getInitAirtightUpgradeFields(airtightness, dimensions);
                              }

                              const upgradesObj =
                                  upgradeIds.length === 0
                                      ? buildUpgradeCell({
                                            empty: true,

                                            upgrades: [
                                                {
                                                    displayLabel: label,
                                                },
                                            ],
                                        })
                                      : buildUpgradeCell({
                                            empty: false,

                                            componentsRemaining,
                                            upgrades: upgradeIds.map((upgradeId) => {
                                                return {
                                                    displayLabel: upgrades[upgradeId]?.label?.[obj.id] || label,
                                                    upgradeType: obj.id,
                                                    upgradeId,
                                                    cost: upgrades[upgradeId]?.cost?.[obj.id]?.total,
                                                };
                                            }),
                                        });

                              return {
                                  ...packagesObj,
                                  [id]: upgradesObj,
                              };
                          }, {}),
                      },
                  })
              )
            : [];

    const hvacRows =
        selectedPackages.length > 0
            ? hvacRowPDFHeadings.filter(rowFilter).map((obj) =>
                  buildRow({
                      ...obj,
                      hideRows,
                      hiddenRows,
                      columns: {
                          base: {
                              ...baseDefault,
                              content: getUpgradeHvacRowLabels({
                                  heatingCooling,
                                  ventilation,
                                  rowId: obj.id,
                              })?.label,
                              // verticalAlign: "top",
                          },
                          ...selectedPackages.reduce((packagesObj, upgradePackage) => {
                              const { id, upgrades = {}, upgradeSettings = {} } = upgradePackage;
                              const { label, upgradeIds } = getUpgradeHvacRowLabels({
                                  heatingCooling,
                                  ventilation,
                                  rowId: obj.id,
                                  upgradePackage: {
                                      [id]: upgradePackage,
                                  },
                              });

                              const upgradesObj =
                                  upgradeIds.length === 0
                                      ? buildUpgradeCell({
                                            empty: true,

                                            upgrades: [
                                                {
                                                    displayLabel: label,
                                                },
                                            ],
                                        })
                                      : buildUpgradeCell({
                                            empty: false,
                                            componentsRemaining: false,
                                            upgrades: upgradeIds.map((upgradeId) => {
                                                return {
                                                    displayLabel: upgrades[upgradeId]?.label?.[obj.id] || label,
                                                    upgradeType: obj.id,
                                                    upgradeId,
                                                    cost: upgrades[upgradeId]?.cost?.[obj.id]?.total,
                                                };
                                            }),
                                        });

                              return {
                                  ...packagesObj,
                                  [id]: upgradesObj,
                              };
                          }, {}),
                      },
                  })
              )
            : [];

    const dhwRows =
        selectedPackages.length > 0
            ? dhwRowHeadings.filter(rowFilter).map((obj) =>
                  buildRow({
                      ...obj,
                      hideRows,
                      hiddenRows,
                      columns: {
                          base: {
                              ...baseDefault,
                              content: getUpgradeDhwRowLabels({
                                  domesticHotWater,
                                  heatingCooling,
                                  rowId: obj.id,
                              })?.label,
                              // verticalAlign: "top",
                          },
                          ...selectedPackages.reduce((packagesObj, upgradePackage) => {
                              const { id, upgrades = {}, upgradeSettings = {} } = upgradePackage;
                              const { label, upgradeIds } = getUpgradeDhwRowLabels({
                                  domesticHotWater,
                                  heatingCooling,
                                  rowId: obj.id,
                                  upgradePackage: {
                                      [id]: upgradePackage,
                                  },
                              });

                              const initUpgradeData = {
                                  primaryHotWater: {
                                      fields: domesticHotWater,
                                  },
                                  dwhr: {
                                      fields: domesticHotWater,
                                  },
                              }[obj.id];

                              const upgradesObj =
                                  upgradeIds.length === 0
                                      ? buildUpgradeCell({
                                            empty: true,
                                            upgrades: [
                                                {
                                                    displayLabel: label,
                                                },
                                            ],
                                        })
                                      : buildUpgradeCell({
                                            empty: false,
                                            componentsRemaining: false,
                                            upgrades: upgradeIds.map((upgradeId) => {
                                                return {
                                                    displayLabel: upgrades[upgradeId]?.label?.[obj.id] || label,
                                                    upgradeType: obj.id,
                                                    upgradeId,
                                                    cost: upgrades[upgradeId]?.cost?.[obj.id]?.total,
                                                };
                                            }),
                                        });

                              return {
                                  ...packagesObj,
                                  [id]: upgradesObj,
                              };
                          }, {}),
                      },
                  })
              )
            : [];

    const otherRows =
        selectedPackages.length > 0
            ? otherRowPDFHeadings.filter(rowFilter).map((obj) =>
                  buildRow({
                      ...obj,
                      hideRows,
                      hiddenRows,
                      columns: {
                          base: {
                              ...baseDefault,
                              content: getUpgradeOtherRowLabels({
                                  generation,
                                  program,
                                  baseLoads,
                                  rowId: obj.id,
                              })?.label,
                              // verticalAlign: "top",
                          },
                          ...selectedPackages.reduce((packagesObj, upgradePackage) => {
                              const { id, upgrades = {}, upgradeSettings = {} } = upgradePackage;
                              const { label, upgradeIds } = getUpgradeOtherRowLabels({
                                  generation,
                                  program,
                                  baseLoads,
                                  rowId: obj.id,
                                  upgradePackage: {
                                      [id]: upgradePackage,
                                  },
                              });

                              const initUpgradeData = {
                                  generation: {
                                      fields: generation,
                                  },
                                  baseLoads: {
                                      fields: baseLoads,
                                  },
                              }[obj.upgradeType];

                              const upgradesObj =
                                  upgradeIds.length === 0
                                      ? buildUpgradeCell({
                                            empty: true,
                                            upgrades: [
                                                {
                                                    displayLabel: label,
                                                },
                                            ],
                                        })
                                      : buildUpgradeCell({
                                            empty: false,
                                            componentsRemaining: false,
                                            upgrades: upgradeIds.map((upgradeId) => {
                                                return {
                                                    displayLabel: upgrades[upgradeId]?.label?.[obj.id] || label,
                                                    upgradeType: obj.id,
                                                    upgradeId,
                                                    cost: upgrades[upgradeId]?.cost?.[obj.id]?.total,
                                                };
                                            }),
                                        });

                              return {
                                  ...packagesObj,
                                  [id]: upgradesObj,
                              };
                          }, {}),
                      },
                  })
              )
            : [];

    const energyEndUseRows =
        selectedPackages.length > 0
            ? [...simulationRowHeadings, ...simulationF280PDFHeadings].filter(rowFilter).map((props, index) =>
                  buildUpgradeRow({
                      ...props,
                      upgradePackages: selectedPackages,
                      allPackageResults: packageResults,
                      packageUiSpecs: packageUiSpecs,
                      packageResults: selectedPackagesUpgrades,
                      rowIndex: index,
                      hideRows,
                      hiddenRows,
                  })
              )
            : [];

    const simulationFuelConsuptionRows =
        selectedPackages.length > 0
            ? simulationFuelHeadings.filter(rowFilter).map((props, index) =>
                  buildUpgradeRow({
                      ...props,
                      upgradePackages: selectedPackages,
                      allPackageResults: packageResults,
                      packageUiSpecs: packageUiSpecs,
                      packageResults: selectedPackagesUpgrades,
                      rowIndex: index,
                      hideRows,
                      hiddenRows,
                  })
              )
            : [];

    const simulationEstimateCostRows =
        selectedPackages.length > 0
            ? simulationCostHeadings.filter(rowFilter).map((props, index) =>
                  buildUpgradeRow({
                      ...props,
                      upgradePackages: selectedPackages,
                      allPackageResults: packageResults,
                      packageUiSpecs: packageUiSpecs,
                      packageResults: selectedPackagesUpgrades,
                      rowIndex: index,
                      fuelPrices,
                      hideRows,
                      hiddenRows,
                  })
              )
            : [];

    const energyEndUseGraphs = buildGraphRows({
        ...buildGraphContentHeadings(simulationEnergyUseHeadings),
        ...buildHorizontalBarGraph(
            simulationEnergyUseHeadings,
            selectedPackages,
            selectedPackagesUpgrades,
            packageUiSpecs
        ),
    });

    const heatlossGraphs = buildGraphRows({
        ...buildGraphContentHeadings(simulationHeatlossHeadings),
        ...buildHorizontalBarGraph(
            simulationHeatlossHeadings,
            selectedPackages,
            selectedPackagesUpgrades,
            packageUiSpecs
        ),
    });

    return pageFormat === "ledger" ? (
        <UpgradesTablePDFLedger
            pdfHeaderText={pdfHeaderText}
            clientData={clientData}
            envelopeRows={envelopeRows}
            hvacRows={hvacRows}
            dhwRows={dhwRows}
            otherRows={otherRows}
            energyEndUseRows={[...simulationDetailsRows, ...energyEndUseRows]}
            simulationFuelConsuptionRows={simulationFuelConsuptionRows}
            simulationEstimateCostRows={simulationEstimateCostRows}
            energyEndUseGraphs={energyEndUseGraphs}
            heatlossGraphs={heatlossGraphs}
            selectedPackages={selectedPackages}
        />
    ) : (
        <UpgradesTablePDFLandscape
            pdfHeaderText={pdfHeaderText}
            clientData={clientData}
            envelopeRows={envelopeRows}
            dhwRows={dhwRows}
            hvacRows={hvacRows}
            otherRows={otherRows}
            energyEndUseRows={[...simulationDetailsRows, ...energyEndUseRows]}
            simulationFuelConsuptionRows={simulationFuelConsuptionRows}
            simulationEstimateCostRows={simulationEstimateCostRows}
            energyEndUseGraphs={energyEndUseGraphs}
            heatlossGraphs={heatlossGraphs}
            selectedPackages={selectedPackages}
        />
    );
};

export default UpgradesTablePDF;
