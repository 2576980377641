import { connect } from "react-redux";
import { reduxForm, formValueSelector, reset } from "redux-form";
import StandardCode from "features/Model/Enclosure/ComponentList/Wall/Construction/WallStandardCode";
import wallCodeTemplate from "features/Model/Enclosure/Templates/wallCode.json";
import { actions as userActions } from "store/users";
import { actions as enclosureActions } from "features/Model/Enclosure/_ducks";
import moment from "moment";
import isEmpty from "lodash/isEmpty";

const { addToCodeLib } = userActions;
const { setInitCode, getRValue } = enclosureActions;

const mapStateToProps = (
    {
        form,
        model:{
            modelId,
        },
        user:{
            uid,
        },
        enclosure:{
            initCodeValues:{
                wallInsType={}
            }={}
        }={}
    },
    {
        drawingFormChange,
        componentId,
        modelFormChange,
        toggleOpen,
        handleUdefSave
    }
) => {
    const selector = formValueSelector('drawingWallCode');
    // const modelSelector = formValueSelector('model');
    const { id:wallStructureId, value:wallStructureValue = 0 } = selector({form}, 'wallCode.layers.structureType') || {};
    const { value:wallTypeSizeValue } = selector({form}, 'wallCode.layers.componentTypeSize') || {};
    const { id:wallSpacingId, value:wallSpacingValue } = selector({form}, 'wallCode.layers.spacing') || {};
    const { value:wallFramingValue } = selector({form}, 'wallCode.layers.framing') || {};
    const { value:wallIns1Value } = selector({form}, 'wallCode.layers.insulationLayer1') || {};
    const { value:wallIns2Value } = selector({form}, 'wallCode.layers.insulationLayer2') || {};
    const { value:wallInteriorValue } = selector({form}, 'wallCode.layers.interior') || {};
    const { value:wallSheathingValue } = selector({form}, 'wallCode.layers.sheathing') || {};
    const { value:wallExteriorValue } = selector({form}, 'wallCode.layers.exterior') || {};
    const { value:wallStudsValue } = selector({form}, 'wallCode.layers.studsCornerIntersection') || {};
    const wallLabel = selector({form}, 'wallCode.label') || '';

    const frameSpacingValue = wallSpacingId === null ? wallFramingValue : wallSpacingValue;

    const wallValue = `1${wallStructureValue.toString().substr(-1)}${wallTypeSizeValue}${frameSpacingValue}${wallIns1Value}${wallIns2Value}${wallInteriorValue}${wallSheathingValue}${wallExteriorValue}${wallStudsValue}`;

    const { codeRef:editingCodeRef=null, isLibCode=false } = wallInsType;

    return {
        wallLabel,
        drawingFormChange,
        initialValues:{
            wallCode: isEmpty(wallInsType) ? wallCodeTemplate : wallInsType,
            addToLibrary:isLibCode
        },
        wallStructureId,
        wallTypeSizeValue,
        isSolid:parseFloat(wallStructureId) === 4,
        isPanel:parseFloat(wallStructureId) === 5,
        isFloorHeader:parseFloat(wallStructureId) === 6,
        makeSpacingDefault:['5','6','7','8','9','A','B','C'].includes(wallTypeSizeValue),
        makeInsulationDefault:['6','7','8','9','A','B','C'].includes(wallTypeSizeValue),
        wallValue,
        modelId,
        uid,
        modelFormChange,
        editingCodeRef,
        isEditing:!!editingCodeRef,
        isLibCode,
        wallSpacingId,
        componentId,
        toggleOpen,
        handleUdefSave
    }
};

const mapDispatchToProps = ( dispatch ) => ({
    clearForm: () => dispatch(reset('drawingWallCode')),
    clearInitCode: () => dispatch(setInitCode({}, 'wallInsType'))
});

const mergeProps = (state, dispatch, own) => ({
    ...state,
    ...dispatch,
    ...own,
});

const onSubmit = async(
    form,
    dispatch,
    {
        toggleOpen,
        uid,
        drawingFormChange,
        editingCodeRef,
        componentId,
        modelFormChange,
        handleUdefSave,
    }
) => {
    const { wallCode={}, addToLibrary=false } = form;
    const { codeRef, value, label, isLibCode, ...rest } = wallCode;

    handleUdefSave();

    // 1. Create and change code ref
    const newCodeRef = `Wall-S-${moment().format('YYYYMMDDHHmmssSS')}`;
    const setCodeRef = editingCodeRef || newCodeRef;

    // ***************************************
    // 2. Fetch rValue
    // ***************************************
    let rVal = 0;
    let nomRVal = 0;
    let warningType = '';

    await dispatch(getRValue({
        codeString: value,
        codeType: 'Wall',
        componentId:componentId,
        fieldId:'wallInsType'
    }))
        .then(({
            rVal:newRVal,
            warningType:warning
        }) => {
            rVal = parseFloat(newRVal.toFixed(2));
            nomRVal = parseFloat(newRVal.toFixed(2));
            warningType = warning;
        });

    const code = { ...rest, nominalRValue:nomRVal, label, value, codeRef:setCodeRef, warningType};

    // 3.1. If code value is already in model, update using existing ref
    modelFormChange(`modelData.codes.${setCodeRef}`, code);

    const fieldValue = {
        codeLabel:label,
        codeRef:setCodeRef,
    };

    //Add warning tag to field
    if (warningType === 'compression') {
        drawingFormChange(`components.${componentId}.wallInsType_warning`, 'compression');
    } else {
        drawingFormChange(`components.${componentId}.wallInsType_warning`, '');
    }
    
    // Update model "wall type" field in wall
    drawingFormChange(`components.${componentId}.wallInsType`, fieldValue);
    drawingFormChange(`components.${componentId}.wallInsType_nomRVal`, nomRVal);
    drawingFormChange(`components.${componentId}.wallInsType_effRVal`, nomRVal);

    if (addToLibrary) {
        await dispatch(addToCodeLib(
            uid,
            {
                ...rest,
                nominalRValue:nomRVal,
                label,
                codeType:"Standard",
                componentType:"Wall",
                value,
                warningType
            },
            "Wall",
            setCodeRef
        ));
    }

    // Clear wall code defaults for next time
    dispatch(setInitCode({}, 'wallInsType'));
    // Reset form
    dispatch(reset('drawingWallCode'));

};

const form = reduxForm({
    form:"drawingWallCode",
    enableReinitialize: true,
    onSubmit:onSubmit
})(StandardCode);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(form);