import EXIF from "exif-js";

export const getTags = (imagesData, currentTags) => {
    let allTagIds = currentTags.map((tag) => tag.id);

    imagesData.forEach((image) => {
        if (!image.tags) return;
        if (image.tags.length === 0) return;

        image.tags.forEach((tag) => {
            if (allTagIds.includes(tag.id)) return;

            currentTags.push({ ...tag, isCustom: true });
            allTagIds.push(tag.id);
        });
    });

    return currentTags;
};

export const filterByTagSelection = (img, selectedTag) => {
    switch (selectedTag) {
        case "all":
            return img;
        case "untagged":
            return img.tags.length === 0;
        default:
            return img.tags.map((t) => t.id).includes(selectedTag);
    }
};

const getStatusValue = (status) => {
    switch (status) {
        case "pre_construction":
            return 1;
        case "mid_construction":
            return 2;
        case "post_construction":
            return 3;
        default:
            return 4; // Handles any unexpected status by placing it at the end
    }
};

export const sortByLastUploaded = (a, b) => b.uploadedDate - a.uploadedDate;

export const sortByFirstUploaded = (a, b) => a.uploadedDate - b.uploadedDate;

export const sortByNameAZ = (a, b) => a.name.localeCompare(b.name);

export const sortByNameZA = (a, b) => b.name.localeCompare(a.name);

export const sortByStatus = (a, b) => getStatusValue(a.status) - getStatusValue(b.status);

export const imageRead = (file) => {
    return new Promise((resolve, reject) => {
        EXIF.getData(file, function () {
            const exifdata = EXIF.getAllTags(this);
            resolve(exifdata);
        });
    });
};
