import React, { useCallback, useRef, useState } from "react";
import classes from "./styles.module.scss";
import { useReactToPrint } from "react-to-print";
import html2pdf from "html2pdf.js";

import Drawer from "components/Drawer";
import Button from "components/Button";
import Input from "components/Input";
import Checkbox from "components/Input/Checkbox";

import Export from "assets/images/icons/JSX/Export";

import F280PDF from "./f280Pdf";

import { pdfBtnsContainer, inputFields } from "./styles.module.scss";

const ExportF280PDF = ({
    isPdfExportOpen,
    handleClose,
    displayData,
    name,
    calcPerformedBy = {},
    heatLossValue,
    latHeatGainValue,
    heatUnit,
}) => {
    const ref = useRef(null);
    const [exporting, toggleExporting] = useState(false);

    const [inputFieldsData, setInputFieldsData] = useState({
        issuedForTheUseOf: "",
        projectNo: "",
        lot: "",
        site: "",
        notes: "",
        additionalDocsList: "",
        dimInfoBasedOn: "",
        disgnSummary: false,
        roomByRoomResults: false,
    });

    const handleChange = (value, name) => setInputFieldsData({ ...inputFieldsData, [name]: value });

    const printHtmlToPdf = useReactToPrint({
        content: () => ref.current,
        documentTitle: `${name}_f280_report.pdf`,
        print: async (printIframe) => {
            const document = printIframe.contentDocument;

            if (document) {
                const html = document.getElementById("f280Print");

                const opt = {
                    filename: `${name}_f280_report.pdf`,
                    image: { type: "jpeg", quality: 1 },
                    html2canvas: { dpi: 192, scale: 4 },
                    jsPDF: { unit: "px", orientation: "portrait", format: [970, 1580] },
                };

                html2pdf().from(html).set(opt).save();
            }
        },
    });

    return (
        <Drawer
            open={isPdfExportOpen}
            close={handleClose}
            title={`Export F280 Report`}
            subtitle="Please provide the following information for your F280 report, and make sure to sign or stamp the bottom right area of the PDF."
        >
            <div className={inputFields}>
                <Input
                    name={"issuedForTheUseOf"}
                    label={"Documents issued for the use of:"}
                    type={"text"}
                    input={{
                        value: inputFieldsData.issuedForTheUseOf,
                        onChange: (value, name) => handleChange(value, name),
                    }}
                />
                <Input
                    name={"projectNo"}
                    label={"Project number"}
                    type={"text"}
                    input={{
                        value: inputFieldsData.projectNo,
                        onChange: (value, name) => handleChange(value, name),
                    }}
                />
                <Input
                    name={"site"}
                    label={"Building location - Site"}
                    type={"text"}
                    input={{
                        value: inputFieldsData.site,
                        onChange: (value, name) => handleChange(value, name),
                    }}
                />
                <Input
                    name={"lot"}
                    label={"Building location - Lot"}
                    type={"text"}
                    input={{
                        value: inputFieldsData.lot,
                        onChange: (value, name) => handleChange(value, name),
                    }}
                />

                <Input
                    name={"dimInfoBasedOn"}
                    label={"Dimensional information based on:"}
                    type={"text"}
                    input={{
                        value: inputFieldsData.dimInfoBasedOn,
                        onChange: (value, name) => handleChange(value, name),
                    }}
                />
                <Input
                    name={"notes"}
                    label={"Notes"}
                    type={"text"}
                    input={{
                        value: inputFieldsData.notes,
                        onChange: (value, name) => handleChange(value, name),
                    }}
                />
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                    }}
                >
                    <Input
                        name={"additionalDocsList"}
                        label={"List any additional documents"}
                        type={"text"}
                        input={{
                            value: inputFieldsData.additionalDocsList,
                            onChange: (value, name) => handleChange(value, name),
                        }}
                        style={{ flex: 1 }}
                    />
                </div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                    }}
                >
                    <Checkbox
                        style={{ marginTop: "20px", flex: 1 }}
                        input={{
                            value: inputFieldsData.disgnSummary,
                            onChange: (event) => handleChange(event.target.checked, "disgnSummary"),
                        }}
                        label={"Design Summary"}
                    />
                    <Checkbox
                        style={{ marginTop: "20px", flex: 1 }}
                        input={{
                            value: inputFieldsData.roomByRoomResults,
                            onChange: (event) => handleChange(event.target.checked, "roomByRoomResults"),
                        }}
                        label={"Room by Room"}
                    />
                </div>
            </div>
            <div className={pdfBtnsContainer}>
                <Button type="hollow" onClick={handleClose} style={{ width: "144px" }}>
                    Cancel
                </Button>
                <Button
                    // smallPadding
                    icon={Export}
                    onClick={() => printHtmlToPdf()}
                    disabled={exporting}
                    className={classes.exportButton}
                >
                    {exporting ? "Exporting..." : "Export PDF"}
                </Button>
                <div
                    style={{
                        visibility: "hidden",
                        height: 0,
                        overflow: "hidden",
                        width: 0,
                        letterSpacing: "0.01px",
                    }}
                >
                    <F280PDF
                        pdfRef={ref}
                        displayData={displayData}
                        inputFieldsData={inputFieldsData}
                        calcPerformedBy={calcPerformedBy}
                        name={name}
                        heatLossValue={heatLossValue}
                        latHeatGainValue={latHeatGainValue}
                        heatUnit={heatUnit}
                        // notes={notes}
                    />
                </div>
            </div>
        </Drawer>
    );
};

export default ExportF280PDF;
