import React from "react";
import { Field } from "redux-form";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import InputWithUnits from "components/Input/InputWithUnits";
import { getValidation, getDecimalPlaces } from "utils/fieldValidation";
import { getBaseUnits, getUnitOptions } from "utils/fields";

const heightValidation = getValidation("doorHeight");
const widthValidation = getValidation("doorWidth");
const areaValidation = getValidation("doorArea");

export default React.memo(
    ({
        accessor,
        doorHeight = 0,
        doorWidth = 0,
        modelUnits,
        heightUnits,
        widthUnits,
        areaUnits,
        change,
        isDrawingComponent,
        doorWidthEquation,
        doorHeightEquation,
    }) => (
        <InputRow gridTemplate="1fr 1fr 1fr">
            <Field
                component={InputWithUnits}
                type="number"
                name={`${accessor}.measurements.width`}
                label="Door Width"
                placeholder="0.00"
                validate={widthValidation}
                decimals={getDecimalPlaces("doorWidth")}
                disabled={isDrawingComponent}
                isDrawing={isDrawingComponent}
                change={change}
                units={{
                    base: getBaseUnits("doorWidth", modelUnits),
                    options: getUnitOptions("length"),
                    selected: widthUnits,
                    unitType: "length",
                    accessor: `${accessor}.measurements.width_u`,
                }}
                withEquation
                equation={doorWidthEquation}
                info={
                    "For a single door, use HOT2000 defaults. For door systems (e.g. double doors, a door with a sidelight) enter the overall Width and Height of the complete door system (i.e. including the outer edge of the door system's frame)."
                }
            />
            <Field
                component={InputWithUnits}
                type="number"
                name={`${accessor}.measurements.height`}
                label="Door Height"
                placeholder="0.00"
                validate={heightValidation}
                decimals={getDecimalPlaces("doorHeight")}
                change={change}
                disabled={isDrawingComponent}
                isDrawing={isDrawingComponent}
                units={{
                    base: getBaseUnits("doorHeight", modelUnits),
                    options: getUnitOptions("length"),
                    selected: heightUnits,
                    unitType: "length",
                    accessor: `${accessor}.measurements.height_u`,
                }}
                withEquation
                equation={doorHeightEquation}
                info={
                    "For a single door, use HOT2000 defaults. For door systems (e.g. double doors, a door with a sidelight) enter the overall Width and Height of the complete door system (i.e. including the outer edge of the door system's frame)."
                }
            />
            <Field
                component={InputWithUnits}
                type="number"
                name={`${accessor}.measurements.area`}
                label="Door Area"
                placeholder="0.00"
                disabled={true}
                setValue={doorHeight * doorWidth}
                validate={areaValidation}
                decimals={getDecimalPlaces("doorArea")}
                change={change}
                units={{
                    base: getBaseUnits("doorArea", modelUnits),
                    options: getUnitOptions("area"),
                    selected: areaUnits,
                    unitType: "area",
                    accessor: `${accessor}.measurements.area_u`,
                }}
            />
        </InputRow>
    )
);
