import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import ReducedOperating from './';


const mapStateToProps = (
    {
        form
    },
    {
        accessor
    }
) => {
    const selector = formValueSelector('model');
    const dryerUnits = selector({form}, `${accessor}.appliances.dryer_u`)
    const washerUnits = selector({form}, `${accessor}.appliances.washer_u`)
    const dishWasherUnits = selector({form}, `${accessor}.appliances.dishWasher_u`)
    const fridgeUnits = selector({form}, `${accessor}.appliances.refrigerator_u`)
    const rangeUnits = selector({form}, `${accessor}.appliances.range_u`)
    const modelUnits = selector({form}, `modelData.uiSettings.primaryUnits`) || {};

    return {
        accessor,
        dryerUnits,
        washerUnits,
        dishWasherUnits,
        fridgeUnits,
        rangeUnits,
        modelUnits
    }
};

const mapDispatchToProps = () => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReducedOperating);