import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import WallMeasurements from "./";
import isEmpty from "lodash/isEmpty";

const mapStateToProps = ({ form }, { accessor }) => {
    const selector = formValueSelector("model");
    const wallHeight = selector({ form }, `${accessor}.wall.measurements.height`) || {};
    const wallDepth = selector({ form }, `${accessor}.wall.measurements.depth`) || {};

    const wallHeightDrawing = selector({ form }, `${accessor}.wall.measurements.height_drawingRef`) || {};
    const wallDepthDrawing = selector({ form }, `${accessor}.wall.measurements.depth_drawingRef`) || {};

    const heightUnits = selector({ form }, `${accessor}.wall.measurements.height_u`);
    const depthUnits = selector({ form }, `${accessor}.wall.measurements.depth_u`);
    const modelUnits = selector({ form }, `modelData.uiSettings.primaryUnits`) || {};

    const crawlspaceWallHeightEquation = selector({ form }, `${accessor}.wall.measurements.height_eqn`);
    const crawlspaceWallDepthEquation = selector({ form }, `${accessor}.wall.measurements.depth_eqn`);

    return {
        accessor,
        wallHeight,
        wallDepth,
        modelUnits,
        heightUnits,
        depthUnits,
        wallHeightDrawing: !isEmpty(wallHeightDrawing),
        wallDepthDrawing: !isEmpty(wallDepthDrawing),
        crawlspaceWallHeightEquation,
        crawlspaceWallDepthEquation,
    };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WallMeasurements);
