import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import PVSystemCommissioning from "./";

const mapStateToProps = ({ form }, { accessor }) => {
    const selector = formValueSelector("model");

    const pvSystems = selector({ form }, `${accessor}.systemOrientation.systems`) || {};
    const pvStrings = selector({ form }, `${accessor}.arrayTests.pvStringOpenCircuitVoltage.strings`) || {};

    const totalModules = selector({ form }, `${accessor}.siteInformation.totalModules`) || 0;
    const moduleRating = selector({ form }, `${accessor}.siteInformation.moduleRating`) || 0;
    const currentDeclAddress = selector({ form }, `${accessor}.declaration.address`) || "";

    return {
        accessor,
        pvSystems,
        pvStrings,
        totalModules,
        moduleRating,
        currentDeclAddress,
    };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PVSystemCommissioning);
