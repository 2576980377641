import React, { useState } from 'react';
import classes from './style.module.scss';
import { Field } from 'redux-form';
import { getOptions, getFirstOption, getBaseUnits, getUnitOptions } from 'utils/fields';
import { getDefaultComboSpecs, getDhwTemplate } from 'utils/mechanicals';
import { maxLength, getValidation, getDecimalPlaces } from 'utils/fieldValidation';
import InputRow from 'components/Input/Row';
import Input from 'components/Input';
import InputWithUnits from 'components/Input/InputWithUnits';
import Select from 'components/Input/Select';
import Checkbox from 'components/Input/Checkbox';
import DwhrSystem from 'features/Model/Mechanicals/HotWater/DwhrSystem/container';
import Add from 'assets/images/icons/JSX/Add';
import Tooltip from 'components/Tooltip';

const charLim100 = maxLength(100);
const comboEnerSourceValidation = getValidation('comboEnergySource');
const comboEquipTypeValidation = getValidation('comboEquipType');

const comboCapacityValidation = getValidation('comboOutputCapacity');

const comboSizingFactorValidation = getValidation('comboSizingFactor');

const comboEffValidation = getValidation('comboEff');

const comboPilotValidation = getValidation('comboPilotLight');
const comboFlueValidation = getValidation('comboFlueDiameter');

const tankLocationValidation = getValidation('comboTankLocation');
const tankVolumeValidation = getValidation('comboTankVolume');
const comboEFValidation = getValidation('comboEF');
const circPumpValidation = getValidation('comboCircPumpPower');




export default ({
    accessor,
    isDwhr,
    energySourceId,
    equipTypeId,
    effValue,
    capacityId,
    capacityValue,
    capacityUnits,
    pilotLightValue,
    flueDiameterValue,
    efValue,
    isEfDefaults,
    tankVolumeId,
    tankVolumeValue,
    isCircPumpCalculated,
    pilotUnits,
    flueUnits,
    tankVolumeUnits,
    circPumpPowerUnits,
    modelUnits,
    change,
    handleDelete,
}) => {

    const [menuOpen, toggleMenu] = useState(false);

    const handleCreateDwhr = () => {
        change(`modelData.domesticHotWater.primarySystem.hasDrainWaterHeatRecovery`, true);
        change(`modelData.domesticHotWater.primarySystem.drainWaterHeatRecovery`, getDhwTemplate('dwhr'));
    }

    const handleDeleteDwhr = () => {
        change(`modelData.domesticHotWater.primarySystem.hasDrainWaterHeatRecovery`, false);
        change(`modelData.domesticHotWater.primarySystem.drainWaterHeatRecovery`, {});
    }

    return (
        <>
            <div className={classes.heading}>
                <div
                    className={`${classes.addDHWR} ${isDwhr && classes.disabled}`}
                    onClick={() => handleCreateDwhr()}
                    data-tip="Add Drain Water Heat Recovery"
                    data-for="dwhrHeader"
                >
                    Add DWHR <Add />
                </div>
                <Tooltip id="dwhrHeader" />
            </div>
            <InputRow gridTemplate="23.4% 23.4% 10rem 23.4%">
                <Field
                    component={Input}
                    name={`${accessor}.system.combo.manufacturer`}
                    type="text"
                    label="Manufacturer Name"
                    placeholder="Enter Manufacturer Name"
                    validate={charLim100}
                />
                <Field
                    component={Input}
                    name={`${accessor}.system.combo.model`}
                    type="text"
                    label="Model Name"
                    placeholder="Enter Model Name"
                    validate={charLim100}
                />
                <Field
                    component={Checkbox}
                    name={`${accessor}.system.combo.energyStar`}
                    label="Energy Star"
                    large
                    className={classes.checkbox}
                    type="checkbox"
                />
            </InputRow>
            <InputRow gridTemplate="23.4% 23.4% 10rem 23.4%">
                <Field
                    component={Select}
                    name={`${accessor}.system.combo.energySource`}
                    options={getOptions({fieldKey: `comboEnergySource`})}
                    label="Energy Source"
                    placeholder="Choose Energy Source"
                    validate={comboEnerSourceValidation}
                    onChange={
                        ({ id }) => {
                            const firstOption = getFirstOption({indKey:id, depKey:`comboEquipType`});
                            change(`${accessor}.system.combo.equipmentType`, firstOption);

                            const { efficiency=80,pilotLight=0,flueDiameter=0 } = getDefaultComboSpecs(firstOption.id) || {};
                            change(`${accessor}.system.combo.specifications.efficiency`, efficiency);
                            change(`${accessor}.system.combo.specifications.pilotLight`, pilotLight);
                            change(`${accessor}.system.combo.specifications.flueDiameter`, flueDiameter);
                        }
                    }
                    info={'An electric combo system should be modelled as separate electric boiler and an electric DHW systems.'}
                />
                <Field
                    component={Select}
                    name={`${accessor}.system.combo.equipmentType`}
                    options={getOptions({fieldKey:`comboEquipType`, indKey: energySourceId})}
                    label="Equipment Type"
                    placeholder="Choose Equipment Type"
                    validate={comboEquipTypeValidation}
                    onChange={
                        ({ id }) => {
                            const { efficiency=80,pilotLight=0,flueDiameter=0 } = getDefaultComboSpecs(id) || {};
                            change(`${accessor}.system.combo.specifications.efficiency`, efficiency);
                            change(`${accessor}.system.combo.specifications.pilotLight`, pilotLight);
                            change(`${accessor}.system.combo.specifications.flueDiameter`, flueDiameter);
                        }
                    }
                />
            </InputRow>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr 1fr">
                <Field
                    component={Select}
                    name={`${accessor}.system.combo.specifications.outputCapacity`}
                    options={[
                        {
                            label:"User Specified",
                            value:{
                                id:0,
                                value:capacityValue,
                                uiUnits: 'kW'
                            }
                        },
                        {
                            label:"Calculated",
                            value:{
                                id:1,
                                value:capacityValue,
                                uiUnits: 'kW'
                            }
                        },
                    ]}
                    label="Capacity Type"
                    placeholder="Choose Input Type"
                    validate={comboCapacityValidation}
                    onChange={
                        ({ id }) => {
                            if (id === 1) {
                                change(`${accessor}.fansAndPumps.power.isCalculated`, true);
                            }
                        }
                    }
                    info={'If the capacity is unknown, set field to Calculated. Note that the calculated value will not appear in Volta SNAP, but will be updated prior to simulating in HOT2000.'}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.system.combo.specifications.outputCapacity.value`}
                    label="Output Capacity"
                    placeholder="0.00"
                    validate={comboCapacityValidation}
                    decimals={getDecimalPlaces(`comboOutputCapacity`)}
                    change={change}
                    disabled={capacityId === 1}
                    units={{
                        base:getBaseUnits(`comboOutputCapacity`, modelUnits),
                        options:getUnitOptions('power'),
                        selected:capacityUnits,
                        unitType:'power',
                        accessor:`${accessor}.system.combo.specifications.outputCapacity_u`
                    }}
                />
                <Field
                    component={Input}
                    type="number"
                    name={`${accessor}.system.combo.specifications.sizingFactor`}
                    label="Sizing Factor"
                    placeholder={0.00}
                    validate={comboSizingFactorValidation}
                    decimals={getDecimalPlaces(`comboSizingFactor`)}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.system.combo.specifications.efficiency`}
                    label="Efficiency"
                    placeholder="0.00"
                    validate={comboEffValidation}
                    decimals={getDecimalPlaces(`comboEff`)}
                    change={change}
                    setValue={effValue}
                    units={{
                        base:getBaseUnits(`comboEff`, modelUnits),
                        options:['%'],
                        selected:'%',
                        unitType:'fraction',
                        accessor:`${accessor}.system.combo.specifications.efficiency_u`
                    }}
                    info={'Unless modelling a boiler combo system, leave at default value. Note that the Combined Seasonal Efficienct (CA-AFUE) is not accepted in Canada.'}
                />
                <Field
                    component={Select}
                    name={`${accessor}.system.combo.specifications.isSteadyState`}
                    options={[
                        {
                            label:"Steady State",
                            value: true
                        },
                        {
                            label:"AFUE",
                            value: false
                        },
                    ]}
                    label="Efficiency Type"
                    placeholder="Choose Efficiency Type"
                    info={("For condensing systems, always use the AFUE rating. For non-condensing systems, use AFUE whenever possible.")}
                />
            </InputRow>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.system.combo.specifications.pilotLight`}
                    label="Pilot Light"
                    placeholder="0.00"
                    validate={comboPilotValidation}
                    decimals={getDecimalPlaces(`comboPilotLight`)}
                    change={change}
                    disabled={false}
                    setValue={pilotLightValue}
                    units={{
                        base:getBaseUnits(`comboPilotLight`, modelUnits),
                        options:getUnitOptions('dailyEnergy'),
                        selected:pilotUnits,
                        unitType:'dailyEnergy',
                        accessor:`${accessor}.system.combo.specifications.pilotLight_u`
                    }}
                    info={'Leave at the default value (variable based on system).'}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.system.combo.specifications.flueDiameter`}
                    label="Flue Diameter"
                    placeholder="0.00"
                    validate={comboFlueValidation}
                    decimals={getDecimalPlaces(`comboFlueDiameter`)}
                    change={change}
                    disabled={false}
                    setValue={flueDiameterValue}
                    units={{
                        base:getBaseUnits(`comboFlueDiameter`, modelUnits),
                        options:getUnitOptions('length'),
                        selected:flueUnits,
                        unitType:'length',
                        accessor:`${accessor}.system.combo.specifications.flueDiameter_u`
                    }}
                    info={'Leave at the default value (variable based on system).'}
                />
            </InputRow>
            <h4 style={{marginBottom:"1.25rem", marginTop:"1.75rem"}}>Combo Tank & Pump</h4>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Field
                    component={Select}
                    name={`${accessor}.system.combo.tankAndPump.tankCapacity`}
                    options={[
                        {
                            label: "User specified",
                            value: {
                                id: 0,
                                value: tankVolumeValue
                            }
                        },
                        ...getOptions({ fieldKey: 'comboTankVolume' })
                    ]}
                    label="Tank Volume"
                    placeholder="Choose Tank Volume"
                    validate={tankVolumeValidation}
                    onChange={
                        ({ id }) => {
                            change('modelData.domesticHotWater.primarySystem.tankVolume.id', id);
                            if (isEfDefaults && [1,2,3,4,5].includes(id)) {
                                change(
                                    `${accessor}.system.combo.tankAndPump.energyFactor.value`, 
                                    [0.63,0.61,0.59,0.57,0.55][id-1]
                                );
                            }
                        }
                    }
                    info={'For a tankless water heater, input a user specified value of 0.'}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.system.combo.tankAndPump.tankCapacity.value`}
                    label="Volume"
                    placeholder={0.00}
                    validate={tankVolumeValidation}
                    setTouched={true}
                    decimals={getDecimalPlaces('comboTankVolume')}
                    change={change}
                    setValue={tankVolumeValue}
                    disabled={tankVolumeId !== 0}
                    units={{
                        base:getBaseUnits('comboTankVolume', modelUnits),
                        options:getUnitOptions('volume'),
                        selected:tankVolumeUnits,
                        unitType:'volume',
                        accessor:`${accessor}.system.combo.tankAndPump.tankCapacity_u`
                    }}
                    onChange={
                        ( value ) => {
                            change('modelData.domesticHotWater.primarySystem.tankVolume.value', value)
                        }
                    }
                />
                <Field
                    component={Select}
                    name={`${accessor}.system.combo.tankAndPump.tankLocation`}
                    options={getOptions({ fieldKey: 'comboTankLocation' })}
                    label="Tank Location"
                    placeholder="Choose Tank Location"
                    validate={tankLocationValidation}
                    onChange={
                        ({ id }) => {
                            change('modelData.domesticHotWater.primarySystem.tankLocation', { id });
                        }
                    }
                />
            </InputRow>
            <InputRow gridTemplate="23.25% 23.25% 15% 15% 15%">
                <Field
                    component={Select}
                    name={`${accessor}.system.combo.tankAndPump.energyFactor.useDefaults`}
                    options={[
                        {
                            label: "User specified",
                            value: false
                        },
                        {
                            label: "Use Defaults",
                            value: true
                        }
                    ]}
                    label="Energy Factor Input Type"
                    placeholder="Choose Input Type"
                    onChange={
                        (value) => {
                            if (value) {
                                change('modelData.domesticHotWater.primarySystem.energyFactor.id', 0);
                            } else {
                                change('modelData.domesticHotWater.primarySystem.energyFactor.id', 1);
                            }
                        }
                    }
                />
                <Field
                    component={Input}
                    name={`${accessor}.system.combo.tankAndPump.energyFactor.value`}
                    type="number"
                    label="Energy Factor"
                    placeholder={0.00}
                    setValue={efValue}
                    validate={comboEFValidation}
                    disabled={isEfDefaults}
                    onChange={
                        (value) => {
                            change('modelData.domesticHotWater.primarySystem.energyFactor.value', value);
                        }
                    }
                />
            </InputRow>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Field
                    component={Select}
                    name={`${accessor}.system.combo.tankAndPump.circulationPump.isCalculated`}
                    options={[
                        {
                            label: "User specified",
                            value: false
                        },
                        {
                            label: "Calculated",
                            value: true
                        }
                    ]}
                    label="Circulation Pump Power Type"
                    placeholder="Choose Input Type"
                />
                <Field
                    component={Input}
                    name={`${accessor}.system.combo.tankAndPump.circulationPump.value`}
                    type="number"
                    label="Circulation Pump Power"
                    placeholder={0.00}
                    validate={circPumpValidation}
                    setTouched={true}
                    decimals={getDecimalPlaces('comboCircPumpPower')}
                    change={change}
                    disabled={isCircPumpCalculated}
                    units={{
                        base:getBaseUnits('comboCircPumpPower', modelUnits),
                        options:getUnitOptions('power'),
                        selected:circPumpPowerUnits,
                        unitType:'power',
                        accessor:`${accessor}.system.combo.tankAndPump.circulationPump_u`
                    }}
                />
                <Field
                    component={Checkbox}
                    name={`${accessor}.system.combo.tankAndPump.circulationPump.energyEfficientMotor`}
                    label="Energy Efficient Pump Motor"
                    large
                    className={classes.checkbox}
                    type="checkbox"
                />
            </InputRow>
            {
                isDwhr &&
                    <DwhrSystem
                        accessor={`modelData.domesticHotWater.primarySystem.drainWaterHeatRecovery`}
                        change={change}
                        handleDelete={() => handleDeleteDwhr()}
                    />
            }
        </>
    )
};