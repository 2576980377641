import { getBaseUnits } from "utils/fields";
import convertUnit from "utils/convertUnit";
import { getLabel, getCodeLayerLabel, getLocation } from "utils/dcfExport/details";
import { isEmpty } from "lodash";

export const getProgramData = ({ program = {}, modelUnits = "Metric" }) => {
    const a1 = "Measurement System";
    const b1 = modelUnits;

    const spacing = [
        {
            [`${a1}`]: "---",
        },
        {
            [`${a1}`]: "---",
        },
        {
            [`${a1}`]: "---",
        },
    ];

    const {
        class: programClass = "",
        options: {
            main = {},
            householdOpConditions = {},
            reducedOperating = {},
            greenerHomes = {},
            atypicalElecLoads = {},
            waterConservation = {},
            refHouse = {},
        } = {},
    } = program || {};

    const programType = programClass.includes("ERS")
        ? "EnerGuide Rating System"
        : programClass.includes("ONrh")
        ? "Ontario Reference House"
        : "General Mode";

    const {
        applyHouseholdOperatingConditions: applyHOC = false,
        applyReducedOperatingConditions: applyROC = false,
        atypicalElectricalLoads: applyAtyp = false,
        greenerHomesActive: applyGH = false,
        referenceHouse: applyRefHouse = false,
        waterConservation: applyWaterCons = false,
    } = main || {};

    const programData = [
        {
            [`${a1}`]: "Program type",
            [`${b1}`]: programType || "General Mode",
        },
        {
            [`${a1}`]: "Presence of vermiculite",
            [`${b1}`]:
                programType === "General Mode"
                    ? " "
                    : getLabel("ersVermiculitePresence", main?.vermiculitePresence?.id) || " ",
        },
    ];

    const householdOpConditionsData = [
        { [`${a1}`]: "Number of occupants", [`${b1}`]: !applyHOC ? " " : householdOpConditions?.numOccupants || " " },
        {
            [`${a1}`]: "Heating season thermostat daytime setting",
            [`${b1}`]: !applyHOC ? " " : householdOpConditions?.thermostat?.heatingDay || " ",
        },
        {
            [`${a1}`]: "Heating season thermostat nighttime setting",
            [`${b1}`]: !applyHOC ? " " : householdOpConditions?.thermostat?.heatingNight || " ",
        },
        {
            [`${a1}`]: "Cooling season thermostat setting",
            [`${b1}`]: !applyHOC ? " " : householdOpConditions?.thermostat?.cooling || " ",
        },
        {
            [`${a1}`]: "Cooling season (# of months)",
            [`${b1}`]: !applyHOC ? " " : householdOpConditions?.coolingSeasonMonths || " ",
        },
        {
            [`${a1}`]: "Clothes washer front load or ENERGY STAR",
            [`${b1}`]: !applyHOC ? " " : householdOpConditions?.efficientWasher ? "Yes" : "No",
        },
        {
            [`${a1}`]: "More than 50% efficient lighting",
            [`${b1}`]: !applyHOC ? " " : householdOpConditions?.efficientLighting ? "Yes" : "No",
        },
        {
            [`${a1}`]: "Fridge, stove and dishwasher are less than 5 years old",
            [`${b1}`]: !applyHOC ? " " : householdOpConditions?.efficientAppliances ? "Yes" : "No",
        },
    ];

    const reducedOpCondData = [
        {
            [`${a1}`]: "Lighting CFL or LED percentage",
            [`${b1}`]: !applyROC ? " " : getLabel("ersReducedOpLighting", reducedOperating?.reducedLighting?.id) || " ",
        },
        {
            [`${a1}`]: "Clothes dryer consumption (kWh)",
            [`${b1}`]: !applyROC ? " " : reducedOperating?.appliances?.dryer || " ",
        },
        {
            [`${a1}`]: "Clothes dryer ENERGY STAR certified",
            [`${b1}`]: !applyROC ? " " : reducedOperating?.appliances?.isDryerEStar ? "Yes" : "No",
        },
        {
            [`${a1}`]: "Clothes washer consumption (kWh)",
            [`${b1}`]: !applyROC ? " " : reducedOperating?.appliances?.washer || " ",
        },
        {
            [`${a1}`]: "Clothes washer ENERGY STAR certified",
            [`${b1}`]: !applyROC ? " " : reducedOperating?.appliances?.isWasherEStar ? "Yes" : "No",
        },
        {
            [`${a1}`]: "Dishwasher consumption (kWh)",
            [`${b1}`]: !applyROC ? " " : reducedOperating?.appliances?.dishWasher || " ",
        },
        {
            [`${a1}`]: "Dishwasher ENERGY STAR certified",
            [`${b1}`]: !applyROC ? " " : reducedOperating?.appliances?.isDishWasherEStar ? "Yes" : "No",
        },
        {
            [`${a1}`]: "Refrigerator consumption (kWh)",
            [`${b1}`]: !applyROC ? " " : reducedOperating?.appliances?.refrigerator || " ",
        },
        {
            [`${a1}`]: "Refrigerator ENERGY STAR certified",
            [`${b1}`]: !applyROC ? " " : reducedOperating?.appliances?.isRefrigeratorEStar ? "Yes" : "No",
        },
        {
            [`${a1}`]: "Range consumption (kWh)",
            [`${b1}`]: !applyROC ? " " : reducedOperating?.appliances?.range || " ",
        },
        {
            [`${a1}`]: "Bathroom faucets - reduced flow rate",
            [`${b1}`]: !applyROC ? " " : reducedOperating?.hotWater?.reducedFlowFaucets ? "Yes" : "No",
        },
        {
            [`${a1}`]: "Shower heads - reduced flow rate",
            [`${b1}`]: !applyROC ? " " : reducedOperating?.hotWater?.reducedFlowShower ? "Yes" : "No",
        },
    ];

    const atypicalLoadData = [
        {
            [`${a1}`]: "De-icing cables",
            [`${b1}`]: !applyAtyp ? " " : atypicalElecLoads?.deIcingCables ? "Yes" : " ",
        },
        {
            [`${a1}`]: "Electric vehicle charging station",
            [`${b1}`]: !applyAtyp ? " " : atypicalElecLoads?.evChargingStation ? "Yes" : " ",
        },
        {
            [`${a1}`]: "Extensive exterior lighting",
            [`${b1}`]: !applyAtyp ? " " : atypicalElecLoads?.exteriorLighting ? "Yes" : " ",
        },
        {
            [`${a1}`]: "Heated garage",
            [`${b1}`]: !applyAtyp ? " " : atypicalElecLoads?.heatedGarage ? "Yes" : " ",
        },
        {
            [`${a1}`]: "Hot tub",
            [`${b1}`]: !applyAtyp ? " " : atypicalElecLoads?.hotTub ? "Yes" : " ",
        },
        {
            [`${a1}`]: "Non-residential occupancy (mixed use)",
            [`${b1}`]: !applyAtyp ? " " : atypicalElecLoads?.mixedUse ? "Yes" : " ",
        },
        {
            [`${a1}`]: "Outdoor gas appliances",
            [`${b1}`]: !applyAtyp ? " " : atypicalElecLoads?.outdoorGasAppliances ? "Yes" : " ",
        },
        {
            [`${a1}`]: "Swimming pool",
            [`${b1}`]: !applyAtyp ? " " : atypicalElecLoads?.pool ? "Yes" : " ",
        },
        {
            [`${a1}`]: "Room air conditioner (# of units)",
            [`${b1}`]: !applyAtyp ? " " : atypicalElecLoads?.roomACtotalUnits || " ",
        },
        {
            [`${a1}`]: "Room air conditioner (# of ENERGY STAR units)",
            [`${b1}`]: !applyAtyp ? " " : atypicalElecLoads?.roomACEStarUnits || " ",
        },
        {
            [`${a1}`]: "Common Space",
            [`${b1}`]: !applyAtyp ? " " : atypicalElecLoads?.commonSpace ? "Yes" : " ",
        },
        {
            [`${a1}`]: "Elevator",
            [`${b1}`]: !applyAtyp ? " " : atypicalElecLoads?.elevator ? "Yes" : " ",
        },
        {
            [`${a1}`]: "Fitness room",
            [`${b1}`]: !applyAtyp ? " " : atypicalElecLoads?.fitnessRoom ? "Yes" : " ",
        },
        {
            [`${a1}`]: "Snowmelt system",
            [`${b1}`]: !applyAtyp ? " " : atypicalElecLoads?.snowmeltSystem ? "Yes" : " ",
        },
        {
            [`${a1}`]: "Unheated garage",
            [`${b1}`]: !applyAtyp ? " " : atypicalElecLoads?.unheatedGarage ? "Yes" : " ",
        },
    ];

    const waterConservationData = [
        {
            [`${a1}`]: "Number of low-flush toilets",
            [`${b1}`]: !applyWaterCons ? " " : waterConservation?.lowFlushToilets || 0,
        },
    ];

    const greenerHomesData = [
        {
            [`${a1}`]: "Evaluation Cost",
            [`${b1}`]: !applyGH ? " " : greenerHomes?.evaluationCost || " ",
        },
        {
            [`${a1}`]: "Basement slab insulated",
            [`${b1}`]: !applyGH ? " " : greenerHomes?.basementSlabInsulated ? "Yes" : "No",
        },
        {
            [`${a1}`]: "Moisture-proof crawl space",
            [`${b1}`]: !applyGH ? " " : greenerHomes?.moistureProofCrawlSpace ? "Yes" : "No",
        },
        {
            [`${a1}`]: "Waterproofing",
            [`${b1}`]: !applyGH ? " " : greenerHomes?.waterproofing ? "Yes" : "No",
        },
        {
            [`${a1}`]: "Adhesive waterproof ice and water barrier underlayment for roofs",
            [`${b1}`]: !applyGH ? " " : greenerHomes?.roofingMembrane ? "Yes" : "No",
        },
        {
            [`${a1}`]: "Minimum R10 continuous for exposed floors",
            [`${b1}`]: !applyGH ? " " : greenerHomes?.minR10ContinuousExposedFloors ? "Yes" : "No",
        },
        {
            [`${a1}`]: "Backwater valve with alarm",
            [`${b1}`]: !applyGH ? " " : greenerHomes?.backwaterValve ? "Yes" : "No",
        },
        {
            [`${a1}`]: "Sump pump with alarm and battery backup",
            [`${b1}`]: !applyGH ? " " : greenerHomes?.sumpPump ? "Yes" : "No",
        },
        {
            [`${a1}`]: "Remote community",
            [`${b1}`]: !applyGH ? " " : greenerHomes?.remoteCommunities ? "Yes" : "No",
        },
    ];

    const referenceHouseData = [
        {
            [`${a1}`]: "Include electricity generation",
            [`${b1}`]: !applyRefHouse ? " " : refHouse?.includeGeneration ? "Yes" : "No",
        },
        {
            [`${a1}`]: "Transfer all heat load to Type 1 system",
            [`${b1}`]: !applyRefHouse ? " " : refHouse?.hpLoadToType1 ? "Yes" : "No",
        },
        {
            [`${a1}`]: "Set to standard performance level",
            [`${b1}`]: !applyRefHouse ? " " : refHouse?.hpStandPerfLevel ? "Yes" : "No",
        },
        {
            [`${a1}`]: "Neutralize Fenestration",
            [`${b1}`]: !applyRefHouse ? " " : refHouse?.neutraliseFenestration ? "Yes" : "No",
        },
        { [`${a1}`]: "User defined HDD", [`${b1}`]: !applyRefHouse ? " " : refHouse?.userHDD || " " },
        { [`${a1}`]: "Above grade party wall area", [`${b1}`]: !applyRefHouse ? " " : refHouse?.areaAgPw || " " },
        { [`${a1}`]: "Below grade party wall area", [`${b1}`]: !applyRefHouse ? " " : refHouse?.areaBgPw || " " },
    ];

    return [
        ...spacing,
        { [`${a1}`]: "Program Details" },
        ...programData,
        ...spacing,
        { [`${a1}`]: "Greener Homes" },
        ...greenerHomesData,
        ...spacing,
        { [`${a1}`]: "Household Operating Conditions" },
        ...householdOpConditionsData,
        ...spacing,
        { [`${a1}`]: "Reduced Operating Conditions" },
        ...reducedOpCondData,
        ...spacing,
        { [`${a1}`]: "Atypical Energy Loads" },
        ...atypicalLoadData,
        ...spacing,
        { [`${a1}`]: "Water Conservation" },
        ...waterConservationData,
        ...spacing,
        { [`${a1}`]: "Reference House" },
        ...referenceHouseData,
        ...spacing,
        // { [`${a1}`]: "Blower Door Test Data" },
        // ...blowerDoorTestData,
    ];
};
