import React from "react";
import classes from "./style.module.scss";

export default ({ title, subtitle, className, sideContent, sideContentClasses }) => (
    <div className={`${classes.sectionHeading} ${className ? className : ""}`}>
        <div className={classes.textContent}>
            <h2>{title}</h2>
            {subtitle && <p>{subtitle}</p>}
        </div>
        {sideContent && <div className={`${classes.sideContent} ${sideContentClasses}`}>{sideContent}</div>}
    </div>
);
