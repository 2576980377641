import React from "react";
import { isEqual } from "lodash";
import { useSortable } from "@dnd-kit/sortable";

import Wall from "../Wall/container";
import ExpFloor from "../ExpFloor/container";
import Ceiling from "../Ceiling/container";
import Basement from "../Basement/container";
import Crawlspace from "../Crawlspace/container";
import Slab from "../Slab/container";
import Window from "../Window/container";
import Door from "../Door/container";
import FloorHeader from "../FloorHeader/container";

import classes from "../style.module.scss";

const ComponentType = React.memo(({ type, ...props }) => {
    const { id, activeId, clone, draggableId, isDragging, typeDrop } = props;

    const {
        attributes,
        listeners,
        setNodeRef,
        overIndex,
        activeIndex,
        transition,
        over,
        active,
        setActivatorNodeRef,
        transform,
    } = useSortable({
        id: props.id,
        data: {
            draggableId,
            type: typeDrop,

            // transition: null,
        },
    });

    const { data: { current: { sortable: { containerId: activeContainer } = {} } = {} } = {} } = active || {};

    const { data: { current: { sortable: { containerId: overContainer } = {} } = {} } = {} } = over || {};

    const dropPosition =
        over?.id !== active?.id && over?.id === id
            ? activeContainer === overContainer
                ? overIndex > activeIndex
                    ? "before"
                    : "after"
                : "after"
            : undefined;

    // CSS.Transform.toString(transform),
    const style = {
        transform: undefined,
        transition,
    };

    return (
        <div
            className={`${classes.componentWrapper}  ${
                clone && activeId === id && isDragging ? classes.activeComponentWrapper : ""
            } ${isDragging && classes.subComponentsActive}`}
            ref={setNodeRef}
            style={style}
            {...attributes}
            // {...listeners}
        >
            {
                {
                    wall: (
                        <Wall
                            dragHandleProps={{ ...listeners, ref: setActivatorNodeRef }}
                            dropPosition={dropPosition}
                            {...props}
                        />
                    ),
                    expFloor: (
                        <ExpFloor
                            dragHandleProps={{ ...listeners, ref: setActivatorNodeRef }}
                            dropPosition={dropPosition}
                            {...props}
                        />
                    ),
                    ceiling: (
                        <Ceiling
                            dragHandleProps={{ ...listeners, ref: setActivatorNodeRef }}
                            dropPosition={dropPosition}
                            {...props}
                        />
                    ),
                    basement: (
                        <Basement
                            dragHandleProps={{ ...listeners, ref: setActivatorNodeRef }}
                            dropPosition={dropPosition}
                            {...props}
                        />
                    ),
                    crawlspace: (
                        <Crawlspace
                            dragHandleProps={{ ...listeners, ref: setActivatorNodeRef }}
                            dropPosition={dropPosition}
                            {...props}
                        />
                    ),
                    slab: (
                        <Slab
                            dragHandleProps={{ ...listeners, ref: setActivatorNodeRef }}
                            dropPosition={dropPosition}
                            {...props}
                        />
                    ),
                    window: (
                        <Window
                            dragHandleProps={{ ...listeners, ref: setActivatorNodeRef }}
                            dropPosition={dropPosition}
                            id={id}
                            {...props}
                        />
                    ),
                    door: (
                        <Door
                            dragHandleProps={{ ...listeners, ref: setActivatorNodeRef }}
                            dropPosition={dropPosition}
                            id={id}
                            {...props}
                        />
                    ),
                    floorHeader: (
                        <FloorHeader
                            dragHandleProps={{ ...listeners, ref: setActivatorNodeRef }}
                            dropPosition={dropPosition}
                            id={id}
                            {...props}
                        />
                    ),
                    default: () => "",
                }[type || "default"]
            }
        </div>
    );
}, isEqual);

export default ComponentType;
