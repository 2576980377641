import moment from "moment";
import { createSelector } from "reselect";
import { initialState } from "./reducers";

export const selectUpgrades = ({ upgrades = initialState }) => upgrades;

export const selectUpgradePackages = createSelector([selectUpgrades], ({ packages = {} }) => packages);

// TODO: implement sorting
// For sorting
// - Need to update sort in redux when it changes and therefore it should update everything on save
// - 1. On create - DONE
// - 2. On delete - DONE
// - 3. On sort change
// ...anything else?
export const selectUpgradePackagesSortedArray = createSelector([selectUpgradePackages], (packages) =>
    Object.keys(packages)
        .filter((packageId) => packageId !== "base")
        .map((packageId) => ({
            id: packageId,
            ...packages[packageId],
        }))
        .sort(upgradePackageSort)
);

export const selectBaseUiDetails = createSelector(
    [selectUpgradePackages],
    (packages) =>
        Object.keys(packages)
            .filter((packageId) => packageId === "base")
            .map((packageId) => ({
                id: packageId,
                ...packages[packageId],
            }))[0]
);

export const selectUpgradePackagesIds = createSelector([selectUpgradePackages], (packages) =>
    Object.keys(packages).filter((packageId) => packageId !== "base")
);

export const upgradePackageSort = (a, b) => {
    const { created: createdA, ui: { displayOrder: orderA = 0 } = {} } = a;
    const { created: createdB, ui: { displayOrder: orderB = 0 } = {} } = b;

    if (orderB !== orderA) {
        return orderB > orderA ? -1 : 1;
    }

    if (moment(createdB) > moment(createdA)) {
        return 1;
    }
    if (moment(createdB) < moment(createdA)) {
        return -1;
    }
    return 0;
};

export const selectSavedUpgradePackages = createSelector([selectUpgrades], (packages) => packages.savedUpgradePackages);
