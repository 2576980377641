import { DashboardAPI } from "utils/api";

export const getSolarAzimuth = async ({ lat, lon, compassDir, searchType = "", postalCode = "" }) => {
    return DashboardAPI({
        method: "POST",
        url: "/getSolarAzimuth",
        data: {
            lat,
            lon,
            compassDir,
            searchType,
            postalCode,
        },
    }).catch((err) => {
        return { status: 500, err, data: {} };
    });
};
