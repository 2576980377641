import React from "react";

import UDefStandard from "features/Model/Enclosure/UserDefinedCodes/StandardLayers/container";
import UDefWindow from "features/Model/Enclosure/UserDefinedCodes/WindowLayers/container";

import StandardWall from "features/Model/Enclosure/ComponentList/Wall/Construction/WallStandardCode/container";
import StandardWindow from "features/Model/Enclosure/ComponentList/Window/Construction/StandardCode/container";
import StandardBasement from "features/Model/Enclosure/ComponentList/Basement/WallConstruction/StandardCode/container";
import StandardLintel from "features/Model/Enclosure/ComponentList/Wall/Construction/StandardCode/container";
import StandardCrawlspace from "features/Model/Enclosure/ComponentList/Crawlspace/WallConstruction/StandardCode/container";
import StandardPonyWall from "features/Model/Enclosure/ComponentList/Basement/PonyWall/StandardCode/container";
import StandardCeiling from "features/Model/Enclosure/ComponentList/Ceiling/Construction/StandardCode/container";
import StandardFloor from "features/Model/Enclosure/ComponentList/ExpFloor/Construction/StandardCode/container";
import StandardFloorHeader from "features/Model/Enclosure/ComponentList/FloorHeader/Construction/StandardCode/container";
import StandardFloorsAdded from "features/Model/Enclosure/ComponentList/Basement/FloorConstruction/StandardSlabCode/container";
import StandardFloorsAbove from "features/Model/Enclosure/ComponentList/Basement/FloorConstruction/StandardFloorsAboveCode/container";

import DrawingStandardWall from "features/Model/Drawing/Actions/Polygon/PolygonFields/Wall/StandardCode/container";
import DrawingStandardCeiling from "features/Model/Drawing/Actions/Polygon/PolygonFields/Ceiling/StandardCode/container";
import DrawingStandardFloor from "features/Model/Drawing/Actions/Polygon/PolygonFields/ExpFloor/StandardCode/container";
import DrawingStandardWindow from "features/Model/Drawing/Actions/WholeComponents/Window/StandardCode/container";
import DrawingUDefStandard from "features/Model/Drawing/Actions/UserDefinedCodes/StandardLayers/container";
import DrawingUDefWindow from "features/Model/Drawing/Actions/UserDefinedCodes/WindowLayers/container";

export const CodeDrawer = ({ componentType = "Wall", codeType = "S", ...props }) => {
    const standardCodes = {
        Wall: StandardWall,
        Window: StandardWindow,
        BasementWall: StandardBasement,
        Ceiling: StandardCeiling,
        CeilingFlat: StandardCeiling,
        CrawlspaceWall: StandardCrawlspace,
        PonyWall: StandardPonyWall,
        Floor: StandardFloor,
        FloorHeader: StandardFloorHeader,
        FloorsAbove: StandardFloorsAbove,
        FloorsAdded: StandardFloorsAdded,
        Lintel: StandardLintel,
    };

    if (codeType === "U" && componentType === "Window") {
        return <UDefWindow componentType={componentType} {...props} />;
    }

    if (codeType === "U") {
        return <UDefStandard componentType={componentType} {...props} />;
    }

    const StandardCode = standardCodes[componentType] || null;

    return <StandardCode {...props} />;
};

export const DrawingCodeDrawer = ({ componentType = "Wall", codeType = "S", ...props }) => {
    const standardCodes = {
        Wall: DrawingStandardWall,
        Window: DrawingStandardWindow,
        Ceiling: DrawingStandardCeiling,
        CeilingFlat: DrawingStandardCeiling,
        Floor: DrawingStandardFloor,
    };

    if (codeType === "U" && componentType === "Window") {
        return <DrawingUDefWindow componentType={componentType} {...props} />;
    }

    if (codeType === "U") {
        return <DrawingUDefStandard componentType={componentType} {...props} />;
    }

    const StandardCode = standardCodes[componentType] || null;
    return <StandardCode {...props} />;
};

export const standardCodeFormName = (type) =>
    ({
        Wall: "wallInsType",
        Window: "windowType",
        BasementWall: "basementWallInsType",
        Ceiling: "ceilingInsType",
        CeilingFlat: "ceilingInsType",
        CrawlspaceWall: "crawlspaceWallInsType",
        Floor: "expFloorInsType",
        FloorHeader: "floorHeaderInsType",
        FloorsAbove: "floorsAboveInsType",
        FloorsAdded: "slabInsType",
        Lintel: "lintelInsType",
    }[type]);

export const uDefCodeFormName = (type) => {
    if (type === "Window") {
        return "windowUDef";
    }

    return "udefStandard";
};
