import { connect } from 'react-redux';
import Info from './';
import convertUnit from 'utils/convertUnit';

const mapStateToProps = ({
    drawing:{
        imageData={},
        activeImage='',
        activeComponent=''
    }
}) => {
    const { 
        scale:{
            px,
            input=0,
            units='m',
            displayUnits='m'
        }={},
        components:{
            [activeComponent]:component={}
        }={},
        polygons:{
            [activeComponent]:polygon={}
        }={},
        lines:{
            [activeComponent]:line={}
        }={}
    } = imageData[activeImage] || {};

    const scaleDisplayValue = convertUnit({
        value:input,
        type:'drawingScale',
        inputUnit:units,
        outputUnit:displayUnits
    });

    const conversionFactor = input / px;

    return {
        activeImage,
        scaleValue: input ? `${scaleDisplayValue.toFixed(4)}${displayUnits}` : '',
        component,
        polygon,
        line,
        conversionFactor,
        displayUnits,
    }
};

const mapDispatchToProps = dispatch => ({});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Info);