import React, { useState, useCallback } from 'react';
import classes from '../../style.module.scss';
import { Field } from 'redux-form';
import Button from 'components/Button';
import Select from 'components/Input/Select';
import InputWithUnits from 'components/Input/InputWithUnits';
import Input from 'components/Input';
import Checkbox from 'components/Input/Checkbox';
import { required, maxLength, getValidation, getDecimalPlaces } from 'utils/fieldValidation';
import { getOptions, getBaseUnits,getUnitOptions } from 'utils/fields';
import Dialog from 'components/Dialog';
import debounce from "lodash/debounce";

const charLim100 = maxLength(100);
const doorTypeValidation = getValidation('doorType');

const Door = ({
    activeImage,
    activeComponent,
    componentOptions,
    handleSubmit,
    cancel,
    doorRValue,
    change,
    doorTypeId,
    rValUnits,
    primaryUnits,
    submitSucceeded,
    removeComponent,
    shape,
    transformer,
    setActiveComponent,
    savedModelRef,
    updateComponentsToDelete
}) => {
    const [deleteDialogOpen, toggleDeleteDialog] = useState(false);

    const saveToState = useCallback(debounce(e => {
        handleSubmit();
    }, 500),[]);

    return (
        <div>
            <Field
                className={classes.inputMarginBottom}
                component={Input}
                type="text"
                label="Door Name*"
                name="componentName"
                validate={[required, charLim100]}
                placeholder="Example: Front Door"
                onChange={saveToState}
            />
            <Field
                className={classes.inputMarginBottom}
                component={Select}
                options={componentOptions}
                name="attachTo"
                placeholder="Choose Component"
                searchPlaceholder="Search Components"
                label="Attach Door to Component"
                search={true}
                info={`This door will be added to your model when the drawing is saved.`}
                onChange={saveToState}
            />
            <Field
                className={classes.inputMarginBottom}
                component={Select}
                name="doorType"
                options={[
                    ...getOptions({fieldKey: 'doorType'}),
                    {
                        label:"User Specified",
                        value:{
                            id:7,
                            value:doorRValue,
                        }
                    },
                ]}
                label="Door Type"
                placeholder="Choose Door Type"
                validate={doorTypeValidation}
                onChange={saveToState}
            />
            <Field
                className={classes.inputMarginBottom}
                component={InputWithUnits}
                type="number"
                name={"doorType.value"}
                label="R-Value"
                placeholder={0.00}
                disabled={doorTypeId !== 7}
                validate={doorTypeValidation}
                setTouched={true}
                decimals={getDecimalPlaces('doorType')}
                change={change}
                setValue={doorTypeId !== 7 ? doorRValue : null}
                units={{
                    base:getBaseUnits('doorType', primaryUnits),
                    options:getUnitOptions('thermalResistance'),
                    selected:rValUnits,
                    unitType:'thermalResistance',
                    accessor:"doorType_u"
                }}
                onChange={saveToState}
                onUnitChange={saveToState}
            />
            <Field
                className={classes.inputMarginBottom}
                component={Checkbox}
                name={"energyStar"}
                label="Energy Star"
                type="checkbox"
                onChange={saveToState}
            />
            <div className={`${classes.buttonRow} ${classes.singleRow}`} style={{marginTop:'0.25rem'}}>
                {
                    cancel && !submitSucceeded ?
                        <Button
                            smallPadding
                            onClick={cancel}
                            type='hollow'
                            className={classes.cancelButton}
                        >
                            Cancel
                        </Button> :
                            <Button
                                smallPadding
                                onClick={()=>toggleDeleteDialog(true)}
                                type='hollow'
                                className={classes.deleteButton}
                            >
                                Delete Door
                            </Button> 
                }
            </div>
            <Dialog
                open={deleteDialogOpen}
                classes={{
                    paperClass:classes.dialogPaper
                }}
            >
                <div className={classes.dialogText}>
                    <h2 style={{maxWidth:'34rem'}}>Are you sure you want to delete this component?</h2>
                    <p>This component and all of its subcomponents will be deleted from the model when Drawing Capture is saved.</p>
                </div>
                <div className={classes.dialogFooter}>
                    <Button type="hollow" onClick={()=>toggleDeleteDialog(false)}>
                        Cancel
                    </Button>
                    <Button type="red" onClick={async()=>{
                        if (savedModelRef) {
                            const refArray = savedModelRef.split('.');
                            const componentId = refArray[refArray.length - 1];
                            const componentType = componentId.split('-')[0];
                            const parentPath = savedModelRef.split(`.${componentType}.${componentId}`)[0];
                            await updateComponentsToDelete([{
                                id:componentId,
                                parentPath:`.${parentPath}`,
                                drawingType:'component'
                            }]);
                        }
                        await setActiveComponent('');
                        await removeComponent(activeImage, activeComponent);
                        shape.destroy();
                        transformer.destroy();
                        toggleDeleteDialog(false);
                    }}>
                        Delete
                    </Button>
                </div>
            </Dialog>
        </div>
    )
};

export default Door;