import { connect } from "react-redux";
import Results from "./";
import { formValueSelector } from "redux-form";

import { actions } from "./_ducks";
import { actions as modelActions } from "features/Model/_ducks";
import { actions as userActions } from "store/users";

const { getResults, runSimulation, runAzureSimulation } = actions;
const { setExportErrors, updateModalDetails } = modelActions;
const { saveUserMeta } = userActions;

const mapStateToProps = ({
    model: {
        modelId,
        modelDetails: { lastCliRun: { cliId = {}, datetime: dateRun = "" } = {}, simulationRunning = false } = {},
        // modelData:{
        //     program:{
        //         class:programClass="" //THIS DIDN'T WORK, didn't update
        //     }={}
        // }={},
        review: { exportErrors = [] } = {},
    } = {},
    results: {
        packageResults: { base: { metadata: { mode, completedAt = "" } = {} } = {} } = {},
        loading = false,
        error = false,
    } = {},
    upgrades: { packages = {} } = {},
    user: { nonErsUser = false, userMeta: { eaErsCertified: isCertified = false } = {}, uid } = {},
    user = {},
    form,
}) => {
    const selector = formValueSelector("model");
    const { default: { runId: defaultId = "" } = {}, ...rest } = cliId;
    const id = defaultId || rest[Object.keys(rest)[0]]?.runId;
    const { major: h2kMajor, minor: h2kMinor } = selector({ form }, "modelData.h2kVersion") || {};
    const programClass = selector({ form }, `modelData.program.class`) || "";
    const filteredPackages = Object.keys(packages).reduce((cache, key) => {
        if (key === "base") return cache;
        return {
            ...cache,
            [key]: packages[key],
        };
    }, {});

    const isAirTestEnabled = selector({ form }, `modelData.airTightness.testDataEnabled`);

    return {
        modelId,
        cliId: id,
        clidIds: cliId,
        dateRun,
        hasResults: !!mode,
        mode,
        loading,
        completedAt,
        programClass,
        simulationRunning,
        error,
        packages: filteredPackages,
        h2kVersion: `v${h2kMajor}-${h2kMinor}`,
        exportErrors,
        nonErsUser,
        isCertified,
        uid,
        isAirTestEnabled,
    };
};

const mapDispatchToProps = (dispatch) => ({
    getResults: ({ cliIds, modelId }) => dispatch(getResults({ cliIds, modelId })),
    runSimulation: ({ packages }) => dispatch(runSimulation({ packages })),
    runAzureSimulation: ({ packages }) => dispatch(runAzureSimulation({ packages })),
    setExportErrors: (errors, location, proceedFunc) => dispatch(setExportErrors(errors, location, proceedFunc)),
    updateModalDetails: (details) => dispatch(updateModalDetails(details)),
    saveUserMeta: ({ uid, userMeta }) => dispatch(saveUserMeta({ uid, userMeta })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Results);
