export const getTValidations = (input, type, field = "", property) => {
    //? Type is not being used here but TTinput passes it because it's useful for other validation components.
    switch (property) {
        case "title": {
            if (input.length !== 0) {
                return {
                    err: false,
                    msg: "",
                };
            } else {
                return {
                    err: true,
                    msg: "Must have at least one character.",
                };
            }
        }
        case "qty": {
            if (input >= 1 && input < 9) {
                return {
                    err: false,
                    msg: "",
                };
            } else {
                return {
                    err: true,
                    msg: "Must be a number between 1 and 9",
                };
            }
        }
        case "ovHeight": {
            if (input >= -30 && input < 30) {
                return {
                    err: false,
                    msg: "",
                };
            } else {
                return {
                    err: true,
                    msg: "Must be a number between -30 and 30",
                };
            }
        }
        default: {
            if (input >= 0 && input < 50000) {
                return {
                    err: false,
                    msg: "",
                };
            } else {
                return {
                    err: true,
                    msg: "Must be a number between 0 and 50m",
                };
            }
        }
    }
};
