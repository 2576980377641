import One from 'assets/images/storeys/One.svg';
import Two from 'assets/images/storeys/Two.svg';
import Three from 'assets/images/storeys/Three.svg';
import OneAndAHalf from 'assets/images/storeys/OneAndAHalf.svg';
import TwoAndAHalf from 'assets/images/storeys/TwoAndAHalf.svg';
import SplitLevel from 'assets/images/storeys/SplitLevel.svg';
import RaisedBase from 'assets/images/storeys/RaisedBase.svg';

export const StoreyIcon = key => ({
    0:{
        img:One,
        alt:"One storey"
    },
    1:{
        img:OneAndAHalf,
        alt:"One and a half storeys"
    },
    2:{
        img:Two,
        alt:"Two storeys"
    },
    3:{
        img:TwoAndAHalf,
        alt:"Two and a half storeys"
    },
    4:{
        img:Three,
        alt:"Three storeys"
    },
    5:{
        img:SplitLevel,
        alt:"Split level"
    },
    6:{
        img:RaisedBase,
        alt:"Raised base"
    },
}[key || 0])