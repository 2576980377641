import React from "react";
import classes from "./style.module.scss";
import { Field } from 'redux-form';
import { getInsulationThumbnail } from 'utils/enclosure/basement';
import { getDecimalPlaces } from 'utils/fieldValidation';
import { getBaseUnits, getUnitOptions, getFoundationConfigParams } from 'utils/fields';

import InputRow from 'components/Input/Row';
import Select from 'components/Input/Select';
import InputWithUnits from 'components/Input/InputWithUnits';
import Button from "components/Button";
import { useEffect } from "react";

const NewLineText = ({text}) => {
    return text.split('\n').map((str,ind) => <li key={ind}>{str.split('- ')[1]}</li>);
};

const InsulationConfiguration = ({
    accessor,
    insConfig,
    overlapValue,
    modelUnits,
    configUnits,
    updateFoundationConfig,
    change,
}) => {
    const {
        text: insConfigDescription = 'No description available.'
    } = getFoundationConfigParams({fieldKey: 'basementInsConfig', configKey:insConfig }) || {};

    const {
        overlap: hasOverlap = false
    } = getFoundationConfigParams({fieldKey: 'basementInsConfig', configKey:insConfig }) || {};

    useEffect(() => {
        if (overlapValue == null || !hasOverlap) {
            change(`${accessor}.configuration.overlap`, 0)
        }
    }, [insConfig, hasOverlap])

    let configOpts = [
        {
            label:'BCCB_4',
            value:{
                type:'BCCB_4',
                overlap:overlapValue,
            }
        },
        {
            label:'BCCB_8',
            value:{
                type:'BCCB_8',
                overlap:overlapValue,
            }
        },
    ]

    if (!['BCCB_4', 'BCCB_8'].includes(insConfig)) {
        configOpts = [{
            label:insConfig,
            value:{
                type:insConfig,
                overlap:overlapValue,
            }
        }, ...configOpts]
    }

    return (
        <InputRow gridTemplate="2fr 1fr">
            <div className={classes.thumbnailWrapper}>
                <div>
                     {getInsulationThumbnail(insConfig) && <img className={classes.thumbnail} src={getInsulationThumbnail(insConfig)} alt="Insulation thumbnail" />}
                     <p className={classes.selectedConfig}>Selected Configuration: {insConfig}</p>
                </div>
                <div>
                    <div className={classes.selectConfigWrapper}>
                        <Field
                            component={Select}
                            name={`${accessor}.configuration`}
                            options={configOpts}
                            label="Quick Select"
                            placeholder="Choose Configuration"
                            info={'For concrete basements, select BCCB_4 for full slab insulation or an uninsulated slab, and select BCCB_8 partial slab insulation. When uninsulated, set the corresponding R-Value for a given section to a User Specified value of 0.'}
                        />
                        <span>OR</span>
                        <Button
                            onClick={()=>updateFoundationConfig({
                                open:true,
                                currentFormChange:change,
                                fieldAccessor:`${accessor}.configuration`,
                                fieldKey:'basementInsConfig'
                            })}
                        >
                            Select New Configuration
                        </Button>
                    </div>
                    <Field
                        className={classes.overlap}
                        component={InputWithUnits}
                        name={`${accessor}.configuration.overlap`}
                        label="Overlap"
                        placeholder="0.00"
                        change={change}
                        type="number"
                        disabled={!hasOverlap}
                        setValue={overlapValue}
                        decimals={getDecimalPlaces('basementInsConfig')}
                        units={{
                            base:getBaseUnits('basementInsConfig', modelUnits),
                            options:getUnitOptions('length'),
                            selected:configUnits,
                            unitType:'length',
                            accessor:`${accessor}.basementInsConfig_u`
                        }}
                    />
                </div>
            </div>
        </InputRow>
    )
};

export default InsulationConfiguration;