import types from "./types";

export const toggleImageGallery = () => ({
    type: types.TOGGLE_IMAGE_GALLERY,
});

export const updateImages = (images) => ({
    type: types.UPDATE_ALL_IMAGES,
    payload: images,
});

export const setTags = (tags) => ({
    type: types.SET_TAGS,
    payload: tags,
});

export const addNewTag = (tag) => ({
    type: types.ADD_NEW_TAG,
    payload: tag,
});

export const getImageGalleryStart = () => ({
    type: types.GET_IMAGE_GALLERY_START,
});

export const getImageGallerySuccess = (images) => ({
    type: types.GET_IMAGE_GALLERY_SUCCESS,
    payload: images,
});

export const getImageGalleryError = (error) => ({
    type: types.GET_IMAGE_GALLERY_ERROR,
    payload: error,
});

export const addImageDataStart = () => ({ type: types.ADD_IMAGE_DATA_START });

export const addImageDataSuccess = (data) => ({
    type: types.ADD_IMAGE_DATA_SUCCESS,
    payload: data,
});

export const addImageDataError = (error) => ({
    type: types.ADD_IMAGE_DATA_ERROR,
    payload: error,
});

export const deleteImageDataStart = () => ({
    type: types.DELETE_IMAGE_DATA_START,
});

export const deleteImageDataSuccess = (data) => ({
    type: types.DELETE_IMAGE_DATA_SUCCESS,
    payload: data,
});

export const deleteImageDataError = (error) => ({
    type: types.DELETE_IMAGE_DATA_ERROR,
    payload: error,
});

export const saveImageDataStart = () => ({
    type: types.SAVE_IMAGE_DATA_START,
});

export const saveImageDataSuccess = () => ({
    type: types.SAVE_IMAGE_DATA_SUCCESS,
});

export const saveImageDataError = (error) => ({
    type: types.SAVE_IMAGE_DATA_ERROR,
    payload: error,
});

export const resetImageGallety = () => ({
    type: types.RESET_IMAGE_GALLERY,
});

export const updateImageGallerySnapshot = (snapshot) => ({
    type: types.UPDATE_IMAGE_GALLERY_SNAPSHOT,
    payload: snapshot,
});
