import React from "react";
import classes from "./style.module.scss";

export default ({ message = null, className, subClassName, styles }) => (
    <div className={`${classes.spinnerWrapper} ${className && className}`} style={styles}>
        <div className={`${classes.spinner} ${subClassName && subClassName}`}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
);
