import React from 'react';

export default (params) => (
    <svg fill="black" width="17" height="9" viewBox="0 0 17 9" xmlns="http://www.w3.org/2000/svg" {...params}>
        <path fillRule="evenodd" clipRule="evenodd" d="M8.03434 6.08192C8.29778 6.08192 8.51135 6.29549 8.51135 6.55893V7.98997C8.51135 8.25341 8.29778 8.46698 8.03434 8.46698C7.77089 8.46698 7.55733 8.25341 7.55733 7.98997V6.55893C7.55733 6.29549 7.77089 6.08192 8.03434 6.08192Z"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M15.7991 0.0880898C16.0139 0.240594 16.0644 0.538367 15.9119 0.753183C14.6267 2.56349 12.4005 4.63001 9.60122 5.20553C6.74987 5.79175 3.4457 4.80487 0.109815 0.781512C-0.0583347 0.578708 -0.0302428 0.277991 0.172561 0.109841C0.375364 -0.0583091 0.676081 -0.0302172 0.844231 0.172586C4.01666 3.9988 6.98819 4.76878 9.4091 4.27105C11.8821 3.76261 13.9247 1.90434 15.134 0.200915C15.2865 -0.0139013 15.5843 -0.0644148 15.7991 0.0880898Z"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.6244 5.66784C10.8694 5.57086 11.1465 5.69082 11.2435 5.93577L11.7703 7.26634C11.8672 7.51128 11.7473 7.78847 11.5023 7.88544C11.2574 7.98241 10.9802 7.86245 10.8832 7.6175L10.3565 6.28694C10.2595 6.04199 10.3795 5.76481 10.6244 5.66784Z"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M12.8456 4.38374C13.0319 4.19745 13.334 4.19745 13.5202 4.38374L14.5321 5.39563C14.7184 5.58191 14.7184 5.88394 14.5321 6.07022C14.3459 6.25651 14.0438 6.25651 13.8575 6.07022L12.8456 5.05833C12.6594 4.87205 12.6594 4.57002 12.8456 4.38374Z"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M5.16512 5.66784C4.92017 5.57086 4.64298 5.69082 4.54601 5.93577L4.01927 7.26634C3.9223 7.51128 4.04226 7.78847 4.28721 7.88544C4.53216 7.98241 4.80934 7.86245 4.90631 7.6175L5.43305 6.28694C5.53002 6.04199 5.41007 5.76481 5.16512 5.66784Z"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M2.94388 4.38374C2.75759 4.19745 2.45556 4.19745 2.26928 4.38374L1.25739 5.39563C1.0711 5.58191 1.0711 5.88394 1.25739 6.07022C1.44367 6.25651 1.7457 6.25651 1.93198 6.07022L2.94388 5.05833C3.13016 4.87205 3.13016 4.57002 2.94388 4.38374Z"/>
    </svg>
)
