import React from "react";

import { CloseDialog } from "components/Dialog";
import Button from "components/Button";
import Dialog from "components/Dialog";
import Input from "components/Input";

import classes from "../style.module.scss";

const OrgRequestDialog = ({ open, close, organizationName, setOrganizationName, onClick, orgRequestLoading }) => (
    <Dialog open={open}>
        <CloseDialog closeActions={() => close()} />
        <div className={classes.dialog}>
            <h2>Request a New Organization</h2>
            <Input
                label={"Organization Name"}
                placeholder="Type organization name..."
                disabled={orgRequestLoading}
                input={{
                    value: organizationName,
                    onChange: (value) => setOrganizationName(value),
                }}
            />
            <div className={classes.orgRequestText}>
                <p>After pressing submit, a Volta SNAP developer will be in touch with next steps.</p>
            </div>
            <div className={classes.footer}>
                <Button type="hollow" onClick={() => close()}>
                    Cancel
                </Button>
                <Button onClick={onClick} disabled={!organizationName || orgRequestLoading}>
                    {orgRequestLoading ? "Submitting..." : "Submit"}
                </Button>
            </div>
        </div>
    </Dialog>
);

export default OrgRequestDialog;
