import { isEmpty } from "lodash";
import { getBaseUnits } from "utils/fields";
import { getLabel, getCodeLayerLabel, getEffRValLabel } from "utils/dcfExport/details";
import convertUnit from "utils/convertUnit";

export const getFoundationData = ({ codes = {}, modelUnits = "Metric", components = {}, dimensions = {} }) => {
    // foundation sheet
    const a1 = "Measurement System";
    const b1 = modelUnits;

    const { belowGradeIntFloorArea: { total: totalBgFloorArea = 0 } = {} } = dimensions;
    const { basement = {}, crawlspace = {}, slab = {} } = components || {};

    //basement
    const basementResults = Object.keys(basement).map((basementKey, ind) => {
        const {
            label = "",
            wall: {
                intAddedInsType: { codeRef: wallCodeRef = "", codeLabel: wallCodeLabel = "" } = {},
                intAddedInsType_effRVal,
                extAddedInsType: { id: extAddedInsId, value: extAddedInsValue = 0 } = {},
                lintelInsType: { codeRef: lintelCodeRef = "", codeLabel: lintelCodeLabel = "" } = {},
                ponyWall: {
                    ponyWallInsType: { codeRef: ponyWallCodeRef = "", codeLabel: ponyWallCodeLabel = "" } = {},
                    ponyWallInsType_effRVal,
                } = {},
            } = {},
            floor: {
                measurements: { isRectangular = false } = {},
                slabInsType: { id: slabId, codeLabel: slabCodeLabel = "" } = {},
                slabInsType_effRVal,
                floorsAboveInsType: { codeRef: floorCodeRef = "", codeLabel: floorCodeLabel = "" } = {},
                floorsAboveInsType_effRVal,
            } = {},
        } = basement[basementKey] || {};

        const {
            [wallCodeRef]: { layers: intWallLayers = {} } = {},
            [lintelCodeRef]: { layers: lintelLayers = {} } = {},
            [ponyWallCodeRef]: { layers: ponyWallLayers = {} } = {},
            [floorCodeRef]: { layers: floorsAboveLayers = {} } = {},
        } = codes;

        const intAddedInsLabel =
            wallCodeRef === "UserSpecified" ? getEffRValLabel(intAddedInsType_effRVal, modelUnits) : wallCodeLabel;

        const extAddedInsLabel =
            extAddedInsId === 10
                ? getEffRValLabel(extAddedInsValue, modelUnits)
                : getLabel("basementExtAddedIns", extAddedInsId) || "";

        const slabLabel = getLabel("basementSlabInsType", slabId) || slabCodeLabel || "";

        const floorsAboveLabel =
            floorCodeRef === "UserSpecified" ? getEffRValLabel(floorsAboveInsType_effRVal, modelUnits) : floorCodeLabel;
        const ponyWallLabel =
            ponyWallCodeRef === "UserSpecified"
                ? getEffRValLabel(ponyWallInsType_effRVal, modelUnits)
                : ponyWallCodeLabel;

        return {
            id: ind + 1,
            label,
            length: convertUnit({
                value: isRectangular ? basement[basementKey]?.floor?.measurements?.length || 0 : 0,
                type: "length",
                inputUnit: getBaseUnits("basementLength", modelUnits).trueBase,
                outputUnit: getBaseUnits("basementLength", modelUnits).displayBase,
            })
                .toFixed(2)
                .replace("0.00", "-"),
            width: convertUnit({
                value: isRectangular ? basement[basementKey]?.floor?.measurements?.width || 0 : 0,
                type: "length",
                inputUnit: getBaseUnits("basementWidth", modelUnits).trueBase,
                outputUnit: getBaseUnits("basementWidth", modelUnits).displayBase,
            })
                .toFixed(2)
                .replace("0.00", "-"),
            perimeter: convertUnit({
                value: !isRectangular ? basement[basementKey]?.floor?.measurements?.perimeter || 0 : 0,
                type: "length",
                inputUnit: getBaseUnits("basementPerimeter", modelUnits).trueBase,
                outputUnit: getBaseUnits("basementPerimeter", modelUnits).displayBase,
            })
                .toFixed(2)
                .replace("0.00", "-"),
            area: convertUnit({
                value: !isRectangular ? basement[basementKey]?.floor?.measurements?.area || 0 : 0,
                type: "area",
                inputUnit: getBaseUnits("basementArea", modelUnits).trueBase,
                outputUnit: getBaseUnits("basementArea", modelUnits).displayBase,
            })
                .toFixed(2)
                .replace("0.00", "-"),
            exposedPerimeter: convertUnit({
                value: basement[basementKey]?.exposedSurfacePerimeter || 0,
                type: "length",
                inputUnit: getBaseUnits("basementExpSurfacePerimeter", modelUnits).trueBase,
                outputUnit: getBaseUnits("basementExpSurfacePerimeter", modelUnits).displayBase,
            }).toFixed(2),

            totalWallHeight: convertUnit({
                value: basement[basementKey]?.wall?.measurements?.height || 0,
                type: "length",
                inputUnit: getBaseUnits("basementHeight", modelUnits).trueBase,
                outputUnit: getBaseUnits("basementHeight", modelUnits).displayBase,
            }).toFixed(2),

            depth: convertUnit({
                value: basement[basementKey]?.wall?.measurements?.depth || 0,
                type: "length",
                inputUnit: getBaseUnits("basementDepth", modelUnits).trueBase,
                outputUnit: getBaseUnits("basementDepth", modelUnits).displayBase,
            }).toFixed(2),

            insulationConfig: basement[basementKey]?.configuration?.type || "",

            basementIntWallLabel: intAddedInsLabel,
            framingMaterial: getCodeLayerLabel("csBasementWallFraming", intWallLayers?.framing?.id) || " ",
            wallSpacing: getCodeLayerLabel("csBasementWallSpacing", intWallLayers?.spacing?.id) || " ",
            insulationInFramingLayer:
                getCodeLayerLabel("csBasementWallFramingIns", intWallLayers?.insulationFramingLayer?.id) || " ",
            extraInsulationLayer:
                getCodeLayerLabel("csBasementWallExtraIns", intWallLayers?.extraInsulationLayer?.id) || " ",
            wallInteriorFinish: getCodeLayerLabel("csBasementWallFinish", intWallLayers?.interiorFinish?.id) || " ",
            wallStudsCorner:
                getCodeLayerLabel("csBasementWallStudsCornersInt", intWallLayers?.studsCornerIntersection?.id) || " ",

            basementExtWallLabel: extAddedInsLabel,

            lintelType: getCodeLayerLabel("csLintelType", lintelLayers?.lintelType?.id) || " ",
            lintelMaterial: getCodeLayerLabel("csLintelMaterial", lintelLayers?.lintelMaterial?.id) || " ",
            lintelIns: getCodeLayerLabel("csLintelInsulation", lintelLayers?.lintelIns?.id) || " ",

            slabInsType: slabLabel,
            slabInsRValue: convertUnit({
                value: slabInsType_effRVal || 0,
                type: "thermalResistance",
                inputUnit: getBaseUnits("basementSlabInsType", modelUnits).trueBase,
                outputUnit: getBaseUnits("basementSlabInsType", modelUnits).displayBase,
            }).toFixed(2),

            floorsAboveType: floorsAboveLabel,
            // floorLayers,
            floorsAboveStructureType:
                getCodeLayerLabel("csFloorsAboveStructureType", floorsAboveLayers?.structureType?.id) || "",
            floorsAboveComponentTypeSize:
                getCodeLayerLabel(
                    "csFloorsAboveComponentTypeSize",
                    floorsAboveLayers?.componentTypeSize?.id,
                    floorsAboveLayers?.structureType?.id
                ) || "",
            floorsAboveSpacing:
                getCodeLayerLabel(
                    "csFloorsAboveFramingSpacing",
                    floorsAboveLayers?.spacing?.id,
                    floorsAboveLayers?.structureType?.id
                ) || " ",
            floorsAboveFraming:
                getCodeLayerLabel(
                    "csFloorsAboveFramingSpacing",
                    floorsAboveLayers?.framing?.id,
                    floorsAboveLayers?.structureType?.id
                ) || " ",
            floorsAboveInsLayer1:
                getCodeLayerLabel(
                    "csFloorsAboveInsLayer1",
                    floorsAboveLayers?.insulationLayer1?.id,
                    floorsAboveLayers?.structureType?.id
                ) || "",
            floorsAboveInsLayer2:
                getCodeLayerLabel("csFloorsAboveInsLayer2", floorsAboveLayers?.insulationLayer2?.id) || "",
            floorsAboveIntFinish: getCodeLayerLabel("csFloorsAboveInterior", floorsAboveLayers?.interior?.id) || "",
            floorsAboveSheathing: getCodeLayerLabel("csFloorsAboveSheathing", floorsAboveLayers?.sheathing?.id) || "",
            floorsAboveExtFinish: getCodeLayerLabel("csFloorsAboveExterior", floorsAboveLayers?.exterior?.id) || "",
            floorsAboveDropFraming:
                getCodeLayerLabel("csFloorsAboveDropFraming", floorsAboveLayers?.dropFraming?.id) || "",
            // pony walls
            // ponyWallLabel:  , // name of parent component
            ponyWallEnabled: !!basement[basementKey]?.wall?.ponyWall?.enabled,
            ponyWallHeight:
                convertUnit({
                    value: basement[basementKey]?.wall?.ponyWall?.ponyWallHeight || 0,
                    type: "length",
                    inputUnit: getBaseUnits("basementPonyWallHeight", modelUnits).trueBase,
                    outputUnit: getBaseUnits("basementPonyWallHeight", modelUnits).displayBase,
                }).toFixed(2) || "",
            ponyWallType: ponyWallLabel || "",
            pwStructureType: getCodeLayerLabel("csWallStructureType", ponyWallLayers?.structureType?.id) || "",
            pwComponentTypeSize:
                getCodeLayerLabel(
                    "csWallComponentTypeSize",
                    ponyWallLayers?.componentTypeSize?.id,
                    ponyWallLayers?.structureType?.id
                ) || "",
            pwSpacing:
                getCodeLayerLabel(
                    "csWallFramingSpacing",
                    ponyWallLayers?.spacing?.id,
                    ponyWallLayers?.structureType?.id
                ) || " ",
            pwFraming:
                getCodeLayerLabel(
                    "csWallFramingSpacing",
                    ponyWallLayers?.framing?.id,
                    ponyWallLayers?.structureType?.id
                ) || " ",
            pwInsLayer1:
                getCodeLayerLabel(
                    "csWallInsLayer1",
                    ponyWallLayers?.insulationLayer1?.id,
                    ponyWallLayers?.structureType?.id
                ) || "",
            pwInsLayer2: getCodeLayerLabel("csWallInsLayer2", ponyWallLayers?.insulationLayer2?.id) || "",
            pwInteriorFinish: getCodeLayerLabel("csWallInterior", ponyWallLayers?.interior?.id) || "",
            pwSheathing: getCodeLayerLabel("csWallSheathing", ponyWallLayers?.sheathing?.id) || "",
            pwExtFinish: getCodeLayerLabel("csWallExterior", ponyWallLayers?.exterior?.id) || "",
            pwStudsCorner:
                getCodeLayerLabel("csWallStudsCornersInt", ponyWallLayers?.studsCornerIntersection?.id) || "",
        };
    });

    const basementObjectData = !isEmpty(basementResults)
        ? basementResults.map((basement) => {
              return {
                  [`${a1}`]: basement.id || " ",
                  [`${b1}`]: basement.label || " ",
                  C: basement.length || " ",
                  D: basement.width || " ",
                  E: basement.perimeter || " ",
                  F: basement.area || " ",
                  G: basement.exposedPerimeter || " ",
                  H: basement.totalWallHeight || " ",
                  I: basement.depth || " ",
                  J: basement.insulationConfig || " ",
                  K: basement.basementIntWallLabel || " ",
                  L: basement.framingMaterial || " ",
                  M: basement.wallSpacing || " ",
                  N: basement.insulationInFramingLayer || " ",
                  O: basement.extraInsulationLayer || " ",
                  P: basement.wallInteriorFinish || " ",
                  Q: basement.wallStudsCorner || " ",

                  R: basement.lintelType || " ",
                  S: basement.lintelMaterial || " ",
                  T: basement.lintelIns || " ",
                  U: basement.basementExtWallLabel || " ",

                  V: basement.slabInsType || " ",
                  W: basement.slabInsRValue || " ",
                  X: basement.floorsAboveType || " ",
                  Y: basement.floorsAboveStructureType || " ",
                  Z: basement.floorsAboveComponentTypeSize || " ",
                  AA: basement.floorsAboveSpacing || " ",
                  AB: basement.floorsAboveFraming || " ",
                  AC: basement.floorsAboveInsLayer1 || " ",
                  AD: basement.floorsAboveInsLayer2 || " ",
                  AE: basement.floorsAboveIntFinish || " ",
                  AF: basement.floorsAboveSheathing || " ",
                  AG: basement.floorsAboveExtFinish || " ",
                  AH: basement.floorsAboveDropFraming || " ",
              };
          })
        : [
              {
                  [`${a1}`]: "-",
                  [`${b1}`]: "-",
                  C: "-",
                  D: "-",
                  E: "-",
                  F: "-",
                  G: "-",
                  H: "-",
                  I: "-",
                  J: "-",
                  K: "-",
                  L: "-",
                  M: "-",
                  N: "-",
                  O: "-",
                  P: "-",
                  Q: "-",
                  R: "-",
                  S: "-",
                  T: "-",
                  U: "-",
                  V: "-",
                  W: "-",
                  X: "-",
                  Y: "-",
                  Z: "-",
                  AA: "-",
                  AB: "-",
                  AC: "-",
                  AD: "-",
                  AE: "-",
                  AF: "-",
                  AG: "-",
                  AH: "-",
              },
          ];

    const ponyWallObjectData = !isEmpty(basementResults)
        ? basementResults.map((basement) => {
              if (basement.ponyWallEnabled) {
                  return {
                      [`${a1}`]: basement.id || " ",
                      [`${b1}`]: basement.label || " ",
                      C: basement.ponyWallHeight || " ",
                      D: basement.ponyWallType || "N/A",
                      E: basement.pwStructureType || " ",
                      F: basement.pwComponentTypeSize || " ",
                      G: basement.pwSpacing || " ",
                      H: basement.pwFraming || " ",
                      I: basement.pwInsLayer1 || " ",
                      J: basement.pwInsLayer2 || " ",
                      K: basement.pwInteriorFinish || " ",
                      L: basement.pwSheathing || " ",
                      M: basement.pwExtFinish || " ",
                      N: basement.pwStudsCorner || " ",
                  };
              }

              return {
                  [`${a1}`]: basement.id,
                  [`${b1}`]: basement.label,
                  C: " ",
                  D: "N/A",
                  E: " ",
                  F: " ",
                  G: " ",
                  H: " ",
                  I: " ",
                  J: " ",
                  K: " ",
                  L: " ",
                  M: " ",
                  N: " ",
              };
          })
        : [
              {
                  [`${a1}`]: "-",
                  [`${b1}`]: "-",
                  C: "-",
                  D: "-",
                  E: "-",
                  F: "-",
                  G: "-",
                  H: "-",
                  I: "-",
                  J: "-",
                  K: "-",
                  L: "-",
                  M: "-",
                  N: "-",
              },
          ];

    // crawlspace
    const crawlspaceResults = Object.keys(crawlspace).map((crawlspaceKey, ind) => {
        const {
            label = "",
            wall: {
                crawlspaceWallInsType: { codeRef: wallCodeRef = "", codeLabel: wallCodeLabel = "" } = {},
                crawlspaceWallInsType_effRVal = {},
                lintelInsType: { codeRef: lintelCodeRef = "", codeLabel: lintelCodeLabel = "" } = {},
            } = {},
            floor: {
                measurements: { isRectangular = false } = {},
                slabInsType: { id: slabId, codeLabel: slabCodeLabel = "" } = {},
                slabInsType_effRVal,
                floorsAboveInsType: { codeRef: floorCodeRef = "", codeLabel: floorCodeLabel = "" } = {},
                floorsAboveInsType_effRVal,
            } = {},
        } = crawlspace[crawlspaceKey] || {};

        const {
            [wallCodeRef]: { layers: crwlWallLayers = {} } = {},
            [lintelCodeRef]: { layers: lintelLayers = {} } = {},
            [floorCodeRef]: { layers: floorsAboveLayers = {} } = {},
        } = codes;

        const crwlWallLabel =
            wallCodeRef === "UserSpecified"
                ? getEffRValLabel(crawlspaceWallInsType_effRVal, modelUnits)
                : wallCodeLabel;

        const slabLabel = getLabel("basementSlabInsType", slabId) || slabCodeLabel || "";

        const floorsAboveLabel =
            floorCodeRef === "UserSpecified" ? getEffRValLabel(floorsAboveInsType_effRVal, modelUnits) : floorCodeLabel;

        return {
            id: `${ind + 1}`,
            label: label,
            length: convertUnit({
                value: isRectangular ? crawlspace[crawlspaceKey]?.floor?.measurements?.length || 0 : 0,
                type: "length",
                inputUnit: getBaseUnits("crawlspaceLength", modelUnits).trueBase,
                outputUnit: getBaseUnits("crawlspaceLength", modelUnits).displayBase,
            })
                .toFixed(2)
                .replace("0.00", "-"),
            width: convertUnit({
                value: isRectangular ? crawlspace[crawlspaceKey]?.floor?.measurements?.width || 0 : 0,
                type: "width",
                inputUnit: getBaseUnits("crawlspaceWidth", modelUnits).trueBase,
                outputUnit: getBaseUnits("crawlspaceWidth", modelUnits).displayBase,
            })
                .toFixed(2)
                .replace("0.00", "-"),
            perimeter: convertUnit({
                value: isRectangular ? crawlspace[crawlspaceKey]?.floor?.measurements?.perimeter || 0 : 0,
                type: "length",
                inputUnit: getBaseUnits("crawlspacePerimeter", modelUnits).trueBase,
                outputUnit: getBaseUnits("crawlspacePerimeter", modelUnits).displayBase,
            })
                .toFixed(2)
                .replace("0.00", "-"),
            area: convertUnit({
                value: isRectangular ? crawlspace[crawlspaceKey]?.floor?.measurements?.area || 0 : 0,
                type: "area",
                inputUnit: getBaseUnits("crawlspaceArea", modelUnits).trueBase,
                outputUnit: getBaseUnits("crawlspaceArea", modelUnits).displayBase,
            })
                .toFixed(2)
                .replace("0.00", "-"),
            exposedPerimeter: convertUnit({
                value: crawlspace[crawlspaceKey]?.exposedSurfacePerimeter,
                type: "length",
                inputUnit: getBaseUnits("crawlspaceExpSurfacePerimeter", modelUnits).trueBase,
                outputUnit: getBaseUnits("crawlspaceExpSurfacePerimeter", modelUnits).displayBase,
            }).toFixed(2),
            totalWallHeight: convertUnit({
                value: crawlspace[crawlspaceKey]?.wall?.measurements?.height,
                type: "length",
                inputUnit: getBaseUnits("crawlspaceWallHeight", modelUnits).trueBase,
                outputUnit: getBaseUnits("crawlspaceWallHeight", modelUnits).displayBase,
            }).toFixed(2),
            depth: convertUnit({
                value: crawlspace[crawlspaceKey]?.wall?.measurements?.depth,
                type: "length",
                inputUnit: getBaseUnits("crawlspaceWallDepthBelowGrade", modelUnits).trueBase,
                outputUnit: getBaseUnits("crawlspaceWallDepthBelowGrade", modelUnits).displayBase,
            }).toFixed(2),
            insulationConfig: crawlspace[crawlspaceKey]?.configuration?.type,

            crawlspaceWallTypeLabel: crwlWallLabel,

            wallStructureType: getCodeLayerLabel("csWallStructureType", crwlWallLayers?.structureType?.id) || " ",
            wallComponentTypeSize:
                getCodeLayerLabel(
                    "csWallComponentTypeSize",
                    crwlWallLayers?.componentTypeSize?.id,
                    crwlWallLayers?.structureType?.id
                ) || " ",
            wallSpacing:
                getCodeLayerLabel(
                    "csWallFramingSpacing",
                    crwlWallLayers?.spacing?.id,
                    crwlWallLayers?.structureType?.id
                ) || " ",
            wallFraming:
                getCodeLayerLabel(
                    "csWallFramingSpacing",
                    crwlWallLayers?.framing?.id,
                    crwlWallLayers?.structureType?.id
                ) || " ",
            wallInsLayer1:
                getCodeLayerLabel(
                    "csWallInsLayer1",
                    crwlWallLayers?.insulationLayer1?.id,
                    crwlWallLayers?.structureType?.id
                ) || " ",
            wallInsLayer2: getCodeLayerLabel("csWallInsLayer2", crwlWallLayers?.insulationLayer2?.id) || " ",
            wallInteriorFinish: getCodeLayerLabel("csWallInterior", crwlWallLayers?.interior?.id) || " ",
            wallSheathing: getCodeLayerLabel("csWallSheathing", crwlWallLayers?.sheathing?.id) || " ",
            wallExtFinish: getCodeLayerLabel("csWallExterior", crwlWallLayers?.exterior?.id) || " ",
            wallStudsCorner:
                getCodeLayerLabel("csWallStudsCornersInt", crwlWallLayers?.studsCornerIntersection?.id) || " ",

            lintelType: getCodeLayerLabel("csLintelType", lintelLayers?.lintelType?.id) || " ",
            lintelMaterial: getCodeLayerLabel("csLintelMaterial", lintelLayers?.lintelMaterial?.id) || " ",
            lintelIns: getCodeLayerLabel("csLintelInsulation", lintelLayers?.lintelIns?.id) || " ",

            slabInsType: slabLabel,
            slabInsRValue: convertUnit({
                value: slabInsType_effRVal || 0,
                type: "thermalResistance",
                inputUnit: getBaseUnits("basementSlabInsType", modelUnits).trueBase,
                outputUnit: getBaseUnits("basementSlabInsType", modelUnits).displayBase,
            }).toFixed(2),

            floorsAboveType: floorsAboveLabel,
            // floorLayers,
            floorsAboveStructureType:
                getCodeLayerLabel("csFloorsAboveStructureType", floorsAboveLayers?.structureType?.id) || " ",
            floorsAboveComponentTypeSize:
                getCodeLayerLabel(
                    "csFloorsAboveComponentTypeSize",
                    floorsAboveLayers?.componentTypeSize?.id,
                    floorsAboveLayers?.structureType?.id
                ) || " ",
            floorsAboveSpacing:
                getCodeLayerLabel(
                    "csFloorsAboveFramingSpacing",
                    floorsAboveLayers?.spacing?.id,
                    floorsAboveLayers?.structureType?.id
                ) || " ",
            floorsAboveFraming:
                getCodeLayerLabel(
                    "csFloorsAboveFramingSpacing",
                    floorsAboveLayers?.framing?.id,
                    floorsAboveLayers?.structureType?.id
                ) || " ",
            floorsAboveInsLayer1:
                getCodeLayerLabel(
                    "csFloorsAboveInsLayer1",
                    floorsAboveLayers?.insulationLayer1?.id,
                    floorsAboveLayers?.structureType?.id
                ) || " ",
            floorsAboveInsLayer2:
                getCodeLayerLabel("csFloorsAboveInsLayer2", floorsAboveLayers?.insulationLayer2?.id) || " ",
            floorsAboveIntFinish: getCodeLayerLabel("csFloorsAboveInterior", floorsAboveLayers?.interior?.id) || " ",
            floorsAboveSheathing: getCodeLayerLabel("csFloorsAboveSheathing", floorsAboveLayers?.sheathing?.id) || " ",
            floorsAboveExtFinish: getCodeLayerLabel("csFloorsAboveExterior", floorsAboveLayers?.exterior?.id) || " ",
            floorsAboveDropFraming:
                getCodeLayerLabel("csFloorsAboveDropFraming", floorsAboveLayers?.dropFraming?.id) || " ",
        };
    });

    const crawlspaceObjectData = !isEmpty(crawlspaceResults)
        ? crawlspaceResults.map((crawlspace) => {
              return {
                  [`${a1}`]: crawlspace.id || " ",
                  [`${b1}`]: crawlspace.label || " ",
                  C: crawlspace.length || " ",
                  D: crawlspace.width || " ",
                  E: crawlspace.perimeter || " ",
                  F: crawlspace.area || " ",
                  G: crawlspace.exposedPerimeter || " ",
                  H: crawlspace.totalWallHeight || " ",
                  I: crawlspace.depth || " ",
                  J: crawlspace.insulationConfig || " ",
                  K: crawlspace.crawlspaceWallTypeLabel || " ",
                  L: crawlspace.wallStructureType || " ",
                  M: crawlspace.wallComponentTypeSize || " ",
                  N: crawlspace.wallSpacing || " ",
                  O: crawlspace.wallFraming || " ",
                  P: crawlspace.wallInsLayer1 || " ",
                  Q: crawlspace.wallInsLayer2 || " ",
                  R: crawlspace.wallInteriorFinish || " ",
                  S: crawlspace.wallSheathing || " ",
                  T: crawlspace.wallExtFinish || " ",
                  U: crawlspace.wallStudsCorner || " ",
                  V: crawlspace.lintelType || " ",
                  W: crawlspace.lintelMaterial || " ",
                  X: crawlspace.lintelIns || " ",
                  Y: crawlspace.slabInsType || " ",
                  Z: crawlspace.slabInsRValue || " ",
                  AA: crawlspace.floorsAboveType || " ",
                  AB: crawlspace.floorsAboveStructureType || " ",
                  AC: crawlspace.floorsAboveComponentTypeSize || " ",
                  AD: crawlspace.floorsAboveSpacing || " ",
                  AE: crawlspace.floorsAboveFraming || " ",
                  AF: crawlspace.floorsAboveInsLayer1 || " ",
                  AG: crawlspace.floorsAboveInsLayer2 || " ",
                  AH: crawlspace.floorsAboveIntFinish || " ",
                  AI: crawlspace.floorsAboveSheathing || " ",
                  AJ: crawlspace.floorsAboveExtFinish || " ",
                  AK: crawlspace.floorsAboveDropFraming || " ",
              };
          })
        : [
              {
                  [`${a1}`]: "-",
                  [`${b1}`]: "-",
                  C: "-",
                  D: "-",
                  E: "-",
                  F: "-",
                  G: "-",
                  H: "-",
                  I: "-",
                  J: "-",
                  K: "-",
                  L: "-",
                  M: "-",
                  N: "-",
                  O: "-",
                  P: "-",
                  Q: "-",
                  R: "-",
                  S: "-",
                  T: "-",
                  U: "-",
                  V: "-",
                  W: "-",
                  X: "-",
                  Y: "-",
                  Z: "-",
                  AA: "-",
                  AB: "-",
                  AC: "-",
                  AD: "-",
                  AE: "-",
                  AF: "-",
                  AG: "-",
                  AH: "-",
                  AI: "-",
                  AJ: "-",
                  AK: "-",
              },
          ];

    // slab on grade
    const slabResults = Object.keys(slab).map((slabKey, ind) => {
        const {
            label = "",
            floor: {
                measurements: { isRectangular = false } = {},
                slabInsType: { id: slabId, codeLabel: slabCodeLabel = "" } = {},
                slabInsType_effRVal,
            } = {},
        } = slab[slabKey] || {};

        const slabInsLabel = getLabel("basementSlabInsType", slabId) || slabCodeLabel || "";
        return {
            id: ind + 1,
            label,
            length: convertUnit({
                value: isRectangular ? slab[slabKey]?.floor?.measurements?.length || 0 : 0,
                type: "length",
                inputUnit: getBaseUnits("slabLength", modelUnits).trueBase,
                outputUnit: getBaseUnits("slabLength", modelUnits).displayBase,
            })
                .toFixed(2)
                .replace("0.00", "-"),
            width: convertUnit({
                value: isRectangular ? slab[slabKey]?.floor?.measurements?.width || 0 : 0,
                type: "width",
                inputUnit: getBaseUnits("slabWidth", modelUnits).trueBase,
                outputUnit: getBaseUnits("slabWidth", modelUnits).displayBase,
            })
                .toFixed(2)
                .replace("0.00", "-"),
            perimeter: convertUnit({
                value: isRectangular ? slab[slabKey]?.floor?.measurements?.perimeter || 0 : 0,
                type: "length",
                inputUnit: getBaseUnits("slabPerimeter", modelUnits).trueBase,
                outputUnit: getBaseUnits("slabPerimeter", modelUnits).displayBase,
            })
                .toFixed(2)
                .replace("0.00", "-"),
            area: convertUnit({
                value: isRectangular ? slab[slabKey]?.floor?.measurements?.area || 0 : 0,
                type: "area",
                inputUnit: getBaseUnits("slabArea", modelUnits).trueBase,
                outputUnit: getBaseUnits("slabArea", modelUnits).displayBase,
            })
                .toFixed(2)
                .replace("0.00", "-"),
            exposedPerimeter: convertUnit({
                value: slab[slabKey]?.exposedSurfacePerimeter,
                type: "length",
                inputUnit: getBaseUnits("slabExpSurfacePerimeter", modelUnits).trueBase,
                outputUnit: getBaseUnits("slabExpSurfacePerimeter", modelUnits).displayBase,
            }).toFixed(2),
            insulationConfig: slab[slabKey]?.configuration?.type,
            slabInsType: slabInsLabel,
            slabInsRValue: convertUnit({
                value: slabInsType_effRVal || 0,
                type: "thermalResistance",
                inputUnit: getBaseUnits("basementSlabInsType", modelUnits).trueBase,
                outputUnit: getBaseUnits("basementSlabInsType", modelUnits).displayBase,
            }).toFixed(2),
        };
    });

    const slabObjectData = !isEmpty(slabResults)
        ? slabResults.map((slab) => {
              return {
                  [`${a1}`]: slab.id || " ",
                  [`${b1}`]: slab.label || " ",
                  C: slab.length || " ",
                  D: slab.width || " ",
                  E: slab.perimeter || " ",
                  F: slab.area || " ",
                  G: slab.exposedPerimeter || " ",
                  H: slab.insulationConfig || " ",
                  I: slab.slabInsType || " ",
                  J: slab.slabInsRValue || " ",
              };
          })
        : [
              {
                  [`${a1}`]: "-",
                  [`${b1}`]: "-",
                  C: "-",
                  D: "-",
                  E: "-",
                  F: "-",
                  G: "-",
                  H: "-",
                  I: "-",
                  J: "-",
              },
          ];

    let floorHeaderInd = 0;
    const floorHeaderResults = ["basement", "crawlspace"].reduce((fndCache, fndCompType) => {
        const { [fndCompType]: fndComp = {} } = components || {};

        return [
            ...fndCache,
            ...Object.keys(fndComp).reduce((cache, fndCompKey) => {
                const { label: fndLabel = "", subcomponents: { floorHeader = {} } = {} } = fndComp[fndCompKey];
                if (isEmpty(floorHeader)) {
                    return cache;
                }

                return [
                    ...cache,
                    ...Object.keys(floorHeader).map((floorHeaderKey) => {
                        const {
                            label,
                            measurements: { height, perimeter } = {},
                            floorHeaderInsType: { codeRef = "", codeLabel = "" },
                            floorHeaderInsType_effRVal,
                        } = floorHeader[floorHeaderKey];

                        const {
                            [codeRef]: {
                                layers: {
                                    insulationLayer1: { id: insulationLayer1Id } = {},
                                    insulationLayer2: { id: insulationLayer2Id } = {},
                                    sheathing: { id: sheathingId } = {},
                                    exterior: { id: exteriorId } = {},
                                } = {},
                            } = {},
                        } = codes;

                        floorHeaderInd += 1;
                        return {
                            id: floorHeaderInd,
                            componentId: floorHeaderKey,
                            label: label,
                            height: convertUnit({
                                value: height,
                                type: "length",
                                inputUnit: getBaseUnits("floorHeaderHeight", modelUnits).trueBase,
                                outputUnit: getBaseUnits("floorHeaderHeight", modelUnits).displayBase,
                            }).toFixed(2),
                            perimeter: convertUnit({
                                value: perimeter,
                                type: "length",
                                inputUnit: getBaseUnits("floorHeaderPerimeter", modelUnits).trueBase,
                                outputUnit: getBaseUnits("floorHeaderPerimeter", modelUnits).displayBase,
                            }).toFixed(2),
                            location: fndLabel,
                            floorHeaderInsType:
                                codeRef === "UserSpecified"
                                    ? getEffRValLabel(floorHeaderInsType_effRVal, modelUnits)
                                    : codeLabel,
                            insLayer1: getCodeLayerLabel("csFloorHeaderInsLayer1", insulationLayer1Id) || " ",
                            insLayer2: getCodeLayerLabel("csFloorHeaderInsLayer2", insulationLayer2Id) || " ",
                            sheathing: getCodeLayerLabel("csFloorHeaderSheathing", sheathingId) || " ",
                            extFinish: getCodeLayerLabel("csFloorHeaderExterior", exteriorId) || " ",
                        };
                    }),
                ];
            }, []),
        ];
    }, []);

    const floorHeaderObjectData =
        floorHeaderResults.length > 0
            ? floorHeaderResults.map((floorHeader) => {
                  return {
                      [`${a1}`]: floorHeader.id || " ",
                      [`${b1}`]: floorHeader.label || " ",
                      C: floorHeader.height || " ",
                      D: floorHeader.perimeter || " ",
                      E: floorHeader.location || " ",
                      F: floorHeader.floorHeaderInsType || " ",
                      G: floorHeader.insLayer1 || " ",
                      H: floorHeader.insLayer2 || " ",
                      I: floorHeader.sheathing || " ",
                      J: floorHeader.extFinish || " ",
                  };
              })
            : [
                  {
                      [`${a1}`]: "-",
                      [`${b1}`]: "-",
                      C: "-",
                      D: "-",
                      E: "-",
                      F: "-",
                      G: "-",
                      H: "-",
                      I: "-",
                      J: "-",
                  },
              ];

    const spacing = [
        {
            [`${a1}`]: "",
        },
        {
            [`${a1}`]: "",
        },
        {
            [`${a1}`]: "",
        },
    ];

    return [
        // each object is a row indcating the column and its value
        {
            [`${a1}`]: `Below Grade Heated Floor Area (${getBaseUnits("bgHeatedFloorArea", modelUnits).displayBase})`,
            [`${b1}`]: parseFloat(
                convertUnit({
                    value: totalBgFloorArea,
                    type: "area",
                    inputUnit: getBaseUnits("bgHeatedFloorArea", modelUnits).trueBase,
                    outputUnit: getBaseUnits("bgHeatedFloorArea", modelUnits).displayBase,
                }).toFixed(2)
            ),
        },
        {
            [`${a1}`]: "",
        },
        {
            [`${a1}`]: "",
        },
        // basement
        {
            [`${a1}`]: "Basement",
        },
        {
            [`${a1}`]: "ID",
            [`${b1}`]: "Label",
            C: `Length (${getBaseUnits("basementLength", modelUnits).displayBase})`,
            D: `Width (${getBaseUnits("basementWidth", modelUnits).displayBase})`,
            E: `Perimeter (${getBaseUnits("basementPerimeter", modelUnits).displayBase})`,
            F: `Area (${getBaseUnits("basementArea", modelUnits).displayBase})`,
            G: `Exposed Perimeter (${getBaseUnits("basementExpSurfacePerimeter", modelUnits).displayBase})`,
            H: `Total Wall Height (${getBaseUnits("basementHeight", modelUnits).displayBase})`,
            I: `Depth Below Grade (${getBaseUnits("basementDepth", modelUnits).displayBase})`,
            J: `Insulation Configuration`,
            K: "Interior Basement Wall Insulation",
            L: "Framing Material $",
            M: "Spacing $",
            N: "Insulation in Framing Layer $",
            O: "Extra Insulation Layer $",
            P: "Interior Finish $",
            Q: "# of studs in corner (if known) $",

            R: "Lintel Type $",
            S: "Lintel Material $",
            T: "Lintel Insulation $",

            U: "Exterior Basement Wall Insulation",

            V: "Slab Insulation Type",
            W: `Slab Insulation R-value (${getBaseUnits("basementSlabInsType", modelUnits).displayBase})`,
            X: "Floor Above Type",
            Y: "Structure type $",
            Z: "Component type/size $",
            AA: "Spacing $",
            AB: "Framing (for Solid structure type only) $",
            AC: "Insulation Layer 1 $",
            AD: "Insulation Layer 2 $",
            AE: "Interior Finish $",
            AF: "Sheathing $",
            AG: "Exterior Finish $",
            AH: "Drop Framing $",
        },
        ...basementObjectData,
        ...spacing,
        // pony walls
        {
            [`${a1}`]: "Pony Walls",
        },
        {
            [`${a1}`]: "ID",
            [`${b1}`]: "Label",
            C: `Height (${getBaseUnits("basementPonyWallHeight", modelUnits).displayBase})`,
            D: "Wall Type",
            E: `Structure Type $`,
            F: `Component Type/Size $`,
            G: `Spacing $`,
            H: `Framing (for solid structure type only) $`,
            I: "Insulation Layer 1 $",
            J: "Insulation Layer 2 $",
            K: "Interior Finish $",
            L: "Sheathing $",
            M: "Exterior Finish $",
            N: "# of studs in corner (if known) $",
        },
        ...ponyWallObjectData,
        ...spacing,
        // Crawlspace
        {
            [`${a1}`]: "Crawlspace",
        },
        {
            [`${a1}`]: "ID",
            [`${b1}`]: "Label",
            C: `Length (${getBaseUnits("crawlspaceLength", modelUnits).displayBase})`,
            D: `Width (${getBaseUnits("crawlspaceWidth", modelUnits).displayBase})`,
            E: `Perimeter (${getBaseUnits("crawlspacePerimeter", modelUnits).displayBase})`,
            F: `Area (${getBaseUnits("crawlspaceArea", modelUnits).displayBase})`,
            G: `Exposed Perimeter (${getBaseUnits("crawlspaceExpSurfacePerimeter", modelUnits).displayBase})`,
            H: `Total Wall Height (${getBaseUnits("crawlspaceWallHeight", modelUnits).displayBase})`,
            I: `Depth Below Grade (${getBaseUnits("crawlspaceWallDepthBelowGrade", modelUnits).displayBase})`,
            J: `Insulation Configuration`,
            K: "Crawlspace Wall Type Label",
            L: "Structure Type $",
            M: "Component type/size $",
            N: "Spacing $",
            O: "Framing (for solid structure type only) $",
            P: "Insulation Layer 1 $",
            Q: "Insulation Layer 2 $",
            R: "Interior Finish $",
            S: "Sheathing $",
            T: "Exterior finish $",
            U: "# of studs in corner $",
            V: "Lintel Type $",
            W: "Lintel Material $",
            X: "Lintel Insulation $",
            Y: "Slab Insulation Type",
            Z: "Slab Insulation R-Value",
            AA: "Floor Above Type",
            AB: "Structure type $",
            AC: "Component type/size $",
            AD: "Spacing $",
            AE: "Framing (for Solid structure type only) $",
            AF: "Insulation Layer 1 $",
            AG: "Insulation Layer 2 $",
            AH: "Interior Finish $",
            AI: "Sheathing $",
            AJ: "Exterior Finish $",
            AK: "Drop Framing $",
        },
        ...crawlspaceObjectData,
        ...spacing,
        // slab on grade
        {
            [`${a1}`]: "Slab-on-grade",
        },
        {
            [`${a1}`]: "ID",
            [`${b1}`]: "Label",
            C: `Length (${getBaseUnits("slabLength", modelUnits).displayBase})`,
            D: `Width (${getBaseUnits("slabWidth", modelUnits).displayBase})`,
            E: `Perimeter (${getBaseUnits("slabPerimeter", modelUnits).displayBase})`,
            F: `Area (${getBaseUnits("slabArea", modelUnits).displayBase})`,
            G: `Exposed Perimeter (${getBaseUnits("slabExpSurfacePerimeter", modelUnits).displayBase})`,
            H: `Insulation Configuration`,
            I: `Slab Insulation Type`,
            J: `Slab Insulation R-value`,
        },
        ...slabObjectData,
        ...spacing,
        // Floors Headers
        {
            [`${a1}`]: "Floor Headers",
        },
        {
            [`${a1}`]: "ID",
            [`${b1}`]: "Label",
            C: `Height (${getBaseUnits("floorHeaderHeight", modelUnits).displayBase})`,
            D: `Perimeter (${getBaseUnits("floorHeaderPerimeter", modelUnits).displayBase})`,
            E: "Location",
            F: "Floor Header Type",
            G: "Insulation Layers 1 $",
            H: "Insulation Layers 1 $",
            I: "Sheathing $",
            J: "Exterior Finish $",
        },
        ...floorHeaderObjectData,
    ];
};
