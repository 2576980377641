import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import CeilingFields from './';
import { actions } from 'features/Model/Enclosure/_ducks';

const { setInitCode, updateCodeDrawer } = actions;

const mapStateToProps = (
    {
        user: {
            codeLib:{
                Ceiling:{
                    codes: ceilingCodes={}
                }={},
                CeilingFlat:{
                    codes: ceilingFlatCodes={}
                }={}
            }={}
        },
        drawing:{
            imageData={},
        },
        enclosure:{
            calculatingRValue={}
        }={},
        form
    },
    {
        componentId
    }
) => {
    const modelSelector = formValueSelector('model');
    const selector = formValueSelector('drawingPolygon');
    const modelCodes = modelSelector({form}, 'modelData.codes') || {};
    const lengthUnits = selector({form}, `components.${componentId}.measurements.length_u`);
    const areaUnits = selector({form}, `components.${componentId}.measurements.area_u`);
    const { id:constructTypeId } = selector({form}, `components.${componentId}.constructionType`) || {};
    const { codeRef:selectedCodeRef } = selector({form}, `components.${componentId}.ceilingInsType`) || {};
    const ceilingCodeWarning = selector({form}, `components.${componentId}.ceilingInsType_warning`) || '';
    const selectedRValue  = selector({form}, `components.${componentId}.ceilingInsType_nomRVal`) || 0;
    const rValUnits = selector({form}, `components.${componentId}.ceilingInsType_u`);
    const {
        [componentId]:{
            ceilingInsType:isCalculatingRValue=false,
        }={}
    } = calculatingRValue;

    const lines = Object.keys(imageData).reduce((cache, image) => {
        const { lines={} } = imageData[image] || {};
        return {
            ...cache,
            ...lines,
        }
    },{});

    const fieldsWithLinesAttached = Object.keys(lines).reduce((cache, line) => {
        const {
            changeField:{
                key:fieldKey=''
            }={},
            attachTo:{
                componentId:lineComponentId,
            }={}
        } = lines[line];

        if (lineComponentId === componentId) {
            return [
                ...cache,
                fieldKey
            ];
        }

        return cache;
    },[]);

    return {
        modelCodes,
        selectedRValue,
        selectedCodeRef,
        lengthUnits,
        areaUnits,
        libCeilingCodes:ceilingCodes,
        libCeilingFlatCodes:ceilingFlatCodes,
        constructTypeId,
        rValUnits,
        fieldsWithLinesAttached,
        isCalculatingRValue,
        ceilingCodeWarning
    }
};

const mapDispatchToProps = (dispatch) => ({
    setInitCode: async (code, type) => {
        return dispatch(setInitCode(code, type));
    },
    updateCodeDrawer: (updates) => dispatch(updateCodeDrawer({ updates }))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CeilingFields);