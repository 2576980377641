import React from "react";
import { Field } from "redux-form";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import InputWithUnits from "components/Input/InputWithUnits";
import { getValidation, getDecimalPlaces } from "utils/fieldValidation";
import { getBaseUnits, getUnitOptions } from "utils/fields";

const heightValidation = getValidation("wallHeight");
const perimeterValidation = getValidation("wallPerimeter");
const areaValidation = getValidation("wallArea");
const wallCornerValidation = getValidation("wallCorner");
const wallIntersectionValidation = getValidation("wallIntersection");

export default React.memo(
    ({
        accessor,
        wallHeight = 0,
        wallPerimeter = 0,
        modelUnits,
        heightUnits,
        perimeterUnits,
        areaUnits,
        change,
        isDrawingComponent,
        wallHeightDrawing,
        wallHeightEquation,
        wallPerimeterEquation,
    }) => (
        <>
            <InputRow gridTemplate="1fr 1fr 1fr">
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.measurements.height`}
                    label="Interior Wall Height"
                    placeholder="0.00"
                    validate={heightValidation}
                    decimals={getDecimalPlaces("wallHeight")}
                    change={change}
                    disabled={wallHeightDrawing}
                    isDrawing={wallHeightDrawing}
                    setValue={wallHeight}
                    units={{
                        base: getBaseUnits("wallHeight", modelUnits),
                        options: getUnitOptions("length"),
                        selected: heightUnits,
                        unitType: "length",
                        accessor: `${accessor}.measurements.height_u`,
                    }}
                    withEquation
                    equation={wallHeightEquation}
                    info={"The interior vertical height of the wall from the floor to the ceiling."}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.measurements.perimeter`}
                    label="Interior Wall Perimeter"
                    placeholder="0.00"
                    validate={perimeterValidation}
                    decimals={getDecimalPlaces("wallPerimeter")}
                    disabled={isDrawingComponent}
                    isDrawing={isDrawingComponent}
                    change={change}
                    setValue={wallPerimeter}
                    units={{
                        base: getBaseUnits("wallPerimeter", modelUnits),
                        options: getUnitOptions("length"),
                        selected: perimeterUnits,
                        unitType: "length",
                        accessor: `${accessor}.measurements.perimeter_u`,
                    }}
                    withEquation
                    equation={wallPerimeterEquation}
                    info={
                        "The interior length of the wall. When entering multiple walls of identical construction as one entry, use the sum of the individual wall interior lengths. If one wall component is used for the entire house, enter the interior exposed wall perimeter of the entire house."
                    }
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.measurements.area`}
                    label="Interior Wall Area"
                    placeholder="0.00"
                    disabled={true}
                    setValue={wallHeight * wallPerimeter}
                    validate={areaValidation}
                    decimals={getDecimalPlaces("wallArea")}
                    change={change}
                    units={{
                        base: getBaseUnits("wallArea", modelUnits),
                        options: getUnitOptions("area"),
                        selected: areaUnits,
                        unitType: "area",
                        accessor: `${accessor}.measurements.area_u`,
                    }}
                />
            </InputRow>
            <InputRow gridTemplate="1fr 1fr 1fr">
                <Field
                    component={Input}
                    type="number"
                    name={`${accessor}.measurements.corners`}
                    label="Corners"
                    placeholder="0"
                    quantity
                    validate={wallCornerValidation}
                    info={"Use default (1) if unknown"}
                />
                <Field
                    component={Input}
                    type="number"
                    name={`${accessor}.measurements.intersections`}
                    label="Intersections"
                    placeholder="0"
                    quantity
                    validate={wallIntersectionValidation}
                    info={"Use default (1) if unknown"}
                />
            </InputRow>
        </>
    )
);
