import React, { useEffect, useCallback, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { isEqual } from "lodash";
import uniqid from "uniqid";

import {
    selectCurrentUpgradePackage,
    selectFetchingCurrentPackage,
    selectCurrentPackageToCompare,
} from "store/upgradeLibrary/selectors";
import { selectCodeLib } from "store/users/selectors";

import { deleteUpgrade, selectUpgrade } from "store/upgradeLibrary/actions";

import {
    fetchUpgradePackage,
    duplicateUpgradePackage,
    deleteUpgradePackage,
    saveUpdateUpgradePackage,
} from "store/upgradeLibrary/thunk";

import {
    getUpgradeEnvelopeRowLabels,
    getUpgradeHvacRowLabels,
    getUpgradeDhwRowLabels,
    getUpgradeOtherRowLabels,
    envelopeRowHeadings,
    hvacRowHeadings,
    dhwRowHeadings,
    otherRowHeadings,
    getBlankUpgradeTemplates,
    getBlankComponentUpgradeFields,
} from "utils/upgrades";

import Loading from "components/Loading";
import Button from "components/Button";
import Table from "components/Table";
import PackageDeleteDialog from "../PackageDeleteDialog";
import PackageNameChangeDialog from "../PackageNameChangeDialog";
import LabelRow from "./LabelRow";
import UpgradeDrawer from "features/Model/Upgrades/UpgradeDrawer/container";
import CodeDrawer from "features/Model/CodeDrawer/container";

import pencilIcon from "assets/images/icons/pencil.svg";
import duplicateIcon from "assets/images/icons/duplicateBlue.svg";
import Delete from "assets/images/icons/JSX/Delete";
import CopyUpgrade from "assets/images/icons/Share-model.svg";

import classes from "./styles.module.scss";

const UpgradePackage = ({
    uid,
    packageId,
    fetchUpgradePackage,
    upgradePackage,
    isFetchingCurrentPackage,
    isLoading,
    fetchUpgradeLabels,
    deleteUpgradePackage,
    duplicateUpgradePackage,
    deleteUpgrade,
    currentPackageToCompare,
    saveUpdateUpgradePackage,
    history,
    selectUpgrade,
    organizationId,
    showOrganizationUpgrades,
}) => {
    const [toDeletePackage, setToDeletePackage] = useState({
        packageId: "",
        packageName: "",
    });
    const [toUpdatePackage, setToUpdatePackage] = useState({
        packageId: "",
        packageName: "",
    });
    const [isDuplication, setIsDuplication] = useState(false);
    const [isCopying, setIsCopying] = useState(false);
    const [upgradeOpen, toggleUpgrade] = useState({});
    const [isSaving, setIsSaving] = useState(false);

    const isAnyChanges = !isEqual(currentPackageToCompare?.upgrades, upgradePackage?.upgrades);

    const fetchPackage = useCallback(() => {
        fetchUpgradePackage(uid, packageId, false, showOrganizationUpgrades ? organizationId : null);
    }, [packageId, showOrganizationUpgrades]);

    useEffect(() => {
        fetchPackage();
    }, [packageId, showOrganizationUpgrades]);

    useEffect(() => {
        if (isAnyChanges) {
            const unloadCallback = (event) => {
                event.preventDefault();
                event.returnValue = "";
                return "";
            };

            window.addEventListener("beforeunload", unloadCallback);
            return () => window.removeEventListener("beforeunload", unloadCallback);
        }
    }, [isAnyChanges]);

    const { name = "" } = upgradePackage || {};

    const handleDelete = async () => {
        await deleteUpgradePackage(uid, packageId, showOrganizationUpgrades, organizationId);
        setToDeletePackage({
            packageId: "",
            packageName: "",
        });
        fetchUpgradeLabels();

        history.push("/upgrade-library");
    };

    const envelopeRows = upgradePackage
        ? envelopeRowHeadings.map((rowObj) => {
              let label = "";
              let initUpgradeData = {};

              const { label: newLabel, upgradeIds } = getUpgradeEnvelopeRowLabels({
                  components: {},
                  codes: {},
                  airtightness: {},
                  rowId: rowObj.id,
                  upgradePackage: {
                      [packageId]: upgradePackage,
                  },
              });

              const isUpgradeIdsExist = upgradeIds ? (upgradeIds.length > 0 ? true : false) : false;

              if (isUpgradeIdsExist) {
                  if (upgradePackage.upgrades[upgradeIds[0]].autoGenerateLabel || newLabel === "None") {
                      label = newLabel;
                  }

                  if (!upgradePackage.upgrades[upgradeIds[0]]?.autoGenerateLabel && newLabel !== "None") {
                      label = Object.values(upgradePackage.upgrades[upgradeIds[0]].label)[0];
                  }
              }

              initUpgradeData = isUpgradeIdsExist
                  ? {
                        fields: upgradePackage.upgrades[upgradeIds[0]].fields,
                    }
                  : getBlankComponentUpgradeFields(rowObj.id);

              const newUpgradeId = uniqid("upgrade-");

              return {
                  house: {
                      ...rowObj,
                      className: `${classes.regularCell} ${classes.freezeCell} ${classes.flexCell} ${
                          classes.flexCell
                      } ${(label === "None" || label === "") && !isUpgradeIdsExist && classes.fadeCell}`,
                  },
                  [packageId]: {
                      content: (
                          <LabelRow
                              label={label === "None" && !isUpgradeIdsExist ? "" : label}
                              isUpgradeIdsExist={isUpgradeIdsExist}
                              deleteUpgrade={() => deleteUpgrade(isUpgradeIdsExist ? upgradeIds[0] : "")}
                              toggleUpgradeDrawer={() => {
                                  selectUpgrade(isUpgradeIdsExist ? upgradeIds[0] : newUpgradeId);
                                  toggleUpgrade({
                                      isOpen: true,
                                      formName: "upgrades",
                                      selectedPackage: packageId,
                                      selectedUpgrade: isUpgradeIdsExist ? upgradeIds[0] : newUpgradeId,
                                      upgradeType: rowObj.id,
                                      selectedUpgradeType: rowObj.id,
                                      selectedUpgradeH2kType: rowObj.upgradeType,
                                      selectedUpgradeTypeLabel: rowObj.content,
                                      selectedUpgradeLabel: label,
                                      initUpgradeData,
                                      withSelectComponents: false,
                                      accessor: "fields",
                                      isUpgradeLibrary: true,
                                      currentPackage: upgradePackage,
                                      isNew: !isUpgradeIdsExist,
                                  });
                              }}
                          />
                      ),
                      className: `${classes.regularCell} ${classes.freezeCell} `,
                  },
              };
          })
        : [];

    const hvacRows = upgradePackage
        ? hvacRowHeadings.map((rowObj) => {
              let label = "";
              let initUpgradeData = {};

              const { label: newLabel, upgradeIds } = getUpgradeHvacRowLabels({
                  heatingCooling: {},
                  ventilation: {},
                  rowId: rowObj.id,
                  upgradePackage: {
                      [packageId]: upgradePackage,
                  },
              });

              const isUpgradeIdsExist = upgradeIds ? (upgradeIds.length > 0 ? true : false) : false;

              if (isUpgradeIdsExist) {
                  if (upgradePackage.upgrades[upgradeIds[0]].autoGenerateLabel || newLabel === "None") {
                      label = newLabel;
                  }

                  if (!upgradePackage.upgrades[upgradeIds[0]]?.autoGenerateLabel && newLabel !== "None") {
                      label = Object.values(upgradePackage.upgrades[upgradeIds[0]].label)[0];
                  }
              }

              initUpgradeData = isUpgradeIdsExist
                  ? {
                        fields: upgradePackage.upgrades[upgradeIds[0]].fields,
                    }
                  : {
                        fields: getBlankUpgradeTemplates(rowObj.id),
                    };

              return {
                  house: {
                      ...rowObj,
                      className: `${classes.regularCell} ${classes.freezeCell} ${classes.flexCell} ${
                          classes.flexCell
                      } ${(label === "None" || label === "") && !isUpgradeIdsExist && classes.fadeCell}`,
                  },
                  [packageId]: {
                      content: (
                          <LabelRow
                              label={label === "None" && !isUpgradeIdsExist ? "" : label}
                              isUpgradeIdsExist={isUpgradeIdsExist}
                              deleteUpgrade={() => deleteUpgrade(isUpgradeIdsExist ? upgradeIds[0] : "")}
                              toggleUpgradeDrawer={() => {
                                  selectUpgrade(isUpgradeIdsExist ? upgradeIds[0] : null);
                                  toggleUpgrade({
                                      isOpen: true,
                                      formName: "upgrades",
                                      selectedPackage: packageId,
                                      selectedUpgrade: isUpgradeIdsExist ? upgradeIds[0] : undefined,
                                      upgradeType: rowObj.id,
                                      selectedUpgradeType: rowObj.id,
                                      selectedUpgradeH2kType: rowObj.upgradeType,
                                      selectedUpgradeTypeLabel: rowObj.content,
                                      selectedUpgradeLabel: label,
                                      initUpgradeData,
                                      withSelectComponents: false,
                                      accessor: "fields",
                                      isUpgradeLibrary: true,
                                  });
                              }}
                          />
                      ),
                      className: `${classes.regularCell} ${classes.freezeCell} `,
                  },
              };
          })
        : [];

    const dhwRows = upgradePackage
        ? dhwRowHeadings.map((rowObj) => {
              let label = "";
              let initUpgradeData = {};

              const { label: newLabel, upgradeIds } = getUpgradeDhwRowLabels({
                  domesticHotWater: {},
                  heatingCooling: {},
                  rowId: rowObj.id,
                  upgradePackage: {
                      [packageId]: upgradePackage,
                  },
              });

              const isUpgradeIdsExist = upgradeIds ? (upgradeIds.length > 0 ? true : false) : false;

              if (isUpgradeIdsExist) {
                  if (upgradePackage.upgrades[upgradeIds[0]].autoGenerateLabel || newLabel === "None") {
                      label = newLabel;
                  }

                  if (!upgradePackage.upgrades[upgradeIds[0]]?.autoGenerateLabel && newLabel !== "None") {
                      label = Object.values(upgradePackage.upgrades[upgradeIds[0]].label)[0];
                  }
              }

              initUpgradeData = isUpgradeIdsExist
                  ? {
                        fields: upgradePackage.upgrades[upgradeIds[0]].fields,
                    }
                  : {
                        fields: getBlankUpgradeTemplates(rowObj.id),
                    };

              return {
                  house: {
                      ...rowObj,
                      className: `${classes.regularCell} ${classes.freezeCell} ${classes.flexCell} ${
                          classes.flexCell
                      } ${(label === "None" || label === "") && !isUpgradeIdsExist && classes.fadeCell}`,
                  },
                  [packageId]: {
                      content: (
                          <LabelRow
                              label={label === "None" && !isUpgradeIdsExist ? "" : label}
                              isUpgradeIdsExist={isUpgradeIdsExist}
                              deleteUpgrade={() => deleteUpgrade(isUpgradeIdsExist ? upgradeIds[0] : "")}
                              toggleUpgradeDrawer={() => {
                                  selectUpgrade(isUpgradeIdsExist ? upgradeIds[0] : null);
                                  toggleUpgrade({
                                      isOpen: true,
                                      formName: "upgrades",
                                      selectedPackage: packageId,
                                      selectedUpgrade: isUpgradeIdsExist ? upgradeIds[0] : undefined,
                                      upgradeType: rowObj.id,
                                      selectedUpgradeType: rowObj.id,
                                      selectedUpgradeH2kType: rowObj.upgradeType,
                                      selectedUpgradeTypeLabel: rowObj.content,
                                      selectedUpgradeLabel: label,
                                      initUpgradeData,
                                      withSelectComponents: false,
                                      accessor: "fields",
                                      isUpgradeLibrary: true,
                                  });
                              }}
                          />
                      ),
                      className: `${classes.regularCell} ${classes.freezeCell} `,
                  },
              };
          })
        : [];

    const otherRows = upgradePackage
        ? otherRowHeadings
              .filter(({ id }) => id !== "totalCapitalCost")
              .map((rowObj) => {
                  let label = "";
                  let initUpgradeData = {};

                  const { label: newLabel, upgradeIds } = getUpgradeOtherRowLabels({
                      generation: {},
                      program: {},
                      baseLoads: {},
                      rowId: rowObj.id,
                      upgradePackage: {
                          [packageId]: upgradePackage,
                      },
                  });

                  const isUpgradeIdsExist = upgradeIds ? (upgradeIds.length > 0 ? true : false) : false;

                  if (isUpgradeIdsExist) {
                      if (upgradePackage.upgrades[upgradeIds[0]].autoGenerateLabel || newLabel === "None") {
                          label = newLabel;
                      }

                      if (!upgradePackage.upgrades[upgradeIds[0]]?.autoGenerateLabel && newLabel !== "None") {
                          label = Object.values(upgradePackage.upgrades[upgradeIds[0]].label)[0];
                      }
                  }

                  initUpgradeData = isUpgradeIdsExist
                      ? {
                            fields: upgradePackage.upgrades[upgradeIds[0]].fields,
                        }
                      : {
                            fields: getBlankUpgradeTemplates(rowObj.id),
                        };

                  return {
                      house: {
                          ...rowObj,
                          className: `${classes.regularCell} ${classes.freezeCell} ${classes.flexCell} ${
                              classes.flexCell
                          } ${(label === "None" || label === "") && !isUpgradeIdsExist && classes.fadeCell}`,
                      },
                      [packageId]: {
                          content: (
                              <LabelRow
                                  label={label === "None" && !isUpgradeIdsExist ? "" : label}
                                  isUpgradeIdsExist={isUpgradeIdsExist}
                                  deleteUpgrade={() => deleteUpgrade(isUpgradeIdsExist ? upgradeIds[0] : "")}
                                  toggleUpgradeDrawer={() => {
                                      selectUpgrade(isUpgradeIdsExist ? upgradeIds[0] : null);
                                      toggleUpgrade({
                                          isOpen: true,
                                          formName: "upgrades",
                                          selectedPackage: packageId,
                                          selectedUpgrade: isUpgradeIdsExist ? upgradeIds[0] : undefined,
                                          upgradeType: rowObj.id,
                                          selectedUpgradeType: rowObj.id,
                                          selectedUpgradeH2kType: rowObj.upgradeType,
                                          selectedUpgradeTypeLabel: rowObj.content,
                                          selectedUpgradeLabel: label,
                                          initUpgradeData,
                                          withSelectComponents: false,
                                          accessor: "fields",
                                          isUpgradeLibrary: true,
                                      });
                                  }}
                              />
                          ),
                          className: `${classes.regularCell} ${classes.freezeCell} `,
                      },
                  };
              })
        : [];

    return (
        <div className={classes.upgradePackageContainer}>
            {/* //TODO Aidan to add copy for error state */}
            {!isFetchingCurrentPackage && !upgradePackage && !isLoading && <h1>Oppsie</h1>}
            {/* 2 loading states because package would load faster than labels */}
            {(isFetchingCurrentPackage || isLoading) && !upgradePackage && (
                <div className={classes.loadingContainer}>
                    <Loading />
                </div>
            )}
            {!isFetchingCurrentPackage && upgradePackage && (
                <>
                    <div className={classes.upgradePackageWrapper}>
                        <div className={classes.upgradePackageHeaderContainer}>
                            <div className={classes.upgradePackageTitleWrapper}>
                                <h3 className={classes.upgradePackageTitle}>{name}</h3>
                                <img
                                    src={pencilIcon}
                                    alt="pencil icon"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                        setToUpdatePackage({
                                            packageId,
                                            packageName: name,
                                        })
                                    }
                                />
                            </div>
                            <div className={classes.upgradePackageHeaderBtnWrapper}>
                                <div
                                    className={classes.iconTextWrapper}
                                    onClick={() =>
                                        setToDeletePackage({
                                            packageId,
                                            packageName: name,
                                        })
                                    }
                                >
                                    <Delete className={classes.deleteSvg} />
                                    <span className={classes.deleteText}>Delete</span>
                                </div>
                                <div
                                    className={classes.iconTextWrapper}
                                    onClick={async () => {
                                        setIsDuplication(true);
                                        await duplicateUpgradePackage(
                                            uid,
                                            upgradePackage,
                                            showOrganizationUpgrades,
                                            organizationId
                                        );
                                        setIsDuplication(false);
                                    }}
                                >
                                    <img src={duplicateIcon} alt="Duplicate Icon" />
                                    <span className={classes.duplicateText}>
                                        {isDuplication ? "Duplicating..." : "Duplicate"}
                                    </span>
                                </div>
                                {!showOrganizationUpgrades && (
                                    <div
                                        className={classes.iconTextWrapper}
                                        onClick={async () => {
                                            setIsCopying(true);
                                            await duplicateUpgradePackage(uid, upgradePackage, true, organizationId);
                                            setIsCopying(false);
                                        }}
                                    >
                                        <img src={CopyUpgrade} alt="Duplicate Icon" />
                                        <span className={classes.duplicateText}>
                                            {isCopying ? "Copying..." : "Copy to Organization"}
                                        </span>
                                    </div>
                                )}
                                <Button
                                    disabled={!isAnyChanges || isSaving}
                                    onClick={async () => {
                                        setIsSaving(true);

                                        await saveUpdateUpgradePackage({
                                            uid,
                                            upgradePackage,
                                            upgradePackageId: packageId,
                                            saveAsNew: false,
                                            withCodes: false,
                                            isCodeLibrary: true,
                                            saveIntoOrganization: showOrganizationUpgrades,
                                            organizationId: organizationId,
                                        });

                                        setIsSaving(false);
                                    }}
                                >
                                    {isSaving ? "Saving..." : "Save Changes"}
                                </Button>
                            </div>
                        </div>
                        <Table
                            className={classes.upgradesTable}
                            headClassName={classes.tableHead}
                            headCells={[{ id: "house" }, { id: packageId }]}
                            rows={[
                                {
                                    house: {
                                        id: "envelope",
                                        value: "envelope",
                                        content: <strong>ENVELOPE</strong>,
                                        className: `${classes.dividerCell} ${classes.freezeCell} `,
                                    },
                                    [packageId]: {
                                        value: "",

                                        className: classes.dividerCell,
                                    },
                                },
                                ...envelopeRows,
                                {
                                    house: {
                                        id: "hvac",
                                        value: "hvac",
                                        content: <strong>HVAC</strong>,
                                        className: `${classes.dividerCell} ${classes.freezeCell}`,
                                    },
                                    [packageId]: {
                                        value: "",
                                        className: classes.dividerCell,
                                    },
                                },
                                ...hvacRows,
                                {
                                    house: {
                                        id: "dhw",
                                        value: "dhw",
                                        content: <strong>DHW</strong>,
                                        className: `${classes.dividerCell} ${classes.freezeCell}`,
                                    },
                                    [packageId]: {
                                        value: "",
                                        className: classes.dividerCell,
                                    },
                                },
                                ...dhwRows,
                                {
                                    house: {
                                        id: "other",
                                        value: "other",
                                        content: <strong>OTHER</strong>,
                                        className: `${classes.dividerCell} ${classes.freezeCell}`,
                                    },
                                    [packageId]: {
                                        value: "",
                                        className: classes.dividerCell,
                                    },
                                },
                                ...otherRows,
                            ]}
                        />
                    </div>
                    <UpgradeDrawer toggleOpen={toggleUpgrade} modelChange={() => {}} {...upgradeOpen} />
                    <CodeDrawer />
                    <PackageNameChangeDialog
                        toUpdatePackage={toUpdatePackage}
                        handleClose={() =>
                            setToUpdatePackage({
                                packageId: "",
                                packageName: "",
                            })
                        }
                        uid={uid}
                        saveIntoOrganization={showOrganizationUpgrades}
                        orgId={organizationId}
                    />
                    <PackageDeleteDialog
                        toDeletePackage={toDeletePackage}
                        handleDelete={() => handleDelete()}
                        toggleToDelete={() =>
                            setToDeletePackage({
                                packageId: "",
                                packageName: "",
                            })
                        }
                    />
                </>
            )}
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    upgradePackage: selectCurrentUpgradePackage,
    isFetchingCurrentPackage: selectFetchingCurrentPackage,
    codeLibrary: selectCodeLib,
    currentPackageToCompare: selectCurrentPackageToCompare,
});

const mapDispatchToProps = (dispatch) => ({
    fetchUpgradePackage: (uid, packageId, withReturn, organizationId) =>
        dispatch(fetchUpgradePackage(uid, packageId, withReturn, organizationId)),
    deleteUpgradePackage: (uid, packageId, deleteFromOrg, orgId) =>
        dispatch(deleteUpgradePackage(uid, packageId, deleteFromOrg, orgId)),
    duplicateUpgradePackage: (uid, upgradePackage, saveIntoOrganization, orgId) =>
        dispatch(duplicateUpgradePackage(uid, upgradePackage, saveIntoOrganization, orgId)),
    deleteUpgrade: (upgradeId) => dispatch(deleteUpgrade(upgradeId)),
    saveUpdateUpgradePackage: ({
        uid,
        upgradePackage,
        upgradePackageId,
        saveAsNew,
        withCodes = true,
        isCodeLibrary,
        organizationId,
        saveIntoOrganization,
    }) =>
        dispatch(
            saveUpdateUpgradePackage({
                uid,
                upgradePackage,
                upgradePackageId,
                saveAsNew,
                withCodes,
                isCodeLibrary,
                organizationId,
                saveIntoOrganization,
            })
        ),
    selectUpgrade: (upgradeId) => dispatch(selectUpgrade(upgradeId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpgradePackage);
