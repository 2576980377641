import moment from "moment";
import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

export const updateObject = (oldObject, updateProperties) => {
    return {
        ...oldObject,
        ...updateProperties,
    };
};

export const timeDifference = ({ start, end, type = "" }) => {
    const a = moment(start);
    const b = moment(end);

    if (type) {
        return `${a.diff(b, type)} ${type}`;
    }

    const diff = a.diff(b, "minutes");

    if (diff < 1) {
        return `less than 1 minute`;
    }

    if (diff > 59 && diff < 1440) {
        return `${a.diff(b, "hours")} hours`;
    }

    if (diff > 1440) {
        return `${a.diff(b, "days")} days`;
    }

    return `${diff} minutes`;
};

export const capitalize = (text) => (text ? text.charAt(0).toUpperCase() + text.slice(1) : "");

export const idIfyDate = (date) => moment(date || new Date()).format("YYYYMMDDHHmmssSS");

export const toCamelCase = (str) =>
    str
        // Lowercase the string
        .toLowerCase()
        // Split the string into an array of words
        .split(" ")
        // Transform each word
        .map((word, index) => {
            // Capitalize the first letter of each word except the first
            if (index === 0) {
                return word;
            } else {
                return word.charAt(0).toUpperCase() + word.slice(1);
            }
        })
        // Join all the words to get a single string
        .join("");
