const SET_RESULTS = "SET_RESULTS";
const SET_LINKED_RESULTS = "SET_LINKED_RESULTS";
const TOGGLE_RESULTS_LOADING = "TOGGLE_RESULTS_LOADING";
const SET_RESULTS_ERROR = "SET_RESULTS_ERROR";
const RESET_RESULTS = "RESET_RESULTS";
const CHANGE_PACKAGE = "CHANGE_PACKAGE";
const CHANGE_CASE = "CHANGE_CASE";

export default {
    SET_RESULTS,
    SET_LINKED_RESULTS,
    TOGGLE_RESULTS_LOADING,
    SET_RESULTS_ERROR,
    RESET_RESULTS,
    CHANGE_PACKAGE,
    CHANGE_CASE,
};
