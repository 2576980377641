import React, { useState, useEffect } from 'react';
import classes from './style.module.scss';
import sharedClasses from '../style.module.scss';
import Select from '../Select';
import Input from '../';
import { updateObject } from 'utils';

const SelectWithInput = ({
    name, 
    label, 
    input,
    selectClassName, 
    large,
    hideLabel,
    options=[],
    disabled=false,
    placeholder,
    updateSelected,
    info='',
    meta={},
    inputData:{
        triggerValue=0,
        inputLabel='',
        inputType='text',
        inputPlaceholder='',
    }={},
    isVertical = false,
    onInputChange=()=>{},
    warning=''
}) => {
    const { invalid=false, error=''  } = meta;
    const { value:valueObj, onChange } = input;
    const {
        id,
        value
    } = valueObj;
    const hasInput = triggerValue === id;

    const { label:triggerLabel } = options.find(({
        value:{ 
            id:val
        } = {}
    }) => val === triggerValue) || {};

    const [ inputField, updateInputField ] = useState(value);

    const handleInputChange = (value) => {
        const targetValue = inputType === 'number' ? parseFloat(value) : value;
        const newValue = updateObject(valueObj, { value:targetValue });
        onInputChange(newValue);
        onChange(newValue);
        updateInputField(value);
    }

    useEffect(()=>{
        if (hasInput && inputField) {
            const newValue = updateObject(valueObj, { value:inputField });
            onChange(newValue);
        }
    },[hasInput]);

    return (
        <div className={`${classes.selectWithInput} ${hasInput ? classes.hasInput : ''} ${isVertical ? classes.isVertical : ''}`}>
            <Select
                className={`${selectClassName} ${classes.selectField}`}
                name={name}
                label={label}
                input={input}
                large={large}
                hideLabel={hideLabel}
                options={options}
                disabled={disabled}
                placeholder={placeholder}
                updateSelected={updateSelected}
                info={info}
                forcedLabel={hasInput ? triggerLabel : ''}
                parentError={invalid && error}
            />
            {
                hasInput &&
                    <Input
                        label={inputLabel}
                        type={inputType}
                        placeholder={inputPlaceholder}
                        hideLabel
                        input={{
                            onChange:(val)=>handleInputChange(val),
                            value:inputField
                        }}
                        parentError={invalid && error}
                        warning={warning}
                    />
            }
            {invalid && error && <span className={sharedClasses.errorMessage}>{error}</span>}
        </div>
    )
};

export default SelectWithInput;