import { connect } from 'react-redux';
import ConfigDrawer from './';
import { actions } from "features/Model/Enclosure/_ducks";

const { clearFoundationConfig } = actions;

const mapStateToProps = ({
    form,
    enclosure:{
        foundationConfig:{
            open=false,
            fieldId="",
            fieldAccessor="",
            rValUnits="",
            currentFormChange,
            formName="model",
            fieldKey="basementInsConfig"
        }={}
    }={}
},{
}) => {
    return {
        open,
        fieldId,
        fieldAccessor,
        currentFormChange,
        fieldKey
    };
};

const mapDispatchToProps = (dispatch) => ({
    clearFoundationConfig: () => dispatch(clearFoundationConfig())
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfigDrawer);
