import React from "react";
import classes from "./style.module.scss";
import classNames from "classnames";
import { getProvCode } from "utils/weather";
import Tooltip from "components/Tooltip";

const BarLine = ({ color, value = 0, addUnit, maxValue }) => {
    // Percentage of outer container the line will take to make room for the value label
    const maxWidth = 75;
    // Draw bar lines for negative values the same length as if it were 0
    const posValue = value > 0 ? value : 0;
    // Calculate the line width in relation to the maximum value possible and the available space 
    const lineWidth = ((posValue / maxValue) * maxWidth) + 0.1; // + 0.1 to draw a small line for 0 values

    return (
        <div className={classes.barLine}>
            <div className={classes[color]} style={{ width: `${lineWidth}%` }} />
            <p>{addUnit(value.toFixed(2))}</p>
        </div>
    );
};

const colorScheme = [
    "dark-blue",
    "blue",
    "blue-highlight",
    "orange",
    "gold",
    "green",
    "blue-sky",
    "red",
    "dark-red",
    "green-dark",
    "blue-light"
];

const getBarColor = (index) => {
    const colorIndex = (parseInt(index)) % colorScheme.length;
    return colorScheme[colorIndex];
}

const getUnitFunc = (metric) => (value) => {
    const formattedValue = `${value}`.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
    );
    switch(metric) {
        case "energy":
            return `${formattedValue} GJ`;
        case "opEmissions":
            return `${formattedValue} t/yr`;
        case "opCost":
            return `$${formattedValue}/yr`;
        case "renoCost":
            return `$${formattedValue}`;
    }
}

const getValueFunc = (metric) => (item) => {
    switch(metric) {
        case "energy":
            return item.energyConsumption || 0;
        case "opEmissions":
            return item.opEmissions || 0;
        case "opCost":
            return item.opCost || 0;
        case "renoCost":
            return item.renoCost || 0;
    }
}

const BarLabels = ({ sortedData }) => {
    return (
        <div className={classes.barLabels}>
            {sortedData.map((model, index) => (
                <div
                    key={index}
                    className={`${classes.info}`}
                    data-html
                    data-tip={`<span style="display:block;max-width:14rem;">${model.name}</span>`}
                    data-for={`${index}_model`}
                >
                    <p>{model.name}</p>
                    <Tooltip id={`${index}_model`} />
                </div>
            ))}
        </div>
    );
}

const BarChart = ({ title, subtitle, sortedData, addUnit, selectValue, maxValue }) => {
    return (
        <div className={classes.barChart}>
            <h1>{title}</h1>
            <p>{subtitle}</p>
            <div className={classes.bars}>
                {sortedData.map((model, index) => (
                    <BarLine
                        key={index}
                        color={getBarColor(index)}
                        value={selectValue(model)}
                        addUnit={addUnit}
                        maxValue={maxValue}
                    />
                ))}
            </div>
        </div>
    );
}

function compareOrder(a, b) {
    // Base Case always goes first then sort by order
    if ((a.id === "base") || (a.displayOrder < b.displayOrder)) {
        return -1; // a before b
    }
    if ((b.id === "base") || (a.displayOrder > b.displayOrder)) {
        return 1; // a after b
    }
    // if a and b have equal display orders, sort alphabetically by name
    if (a.name < b.name) {
        return -1;
    }
    if (a.name > b.name) {
        return 1;
    }
    return 0;
}

const BarGraph = ({ className, singleModelData = {}, allModeldata = [], metric = "energy", maxValues = {} }) => {
    const { name = "Selected Models", userName = "Multiple Users", provTerr = "CAN" } = singleModelData;

    var sortedData = (metric === "renoCost") 
        ? allModeldata.sort((a, b) => a.name.localeCompare(b.name)) // Graph using all models
        : singleModelData.data.sort(compareOrder);                  // Graph using one model and its packages

    return (
        <div className={classNames(classes.container, className)}>
            <BarLabels sortedData={sortedData} />
            <BarChart 
                title={name}
                subtitle={`${userName} - ${getProvCode(provTerr)}`}
                sortedData={sortedData}
                addUnit={getUnitFunc(metric)}
                selectValue={getValueFunc(metric)}
                maxValue={Math.ceil(maxValues[metric])}
            />
        </div>
    );
};

// TODO: Make component reusable rather than community specific or change the folder
export default BarGraph;
