import React, { useState } from "react";
import classes from "./style.module.scss";

const CostInput = ({ value, onChange }) => {
    const [displayValue, changeDisplayValue] = useState(value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","));

    const handleBlur = (e) => {
        const updatedValue = parseFloat(`${displayValue}`.replace(/[^0-9.]/g, "")).toFixed(2);
        const formattedValue = `${updatedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        changeDisplayValue(formattedValue);

        onChange(parseFloat(updatedValue));
    };

    const handleChange = (e) => {
        changeDisplayValue(e.target.value.replace(/[A-Za-z!@#$%^&*()]/g, ""));
    };

    return (
        <div>
            <input
                className={classes.costInput}
                value={displayValue}
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
            />

            {/* <textarea placeholder="Text..." value={value} onChange={onChange} className={classes.input} /> */}
        </div>
    );
};

export default CostInput;
