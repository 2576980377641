import React, { useState } from "react";
import classes from "./style.module.scss";
import { useLocation } from "react-router-dom";
import Navigation from "features/Navigation/container";
import TopBar from "features/TopBar/container";

export default ({ children, history, user }) => {
    const simplePaths = ["/login", "/register", "/create"];
    const barsActive =
        !!user &&
        !simplePaths.some((path) => useLocation().pathname.includes(path));
    const isDashboard =
        useLocation().pathname === "" || useLocation().pathname === "/";
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [isCollapsedDone, setIsCollapsedDone] = useState(false);

    return (
        <>
            {barsActive && (
                <>
                    <TopBar history={history} />
                    <Navigation
                        history={history}
                        isCollapsed={isCollapsed}
                        isCollapsedDone={isCollapsedDone}
                        setIsCollapsed={setIsCollapsed}
                        setIsCollapsedDone={setIsCollapsedDone}
                    />
                </>
            )}
            <div
                className={`${classes.main} ${
                    barsActive ? classes.barsActive : ""
                } ${isCollapsed && classes.barsActiveCollapsed} ${
                    isDashboard ? classes.isDashboard : ""
                }`}
            >
                <div className={classes.container}>{children}</div>
            </div>
        </>
    );
};
