import React, { useCallback, lazy, Suspense } from "react";
import { isEmpty } from "lodash";
import classes from "./style.module.scss";
import sharedClasses from "features/Model/sharedStyles.module.scss";
import Drawer from "components/Drawer";
import { ReactComponent as ChecklistIcon } from "assets/images/icons/Icon-Checklist.svg";
import Button from "components/Button";
import { Field } from "redux-form";
import Select from "components/Input/Select";
import InputRow from "components/Input/Row";
import moment from "moment";
import ComponentHeading from "../Enclosure/ComponentList/ComponentHeading";

import LoadingSection from "components/Loading/LoadingSection";
import FileSharing from "./FileSharing/container";
import ErrorFlagging from "./ErrorFlagging/container";
import Accordion from "components/Accordion";
import HousesSelect from "assets/images/icons/JSX/HousesSelect";

// import ModelDefaults from "./ModelDefaults/container";

const LazyLoadedModelDefaults = lazy(() => import("./ModelDefaults/container"));

const Settings = ({ open = false, toggleOpen, id, change, organization = {} }) => {
    const handleClose = useCallback(() => {
        toggleOpen();
    }, [toggleOpen]);

    return (
        <Drawer open={open} title="Settings" subtitle="Model Settings" close={handleClose}>
            {!isEmpty(organization) && <FileSharing />}
            <Accordion
                className={sharedClasses.accordion}
                heading={
                    <div className={sharedClasses.accordionIconHeading}>
                        Default Model Codes <HousesSelect />
                    </div>
                }
                large
            >
                <Suspense fallback={<LoadingSection sectionLoadingName={<h3>Loading Default Model Codes</h3>} />}>
                    <LazyLoadedModelDefaults change={change} />
                </Suspense>
            </Accordion>

            <ErrorFlagging />
            <div className={classes.buttonRow}>
                <Button onClick={handleClose} type="hollow">
                    Close
                </Button>
            </div>
        </Drawer>
    );
};

export default Settings;
