import React, { useEffect } from "react";
import classes from "./style.module.scss";
import sharedClasses from "features/Model/sharedStyles.module.scss";
import { Field } from "redux-form";
import { getOptions, getFirstOption, getBaseUnits, getUnitOptions } from "utils/fields";
import { maxLength, getValidation, getDecimalPlaces } from "utils/fieldValidation";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import InputWithUnits from "components/Input/InputWithUnits";
import Select from "components/Input/Select";
import Checkbox from "components/Input/Checkbox";
import Actions from "../Actions/container";
import Accordion from "components/Accordion";

const charLim100 = maxLength(100);
const enerSourceValidation = getValidation("supplHtgEnergySource");
const equipTypeValidation = getValidation("supplHtgEquipType");
const yearMadeValidation = getValidation("supplHtgYearMade");
const usageValidation = getValidation("supplHtgUsage");
const areaHeatedValidation = getValidation("supplHtgAreaHeated");

const capacityValidation = getValidation("supplHtgOutputCapacity");

const effValidation = getValidation("supplHtgEff");

const flueTypeValidation = getValidation("supplHtgFlueType");
const flueDiameterValidation = getValidation("supplHtgFlueDiameter");
const pilotValidation = getValidation("supplHtgPilotLight");
const monthlyPercentValidation = getValidation("supplHtgJanPercent");
const monthlyDailyHoursValidation = getValidation("supplHtgJanDailyHour");

export default ({
    accessor,
    parentAccessor,
    manufacturer,
    model,
    energySourceId,
    equipTypeId,
    effValue,
    capacityValue,
    areaHeatedId,
    areaHeatedValue,
    areaHeatedUnits,
    capacityUnits,
    pilotLightValue,
    flueDiameterValue,
    pilotUnits,
    flueUnits,
    modelUnits,
    change,
    isMonthlyUsage,
    monthlyUsageUnits,
    noBasementComponents,
    contentOpen,
}) => {
    const setPilotDefault = (id) => {
        if ([10, 11, 13, 19, 20, 22].includes(id)) {
            change(`${accessor}.specifications.pilotLight`, 17.7);
        } else {
            change(`${accessor}.specifications.pilotLight`, 0);
        }
    };

    const setFlueDiameterDefault = (id) => {
        if ([7, 8, 10, 13, 16, 17, 19, 22].includes(id)) {
            change(`${accessor}.specifications.flue.diameter`, 76.2);
        } else if ([14, 15].includes(id)) {
            change(`${accessor}.specifications.flue.diameter`, 101.6);
        } else if (id >= 23 && id <= 46) {
            change(`${accessor}.specifications.flue.diameter`, 127.0);
        } else {
            change(`${accessor}.specifications.flue.diameter`, 0);
        }
    };

    useEffect(() => {
        setPilotDefault(equipTypeId);
        setFlueDiameterDefault(equipTypeId);
    }, [equipTypeId]);

    const createLabel = () => {
        if (manufacturer && !model) {
            return `${manufacturer}`;
        } else if (manufacturer && model) {
            return `${manufacturer} | ${model}`;
        } else if (!manufacturer && model) {
            return `${model}`;
        } else {
            return "Supplementary Heating System";
        }
    };

    const isFlueDisabled = energySourceId === 0 || [9, 11, 12, 18, 20, 21].includes(equipTypeId);

    return (
        <>
            <Accordion
                className={sharedClasses.accordion}
                heading={<span className={sharedClasses.heading}>{createLabel()}</span>}
                large
                hoverActions={
                    <Actions
                        label="Supplementary System"
                        type="supplSystems"
                        accessor={accessor}
                        parentAccessor={parentAccessor}
                        change={change}
                    />
                }
                forceOpen={contentOpen}
            >
                <InputRow gridTemplate="23.4% 23.4% 23.4% 23.4%">
                    <Field
                        component={Input}
                        name={`${accessor}.manufacturer`}
                        type="text"
                        label="Manufacturer Name"
                        placeholder="Enter Manufacturer Name"
                        validate={charLim100}
                    />
                    <Field
                        component={Input}
                        name={`${accessor}.model`}
                        type="text"
                        label="Model Name"
                        placeholder="Enter Model Name"
                        validate={charLim100}
                    />
                    <Field
                        component={Input}
                        name={`${accessor}.description`}
                        type="text"
                        label="Description"
                        placeholder="Enter Description"
                        validate={charLim100}
                    />
                    <Field
                        component={Checkbox}
                        name={`${accessor}.epaCsa`}
                        label="EPA/CSA"
                        large
                        className={classes.checkbox}
                        type="checkbox"
                        disabled={energySourceId <= 3}
                        info={"For solid fuel-fired equipment"}
                    />
                </InputRow>
                <InputRow gridTemplate="23.4% 23.4% 10rem 23.4%">
                    <Field
                        component={Select}
                        name={`${accessor}.energySource`}
                        options={getOptions({ fieldKey: "supplHtgEnergySource" })}
                        label="Energy Source"
                        placeholder="Choose Energy Source"
                        validate={enerSourceValidation}
                        onChange={({ id }) => {
                            change(
                                `${accessor}.equipmentType`,
                                getFirstOption({ indKey: id, depKey: "supplHtgEquipType" })
                            );
                            if (id === 0) {
                                change(`${accessor}.specifications.efficiency`, 100);
                            }
                        }}
                    />
                    <Field
                        component={Select}
                        name={`${accessor}.equipmentType`}
                        options={getOptions({ fieldKey: "supplHtgEquipType", indKey: energySourceId })}
                        label="Equipment Type"
                        placeholder="Choose Equipment Type"
                        validate={equipTypeValidation}
                    />
                    <Field
                        component={Select}
                        name={`${accessor}.specifications.yearMade`}
                        options={getOptions({ fieldKey: "supplHtgYearMade" })}
                        label="Year Made"
                        placeholder="Choose Year Made"
                        validate={yearMadeValidation}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.specifications.outputCapacity.value`}
                        label="Rated Output Capacity"
                        placeholder="0.00"
                        validate={capacityValidation}
                        setValue={capacityValue}
                        decimals={getDecimalPlaces("supplHtgOutputCapacity")}
                        change={change}
                        units={{
                            base: getBaseUnits("supplHtgOutputCapacity", modelUnits),
                            options: getUnitOptions("power"),
                            selected: capacityUnits,
                            unitType: "power",
                            accessor: `${accessor}.specifications.outputCapacity_u`,
                        }}
                        info={
                            "If the capacity is unknown, use the default value (2 kW). This field is required if the Usage is set to Always."
                        }
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.specifications.efficiency`}
                        label="Steady State Efficiency"
                        placeholder="0.00"
                        validate={effValidation}
                        decimals={getDecimalPlaces("supplHtgEff")}
                        change={change}
                        setValue={effValue}
                        units={{
                            base: getBaseUnits("supplHtgEff", modelUnits),
                            options: ["%"],
                            selected: "%",
                            unitType: "fraction",
                            accessor: `${accessor}.specifications.efficiency_u`,
                        }}
                        info={
                            "If the steady state efficiency is unknown, the AFUE may be entered. This field is required if the Usage has been set to Always."
                        }
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.specifications.pilotLight`}
                        label="Pilot Light Energy Consumption"
                        placeholder="0.00"
                        validate={pilotValidation}
                        decimals={getDecimalPlaces("supplHtgPilotLight")}
                        change={change}
                        setValue={pilotLightValue}
                        units={{
                            base: getBaseUnits("supplHtgPilotLight", modelUnits),
                            options: getUnitOptions("dailyEnergy"),
                            selected: pilotUnits,
                            unitType: "dailyEnergy",
                            accessor: `${accessor}.specifications.pilotLight_u`,
                        }}
                        disabled={
                            [0, 2, 4, 5, 6, 7].includes(energySourceId) ||
                            [7, 8, 9, 12, 16, 17, 18, 21].includes(equipTypeId)
                        }
                        info={"Enter this value if known, otherwise use the default value."}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.specifications.flue.diameter`}
                        label="Flue Diameter"
                        placeholder="0.00"
                        validate={flueDiameterValidation}
                        decimals={getDecimalPlaces("supplHtgFlueDiameter")}
                        change={change}
                        setValue={flueDiameterValue}
                        units={{
                            base: getBaseUnits("supplHtgFlueDiameter", modelUnits),
                            options: getUnitOptions("length"),
                            selected: flueUnits,
                            unitType: "length",
                            accessor: `${accessor}.specifications.flue.diameter_u`,
                        }}
                        disabled={isFlueDisabled}
                    />
                    <Field
                        component={Select}
                        name={`${accessor}.specifications.flue.isInterior`}
                        options={[
                            {
                                label: "Interior",
                                value: true,
                            },
                            {
                                label: "Exterior",
                                value: false,
                            },
                        ]}
                        label="Flue Location"
                        placeholder="Choose Flue Location"
                        disabled={isFlueDisabled}
                    />
                    <Field
                        component={Select}
                        name={`${accessor}.specifications.flue.type`}
                        options={getOptions({ fieldKey: "supplHtgFlueType" })}
                        label="Flue Type"
                        placeholder="Choose Flue Type"
                        validate={flueTypeValidation}
                        disabled={isFlueDisabled}
                    />
                    <Field
                        component={Checkbox}
                        name={`${accessor}.damperClosed`}
                        label="Is Damper Closed?"
                        large
                        className={classes.checkbox}
                        type="checkbox"
                        disabled={isFlueDisabled}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                    <Field
                        component={Select}
                        name={`${accessor}.specifications.usage`}
                        options={getOptions({ fieldKey: "supplHtgUsage" })}
                        label="Usage"
                        placeholder="Choose Usage Frequency"
                        validate={usageValidation}
                        info={"In accordance with EnerGuide, the Usage must be set to either Never or Always"}
                        onChange={({ id }) => {
                            if (id === 6) {
                                change(`${accessor}.specifications.monthlyUsage`, {});
                                //monthly %
                                change(`${accessor}.specifications.monthlyUsage`, {
                                    uiUnits: "%",
                                    january: 0,
                                    february: 0,
                                    march: 0,
                                    april: 0,
                                    may: 0,
                                    june: 0,
                                    july: 0,
                                    august: 0,
                                    september: 0,
                                    october: 0,
                                    november: 0,
                                    december: 0,
                                });
                            } else if (id === 7) {
                                change(`${accessor}.specifications.monthlyUsage`, {});
                                //monthly hours/day
                                change(`${accessor}.specifications.monthlyUsage`, {
                                    uiUnits: "hr/day",
                                    january: 0,
                                    february: 0,
                                    march: 0,
                                    april: 0,
                                    may: 0,
                                    june: 0,
                                    july: 0,
                                    august: 0,
                                    september: 0,
                                    october: 0,
                                    november: 0,
                                    december: 0,
                                });
                            } else {
                                change(`${accessor}.specifications.monthlyUsage`, {});
                            }
                        }}
                    />
                    <Field
                        component={Select}
                        name={`${accessor}.specifications.areaHeated`}
                        options={[
                            {
                                label: "Main Floors",
                                value: {
                                    id: 0,
                                    value: areaHeatedValue,
                                },
                            },
                            {
                                label: "Basement",
                                value: {
                                    id: 1,
                                    value: areaHeatedValue,
                                },
                            },
                            {
                                label: "Exterior",
                                value: {
                                    id: 2,
                                    value: areaHeatedValue,
                                },
                            },
                        ]}
                        label="Location Heated"
                        placeholder="Choose Location Heated"
                        validate={areaHeatedValidation}
                        warning={noBasementComponents && areaHeatedId === 1 ? "supplHeatingNoBasement" : ""}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.specifications.areaHeated.value`}
                        label="Approximate Heated Floor Area"
                        placeholder="0.00"
                        validate={areaHeatedValidation}
                        decimals={getDecimalPlaces("supplHtgAreaHeated")}
                        change={change}
                        disabled={false}
                        setValue={areaHeatedValue}
                        units={{
                            base: getBaseUnits("supplHtgAreaHeated", modelUnits),
                            options: getUnitOptions("area"),
                            selected: areaHeatedUnits,
                            unitType: "area",
                            accessor: `${accessor}.specifications.areaHeated_u`,
                        }}
                        info={
                            "The area of the house heated by the supplementary heating system. This field is required when Usage is set to Always."
                        }
                    />
                </InputRow>
                {isMonthlyUsage && (
                    <>
                        <h5 style={{ marginBottom: "1.25rem", marginTop: "1.75rem" }}>{`Define monthly usage in ${
                            monthlyUsageUnits === "%" ? "% of time" : "hours per day"
                        }`}</h5>
                        <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                            <Field
                                component={Input}
                                type="number"
                                name={`${accessor}.specifications.monthlyUsage.january`}
                                label="January"
                                placeholder="0.00"
                                validate={
                                    monthlyUsageUnits === "%" ? monthlyPercentValidation : monthlyDailyHoursValidation
                                }
                                decimals={getDecimalPlaces("supplHtgJanPercent")}
                            />
                            <Field
                                component={Input}
                                type="number"
                                name={`${accessor}.specifications.monthlyUsage.february`}
                                label="February"
                                placeholder="0.00"
                                validate={
                                    monthlyUsageUnits === "%" ? monthlyPercentValidation : monthlyDailyHoursValidation
                                }
                                decimals={getDecimalPlaces("supplHtgJanPercent")}
                            />
                            <Field
                                component={Input}
                                type="number"
                                name={`${accessor}.specifications.monthlyUsage.march`}
                                label="March"
                                placeholder="0.00"
                                validate={
                                    monthlyUsageUnits === "%" ? monthlyPercentValidation : monthlyDailyHoursValidation
                                }
                                decimals={getDecimalPlaces("supplHtgJanPercent")}
                            />
                            <Field
                                component={Input}
                                type="number"
                                name={`${accessor}.specifications.monthlyUsage.april`}
                                label="April"
                                placeholder="0.00"
                                validate={
                                    monthlyUsageUnits === "%" ? monthlyPercentValidation : monthlyDailyHoursValidation
                                }
                                decimals={getDecimalPlaces("supplHtgJanPercent")}
                            />
                        </InputRow>
                        <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                            <Field
                                component={Input}
                                type="number"
                                name={`${accessor}.specifications.monthlyUsage.may`}
                                label="May"
                                placeholder="0.00"
                                validate={
                                    monthlyUsageUnits === "%" ? monthlyPercentValidation : monthlyDailyHoursValidation
                                }
                                decimals={getDecimalPlaces("supplHtgJanPercent")}
                            />
                            <Field
                                component={Input}
                                type="number"
                                name={`${accessor}.specifications.monthlyUsage.june`}
                                label="June"
                                placeholder="0.00"
                                validate={
                                    monthlyUsageUnits === "%" ? monthlyPercentValidation : monthlyDailyHoursValidation
                                }
                                decimals={getDecimalPlaces("supplHtgJanPercent")}
                            />
                            <Field
                                component={Input}
                                type="number"
                                name={`${accessor}.specifications.monthlyUsage.july`}
                                label="July"
                                placeholder="0.00"
                                validate={
                                    monthlyUsageUnits === "%" ? monthlyPercentValidation : monthlyDailyHoursValidation
                                }
                                decimals={getDecimalPlaces("supplHtgJanPercent")}
                            />
                            <Field
                                component={Input}
                                type="number"
                                name={`${accessor}.specifications.monthlyUsage.august`}
                                label="August"
                                placeholder="0.00"
                                validate={
                                    monthlyUsageUnits === "%" ? monthlyPercentValidation : monthlyDailyHoursValidation
                                }
                                decimals={getDecimalPlaces("supplHtgJanPercent")}
                            />
                        </InputRow>
                        <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                            <Field
                                component={Input}
                                type="number"
                                name={`${accessor}.specifications.monthlyUsage.september`}
                                label="September"
                                placeholder="0.00"
                                validate={
                                    monthlyUsageUnits === "%" ? monthlyPercentValidation : monthlyDailyHoursValidation
                                }
                                decimals={getDecimalPlaces("supplHtgJanPercent")}
                            />
                            <Field
                                component={Input}
                                type="number"
                                name={`${accessor}.specifications.monthlyUsage.october`}
                                label="October"
                                placeholder="0.00"
                                validate={
                                    monthlyUsageUnits === "%" ? monthlyPercentValidation : monthlyDailyHoursValidation
                                }
                                decimals={getDecimalPlaces("supplHtgJanPercent")}
                            />
                            <Field
                                component={Input}
                                type="number"
                                name={`${accessor}.specifications.monthlyUsage.november`}
                                label="November"
                                placeholder="0.00"
                                validate={
                                    monthlyUsageUnits === "%" ? monthlyPercentValidation : monthlyDailyHoursValidation
                                }
                                decimals={getDecimalPlaces("supplHtgJanPercent")}
                            />
                            <Field
                                component={Input}
                                type="number"
                                name={`${accessor}.specifications.monthlyUsage.december`}
                                label="December"
                                placeholder="0.00"
                                validate={
                                    monthlyUsageUnits === "%" ? monthlyPercentValidation : monthlyDailyHoursValidation
                                }
                                decimals={getDecimalPlaces("supplHtgJanPercent")}
                            />
                        </InputRow>
                    </>
                )}
            </Accordion>
        </>
    );
};
