import { connect } from 'react-redux';
import Components from './';
import { actions } from 'features/Model/Drawing/_ducks';

const {
    setActiveImage,
    setActiveComponent,
    setAction,
    setActiveTool,
    updateStage,
} = actions;

const mapStateToProps = ({
    drawing:{
        imageData={},
        activeImage,
        activeComponent,
        processingImages=false,
    }
}) => ({
    imageData,
    activeImage,
    activeComponent,
    processingImages
});

const mapDispatchToProps = dispatch => ({
    setActiveImage:(image) => dispatch(setActiveImage({activeImage:image})),
    setActiveComponent:(component) => dispatch(setActiveComponent({activeComponent:component})),
    setAction:({id, meta}) => dispatch(setAction({id, meta})),
    setActiveTool:(tool) => dispatch(setActiveTool({activeTool:tool})),
    updateStage: (updates) => dispatch(updateStage(updates))
});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Components);