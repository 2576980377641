import React from 'react';
import { Field } from 'redux-form';
import InputRow from 'components/Input/Row';
import Input from 'components/Input';
import { getValidation } from 'utils/fieldValidation';
import { getOptions } from 'utils/fields';
import Select from 'components/Input/Select';

const fileIdValidation = getValidation('fileID');
const prevFileIdValidation = getValidation('prevFileID');
const enrollmentValidation = getValidation('houseEnrollmentID');
const homeAuthIdValidation = getValidation('homeownerAuthID');
const ownershipValidation = getValidation('ownershipType');
const propertyTaxValidation = getValidation('propertyTaxNumber');
const builderNameValidation = getValidation('builderName');
const userNameValidation = getValidation('userNameEnteredBy');
const userPhoneValidation = getValidation('userTelephone');
const userPhoneExtValidation = getValidation('userExtension');
const userCompanyValidation = getValidation('userCompany');
const companyPhoneValidation = getValidation('companyTelephone');
const companyExtValidation = getValidation('companyExtension');

//TODO: Check %'s on third and fourth input row
export default React.memo(({
    accessor,
    modelUnits,
    username,
    change
}) => (
    <>
        <InputRow gridTemplate="1fr 1fr 1fr 1fr">
            <Field
                component={Input}
                type="text"
                name={`${accessor}.fileID`}
                label="File ID"
                placeholder=""
                validate={fileIdValidation}
                change={change}
            />
            <Field
                component={Input}
                type="text"
                name={`${accessor}.prevFileID`}
                label="Previous File ID"
                placeholder=""
                validate={prevFileIdValidation}
                change={change}
            />
            <Field
                component={Input}
                type="text"
                name={`${accessor}.houseEnrollmentID`}
                label="House Enrollment ID"
                placeholder=""
                validate={enrollmentValidation}
                change={change}
            />
            <Field
                component={Input}
                type="text"
                name={`${accessor}.homeownerAuthID`}
                label="Homeowner Authorization ID"
                placeholder=""
                validate={homeAuthIdValidation}
                change={change}
            />
        </InputRow>
        <InputRow gridTemplate="1fr 1fr 1fr">
            <Field
                component={Select}
                name={`${accessor}.ownershipType`}
                options={getOptions({fieldKey:'ownershipType'})}
                label="Ownership"
                placeholder="Choose Ownership"
                validate={ownershipValidation}
            />
            <Field
                component={Input}
                type="text"
                name={`${accessor}.propertyTaxNumber`}
                label="Property Tax Roll Number"
                placeholder=""
                validate={propertyTaxValidation}
                change={change}
            />
            <Field
                component={Input}
                type="text"
                name={`${accessor}.builderName`}
                label="Builder Name"
                placeholder=""
                validate={builderNameValidation}
                change={change}
                info={"Enter the builder number followed by the builder name separated by a space. For a homeowner builder, enter the builder number as H0000, followed by the homeowner's name."}
            />
        </InputRow>
        <InputRow gridTemplate="31.85% 40% 25%">
            <Field
                component={Input}
                type="text"
                name={`${accessor}.userNameEnteredBy`}
                label="User Name (Entered by)"
                placeholder=""
                validate={userNameValidation}
                change={change}
            />
            <Field
                component={Input}
                type="text"
                name={`${accessor}.userTelephone`}
                label="Telephone"
                placeholder=""
                validate={userPhoneValidation}
                change={change}
            />
            <Field
                component={Input}
                type="text"
                name={`${accessor}.userExtension`}
                label="Extension"
                placeholder=""
                validate={userPhoneExtValidation}
                change={change}
            />
        </InputRow>
        <InputRow gridTemplate="31.85% 40% 25%">
            <Field
                component={Input}
                type="text"
                name={`${accessor}.userCompany`}
                label="User Company"
                placeholder=""
                validate={userCompanyValidation}
                change={change}
            />
            <Field
                component={Input}
                type="text"
                name={`${accessor}.companyTelephone`}
                label="Company Telephone"
                placeholder=""
                validate={companyPhoneValidation}
                change={change}
            />
            <Field
                component={Input}
                type="text"
                name={`${accessor}.companyExtension`}
                label="Extension"
                placeholder=""
                validate={companyExtValidation}
                change={change}
            />
        </InputRow>
    </>
))