import { connect } from "react-redux";
import { isEmpty } from "lodash";

import {
    toggleImageProcessing,
    updateStage,
    setMessage,
    setTools,
    setAction,
    setActiveImageIndex,
    setScale,
    setActiveTool,
    setActiveComponent,
    setPolygon,
    setRectangle,
    setLine,
    setMultiPointLine,
    removePolygon,
    removeRectangle,
    removeMultiPointLine,
    removeLine,
} from "../_ducks/actions";

import Canvas from "./";

const mapStateToProps = ({
    model: {
        modelFiles = [],
        modelData: { uiSettings: { primaryUnits } = {} } = {},
    },
    drawing: {
        imageData = {},
        stage,
        message = {},
        tools: { disabled: disabledTools = [] } = {},
        activeTool,
        activeImageIndex,
        processingImages,
        activeComponent,
    },
}) => ({
    images: modelFiles
        .filter(({ contentType = "" }) => contentType === "image/jpeg")
        .map(({ fileName, ...rest }) => ({
            fileName: fileName.split(".jpg")[0],
            ...rest,
        }))
        .sort(({ fileName: fileA = "" }, { fileName: fileB = "" }) =>
            fileA.toLowerCase().localeCompare(fileB.toLowerCase())
        ),
    imageData,
    stage,
    stageScale: stage.scale,
    message,
    disabledTools,
    activeTool,
    activeImageIndex,
    processingImages,
    primaryUnits: primaryUnits === "Metric" ? "m" : "ft",
    activeComponent,
});

const mapDispatchToProps = (dispatch) => ({
    toggleImageProcessing: () => dispatch(toggleImageProcessing()),
    updateStage: (stage) => dispatch(updateStage(stage)),
    setMessage: ({
        message,
        type,
        anchorOrigin,
        autoHideDuration,
        isOpen,
        direction,
    }) =>
        dispatch(
            setMessage({
                message,
                type,
                anchorOrigin,
                autoHideDuration,
                isOpen,
                direction,
            })
        ),
    setTools: (activeTool, disabledTools) =>
        dispatch(setTools({ active: activeTool, disabled: disabledTools })),
    setAction: ({ id, meta = {} }) => dispatch(setAction({ id, meta })),
    setActiveImageIndex: (index) => dispatch(setActiveImageIndex(index)),
    setScale: ({ image, scale }) => dispatch(setScale({ image, scale })),
    setActiveTool: (tool) => dispatch(setActiveTool(tool)),
    setActiveComponent: (component) => dispatch(setActiveComponent(component)),
    setPolygon: (image, polygon) => dispatch(setPolygon(image, polygon)),
    setRectangle: (image, rectangle) =>
        dispatch(setRectangle(image, rectangle)),
    setLine: (image, line) => dispatch(setLine(image, line)),
    setMultiPointLine: (image, multiPointLine) =>
        dispatch(setMultiPointLine(image, multiPointLine)),
    removePolygon: (image, polygonId) =>
        dispatch(removePolygon(image, polygonId)),
    removeRectangle: (image, rectangleId) =>
        dispatch(removeRectangle(image, rectangleId)),
    removeMultiPoint: (image, multiPointId) =>
        dispatch(removeMultiPointLine(image, multiPointId)),
    removeLine: (image, lineId) => dispatch(removeLine(image, lineId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Canvas);
