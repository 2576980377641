import { connect } from 'react-redux';
import { reduxForm, formValueSelector, reset } from 'redux-form';
import StandardCode from 'features/Model/Enclosure/ComponentList/ExpFloor/Construction/StandardCode';
import expFloorCodeTemplate from 'features/Model/Enclosure/Templates/expFloorCode.json';
import { actions as userActions } from 'store/users';
import { actions as enclosureActions } from 'features/Model/Enclosure/_ducks';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';

const { addToCodeLib } = userActions;
const { setInitCode, getRValue } = enclosureActions;

const mapStateToProps = (
    {
        form,
        model:{
            modelId,
        },
        user:{
            uid,
            codeLib:{
                Floor:{
                    codes:expFloorCodeLib={}
                }={},
            }={}
        },
        enclosure:{
            initCodeValues:{
                expFloorInsType={}
            }={}
        }={}
    },
    {
        drawingFormChange,
        componentId,
        toggleOpen,
        modelFormChange
    }
) => {
    const selector = formValueSelector('drawingExpFloorCode');
    const modelSelector = formValueSelector('model');
    const { id:expFloorStructureId, value:expFloorStructureValue=0 } = selector({form}, 'expFloorCode.layers.structureType') || {};
    const { value:expFloorTypeSizeValue } = selector({form}, 'expFloorCode.layers.componentTypeSize') || {};
    const { id:expFloorSpacingId, value:expFloorSpacingValue } = selector({form}, 'expFloorCode.layers.spacing') || {};
    const { value:expFloorFramingValue } = selector({form}, 'expFloorCode.layers.framing') || {};
    const { value:expFloorIns1Value } = selector({form}, 'expFloorCode.layers.insulationLayer1') || {};
    const { value:expFloorIns2Value } = selector({form}, 'expFloorCode.layers.insulationLayer2') || {};
    const { value:expFloorInteriorValue } = selector({form}, 'expFloorCode.layers.interior') || {};
    const { value:expFloorSheathingValue } = selector({form}, 'expFloorCode.layers.sheathing') || {};
    const { value:expFloorExteriorValue } = selector({form}, 'expFloorCode.layers.exterior') || {};
    const { id:expFloorDropFramingId, value:expFloorDropFramingValue } = selector({form}, 'expFloorCode.layers.dropFraming') || {};
    const expFloorLabel = selector({form}, 'expFloorCode.label') || '';
    const modelCodes = modelSelector({form}, 'modelData.codes') || {};

    const frameSpacingValue = expFloorSpacingId === null ? expFloorFramingValue : expFloorSpacingValue;

    const expFloorValue = `3${expFloorStructureValue.toString().substr(-1)}${expFloorTypeSizeValue}${frameSpacingValue}${expFloorIns1Value}${expFloorIns2Value}${expFloorInteriorValue}${expFloorSheathingValue}${expFloorExteriorValue}${expFloorDropFramingValue}`;

    const { codeRef:editingCodeRef=null, isLibCode=false } = expFloorInsType;

    return {
        expFloorLabel,
        drawingFormChange,
        initialValues:{
            expFloorCode:isEmpty(expFloorInsType) ? expFloorCodeTemplate : expFloorInsType,
            addToLibrary:isLibCode,
        },
        expFloorStructureId,
        expFloorTypeSizeValue,
        isSolid:parseFloat(expFloorStructureId) === 4,
        isPanel:parseFloat(expFloorStructureId) === 5,
        isDropFraming:parseFloat(expFloorDropFramingId) === 1,
        makeSpacingDefault:['5','6','7','8','9','A','B','C'].includes(expFloorTypeSizeValue),
        makeInsulationDefault:['6','7','8','9','A','B','C'].includes(expFloorTypeSizeValue),
        makeDropFramingInsDefault:['9','A','E','F','U','M','N','O','P','Q','R','S','T'].includes(expFloorIns1Value) & parseFloat(expFloorDropFramingId) === 1,
        expFloorValue,
        modelId,
        uid,
        modelCodes,
        expFloorCodeLib,
        modelFormChange,
        editingCodeRef,
        isEditing:!!editingCodeRef,
        isLibCode,
        componentId,
        toggleOpen,
        expFloorSpacingId,
        expFloorIns1Value,
    }
};

const mapDispatchToProps = ( dispatch ) => ({
    clearForm: () => dispatch(reset('drawingExpFloorCode')),
    clearInitCode: () => dispatch(setInitCode({}, 'expFloorInsType'))
});

const mergeProps = (state, dispatch, own) => ({
    ...state,
    ...dispatch,
    ...own,
});

const onSubmit = async(
    form,
    dispatch,
    {
        toggleOpen,
        uid,
        drawingFormChange,
        editingCodeRef,
        componentId,
        modelFormChange
    }
) => {
    const { expFloorCode={}, addToLibrary=false } = form;
    const { codeRef, value, nominalRValue=null, label, isLibCode, ...rest } = expFloorCode;

    toggleOpen(false);

    // 1. Create and change code ref
    const newCodeRef = `Floor-S-${moment().format('YYYYMMDDHHmmssSS')}`;
    const setCodeRef = editingCodeRef || newCodeRef;

    let rVal = 0;
    let nomRVal = 0;
    let warningType = '';

    await dispatch(getRValue({
        codeString: value,
        codeType: 'Floor',
        componentId:componentId,
        fieldId:'expFloorInsType'
    }))
        .then(({
            rVal:newRVal,
            warningType:warning
        }) => {
            rVal = parseFloat(newRVal.toFixed(2));
            nomRVal = parseFloat(newRVal.toFixed(2));
            warningType = warning;
        });

   const code = { ...rest, nominalRValue:nomRVal, label, value, codeRef:setCodeRef, warningType};

    // 3.1. If code value is already in model, update using existing ref
    modelFormChange(`modelData.codes.${setCodeRef}`, code);

    if (addToLibrary) {
        await dispatch(addToCodeLib(
            uid,
            {
                ...rest,
                nominalRValue:nomRVal,
                label,
                codeType:"Standard",
                componentType:'Floor',
                value,
                warningType
            },
            'Floor',
            setCodeRef
        ));
    }

    const fieldValue = {
        codeLabel:label,
        codeRef:setCodeRef,
    };

    //Add warning tag to field
    if (warningType === 'compression') {
        drawingFormChange(`components.${componentId}.expFloorInsType_warning`, 'compression');
    } else {
        drawingFormChange(`components.${componentId}.expFloorInsType_warning`, '');
    }
    
    // Update model "exposed floor type" field in expFloor
    drawingFormChange(`components.${componentId}.expFloorInsType`, fieldValue);
    drawingFormChange(`components.${componentId}.expFloorInsType_nomRVal`, nomRVal);
    drawingFormChange(`components.${componentId}.expFloorInsType_effRVal`, nomRVal);

    // Clear exposed floor code defaults for next time
    dispatch(setInitCode({}, 'expFloorInsType'));
    // Reset form
    dispatch(reset('drawingExpFloorCode'));

};

const form = reduxForm({
    form:"drawingExpFloorCode",
    enableReinitialize: true,
    onSubmit:onSubmit
})(StandardCode);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(form);