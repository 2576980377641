import folderTypes from "./types";

export const createFolderStart = () => ({
    type: folderTypes.CREATE_FOLDER_START,
});

export const createFolderSuccess = () => ({
    type: folderTypes.CREATE_FOLDER_SUCCESS,
});

export const createFolderError = (err) => ({
    type: folderTypes.CREATE_FOLDER_ERROR,
    payload: err,
});

export const deleteFolderStart = () => ({
    type: folderTypes.DELETE_FOLDER_START,
});

export const deleteFolderSuccess = () => ({
    type: folderTypes.DELETE_FOLDER_SUCCESS,
});

export const deleteFolderError = (err) => ({
    type: folderTypes.DELETE_FOLDER_ERROR,
    payload: err,
});

export const renameFolderStart = () => ({
    type: folderTypes.RENAME_FOLDER_START,
});

export const renameFolderSuccess = () => ({
    type: folderTypes.RENAME_FOLDER_SUCCESS,
});

export const renameFolderError = (err) => ({
    type: folderTypes.RENAME_FOLDER_ERROR,
    payload: err,
});

export const moveFolderStart = () => ({
    type: folderTypes.MOVE_FOLDER_START,
});

export const moveFolderSuccess = () => ({
    type: folderTypes.MOVE_FOLDER_SUCCESS,
});

export const moveFolderError = (err) => ({
    type: folderTypes.MOVE_FOLDER_ERROR,
    payload: err,
});

export const batchDeleteFolderStart = () => ({
    type: folderTypes.DELETE_FOLDER_START,
});

export const batchDeleteFolderSuccess = () => ({
    type: folderTypes.DELETE_FOLDER_SUCCESS,
});

export const batchDeleteFolderError = (err) => ({
    type: folderTypes.DELETE_FOLDER_ERROR,
    payload: err,
});
