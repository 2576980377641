import { getOptions } from "utils/fields";

export const getLabel = (keyName, myID, indKey = "default", h2kVersion) =>
    getOptions({ fieldKey: keyName, indKey, version: h2kVersion }).reduce((acc, type) => {
        return myID === type?.value?.id ? type.label : acc;
    }, "");

export const getLocation = (array, subcomponent) =>
    array.reduce((acc, pairing) => {
        return pairing.componentId === subcomponent.componentId ? pairing.location : acc;
    }, "");

export const getCodeLayerLabel = (keyName, myID, indKey = "default") => {
    return getOptions({ fieldKey: keyName, indKey }).reduce((acc, type) => {
        return myID === type?.value?.id ? type.label : acc;
    }, "");
};

export const getEffRValLabel = (effRVal, modelUnits) =>
    modelUnits === "Metric"
        ? `User Specified ${effRVal.toFixed(2)} RSI`
        : `User Specified R${(effRVal * 5.678).toFixed(1)}`;
