import React, { useEffect, useRef, useCallback } from "react";
import globalStyles from "components/globalStyles.module.scss";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import Select from "components/Input/Select";
import MultiSelect from "components/Input/MultiSelect";
import Checkbox from "components/Input/Checkbox";
import { Field } from "redux-form";
import Button from "components/Button";
import { getOptions, getFirstOption, getDefaultOption } from "utils/fields";
import { getAllSubcomponents } from "utils/enclosure/components";
import { required, maxLength, getValidation } from "utils/fieldValidation";

const charLim32 = maxLength(32);

const labelValidation = [required, charLim32];
const glazingValidation = getValidation("csWindowGlazingType");
const coatingTintValidation = getValidation("csWindowCoatingTint");
const fillValidation = getValidation("csWindowFillType");
const spacerValidation = getValidation("csWindowSpacerType");
const typeValidation = getValidation("csWindowType");
const frameMatValidation = getValidation("csWindowFrameMaterial");

const codeSort = (a, b) => {
    if (a.label < b.label) {
        return -1;
    }
    if (a.label > b.label) {
        return 1;
    }
    return 0;
};

export default React.memo(
    ({
        open = false,
        toggleOpen,
        windowLabel,
        windowGlazingTypeId,
        windowGlazingTypeValue,
        windowFillTypeValue,
        windowSpacerTypeValue,
        windowTypeValue,
        windowTypeId,
        windowFrameMatId,
        isSingleGlazing,
        makeSpacerFillDefault,
        windowValue,
        submitting,
        handleSubmit,
        change,
        invalid,
        isEditing,
        isLibCode,
        clearInitCode,
        hideUpdateAll = false,
        isCodeLibrary,
        isUpgradeComponent,
        components,
        fieldAccessor = "",
        windowAccessor,
    }) => {
        const valueRef = useRef(null);

        const handleGlazingChange = useCallback(
            ({ id, value }) => {
                change(
                    "windowCode.layers.coatingsTints",
                    getFirstOption({ indKey: id, depKey: "csWindowCoatingTint" })
                );

                //Reset Fill type and spacer type whenever the glazing type is changed. Matching the values is currently
                change("windowCode.layers.fillType", getFirstOption({ indKey: id, depKey: "csWindowFillType" }));
                change("windowCode.layers.spacerType", getFirstOption({ indKey: id, depKey: "csWindowSpacerType" }));
            },
            [windowGlazingTypeValue, change]
        );

        useEffect(() => {
            valueRef.current = windowValue;
        }, [windowValue, windowLabel]);

        //Window Type Options -> Dependent on Spacer Type
        let windowTypeOpts = getOptions({ fieldKey: "csWindowType" });
        if (windowSpacerTypeValue !== "0") {
            windowTypeOpts = windowTypeOpts.filter((opt) => opt.value.value !== "3");
        }

        //Frame Material Options -> Dependent on Window Type
        let frameOpts = getOptions({ fieldKey: "csWindowFrameMaterial", indKey: windowGlazingTypeId });
        if (windowTypeValue === "3") {
            frameOpts = frameOpts.filter((opt) => ["0", "1", "2", "3"].includes(opt.value.value));
        } else if (windowTypeValue === "4") {
            if (["3", "4", "6", "7", "8"].includes(windowGlazingTypeValue)) {
                //Can't have frame 1
                frameOpts = frameOpts.filter((opt) => opt.value.value !== "1");
            }
            frameOpts = frameOpts.filter((opt) => opt.value.value !== "4");
        } else if (windowTypeValue === "5") {
            frameOpts = frameOpts.filter((opt) => opt.value.value !== "6");
        }

        const frameSelectionInvalid = !frameOpts.map(({ value }) => value.id).includes(windowFrameMatId);
        const windowTypeSelectionInvalid = !windowTypeOpts.map(({ value }) => value.id).includes(windowTypeId);

        let availableComponents = getAllSubcomponents("window", components, windowAccessor).sort(codeSort);

        return (
            <div className={globalStyles.standardCodeWrapper}>
                <h3>Standard Code</h3>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Input}
                        type="text"
                        name={`windowCode.label`}
                        label="Code Label"
                        placeholder="Code Label"
                        validate={labelValidation}
                        setValue={windowLabel.toString() === valueRef.current ? windowValue : null}
                    />
                    <Field
                        component={Input}
                        type="text"
                        name={`windowCode.value`}
                        label="Internal Code"
                        placeholder="###########"
                        disabled={true}
                        setValue={windowValue}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Select}
                        name={`windowCode.layers.glazingType`}
                        label="Glazing Type"
                        placeholder="Choose Glazing Type"
                        options={getOptions({ fieldKey: "csWindowGlazingType" })}
                        validate={glazingValidation}
                        onChange={handleGlazingChange}
                    />
                    <Field
                        component={Select}
                        name={`windowCode.layers.frameMaterial`}
                        label="Frame Material"
                        placeholder="Choose Frame Material"
                        options={frameOpts}
                        validate={frameMatValidation}
                        warning={frameSelectionInvalid && "invalidWindowFrame"}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Select}
                        name={`windowCode.layers.coatingsTints`}
                        label="Coatings / Tints"
                        placeholder="Choose Coatings / Tints"
                        options={getOptions({ fieldKey: "csWindowCoatingTint", indKey: windowGlazingTypeId })}
                        validate={coatingTintValidation}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Select}
                        name={`windowCode.layers.fillType`}
                        label="Fill Type"
                        placeholder="Choose Fill Type"
                        options={getOptions({ fieldKey: "csWindowFillType", indKey: windowGlazingTypeId })}
                        validate={fillValidation}
                        disabled={isSingleGlazing || makeSpacerFillDefault}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Select}
                        name={`windowCode.layers.spacerType`}
                        label="Spacer Type"
                        placeholder="Choose Spacer Type"
                        options={getOptions({ fieldKey: "csWindowSpacerType", indKey: windowGlazingTypeId })}
                        validate={spacerValidation}
                        disabled={isSingleGlazing || makeSpacerFillDefault}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Select}
                        name={`windowCode.layers.windowType`}
                        label="Window Type"
                        placeholder="Choose Window Type"
                        options={windowTypeOpts}
                        validate={typeValidation}
                        warning={windowTypeSelectionInvalid && "invalidWindowType"}
                    />
                </InputRow>
                <div style={{ borderBottom: "1px solid #e1eaf0", marginTop: "1.5rem", marginBottom: "1.5rem" }}></div>
                {!isCodeLibrary && !isUpgradeComponent && (
                    <InputRow gridTemplate="1fr">
                        <Field
                            component={MultiSelect}
                            name={`selectedComponents`}
                            label={`Apply to multiple window components`}
                            options={availableComponents.map(({ disabled = false, label = "", ...rest }) => ({
                                ...rest,
                                disabled,
                                label: disabled ? `${label} (current component)` : label,
                            }))}
                            hasSelectAll
                        />
                    </InputRow>
                )}

                {!isCodeLibrary && (
                    <InputRow gridTemplate="1fr 1fr">
                        <div data-tip="Code will automatically be updated in library" data-for="codeLibTip">
                            <Field
                                component={Checkbox}
                                name={"addToLibrary"}
                                label={isLibCode ? "Update code in library" : "Add to code library"}
                                large
                                disabled={isLibCode}
                                type="checkbox"
                            />
                        </div>
                        <Field
                            component={Checkbox}
                            name={"setAsModelDefault"}
                            label={"Set as model default"}
                            large
                            type="checkbox"
                        />
                        {/* <Tooltip id="codeLibTip"/> */}
                    </InputRow>
                )}

                <div className={globalStyles.buttons}>
                    <Button
                        large
                        type="hollow"
                        onClick={() => {
                            clearInitCode();
                            toggleOpen(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button large onClick={handleSubmit} disabled={submitting || invalid}>
                        {submitting ? "Saving Code..." : "Save Code"}
                    </Button>
                </div>
                <div style={{ paddingTop: "7.5rem" }}></div>
            </div>
        );
    }
);
