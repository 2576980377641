import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import InsulationConfiguration from './';
import { actions } from "features/Model/Enclosure/_ducks";

const { updateFoundationConfig } = actions;

const mapStateToProps = ({
    form,
},{
    accessor,
    modelFormChange
}) => {
    const selector = formValueSelector('model');
    const { type:insConfig = '', overlap: overlapValue } = selector({form}, `${accessor}.configuration`) || {};

    const configUnits = selector({form}, `${accessor}.basementInsConfig_u`);

    const modelUnits = selector({form}, `modelData.uiSettings.primaryUnits`) || {};

    return {
        accessor,
        insConfig,
        overlapValue,
        modelUnits,
        configUnits,
        modelFormChange
    };
};

const mapDispatchToProps = (dispatch) => ({
    updateFoundationConfig: (updates) => dispatch(updateFoundationConfig(updates))
});

export default connect(mapStateToProps, mapDispatchToProps)(InsulationConfiguration);
