import React, { useEffect, useRef, useCallback } from "react";
import globalStyles from "components/globalStyles.module.scss";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import Select from "components/Input/Select";
import MultiSelect from "components/Input/MultiSelect";
import Checkbox from "components/Input/Checkbox";
import { Field } from "redux-form";
import Button from "components/Button";
import { getOptions, getFirstOption, getDefaultOption } from "utils/fields";
import { required, maxLength, getValidation } from "utils/fieldValidation";
import Tooltip from "components/Tooltip";
import { getAllComponents } from "utils/enclosure/components";

const charLim32 = maxLength(32);

const labelValidation = [required, charLim32];
const interiorValidation = getValidation("csExpFloorInterior");
const structureValidation = getValidation("csExpFloorStructureType");
const typeSizeValidation = getValidation("csExpFloorComponentTypeSize");
const sheathingValidation = getValidation("csExpFloorSheathing");
const framingSpacingValidation = getValidation("csExpFloorFramingSpacing");
const exteriorValidation = getValidation("csExpFloorExterior");
const ins1Validation = getValidation("csExpFloorInsLayer1");
const ins2Validation = getValidation("csExpFloorInsLayer2");
const dropFramingValidation = getValidation("csExpFloorDropFraming");

const codeSort = (a, b) => {
    if (a.label < b.label) {
        return -1;
    }
    if (a.label > b.label) {
        return 1;
    }
    return 0;
};

export default React.memo(
    ({
        toggleOpen,
        expFloorLabel,
        expFloorStructureId,
        isSolid,
        isPanel,
        isDropFraming,
        makeSpacingDefault,
        makeInsulationDefault,
        expFloorValue,
        submitting,
        handleSubmit,
        change,
        invalid,
        isEditing,
        isLibCode,
        clearInitCode,
        expFloorSpacingId,
        expFloorIns1Value,
        isCodeLibrary = false,
        isUpgradeComponent = false,
        fieldAccessor,
        components,
    }) => {
        const valueRef = useRef(null);

        const handleStuctureChange = useCallback(
            ({ id, value }) => {
                change(
                    "expFloorCode.layers.insulationLayer1",
                    getFirstOption({ indKey: id, depKey: "csExpFloorInsLayer1" })
                );
                change(
                    "expFloorCode.layers.componentTypeSize",
                    getFirstOption({ indKey: id, depKey: "csExpFloorComponentTypeSize" })
                );

                // Use "Framing" when set to Solid, use Spacing otherwise
                if (parseFloat(id) === 4) {
                    //set spacing to null and grab first option for framing
                    change("expFloorCode.layers.spacing", { id: null });
                    change(
                        "expFloorCode.layers.framing",
                        getFirstOption({
                            indKey: id,
                            depKey: "csExpFloorFramingSpacing",
                        })
                    );
                } else {
                    //set framing to null and grab first option for spacing
                    change("expFloorCode.layers.framing", { id: null });

                    // Only grab default spacing id if not set
                    if (expFloorSpacingId === null) {
                        change("expFloorCode.layers.spacing", getDefaultOption("csExpFloorFramingSpacing"));
                    }
                }

                // Panel
                if (parseFloat(id) === 5) {
                    change("expFloorCode.layers.insulationLayer1", getDefaultOption("csExpFloorInsLayer1"));
                    change("expFloorCode.layers.insulationLayer2", getDefaultOption("csExpFloorInsLayer2"));
                    change("expFloorCode.layers.spacing", getDefaultOption("csExpFloorFramingSpacing"));
                    change("expFloorCode.layers.dropFraming", getDefaultOption("csExpFloorDropFraming"));
                }
            },
            [expFloorStructureId, change, expFloorSpacingId]
        );

        useEffect(() => {
            valueRef.current = expFloorValue;
        }, [expFloorValue, expFloorLabel]);

        //Filter Insulation Layer 1 for drop framing
        const baseInsLayer1Opts = [
            "0",
            "K",
            "1",
            "2",
            "X",
            "Y",
            "3",
            "4",
            "Z",
            "a",
            "5",
            "L",
            "c",
            "J",
            "b",
            "d",
            "V",
            "W",
            "I",
            "e",
        ];
        let insLayer1Opts = getOptions({ fieldKey: "csExpFloorInsLayer1", indKey: expFloorStructureId });
        if (isDropFraming) {
            insLayer1Opts = insLayer1Opts.filter((opt) => baseInsLayer1Opts.includes(opt.value.value));
        }

        let availableComponents = getAllComponents("expFloor", components, fieldAccessor).sort(codeSort);

        return (
            <div className={globalStyles.standardCodeWrapper}>
                <h3>Standard Code</h3>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Input}
                        type="text"
                        name={`expFloorCode.label`}
                        label="Code Label"
                        placeholder="Code Label"
                        validate={labelValidation}
                        setValue={expFloorLabel.toString() === valueRef.current ? expFloorValue : null}
                    />
                    <Field
                        component={Input}
                        type="text"
                        name={`expFloorCode.value`}
                        label="Internal Code"
                        placeholder="###########"
                        disabled={true}
                        setValue={expFloorValue}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Select}
                        name={`expFloorCode.layers.structureType`}
                        label="Structure Type"
                        placeholder="Choose Structure Type"
                        options={getOptions({ fieldKey: "csExpFloorStructureType" })}
                        validate={structureValidation}
                        onChange={handleStuctureChange}
                    />
                    <Field
                        component={Select}
                        name={`expFloorCode.layers.interior`}
                        label="Interior"
                        placeholder="Choose Interior"
                        options={getOptions({ fieldKey: "csExpFloorInterior" })}
                        validate={interiorValidation}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Select}
                        name={`expFloorCode.layers.componentTypeSize`}
                        label="Component Type / Size"
                        placeholder="Choose Type / Size"
                        options={getOptions({ fieldKey: "csExpFloorComponentTypeSize", indKey: expFloorStructureId })}
                        validate={typeSizeValidation}
                        onChange={({ value = "" }) => {
                            if (["6", "7", "8", "9", "A", "B", "C"].includes(value)) {
                                change("expFloorCode.layers.insulationLayer1", getDefaultOption("csExpFloorInsLayer1"));
                                change("expFloorCode.layers.insulationLayer2", getDefaultOption("csExpFloorInsLayer2"));
                            }
                            if (["5", "6", "7", "8", "9", "A", "B", "C"].includes(value)) {
                                change("expFloorCode.layers.framing", { id: 0, value: "0" });
                            }
                        }}
                    />
                    <Field
                        component={Select}
                        name={`expFloorCode.layers.sheathing`}
                        label="Sheathing"
                        placeholder="Choose Sheathing"
                        options={getOptions({ fieldKey: "csExpFloorSheathing" })}
                        validate={sheathingValidation}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    {isSolid ? (
                        <Field
                            component={Select}
                            name={`expFloorCode.layers.framing`}
                            label="Framing"
                            placeholder="Choose Framing"
                            options={getOptions({ fieldKey: "csExpFloorFramingSpacing", indKey: expFloorStructureId })}
                            validate={framingSpacingValidation}
                            disabled={makeSpacingDefault}
                        />
                    ) : (
                        <Field
                            component={Select}
                            name={`expFloorCode.layers.spacing`}
                            label="Spacing"
                            placeholder="Choose Spacing"
                            options={getOptions({ fieldKey: "csExpFloorFramingSpacing", indKey: expFloorStructureId })}
                            validate={framingSpacingValidation}
                            disabled={isPanel}
                        />
                    )}
                    <Field
                        component={Select}
                        name={`expFloorCode.layers.exterior`}
                        label="Exterior"
                        placeholder="Choose Exterior"
                        options={getOptions({ fieldKey: "csExpFloorExterior" })}
                        validate={exteriorValidation}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Select}
                        name={`expFloorCode.layers.insulationLayer1`}
                        label="Framing Cavity Insulation (Layer 1)"
                        placeholder="Choose Insulation"
                        options={insLayer1Opts}
                        validate={ins1Validation}
                        disabled={isPanel || makeInsulationDefault}
                    />
                    <Field
                        component={Select}
                        name={`expFloorCode.layers.dropFraming`}
                        label="Drop Framing"
                        placeholder="Choose"
                        options={getOptions({ fieldKey: "csExpFloorDropFraming" })}
                        validate={dropFramingValidation}
                        disabled={isPanel}
                        onChange={({ value = "" }) => {
                            if (
                                ["9", "A", "E", "F", "U", "M", "N", "O", "P", "Q", "R", "S", "T"].includes(
                                    expFloorIns1Value
                                ) &&
                                value == 1
                            ) {
                                change("expFloorCode.layers.insulationLayer1", getDefaultOption("csExpFloorInsLayer1"));
                            }
                        }}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Select}
                        name={`expFloorCode.layers.insulationLayer2`}
                        label="Continuous Layer of Insulation (Layer 2)"
                        placeholder="Choose Insulation"
                        options={getOptions({ fieldKey: "csExpFloorInsLayer2" })}
                        validate={ins2Validation}
                        disabled={isPanel || makeInsulationDefault}
                    />
                </InputRow>
                <div style={{ borderBottom: "1px solid #e1eaf0", marginTop: "1.5rem", marginBottom: "1.5rem" }}></div>
                {!isCodeLibrary && !isUpgradeComponent && (
                    <InputRow gridTemplate="1fr">
                        <Field
                            component={MultiSelect}
                            name={`selectedComponents`}
                            label={`Apply to multiple exposed floor components`}
                            options={availableComponents.map(({ disabled = false, label = "", ...rest }) => ({
                                ...rest,
                                disabled,
                                label: disabled ? `${label} (current component)` : label,
                            }))}
                            hasSelectAll
                        />
                    </InputRow>
                )}
                {!isCodeLibrary && (
                    <InputRow gridTemplate="1fr 1fr">
                        <div data-tip="Code will automatically be updated in library" data-for="codeLibTip">
                            <Field
                                component={Checkbox}
                                name={"addToLibrary"}
                                label={isLibCode ? "Update code in library" : "Add to code library"}
                                large
                                disabled={isLibCode}
                                type="checkbox"
                            />
                        </div>
                        <Field
                            component={Checkbox}
                            name={"setAsModelDefault"}
                            label={"Set as model default"}
                            large
                            type="checkbox"
                        />
                    </InputRow>
                )}
                <div className={globalStyles.buttons}>
                    <Button
                        large
                        type="hollow"
                        onClick={() => {
                            clearInitCode();
                            toggleOpen(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button large onClick={handleSubmit} disabled={submitting || invalid}>
                        {submitting ? "Saving Code..." : "Save Code"}
                    </Button>
                </div>
                <div style={{ paddingTop: "7.5rem" }}></div>
                <Tooltip id="codeLibTip" hide={!isLibCode} />
            </div>
        );
    }
);
