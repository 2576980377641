import React from "react";
import classes from "./style.module.scss";
import sharedClasses from "../style.module.scss";

export default ({ isSlab }) => {
    return (
        <>
            <p>
                In HOT2000, a walkout must be modelled using separate basement and slab-on-grade components. Below are
                the instructions for this workaround.
            </p>
            <ol className={classes.listItem}>
                <h4>Modelling in the slab-on-grade component:</h4>
                <li>
                    Model a slab-on-grade foundation with the same total perimeter and area as the slab-on-grade portion
                    of the walkout with slab-on-grade foundation.
                </li>
                <li>
                    Set the slab-on-grade foundation's <strong>Exposed Surfaces Perimeter</strong> to the exposed
                    perimeter of the slab-on-grade foundation, i.e. the perimeter not attached to the rest of the
                    foundation.
                </li>
                <h4>Modelling in the basement component:</h4>
                <li>
                    Model the portion of the walkout basement that is not a slab-on-grade foundation (hereafter referred
                    to as the remainder) as a basement.
                </li>
                <li>Set the total floor perimeter and area of the basement to that of the remainder.</li>
                <li>
                    Set the <strong>Exposed Surfaces Perimeter</strong> of the basement to be the perimeter of the
                    remainder that is not attached to the slab-on-grade foundation.
                </li>
                <li>Calculate the total wall area of the entire foundation (basement and slab-on-grade portions).</li>
                <li>Calculate the total below-ground wall area of the basement.</li>
                <li>
                    Divide the total below-ground wall area of the basement by the{" "}
                    <strong>Exposed Surfaces Perimeter</strong> of the basement to determine the average depth below
                    grade.
                </li>
                <li>
                    Enter the average depth below grade into the basement <strong>Depth Below Grade</strong> field.
                </li>
                <li>
                    Calculate the average basement height by dividing the total wall area of the entire foundation by
                    the <strong>Exposed Surfaces Perimeter</strong> of the basement.
                </li>
                <li>
                    Enter the average basement height into the basement <strong>Total Height</strong> field.
                </li>
                <li>
                    If there is a pony wall, determine the pony wall height by dividing the total pony wall area (for
                    both the basement and slab-on-grade foundations) by the <strong>Exposed Surfaces Perimeter</strong>{" "}
                    of the basement.
                </li>
                <li>
                    Check the <strong>Pony Wall</strong> box and enter the pony wall height into the basement{" "}
                    <strong>Height</strong> field.
                </li>
                <li>
                    Model all foundation walls as being part of the basement. Do not model any foundation wall as a Main
                    Wall above the slab-on-grade foundation.
                </li>
            </ol>
        </>
    );
};
