import React from 'react';
import classes from './style.module.scss';

export default ({
    children,
    gridTemplate="none",
    lastRow=false,
    style,
    flex=false,
    className
}) => (
    <div className={`${classes.inputRow} ${flex && classes.flex} ${className && className}`} style={{gridTemplateColumns:gridTemplate, marginBottom:lastRow ? 0 : "1.25rem", ...style}}>
        {children}
    </div>
);