export const dayIndex = {
    Jan: 31,
    Feb: 28,
    Mar: 31,
    Apr: 30,
    May: 31,
    Jun: 30,
    Jul: 31,
    Aug: 31,
    Sep: 30,
    Oct: 31,
    Nov: 30,
    Dec: 31,
};

export const months = Object.keys(dayIndex).map((month) => {
    return { value: month, label: month };
});

export const monthList = months.map((month) => {
    return month.label;
});
export const days = Object.entries(dayIndex).reduce((acc, month, ind) => {
    let daysPerMonth = [];
    for (let i = 1; i < month[1] + 1; i++) {
        daysPerMonth.push({ value: i, label: i });
    }
    return { ...acc, [`${month[0]}`]: daysPerMonth };
}, {});

export const tables = ["preUpgrade"];
// "postUpgrade"

export const rows = months;

export const getYear = (ind, firstMonth, firstYear) => {
    const year = monthList.indexOf(firstMonth) + ind > 11 ? firstYear + 1 : firstYear;
    return year;
};

export const getMonth = (ind, firstMonth) => {
    if (monthList.indexOf(firstMonth) + ind > 11) {
        return `${monthList[monthList.indexOf(firstMonth) + ind - 12]}`;
    } else {
        return monthList[monthList.indexOf(firstMonth) + ind];
    }
};
