import Apartment from 'assets/images/buildingTypes/Apartment.png';
import ApartmentNonMurb from 'assets/images/buildingTypes/ApartmentNonMurb.png';
import ApartmentRow from 'assets/images/buildingTypes/ApartmentRow.png';
import AttachedDuplex from 'assets/images/buildingTypes/AttachedDuplex.png';
import AttachedTriplex from 'assets/images/buildingTypes/AttachedTriplex.png';
import DetachedDuplex from 'assets/images/buildingTypes/DetachedDuplex.png';
import DetachedTriplex from 'assets/images/buildingTypes/DetachedTriplex.png';
import DuplexNonMurb from 'assets/images/buildingTypes/DuplexNonMurb.png';
import MobileHome from 'assets/images/buildingTypes/MobileHome.png';
import RowhouseEnd from 'assets/images/buildingTypes/RowhouseEnd.png';
import RowhouseMiddle from 'assets/images/buildingTypes/RowhouseMiddle.png';
import SemiDetached from 'assets/images/buildingTypes/SemiDetached.png';
import SingleDetached from 'assets/images/buildingTypes/SingleDetached.png';
import TriplexNonMurb from 'assets/images/buildingTypes/TriplexNonMurb.png';

export const BuildingIcon = key => ({
    0:{
        img:SingleDetached,
        alt:"Single detached building"
    },
    1:{
        img:SemiDetached,
        alt:"Semi detached building"
    },
    2:{
        img:DuplexNonMurb,
        alt:"Duplex (non-MURB) building"
    },
    3:{
        img:TriplexNonMurb,
        alt:"Triplex (non-MURB) building"
    },
    4:{
        img:ApartmentNonMurb,
        alt:"Apartment (non-MURB) building"
    },
    5:{
        img:RowhouseEnd,
        alt:"Row house (end) building"
    },
    6:{
        img:MobileHome,
        alt:"Mobile Home building"
    },
    7:{
        img:RowhouseMiddle,
        alt:"Row house (middle) building"
    },
    8:{
        img:DetachedDuplex,
        alt:"Detached duplex building"
    },
    9:{
        img:DetachedTriplex,
        alt:"Detached triplex building"
    },
    10:{
        img:AttachedDuplex,
        alt:"Attached duplex building"
    },
    11:{
        img:AttachedTriplex,
        alt:"Attached triplex building"
    },
    12:{
        img:Apartment,
        alt:"Apartment building"
    },
    13:{
        img:ApartmentRow,
        alt:"Apartment row building"
    },
}[key || 0])