import React, { useCallback } from "react";
import classes from "./style.module.scss";
import globalStyles from "components/globalStyles.module.scss";
import { Field } from "redux-form";
import InputRow from "components/Input/Row";
import InputWithUnits from "components/Input/InputWithUnits";
import { required, requiredCodeRef, getValidation, getDecimalPlaces } from "utils/fieldValidation";
import Select from "components/Input/Select";
import { useEffect } from "react";
import Add from "assets/images/icons/JSX/Add";
import Book from "assets/images/icons/JSX/Book";
import Edit from "assets/images/icons/JSX/Edit";
import { getBaseUnits, getUnitOptions } from "utils/fields";
import Tooltip from "components/Tooltip";
import { isEmpty, isEqual } from "lodash";

const floorHeaderTypeValidation = [required, ...getValidation("floorHeaderInsType")];

export const CodeFooter = ({ toggleDrawer, setInitCode, codeType }) => (
    <div
        className={classes.codeFooter}
        onClick={async () => {
            await setInitCode({}, codeType);
            toggleDrawer(true);
        }}
    >
        <span>Create New Code</span>
        <Add />
    </div>
);

const codeSort = (a, b) => {
    if (a.label < b.label) {
        return -1;
    }
    if (a.label > b.label) {
        return 1;
    }
    return 0;
};

const LabelActionComponent = ({ codeRef, handleEdit, codeType }) => (
    <>
        <div
            className={globalStyles.editCode}
            data-tip="Edit Code"
            data-for={codeRef}
            onClick={(event) => handleEdit(event, codeRef, codeType)}
        >
            <Edit />
            <Tooltip id={codeRef} className={globalStyles.fieldTooltip} place="left"/>
        </div>
    </>
);

// Floor Header Ins. Type codes found in code library
export const floorHeaderTypeLibCodesMap = (libCodes, handleEditLibFloorHeaderCode) =>
    Object.keys(libCodes)
        .map((codeRef) => {
            const { label = "" } = libCodes[codeRef] || {};
            const [standardCode] = codeRef.split("FloorHeader-U");

            return {
                label: label,
                labelIcon: Book,
                labelTag: !standardCode ? "User Defined" : "",
                labelActionComponent: () =>
                    handleEditLibFloorHeaderCode ? (
                        <LabelActionComponent
                            codeRef={codeRef}
                            handleEdit={handleEditLibFloorHeaderCode}
                            codeType={standardCode ? "S" : "U"}
                        />
                    ) : null,
                value: {
                    codeLabel: label,
                    codeRef,
                },
            };
        })
        .sort(codeSort);

// Floor Header Ins. Type codes found in model, filtered by those in library
export const floorheaderTypeModelCodesMap = (modelCodes, libCodes, handleEditModelFloorHeaderCode) =>
    Object.values(modelCodes)
        .filter(({ codeRef }) => {
            const [component] = codeRef.split("-");
            if (!!libCodes[codeRef]) {
                return (
                    (!libCodes[codeRef] && component === "FloorHeader") ||
                    !isEqual(modelCodes[codeRef].layers, libCodes[codeRef].layers)
                );
            }
            return !libCodes[codeRef] && component === "FloorHeader";
        })
        .map(({ label, codeRef }) => {
            const [standardCode] = codeRef.split("FloorHeader-U");

            return {
                label,
                labelTag: !standardCode ? "User Defined" : "",
                labelActionComponent: () => (
                    <LabelActionComponent
                        codeRef={codeRef}
                        handleEdit={handleEditModelFloorHeaderCode}
                        codeType={standardCode ? "S" : "U"}
                    />
                ),
                value: {
                    codeLabel: label,
                    codeRef,
                },
            };
        })
        .sort(codeSort);

export default React.memo(
    ({
        accessor,
        selectedCodeRef,
        change,
        modelChange = change,
        id,
        modelUnits,
        rValUnits,
        libCodes,
        modelCodes,
        selectedNomRValue,
        setInitCode,
        floorHeaderCodeWarning,
        isCalculatingRValue,
        updateCodeDrawer,
        disabledCodeEdit = false,
        isErsAndUSpec = false,
    }) => {
        const handleTypeChange = useCallback(
            ({ codeRef = "" }) => {
                if (codeRef === "UserSpecified") {
                    change(`${accessor}.floorHeaderInsType_warning`, "");
                    return;
                }

                const modelMatch = modelCodes[codeRef] || {};
                if (!isEmpty(modelMatch)) {
                    change(`${accessor}.floorHeaderInsType_nomRVal`, modelMatch.nominalRValue || 0.1);
                    change(`${accessor}.floorHeaderInsType_effRVal`, modelMatch.nominalRValue || 0.1);
                    change(`${accessor}.floorHeaderInsType_warning`, modelMatch.warningType || "");
                } else {
                    const { nominalRValue = 0, warningType = "" } = libCodes[codeRef] || {};
                    change(`${accessor}.floorHeaderInsType_nomRVal`, nominalRValue || 0.1);
                    change(`${accessor}.floorHeaderInsType_effRVal`, nominalRValue || 0.1);
                    change(`${accessor}.floorHeaderInsType_warning`, warningType || "");
                    modelChange(`modelData.codes.${codeRef}`, { ...libCodes[codeRef], codeRef });
                }
                //Redundancy
                modelChange(`stashedCodes.FloorHeader.${accessor.split(".").slice(-1)[0]}`, codeRef);
            },
            [libCodes, modelCodes, change, modelChange, accessor]
        );

        // useEffect(() => {
        //     if ((selectedCodeRef || "").includes("FloorHeader-")) {
        //         modelChange(`stashedCodes.FloorHeader.${id}`, selectedCodeRef);
        //     }
        // }, [id, selectedCodeRef]);

        const handleEditModelFloorHeaderCode = useCallback(
            async (event, codeRef, codeType) => {
                event.stopPropagation(); //Don't let click event bubble up to parent
                const initCodeType = codeType === "U" ? "udefStandard" : "floorHeaderInsType";
                const code = modelCodes[codeRef];
                await setInitCode(code, initCodeType);
                updateCodeDrawer({
                    isOpen: true,
                    codeName: "FloorHeader",
                    isEditing: true,
                    componentType: "FloorHeader",
                    codeType: codeType,
                    fieldAccessor: `${accessor}.floorHeaderInsType`,
                    componentId: id,
                    id: codeRef,
                    modelFormChange: modelChange,
                    currentFormChange: change,
                    lastEdited: code.lastEdited || "",
                });
            },
            [accessor, id, modelCodes, setInitCode, updateCodeDrawer, change, modelChange]
        );

        const handleEditLibFloorHeaderCode = disabledCodeEdit
            ? null
            : useCallback(
                  async (event, codeRef, codeType) => {
                      event.stopPropagation(); //Don't let click event bubble up to parent
                      const initCodeType = codeType === "U" ? "udefStandard" : "floorHeaderInsType";
                      const code = { ...libCodes[codeRef], codeRef: codeRef, isLibCode: true };
                      await setInitCode(code, initCodeType);
                      updateCodeDrawer({
                          isOpen: true,
                          codeName: "FloorHeader",
                          isEditing: true,
                          componentType: "FloorHeader",
                          codeType: codeType,
                          fieldAccessor: `${accessor}.floorHeaderInsType`,
                          componentId: id,
                          id: codeRef,
                          modelFormChange: modelChange,
                          currentFormChange: change,
                          lastEdited: code.lastEdited || "",
                      });
                  },
                  [accessor, id, libCodes, setInitCode, updateCodeDrawer, change, modelChange]
              );

        return (
            <>
                <InputRow gridTemplate="31.85% 23.25%" style={{ marginBottom: "1.5rem" }}>
                    <Field
                        component={Select}
                        type="number"
                        name={`${accessor}.floorHeaderInsType`}
                        options={[
                            {
                                label: "User Specified",
                                value: {
                                    codeLabel: "User Specified",
                                    codeRef: "UserSpecified",
                                },
                            },
                            ...floorheaderTypeModelCodesMap(modelCodes, libCodes, handleEditModelFloorHeaderCode),
                            ...floorHeaderTypeLibCodesMap(libCodes, handleEditLibFloorHeaderCode),
                        ]}
                        label="Floor Header Type"
                        placeholder="Choose Floor Header Type"
                        searchPlaceholder="Search Floor Header Codes"
                        search={true}
                        footer={() => (
                            <CodeFooter
                                toggleDrawer={(value) =>
                                    updateCodeDrawer({
                                        isOpen: value,
                                        codeName: "FloorHeader",
                                        isEditing: false,
                                        componentType: "FloorHeader",
                                        codeType: "S",
                                        fieldAccessor: `${accessor}.floorHeaderInsType`,
                                        componentId: id,
                                        modelFormChange: modelChange,
                                        currentFormChange: change,
                                    })
                                }
                                setInitCode={setInitCode}
                                codeType="floorHeaderInsType"
                            />
                        )}
                        className={classes.floorHeaderType}
                        isLoading={isCalculatingRValue}
                        onChange={handleTypeChange}
                        validate={requiredCodeRef}
                    />
                    {selectedCodeRef === "UserSpecified" ? (
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.floorHeaderInsType_effRVal`}
                            label={"Effective R-Value"}
                            placeholder={0.0}
                            disabled={selectedCodeRef !== "UserSpecified"}
                            validate={floorHeaderTypeValidation}
                            setTouched={true}
                            decimals={getDecimalPlaces("floorHeaderInsType")}
                            change={change}
                            units={{
                                base: getBaseUnits("floorHeaderInsType", modelUnits),
                                options: getUnitOptions("thermalResistance"),
                                selected: rValUnits,
                                unitType: "thermalResistance",
                                accessor: `${accessor}.floorHeaderInsType_u`,
                            }}
                            onChange={(value) => {
                                if (selectedCodeRef === "UserSpecified") {
                                    change(`${accessor}.floorHeaderInsType_nomRVal`, value);
                                }
                            }}
                            info={"User specified values are assumed to be effective R-values."}
                            isLoading={isCalculatingRValue}
                            warning={isErsAndUSpec ? "ersUSpecRVal" : ""}
                        />
                    ) : (
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.floorHeaderInsType_nomRVal`}
                            label={"Nominal R-Value"}
                            placeholder={0.0}
                            disabled={true}
                            validate={floorHeaderTypeValidation}
                            setTouched={true}
                            decimals={getDecimalPlaces("floorHeaderInsType")}
                            change={change}
                            setValue={selectedNomRValue || 0.1}
                            units={{
                                base: getBaseUnits("floorHeaderInsType", modelUnits),
                                options: getUnitOptions("thermalResistance"),
                                selected: rValUnits,
                                unitType: "thermalResistance",
                                accessor: `${accessor}.floorHeaderInsType_u`,
                            }}
                            info={
                                "Values calculated by the Code Selector are nominal R-values. HOT2000 re-calculates R-values based on the content of the code when running a simulation."
                            }
                            warning={floorHeaderCodeWarning}
                            isLoading={isCalculatingRValue}
                        />
                    )}
                </InputRow>
            </>
        );
    }
);
