const folderTypes = {
    CREATE_FOLDER_START: "CREATE_FOLDER_START",
    CREATE_FOLDER_SUCCESS: "CREATE_FOLDER_SUCCESS",
    CREATE_FOLDER_ERROR: "CREATE_FOLDER_ERROR",
    DELETE_FOLDER_START: "DELETE_FOLDER_START",
    DELETE_FOLDER_SUCCESS: "DELETE_FOLDER_SUCCESS",
    DELETE_FOLDER_ERROR: "DELETE_FOLDER_ERROR",
    RENAME_FOLDER_START: "RENAME_FOLDER_START",
    RENAME_FOLDER_SUCCESS: "RENAME_FOLDER_SUCCESS",
    RENAME_FOLDER_ERROR: "RENAME_FOLDER_ERROR",
    MOVE_FOLDER_START: "MOVE_FOLDER_START",
    MOVE_FOLDER_SUCCESS: "MOVE_FOLDER_SUCCESS",
    MOVE_FOLDER_ERROR: "MOVE_FOLDER_ERROR",
    BATCH_FOLDER_DELETE_START: "BATCH_FOLDER_DELETE_START",
    BATCH_FOLDER_DELETE_SUCCESS: "BATCH_FOLDER_DELETE_SUCCESS",
    BATCH_FOLDER_DELETE_ERROR: "BATCH_FOLDER_DELETE_ERROR",
};

export default folderTypes;
