import React from 'react';
import classes from './style.module.scss';
import Exclamation from 'assets/images/icons/JSX/Exlamation';
import getWarningText from 'utils/getWarningText';
import Tooltip from 'components/Tooltip';
import uniqid from 'uniqid';

export default ({ warning, className }) => {
    const id = uniqid("tooltip-");
    return (
        <div
            className={`${classes.warning} ${className && className}`}
            data-html
            data-tip={`<span style="display:block;max-width:14rem;">${getWarningText(warning)}</span>`}
            data-for={`${id}_warningTip`}
        >
            <Exclamation />
            <Tooltip id={`${id}_warningTip`} />
        </div>
    )
}