import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import Measurements from "./";
import isEmpty from "lodash/isEmpty";

const mapStateToProps = ({ form }, { accessor }) => {
    const selector = formValueSelector("model");
    const floorHeaderHeight = selector({ form }, `${accessor}.measurements.height`) || {};
    const headerHeightDrawing = selector({ form }, `${accessor}.measurements.height_drawingRef`) || {};
    const floorHeaderPerimeter = selector({ form }, `${accessor}.measurements.perimeter`) || {};
    const heightUnits = selector({ form }, `${accessor}.measurements.height_u`);
    const perimeterUnits = selector({ form }, `${accessor}.measurements.perimeter_u`);
    const areaUnits = selector({ form }, `${accessor}.measurements.area_u`);
    const modelUnits = selector({ form }, `modelData.uiSettings.primaryUnits`) || {};

    const floorHeaderHeightEquation = selector({ form }, `${accessor}.measurements.height_eqn`);
    const floorHeaderPerimeterEquation = selector({ form }, `${accessor}.measurements.perimeter_eqn`);

    return {
        accessor,
        floorHeaderHeight,
        floorHeaderPerimeter,
        modelUnits,
        heightUnits,
        perimeterUnits,
        areaUnits,
        headerHeightDrawing: !isEmpty(headerHeightDrawing),
        floorHeaderHeightEquation,
        floorHeaderPerimeterEquation,
    };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Measurements);
