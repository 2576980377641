import React from 'react';

export default () => (
    <svg width="16px" height="17px" viewBox="0 0 16 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="Accordion-/-Hover-Elements-/-Dupe-+-Trash-+-Drag" transform="translate(-26.000000, -4.000000)" fill="#65676A" fillRule="nonzero">
            <g id="Group-2" transform="translate(-4.000000, 0.000000)">
                <g id="Duplicate" transform="translate(30.000000, 4.000000)">
                    <path d="M16,0.5 L16,13.5 L14,13.5 L14,12.5 L15,12.5 L15,1.5 L4,1.5 L4,2.5 L3,2.5 L3,0.5 L16,0.5 Z M13,3.5 L13,16.5 L0,16.5 L0,3.5 L13,3.5 Z M12,4.5 L1,4.5 L1,15.5 L12,15.5 L12,4.5 Z M7,7 L7,9.5 L9.5,9.5 L9.5,10.5 L7,10.5 L7,13 L6,13 L6,10.5 L3.5,10.5 L3.5,9.5 L6,9.5 L6,7 L7,7 Z" id="Rectangle-Copy-3"></path>
                </g>
            </g>
        </g>
    </svg>
)