import React from "react";

export default ({ className }) => (
    <svg
        className={className}
        width="16px"
        height="16px"
        viewBox="0 0 16 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g
            transform="translate(-52.000000, -5.000000)"
            fill="#65676A"
            fillRule="nonzero"
        >
            <g id="Group-2" transform="translate(-4.000000, 0.000000)">
                <g id="Remove" transform="translate(56.000000, 0.000000)">
                    <path
                        d="M8,5 C12.418278,5 16,8.581722 16,13 C16,17.418278 12.418278,21 8,21 C3.581722,21 0,17.418278 0,13 C0,8.581722 3.581722,5 8,5 Z M8,6 C4.13400675,6 1,9.13400675 1,13 C1,16.8659932 4.13400675,20 8,20 C11.8659932,20 15,16.8659932 15,13 C15,9.13400675 11.8659932,6 8,6 Z M10.1213203,10.1715729 L10.8284271,10.8786797 L8.707,13 L10.8284271,15.1213203 L10.1213203,15.8284271 L8,13.707 L5.87867966,15.8284271 L5.17157288,15.1213203 L7.293,13 L5.17157288,10.8786797 L5.87867966,10.1715729 L8,12.293 L10.1213203,10.1715729 Z"
                        id="Oval-Copy"
                    ></path>
                </g>
            </g>
        </g>
    </svg>
);
