import React, { useCallback } from "react";
import classes from "./style.module.scss";
import Select from "components/Input/Select";
import Input from "components/Input";
import InputRow from "components/Input/Row";
import CostTableInput from "components/Input/CostTableTotal";
import CostTableHeader from "components/CostTableHeader";
import { Field } from "redux-form";
import moment from "moment";
import { IconButton } from "@material-ui/core";
import Delete from "assets/images/icons/JSX/Delete";
import { costTypesByUpgrade, codeToLabel, overallCostTypes, getCodeToLabel } from "utils/costTypeIndex";
import convertUnit from "utils/convertUnit";
import { isEmpty } from "lodash";
import Accordion from "components/Accordion";
import { modelFormSelector } from "store/form/selectors";
import { useDispatch, useSelector } from "react-redux";
import InputWithUnits from "components/Input/InputWithUnits";
import { getBaseUnits, getObjValAtPath } from "utils/fields";
import CostPerAreaInput from "components/Input/CostPerAreaInput";
import { getCompAreaForCostCalc } from "utils/enclosure/components";
import { calcUpgradeDetailedCost } from "utils/upgrades";

const calcCostPerUnitBasis = (value, perUnitCost) => {
    return parseFloat((perUnitCost * value).toFixed(2)) || 0;
};

const CostTable = ({
    change,
    accessor,
    costItems,
    upgradeType,
    showCost,
    selectedUpgrade,
    selectedComponents = [],
    selectedPackage,
    modelUnits,
    upgradeFields,
    isUpgradeLibrary,
}) => {
    const components = useSelector(modelFormSelector)("modelData.components");

    const totalPvCapacity = upgradeType === "generation" ? upgradeFields?.pvCapacity || 0 : 0;

    //This variable tracks the total component area (unit value) that our cost per unit is based on.
    //For doors, it tracks the count of doors present
    const calcTotalComponentArea = useCallback(() => {
        if (!isEmpty(selectedComponents) && !isUpgradeLibrary) {
            return getCompAreaForCostCalc(selectedComponents, components, upgradeType);
        }
        return 0;
    }, [components, selectedComponents, upgradeType]);

    const totalArea = parseFloat(calcTotalComponentArea().toFixed(2));

    const costTypeOptions =
        costTypesByUpgrade[upgradeType]
            ?.map((code) => {
                return {
                    value: code,
                    label: getCodeToLabel(modelUnits)[code],
                };
            })
            .sort(
                (a, b) =>
                    Object.keys(getCodeToLabel(modelUnits)).indexOf(a.value) -
                    Object.keys(getCodeToLabel(modelUnits)).indexOf(b.value)
            ) || [];

    const addRow = (value) => {
        change(`${accessor}.items`, {
            ...costItems,
            [`item-${moment().format("YYYYMMDDHHmmssSS")}`]: {
                label: value,
                total: 0,
            },
        });
    };

    const removeRow = (item) => {
        const { [item]: itemToRemove, ...restItems } = costItems || {};
        change(`${accessor}.items`, {
            ...restItems,
        });
    };

    const { totalCost, preTaxTotal, taxFraction, postTaxRebate } = calcUpgradeDetailedCost(costItems);

    const inputUnit = "m2"; // always
    const displayUnit = ["Imperial", "US"].includes(modelUnits) ? "ft2" : "m2";

    const sortedItems = Object.keys(costItems).sort(
        (a, b) => parseInt(a.replace(/[^0-9\.]/g, ""), 10) - parseInt(b.replace(/[^0-9\.]/g, ""), 10)
    );

    // const convertedAreaForDisplay = convertUnit({
    //     value: parseFloat(totalArea),
    //     type: "area",
    //     inputUnit,
    //     outputUnit: displayUnit,
    // });

    const ItemsList = sortedItems.map((item, ind) => {
        // per unit cost will change depending on modelUnits

        const pricePerUnit = costItems[item]?.pricePerUnit || 0;
        const tax = costItems[item]?.tax || 0;
        const unit = displayUnit;
        return (
            <div key={ind} className={`${classes.tableRow} ${ind % 2 !== 1 && classes.oddRow}`}>
                <Field
                    component={Select}
                    className={`${classes.costSelect} ${ind % 2 !== 1 && classes.oddRow}`}
                    name={`${accessor}.items.${item}.label`}
                    options={costTypeOptions}
                />
                <div className={classes.endRow}>
                    {overallCostTypes.includes(costItems[item].label) ? (
                        <Field
                            className={classes.amount}
                            component={Input}
                            name={`${accessor}.items.${item}.total`}
                            type="number"
                        />
                    ) : costItems[item].label === "tax" ? (
                        <div className={classes.calcFields}>
                            <Field
                                // label="$/Unit"
                                placeholder="% tax"
                                className={classes.amount}
                                component={Input}
                                type="number"
                                name={`${accessor}.items.${item}.tax`}
                            />
                            <Field
                                className={classes.amount}
                                component={Input}
                                name={`${accessor}.items.${item}.total`}
                                setValue={parseFloat(((tax / 100) * preTaxTotal).toFixed(2)) || 0}
                                type="number"
                                disabled
                            />
                        </div>
                    ) : (
                        <div style={{ display: "flex" }}>
                            {["window", "skylights", "door"].includes(upgradeType) ? (
                                <div className={classes.calcFields}>
                                    <Field
                                        placeholder="$/unit"
                                        className={classes.amount}
                                        component={Input}
                                        name={`${accessor}.items.${item}.totalArea`}
                                        disabled
                                        setValue={totalArea}
                                        type="number"
                                    />
                                    <Field
                                        placeholder="$/unit"
                                        className={classes.amount}
                                        component={Input}
                                        name={`${accessor}.items.${item}.pricePerUnit`}
                                        type="number"
                                    />
                                    <Field
                                        className={classes.amount}
                                        component={Input}
                                        name={`${accessor}.items.${item}.total`}
                                        setValue={parseFloat((totalArea * pricePerUnit).toFixed(2)) || 0}
                                        disabled
                                        type="number"
                                    />
                                </div>
                            ) : upgradeType === "generation" ? (
                                <div className={classes.calcFields}>
                                    <Field
                                        className={classes.amount}
                                        component={InputWithUnits}
                                        name={`${accessor}.items.${item}.totalPvCapacity`}
                                        decimals={2}
                                        setValue={totalPvCapacity}
                                        disabled
                                        setTouched={true}
                                        change={change}
                                        units={{
                                            base: { trueBase: "kW", displayBase: "kW" },
                                            options: ["kW"],
                                            selected: "kW",
                                            unitType: "power",
                                            accessor: `${accessor}.items.${item}.totalPvCapacity_u`,
                                        }}
                                    />
                                    <Field
                                        className={classes.amount}
                                        component={CostPerAreaInput}
                                        name={`${accessor}.items.${item}.pricePerUnit`}
                                        decimals={2}
                                        placeholder="$/kW"
                                        setTouched={true}
                                        change={change}
                                        units={{
                                            base: { trueBase: "kW", displayBase: "kW" }, //always working in base units
                                            selected: "kW",
                                            unitType: "power",
                                            accessor: `${accessor}.items.${item}.pricePerUnit_u`,
                                        }}
                                    />
                                    <Field
                                        className={classes.amount}
                                        component={Input}
                                        type="number"
                                        name={`${accessor}.items.${item}.total`}
                                        setValue={calcCostPerUnitBasis(totalPvCapacity, pricePerUnit) || 0} //always do calculation on base value
                                        disabled
                                    />
                                </div>
                            ) : (
                                <div className={classes.calcFields}>
                                    <Field
                                        className={classes.amount}
                                        component={InputWithUnits}
                                        name={`${accessor}.items.${item}.totalArea`}
                                        decimals={2}
                                        setValue={totalArea}
                                        disabled
                                        setTouched={true}
                                        change={change}
                                        units={{
                                            base: getBaseUnits("commonAreaFloors", modelUnits),
                                            // options: ["m2", "ft2"],
                                            selected: unit,
                                            unitType: "area",
                                            accessor: `${accessor}.items.${item}.totalArea_u`,
                                        }}
                                    />
                                    <Field
                                        className={classes.amount}
                                        component={CostPerAreaInput}
                                        name={`${accessor}.items.${item}.pricePerUnit`}
                                        decimals={2}
                                        placeholder="$/unit"
                                        setTouched={true}
                                        change={change}
                                        units={{
                                            base: { trueBase: "m2", displayBase: displayUnit },
                                            selected: displayUnit,
                                            unitType: "pricePerArea",
                                            accessor: `${accessor}.items.${item}.pricePerUnit_u`,
                                        }}
                                    />
                                    <Field
                                        className={classes.amount}
                                        component={Input}
                                        type="number"
                                        name={`${accessor}.items.${item}.total`}
                                        setValue={calcCostPerUnitBasis(totalArea, pricePerUnit) || 0} //always do calculation on base value
                                        disabled
                                    />
                                </div>
                            )}
                        </div>
                    )}
                    <IconButton onClick={() => removeRow(item)} style={{ marginLeft: "0rem", marginRight: "0rem" }}>
                        <Delete />
                    </IconButton>
                </div>
            </div>
        );
    });

    return (
        <div style={{ marginTop: "1rem" }}>
            <Accordion
                heading={
                    <div className={classes.accordionHeader}>
                        <p>Upgrade Costs</p>
                        <p className={classes.costPreview}>
                            <CostTableHeader
                            //  isOpen={open}
                            >
                                <Field
                                    className={classes.amount}
                                    component={CostTableInput}
                                    name={`${accessor}.total`}
                                    setValue={totalCost}
                                    disabled
                                />
                            </CostTableHeader>
                        </p>
                    </div>
                }
                large
            >
                <div className={classes.table}>
                    <div className={classes.tableHeader}>
                        <p>Line Item</p>
                        <p>Amount</p>
                    </div>
                </div>
                <div className={classes.tableBody}>
                    {ItemsList}
                    <div className={classes.tableRow}>
                        <Select
                            className={classes.costSelect}
                            input={{
                                onChange: (value) => {
                                    addRow(value);
                                },
                                value: null,
                            }}
                            options={costTypeOptions}
                            placeholder="Select new line item"
                        />
                    </div>

                    <InputRow gridTemplate="2fr 1fr" className={classes.total}>
                        <p>Total</p>
                        <Field
                            className={classes.amount}
                            component={CostTableInput}
                            name={`${accessor}.total`}
                            setValue={totalCost}
                            disabled
                        />
                    </InputRow>
                    {isUpgradeLibrary && (
                        <div className={classes.upgradeLibraryText}>
                            <p>Costs per unit will be updated when applying this upgrade to a model.</p>
                        </div>
                    )}
                </div>
            </Accordion>
        </div>
    );
};

export default CostTable;
