import React from 'react';
import classes from '../../../style.module.scss';
import { Field } from 'redux-form';
import InputWithUnits from 'components/Input/InputWithUnits';
import Input from 'components/Input';
import { required, maxLength, getValidation, getDecimalPlaces } from 'utils/fieldValidation';
import { getBaseUnits, getUnitOptions } from 'utils/fields';
import convertUnit from 'utils/convertUnit';
import { isEqual } from 'lodash';

const charLim100 = maxLength(100);
const exposedSurfaceValidation = getValidation('slabExpSurfacePerimeter');
const perimeterValidation = getValidation('slabPerimeter');
const areaValidation = getValidation('slabArea');

const Slab = React.memo(({
    componentId,
    primaryUnits,
    imageUnits,
    area,
    perimeter,
    conversionFactor,
    change,
    expPerimeterUnits,
    saveToState,
}) => {

    const calculatedArea = parseFloat(convertUnit({
        value:area*Math.pow(conversionFactor, 2),
        type:'area',
        inputUnit:'m2',
        outputUnit:getBaseUnits('area', primaryUnits).trueBase
    }).toFixed(2));

    const calculatedPerimeter = parseFloat(convertUnit({
        value:perimeter*conversionFactor,
        type:'length',
        inputUnit:'m',
        outputUnit:getBaseUnits('perimeter',primaryUnits).trueBase
    }).toFixed(2));

    return (
        <div>
            <Field
                className={classes.inputMarginBottom}
                component={Input}
                type="text"
                label="Slab Name*"
                name={`components.${componentId}.label`}
                validate={[required, charLim100]}
                placeholder="Example: Slab-on-grade"
                onChange={saveToState}
            />
             <Field
                className={classes.inputMarginBottom}
                component={InputWithUnits}
                type="number"
                name={`components.${componentId}.floor.measurements.perimeter`}
                label="Floor Perimeter"
                placeholder="0.00"
                validate={perimeterValidation}
                decimals={getDecimalPlaces('slabPerimeter')}
                change={()=>''}
                disabled={true}
                setValue={calculatedPerimeter}
                units={{
                    base:getBaseUnits('slabPerimeter', primaryUnits),
                    options:[imageUnits],
                    selected:imageUnits,
                    unitType:'length',
                    accessor:`components.${componentId}.floor.measurements.perimeter_u`
                }}
                info="Calculated based on polygon shape and the set scale."
                onChange={saveToState}
                onUnitChange={saveToState}
            />
            <Field
                className={classes.inputMarginBottom}
                component={InputWithUnits}
                type="number"
                name={`components.${componentId}.floor.measurements.area`}
                label="Floor Area"
                placeholder="0.00"
                validate={areaValidation}
                decimals={getDecimalPlaces('slabArea')}
                change={()=>''}
                disabled={true}
                setValue={calculatedArea}
                units={{
                    base:getBaseUnits('slabArea', primaryUnits),
                    options:[`${imageUnits}2`],
                    selected:`${imageUnits}2`,
                    unitType:'area',
                    accessor:`components.${componentId}.floor.measurements.area_u`
                }}
                info="Calculated based on polygon shape and the set scale."
                onChange={saveToState}
                onUnitChange={saveToState}
            />
            <Field
                className={classes.inputMarginBottom}
                component={InputWithUnits}
                type="number"
                name={`components.${componentId}.exposedSurfacePerimeter`}
                label="Exposed Surfaces Perimeter"
                placeholder="0.00"
                validate={exposedSurfaceValidation}
                decimals={getDecimalPlaces('slabExpSurfacePerimeter')}
                change={change}
                units={{
                    base:getBaseUnits('slabExpSurfacePerimeter', primaryUnits),
                    options:getUnitOptions('length'),
                    selected:expPerimeterUnits,
                    unitType:'length',
                    accessor:`components.${componentId}.exposedSurfacePerimeter_u`
                }}
                info={"Used only when there are multiple foundations present. Enter the total perimeter of this foundation component that is not attached to any other foundation components."}
                onChange={saveToState}
                onUnitChange={saveToState}
            />
        </div>
    )
}, isEqual);

export default Slab;