import React from "react";

export default () => (
    <svg
        width="28"
        height="24"
        viewBox="0 0 28 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 2.25H3H2.25V3V21V21.75H3H25H25.75V21V20H24.25V20.25H3.75V3.75H4V2.25Z"
            fill="black"
        />
        <rect
            x="1.5"
            y="1.5"
            width="3"
            height="3"
            rx="1.5"
            fill="black"
            stroke="black"
            strokeWidth="2"
        />
        <rect
            x="1.5"
            y="19.5"
            width="3"
            height="3"
            rx="1.5"
            fill="black"
            stroke="black"
            strokeWidth="2"
        />
        <rect
            x="23.5"
            y="19.5"
            width="3"
            height="3"
            rx="1.5"
            fill="black"
            stroke="black"
            strokeWidth="2"
        />
    </svg>
);
