import React from "react";

const Circle = ({ color, percentage = null, r, strokeWidth }) => {
    const circ = 2 * Math.PI * r;
    const strokePct = ((100 - percentage) * circ) / 100;

    return (
        <circle
            r={r}
            cx={8}
            cy={8}
            fill="transparent"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeDasharray={circ}
            strokeDashoffset={percentage !== null ? strokePct : circ}
            strokeLinecap="round"
            style={{ transition: "stroke-dashoffset 0.3s ease-out" }}
        ></circle>
    );
};

const ProjectChecklistProgress = ({ progress, color = "#038851", radius = 5, strokeWidth="0.2rem" }) => {
    return (
        <svg width={16} height={16}>
            <g transform={`rotate(-90 ${"8 8"})`}>
                <Circle color="#e1eaf0" percentage={100} r={radius} strokeWidth={strokeWidth}/>
                <Circle color={color} percentage={progress} r={radius} strokeWidth={strokeWidth}/>
            </g>
        </svg>
    );
};

export default ProjectChecklistProgress;
