import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useOutsideClickHook } from "utils/outsideClick";
import MenuDots from "components/MenuDots";
import DropDown from "components/Dropdown";
import classes from "../UpgradesTable.module.scss";
import Edit from "assets/images/icons/JSX/Edit";
import HousesSelect from "assets/images/icons/JSX/HousesSelect";
import Delete from "assets/images/icons/JSX/Delete";
import { actions as userActions } from "store/users";
import { actions as modelActions } from "features/Model/_ducks";
import { actions as resultsActions } from "features/Model/Review/Results/_ducks";

const { fetchUserDir } = userActions;
const { getLinkedResultsStatus, clearModelInfo } = modelActions;
const { clearLinkedResults } = resultsActions;

const UpgradeHead = ({
    packageId,
    name,
    togglePackageEdit,
    toggleSelectFolder,
    uid,
    acceptLinkedBaseline,
    setAcceptLinkedBaseline,
}) => {
    const dispatch = useDispatch();

    const [open, toggleOpen] = useState(false);

    const ref = useOutsideClickHook(() => toggleOpen(false));

    const handleEditClick = () => {
        toggleOpen(false);
        togglePackageEdit();
    };

    const handleSelectModel = () => {
        toggleOpen(false);
        dispatch(fetchUserDir(uid));
        toggleSelectFolder(true);
    };

    const handleUnlinkModel = () => {
        toggleOpen(false);
        dispatch(clearModelInfo())
        dispatch(clearLinkedResults());
        dispatch(getLinkedResultsStatus(false));
        setAcceptLinkedBaseline(false);
    };

    const [headerOptions, setHeaderOptions] = useState([
        {
            label: (
                <>
                    <Edit /> Edit base case name
                </>
            ),
            className: classes.dropdownLabel,
            onClick: handleEditClick,
        },
        {
            label: (
                <>
                    <HousesSelect /> Select pre-retrofit base case file
                </>
            ),
            className: classes.dropdownLabel,
            onClick: handleSelectModel,
        },
    ]);

    useEffect(() => {
        if (acceptLinkedBaseline) {
            setHeaderOptions([
                ...headerOptions,
                {
                    label: (
                        <>
                            <Delete /> Unlink pre-retrofit base case
                        </>
                    ),
                    className: `${classes.dropdownLabel} ${classes.deleteLabel}`,
                    onClick: handleUnlinkModel,
                },
            ]);
        } else {
            setHeaderOptions(headerOptions.slice(0, 2));
        }
    }, [acceptLinkedBaseline]);

    return (
        <span className={classes.upgradeHead}>
            <span className={classes.upgradeHeadLabel}>{name || "Existing Home"}</span>
            <div ref={ref} className={classes.upgradeHeadMenuWrapper}>
                <MenuDots className={classes.upgradeHeadMenu} onClick={() => toggleOpen(true)} />
                <DropDown
                    className={classes.upgradeHeadBaseDropdown}
                    open={open}
                    toggleOpen={() => toggleOpen(!open)}
                    options={headerOptions}
                />
            </div>
        </span>
    );
};

export default UpgradeHead;
