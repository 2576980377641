import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import WoodFraming from './';


const mapStateToProps = (
    {
        form,
    },
    {
        accessor
    }
) => {
    const selector = formValueSelector('uDefCode');
    const modelSelector = formValueSelector('model');
    const { id: structureTypeId } = selector({form}, `${accessor}.structureType`) || {};
    const { id: framingMaterialCatId } = selector({form}, `${accessor}.framing.materialCategory`) || {};
    const { id: framingMaterialTypeId } = selector({form}, `${accessor}.framing.materialType`) || {};
    const framingResistivityValue = selector({form}, `${accessor}.framing.resistivity`);
    const framingThicknessValue = selector({form}, `${accessor}.framing.thickness`);
    const woodFramingLayers = selector({form}, 'uDefCode.layers.woodFraming');

    const framingRValue = parseFloat((framingResistivityValue * framingThicknessValue).toFixed(2));

    const { id: cavityMaterialCatId } = selector({form}, `${accessor}.cavityInsulation.materialCategory`) || {};
    const { id: cavityMaterialTypeId } = selector({form}, `${accessor}.cavityInsulation.materialType`) || {};
    const cavityResistivityValue = selector({form}, `${accessor}.cavityInsulation.resistivity`);
    const cavityThicknessValue = selector({form}, `${accessor}.cavityInsulation.thickness`);

    const cavityRValue = parseFloat((cavityResistivityValue * cavityThicknessValue).toFixed(2));

    const framingResistivityUnits = selector({form}, `${accessor}.framing.resistivity_u`);
    const framingThicknessUnits = selector({form}, `${accessor}.framing.thickness_u`);
    const framingRValueUnits = selector({form}, `${accessor}.framing.rValue_u`);
    const framingWidthUnits = selector({form}, `${accessor}.framing.width_u`);
    const framingSpacingUnits = selector({form}, `${accessor}.framing.spacing_u`);

    const cavityResistivityUnits = selector({form}, `${accessor}.cavityInsulation.resistivity_u`);
    const cavityThicknessUnits = selector({form}, `${accessor}.cavityInsulation.thickness_u`);
    const cavityRValueUnits = selector({form}, `${accessor}.cavityInsulation.rValue_u`);

    const modelUnits = modelSelector({form}, `modelData.uiSettings.primaryUnits`) || {};
    const layer = selector({form}, `${accessor}.layer`);

    return {
        accessor,
        structureTypeId,
        framingMaterialCatId,
        framingMaterialTypeId,
        framingResistivityValue,
        framingResistivityUnits,
        framingThicknessUnits,
        framingRValue,
        framingRValueUnits,
        framingWidthUnits,
        framingSpacingUnits,
        cavityMaterialCatId,
        cavityMaterialTypeId,
        cavityResistivityValue,
        cavityResistivityUnits,
        cavityThicknessUnits,
        cavityRValue,
        cavityRValueUnits,
        modelUnits,
        layer,
        woodFramingLayers
    }
};

const mapDispatchToProps = () => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WoodFraming);