import { createSelector } from "reselect";

const selectUpgradeLibrary = (state) => state.upgradeLibrary;

export const selectSaveUpgradePackageLabels = createSelector(
    [selectUpgradeLibrary],
    (packages) => packages.upgradePackagesLabels
);

export const selectCurrentUpgradePackage = createSelector(
    [selectUpgradeLibrary],
    (packages) => packages.currentPackage
);

export const selectFetchingCurrentPackage = createSelector(
    [selectUpgradeLibrary],
    (packages) => packages.fetchingCurrentPackage
);

export const selectCurrentPackageToCompare = createSelector(
    [selectUpgradeLibrary],
    (packages) => packages.currentPackageToCompare
);
