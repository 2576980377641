import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import Technical from "./";

const mapStateToProps = ({ form }, { accessor, formName = "model" }) => {
    const selector = formValueSelector("model");
    const modelUnits = selector({ form }, `modelData.uiSettings.primaryUnits`) || {};
    const houseVolumeUnits = selector({ form }, `${accessor}.spaceCooling.houseVolume_u`);

    const totalHouseVolume = selector({ form }, `modelData.dimensions.volume.total`) || 0;

    return {
        accessor,
        formName,
        modelUnits,
        houseVolumeUnits,
        totalHouseVolume,
    };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Technical);
