import React from "react";
import classes from "./style.module.scss";
import Tooltip from "components/Tooltip";

export default ({ options = [], className, open = false, selected, toggleOpen }) => {
    return (
        <div className={`${classes.dropDown} ${open && classes.open} ${className && className}`}>
            <ul>
                {options.map(
                    (
                        {
                            label,
                            onClick,
                            className,
                            disabled,
                            tipId,
                            tip = null,
                            hasDivider = false,
                            shouldShow = true,
                        },
                        index
                    ) => {
                        const classNames = [
                            className && className,
                            classes.option,
                            selected === label && classes.selected,
                            disabled && classes.disabled,
                            hasDivider && classes.hasDivider,
                        ].filter((c) => c);

                        return shouldShow ? (
                            <li
                                data-for={`dropdown-${tipId}`}
                                data-tip={tip ? `<span style="display:block; max-width:16rem;">${tip}</span>` : null}
                                data-html
                                className={classNames.join(" ")}
                                key={index}
                                onClick={disabled ? () => {} : onClick}
                            >
                                {label}
                            </li>
                        ) : null;
                    }
                )}
            </ul>
            {options.map(
                ({ tipId }, index) => tipId && <Tooltip key={`tip-${index}`} id={`dropdown-${tipId}`} delay />
            )}
        </div>
    );
};
