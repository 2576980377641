import React from 'react';
import classes from '../style.module.scss';
import sharedClasses from 'features/Model/sharedStyles.module.scss';
import { Field } from 'redux-form';
import { required, maxLength, getValidation, getDecimalPlaces } from 'utils/fieldValidation';
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { getOptions, getFirstOption, getBaseUnits, getUnitOptions } from 'utils/fields';
import InputRow from 'components/Input/Row';
import Input from 'components/Input';
import InputWithUnits from 'components/Input/InputWithUnits';
import Select from 'components/Input/Select';
import SelectWithInput from 'components/Input/SelectWithInput';
import Button from 'components/Button';
import Checkbox from 'components/Input/Checkbox';
import Accordion from 'components/Accordion';

const numOccupantsValidation = getValidation('ersNumOccupants');
const coolingSeasonValidation = getValidation('ersCoolingSeasonNumMonths');
const heatingDayValidation = getValidation('ersThermostatHeatingDay');
const heatingNightValidation = getValidation('ersThermostatHeatingNight');
const coolingValidation = getValidation('ersThermostatCooling');

export default React.memo(({
    accessor,
    heatingDayUnits,
    heatingNightUnits,
    coolingUnits,
    modelUnits,
    change
}) => {

    return (
        <div>
            <InputRow gridTemplate="1fr 1fr 1fr">
                <Field
                    component={Input}
                    type="number"
                    name={`${accessor}.numOccupants`}
                    label="Number of Occupants"
                    placeholder={0.00}
                    validate={numOccupantsValidation}
                    decimals={getDecimalPlaces('ersNumOccupants')}
                />
                <Field
                    component={Input}
                    type="number"
                    name={`${accessor}.coolingSeasonMonths`}
                    label="Months of Cooling Season"
                    placeholder="0.00"
                    validate={coolingSeasonValidation}
                    decimals={getDecimalPlaces('ersCoolingSeasonNumMonths')}
                />
            </InputRow>
            <h4 style={{marginBottom:"1.25rem", marginTop:"1.75rem"}}>Thermostat Settings</h4>
            <InputRow gridTemplate="1fr 1fr 1fr">
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.thermostat.heatingDay`}
                    label="Daytime Heating Set Point"
                    placeholder="0.00"
                    validate={heatingDayValidation}
                    decimals={getDecimalPlaces('ersThermostatHeatingDay')}
                    change={change}
                    units={{
                        base:getBaseUnits('ersThermostatHeatingDay', modelUnits),
                        options:getUnitOptions('temperature'),
                        selected:heatingDayUnits,
                        unitType:'temperature',
                        accessor:`${accessor}.thermostat.heatingDay_u`
                    }}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.thermostat.heatingNight`}
                    label="Nighttime Heating Set Point"
                    placeholder="0.00"
                    validate={heatingNightValidation}
                    decimals={getDecimalPlaces('ersThermostatHeatingNight')}
                    change={change}
                    units={{
                        base:getBaseUnits('ersThermostatHeatingNight', modelUnits),
                        options:getUnitOptions('temperature'),
                        selected:heatingNightUnits,
                        unitType:'temperature',
                        accessor:`${accessor}.thermostat.heatingNight_u`
                    }}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.thermostat.cooling`}
                    label="Cooling Set Point"
                    placeholder="0.00"
                    validate={coolingValidation}
                    decimals={getDecimalPlaces('ersThermostatCooling')}
                    change={change}
                    units={{
                        base:getBaseUnits('ersThermostatCooling', modelUnits),
                        options:getUnitOptions('temperature'),
                        selected:coolingUnits,
                        unitType:'temperature',
                        accessor:`${accessor}.thermostat.cooling_u`
                    }}
                    info={'The cooling set point must be greater than the weighted average of the heating season daytime and nighttime setpoints.'}
                />
            </InputRow>
            <InputRow gridTemplate="1fr 1fr 1fr">
                <Field
                    component={Checkbox}
                    name={`${accessor}.efficientWasher`}
                    label="Is clothes washer Energy Star or front load?"
                    large
                    className={classes.bottomCheckBox}
                    type="checkbox"
                />
                <Field
                    component={Checkbox}
                    name={`${accessor}.efficientAppliances`}
                    label="Are appliances less than five years old?"
                    large
                    className={classes.bottomCheckBox}
                    type="checkbox"
                />
                <Field
                    component={Checkbox}
                    name={`${accessor}.efficientLighting`}
                    label="Is more than 50% of lighting efficient?"
                    large
                    className={classes.bottomCheckBox}
                    type="checkbox"
                />
            </InputRow>
        </div>
    )
})