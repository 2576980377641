import types from "./types";
import { updateObject } from "utils";

const initialState = {
    auditIdIsValid: false,
    validationError: "",
    isValidating: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.TOGGLE_AUDIT_ID_VALID:
            return toggleAuditIdValid(state, action);
        case types.SET_VALIDATION_ERROR:
            return setValidationError(state, action);
        case types.TOGGLE_IS_AUDIT_VALIDATING:
            return toggleIsAuditValidating(state, action);
        case types.SET_MINI_AUDIT_VALID:
            return setMiniAuditValid(state, action);
        default:
            return state;
    }
};

const toggleAuditIdValid = (state, action) => {
    return updateObject(state, {
        auditIdIsValid: action.isValid,
    });
};

const setValidationError = (state, action) => {
    return updateObject(state, {
        validationError: action.error,
    });
}

const toggleIsAuditValidating = (state, action) => {
    return updateObject(state, {
        isValidating: action.isValidating,
    });
}

const setMiniAuditValid = (state, action) => {
    return updateObject(state, {
        isValidating: false,
        validationError: action.isValid ? "" : "This assessment id is invalid",
        auditIdIsValid: action.isValid,
    });
};