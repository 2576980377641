import React from 'react';
import classes from './style.module.scss';
import { Dialog, Slide } from '@material-ui/core';

export const CloseModal = ({closeActions}) => (
    <div className={classes.close} onClick={() => closeActions()}>
        <div>
            <span>close</span>
        </div>
    </div>
);

const Transition = React.forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);

const getClasses = ({ rootClass='', paperClass='' }) => ({
    root: `${classes.dialogRoot} ${rootClass ? rootClass : ''}`,
    paper: `${classes.paper} ${paperClass ? paperClass : ''}`
});

export default ({children, classes={}, ...props}) => (
    <Dialog
        fullScreen
        classes={getClasses(classes)}
        TransitionComponent={Transition}
        transitionDuration={450}
        {...props}
    >
        {children}
    </Dialog>
)