import React, { useEffect, useCallback } from "react";
import SectionHeading from "components/SectionHeading";
import classes from "./style.module.scss";
import InputCodeLib from "components/Input/CodeLibUpload";
import VerticalTabs from "components/Tabs/VerticalTabs";
import CodeList from "./CodeList/container";
import ToggleMulti from "components/ToggleMulti";

const tabs = [
    { name:"Basement Wall", url:'/', type:"BasementWall" },
    { name:"Ceiling", url:'/ceiling', type:"Ceiling" },
    { name:"Flat Ceiling", url:'/ceiling-flat', type:"CeilingFlat" },
    { name:"Crawlspace Wall", url:'/crawlspace', type:"CrawlspaceWall" },
    { name:"Floor", url:'/floor', type:"Floor" },
    { name:"Floor Header", url:'/floor-header', type:"FloorHeader" },
    { name:"Floors Above Foundation", url:'/floors-above', type:"FloorsAbove" },
    { name:"Floors Added to Slab", url:'/floors-added', type:"FloorsAdded" },
    { name:"Lintel", url:'/lintel', type:"Lintel" },
    { name:"Wall", url:'/wall', type:"Wall" },
    { name:"Window", url:'/window', type:"Window" },
];

const RValueToggle = ({ rValueUnits, handleRValueToggle }) => {
    return (
        <div className={classes.rValueToggle}>
            <span>R-Value Display</span>
            <ToggleMulti
                className={classes.toggle}
                active={rValueUnits}
                options={[
                    {
                        id:"R",
                        label:"R",
                        onClick:()=>handleRValueToggle("R"),
                        className:classes.toggleOption
                    },
                    {
                        id:"RSI",
                        label:"RSI",
                        onClick:()=>handleRValueToggle("RSI"),
                        className:classes.toggleOption
                    }
                ]}
            />
        </div>
    )
}

export const CodeLibrary = ({ history, fetchUserCodeLib, uid, saveUserMeta, rValueUnits }) => {
    const [ intialPath, initialTab ] = window.location.pathname.split("code-library");

    const handleUpload = useCallback((data) => {
        if (!data) {
            return;
        }
        fetchUserCodeLib(uid);
    },[uid, fetchUserCodeLib]);

    const handleRValueToggle = useCallback((units) => {
        saveUserMeta(uid, { rValueUnits:units });
    },[saveUserMeta, uid]);

    useEffect(() => {
        fetchUserCodeLib(uid);
    },[uid, fetchUserCodeLib])

    return (
        <div>
            <SectionHeading
                title="Code Library"
                subtitle="Manage your standard and user defined codes."
                sideContent={
                    <div className={classes.sideContent}>
                        <InputCodeLib
                            name="CodeLibInput"
                            id="CodeLibInput"
                            label="Upload Code Library"
                            accept=".cod,.xml"
                            handleUpload={handleUpload}
                        /> 
                        <RValueToggle rValueUnits={rValueUnits} handleRValueToggle={handleRValueToggle} />
                    </div>
                }
            />
            <div className={classes.contentWrapper}>
                <VerticalTabs
                    title="Component Type"
                    tabNavClassName={classes.tabNav}
                    initialTab={initialTab || '/'}
                    tabActive={initialTab || '/'}
                    tabs={tabs.map(({ name, url }) => ({ name, url }))}
                    onChange={(e) => {
                        history.push(`/code-library${e}`);
                    }}
                >
                    {tabs.map(({ name, type }) => <CodeList name={name} type={type} rValueUnits={rValueUnits} />)}
                </VerticalTabs>
            </div>
        </div>
    )
};

export default CodeLibrary;