import { createSelector } from "reselect";

const selectNewDrawing = (state) => state.drawing;

export const selectNewDrawingStage = createSelector([selectNewDrawing], (drawing) => drawing.stage);

export const selectMessage = createSelector([selectNewDrawing], (drawing) => drawing.message);

export const selectActiveTool = createSelector([selectNewDrawing], (drawing) => drawing.activeTool);

export const selectActiveImageIndex = createSelector([selectNewDrawing], (drawing) => drawing.activeImageIndex);

export const selectImageData = createSelector([selectNewDrawing], (drawing) => drawing.imageData);

export const selectCurrentAction = createSelector([selectNewDrawing], (drawing) => drawing.action);

export const selectActiveComponent = createSelector([selectNewDrawing], (drawing) => drawing.activeComponent);

export const selectDidUserSawNewDrawing = createSelector([selectNewDrawing], (drawing) => drawing.didUserSawNewDrawing);

export const selectIsCopyScaleOpen = createSelector([selectNewDrawing], (drawing) => drawing.isCopyScaleOpen);

export const selectProcessingImages = createSelector([selectNewDrawing], (drawing) => drawing.processingImages);
