import React from "react";
import withTakeoffFoundation from "./withTakeoffFoundation";

import Tinput from "../../components/TSelect/Tinput";
import Checkbox from "components/Input/Checkbox";

import { getFoundationInputs } from "./utils/foundInputs";
import { getFoundationName } from "../../TakeoffData/FondationOptions";
import { getBaseUnits, getUnitOptions } from "utils/fields";

import TCompCard from "../../components/TCompCard/TCompCard";
import AddButton from "../../components/AddButton/AddButton";
import subComponentsList, { slabComponentList } from "./utils/FoundationSubcomponentsList";
import TTable from "../../TTable/TTable";
import AddBox from "components/AddBox";

import convertUnit from "utils/convertUnit";

const TakeoffFoundation = ({
    takeoffState,
    changeFoundationValue,
    getFValidations,
    handleError,
    removeSubComponent,
    addSubComponent,
    editSubComponent,
    duplicateSubComponent,
    addTableComponent,
    removeTableComponent,
    editTableComponent,
    translateComponent,
    errors,
    modelUnits,
    openWindowsDrawer,
}) => {
    const { foundation } = takeoffState;

    return (
        <div className="foundation-component-div">
            <span className="sub-header">{getFoundationName(foundation.compType)} Measurements</span>
            <div className="foundation-input-rows">
                {getFoundationInputs(foundation.compType, foundation, modelUnits).map((input, id) => {
                    if (input.type === "divider") {
                        return <div style={{ width: 1400, height: 30 }}> </div>;
                    } else {
                        return (
                            <Tinput
                                className={"found-input"}
                                key={`found-input-${id}`}
                                label={input.label}
                                type={input.type}
                                property={input.property}
                                decimals={input.decimals}
                                validation={getFValidations}
                                handleError={(err) => handleError(input.property, err)}
                                disabled={input.disabled}
                                input={{
                                    value: input.stateValue,
                                    onChange: (e) => changeFoundationValue(input.property, e, input.stateUnit),
                                }}
                                units={{
                                    ...input.units,
                                    base: getBaseUnits(input.fieldKey, modelUnits),
                                    // selected: getBaseUnits(
                                    //     input.fieldKey,
                                    //     modelUnits
                                    // ).displayBase,
                                }}
                                // units={input.units}
                                onUnitChange={(value, unit) => {
                                    changeFoundationValue(input.property, value, unit);
                                }}
                            />
                        );
                    }
                })}
                {foundation.compType.toLowerCase() === "crawlspace" && (
                    <Checkbox
                        style={{ alignSelf: "flex-end" }}
                        large
                        label="Heated Volume"
                        input={{
                            value: foundation?.includeCrawlspaceVolume?.value,
                            onChange: () =>
                                changeFoundationValue(
                                    "includeCrawlspaceVolume",
                                    foundation?.includeCrawlspaceVolume
                                        ? !foundation?.includeCrawlspaceVolume.value
                                        : true,
                                    ""
                                ),
                        }}
                    />
                )}
            </div>
            <div className="foundation-subcomponents-div">
                {foundation.components.map((comp, index) => {
                    return (
                        <TCompCard
                            component={comp}
                            key={`foundation-${index}`}
                            index={index}
                            onDelete={removeSubComponent}
                            onDuplicate={duplicateSubComponent}
                            onError={handleError}
                            onChange={editSubComponent}
                            compList={foundation.components}
                            modelUnits={modelUnits}
                        />
                    );
                })}
                <AddButton
                    optionsList={
                        ["slab", "crawlspace"].includes(foundation.compType.toLowerCase())
                            ? slabComponentList
                            : subComponentsList
                    }
                    onSelect={addSubComponent}
                    componentType={"foundation"}
                    componentSubtype={null}
                    translateComponent={translateComponent}
                    errors={errors}
                />
            </div>
            <TTable
                dataset={foundation.tableComponents}
                addTableComponent={addTableComponent}
                removeTableComponent={removeTableComponent}
                editTableComponent={editTableComponent}
                modelUnits={modelUnits}
            />
            <div style={{ width: "100%" }}>
                <AddBox onClick={openWindowsDrawer} title="Window Table Inputs" icon="" />
            </div>

            <style>
                {`
        .sub-header {
          font-weight: 400;
          font-size: 14px;
          margin-bottom: 20px;
        }
        
        .foundation-component-div {
          width: 100%;
          display: flex;
          flex-flow: row wrap;
          width: 100%;
        }

        .foundation-input-rows, .foundation-components {
          display: grid;
          align-items: center;
          grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
          gap: 20px;
          width: 100%;
        }
        
        .foundation-subcomponents-div {
          margin-top: 35px;
          display: flex;
          gap: 15px;
          flex-flow: row wrap;
        }

        .bottomCheckBox {
            align-self: flex-end;
        }
      `}
            </style>
        </div>
    );
};

export default withTakeoffFoundation(TakeoffFoundation);
