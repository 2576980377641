import React, { forwardRef } from "react";
import Alert from "@material-ui/lab/Alert";
import { Snackbar, SnackbarContent, Grow, Slide } from "@material-ui/core";

const Content = forwardRef(({ message, elevation, variant, severity, ...props }, ref) => (
    <SnackbarContent
        ref={ref}
        style={{
            backgroundColor: "transparent",
            boxShadow: "none",
        }}
        message={
            message ? (
                <Alert elevation={elevation} variant={variant} severity={severity} {...props}>
                    {message}
                </Alert>
            ) : null
        }
    />
));

const LeftSlideTransition = (props) => <Slide {...props} direction="left" />;
const UpSlideTransition = (props) => <Slide {...props} direction="up" />;
const DownSlideTransition = (props) => <Slide {...props} direction="down" />;

const directionAnim = {
    left: LeftSlideTransition,
    up: UpSlideTransition,
    down: DownSlideTransition,
};

const SnackbarAlert = ({
    open,
    autoHideDuration,
    onClose,
    onExited,
    anchorOrigin = { vertical: "bottom", horizontal: "center" },
    elevation = 6,
    variant = "filled",
    message,
    severity = "info",
    direction = "left",
    top,
    ...props
}) => {
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        onClose();
    };

    const handleExited = () => {
        onExited();
    };

    return message ? (
        <Snackbar
            open={open}
            anchorOrigin={anchorOrigin}
            autoHideDuration={autoHideDuration}
            onClose={handleClose}
            TransitionComponent={directionAnim[direction]}
            TransitionProps={{ onExited: handleExited }}
            style={{ pointerEvents: "none", position: "fixed", transform: "unset", top: top, zIndex: 9999 }}
        >
            <Content elevation={elevation} variant={variant} severity={severity} message={message} />
        </Snackbar>
    ) : null;
};

export default SnackbarAlert;
