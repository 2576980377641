import { connect } from "react-redux";
import { actions } from "store/users";
import { reduxForm } from "redux-form";
import Preferences from "./";

const { addUserPreferences } = actions;

const mapStateToProps = ({
    user: {
        uid,
        name = "",
        // email='', //field would have to be disabled
        userMeta: { company = "", role = "", experienceLevel = "", agreedToTerms = false } = {},
        userPreferences: {
            userPhone = "",
            eaId = "",
            companyPhone = "",
            province = "",
            preferredUnits = "",
            preferredProgramType = "",
            companyAddress = "",
            companyCity = "",
            companyPostalCode = "",
            companyFax = "",
        } = {},
    },
}) => {
    const nameArray = name ? name.split(" ") : [];
    const initialValues = name
        ? {
              firstName: nameArray[0],
              lastName: nameArray[1],
              userPhone,
              eaId,
              company,
              companyPhone,
              province,
              preferredUnits,
              preferredProgramType,
              companyAddress,
              companyCity,
              companyPostalCode,
              companyFax,
          }
        : {};

    return {
        uid,
        initialValues: {
            ...initialValues,
        },
    };
};

const mapDispatchToProps = () => ({});

const mergeProps = (state, dispatch, own) => ({
    ...state,
    ...dispatch,
    ...own,
});

const onSubmit = async (form, dispatch, { uid }) => {
    const {
        firstName = "",
        lastName = "",
        company = "",
        userPhone = "",
        eaId = "",
        companyPhone = "",
        province = "",
        preferredUnits = "",
        preferredProgramType = "",
        companyAddress = "",
        companyCity = "",
        companyPostalCode = "",
        companyFax = "",
    } = form;

    const userMeta = {
        company,
    };

    const userPreferences = {
        userPhone,
        eaId,
        companyPhone,
        province,
        preferredUnits,
        preferredProgramType,
        companyAddress,
        companyCity,
        companyPostalCode,
        companyFax,
    };

    const name = `${firstName} ${lastName}`;

    return dispatch(addUserPreferences({ uid, name, userMeta, userPreferences }));
};

const form = reduxForm({
    form: "userPreferences",
    enableReinitialize: true,
    onSubmit,
})(Preferences);

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(form);
