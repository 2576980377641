import React, { useState } from 'react';
import classes from './style.module.scss';
import sharedClasses from '../style.module.scss';
import moment from 'moment';
import Calendar from 'assets/images/icons/JSX/calendar';
import DayPicker, { LocaleUtils } from 'react-day-picker';
import { useOutsideClickHook } from 'utils/outsideClick';
import InfoTooltip from 'components/InfoTooltip';

const formatWeekdayShort = day => ({
    0:'s',
    1:'m',
    2:'t',
    3:'w',
    4:'t',
    5:'f',
    6:'s'
}[day]);

export default ({
    name, 
    label, 
    input,
    className, 
    large,
    hideLabel,
    date,
    formatting,
    info='',
    disabled=false,
    disabledDays={ before:null, after: new Date() },
}) => {
    const classNames = [
        classes.dayPicker,
        sharedClasses.inputField,
        className && className,
        large && classes.large,
    ].filter(c => c);

    const { value, onChange } = input;
    const [open, toggleOpen] = useState(false);
    const [selected, updateDate] = useState(new Date(moment(value).format('YYYY, MM, DD')));
    const ref = useOutsideClickHook(() => toggleOpen(false));

    return (
        <div ref={ref} className={classNames.join(' ')}>
            <label className={hideLabel && 'invisible'} htmlFor={name}>{label}</label>
            <div
                className={`${sharedClasses.dayPickerInput} ${classes.value} ${open && classes.open} ${disabled && `${classes.disabled} ${sharedClasses.disabled}`}`}
                onClick={() => !disabled && toggleOpen(!open)}
            >{moment(value).format('YYYY-MM-DD')} <Calendar fillClass={classes.calendarFill} /></div>
            {
                info && <InfoTooltip className={sharedClasses.infoTooltip} info={info} />
            }
            <DayPicker
                className={`${classes.dayPickerBox} ${open && classes.open}`}
                initialMonth={date || new Date()}
                localeUtils={ { ...LocaleUtils, formatWeekdayShort } }
                selectedDays={selected}
                disabledDays={disabledDays}
                modifiersStyles={{
                    today:{
                        fontWeight:'900',
                    },
                    daysWithAnomalies: {
                        cursor:'pointer'
                    },
                    disabled: {
                        color: '#BCCFDC'
                    }
                }}
                onDayClick={(day, modifiers={}) => {
                    if (modifiers.disabled) {
                        return;
                    }
                    onChange(moment(day).format(formatting));
                    updateDate(day);
                    toggleOpen(false);
                }}
            />
        </div>
    )
}