import BCCB_4 from "assets/images/foundation/Foundation Insulation - BCCB_4.png";
import BCCB_8 from "assets/images/foundation/Foundation Insulation - BCCB_8.png";
import BBEB_1 from "assets/images/foundation/Foundation Insulation - BBEB_1.png";
import BBEB_2 from "assets/images/foundation/Foundation Insulation - BBEB_2.png";
import BBEB_3 from "assets/images/foundation/Foundation Insulation - BBEB_3.png";
import BBEB_4 from "assets/images/foundation/Foundation Insulation - BBEB_4.png";
import BBEB_5 from "assets/images/foundation/Foundation Insulation - BBEB_5.png";
import BBEN_1 from "assets/images/foundation/Foundation Insulation - BBEN_1.png";
import BBEN_2 from "assets/images/foundation/Foundation Insulation - BBEN_2.png";
import BBIA_1 from "assets/images/foundation/Foundation Insulation - BBIA_1.png";
import BBIA_2 from "assets/images/foundation/Foundation Insulation - BBIA_2.png";
import BBIB_1 from "assets/images/foundation/Foundation Insulation - BBIB_1.png";
import BBIB_2 from "assets/images/foundation/Foundation Insulation - BBIB_2.png";
import BBIB_3 from "assets/images/foundation/Foundation Insulation - BBIB_3.png";
import BBIB_4 from "assets/images/foundation/Foundation Insulation - BBIB_4.png";
import BBIB_5 from "assets/images/foundation/Foundation Insulation - BBIB_5.png";
import BBIB_6 from "assets/images/foundation/Foundation Insulation - BBIB_6.png";
import BBIN_1 from "assets/images/foundation/Foundation Insulation - BBIN_1.png";
import BBIN_2 from "assets/images/foundation/Foundation Insulation - BBIN_2.png";
import BCCA_1 from "assets/images/foundation/Foundation Insulation - BCCA_1.png";
import BCCA_4 from "assets/images/foundation/Foundation Insulation - BCCA_4.png";
import BCCA_7 from "assets/images/foundation/Foundation Insulation - BCCA_7.png";
import BCCA_8 from "assets/images/foundation/Foundation Insulation - BCCA_8.png";
import BCCB_9 from "assets/images/foundation/Foundation Insulation - BCCB_9.png";
import BCCB_10 from "assets/images/foundation/Foundation Insulation - BCCB_10.png";
import BCCN_1 from "assets/images/foundation/Foundation Insulation - BCCN_1.png";
import BCCN_2 from "assets/images/foundation/Foundation Insulation - BCCN_2.png";
import BCCN_3 from "assets/images/foundation/Foundation Insulation - BCCN_3.png";
import BCCN_4 from "assets/images/foundation/Foundation Insulation - BCCN_4.png";
import BCCN_5 from "assets/images/foundation/Foundation Insulation - BCCN_5.png";
import BCEA_1 from "assets/images/foundation/Foundation Insulation - BCEA_1.png";
import BCEA_3 from "assets/images/foundation/Foundation Insulation - BCEA_3.png";
import BCEA_4 from "assets/images/foundation/Foundation Insulation - BCEA_4.png";
import BCEA_5 from "assets/images/foundation/Foundation Insulation - BCEA_5.png";
import BCEA_6 from "assets/images/foundation/Foundation Insulation - BCEA_6.png";
import BCEA_7 from "assets/images/foundation/Foundation Insulation - BCEA_7.png";
import BCEA_8 from "assets/images/foundation/Foundation Insulation - BCEA_8.png";
import BCEA_9 from "assets/images/foundation/Foundation Insulation - BCEA_9.png";
import BCEA_10 from "assets/images/foundation/Foundation Insulation - BCEA_10.png";
import BCEA_11 from "assets/images/foundation/Foundation Insulation - BCEA_11.png";
import BCEB_1 from "assets/images/foundation/Foundation Insulation - BCEB_1.png";
import BCEB_2 from "assets/images/foundation/Foundation Insulation - BCEB_2.png";
import BCEB_3 from "assets/images/foundation/Foundation Insulation - BCEB_3.png";
import BCEB_4 from "assets/images/foundation/Foundation Insulation - BCEB_4.png";
import BCEB_5 from "assets/images/foundation/Foundation Insulation - BCEB_5.png";
import BCEB_6 from "assets/images/foundation/Foundation Insulation - BCEB_6.png";
import BCEB_8 from "assets/images/foundation/Foundation Insulation - BCEB_8.png";
import BCEB_9 from "assets/images/foundation/Foundation Insulation - BCEB_9.png";
import BCEB_11 from "assets/images/foundation/Foundation Insulation - BCEB_11.png";
import BCEB_10 from "assets/images/foundation/Foundation Insulation - BCEB_10.png";
import BCEN_1 from "assets/images/foundation/Foundation Insulation - BCEN_1.png";
import BCEN_2 from "assets/images/foundation/Foundation Insulation - BCEN_2.png";
import BCEN_3 from "assets/images/foundation/Foundation Insulation - BCEN_3.png";
import BCEN_4 from "assets/images/foundation/Foundation Insulation - BCEN_4.png";
import BCEN_5 from "assets/images/foundation/Foundation Insulation - BCEN_5.png";
import BCIA_1 from "assets/images/foundation/Foundation Insulation - BCIA_1.png";
import BCIA_3 from "assets/images/foundation/Foundation Insulation - BCIA_3.png";
import BCIA_4 from "assets/images/foundation/Foundation Insulation - BCIA_4.png";
import BCIA_5 from "assets/images/foundation/Foundation Insulation - BCIA_5.png";
import BCIA_6 from "assets/images/foundation/Foundation Insulation - BCIA_6.png";
import BCIB_1 from "assets/images/foundation/Foundation Insulation - BCIB_1.png";
import BCIB_2 from "assets/images/foundation/Foundation Insulation - BCIB_2.png";
import BCIB_3 from "assets/images/foundation/Foundation Insulation - BCIB_3.png";
import BCIB_4 from "assets/images/foundation/Foundation Insulation - BCIB_4.png";
import BCIB_5 from "assets/images/foundation/Foundation Insulation - BCIB_5.png";
import BCIN_1 from "assets/images/foundation/Foundation Insulation - BCIN_1.png";
import BCIN_2 from "assets/images/foundation/Foundation Insulation - BCIN_2.png";
import BCIN_3 from "assets/images/foundation/Foundation Insulation - BCIN_3.png";
import BCIN_4 from "assets/images/foundation/Foundation Insulation - BCIN_4.png";
import BCNN_1 from "assets/images/foundation/Foundation Insulation - BCNN_1.png";
import BCNN_2 from "assets/images/foundation/Foundation Insulation - BCNN_2.png";
import BWEB_1 from "assets/images/foundation/Foundation Insulation - BWEB_1.png";
import BWEB_2 from "assets/images/foundation/Foundation Insulation - BWEB_2.png";
import BWEB_3 from "assets/images/foundation/Foundation Insulation - BWEB_3.png";
import BWEB_4 from "assets/images/foundation/Foundation Insulation - BWEB_4.png";
import BWEN_1 from "assets/images/foundation/Foundation Insulation - BWEN_1.png";
import BWEN_2 from "assets/images/foundation/Foundation Insulation - BWEN_2.png";
import BWEN_3 from "assets/images/foundation/Foundation Insulation - BWEN_3.png";
import BWIA_1 from "assets/images/foundation/Foundation Insulation - BWIA_1.png";
import BWIA_2 from "assets/images/foundation/Foundation Insulation - BWIA_2.png";
import BWIA_3 from "assets/images/foundation/Foundation Insulation - BWIA_3.png";
import BWIB_1 from "assets/images/foundation/Foundation Insulation - BWIB_1.png";
import BWIB_2 from "assets/images/foundation/Foundation Insulation - BWIB_2.png";
import BWIB_4 from "assets/images/foundation/Foundation Insulation - BWIB_4.png";
import BWIN_1 from "assets/images/foundation/Foundation Insulation - BWIN_1.png";
import BWIN_2 from "assets/images/foundation/Foundation Insulation - BWIN_2.png";
import BWIN_3 from "assets/images/foundation/Foundation Insulation - BWEB_1.png";
import BWNN_1 from "assets/images/foundation/Foundation Insulation - BWNN_1.png";
import SCA_1 from "assets/images/foundation/Foundation Insulation - SCA_1.png";
import SCA_6 from "assets/images/foundation/Foundation Insulation - SCA_6.png";
import SCA_17 from "assets/images/foundation/Foundation Insulation - SCA_17.png";
import SCA_18 from "assets/images/foundation/Foundation Insulation - SCA_18.png";
import SCA_19 from "assets/images/foundation/Foundation Insulation - SCA_19.png";
import SCA_20 from "assets/images/foundation/Foundation Insulation - SCA_20.png";
import SCB_5 from "assets/images/foundation/Foundation Insulation - SCB_5.png";

export const getInsulationThumbnail = (id) =>
    ({
        BCCB_4,
        BCCB_8,
        BBEB_1,
        BBEB_2,
        BBEB_3,
        BBEB_4,
        BBEB_5,
        BCEB_6,
        BCEB_11,
        BBEN_1,
        BBEN_2,
        BBIA_1,
        BBIA_2,
        BBIB_1,
        BBIB_2,
        BBIB_3,
        BBIB_4,
        BBIB_5,
        BBIB_6,
        BBIN_1,
        BBIN_2,
        BCCA_1,
        BCCA_4,
        BCCA_7,
        BCCA_8,
        BCCB_9,
        BCCB_10,
        BCCN_1,
        BCCN_2,
        BCCN_3,
        BCCN_4,
        BCCN_5,
        BCEA_1,
        BCEA_3,
        BCEA_4,
        BCEA_5,
        BCEA_6,
        BCEA_7,
        BCEA_8,
        BCEA_9,
        BCEA_10,
        BCEA_11,
        BCEB_1,
        BCEB_2,
        BCEB_3,
        BCEB_4,
        BCEB_5,
        BCEB_8,
        BCEB_9,
        BCEB_10,
        BCEN_1,
        BCEN_2,
        BCEN_3,
        BCEN_4,
        BCEN_5,
        BCIA_1,
        BCIA_3,
        BCIA_4,
        BCIA_5,
        BCIA_6,
        BCIB_1,
        BCIB_2,
        BCIB_3,
        BCIB_4,
        BCIB_5,
        BCIN_1,
        BCIN_2,
        BCIN_3,
        BCIN_4,
        BCNN_1,
        BCNN_2,
        BWEB_1,
        BWEB_2,
        BWEB_3,
        BWEB_4,
        BWEN_1,
        BWEN_2,
        BWEN_3,
        BWIA_1,
        BWIA_2,
        BWIA_3,
        BWIB_1,
        BWIB_2,
        BWIB_4,
        BWIN_1,
        BWIN_2,
        BWIN_3,
        BWNN_1,
        SCA_1,
        SCA_6,
        SCA_17,
        SCA_18,
        SCA_19,
        SCA_20,
        SCB_5,
    }[id]);

export const getDefaultCompositeObj = (value) => {
    return {
        section0: {
            section: 1,
            percentage: 100,
            rsi: value,
            nominalRsi: value,
        },
        section1: {
            section: 2,
            percentage: 0,
            rsi: 0,
            nominalRsi: 0,
        },
        section2: {
            section: 3,
            percentage: 0,
            rsi: 0,
            nominalRsi: 0,
        },
        section3: {
            section: 4,
            percentage: 0,
            rsi: 0,
            nominalRsi: 0,
        },
        effRVal: value,
    };
};
