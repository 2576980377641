import React from "react";

import StatusRadio from "features/Model/ImageGallery/components/ImageView/components/Status/StatusRadio";

import styles from "./styles.module.scss";

const Status = ({ status, imageId, setImages, allImages }) => {
    const updateStatus = (newStatus) => {
        setImages(
            allImages.map((img) =>
                img.id === imageId
                    ? {
                          ...img,
                          status: newStatus,
                      }
                    : img
            )
        );
    };

    return (
        <div className={styles.statusContainer}>
            <span className={styles.statusContainerTitle}>Status</span>
            <div className={styles.statusesWrapper}>
                <StatusRadio
                    label={"Pre-construction"}
                    checked={status === "pre_construction"}
                    onChange={() => updateStatus("pre_construction")}
                    inputProps={{
                        name: imageId,
                        value: "pre_construction",
                    }}
                />
                <StatusRadio
                    label={"Mid-construction"}
                    checked={status === "mid_construction"}
                    onChange={() => updateStatus("mid_construction")}
                    inputProps={{
                        name: imageId,
                        value: "mid_construction",
                    }}
                />
                <StatusRadio
                    label={"Post-construction"}
                    checked={status === "post_construction"}
                    onChange={() => updateStatus("post_construction")}
                    inputProps={{
                        name: imageId,
                        value: "post_construction",
                    }}
                />
            </div>
        </div>
    );
};

export default Status;
