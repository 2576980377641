import { storageRef, storage, firestore, MODEL_COLL, USERS_COLL } from "_firebase";

import {
    savingDrawingStart,
    savingDrawingSuccess,
    savingDrawingFailure,
    uploadingDrawingStart,
    fetchDrawingDataError,
    fetchDrawingDataSuccess,
    fetchDrawingDataStart,
} from "./actions";

import { setFileUploadStatus, fetchModelFiles } from "features/Model/_ducks/actions";

import { convertPdfToImage } from "utils/drawing/api";
import convertUnit from "utils/convertUnit";
import {
    floorAreas,
    foundationTypes,
    storeysTypes,
    changeFoundationValue,
    changeStoreyValue,
    addTakeoffSubComponent,
    addTableComponent,
    editTableComponent,
    editSubComponent,
    addSubComponent,
} from "./utils";
import { mergeObjects } from "utils/objects";

export const saveDrawingData =
    (modelId, updateTakeoff = false) =>
    async (dispatch, getState) => {
        dispatch(savingDrawingStart());
        try {
            const { imageData, didUserSawNewDrawing, imageDataSnapshot, imageDataToCompare } = getState().drawing;
            const { modelTakeoff } = getState().model;
            const { uid } = getState().user;

            const imageDataArr = Object.keys(imageData);

            if (imageDataArr.length > 0 && updateTakeoff) {
                for (let i = 0; i < imageDataArr.length; i++) {
                    const currentImgData = imageData[imageDataArr[i]];

                    let foundationShapes = [];
                    let storeysShapes = [];

                    const {
                        scale = {},
                        polygons = {},
                        rectangles = {},
                        multiPointLines = {},
                        lines = {},
                    } = currentImgData;

                    const { px, input = 0, units = "m", displayUnits = "m" } = scale || {};

                    const conversionFactor = input / px;

                    const polygonsKeys = Object.keys(polygons);
                    const rectangleKeys = Object.keys(rectangles);
                    const multiPointLinesKeys = Object.keys(multiPointLines);
                    const linesKeys = Object.keys(lines);

                    if (polygonsKeys.length > 0) {
                        const polysFoundation = polygonsKeys
                            .filter((key) => foundationTypes.includes(polygons[key].takeoff?.foundationStorey))
                            .map((key) => polygons[key]);
                        const polysStoreys = polygonsKeys
                            .filter((key) => storeysTypes.includes(polygons[key].takeoff?.foundationStorey))
                            .map((key) => polygons[key]);

                        foundationShapes = [...foundationShapes, ...polysFoundation];
                        storeysShapes = [...storeysShapes, ...polysStoreys];
                    }

                    if (rectangleKeys.length > 0) {
                        const rectsFoundation = rectangleKeys
                            .filter(
                                (key) =>
                                    foundationTypes.includes(rectangles[key].takeoff?.foundationStorey) &&
                                    rectangles[key]
                            )
                            .map((key) => rectangles[key]);
                        const rectsStoreys = rectangleKeys
                            .filter(
                                (key) =>
                                    storeysTypes.includes(rectangles[key].takeoff?.foundationStorey) && rectangles[key]
                            )
                            .map((key) => rectangles[key]);

                        foundationShapes = [...foundationShapes, ...rectsFoundation];
                        storeysShapes = [...storeysShapes, ...rectsStoreys];
                    }

                    if (multiPointLinesKeys.length > 0) {
                        const multiFoundation = multiPointLinesKeys
                            .filter(
                                (key) =>
                                    foundationTypes.includes(multiPointLines[key].takeoff?.foundationStorey) &&
                                    multiPointLines[key]
                            )
                            .map((key) => multiPointLines[key]);
                        const multiStoreys = multiPointLinesKeys
                            .filter(
                                (key) =>
                                    storeysTypes.includes(multiPointLines[key].takeoff?.foundationStorey) &&
                                    multiPointLines[key]
                            )
                            .map((key) => multiPointLines[key]);

                        foundationShapes = [...foundationShapes, ...multiFoundation];
                        storeysShapes = [...storeysShapes, ...multiStoreys];
                    }

                    if (linesKeys.length > 0) {
                        const linesFoundation = linesKeys
                            .filter(
                                (key) => foundationTypes.includes(lines[key].takeoff?.foundationStorey) && lines[key]
                            )
                            .map((key) => lines[key]);
                        const linesStoreys = linesKeys
                            .filter((key) => storeysTypes.includes(lines[key].takeoff?.foundationStorey) && lines[key])
                            .map((key) => lines[key]);

                        foundationShapes = [...foundationShapes, ...linesFoundation];
                        storeysShapes = [...storeysShapes, ...linesStoreys];
                    }

                    if (foundationShapes.length > 0) {
                        let totalFloorArea = 0;
                        let totalFloorPerimeter = 0;
                        let totalFloorHeaderHeight = 0;
                        let totalWallHeight = 0;
                        let totalAGWallHeight = 0;
                        let totalBGWallDepth = 0;
                        let totalSharedWallPerimeter = 0;

                        const componentIds =
                            modelTakeoff?.foundation?.components?.length > 0
                                ? modelTakeoff.foundation.components.map((comp) => comp.id)
                                : [];
                        const tableComponentIds =
                            modelTakeoff?.foundation?.tableComponents?.length > 0
                                ? modelTakeoff.foundation.tableComponents.map((comp) => comp.id)
                                : [];

                        for (let shape of foundationShapes) {
                            if (shape.shape === "polygon") {
                                const { area, perimeter, takeoff } = shape;

                                const { takeoffField, window, id } = takeoff;

                                const convertedPerimeter = convertUnit({
                                    value: perimeter * conversionFactor,
                                    type: "length",
                                    inputUnit: "m",
                                    // outputUnit: displayUnits,
                                    outputUnit: "m",
                                });

                                const convertedArea = convertUnit({
                                    value: area * Math.pow(conversionFactor, 2),
                                    type: "area",
                                    inputUnit: "m2",
                                    // outputUnit: `${displayUnits}2`,
                                    outputUnit: "m2",
                                });

                                const polyWidthHeightLength = Math.sqrt(convertedArea);

                                if (floorAreas.includes(takeoffField)) {
                                    totalFloorArea += convertedArea;
                                    totalFloorPerimeter += convertedPerimeter;
                                }

                                if (takeoffField === "floor header height")
                                    totalFloorHeaderHeight += polyWidthHeightLength;

                                if (takeoffField === "pony wall (elevation)") {
                                    const existingPonyWall = modelTakeoff.foundation.components.find(
                                        (el) => el.id === id
                                    );

                                    if (existingPonyWall) {
                                        for (let p = 0; p < existingPonyWall.fields.length; p++) {
                                            const { field } = existingPonyWall.fields[p];

                                            editSubComponent(
                                                dispatch,
                                                existingPonyWall.id,
                                                "foundation",
                                                field,
                                                field === "height1" || field === "height2"
                                                    ? polyWidthHeightLength
                                                    : convertedPerimeter,
                                                displayUnits
                                            );
                                        }
                                    }

                                    if (!componentIds.includes(id)) {
                                        addTakeoffSubComponent(dispatch, "foundation", "ponywall", {
                                            height1: polyWidthHeightLength,
                                            height2: polyWidthHeightLength,
                                            perimeter: convertedPerimeter,
                                            id,
                                            unit: displayUnits,
                                        });
                                    }
                                }

                                if (takeoffField === "window") {
                                    const {
                                        title,
                                        ovHeightUnits = displayUnits === "m" ? "mm" : "ft",
                                        ovHeight = 0,
                                        ovWidthUnits = 0,
                                        ovWidth = 0,
                                        quantity = 1,
                                    } = window;

                                    // if (ovHeight === 0 || ovWidth === 0 || quantity === 0) continue;

                                    const windowDisplayUnits = displayUnits === "m" ? "mm" : "ft";

                                    // console.log("Inside window", existingWindow);

                                    const existingWindow = modelTakeoff.foundation.tableComponents.find(
                                        (el) => el.id === id
                                    );

                                    if (!existingWindow) {
                                        const existingWindowFields = Object.keys(existingWindow);

                                        for (let w = 0; w < existingWindowFields.length; w++) {
                                            if (existingWindowFields[w] === "id") continue;

                                            const newValue =
                                                existingWindowFields[w] === "height"
                                                    ? polyWidthHeightLength
                                                    : existingWindowFields[w] === "width"
                                                    ? polyWidthHeightLength
                                                    : existingWindowFields[w] === "ovHeight"
                                                    ? ovHeight
                                                    : existingWindowFields[w] === "ovWidth"
                                                    ? ovWidth
                                                    : existingWindowFields[w] === "title"
                                                    ? title
                                                    : quantity;

                                            const newUnit =
                                                existingWindowFields[w] === "height"
                                                    ? windowDisplayUnits
                                                    : existingWindowFields[w] === "width"
                                                    ? windowDisplayUnits
                                                    : existingWindowFields[w] === "ovHeight"
                                                    ? ovHeightUnits
                                                    : existingWindowFields[w] === "ovWidth"
                                                    ? ovWidthUnits
                                                    : "";

                                            editTableComponent(
                                                dispatch,
                                                "foundation",
                                                existingWindow.id,
                                                existingWindowFields[w],
                                                newValue,
                                                newUnit
                                            );
                                        }
                                    }

                                    if (!tableComponentIds.includes(id))
                                        addTableComponent(dispatch, "foundation", {
                                            title: { value: title },
                                            width: {
                                                value: polyWidthHeightLength,
                                                unit: windowDisplayUnits,
                                            },
                                            height: {
                                                value: polyWidthHeightLength,
                                                unit: windowDisplayUnits,
                                            },
                                            ovWidth: {
                                                value: ovWidth,
                                                unit: ovWidthUnits,
                                            },
                                            ovHeight: {
                                                value: ovHeight,
                                                unit: ovHeightUnits,
                                            },
                                            qty: { value: quantity },
                                            id,
                                        });
                                }
                            }

                            if (shape.shape === "rectangle") {
                                const { area, perimeter, takeoff, height, width } = shape;

                                const { takeoffField, window, id } = takeoff;

                                const convertedHeight = convertUnit({
                                    value: height * conversionFactor,
                                    type: "drawingScale",
                                    inputUnit: "m",
                                    outputUnit: "m",
                                    // outputUnit: displayUnits,
                                });

                                const convertedWidth = convertUnit({
                                    value: width * conversionFactor,
                                    type: "drawingScale",
                                    inputUnit: "m",
                                    outputUnit: "m",
                                    // outputUnit: displayUnits,
                                });

                                const convertedPerimeter = convertUnit({
                                    value: perimeter * conversionFactor,
                                    type: "length",
                                    inputUnit: "m",
                                    outputUnit: "m",
                                    // outputUnit: displayUnits,
                                });

                                const convertedArea = convertUnit({
                                    value: area * Math.pow(conversionFactor, 2),
                                    type: "area",
                                    inputUnit: "m2",
                                    outputUnit: "m2",
                                    // outputUnit: `${displayUnits}2`,
                                });

                                if (floorAreas.includes(takeoffField)) {
                                    totalFloorArea += convertedArea;
                                    totalFloorPerimeter += convertedPerimeter;
                                }

                                if (takeoffField === "floor header height") totalFloorHeaderHeight += convertedHeight;

                                if (takeoffField === "pony wall (elevation)") {
                                    const existingPonyWall = modelTakeoff.foundation.components.find(
                                        (el) => el.id === id
                                    );

                                    if (existingPonyWall) {
                                        for (let p = 0; p < existingPonyWall.fields.length; p++) {
                                            const { field } = existingPonyWall.fields[p];

                                            editSubComponent(
                                                dispatch,
                                                existingPonyWall.id,
                                                "foundation",
                                                field,
                                                field === "height1" || field === "height2"
                                                    ? convertedHeight
                                                    : convertedPerimeter,
                                                displayUnits
                                            );
                                        }
                                    }

                                    if (!componentIds.includes(id)) {
                                        addTakeoffSubComponent(dispatch, "foundation", "ponywall", {
                                            height1: convertedHeight,
                                            height2: convertedHeight,
                                            perimeter: convertedPerimeter,
                                            id,
                                            unit: displayUnits,
                                        });
                                    }
                                }

                                if (takeoffField === "window") {
                                    const {
                                        title,
                                        ovHeightUnits = displayUnits === "m" ? "mm" : "ft",
                                        ovHeight = 0,
                                        ovWidthUnits = 0,
                                        ovWidth = 0,
                                        quantity = 1,
                                    } = window;

                                    // if (ovHeight === 0 || ovWidth === 0 || quantity === 0) continue;

                                    const windowDisplayUnits = displayUnits === "m" ? "mm" : "ft";

                                    const existingWindow = modelTakeoff.foundation.tableComponents.find(
                                        (el) => el.id === id
                                    );

                                    if (existingWindow) {
                                        const existingWindowFields = Object.keys(existingWindow);

                                        for (let w = 0; w < existingWindowFields.length; w++) {
                                            if (existingWindowFields[w] === "id") continue;

                                            const newValue =
                                                existingWindowFields[w] === "height"
                                                    ? convertedHeight
                                                    : existingWindowFields[w] === "width"
                                                    ? convertedWidth
                                                    : existingWindowFields[w] === "ovHeight"
                                                    ? ovHeight
                                                    : existingWindowFields[w] === "ovWidth"
                                                    ? ovWidth
                                                    : existingWindowFields[w] === "title"
                                                    ? title
                                                    : quantity;

                                            const newUnit =
                                                existingWindowFields[w] === "height"
                                                    ? windowDisplayUnits
                                                    : existingWindowFields[w] === "width"
                                                    ? windowDisplayUnits
                                                    : existingWindowFields[w] === "ovHeight"
                                                    ? ovHeightUnits
                                                    : existingWindowFields[w] === "ovWidth"
                                                    ? ovWidthUnits
                                                    : "";

                                            editTableComponent(
                                                dispatch,
                                                "foundation",
                                                existingWindow.id,
                                                existingWindowFields[w],
                                                newValue,
                                                newUnit
                                            );
                                        }
                                    }

                                    if (!tableComponentIds.includes(id))
                                        addTableComponent(dispatch, "foundation", {
                                            title: { value: title },
                                            width: {
                                                value: convertedWidth,
                                                unit: windowDisplayUnits,
                                            },
                                            height: {
                                                value: convertedHeight,
                                                unit: windowDisplayUnits,
                                            },
                                            ovWidth: {
                                                value: ovWidth,
                                                unit: ovWidthUnits,
                                            },
                                            ovHeight: {
                                                value: ovHeight,
                                                unit: ovHeightUnits,
                                            },
                                            qty: { value: quantity },
                                            id,
                                        });
                                }
                            }

                            if (shape.shape === "line") {
                                const { length, takeoff } = shape;

                                const { takeoffField } = takeoff;

                                const convertedLength = convertUnit({
                                    value: length * conversionFactor,
                                    type: "length",
                                    inputUnit: "m",
                                    outputUnit: "m",
                                    // outputUnit: displayUnits,
                                });

                                if (takeoffField === "total wall height") totalWallHeight += convertedLength;

                                if (takeoffField === "above grade height") totalAGWallHeight += convertedLength;

                                if (takeoffField === "below grade depth") totalBGWallDepth += convertedLength;

                                if (takeoffField === "int. (shared) wall perimeter")
                                    totalSharedWallPerimeter += convertedLength;
                            }

                            if (shape.shape === "multiPointLine") {
                                const { totalLength, takeoff, points } = shape;

                                const { id, takeoffField } = takeoff;

                                const convertedLength = convertUnit({
                                    value: totalLength * conversionFactor,
                                    type: "length",
                                    inputUnit: "m",
                                    outputUnit: "m",
                                    // outputUnit: displayUnits,
                                });

                                if (takeoffField === "int. (shared) wall perimeter")
                                    totalSharedWallPerimeter += convertedLength;

                                if (takeoffField === "garage wall") {
                                    const existingGarageWall = modelTakeoff.foundation.components.find(
                                        (el) => el.id === id
                                    );

                                    if (existingGarageWall) {
                                        for (let p = 0; p < existingGarageWall.fields.length; p++) {
                                            const { field } = existingGarageWall.fields[p];

                                            editSubComponent(
                                                dispatch,
                                                existingGarageWall.id,
                                                "foundation",
                                                field,
                                                field === "intPerimeter"
                                                    ? convertedLength
                                                    : field === "corners"
                                                    ? points.length / 2
                                                    : field.value,
                                                displayUnits
                                            );
                                        }
                                    }

                                    if (!componentIds.includes(id)) {
                                        addTakeoffSubComponent(dispatch, "foundation", "garagewall", {
                                            intPerimeter: convertedLength,
                                            corners: points.length / 2,
                                            id,
                                        });
                                    }
                                }
                            }
                        }
                        if (totalFloorArea > 0)
                            changeFoundationValue(
                                dispatch,
                                modelTakeoff.foundation.compType === "slab" ? "slabArea" : "area",
                                totalFloorArea,
                                `${displayUnits}2`
                            );

                        if (totalFloorPerimeter > 0)
                            changeFoundationValue(dispatch, "totalPerimeter", totalFloorPerimeter, displayUnits);

                        if (totalFloorHeaderHeight > 0)
                            changeFoundationValue(dispatch, "floorHeaderHeight", totalFloorHeaderHeight, displayUnits);

                        if (totalWallHeight > 0)
                            changeFoundationValue(dispatch, "totalWallHeight", totalWallHeight, displayUnits);

                        if (totalAGWallHeight > 0)
                            changeFoundationValue(dispatch, "agHeight", totalAGWallHeight, displayUnits);

                        if (totalBGWallDepth > 0)
                            changeFoundationValue(dispatch, "bgDepth", totalBGWallDepth, displayUnits);

                        if (totalSharedWallPerimeter > 0)
                            changeFoundationValue(
                                dispatch,
                                "interiorWallLength",
                                totalSharedWallPerimeter,
                                displayUnits
                            );
                    }

                    if (storeysShapes.length > 0) {
                        let sortedStoreysShapes = {};

                        for (let storeyShape of storeysShapes) {
                            const { takeoff } = storeyShape;
                            const { foundationStorey } = takeoff;

                            sortedStoreysShapes[foundationStorey] = sortedStoreysShapes[foundationStorey]
                                ? [...sortedStoreysShapes[foundationStorey], storeyShape]
                                : [storeyShape];
                        }

                        const sortedStoreysShapesKeys = Object.keys(sortedStoreysShapes);

                        for (let sortedKey of sortedStoreysShapesKeys) {
                            const shapes = sortedStoreysShapes[sortedKey];

                            let totalFloorArea = 0;
                            let totalFloorPerimeter = 0;
                            let totalWallHeight = 0;
                            let totalFloorHeaderHeight = 0;
                            let bufferedWallPerimeter = 0;

                            const componentIds =
                                modelTakeoff[sortedKey]?.components?.length > 0
                                    ? modelTakeoff[sortedKey]?.components.map((comp) => comp.id)
                                    : [];
                            const tableComponentIds =
                                modelTakeoff?.foundation?.tableComponents?.length > 0
                                    ? modelTakeoff[sortedKey]?.tableComponents.map((comp) => comp.id)
                                    : [];

                            for (let i = 0; i < shapes.length; i++) {
                                const shape = shapes[i];

                                if (shape.shape === "polygon") {
                                    const { area, perimeter, takeoff } = shape;

                                    const {
                                        takeoffField,
                                        window,
                                        id,
                                        ceilingHeightUnits = displayUnits,
                                        ceilingHeight,
                                        numGables,
                                    } = takeoff;

                                    const convertedPerimeter = convertUnit({
                                        value: perimeter * conversionFactor,
                                        type: "length",
                                        inputUnit: "m",
                                        outputUnit: "m",
                                        // outputUnit: displayUnits,
                                    });

                                    const convertedArea = convertUnit({
                                        value: area * Math.pow(conversionFactor, 2),
                                        type: "area",
                                        inputUnit: "m2",
                                        outputUnit: "m2",
                                        // outputUnit: `${displayUnits}2`,
                                    });
                                    const polyWidthHeightLength = Math.sqrt(convertedArea);

                                    if (takeoffField === "total floor area") totalFloorArea += convertedArea;

                                    if (takeoffField === "total floor perimeter")
                                        totalFloorPerimeter += convertedPerimeter;

                                    if (takeoffField === "total floor area & perimeter") {
                                        totalFloorArea += convertedArea;
                                        totalFloorPerimeter += convertedPerimeter;
                                    }

                                    if (takeoffField === "exposed floor") {
                                        const existingEf = modelTakeoff[sortedKey].components.find(
                                            (el) => el.id === id
                                        );

                                        if (existingEf) {
                                            for (let e = 0; e < existingEf.fields.length; e++) {
                                                const { field } = existingEf.fields[e];

                                                editSubComponent(
                                                    dispatch,
                                                    existingEf.id,
                                                    sortedKey,
                                                    field,
                                                    field === "length" ? polyWidthHeightLength : convertedArea,
                                                    field === "length" ? displayUnits : `${displayUnits}2`
                                                );
                                            }
                                        }

                                        if (!componentIds.includes(id)) {
                                            addSubComponent(dispatch, sortedKey, "exposedfloor", {
                                                id,
                                                length: polyWidthHeightLength,
                                                area: convertedArea,
                                                unit: displayUnits,
                                            });
                                        }
                                    }

                                    if (takeoffField === "window") {
                                        const {
                                            title,
                                            ovHeightUnits = displayUnits === "m" ? "mm" : "ft",
                                            ovHeight = 0,
                                            ovWidthUnits = 0,
                                            ovWidth = 0,
                                            quantity = 1,
                                        } = window;

                                        // if (ovHeight === 0 || ovWidth === 0 || quantity === 0) continue;

                                        const windowDisplayUnits = displayUnits === "m" ? "mm" : "ft";

                                        const existingWindow = modelTakeoff[sortedKey].tableComponents.find(
                                            (el) => el.id === id
                                        );

                                        if (existingWindow) {
                                            const existingWindowFields = Object.keys(existingWindow);

                                            for (let w = 0; w < existingWindowFields.length; w++) {
                                                if (existingWindowFields[w] === "id") continue;

                                                const newValue =
                                                    existingWindowFields[w] === "height"
                                                        ? polyWidthHeightLength
                                                        : existingWindowFields[w] === "width"
                                                        ? polyWidthHeightLength
                                                        : existingWindowFields[w] === "ovHeight"
                                                        ? ovHeight
                                                        : existingWindowFields[w] === "ovWidth"
                                                        ? ovWidth
                                                        : existingWindowFields[w] === "title"
                                                        ? title
                                                        : quantity;

                                                const newUnit =
                                                    existingWindowFields[w] === "height"
                                                        ? windowDisplayUnits
                                                        : existingWindowFields[w] === "width"
                                                        ? windowDisplayUnits
                                                        : existingWindowFields[w] === "ovHeight"
                                                        ? ovHeightUnits
                                                        : existingWindowFields[w] === "ovWidth"
                                                        ? ovWidthUnits
                                                        : "";

                                                editTableComponent(
                                                    dispatch,
                                                    sortedKey,
                                                    existingWindow.id,
                                                    existingWindowFields[w],
                                                    newValue,
                                                    newUnit
                                                );
                                            }
                                        }

                                        if (!tableComponentIds.includes(id))
                                            addTableComponent(dispatch, sortedKey, {
                                                title: { value: title },
                                                width: {
                                                    value: polyWidthHeightLength,
                                                    unit: windowDisplayUnits,
                                                },
                                                height: {
                                                    value: polyWidthHeightLength,
                                                    unit: windowDisplayUnits,
                                                },
                                                ovWidth: {
                                                    value: ovWidth,
                                                    unit: ovWidthUnits,
                                                },
                                                ovHeight: {
                                                    value: ovHeight,
                                                    unit: ovHeightUnits,
                                                },
                                                qty: { value: quantity },
                                                id,
                                            });
                                    }

                                    if (takeoffField === "attic ceiling (floor plan)") {
                                        const ceilingType = {
                                            "attic ceiling (floor plan)": "atticceiling",
                                            "cathedral ceiling (floor plan)": "cathedralceiling",
                                            "sloped ceiling (floor plan)": "slopedceiling",
                                            "raised ceiling (floor plan)": "raisedceiling",
                                        };

                                        const existingCeiling = modelTakeoff[sortedKey].components.find(
                                            (el) => el.id === id
                                        );

                                        //Assign here (width smaller) to avoid ambiguity, not used for attics
                                        const assignedWidth = Math.sqrt(convertedArea);
                                        const assignedLength = Math.sqrt(convertedArea);

                                        if (existingCeiling) {
                                            for (let e = 0; e < existingCeiling.fields.length; e++) {
                                                const { field } = existingCeiling.fields[e];

                                                editSubComponent(
                                                    dispatch,
                                                    existingCeiling.id,
                                                    sortedKey,
                                                    field,
                                                    field === "width"
                                                        ? assignedWidth
                                                        : field === "length"
                                                        ? assignedLength
                                                        : field === "height"
                                                        ? ceilingHeight
                                                        : field === "area"
                                                        ? convertedArea
                                                        : field === "eaveLength"
                                                        ? convertedPerimeter / 2
                                                        : field === "numGables"
                                                        ? numGables
                                                        : convertedPerimeter,
                                                    field === "area"
                                                        ? `${displayUnits}2`
                                                        : field === "height"
                                                        ? ceilingHeightUnits
                                                        : displayUnits
                                                );
                                            }
                                        }

                                        if (!componentIds.includes(id)) {
                                            addSubComponent(dispatch, sortedKey, ceilingType[takeoffField], {
                                                id,
                                                width: assignedWidth,
                                                length: assignedLength,
                                                eaveLength: convertedPerimeter / 2,
                                                height: ceilingHeight,
                                                area: convertedArea,
                                                perimeter: convertedPerimeter,
                                                unit: displayUnits,
                                                ceilingHeightUnits,
                                            });
                                        }
                                    }
                                }

                                if (shape.shape === "rectangle") {
                                    const { area, perimeter, takeoff, height, width } = shape;

                                    const {
                                        takeoffField,
                                        window,
                                        id,
                                        ceilingHeightUnits = displayUnits,
                                        ceilingHeight,
                                        numGables,
                                    } = takeoff;

                                    const convertedHeight = convertUnit({
                                        value: height * conversionFactor,
                                        type: "drawingScale",
                                        inputUnit: "m",
                                        outputUnit: "m",
                                        // outputUnit: displayUnits,
                                    });

                                    const convertedWidth = convertUnit({
                                        value: width * conversionFactor,
                                        type: "drawingScale",
                                        inputUnit: "m",
                                        outputUnit: "m",
                                        // outputUnit: displayUnits,
                                    });

                                    const convertedPerimeter = convertUnit({
                                        value: perimeter * conversionFactor,
                                        type: "length",
                                        inputUnit: "m",
                                        outputUnit: "m",
                                        // outputUnit: displayUnits,
                                    });

                                    const convertedArea = convertUnit({
                                        value: area * Math.pow(conversionFactor, 2),
                                        type: "area",
                                        inputUnit: "m2",
                                        outputUnit: "m2",
                                        // outputUnit: `${displayUnits}2`,
                                    });

                                    if (takeoffField === "total floor area") totalFloorArea += convertedArea;

                                    if (takeoffField === "total floor perimeter")
                                        totalFloorPerimeter += convertedPerimeter;

                                    if (takeoffField === "total floor area & perimeter") {
                                        totalFloorArea += convertedArea;
                                        totalFloorPerimeter += convertedPerimeter;
                                    }
                                    if (takeoffField === "total floor area & perimeter") {
                                        totalFloorArea += convertedArea;
                                        totalFloorPerimeter += convertedPerimeter;
                                    }

                                    if (takeoffField === "exposed floor") {
                                        const existingEf = modelTakeoff[sortedKey].components.find(
                                            (el) => el.id === id
                                        );

                                        if (existingEf) {
                                            for (let e = 0; e < existingEf.fields.length; e++) {
                                                const { field } = existingEf.fields[e];

                                                editSubComponent(
                                                    dispatch,
                                                    existingEf.id,
                                                    sortedKey,
                                                    field,
                                                    field === "length" ? convertedWidth : convertedArea,
                                                    displayUnits
                                                );
                                            }
                                        }

                                        if (!componentIds.includes(id)) {
                                            addSubComponent(dispatch, sortedKey, "exposedfloor", {
                                                id,
                                                length: convertedWidth,
                                                area: convertedArea,
                                                unit: displayUnits,
                                            });
                                        }
                                    }

                                    if (takeoffField === "window") {
                                        const {
                                            title,
                                            ovHeightUnits = displayUnits === "m" ? "mm" : "ft",
                                            ovHeight = 0,
                                            ovWidthUnits = 0,
                                            ovWidth = 0,
                                            quantity = 1,
                                        } = window;

                                        // if (ovHeight === 0 || ovWidth === 0 || quantity === 0) continue;

                                        const windowDisplayUnits = displayUnits === "m" ? "mm" : "ft";

                                        const existingWindow = modelTakeoff[sortedKey].tableComponents.find(
                                            (el) => el.id === id
                                        );

                                        if (existingWindow) {
                                            const existingWindowFields = Object.keys(existingWindow);

                                            for (let w = 0; w < existingWindowFields.length; w++) {
                                                if (existingWindowFields[w] === "id") continue;

                                                const newValue =
                                                    existingWindowFields[w] === "height"
                                                        ? convertedHeight
                                                        : existingWindowFields[w] === "width"
                                                        ? convertedWidth
                                                        : existingWindowFields[w] === "ovHeight"
                                                        ? ovHeight
                                                        : existingWindowFields[w] === "ovWidth"
                                                        ? ovWidth
                                                        : existingWindowFields[w] === "title"
                                                        ? title
                                                        : quantity;

                                                const newUnit =
                                                    existingWindowFields[w] === "height"
                                                        ? windowDisplayUnits
                                                        : existingWindowFields[w] === "width"
                                                        ? windowDisplayUnits
                                                        : existingWindowFields[w] === "ovHeight"
                                                        ? ovHeightUnits
                                                        : existingWindowFields[w] === "ovWidth"
                                                        ? ovWidthUnits
                                                        : "";

                                                editTableComponent(
                                                    dispatch,
                                                    sortedKey,
                                                    existingWindow.id,
                                                    existingWindowFields[w],
                                                    newValue,
                                                    newUnit
                                                );
                                            }
                                        }

                                        if (!tableComponentIds.includes(id))
                                            addTableComponent(dispatch, sortedKey, {
                                                title: { value: title },
                                                width: {
                                                    value: convertedWidth,
                                                    unit: windowDisplayUnits,
                                                },
                                                height: {
                                                    value: convertedHeight,
                                                    unit: windowDisplayUnits,
                                                },
                                                ovWidth: {
                                                    value: ovWidth,
                                                    unit: ovWidthUnits,
                                                },
                                                ovHeight: {
                                                    value: ovHeight,
                                                    unit: ovHeightUnits,
                                                },
                                                qty: { value: quantity },
                                                id,
                                            });
                                    }

                                    if (
                                        takeoffField === "attic ceiling (floor plan)" ||
                                        takeoffField === "cathedral ceiling (floor plan)" ||
                                        takeoffField === "sloped ceiling (floor plan)" ||
                                        takeoffField === "raised ceiling (floor plan)"
                                    ) {
                                        const ceilingType = {
                                            "attic ceiling (floor plan)": "atticceiling",
                                            "cathedral ceiling (floor plan)": "cathedralceiling",
                                            "sloped ceiling (floor plan)": "slopedceiling",
                                            "raised ceiling (floor plan)": "raisedceiling",
                                        };

                                        const existingCeiling = modelTakeoff[sortedKey].components.find(
                                            (el) => el.id === id
                                        );

                                        //Assign here (width smaller) to avoid ambiguity
                                        const assignedWidth = Math.min(convertedWidth, convertedHeight);
                                        const assignedLength = Math.max(convertedWidth, convertedHeight);

                                        if (existingCeiling) {
                                            for (let e = 0; e < existingCeiling.fields.length; e++) {
                                                const { field } = existingCeiling.fields[e];

                                                editSubComponent(
                                                    dispatch,
                                                    existingCeiling.id,
                                                    sortedKey,
                                                    field,
                                                    field === "width"
                                                        ? assignedWidth
                                                        : field === "length"
                                                        ? assignedLength
                                                        : field === "height"
                                                        ? ceilingHeight
                                                        : field === "area"
                                                        ? convertedArea
                                                        : field === "eaveLength"
                                                        ? convertedPerimeter / 2
                                                        : field === "numGables"
                                                        ? numGables
                                                        : convertedPerimeter,
                                                    field === "area"
                                                        ? `${displayUnits}2`
                                                        : field === "height"
                                                        ? ceilingHeightUnits
                                                        : displayUnits
                                                );
                                            }
                                        }

                                        if (!componentIds.includes(id)) {
                                            addSubComponent(dispatch, sortedKey, ceilingType[takeoffField], {
                                                id,
                                                width: assignedWidth,
                                                length: assignedLength,
                                                eaveLength: convertedPerimeter / 2,
                                                height: ceilingHeight,
                                                area: convertedArea,
                                                perimeter: convertedPerimeter,
                                                unit: displayUnits,
                                                ceilingHeightUnits,
                                            });
                                        }
                                    }
                                }

                                if (shape.shape === "line") {
                                    const { length, takeoff, points } = shape;

                                    const { id, takeoffField } = takeoff;

                                    const convertedLength = convertUnit({
                                        value: length * conversionFactor,
                                        type: "length",
                                        inputUnit: "m",
                                        outputUnit: "m",
                                        // outputUnit: displayUnits,
                                    });

                                    if (takeoffField === "wall height") totalWallHeight += convertedLength;

                                    if (takeoffField === "floor header height")
                                        totalFloorHeaderHeight += convertedLength;

                                    if (takeoffField === "buffered wall perimeter") {
                                        const existingBw = modelTakeoff[sortedKey].components.find(
                                            (el) => el.id === id
                                        );

                                        if (existingBw) {
                                            for (let e = 0; e < existingBw.fields.length; e++) {
                                                const { field } = existingBw.fields[e];

                                                if (field === "intersections") continue;

                                                editSubComponent(
                                                    dispatch,
                                                    existingBw.id,
                                                    sortedKey,
                                                    field,
                                                    field === "intPerimeter" ? convertedLength : points.length / 2,
                                                    displayUnits
                                                );
                                            }
                                        }

                                        if (!componentIds.includes(id)) {
                                            addSubComponent(dispatch, sortedKey, "bufferedwall", {
                                                id,
                                                perimeter: convertedLength,
                                                corners: points.length / 2,
                                                unit: displayUnits,
                                            });
                                        }
                                    }
                                }

                                if (shape.shape === "multiPointLine") {
                                    const { totalLength, takeoff, points } = shape;

                                    const { id, takeoffField } = takeoff;

                                    const convertedLength = convertUnit({
                                        value: totalLength * conversionFactor,
                                        type: "length",
                                        inputUnit: "m",
                                        outputUnit: "m",
                                        // outputUnit: displayUnits,
                                    });

                                    if (takeoffField === "floor header height")
                                        totalFloorHeaderHeight += convertedLength;

                                    if (takeoffField === "buffered wall perimeter") {
                                        const existingBw = modelTakeoff[sortedKey].components.find(
                                            (el) => el.id === id
                                        );

                                        if (existingBw) {
                                            for (let e = 0; e < existingBw.fields.length; e++) {
                                                const { field } = existingBw.fields[e];

                                                if (field === "intersections") continue;

                                                editSubComponent(
                                                    dispatch,
                                                    existingBw.id,
                                                    sortedKey,
                                                    field,
                                                    field === "intPerimeter" ? convertedLength : points.length / 2,
                                                    displayUnits
                                                );
                                            }
                                        }

                                        if (!componentIds.includes(id)) {
                                            addSubComponent(dispatch, sortedKey, "bufferedwall", {
                                                id,
                                                perimeter: convertedLength,
                                                corners: points.length / 2,
                                                unit: displayUnits,
                                            });
                                        }
                                    }
                                }
                            }

                            if (totalFloorArea > 0)
                                changeStoreyValue(
                                    dispatch,
                                    "floorPlanArea",
                                    totalFloorArea,
                                    `${displayUnits}2`,
                                    sortedKey
                                );

                            if (totalFloorPerimeter > 0)
                                changeStoreyValue(
                                    dispatch,
                                    "floorPlanPerimeter",
                                    totalFloorPerimeter,
                                    `${displayUnits}`,
                                    sortedKey
                                );

                            if (totalWallHeight > 0)
                                changeStoreyValue(
                                    dispatch,
                                    "wallHeight",
                                    totalWallHeight,
                                    `${displayUnits}`,
                                    sortedKey
                                );

                            if (totalFloorHeaderHeight > 0)
                                changeStoreyValue(
                                    dispatch,
                                    "floorHeaderHeight",
                                    totalFloorHeaderHeight,
                                    `${displayUnits}`,
                                    sortedKey
                                );
                        }
                    }
                }

                // dispatch(
                //     setMessage({
                //         message: "Drawing saved successfully",
                //         type: "success",
                //         anchorOrigin: {
                //             vertical: "top",
                //             horizontal: "right",
                //         },
                //         autoHideDuration: 2000,
                //         isOpen: true,
                //     })
                // );
            }

            await firestore.doc(`${USERS_COLL}/${uid}`).update({ didUserSawNewDrawing: true });

            const mergedData = mergeObjects(imageData, imageDataToCompare, imageDataSnapshot);

            await firestore
                .doc(`${MODEL_COLL}/${modelId}`)
                .collection("drawingData")
                .doc("data")
                .set({ imageData: { imageData: mergedData, didUserSawNewDrawing } })
                .catch((err) => console.log("err", err));

            dispatch(savingDrawingSuccess(mergedData));
        } catch (error) {
            console.error("error", error);
            dispatch(savingDrawingFailure());
        }
    };

export const fetchDrawingData = (modelId) => async (dispatch, getState) => {
    try {
        dispatch(fetchDrawingDataStart());

        const { didUserSawNewDrawing = false } = getState().user;

        const drawingData = await firestore.doc(`${MODEL_COLL}/${modelId}`).collection("drawingData").doc("data").get();

        let { imageData = {} } = drawingData.data()?.imageData || {};

        if (!didUserSawNewDrawing) imageData = {};

        dispatch(fetchDrawingDataSuccess({ imageData, didUserSawNewDrawing }));
    } catch (error) {
        dispatch(fetchDrawingDataError());
    }
};

export const generateDrawing =
    ({ modelId, uid, file, fileName }) =>
    async (dispatch) => {
        dispatch(uploadingDrawingStart());

        const pdfPath = `${uid}/${modelId}/${fileName}/${fileName}.pdf`;

        const uploadTask = storageRef.child(pdfPath).put(file);

        dispatch(
            setFileUploadStatus({
                isUploading: true,
            })
        );

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);

                dispatch(setFileUploadStatus({ progress }));

                switch (snapshot.state) {
                    case storage.TaskState.PAUSED:
                        break;
                    case storage.TaskState.RUNNING:
                        break;
                    default:
                        console.log("default");
                }
            },
            (error) => {
                dispatch(
                    setFileUploadStatus({
                        error: error,
                    })
                );
            },
            async () => {
                await uploadTask.snapshot.ref.getDownloadURL();

                dispatch(
                    setFileUploadStatus({
                        isGenerating: true,
                        isUploading: false,
                        uploadSuccess: true,
                    })
                );

                convertPdfToImage({ pdfLink: pdfPath })
                    .then(async (data) => {})
                    .finally(async () => {
                        await dispatch(fetchModelFiles(modelId));

                        dispatch(
                            setFileUploadStatus({
                                isGenerating: false,
                                isUploading: false,
                                success: true,
                            })
                        );
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        );
    };
