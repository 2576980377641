const SET_SCALE = "SET_SCALE";
const SET_ACTION = "SET_ACTION";
const SET_ACTIVE_IMAGE = "SET_ACTIVE_IMAGE";
const SET_ACTIVE_TOOL = "SET_ACTIVE_TOOL";
const SET_DISABLED_TOOLS = "SET_DISABLED_TOOLS";
const SET_MESSAGE = "SET_MESSAGE";
const SET_ACTIVE_COMPONENT = "SET_ACTIVE_COMPONENT";
const ADD_COMPONENT = "ADD_COMPONENT";
const ATTACH_COMPONENT = "ATTACH_COMPONENT";
const REMOVE_COMPONENT = "REMOVE_COMPONENT";
const UPDATE_COMPONENT = "UPDATE_COMPONENT";
const ADD_POLYGON = "ADD_POLYGON";
const UPDATE_POLYGON = "UPDATE_POLYGON";
const UPDATE_POLYGON_COMPONENT = "UPDATE_POLYG_COMPONENT";
const ADD_LINE = "ADD_LINE";
const UPDATE_LINE = "UPDATE_LINE";
const REMOVE_LINE = "REMOVE_LINE";
const REMOVE_POLYGON = "REMOVE_POLYGON";
const TOGGLE_SAVING = "TOGGLE_SAVING";
const SET_DRAWING_DATA = "SET_DRAWING_DATA";
const UPDATE_PREVIOUS_REFS = "UPDATE_PREVIOUS_REFS";
const UPDATE_COMPONENTS_TO_DELETE = "UPDATE_COMPONENTS_TO_DELETE";
const REMOVE_POLYGON_COMPONENT = "REMOVE_POLYGON_COMPONENT";
const UPDATE_FIELDS_TO_DETACH = "UPDATE_FIELDS_TO_DETACH";
const CLEAR_DRAWING_DATA = "CLEAR_DRAWING_DATA";
const TOGGLE_IMAGE_PROCESSING = "TOGGLE_IMAGE_PROCESSING";
const UPDATE_REMOVE_FROM_FLOOR = "UPDATE_REMOVE_FROM_FLOOR";
const TOGGLE_DATA_LOADING = "TOGGLE_DATA_LOADING";
const RESET_DRAWING = "RESET_DRAWING";
const UPDATE_STAGE = "UPDATE_STAGE";

export default {
    SET_SCALE,
    SET_ACTION,
    SET_ACTIVE_IMAGE,
    SET_ACTIVE_TOOL,
    SET_DISABLED_TOOLS,
    SET_MESSAGE,
    SET_ACTIVE_COMPONENT,
    ADD_COMPONENT,
    ATTACH_COMPONENT,
    REMOVE_COMPONENT,
    UPDATE_COMPONENT,
    ADD_POLYGON,
    UPDATE_POLYGON,
    ADD_LINE,
    UPDATE_LINE,
    REMOVE_LINE,
    REMOVE_POLYGON,
    TOGGLE_SAVING,
    SET_DRAWING_DATA,
    UPDATE_PREVIOUS_REFS,
    UPDATE_COMPONENTS_TO_DELETE,
    UPDATE_POLYGON_COMPONENT,
    REMOVE_POLYGON_COMPONENT,
    UPDATE_FIELDS_TO_DETACH,
    CLEAR_DRAWING_DATA,
    TOGGLE_IMAGE_PROCESSING,
    UPDATE_REMOVE_FROM_FLOOR,
    TOGGLE_DATA_LOADING,
    RESET_DRAWING,
    UPDATE_STAGE,
};
