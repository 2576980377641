import React from 'react';
import classes from '../../../style.module.scss';
import { Field } from 'redux-form';
import InputWithUnits from 'components/Input/InputWithUnits';
import Input from 'components/Input';
import { required, maxLength, getValidation, getDecimalPlaces } from 'utils/fieldValidation';
import { getBaseUnits, getUnitOptions } from 'utils/fields';
import convertUnit from 'utils/convertUnit';
import { isEqual } from 'lodash';

const charLim100 = maxLength(100);
const exposedSurfaceValidation = getValidation('crawlspaceExpSurfacePerimeter');
const perimeterValidation = getValidation('crawlspacePerimeter');
const areaValidation = getValidation('crawlspaceArea');
const heightValidation = getValidation('crawlspaceWallHeight');
const depthValidation = getValidation('crawlspaceWallDepthBelowGrade');
const cornerValidation = getValidation('crawlspaceWallCorner');

const Crawlspace = React.memo(({
    componentId,
    primaryUnits,
    imageUnits,
    area,
    perimeter,
    conversionFactor,
    change,
    expPerimeterUnits,
    heightUnits,
    depthUnits,
    fieldsWithLinesAttached,
    saveToState
}) => {

    const calculatedArea = parseFloat(convertUnit({
        value:area*Math.pow(conversionFactor, 2),
        type:'area',
        inputUnit:'m2',
        outputUnit:getBaseUnits('area', primaryUnits).trueBase
    }).toFixed(2));

    const calculatedPerimeter = parseFloat(convertUnit({
        value:perimeter*conversionFactor,
        type:'length',
        inputUnit:'m',
        outputUnit:getBaseUnits('perimeter',primaryUnits).trueBase
    }).toFixed(2));

    return (
        <div>
            <Field
                className={classes.inputMarginBottom}
                component={Input}
                type="text"
                label="Crawlspace Name*"
                name={`components.${componentId}.label`}
                validate={[required, charLim100]}
                placeholder="Example: Crawlspace"
                onChange={saveToState}
            />
             <Field
                className={classes.inputMarginBottom}
                component={InputWithUnits}
                type="number"
                name={`components.${componentId}.floor.measurements.perimeter`}
                label="Floor Perimeter"
                placeholder="0.00"
                validate={perimeterValidation}
                decimals={getDecimalPlaces('crawlspacePerimeter')}
                change={()=>''}
                disabled={true}
                setValue={calculatedPerimeter}
                units={{
                    base:getBaseUnits('crawlspacePerimeter', primaryUnits),
                    options:[imageUnits],
                    selected:imageUnits,
                    unitType:'length',
                    accessor:`components.${componentId}.floor.measurements.perimeter_u`
                }}
                info="Calculated based on polygon shape and the set scale."
                onChange={saveToState}
                onUnitChange={saveToState}
            />
            <Field
                className={classes.inputMarginBottom}
                component={InputWithUnits}
                type="number"
                name={`components.${componentId}.floor.measurements.area`}
                label="Floor Area"
                placeholder="0.00"
                validate={areaValidation}
                decimals={getDecimalPlaces('crawlspaceArea')}
                change={()=>''}
                disabled={true}
                setValue={calculatedArea}
                units={{
                    base:getBaseUnits('crawlspaceArea', primaryUnits),
                    options:[`${imageUnits}2`],
                    selected:`${imageUnits}2`,
                    unitType:'area',
                    accessor:`components.${componentId}.floor.measurements.area_u`
                }}
                info="Calculated based on polygon shape and the set scale."
                onChange={saveToState}
                onUnitChange={saveToState}
            />
            <Field
                className={classes.inputMarginBottom}
                component={InputWithUnits}
                type="number"
                name={`components.${componentId}.exposedSurfacePerimeter`}
                label="Exposed Surfaces Perimeter"
                placeholder="0.00"
                validate={exposedSurfaceValidation}
                decimals={getDecimalPlaces('crawlspaceExpSurfacePerimeter')}
                change={change}
                units={{
                    base:getBaseUnits('crawlspaceExpSurfacePerimeter', primaryUnits),
                    options:getUnitOptions('length'),
                    selected:expPerimeterUnits,
                    unitType:'length',
                    accessor:`components.${componentId}.exposedSurfacePerimeter_u`
                }}
                info={"Used only when there are multiple foundations present. Enter the total perimeter of this foundation component that is not attached to any other foundation components."}
                onChange={saveToState}
                onUnitChange={saveToState}
            />
            <Field
                className={classes.inputMarginBottom}
                component={InputWithUnits}
                type="number"
                name={`components.${componentId}.wall.measurements.height`}
                label="Total Wall Height"
                placeholder="0.00"
                validate={heightValidation}
                decimals={getDecimalPlaces('crawlspaceWallHeight')}
                change={change}
                disabled={fieldsWithLinesAttached.includes('crawlspaceWallHeight')}
                isDrawing={fieldsWithLinesAttached.includes('crawlspaceWallHeight')}
                isDrawingTip="This field is associated with a Line in the Drawing Capture. To detach, delete the Line or use it for another field."
                units={{
                    base:getBaseUnits('crawlspaceWallHeight', primaryUnits),
                    options:getUnitOptions('length'),
                    selected:heightUnits,
                    unitType:'length',
                    accessor:`components.${componentId}.wall.measurements.height_u`
                }}
                onChange={saveToState}
                onUnitChange={saveToState}
            />
            <Field
                className={classes.inputMarginBottom}
                component={InputWithUnits}
                type="number"
                name={`components.${componentId}.wall.measurements.depth`}
                label="Wall Depth Below Grade"
                placeholder="0.00"
                validate={depthValidation}
                decimals={getDecimalPlaces('crawlspaceWallDepthBelowGrade')}
                disabled={fieldsWithLinesAttached.includes('crawlspaceWallDepthBelowGrade')}
                isDrawing={fieldsWithLinesAttached.includes('crawlspaceWallDepthBelowGrade')}
                isDrawingTip="This field is associated with a Line in the Drawing Capture. To detach, delete the Line or use it for another field."
                change={change}
                units={{
                    base:getBaseUnits('crawlspaceWallDepthBelowGrade', primaryUnits),
                    options:getUnitOptions('length'),
                    selected:depthUnits,
                    unitType:'length',
                    accessor:`components.${componentId}.wall.measurements.depth_u`
                }}
                onChange={saveToState}
                onUnitChange={saveToState}
            />
            <Field
                className={classes.inputMarginBottom}
                component={Input}
                type="number"
                name={`components.${componentId}.wall.measurements.corners`}
                label="Corners"
                placeholder="1"
                quantity
                validate={cornerValidation}
                onChange={saveToState}
            />
        </div>
    )
}, isEqual);

export default Crawlspace;