import React, { useEffect, useCallback } from "react";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";

import {
    selectActiveImageIndex,
    selectActiveTool,
    selectActiveComponent,
    selectImageData,
    selectCurrentAction,
} from "../_ducks/selector";
import { setActiveTool, setActiveComponent, setScale, setMessage, setAction } from "../_ducks/actions";

import { deactivateComponent } from "../drawingHelpers";

import Tooltip from "components/Tooltip";

import Rectangle from "assets/images/components/JSX/Rectangle";
import Line from "assets/images/components/JSX/Line";
import MultiLine from "assets/images/components/JSX/MultiLine";
import Polygon from "assets/images/components/JSX/Polygon";
import Ruler from "assets/images/components/JSX/Ruler";

import { tools, topRow, tool, active, disabled, scale } from "./style.module.scss";

const Tools = ({
    activeTool = "",
    setActiveTool,
    disabledTools = [],
    setActiveComponent,
    images,
    activeImageIndex,
    imageData,
    activeComponent,
    layer,
    setScale,
    setMessage,
    setAction,
    currentAction,
}) => {
    const isCurrentScaleSet =
        images[activeImageIndex] && imageData[images[activeImageIndex].fileName]
            ? imageData[images[activeImageIndex].fileName]?.scale?.isSet
            : false;

    const { meta = {} } = currentAction || {};

    const handleToolToggle = (tool) => {
        if (activeTool === tool) {
            return setActiveTool("");
        }

        let stage;

        if (layer.current) stage = layer.current.getParent();

        if (meta.cancel) meta.cancel();

        deactivateComponent({
            activeComponent,
            setAction,
            setActiveComponent,
            stage,
            activeTool,
            imageData: images[activeImageIndex] ? imageData[images[activeImageIndex].fileName] : {},
        });

        setActiveTool(tool);
    };

    return (
        <div className={tools}>
            <h3>Drawing Tools</h3>
            <div className={topRow}>
                <div
                    className={`${tool} ${activeTool === "polygon" ? active : ""} ${
                        !isCurrentScaleSet ? disabled : ""
                    }`}
                    style={{
                        backgroundColor: activeTool === "polygon" && "#FF8C1D",
                    }}
                    data-tip="Polygon"
                    onClick={() => handleToolToggle("polygon")}
                >
                    <Polygon />
                </div>
                <div
                    className={`${tool} ${activeTool === "rectangle" ? active : ""} ${
                        !isCurrentScaleSet ? disabled : ""
                    }`}
                    style={{
                        backgroundColor: activeTool === "rectangle" && "#62BCF8",
                    }}
                    data-tip="Rectangle"
                    onClick={() => handleToolToggle("rectangle")}
                >
                    <Rectangle />
                </div>
                <div
                    className={`${tool} ${activeTool === "line" ? active : ""} ${!isCurrentScaleSet ? disabled : ""}`}
                    style={{
                        backgroundColor: activeTool === "line" && "#F5547C",
                    }}
                    data-tip="Line"
                    onClick={() => handleToolToggle("line")}
                >
                    <Line />
                </div>
                <div
                    className={`${tool} ${activeTool === "multiPointLine" ? active : ""} ${
                        !isCurrentScaleSet ? disabled : ""
                    }`}
                    style={{
                        backgroundColor: activeTool === "multiPointLine" && "#18C1AD",
                    }}
                    data-tip="Multi-point Line"
                    onClick={() => handleToolToggle("multiPointLine")}
                >
                    <MultiLine />
                </div>
            </div>
            <div
                className={`${scale} ${activeTool === "measure" ? active : ""} ${isCurrentScaleSet ? disabled : ""}`}
                onClick={() => {
                    if (!isCurrentScaleSet) {
                        handleToolToggle("measure");
                    } else {
                        const measure = layer.current.findOne(
                            (node) => node.getId() === `group_measure_${images[activeImageIndex].fileName}`
                        );

                        setMessage({
                            message: "Place first scale point.",
                            type: "info",
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "center",
                            },
                            autoHideDuration: null,
                            isOpen: true,
                            direction: "up",
                        });
                        setActiveTool("measure");
                        setActiveComponent("");
                        setAction({
                            id: "setScale",
                            meta: {
                                image: images[activeImageIndex].fileName,
                                cancel: () => {
                                    const currentScale = imageData[images[activeImageIndex].fileName].scale;

                                    setActiveTool("");
                                    setActiveComponent("");
                                    setAction({ id: null, meta: {} });
                                    setMessage({
                                        message: "",
                                        type: "",
                                    });
                                    setScale({
                                        image: images[activeImageIndex].fileName,
                                        scale: currentScale,
                                    });

                                    layer.current.add(measure);
                                    layer.current.draw();
                                },
                            },
                        });
                        setScale({
                            image: images[activeImageIndex].fileName,
                            scale: {},
                        });

                        measure.remove();
                        layer.current.draw();
                    }
                }}
            >
                <span>
                    {isCurrentScaleSet
                        ? "Reset Scale"
                        : activeTool === "measure" && !isCurrentScaleSet
                          ? "Setting Scale"
                          : "Set Scale"}
                </span>
                <Ruler />
            </div>
            <Tooltip for="tools" />
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    activeTool: selectActiveTool,
    activeImageIndex: selectActiveImageIndex,
    imageData: selectImageData,
    activeComponent: selectActiveComponent,
    currentAction: selectCurrentAction,
});

const mapDispatchToProps = (dispatch) => ({
    setActiveTool: (tool) => dispatch(setActiveTool(tool)),
    setActiveComponent: (comp) => dispatch(setActiveComponent(comp)),
    setScale: ({ image, scale }) => dispatch(setScale({ image, scale })),
    setMessage: ({ message, type, anchorOrigin, autoHideDuration, isOpen, direction }) =>
        dispatch(
            setMessage({
                message,
                type,
                anchorOrigin,
                autoHideDuration,
                isOpen,
                direction,
            })
        ),
    setAction: ({ id, meta = {} }) => dispatch(setAction({ id, meta })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tools);
