import React from 'react';

export default({ strokeClass }) => (
    <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Group" className={strokeClass} stroke="#0066B1">
                <path d="M14.4381981,0.5 L19.5,5.70411013 L19.5,16 C19.5,16.9664983 19.1082492,17.8414983 18.4748737,18.4748737 C17.8414983,19.1082492 16.9664983,19.5 16,19.5 L16,19.5 L4,19.5 C3.03350169,19.5 2.15850169,19.1082492 1.52512627,18.4748737 C0.891750844,17.8414983 0.5,16.9664983 0.5,16 L0.5,16 L0.5,4 C0.5,3.03350169 0.891750844,2.15850169 1.52512627,1.52512627 C2.15850169,0.891750844 3.03350169,0.5 4,0.5 L4,0.5 L14.4381981,0.5 Z" id="Rectangle"></path>
                <rect id="Rectangle" x="3.57692308" y="0.5" width="9" height="5" rx="1"></rect>
                <rect id="Rectangle-Copy" x="3.57692308" y="9.73076923" width="12.0769231" height="9.76923077" rx="1"></rect>
                <line x1="5" y1="12.3846154" x2="14" y2="12.3846154" id="Line"></line>
                <line x1="5" y1="14.6923077" x2="14" y2="14.6923077" id="Line-Copy"></line>
                <line x1="5" y1="17" x2="14" y2="17" id="Line-Copy-2"></line>
            </g>
        </g>
    </svg>
)