import React from "react";

import useComponentVisible from "utils/hooks";

import DropdownMenu from "components/DropdownMenu";
import DropdownItem from "components/DropdownMenu/DropdownItem";

import {
    tSelectContainer,
    openSelect,
    arrow,
    arrowOpen,
} from "./style.module.scss";

import iArrow from "assets/images/icons/arrow-down.svg";

const TSelect = ({
    value,
    onChange,
    options,
    getName,
    top = 42,
    style = {},
}) => {
    const { ref, isComponentVisible, setIsComponentVisible } =
        useComponentVisible(false);

    return (
        <div
            className={`${tSelectContainer} ${
                isComponentVisible && openSelect
            }`}
            ref={ref}
            onClick={() => setIsComponentVisible(!isComponentVisible)}
        >
            <span>{getName(value)}</span>
            <img
                className={`${arrow} ${isComponentVisible && arrowOpen}`}
                src={iArrow}
                alt={iArrow}
            />
            {options && (
                <DropdownMenu isOpen={isComponentVisible} top={top}>
                    {options.map((option, index) => (
                        <DropdownItem
                            key={index}
                            onClick={(e) => {
                                onChange(option.value);
                                setIsComponentVisible(false);
                            }}
                            text={getName(option.value)}
                        />
                    ))}
                </DropdownMenu>
            )}
        </div>
    );
};

export default TSelect;
