import React from 'react';

export default ({ className='' }) => (
    <svg className={className} width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="Accordion-/-Hover-Elements-/-Dupe-+-Trash-+-Drag" transform="translate(0.000000, -5.000000)" fill="#65676A" fillRule="nonzero">
            <g id="Group-2" transform="translate(-4.000000, 0.000000)">
                <g id="Group" transform="translate(12.000000, 13.000000) rotate(-45.000000) translate(-12.000000, -13.000000) translate(4.000000, 5.000000)">
                    <path d="M8,0 C12.418278,0 16,3.581722 16,8 C16,12.418278 12.418278,16 8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,0 8,0 Z M8,1 C4.13400675,1 1,4.13400675 1,8 C1,11.8659932 4.13400675,15 8,15 C11.8659932,15 15,11.8659932 15,8 C15,4.13400675 11.8659932,1 8,1 Z M10.1213203,5.17157288 L10.8284271,5.87867966 L8.707,8 L10.8284271,10.1213203 L10.1213203,10.8284271 L8,8.707 L5.87867966,10.8284271 L5.17157288,10.1213203 L7.293,8 L5.17157288,5.87867966 L5.87867966,5.17157288 L8,7.293 L10.1213203,5.17157288 Z" id="Oval-Copy"></path>
                </g>
            </g>
        </g>
    </svg>
)