import React from "react";
import { useSelector } from "react-redux";
import EyeOpen from "assets/images/icons/JSX/Eye-Open";
import EyeClosed from "assets/images/icons/JSX/Eye-Closed";
import classes from "./RowToggle.module.scss";
import { modelFormSelector } from "store/form/selectors";

const RowToggle = ({ rowId, className, modelChange }) => {
    const { upgrades: { hiddenRows = {} } = {} } = useSelector(
        modelFormSelector
    )("modelData.uiSettings");

    const hidden = hiddenRows[rowId];

    const toggleRow = () =>
        modelChange(
            `modelData.uiSettings.upgrades.hiddenRows.${rowId}`,
            !hidden
        );

    return (
        <span className={className} onClick={toggleRow}>
            {hidden ? (
                <EyeClosed className={classes.eyeIconClosed} />
            ) : (
                <EyeOpen className={classes.eyeIcon} />
            )}
        </span>
    );
};

export default RowToggle;
