import types from "./types";

export const setProgress = (progress) => ({
    type: types.SET_UPLOADING_PROGRESS,
    payload: progress,
});

export const savingDrawingStart = () => ({
    type: types.DRAWING_DATA_SAVING_START,
});

export const savingDrawingSuccess = (newDrawingData) => ({
    type: types.DRAWING_DATA_SAVING_SUCCESS,
    payload: newDrawingData,
});

export const savingDrawingFailure = (error) => ({
    type: types.DRAWING_DATA_SAVING_FAILURE,
    payload: error,
});

export const uploadingDrawingStart = () => ({
    type: types.UPLOADING_DRAWING_START,
});

export const uploadingDrawingSuccess = (newDrawingData) => ({
    type: types.UPLOADING_DRAWING_SUCCESS,
    payload: newDrawingData,
});

export const uploadingDrawingFailure = (error) => ({
    type: types.UPLOADING_DRAWING_FAILURE,
    payload: error,
});

export const generatingDrawingStart = () => ({
    type: types.GENERATING_DRAWING_START,
});

export const generatingDrawingSuccess = (newDrawingData) => ({
    type: types.GENERATING_DRAWING_SUCCESS,
    payload: newDrawingData,
});

export const generatingDrawingFailure = (error) => ({
    type: types.GENERATING_DRAWING_FAILURE,
    payload: error,
});

export const fetchDrawingDataStart = () => ({
    type: types.FETCH_DRAWING_DATA_START,
});

export const fetchDrawingDataSuccess = ({ imageData, didUserSawNewDrawing }) => ({
    type: types.FETCH_DRAWING_DATA_SUCCESS,
    payload: { imageData, didUserSawNewDrawing },
});

export const fetchDrawingDataError = () => ({
    type: types.FETCH_DRAWING_DATA_ERROR,
});

export const toggleImageProcessing = () => ({
    type: types.TOGGLE_IMAGE_PROCESSING,
});

export const updateStage = (stage) => ({
    type: types.UPDATE_STAGE,
    payload: stage,
});

export const setMessage = ({ message, type, anchorOrigin, autoHideDuration, isOpen, direction }) => ({
    type: types.SET_MESSAGE,
    payload: {
        message,
        type,
        anchorOrigin,
        autoHideDuration,
        isOpen,
        direction,
    },
});

export const setScale = ({ image, scale }) => ({
    type: types.SET_SCALE,
    payload: { image, scale },
});

export const setAction = ({ id, meta = {} }) => ({
    type: types.SET_ACTION,
    payload: { id, meta },
});

export const setActiveImageIndex = (imageIndex) => ({
    type: types.SET_ACTIVE_IMAGE_INDEX,
    payload: imageIndex,
});

export const setTools = (tools) => ({
    type: types.SET_TOOLS,
    payload: tools,
});

export const setActiveTool = (tool) => ({
    type: types.SET_ACTIVE_TOOL,
    payload: tool,
});

export const setActiveComponent = (component) => ({
    type: types.SET_ACTIVE_COMPONENT,
    payload: component,
});

export const setPolygon = (image, polygon) => ({
    type: types.SET_POLYGON,
    payload: { image, polygon },
});

export const setLine = (image, line) => ({
    type: types.SET_LINE,
    payload: { image, line },
});

export const setRectangle = (image, rectangle) => ({
    type: types.SET_RECTANGLE,
    payload: { image, rectangle },
});

export const setMultiPointLine = (image, multiPointLine) => ({
    type: types.SET_MULTI_POINT_LINE,
    payload: { image, multiPointLine },
});

export const setDidUserSawNewDrawing = (bool) => ({
    type: types.SET_DID_USER_SAW_NEW_DRAWING,
    payload: bool,
});

export const removePolygon = (image, polygonId) => ({
    type: types.REMOVE_POLYGON,
    payload: { image, polygonId },
});

export const removeRectangle = (image, rectangleId) => ({
    type: types.REMOVE_RECTANGLE,
    payload: { image, rectangleId },
});

export const removeLine = (image, lineId) => ({
    type: types.REMOVE_LINE,
    payload: { image, lineId },
});

export const removeMultiPointLine = (image, lineId) => ({
    type: types.REMOVE_MULTI_POINT_LINE,
    payload: { image, lineId },
});

export const toggleIsCopyScaleOpen = (bool) => ({
    type: types.TOGGLE_IS_COPY_SCALE_OPEN,
    payload: bool,
});

export const clearDrawingData = () => ({
    type: types.CLEAR_DRAWING_DATA,
});

export const resetDrawing = () => ({
    type: types.RESET_DRAWING,
});

export const imageDataSnapshotUpdate = (imageData) => ({
    type: types.IMAGE_DATA_SNAPSHOT_UPDATE,
    payload: imageData,
});
