import types from "./types";
import { updateObject } from "utils";
import { upgradePackageSort } from "./selectors";

export const initialState = {
    packages: {},
    savedUpgradePackages: {},
    initPackages: {},
    initPackagesSnapshot: {},
    loading: false,
    error: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.CLEAR_UPGRADE_DATA:
            return clearUpgradeData(state, action);
        case types.TOGGLE_UPGRADES_LOADING:
            return toggleUpgradesLoading(state, action);
        case types.SET_UPGRADES_ERROR:
            return setUpgradesError(state, action);
        case types.UPDATE_UPGRADE_PACKAGE:
            return updateUpgradePackage(state, action);
        case types.DELETE_UPGRADE_PACKAGE:
            return deleteUpgradePackage(state, action);
        case types.SET_UPGRADE_PACKAGES:
            return setUpgradePackages(state, action);
        case types.UPDATE_PACKAGE_UPGRADES:
            return updatePackageUpgrades(state, action);
        case types.SET_INIT_UPGRADE_PACKAGES:
            return setInitUpgradePackages(state, action);
        case types.DELETE_PACKAGE_UPGRADE:
            return deletePackageUpgrade(state, action);
        case types.UPDATE_UPGRADE_PACKAGE_ORDER:
            return reorderUpgradePackages(state, action);
        case types.UPDATE_UPGRADE_COST:
            return updateUpgradeCost(state, action);
        case types.UPDATE_PACKAGE_OPERATING_CONDITIONS:
            return updatePackageOperatingConditions(state, action);
        case types.UPDATE_PACKAGE_BASE_CASE:
            return updatePackageBaseCase(state, action);
        case types.UPDATE_UPGRADE_SNAPSHOT_SUCCESS:
            return updateUpgradeSnapshot(state, action);
        default:
            return state;
    }
};

const clearUpgradeData = (state, action) => initialState;

const toggleUpgradesLoading = (state, action) =>
    updateObject(state, {
        loading: action.isLoading,
    });

const setUpgradesError = (state, action) =>
    updateObject(state, {
        error: action.error,
    });

const updateUpgradePackage = (state, action) => {
    return updateObject(state, {
        packages: updateObject(state.packages, {
            [action.packageId]: {
                ...(state.packages[action.packageId] ? state.packages[action.packageId] : {}),
                ...action.packageUpdates,
            },
        }),
    });
};

const updatePackageUpgrades = (state, action) => {
    return updateObject(state, {
        packages: updateObject(state.packages, {
            [action.packageId]: {
                ...state.packages[action.packageId],
                lastEdited: action?.dateTime,
                upgrades: {
                    ...(state.packages[action.packageId]?.upgrades ? state.packages[action.packageId].upgrades : {}),
                    ...action.upgrades,
                },
            },
        }),
    });
};

const deleteUpgradePackage = (state, action) => {
    const removedPackageArray = Object.keys(state.packages)
        .filter((id) => id !== action.packageId)
        .map((packageId) => ({
            id: packageId,
            ...state.packages[packageId],
        }))
        .sort(upgradePackageSort);

    const packagesObj = removedPackageArray.reduce((obj, { id, ui = {}, ...rest }, index) => {
        return {
            ...obj,
            [id]: {
                ui: {
                    displayOrder: Math.abs(index - (removedPackageArray.length - 1)),
                    ...ui,
                },
                ...rest,
            },
        };
    }, {});

    return updateObject(state, {
        packages: packagesObj,
    });
};

const deletePackageUpgrade = (state, action) => {
    const { [action.packageId]: thisPackage = {} } = state.packages;
    const { upgrades: { [action.upgradeId]: _, ...remainingUpgrades } = {} } = thisPackage;

    return updateObject(state, {
        packages: updateObject(state.packages, {
            [action.packageId]: {
                ...thisPackage,
                upgrades: remainingUpgrades,
            },
        }),
    });
};

const setUpgradePackages = (state, action) =>
    updateObject(state, {
        packages: action.packages,
    });

const setInitUpgradePackages = (state, action) =>
    updateObject(state, {
        initPackages: action.packages,
    });

const reorderUpgradePackages = (state, action) => {
    const updatedPackages = action.packages.reduce(
        (obj, id, index) => ({
            ...obj,
            [id]: {
                ...state.packages[id],
                ui: {
                    displayOrder: index,
                },
            },
        }),
        {}
    );

    return updateObject(state, {
        packages: updatedPackages,
    });
};

const updateUpgradeCost = (state, action) => {
    // console.log("from reducer", state, action);
    const upgrades = state.packages[action.packageId].upgrades;
    return updateObject(state, {
        packages: updateObject(state.packages, {
            [action.packageId]: updateObject(state.packages[action.packageId], {
                upgrades: updateObject(upgrades, {
                    [action.upgradeId]: updateObject(upgrades[action.upgradeId], {
                        cost: updateObject(upgrades[action.upgradeId]?.cost, {
                            ...upgrades[action.upgradeId]?.cost,
                            [action.upgradeType]: { total: action.cost, items: action?.items || {} },
                        }),
                    }),
                }),
            }),
        }),
    });
};

const updatePackageOperatingConditions = (state, action) => {
    const ui = state?.packages?.[action.packageId]?.ui || {};
    return updateObject(state, {
        packages: updateObject(state.packages, {
            [action.packageId]: updateObject(state.packages[action.packageId], {
                ui: updateObject(ui, {
                    operatingConditions: action.operatingConditions,
                }),
            }),
        }),
    });
};

const updatePackageBaseCase = (state, action) => {
    const ui = state?.packages?.[action.packageId]?.ui || {};
    return updateObject(state, {
        packages: updateObject(state.packages, {
            [action.packageId]: updateObject(state.packages[action.packageId], {
                ui: updateObject(ui, {
                    relativeBaseCase: action.relativeBaseCase,
                }),
            }),
        }),
    });
};

const updateUpgradeSnapshot = (state, action) => {
    return updateObject(state, {
        initPackagesSnapshot: action.upgrades,
    });
};
