import React from "react";
import classes from "../../style.module.scss";
import classNames from "classnames";
import DashboardItem from "..";

const Model = ({
    ref,
    modelId,
    name,
    startDelete,
    tags = [],
    setModelToMove,
    setModelToDuplicate,
    setDuplicateName,
    sharedWithUser = false,
    isSelected,
    isDragging,
    isDropInProcess,
    multiSelectedItems,
    setFolderItemsToDelete,
    isRenoCommModel,
    disableActions = false,
    details, // { userName, provTerr, currentPhase, lastEdited, targetCompletionDate }
    ...props
}) => {
    return (
        <DashboardItem
            ref={ref}
            className={classNames({
                [classes.selectedItem]: isSelected,
                [classes.itemDragging]: isDragging && isSelected,
                [classes.isDropInProcess]: isDropInProcess
            })}
            itemId={modelId}
            name={name}
            sharedWithUser={sharedWithUser}
            itemType={isRenoCommModel ? "CHBAModel" : "model"}
            details={details}
            tags={tags}
            actions={disableActions ? null : [
                {
                    label: "Duplicate Model",
                    onClick: () => {
                        setModelToDuplicate(modelId);
                        setDuplicateName(`${name} (1)`);
                    },
                },
                {
                    label: "Move Model",
                    onClick: () => {
                        setModelToMove(modelId);
                    },
                },
                {
                    label: "Delete Model",
                    onClick: (e) => {
                        startDelete(e, modelId);
                    },
                },
                {
                    label: `Delete ${multiSelectedItems.length} files`,
                    shouldShow: multiSelectedItems.length > 1,
                    onClick: () => {
                        setFolderItemsToDelete(multiSelectedItems); 
                    },
                },
            ]}
            {...props}
        />
    );
}

export default Model;