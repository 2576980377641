import React from "react";
import classes from "./style.module.scss";
import Button from "components/Button";
import SetScale from "./Scale";
import WholeComponents from "./WholeComponents/container";
import PolygonSet from "./Polygon/container";
import PolygonFields from "./Polygon/PolygonFields/container";
import Line from "./Line/container";

const SettingScale = ({ page = "Page", cancel }) => (
    <div>
        <p>
            <span className={classes.tip}>Getting Started:</span> Click anywhere on the active page to place the initial
            scale line.
        </p>
        <Button smallPadding onClick={cancel} type="hollow" className={classes.cancelButton}>
            Cancel
        </Button>
    </div>
);

const actions = (id) =>
    ({
        settingScale: SettingScale,
        setScale: SetScale,
        componentActions: WholeComponents,
        polygonSet: PolygonSet,
        polygonFields: PolygonFields,
        lineActions: Line,
        default: () => null,
    }[id || "default"]);

const Actions = ({ activeTool, units, actionId = "", meta = {}, setScale, scale, stage, modelChange }) => {
    const Action = actions(actionId);
    // console.log(meta);
    return (
        <div className={classes.actions}>
            <h3>Actions</h3>
            <div>
                <Action
                    {...meta}
                    setScale={setScale}
                    scale={scale}
                    units={units}
                    stage={stage}
                    modelChange={modelChange}
                />
            </div>
        </div>
    );
};

export default Actions;
