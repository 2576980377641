import React, { useState, useCallback } from "react";
import classes from "./style.module.scss";
import { DropDown } from "components/Input/Select";
import Add from "assets/images/icons/JSX/Add";
import { useOutsideClickHook } from "utils/outsideClick";
import Dialog, { CloseDialog } from "components/Dialog";
import Input from "components/Input";
import Button from "components/Button";
import { getComponentName, getComponentIcon } from "utils/enclosure";
import { required, maxLength } from "utils/fieldValidation";
import moment from "moment";
import { getComponentTemplate } from "utils/enclosure";
import { mixpanel } from "components/Mixpanel";

import { isEqual, merge } from "lodash";

import { modalComponentNameBuilder } from "./withAddComponent";

const charLim32 = maxLength(32);

//No subcomponents. Handled in ComponentsList/Actions/index.jsx
const codeCompMap = {
    wall: ["Wall", "Lintel"],
    ceiling: ["Ceiling"],
    expFloor: ["Floor"],
    basement: ["FloorsAbove", "BasementWall", "Lintel", "FloorsAdded"],
    crawlspace: ["FloorsAbove", "CrawlspaceWall", "Lintel", "FloorsAdded"],
    slab: ["FloorsAdded"],
};

export default React.memo(({ options, catId, newComponentDisplay, change, defaultParams, components }) => {
    const [open, toggleOpen] = useState(false);
    const [dialogOpen, toggleDialog] = useState(false);
    const [componentType, changeComponentType] = useState("wall");
    const [name, changeName] = useState("");

    const ref = useOutsideClickHook(() => toggleOpen(false));

    const invalidMessage = required(name) || charLim32(name);

    const addComponent = useCallback(
        ({ componentType, name, catId, displayOrder = 0 }) => {
            let newComponents = [];
            if (componentType === "walkout") {
                const defaultFields = codeCompMap["basement"].reduce((cache, currCode) => {
                    const { [currCode]: compDefaults = {} } = defaultParams;
                    return merge(cache, compDefaults);
                }, {});

                let tempBasement = {
                    componentId: `basement-${moment().format("YYYYMMDDHHmmssSS")}`,
                    type: "basement",
                    componentData: {
                        ...getComponentTemplate("basement"),
                        displayOrder,
                        label: `${name} - Walkout (Basement)`,
                        category: catId,
                    },
                };

                let tempSlab = {
                    componentId: `slab-${moment().format("YYYYMMDDHHmmssSS")}`,
                    type: "slab",
                    componentData: {
                        ...getComponentTemplate("slab"),
                        displayOrder,
                        label: `${name} - Walkout (Slab)`,
                        category: catId,
                    },
                };

                const { floor: floorDefaults = {}, wall: wallDefaults = {} } = defaultFields || {};

                tempBasement.componentData = {
                    ...tempBasement.componentData,
                    wall: {
                        ...tempBasement.componentData.wall,
                        ...wallDefaults,
                    },
                    floor: {
                        ...tempBasement.componentData.floor,
                        ...floorDefaults,
                    },
                };

                tempSlab.componentData = {
                    ...tempSlab.componentData,
                    floor: {
                        ...tempSlab.componentData.floor,
                        ...floorDefaults,
                    },
                };

                newComponents = [tempBasement, tempSlab];
            } else if (["basement", "crawlspace"].includes(componentType)) {
                const defaultFields = codeCompMap[componentType].reduce((cache, currCode) => {
                    const { [currCode]: compDefaults = {} } = defaultParams;
                    return merge(cache, compDefaults);
                }, {});

                let tempComp = {
                    componentId: `${componentType}-${moment().format("YYYYMMDDHHmmssSS")}`,
                    type: componentType,
                    componentData: {
                        ...getComponentTemplate(componentType),
                        displayOrder,
                        label: name,
                        category: catId,
                    },
                };

                const { floor: floorDefaults = {}, wall: wallDefaults = {} } = defaultFields || {};

                tempComp.componentData = {
                    ...tempComp.componentData,
                    wall: {
                        ...tempComp.componentData.wall,
                        ...wallDefaults,
                    },
                    floor: {
                        ...tempComp.componentData.floor,
                        ...floorDefaults,
                    },
                };

                newComponents = [tempComp];
            } else if (componentType === "slab") {
                const defaultFields = codeCompMap[componentType].reduce((cache, currCode) => {
                    const { [currCode]: compDefaults = {} } = defaultParams;
                    return merge(cache, compDefaults);
                }, {});

                let tempComp = {
                    componentId: `${componentType}-${moment().format("YYYYMMDDHHmmssSS")}`,
                    type: componentType,
                    componentData: {
                        ...getComponentTemplate(componentType),
                        displayOrder,
                        label: name,
                        category: catId,
                    },
                };

                const { floor: floorDefaults = {} } = defaultFields || {};

                tempComp.componentData = {
                    ...tempComp.componentData,
                    floor: {
                        ...tempComp.componentData.floor,
                        ...floorDefaults,
                    },
                };

                newComponents = [tempComp];
            } else {
                //Okay to spread default fields at root level
                const defaultFields = codeCompMap[componentType].reduce((cache, currCode) => {
                    const { [currCode]: compDefaults = {} } = defaultParams;
                    return {
                        ...cache,
                        ...compDefaults,
                    };
                }, {});

                newComponents = [
                    {
                        componentId: `${componentType}-${moment().format("YYYYMMDDHHmmssSS")}`,
                        type: componentType,
                        componentData: {
                            ...getComponentTemplate(componentType),
                            displayOrder,
                            label: name,
                            category: catId,
                        },
                        defaultFields,
                    },
                ];
            }

            mixpanel.track("Add Component", {
                Type: componentType,
                Category: catId,
            });

            return Promise.all(
                newComponents.map(({ type, componentData, componentId, defaultFields = {} }) => {
                    change(`modelData.components.${type}.${componentId}`, componentData);
                    Object.keys(defaultFields).forEach((path) =>
                        change(`modelData.components.${type}.${componentId}.${path}`, defaultFields[path])
                    );
                    if (Object.keys(componentData).includes("wall")) {
                        change(
                            `modelData.components.${type}.${componentId}.wall.lintelInsType`,
                            defaultFields[`lintelInsType`]
                        );
                    }
                })
            );
        },
        [change, defaultParams]
    );

    const pushComponent = async () => {
        await addComponent({
            componentType,
            name,
            catId,
            displayOrder: newComponentDisplay,
        });
        toggleDialog(false);
    };

    return (
        <div className={classes.addComponent} ref={ref}>
            <div
                className={`${classes.addText} ${open && classes.open}`}
                onClick={() => {
                    toggleOpen(!open);
                }}
            >
                Add Component <Add />
            </div>
            <DropDown
                className={classes.dropDown}
                options={options}
                open={open}
                toggleOpen={toggleOpen}
                onChange={(value) => {
                    changeComponentType(value);
                    toggleDialog(true);
                    changeName(modalComponentNameBuilder(value, components));

                    //? Here i need to pass the value but, more importantly, find the
                    //? all elements of the same type
                }}
            />
            <Dialog actionkey={"Enter"} closeActions={pushComponent} open={dialogOpen}>
                <CloseDialog
                    closeActions={() => {
                        toggleDialog(false);
                        changeName("");
                    }}
                />
                <div className={classes.nameDialog}>
                    {getComponentIcon(componentType) && (
                        <img src={getComponentIcon(componentType)} alt={`${getComponentName(componentType)} icon`} />
                    )}
                    <h2>New {getComponentName(componentType)}</h2>
                    <Input
                        type="text"
                        name="componentName"
                        label="Name Component"
                        placeholder="Name Component"
                        hideLabel
                        input={{
                            value: name,
                            onChange: (value) => changeName(value),
                        }}
                        meta={{
                            error: invalidMessage,
                            invalid: !!invalidMessage,
                        }}
                    />
                    <div className={classes.footer}>
                        <Button
                            type="hollow"
                            onClick={() => {
                                toggleDialog(false);
                                changeName("");
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={async () => {
                                await addComponent({
                                    componentType,
                                    name,
                                    catId,
                                    displayOrder: newComponentDisplay,
                                });
                                toggleDialog(false);
                            }}
                            disabled={invalidMessage}
                            // keyPress={true}
                            // keyCb={true}
                        >
                            Add {getComponentName(componentType)}
                        </Button>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}, isEqual);
