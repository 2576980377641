const types = {
    DRAWING_DATA_SAVING_START: "DRAWING_DATA_SAVING_START",
    DRAWING_DATA_SAVING_SUCCESS: "DRAWING_DATA_SAVING_SUCCESS",
    DRAWING_DATA_SAVING_FAILURE: "DRAWING_DATA_SAVING_FAILURE",
    UPLOADING_DRAWING_START: "UPLOADING_DRAWING_START",
    UPLOADING_DRAWING_SUCCESS: "UPLOADING_DRAWING_SUCCESS",
    UPLOADING_DRAWING_FAILURE: "UPLOADING_DRAWING_FAILURE",
    GENERATING_DRAWING_START: "GENERATING_DRAWING_START",
    GENERATING_DRAWING_SUCCESS: "GENERATING_DRAWING_SUCCESS",
    GENERATING_DRAWING_FAILURE: "GENERATING_DRAWING_FAILURE",
    FETCH_DRAWING_DATA_START: "FETCH_DRAWING_DATA_START",
    FETCH_DRAWING_DATA_SUCCESS: "FETCH_DRAWING_DATA_SUCCESS",
    FETCH_DRAWING_DATA_ERROR: "FETCH_DRAWING_DATA_ERROR",
    SET_UPLOADING_PROGRESS: "SET_UPLOADING_PROGRESS",
    SET_MODEL_FILES: "SET_MODEL_FILES",
    TOGGLE_IMAGE_PROCESSING: "TOGGLE_IMAGE_PROCESSING",
    UPDATE_STAGE: "UPDATE_STAGE",
    SET_MESSAGE: "SET_MESSAGE",
    SET_TOOLS: "SET_TOOLS",
    SET_ACTIVE_COMPONENT: "SET_ACTIVE_COMPONENT",
    SET_SCALE: "SET_SCALE",
    SET_ACTION: "SET_ACTION",
    SET_ACTIVE_IMAGE_INDEX: "SET_ACTIVE_IMAGE_INDEX",
    SET_SCALE: "SET_SCALE",
    SET_ACTIVE_TOOL: "SET_ACTIVE_TOOL",
    SET_POLYGON: "SET_POLYGON",
    SET_LINE: "SET_LINE",
    SET_RECTANGLE: "SET_RECTANGLE",
    SET_MULTI_POINT_LINE: "SET_MULTI_POINT_LINE",
    SET_DID_USER_SAW_NEW_DRAWING: "SET_DID_USER_SAW_NEW_DRAWING",
    REMOVE_POLYGON: "REMOVE_POLYGON",
    REMOVE_RECTANGLE: "REMOVE_RECTANGLE",
    REMOVE_LINE: "REMOVE_LINE",
    REMOVE_MULTI_POINT_LINE: "REMOVE_MULTI_POINT_LINE",
    TOGGLE_IS_COPY_SCALE_OPEN: "TOGGLE_IS_COPY_SCALE_OPEN",
    CLEAR_DRAWING_DATA: "CLEAR_DRAWING_DATA",
    RESET_DRAWING: "RESET_DRAWING",
    IMAGE_DATA_SNAPSHOT_UPDATE: "IMAGE_DATA_SNAPSHOT_UPDATE",
};

export default types;
