import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import ArrowDown from "assets/images/icons/JSX/arrow-down";
import { Collapse } from "@material-ui/core";
import { isEqual } from "lodash";
import classNames from "classnames";
import { useLocation } from "react-router";
import isEmpty from "lodash/isEmpty";

const Accordion = React.memo(
    ({
        children,
        heading,
        id = "",
        hoverActions = null,
        className,
        headerClassName,
        bodyClassName,
        large = false,
        stats: Stats,
        initOpenState = false,
        isDragging = false,
        isNew = false,
        dragActive = false,
        toggleNew = () => "",
        disabled = false,
        number,
        forceOpen = false,
        forceClose = false,
        // toggleFunction,
    }) => {
        const [open, toggleOpen] = useState(initOpenState);
        const [highlight, toggleHighlight] = useState(isNew);

        const containerClasses = [
            classes.accordion,
            open && !isDragging ? classes.open : "",
            className && className,
            large && classes.large,
            isDragging && classes.dragging,
            highlight && classes.highlight,
            dragActive && classes.dragActive,
            disabled && classes.disabled,
        ].filter((clss) => clss);

        useEffect(() => {
            if (isDragging) {
                toggleOpen(false);
            }

            if (forceOpen) {
                toggleOpen(true);
            } else {
                toggleOpen(initOpenState);
            }
        }, [forceOpen, isDragging]);

        useEffect(() => {
            if (forceClose) {
                toggleOpen(false);
            }
        }, [forceClose]);

        const isEnclosure = !!(Stats && hoverActions); //used to prevent width = 100% which would push stats content to the right

        return (
            <div
                className={containerClasses.join(" ")}
                onClick={() => {
                    toggleHighlight(false);
                    if (isNew) {
                        toggleNew();
                    }
                    // console.log(toggleFunction);
                    // if (toggleFunction) {
                    //     toggleFunction()
                    // }
                }}
            >
                <div
                    className={classNames(classes.header, {
                        [classes.hasNumber]: number,
                        [headerClassName]: headerClassName,
                    })}
                >
                    {number && <span className={classes.number}>{number}</span>}
                    <div
                        className={`${classes.heading} ${isEnclosure && classes.isEnclosure}`}
                        onClick={(e) => {
                            if (e.target.tagName !== "svg") {
                                toggleOpen(!open);
                            }
                        }}
                    >
                        {heading}
                    </div>

                    {Stats && <div className={classes.stats}>{typeof Stats === "function" ? <Stats /> : Stats}</div>}
                    {hoverActions && <div className={classes.hoverActions}>{hoverActions}</div>}
                    <div
                        className={classes.toggle}
                        onClick={() => {
                            toggleOpen(!open);
                        }}
                    >
                        <ArrowDown />
                    </div>
                </div>
                <Collapse
                    in={open}
                    timeout="auto"
                    unmountOnExit
                    style={{ height: isDragging ? 0 : "auto", overflow: isDragging ? "hidden" : "" }}
                >
                    <div className={classNames(classes.body, { [bodyClassName]: bodyClassName })}>
                        {children}
                    </div>
                </Collapse>
            </div>
        );
    },
    isEqual
);

export default Accordion;
