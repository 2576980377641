import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import isEmpty from "lodash/isEmpty";

import convertUnit from "utils/convertUnit";

import { selectActiveComponent, selectActiveImageIndex, selectImageData } from "../_ducks/selector";

import DisplayPolygonInfo from "./DisplayPolygonInfo";
import DisplayRectangleInfo from "./DisplayRectangleInfo";
import DisplayLineInfo from "./DisplayLineInfo";
import DisplayMultiPointInfo from "./DisplayMultiPointInfo";

import { info, label, scaleValue, value, activeComponent } from "./style.module.scss";

const Info = ({ images, activeImageIndex, imageData, activeComponent }) => {
    const currentImageData = images[activeImageIndex] && imageData[images[activeImageIndex].fileName];

    const currentScale = images[activeImageIndex] && currentImageData ? currentImageData.scale : {};

    const { polygons = {}, rectangles = {}, lines = {}, multiPointLines = {} } = currentImageData || {};

    const polygon = polygons[activeComponent] || {};
    const rectangle = rectangles[activeComponent] || {};
    const line = lines[activeComponent] || {};
    const multiPointLine = multiPointLines[activeComponent] || {};

    const { px, input = 0, units = "m", displayUnits = "m", copiedFromName = "" } = currentScale || {};

    const scaleDisplayValue = convertUnit({
        value: input,
        type: "drawingScale",
        inputUnit: units,
        outputUnit: displayUnits,
    });

    const conversionFactor = input / px;

    return (
        <div className={info}>
            <h3>Info</h3>
            {currentScale && currentScale.isSet && (
                <div className={scaleValue}>
                    <span className={label}>{`Scale${copiedFromName ? ` (from ${copiedFromName})` : ""}:`}</span>{" "}
                    <span className={value}>{`${scaleDisplayValue.toFixed(4)} ${displayUnits}`}</span>
                </div>
            )}

            {!isEmpty(polygon) && polygon.isSet && (
                <DisplayPolygonInfo conversionFactor={conversionFactor} displayUnits={displayUnits} polygon={polygon} />
            )}

            {!isEmpty(rectangle) && (
                <DisplayRectangleInfo
                    rectangle={rectangle}
                    conversionFactor={conversionFactor}
                    displayUnits={displayUnits}
                />
            )}
            {!isEmpty(line) && (
                <DisplayLineInfo line={line} conversionFactor={conversionFactor} displayUnits={displayUnits} />
            )}
            {!isEmpty(multiPointLine) && multiPointLine.isSet && (
                <DisplayMultiPointInfo
                    line={multiPointLine}
                    conversionFactor={conversionFactor}
                    displayUnits={displayUnits}
                />
            )}
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    activeImageIndex: selectActiveImageIndex,
    imageData: selectImageData,
    activeComponent: selectActiveComponent,
});

export default connect(mapStateToProps, null)(Info);
