import React, { useState } from "react";

import SidebarSelectors from "../../SidebarSelectors";

import styles from "../styles.module.scss";

import ArrowIcon from "assets/images/icons/arrow-down.svg";
import { ReactComponent as GreenCheckIcon } from "assets/images/icons/GreenCheck.svg";

const TagSection = ({
    requiredTags,
    customTags,
    selectedTag,
    setSelectedTag,
    includedTags,
    tagsSectionTitle,
    allImages,
}) => {
    const [isOpen, setIsOpen] = useState(true);

    const fixedAllImages = allImages
        ? allImages.map((img) => (img.tags === undefined ? { ...img, tags: [] } : img))
        : [];

    return (
        <>
            <div
                className={`${styles.requredTagsCloseContainer} ${!isOpen ? styles.closed : ""}`}
                onClick={() => setIsOpen(!isOpen)}
            >
                <span>{tagsSectionTitle}</span>
                <img src={ArrowIcon} />
            </div>
            <div className={`${styles.requiredTagsOpenedWrapper} ${!isOpen ? styles.requiredTagsClosedWrapper : ""}`}>
                {requiredTags.map((tag) =>
                    includedTags
                        ? tag.isInModel && (
                              <SidebarSelectors
                                  key={tag.id}
                                  type="tag"
                                  Icon={
                                      fixedAllImages?.filter((img) => img.tags.map((t) => t.id).includes(tag.id))
                                          ?.length >= tag.requiredAmountOfImages
                                          ? () => <GreenCheckIcon />
                                          : null
                                  }
                                  text={tag.text}
                                  number={
                                      fixedAllImages
                                          ? fixedAllImages.filter((img) => img.tags.map((t) => t.id).includes(tag.id))
                                                .length
                                          : 0
                                  }
                                  selected={selectedTag === tag.id}
                                  onSelect={() => setSelectedTag(tag.id)}
                                  isDisabled={
                                      fixedAllImages
                                          ? fixedAllImages.filter((img) => img.tags.map((t) => t.id).includes(tag.id))
                                                .length === 0
                                          : false
                                  }
                              />
                          )
                        : customTags
                        ? tag.isCustom && (
                              <SidebarSelectors
                                  key={tag.id}
                                  type="tag"
                                  Icon={null}
                                  text={tag.text}
                                  number={
                                      fixedAllImages
                                          ? fixedAllImages.filter((img) => img.tags.map((t) => t.id).includes(tag.id))
                                                .length
                                          : 0
                                  }
                                  selected={selectedTag === tag.id}
                                  onSelect={() => setSelectedTag(tag.id)}
                                  isDisabled={
                                      fixedAllImages
                                          ? fixedAllImages.filter((img) => img.tags.map((t) => t.id).includes(tag.id))
                                                .length === 0
                                              ? true
                                              : false
                                          : false
                                  }
                              />
                          )
                        : !tag.isInModel &&
                          !tag.isCustom && (
                              <SidebarSelectors
                                  key={tag.id}
                                  type="tag"
                                  Icon={null}
                                  text={tag.text}
                                  number={tag.number}
                                  selected={selectedTag === tag}
                                  onSelect={() => setSelectedTag(tag.id)}
                                  isDisabled={true}
                              />
                          )
                )}
            </div>
        </>
    );
};

export default TagSection;
