import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import Specifications from './';

const mapStateToProps = (
    {
        form
    },
    {
        accessor
    }
) => {
    const selector = formValueSelector('model');
    const isDefaultRoofInputs = selector({form}, `${accessor}.isDefaultRoofCavity`) || true;
    const {id: wallColourId, value: wallColourValue} = selector({form}, `${accessor}.wallColour`) || {};
    const {id: roofColourId, value: roofColourValue} = selector({form}, `${accessor}.roofColour`) || {};
    const { major: h2kMajor, minor: h2kMinor} = selector({form}, 'modelData.h2kVersion') || {};
    const modelUnits = selector({form}, `modelData.uiSettings.primaryUnits`) || {};

    return {
        accessor,
        isDefaultRoofInputs,
        h2kVersion: `v${h2kMajor}-${h2kMinor}`,
        h2kMinor,
        wallColourId,
        roofColourId,
        wallColourValue,
        roofColourValue,
        modelUnits
    }
};

const mapDispatchToProps = () => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Specifications);