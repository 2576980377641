import { firestore, MODEL_COLL } from "_firebase";

import {
    getChbanzChecklistStart,
    getChbanzChecklistSuccess,
    getChbanzChecklistError,
    saveChecklistSuccess,
} from "./actions";

import { isEmpty } from "lodash";

export const getChbaNzChecklist = (modelId) => async (dispatch) => {
    try {
        dispatch(getChbanzChecklistStart());

        // console.log("MODEL: ", modelId)

        const chbaNzChecklist = await firestore
            .doc(`${MODEL_COLL}/${modelId}`)
            .collection("modelData")
            .doc("checklist")
            .get();

        const items = chbaNzChecklist.data();

        dispatch(getChbanzChecklistSuccess({ items }));
    } catch (error) {
        dispatch(getChbanzChecklistError(error));
    }
};

export const saveChbaNzChecklist = () => async (dispatch, getState) => {
    const { model: { modelId } = {}, chbaChecklist } = getState();

    if (!modelId || isEmpty(chbaChecklist)) {
        return;
    }

    await firestore.doc(`${MODEL_COLL}/${modelId}`).collection("modelData").doc("checklist").set(chbaChecklist);

    dispatch(saveChecklistSuccess(chbaChecklist?.items || {}));
};
