const SAVE_UPDATE_UPGRADE_PACKAGE_START = "SAVE_UPDATE_UPGRADE_PACKAGE_START";
const SAVE_UPDATE_UPGRADE_PACKAGE_SUCCESS = "SAVE_UPDATE_UPGRADE_PACKAGE_SUCCESS";
const SAVE_UPDATE_UPGRADE_PACKAGE_ERROR = "SAVE_UPDATE_UPGRADE_PACKAGE_ERROR";
const FETCH_UPGRADE_LABELS_START = "FETCH_UPGRADE_LABELS_START";
const FETCH_UPGRADE_LABELS_SUCCESS = "FETCH_UPGRADE_LABELS_SUCCESS";
const FETCH_UPGRADE_LABELS_ERROR = "FETCH_UPGRADE_LABELS_ERROR";
const FETCH_UPGRADE_PACKAGE_START = "FETCH_UPGRADE_PACKAGE_START";
const FETCH_UPGRADE_PACKAGE_SUCCESS = "FETCH_UPGRADE_PACKAGE_SUCCESS";
const FETCH_UPGRADE_PACKAGE_ERROR = "FETCH_UPGRADE_PACKAGE_ERROR";
const DELETE_UPGRADE_PACKAGE_START = "DELETE_UPGRADE_PACKAGE_START";
const DELETE_UPGRADE_PACKAGE_SUCCESS = "DELETE_UPGRADE_PACKAGE_SUCCESS";
const DELETE_UPGRADE_PACKAGE_ERROR = "DELETE_UPGRADE_PACKAGE_ERROR";
const CHANGE_NAME_UPGRADE_PACKAGE_START = "CHANGE_NAME_UPGRADE_PACKAGE_START";
const CHANGE_NAME_UPGRADE_PACKAGE_SUCCESS = "CHANGE_NAME_UPGRADE_PACKAGE_SUCCESS";
const CHANGE_NAME_UPGRADE_PACKAGE_ERROR = "CHANGE_NAME_UPGRADE_PACKAGE_ERROR";
const DUPLICATE_UPGRADE_PACKAGE_START = "DUPLICATE_UPGRADE_PACKAGE_START";
const DUPLICATE_UPGRADE_PACKAGE_SUCCESS = "DUPLICATE_UPGRADE_PACKAGE_SUCCESS";
const DUPLICATE_UPGRADE_PACKAGE_ERROR = "DUPLICATE_UPGRADE_PACKAGE_ERROR";
const FETCH_ORGANIZATION_UPGRADES_START = "FETCH_ORGANIZATION_UPGRADES_START";
const FETCH_ORGANIZATION_UPGRADES_SUCCESS = "FETCH_ORGANIZATION_UPGRADES_SUCCESS";
const FETCH_ORGANIZATION_UPGRADES_ERROR = "FETCH_ORGANIZATION_UPGRADES_ERROR";
const UPDATE_UPGRADE = "UPDATE_UPGRADE";
const DELETE_UPGRADE = "DELETE_UPGRADE";
const RESET_UPGRADE_LIBRARY = "RESET_UPGRADE_LIBRARY";
const SELECT_UPGRADE = "SELECT_UPGRADE";

export default {
    SAVE_UPDATE_UPGRADE_PACKAGE_SUCCESS,
    SAVE_UPDATE_UPGRADE_PACKAGE_START,
    SAVE_UPDATE_UPGRADE_PACKAGE_ERROR,
    FETCH_UPGRADE_LABELS_START,
    FETCH_UPGRADE_LABELS_SUCCESS,
    FETCH_UPGRADE_LABELS_ERROR,
    FETCH_UPGRADE_PACKAGE_START,
    FETCH_UPGRADE_PACKAGE_SUCCESS,
    FETCH_UPGRADE_PACKAGE_ERROR,
    DELETE_UPGRADE_PACKAGE_START,
    DELETE_UPGRADE_PACKAGE_SUCCESS,
    DELETE_UPGRADE_PACKAGE_ERROR,
    CHANGE_NAME_UPGRADE_PACKAGE_START,
    CHANGE_NAME_UPGRADE_PACKAGE_SUCCESS,
    CHANGE_NAME_UPGRADE_PACKAGE_ERROR,
    DUPLICATE_UPGRADE_PACKAGE_START,
    DUPLICATE_UPGRADE_PACKAGE_SUCCESS,
    DUPLICATE_UPGRADE_PACKAGE_ERROR,
    UPDATE_UPGRADE,
    DELETE_UPGRADE,
    RESET_UPGRADE_LIBRARY,
    SELECT_UPGRADE,
    FETCH_ORGANIZATION_UPGRADES_START,
    FETCH_ORGANIZATION_UPGRADES_SUCCESS,
    FETCH_ORGANIZATION_UPGRADES_ERROR,
};
