import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./PackageDeleteDialog.module.scss";
import Dialog, { CloseDialog } from "components/Dialog";
import Button from "components/Button";
import { actions } from "../_ducks";
import { selectUpgradePackages } from "../_ducks/selectors";

const { deleteUpgradePackage } = actions;

const PackageDeleteDialog = ({ toDelete, toggleToDelete }) => {
    const [deleting, toggleDeleting] = useState(false);
    const dispatch = useDispatch();

    const upgradePackages = useSelector(selectUpgradePackages);

    return (
        <Dialog open={!!toDelete}>
            <CloseDialog closeActions={() => toggleToDelete("")} />
            <div className={classes.dialog}>
                <h3>
                    Are you sure you want to delete{" "}
                    <strong>{upgradePackages[toDelete]?.name}</strong>?
                </h3>
                <div className={classes.footer}>
                    <Button type="hollow" onClick={() => toggleToDelete("")}>
                        Cancel
                    </Button>
                    <Button
                        onClick={async () => {
                            toggleDeleting(true);
                            await dispatch(
                                deleteUpgradePackage({ packageId: toDelete })
                            );
                            toggleToDelete("");
                            toggleDeleting(false);
                        }}
                        type="red"
                        disabled={deleting}
                    >
                        {deleting ? "Deleting..." : "Delete"}
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default PackageDeleteDialog;
