import React, { useEffect, useState } from "react";
import Plotly from "plotly.js-cartesian-dist";
import classes from "./Heatmap.module.scss";

import createPlotlyComponent from "react-plotly.js/factory";
import { isEmpty } from "lodash";

import BlurredHeatmap from "assets/images/heatmap/BlurredHeatmap.jpg";

let width = 1050;
let height = 500;

// if (window.innerWidth < 1480) {
//     width = 700
// }

const Plot = createPlotlyComponent(Plotly);

const Heatmap = ({ hourlyData = [], dataSet = "", maxHourlyLoad = 0, normalizeHourlyScale = false }) => {
    const [chartUnits, setChartUnits] = useState("kW");

    useEffect(() => {
        if (dataSet.toLowerCase().includes("emission")) {
            setChartUnits("gCO₂");
        } else {
            setChartUnits("kW");
        }
    }, [dataSet]);

    const heatmapArray = [];
    hourlyData = hourlyData.slice(0, 8760);

    const chunkSize = 24;
    for (let i = 0; i < hourlyData.length; i += chunkSize) {
        const chunk = hourlyData.slice(i, i + chunkSize);
        heatmapArray.push(chunk);
    }

    if (normalizeHourlyScale && !dataSet.toLowerCase().includes("emission")) {
        const maxChunk = new Array(chunkSize).fill(maxHourlyLoad);
        heatmapArray.push(maxChunk); //This overflow line will be hidden
    }

    let hours = [];
    for (let i = 0; i < 24; i++) {
        hours.push(i);
    }

    let days = [];
    for (let i = 0; i < 365; i++) {
        days.push(i);
    }

    if (!isEmpty(hourlyData)) {
        var text = hours.map((hourOfDay, j) => {
            //j 0 -> 23
            return days.map((dayOfYear, i) => {
                //i 0 -> 365
                const hourlyVal = heatmapArray[i][j] || 0;
                return `Day ${i + 1}, Hour ${j} <br> <b>${hourlyVal.toFixed(2)}</b> ${chartUnits} `;
            });
        });
    }

    let colorscale = [
        [0, "#0049c6"],
        [0.25, "#18c1ad"],
        [0.75, "#ffb22d"],
        [1, "#c61717"],
    ];
    if (Math.min(...hourlyData) < 0) {
        const range = Math.max(...hourlyData) - Math.min(...hourlyData);
        const negativeFraction = Math.round((1000 * Math.abs(Math.min(...hourlyData))) / range) / 1000;
        const positiveFraction = 1 - negativeFraction;

        colorscale = [
            [0, "purple"],
            // [negativeFraction, "white"],
            [negativeFraction + 0.001, "#0049c6"],
            [0.25 * positiveFraction + negativeFraction, "#18c1ad"],
            [0.75 * positiveFraction + negativeFraction, "#ffb22d"],
            [1, "#c61717"],
        ];
    }

    return (
        <div className={classes.heatmap}>
            <div>
                {!isEmpty(hourlyData) ? (
                    <Plot
                        data={[
                            {
                                type: "heatmap",
                                z: heatmapArray,
                                x: days,
                                y: hours,
                                transpose: true,
                                colorscale: colorscale,
                                text: text,
                                // textfont: {
                                //   family: 'sans serif',
                                //   size: 18,
                                //   color: '#1f77b4'
                                // },
                                hoverinfo: "text",
                                zsmooth: "best",
                            },
                        ]}
                        layout={{
                            title: {
                                text: `<b>${dataSet}</b>`,
                                font: {
                                    family: "Noto Sans TC, sans serif",
                                    color: "#000",
                                    // family: 'Courier New, monospace',
                                    size: 18,
                                },
                                xref: "paper",
                                x: 0.0,
                            },
                            xaxis: {
                                title: "Day of year",
                                range: [0, 364], // used to apply limits if normalized maximum was used
                            },
                            yaxis: { title: "Hour of day" },
                            autosize: false,
                            width: width,
                            height: height,
                            margin: {
                                l: 50,
                                //   r: 50,
                                b: 100,
                                // t: 100,
                                pad: 0,
                                //   t: 100,
                                // pad: 4,
                            },
                        }}
                    />
                ) : (
                    <div className={classes.placeholder}>
                        <div className={classes.buildingDiagram}>
                            <img src={BlurredHeatmap} alt={BlurredHeatmap} />
                        </div>
                        <p>No data available for selected case</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Heatmap;
