import React from "react";

import styles from "./styles.module.scss";

const StatusRadio = ({ checked, label, onChange, inputProps }) => (
    <div className={styles.radioInputContainer} onClick={onChange}>
        <input checked={checked} type={"radio"} {...inputProps} />
        <span>{label}</span>
    </div>
);

export default StatusRadio;
