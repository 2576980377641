import { connect } from 'react-redux';
import Checklist from './';
import { actions } from "features/Model/_ducks";

const { updateChecklistItem } = actions

const mapStateToProps = (
    {
        model:{
            checklist:{
                list={}
            }={},
        },
        form
    }
) => {
    return {
        list,
    }
};

const mapDispatchToProps = (dispatch) => ({
    updateChecklistItem: (id, updates) => dispatch(updateChecklistItem(id, updates))
});

export default connect(mapStateToProps, mapDispatchToProps)(Checklist);
