export const authErrors = ({ code, message }) => {
    switch (code) {
        case 'auth/email-already-in-use':
            return `We're sorry, an account with that email aready exists.`;
        case 'auth/weak-password':
            return `Please enter a stronger password.`;
        case 'auth/invalid-email':
            return `Please enter a valid email.`;
        case 'auth/wrong-password':
            return `We're sorry, the password is incorrect.`;
        case 'auth/user-disabled':
            return `The account associated with this email has been disabled.`;
        case 'auth/user-not-found':
            return `We're sorry, we can't find an account associated with this email address.`;
        default:
            return message;
    }
};
