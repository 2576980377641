import React from 'react';
import classes from './style.module.scss';
import classNames from "classnames";
import OpenIcon from 'assets/images/icons/JSX/OpenDrawer';
import { mixpanel } from 'components/Mixpanel';

export default ({
    title='',
    description='',
    children,
    detailsTrigger,
    detailsText,
    style,
    additionalContent,
    className,
    noBorder
}) => (
    <div className={classNames(classes.tabHeader, noBorder && classes.noBorder, { [className]:className })} style={style}>
        <div className={classes.heading}>
            <h3>{title}</h3>
            <p>{description}</p>
        </div>
        {additionalContent && <div className={classes.additionalContent}>{additionalContent}</div>}
        {
            detailsText &&
                <span
                    className={classes.details}
                    onClick={() => {
                        detailsTrigger();
                        mixpanel.track("Details Drawer Open", {
                            "Drawer Name": detailsText
                        });
                    }}
                >
                    {detailsText} <OpenIcon />
                </span>
        }
    </div>
)