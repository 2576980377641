import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import Airtightness from "./";
import { isEmpty } from "lodash";
import { calcBuildingSurfaceArea } from "utils/enclosure/geometry";

const mapStateToProps = (
    { form, model: { contentOpen = false, modelId } = {}, upgradeLibrary: { currentPackage = {} } },
    { accessor, formName = "model", isUpgradeLibrary = false }
) => {
    const selector = formValueSelector(formName);
    const modelSelector = formValueSelector("model");

    const { id: airTightnessTypeId } = selector({ form }, `${accessor}.testType`) || {};
    const achValue = selector({ form }, `${accessor}.blowerTest.airChangeRate50Pa`);
    const { id: pressureId } = selector({ form }, `${accessor}.blowerTest.pressure`) || {};
    const eqLeakageValue = selector({ form }, `${accessor}.blowerTest.eqLeakageArea`) || 0;
    const eqLeakageUnits = selector({ form }, `${accessor}.blowerTest.eqLeakageArea_u`);
    const exhaustDeviceTestUnits = selector({ form }, `${accessor}.exhaustDevicesTest_u`);
    const anemometerUnits = selector({ form }, `${accessor}.other.anemometerHeight_u`);
    const isEqLeakageAreaCalculated = selector({ form }, `${accessor}.blowerTest.isEqLeakageAreaCalculated`);
    const { id: exhaustTestId, result: exhaustTestResult } = selector({ form }, `${accessor}.exhaustDevicesTest`) || {};
    const isDefaultLeakage = selector({ form }, `${accessor}.other.leakageFractions.useDefaults`);
    const commonFloorsValue = selector({ form }, `${accessor}.commonSurfaces.floors`) || 0;
    const commonWallsValue = selector({ form }, `${accessor}.commonSurfaces.walls`) || 0;
    const commonCeilingsValue = selector({ form }, `${accessor}.commonSurfaces.ceilings`) || 0;
    const commonFloorsUnits = selector({ form }, `${accessor}.commonSurfaces.floors_u`);
    const commonWallsUnits = selector({ form }, `${accessor}.commonSurfaces.walls_u`);
    const commonCeilingsUnits = selector({ form }, `${accessor}.commonSurfaces.ceilings_u`);
    const commonTotalUnits = selector({ form }, `${accessor}.commonSurfaces.total_u`);
    const buildingSurfaceAreaUnits = selector({ form }, `${accessor}.buildingSurfaceArea_u`);

    const normalizedAirLeakageRateValue = selector({ form }, `${accessor}.normalizedAirLeakageRate`);
    const normalizedAirLeakageRateUnits = selector({ form }, `${accessor}.normalizedAirLeakageRate_u`);
    const normalizedLeakageAreaUnits = selector({ form }, `${accessor}.normalizedLeakageArea_u`);

    const { id: windowTightnessId, value: windowTightnessValue } =
        modelSelector({ form }, `modelData.specifications.windowTightness`) || {};
    const { id: buildingTypeId } = modelSelector({ form }, `modelData.specifications.buildingType`) || {};
    const modelUnits = modelSelector({ form }, `modelData.uiSettings.primaryUnits`) || {};
    const houseVolume = modelSelector({ form }, `modelData.dimensions.volume.total`) || 0;
    const components = modelSelector({ form }, "modelData.components") || {};
    const uploadedData = modelSelector({ form }, `${accessor}.uploadedData`) || {
        data: "",
    };

    const airtightnessInputType = selector({ form }, `${accessor}.airtightnessInputType`) || "";

    const isMURB = buildingTypeId !== 0;
    const totalCommonAreaValue = commonFloorsValue + commonWallsValue + commonCeilingsValue || 0;

    const { major: h2kMajor, minor: h2kMinor } = selector({ form }, "modelData.h2kVersion") || {};
    const isAirTestEnabled = selector({ form }, `modelData.airTightness.testDataEnabled`);
    const testData = selector({ form }, `modelData.airTightness.testData`) || {};

    //ACH = Q@∆P / volume => [L/s] or [cfm]
    const airflow = achValue * houseVolume * 1000 * (1 / 3600); //[houses/h] * [m3/house] * [1000 L/1m3] * [1h/3600s]

    //NLR = Q@∆P / SA => [L/s/m2]
    const componentSurfaceArea = calcBuildingSurfaceArea(components, false);
    const buildingSurfaceArea = totalCommonAreaValue + componentSurfaceArea;

    const normalizedAirLeakageRate = airflow / buildingSurfaceArea;

    const achFromNlr = parseFloat(
        ((normalizedAirLeakageRateValue * buildingSurfaceArea) / (houseVolume * 1000 * (1 / 3600))).toFixed(2)
    );

    //Normalized Leakage Area (NLA) = ELA / SA
    //Note that ELA is already at 10Pa
    // const assumedFlowExponent = 0.65;
    const normalizedLeakageArea = eqLeakageValue / buildingSurfaceArea;

    return {
        accessor,
        modelUnits,
        modelId,
        contentOpen,
        isAirTestEnabled,
        eqLeakageValue,
        eqLeakageUnits,
        exhaustDeviceTestUnits,
        anemometerUnits,
        isEqLeakageAreaCalculated,
        pressureId,
        airTightnessTypeId,
        exhaustTestId,
        exhaustTestResult,
        isDefaultLeakage,
        achValue,
        windowTightnessId,
        windowTightnessValue,
        commonFloorsUnits,
        commonWallsUnits,
        commonCeilingsUnits,
        commonTotalUnits,
        totalCommonAreaValue,
        isMURB,
        houseVolume: isUpgradeLibrary ? 600 : houseVolume,
        contentOpen,
        isUpgradeLibrary,
        h2kMinor,
        airTestDataEmpty: isEmpty(testData),
        normalizedAirLeakageRate,
        achFromNlr,
        airtightnessInputType,
        normalizedLeakageArea,
        buildingSurfaceArea,
        buildingSurfaceAreaUnits,
        uploadedData,
        normalizedAirLeakageRateUnits,
        normalizedLeakageAreaUnits,
    };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Airtightness);
