import React from "react";

import useComponentVisible from "utils/hooks";
import { capFirstLetter } from "utils/generalUtils/generalUtils";

import DropdownMenu from "components/DropdownMenu";
import DropdownItem from "components/DropdownMenu/DropdownItem";

import { dataEntryModeContainer, arrow, arrowOpen } from "./style.module.scss";

import iArrow from "assets/images/icons/blue-arrow-down.svg";

const DataEntryMode = ({ value, onChange }) => {
    const { ref, isComponentVisible, setIsComponentVisible } =
        useComponentVisible(false);

    return (
        <div
            className={dataEntryModeContainer}
            ref={ref}
            onClick={() => setIsComponentVisible(!isComponentVisible)}
        >
            <span>
                Data entry mode:
                <span style={{ fontWeight: "bold" }}>
                    {" "}
                    {value.toLowerCase() === "hot2000"
                        ? capFirstLetter(value).toUpperCase()
                        : capFirstLetter(value)}{" "}
                </span>
            </span>
            <img
                className={`${arrow} ${isComponentVisible && arrowOpen}`}
                src={iArrow}
                alt={iArrow}
            />
            <DropdownMenu isOpen={isComponentVisible} top={35}>
                <DropdownItem
                    onClick={() => onChange("Hot2000")}
                    text={"HOT2000"}
                />
                <DropdownItem
                    onClick={() => onChange("combined")}
                    text={"Combined"}
                />
                <DropdownItem
                    onClick={() => onChange("takeoff")}
                    text={"Takeoff"}
                />
            </DropdownMenu>
        </div>
    );
};

export default DataEntryMode;
