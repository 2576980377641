import { connect } from "react-redux";
import moment from "moment";
import { formValueSelector } from "redux-form";
import Grant from "./";

const mapStateToProps = ({ form }, { accessor, formName = "model", change }) => {
    const selector = formValueSelector(formName);

    const grants = selector({ form }, `${accessor}`) || {
        _firstGrant: {
            amount: null,
            grantType: "",
            note: "",
        },
    };

    return {
        accessor,
        formName,
        grants,
        change,
    };
};

const mergeProps = (state, dispatch, own) => ({
    ...state,
    ...dispatch,
    ...own,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Grant);
