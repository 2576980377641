import { connect } from "react-redux";
import { isEqual } from "lodash";

import { saveDrawingData, fetchDrawingData } from "./_ducks/thunk";

import { setMessage } from "features/Model/DrawingNew/_ducks/actions";

import DrawingNew from "./index";

const mapStateToProps = ({
    user: { uid },

    drawing: { stage, imageDataToCompare, imageData },
    model: {
        modelFiles = [],
        modelId,
        modelData = {},
        fileUpload: { isUploading = false },
    },
}) => ({
    // saving,
    modelFiles,
    modelId,
    modelData,
    stage,
    stageScale: stage.scale,
    uid,
    isUploading,
    changesMade: !isEqual(imageDataToCompare, imageData),
});

const mapDispatchToProps = (dispatch) => ({
    saveDrawingData: (modelId) => dispatch(saveDrawingData(modelId, true)),
    fetchDrawingData: (modelId) => dispatch(fetchDrawingData(modelId)),
    setMessage: ({ message, type, anchorOrigin, autoHideDuration, isOpen, direction }) =>
        dispatch(
            setMessage({
                message,
                type,
                anchorOrigin,
                autoHideDuration,
                isOpen,
                direction,
            })
        ),
});

export default connect(mapStateToProps, mapDispatchToProps)(DrawingNew);
