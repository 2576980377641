import React from "react";

import styles from "./styles.module.scss";

import closeCircle from "assets/images/icons/closeCircle.svg";
import imageUploadIcon from "assets/images/icons/imageUploadIcon.svg";

const UploadingFile = ({ fileName, progress, cancel, isCancelled, isFirst, isComplete }) => {
    return (
        <div
            className={`${styles.uploadingFileContainer} ${isCancelled ? styles.uploadingFileCanceled : ""} ${isComplete ? styles.uploadingFileComplete : ""}`}
            style={{ marginTop: isFirst ? 0 : "10px" }}
        >
            <div className={styles.uploadProcessContainer}>
                <img src={imageUploadIcon} alt="upload" />
                <div style={{ width: "100%" }}>
                    <div className={styles.uploadTextWrapper}>
                        <span>{fileName}</span>
                        <span>{progress}%</span>
                    </div>
                    <div className={styles.progressBar}>
                        <div
                            style={{ width: `${progress}%`, background: !isComplete ? "#0049c6" : "#0eb0a9" }}
                            className={styles.progressBarInner}
                        />
                    </div>
                </div>
            </div>
            {/*     CLOSE    */}
            <img
                src={closeCircle}
                style={{ opacity: isCancelled ? 0.5 : 1 }}
                alt="Close icon"
                onClick={() => cancel()}
            />
        </div>
    );
};

export default UploadingFile;
