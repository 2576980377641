import clientFields from "utils/settings/clientFields.json";
import clientFieldsV11_10 from "utils/settings/clientFields-v11-10.json";
//********************* */
//
//  Helpers
//
//********************* */

// const getType = (type) => {
//     if (type === 'dateString') return 'string';
//     return type;
// };

const getValue = (value) =>
    typeof value === "object" && value !== null && value.constructor !== Array
        ? value.value
        : typeof value === "object" && value !== null && value.constructor === Array
        ? Boolean(value.length)
        : value;

const getId = (value) => (typeof value === "object" && value !== null ? value.id : value);

const getCodeRef = (value) => (typeof value === "object" && value !== null ? value.codeRef : value);

//********************* */
//
//  Constraints
//
//********************* */

export const required = (value) => {
    const val = getValue(value);
    // Allow 0
    return val || val === 0 ? undefined : "This field is required.";
};

export const requiredId = (value) => {
    const id = getId(value);
    // Allow 0
    return id || id === 0 ? undefined : "This field is required.";
    // return "This field is required.";
};

export const requiredCodeRef = (value) => {
    const codeRef = getCodeRef(value);
    return codeRef ? undefined : "This field is required.";
};

export const requiredIdOrCodeRef = (value) => {
    const id = getId(value);
    const codeRef = getCodeRef(value);
    return id || id === 0 || codeRef ? undefined : "This field is required.";
};

export const maxLength = (max) => (value) => {
    const val = getValue(value);
    return val && val.length > max ? `Must be ${max} characters or less.` : undefined;
};

export const minValue = (min, units) => (value) => {
    const val = parseFloat(getValue(value));
    return val < min ? `Must be at least ${min}${units ? ` ${units}` : ""}.` : undefined;
};

export const maxValue = (max, units) => (value) => {
    const val = parseFloat(getValue(value));
    return val > max ? `Must be less than or equal to ${max}${units ? ` ${units}` : ""}.` : undefined;
};

export const decimalValidation = (decimals) => (value) => {
    const val = parseFloat(getValue(value));
    var allowedDecimals = 0;
    if (decimals > 0) {
        allowedDecimals = decimals + 2;
    }
    if (val && Math.floor(val) !== val && val.toString().split(".")[1].length > allowedDecimals) {
        return decimals === 0
            ? "Number must be an integer."
            : `Number must have no more than ${decimals} decimal places.`;
    }

    return undefined;
};

//********************* */
//
//  Additional Validation
//
//********************* */

export const typeValidation = (type) => (value) => value && typeof value !== type ? `Must be a ${type}` : undefined;

//********************* */
//
//  Functions
//
//********************* */
const getFieldSettings = (fieldKey, version = "") => {
    let targetField = clientFields[fieldKey] || {};
    if (version === "v11-10" && clientFieldsV11_10[fieldKey]) {
        targetField = clientFieldsV11_10[fieldKey] || {};
    }

    return targetField;
};

export const getValidation = (fieldKey, version = "") => {
    const {
        constraints: { charLim = null, max = null, min = null, decimals = null } = {},
        type = null,
        baseUnits = "",
    } = getFieldSettings(fieldKey, version) || {};

    const maxLengthFunc = maxLength(charLim);
    const maxValueFunc = maxValue(max, baseUnits);
    const minValueFunc = minValue(min, baseUnits);
    const decimalValidationFunc = decimalValidation(decimals);
    const typeValidationFunc = typeValidation(type);

    const validation = [
        ...(charLim ? [maxLengthFunc] : []),
        ...(max || max === 0 ? [maxValueFunc] : []),
        ...(min || min === 0 ? [minValueFunc] : []),
        ...(decimals || decimals === 0 ? [decimalValidationFunc] : []),
        ...(type === "number" ? [typeValidationFunc] : []),
    ];

    return validation;
};

export const getDecimalPlaces = (fieldKey, version = "") => {
    const { constraints: { decimals = null } = {} } = getFieldSettings(fieldKey, version) || {};

    return decimals ? parseInt(decimals) : "";
};
