import f280WeatherMap from "./f280WeatherMap.json";
import clientF280Weather from "./clientF280Weather.json";

export const getF280WeatherOpts = (weatherProvTerrId) => {
    const provCode = provinceCodeMap[weatherProvTerrId];

    const provMatches = Object.keys(clientF280Weather).reduce((cache, seqNum) => {
        if (provCode === clientF280Weather[seqNum].Region) {
            return [
                ...cache,
                {
                    label: `${clientF280Weather[seqNum].City} (${clientF280Weather[seqNum].Region})`,
                    value: { id: parseInt(seqNum) },
                },
            ];
        }
        return cache;
    }, []);

    const neighbourCodes = neighbourProvCodes[weatherProvTerrId] || [];
    const neighbourMatches = Object.keys(clientF280Weather).reduce((cache, seqNum) => {
        if (neighbourCodes.includes(clientF280Weather[seqNum].Region)) {
            return [
                ...cache,
                {
                    label: `${clientF280Weather[seqNum].City} (${clientF280Weather[seqNum].Region})`,
                    value: { id: parseInt(seqNum) },
                },
            ];
        }
        return cache;
    }, []);

    return [...provMatches, ...neighbourMatches];
};

export const getDefaultF280Option = (weatherLocationId) => {
    const { [weatherLocationId]: { seqNum = "1" } = {} } = f280WeatherMap;
    return parseInt(seqNum);
};

export const getF280DesignHeatingTemp = (seqNum) => clientF280Weather[seqNum]?.DHDBT || null;
export const getF280DesignCoolingTemp = (seqNum) => clientF280Weather[seqNum]?.DCDBT || null;

export const provinceCodeMap = {
    10: "NL",
    9: "PE",
    8: "NS",
    7: "NB",
    6: "QC",
    5: "ON",
    4: "MB",
    3: "SK",
    2: "AB",
    1: "BC",
    11: "YT",
    12: "NT",
    13: "NU",
};

const provinceNameMap = {
    10: "Newfoundland and Labrador",
    9: "Prince Edward Island",
    8: "Nova Scotia",
    7: "New Brunswick",
    6: "Quebec",
    5: "Ontario",
    4: "Manitoba",
    3: "Saskatchewan",
    2: "Alberta",
    1: "British Columbia",
    11: "Yukon",
    12: "Northwest Territories",
    13: "Nunavut",
};

export const getProvCode = (weatherProvTerrId) => {
    return provinceCodeMap[weatherProvTerrId] || "CAN";
}
export const getProvName = (weatherProvTerrId) => {
    return provinceNameMap[weatherProvTerrId] || "Canada";
}

const neighbourProvCodes = {
    10: ["QC", "NS"],
    9: ["NS", "NB"],
    8: ["PE", "NB"],
    7: ["NS", "NB", "QC"],
    6: ["ON", "NB", "NL"],
    5: ["QC", "MB"],
    4: ["ON", "SK"],
    3: ["MB", "AB"],
    2: ["SK", "BC", "NT"],
    1: ["AB", "YT"],
    11: ["NT", "BC"],
    12: ["YT", "NU", "BC", "AB", "SK"],
    13: ["NT", "MB"],
};
