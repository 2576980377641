import React, { useState } from 'react';
import classes from './style.module.scss';
import sharedClasses from 'features/Model/sharedStyles.module.scss';
import { Field, unregisterField } from 'redux-form';
import { getOptions, getFirstOption, getBaseUnits, getUnitOptions } from 'utils/fields';
import { getDefaultComboSpecs, getDhwTemplate, getP9Manufacturers, getP9Models, getP9Details } from 'utils/mechanicals';
import { required, maxLength, getValidation, getDecimalPlaces } from 'utils/fieldValidation';
import { CSSTransition, SwitchTransition } from "react-transition-group";
import InputRow from 'components/Input/Row';
import Input from 'components/Input';
import InputWithUnits from 'components/Input/InputWithUnits';
import Select from 'components/Input/Select';
import Checkbox from 'components/Input/Checkbox';
import Button from 'components/Button';
import DwhrSystem from 'features/Model/Mechanicals/HotWater/DwhrSystem/container';
import Delete from 'assets/images/icons/JSX/Delete';
import Add from 'assets/images/icons/JSX/Add';
import Tooltip from 'components/Tooltip';
import ThreeDots from 'assets/images/icons/JSX/ThreeDots';
import DropDown from 'components/Dropdown';
import Accordion from 'components/Accordion';

const charLim100 = maxLength(100);
const numSysValidation = getValidation('p9NumSystems');
const tpfValidation = getValidation('p9TPF');
const annualElecValidation = getValidation('p9AnnualElecConsumption');
const heatingCapValidation = getValidation('p9SpaceHeatingCapacity');
const heatingEffValidation = getValidation('p9CSHE');
const whpfValidation = getValidation('p9WHPF');
const burnerInputValidation = getValidation('p9NominalBurnerInput');
const recoveryEffValidation = getValidation('p9RecoveryEff');
const energySourceValidation = getValidation('p9EnergySource');

const netEffTestValidation = getValidation('p9NetEff15Load');
const avgElecTestValidation = getValidation('p9AvgElec15Load');
const blowerTestValidation = getValidation('p9Blower15Load');

const controlsPowerValidation = getValidation('p9ControlsPower');
const circPowerValidation = getValidation('p9CircPower');
const dailyElecUseValidation = getValidation('p9DailyElecUse');
const standbyLossFanOnValidation = getValidation('p9ThermalStanbyLossFanOn');
const standbyLossFanOffValidation = getValidation('p9ThermalStanbyLossFanOff');
const oneHourHotWaterValidation = getValidation('p9OneHourRatingHotWater');
const oneHourConcurrentValidation = getValidation('p9OneHourRatingConcurrent');

export default ({
    accessor,
    isP9UserSpecified,
    p9Manufacturer,
    p9Model,
    tpfValue,
    tpfUnits,
    annualElecValue,
    annualElecUnits,
    heatingCapValue,
    heatingCapUnits,
    heatingEffValue,
    heatingEffUnits,
    whpfValue,
    whpfUnits,
    burnerInputValue,
    burnerInputUnits,
    recoveryEffValue,
    recoveryEffUnits,
    netEff15Value,
    netEff40Value,
    netEff100Value,
    avgElec15Value,
    avgElec40Value,
    avgElec100Value,
    blower15Value,
    blower40Value,
    blower100Value,
    netEff15Units,
    netEff40Units,
    netEff100Units,
    avgElec15Units,
    avgElec40Units,
    avgElec100Units,
    blower15Units,
    blower40Units,
    blower100Units,
    controlsPowerValue,
    controlsPowerUnits,
    circPowerValue,
    circPowerUnits,
    dailyElecUseValue,
    dailyElecUseUnits,
    standbyLossFanOnValue,
    standbyLossFanOnUnits,
    standbyLossFanOffValue,
    standbyLossFanOffUnits,
    oneHourHotWaterValue,
    oneHourHotWaterUnits,
    oneHourConcurrentValue,
    oneHourConcurrentUnits,
    isDwhr,
    modelUnits,
    change,
    handleDelete,
}) => {

    const [menuOpen, toggleMenu] = useState(false);

    const handleCreateDwhr = () => {
        change(`modelData.domesticHotWater.primarySystem.hasDrainWaterHeatRecovery`, true);
        change(`modelData.domesticHotWater.primarySystem.drainWaterHeatRecovery`, getDhwTemplate('dwhr'));
    }

    const handleDeleteDwhr = () => {
        change(`modelData.domesticHotWater.primarySystem.hasDrainWaterHeatRecovery`, false);
        change(`modelData.domesticHotWater.primarySystem.drainWaterHeatRecovery`, {});
    }

    return (
        <>
            <div className={classes.heading}>
                <div
                    className={`${classes.addDHWR} ${isDwhr && classes.disabled}`}
                    onClick={() => handleCreateDwhr()}
                    data-tip="Add Drain Water Heat Recovery"
                    data-for="dwhrHeader"
                >
                    Add DWHR <Add />
                </div>
                <Tooltip id="dwhrHeader" />
            </div>
            <InputRow gridTemplate="23.4% 23.4% 23.4% 23.4%">
                <Field
                    component={Select}
                    name={`${accessor}.system.p9Combo.isUserSpecified`}
                    options={[
                        {
                            label:"User Specified",
                            value:true
                        },
                        {
                            label:"Library",
                            value:false
                        },
                    ]}
                    label="Data Input Type"
                    placeholder="Choose Input Type"
                />
                {
                    isP9UserSpecified ? 
                        <>
                            <Field
                                component={Input}
                                name={`${accessor}.system.p9Combo.manufacturer`}
                                type="text"
                                label="Manufacturer Name"
                                placeholder="Enter Manufacturer Name"
                                validate={charLim100}
                            />
                            <Field
                                component={Input}
                                name={`${accessor}.system.p9Combo.model`}
                                type="text"
                                label="Model Name"
                                placeholder="Enter Model Name"
                                validate={charLim100}
                            />
                        </> :
                        <>
                            <Field
                                component={Select}
                                name={`${accessor}.system.p9Combo.manufacturer`}
                                options={getP9Manufacturers()}
                                label="Manufacturer Name"
                                placeholder="Choose Manufacturer"
                                onChange={
                                    (value) => {
                                        change(
                                            `${accessor}.system.p9Combo.model`, 
                                            getP9Models(value)[0].value
                                        )
                                    }
                                }
                            />
                            <Field
                                component={Select}
                                name={`${accessor}.system.p9Combo.model`}
                                options={getP9Models(p9Manufacturer)}
                                label="Model Name"
                                placeholder="Choose Model"
                                setValue={p9Model}
                                onChange={
                                    (modelValue) => {
                                        change(
                                            `${accessor}.system.p9Combo`, 
                                            getP9Details({manufacturer: p9Manufacturer, model: modelValue})
                                        )
                                    }
                                }
                            />
                        </>
                }
                <Field
                    component={Input}
                    type="number"
                    name={`${accessor}.system.p9Combo.numberOfSystems`}
                    label="Number of Systems"
                    placeholder={0}
                    validate={numSysValidation}
                    decimals={getDecimalPlaces(`p9NumSystems`)}
                    change={change}
                    disabled={false}
                />
            </InputRow>
            <h5 style={{marginBottom:"1.25rem", marginTop:"1.75rem"}}>Performance Summary</h5>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.system.p9Combo.thermalPerformanceFactor`}
                    label="Thermal Performance Factor"
                    placeholder={0}
                    validate={tpfValidation}
                    decimals={getDecimalPlaces(`p9TPF`)}
                    change={change}
                    setValue={tpfValue}
                    disabled={!isP9UserSpecified}
                    units={{
                        base:getBaseUnits(`p9TPF`, modelUnits),
                        options:getUnitOptions('fraction'),
                        selected:tpfUnits,
                        unitType:'fraction',
                        accessor:`${accessor}.system.p9Combo.thermalPerformanceFactor_u`
                    }}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.system.p9Combo.annualElectricity`}
                    label="Annual Electricity Consumption"
                    placeholder={0}
                    validate={annualElecValidation}
                    decimals={getDecimalPlaces(`p9AnnualElecConsumption`)}
                    change={change}
                    setValue={annualElecValue}
                    disabled={!isP9UserSpecified}
                    units={{
                        base:getBaseUnits(`p9AnnualElecConsumption`, modelUnits),
                        options:getUnitOptions('energy'),
                        selected:annualElecUnits,
                        unitType:'energy',
                        accessor:`${accessor}.system.p9Combo.annualElectricity_u`
                    }}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.system.p9Combo.spaceHeatingCapacity`}
                    label="Space Heating Capacity"
                    placeholder={0}
                    validate={heatingCapValidation}
                    decimals={getDecimalPlaces(`p9SpaceHeatingCapacity`)}
                    change={change}
                    setValue={heatingCapValue}
                    disabled={!isP9UserSpecified}
                    units={{
                        base:getBaseUnits(`p9SpaceHeatingCapacity`, modelUnits),
                        options:getUnitOptions('power'),
                        selected:heatingCapUnits,
                        unitType:'power',
                        accessor:`${accessor}.system.p9Combo.spaceHeatingCapacity_u`
                    }}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.system.p9Combo.spaceHeatingEfficiency`}
                    label="Composite Space Heating Efficiency"
                    placeholder={0}
                    validate={heatingEffValidation}
                    decimals={getDecimalPlaces(`p9CSHE`)}
                    change={change}
                    setValue={heatingEffValue}
                    disabled={!isP9UserSpecified}
                    units={{
                        base:getBaseUnits(`p9CSHE`, modelUnits),
                        options:getUnitOptions('fraction'),
                        selected:heatingEffUnits,
                        unitType:'fraction',
                        accessor:`${accessor}.system.p9Combo.spaceHeatingEfficiency_u`
                    }}
                />
            </InputRow>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.system.p9Combo.waterHeatingPerformanceFactor`}
                    label="Water Heating Performance Factor"
                    placeholder={0}
                    validate={whpfValidation}
                    decimals={getDecimalPlaces(`p9WHPF`)}
                    change={change}
                    setValue={whpfValue}
                    disabled={!isP9UserSpecified}
                    units={{
                        base:getBaseUnits(`p9WHPF`, modelUnits),
                        options:getUnitOptions('fraction'),
                        selected:whpfUnits,
                        unitType:'fraction',
                        accessor:`${accessor}.system.p9Combo.waterHeatingPerformanceFactor_u`
                    }}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.system.p9Combo.burnerInput`}
                    label="Nominal Burner Input"
                    placeholder={0}
                    validate={burnerInputValidation}
                    decimals={getDecimalPlaces('p9NominalBurnerInput')}
                    change={change}
                    setValue={burnerInputValue}
                    disabled={!isP9UserSpecified}
                    units={{
                        base:getBaseUnits('p9NominalBurnerInput', modelUnits),
                        options:getUnitOptions('power'),
                        selected:burnerInputUnits,
                        unitType:'power',
                        accessor:`${accessor}.system.p9Combo.burnerInput_u`
                    }}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.system.p9Combo.recoveryEfficiency`}
                    label="Recovery Efficiency"
                    placeholder={0}
                    validate={recoveryEffValidation}
                    decimals={getDecimalPlaces('p9RecoveryEff')}
                    change={change}
                    setValue={recoveryEffValue}
                    disabled={!isP9UserSpecified}
                    units={{
                        base:getBaseUnits('p9RecoveryEff', modelUnits),
                        options:getUnitOptions('fraction'),
                        selected:recoveryEffUnits,
                        unitType:'fraction',
                        accessor:`${accessor}.system.p9Combo.recoveryEfficiency_u`
                    }}
                />
            </InputRow>
            <Accordion
                className={sharedClasses.accordion}
                heading={<span className={sharedClasses.heading}>Test Data</span>}
                large
            >
                <InputRow gridTemplate="1fr 1fr 1fr">
                    <Field
                        component={Select}
                        name={`${accessor}.system.p9Combo.testData.energySource`}
                        options={getOptions({fieldKey: `p9EnergySource`})}
                        label="Energy Source"
                        placeholder="Choose Energy Source"
                        validate={energySourceValidation}
                        disabled={!isP9UserSpecified}
                    />
                </InputRow>
                <h4 style={{marginBottom:"0.5rem", marginTop:"1.75rem"}}>Space Heating Part-load Performance</h4>
                <h5 style={{marginBottom:"0.5rem", marginTop:"1.75rem"}}>Net Efficiency</h5>
                <InputRow gridTemplate="1fr 1fr 1fr">
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.system.p9Combo.testData.netEfficiency.loadPerformance15`}
                        label="15% Part-load"
                        placeholder={0}
                        validate={netEffTestValidation}
                        decimals={getDecimalPlaces('p9NetEff15Load')}
                        change={change}
                        setValue={netEff15Value}
                        disabled={!isP9UserSpecified}
                        units={{
                            base:getBaseUnits('p9NetEff15Load', modelUnits),
                            options:getUnitOptions('fraction'),
                            selected:netEff15Units,
                            unitType:'fraction',
                            accessor:`${accessor}.system.p9Combo.testData.netEfficiency.loadPerformance15_u`
                        }}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.system.p9Combo.testData.netEfficiency.loadPerformance40`}
                        label="40% Part-load"
                        placeholder={0}
                        validate={netEffTestValidation}
                        decimals={getDecimalPlaces('p9NetEff40Load')}
                        change={change}
                        setValue={netEff40Value}
                        disabled={!isP9UserSpecified}
                        units={{
                            base:getBaseUnits('p9NetEff40Load', modelUnits),
                            options:getUnitOptions('fraction'),
                            selected:netEff40Units,
                            unitType:'fraction',
                            accessor:`${accessor}.system.p9Combo.testData.netEfficiency.loadPerformance40_u`
                        }}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.system.p9Combo.testData.netEfficiency.loadPerformance100`}
                        label="100% Load"
                        placeholder={0}
                        validate={netEffTestValidation}
                        decimals={getDecimalPlaces('p9NetEff100Load')}
                        change={change}
                        setValue={netEff100Value}
                        disabled={!isP9UserSpecified}
                        units={{
                            base:getBaseUnits('p9NetEff100Load', modelUnits),
                            options:getUnitOptions('fraction'),
                            selected:netEff100Units,
                            unitType:'fraction',
                            accessor:`${accessor}.system.p9Combo.testData.netEfficiency.loadPerformance100_u`
                        }}
                    />
                </InputRow>
                <h5 style={{marginBottom:"0.5rem", marginTop:"1.75rem"}}>Average Electrical Use</h5>
                <InputRow gridTemplate="1fr 1fr 1fr">
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.system.p9Combo.testData.electricalUse.loadPerformance15`}
                        label="15% Part-load"
                        placeholder={0}
                        validate={avgElecTestValidation}
                        decimals={getDecimalPlaces('p9AvgElec15Load')}
                        change={change}
                        setValue={avgElec15Value}
                        disabled={!isP9UserSpecified}
                        units={{
                            base:getBaseUnits('p9AvgElec15Load', modelUnits),
                            options:getUnitOptions('power'),
                            selected:avgElec15Units,
                            unitType:'power',
                            accessor:`${accessor}.system.p9Combo.testData.electricalUse.loadPerformance15_u`
                        }}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.system.p9Combo.testData.electricalUse.loadPerformance40`}
                        label="40% Part-load"
                        placeholder={0}
                        validate={avgElecTestValidation}
                        decimals={getDecimalPlaces('p9AvgElec40Load')}
                        change={change}
                        setValue={avgElec40Value}
                        disabled={!isP9UserSpecified}
                        units={{
                            base:getBaseUnits('p9AvgElec40Load', modelUnits),
                            options:getUnitOptions('power'),
                            selected:avgElec40Units,
                            unitType:'power',
                            accessor:`${accessor}.system.p9Combo.testData.electricalUse.loadPerformance40_u`
                        }}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.system.p9Combo.testData.electricalUse.loadPerformance100`}
                        label="100% Load"
                        placeholder={0}
                        validate={avgElecTestValidation}
                        decimals={getDecimalPlaces('p9AvgElec100Load')}
                        change={change}
                        setValue={avgElec100Value}
                        disabled={!isP9UserSpecified}
                        units={{
                            base:getBaseUnits('p9AvgElec100Load', modelUnits),
                            options:getUnitOptions('power'),
                            selected:avgElec100Units,
                            unitType:'power',
                            accessor:`${accessor}.system.p9Combo.testData.electricalUse.loadPerformance100_u`
                        }}
                    />
                </InputRow>
                <h5 style={{marginBottom:"0.5rem", marginTop:"1.75rem"}}>Circulating Blower Motor Power</h5>
                <InputRow gridTemplate="1fr 1fr 1fr">
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.system.p9Combo.testData.blowerPower.loadPerformance15`}
                        label="15% Part-load"
                        placeholder={0}
                        validate={blowerTestValidation}
                        decimals={getDecimalPlaces('p9Blower15Load')}
                        change={change}
                        setValue={blower15Value}
                        disabled={!isP9UserSpecified}
                        units={{
                            base:getBaseUnits('p9Blower15Load', modelUnits),
                            options:getUnitOptions('power'),
                            selected:blower15Units,
                            unitType:'power',
                            accessor:`${accessor}.system.p9Combo.testData.blowerPower.loadPerformance15_u`
                        }}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.system.p9Combo.testData.blowerPower.loadPerformance40`}
                        label="40% Part-load"
                        placeholder={0}
                        validate={blowerTestValidation}
                        decimals={getDecimalPlaces('p9Blower40Load')}
                        change={change}
                        setValue={blower40Value}
                        disabled={!isP9UserSpecified}
                        units={{
                            base:getBaseUnits('p9Blower40Load', modelUnits),
                            options:getUnitOptions('power'),
                            selected:blower40Units,
                            unitType:'power',
                            accessor:`${accessor}.system.p9Combo.testData.blowerPower.loadPerformance40_u`
                        }}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.system.p9Combo.testData.blowerPower.loadPerformance100`}
                        label="100% Load"
                        placeholder={0}
                        validate={blowerTestValidation}
                        decimals={getDecimalPlaces('p9Blower100Load')}
                        change={change}
                        setValue={blower100Value}
                        disabled={!isP9UserSpecified}
                        units={{
                            base:getBaseUnits('p9Blower100Load', modelUnits),
                            options:getUnitOptions('power'),
                            selected:blower100Units,
                            unitType:'power',
                            accessor:`${accessor}.system.p9Combo.testData.blowerPower.loadPerformance100_u`
                        }}
                    />
                </InputRow>
                <h4 style={{marginBottom:"0.5rem", marginTop:"1.75rem"}}>Other Specifications</h4>
                <InputRow gridTemplate="1fr 1fr 1fr">
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.system.p9Combo.testData.controlsPower`}
                        label="Controls Power"
                        placeholder={0}
                        validate={controlsPowerValidation}
                        decimals={getDecimalPlaces('p9ControlsPower')}
                        change={change}
                        setValue={controlsPowerValue}
                        disabled={!isP9UserSpecified}
                        units={{
                            base:getBaseUnits('p9ControlsPower', modelUnits),
                            options:getUnitOptions('power'),
                            selected:controlsPowerUnits,
                            unitType:'power',
                            accessor:`${accessor}.system.p9Combo.testData.controlsPower_u`
                        }}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.system.p9Combo.testData.circulationPower`}
                        label="Circulation Power"
                        placeholder={0}
                        validate={circPowerValidation}
                        decimals={getDecimalPlaces('p9CircPower')}
                        change={change}
                        setValue={circPowerValue}
                        disabled={!isP9UserSpecified}
                        units={{
                            base:getBaseUnits('p9CircPower', modelUnits),
                            options:getUnitOptions('power'),
                            selected:circPowerUnits,
                            unitType:'power',
                            accessor:`${accessor}.system.p9Combo.testData.circulationPower_u`
                        }}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.system.p9Combo.testData.dailyElecUse`}
                        label="Daily Electricity Use for Water Heating"
                        placeholder={0}
                        validate={dailyElecUseValidation}
                        decimals={getDecimalPlaces('p9DailyElecUse')}
                        change={change}
                        setValue={dailyElecUseValue}
                        disabled={!isP9UserSpecified}
                        units={{
                            base:getBaseUnits('p9DailyElecUse', modelUnits),
                            options:getUnitOptions('energy'),
                            selected:dailyElecUseUnits,
                            unitType:'energy',
                            accessor:`${accessor}.system.p9Combo.testData.dailyElecUse_u`
                        }}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr 1fr">
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.system.p9Combo.testData.standbyLossWithFanOn`}
                        label="Thermal Standby Loss (Fan On)"
                        placeholder={0}
                        validate={standbyLossFanOnValidation}
                        decimals={getDecimalPlaces('p9ThermalStanbyLossFanOn')}
                        change={change}
                        setValue={standbyLossFanOnValue}
                        disabled={!isP9UserSpecified}
                        units={{
                            base:getBaseUnits('p9ThermalStanbyLossFanOn', modelUnits),
                            options:getUnitOptions('power'),
                            selected:standbyLossFanOnUnits,
                            unitType:'power',
                            accessor:`${accessor}.system.p9Combo.testData.standbyLossWithFanOn_u`
                        }}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.system.p9Combo.testData.standbyLossWithFanOff`}
                        label="Thermal Standby Loss (Fan Off)"
                        placeholder={0}
                        validate={standbyLossFanOffValidation}
                        decimals={getDecimalPlaces('p9ThermalStanbyLossFanOff')}
                        change={change}
                        setValue={standbyLossFanOffValue}
                        disabled={!isP9UserSpecified}
                        units={{
                            base:getBaseUnits('p9ThermalStanbyLossFanOff', modelUnits),
                            options:getUnitOptions('power'),
                            selected:standbyLossFanOffUnits,
                            unitType:'power',
                            accessor:`${accessor}.system.p9Combo.testData.standbyLossWithFanOff_u`
                        }}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr 1fr">
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.system.p9Combo.testData.oneHourRatingHotWater`}
                        label="One Hour Delivery Rating (Hot Water Only)"
                        placeholder={0}
                        validate={oneHourHotWaterValidation}
                        decimals={getDecimalPlaces('p9OneHourRatingHotWater')}
                        change={change}
                        setValue={oneHourHotWaterValue}
                        disabled={!isP9UserSpecified}
                        units={{
                            base:getBaseUnits('p9OneHourRatingHotWater', modelUnits),
                            options:getUnitOptions('volume'),
                            selected:oneHourHotWaterUnits,
                            unitType:'volume',
                            accessor:`${accessor}.system.p9Combo.testData.oneHourRatingHotWater_u`
                        }}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.system.p9Combo.testData.oneHourRatingConcurrent`}
                        label="One Hour Delivery Rating (Concurrent Load)"
                        placeholder={0}
                        validate={oneHourConcurrentValidation}
                        decimals={getDecimalPlaces('p9OneHourRatingConcurrent')}
                        change={change}
                        setValue={oneHourConcurrentValue}
                        disabled={!isP9UserSpecified}
                        units={{
                            base:getBaseUnits('p9OneHourRatingConcurrent', modelUnits),
                            options:getUnitOptions('volume'),
                            selected:oneHourConcurrentUnits,
                            unitType:'volume',
                            accessor:`${accessor}.system.p9Combo.testData.oneHourRatingConcurrent_u`
                        }}
                    />
                </InputRow>
            </Accordion>
            {
                isDwhr &&
                    <DwhrSystem
                        accessor={`modelData.domesticHotWater.primarySystem.drainWaterHeatRecovery`}
                        change={change}
                        handleDelete={() => handleDeleteDwhr()}
                    />
            }
        </>
    )
};