import moment from "moment";

import types from "./types";

const initialState = {
    upgradePackagesLabels: null,
    currentPackage: null,
    currentPackageToCompare: null,
    fetchingCurrentPackage: true,
    selectedUpgrade: null,
};

const upgradeLibrary = (state = initialState, action) => {
    switch (action.type) {
        case types.SAVE_UPDATE_UPGRADE_PACKAGE_START:
            return { ...state };
        case types.SAVE_UPDATE_UPGRADE_PACKAGE_ERROR:
            return { ...state };
        case types.SAVE_UPDATE_UPGRADE_PACKAGE_SUCCESS:
            return {
                ...state,
                currentPackage: action.payload.upgradePackage,
                currentPackageToCompare: JSON.parse(JSON.stringify(action.payload.upgradePackage)),
            };
        case types.FETCH_UPGRADE_LABELS_START:
            return { ...state };
        case types.FETCH_UPGRADE_LABELS_ERROR:
            return { ...state };
        case types.FETCH_UPGRADE_LABELS_SUCCESS:
            return { ...state, upgradePackagesLabels: action.payload };
        case types.FETCH_UPGRADE_PACKAGE_START:
            return {
                ...state,
                fetchingCurrentPackage: true,
                currentPackage: null,
                currentPackageToCompare: null,
            };
        case types.FETCH_UPGRADE_PACKAGE_ERROR:
            return {
                ...state,
                currentPackage: null,
                currentPackageToCompare: null,
                fetchingCurrentPackage: false,
            };
        case types.FETCH_UPGRADE_PACKAGE_SUCCESS:
            return {
                ...state,
                currentPackage: action.payload,
                currentPackageToCompare: JSON.parse(JSON.stringify(action.payload)),
                fetchingCurrentPackage: false,
            };
        case types.DELETE_UPGRADE_PACKAGE_START:
            return { ...state };
        case types.DELETE_UPGRADE_PACKAGE_ERROR:
            return { ...state };
        case types.DELETE_UPGRADE_PACKAGE_SUCCESS:
            return { ...state };
        case types.CHANGE_NAME_UPGRADE_PACKAGE_START:
            return { ...state };
        case types.CHANGE_NAME_UPGRADE_PACKAGE_SUCCESS:
            return {
                ...state,
                currentPackage: action.payload,
                currentPackageToCompare: JSON.parse(JSON.stringify(action.payload)),
            };
        case types.CHANGE_NAME_UPGRADE_PACKAGE_ERROR:
            return { ...state };
        case types.DUPLICATE_UPGRADE_PACKAGE_START:
            return { ...state };
        case types.DUPLICATE_UPGRADE_PACKAGE_SUCCESS:
            return { ...state };
        case types.DUPLICATE_UPGRADE_PACKAGE_ERROR:
            return { ...state };
        case types.UPDATE_UPGRADE:
            return {
                ...state,
                currentPackage: {
                    ...state.currentPackage,
                    lastEdited: moment().format("LLLL"),
                    upgrades: {
                        ...state.currentPackage.upgrades,
                        [action.payload.upgradeId]: action.payload.upgrade,
                    },
                },
            };
        case types.DELETE_UPGRADE: {
            const upgrades = state.currentPackage.upgrades;

            delete upgrades[action.payload];

            return {
                ...state,
                currentPackage: {
                    ...state.currentPackage,
                    upgrades,
                },
            };
        }
        case types.RESET_UPGRADE_LIBRARY:
            return { ...initialState };
        case types.SELECT_UPGRADE:
            return { ...state, selectedUpgrade: action.payload };
        default:
            return state;
    }
};

export default upgradeLibrary;
