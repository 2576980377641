import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import Mechanicals from './';

const mapStateToProps = (
    {
        form,
        model:{
            modelId,
            contentOpen=false,
        }={}
    },
    {
        accessor
    }
) => {
    const selector = formValueSelector('model');
    const modelUnits = selector({form}, `modelData.uiSettings.primaryUnits`) || {};

    return {
        accessor,
        modelUnits,
        modelId,
        contentOpen
    }
};

const mapDispatchToProps = () => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Mechanicals);