import { floor, isEmpty, merge } from "lodash";

import convertUnit from "utils/convertUnit";
import IDGenerator from "../../TakeoffData/IDGenerator";

import wallTemplate from "features/Model/Enclosure/Templates/wall.json";
import windowTemplate from "features/Model/Enclosure/Templates/window.json";
import basementTemplate from "features/Model/Enclosure/Templates/basement.json";
import crawlspaceTemplate from "features/Model/Enclosure/Templates/crawlspace.json";
import slabTemplate from "features/Model/Enclosure/Templates/slab.json";
import expFloorTemplate from "features/Model/Enclosure/Templates/expFloor.json";
import ceilingTemplate from "features/Model/Enclosure/Templates/ceiling.json";
import floorHeaderTemplate from "features/Model/Enclosure/Templates/floorHeader.json";
import { getTotalPonyWallArea, getWalkoutGeometry } from "utils/enclosure/geometry";
import { capFirstLetter } from "utils/generalUtils/generalUtils";

const getSubCompValue = (fields, propertyName = "") => {
    const { value = 0 } = fields.find((field) => field.field === propertyName) || {};

    const decimals = ["corners", "intersections"].includes(propertyName) ? 0 : 2;
    return parseFloat(value.toFixed(decimals));
};

const _toMetric = (value, unit) => {
    //All values stored in metric
    return parseFloat(value.toFixed(2));

    // if (unit.includes("m") || unit === "count") {
    //     return value;
    // } else {
    //     let { outputUnit, type } = _getOutputUnit(unit);
    //     return convertUnit({
    //         value: value,
    //         type: type,
    //         inputUnit: unit,
    //         outputUnit: outputUnit,
    //     }).toFixed(2);
    // }
};

const _getOutputUnit = (unit) => {
    switch (unit) {
        case "ft2":
            return { outputUnit: "m2", type: "area" };
        case "ft3":
            return { outputUnit: "m3", type: "volume" };
        default:
            return { outputUnit: "m", type: "length" };
    }
};

const _toMilimeters = (value, unit) => {
    return value; //already storing window measurements in mm
    // return (_toMetric(value, unit) * 1000).toFixed(2);
};

export const translateGarageWalls = (component) => {
    let garageWalls = {};
    component.components.forEach((comp, index) => {
        if (comp.type === "garagewall") {
            garageWalls[`wall-btoff-${index}`] = {
                ...wallTemplate,
                label: `Garage wall${index > 0 ? ` (${index})` : ""}`,
                category: "foundation",
                adjacentEnclosedSpace: true,
                measurements: {
                    corners: getSubCompValue(comp.fields, "corners"),
                    intersections: getSubCompValue(comp.fields, "intersections"),
                    perimeter: getSubCompValue(comp.fields, "intPerimeter"),
                    height: getSubCompValue(comp.fields, "height"),
                    // area: _toMetric(comp.fields[1].value, comp.fields[1].unit),
                },
            };
        }
    });
    return garageWalls;
};

const _getWindows = (component, windowDefaults = {}, windowParentId = "") => {
    let windows = {};

    component.tableComponents.forEach((window, index) => {
        const { title: { value = "Window" } = {} } = window;

        const [label = "Window", dir = ""] = value.split("_");
        //Check for direction indicator

        windows[`window-toff-${windowParentId}-${index}`] = {
            ...windowTemplate,
            ...windowDefaults,
            label,
            numIdentical: window.qty.value,
            facingDirection: getWindowDirection(dir),
            measurements: {
                ...windowTemplate.measurements,
                height: _toMilimeters(window.height.value, window.height.unit),
                width: _toMilimeters(window.width.value, window.width.unit),
                headerHeight: _toMetric(window.ovHeight.value, window.ovHeight.unit),
                overhangWidth: _toMetric(window.ovWidth.value, window.ovWidth.unit),
            },
        };
    });
    return windows;
};

const facingDirMap = {
    S: 0,
    SE: 1,
    E: 2,
    NE: 3,
    N: 4,
    NW: 5,
    W: 6,
    SW: 7,
};

const getWindowDirection = (dir = "") => {
    const trimmed = dir.trim().toUpperCase();
    if (Object.keys(facingDirMap).includes(trimmed)) {
        return { id: facingDirMap[trimmed] };
    }
    return { id: 0 }; //south
};

const codeCompMap = {
    wall: ["Wall"],
    ceiling: ["Ceiling"],
    ceilingFlat: ["CeilingFlat"],
    expFloor: ["Floor"],
    basement: ["FloorsAbove", "BasementWall", "FloorsAdded"],
    walkout: ["FloorsAbove", "BasementWall", "FloorsAdded"],
    crawlspace: ["FloorsAbove", "CrawlspaceWall", "FloorsAdded"],
    slab: ["FloorsAdded"],
};

export const foundationTranslator = (component, defaultCodes = {}) => {
    const { floorHeaderHeight: { value: floorHeaderHeightValue = 0 } = {} } = component;

    const hasFloorHeader = floorHeaderHeightValue > 0;

    const defaultFields = codeCompMap[component.compType.toLowerCase()].reduce((cache, currCode) => {
        const { [currCode]: compDefaults = {} } = defaultCodes;
        return merge(cache, compDefaults);
    }, {});

    const { Window: windowDefaults = {}, FloorHeader: floorHeaderDefaults = {} } = defaultCodes;

    const totalPonyWallArea = getTotalPonyWallArea(
        component.components
            .filter((comp) => comp.type === "ponywall")
            .map((ponywall) => {
                return ponywall.fields.reduce(
                    (cache, field) => ({
                        ...cache,
                        [field.field]: _toMetric(field.value, field.unit),
                    }),
                    {}
                );
            })
    );

    const equivPonyWallHeight = Math.max(
        0,
        totalPonyWallArea / _toMetric(component.exteriorWallLength.value, component.exteriorWallLength.unit)
    );

    switch (component.compType.toLowerCase()) {
        case "basement": {
            let tempComp = {
                ...basementTemplate,
                toffId: component.toffId,
                label: "Basement",
                exposedSurfacePerimeter: _toMetric(
                    component.exteriorWallLength.value,
                    component.exteriorWallLength.unit
                ),
                floor: {
                    ...basementTemplate.floor,
                    measurements: {
                        ...basementTemplate.floor.measurements,
                        isRectangular: false,
                        area: _toMetric(component.area.value, component.area.unit),
                        perimeter: _toMetric(component.totalPerimeter.value, component.totalPerimeter.unit),
                    },
                },
                wall: {
                    ...basementTemplate.wall,
                    measurements: {
                        ...basementTemplate.wall.measurements,
                        corners: component.corners.value,
                        height: _toMetric(component.totalWallHeight.value, component.totalWallHeight.unit),
                        depth: _toMetric(component.bgDepth.value, component.bgDepth.unit),
                        drawing: {},
                    },
                    ponyWall: {
                        ...basementTemplate.ponyWall,
                        enabled: equivPonyWallHeight !== 0 ? true : false,
                        ponyWallHeight: equivPonyWallHeight,
                        ponyWallInsType:
                            equivPonyWallHeight !== 0
                                ? {
                                      codeRef: "UserSpecified",
                                      codeLabel: "User Specified",
                                  }
                                : {},
                        ponyWallInsType_effRVal: 0,
                        ponyWallInsType_nomRVal: 0,
                    },
                },
                subcomponents: {
                    ...basementTemplate.subcomponents,
                    window: _getWindows(component, windowDefaults, "basement"),
                    floorHeader: hasFloorHeader
                        ? {
                              "floor-header-toff-basement": {
                                  ...floorHeaderTemplate,
                                  ...floorHeaderDefaults,
                                  displayOrder: 1,
                                  measurements: {
                                      ...floorHeaderTemplate.measurements,
                                      perimeter: parseFloat(component.exteriorWallLength.value.toFixed(2)),
                                      height: parseFloat(floorHeaderHeightValue.toFixed(2)),
                                      area: component.exteriorWallLength.value * floorHeaderHeightValue,
                                  },
                              },
                          }
                        : {},
                },
            };

            const { floor: floorDefaults = {}, wall: wallDefaults = {} } = defaultFields || {};

            tempComp = {
                ...tempComp,
                wall: {
                    ...tempComp.wall,
                    ...wallDefaults,
                },
                floor: {
                    ...tempComp.floor,
                    ...floorDefaults,
                },
            };

            return tempComp;
        }

        case "crawlspace": {
            let tempComp = {
                ...crawlspaceTemplate,
                toffId: component.toffId,
                exposedSurfacePerimeter: _toMetric(
                    component.exteriorWallLength.value,
                    component.exteriorWallLength.unit
                ),
                floor: {
                    ...crawlspaceTemplate.floor,
                    measurements: {
                        ...crawlspaceTemplate.floor.measurements,
                        isRectangular: false,
                        area: _toMetric(component.area.value, component.area.unit),
                        perimeter: _toMetric(component.totalPerimeter.value, component.totalPerimeter.unit),
                    },
                },
                wall: {
                    ...crawlspaceTemplate.wall,
                    measurements: {
                        ...crawlspaceTemplate.wall.measurements,
                        corners: component.corners.value,
                        height: _toMetric(component.totalWallHeight.value, component.totalWallHeight.unit),
                        depth: _toMetric(component.bgDepth.value, component.bgDepth.unit),
                    },
                },
                subcomponents: {
                    ...crawlspaceTemplate.subcomponents,
                    window: _getWindows(component, windowDefaults, "crawlspace"),
                    floorHeader: hasFloorHeader
                        ? {
                              "floor-header-toff-crawlspace": {
                                  ...floorHeaderTemplate,
                                  ...floorHeaderDefaults,
                                  displayOrder: 1,
                                  measurements: {
                                      ...floorHeaderTemplate.measurements,
                                      perimeter: parseFloat(component.exteriorWallLength.value.toFixed(2)),
                                      height: parseFloat(floorHeaderHeightValue.toFixed(2)),
                                      area: component.exteriorWallLength.value * floorHeaderHeightValue,
                                  },
                              },
                          }
                        : {},
                },
            };

            const { floor: floorDefaults = {}, wall: wallDefaults = {} } = defaultFields || {};

            tempComp = {
                ...tempComp,
                wall: {
                    ...tempComp.wall,
                    ...wallDefaults,
                },
                floor: {
                    ...tempComp.floor,
                    ...floorDefaults,
                },
            };

            return tempComp;
        }

        case "slab":
        case "slab-on-grade": {
            let slab = {
                ...slabTemplate,
                exposedSurfacePerimeter: _toMetric(
                    component.exteriorWallLength.value,
                    component.exteriorWallLength.unit
                ),
                floor: {
                    ...slabTemplate.floor,
                    measurements: {
                        ...slabTemplate.floor.measurements,
                        isRectangular: false,
                        area: _toMetric(component.slabArea.value, component.slabArea.unit),
                        perimeter: _toMetric(component.totalPerimeter.value, component.totalPerimeter.unit),
                    },
                },
            };

            const { floor: floorDefaults = {} } = defaultFields || {};

            slab = {
                ...slab,
                floor: {
                    ...slab.floor,
                    ...floorDefaults,
                },
            };

            return { slab };
        }

        // case "basementcrawlspace":
        // case "basement-crawlspace": {

        // }

        case "walkout": {
            const {
                agWalkoutArea,
                totAgWalkoutArea,
                bgWalkoutArea,
                avgBsmtWallHeight,
                avgBelowGradeDepth,
                slabArea,
                slabExpPerimeter,
                slabPerimeter,
                basementArea,
                basementExpPerimeter,
                basementPerimeter,
            } = getWalkoutGeometry({
                totalFloorArea: _toMetric(component.area.value, component.area.unit),
                exposedPerimeter: _toMetric(component.exteriorWallLength.value, component.exteriorWallLength.unit),
                totalWallHeight: _toMetric(component.totalWallHeight.value, component.totalWallHeight.unit),
                buriedWalkoutDepth: _toMetric(component.buriedWalkoutDepth.value, component.buriedWalkoutDepth.unit),
                shallowWalkoutDepth: _toMetric(component.shallowWalkoutDepth.value, component.shallowWalkoutDepth.unit),
                slopedGradeWallLength: _toMetric(
                    component.slopedGradeWallLength.value,
                    component.slopedGradeWallLength.unit
                ),
                upperGradeLength: _toMetric(component.upperGradeLength.value, component.upperGradeLength.unit),
                walkoutSlabLength: _toMetric(component.walkoutSlabLength.value, component.walkoutSlabLength.unit),
                totalPonyWallArea: totalPonyWallArea,
            });

            let basement = {
                ...basementTemplate,
                toffId: component.toffId,
                label: "Walkout (Basement)",
                exposedSurfacePerimeter: basementExpPerimeter,
                floor: {
                    ...basementTemplate.floor,
                    measurements: {
                        ...basementTemplate.floor.measurements,
                        isRectangular: false,
                        area: _toMetric(basementArea, "m"), //already in m
                        perimeter: _toMetric(basementPerimeter, "m"), //already in m
                    },
                },
                wall: {
                    ...basementTemplate.wall,
                    measurements: {
                        ...basementTemplate.wall.measurements,
                        corners: component.corners.value,
                        height: _toMetric(avgBsmtWallHeight, "m"), //already in m
                        depth: _toMetric(avgBelowGradeDepth, "m"), //already in m
                    },
                    ponyWall: {
                        ...basementTemplate.wall.ponyWall,
                        enabled: equivPonyWallHeight !== 0 ? true : false,
                        ponyWallHeight: equivPonyWallHeight,
                        ponyWallInsType:
                            equivPonyWallHeight !== 0
                                ? {
                                      codeRef: "UserSpecified",
                                      codeLabel: "User Specified",
                                  }
                                : {},
                        ponyWallInsType_effRVal: 0,
                        ponyWallInsType_nomRVal: 0,
                    },
                },
                subcomponents: {
                    ...basementTemplate.subcomponents,
                    window: _getWindows(component, windowDefaults, "walkout"),
                    floorHeader: hasFloorHeader
                        ? {
                              "floor-header-toff-walkout": {
                                  ...floorHeaderTemplate,
                                  ...floorHeaderDefaults,
                                  displayOrder: 1,
                                  measurements: {
                                      ...floorHeaderTemplate.measurements,
                                      perimeter: parseFloat(component.exteriorWallLength.value.toFixed(2)),
                                      height: parseFloat(floorHeaderHeightValue.toFixed(2)),
                                      area: component.exteriorWallLength.value * floorHeaderHeightValue,
                                  },
                              },
                          }
                        : {},
                },
            };

            const { floor: floorDefaults = {}, wall: wallDefaults = {} } = defaultFields || {};

            basement = {
                ...basement,
                wall: {
                    ...basement.wall,
                    ...wallDefaults,
                },
                floor: {
                    ...basement.floor,
                    ...floorDefaults,
                },
            };

            //Only create slab if slab area is non-zero

            let slab = {};

            if (slabArea > 0) {
                slab = {
                    ...slabTemplate,
                    label: "Walkout (Slab)",
                    toffId: component.toffId,
                    exposedSurfacePerimeter: slabExpPerimeter,
                    floor: {
                        ...slabTemplate.floor,
                        measurements: {
                            ...slabTemplate.floor.measurements,
                            isRectangular: false,
                            area: _toMetric(slabArea, "m"), //already in m
                            perimeter: _toMetric(slabPerimeter, "m"), //already in m
                        },
                    },
                };

                slab = {
                    ...slab,
                    floor: {
                        ...slab.floor,
                        ...floorDefaults,
                    },
                };
            }

            return { basement, slab };
        }

        default: {
            //! This should never happen. If it does, it will at least log why it's happening
            console.log(component.compType.toLowerCase());
            return;
        }
    }
};

export const storeyTranslator = (component, defaultCodes = {}) => {
    const {
        floorHeaderHeight: { value: floorHeaderHeightValue = 0 } = {},
        hasAttic: { value: hasAttic = false } = {},
    } = component;

    const hasFloorHeader = floorHeaderHeightValue > 0;

    let walls = {};
    let ceilings = {};
    let expFloors = {};
    let totCeilingArea = 0;

    let bufferedFloorHeaderPerimeter = Math.max(0, component.interiorWallLength.value);

    const {
        Wall: wallDefaults = {},
        Ceiling: ceilingDefaults = {},
        CeilingFlat: ceilingFlatDefaults = {},
        FloorHeader: floorHeaderDefaults = {},
        Window: windowDefaults = {},
        Floor: expFloorDefaults = {},
    } = defaultCodes;

    component.components.map((comp, index) => {
        if (comp.type === "exposedfloor") {
            expFloors[`expFloor-toff-${index}`] = {
                ...expFloorTemplate,
                ...expFloorDefaults,
                label: comp.name,
                category: component.compType,
                measurements: {
                    ...expFloorTemplate.measurements,
                    length: getSubCompValue(comp.fields, "length"),
                    area: getSubCompValue(comp.fields, "area"),
                },
            };
        } else if (comp.type === "bufferedwall") {
            walls[`wall-toff-${index}`] = {
                ...wallTemplate,
                ...wallDefaults,
                label: comp.name,
                category: component.compType,
                measurements: {
                    ...wallTemplate.measurements,
                    corners: getSubCompValue(comp.fields, "corners"),
                    intersections: getSubCompValue(comp.fields, "intersections"),
                    perimeter: getSubCompValue(comp.fields, "intPerimeter"),
                    height: component.wallHeight.value,
                },
                subcomponents: {
                    ...wallTemplate.subcomponents,
                    floorHeader: !hasFloorHeader
                        ? {}
                        : {
                              [`${component.compType}-floor-header-toff-buffWall-${index}`]: {
                                  ...floorHeaderTemplate,
                                  ...floorHeaderDefaults,
                                  displayOrder: 1,
                                  measurements: {
                                      ...floorHeaderTemplate.measurements,
                                      perimeter: getSubCompValue(comp.fields, "intPerimeter"),
                                      height: parseFloat(floorHeaderHeightValue.toFixed(2)),
                                  },
                              },
                          },
                },
            };

            bufferedFloorHeaderPerimeter += hasFloorHeader ? getSubCompValue(comp.fields, "intPerimeter") : 0;
        } else {
            //? This option is more verbose than outsourcing it to a function, however I think clarity is paramount here

            if (comp.type === "atticceiling") {
                const cArea = getSubCompValue(comp.fields, "area") || 0;
                const cEaveLength = getSubCompValue(comp.fields, "eaveLength");

                totCeilingArea += cArea;

                ceilings[`ceiling-toff-${index}`] = {
                    ...ceilingTemplate,
                    ...ceilingDefaults,
                    label: comp.name,
                    displayOrder: 1,
                    category: component.compType,
                    measurements: {
                        ...ceilingTemplate.measurements,
                        heelHeight: 0.13,
                        length: parseFloat(cEaveLength.toFixed(2)),
                        drawing: {},
                        area: parseFloat(cArea.toFixed(2)),
                    },
                };
            } else if (comp.type === "cathedralceiling") {
                const cWidth = getSubCompValue(comp.fields, "width");
                const cLength = getSubCompValue(comp.fields, "length");
                const cHeight = getSubCompValue(comp.fields, "height");
                const numGables = getSubCompValue(comp.fields, "numGables") || 0;
                const numIntWalls = 2 - Math.max(Math.min(2, numGables), 0);
                totCeilingArea += cLength * cWidth; //projected floor plan area

                ceilings[`ceiling-toff-${index}`] = {
                    ...ceilingTemplate,
                    ...ceilingFlatDefaults,
                    label: comp.name,
                    displayOrder: 1,
                    constructionType: {
                        id: 2,
                    },
                    category: component.compType,
                    measurements: {
                        ...ceilingTemplate.measurements,
                        slope: {
                            ...ceilingTemplate.measurements.slope,
                            value: parseFloat((cHeight / (cLength / 2)).toFixed(2)),
                            id: 0,
                        },
                        heelHeight: 0.13,
                        length: parseFloat((cLength * 2).toFixed(2)),
                        drawing: {},
                        area: parseFloat((2 * Math.sqrt(cHeight ** 2 + cWidth ** 2 / 4) * cLength).toFixed(2)),
                    },
                };

                if (numIntWalls > 0) {
                    const singleIntWallArea = (1 / 2) * cWidth * cHeight;
                    Array(numIntWalls)
                        .fill()
                        .map((kw, i) => {
                            walls[`cathwall-toff-${index}${i}`] = {
                                ...wallTemplate,
                                ...wallDefaults,
                                label: `Cathedral Wall ${i + 1}`,
                                category: component.compType,
                                measurements: {
                                    ...wallTemplate.measurements,
                                    corners: 2,
                                    intersections: 0,
                                    perimeter: cWidth,
                                    height: singleIntWallArea / cWidth,
                                },
                                subcomponents: {},
                            };
                        });
                }
            } else if (comp.type === "slopedceiling") {
                //? Not currently used const cPitch = comp.fields[0].value
                const cHeight = getSubCompValue(comp.fields, "height");
                const cWidth = getSubCompValue(comp.fields, "width");
                const cLength = getSubCompValue(comp.fields, "length");

                totCeilingArea += cWidth * cLength; //projected floor plan area

                ceilings[`ceiling-toff-${index}`] = {
                    ...ceilingTemplate,
                    ...ceilingFlatDefaults,
                    label: comp.name,
                    compId: -1,
                    displayOrder: 1,
                    constructionType: {
                        id: 3,
                    },
                    category: component.compType,
                    measurements: {
                        ...ceilingTemplate.measurements,
                        slope: {
                            ...ceilingTemplate.measurements.slope,
                            value: parseFloat((cHeight / cLength).toFixed(2)),
                            id: 0,
                        },
                        heelHeight: 0.13,
                        length: cLength,
                        drawing: {},
                        area: parseFloat((Math.sqrt(cHeight ** 2 + cWidth ** 2) * cLength).toFixed(2)),
                    },
                };
            } else if (comp.type === "raisedceiling") {
                // const cHeight = comp.fields[0].value
                const cArea = getSubCompValue(comp.fields, "area");
                // const cPerimeter = comp.fields[2].value
                const cLength = getSubCompValue(comp.fields, "baseLength");

                totCeilingArea += cArea;

                ceilings[`ceiling-toff-${index}`] = {
                    ...ceilingTemplate,
                    ...ceilingFlatDefaults,
                    label: comp.name,
                    compId: -1,
                    displayOrder: 1,
                    constructionType: {
                        id: 3,
                    },
                    category: component.compType,
                    measurements: {
                        ...ceilingTemplate.measurements,
                        slope: {
                            value: 0,
                            id: 1,
                        },
                        heelHeight: 0.13,
                        length: cLength,
                        drawing: {},
                        area: cArea,
                    },
                };
            }
        }
    });

    const takeoffWall = {
        ...wallTemplate,
        ...wallDefaults,
        label: `Main Wall - ${capFirstLetter(component.compType)}`,
        toffId: IDGenerator("toffmainwall"),
        category: component.compType,
        adjacentEnclosedSpace: false,
        // adjacentEnclosedSpace: true,
        measurements: {
            ...wallTemplate.measurements,
            corners: component.corners.value,
            intersections: component.intersections.value,
            // "height": 2.46,
            height: _toMetric(component.wallHeight.value, component.wallHeight.unit),
            perimeter: component.equivalentPerimeter.value,
        },
        subcomponents: {
            ...wallTemplate.subcomponents,
            window: _getWindows(component, windowDefaults, `wall-${component.compType}`),
            floorHeader: !hasFloorHeader
                ? {}
                : {
                      [`${component.compType}-floor-header-toff`]: {
                          ...floorHeaderTemplate,
                          ...floorHeaderDefaults,
                          displayOrder: 1,
                          measurements: {
                              ...floorHeaderTemplate.measurements,
                              perimeter: parseFloat(
                                  Math.max(
                                      0,
                                      component.floorPlanPerimeter.value - bufferedFloorHeaderPerimeter
                                  ).toFixed(2)
                              ), //Subtract shared and buffered walls
                              height: parseFloat(floorHeaderHeightValue.toFixed(2)),
                          },
                      },
                  },
        },
    };

    // isLastFloor &&
    if (hasAttic) {
        const floorArea = _toMetric(component.floorPlanArea.value, component.floorPlanArea.unit);
        const atticLength = _toMetric(component.floorPlanPerimeter.value, component.floorPlanPerimeter.unit) / 2; //take half the perimeter assuming 2 gable ends

        const atticArea = Math.max(0, floorArea - totCeilingArea);

        if (atticArea > 0) {
            ceilings[`ceiling-toff-000`] = {
                ...ceilingTemplate,
                ...ceilingDefaults,
                label: "Attic",
                displayOrder: 1,
                constructionType: {
                    id: 0,
                },
                category: component.compType,
                measurements: {
                    ...ceilingTemplate.measurements,
                    heelHeight: 0.13,
                    length: atticLength,
                    area: atticArea,
                    drawing: {},
                },
            };
        }
    }

    return {
        takeoffWall,
        walls,
        ceilings,
        expFloors,
    };
};
