import React from 'react';

export default ({className, style={}}) => (
    <svg className={className} style={style} width="38px" height="38px" viewBox="0 0 38 38" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="Model---General" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinejoin="bevel">
            <g id="General---Open" transform="translate(-876.000000, -496.000000)" stroke="#39393C" strokeWidth="2">
                <g id="Group-15-Copy-3" transform="translate(862.000000, 481.000000)">
                    <g id="Group-13" transform="translate(0.000000, 1.000000)">
                        <g id="Group" transform="translate(33.000000, 33.000000) rotate(-225.000000) translate(-33.000000, -33.000000) translate(10.000000, 10.000000)">
                            <g id="Group-7" transform="translate(23.166667, 23.083333) rotate(-135.000000) translate(-23.166667, -23.083333) translate(19.166667, 9.583333)">
                                <polygon id="Triangle-Copy" fill="#F48020" transform="translate(3.833333, 6.708333) rotate(-360.000000) translate(-3.833333, -6.708333) " points="3.83333333 3.77475828e-13 7.66666667 13.4166667 3.74811293e-13 13.4166667"></polygon>
                                <polygon id="Triangle-Copy" fill="#FFFFFF" transform="translate(3.833333, 20.125000) scale(-1, -1) rotate(-360.000000) translate(-3.833333, -20.125000) " points="3.83333333 13.4166667 7.66666667 26.8333333 3.37507799e-14 26.8333333"></polygon>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)