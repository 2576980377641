import React from "react";

import { CloseDialog } from "components/Dialog";
import Button from "components/Button";
import Dialog from "components/Dialog";
import Input from "components/Input";

import classes from "../style.module.scss";

const CreateDialog = ({ toCreate, close, newFolderName, setNewFolderName, onClick, folderLoading }) => (
    <Dialog open={toCreate}>
        <CloseDialog closeActions={() => close()} />
        <div className={classes.dialog}>
            <h2>Create a New Folder</h2>
            <Input
                label={"Folder Name"}
                placeholder="Type folder name..."
                disabled={folderLoading}
                input={{
                    value: newFolderName,
                    onChange: (value) => setNewFolderName(value),
                }}
            />
            <div className={classes.footer}>
                <Button type="hollow" onClick={() => close()}>
                    Cancel
                </Button>
                <Button onClick={onClick} type="">
                    {folderLoading ? "Creating..." : "Create"}
                </Button>
            </div>
        </div>
    </Dialog>
);

export default CreateDialog;
