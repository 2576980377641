import React from "react";

export default ({ fill = "#18c1ad" }) => (
    <svg
        width="16px"
        height="15px"
        viewBox="0 0 16 15"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g id="Star-/-Blue" fill={fill} fillRule="nonzero">
            <path
                d="M8,0 L10.351141,4.76393202 L15.6084521,5.52786405 L11.8042261,9.23606798 L12.702282,14.472136 L8,12 L3.29771798,14.472136 L4.19577393,9.23606798 L0.39154787,5.52786405 L5.64885899,4.76393202 L8,0 Z M9.6870755,5.67793979 L8,2.259 L6.3129245,5.67793979 L2.54,6.226 L5.27025451,8.88694808 L4.626,12.644 L8,10.8702243 L11.373,12.644 L10.7297455,8.88694808 L13.459,6.226 L9.6870755,5.67793979 Z"
                id="Star"
            ></path>
        </g>
    </svg>
);
