import React from "react";

import classes from "./styles.module.scss";

import circlePencil from "assets/images/icons/circlePencil.svg";
import circleRemove from "assets/images/icons/circleRemove.svg";

const LabelRow = ({ label, toggleUpgradeDrawer, isUpgradeIdsExist, deleteUpgrade }) => {
    return (
        <div className={classes.labelRowContainer}>
            <span>{label}</span>
            {isUpgradeIdsExist ? (
                <div className={classes.icons}>
                    <img src={circlePencil} alt="circle pencil" onClick={() => toggleUpgradeDrawer()} />
                    <img src={circleRemove} alt="circle remove" onClick={() => deleteUpgrade()} />
                </div>
            ) : (
                <div className={classes.icons}>
                    <img
                        src={circleRemove}
                        style={{ transform: "rotate(45deg)" }}
                        alt="circle pencil"
                        onClick={() => toggleUpgradeDrawer()}
                    />
                </div>
            )}
        </div>
    );
};

export default LabelRow;
