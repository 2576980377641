import React from "react";

export default () => (
    <svg width="14" height="19" viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.9146 6.58729C12.3236 5.80803 11.5744 5.12836 10.6802 4.60031C7.70808 2.84518 4.04424 3.30149 1.59563 5.47415L2.59118 6.59615C4.56693 4.84306 7.52237 4.47754 9.91745 5.89191C10.6316 6.31363 11.2311 6.85526 11.7055 7.47605L12.9146 6.58729Z"
            fill="#65676A"
        />
        <rect x="2.03613" y="11.8643" width="10.4204" height="6.13672" stroke="#65676A" strokeWidth="1.5" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.129883 5.91895L5.08103 8.84276L5.84376 7.55116L2.18422 5.39008L4.3453 1.73054L3.0537 0.967802L0.129883 5.91895Z"
            fill="#65676A"
        />
    </svg>
);
