import IDGenerator from "../../../TakeoffData/IDGenerator";

export const foundationSubComponentsFactory = (type, compFields) => {
    switch (type.toLowerCase()) {
        case "ponywall": {
            const {
                height1 = 1,
                height2 = 1,
                perimeter = 10,
                id = IDGenerator("pw"),
            } = compFields || {};

            return {
                type: "ponywall",
                name: "Pony Wall Segment",
                id,
                fields: [
                    {
                        name: "Height 1",
                        field: "height1",
                        value: height1,
                        fieldKey: "takeoffLength",
                        type: "number",
                    },
                    {
                        name: "Height 2",
                        field: "height2",
                        value: height2,
                        fieldKey: "takeoffLength",
                        type: "number",
                    },
                    {
                        name: "Perimeter",
                        field: "perimeter",
                        value: perimeter,
                        fieldKey: "takeoffLength",
                        type: "number",
                    },
                ],
            };
        }
        case "garagewall": {
            const {
                intPerimeter = 12,
                height = 2.77,
                corners = 3,
                id = IDGenerator("gw"),
            } = compFields || {};

            return {
                type: "garagewall",
                name: "Garage Wall",
                id,
                fields: [
                    {
                        name: "Int. Perimeter",
                        field: "intPerimeter",
                        value: intPerimeter,
                        fieldKey: "takeoffLength",
                        type: "number",
                    },
                    {
                        name: "Height",
                        field: "height",
                        value: height,
                        fieldKey: "takeoffLength",
                        type: "number",
                    },
                    {
                        name: "Corners",
                        field: "corners",
                        value: corners,
                        unit: "count",
                        fieldKey: "takeoffCount",
                        type: "number",
                    },
                    {
                        name: "Intersections",
                        field: "intersections",
                        value: 2,
                        unit: "count",
                        fieldKey: "takeoffCount",
                        type: "number",
                    },
                ],
            };
        }
        default:
            break;
    }
};

export const foundationTableComponentsFactory = (type = "") => {
    switch (type) {
        default:
            return {
                title: "",
                width: 0,
                height: 0,
                overhangWidth: 0,
                qty: 0,
            };
    }
};

export const getFoundationSubcomponentsUnits = (type) => {
    switch (type.toLowerCase()) {
        case "corners":
        case "intersections": {
            return {
                options: [],
                unitType: "count",
            };
        }
        default:
            return {
                options: ["m", "ft"],
                unitType: "length",
            };
    }
};

export const getFoundationTablecomponentsUnits = (key) => {
    switch (key.toLowerCase()) {
        case "title":
            return {
                options: [],
                selected: null,
                unitType: "string",
            };
        case "qty":
            return {
                options: [],
                selected: "count",
                unitType: "count",
            };

        case "height":
        case "width":
            return {
                options: ["mm", "in", "m", "ft"],
                unitType: "length",
            };
        default:
            return {
                options: ["m", "ft"],
                unitType: "length",
            };
    }
};

export const getTableFieldKey = (key) => {
    switch (key.toLowerCase()) {
        case "title":
            return "takeoffTitle";
        case "qty":
            return "takeoffCount";

        case "height":
        case "width":
            return "takeoffWindowLength";
        default:
            return "takeoffLength";
    }
};
