import React from "react";
import classes from "./style.module.scss";

const ParagraphInput = ({ value, onChange, formTitle, placeholder = "", disabled = false, className }) => {
    return (
        <div className={classes.textInputContainer}>
            {formTitle && <label>{formTitle}</label>}
            <textarea
                placeholder={placeholder || "Text..."}
                value={value}
                onChange={onChange}
                className={classes.input}
                disabled={disabled}
            />
        </div>
    );
};

export default ParagraphInput;
