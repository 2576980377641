import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import ContinuousMedium from './';


const mapStateToProps = (
    {
        form,
    },
    {
        accessor
    }
) => {
    const selector = formValueSelector('uDefCode');
    const modelSelector = formValueSelector('model');
    const { id: materialCatId } = selector({form}, `${accessor}.materialCategory`) || {};
    const { id: materialTypeId } = selector({form}, `${accessor}.materialType`) || {};
    const resistivityValue = selector({form}, `${accessor}.resistivity`);
    const thicknessValue = selector({form}, `${accessor}.thickness`);
    const mediumLayers = selector({form}, 'uDefCode.layers.continuousMedium');

    const rValue = parseFloat((resistivityValue * thicknessValue).toFixed(2))

    const resistivityUnits = selector({form}, `${accessor}.resistivity_u`);
    const thicknessUnits = selector({form}, `${accessor}.thickness_u`);
    const rValueUnits = selector({form}, `${accessor}.rValue_u`);
    const modelUnits = modelSelector({form}, `modelData.uiSettings.primaryUnits`) || {};
    const layer = selector({form}, `${accessor}.layer`);

    return {
        accessor,
        materialCatId,
        materialTypeId,
        resistivityValue,
        resistivityUnits,
        thicknessUnits,
        rValue,
        rValueUnits,
        modelUnits,
        layer,
        mediumLayers
    }
};

const mapDispatchToProps = () => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ContinuousMedium);