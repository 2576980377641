import { connect } from "react-redux";
import { reduxForm, formValueSelector, reset } from "redux-form";
import StandardCode from "./";
import floorsAboveCodeTemplate from "features/Model/Enclosure/Templates/floorsAboveCode.json";
import { actions as userActions } from "store/users";
import { actions as enclosureActions } from "features/Model/Enclosure/_ducks";
import { actions as upgradeActions } from "features/Model/Upgrades/_ducks";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import { getMatchCodeRefs } from "utils/enclosure/components";
import { standardCodeLogic } from "utils/codes";

const { addToCodeLib, deleteCode } = userActions;
const { setInitCode, getRValue } = enclosureActions;
const { updateUpgradeCodes } = upgradeActions;

const mapStateToProps = (
    {
        form,
        model: { modelId },
        user: { uid },
        enclosure: { initCodeValues: { floorsAboveInsType = {} } = {} } = {},
        upgradeLibrary: { currentPackage, selectedUpgrade },
    },
    { fieldAccessor, modelFormChange, currentFormChange, toggleOpen, isCodeLibrary = false, componentId }
) => {
    const selector = formValueSelector("floorsAboveCode");
    const { id: floorsAboveStructureId, value: floorsAboveStructureValue = 0 } =
        selector({ form }, "floorsAboveCode.layers.structureType") || {};
    const { value: floorsAboveTypeSizeValue } = selector({ form }, "floorsAboveCode.layers.componentTypeSize") || {};
    const { id: floorsAboveSpacingId, value: floorsAboveSpacingValue } =
        selector({ form }, "floorsAboveCode.layers.spacing") || {};
    const { value: floorsAboveFramingValue } = selector({ form }, "floorsAboveCode.layers.framing") || {};
    const { value: floorsAboveIns1Value } = selector({ form }, "floorsAboveCode.layers.insulationLayer1") || {};
    const { value: floorsAboveIns2Value } = selector({ form }, "floorsAboveCode.layers.insulationLayer2") || {};
    const { value: floorsAboveInteriorValue } = selector({ form }, "floorsAboveCode.layers.interior") || {};
    const { value: floorsAboveSheathingValue } = selector({ form }, "floorsAboveCode.layers.sheathing") || {};
    const { value: floorsAboveExteriorValue } = selector({ form }, "floorsAboveCode.layers.exterior") || {};
    const { id: floorsAboveDropFramingId, value: floorsAboveDropFramingValue } =
        selector({ form }, "floorsAboveCode.layers.dropFraming") || {};
    const floorsAboveLabel = selector({ form }, "floorsAboveCode.label") || "";

    const frameSpacingValue = floorsAboveSpacingId === null ? floorsAboveFramingValue : floorsAboveSpacingValue;

    const floorsAboveValue = `4${floorsAboveStructureValue
        .toString()
        .substr(
            -1
        )}${floorsAboveTypeSizeValue}${frameSpacingValue}${floorsAboveIns1Value}${floorsAboveIns2Value}${floorsAboveInteriorValue}${floorsAboveSheathingValue}${floorsAboveExteriorValue}${floorsAboveDropFramingValue}`;

    const { codeRef: editingCodeRef = null, isLibCode = false } = floorsAboveInsType;

    const modelSelector = formValueSelector("model");
    const modelCodes = modelSelector({ form }, "modelData.codes") || {};
    const components = modelSelector({ form }, "modelData.components") || {};

    return {
        floorsAboveLabel,
        fieldAccessor,
        modelFormChange,
        currentFormChange,
        initialValues: {
            floorsAboveCode: isEmpty(floorsAboveInsType) ? floorsAboveCodeTemplate : floorsAboveInsType,
            addToLibrary: isLibCode,
        },
        floorsAboveStructureId,
        floorsAboveTypeSizeValue,
        isSolid: parseFloat(floorsAboveStructureId) === 4,
        isPanel: parseFloat(floorsAboveStructureId) === 5,
        isDropFraming: parseFloat(floorsAboveDropFramingId) === 1,
        makeSpacingDefault: ["5", "6", "7", "8", "9", "A", "B", "C"].includes(floorsAboveTypeSizeValue),
        makeInsulationDefault: ["6", "7", "8", "9", "A", "B", "C"].includes(floorsAboveTypeSizeValue),
        makeDropFramingInsDefault:
            ["9", "A", "E", "F", "U", "M", "N", "O", "P", "Q", "R", "S", "T"].includes(floorsAboveIns1Value) &
            (parseFloat(floorsAboveDropFramingId) === 1),
        floorsAboveValue,
        modelId,
        uid,
        editingCodeRef,
        isEditing: !!editingCodeRef,
        isLibCode,
        floorsAboveSpacingId,
        floorsAboveIns1Value,
        toggleOpen,
        isCodeLibrary,
        componentId,
        modelCodes,
        components,
        currentPackage,
        selectedUpgrade,
    };
};

const mapDispatchToProps = (dispatch) => ({
    clearForm: () => dispatch(reset("floorsAboveCode")),
    clearInitCode: () => dispatch(setInitCode({}, "floorsAboveInsType")),
});

const mergeProps = (state, dispatch, own) => ({
    ...state,
    ...dispatch,
    ...own,
});

const onSubmit = async (
    form,
    dispatch,
    {
        toggleOpen,
        uid,
        fieldAccessor,
        modelFormChange,
        currentFormChange,
        editingCodeRef,
        isCodeLibrary,
        componentId,
        isEditing,
        modelCodes,
        components,
        currentPackage,
        selectedUpgrade,
    }
) => {
    const { floorsAboveCode = {}, addToLibrary = false, setAsModelDefault = false, selectedComponents = [] } = form;

    toggleOpen(false);

    // Run standard code logic
    standardCodeLogic({
        componentCodeType: "FloorsAbove",
        componentId,
        code: floorsAboveCode,
        formInputName: "floorsAboveInsType",
        codeType: "S",
        isCodeLibrary,
        isEditing,
        editingCodeRef,
        dispatch,
        getRValue,
        modelFormChange,
        currentFormChange,
        fieldAccessor,
        components,
        modelCodes,
        addToLibrary,
        addToCodeLib,
        uid,
        deleteCode,
        updateUpgradeCodes,
        setAsModelDefault,
        selectedComponents,
        currentPackage,
        selectedUpgrade,
    });

    // Clear floors above code defaults for next time
    dispatch(setInitCode({}, "floorsAboveInsType"));
    // Reset form
    dispatch(reset("floorsAboveCode"));
};

const form = reduxForm({
    form: "floorsAboveCode",
    enableReinitialize: true,
    onSubmit: onSubmit,
})(StandardCode);

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(form);
