import { connect } from 'react-redux';
import UserTestingInfo from './';
import { reduxForm } from "redux-form";
import { actions } from 'store/users';
import { mixpanel } from "components/Mixpanel";

const { addUserTestingInfo } = actions;

const mapStateToProps = ({
    user:{
        uid,
        name='',
        userMeta:{
            company='',
            role='',
            experienceLevel='',
            agreedToTerms=false,
        }={},
    },
}) => {
    const nameArray = name ? name.split(' ') : [];
    const initialValues = name ? {
        firstName: nameArray[0],
        lastName: nameArray[1],
    } : {};

    return {
        uid,
        isOpen: !name || !company || !role || !agreedToTerms,
        initialValues:{
            ...initialValues
        }
    }
};

const mapDispatchToProps = () => ({});

const mergeProps = (state, dispatch, own) => ({
    ...state,
    ...dispatch,
    ...own,
});

const onSubmit = async (
    form,
    dispatch,
    {
        uid
    }
) => {
    const {
        firstName='',
        lastName='',
        company='',
        role='',
        experienceLevel='',
    } = form;

    const userMeta = {
        company,
        role,
        experienceLevel,
        agreedToTerms: true,
    };

    const name = `${firstName} ${lastName}`;

    await mixpanel.people.set({
        $name: name,
        Company: company,
        Role: role,
        "Expierience Level": experienceLevel,
    });
    await mixpanel.identify(uid);

    return dispatch(addUserTestingInfo({ uid, name, userMeta }));
}

const form = reduxForm({
    form:"userTestingInfo",
    enableReinitialize: true,
    onSubmit
})(UserTestingInfo);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(form);