import React from "react";
import { Field } from "redux-form";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import InputWithUnits from "components/Input/InputWithUnits";
import { getValidation, getDecimalPlaces } from "utils/fieldValidation";
import { getBaseUnits, getUnitOptions } from "utils/fields";

const heightValidation = getValidation("floorHeaderHeight");
const perimeterValidation = getValidation("floorHeaderPerimeter");
const areaValidation = getValidation("floorHeaderArea");

export default React.memo(
    ({
        accessor,
        floorHeaderHeight = 0,
        floorHeaderPerimeter = 0,
        modelUnits,
        heightUnits,
        perimeterUnits,
        areaUnits,
        change,
        headerHeightDrawing,
        floorHeaderHeightEquation,
        floorHeaderPerimeterEquation,
    }) => (
        <InputRow gridTemplate="1fr 1fr 1fr">
            <Field
                component={InputWithUnits}
                type="number"
                name={`${accessor}.measurements.height`}
                label="Floor Header Height"
                placeholder="0.00"
                validate={heightValidation}
                disabled={headerHeightDrawing}
                isDrawing={headerHeightDrawing}
                decimals={getDecimalPlaces("floorHeaderHeight")}
                change={change}
                units={{
                    base: getBaseUnits("floorHeaderHeight", modelUnits),
                    options: getUnitOptions("length"),
                    selected: heightUnits,
                    unitType: "length",
                    accessor: `${accessor}.measurements.height_u`,
                }}
                withEquation
                equation={floorHeaderHeightEquation}
                info={"The vertical height of the header from the bottom of the joist to the top of the floor above."}
            />
            <Field
                component={InputWithUnits}
                type="number"
                name={`${accessor}.measurements.perimeter`}
                label="Floor Header Perimeter"
                placeholder="0.00"
                validate={perimeterValidation}
                decimals={getDecimalPlaces("floorHeaderPerimeter")}
                change={change}
                units={{
                    base: getBaseUnits("floorHeaderPerimeter", modelUnits),
                    options: getUnitOptions("length"),
                    selected: perimeterUnits,
                    unitType: "length",
                    accessor: `${accessor}.measurements.perimeter_u`,
                }}
                withEquation
                equation={floorHeaderPerimeterEquation}
                info={"The interior length of the header."}
            />
            <Field
                component={InputWithUnits}
                type="number"
                name={`${accessor}.measurements.area`}
                label="Floor Header Area"
                placeholder="0.00"
                disabled={true}
                setValue={floorHeaderHeight * floorHeaderPerimeter}
                validate={areaValidation}
                decimals={getDecimalPlaces("floorHeaderArea")}
                change={change}
                units={{
                    base: getBaseUnits("floorHeaderArea", modelUnits),
                    options: getUnitOptions("area"),
                    selected: areaUnits,
                    unitType: "area",
                    accessor: `${accessor}.measurements.area_u`,
                }}
            />
        </InputRow>
    )
);
