import React from "react";
import { Field } from "redux-form";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import InputWithUnits from "components/Input/InputWithUnits";
import { getValidation, getDecimalPlaces } from "utils/fieldValidation";
import { getBaseUnits, getUnitOptions } from "utils/fields";

const lengthValidation = getValidation("expFloorLength");
const floorAreaValidation = getValidation("expFloorArea");

export default React.memo(
    ({
        accessor,
        modelUnits,
        lengthUnits,
        floorAreaUnits,
        change,
        lengthDrawing,
        isDrawingComponent,
        expFloorLengthEquation,
        expFloorAreaEquation,
    }) => (
        <InputRow gridTemplate="1fr 1fr 1fr">
            <Field
                component={InputWithUnits}
                type="number"
                name={`${accessor}.measurements.length`}
                label="Interior Floor Length"
                placeholder="0.00"
                validate={lengthValidation}
                disabled={lengthDrawing}
                isDrawing={lengthDrawing}
                decimals={getDecimalPlaces("expFloorLength")}
                change={change}
                units={{
                    base: getBaseUnits("expFloorLength", modelUnits),
                    options: getUnitOptions("length"),
                    selected: lengthUnits,
                    unitType: "length",
                    accessor: `${accessor}.measurements.length_u`,
                }}
                withEquation
                equation={expFloorLengthEquation}
                info={"Enter the interior floor length that runs perpendicular to the joists."}
            />
            <Field
                component={InputWithUnits}
                type="number"
                name={`${accessor}.measurements.area`}
                label="Interior Floor Area"
                placeholder="0.00"
                validate={floorAreaValidation}
                decimals={getDecimalPlaces("expFloorArea")}
                change={change}
                disabled={isDrawingComponent}
                isDrawing={isDrawingComponent}
                units={{
                    base: getBaseUnits("expFloorArea", modelUnits),
                    options: getUnitOptions("area"),
                    selected: floorAreaUnits,
                    unitType: "area",
                    accessor: `${accessor}.measurements.area_u`,
                }}
                withEquation
                equation={expFloorAreaEquation}
                info={
                    "Use this field to define the area of the exposed floor (i.e. the area of floor exposed to outside conditions)."
                }
            />
        </InputRow>
    )
);
