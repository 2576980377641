import React, { useState, useEffect } from 'react';
import classes from '../TableSelect/style.module.scss';
import sharedClasses from '../style.module.scss';
import ArrowDown from 'assets/images/icons/JSX/arrow-down';
import { useOutsideClickHook } from 'utils/outsideClick';
import InfoTooltip from 'components/InfoTooltip';
import isEqual from 'lodash/isEqual';
import DropdownSearch from 'components/Dropdown/DropdownSearch';
import LoadingDots from "components/LoadingDots";
import WarningTooltip from 'components/WarningTooltip';

export const DropDown = ({
    className,
    options,
    selected={},
    onChange,
    open,
    toggleOpen,
    search=false,
    searchPlaceholder,
    footer:Footer,
    dropDownWidth,
    emptyMessage="No options meet the requirements.",
}) => {
    const [filter, changeFilter] = useState('');

   const filteredOptions = options.filter(({ label='' }) => {
       if (!search) {
           return true;
       }
       return label.toString().toLowerCase().includes(filter.toLowerCase());
   });

    const classNames = [
        classes.dropDown,
        open && classes.open,
        className && className,
    ].filter(clss => clss);

    return (
        <div className={classNames.join(' ')} style={{ width:dropDownWidth }}>
            {
                search && 
                    <DropdownSearch
                        className={classes.selectFieldSearch}
                        changeFilter={changeFilter}
                        filter={filter}
                        placeholder={searchPlaceholder}
                    />
            }
            {
                filteredOptions.length > 0 ?
                    <ul>
                        {
                            filteredOptions.map(({
                                value,
                                label,
                                labelIcon:Icon=null,
                                labelActionComponent:ActionComponent=null,
                                labelTag="",
                                disabled=false,
                            }, index) => {
                                const classNames = [
                                    classes.option,
                                    isEqual(selected, value) && classes.selected,
                                    disabled && classes.disabled
                                ].filter(c => c);

                                if (value === "heading") {
                                    return (
                                        <li className={classes.heading}>
                                            <h4>{label}</h4>
                                        </li>
                                    )
                                }
            
                                return (
                                    <li
                                        key={index}
                                        className={classNames.join(' ')}
                                        onClick={() => {
                                            if (disabled) {
                                                return;
                                            }
                                            onChange(value);
                                            toggleOpen(false);
                                        }}
                                    >
                                        {Icon && <Icon />}
                                        <div className={classes.label}>
                                            {label}
                                            {labelTag && <span className={classes.labelTag}>{labelTag}</span>}
                                        </div>
                                        {ActionComponent && <ActionComponent />}
                                    </li>
                                )
                            })
                        }
                    </ul> :
                        <div className={classes.emptyFilter}>
                            <span>{emptyMessage}</span>
                        </div>
            }
            {
                Footer && <Footer />
            }
        </div>
    )
};

const Select = ({
    name, 
    label, 
    input,
    className, 
    large,
    hideLabel=false,
    options=[],
    disabled=false,
    placeholder='Select',
    updateSelected,
    info='',
    meta={},
    forcedLabel='',
    parentError=false,
    search=false,
    searchPlaceholder='',
    footer=null,
    dropDownWidth="100%",
    isLoading = false,
    warning="",
}) => {
    const { invalid=false, error='', touched=false } = meta;
    const classNames = [
        classes.selectField,
        sharedClasses.inputField,
        className && className,
        large && sharedClasses.large,
        disabled && classes.disabled,
        hideLabel && classes.labelHidden,
        ((parentError || invalid) && touched) && sharedClasses.invalid,
        warning && classes.warning
    ].filter(c => c);

    const { value, onChange } = input;
    const [open, toggleOpen] = useState(false);
    const [selected, toggleSelected] = useState('');
    const ref = useOutsideClickHook(() => toggleOpen(false));

    const handleChange = newValue => {
        if (updateSelected) {
            updateSelected(newValue);
        }
        onChange(newValue);
    };

    useEffect(() => {
        if (forcedLabel) {
            return toggleSelected(forcedLabel);
        }
        const { label:selectedLabel } = options.find(({value:val}) => isEqual(val, value)) || {};
        toggleSelected(selectedLabel);
    },[value, options]);

    return (
        <div ref={ref} className={classNames.join(' ')}>
            <label className={hideLabel ? 'invisible' : ''} htmlFor={name}>{label}</label>
            {isLoading && <div className={classes.loading}><LoadingDots /></div>}
            {
                info && <InfoTooltip className={sharedClasses.infoTooltip} info={info} />
            }
            {
                warning && <WarningTooltip className={`${classes.warningTooltip} ${info && classes.pushLeft}`} warning={warning} />
            }
            {!isLoading &&
                <div
                    className={`${sharedClasses.selectInput} ${classes.value} ${open && classes.open} ${disabled && `${classes.disabled} ${sharedClasses.disabled}`}`}
                    onClick={() => !disabled && toggleOpen(!open)}
                >
                    {selected ? <span className={classes.selected}>{selected}</span> : <span className={classes.placholder}>{placeholder}</span>} <ArrowDown />
                </div>
            }
            {!isLoading &&
                <DropDown
                    className={classes.dropDown}
                    options={options}
                    selected={value}
                    onChange={handleChange}
                    open={open}
                    toggleOpen={toggleOpen}
                    search={search}
                    searchPlaceholder={searchPlaceholder}
                    footer={footer}
                    dropDownWidth={dropDownWidth}
                />
            }
            {invalid && error && touched && typeof error === "string" && <span className={sharedClasses.errorMessage}>{error}</span>}
        </div>
    )
};

export default Select;