import React, { useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectImageData, selectActiveComponent } from "../../_ducks/selector";
import { setMessage, setRectangle } from "../../_ducks/actions";

import { selectOptionsForDropdown, foundationTypes, storeysTypes } from "../../_ducks/utils";
import { getDecimalPlaces } from "utils/fieldValidation";
import { getBaseUnits, getUnitOptions } from "utils/fields";

import IDGenerator from "features/Model/Enclosure/Takeoff/TakeoffData/IDGenerator";

import Button from "components/Button";
import Input from "components/Input";
import Select from "components/Input/Select";
import InputWithUnits from "components/Input/InputWithUnits";

import { cancelButton, buttonRow, saveButton, inputMarginBottom } from "../style.module.scss";

const SetRectangle = ({
    imageData,
    image,
    activeComponent,
    foundation,
    storeys,
    primaryUnits,
    deleteShape,
    setRectangle,
    setMessage,
}) => {
    const { rectangles } = imageData[image];
    const activeRectangle = rectangles[activeComponent] || {};

    const {
        id,
        foundationStorey = "",
        takeoffField = "",
        window = {
            title: activeRectangle?.name || "",
            ovHeightUnits: primaryUnits,
            ovHeight: 0,
            ovWidthUnits: primaryUnits,
            ovWidth: 0,
            quantity: 1,
        },
        ceilingHeight = 0,
        numGables = 2,
        ceilingHeightUnits = primaryUnits,
    } = activeRectangle.takeoff || {};

    const {
        ovHeightUnits = primaryUnits,
        ovHeight = 0,
        ovWidthUnits = primaryUnits,
        ovWidth = 0,
        quantity = 1,
    } = window;

    const foundationStoreySelectArr = [
        foundation.compType,
        ...storeysTypes.filter((storey) => Object.keys(storeys).includes(storey)),
    ];

    const foundationStoreySelect = foundationStoreySelectArr.map((val, index) => ({
        value: val.toLowerCase(),
        label:
            index === 0
                ? `Foundation - ${val.charAt(0).toUpperCase() + val.slice(1)}`
                : `${val.charAt(0).toUpperCase() + val.slice(1)}${
                      index === foundationStoreySelectArr.length - 1 ? "" : " Storey"
                  }`,
    }));

    const takeoffFieldOptions =
        selectOptionsForDropdown({
            shapeType: "rectangle",
            foundation: foundationTypes.includes(foundationStorey) ? foundationStorey : "",
            storey: Object.keys(storeys).includes(foundationStorey) ? foundationStorey : "",
        }) || [];

    const withIdTakeoffFields = [
        "pony wall (elevation)",
        "window",
        "exposed floor",
        "attic ceiling (floor plan)",
        "cathedral ceiling (floor plan)",
        "sloped ceiling (floor plan)",
        "raised ceiling (floor plan)",
    ];

    const idGeneratorOption = {
        window: "toff-table",
        "pony wall (elevation)": "pw",
        "exposed floor": "ef",
        "attic ceiling (floor plan)": "ac",
        "cathedral ceiling (floor plan)": "cc",
        "sloped ceiling (floor plan)": "sc",
        "raised ceiling (floor plan)": "rc",
    };

    return (
        <div>
            <div>
                <Input
                    className={inputMarginBottom}
                    label={"Rectangle's Name"}
                    input={{
                        value: activeRectangle?.name,
                        onChange: (e) =>
                            setRectangle(image, {
                                ...activeRectangle,
                                name: e,
                                takeoff: {
                                    ...activeRectangle.takeoff,
                                    window: {
                                        ...activeRectangle.takeoff.window,
                                        title: e,
                                    },
                                },
                            }),
                    }}
                />
                <Select
                    className={inputMarginBottom}
                    label={"Select Foundation or Storey"}
                    options={foundationStoreySelect}
                    input={{
                        value: foundationStorey,
                        onChange: (e) =>
                            setRectangle(image, {
                                ...activeRectangle,
                                takeoff: {
                                    ...activeRectangle.takeoff,
                                    foundationStorey: e,
                                    takeoffField: "",
                                },
                            }),
                    }}
                />
                <Select
                    className={
                        (takeoffField === "window" ||
                            takeoffField === "attic ceiling (floor plan)" ||
                            takeoffField === "cathedral ceiling (floor plan)" ||
                            takeoffField === "sloped ceiling (floor plan)" ||
                            takeoffField === "raised ceiling (floor plan)") &&
                        inputMarginBottom
                    }
                    label={"Select Takeoff Field"}
                    disabled={!foundationStorey}
                    options={takeoffFieldOptions?.map((val) => ({
                        value: val.toLowerCase(),
                        label: val,
                    }))}
                    input={{
                        value: takeoffField,
                        onChange: (e) => {
                            const newId = withIdTakeoffFields.includes(takeoffField)
                                ? !id || !id.includes(idGeneratorOption[takeoffField])
                                    ? IDGenerator(idGeneratorOption[takeoffField])
                                    : id
                                : "";

                            setRectangle(image, {
                                ...activeRectangle,
                                takeoff: {
                                    ...activeRectangle.takeoff,
                                    id: newId,
                                    takeoffField: e,
                                },
                            });
                        },
                    }}
                />
                {takeoffField === "window" && (
                    <>
                        <InputWithUnits
                            className={inputMarginBottom}
                            label={"Header Height"}
                            type="number"
                            decimals={getDecimalPlaces("windowHeaderHeight")}
                            units={{
                                base: getBaseUnits("windowHeaderHeight", primaryUnits),
                                options: getUnitOptions("length"),
                                selected: ovHeightUnits,
                                unitType: "length",
                            }}
                            input={{
                                onChange: (value) =>
                                    setRectangle(image, {
                                        ...activeRectangle,
                                        takeoff: {
                                            ...activeRectangle.takeoff,
                                            window: {
                                                ...activeRectangle.takeoff.window,
                                                ovHeight: value,
                                            },
                                        },
                                    }),
                                value: ovHeight,
                            }}
                            setValue={ovHeight}
                            change={(value, unit) =>
                                setRectangle(image, {
                                    ...activeRectangle,
                                    takeoff: {
                                        ...activeRectangle.takeoff,
                                        window: {
                                            ...activeRectangle.takeoff.window,
                                            ovHeightUnits: unit,
                                        },
                                    },
                                })
                            }
                        />
                        <InputWithUnits
                            className={inputMarginBottom}
                            label={"Overhang Width"}
                            type="number"
                            decimals={getDecimalPlaces("windowOverhangWidth")}
                            units={{
                                base: getBaseUnits("windowOverhangWidth", primaryUnits),
                                options: getUnitOptions("length"),
                                selected: ovWidthUnits,
                                unitType: "length",
                            }}
                            input={{
                                onChange: (value) =>
                                    setRectangle(image, {
                                        ...activeRectangle,
                                        takeoff: {
                                            ...activeRectangle.takeoff,
                                            window: {
                                                ...activeRectangle.takeoff.window,
                                                ovWidth: value,
                                            },
                                        },
                                    }),
                                value: ovWidth,
                            }}
                            setValue={ovWidth}
                            change={(value, unit) =>
                                setRectangle(image, {
                                    ...activeRectangle,
                                    takeoff: {
                                        ...activeRectangle.takeoff,
                                        window: {
                                            ...activeRectangle.takeoff.window,
                                            ovWidthUnits: unit,
                                        },
                                    },
                                })
                            }
                        />
                        <Input
                            label={"Quantity"}
                            type="number"
                            input={{
                                onChange: (value) =>
                                    setRectangle(image, {
                                        ...activeRectangle,
                                        takeoff: {
                                            ...activeRectangle.takeoff,
                                            window: {
                                                ...activeRectangle.takeoff.window,
                                                quantity: value,
                                            },
                                        },
                                    }),
                                value: quantity,
                            }}
                        />
                    </>
                )}
                {(takeoffField === "cathedral ceiling (floor plan)" ||
                    takeoffField === "sloped ceiling (floor plan)" ||
                    takeoffField === "raised ceiling (floor plan)") && (
                    <InputWithUnits
                        className={inputMarginBottom}
                        label={"Ceiling Height from Base"}
                        type="number"
                        decimals={2}
                        units={{
                            base: getBaseUnits("ceilingLength", primaryUnits),
                            options: getUnitOptions("length"),
                            selected: ceilingHeightUnits,
                            unitType: "length",
                        }}
                        input={{
                            onChange: (value) =>
                                setRectangle(image, {
                                    ...activeRectangle,
                                    takeoff: {
                                        ...activeRectangle.takeoff,
                                        ceilingHeight: value,
                                    },
                                }),
                            value: ceilingHeight,
                        }}
                        setValue={ceilingHeight}
                        change={(value, unit) =>
                            setRectangle(image, {
                                ...activeRectangle,
                                takeoff: {
                                    ...activeRectangle.takeoff,
                                    ceilingHeightUnits: unit,
                                },
                            })
                        }
                    />
                )}
                {(takeoffField === "cathedral ceiling (floor plan)" ||
                    takeoffField === "sloped ceiling (floor plan)") && (
                    <Input
                        className={inputMarginBottom}
                        label={"Exposed Wall/Gable Ends"}
                        type="number"
                        decimals={0}
                        input={{
                            onChange: (value) =>
                                setRectangle(image, {
                                    ...activeRectangle,
                                    takeoff: {
                                        ...activeRectangle.takeoff,
                                        numGables: Math.max(Math.min(2, value), 0),
                                    },
                                }),
                            value: Math.max(Math.min(2, numGables), 0),
                        }}
                        setValue={Math.max(Math.min(2, numGables), 0)}
                    />
                )}
            </div>
            <div className={`${buttonRow} `}>
                <Button smallPadding onClick={deleteShape} type="red" className={cancelButton}>
                    Delete
                </Button>
            </div>
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    imageData: selectImageData,
    activeComponent: selectActiveComponent,
});

const mapDispatchToProps = (dispatch) => ({
    setRectangle: (image, rectangle) => dispatch(setRectangle(image, rectangle)),
    setMessage: ({ message, type, anchorOrigin, autoHideDuration, isOpen, direction }) =>
        dispatch(
            setMessage({
                message,
                type,
                anchorOrigin,
                autoHideDuration,
                isOpen,
                direction,
            })
        ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SetRectangle);
