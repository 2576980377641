import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import CompositeRCalc from '.';
import { getDefaultCompositeObj } from 'utils/enclosure/basement';
import { isEmpty } from 'lodash';
import { getDecimalPlaces } from 'utils/fieldValidation';
import { actions } from 'features/Model/Enclosure/_ducks';

const { updateCompositeCalc, clearCompositeCalc } = actions;


const calculateRSIPortion = (percentage, rsi, coreRVal=0.116) => percentage !== 0 ? percentage / (rsi + coreRVal) : 0;

const mapStateToProps = (
    {
        form,
        enclosure:{
            compositeRCalc:{
                open=false,
                fieldId="",
                fieldAccessor="",
                isConcrete=true,
                rValUnits="",
                currentFormChange,
                formName="model"
            }={}
        }={}
    },
    {
        modelFormChange,
    }
) => {
    const selector = formValueSelector('compositeRCalc');
    const modelFormSelector = formValueSelector('model');
    const currentFormSelector = formValueSelector(formName);

    // Composite form
    const section0Percentage = selector({form}, `section0.percentage`) || 0;
    const section1Percentage  = selector({form}, `section1.percentage`) || 0;
    const section2Percentage  = selector({form}, `section2.percentage`) || 0;
    const section3Percentage  = selector({form}, `section3.percentage`) || 0;
    const section0RSI = selector({form}, `section0.rsi`) || 0;
    const section1RSI  = selector({form}, `section1.rsi`) || 0;
    const section2RSI  = selector({form}, `section2.rsi`) || 0;
    const section3RSI  = selector({form}, `section3.rsi`) || 0;

    // Model form
    // const { codeRef:selectedCodeRef } = modelFormSelector({form}, `${fieldAccessor}`) || {};

    const currentEffRVal = fieldAccessor.includes('extAddedInsType') ?
        currentFormSelector({form}, `${fieldAccessor}`) || 0 :
        currentFormSelector({form}, `${fieldAccessor}_effRVal`) || 0

    const currentComposite = fieldAccessor.includes('extAddedInsType') ?
        currentFormSelector({form}, `${fieldAccessor.split('.value')[0]}_composite`) :
        currentFormSelector({form}, `${fieldAccessor}_composite`)

    const modelUnits = modelFormSelector({form}, `modelData.uiSettings.primaryUnits`) || {};

    // Calculate total
    const section0 = calculateRSIPortion(section0Percentage, section0RSI, isConcrete ? 0.116 : 0.417);
    const section1 = calculateRSIPortion(section1Percentage, section1RSI, isConcrete ? 0.116 : 0.417);
    const section2 = calculateRSIPortion(section2Percentage, section2RSI, isConcrete ? 0.116 : 0.417);
    const section3 = calculateRSIPortion(section3Percentage, section3RSI, isConcrete ? 0.116 : 0.417); 

    const sumQuotient = section0 + section1 + section2 + section3;
    const decimals = getDecimalPlaces(fieldId);
    const totalEffRValue = parseFloat(((100 / sumQuotient) - (isConcrete ? 0.116 : 0.417)).toFixed(decimals));

    // Invalid total
    const totalInvalid = false
    // const totalInvalid = section0RSI == null || section1RSI == null || section2RSI == null || section3RSI == null
    //     || (section0Percentage && !section0RSI)
    //     || (section3Percentage && !section3RSI);

    // Invlaid percentages
    const areaPercent = section0Percentage + section1Percentage + section2Percentage;
    const percentInvalid = areaPercent > 100;


    return {
        initialValues: !isEmpty(currentComposite)
            ? {
                ...getDefaultCompositeObj(currentEffRVal),
                ...currentComposite,
            }
            : getDefaultCompositeObj(currentEffRVal),
        open,
        fieldId,
        fieldAccessor,
        currentFormChange: currentFormChange || modelFormChange,
        rValUnits,
        modelUnits,
        section0RSI,
        section1RSI,
        section2RSI,
        section3RSI,
        section0Percentage,
        section1Percentage,
        section2Percentage,
        section3Percentage,
        totalEffRValue,
        totalInvalid,
        percentInvalid,
        rZeroNotAllowed: ['crawlspaceWallType','basementPonyWallType'].includes(fieldId)
    }
};

const mapDispatchToProps = (dispatch) => ({
    updateCompositeCalc: (updates) => dispatch(updateCompositeCalc(updates)),
    clearCompositeCalc: () => dispatch(clearCompositeCalc()),
});

const mergeProps = (state, dispatch, own) => ({
    ...state,
    ...dispatch,
    ...own,
});

const onSubmit = (form, dispatch, state) => {
    const { fieldAccessor="", currentFormChange, reset } = state;
    let modelCompositeField = `${fieldAccessor}_composite`;
    let modelEffValueField = `${fieldAccessor}_effRVal`;
    
    if (fieldAccessor.includes('extAddedInsType')) {
        modelEffValueField = fieldAccessor;
        modelCompositeField = `${fieldAccessor.split('.value')[0]}_composite`;
    }

    const { effRVal=0 } = form;

    currentFormChange(modelCompositeField, form);
    currentFormChange(modelEffValueField, effRVal);
    reset();
    dispatch(clearCompositeCalc());
};

const form = reduxForm({
    form:"compositeRCalc",
    enableReinitialize: true,
    onSubmit:onSubmit
})(CompositeRCalc);


export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(form);