import React from "react";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";

import { selectChbaCheckList } from "features/Model/ProjectPathChecklist/_ducks/selectors";
import { finishedPhases, totalPhases, phaseMap } from "utils/chbaRoadMap";

import classes from "./style.module.scss";

const ChecklistDrawerButtonModel = ({ open, toggleOpen, chbaCheckList }) => {
    return (
        <button
            onClick={() => {
                toggleOpen(!open);
            }}
            className={`${classes.checklistDrawer} `}
        >
            <div className={classes.drawerButtonText}>
                <p>{`CHBA NZ Reno (${phaseMap[finishedPhases(chbaCheckList)] || 0}/${totalPhases - 2})`}</p>
            </div>
        </button>
    );
};

// export default ChecklistDrawerButton

const mapStateToProps = createStructuredSelector({
    chbaCheckList: selectChbaCheckList,
});

export default connect(mapStateToProps)(ChecklistDrawerButtonModel);
