import { isEmpty } from "lodash";
import types from "./types";
import { updateObject } from "utils";

import { chbaRoadmapPhases } from "../templates/chbaRoadmapPhases";

export const phases = chbaRoadmapPhases;

export const initialState = {
    isLoading: true,
    error: null,
    items: chbaRoadmapPhases,
    itemsToCompare: chbaRoadmapPhases,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.GET_CHBANZ_CHECKLIST_START:
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        case types.GET_CHBANZ_CHECKLIST_SUCCESS:
            return setChecklistItems(state, action);
        case types.GET_CHBANZ_CHECKLIST_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        case types.UPDATE_CHECKLIST_ITEMS:
            return updateChecklistItems(state, action);
        case types.SAVE_CHECKLIST_SUCCESS:
            return {
                ...state,
                itemsToCompare: action.payload,
                items: action.payload,
            };
        default:
            return state;
    }
};

//! Ones list is filled with content we can turn on saving, for now it's just loading default values
//! Because if we save items, we won't be able to update them without resitting the whole list
const setChecklistItems = (state, action) =>
    updateObject(state, {
        items: isEmpty(action.payload.items) ? phases : { ...phases, ...action.payload.items }, // if empty then set default values
        itemsToCompare: isEmpty(action.payload.items) ? phases : { ...phases, ...action.payload.items }, // if empty then set default values
        // items: phases,
        // itemsToCompare: phases,
        isLoading: false,
        error: null,
    });

const updateChecklistItems = (state, action) => {
    const { path = "", value } = action?.payload;

    const keyPath = path.split(".");

    // const isBoolean = (val) => "boolean" === typeof val;

    // return { ...state };
    return updateObject(state, {
        items: {
            ...state.items,
            [`${keyPath[0]}`]: updateObject(state.items[`${keyPath[0]}`], {
                [`${keyPath[1]}`]: updateObject(state.items[`${keyPath[0]}.${keyPath[1]}`], {
                    ...state.items[`${keyPath[0]}`][`${keyPath[1]}`],
                    [`${keyPath[2]}`]: value,
                }),
            }),
        },
    });
};
