const GET_USER_DATA = "GET_USER_DATA";
const GET_USER_DATA_START = "GET_USER_DATA_START";
const GET_USER_DATA_FAIL = "GET_USER_DATA_FAIL";
const GET_USER_DIR = "GET_USER_DIR";
const GET_USER_COMM_DIR ="GET_USER_COMM_DIR";
const GET_USER_BATCH_DIR = "GET_USER_BATCH_DIR";
const GET_USER_ORG = "GET_USER_ORG";
const SET_CODE_IN_LIB = "SET_CODE_IN_LIB";
const GET_USER_CODE_LIB = "GET_USER_CODE_LIB";
const REMOVE_FROM_DIR = "REMOVE_FROM_DIR";
const UPDATE_USER = "UPDATE_USER";
const UPDATE_USER_META = "UPDATE_USER_META";
const TOGGLE_DIR_LOADING = "TOGGLE_DIR_LOADING";
const TOGGLE_COMM_DIR_LOADING = "TOGGLE_COMM_DIR_LOADING";
const TOGGLE_BATCH_DIR_LOADING = "TOGGLE_BATCH_DIR_LOADING";
const TOGGLE_ORG_LOADING = "TOGGLE_ORG_LOADING";
const REMOVE_CODE_FROM_LIB = "REMOVE_CODE_FROM_LIB";
const UPDATE_USER_PREFERENCES = "UPDATE_USER_PREFERENCES";

export default {
    GET_USER_DATA,
    GET_USER_DATA_START,
    GET_USER_DATA_FAIL,
    GET_USER_DIR,
    GET_USER_COMM_DIR,
    GET_USER_BATCH_DIR,
    GET_USER_ORG,
    SET_CODE_IN_LIB,
    GET_USER_CODE_LIB,
    REMOVE_FROM_DIR,
    UPDATE_USER,
    UPDATE_USER_META,
    UPDATE_USER_PREFERENCES,
    TOGGLE_DIR_LOADING,
    TOGGLE_COMM_DIR_LOADING,
    TOGGLE_BATCH_DIR_LOADING,
    TOGGLE_ORG_LOADING,
    REMOVE_CODE_FROM_LIB,
};
