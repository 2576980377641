import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";

import Dialog from "components/Dialog";
import Button from "components/Button";

import {
    headerContainer,
    headerText,
    dialogContainer,
    foldersContainer,
    noFoldersText,
    folderContainer,
    folderImg,
    selectedFolderContainer,
    headerTextContainer,
    goBackImg,
    moveToContainer,
    moveToText,
} from "./style.module.scss";

import closeCircle from "assets/images/icons/closeCircle.svg";
import blueArrowRight from "assets/images/icons/blueArrowRight.svg";
import goBackFolder from "assets/images/icons/goBackFolder.svg";

const SelectFolder = ({
    folderToMove,
    modelToMove,
    close,
    folders,
    models,
    moveFolder,
    moveModel,
    folderLoading,
    modelLoading,
    history,
    isMoving = true,
    selectFolder,
}) => {
    const [currentFolderId, setCurrentFolderId] = useState(null);
    const [selectedFolder, setSelectedFolder] = useState("");

    const filteredFolders = !isEmpty(folders)
        ? Object.entries(folders).filter(([key, { parentFolderId }]) => {
              return parentFolderId === currentFolderId && key !== folderToMove;
          })
        : [];

    //AIDAN:
    //_AB Demo May 16: NkHImmysAMGcC0Fs7g7d

    return (
        <Dialog open={!!folderToMove || !!modelToMove} classes={{ paperClass: dialogContainer }}>
            <div className={headerContainer}>
                <div className={headerTextContainer}>
                    {currentFolderId && (
                        <img
                            className={goBackImg}
                            src={goBackFolder}
                            alt="go back"
                            onClick={() => {
                                setCurrentFolderId(folders[currentFolderId].parentFolderId);
                                setSelectedFolder("");
                            }}
                        />
                    )}
                    <span className={headerText}>
                        {currentFolderId ? `${folders[currentFolderId].name}` : "Model Directory"}
                    </span>
                </div>
                <img
                    src={closeCircle}
                    alt="close circle"
                    onClick={() => {
                        close();
                        setCurrentFolderId(null);
                        setSelectedFolder("");
                    }}
                    style={{ cursor: "pointer" }}
                />
            </div>
            <div className={foldersContainer}>
                {isEmpty(filteredFolders) ? (
                    <div>
                        <span className={noFoldersText}>You haven't created any folders yet.</span>
                    </div>
                ) : (
                    filteredFolders
                        .sort(([aKey, aValues], [bKey, bValues]) => aValues.name.localeCompare(bValues.name))
                        .map(([key, { name }]) => (
                            <div
                                key={key}
                                className={`${folderContainer} ${selectedFolder === key && selectedFolderContainer}`}
                                onClick={() => {
                                    if (selectedFolder === key) {
                                        setSelectedFolder("");
                                        setCurrentFolderId(key);
                                    } else {
                                        setSelectedFolder(key);
                                    }
                                }}
                            >
                                {name}
                                <img
                                    className={selectedFolder !== key && folderImg}
                                    src={blueArrowRight}
                                    alt="blue arrow right"
                                />
                            </div>
                        ))
                )}
            </div>
            <div className={moveToContainer}>
                {isMoving ? (
                    <>
                        <span className={moveToText}>
                            Moving{" "}
                            {folderToMove ? (
                                <strong style={{ color: "#0049c6" }}>{folders[folderToMove].name}</strong>
                            ) : modelToMove ? (
                                <strong style={{ color: "#0049c6" }}>{models[modelToMove].name}</strong>
                            ) : (
                                ""
                            )}{" "}
                            to{" "}
                            {selectedFolder || currentFolderId ? (
                                <strong>{folders[selectedFolder || currentFolderId].name}</strong>
                            ) : (
                                <strong>Model Directory</strong>
                            )}
                        </span>
                        <Button
                            onClick={async () => {
                                if (folderToMove) {
                                    await moveFolder(selectedFolder || currentFolderId);
                                    close();
                                    setCurrentFolderId(null);
                                    setSelectedFolder("");
                                    history.push("/dashboard");
                                }

                                if (modelToMove) {
                                    await moveModel(selectedFolder || currentFolderId);
                                    close();
                                    setCurrentFolderId(null);
                                    setSelectedFolder("");
                                }
                            }}
                            disabled={isEmpty(folders)} //disabled in the case that we're at the root and have no folders at all
                        >
                            {folderLoading || modelLoading ? "Moving..." : "Move"}
                        </Button>
                    </>
                ) : (
                    <>
                        <Button onClick={() => close()} type="hollow">
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                selectFolder(selectedFolder || currentFolderId);
                                close();
                            }}
                        >
                            Select This Folder
                        </Button>
                    </>
                )}
            </div>
        </Dialog>
    );
};

export default SelectFolder;
