import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import Construction from "./";
import { actions } from "features/Model/Enclosure/_ducks";

const { setInitCode, updateDrawingChanges, updateCodeDrawer } = actions;

const mapStateToProps = (
    {
        form,
        user: { codeLib: { Window: { codes = {} } = {} } = {} },
        enclosure: { calculatingRValue = {} } = {},
        upgradeLibrary: { currentPackage = {} },
    },
    { accessor, formName = "model", id, selectedUpgrade }
) => {
    const selector = formValueSelector(formName);
    const modelSelector = formValueSelector("model");

    const modelCodes =
        currentPackage?.upgrades?.[selectedUpgrade]?.codes || modelSelector({ form }, "modelData.codes") || {};
    const modelUnits = modelSelector({ form }, `modelData.uiSettings.primaryUnits`) || {};

    const { codeRef: selectedCodeRef, codeLabel: selectedLabel } = selector({ form }, `${accessor}.windowType`) || {};
    const selectedRValue = selector({ form }, `${accessor}.windowType_effRVal`) || 0;
    const windowShgc = selector({ form }, `${accessor}.shgc`) || 0;
    const windowEr = selector({ form }, `${accessor}.er`) || 0;
    const rValUnits = selector({ form }, `${accessor}.windowType_u`);

    const enerStar = selector({ form }, `${accessor}.energyStar`);
    const { component: drawingComponent = "", image: drawingImage } = selector({ form }, `${accessor}.drawing`) || {};
    const { [id]: { windowInsType: isCalculatingRValue = false } = {} } = calculatingRValue;

    const { value: eStarERVal = 0 } = selector({ form }, `${accessor}.energyStarType`) || {};
    const { major: h2kMajor, minor: h2kMinor } = modelSelector({ form }, "modelData.h2kVersion") || {};

    return {
        id,
        accessor,
        libCodes: codes,
        modelCodes,
        selectedCodeRef,
        selectedLabel,
        modelUnits,
        rValUnits,
        selectedRValue,
        windowShgc,
        windowEr,
        enerStar,
        drawingComponent,
        drawingImage,
        isCalculatingRValue,
        eStarERVal,
        h2kMinor,
    };
};

const mergeProps = (state, dispatch, own) => ({
    ...state,
    ...dispatch,
    ...own,
});

const mapDispatchToProps = (dispatch) => ({
    setInitCode: async (code, type) => {
        return dispatch(setInitCode(code, type));
    },
    updateDrawingChanges: (componentId, updates) => dispatch(updateDrawingChanges({ componentId, updates })),
    updateCodeDrawer: (updates) => dispatch(updateCodeDrawer({ updates })),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Construction);
