import React from 'react';
import classes from './style.module.scss';
import { Field } from 'redux-form';
import InputRow from 'components/Input/Row';
import Input from 'components/Input';
import InputWithUnits from 'components/Input/InputWithUnits';
import { required, requiredId, maxLength, getValidation, getDecimalPlaces } from 'utils/fieldValidation';
import { getOptions, getFirstOption, getBaseUnits, getUnitOptions } from 'utils/fields';
import Checkbox from 'components/Input/Checkbox';

const fuelPriceValidation = getValidation('generalFuelPrice');
const fuelTaxRebateValidation = getValidation('generalFuelTaxRebate');


//TODO: Check %'s on third and fourth input row
export default React.memo(({
    accessor,
    change
}) => {
    const modelUnits = "Metric"
    return (
        <>
            <h5 style={{marginBottom:"0.5rem", marginTop:"0.5rem"}}>Electricity</h5>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.electricity.dollarsPerUnit`}
                    label="Variable Rate"
                    placeholder="0.00"
                    validate={fuelPriceValidation}
                    decimals={getDecimalPlaces('generalFuelPrice')}
                    change={change}
                    units={{
                        base:getBaseUnits('generalFuelPrice', modelUnits),
                        options:['$/kWh'],
                        selected:'$/kWh',
                        unitType:'price',
                        accessor:`${accessor}.electricity.dollarsPerUnit_u`
                    }}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.electricity.dollarsPerMonth`}
                    label="Fixed Monthly Rate"
                    placeholder="0.00"
                    validate={fuelPriceValidation}
                    decimals={getDecimalPlaces('generalFuelPrice')}
                    change={change}
                    units={{
                        base:getBaseUnits('generalFuelPrice', modelUnits),
                        options:['$/mo'],
                        selected:'$/mo',
                        unitType:'price',
                        accessor:`${accessor}.electricity.dollarsPerMonth_u`
                    }}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.electricity.preTaxRebate`}
                    label="Rebate (Before Tax)"
                    placeholder="0.00"
                    validate={fuelTaxRebateValidation}
                    decimals={getDecimalPlaces('generalFuelTaxRebate')}
                    change={change}
                    units={{
                        base:getBaseUnits('generalFuelTaxRebate', modelUnits),
                        options:['%'],
                        selected:'%',
                        unitType:'fraction',
                        accessor:`${accessor}.electricity.preTaxRebate_u`
                    }}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.electricity.tax`}
                    label="Tax"
                    placeholder="0.00"
                    validate={fuelTaxRebateValidation}
                    decimals={getDecimalPlaces('generalFuelTaxRebate')}
                    change={change}
                    units={{
                        base:getBaseUnits('generalFuelTaxRebate', modelUnits),
                        options:['%'],
                        selected:'%',
                        unitType:'fraction',
                        accessor:`${accessor}.electricity.tax_u`
                    }}
                />                
            </InputRow>
            <h5 style={{marginBottom:"0.5rem", marginTop:"0.5rem"}}>Natural Gas</h5>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.naturalGas.dollarsPerUnit`}
                    label="Variable Rate"
                    placeholder="0.00"
                    validate={fuelPriceValidation}
                    decimals={getDecimalPlaces('generalFuelPrice')}
                    change={change}
                    units={{
                        base:getBaseUnits('generalFuelPrice', modelUnits),
                        options:['$/m³'],
                        selected:'$/m³',
                        unitType:'price',
                        accessor:`${accessor}.naturalGas.dollarsPerUnit_u`
                    }}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.naturalGas.dollarsPerMonth`}
                    label="Fixed Monthly Rate"
                    placeholder="0.00"
                    validate={fuelPriceValidation}
                    decimals={getDecimalPlaces('generalFuelPrice')}
                    change={change}
                    units={{
                        base:getBaseUnits('generalFuelPrice', modelUnits),
                        options:['$/mo'],
                        selected:'$/mo',
                        unitType:'price',
                        accessor:`${accessor}.naturalGas.dollarsPerMonth_u`
                    }}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.naturalGas.preTaxRebate`}
                    label="Rebate (Before Tax)"
                    placeholder="0.00"
                    validate={fuelTaxRebateValidation}
                    decimals={getDecimalPlaces('generalFuelTaxRebate')}
                    change={change}
                    units={{
                        base:getBaseUnits('generalFuelTaxRebate', modelUnits),
                        options:['%'],
                        selected:'%',
                        unitType:'fraction',
                        accessor:`${accessor}.naturalGas.preTaxRebate_u`
                    }}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.naturalGas.tax`}
                    label="Tax"
                    placeholder="0.00"
                    validate={fuelTaxRebateValidation}
                    decimals={getDecimalPlaces('generalFuelTaxRebate')}
                    change={change}
                    units={{
                        base:getBaseUnits('generalFuelTaxRebate', modelUnits),
                        options:['%'],
                        selected:'%',
                        unitType:'fraction',
                        accessor:`${accessor}.naturalGas.tax_u`
                    }}
                />
            </InputRow>
            <h5 style={{marginBottom:"0.5rem", marginTop:"0.5rem"}}>Propane</h5>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.propane.dollarsPerUnit`}
                    label="Variable Rate"
                    placeholder="0.00"
                    validate={fuelPriceValidation}
                    decimals={getDecimalPlaces('generalFuelPrice')}
                    change={change}
                    units={{
                        base:getBaseUnits('generalFuelPrice', modelUnits),
                        options:['$/L'],
                        selected:'$/L',
                        unitType:'price',
                        accessor:`${accessor}.propane.dollarsPerUnit_u`
                    }}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.propane.dollarsPerMonth`}
                    label="Fixed Monthly Rate"
                    placeholder="0.00"
                    validate={fuelPriceValidation}
                    decimals={getDecimalPlaces('generalFuelPrice')}
                    change={change}
                    units={{
                        base:getBaseUnits('generalFuelPrice', modelUnits),
                        options:['$/mo'],
                        selected:'$/mo',
                        unitType:'price',
                        accessor:`${accessor}.propane.dollarsPerMonth_u`
                    }}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.propane.preTaxRebate`}
                    label="Rebate (Before Tax)"
                    placeholder="0.00"
                    validate={fuelTaxRebateValidation}
                    decimals={getDecimalPlaces('generalFuelTaxRebate')}
                    change={change}
                    units={{
                        base:getBaseUnits('generalFuelTaxRebate', modelUnits),
                        options:['%'],
                        selected:'%',
                        unitType:'fraction',
                        accessor:`${accessor}.propane.preTaxRebate_u`
                    }}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.propane.tax`}
                    label="Tax"
                    placeholder="0.00"
                    validate={fuelTaxRebateValidation}
                    decimals={getDecimalPlaces('generalFuelTaxRebate')}
                    change={change}
                    units={{
                        base:getBaseUnits('generalFuelTaxRebate', modelUnits),
                        options:['%'],
                        selected:'%',
                        unitType:'fraction',
                        accessor:`${accessor}.propane.tax_u`
                    }}
                />
            </InputRow>
            <h5 style={{marginBottom:"0.5rem", marginTop:"0.5rem"}}>Oil</h5>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.oil.dollarsPerUnit`}
                    label="Variable Rate"
                    placeholder="0.00"
                    validate={fuelPriceValidation}
                    decimals={getDecimalPlaces('generalFuelPrice')}
                    change={change}
                    units={{
                        base:getBaseUnits('generalFuelPrice', modelUnits),
                        options:['$/L'],
                        selected:'$/L',
                        unitType:'price',
                        accessor:`${accessor}.oil.dollarsPerUnit_u`
                    }}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.oil.dollarsPerMonth`}
                    label="Fixed Monthly Rate"
                    placeholder="0.00"
                    validate={fuelPriceValidation}
                    decimals={getDecimalPlaces('generalFuelPrice')}
                    change={change}
                    units={{
                        base:getBaseUnits('generalFuelPrice', modelUnits),
                        options:['$/mo'],
                        selected:'$/mo',
                        unitType:'price',
                        accessor:`${accessor}.oil.dollarsPerMonth_u`
                    }}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.oil.preTaxRebate`}
                    label="Rebate (Before Tax)"
                    placeholder="0.00"
                    validate={fuelTaxRebateValidation}
                    decimals={getDecimalPlaces('generalFuelTaxRebate')}
                    change={change}
                    units={{
                        base:getBaseUnits('generalFuelTaxRebate', modelUnits),
                        options:['%'],
                        selected:'%',
                        unitType:'fraction',
                        accessor:`${accessor}.oil.preTaxRebate_u`
                    }}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.oil.tax`}
                    label="Tax"
                    placeholder="0.00"
                    validate={fuelTaxRebateValidation}
                    decimals={getDecimalPlaces('generalFuelTaxRebate')}
                    change={change}
                    units={{
                        base:getBaseUnits('generalFuelTaxRebate', modelUnits),
                        options:['%'],
                        selected:'%',
                        unitType:'fraction',
                        accessor:`${accessor}.oil.tax_u`
                    }}
                />
            </InputRow>
            <h5 style={{marginBottom:"0.5rem", marginTop:"0.5rem"}}>Wood</h5>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.wood.dollarsPerUnit`}
                    label="Variable Rate"
                    placeholder="0.00"
                    validate={fuelPriceValidation}
                    decimals={getDecimalPlaces('generalFuelPrice')}
                    change={change}
                    units={{
                        base:getBaseUnits('generalFuelPrice', modelUnits),
                        options:['$/cord'],
                        selected:'$/cord',
                        unitType:'price',
                        accessor:`${accessor}.wood.dollarsPerUnit_u`
                    }}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.wood.dollarsPerMonth`}
                    label="Fixed Monthly Rate"
                    placeholder="0.00"
                    validate={fuelPriceValidation}
                    decimals={getDecimalPlaces('generalFuelPrice')}
                    change={change}
                    units={{
                        base:getBaseUnits('generalFuelPrice', modelUnits),
                        options:['$/mo'],
                        selected:'$/mo',
                        unitType:'price',
                        accessor:`${accessor}.wood.dollarsPerMonth_u`
                    }}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.wood.preTaxRebate`}
                    label="Rebate (Before Tax)"
                    placeholder="0.00"
                    validate={fuelTaxRebateValidation}
                    decimals={getDecimalPlaces('generalFuelTaxRebate')}
                    change={change}
                    units={{
                        base:getBaseUnits('generalFuelTaxRebate', modelUnits),
                        options:['%'],
                        selected:'%',
                        unitType:'fraction',
                        accessor:`${accessor}.wood.preTaxRebate_u`
                    }}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.wood.tax`}
                    label="Tax"
                    placeholder="0.00"
                    validate={fuelTaxRebateValidation}
                    decimals={getDecimalPlaces('generalFuelTaxRebate')}
                    change={change}
                    units={{
                        base:getBaseUnits('generalFuelTaxRebate', modelUnits),
                        options:['%'],
                        selected:'%',
                        unitType:'fraction',
                        accessor:`${accessor}.wood.tax_u`
                    }}
                />
            </InputRow>
        </>
    );
})