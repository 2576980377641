import React from 'react';
import SectionHeading from 'components/SectionHeading';
import classes from '../style.module.scss';

export default () => (
    <div>
        <SectionHeading
            title="FAQs"
            subtitle="Answers to frequestly asked questions."
        />
        <div className={classes.contentWrapper}>
            <div className={classes.faq}>
                <h3>What do I do if my exported .h2k file will not run in HOT2000?</h3>
                <p>First, ensure that when exporting the file, no potential errors are flagged. If errors have been identified, try changing the value in the field referenced.</p>
                <p>If this still produces a corrupted file, you can submit a file error review in the Review tab. This file will be sent to the Volta SNAP team for review, and we will do our best to provide you with a solution as soon as possible.</p>
            </div>
            <div className={classes.faq}>
                <h3>I imported a file and some of the fields were blank, is that data gone?</h3>
                <p>No, that data is still contained within the uploaded file and if you export from this state, the file should remain intact. Some of our fields, particularly "type" drop-down menus, can be picky about what data they display, and sometimes the incoming information does not match the exact format expected.</p>
                <p>It is best practice, however, to update any blank fields to their expected value, so that Volta SNAP can properly run and display analytics.</p>
                <p>The development team is working towards reducing these occurrences. You can help by reaching out to our support team and identifying any fields that were unexpectedly blank after importing a file.</p>
            </div>
            <div className={classes.faq}>
                <h3>When I input a value in a field that allows unit conversions, sometimes the value will unexpectedly change by a few decimal places after I exit the field.</h3>
                <p>The .h2k file stores all values in metric, regardless of the unit selection the user makes, and we do the same to simplify data processing and converting to .h2k format. This means that, regardless of what units the user chooses when inputting their data, values are always stored in the base units that HOT2000 uses.</p>
                <p>This has the effect of occasionally producing rounding errors, since a value input in feet, for example, will be immediately converted to meters for storage, and then converted back to feet for display. Depending on the number of decimal places displayed, this can cause values input in non-metric units to "jump" a few decimal point</p>
                <p>This should not have an impact on any calculations since the data is stored to the same degree of precision as in HOT2000 itself. However, it is understandably an awkward artifact of the current unit conversion system. The Volta SNAP team is investigating this issue and looking into potential solutions.</p>
            </div>
        </div>
    </div>
)