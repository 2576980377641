import React from 'react';
import classes from './style.module.scss';
import sharedClasses from '../style.module.scss';

export default () => {
    return (
        <>
            <p>
                This parametric analysis performs two main types of simulations on the file you upload, investigating the impact of different combinations of energy conservation measures (ECMs) on performance.
            </p>
            <h3>Parametric Analysis</h3>
            <p>
                This analysis simulates all possible combinations of the ECM options listed below for a total of 144,000 simulation runs.
            </p>
            <h4>Ceilings with Attic Space</h4>
            <ul>
                <li>R-40 Fibreglass Blown-in</li>
                <li>R-50 Fibreglass Blown-in</li>
                <li>R-60 Fibreglass Blown-in</li>
                <li>R-70 Fibreglass Blown-in</li>
                <li>R-80 Fibreglass Blown-in</li>
            </ul>
            <h4>Flat and Cathedral Ceilings</h4>
            <ul>
                <li>R-40 effective</li>
            </ul>
            <h4>Walls</h4>
            <ul>
                <li>R-16 Effective, Fiberglass Batt, Standard Wall</li>
                <li>R-22 Effective, Fiberglass Batt, Exterior Insulation</li>
                <li>R-26 Effective, Fiberglass Batt, Exterior Insulation</li>
                <li>R-30 Effective, Fiberglass Batt, Exterior Insulation</li>
                <li>R-40 Effective, Fiberglass Batt, Exterior Insulation</li>
            </ul>
            <h4>Windows</h4>
            <ul>
                <li>Double Glazed, U=1.65, SHGC= 0.19, Vinyl Frame</li>
                <li>Double Glazed, U=1.65, SHGC= 0.26, Vinyl Frame</li>
                <li>Triple Glazed, U=1.08, SHGC= 0.17, Vinyl Frame</li>
                <li>Triple Glazed, U=1.08, SHGC= 0.26, Vinyl Frame</li>
            </ul>
            <h4>Floor Headers (interior insulation)</h4>
            <ul>
                <li>R-15 effective</li>
            </ul>
            <h4>Exposed Floors</h4>
            <ul>
                <li>R-28 effective</li>
            </ul>

            <h4>Exterior Foundation Wall</h4>
            <ul>
                <li>Interior Only (R-15 Effective, Fiberglass Batt)</li>
                <li>R-22 Effective, Fiberglass Batt, Exterior Insulation</li>
                <li>R-28 Effective, Fiberglass Batt, Exterior Insulation</li>
            </ul>
            <h4>Interior Foundation Wall</h4>
            <ul>
                <li>R-15 Effective, Fiberglass Batt</li>
            </ul>
            <h4>Foundation Slab (Below Grade)</h4>
            <ul>
                <li>Uninsulated</li>
                <li>R-10 Effective, Sub-slab Insulation, XPS</li>
                <li>R-20 Effective, Sub-slab Insulation, XPS</li>
            </ul>
            <h4>Airtightness</h4>
            <ul>
                <li>3.50 ACH</li>
                <li>2.50 ACH</li>
                <li>1.50 ACH</li>
                <li>1.00 ACH</li>
                <li>0.60 ACH</li>
            </ul>
            <h4>Ventilation System</h4>
            <ul>
                <li>67% Efficiency HRV</li>
            </ul>
            <h4>Heating and Cooling Systems</h4>
            <ul>
                <li>Electric Baseboard + AC, 4 COP</li>
                <li>96% Natural Gas Furnace, ECM Motor + AC, 4 COP</li>
                <li>Air Source Heat Pump, 3.66 COP</li>
                <li>Cold Climate Air Source Heat Pump, 2.97 COP</li>
            </ul>
            <h4>Domestic Hot Water System</h4>
            <ul>
                <li>Natural Gas Tank, 190L, 0.67 Eff.</li>
                <li>Natural Gas, Condensing Tankless, 0.95 Eff.</li>
                <li>Electric Tank, 190L, 0.87 Eff.</li>
                <li>Heat Pump Water Heater, 190L, 2.35 Eff.</li>
            </ul>
            <h4>Drain Water Heat Recovery</h4>
            <ul>
                <li>None</li>
                <li>DWHR unit, 55% Eff. </li>
            </ul>

            <br/>
            <h3>Reference Houses</h3>
            <p>
                Four reference simulations are run on the house file provided. Paramters that differ between reference runs are:
            </p>
            <p>
                <ul>
                    <li>Proposed windows / evenly distributed windows</li>
                    <li>Gas heating and hot water / electric heating and hot water</li>
                </ul>
            </p>
            <p>
                Common factors between reference runs are listed below.
            </p>
            <h4>Ceilings with Attic Space</h4>
            <ul>
                <li>R-40 Fibreglass Blown-in (climate zones 4 and 5)</li>
                <li>R-50 Fibreglass Blown-in (climate zones 6 and 7A)</li>
                <li>R-60 Fibreglass Blown-in (climate zones 7B and 8)</li>
            </ul>
            <h4>Flat and Cathedral Ceilings</h4>
            <ul>
                <li>R-28 effective</li>
            </ul>
            <h4>Walls</h4>
            <ul>
                <li>R-16 Effective, Fiberglass Batt, Standard Wall (climate zone 4)</li>
                <li>R-17 Effective, Fiberglass Batt, Standard Wall (climate zones 5, 6, and 7A)</li>
                <li>R-18 Effective, Fiberglass Batt, Exterior Insulation (climate zones 7B and 8)</li>
            </ul>
            <h4>Windows</h4>
            <ul>
                <li>Double Glazed, U=1.82, SHGC=0.5, Vinyl Frame (climate zones 4 and 5)</li>
                <li>Double Glazed, U=1.65, SHGC= 0.5, Vinyl Frame (climate zones 6 and 7A)</li>
                <li>Triple Glazed, U=1.36, SHGC= 0.46, Vinyl Frame (climate zones 7B and 8)</li>
            </ul>
            <h4>Floor Headers (interior insulation)</h4>
            <ul>
                <li>R-17 effective</li>
            </ul>
            <h4>Exposed Floors</h4>
            <ul>
                <li>R-28 Effective</li>
            </ul>

            <h4>Exterior Foundation Wall</h4>
            <ul>
                <li>Interior Only (R-15 Effective, Fiberglass Batt)</li>
            </ul>
            <h4>Interior Foundation Wall</h4>
            <ul>
                <li>R-15 Effective, Fiberglass Batt</li>
            </ul>
            <h4>Foundation Slab (Below Grade)</h4>
            <ul>
                <li>Uninsulated Slab</li>
            </ul>
            <h4>Foundation Slab (On Grade)</h4>
            <ul>
                <li>R-17 effective</li>
            </ul>
            <h4>Airtightness</h4>
            <ul>
                <li>2.50 ACH</li>
            </ul>
            <h4>Ventilation System</h4>
            <ul>
                <li>60% Efficiency HRV</li>
            </ul>
            
            <h4>Heating and Cooling Systems</h4>
            <ul>
                <li>Electric Baseboard <strong>OR</strong></li>
                <li>92% Natural Gas Furnace, ECM Motor</li>
            </ul>
            <h4>Domestic Hot Water System</h4>
            <ul>
                <li>Electric Tank, 190L, 0.88 Eff.  <strong>OR</strong></li>
                <li>Natural Gas Tank, 190L, 0.58 Eff.</li>
            </ul>
            <h4>Drain Water Heat Recovery</h4>
            <ul>
                <li>None</li>
            </ul>
            
           
        </>
    )
}