import React from "react";
import classes from "./style.module.scss";
import Loading from "..";

export default ({ sectionLoadingName = null, subClassName }) => (
    <div className={classes.sectionLoadingContainer}>
        <div className={`${classes.sectionLoadingText} ${subClassName && subClassName}`}>{sectionLoadingName}</div>
        <Loading />
    </div>
);
