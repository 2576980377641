export const iaqTemplate = {
    airLeakage: {
        points: 0,
        section: "3.2",
        text: "The whole house air leakage is ≤ 1.5 ACH@50pa for detached homes and ≤ 2.0 ACH@50pa for attached homes or meets or exceeds one of the other appliable targets specified in Table 3 of the Technical Requirements.",
        tooltip:
            "Reducing air leakage is not only an opportunity for reducing energy consumption, it is also important for improving indoor air quality. Building a sufficiently airtight building envelope reduces the ability for contaminants to infiltrate the building and allows mechanical ventilation to better filter and remove contaminants.",
    },
    slabInsulation: {
        points: 0,
        section: "3.3",
        text: "Slab insulation is installed. Comply with section 3.3.1 of the Technical Requirements. (renovation exemption)",
        tooltip:
            "Slab insulation reduces the downward heat transfer which not only saves energy but also provides a more comfortable, consistent, indoor environment for the occupant. Slab insulation also reduces the risk of exposure to radon (if present), protects against moisture ingress, and the corresponding risk of mold.",
    },
    unventedCombustion: {
        points: 0,
        section: "4.4",
        text: "Unvented fuel-fired combustion appliances are not installed in the home (except for natural gas and propane cooking appliances).",
        tooltip:
            "Unvented combustion appliances can release harmful air contaminants into the home. It is required that all combustion appliances be properly sealed and vented as described in the Technical Requirements. Natural gas and propane cooking appliances, where provision is made to exhaust the products of combustion to the outdoors, are exempt from this requirement. With that said, it is recommended to consider either an induction or electric range to further reduce the potential for combustion spillage into the home. Optional credit is available below for builders/renovators that do not install natural gas or propane cooking appliances. Comply with section 4.4 of the Technical Requirements.",
    },
    hrvInstalled: {
        points: 0,
        section: "4.7",
        text: "An active Heat Recovery Ventilator (HRV) or Energy Recovery Ventilator (ERV) with balanced ventilation is installed.",
        tooltip:
            "HRVs and ERVs provide consistent mechanical ventilation to the home that includes fresh air to the home while exhausting stale air. Including balanced mechanical ventilation is especially important when homes are extremely airtight. Comply with section 4.7 of the Technical Requirements.",
    },
    ductsSealed: {
        points: 0,
        section: "4.8",
        text: "Ducts shall be sufficiently sealed as described in the Technical Requirements. (renovation exemption)",
        tooltip:
            "A well designed and properly sealed duct system can make a home more energy efficient, more comfortable and improve air quality. Sealing ducts can improve indoor air quality by reducing the risk of pollutants entering ducts and circulating through the home. Comply with section 4.8 of the Technical Requirements.",
    },
    airFiltersMerv8: {
        points: 0,
        section: "X.1",
        text: "Air filters with a minimum MERV 8 or higher on all recirculating space conditioning systems.",
        tooltip:
            "Filtering the air helps remove airborne contaminants and keeps the air handling system clean. Ductwork and central blowers are designed to account for the pressure drop across the filter. Filter housings must be airtight to prevent bypass or leakage. Non-ducted systems are exempt from this requirement.",
    },
    lowVocIntPaints: {
        points: 0,
        section: "X.2",
        text: "Newly applied interior paints and painting finishes are low-VOC or no-VOC emitting products.",
        tooltip:
            "At least 90% of the interior surface area covered by site-applied paints and finishes (eg. liquid coatings and varnishes) shall use low-VOC or no-VOC products certified by one of the following 3rd party standards or certifications: ECOLOGO, GREENGUARD, Green Seal, or Indoor Advantage.",
    },
    lowVocIntAdhesives: {
        points: 1,
        section: "1.1",
        text: "Site-applied interior adhesives and sealants are low-VOC or no-VOC emitting products.",
        tooltip:
            "At least 90% of site-applied interior adhesives and sealants are low-VOC or no-VOC products certified by one of the following 3rd-party standards or certifications: ECOLOGO, EU Ecolabel, Green Seal, GREENGUARD, GREENGUARD GOLD.",
    },
    naturalIntCoatings: {
        points: 1,
        section: "1.2",
        text: "Natural interior coatings are used.",
        tooltip: "Minimum 50% of the interior building area uses natural finishes such as milk or lime-based plasters.",
    },
    woodSheeting: {
        points: 1,
        section: "1.3",
        text: "Wood sheeting products used within the building envelope (e.g.: sub-flooring) are certified UF free.",
        tooltip:
            "Use sheathing with no or low added formaldehyde (NAUF/LAF) (e.g.: OSB or plywood sheeting). Products are certified by one of the following 3rd-party standards or certifications, or be pre-finished off site: ECOLOGO, GREENGUARD, GREENGUARD GOLD, ANSI A208.1-1993 Table B, European standard E-1.",
    },
    lowVocInsulation: {
        points: 1,
        section: "1.4",
        text: "Insulation is low-VOC, non-emitting, or exterior to the vapour barrier.",
        tooltip:
            "(a) 100% of all building insulation is exterior to the vapour barrier, or (b) Insulation is acting as a vapour barrier, or is installed within the vapour barrier, and is certified low-VOC by one of the following 3rd-party standards or certifications, or is a non-VOC emitting material: Certified to GREENGUARD, GREENGUARD GOLD, or Indoor Advantage Gold; Glass or mineral fiber insulation with formaldehyde-free binder (e.g.: no UF); Sprayed or dense-packed cellulose insulation; Recycled cotton, denim or wool batts.",
    },
    certifiedCarpeting: {
        points: 1,
        section: "1.5",
        text: "No carpeting is installed, or carpeting is certified Green Label Plus by the Carpet and Rug Institute (CRI).",
        tooltip:
            "Where carpeting is installed, carpet and carpet adhesives must use products labeled with, or otherwise documented as meeting, the Carpet and Rug Institute (CRI) Green Label Plus program criteria. For carpet cushion (e.g., padding), use only products certified to meet the CRI Green Label.",
    },
    lowVocFlooring: {
        points: 1,
        section: "1.6",
        text: "Hard surface flooring is factory finished and low-VOC or non-emitting.",
        tooltip:
            "(a) Interior hard surface flooring materials, adhesives, and underlayments are low-VOC as certified by one of the following 3rd party programs: ECOLGO, FloorScore, GREENGUARD or GREENGUARD Gold, Indoor Advantage Gold, CRI Green Label Plus (adhesives), or (b) Are inherently non-emitting sources of VOCs such as stone, ceramic, glass or concrete.",
    },
    lowVocGypsum: {
        points: 1,
        section: "1.7",
        text: "Gypsum boards are low-VOC or no-VOC emitting products.",
        tooltip:
            "At least 90% of interior gypsum board (drywall) is low-VOC or no-VOC certified by one of the following 3rd-party standards or certifications: ECOLOGO, GREENGUARD, GREENGUARD GOLD.",
    },
    noGasCooking: {
        points: 1,
        section: "1.8",
        text: "No combustion-based cooking appliances installed in the home.",
        tooltip:
            "Gas burning stoves can emit a range of health-damaging pollutants, including particulate matter (PM), nitrogen dioxide (NO2), carbon monoxide (CO), and formaldehyde. Install an electric or induction range instead.",
    },
    noFuelFireplaces: {
        points: 1,
        section: "1.9",
        text: "No natural gas, propane, or wood fireplaces installed in the home.",
        tooltip:
            "Even when sealed and direct vented to the levels outlined in the Technical Requirements, fireplaces in homes allow for the potential intrusion of harmful air contaminants.",
    },
    ventsSealedConstruction: {
        points: 0.5,
        section: "1.10",
        text: "During construction all ducts and vents are sealed. (½ point)",
        tooltip:
            "During construction, debris often enters through the registers to collect in the ducting. Seal all opening to ductwork prior to construction. After all dust-producing construction activities are complete (e.g., drywall, trim carpentry, floor sanding), verify HVAC filters are new and clean.",
    },
    ventsVacuumedConstruction: {
        points: 0.5,
        section: "1.11",
        text: "Following construction all ducts and vents are vacuumed (including fan blades). (½ point)",
        tooltip:
            "Thoroughly vacuum out each opening prior to installing registers, grilles and diffusers. After all dust-producing construction activities are complete (e.g., drywall, trim carpentry, floor sanding), verify HVAC filters are new and clean.",
    },
    circulatedAirFiltration: {
        points: 0,
        section: "2.1",
        text: "Circulated/ventilated air is filtered to minimum:",
        tooltip:
            "a) For fully ducted systems, filters should be at the central air handling system (e.g.: furnace); b) For non-ducted systems, filters should be at the main ventilator (e.g.: HRV / ERV). Filtering the air helps remove airborne contaminants and keeps the air handling system clean. Ductwork and central blower must be designed to account for the pressure drop across the filter. Filter housings must be airtight to prevent bypass or leakage. Homeowner should be made aware of the associated maintenance cost and schedule of utilizing higher performing MERV rated filters.",
    },
    circulatedAirFiltrationMerv10: {
        points: 1,
        section: "2.1",
        text: "MERV 10 (1 point)",
    },
    circulatedAirFiltrationMerv13: {
        points: 2,
        section: "2.1",
        text: "MERV 13 (2 points)",
    },
    circulatedAirFiltrationHepa: {
        points: 3,
        section: "2.1",
        text: "HEPA (3 points).",
    },
    estarAirPurifier: {
        points: 0,
        section: "2.2",
        text: "ENERGY STAR certified air purifier with a minimum Clean Air Delivery Rate of 100 cfm:",
        tooltip:
            "Air purifiers are used to reduce the presence of common indoor air pollutants including a wide variety of particulate matter and gaseous contaminants. Systems must be appropriately sized for the space within the home in which they are intended to service and must be provided by the builder/renovator at the conclusion of construction/renovation. Ensure that the occupant receives information about the system installed (including type, maintenance requirements, and the ideal maintenance schedule) so that they can properly maintain it. Devices that use the reactivity of ozone for cleaning air should not be used.",
    },
    estarAirPurifierLessHalf: {
        points: 1,
        section: "2.2",
        text: "Sized for less than 50% of the home (1 point)",
    },
    estarAirPurifierOverHalf: {
        points: 2,
        section: "2.2",
        text: "Sized for greater than 50% of the home (2 points)",
    },
    supplyAirTested: {
        points: 2,
        section: "3.1",
        text: "Supply air flow rates are 3rd party tested and verified. (Includes both ventilation and space conditioning). (2 points)",
        tooltip:
            "Have the measured space heating and cooling system fan airflow tested to confirm it is either within 10% of the installer's measured fan airflow, or within 15% of design HVAC fan airflow. Have the total supply air-flow rates in each room tested using a flow hood with doors closed, or another acceptable method, per HRAI Residential Commissioning SAR-R8 Manual or equivalent. Supply air-flow rates must be within +/- 20% (or +/- 25 cfm or 11 lps) of calculated values from an appropriate duct design such as described in the HRAI 'Residential Air System Design' or equivalent.",
    },
    estarRangeHood: {
        points: 1,
        section: "3.2",
        text: "ENERGY STAR certified kitchen range hood and < 300 cfm. (½ point)",
        tooltip:
            "Exhausting too much air in a residential kitchen can depressurize a home, cause back-drafting in fireplaces or ventilation ducts, and waste energy. Builder/Renovators should ensure that range hoods are not oversized to avoid depressurizing the home. Builders/Renovators may consider installing a make up air system. Noise level is also a consideration when selecting a range hood. See the list of ENERGY STAR rated kitchen range hoods at www.energystar.gc.ca. Range hoods to be sized to fit over entire cooking surface and installed at recommended height.",
    },
    multiZonedHvac: {
        points: 1,
        section: "3.3",
        text: "Multi Zoning of HVAC systems installed.",
        tooltip:
            "Space conditioning systems that have more than one zone offer more precise control over the energy used to condition each zone within the space. Multi zoned space conditioning systems can improve both energy efficiency and indoor comfort of the home.",
    },
    permanentDehumidification: {
        points: 2,
        section: "4.1",
        text: "Permanent dehumidification installed in HVAC system (ERV is not acceptable). (2 points)",
        tooltip:
            "Maintaining a relative humidity level of 30-50% is important for both occupant comfort and health. Dehumidification helps to reduce the presence of bacteria & mold. ERVs are not accepted for this credit. These permanent dehumidification systems exceed the humidification control abilities of a standard ERV.",
    },
    ervInstalled: {
        points: 1,
        section: "4.2",
        text: "Energy Recovery Ventilator (ERV) is installed.",
        tooltip:
            "Balanced mechanical ventilation is a requirement of the Program in the form of either a Heat Recovery Ventilator (HRV) or an Energy Recovery Ventilator (ERV). ERVs provide the benefit of handling humidity. This optional credit is provided if an ERV is used for whole house mechanical ventilation.",
    },
    estarStandaloneDehumidification: {
        points: 1,
        section: "4.3",
        text: "ENERGY STAR certified stand-alone dehumidification system provided to occupant.",
        tooltip:
            "Maintaining a relative humidity level of 30-50% is important for both occupant comfort and health. Dehumidification helps to reduce the presence of bacteria & mold. Stand-alone systems must be appropriately sized for the location of the home in which they are intended to service and must be provided by the builder/renovator at the conclusion of construction/renovation. See the list of ENERGY STAR dehumidifiers at www.energystar.gc.ca.",
    },
    radonMitigation: {
        points: 0,
        section: "5.1",
        text: "Radon mitigation system installed (see note):",
        tooltip:
            "Where a full passive vertical radon stack or active soil depressurization system is installed following the best practice procedures described in the Canadian General Standards Board (CGSB) 149.11 'Radon control options for new construction in low-rise residential buildings'. These systems also improve the general air quality in basement storeys by helping to reduce entry of moisture and odours. Note: This credit is meant to apply when builders go beyond minimum code requirements. Therefore, this credit may not be available in regions where codes require an active soil depressurization system. In regions where codes require a passive system, one point can be earned by installing an active soil depressurization system.",
    },
    radonMitigationPassive: {
        points: 1,
        section: "5.1",
        text: "Passive system (1 point)",
    },
    radonMitigationActive: {
        points: 2,
        section: "5.1",
        text: "Active system (2 points)",
    },
    iaqMonitoring: {
        points: 1,
        section: "6.1",
        text: "Constant Indoor air quality monitoring that is connected to ventilation controls.",
        tooltip:
            "Smart air quality monitoring controls constantly measures the air for contaminants such as volatile organic compounds (VOC), particulate matter (PM), carbon monoxide (CO), Formaldehyde etc. When conditions are measured as undesirable, the system either notifies the occupant or automatically makes adjustments to the ventilation and/or space conditioning system.",
    },
};
