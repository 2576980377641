import React from "react";
import classes from "../../style.module.scss";
import classNames from "classnames";
import DashboardItem from "..";
import { CHBA_RENO_COMM_ID } from "utils/communities/api";

const Community = ({
    ref,
    communityId,
    name,
    startDelete,
    tags = [],
    setCommunityToMove,
    setCommunityToDuplicate,
    setDuplicateName,
    sharedWithUser = false,
    isSelected,
    isDragging,
    isDropInProcess,
    setFolderItemsToDelete,
    multiSelectedItems,
    details, // { totalModels, region, lastEdited }
    disableActions = false,
    ...props
}) => {
    const isCHBAComm = communityId === CHBA_RENO_COMM_ID;

    return (
        <DashboardItem
            ref={ref}
            className={classNames({
                [classes.selectedItem]: isSelected,
                [classes.itemDragging]: isDragging && isSelected,
                [classes.isDropInProcess]: isDropInProcess
            })}
            itemId={communityId}
            name={name}
            sharedWithUser={isCHBAComm || sharedWithUser}
            itemType={"community"}
            details={details}
            tags={tags}
            actions={isCHBAComm || disableActions ? null : 
                [{
                    label: "Duplicate Community",
                    onClick: () => {
                        setCommunityToDuplicate(communityId);
                        setDuplicateName(`${name} (1)`);
                    },
                },
                {
                    label: "Move Community",
                    onClick: () => {
                        setCommunityToMove(communityId);
                    },
                },
                {
                    label: "Delete Community",
                    onClick: (e) => {
                        startDelete(e, communityId);
                    },
                },
                {
                    label: `Delete ${multiSelectedItems.length} files`,
                    shouldShow: multiSelectedItems.length > 1,
                    onClick: () => setFolderItemsToDelete(multiSelectedItems),
                },
            ]}
            {...props}
        />
    );
}

export default Community;