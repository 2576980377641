import React from 'react';
import classes from '../style.module.scss';
import isEmpty from 'lodash/isEmpty';
import Exclamation from 'assets/images/icons/JSX/Exlamation';

import Table from "components/Table";

export default ({
    exportErrors
}) => {

    let rows = exportErrors;

    if (isEmpty(exportErrors)) {
        return null;
    }

    const tableCaption = isEmpty(exportErrors) ?
        'There are no errors to display' :
        'We detected some issues that may impact HOT2000 compatibility. Please address them before proceeding.'

    return (
        <div className={classes.errorSummary}>
            <h4>{tableCaption}</h4>
            <Table
                className={classes.errorTable}
                headCells={[
                    {
                        id:"field",
                        label:"Field",
                        align:"left",
                        width: "240",
                    },
                    {
                        id:"dataType",
                        label:"Intended Data Type",
                        align:"left",
                        width:"160"
                    },
                    {
                        id:"description",
                        label:"Description",
                        align:"left"
                    }
                ]}
                rows={rows.map(({ fieldId, dataType, message }, ind) => ({
                    field:{
                        value:fieldId,
                        content:<div className={classes.fieldColumn}><Exclamation /> {fieldId}</div>,
                    },
                    dataType: {
                        value:dataType,
                        content:dataType,
                    },
                    description: {
                        value:message,
                        content:message,
                    }
                }))}
            >
        </Table>
    </div>)
}