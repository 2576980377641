import React from 'react';
import classes from './style.module.scss';
import { CSSTransition } from 'react-transition-group';
import Close from 'assets/images/icons/JSX/Close';
import Tooltip from 'components/Tooltip';

export default ({ message='', setMessage, fill='#646F81' }) => (
    <CSSTransition
        in={!!message}
        timeout={500}
        classNames={{
            enter: classes.messageEnter,
            enterActive: classes.messageEnterActive,
            enterDone: classes.messageEnterDone
        }}
    >
        <div className={classes.messageBar} style={{backgroundColor:fill}}>
            <p>{message || <span>&nbsp;</span>}</p>
            <span
                className={classes.dismiss}
                onClick={()=>setMessage({content:''})}
                data-for='messageClose'
                data-tip='Dismiss'
            >
                <Close />
            </span>
            <Tooltip id='messageClose' />
        </div>
    </CSSTransition>
)