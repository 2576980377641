import React from "react";

import styles from "./styles.module.scss";

const Notes = ({ notes, imageId, allImages, setImages }) => {
    const handleChange = (event) => {
        event.preventDefault();

        setImages(allImages.map((img) => (img.id === imageId ? { ...img, notes: event.target.value } : img)));
    };

    return (
        <div className={styles.notesContainer}>
            <span className={styles.notesContainerTitle}>Notes</span>
            <textarea
                className={styles.notesTextarea}
                value={notes}
                onChange={(event) => handleChange(event)}
                placeholder={"Add notes..."}
            />
        </div>
    );
};

export default Notes;
