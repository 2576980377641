// Temporary Community Object Data
export const commDir_DUMMY_DATA = {
    folders: {
        folderID1: {
            name: "Folder 1",
            totalModels: 17,
            region: "ON",
            lastEdited: new Date(),
            parentFolderId: null,
            type: "folder"
        },
        folderID2: {
            name: "Folder 2",
            totalModels: 17,
            region: "ON",
            lastEdited: new Date(),
            parentFolderId: "folderID1",
            type: "folder"
        },
        folderID3: {
            name: "Folder 3",
            totalModels: 17,
            region: "ON",
            lastEdited: new Date(),
            parentFolderId: null,
            type: "folder"
        },
    },
    communities: {
        CHBA_TNZ_RENO_PILOT_ADMIN: {
            name: "CHBA Net Zero Renovations",
            totalModels: 35,
            region: "CA",
            lastEdited: new Date(),
            parentFolderId: null
        },
        commID2: {
            name: "Community 2",
            totalModels: 17,
            region: "AB",
            lastEdited: new Date(),
            parentFolderId: null
        },
        commID3: {
            name: "Community 3",
            totalModels: 17,
            region: "AB",
            lastEdited: new Date(),
            parentFolderId: "folderID1"
        },
    }
}

export const commDir_EMPTY = {
    folders: {},
    communities: {}
}