import { isEmpty } from "lodash";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import FloorMeasurements from "./";

const mapStateToProps = ({ form }, { accessor, change }) => {
    const selector = formValueSelector("model");
    const isRectangular = selector({ form }, `${accessor}.floor.measurements.isRectangular`);
    const expPerimeterValue = selector({ form }, `${accessor}.exposedSurfacePerimeter`);
    const expPerimeterUnits = selector({ form }, `${accessor}.exposedSurfacePerimeter_u`);
    const lengthValue = selector({ form }, `${accessor}.floor.measurements.length`);
    const widthValue = selector({ form }, `${accessor}.floor.measurements.width`);
    const lengthUnits = selector({ form }, `${accessor}.floor.measurements.length_u`);
    const widthUnits = selector({ form }, `${accessor}.floor.measurements.width_u`);
    const perimeterUnits = selector({ form }, `${accessor}.floor.measurements.perimeter_u`);
    const areaUnits = selector({ form }, `${accessor}.floor.measurements.area_u`);
    const modelUnits = selector({ form }, `modelData.uiSettings.primaryUnits`) || {};

    const perimeterValue = selector({ form }, `${accessor}.floor.measurements.perimeter`);
    const areaValue = selector({ form }, `${accessor}.floor.measurements.area`);
    const minPerimeter = 4 * Math.sqrt(areaValue) || 0;
    //Must do this to get decimals on ft to show correct minimum value
    const minPerimeterRounded = Math.ceil(minPerimeter * 100) / 100;
    const minPerimeterString = `${minPerimeterRounded}m (${Math.ceil(3.28084 * minPerimeterRounded * 100) / 100}ft)`;

    let totCrawlspacePerimeter = isRectangular ? 2 * (lengthValue + widthValue) : perimeterValue;

    const basementCount = Object.values(selector({ form }, `modelData.components.basement`) || {}).filter(
        (val) => !isEmpty(val)
    ).length;
    const crawlspaceCount = Object.values(selector({ form }, `modelData.components.crawlspace`) || {}).filter(
        (val) => !isEmpty(val)
    ).length;
    const slabCount = Object.values(selector({ form }, `modelData.components.slab`) || {}).filter(
        (val) => !isEmpty(val)
    ).length;

    let hasMultiFoundation = basementCount + crawlspaceCount + slabCount > 1;
    const { id: houseTypeId } = selector({ form }, `modelData.specifications.buildingSubType`) || {};
    const isDetached = houseTypeId === 0;

    const crawlspaceFloorLengthEquation = selector({ form }, `${accessor}.floor.measurements.length_eqn`);
    const crawlspaceFloorWidthEquation = selector({ form }, `${accessor}.floor.measurements.width_eqn`);
    const crawlspaceFloorPerimeterEquation = selector({ form }, `${accessor}.floor.measurements.perimeter_eqn`);
    const crawlspaceFloorAreaEquation = selector({ form }, `${accessor}.floor.measurements.area_eqn`);
    const crawlspaceExposedPerimeterEquation = selector({ form }, `${accessor}.exposedSurfacePerimeter_eqn`);

    return {
        accessor,
        expPerimeterValue,
        totCrawlspacePerimeter,
        expPerimeterUnits,
        isRectangular,
        modelUnits,
        lengthUnits,
        widthUnits,
        perimeterValue,
        minPerimeter,
        minPerimeterString,
        perimeterUnits,
        areaUnits,
        hasMultiFoundation,
        isDetached,
        change,
        crawlspaceFloorLengthEquation,
        crawlspaceFloorWidthEquation,
        crawlspaceFloorPerimeterEquation,
        crawlspaceFloorAreaEquation,
        crawlspaceExposedPerimeterEquation,
    };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FloorMeasurements);
