import React from 'react';

export default ({fill="#65676A", fillClass=''}) => (
    <svg width="18px" height="16px" viewBox="0 0 18 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="Iconography" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Calendar" transform="translate(0.000000, -1.000000)" stroke={fill} className={fillClass && fillClass}>
                <g id="Group" transform="translate(0.000000, 1.000000)">
                    <rect id="Rectangle-Copy-5" x="0.5" y="1.5" width="17" height="14"></rect>
                    <rect id="Rectangle-Copy-5" x="0.5" y="1.5" width="17" height="3"></rect>
                    <line x1="14.5" y1="0" x2="14.5" y2="3" id="Line-2-Copy-3"></line>
                    <line x1="3.5" y1="0" x2="3.5" y2="3" id="Line-2-Copy-4"></line>
                    <rect id="Rectangle-Copy-3" fill="#FFFFFF" x="2.5" y="6.5" width="1" height="1"></rect>
                    <rect id="Rectangle-Copy-9" fill="#FFFFFF" x="2.5" y="9.5" width="1" height="1"></rect>
                    <rect id="Rectangle-Copy-14" fill="#FFFFFF" x="2.5" y="12.5" width="1" height="1"></rect>
                    <rect id="Rectangle-Copy-4" fill="#FFFFFF" x="5.5" y="6.5" width="1" height="1"></rect>
                    <rect id="Rectangle-Copy-10" fill="#FFFFFF" x="5.5" y="9.5" width="1" height="1"></rect>
                    <rect id="Rectangle-Copy-15" fill="#FFFFFF" x="5.5" y="12.5" width="1" height="1"></rect>
                    <rect id="Rectangle-Copy-6" fill="#FFFFFF" x="8.5" y="6.5" width="1" height="1"></rect>
                    <rect id="Rectangle-Copy-11" fill="#FFFFFF" x="8.5" y="9.5" width="1" height="1"></rect>
                    <rect id="Rectangle-Copy-16" fill="#FFFFFF" x="8.5" y="12.5" width="1" height="1"></rect>
                    <rect id="Rectangle-Copy-7" fill="#FFFFFF" x="11.5" y="6.5" width="1" height="1"></rect>
                    <rect id="Rectangle-Copy-12" fill="#FFFFFF" x="11.5" y="9.5" width="1" height="1"></rect>
                    <rect id="Rectangle-Copy-8" fill="#FFFFFF" x="14.5" y="6.5" width="1" height="1"></rect>
                    <rect id="Rectangle-Copy-13" fill="#FFFFFF" x="14.5" y="9.5" width="1" height="1"></rect>
                </g>
            </g>
        </g>
    </svg>
)