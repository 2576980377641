import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import HourlyAnalysis from "./";

const mapStateToProps = (
    {
        results: { selectedPackage = "base" } = {},
        model: {
            modelId,
            modelDetails: { lastCliRun: { cliId = {}, datetime: dateRun = "" } = {}, simulationRunning = false } = {},
        },
        form,
    },
    { upgradePackageId }
) => {
    const selector = formValueSelector("model");
    const { id: weatherProvTerrId = 0 } = selector({ form }, "modelData.location.weather.region") || {};

    const {
        default: { runId: defaultId = "" } = {},
        [upgradePackageId]: { runId: upgradeRunId = "" } = {},
        ...rest
    } = cliId;

    const id = upgradeRunId || defaultId || rest[Object.keys(rest)[0]]?.runId;

    return {
        selectedPackage,
        weatherProvTerrId,
        modelId,
        cliId: id,
    };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(HourlyAnalysis);
