import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import isEqual from "lodash/isEqual";

import { actions as modelActions } from "features/Model/_ducks";
import { fetchDrawingData } from "../DrawingNew/_ducks/thunk";
import { setSaveTimeout } from "../_ducks/actions";
import { toggleImageGallery } from "../../../store/imageGallery/actions";

import TopButtons from "./";

const { toggleContentOpen, changeDataentryMode } = modelActions;

const mapStateToProps = ({
    user: { uid } = {},
    model: {
        modelId,
        modelDetails = {},
        checklist: { list = {} } = {},
        contentOpen = false,
        dataEntryMode = "",
        modelDataToCompare = {},
        modelDetailsToCompare = {},
        modelTakeoffToCompare = {},
        modelTakeoff,
        linkedBaselineToCompare = {},
        linkedBaseline,
    },
    upgrades: { packages: upgradePackages = {}, initPackages: initUpgradePackages = {} } = {},
    chbaChecklist: { items: chbaChecklistItems = {}, itemsToCompare: chbaChecklistItemsToCompare = {} } = {},
    form,
}) => {
    const { owner: { uid: ownerId = uid } = {}, writePermission = [] } = modelDetails;

    const selector = formValueSelector("model");
    const components = selector({ form }, `modelData.components`) || {};

    const formModelData = selector({ form }, "modelData");
    const formModelDetails = selector({ form }, "modelDetails");

    const hasChecklist = Object.keys(list).length > 0;
    const hasUnchecked = Object.keys(list).some((id) => !list[id]?.addressed && !list[id]?.dismissed);

    return {
        modelId,
        dataEntryMode,
        components,
        hasChecklist,
        hasUnchecked,
        contentOpen,
        readOnly: ownerId !== uid && !writePermission.includes(uid),
        // implement linkedBaseline data 'to compare' here
        isDataChanged:
            !isEqual(formModelData, modelDataToCompare) ||
            !isEqual({ ...modelDetailsToCompare, lastEdited: {} }, { ...formModelDetails, lastEdited: {} }) ||
            !isEqual(upgradePackages, initUpgradePackages) ||
            !isEqual(modelTakeoffToCompare, modelTakeoff) ||
            !isEqual(linkedBaselineToCompare, linkedBaseline) ||
            !isEqual(chbaChecklistItems, chbaChecklistItemsToCompare),
    };
};

const mapDispatchToProps = (dispatch) => ({
    fetchDrawingData: (modelId) => dispatch(fetchDrawingData(modelId)),
    toggleContentOpen: () => dispatch(toggleContentOpen()),
    changeDataentryMode: (value) => dispatch(changeDataentryMode(value)),
    setSaveTimeout: (timeoutFunc) => dispatch(setSaveTimeout(timeoutFunc)),
    toggleImageGallery: () => dispatch(toggleImageGallery()),
});

const mergeProps = (state, dispatch, own) => {
    const { saveDrawingData, changeDataentryMode } = dispatch;

    const { components: modelComponents, modelId } = state;

    return {
        ...state,
        ...dispatch,
        ...own,
        saveDrawingData: () => saveDrawingData(modelId),
        changeDataentryMode,
    };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(TopButtons);
