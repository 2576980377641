import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import Enclosure from "./";
import { actions } from "features/Model/Enclosure/_ducks";
const { updateDrawingChanges } = actions;

const mapStateToProps = ({ form, model }) => {
    const selector = formValueSelector("model");
    const { id: storeysId } = selector({ form }, "modelData.specifications.numStoreys") || {};
    const components = selector({ form }, "modelData.components") || {};
    const formModelData = selector({ form }, "modelData") || {};
    const { dataEntryMode } = model;

    return {
        storeysId,
        components,
        formModelData,
        dataEntryMode,
        model,
    };
};

const mapDispatchToProps = (dispatch) => ({
    updateDrawingChanges: (componentId, updates) => {
        return dispatch(updateDrawingChanges({ componentId, updates }));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(Enclosure);
