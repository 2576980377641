import { DashboardAPI } from 'utils/api';

export const getStandardRVal = async (data) => {
    const { codeString = '', codeType = '' } = data;
    return DashboardAPI({
        method: 'POST',
        url: '/getRValue',
        data: {
            codeString: codeString ? codeString : '1200000000',
            codeType: codeType ? codeType : 'Wall',
        },
    }).catch((err) => console.log(err));
};
