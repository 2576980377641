import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";

import Button from "components/Button";
import Loading from "components/Loading";

import {
    uploadWrapper,
    upload,
    active,
    filesUploaded,
    processing,
    initialText,
    error,
    uploadDiff,
    filePath,
    loadingText,
    loading,
    progressBar,
    progressLoading,
    generateButton,
} from "./style.module.scss";

import Upload from "assets/images/icons/Upload.svg";

export default ({
    modelId,
    uid,
    generateImages,
    isUploading,
    isGenerating,
    progress,
    success,
    uploadSuccess,
    error,
}) => {
    const { acceptedFiles, fileRejections, getRootProps, getInputProps, isDragActive, open } = useDropzone({
        multiple: false,
        maxSize: 1073741824,
        accept: "application/pdf",
        noClick: true,
    });

    const generateDrawing = async (event) => {
        event.stopPropagation();
        await generateImages({ modelId, uid, file: acceptedFiles[0] });
    };

    return (
        <div className={uploadWrapper}>
            <div
                className={`${upload} ${isDragActive ? active : ""} ${acceptedFiles.length > 0 ? filesUploaded : ""} ${
                    (isUploading || isGenerating) && processing
                }`}
                {...getRootProps()}
            >
                <input {...getInputProps()} />
                {!isUploading && !isGenerating && <img src={Upload} alt="Upload icon" />}
                {acceptedFiles.length === 0 && (
                    <h3>{isDragActive ? "Drop your file here..." : "Drag and drop your drawing file"}</h3>
                )}
                {fileRejections.length > 0 && (
                    <>
                        {fileRejections.map(({ errors }) =>
                            errors.map((e) => (
                                <p className={error} key={e.code}>
                                    {e.message}
                                </p>
                            ))
                        )}
                        <Button type="slate" onClick={open}>
                            Upload another file
                        </Button>
                    </>
                )}
                {isUploading && (
                    <>
                        <div className={progressBar}>
                            <span style={{ width: `${progress}%` }} className={progressLoading}></span>
                        </div>
                        <p className={loadingText}>Uploading File...</p>
                    </>
                )}
                {isGenerating && (
                    <>
                        <div className={loading}>
                            <Loading />
                        </div>
                        <p className={loadingText}>Generating Drawing...</p>
                    </>
                )}
                {acceptedFiles.length > 0 && !isUploading && !isGenerating && (
                    <>
                        {acceptedFiles.map((file, index) => (
                            <p className={filePath} key={index}>
                                {file.path}
                            </p>
                        ))}
                        <Button
                            type="blue"
                            onClick={generateDrawing}
                            disabled={isUploading || isGenerating}
                            className={generateButton}
                        >
                            Generate Drawing
                        </Button>
                        <p className={uploadDiff} onClick={open}>
                            Choose a different file
                        </p>
                    </>
                )}
                {acceptedFiles.length === 0 && fileRejections.length === 0 && (
                    <>
                        <p className={initialText}>
                            Upload any drawing PDF to get started. Maxiumum file size is 1 GB.
                        </p>
                        <Button type="slate" onClick={open}>
                            Browse Files
                        </Button>
                    </>
                )}
            </div>
        </div>
    );
};
