import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import {
    selectModelComponents,
    selectHeatingCooling,
    selectDomesticHotWater,
    selectGeneration,
    selectProgram,
    selectAirtightness,
    selectSpecifications,
} from "features/Model/_ducks/selectors";
import { selectAllTags } from "store/imageGallery/selectors";

import TagSection from "./TagsSection";

import styles from "./styles.module.scss";

const RequiredTags = ({
    selectedTag,
    setSelectedTag,
    allImages,
    modelComponents,
    modelHeatingCooling,
    modelDomesticHotWater,
    modelGeneration,
    modelProgram,
    modelAirtightness,
    modelSpecifications,
    requiredTags,
}) => {
    // -------------------Checking model components-------------------
    const { basement = {}, ceiling = {}, crawlspace = {}, expFloor = {} } = modelComponents || {};

    const basementKeys = Object.keys(basement);
    const ceilingKeys = Object.keys(ceiling);
    const crawlspaceKeys = Object.keys(crawlspace);
    const expFloorKeys = Object.keys(expFloor);

    if (basementKeys.length > 0) {
        requiredTags[requiredTags.findIndex(({ id }) => id === "basementWall")].isInModel = true;

        basementKeys.forEach((key) => {
            const { subcomponents: { floorHeader = {} } = {} } = basement[key] || {};

            const floorHeaderKeys = Object.keys(floorHeader);

            if (floorHeaderKeys.length > 0) {
                requiredTags[requiredTags.findIndex(({ id }) => id === "header")].isInModel = true;
            }
        });
    } else {
        requiredTags[requiredTags.findIndex(({ id }) => id === "basementWall")].isInModel = false;
        requiredTags[requiredTags.findIndex(({ id }) => id === "header")].isInModel = false;
    }

    if (crawlspaceKeys.length > 0) {
        requiredTags[requiredTags.findIndex(({ id }) => id === "crawlSpace")].isInModel = true;

        crawlspaceKeys.forEach((key) => {
            const { subcomponents: { floorHeader = {} } = {} } = crawlspace[key] || {};

            const floorHeaderKeys = Object.keys(floorHeader);

            if (floorHeaderKeys.length > 0) {
                requiredTags[requiredTags.findIndex(({ id }) => id === "header")].isInModel = true;
            }
        });
    } else {
        requiredTags[requiredTags.findIndex(({ id }) => id === "crawlSpace")].isInModel = false;
        requiredTags[requiredTags.findIndex(({ id }) => id === "header")].isInModel = false;
    }

    if (expFloorKeys.length > 0) {
        requiredTags[requiredTags.findIndex(({ id }) => id === "exposedFloor")].isInModel = true;
    } else {
        requiredTags[requiredTags.findIndex(({ id }) => id === "exposedFloor")].isInModel = false;
    }

    if (ceilingKeys.length > 0) {
        const isAtticSet = requiredTags[requiredTags.findIndex(({ id }) => id === "attic")].isInModel;
        const isCathedralAndFlatSet =
            requiredTags[requiredTags.findIndex(({ id }) => id === "cathedral&FlatCeilings")].isInModel;

        ceilingKeys.forEach((key) => {
            const {
                constructionType: { id },
            } = ceiling[key];

            if ((id === 0 || id === 1) && isAtticSet) return;
            if ((id === 2 || id === 3) && isCathedralAndFlatSet) return;

            if (id === 0 || id === 1) {
                requiredTags[requiredTags.findIndex(({ id }) => id === "attic")].isInModel = true;
            }

            if (id === 2 || id === 3) {
                requiredTags[requiredTags.findIndex(({ id }) => id === "cathedral&FlatCeilings")].isInModel = true;
            }
        });
    } else {
        requiredTags[requiredTags.findIndex(({ id }) => id === "attic")].isInModel = false;
        requiredTags[requiredTags.findIndex(({ id }) => id === "cathedral&FlatCeilings")].isInModel = false;
    }

    // -------------------Checking heating cooling -------------------
    const { cooling: { system = {} } = {} } = modelHeatingCooling || {};

    const systemKeys = Object.keys(system);

    if (systemKeys.length > 0) {
        requiredTags[requiredTags.findIndex(({ id }) => id === "ashpAndCentralAc")].isInModel = true;
    } else {
        requiredTags[requiredTags.findIndex(({ id }) => id === "ashpAndCentralAc")].isInModel = false;
    }

    // -------------------Checking Model Domestic Hot Water -------------------
    const { primarySystem = {}, secondarySystem = {} } = modelDomesticHotWater || {};

    if (primarySystem.hasDrainWaterHeatRecovery || secondarySystem.hasDrainWaterHeatRecovery) {
        requiredTags[requiredTags.findIndex(({ id }) => id === "dwhr")].isInModel = true;
    } else {
        requiredTags[requiredTags.findIndex(({ id }) => id === "dwhr")].isInModel = true;
    }

    if (primarySystem?.energySource?.id === 8 || secondarySystem?.energySource?.id === 8) {
        requiredTags[requiredTags.findIndex(({ id }) => id === "solarDHW")].isInModel = true;
    } else {
        requiredTags[requiredTags.findIndex(({ id }) => id === "solarDHW")].isInModel = true;
    }

    // -------------------Checking Model Generation -------------------
    const { pvSystems = {}, windEnergyContribution = 0 } = modelGeneration || {};

    const pvSystemKeys = Object.keys(pvSystems);

    if (pvSystemKeys.length > 0) {
        requiredTags[requiredTags.findIndex(({ id }) => id === "pvSolarPanel")].isInModel = true;
    } else {
        requiredTags[requiredTags.findIndex(({ id }) => id === "pvSolarPanel")].isInModel = false;
    }

    if (windEnergyContribution > 0) {
        requiredTags[requiredTags.findIndex(({ id }) => id === "windTurbines")].isInModel = true;
    } else {
        requiredTags[requiredTags.findIndex(({ id }) => id === "windTurbines")].isInModel = false;
    }

    // -------------------Checking Model Program -------------------
    const { options: { main: { atypicalElectricalLoads = false } = {} } = {} } = modelProgram || {};

    if (atypicalElectricalLoads) {
        requiredTags[requiredTags.findIndex(({ id }) => id === "atypicalEnergyLoads")].isInModel = true;
    } else {
        requiredTags[requiredTags.findIndex(({ id }) => id === "atypicalEnergyLoads")].isInModel = false;
    }

    // -------------------Checking Model Airtightness -------------------
    const { testType: { id: testTypeId } = {} } = ({} = modelAirtightness || {});

    if (testTypeId === 0) {
        requiredTags[requiredTags.findIndex(({ id }) => id === "blowerDoor")].isInModel = true;
    } else {
        requiredTags[requiredTags.findIndex(({ id }) => id === "blowerDoor")].isInModel = false;
    }

    // -------------------Checking Model Specifications -------------------
    const { numStoreys: { id: numStoreysId } = {} } = modelSpecifications || {};

    if (numStoreysId === 1 || numStoreysId === 3) {
        requiredTags[requiredTags.findIndex(({ id }) => id === "halfStorey")].isInModel = true;
    } else {
        requiredTags[requiredTags.findIndex(({ id }) => id === "halfStorey")].isInModel = false;
    }

    return (
        <>
            <div className={styles.requiredTagsContainer}>
                <TagSection
                    selectedTag={selectedTag}
                    setSelectedTag={setSelectedTag}
                    requiredTags={requiredTags}
                    includedTags
                    tagsSectionTitle={"REQUIRED TAGS"}
                    allImages={allImages}
                />
                <div className={styles.divider} />
                <TagSection
                    selectedTag={selectedTag}
                    setSelectedTag={setSelectedTag}
                    requiredTags={requiredTags}
                    includedTags={false}
                    customTags={false}
                    tagsSectionTitle={"NOT IN CURRENT MODEL"}
                    allImages={allImages}
                />
                <div className={styles.divider} />
                <TagSection
                    selectedTag={selectedTag}
                    setSelectedTag={setSelectedTag}
                    requiredTags={requiredTags}
                    includedTags={false}
                    customTags={true}
                    tagsSectionTitle={"CUSTOM TAGS"}
                    allImages={allImages}
                />
            </div>
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    modelComponents: selectModelComponents,
    modelHeatingCooling: selectHeatingCooling,
    modelDomesticHotWater: selectDomesticHotWater,
    modelGeneration: selectGeneration,
    modelProgram: selectProgram,
    modelAirtightness: selectAirtightness,
    modelSpecifications: selectSpecifications,
    requiredTags: selectAllTags,
});

export default connect(mapStateToProps, null)(RequiredTags);
