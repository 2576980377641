import React from "react";
import classes from "./style.module.scss";
import { Drawer } from "@material-ui/core";
import CloseIcon from "assets/images/icons/JSX/Close";
import classNames from "classnames";
import Button from "components/Button";
import ChbaChecklistDrawerButton from "components/ChbaChecklist/ChbaChecklistDrawerButton";

const getClasses = ({ rootClass = "", paperClass = "" }) => ({
    paper: `${classes.drawerPaper} ${paperClass}`,
});

const ChbaChecklistDrawerComponent = ({
    children,
    preTitle,
    preIcon: PreIcon,
    title = "",
    subtitle = "",
    classes: drawerClasses = {},
    headerClassName,
    bodyClass = "",
    close,
    topBarColor,
    introColor,
    whiteHeaderText = false,
    icon: Icon,
    currentPhase,
    ...props
}) => {
    return (
        <Drawer anchor="right" classes={getClasses(drawerClasses)} onClose={close} {...props}>
            <div style={{ overflowY: "scroll" }}>
                <div className={classes.drawerHeader}>
                    {close && (
                        <div
                            className={classNames(classes.close, {
                                [classes.hasPreTitle]: preTitle,
                                [classes.whiteHeaderText]: whiteHeaderText,
                            })}
                            onClick={close}
                        >
                            <CloseIcon />
                        </div>
                    )}
                    {preTitle && (
                        <div className={classes.preTitle} style={{ backgroundColor: topBarColor || "#0066B1" }}>
                            {PreIcon && <PreIcon />}
                            <p>{preTitle}</p>
                        </div>
                    )}
                    {title && (
                        <div
                            className={classNames(classes.intro, {
                                [headerClassName]: headerClassName,
                                [classes.whiteHeaderText]: whiteHeaderText,
                            })}
                            style={{ backgroundColor: introColor || "#f6f8f9" }}
                        >
                            {Icon && <Icon />}
                            <h2>{title}</h2>
                            {subtitle && <p className={classes.subtitle}>{subtitle}</p>}
                        </div>
                    )}
                </div>
                <ChbaChecklistDrawerButton onClick={close} />
                <div className={`${classes.drawerBody} ${bodyClass && bodyClass}`}>{children}</div>
            </div>
        </Drawer>
    );
};

export default ChbaChecklistDrawerComponent;
