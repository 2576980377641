
import React, { useCallback, useEffect } from "react";
import classes from "./style.module.scss";
import { connect } from "react-redux";
import { matchPath } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { actions as communityActions } from "features/Community/_ducks";
import { selectUserUid } from "store/users/selectors";
import { selectCommunityId, selectCommunityLoading } from "./_ducks/selectors";
import Loading from "components/Loading";
import CommTabs from "./CommTabs";
import { CommunityRestricted } from "features/Navigation/PageNotFound";

const { fetchCommunityData, communityLoading } = communityActions;

const Community = ({
    loading,
    communityId,
    history,
    fetchCommunityData,
    uid,
    // CHBA_NZ_ADMIN = false, //?
    // VOLTA_DEV = false //?
}) => {
    const pathCallback = useCallback(() => { return history.location.pathname }, [history.location]);

    useEffect(() => {
        if (!communityId) {
            fetchCommunityData();
        }
    }, [communityId, fetchCommunityData]);

    if (loading) {
        return <Loading className={classes.spinner} />;
    }

    if (!loading && !communityId) {
        return <CommunityRestricted />
    }

    return (
        <div>
            <CommTabs 
                uid={uid}
                communityId={communityId}
                history={history}
                pathCallback={pathCallback}
            />
        </div>
    );
}

const mapStateToProps = createStructuredSelector({
    uid: selectUserUid,
    communityId: selectCommunityId,
    loading: selectCommunityLoading,
});

const mapDispatchToProps = (
    dispatch,
    {
        history: {
            location: { pathname },
        },
    }
) => ({
    fetchCommunityData: async () => {
        const { params: { communityId } = {} } =
            matchPath(pathname, {
                path: "/communities/:communityId",
            }) || {};

        if (communityId) {
            await dispatch(communityLoading());
            return dispatch(fetchCommunityData(communityId));
        }
    },
    // clearCommunityData: () => dispatch(clearCommunityData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Community);