import { createSelector } from "reselect";
import { initialState } from "./reducers";

export const selectResults = ({ results = initialState }) => results;

export const selectPackageResults = createSelector(
    [selectResults],
    ({ packageResults = {} }) => packageResults
);

export const selectLinkedBaselineResults = createSelector(
    [selectResults],
    ({ linkedBaselineResults = {} }) => linkedBaselineResults
);

export const selectResultsLoading = createSelector(
    [selectResults],
    ({ loading = {} }) => loading
);

export const selectResultsError = createSelector(
    [selectResults],
    ({ error = {} }) => error
);
