import React, { useCallback, useEffect, useState } from "react";
import { MODEL_COLL } from "_firebase";
import globalStyles from "components/globalStyles.module.scss";
import classes from "../style.module.scss";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import InputFile from "components/Input/FileUpload";
import Checkbox from "components/Input/Checkbox";
import { isEmpty, isEqual } from "lodash";

const NewBuildBatchEcms = ({ ecmInputMap, setEcmInputMap, disabledFields }) => {
    return (
        <div className={classes.ecmListContainer}>
            <InputRow gridTemplate="1fr 1fr">
                <div className={classes.checkboxContainer}>
                    <h4 style={{ marginBottom: "0.5rem" }}>Ceilings with Attic</h4>
                    <Checkbox
                        name="atticR40"
                        label="R-40 Fibreglass Blown-in"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-AtticCeilings"]: {
                                        ...prevState["Opt-AtticCeilings"],
                                        ["CeilR40-Opt-AtticCeilings"]:
                                            !prevState["Opt-AtticCeilings"]["CeilR40-Opt-AtticCeilings"],
                                    },
                                })),
                            value: ecmInputMap["Opt-AtticCeilings"]["CeilR40-Opt-AtticCeilings"],
                        }}
                        disabled={disabledFields["CeilR40-Opt-AtticCeilings"]}
                    />
                    <Checkbox
                        name="atticR50"
                        label="R-50 Fibreglass Blown-in"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-AtticCeilings"]: {
                                        ...prevState["Opt-AtticCeilings"],
                                        ["CeilR50-Opt-AtticCeilings"]:
                                            !prevState["Opt-AtticCeilings"]["CeilR50-Opt-AtticCeilings"],
                                    },
                                })),
                            value: ecmInputMap["Opt-AtticCeilings"]["CeilR50-Opt-AtticCeilings"],
                        }}
                        disabled={disabledFields["CeilR50-Opt-AtticCeilings"]}
                    />
                    <Checkbox
                        name="atticR60"
                        label="R-60 Fibreglass Blown-in"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-AtticCeilings"]: {
                                        ...prevState["Opt-AtticCeilings"],
                                        ["CeilR60-Opt-AtticCeilings"]:
                                            !prevState["Opt-AtticCeilings"]["CeilR60-Opt-AtticCeilings"],
                                    },
                                })),
                            value: ecmInputMap["Opt-AtticCeilings"]["CeilR60-Opt-AtticCeilings"],
                        }}
                        disabled={disabledFields["CeilR60-Opt-AtticCeilings"]}
                    />
                    <Checkbox
                        name="atticR70"
                        label="R-70 Fibreglass Blown-in"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-AtticCeilings"]: {
                                        ...prevState["Opt-AtticCeilings"],
                                        ["CeilR70-Opt-AtticCeilings"]:
                                            !prevState["Opt-AtticCeilings"]["CeilR70-Opt-AtticCeilings"],
                                    },
                                })),
                            value: ecmInputMap["Opt-AtticCeilings"]["CeilR70-Opt-AtticCeilings"],
                        }}
                        disabled={disabledFields["CeilR70-Opt-AtticCeilings"]}
                    />
                    <Checkbox
                        name="atticR80"
                        label="R-80 Fibreglass Blown-in"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-AtticCeilings"]: {
                                        ...prevState["Opt-AtticCeilings"],
                                        ["CeilR80-Opt-AtticCeilings"]:
                                            !prevState["Opt-AtticCeilings"]["CeilR80-Opt-AtticCeilings"],
                                    },
                                })),
                            value: ecmInputMap["Opt-AtticCeilings"]["CeilR80-Opt-AtticCeilings"],
                        }}
                        disabled={disabledFields["CeilR80-Opt-AtticCeilings"]}
                    />
                </div>
                <div className={classes.checkboxContainer}>
                    <h5 style={{ marginBottom: "0.5rem" }}>Airtightness</h5>
                    <Checkbox
                        name="ach350"
                        label="3.50 ACH"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-ACH"]: {
                                        ...prevState["Opt-ACH"],
                                        ["New-Const-air_seal_to_3.50_ach"]:
                                            !prevState["Opt-ACH"]["New-Const-air_seal_to_3.50_ach"],
                                    },
                                })),
                            value: ecmInputMap["Opt-ACH"]["New-Const-air_seal_to_3.50_ach"],
                        }}
                        disabled={disabledFields["New-Const-air_seal_to_3.50_ach"]}
                    />
                    <Checkbox
                        name="ach250"
                        label="2.50 ACH"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-ACH"]: {
                                        ...prevState["Opt-ACH"],
                                        ["New-Const-air_seal_to_2.50_ach"]:
                                            !prevState["Opt-ACH"]["New-Const-air_seal_to_2.50_ach"],
                                    },
                                })),
                            value: ecmInputMap["Opt-ACH"]["New-Const-air_seal_to_2.50_ach"],
                        }}
                        disabled={disabledFields["New-Const-air_seal_to_2.50_ach"]}
                    />
                    <Checkbox
                        name="ach150"
                        label="1.50 ACH"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-ACH"]: {
                                        ...prevState["Opt-ACH"],
                                        ["New-Const-air_seal_to_1.50_ach"]:
                                            !prevState["Opt-ACH"]["New-Const-air_seal_to_1.50_ach"],
                                    },
                                })),
                            value: ecmInputMap["Opt-ACH"]["New-Const-air_seal_to_1.50_ach"],
                        }}
                        disabled={disabledFields["New-Const-air_seal_to_1.50_ach"]}
                    />
                    <Checkbox
                        name="ach100"
                        label="1.00 ACH"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-ACH"]: {
                                        ...prevState["Opt-ACH"],
                                        ["New-Const-air_seal_to_1.00_ach"]:
                                            !prevState["Opt-ACH"]["New-Const-air_seal_to_1.00_ach"],
                                    },
                                })),
                            value: ecmInputMap["Opt-ACH"]["New-Const-air_seal_to_1.00_ach"],
                        }}
                        disabled={disabledFields["New-Const-air_seal_to_1.00_ach"]}
                    />
                    <Checkbox
                        name="ach060"
                        label="0.60 ACH"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-ACH"]: {
                                        ...prevState["Opt-ACH"],
                                        ["New-Const-air_seal_to_0.60_ach"]:
                                            !prevState["Opt-ACH"]["New-Const-air_seal_to_0.60_ach"],
                                    },
                                })),
                            value: ecmInputMap["Opt-ACH"]["New-Const-air_seal_to_0.60_ach"],
                        }}
                        disabled={disabledFields["New-Const-air_seal_to_0.60_ach"]}
                    />
                </div>
            </InputRow>
            <InputRow gridTemplate="1fr 1fr">
                <div className={classes.checkboxContainer}>
                    <h4 style={{ marginBottom: "0.5rem" }}>Above Grade Walls</h4>
                    <Checkbox
                        name="wallR16"
                        label="R-16 Effective, Fiberglass Batt, Standard Wall"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-AboveGradeWall"]: {
                                        ...prevState["Opt-AboveGradeWall"],
                                        ["NC_R-16(eff)_2x6-16inOC_R19-batt_poly_vb"]:
                                            !prevState["Opt-AboveGradeWall"][
                                                "NC_R-16(eff)_2x6-16inOC_R19-batt_poly_vb"
                                            ],
                                    },
                                })),
                            value: ecmInputMap["Opt-AboveGradeWall"]["NC_R-16(eff)_2x6-16inOC_R19-batt_poly_vb"],
                        }}
                        disabled={disabledFields["NC_R-16(eff)_2x6-16inOC_R19-batt_poly_vb"]}
                    />
                    <Checkbox
                        name="wallR22"
                        label="R-22 Effective, Fiberglass Batt, Exterior Insulation"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-AboveGradeWall"]: {
                                        ...prevState["Opt-AboveGradeWall"],
                                        ["NC_R-22(eff)_2x6-16inOC_R22-batt+1inXPS_poly_vb"]:
                                            !prevState["Opt-AboveGradeWall"][
                                                "NC_R-22(eff)_2x6-16inOC_R22-batt+1inXPS_poly_vb"
                                            ],
                                    },
                                })),
                            value: ecmInputMap["Opt-AboveGradeWall"]["NC_R-22(eff)_2x6-16inOC_R22-batt+1inXPS_poly_vb"],
                        }}
                        disabled={disabledFields["NC_R-22(eff)_2x6-16inOC_R22-batt+1inXPS_poly_vb"]}
                    />
                    <Checkbox
                        name="wallR26"
                        label="R-26 Effective, Fiberglass Batt, Exterior Insulation"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-AboveGradeWall"]: {
                                        ...prevState["Opt-AboveGradeWall"],
                                        ["NC_R-26(eff)_2x6-16inOC_R19-batt+2inXPS_poly_vb"]:
                                            !prevState["Opt-AboveGradeWall"][
                                                "NC_R-26(eff)_2x6-16inOC_R19-batt+2inXPS_poly_vb"
                                            ],
                                    },
                                })),
                            value: ecmInputMap["Opt-AboveGradeWall"]["NC_R-26(eff)_2x6-16inOC_R19-batt+2inXPS_poly_vb"],
                        }}
                        disabled={disabledFields["NC_R-26(eff)_2x6-16inOC_R19-batt+2inXPS_poly_vb"]}
                    />
                    <Checkbox
                        name="wallR30"
                        label="R-30 Effective, Fiberglass Batt, Exterior Insulation"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-AboveGradeWall"]: {
                                        ...prevState["Opt-AboveGradeWall"],
                                        ["NC_R-30(eff)_2x6-16inOC_R24-batt+3inMineralWool_poly_vb"]:
                                            !prevState["Opt-AboveGradeWall"][
                                                "NC_R-30(eff)_2x6-16inOC_R24-batt+3inMineralWool_poly_vb"
                                            ],
                                    },
                                })),
                            value: ecmInputMap["Opt-AboveGradeWall"][
                                "NC_R-30(eff)_2x6-16inOC_R24-batt+3inMineralWool_poly_vb"
                            ],
                        }}
                        disabled={disabledFields["NC_R-30(eff)_2x6-16inOC_R24-batt+3inMineralWool_poly_vb"]}
                    />
                    <Checkbox
                        name="wallR40"
                        label="R-40 Effective, Fiberglass Batt, Exterior Insulation"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-AboveGradeWall"]: {
                                        ...prevState["Opt-AboveGradeWall"],
                                        ["NC_R-40(eff)_2x6-16inOC_R24-batt+4.5inXPS_poly_vb"]:
                                            !prevState["Opt-AboveGradeWall"][
                                                "NC_R-40(eff)_2x6-16inOC_R24-batt+4.5inXPS_poly_vb"
                                            ],
                                    },
                                })),
                            value: ecmInputMap["Opt-AboveGradeWall"][
                                "NC_R-40(eff)_2x6-16inOC_R24-batt+4.5inXPS_poly_vb"
                            ],
                        }}
                        disabled={disabledFields["NC_R-40(eff)_2x6-16inOC_R24-batt+4.5inXPS_poly_vb"]}
                    />
                </div>
                <div className={classes.checkboxContainer}>
                    <h5 style={{ marginBottom: "0.5rem" }}>Windows</h5>
                    <Checkbox
                        name="windowDG_LG"
                        label="Double Glazed, U=1.65, SHGC= 0.19, Vinyl Frame"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-Windows"]: {
                                        ...prevState["Opt-Windows"],
                                        ["NC-2g-LG-u1.65"]: !prevState["Opt-Windows"]["NC-2g-LG-u1.65"],
                                    },
                                })),
                            value: ecmInputMap["Opt-Windows"]["NC-2g-LG-u1.65"],
                        }}
                        disabled={disabledFields["NC-2g-LG-u1.65"]}
                    />
                    <Checkbox
                        name="windowDG_MG"
                        label="Double Glazed, U=1.65, SHGC= 0.26, Vinyl Frame"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-Windows"]: {
                                        ...prevState["Opt-Windows"],
                                        ["NC-2g-MG-u1.65"]: !prevState["Opt-Windows"]["NC-2g-MG-u1.65"],
                                    },
                                })),
                            value: ecmInputMap["Opt-Windows"]["NC-2g-MG-u1.65"],
                        }}
                        disabled={disabledFields["NC-2g-MG-u1.65"]}
                    />
                    <Checkbox
                        name="windowTG_LG"
                        label="Triple Glazed, U=1.08, SHGC= 0.17, Vinyl Frame"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-Windows"]: {
                                        ...prevState["Opt-Windows"],
                                        ["NC-3g-LG-u1.08"]: !prevState["Opt-Windows"]["NC-3g-LG-u1.08"],
                                    },
                                })),
                            value: ecmInputMap["Opt-Windows"]["NC-3g-LG-u1.08"],
                        }}
                        disabled={disabledFields["NC-3g-LG-u1.08"]}
                    />
                    <Checkbox
                        name="windowTG_MG"
                        label="Triple Glazed, U=1.08, SHGC= 0.26, Vinyl Frame"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-Windows"]: {
                                        ...prevState["Opt-Windows"],
                                        ["NC-3g-MG-u1.08"]: !prevState["Opt-Windows"]["NC-3g-MG-u1.08"],
                                    },
                                })),
                            value: ecmInputMap["Opt-Windows"]["NC-3g-MG-u1.08"],
                        }}
                        disabled={disabledFields["NC-3g-MG-u1.08"]}
                    />
                </div>
            </InputRow>
            <InputRow gridTemplate="1fr 1fr">
                <div className={classes.checkboxContainer}>
                    <h5 style={{ marginBottom: "0.5rem" }}>Exterior Foundation Wall</h5>
                    <Checkbox
                        name="bsmtWallExtUninsulated"
                        label="Interior Only (R-15 Effective, Fiberglass Batt)"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-FoundationWallExtIns"]: {
                                        ...prevState["Opt-FoundationWallExtIns"],
                                        ["uninsulated-Opt-FoundationWallExtIns"]:
                                            !prevState["Opt-FoundationWallExtIns"][
                                                "uninsulated-Opt-FoundationWallExtIns"
                                            ],
                                    },
                                })),
                            value: ecmInputMap["Opt-FoundationWallExtIns"]["uninsulated-Opt-FoundationWallExtIns"],
                        }}
                        disabled={disabledFields["uninsulated-Opt-FoundationWallExtIns"]}
                    />
                    <Checkbox
                        name="bsmtWallExtR22"
                        label="R-22 Effective, Fiberglass Batt, Exterior Insulation"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-FoundationWallExtIns"]: {
                                        ...prevState["Opt-FoundationWallExtIns"],
                                        ["xps1.5inEffR7.5"]: !prevState["Opt-FoundationWallExtIns"]["xps1.5inEffR7.5"],
                                    },
                                })),
                            value: ecmInputMap["Opt-FoundationWallExtIns"]["xps1.5inEffR7.5"],
                        }}
                        disabled={disabledFields["xps1.5inEffR7.5"]}
                    />
                    <Checkbox
                        name="bsmtWallExtR28"
                        label="R-28 Effective, Fiberglass Batt, Exterior Insulation"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-FoundationWallExtIns"]: {
                                        ...prevState["Opt-FoundationWallExtIns"],
                                        ["xps2.5inEffR12.5"]:
                                            !prevState["Opt-FoundationWallExtIns"]["xps2.5inEffR12.5"],
                                    },
                                })),
                            value: ecmInputMap["Opt-FoundationWallExtIns"]["xps2.5inEffR12.5"],
                        }}
                        disabled={disabledFields["xps2.5inEffR12.5"]}
                    />
                </div>
                <div className={classes.checkboxContainer}>
                    <h5 style={{ marginBottom: "0.5rem" }}>Foundation Slab (Below Grade)</h5>
                    <Checkbox
                        name="bgSlabUninsulated"
                        label="Uninsulated"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-FoundationSlabBelowGrade"]: {
                                        ...prevState["Opt-FoundationSlabBelowGrade"],
                                        ["uninsulated-Opt-FoundationSlabBelowGrade"]:
                                            !prevState["Opt-FoundationSlabBelowGrade"][
                                                "uninsulated-Opt-FoundationSlabBelowGrade"
                                            ],
                                    },
                                })),
                            value: ecmInputMap["Opt-FoundationSlabBelowGrade"][
                                "uninsulated-Opt-FoundationSlabBelowGrade"
                            ],
                        }}
                        disabled={disabledFields["uninsulated-Opt-FoundationSlabBelowGrade"]}
                    />
                    <Checkbox
                        name="bgSlabR10"
                        label="R-10 Effective, Sub-slab Insulation, XPS"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-FoundationSlabBelowGrade"]: {
                                        ...prevState["Opt-FoundationSlabBelowGrade"],
                                        ["xps2inEffR10"]: !prevState["Opt-FoundationSlabBelowGrade"]["xps2inEffR10"],
                                    },
                                })),
                            value: ecmInputMap["Opt-FoundationSlabBelowGrade"]["xps2inEffR10"],
                        }}
                        disabled={disabledFields["xps2inEffR10"]}
                    />
                    <Checkbox
                        name="bgSlabR20"
                        label="R-20 Effective, Sub-slab Insulation, XPS"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-FoundationSlabBelowGrade"]: {
                                        ...prevState["Opt-FoundationSlabBelowGrade"],
                                        ["xps4inEffR20"]: !prevState["Opt-FoundationSlabBelowGrade"]["xps4inEffR20"],
                                    },
                                })),
                            value: ecmInputMap["Opt-FoundationSlabBelowGrade"]["xps4inEffR20"],
                        }}
                        disabled={disabledFields["xps4inEffR20"]}
                    />
                </div>
            </InputRow>
            <InputRow gridTemplate="1fr 1fr">
                <div className={classes.checkboxContainer}>
                    <h5 style={{ marginBottom: "0.5rem" }}>Heating and Cooling Systems</h5>
                    <Checkbox
                        name="elecBaseboardsAC"
                        label="Electric Baseboard + AC, 4 COP"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-Heating-Cooling"]: {
                                        ...prevState["Opt-Heating-Cooling"],
                                        ["elec-baseboard+AC"]: !prevState["Opt-Heating-Cooling"]["elec-baseboard+AC"],
                                    },
                                })),
                            value: ecmInputMap["Opt-Heating-Cooling"]["elec-baseboard+AC"],
                        }}
                        disabled={disabledFields["elec-baseboard+AC"]}
                    />
                    <Checkbox
                        name="ngFurnaceAC"
                        label="96% Natural Gas Furnace, ECM Motor + AC, 4 COP"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-Heating-Cooling"]: {
                                        ...prevState["Opt-Heating-Cooling"],
                                        ["gas-furnace-ecm+AC"]: !prevState["Opt-Heating-Cooling"]["gas-furnace-ecm+AC"],
                                    },
                                })),
                            value: ecmInputMap["Opt-Heating-Cooling"]["gas-furnace-ecm+AC"],
                        }}
                        disabled={disabledFields["gas-furnace-ecm+AC"]}
                    />
                    <Checkbox
                        name="ashp"
                        label="Air Source Heat Pump, 3.66 COP"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-Heating-Cooling"]: {
                                        ...prevState["Opt-Heating-Cooling"],
                                        ["ASHP"]: !prevState["Opt-Heating-Cooling"]["ASHP"],
                                    },
                                })),
                            value: ecmInputMap["Opt-Heating-Cooling"]["ASHP"],
                        }}
                        disabled={disabledFields["ASHP"]}
                    />
                    <Checkbox
                        name="ccashp"
                        label="Cold Climate Air Source Heat Pump, 2.97 COP"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-Heating-Cooling"]: {
                                        ...prevState["Opt-Heating-Cooling"],
                                        ["CCASHP"]: !prevState["Opt-Heating-Cooling"]["CCASHP"],
                                    },
                                })),
                            value: ecmInputMap["Opt-Heating-Cooling"]["CCASHP"],
                        }}
                        disabled={disabledFields["CCASHP"]}
                    />
                </div>
                <div className={classes.checkboxContainer}>
                    <h5 style={{ marginBottom: "0.5rem" }}>Domestic Hot Water System</h5>
                    <Checkbox
                        name="ngTank"
                        label="Natural Gas Tank, 190L, 0.67 EF"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-DHWSystem"]: {
                                        ...prevState["Opt-DHWSystem"],
                                        ["gas_storagetank_w/powervent_ef0.67"]:
                                            !prevState["Opt-DHWSystem"]["gas_storagetank_w/powervent_ef0.67"],
                                    },
                                })),
                            value: ecmInputMap["Opt-DHWSystem"]["gas_storagetank_w/powervent_ef0.67"],
                        }}
                        disabled={disabledFields["gas_storagetank_w/powervent_ef0.67"]}
                    />
                    <Checkbox
                        name="ngInstantaneous"
                        label="Natural Gas, Condensing Tankless, 0.95 EF"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-DHWSystem"]: {
                                        ...prevState["Opt-DHWSystem"],
                                        ["GasInstantaneous"]: !prevState["Opt-DHWSystem"]["GasInstantaneous"],
                                    },
                                })),
                            value: ecmInputMap["Opt-DHWSystem"]["GasInstantaneous"],
                        }}
                        disabled={disabledFields["GasInstantaneous"]}
                    />
                    <Checkbox
                        name="elecTank"
                        label="Electric Tank, 190L, 0.87 EF"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-DHWSystem"]: {
                                        ...prevState["Opt-DHWSystem"],
                                        ["elec_storage_ef0.89"]: !prevState["Opt-DHWSystem"]["elec_storage_ef0.89"],
                                    },
                                })),
                            value: ecmInputMap["Opt-DHWSystem"]["elec_storage_ef0.89"],
                        }}
                        disabled={disabledFields["elec_storage_ef0.89"]}
                    />
                    <Checkbox
                        name="hpwh"
                        label="Heat Pump Water Heater, 190L, 2.35 EF"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-DHWSystem"]: {
                                        ...prevState["Opt-DHWSystem"],
                                        ["HPHotWater"]: !prevState["Opt-DHWSystem"]["HPHotWater"],
                                    },
                                })),
                            value: ecmInputMap["Opt-DHWSystem"]["HPHotWater"],
                        }}
                        disabled={disabledFields["HPHotWater"]}
                    />
                </div>
            </InputRow>
            <InputRow gridTemplate="1fr 1fr">
                <div className={classes.checkboxContainer}>
                    <h5 style={{ marginBottom: "0.5rem" }}>Drain Water Heat Recovery</h5>
                    <Checkbox
                        name="noDwhr"
                        label="None"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-DWHR"]: {
                                        ...prevState["Opt-DWHR"],
                                        ["None;lpDWHR_00_X"]: !prevState["Opt-DWHR"]["None;lpDWHR_00_X"],
                                    },
                                })),
                            value: ecmInputMap["Opt-DWHR"]["None;lpDWHR_00_X"],
                        }}
                        disabled={disabledFields["None;lpDWHR_00_X"]}
                    />
                    <Checkbox
                        name="dwhr55"
                        label="DWHR unit, 55% Eff."
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-DWHR"]: {
                                        ...prevState["Opt-DWHR"],
                                        ["DWHR-eff-55"]: !prevState["Opt-DWHR"]["DWHR-eff-55"],
                                    },
                                })),
                            value: ecmInputMap["Opt-DWHR"]["DWHR-eff-55"],
                        }}
                        disabled={disabledFields["DWHR-eff-55"]}
                    />
                </div>
            </InputRow>
            <div className={classes.textWrapper}>
                <p>All remaining categories only contain one ECM option and may not be toggled off.</p>
            </div>
        </div>
    );
};
export default NewBuildBatchEcms;
