import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import Generation from "./";

const mapStateToProps = ({ form }, { accessor, formName = "model" }) => {
    const selector = formValueSelector(formName);
    const modelSelector = formValueSelector("model");

    const pvSystems = selector({ form }, `${accessor}.pvSystems`) || {};
    const windEnergyUnits = selector({ form }, `${accessor}.windEnergyContribution_u`);
    const pvCapacityValue = selector({ form }, `${accessor}.pvCapacity`);
    const pvCapacityUnits = selector({ form }, `${accessor}.pvCapacity_u`);

    const modelUnits = modelSelector({ form }, `modelData.uiSettings.primaryUnits`) || {};
    const { major: h2kMajor, minor: h2kMinor = 11 } = modelSelector({ form }, "modelData.h2kVersion") || {};

    return {
        accessor,
        pvSystems,
        windEnergyUnits,
        pvCapacityValue,
        pvCapacityUnits,
        h2kMinor,
        modelUnits,
    };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Generation);
