import React, { useEffect, useCallback } from 'react';
import classes from '../../../style.module.scss';
import { Field } from 'redux-form';
import Select from 'components/Input/Select';
import InputWithUnits from 'components/Input/InputWithUnits';
import SelectWithInput from 'components/Input/SelectWithInput';
import Input from 'components/Input';
import { required, maxLength, getValidation, getDecimalPlaces } from 'utils/fieldValidation';
import { getOptions, getBaseUnits, getUnitOptions } from 'utils/fields';
import convertUnit from 'utils/convertUnit';
import { isEmpty, isEqual } from 'lodash'
import {
    ceilingFlatTypeModelCodesMap,
    ceilingTypeModelCodesMap,
    ceilingFlatTypeLibCodesMap,
    ceilingTypeLibCodesMap,
    CodeFooter
} from 'features/Model/Enclosure/ComponentList/Ceiling/Construction';

const charLim100 = maxLength(100);
const lengthValidation = getValidation('ceilingLength');
const slopeValidation = getValidation('ceilingSlope');
const ceilingTypeValidation = getValidation('ceilingInsType');
const constructionTypeValidation = getValidation('ceilingConstructionType');

const Ceiling = React.memo(({
    componentId,
    primaryUnits,
    imageUnits,
    area,
    conversionFactor,
    change,
    modelCodes,
    selectedRValue,
    selectedCodeRef,
    lengthUnits,
    libCeilingCodes,
    libCeilingFlatCodes,
    constructTypeId,
    rValUnits,
    fieldsWithLinesAttached,
    setInitCode,
    modelChange,
    saveToState,
    isCalculatingRValue,
    ceilingCodeWarning,
    updateCodeDrawer
}) => {
    const calculatedArea = parseFloat(convertUnit({
        value:area*Math.pow(conversionFactor, 2),
        type:'area',
        inputUnit:'m2',
        outputUnit:getBaseUnits('area', primaryUnits).trueBase
    }).toFixed(2));

    const isFlat = [2,3].includes(constructTypeId);

    const handleTypeChange = useCallback(({ codeRef = '' })=>{
        if (codeRef === 'UserSpecified') {
            change(`components.${componentId}.ceilingInsType_warning`, '');
            return
        }

        const modelMatch = modelCodes[codeRef] || {};
        if (!isEmpty(modelMatch)) {
            change(`components.${componentId}.ceilingInsType_nomRVal`, modelMatch.nominalRValue || 0);
            change(`components.${componentId}.ceilingInsType_effRVal`, modelMatch.nominalRValue || 0);
            change(`components.${componentId}.ceilingInsType_warning`, modelMatch.warningType || '');
        } else {
            const {nominalRValue = 0, warningType = ''} = isFlat ? (libCeilingFlatCodes[codeRef] || {}) : (libCeilingCodes[codeRef] || {});
            change(`components.${componentId}.ceilingInsType_nomRVal`, nominalRValue || 0);
            change(`components.${componentId}.ceilingInsType_effRVal`, nominalRValue || 0);
            change(`components.${componentId}.ceilingInsType_warning`, warningType || '');
        }
    },[libCeilingCodes,libCeilingFlatCodes,modelCodes,change,componentId]);

    useEffect(() => {
        if ((selectedCodeRef || '').includes('Ceiling-')) {
            modelChange(`stashedCodes.Ceiling.${componentId}`, selectedCodeRef);
        }
    },[componentId, selectedCodeRef]);

    useEffect(() => {
        if ((selectedCodeRef || '').includes('CeilingFlat-')) {
            modelChange(`stashedCodes.CeilingFlat.${componentId}`, selectedCodeRef);
        }
    },[componentId, selectedCodeRef]);

    const handleEditModelCeilingCode = useCallback(async (event, codeRef, codeType) => {
        event.stopPropagation(); //Don't let click event bubble up to parent
        const initCodeType = codeType === "U" ? "udefStandard" : "ceilingInsType";
        const code = modelCodes[codeRef];
        await setInitCode(code, initCodeType);
        updateCodeDrawer({
            isOpen:true,
            codeName: "Ceiling",
            isEditing: true,
            isDrawing: true,
            componentType: "Ceiling",
            codeType,
            fieldAccessor: `components.${componentId}.ceilingInsType`,
            componentId,
            id: codeRef,
            modelFormChange: modelChange,
            drawingFormChange: change,
            drawingSave: saveToState,
            lastEdited: code.lastEdited || ''
        });
    }, [change, componentId, modelCodes, modelChange, setInitCode, updateCodeDrawer, saveToState]);

    const handleEditLibCeilingCode = useCallback(async (event, codeRef, codeType) => {
        event.stopPropagation(); //Don't let click event bubble up to parent
        const initCodeType = codeType === "U" ? "udefStandard" : "ceilingInsType";
        const code = {...libCeilingCodes[codeRef], codeRef:codeRef, isLibCode:true };
        await setInitCode(code, initCodeType);
        updateCodeDrawer({
            isOpen:true,
            codeName: "Ceiling",
            isEditing: true,
            isDrawing: true,
            componentType: "Ceiling",
            codeType,
            fieldAccessor: `components.${componentId}.ceilingInsType`,
            componentId,
            id: codeRef,
            modelFormChange: modelChange,
            drawingFormChange: change,
            drawingSave: saveToState,
            lastEdited: code.lastEdited || ''
        });
    }, [change, componentId, libCeilingCodes, modelChange, setInitCode, updateCodeDrawer, saveToState]);

    const handleEditModelCeilingFlatCode = useCallback(async (event, codeRef, codeType) => {
        event.stopPropagation(); //Don't let click event bubble up to parent
        const initCodeType = codeType === "U" ? "udefStandard" : "ceilingInsType";
        const code = modelCodes[codeRef];
        await setInitCode(code, initCodeType);
        updateCodeDrawer({
            isOpen:true,
            codeName: "CeilingFlat",
            isEditing: true,
            isDrawing: true,
            componentType: "Ceiling",
            codeType,
            fieldAccessor: `components.${componentId}.ceilingInsType`,
            componentId,
            id: codeRef,
            modelFormChange: modelChange,
            drawingFormChange: change,
            drawingSave: saveToState,
            lastEdited: code.lastEdited || ''
        });
    }, [change, componentId, modelCodes, modelChange, setInitCode, updateCodeDrawer, saveToState]);

    const handleEditLibCeilingFlatCode = useCallback(async (event, codeRef, codeType) => {
        event.stopPropagation(); //Don't let click event bubble up to parent
        const initCodeType = codeType === "U" ? "udefStandard" : "ceilingInsType";
        const code = {...libCeilingFlatCodes[codeRef], codeRef:codeRef, isLibCode:true };
        await setInitCode(code, initCodeType);
        updateCodeDrawer({
            isOpen:true,
            codeName: "CeilingFlat",
            isEditing: true,
            isDrawing: true,
            componentType: "Ceiling",
            codeType,
            fieldAccessor: `components.${componentId}.ceilingInsType`,
            componentId,
            id: codeRef,
            modelFormChange: modelChange,
            drawingFormChange: change,
            drawingSave: saveToState,
            lastEdited: code.lastEdited || ''
        });
    }, [change, componentId, libCeilingFlatCodes, modelChange, setInitCode, updateCodeDrawer, saveToState]);

    const insTypeModelCodesMap = isFlat ? 
        ceilingFlatTypeModelCodesMap(modelCodes, libCeilingFlatCodes, handleEditModelCeilingFlatCode) :
        ceilingTypeModelCodesMap(modelCodes, libCeilingCodes, handleEditModelCeilingCode);

    const insTypeLibCodesMap = isFlat ? 
        ceilingFlatTypeLibCodesMap(libCeilingFlatCodes, null) :
        ceilingTypeLibCodesMap(libCeilingCodes, null);


    return (
        <div>
            <Field
                className={classes.inputMarginBottom}
                component={Input}
                type="text"
                label="Ceiling Name*"
                name={`components.${componentId}.label`}
                validate={[required, charLim100]}
                placeholder="Example: Main Ceiling"
                onChange={saveToState}
            />
            <Field
                className={classes.inputMarginBottom}
                component={InputWithUnits}
                type="number"
                name={`components.${componentId}.measurements.length`}
                label="Ceiling Length"
                placeholder="0.00"
                validate={lengthValidation}
                decimals={getDecimalPlaces('ceilingLength')}
                disabled={fieldsWithLinesAttached.includes('ceilingLength')}
                isDrawing={fieldsWithLinesAttached.includes('ceilingLength')}
                isDrawingTip="This field is associated with a Line in the Drawing Capture. To detach, delete the Line or use it for another field."
                change={change}
                units={{
                    base:getBaseUnits('ceilingLength', primaryUnits),
                    options:getUnitOptions('length'),
                    selected:lengthUnits,
                    unitType:'length',
                    accessor:`components.${componentId}.measurements.length_u`
                }}
                onChange={saveToState}
                onUnitChange={saveToState}
            />
            <Field
                className={classes.inputMarginBottom}
                component={InputWithUnits}
                type="number"
                name={`components.${componentId}.measurements.area`}
                label="Ceiling Area"
                placeholder="0.00"
                decimals={getDecimalPlaces('ceilingArea')}
                change={()=>''}
                disabled={true}
                setValue={calculatedArea}
                units={{
                    base:getBaseUnits('ceilingArea', primaryUnits),
                    options:[`${imageUnits}2`],
                    selected:`${imageUnits}2`,
                    unitType:'area',
                    accessor:`components.${componentId}.measurements.area_u`
                }}
                info="Calculated based on polygon shape and the set scale."
                onChange={saveToState}
                onUnitChange={saveToState}
            />
            <Field
                selectClassName={classes.inputMarginBottom}
                component={SelectWithInput}
                inputData={{
                    triggerValue:0,
                    inputLabel:'Slope',
                    inputType:'number',
                    inputPlaceholder:'',
                }}
                name={`components.${componentId}.measurements.slope`}
                options={getOptions({fieldKey:'ceilingSlope'})}
                label="Ceiling Slope"
                placeholder="Choose Ceiling Slope"
                validate={slopeValidation}
                isVertical
                onChange={saveToState}
                onInputChange={saveToState}
            />
            <Field
                className={classes.inputMarginBottom}
                component={Select}
                name={`components.${componentId}.constructionType`}
                options={getOptions({fieldKey:'ceilingConstructionType'})}
                label="Ceiling Construction Type"
                placeholder="Choose Ceiling Construction Type"
                validate={constructionTypeValidation}
                onChange={saveToState}
            />
            <Field
                className={classes.inputMarginBottom}
                component={Select}
                type="number"
                name={`components.${componentId}.ceilingInsType`}
                options={[
                    {
                        label:"User Specified",
                        value:{
                            codeLabel:"User Specified",
                            codeRef:"UserSpecified",
                        }
                    },
                    ...insTypeModelCodesMap,
                    ...insTypeLibCodesMap,
                ]}
                label={isFlat? "Flat Ceiling Type": "Ceiling Type"}
                placeholder={isFlat? "Choose Flat Ceiling Type": "Choose Ceiling Type"}
                searchPlaceholder="Search Ceiling Codes"
                search={true}
                footer={()=><CodeFooter
                    toggleDrawer={(value) => (
                        updateCodeDrawer({
                            isOpen:value,
                            codeName: isFlat ? "CeilingFlat" : "Ceiling",
                            isEditing: false,
                            isDrawing: true,
                            componentType: isFlat ? "CeilingFlat" : "Ceiling",
                            codeType: "S",
                            fieldAccessor: `components.${componentId}.ceilingInsType`,
                            componentId,
                            modelFormChange: modelChange,
                            drawingFormChange: change,
                            drawingSave: saveToState
                        })
                    )}
                    setInitCode={setInitCode}
                    codeType="ceilingInsType"
                />}
                isLoading={isCalculatingRValue}
                onChange={(values) => {
                    handleTypeChange(values);
                    saveToState(values);
                }}
            />
            <Field
                className={classes.inputMarginBottom}
                component={InputWithUnits}
                type="number"
                name={`components.${componentId}.ceilingInsType_nomRVal`}
                label={selectedCodeRef === "UserSpecified" ? "Effective R-Value" : "Nominal R-Value"}
                placeholder={0.00}
                disabled={selectedCodeRef !== "UserSpecified"}
                validate={ceilingTypeValidation}
                setTouched={true}
                decimals={getDecimalPlaces('ceilingInsType')}
                change={change}
                setValue={selectedRValue}
                units={{
                    base:getBaseUnits('ceilingInsType', primaryUnits),
                    options:getUnitOptions('thermalResistance'),
                    selected:rValUnits,
                    unitType:'thermalResistance',
                    accessor:`components.${componentId}.ceilingInsType_u`
                }}
                onChange={(value) => {
                    if (selectedCodeRef === "UserSpecified") {
                        change(`components.${componentId}.ceilingInsType_nomRVal`, value);
                        change(`components.${componentId}.ceilingInsType_effRVal`, value);
                    }
                    saveToState();
                }}
                onUnitChange={saveToState}
                isLoading={isCalculatingRValue}
                warning={ceilingCodeWarning}
            />
        </div>
    )
}, isEqual);

export default Ceiling;