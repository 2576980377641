import React, { useCallback, useState } from "react";
import classes from "./style.module.scss";
import sharedClasses from "features/Model/sharedStyles.module.scss";
import { Field } from "redux-form";
import { getOptions, getFirstOption, getBaseUnits, getUnitOptions } from "utils/fields";
import { maxLength, getValidation, getDecimalPlaces, required } from "utils/fieldValidation";
import { getDhwTemplate, getDhwEfDefault, getDefaultDhwSpecs } from "utils/mechanicals";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import InputWithUnits from "components/Input/InputWithUnits";
import Select from "components/Input/Select";
import Checkbox from "components/Input/Checkbox";
import Button from "components/Button";
import DwhrSystem from "../DwhrSystem/container";
import Delete from "assets/images/icons/JSX/Delete";
import Add from "assets/images/icons/JSX/Add";
import Tooltip from "components/Tooltip";
import ThreeDots from "assets/images/icons/JSX/ThreeDots";
import DropDown from "components/Dropdown";

const charLim100 = maxLength(100);
const dhwTankFractionValidation = getValidation("dhwTankFraction");
const dhwEnergySourceValidation = getValidation("dhwEnergySource");
const dhwTankTypeValidation = getValidation("dhwTankType");
const efValidation = getValidation("dhwEnergyFactor");
const tankLocationValidation = getValidation("dhwTankLocation");
const tankVolumeValidation = getValidation("dhwTankVolume");
const uefDrawPatternValidation = getValidation("dhwUniformEFDrawPattern");
const standbyLossValidation = getValidation("dhwStandbyHeatLoss");
const standbyLossModeValidation = getValidation("dhwStandbyHeatLossMode");
const thermalEffValidation = getValidation("dhwThermalEff");
const inputCapValidation = getValidation("dhwInputCapacity");

const insulatingBlanketValidation = getValidation("dhwInsulatingBlanket");
const pilotEnergyValidation = getValidation("dhwPilotEnergy");
const flueDiameterValidation = getValidation("dhwFlueDiameter");
const dhwHPCOPValidation = getValidation("dhwHPCOP");

const solarRatingValidation = getValidation("dhwSolarRating");
const solarAzimuthValidation = getValidation("dhwSolarAzimuth");
const solarSlopeValidation = getValidation("dhwSolarSlope");

export default ({
    accessor,
    hasSecondarySystem,
    isPrimarySolar,
    fractionOfTankValue,
    heatingSystemType,
    energySourceId,
    isDwhr,
    tankTypeId,
    tankVolumeId,
    dhwTankLocationId,
    tankVolumeValue,
    isUniformEF,
    efId,
    efValue,
    inputCapValue,
    standbyHeatLossValue,
    standbyHeatLossModeValue,
    thermalEfficiencyValue,
    insulatingBlanketValue,
    pilotEnergyValue,
    flueDiameterValue,
    hpCopValue,
    isSolarEmpty,
    tankVolumeUnits,
    standbyHeatLossUnits,
    thermalEffUnits,
    inputCapUnits,
    insulatingBlanketUnits,
    pilotEnergyUnits,
    flueDiameterUnits,
    solarRatingUnits,
    fractionOfTankUnits,
    modelUnits,
    change,
    isPrimary,
    handleDelete,
    noBasementComponents,
    isUpgrade = false,
    formName,
    isMURB,
}) => {
    const [menuOpen, toggleMenu] = useState(false);

    const efOpts = isUniformEF
        ? [
              {
                  label: "User Specified",
                  value: {
                      id: 1,
                      value: efValue,
                  },
              },
          ]
        : [
              {
                  label: "Use Defaults",
                  value: {
                      id: 0,
                      value: efValue,
                  },
              },
              {
                  label: "User Specified",
                  value: {
                      id: 1,
                      value: efValue,
                  },
              },
              {
                  label: "Standby",
                  value: {
                      id: 2,
                      value: efValue,
                  },
              },
          ];

    const handleSolarInit = (id) => {
        if (isSolarEmpty) {
            change(`${accessor}.solar`, {
                rating: 6350,
                slope: 51.19,
                azimuth: 0,
            });
        }
        change(`${accessor}.energyFactor`, { id: 0, value: 0 });
        change(`${accessor}.tankVolume`, { id: 6, value: 0 });
    };

    const handleTankTypeChange = (id) => {
        //Handle disabling tank volume
        if (id === 5 && (hpCopValue == null || isNaN(hpCopValue))) {
            change(`${accessor}.heatPumpCOP`, 2.5);
            change(`${accessor}.energyFactor`, { id: 1, value: getDhwEfDefault({ tankTypeId: id, tankVolId: 6 }) });
        } else if ([2, 3, 4, 8, 9, 10, 11, 18, 21, 22, 23, 24].includes(id)) {
            change(`${accessor}.tankVolume`, { id: 6, value: 0 });
            change(`${accessor}.energyFactor.value`, getDhwEfDefault({ tankTypeId: id, tankVolId: 6 }));
        } else if (id <= 53 || id >= 30) {
            change(`${accessor}.energyFactor.value`, getDhwEfDefault({ tankTypeId: id, tankVolId: 6 }));
        }

        const { pilotEnergy = 0, flueDiameter = 0 } = getDefaultDhwSpecs(id);

        change(`${accessor}.pilotEnergy`, pilotEnergy);
        change(`${accessor}.flueDiameter`, flueDiameter);
    };

    const handleTankVolumeChange = (id) => {
        if (efId === 0) {
            change(`${accessor}.energyFactor.value`, getDhwEfDefault({ tankTypeId, tankVolId: id }));
        }
    };

    const handleCreateDwhr = () => {
        change(`${accessor}.hasDrainWaterHeatRecovery`, true);
        change(`${accessor}.drainWaterHeatRecovery`, getDhwTemplate("dwhr"));
        if (isMURB) {
            change(`${accessor}.connectedUnitsDwhr`, 1);
        }
    };

    const handleDeleteDwhr = () => {
        change(`${accessor}.hasDrainWaterHeatRecovery`, false);
        change(`${accessor}.drainWaterHeatRecovery`, {});
    };

    const isPilotDisabled = ![1, 3].includes(energySourceId) || ![7, 11, 13, 15, 20, 24, 26, 28].includes(tankTypeId);

    return (
        <div
            className={`${!isUpgrade && sharedClasses.section} ${!isPrimary && classes.sectionSecondary}`}
            style={{ marginTop: isUpgrade ? "1.875rem" : "" }}
        >
            <div className={classes.heading}>
                <div className={classes.title}>
                    <h4>{isPrimary ? "Primary" : "Secondary"} Hot Water System</h4>
                    <span className={classes.menu} onClick={() => toggleMenu(!menuOpen)}>
                        <ThreeDots />
                    </span>
                    <DropDown
                        className={classes.dropDown}
                        open={menuOpen}
                        toggleOpen={toggleMenu}
                        options={[
                            {
                                label: (
                                    <span>
                                        <Delete /> Delete
                                    </span>
                                ),
                                className: classes.delete,
                                onClick: handleDelete,
                            },
                        ]}
                    />
                </div>
                <div
                    className={`${classes.addDHWR} ${isDwhr && classes.disabled}`}
                    onClick={() => handleCreateDwhr()}
                    data-tip="Add Drain Water Heat Recovery"
                    data-for="dwhrHeader"
                >
                    Add DWHR <Add />
                </div>
                <Tooltip id="dwhrHeader" />
            </div>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Field
                    component={Select}
                    name={`${accessor}.energySource`}
                    options={getOptions({ fieldKey: "dhwEnergySource" })}
                    label="Energy Source"
                    placeholder="Choose Energy Source"
                    validate={dhwEnergySourceValidation}
                    onChange={({ id }) => {
                        change(`${accessor}.tankType`, getFirstOption({ indKey: id, depKey: "dhwTankType" }));
                        if (id === 8) {
                            handleSolarInit(id);
                        } else if (energySourceId > 3) {
                            change(`${accessor}.tankVolume`, { id: 6, value: 0 });
                        }

                        if (id === 0 && efId === 2) {
                            change(`${accessor}.standby.thermalEfficiency`, 98);
                        } else if (efId === 2) {
                            change(`${accessor}.standby.thermalEfficiency`, 0);
                        }

                        if (id >= 4) {
                            change(`${accessor}.isUniform`, false);
                        }
                    }}
                />
                <Field
                    component={Select}
                    name={`${accessor}.tankType`}
                    options={getOptions({ fieldKey: "dhwTankType", indKey: energySourceId })}
                    label="Tank Type"
                    placeholder="Choose Tank Type"
                    validate={dhwTankTypeValidation}
                    onChange={({ id }) => handleTankTypeChange(id)}
                    info={
                        "For heat pump water heaters, only the Integrated Heat Pump option may be modelled in HOT2000."
                    }
                    warning={[3, 4].includes(tankTypeId) ? "hpwhWorkaroundError" : ""}
                />
                <Field
                    component={Input}
                    name={`${accessor}.manufacturer`}
                    type="text"
                    label="Manufacturer Name"
                    placeholder="Enter Manufacturer Name"
                    validate={charLim100}
                />
                <Field
                    component={Input}
                    name={`${accessor}.model`}
                    type="text"
                    label="Model Name"
                    placeholder="Enter Model Name"
                    validate={charLim100}
                />
            </InputRow>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Field
                    component={Select}
                    name={`${accessor}.tankLocation`}
                    options={getOptions({ fieldKey: "dhwTankLocation" })}
                    label="Tank Location"
                    placeholder="Choose Tank Location"
                    validate={tankLocationValidation}
                    warning={noBasementComponents && dhwTankLocationId === 1 ? "dhwNoBasement" : ""}
                />
                <Field
                    component={Select}
                    name={`${accessor}.tankVolume`}
                    options={[
                        {
                            label: "User specified",
                            value: {
                                id: 0,
                                value: tankVolumeValue,
                            },
                        },
                        ...getOptions({ fieldKey: "dhwTankVolume" }),
                    ]}
                    label="Tank Volume"
                    placeholder="Choose Tank Volume"
                    validate={tankVolumeValidation}
                    disabled={energySourceId > 3 || [2, 3, 4, 8, 9, 10, 11, 18, 21, 22, 23, 24].includes(tankTypeId)}
                    onChange={({ id }) => handleTankVolumeChange(id)}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.tankVolume.value`}
                    label="Volume"
                    placeholder={0.0}
                    disabled={
                        tankVolumeId !== 0 ||
                        energySourceId > 3 ||
                        [2, 3, 4, 8, 9, 10, 11, 18, 21, 22, 23, 24].includes(tankTypeId)
                    }
                    validate={tankVolumeValidation}
                    setTouched={true}
                    decimals={getDecimalPlaces("dhwTankVolume")}
                    change={change}
                    setValue={tankVolumeValue}
                    units={{
                        base: getBaseUnits("dhwTankVolume", modelUnits),
                        options: getUnitOptions("volume"),
                        selected: tankVolumeUnits,
                        unitType: "volume",
                        accessor: `${accessor}.tankVolume_u`,
                    }}
                />
            </InputRow>
            <InputRow gridTemplate="23.25% 23.25% 15% 15% 15%">
                <Field
                    component={Select}
                    name={`${accessor}.isUniform`}
                    options={[
                        {
                            label: "Uniform Energy Factor",
                            value: true,
                        },
                        {
                            label: "Energy Factor",
                            value: false,
                        },
                    ]}
                    label="Efficiency Type"
                    placeholder="Choose Efficiency Type"
                    info={"Select based on the available efficiency type data. If unknown, select Energy Factor."}
                    onChange={(value) => {
                        if (value) {
                            change(`${accessor}.energyFactor`, { id: 1, value: efValue });
                            change(`${accessor}.drawPattern`, { id: 2 });
                        }
                    }}
                />
                <Field
                    component={Select}
                    name={`${accessor}.energyFactor`}
                    options={efOpts}
                    label="Input Type"
                    placeholder="Choose Input Factor"
                    validate={efValidation}
                    disabled={energySourceId === 8}
                    onChange={({ id }) => {
                        if (energySourceId === 0 && id === 2) {
                            change(`${accessor}.standby.thermalEfficiency`, 98);
                        } else {
                            change(`${accessor}.standby.thermalEfficiency`, 0);
                        }
                    }}
                />
                <Field
                    component={Input}
                    name={`${accessor}.energyFactor.value`}
                    type="number"
                    label={isUniformEF ? "Uniform Energy Factor" : "Energy Factor"}
                    placeholder={0.0}
                    setValue={efValue}
                    validate={efValidation}
                    disabled={efId !== 1}
                />
                <Field
                    component={Checkbox}
                    name={`${accessor}.energyStar`}
                    label="Energy Star"
                    large
                    className={classes.checkbox}
                    type="checkbox"
                />
                <Field
                    component={Checkbox}
                    name={`${accessor}.ecoEnergy`}
                    label="ecoEnergy"
                    large
                    className={classes.checkbox}
                    type="checkbox"
                />
            </InputRow>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.standby.inputCapacity`}
                    label="Input Capacity"
                    placeholder="Enter Input Capacity"
                    validate={[required, ...inputCapValidation]}
                    setValue={inputCapValue}
                    setTouched={true}
                    decimals={getDecimalPlaces("dhwInputCapacity")}
                    change={change}
                    units={{
                        base: getBaseUnits("dhwInputCapacity", modelUnits),
                        options: getUnitOptions("power"),
                        selected: inputCapUnits,
                        unitType: "power",
                        accessor: `${accessor}.standby.inputCapacity_u`,
                    }}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.standby.thermalEfficiency`}
                    label="Thermal Efficiency"
                    placeholder={0.0}
                    disabled={efId !== 2 || [0, 8].includes(energySourceId)}
                    validate={thermalEffValidation}
                    setValue={thermalEfficiencyValue}
                    setTouched={true}
                    decimals={getDecimalPlaces("dhwThermalEff")}
                    change={change}
                    units={{
                        base: getBaseUnits("dhwThermalEff", modelUnits),
                        options: getUnitOptions("fraction"),
                        selected: thermalEffUnits,
                        unitType: "fraction",
                        accessor: `${accessor}.standby.thermalEfficiency_u`,
                    }}
                />
                {isUniformEF && (
                    <Field
                        component={Select}
                        name={`${accessor}.drawPattern`}
                        options={getOptions({ fieldKey: "dhwUniformEFDrawPattern" })}
                        label="UEF Draw Pattern"
                        placeholder="Choose Draw Pattern"
                        validate={uefDrawPatternValidation}
                        disabled={energySourceId >= 4}
                    />
                )}
                {efId === 2 ? (
                    <>
                        {standbyHeatLossModeValue === 1 ? (
                            <>
                                <Field
                                    component={Input}
                                    name={`${accessor}.standby.standbyHeatLoss`}
                                    type="number"
                                    label="Standby Heat Loss"
                                    placeholder={0.0}
                                    setValue={standbyHeatLossValue}
                                    validate={standbyLossValidation}
                                    disabled={efId !== 2}
                                />
                            </>
                        ) : (
                            <>
                                <Field
                                    component={InputWithUnits}
                                    type="number"
                                    name={`${accessor}.standby.standbyHeatLoss`}
                                    label="Standby Heat Loss"
                                    placeholder={0.0}
                                    disabled={efId !== 2}
                                    validate={standbyLossValidation}
                                    setTouched={true}
                                    decimals={getDecimalPlaces("dhwStandbyHeatLoss")}
                                    change={change}
                                    setValue={standbyHeatLossValue}
                                    units={{
                                        base: getBaseUnits("dhwStandbyHeatLoss", modelUnits),
                                        options: getUnitOptions("power"),
                                        selected: standbyHeatLossUnits,
                                        unitType: "power",
                                        accessor: `${accessor}.standby.standbyHeatLoss_u`,
                                    }}
                                />
                            </>
                        )}
                        <Field
                            component={Select}
                            name={`${accessor}.standby.standbyHeatLossMode`}
                            type="number"
                            label="Standby Heat Loss Input Type"
                            placeholder="Choose Input Type"
                            options={[
                                {
                                    label: "Power Units",
                                    value: 0,
                                },
                                {
                                    label: "%/hr",
                                    value: 1,
                                },
                            ]}
                            setValue={standbyHeatLossModeValue}
                            validate={standbyLossModeValidation}
                            disabled={efId !== 2}
                        />
                    </>
                ) : (
                    <></>
                )}
            </InputRow>
            {![0, 8].includes(energySourceId) ? (
                <>
                    <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.insulatingBlanket`}
                            label="Insulating Blanket"
                            placeholder={0.0}
                            disabled={energySourceId === 8 || [3, 4].includes(tankTypeId)}
                            validate={insulatingBlanketValidation}
                            setTouched={true}
                            setValue={insulatingBlanketValue}
                            decimals={getDecimalPlaces("dhwInsulatingBlanket")}
                            change={change}
                            units={{
                                base: getBaseUnits("dhwInsulatingBlanket", modelUnits),
                                options: getUnitOptions("thermalResistance"),
                                selected: insulatingBlanketUnits,
                                unitType: "thermalResistance",
                                accessor: `${accessor}.insulatingBlanket_u`,
                            }}
                            info={"Enter a value of 0 to indicate that no insulating blanket is present."}
                        />
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.pilotEnergy`}
                            label="Pilot Energy"
                            placeholder={0.0}
                            disabled={isPilotDisabled}
                            validate={pilotEnergyValidation}
                            setTouched={true}
                            setValue={isPilotDisabled ? 0 : pilotEnergyValue}
                            decimals={getDecimalPlaces("dhwPilotEnergy")}
                            change={change}
                            units={{
                                base: getBaseUnits("dhwPilotEnergy", modelUnits),
                                options: getUnitOptions("dailyEnergy"),
                                selected: pilotEnergyUnits,
                                unitType: "dailyEnergy",
                                accessor: `${accessor}.pilotEnergy_u`,
                            }}
                            onChange={(value) => {
                                if (value !== 0) {
                                    change(`${accessor}.userDefinedPilot`, true);
                                } else {
                                    change(`${accessor}.userDefinedPilot`, false);
                                }
                            }}
                        />
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.flueDiameter`}
                            label="Flue Diameter"
                            placeholder={0.0}
                            disabled={
                                ![1, 2, 3].includes(energySourceId) || [8, 14, 15, 18, 21, 27, 28].includes(tankTypeId)
                            }
                            validate={flueDiameterValidation}
                            setTouched={true}
                            setValue={flueDiameterValue}
                            decimals={getDecimalPlaces("dhwFlueDiameter")}
                            change={change}
                            units={{
                                base: getBaseUnits("dhwFlueDiameter", modelUnits),
                                options: getUnitOptions("length"),
                                selected: flueDiameterUnits,
                                unitType: "length",
                                accessor: `${accessor}.flueDiameter_u`,
                            }}
                            info={"Leave as the default value."}
                        />
                        <Field
                            component={Checkbox}
                            name={`${accessor}.combinedFlue`}
                            label="Combined with heating system flue?"
                            large
                            className={classes.checkbox}
                            type="checkbox"
                            disabled={!["furnace", "boiler"].includes(heatingSystemType)}
                        />
                    </InputRow>
                </>
            ) : (
                <></>
            )}
            {energySourceId === 0 ? (
                <>
                    <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.insulatingBlanket`}
                            label="Insulating Blanket"
                            placeholder={0.0}
                            disabled={energySourceId === 8 || [3, 4].includes(tankTypeId)}
                            validate={insulatingBlanketValidation}
                            setTouched={true}
                            setValue={insulatingBlanketValue}
                            decimals={getDecimalPlaces("dhwInsulatingBlanket")}
                            change={change}
                            units={{
                                base: getBaseUnits("dhwInsulatingBlanket", modelUnits),
                                options: getUnitOptions("thermalResistance"),
                                selected: insulatingBlanketUnits,
                                unitType: "thermalResistance",
                                accessor: `${accessor}.insulatingBlanket_u`,
                            }}
                        />
                        <Field
                            component={Input}
                            type="number"
                            name={`${accessor}.heatPumpCOP`}
                            label="Heat Pump COP"
                            placeholder={"Enter Heat Pump COP"}
                            disabled={tankTypeId !== 5}
                            validate={dhwHPCOPValidation}
                            decimals={getDecimalPlaces("dhwHPCOP")}
                            setValue={!isNaN(hpCopValue) ? hpCopValue : null} //Only set value if it's nonNaN
                            info={"Only applicable with an Integrated Heat Pump"}
                        />
                    </InputRow>
                </>
            ) : (
                <></>
            )}
            {energySourceId === 8 && (
                <>
                    <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.solar.rating`}
                            label="CSA F379 Rating (per year)"
                            placeholder={0.0}
                            disabled={energySourceId !== 8}
                            validate={solarRatingValidation}
                            setTouched={true}
                            decimals={getDecimalPlaces("dhwSolarRating")}
                            change={change}
                            units={{
                                base: getBaseUnits("dhwSolarRating", modelUnits),
                                options: getUnitOptions("energy"),
                                selected: solarRatingUnits,
                                unitType: "energy",
                                accessor: `${accessor}.solar.rating_u`,
                            }}
                            info={"Enter either the CSA F379 rating or SRCC Canadian One-Day Performance Rating."}
                        />
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.solar.slope`}
                            label="Solar Slope"
                            placeholder={0.0}
                            disabled={energySourceId !== 8}
                            validate={solarSlopeValidation}
                            setTouched={true}
                            decimals={getDecimalPlaces("dhwSolarSlope")}
                            change={change}
                            units={{
                                base: ["°"],
                                options: ["°"],
                                selected: ["°"],
                                unitType: "angle",
                                accessor: `${accessor}.solar.slope_u`,
                            }}
                            info={"As measured from the horizontal."}
                        />
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.solar.azimuth`}
                            label="Solar Azimuth"
                            placeholder={0.0}
                            disabled={energySourceId !== 8}
                            validate={solarAzimuthValidation}
                            setTouched={true}
                            decimals={getDecimalPlaces("dhwSolarAzimuth")}
                            change={change}
                            units={{
                                base: ["°"],
                                options: ["°"],
                                selected: ["°"],
                                unitType: "angle",
                                accessor: `${accessor}.solar.azimuth_u`,
                            }}
                            info={
                                "Relative to true south, where: South = 0°, East = 90°, West = -90°, and North = ±180°."
                            }
                        />
                    </InputRow>
                </>
            )}
            {hasSecondarySystem && !isPrimarySolar && (
                <InputRow gridTemplate="1fr 1fr 1fr 1fr" lastRow={!isDwhr}>
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.fractionOfTank`}
                        label="Fraction of Tank"
                        placeholder={0.0}
                        disabled={!hasSecondarySystem}
                        validate={dhwTankFractionValidation}
                        setTouched={true}
                        decimals={getDecimalPlaces("dhwTankFraction")}
                        change={change}
                        setValue={isPrimary ? fractionOfTankValue : 1 - fractionOfTankValue}
                        units={{
                            base: getBaseUnits("dhwTankFraction", modelUnits),
                            options: getUnitOptions("fraction"),
                            selected: fractionOfTankUnits,
                            unitType: "fraction",
                            accessor: `${accessor}.fractionOfTank_u`,
                        }}
                    />
                </InputRow>
            )}
            {isDwhr && (
                <DwhrSystem
                    accessor={`${accessor}.drainWaterHeatRecovery`}
                    change={change}
                    handleDelete={() => handleDeleteDwhr()}
                    formName={formName}
                />
            )}
        </div>
    );
};
