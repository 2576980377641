import { firestore, ORG_COLL, USERS_COLL } from "_firebase";
import moment from "moment";
import uniqid from "uniqid";
import { isEmpty, orderBy } from "lodash";

import { DashboardAPI } from "utils/api";

import { getUpgradeEnvelopeRowLabels, envelopeRowHeadings } from "utils/upgrades";

import {
    saveUpdateUpgradePackageStart,
    saveUpdateUpgradePackageError,
    saveUpdateUpgradePackageSuccess,
    fetchUpgradeLabelsStart,
    fetchUpgradeLabelsSuccess,
    fetchUpgradeLabelsError,
    fetchUpgradePackageStart,
    fetchUpgradePackageSuccess,
    fetchUpgradePackageError,
    deleteUpgradePackageStart,
    deleteUpgradePackageSuccess,
    deleteUpgradePackageError,
    changeUpgradePackageNameStart,
    changeUpgradePackageNameSuccess,
    changeUpgradePackageNameError,
    duplicateUpgradePackageStart,
    duplicateUpgradePackageSuccess,
    duplicateUpgradePackageError,
} from "./actions";

export const saveUpdateUpgradePackage =
    ({
        uid,
        upgradePackage,
        upgradePackageId,
        saveAsNew = false,
        codes,
        withCodes = true,
        isCodeLibrary = false,
        saveIntoOrganization = false,
        organizationId = null,
    }) =>
    async (dispatch) => {
        try {
            dispatch(saveUpdateUpgradePackageStart());

            // console.log("INSIDE SAVE", organizationId, upgradePackageId);

            const docPath =
                saveIntoOrganization && organizationId ? `${ORG_COLL}/${organizationId}` : `${USERS_COLL}/${uid}`;

            const upgradeDoc = await firestore.doc(docPath).collection("upgradesLib").doc(upgradePackageId).get();

            let allUpgradeIds = [];

            envelopeRowHeadings.map((rowObj) => {
                //Note that because of the way getUpgradeEnvelopeRowLabels returns upgradeIds,
                //we are allowed one regular window and one skylight upgrade
                const { upgradeIds } = getUpgradeEnvelopeRowLabels({
                    components: {},
                    codes: {},
                    airtightness: {},
                    rowId: rowObj.id,
                    upgradePackage: {
                        [upgradePackageId]: upgradePackage,
                    },
                });
                const hasRepeatedPackages = upgradeIds ? upgradeIds.length > 1 : false;

                if (hasRepeatedPackages) {
                    const sameUpgradesSorted = upgradeIds
                        .map((id) => ({ ...upgradePackage.upgrades[id], id }))
                        .sort((a, b) => moment(a.created).valueOf() - moment(b.created).valueOf());

                    const packagesToDelete = sameUpgradesSorted.filter((_, index) => index !== 0).map((pkg) => pkg.id);

                    allUpgradeIds = [...allUpgradeIds, ...packagesToDelete];
                }
            });

            // console.log(Object.keys(upgradePackage.upgrades).length);

            for (let i = 0; i < allUpgradeIds.length; i++) {
                // console.log(upgradePackage.upgrades[allUpgradeIds[i]]);
                delete upgradePackage.upgrades[allUpgradeIds[i]];
            }

            // console.log(upgradePackage.upgrades);

            if (withCodes && upgradePackage.upgrades) {
                Object.keys(upgradePackage.upgrades).map((upgradePgk) => {
                    const fields = upgradePackage.upgrades[upgradePgk]?.fields || {};

                    if (!isEmpty(fields)) {
                        const checkIfWallAndFloor = new RegExp(Object.keys(fields).join("|")).test(/wall|floor/);

                        const codeReferences = checkIfWallAndFloor
                            ? Object.keys(fields)
                                  .map((key) =>
                                      Object.keys(fields[key])
                                          .filter((k) => fields[key][k].codeRef !== undefined)
                                          .map((k) => fields[key][k].codeRef)
                                  )
                                  .flat()
                            : Object.keys(fields)
                                  .filter((key) => fields[key].codeRef !== undefined)
                                  .map((key) => fields[key].codeRef);

                        const codesToAdd = Object.assign(
                            {},
                            ...codeReferences
                                .filter((ref) => codes[ref] !== undefined)
                                .map((ref) => ({ [ref]: codes[ref] }))
                        );

                        upgradePackage.upgrades[upgradePgk].codes = codesToAdd || {};
                    }
                });
            }

            if (upgradeDoc.exists) {
                await firestore
                    .doc(docPath)
                    .collection("upgradesLib")
                    .doc(upgradePackageId)
                    .update({
                        ...upgradePackage,
                        lastEdited: moment().format("LLLL"),
                    });
            }

            if (!upgradeDoc.exists) {
                if (saveAsNew) {
                    upgradePackage.created = moment().format("LLLL");
                    upgradePackage.name = `${upgradePackage.name} - (copy)`;

                    Object.keys(upgradePackage.upgrades).map((key) => ({
                        [uniqid("upgrade-")]: upgradePackage.upgrades[key],
                    }));
                }

                await firestore
                    .doc(docPath)
                    .collection("upgradesLib")
                    .doc(upgradePackageId)
                    .set({
                        ...upgradePackage,
                        lastEdited: moment().format("LLLL"),
                    });
            }

            await dispatch(fetchUpgradeLabels(uid, organizationId));

            dispatch(saveUpdateUpgradePackageSuccess(isCodeLibrary ? upgradePackage : null));
        } catch (error) {
            console.log("error", error);
            dispatch(saveUpdateUpgradePackageError(error));
        }
    };

export const fetchUpgradeLabels = (uid, orgId) => async (dispatch) => {
    try {
        dispatch(fetchUpgradeLabelsStart());

        const { data } = await DashboardAPI({
            method: "GET",
            url: "/getUpgradeLibraryMap",
            query: {
                uid,
                orgId,
            },
        });

        dispatch(fetchUpgradeLabelsSuccess(data));
    } catch (error) {
        dispatch(fetchUpgradeLabelsError(error.message));
    }
};

export const fetchUpgradePackage =
    (uid, packageId, withReturn = false, organizationId = null) =>
    async (dispatch) => {
        try {
            dispatch(fetchUpgradePackageStart());

            let upgradePackage;

            if (organizationId) {
                upgradePackage = await firestore
                    .doc(`${ORG_COLL}/${organizationId}`)
                    .collection("upgradesLib")
                    .doc(packageId)
                    .get();
            } else {
                upgradePackage = await firestore
                    .doc(`${USERS_COLL}/${uid}`)
                    .collection("upgradesLib")
                    .doc(packageId)
                    .get();
            }

            if (!upgradePackage.exists) {
                throw new Error("Upgrade package doesn't exist");
            }

            dispatch(
                fetchUpgradePackageSuccess({
                    ...upgradePackage.data(),
                    id: upgradePackage.id,
                })
            );

            if (withReturn) {
                return upgradePackage.data();
            }
        } catch (error) {
            dispatch(fetchUpgradePackageError(error.message));
        }
    };

export const deleteUpgradePackage = (uid, packageId, deleteFromOrg, orgId) => async (dispatch) => {
    try {
        dispatch(deleteUpgradePackageStart());

        await firestore
            .doc(deleteFromOrg ? `${ORG_COLL}/${orgId}` : `${USERS_COLL}/${uid}`)
            .collection("upgradesLib")
            .doc(packageId)
            .delete();

        dispatch(deleteUpgradePackageSuccess());
    } catch (error) {
        dispatch(deleteUpgradePackageError(error.message));
    }
};

export const changeUpgradePackageName = (uid, packageId, newName, saveIntoOrganization, orgId) => async (dispatch) => {
    try {
        dispatch(changeUpgradePackageNameStart());

        await firestore
            .doc(saveIntoOrganization ? `${ORG_COLL}/${orgId}` : `${USERS_COLL}/${uid}`)
            .collection("upgradesLib")
            .doc(packageId)
            .update({ name: newName });

        const updatedPackage = await firestore
            .doc(saveIntoOrganization ? `${ORG_COLL}/${orgId}` : `${USERS_COLL}/${uid}`)
            .collection("upgradesLib")
            .doc(packageId)
            .get();

        await dispatch(fetchUpgradeLabels(uid, orgId));

        dispatch(
            changeUpgradePackageNameSuccess({
                ...updatedPackage.data(),
                id: updatedPackage.id,
            })
        );
    } catch (error) {
        dispatch(changeUpgradePackageNameError(error.message));
    }
};

export const duplicateUpgradePackage = (uid, upgradePackage, saveIntoOrganization, orgId) => async (dispatch) => {
    try {
        dispatch(duplicateUpgradePackageStart());

        await firestore
            .doc(saveIntoOrganization ? `${ORG_COLL}/${orgId}` : `${USERS_COLL}/${uid}`)
            .collection("upgradesLib")
            .doc(uniqid("package-"))
            .set({
                ...upgradePackage,
                name: `${upgradePackage.name} - Copy`,
                lastEdited: moment().format("LLLL"),
                created: moment().format("LLLL"),
            });

        await dispatch(fetchUpgradeLabels(uid, orgId));

        dispatch(duplicateUpgradePackageSuccess());
    } catch (error) {
        dispatch(duplicateUpgradePackageError(error.message));
    }
};
