export const energyConversion = {
    ngGJperm3: 0.03726, //h2k's conversion factor (HHV) [GJ/m3]
    elecGJperkWh: 0.0036, //h2k and expected conversion factor [GJ/kWh]
    propaneGJperL: 0.0256, // h2k factor [GJ/L]
    oilGJperL: 0.0385, //h2k factor [GJ/L]
    woodPelletsGJperkg: 0.0198, //(H2k factor, aligns with NRCan Solid Biofuels Bulletin No. 2) [GJ/kg]
    softwoodGJperkg: 0.0107, //(H2k factor, aligns with NRCan Solid Biofuels Bulletin No. 2) [GJ/kg]
    hardwoodsGJperkg: 0.0179, //(H2k factor, aligns with NRCan Solid Biofuels Bulletin No. 2) [GJ/kg]
    mixedWoodGJperkg: 0.0139, //(H2k factor, aligns with NRCan Solid Biofuels Bulletin No. 2) [GJ/kg]
    woodGJperCord: 18.3, //From HTAP [GJ/cord]
};
