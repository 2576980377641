import React, { useState } from 'react';
import classes from './style.module.scss';
import { ReactComponent as Check } from "assets/images/icons/Check.svg";

export default ({
    name,
    id,
    label,
    text,
    handleUpload,
    accept='',
    disabled=false
}) => {
    const [fileNames, changeFileNames] = useState([]);
    const [importState, updateState] = useState('');
    const [fileValue, updateFileValue] = useState('');

    const handleInputChange = (e) => {
        updateState('importing');
        updateFileValue(e.target.value);

        const newFileNames = [];

        Array.from(e.target.files).map((file, index) => {
            const fileName = file.name || '';
            newFileNames.push(fileName);
        });    
        
        changeFileNames(newFileNames);
        updateState('success');

        handleUpload(e.target.files);
        return e.target.files;
    }

    const getLabel = () => {
        if (importState === 'success') {
            return 'Change Files';
        }
        if (importState === 'importing') {
            return 'Processing...';
        }
        return label;
    };

    const handleClick = () => {
        updateFileValue('');
        changeFileNames([]);
    }

    return (
        <div className={`${classes.inputWrapper} ${disabled && classes.disabled}`}>
            <div className={classes.text}>
                <p>{text}</p>
                <input
                    value={fileValue}
                    multiple
                    type="file"
                    name={name}
                    id={id}
                    onClick={handleClick}
                    onChange={handleInputChange}
                    accept={accept}
                />
                <label htmlFor={id}>{getLabel()}</label>
            </div>
            {fileNames.length > 0 && (
                <div className={classes.fileListWrapper}>
                    {fileNames.map((name, index) => (
                        <p key={`file-${index}`} className={classes.fileName}><Check /> {name}</p>
                    ))}
                </div>
            )}
        </div>
    )
}