import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import Wall from './';
import { getBaseUnits, unitLabels } from 'utils/fields';
import convertUnit from 'utils/convertUnit';

const mapStateToProps = (
    {
        form
    },
    {
        accessor
    }
) => {
    const selector = formValueSelector('model');
    const wallHeight = selector({form}, `${accessor}.measurements.height`) || {};
    const wallPerimeter = selector({form}, `${accessor}.measurements.perimeter`) || {};
    const wallArea = selector({form}, `${accessor}.measurements.area`) || {};
    const { codeLabel: wallLabel } = selector({form}, `${accessor}.wallInsType`) || {};
    const wallNomRVal = selector({form}, `${accessor}.wallInsType_nomRVal`) || 0;
    const wallEffRVal = selector({form}, `${accessor}.wallInsType_effRVal`) || 0;

    const rValUnits = selector({form}, `${accessor}.wallInsType_u`);
    const heightUnits = selector({form}, `${accessor}.measurements.height_u`);
    const perimeterUnits = selector({form}, `${accessor}.measurements.perimeter_u`);
    const areaUnits = selector({form}, `${accessor}.measurements.area_u`);
    const modelUnits = selector({form}, `modelData.uiSettings.primaryUnits`);


    const { trueBase:rValBase, displayBase:rValDisplay } = getBaseUnits('wallInsType', modelUnits);
    const { trueBase:heightBase, displayBase:heightDisplay } = getBaseUnits('wallHeight', modelUnits);
    const { trueBase:perimeterBase, displayBase:perimeterDisplay } = getBaseUnits('wallPerimeter', modelUnits);
    const { trueBase:areaBase, displayBase:areaDisplay } = getBaseUnits('wallArea', modelUnits);

    const rValU = rValUnits || rValDisplay || rValBase;
    const heightU = heightUnits || heightDisplay || heightBase;
    const perimeterU = perimeterUnits || perimeterDisplay || perimeterBase;
    const areaU = areaUnits || areaDisplay || areaBase;
    
    return {
        codeLabel:wallLabel,
        rValue:`${parseFloat(convertUnit({
            value:wallLabel === 'User Specified' ? wallEffRVal : wallNomRVal,
            type:'thermalResistance',
            inputUnit:rValBase,
            outputUnit:rValU,
        })).toFixed(1)}${rValU}`,
        height:`${parseFloat(convertUnit({
            value:wallHeight,
            type:'length',
            inputUnit:heightBase,
            outputUnit:heightU,
        })).toFixed(1)}${heightU}`,
        perimeter:`${parseFloat(convertUnit({
            value:wallPerimeter,
            type:'length',
            inputUnit:perimeterBase,
            outputUnit:perimeterU,
        })).toFixed(1)}${perimeterU}`,
        area:`${parseFloat(convertUnit({
            value:wallArea,
            type:'area',
            inputUnit:areaBase,
            outputUnit:areaU,
        })).toFixed(1)}${unitLabels(areaU)}`,
    }
};

const mapDispatchToProps = () => ({});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Wall);