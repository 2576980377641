import React from 'react';
import classes from './style.module.scss';
import AddIcon from 'assets/images/icons/JSX/Add';

export default ({
    onClick,
    title=""
}) => (
    <div onClick={onClick} className={classes.addBox}>
        <AddIcon /> <p>{title}</p>
    </div>
);