import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import isEmpty from 'lodash/isEmpty';
import AddSystem from './';

const mapStateToProps = (
    {
        form
    },
    {
        accessor,
        formName="model"
    }
) => {
    const selector = formValueSelector(formName);
    const modelSelector = formValueSelector('model');

    const modelUnits = modelSelector({form}, `modelData.uiSettings.primaryUnits`) || {};

    return {
        accessor,
        modelUnits
    }
};

const mapDispatchToProps = () => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddSystem);