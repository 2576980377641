import React, { useEffect, useCallback, useState, useRef } from "react";
import { Stage, Layer, Circle } from "react-konva";
import { isEmpty } from "lodash";

// import { firestore, MODEL_COLL } from "_firebase";

import {
    checkForSnap,
    generateInfoSquare,
    removeInfoSquares,
    newMeasure,
    continueNewMeasure,
    handleMeasureChange,
    newPolygon,
    continuePolygon,
    editPolygonPoints,
    handlePolygonChange,
    handleRectangleChange,
    newRectangle,
    newLine,
    continueNewLine,
    handleLineChange,
    newMultiPoint,
    continueMultiPoint,
    editMultiPoints,
    handleMultiPointLineChange,
    initImages,
    deactivateComponent,
    activateComponent,
    initShapes,
} from "../drawingHelpers";

import SnackbarAlert from "components/Alert";
import Loading from "components/Loading";

import { getCursorPosition, handleAnchorDrag } from "utils/drawing/shapes";

import { canvasContainer, loadingImages, loading } from "./style.module.scss";

// const getRandomColor = () => {
//     const letters = "0123456789ABCDEF";
//     let color = "#";
//     for (let i = 0; i < 6; i++) {
//         color += letters[Math.floor(Math.random() * 16)];
//     }
//     return color;
// };

const Canvas = React.memo(
    ({
        stage,
        layer,
        canvas,
        stageScale = 1,
        images,
        toggleImageProcessing,
        imageData,
        updateStage,
        setMessage,
        message: { message, type, anchorOrigin, autoHideDuration, isOpen: isMessageOpen, direction },
        setActiveTool,
        setAction,
        activeTool,
        setScale,
        activeImageIndex,
        processingImages,
        primaryUnits,
        setActiveComponent,
        activeComponent,
        setPolygon,
        setRectangle,
        setLine,
        setMultiPointLine,
        removePolygon,
        removeRectangle,
        removeMultiPoint,
        removeLine,
        modelId,
        uid,
        cursorLayer,
        saving,
        isNewSnapshot,
        canUpdate,
        setCanUpdate,
    }) => {
        // const cursorPositionRef = useRef({ x: 0, y: 0 });

        const didMount = useRef(false);

        const [currentPageIndex, setCurrentPageIndex] = useState(activeImageIndex);
        const [stageScaleLocal, setStageScaleLocal] = useState({
            scale: 1,
            coords: { x: 0, y: 0 },
        });
        const [isShiftPressed, setIsShiftPressed] = useState(false);
        const [isSnapMode, setIsSnapMode] = useState(false);
        const [snapLines, setSnapLines] = useState([]);
        const [snapPoint, setSnapPoint] = useState(null);
        const [showInfo, setShowInfo] = useState(false);
        // const [cursors, setCursors] = useState({});
        // const [color, setColor] = useState(getRandomColor());

        // const debounce = (func, delay) => {
        //     let timeoutId;
        //     return (...args) => {
        //         if (timeoutId) clearTimeout(timeoutId);
        //         timeoutId = setTimeout(() => func(...args), delay);
        //     };
        // };

        const setDefaultMessage = () => {
            setMessage({
                message: "This page is missing its scale. Click on the image to start setting the scale.",
                type: "warning",
                anchorOrigin: { vertical: "bottom", horizontal: "center" },
                autoHideDuration: null,
                isOpen: true,
                direction: "up",
            });
        };
        // useEffect(() => {
        //     const userRef = firestore
        //         .doc(`${MODEL_COLL}/${modelId}`)
        //         .collection("drawingData")
        //         .doc("data")
        //         .collection("cursors")
        //         .doc(uid);

        //     // Listen for cursor updates
        //     const unsubscribeCursors = firestore
        //         .doc(`${MODEL_COLL}/${modelId}`)
        //         .collection("drawingData")
        //         .doc("data")
        //         .collection("cursors")
        //         .onSnapshot((snapshot) => {
        //             const newCursors = {};
        //             snapshot.docs.forEach((doc) => {
        //                 newCursors[doc.id] = doc.data();
        //             });

        //             setCursors(newCursors);
        //         });

        //     // Update cursor presence periodically
        //     const updatePresence = () => {
        //         userRef.set(
        //             {
        //                 x: cursorPositionRef.current.x,
        //                 y: cursorPositionRef.current.y,
        //                 timestamp: Date.now(),
        //             },
        //             { merge: true }
        //         );
        //     };

        //     debounce(updatePresence, 500);

        //     // Cleanup function to run when component unmounts
        //     return () => {
        //         // clearInterval(heartbeatInterval);

        //         // Remove the user's cursor from Firestore
        //         userRef.delete().catch((error) => {
        //             console.error("Error removing cursor: ", error);
        //         });

        //         // Unsubscribe from cursor updates
        //         unsubscribeCursors();
        //     };
        // }, [uid]);

        // console.log(Object.keys(cursors).length);

        /* ----------------- Stage ----------------- */

        const handleWheel = (e) => {
            e.evt.preventDefault();

            const { scale } = stageScaleLocal;

            const scaleBy = 1.02;
            const stageref = e.target.getStage();
            const oldScale = scale;
            const mousePointTo = {
                x: stageref.getPointerPosition().x / oldScale - stageref.x() / oldScale,
                y: stageref.getPointerPosition().y / oldScale - stageref.y() / oldScale,
            };

            const newScale =
                e.evt.deltaY > 0 ? (oldScale > 0.1 ? oldScale / scaleBy : 0.1) : oldScale < 3 ? oldScale * scaleBy : 3;

            setStageScaleLocal({
                scale: newScale,
                coords: {
                    x: -(mousePointTo.x - stageref.getPointerPosition().x / newScale) * newScale,
                    y: -(mousePointTo.y - stageref.getPointerPosition().y / newScale) * newScale,
                },
            });

            updateStage({ scale: newScale });
        };

        const handleOverlayClick = useCallback(async (overlay) => {
            const id = overlay.getId();
            const image = id.split("overlay_")[1];

            await setMessage({
                message: "Place the first scale point.",
                type: "info",
                anchorOrigin: { vertical: "bottom", horizontal: "center" },
                autoHideDuration: null,
                isOpen: true,
                direction: "up",
            });

            await setActiveTool("measure");
            setAction({
                id: "setScale",
                meta: {
                    image,
                    cancel: () => {
                        // setActiveTool("");
                        // setActiveComponent("");
                        setAction({ id: null, meta: {} });
                        setDefaultMessage();
                    },
                },
            });

            overlay.moveToBottom();
            stage.current.container().style.cursor = "crosshair";
        }, []);

        const handleStageClick = async (event) => {
            const image = images[activeImageIndex].fileName;

            const targetName = event.target.getAttr("name");
            // const targetId = event.target.getId();
            // const {  page = 0 } = event.target.attrs || {};

            const { scale: currentScale = {} } = imageData[image] || {};
            const { x, y } = getCursorPosition(event);

            if (targetName === "overlay") {
                handleOverlayClick(event.target);
            }

            if (!isShiftPressed)
                deactivateComponent({
                    activeComponent,
                    setAction,
                    setActiveComponent,
                    stage,
                    activeTool,
                    imageData: imageData[image],
                });

            if (
                ["rectangle", "polygon", "line", "measure", "multiPointLine"].includes(targetName) &&
                !activeTool &&
                !isShiftPressed
            ) {
                activateComponent({
                    shape: event.target,
                    setAction,
                    setActiveComponent,
                    setActiveTool,
                    removeLine,
                    removeMultiPoint,
                    removePolygon,
                    removeRectangle,
                    layer,
                    stage,
                });
            }

            if (
                activeTool &&
                activeTool !== "polygon" &&
                activeTool !== "line" &&
                activeTool !== "multiPointLine" &&
                currentScale.isSet
            ) {
                setActiveTool("");
            }

            if (!activeTool && activeComponent.includes("polygon") && isShiftPressed) {
                editPolygonPoints({
                    image,
                    x: isSnapMode ? snapPoint.x : x,
                    y: isSnapMode ? snapPoint.y : y,
                    isAnchor: targetName === "anchor",
                    attrs: event.target.attrs,
                    activeComponent,
                    imageData,
                    setPolygon,
                    layer,
                    stage,
                });
            }

            if (!activeTool && activeComponent.includes("multiPointLine") && isShiftPressed) {
                editMultiPoints({
                    image,
                    x: isSnapMode ? snapPoint.x : x,
                    y: isSnapMode ? snapPoint.y : y,
                    isAnchor: targetName === "anchor",
                    attrs: event.target.attrs,
                    activeComponent,
                    imageData,
                    setMultiPointLine,
                    layer,
                    stage,
                });
            }

            if (activeTool && activeComponent.includes("multiPointLine") && isShiftPressed) {
                editMultiPoints({
                    image,
                    x: isSnapMode ? snapPoint.x : x,
                    y: isSnapMode ? snapPoint.y : y,
                    isAnchor: targetName === "anchor",
                    attrs: event.target.attrs,
                    activeComponent,
                    imageData,
                    setMultiPointLine,
                    layer,
                    stage,
                });

                return;
            }

            if (activeTool && activeComponent.includes("polygon") && isShiftPressed) {
                editPolygonPoints({
                    image,
                    x: isSnapMode ? snapPoint.x : x,
                    y: isSnapMode ? snapPoint.y : y,
                    isAnchor: targetName === "anchor",
                    attrs: event.target.attrs,
                    activeComponent,
                    imageData,
                    setPolygon,
                    layer,
                    stage,
                });

                return;
            }

            //Initianl scale set with default values
            if (activeTool === "measure") {
                if (isEmpty(currentScale)) {
                    return newMeasure({
                        image,
                        x,
                        y,
                        activeTool,
                        stage,
                        layer,
                        setAction,
                        setMessage,
                        setScale,
                        primaryUnits,
                        isSnapMode,
                        snapPoint,
                        setActiveComponent,
                        setDefaultMessage,
                    });
                }

                if (currentScale.points.length === 2) {
                    continueNewMeasure({
                        image,
                        x,
                        y,
                        imageData,
                        isSnapMode,
                        snapPoint,
                        activeComponent,
                        setAction,
                        setActiveComponent,
                        setActiveTool,
                        setDefaultMessage,
                        setMessage,
                        setScale,
                        stage,
                        primaryUnits,
                        layer,
                    });

                    stage.current.container().style.cursor = "default";
                }
            }

            if (activeTool === "polygon" && !activeComponent) {
                newPolygon({
                    image,
                    x,
                    y,
                    imageData,
                    activeTool,
                    isSnapMode,
                    snapPoint,
                    stage,
                    layer,
                    setActiveComponent,
                    setAction,
                    setPolygon,
                    removePolygon,
                    setActiveTool,
                });
            }

            if (activeTool === "polygon" && activeComponent) {
                continuePolygon({
                    image,
                    x,
                    y,
                    imageData,
                    isSnapMode,
                    setAction,
                    setActiveTool,
                    setPolygon,
                    snapPoint,
                    stage,
                    layer,
                    removePolygon,
                    deactivateComponent,
                    activeComponent,
                    setActiveComponent,
                });
            }

            if (activeTool === "rectangle" && !activeComponent) {
                newRectangle({
                    image,
                    x,
                    y,
                    imageData,
                    activeTool,
                    isSnapMode,
                    snapPoint,
                    stage,
                    layer,
                    setActiveComponent,
                    setAction,
                    setRectangle,
                    removeRectangle,
                });
            }

            if (activeTool === "line" && !activeComponent) {
                newLine({
                    image,
                    x,
                    y,
                    setAction,
                    setActiveComponent,
                    setActiveTool,
                    setLine,
                    snapPoint,
                    stage,
                    layer,
                    isSnapMode,
                    imageData,
                    activeTool,
                    removeLine,
                });
            }

            if (activeTool === "line" && activeComponent) {
                continueNewLine({
                    image,
                    x,
                    y,
                    imageData,
                    setAction,
                    setActiveTool,
                    setLine,
                    snapPoint,
                    stage,
                    layer,
                    activeTool,
                    activeComponent,
                    removeLine,
                    setActiveComponent,
                });
            }

            if (activeTool === "multiPointLine" && !activeComponent) {
                newMultiPoint({
                    image,
                    x,
                    y,
                    imageData,
                    activeTool,
                    isSnapMode,
                    snapPoint,
                    stage,
                    layer,
                    setActiveComponent,
                    setAction,
                    setMultiPointLine,
                    removeMultiPoint,
                    setActiveTool,
                });
            }

            if (activeTool === "multiPointLine" && activeComponent) {
                continueMultiPoint({
                    image,
                    x,
                    y,
                    imageData,
                    setAction,
                    setActiveTool,
                    setMultiPointLine,
                    snapPoint,
                    stage,
                    layer,
                    activeComponent,
                    setActiveComponent,
                    removeMultiPoint,
                });
            }

            stage.current.draw();
        };

        const onDragMove = (event) => {
            // stage.current.container().style.cursor = "grabbing";

            const eventTarget = event.target;

            const targetId = eventTarget.getAttr("id");
            const targetName = eventTarget.getAttr("name");
            const targetClassName = event.target.getClassName();
            const targetShapeType = eventTarget.getAttr("shapeType");
            const targetShapeId = eventTarget.getAttr("shapeId");

            const shape =
                targetName === "anchor"
                    ? stage.current.findOne(`#${targetShapeId}`)
                    : stage.current.findOne(`#${targetId}`);

            if (targetName === "anchor") {
                if (isSnapMode) {
                    const { x, y } = getCursorPosition(event);

                    checkForSnap({ x, y }, snapLines, setSnapPoint);
                }

                handleAnchorDrag({
                    shapeType: targetShapeType,
                    shape,
                    anchor: eventTarget,
                    isSnapMode,
                    snapX: snapPoint?.x,
                    snapY: snapPoint?.y,
                });

                stage.current.container().style.cursor = "move";
                return;
            }

            if (activeTool) {
                stage.current.container().style.cursor = "crosshair";
            }

            if (targetClassName === "Stage" && !activeTool) {
                stage.current.container().style.cursor = "grabbing";
            }

            if (
                ["Rect", "Line", "Group"].includes(targetClassName) &&
                targetName !== "drawing" &&
                !activeTool &&
                targetShapeId !== activeComponent
            ) {
                stage.current.container().style.cursor = "grabbing";
            }

            if (targetName === "drawing" && !activeTool) {
                stage.current.container().style.cursor = "default";
            }
        };

        const stageDragMoveEnd = (event) => {
            const eventTarget = event.target;
            const image = images[activeImageIndex].fileName;

            const targetClass = eventTarget.getClassName();
            const shape =
                targetClass === "Group"
                    ? eventTarget.findOne("Line")
                    : eventTarget.name() === "anchor"
                    ? stage.current.findOne(`#${eventTarget.getAttr("shapeId")}`)
                    : eventTarget;
            const shapeName = shape && shape.getAttr("name");

            const { scale: currentScale = {} } = imageData[image] || {};

            if (shapeName === "rectangle") {
                const shapeId = shape.getAttr("id");
                const x = shape.getAttr("x");
                const y = shape.getAttr("y");

                const { rectangles } = imageData[image];
                const rectangle = rectangles[shapeId];

                handleRectangleChange({
                    ...rectangle,
                    id: shapeId,
                    image,
                    x,
                    y,
                    shape,
                    setRectangle,
                });
            }

            if (eventTarget.name() === "anchor") {
                const shapeType = eventTarget.getAttr("shapeType");
                const shapeId = eventTarget.getAttr("shapeId");
                const group = stage.current.findOne(`#group_${shapeId}`);
                const shapePoints = shape.points();
                const shapeX = group.x();
                const shapeY = group.y();

                if (shapeType === "measure") {
                    handleMeasureChange({ shapePoints, currentScale, image, shapeX, shapeY, imageData, setScale });
                }

                if (shapeType === "polygon") {
                    handlePolygonChange({
                        points: shapePoints,
                        id: shapeId,
                        image,
                        stage: stage.current,
                        x: shapeX,
                        y: shapeY,
                        setPolygon,
                        imageData,
                    });
                }

                if (shapeType === "multiPointLine") {
                    handleMultiPointLineChange({
                        points: shapePoints,
                        id: shapeId,
                        image,
                        stage: stage.current,
                        x: shapeX,
                        y: shapeY,
                        imageData,
                        setMultiPointLine,
                    });
                }

                if (shapeName === "line") {
                    handleLineChange({
                        points: shapePoints,
                        id: shapeId,
                        image,
                        x: shapeX,
                        y: shapeY,
                        imageData,
                        setLine,
                    });
                }
                stage.current.container().style.cursor = "default";
            }

            if (targetClass === "Group") {
                const shapeId = shape.getAttr("id");
                const x = eventTarget.x();
                const y = eventTarget.y();
                const points = shape.points();

                if (shapeName === "polygon") {
                    handlePolygonChange({
                        points,
                        id: shapeId,
                        image,
                        x,
                        y,
                        imageData,
                        setPolygon,
                    });
                }

                if (shapeName === "measure") {
                    setScale({
                        image: image,
                        scale: { ...currentScale, points, x, y },
                    });
                }

                if (shapeName === "multiPointLine") {
                    handleMultiPointLineChange({
                        points,
                        id: shapeId,
                        image,
                        x,
                        y,
                        imageData,
                        setMultiPointLine,
                    });
                }

                if (shapeName === "line") {
                    handleLineChange({
                        points,
                        id: shapeId,
                        image,
                        x,
                        y,
                        imageData,
                        setLine,
                    });
                }

                stage.current.container().style.cursor = "default";
            }

            if (targetClass === "Stage" && !activeTool) {
                stage.current.container().style.cursor = "default";
            }
        };

        const onMouseMove = (event) => {
            const eventTarget = event.target;

            const targetId = eventTarget.getAttr("id");
            const targetName = eventTarget.getAttr("name");
            const targetClassName = event.target.getClassName();

            // const position = getCursorPosition(event);
            // const { x, y } = position;

            // cursorPositionRef.current = { x, y }; // Update last known position

            // Update local state
            // setCursors((prevCursors) => ({
            //     ...prevCursors,
            //     [uid]: {
            //         ...prevCursors[uid],
            //         x,
            //         y,
            //     },
            // }));

            // firestore
            //     .doc(`${MODEL_COLL}/${modelId}`)
            //     .collection("drawingData")
            //     .doc("data")
            //     .collection("cursors")
            //     .doc(uid)
            //     .set(
            //         {
            //             x,
            //             y,
            //             timestamp: Date.now(),
            //             color,
            //         },
            //         { merge: true }
            //     );

            if ((activeComponent.includes("polygon") || activeComponent.includes("multiPointLine")) && isShiftPressed) {
                if (isSnapMode) {
                    const { x, y } = getCursorPosition(event);

                    checkForSnap({ x, y }, snapLines, setSnapPoint);
                }

                stage.current.container().style.cursor = "crosshair";
                return;
            }

            if (activeTool) {
                stage.current.container().style.cursor = "crosshair";
            }

            if (!activeTool) {
                stage.current.container().style.cursor = "default";
            }

            if (
                ["Rect", "Line", "Group"].includes(targetClassName) &&
                targetName !== "drawing" &&
                !activeTool &&
                targetId !== activeComponent
            ) {
                stage.current.container().style.cursor = "pointer";
            }

            if (targetName === "anchor") {
                stage.current.container().style.cursor = "move";
            }

            if (isSnapMode) {
                const { x, y } = getCursorPosition(event);

                checkForSnap({ x, y }, snapLines, setSnapPoint);
            }
        };

        const buttonDownHandler = (e) => {
            const { key } = e;

            if (e.shiftKey) {
                setIsShiftPressed(true);

                if (activeComponent.includes("polygon") || activeComponent.includes("multiPointLine")) {
                    stage.current.container().style.cursor = "crosshair";
                }
            }

            if (key.toLowerCase() === "s") {
                setIsSnapMode(true);
            }

            if (key === "i") {
                setShowInfo(true);
            }
        };

        const buttonUpHandler = ({ key }) => {
            if (key === "Shift") {
                setIsShiftPressed(false);

                if (activeComponent.includes("polygon") || activeComponent.includes("multiPointLine")) {
                    stage.current.container().style.cursor = "default";
                }
            }

            if (key.toLowerCase() === "s") {
                setIsSnapMode(false);
            }

            if (key === "i") {
                setShowInfo(false);
            }
        };

        const updateSize = () =>
            updateStage({
                size: {
                    width: window.innerWidth - 512,
                    height: window.innerHeight - 73,
                },
            });

        useEffect(() => {
            setStageScaleLocal({
                ...stageScaleLocal,
                scale: stage.scale,
            });
        }, [stage.scale]);

        useEffect(() => {
            if (images.length > 0) {
                initImages({
                    images,
                    stage,
                    layer,
                    setCurrentPageIndex,
                    toggleImageProcessing,
                    imageData,
                    updateStage,
                    setMessage,
                    setSnapLines,
                    stageScale,
                    activeImageIndex,
                    setRectangle,
                    cursorLayer,
                });
                updateStage({ scale: 1 });
                setStageScaleLocal({ scale: 1, coords: { x: 0, y: 0 } });
            }
        }, [activeImageIndex]);

        useEffect(() => {
            updateSize();

            if (!isSnapMode) {
                setSnapPoint(null);
            }

            window.addEventListener("resize", updateSize);
            window.addEventListener("keydown", buttonDownHandler);
            window.addEventListener("keyup", buttonUpHandler);

            return () => {
                window.removeEventListener("keydown", buttonDownHandler);
                window.removeEventListener("keyup", buttonUpHandler);
                window.removeEventListener("resize", updateSize);
            };
        }, []);

        useEffect(() => {
            if (!isSnapMode) {
                setSnapPoint(null);
            }
        }, [isSnapMode]);

        useEffect(() => {
            if (didMount.current) {
                if (showInfo) {
                    const allShapes = imageData[images[activeImageIndex].fileName];

                    if (stage.current && layer.current) generateInfoSquare(stage, layer, allShapes);
                } else {
                    removeInfoSquares(stage, layer);
                }
            } else {
                didMount.current = true;
            }
        }, [showInfo]);

        useEffect(() => {
            if (canUpdate && !saving) {
                initImages({
                    images,
                    stage,
                    layer,
                    setCurrentPageIndex,
                    toggleImageProcessing,
                    imageData,
                    updateStage,
                    setMessage,
                    setSnapLines,
                    stageScale,
                    activeImageIndex,
                    setRectangle,
                    cursorLayer,
                    isFromSaving: true,
                });

                setCanUpdate(false);
            }
        }, [canUpdate, saving]);

        // const allShapesArray = Object.keys(allShapes).map((key) => allShapes[key]);

        return (
            <div className={canvasContainer} ref={canvas}>
                <SnackbarAlert
                    open={isMessageOpen}
                    severity={type}
                    direction={direction}
                    onClose={() =>
                        setMessage({
                            message,
                            type,
                            anchorOrigin,
                            autoHideDuration,
                            isOpen: false,
                        })
                    }
                    onExited={() =>
                        setMessage({
                            message: "",
                            type: "",
                            isOpen: false,
                        })
                    }
                    message={message}
                    anchorOrigin={anchorOrigin}
                    autoHideDuration={autoHideDuration}
                />

                <SnackbarAlert
                    open={isNewSnapshot}
                    message={
                        "Changes to the model have been detected. Save your model to merge changes or refresh to discard changes."
                    }
                    anchorOrigin={{ vertical: "top", horizontal: "top-center" }}
                    direction="down"
                    severity="error"
                    onExited={() => {}}
                    top={"60px"}
                />

                {processingImages && (
                    <div className={loadingImages}>
                        <Loading className={loading} />
                        <p>Loading Images...</p>
                    </div>
                )}
                <Stage
                    ref={stage}
                    width={stage.size.width}
                    height={stage.size.height}
                    scaleX={stageScaleLocal.scale}
                    scaleY={stageScaleLocal.scale}
                    onWheel={handleWheel}
                    x={stageScaleLocal?.coords?.x}
                    y={stageScaleLocal?.coords?.y}
                    onClick={handleStageClick}
                    onDragEnd={stageDragMoveEnd}
                    onDragMove={onDragMove}
                    onMouseMove={onMouseMove}
                    draggable
                >
                    <Layer ref={layer}>
                        {snapPoint && <Circle x={snapPoint.x} y={snapPoint.y} radius={5} fill="blue" />}
                    </Layer>
                    {/* <Layer ref={cursorLayer}>
                        {Object.keys(cursors).map((userId, i) => (
                            <React.Fragment key={i}>
                                <Circle
                                    x={cursors[userId].x}
                                    y={cursors[userId].y}
                                    radius={5}
                                    fill={cursors[userId].color} // Use the random color
                                />
                                <Text
                                    x={cursors[userId].x + 10} // Offset to position the text next to the cursor
                                    y={cursors[userId].y - 10} // Offset to position the text above the cursor
                                    text={userId}
                                    fontSize={12}
                                    fill="#000000"
                                    shadowColor="#ffffff"
                                    shadowBlur={2}
                                    shadowOffsetX={1}
                                    shadowOffsetY={1}
                                />
                            </React.Fragment>
                        ))}
                    </Layer> */}
                </Stage>
            </div>
        );
    }
);

export default Canvas;
