import React from 'react';

export default () => (
    <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="Accordion-/-Icon-/-Window-(Grey)-Copy-4" transform="translate(-2.000000, -2.000000)" fill="#979797" fillRule="nonzero">
            <g id="Group" transform="translate(2.000000, 2.000000)">
                <path d="M20,0 L20,20 L0,20 L0,0 L20,0 Z M9.5,10.5 L1,10.5 L1,19 L9.5,19 L9.5,10.5 Z M19,10.5 L10.5,10.5 L10.5,19 L19,19 L19,10.5 Z M9.5,1 L1,1 L1,9.5 L9.5,9.5 L9.5,1 Z M19,1 L10.5,1 L10.5,9.5 L19,9.5 L19,1 Z" id="WIndow-Simple"></path>
            </g>
        </g>
    </svg>
    )