import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import IndoorAirQuality from "./";

const mapStateToProps = ({ form }, { accessor, formName = "model" }) => {
    const selector = formValueSelector(formName);

    const iaqChecklistContent = selector({ form }, `${accessor}`);

    return {
        accessor,
        formName,
        iaqChecklistContent,
    };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(IndoorAirQuality);
