import React, { useCallback } from 'react';
import classes from 'features/Model/Enclosure/UserDefinedCodes/style.module.scss';
import InputRow from 'components/Input/Row';
import Input from 'components/Input';
import InputWithUnits from 'components/Input/InputWithUnits';
import Select from 'components/Input/Select';
import { Field } from 'redux-form';
import { getOptions, getFirstOption, getBaseUnits, getUnitOptions } from 'utils/fields';
import {  getValidation, getDecimalPlaces } from 'utils/fieldValidation';
import { getContinuousResistivity } from 'utils/enclosure/codeLayers';
import Accordion from 'components/Accordion';
import AccordionActions from "../../AccordionActions";

const layerValidation = getValidation('udefContMedLayer');
const categoryValidation = getValidation('udefContMedMaterialCat');
const typeValidation = getValidation('udefContMedMaterialType');
const resistivityValidation = getValidation('udefContMedResistivity');
const thicknessValidation = getValidation('udefContMedThickness');
const rValueValidation = getValidation('udefContMedRValue');


export default React.memo(({
    change,
    accessor,
    materialCatId,
    materialTypeId,
    resistivityValue,
    resistivityUnits,
    thicknessUnits,
    rValue,
    rValueUnits,
    modelUnits,
    isDragging= false,
    dragHandleProps,
    dragActive= false,
    layer,
    mediumLayers,
    id
}) => {
    const materialTypeOptions = getOptions({fieldKey:'udefContMedMaterialType', indKey: materialCatId}) || [];

    const {
        label=""
    } = materialTypeOptions.find(({ value:{ id=0 }={} }) => id === materialTypeId) || {};

    const { displayBase:baseRValUnits="" } = getBaseUnits('udefContMedRValue', modelUnits);
    const displayRValueUnits = rValueUnits || baseRValUnits;
    const materialLabel = label || `User Defined: ${rValue} ${displayRValueUnits}`;

    const deleteLayer = useCallback(() => {
        const { [id]:_, ...remainingLayers }  = mediumLayers;
        change('uDefCode.layers.continuousMedium', remainingLayers);
    },[id, mediumLayers, change]);


    //ADD USER DEFINED DEPENDENCIES
    return (
        <div>
            <Accordion
                className={classes.accordion}
                heading={
                    <span className={classes.accordionHeader}>
                        <span className={classes.accordionPreTitle}>Continuous Medium</span>
                        <span className={classes.accordionTitle}>{materialLabel}</span>
                    </span>
                }   
                number={layer}
                isDragging={isDragging}
                dragActive={dragActive}
                hoverActions={<AccordionActions dragHandleProps={dragHandleProps} accessor={accessor} deleteLayer={deleteLayer} />}
            >
                <InputRow gridTemplate="1fr">
                    <Field
                        component={Select}
                        name={`${accessor}.materialCategory`}
                        options={getOptions({fieldKey:'udefContMedMaterialCat'})}
                        label="Material Category"
                        placeholder="Choose Material Category"
                        validate={categoryValidation}
                        onChange={({id})=>{
                            if (id === 0) {
                                change(`${accessor}.materialType`, {id:null})
                            } else {
                                change(
                                    `${accessor}.materialType`,
                                    getFirstOption({indKey:id, depKey:'udefContMedMaterialType'})
                                );
                            }
                        }}
                        info={'Includes any continuous layer that is uninterrupted material. This could include sheathing, masonry, air spaces, or interior or exterior materials.'}
                    />
                </InputRow>
                <InputRow gridTemplate="2fr 1fr">
                    <Field
                        component={Select}
                        name={`${accessor}.materialType`}
                        options={materialTypeOptions}
                        disabled={materialCatId === 0}
                        label="Material Type"
                        placeholder="Choose Material Type"
                        validate={typeValidation}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.resistivity`}
                        label="Resistivity"
                        placeholder={0.00}
                        disabled={materialCatId !== 0}
                        validate={resistivityValidation}
                        setTouched={true}
                        decimals={getDecimalPlaces('udefContMedResistivity')}
                        change={change}
                        setValue={materialTypeId == null ? resistivityValue : getContinuousResistivity(materialTypeId)}
                        units={{
                            base:getBaseUnits('udefContMedResistivity', modelUnits),
                            options:getUnitOptions('thermalResistivity'),
                            selected:resistivityUnits,
                            unitType:'thermalResistivity',
                            accessor:`${accessor}.resistivity_u`
                        }}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.thickness`}
                        label="Thickness"
                        placeholder={0.00}
                        validate={thicknessValidation}
                        setTouched={true}
                        decimals={getDecimalPlaces('udefContMedThickness')}
                        change={change}
                        units={{
                            base:getBaseUnits('udefContMedThickness', modelUnits),
                            options:getUnitOptions('length'),
                            selected:thicknessUnits,
                            unitType:'length',
                            accessor:`${accessor}.thickness_u`
                        }}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.rValue`}
                        label="R-Value"
                        placeholder={0.00}
                        disabled={true}
                        setValue={rValue}
                        validate={rValueValidation}
                        setTouched={true}
                        decimals={getDecimalPlaces('udefContMedRValue')}
                        change={change}
                        units={{
                            base:getBaseUnits('udefContMedRValue', modelUnits),
                            options:getUnitOptions('thermalResistance'),
                            selected:rValueUnits,
                            unitType:'thermalResistance',
                            accessor:`${accessor}.rValue_u`
                        }}
                    />
                </InputRow>
            </Accordion>
        </div>
    )
});