import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import Ceiling from "./";
import isEmpty from "lodash/isEmpty";

const mapStateToProps = (
    { form, model: { newestComponents = [], recentDuplicates = [], contentOpen = false } = {} },
    { id, clone, isDragging, draggingType }
) => {
    const accessor = `modelData.components.ceiling.${id}`;

    const selector = formValueSelector("model");
    const label = selector({ form }, `${accessor}.label`) || "";
    const { component: drawingComponent = "" } = selector({ form }, `${accessor}.drawing`) || {};
    const lengthDrawing = selector({ form }, `${accessor}.measurements.length_drawingRef`) || "";

    const drawingLines = [...(lengthDrawing ? [lengthDrawing] : [])];

    const subCompObj = selector({ form }, `${accessor}.subcomponents`) || {};

    const { window = {} } = subCompObj || {};

    const mapSubComponents = () =>
        Object.entries(window)
            .filter(([key, values]) => values && !isEmpty(values))
            .map(([key, values]) => ({
                id: key,
                componentId: key,
                componentType: "window",
                componentLabel: values.label,
                displayOrder: values.displayOrder,
            }))
            .sort((a, b) => {
                if (a.displayOrder !== b.displayOrder) {
                    return a.displayOrder - b.displayOrder;
                }
                if (a.componentLabel !== b.componentLabel && a.componentLabel != null && b.componentLabel !== null) {
                    if (a.componentLabel?.toLowerCase() < b.componentLabel?.toLowerCase()) {
                        return -1;
                    }
                    if (a.componentLabel?.toLowerCase() > b.componentLabel?.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                }
                return 0;
            });

    return {
        componentId: id,
        mapSubComponents: mapSubComponents(),
        accessor,
        label,
        isNew: newestComponents.includes(id),
        isRecentDuplicate: recentDuplicates.includes(id),
        isDrawingComponent: !!drawingComponent,
        drawingLines,
        contentOpen,
        subCompObj,
    };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Ceiling);
