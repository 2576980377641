import React from 'react';
import sharedClasses from '../../style.module.scss';
import Tooltip from 'components/Tooltip';

export default React.memo(({
    height,
    perimeter,
    area,
    codeLabel,
    rValue,
}) => (
    <div className={sharedClasses.stats} style={{gridTemplateColumns:'1.5fr 1fr 1fr 1fr'}}>
        <span
            data-for="floorHeader-stats"
            data-tip={`<span><strong>Type:</strong> ${codeLabel}</span>`}
            data-html
            className={sharedClasses.clipType}
        >
            <strong>Type:</strong> {codeLabel}
        </span>
        <span
            data-for="floorHeader-stats"
            data-tip={`<span><strong>${codeLabel === 'User Specified' ? 'Eff.' : 'Nom.'} R-Value:</strong> ${rValue}</span>`}
            data-html
        >
            <strong>R:</strong> {rValue}
        </span>
        <span
            data-for="floorHeader-stats"
            data-tip={`<span><strong>Perimeter:</strong> ${perimeter}</span>`}
            data-html
        >
            <strong>P:</strong> {perimeter}
        </span>
        <span
            data-for="floorHeader-stats"
            data-tip={`<span><strong>Height:</strong> ${height}</span>`}
            data-html
        >
            <strong>H:</strong> {height}
        </span>
        <Tooltip id="floorHeader-stats" />
    </div>
));