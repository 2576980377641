const CLEAR_UPGRADE_DATA = "CLEAR_UPGRADE_DATA";
const TOGGLE_UPGRADES_LOADING = "TOGGLE_UPGRADES_LOADING";
const SET_UPGRADES_ERROR = "SET_UPGRADES_ERROR";
const UPDATE_UPGRADE_PACKAGE = "UPDATE_UPGRADE_PACKAGE";
const SET_UPGRADE_PACKAGES = "SET_UPGRADE_PACKAGES";
const DELETE_UPGRADE_PACKAGE = "DELETE_UPGRADE_PACKAGE";
const SET_INIT_UPGRADE_PACKAGES = "SET_INIT_UPGRADE_PACKAGES";
const UPDATE_PACKAGE_UPGRADES = "UPDATE_PACKAGE_UPGRADES";
const DELETE_PACKAGE_UPGRADE = "DELETE_PACKAGE_UPGRADE";
const UPDATE_UPGRADE_PACKAGE_ORDER = "UPDATE_UPGRADE_PACKAGE_ORDER";
const UPDATE_UPGRADE_COST = "UPDATE_UPGRADE_COST";
const UPDATE_PACKAGE_OPERATING_CONDITIONS = "UPDATE_PACKAGE_OPERATING_CONDITIONS";
const UPDATE_PACKAGE_BASE_CASE = "UPDATE_PACKAGE_BASE_CASE";
const UPDATE_UPGRADE_SNAPSHOT_START = "UPDATE_UPGRADE_SNAPSHOT_START";
const UPDATE_UPGRADE_SNAPSHOT_SUCCESS = "UPDATE_UPGRADE_SNAPSHOT_SUCCESS";
const UPDATE_UPGRADE_SNAPSHOT_ERROR = "UPDATE_UPGRADE_SNAPSHOT_ERROR";

export default {
    CLEAR_UPGRADE_DATA,
    TOGGLE_UPGRADES_LOADING,
    SET_UPGRADES_ERROR,
    UPDATE_UPGRADE_PACKAGE,
    SET_UPGRADE_PACKAGES,
    SET_INIT_UPGRADE_PACKAGES,
    DELETE_UPGRADE_PACKAGE,
    UPDATE_PACKAGE_UPGRADES,
    DELETE_PACKAGE_UPGRADE,
    UPDATE_UPGRADE_PACKAGE_ORDER,
    UPDATE_UPGRADE_COST,
    UPDATE_PACKAGE_OPERATING_CONDITIONS,
    UPDATE_PACKAGE_BASE_CASE,
    UPDATE_UPGRADE_SNAPSHOT_START,
    UPDATE_UPGRADE_SNAPSHOT_SUCCESS,
    UPDATE_UPGRADE_SNAPSHOT_ERROR,
};
