const subComponentList = [
    {
        type: "exposedfloor",
        name: "Exposed Floor",
    },
    {
        type: "bufferedwall",
        name: "Buffered Wall",
    },
    {
        type: "atticceiling",
        name: "Attic ceiling",
    },
    {
        type: "cathedralceiling",
        name: "Cathedral ceiling",
    },
    {
        type: "slopedceiling",
        name: "Sloped ceiling",
    },
    {
        type: "raisedceiling",
        name: "Raised ceiling",
    },
];

export default subComponentList;
