import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import isEmpty from "lodash/isEmpty";
import DwhrSystem from "./";

const mapStateToProps = ({ form }, { accessor, formName = "model" }) => {
    const selector = formValueSelector(formName);
    const modelSelector = formValueSelector("model");

    const selectedManufacturer = selector({ form }, `${accessor}.manufacturer`);
    const effValue = selector({ form }, `${accessor}.efficiencyRating`);
    const effUnits = selector({ form }, `${accessor}.efficiencyRating_u`);

    const connectedUnits =
        selector({ form }, `${accessor.replace(".drainWaterHeatRecovery", "")}.connectedUnitsDwhr`) || 0;

    const modelUnits = modelSelector({ form }, `modelData.uiSettings.primaryUnits`) || {};

    const { id: buildingTypeId } = modelSelector({ form }, `modelData.specifications.buildingType`) || {};
    const isMURB = buildingTypeId === 2; //Whole building MURB

    return {
        accessor,
        selectedManufacturer,
        effValue,
        effUnits,
        modelUnits,
        isMURB,
        zeroConnectedUnits: connectedUnits === 0,
    };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DwhrSystem);
