import React, { useEffect, useState, useRef, useCallback } from "react";
import { isEmpty } from "lodash";
import { useHistory, useLocation, Redirect } from "react-router";
import ReactDOM from "react-dom";
import classes from "./style.module.scss";
import { createFolder, deleteFolder, renameFolder, moveFolder, batchFolderRemove } from "store/folders/thunk";
import { DashboardTable } from "components/Dashboard/Table";
import { DeleteDialog, DeleteMultipleFilesDialog, DuplicateDialog, RenameDialog, SelectFolder } from "components/Dashboard/Actions";
import NestedPath from "components/Dashboard/NestedPath";
import ComponentListImage from "components/Draggable/DraggableImage/ComponentListImage";
import Loading from "components/Loading";
import classNames from "classnames";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserError, selectUserUid } from "store/users/selectors";

const DashboardLayout = ({
    uid,
    error,
    dashboardType,
    draggable = true,
    fetchDir,       // fetchUserDir, fetchUserCommDir
    dir,            // modelDir, commDir
    dirLoading,     //modelDirLoading, commDirLoading    
    fetchUserOrganization,
    organization,
    rootDir,        // Model Directory, Communities
    rootPath,       // /dashboard, /communities/dashboard, /communities/{commID}
    allFoldersItems,
    itemType,       // model, community
    selectItem,     // selectModel, selectModel
    duplicateItem,  // duplicateModel, duplicateCommunity
    itemLoading,    // modelLoading, communityLoading
    deleteItem,     // deleteModel, deleteCommunity
    moveItem,       // moveModel, moveCommunity
    createFolder,
    folderLoading,
    deleteFolder,
    renameFolder,
    moveFolder,
    batchFolderRemove,
    batchDeleteItems,
    emptyFolderDialog,
    children,
    className,
}) => {
    const { pathname } = useLocation();
    const history = useHistory();

    /* Actions */
    const [deleting, toggleDeleting] = useState(false);
    const [duplicateLoading, toggleDuplicateLoading] = useState(false);
    const [itemToDelete, setItemToDelete] = useState("");
    const [itemToMove, setItemToMove] = useState("");
    const [itemToDuplicate, setItemToDuplicate] = useState("");
    const [duplicateName, setDuplicateName] = useState("");
    const [folderToDelete, setFolderToDelete] = useState("");
    const [folderToMove, setFolderToMove] = useState("");
    const [folderToRename, setFolderToRename] = useState("");
    const [newFolderName, setNewFolderName] = useState("");
    const [folderItemsToDelete, setFolderItemsToDelete] = useState([]);
    const [isMultipleDeleting, setIsMultipleDeleting] = useState(false);

    /* Selected Items and Folders */
    const [selectedItem, setSelectedItem] = useState("");
    const [multiSelectedItems, setMultiSelectedItems] = useState([]);
    
    /* Drag and Drop */
    const [isDragging, setIsDragging] = useState(false);
    const [dropTo, setDropTo] = useState("");
    const [isDropInProcess, setIsDropInProcess] = useState(false);
    
    /* Loading state and cursor position for loading spinner */
    const [cursorPosition, setCursorPosition] = useState({ top: 0, left: 0 });
    const [isLoading, setIsLoading] = useState(false);

    const scrollContainerRef = useRef(null);
    const folderItemRef = useRef(null);

    const path = pathname.split("/").filter((str) => ["", "dashboard", "communities"].includes(str) === false) || [];

    const currentFolderId = path[path.length - 1] || null;

    // Get cursor position for loading spinner
    const onMouseMove = useCallback((e) => {
        const top = e.pageY - window.scrollY + 10;
        const left = e.pageX - window.scrollX + 10;

        setCursorPosition({ top, left });
    }, []);

    useEffect(() => {
        if (uid) {
            fetchDir(uid, false);
        }
    }, [uid]);

    useEffect(() => {
        if (uid && organization?.memberOrgIds && !organization.details) {
            // console.log("uid/org", uid, organization);
            //only handling a single org at the moment
            fetchUserOrganization(uid, Object.keys(organization.memberOrgIds)[0]);
        }
    }, [uid, organization]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (folderItemRef.current && !folderItemRef.current.contains(event.target)) {
                setSelectedItem("");
                setMultiSelectedItems([]);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleMouseMove = (e) => {
            onMouseMove(e);
        };

        if (!isLoading) return;

        window.addEventListener("mousemove", handleMouseMove);
        window.addEventListener("wheel", handleMouseMove);

        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
            window.removeEventListener("wheel", handleMouseMove);
        };
    }, [isLoading, onMouseMove]);

    const { items: singleItems = {}, folders = {} } = dir;

    /* Deleting a model or community */
    const startItemDelete = (event, itemId) => {
        event.stopPropagation();
        setItemToDelete(itemId);
    };
    /* Deleting a folder */
    const startFolderDelete = (event, folderId) => {
        event.stopPropagation();
        setFolderToDelete(folderId);
    };

    const handleMultipleDelete = async () => {
        setIsMultipleDeleting(true);

        let foldersBatchDelete = [];
        let itemsBatchToDelete = [];

        folderItemsToDelete.forEach(async (folderModel) => {
            const modelFolder = allFoldersItems.find(([key, {}]) => key === folderModel);

            const modelFolderId = modelFolder[0];
            const modelFolderType = modelFolder[1].type;

            if (modelFolderType === "folder") {
                foldersBatchDelete.push(modelFolderId);
            }

            if (modelFolderType === "model") {
                itemsBatchToDelete.push(modelFolderId);
            }
        });

        await batchFolderRemove(foldersBatchDelete, uid);
        await batchDeleteItems(itemsBatchToDelete, uid);

        await fetchDir(uid, false);

        setIsMultipleDeleting(false);
        setMultiSelectedItems([]);
        setFolderItemsToDelete([]);
    };

    /* Selecting a Folder, Model, or Community */
    const isSelected = (id) => {
        return multiSelectedItems.includes(id) || id === selectedItem;
    }

    const onClick = async (event, type, key, index) => {
        event.preventDefault();

        if (event.shiftKey && !event.ctrlKey && !event.metaKey) {
            multipleItemSelect(event, index);
            return;
        }

        if (
            (event.ctrlKey || event.metaKey) &&
            multiSelectedItems &&
            !multiSelectedItems.includes(key) &&
            selectedItem
        ) {
            setMultiSelectedItems([...multiSelectedItems, key]);
            return;
        }

        if (
            (event.ctrlKey || event.metaKey) &&
            multiSelectedItems &&
            multiSelectedItems.includes(key) &&
            selectedItem
        ) {
            const newFoldersAndItems = multiSelectedItems.filter((id) => id !== key);

            setMultiSelectedItems(newFoldersAndItems);
            return;
        }

        if (!selectedItem || selectedItem !== key) {
            setSelectedItem(key);
            multipleItemSelect(event, index);
        }

        if (selectedItem && selectedItem === key && type === "folder") {
            history.push(`${pathname === "/" ? "/dashboard" : pathname}/${key}`);
            setSelectedItem("");
        }

        if (selectedItem && selectedItem === key && type === itemType) {
            setIsLoading(true);
            await selectItem(key);
        }
    };

    const multipleItemSelect = (event, index) => {
        event.preventDefault();

        if (!event.shiftKey && (multiSelectedItems.length <= 1 || multiSelectedItems.length > 1)) {
            setMultiSelectedItems([allFoldersItems[index][0]]);
        }

        if (event.shiftKey && multiSelectedItems.length === 0) {
            setMultiSelectedItems([allFoldersItems[index][0]]);
        }

        if (event.shiftKey && multiSelectedItems.length > 0) {
            const firstIndex = allFoldersItems.findIndex(([key, el]) => key == multiSelectedItems[0]);
            const startIndex =
                multiSelectedItems.length > 1 && firstIndex >= index
                    ? allFoldersItems.findIndex(
                        ([key, el]) => key == multiSelectedItems[multiSelectedItems.length - 1]
                    )
                    : firstIndex;
            const endIndex = multiSelectedItems.includes(allFoldersItems[index][0]) ? index : index;

            const selectedItems =
                endIndex < startIndex
                    ? allFoldersItems.slice(endIndex, startIndex + 1)
                    : allFoldersItems.slice(startIndex, endIndex + 1);

            setMultiSelectedItems(selectedItems.map(([key, _]) => key));
        }
    };

    /* Drag and drop Folders, Models, and Communities */
    const onDragEnd = async (event) => {
        event.preventDefault();

        setCursorPosition({ top: event.clientY, left: event.clientX - 250 });

        setIsDragging(false);

        if (dropTo !== "") {
            setIsLoading(true);
            setIsDropInProcess(true);
        } else return;

        if (selectedItem && multiSelectedItems.length <= 1) {
            const itemToMove = allFoldersItems.find(([key, el]) => key === selectedItem);

            const { type } = itemToMove[1];

            if (type === itemType) await moveItem(itemToMove[0], uid, dropTo, true);

            if (type === "folder") await moveFolder(itemToMove[0], uid, dropTo, true);
        }

        if (!multiSelectedItems.includes(dropTo) && multiSelectedItems.length > 1 && dropTo !== "") {
            await Promise.all(
                multiSelectedItems.map((itemId) => {
                    const itemToMove = allFoldersItems.find(([key, el]) => key === itemId);

                    const { type } = itemToMove[1];

                    if (type === itemType) return moveItem(itemToMove[0], uid, dropTo, true);

                    if (type === "folder") return moveFolder(itemToMove[0], uid, dropTo, true);
                })
            );
        }

        await fetchDir(uid, true);

        setIsLoading(false);
        setIsDropInProcess(false);
        setDropTo("");
        setSelectedItem("");
        if (multiSelectedItems.length > 1) setMultiSelectedItems([]);
    };

    const onDragStart = (event, draggableId, name, type) => {
        event.dataTransfer.effectAllowed = "move";

        const image = (
            <ComponentListImage
                componentLabel={name}
                componentType={type}
                multipleSelect={multiSelectedItems}
            />
        );

        let ghost = document.createElement("div");

        ghost.style.transform = "translate(-10000px, -10000px)";
        ghost.style.position = "absolute";
        document.body.appendChild(ghost);
        event.dataTransfer.setDragImage(ghost, 0, 0);

        ReactDOM.render(image, ghost);

        setIsDragging(true);

        if (multiSelectedItems.length <= 1) {
            setSelectedItem(draggableId);
        }
    };

    const onDragUpdate = (event, dropToId, type) => {
        event.preventDefault();

        if (dropToId === dropTo) return;

        if (type === "folder") {
            if (!isSelected(dropToId)) {
                setDropTo(dropToId);
            }
        }

        if (type === itemType && dropTo !== "") {
            setDropTo("");
        }
    };

    if (!isLoading && path.length > 0 && !folders[path[0]]) {
        return <Redirect to="/404-page-not-found" />
    }

    return (
        <div
            className={classNames(classes.dashboard, className)}
            onMouseMove={onMouseMove}
            style={{ pointerEvents: isLoading ? "none" : "auto" }}
            ref={scrollContainerRef}
        >
            <div style={{ position: "fixed", pointerEvents: "none", ...cursorPosition }}>
                {isLoading && <Loading className={classes.smallerSpinner} subClassName={classes.smallSpinner} />}
            </div>

            {children}

            {!dirLoading && !isEmpty(singleItems) && (
                <div className={classes.dashboardBody}>
                    {error && <p>{error}</p>}
                    <NestedPath
                        rootDir={rootDir}
                        rootDirPath={rootPath}
                        path={path}
                        setFolderToDelete={(folderId) => setFolderToDelete(folderId)}
                        setFolderToRename={(folderId) => setFolderToRename(folderId)}
                        folders={folders}
                        setFolderToMove={(folderId) => setFolderToMove(folderId)}
                        onDragOver={onDragUpdate}
                    />
                    <DashboardTable
                        type={dashboardType}
                        folderItemRef={folderItemRef}
                        allFoldersItems={allFoldersItems}
                        uid={uid} // for creating folders
                        folderLoading={folderLoading}
                        createFolder={createFolder}
                        dragAndDrop={{
                            draggable,
                            onDragStart,
                            onDragEnd,
                            onDragUpdate,
                            dropTo,
                            setDropTo,
                            isDragging,
                            isDropInProcess,
                        }}
                        selection={{
                            onClick,
                            isSelected,
                            multiSelectedItems,
                        }}
                        actions={{
                            startFolderDelete,
                            setFolderToMove,
                            setFolderToRename,
                            startItemDelete,
                            setItemToDuplicate,
                            setDuplicateName,
                            setItemToMove,
                            setFolderItemsToDelete,
                        }}
                    />
                </div>
            )}

            {emptyFolderDialog}

            {itemToDelete && (
                <DeleteDialog
                    singleItems={singleItems}
                    toDelete={itemToDelete}
                    deleting={deleting}
                    onClick={async () => {
                        toggleDeleting(true);
                        if (itemType === "model")
                            await deleteItem({ modelId: itemToDelete, uid });
                        else
                            await deleteItem({ communityId: itemToDelete, uid });
                        toggleDeleting(false);
                        setItemToDelete("");
                    }}
                    setToDelete={() => setItemToDelete("")}
                />
            )}
            {folderToDelete && (
                <DeleteDialog
                    singleItems={folders}
                    toDelete={folderToDelete}
                    deleting={folderLoading}
                    onClick={async () => {
                        const index = path.findIndex((el) => el === folderToDelete);

                        if (index !== -1) {
                            if (index === 0) history.push(`${rootPath}`);

                            if (index > 0) history.push(`${rootPath}/${path.slice(0, index).join("/").toString()}`);
                        }

                        await deleteFolder(folderToDelete, uid);
                        setFolderToDelete("");
                    }}
                    setToDelete={() => setFolderToDelete("")}
                    isFolder
                />
            )}
            {folderItemsToDelete.length > 1 && (
                <DeleteMultipleFilesDialog
                    filesToDelete={folderItemsToDelete}
                    toggleToDelete={() => setFolderItemsToDelete([])}
                    onClick={() => handleMultipleDelete()}
                    deleting={isMultipleDeleting}
                />
            )}
            <DuplicateDialog
                itemType={itemType}
                itemToDuplicate={itemToDuplicate}
                newDuplicateName={duplicateName}
                setDuplicateName={(newVal) => setDuplicateName(newVal)}
                onClick={async () => {
                    toggleDuplicateLoading(true);
                    await duplicateItem(itemToDuplicate, uid, currentFolderId, duplicateName);
                    setItemToDuplicate("");
                    toggleDuplicateLoading(false);
                }}
                close={() => setItemToDuplicate("")}
                duplicateLoading={duplicateLoading}
            />
            <RenameDialog
                toRename={folderToRename}
                newFolderName={newFolderName}
                onClick={async () => {
                    await renameFolder(folderToRename, uid, newFolderName);
                    setNewFolderName("");
                    setFolderToRename("");
                }}
                setNewFolderName={(val) => setNewFolderName(val)}
                close={() => setFolderToRename("")}
                folderLoading={folderLoading}
            />
            <SelectFolder
                rootDir={rootDir}
                rootDirPath={rootPath}
                folderToMove={folderToMove}
                itemToMove={itemToMove}
                close={() => {
                    setFolderToMove("");
                    setItemToMove("");
                }}
                folders={folders}
                items={singleItems}
                moveFolder={(moveToId) => {
                    moveFolder(folderToMove, uid, moveToId);

                    setSelectedItem("");
                    if (multiSelectedItems.length > 1) setMultiSelectedItems([]);
                }}
                moveItem={(moveToId) => {
                    moveItem(itemToMove, uid, moveToId);

                    setSelectedItem("");
                    if (multiSelectedItems.length > 1) setMultiSelectedItems([]);
                }}
                folderLoading={folderLoading}
                itemLoading={itemLoading}
                history={history}
            />
        </div>
    );
}

const mapStateToProps = createStructuredSelector({
    uid: selectUserUid,
    error: selectUserError
});

const mapDispatchToProps = (dispatch, { history }) => ({
    createFolder: async (uid, newFolderName, parentFolderId) =>
        dispatch(createFolder(uid, newFolderName, parentFolderId)),
    deleteFolder: async (folderId, uid) => dispatch(deleteFolder(folderId, uid)),
    renameFolder: async (folderId, uid, newFolderName) => dispatch(renameFolder(folderId, uid, newFolderName)),
    moveFolder: async (folderId, uid, moveToId, skipLoading) =>
        dispatch(moveFolder(folderId, uid, moveToId, skipLoading)),
    batchFolderRemove: async (foldersToDelete, uid) => dispatch(batchFolderRemove(foldersToDelete, uid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayout);