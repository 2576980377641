import React from "react";
import classes from "./style.module.scss";
import WorkbookCheck from "assets/images/icons/WorkbookCheck.svg";

export default ({
    checked,
    checkBox
}) => {

    return (
        <div className={`${checked ? classes.checked : classes.checkbox}`} onClick={checkBox}>
            {checked && <img src={WorkbookCheck} alt="Checkmark" />}
        </div>
    );
};
