import { getBaseUnits } from "utils/fields";
import convertUnit from "utils/convertUnit";
import { getLabel } from "./details";
import { isEmpty } from "lodash";

export const getAirTightnessData = ({ airTightness, dimensions, modelUnits }) => {
    const a1 = "Measurement System";
    const b1 = modelUnits;

    const spacing = [
        {
            [`${a1}`]: "---",
        },
        {
            [`${a1}`]: "---",
        },
        {
            [`${a1}`]: "---",
        },
    ];

    const airTightnessSpecifications = {
        houseVolume: convertUnit({
            value: dimensions?.volume?.total,
            type: "volume",
            inputUnit: getBaseUnits("totalHouseVolume", modelUnits).trueBase,
            outputUnit: getBaseUnits("totalHouseVolume", modelUnits).displayBase,
        }).toFixed(2),
        includeCrawlspaceVolume: dimensions?.volume?.includeCrawlspaceVolume ? "Yes" : "No",
        aboveGradeHeightHighestCeiling: convertUnit({
            value: dimensions?.highestCeiling?.total,
            type: "length",
            inputUnit: getBaseUnits("agHeightHighestCeiling", modelUnits).trueBase,
            outputUnit: getBaseUnits("agHeightHighestCeiling", modelUnits).displayBase,
        }).toFixed(2),
        depressurizationTestStatus: getLabel("depressurizationTestStatus", airTightness?.exhaustDevicesTest?.id),
        depressurizationTestResult: airTightness?.exhaustDevicesTest?.result || "",
        blowerDoorTestResults: getLabel("airTightnessType", airTightness?.testType?.id),
        correlationCoefficient: "",
        ach50Pa: airTightness?.blowerTest?.airChangeRate50Pa,
        relativeError: "",
        flowExponent: "",
        eqLeakageArea: convertUnit({
            value: airTightness?.blowerTest?.eqLeakageArea,
            type: "area",
            inputUnit: getBaseUnits("eqLeakageArea", modelUnits).trueBase,
            outputUnit: getBaseUnits("eqLeakageArea", modelUnits).displayBase,
        }).toFixed(2),
    };

    const airTightnessSpecsData = [
        {
            [`${a1}`]: `House volume (${
                getBaseUnits("totalHouseVolume", modelUnits).displayBase === "ft3" ? "ft³" : "m³"
            })`,
            [`${b1}`]: airTightnessSpecifications.houseVolume,
        },
        { [`${a1}`]: "Includes crawl space volume", [`${b1}`]: airTightnessSpecifications.includeCrawlspaceVolume },
        {
            [`${a1}`]: `Above grade height of highest ceiling (${
                getBaseUnits("agHeightHighestCeiling", modelUnits).displayBase
            })`,
            [`${b1}`]: airTightnessSpecifications.aboveGradeHeightHighestCeiling,
        },
        { [`${a1}`]: "Depressurization test status", [`${b1}`]: airTightnessSpecifications.depressurizationTestStatus },
        {
            [`${a1}`]: "Depressurization test result (Pa)",
            [`${b1}`]: airTightnessSpecifications.depressurizationTestResult,
        },
        { [`${a1}`]: "Blower door test results", [`${b1}`]: airTightnessSpecifications.blowerDoorTestResults },

        { [`${a1}`]: "ACH @ 50 Pa", [`${b1}`]: airTightnessSpecifications.ach50Pa },
        {
            [`${a1}`]: `Equivalent leakage area (${getBaseUnits("eqLeakageArea", modelUnits).displayBase})`,
            [`${b1}`]: airTightnessSpecifications.eqLeakageArea,
        },
        {
            [`${a1}`]: "Correlation coefficient (r >= .99)",
            [`${b1}`]: airTightnessSpecifications.correlationCoefficient,
        },
        { [`${a1}`]: "Relative error (< 7% error)", [`${b1}`]: airTightnessSpecifications.relativeError },

        { [`${a1}`]: "Flow exponent (.5 < n < 1.0)", [`${b1}`]: airTightnessSpecifications.flowExponent },
    ];

    const airTestDataPresent = airTightness.testDataEnabled && !isEmpty(airTightness.testData);

    const airLeakageTestData = [
        {
            [`${a1}`]: "Test type",
            [`${b1}`]: airTestDataPresent ? getLabel("airTestType", airTightness?.testData?.testType?.id) || "" : "",
        },
        {
            [`${a1}`]: "Outdoor temp (°C / °F)",
            [`${b1}`]: airTestDataPresent ? airTightness?.testData?.outsideTemp || "" : "",
        },
    ];

    let blowerDoorTestData = [
        { [`${a1}`]: "Test", [`${b1}`]: "" },
        { [`${a1}`]: "Fan type", [`${b1}`]: "" },
        { [`${a1}`]: "Initial static pressure (Pa)", [`${b1}`]: "" },
        { [`${a1}`]: "Final static pressure (Pa)", [`${b1}`]: "" },
        { [`${a1}`]: "Manometer", [`${b1}`]: "" },
        { [`${a1}`]: "Initial and final indoor temp  (°C / °F)", [`${b1}`]: "" },
        { [`${a1}`]: "Zone heated volume (m³ / ft³)", [`${b1}`]: "" },
    ];

    const airLeakageTests = airTightness?.testData?.tests || {};

    const dataPoints = airTightness?.testData?.tests?.test1Equip1?.dataPoints || {};

    const testValues = Object.values(dataPoints);

    const testTypeColumnKey = [`${b1}`, "C", "D", "E", "F"];
    const testTypeRowAccessor = [
        "NAME",
        "fanType.id",
        "initStaticPressure",
        "finalStaticPressure",
        "manometerModel",
        "insideTemperature",
        "zoneHeatedVolume",
    ];
    let testsPresent = 0;
    const testInfo = Object.keys(airLeakageTests).reduce((acc, test, ind) => {
        const testData = airLeakageTests[test];
        const data = testData.dataPoints;

        const testString = test.replace("test", "Test ").replace("Equip", ", Equipment ");

        if (!isEmpty(data)) {
            blowerDoorTestData.forEach((row, j) => {
                const rowAccessor = testTypeRowAccessor[j];
                let rowValue = "";
                if (rowAccessor.includes(".id")) {
                    rowValue = getLabel("airTestFanType", testData?.fanType?.id) || "";
                } else if (rowAccessor === "NAME") {
                    rowValue = testString;
                } else {
                    rowValue = testData[rowAccessor];
                }

                blowerDoorTestData[j] = {
                    ...blowerDoorTestData[j],
                    [testTypeColumnKey[testsPresent]]: rowValue,
                };
            });

            testsPresent += 1;

            return [
                ...acc,
                ...spacing,
                { [`${a1}`]: testString },
                {
                    [`${a1}`]: "House Pressure (Pa)",
                    [`${b1}`]: "Fan Pressure (Pa)",
                    C: `Measured Flow (L/s / cfm)`,
                    D: `Flow Ranges`,
                    E: `Zone 1 Pressure (Pa)`,
                    F: `Zone 2 Pressure (Pa)`,
                },
                ...Object.values(data).map((datapoint) => {
                    return {
                        [`${a1}`]: datapoint.housePressure || 0,
                        [`${b1}`]: datapoint.fanPressure || 0,
                        C: datapoint.measuredFlow || 0,
                        D: datapoint.flowRanges.id || 0,
                        E: datapoint.zonePressure || "",
                        F: datapoint.zone2Pressure || "",
                    };
                }),
            ];
        } else {
            return acc;
        }
    }, []);

    const results = !isEmpty(testValues)
        ? testValues.map((datapoint) => {
              return {
                  [`${a1}`]: datapoint.housePressure || 0,
                  [`${b1}`]: datapoint.fanPressure || 0,
                  C: datapoint.measuredFlow || 0,
                  D: datapoint.flowRanges.id || 0,
                  E: datapoint.zonePressure || 0,
                  F: datapoint.zone2Pressure || 0,
              };
          })
        : [
              {
                  [`${a1}`]: "-",
                  [`${b1}`]: "-",
                  C: "-",
                  D: "-",
                  E: "-",
                  F: "-",
              },
          ];

    return [
        ...spacing,
        { [`${a1}`]: "Specifications" },
        ...airTightnessSpecsData,
        ...spacing,
        { [`${a1}`]: "Air Leakage Test Data" },
        ...airLeakageTestData,
        ...spacing,
        { [`${a1}`]: "Blower Door Test Data" },
        ...blowerDoorTestData,
        ...testInfo,
    ];
};
