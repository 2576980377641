const types = {
    TOGGLE_IMAGE_GALLERY: "TOGGLE_IMAGE_GALLERY",
    SET_UPLOADING_IMAGES: "SET_UPLOADING_IMAGES",
    GET_IMAGE_GALLERY_START: "GET_IMAGE_GALLERY_START",
    GET_IMAGE_GALLERY_SUCCESS: "GET_IMAGE_GALLERY_SUCCESS",
    GET_IMAGE_GALLERY_ERROR: "GET_IMAGE_GALLERY_ERROR",
    ADD_IMAGE_DATA_START: "ADD_IMAGE_DATA_START",
    ADD_IMAGE_DATA_SUCCESS: "ADD_IMAGE_DATA_SUCCESS",
    ADD_IMAGE_DATA_ERROR: "ADD_IMAGE_DATA_ERROR",
    DELETE_IMAGE_DATA_START: "DELETE_IMAGE_DATA_START",
    DELETE_IMAGE_DATA_SUCCESS: "DELETE_IMAGE_DATA_SUCCESS",
    DELETE_IMAGE_DATA_ERROR: "DELETE_IMAGE_DATA_ERROR",
    SAVE_IMAGE_DATA_START: "SAVE_IMAGE_DATA_START",
    SAVE_IMAGE_DATA_SUCCESS: "SAVE_IMAGE_DATA_SUCCESS",
    SAVE_IMAGE_DATA_ERROR: "SAVE_IMAGE_DATA_ERROR",
    UPDATE_ALL_IMAGES: "UPDATE_ALL_IMAGES",
    SET_TAGS: "SET_TAGS",
    ADD_NEW_TAG: "ADD_NEW_TAG",
    RESET_IMAGE_GALLERY: "RESET_IMAGE_GALLERY",
    UPDATE_IMAGE_GALLERY_SNAPSHOT: "UPDATE_IMAGE_GALLERY_SNAPSHOT",
};

export default types;
