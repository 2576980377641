import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import user from "./users";
import model from "features/Model/_ducks";
import enclosure from "features/Model/Enclosure/_ducks";
import drawing from "features/Model/Drawing/_ducks";

import drawingReducer from "features/Model/DrawingNew/_ducks/reducer";
import results from "features/Model/Review/Results/_ducks";
import upgrades from "features/Model/Upgrades/_ducks";
import create from "features/CreateModel/_ducks";
import upgradeLibrary from "./upgradeLibrary/reducers";
import chbaChecklist from "features/Model/ProjectPathChecklist/_ducks";
import imageGalleryReducer from "./imageGallery/reducers";
import community from "features/Community/_ducks";

import folderReducer from "./folders/reducer";
import thunk from "redux-thunk";
import { reducer as formReducer } from "redux-form";

const composeEnhancers =
    (process.env.NODE_ENV === "development"
        ? window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?.({
              trace: true,
              traceLimit: 25,
          })
        : null) || compose;

const rootReducer = combineReducers({
    user,
    model,
    enclosure,
    oldDrawing: drawing,
    results,
    upgrades,
    create,
    upgradeLibrary,
    folder: folderReducer,
    drawing: drawingReducer,
    form: formReducer,
    chbaChecklist,
    imageGallery: imageGalleryReducer,
    community,
});

export default createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
