import { connect } from "react-redux";
import moment from "moment";
import { formValueSelector } from "redux-form";
import Loan from "./";

const mapStateToProps = ({ form }, { accessor, formName = "model", change }) => {
    const selector = formValueSelector(formName);

    const loans = selector({ form }, `${accessor}`) || {
        _firstLoan: {
            amount: null,
            rate: null,
            term: null,
            adminFee: null,
        },
    };

    return {
        accessor,
        formName,
        loans,
        change,
    };
};

const mergeProps = (state, dispatch, own) => ({
    ...state,
    ...dispatch,
    ...own,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Loan);
