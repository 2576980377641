import React from "react";

import withTakeoffStorey from "./withTakeoffStorey";
import Tinput from "../../components/TSelect/Tinput";
import TCompCard from "../../components/TCompCard/TCompCard";
import AddButton from "../../components/AddButton/AddButton";
import TTable from "../../TTable/TTable";
import Checkbox from "components/Input/Checkbox";
import AddBox from "components/AddBox";

import { getStoreyInputs } from "./utils/storeyInputs";
import subComponentList from "./utils/storeySubcomponentsList";
import { getStoreySubcomponentsUnits } from "./utils/storeySubcomponentsFactory";
import { getBaseUnits } from "utils/fields";

const TakeoffStorey = ({
    takeoffState,
    changeStoreyValue,
    getSValidations,
    handleError,
    removeSubComponent,
    addSubComponent,
    editSubComponent,
    errors,
    duplicateSubComponent,
    addTableComponent,
    removeTableComponent,
    editTableComponent,
    storeyType,
    translateComponent,
    title,
    modelUnits,
    openWindowsDrawer,
}) => {
    return (
        <div className="storey-component-div">
            <span className="sub-header">{title} Measurements</span>
            <div className="storey-input-rows">
                {getStoreyInputs(takeoffState[storeyType]).map((input, id) => {
                    if (input.type === "divider") {
                        return <div style={{ width: 1400, height: 30 }}> </div>;
                    } else {
                        return (
                            <Tinput
                                className={"storey-input"}
                                key={`storeyinput-${id}`}
                                label={input.label}
                                type={input.type}
                                property={input.property}
                                decimals={input.decimals}
                                validation={getSValidations}
                                handleError={(err) =>
                                    handleError(input.property, err)
                                }
                                disabled={input.disabled}
                                input={{
                                    value: input.stateValue,
                                    onChange: (e) =>
                                        changeStoreyValue(
                                            input.property,
                                            e,
                                            input.stateUnit,
                                            storeyType
                                        ),
                                }}
                                units={{
                                    ...input.units,
                                    base: getBaseUnits(
                                        input.fieldKey,
                                        modelUnits
                                    ),
                                }}
                                onUnitChange={(value, unit) => {
                                    changeStoreyValue(
                                        input.property,
                                        value,
                                        unit,
                                        storeyType
                                    );
                                }}
                            />
                        );
                    }
                })}
                <Checkbox
                    large
                    label="Remainder Attic"
                    input={{
                        value: takeoffState[storeyType]?.hasAttic?.value,
                        onChange: () =>
                            changeStoreyValue(
                                "hasAttic",
                                takeoffState[storeyType]?.hasAttic
                                    ? !takeoffState[storeyType]?.hasAttic.value
                                    : true,
                                "",
                                storeyType
                            ),
                    }}
                />{" "}
            </div>
            <div className="foundation-subcomponents-div">
                {/* ? In storey, unlike foundations,you are not guaranteed to have a key on the state object at the first rendering, so if you don't check, its gonna crash */}
                {/* In practice, if we made it this far, we are going to have the proper key. This just makes sure we don't crash on the first rendering */}
                {/* You could delay the rendering of this component until the state is ready though. */}
                {takeoffState[storeyType] &&
                    takeoffState[storeyType].components &&
                    takeoffState[storeyType].components.map((comp, index) => {
                        return (
                            <TCompCard
                                component={comp}
                                key={`storey-${index}`}
                                index={index}
                                onDelete={removeSubComponent}
                                onDuplicate={duplicateSubComponent}
                                onError={handleError}
                                onChange={editSubComponent}
                                getValidations={getSValidations}
                                getSubcomponentUnits={
                                    getStoreySubcomponentsUnits
                                }
                                compList={takeoffState[storeyType].components}
                                modelUnits={modelUnits}
                            />
                        );
                    })}
                <AddButton
                    optionsList={subComponentList}
                    onSelect={addSubComponent}
                    componentType={storeyType}
                    componentSubtype={""}
                    translateComponent={translateComponent}
                    errors={errors}
                />
            </div>

            <TTable
                dataset={
                    takeoffState[storeyType]
                        ? takeoffState[storeyType].tableComponents
                        : []
                }
                addTableComponent={addTableComponent}
                removeTableComponent={removeTableComponent}
                editTableComponent={editTableComponent}
                modelUnits={modelUnits}
            />
            <div style={{ width: "100%" }}>
                <AddBox
                    onClick={openWindowsDrawer}
                    title="Window Table Inputs"
                    icon=""
                />
            </div>

            <style>
                {`
        .sub-header {
          font-weight: 400;
          font-size: 14px;
          margin-bottom: 20px;
        }
        
        .storey-component-div {
          width: 100%;
          display: flex;
          flex-flow: row wrap;
          width: 100%;
        }

        .storey-input-rows, .storey-components {
            display: grid;
            align-items: center;
            grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
            gap: 20px;
            width: 100%; 
        }
    
        .storey-input {
          margin-right: 20px;
          margin-bottom: 20px;          
        }

        .storey-subcomponents-div {
          margin-top: 35px;
          display: flex;
          flex-flow: row wrap;
        }
      `}
            </style>
        </div>
    );
};

export default withTakeoffStorey(TakeoffStorey);
