import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { useLocation } from "react-router";
import Dialog from "components/Dialog";
import Button from "components/Button";
import FolderIcon from "assets/images/icons/folder.svg";
import ModelIcon from "assets/images/icons/model.svg";
import { selectModel } from "features/Model/_ducks/selectors";

import { actions as modelActions } from "features/Model/_ducks";
import { actions as resultsActions } from "features/Model/Review/Results/_ducks";

import {
    headerContainer,
    headerText,
    dialogContainer,
    foldersContainer,
    folderImg,
    folderContainer,
    selectedFolderContainer,
    headerTextContainer,
    goBackImg,
    moveToContainer,
    moveToText,
    loading,
    itemLabel,
    modelText,
    selectedModelText,
    labelRowText,
    smallSpinner,
    smallerSpinner,
    errorMessage,
} from "./style.module.scss";
import closeCircle from "assets/images/icons/closeCircle.svg";
import blueArrowRight from "assets/images/icons/blueArrowRight.svg";
import goBackFolder from "assets/images/icons/goBackFolder.svg";
import Loading from "components/Loading";

const { fetchModelInfo, clearModelInfo, getLinkedResultsStatus, clearMissingResultsWarning } = modelActions;
const { getLinkedResults, clearLinkedResults } = resultsActions;

const SelectModel = ({
    folderToMove,
    modelToMove,
    close,
    models,
    folders,
    setAcceptLinkedBaseline,
    acceptLinkedBaseline,
}) => {
    const { pathname } = useLocation();
    const dispatch = useDispatch();

    const path = pathname.split("/").filter((str) => str !== "" && str !== "upgrades") || [];
    const currentModelId = path[path.length - 1] || null;

    const [currentFolderId, setCurrentFolderId] = useState(null);
    const [selectedFolder, setSelectedFolder] = useState(currentModelId);
    const [preSelectedModel, setPreSelectedModel] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [showError, setShowError] = useState(false);

    const model = useSelector(selectModel);
    const linkedBaseline = model.linkedBaseline;
    const hasResults = linkedBaseline.hasResults;

    // 'select model' button is not fixed at bottom, user has to scroll down to use it

    // catch errors if fetching data takes too long and times out

    // no dependents required here, just starting off the function with the chosen inputs
    const handleModelSelect = (key) => {
        setIsLoading(true);
        // need the cliId for the getLinkedResults function, so running fetchModelInfo for that
        if (key !== null) {
            dispatch(fetchModelInfo(key));
        } else {
            dispatch(clearModelInfo());
        }
    };

    // using the distinct ID of the linkedBaseline as dependent means that it will only run after the
    // fetchModelInfo call has run and has returned valid inputs
    useEffect(() => {
        if (!isEmpty(linkedBaseline.selectedModelId)) {
            // this returns in an error sometimes, change so that it doesn't end up undefined
            const cliId = linkedBaseline?.data?.modelDetails?.lastCliRun?.cliId || null;
            if (!isEmpty(cliId)) {
                const runIds = [Object.values(cliId)[0].runId];
                dispatch(getLinkedResults({ cliIds: runIds, modelId: linkedBaseline.selectedModelId })).then((data) => {
                    setIsLoading(false);
                });
            } else {
                dispatch(clearModelInfo());
                setIsLoading(false);
                setShowError(true);
            }
        }
    }, [linkedBaseline.selectedModelId]);

    // using isLoading as the dependent variable here means that it will wait until loading completes in order to
    // display the error message, and resets the error message when a new model is loading.
    useEffect(() => {
        if (!isLoading && hasResults === false && !isEmpty(preSelectedModel)) {
            setShowError(true);
        } else if (isLoading) {
            setShowError(false);
        }
    }, [isLoading]);

    // acceptLinkedBaseline is the gatekeeper for the upgradestable data hasResults determines this outcome.
    // Could reduce variables here, but hasResults is linked to a state value in linkedBaseline
    useEffect(() => {
        if (hasResults === true) {
            setAcceptLinkedBaseline(true);
            close();
        } else {
            setAcceptLinkedBaseline(false);
            dispatch(clearModelInfo());
        }
    }, [hasResults]);

    const modelNames = !isEmpty(models)
        ? Object.entries(models)
              .map(([key, { name, parentFolderId }]) => {
                  return {
                      key,
                      name,
                      parentFolderId,
                      type: "model",
                  };
              })
              .sort((a, b) => a?.name?.localeCompare(b?.name || ""))
        : [];

    const folderNames = !isEmpty(folders)
        ? Object.entries(folders)
              .map(([key, { name, parentFolderId }]) => {
                  return {
                      key,
                      name,
                      parentFolderId,
                      type: "folder",
                  };
              })
              .sort((a, b) => a?.name?.localeCompare(b?.name || ""))
        : [];

    const directory = [...folderNames, ...modelNames];

    const filteredDirectory = directory
        .filter(({ parentFolderId }) => {
            return (parentFolderId == null && currentFolderId == null) || parentFolderId === currentFolderId;
        })
        .filter((item) => {
            return item.key !== currentModelId;
        });

    // this appears when pre-selecting a model
    const preSelectedModelLabel =
        preSelectedModel != null
            ? modelNames.reduce((acc, { key, name }) => {
                  if (key === preSelectedModel) {
                      return name;
                  } else {
                      return acc;
                  }
              }, "")
            : "";

    return (
        <Dialog onClose={close} open={!!folderToMove || !!modelToMove} classes={{ paperClass: dialogContainer }}>
            {/* <div style={{ position: "fixed", pointerEvents: "none" }}>
                {isLoading && <Loading className={smallerSpinner} subClassName={smallSpinner} />}
            </div> */}
            <div className={headerContainer}>
                <div className={headerTextContainer}>
                    {currentFolderId && (
                        <img
                            className={goBackImg}
                            src={goBackFolder}
                            alt="go back"
                            onClick={() => {
                                setCurrentFolderId(folders[currentFolderId].parentFolderId);
                                setSelectedFolder("");
                            }}
                        />
                    )}
                    <span className={headerText}>
                        {" "}
                        {currentFolderId ? `${folders[currentFolderId].name}` : "Select a pre-retrofit model"}
                    </span>
                </div>
                <img
                    src={closeCircle}
                    alt="close circle"
                    onClick={() => {
                        setPreSelectedModel(null);
                        setCurrentFolderId(null);
                        setSelectedFolder("");
                        setShowError(false);
                        close();
                    }}
                    style={{ cursor: "pointer" }}
                />
            </div>
            <div className={foldersContainer}>
                {isEmpty(models) ? (
                    <div className={loading}>
                        <Loading message="loading" />
                    </div>
                ) : (
                    filteredDirectory.map(({ key, name, type }) => (
                        <div
                            key={key}
                            className={`
                                ${folderContainer} 
                                ${selectedFolder === key && preSelectedModel !== key && selectedFolderContainer}
                                ${preSelectedModel === key && selectedFolderContainer}
                            `}
                            onClick={
                                type === "folder"
                                    ? () => {
                                          if (selectedFolder === key) {
                                              setSelectedFolder("");
                                              setCurrentFolderId(key);
                                          } else {
                                              setSelectedFolder(key);
                                              setPreSelectedModel(null);
                                          }
                                          setShowError(false);
                                      }
                                    : () => {
                                          if (preSelectedModel === key) {
                                              setPreSelectedModel(null);
                                              handleModelSelect(key);
                                              setIsLoading(true);
                                              if (acceptLinkedBaseline) {
                                                  close();
                                              }
                                          } else {
                                              setPreSelectedModel(key);
                                              setSelectedFolder("");
                                              dispatch(clearLinkedResults());
                                              dispatch(getLinkedResultsStatus(false));
                                              setAcceptLinkedBaseline(false);
                                          }
                                          setShowError(false);
                                      }
                            }
                        >
                            <div className={itemLabel}>
                                {type === "folder" ? (
                                    <img src={FolderIcon} alt="Folder icon" />
                                ) : (
                                    <img src={ModelIcon} alt="Model icon" />
                                )}
                                <div className={labelRowText}>
                                    <p>{name}</p>
                                    {key === linkedBaseline.selectedModelId && hasResults && (
                                        <p className={selectedModelText}>{`(Selected)`}</p>
                                    )}
                                </div>
                            </div>
                            {type === "folder" && (
                                <img
                                    className={selectedFolder !== key && folderImg}
                                    src={blueArrowRight}
                                    alt="blue arrow right"
                                />
                            )}
                        </div>
                    ))
                )}
            </div>
            <div className={moveToContainer}>
                <div className={moveToText}>
                    <p>{preSelectedModelLabel}</p>
                    {showError && <span className={errorMessage}>Model does not contain results</span>}
                </div>
                <Button
                    onClick={() => {
                        handleModelSelect(preSelectedModel);
                        setPreSelectedModel(null);
                    }}
                    disabled={preSelectedModel === null}
                >
                    Select Model
                </Button>
            </div>
        </Dialog>
    );
};

export default SelectModel;
