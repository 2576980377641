import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import Strapping from './';


const mapStateToProps = (
    {
        form,
    },
    {
        accessor
    }
) => {
    const selector = formValueSelector('uDefCode');
    const modelSelector = formValueSelector('model');
    const { id: structureTypeId } = selector({form}, `${accessor}.structureType`) || {};
    const { id: strappingMaterialCatId } = selector({form}, `${accessor}.materialCategory`) || {};
    const { id: strappingMaterialTypeId } = selector({form}, `${accessor}.materialType`) || {};
    const strappingResistivityValue = selector({form}, `${accessor}.resistivity`);
    const strappingThicknessValue = selector({form}, `${accessor}.thickness`);
    const strappingSpacingValue = selector({form}, `${accessor}.spacing`);
    const strappingWidthValue = selector({form}, `${accessor}.width`);
    const strappingLayers = selector({form}, 'uDefCode.layers.strapping');

    const strappingRValue = parseFloat((strappingResistivityValue * strappingThicknessValue).toFixed(2));

    const { id: cavityMaterialCatId } = selector({form}, `${accessor}.cavityInsulation.materialCategory`) || {};
    const { id: cavityMaterialTypeId } = selector({form}, `${accessor}.cavityInsulation.materialType`) || {};
    const cavityResistivityValue = selector({form}, `${accessor}.cavityInsulation.resistivity`);
    const cavityThicknessValue = selector({form}, `${accessor}.cavityInsulation.thickness`);

    const cavityRValue = parseFloat((cavityResistivityValue * cavityThicknessValue).toFixed(2));

    const strappingResistivityUnits = selector({form}, `${accessor}.resistivity_u`);
    const strappingThicknessUnits = selector({form}, `${accessor}.thickness_u`);
    const strappingRValueUnits = selector({form}, `${accessor}.rValue_u`);
    const strappingWidthUnits = selector({form}, `${accessor}.width_u`);
    const strappingSpacingUnits = selector({form}, `${accessor}.spacing_u`);

    const cavityResistivityUnits = selector({form}, `${accessor}.cavityInsulation.resistivity_u`);
    const cavityThicknessUnits = selector({form}, `${accessor}.cavityInsulation.thickness_u`);
    const cavityRValueUnits = selector({form}, `${accessor}.cavityInsulation.rValue_u`);

    const modelUnits = modelSelector({form}, `modelData.uiSettings.primaryUnits`) || {};
    const layer = selector({form}, `${accessor}.layer`);

    return {
        accessor,
        strappingMaterialCatId,
        strappingMaterialTypeId,
        strappingThicknessValue,
        strappingSpacingValue,
        strappingWidthValue,
        strappingResistivityValue,
        strappingResistivityUnits,
        strappingThicknessUnits,
        strappingRValue,
        strappingRValueUnits,
        strappingWidthUnits,
        strappingSpacingUnits,
        cavityMaterialCatId,
        cavityMaterialTypeId,
        cavityResistivityValue,
        cavityResistivityUnits,
        cavityThicknessUnits,
        cavityRValue,
        cavityRValueUnits,
        modelUnits,
        layer,
        strappingLayers
    }
};

const mapDispatchToProps = () => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Strapping);