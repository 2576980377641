import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import TestDataPoints from "./";

const mapStateToProps = ({ form, model: { modelId, contentOpen = false } = {} }, { accessor, formName = "model" }) => {
    const selector = formValueSelector(formName);
    const modelSelector = formValueSelector("model");

    //Above data point level
    const initStaticPressureUnits = selector({ form }, `${accessor}.initStaticPressure_u`);
    const finalStaticPressureUnits = selector({ form }, `${accessor}.finalStaticPressure_u`);
    const insideTempValue = selector({ form }, `${accessor}.insideTemperature`);
    const insideTempUnits = selector({ form }, `${accessor}.insideTemperature_u`);
    const zoneHeatedVolumeUnits = selector({ form }, `${accessor}.zoneHeatedVolume_u`);
    const initZonePressureUnits = selector({ form }, `${accessor}.initZonePressure_u`);
    const initZone2PressureUnits = selector({ form }, `${accessor}.initZone2Pressure_u`);
    const finalZonePressureUnits = selector({ form }, `${accessor}.finalZonePressure_u`);
    const finalZone2PressureUnits = selector({ form }, `${accessor}.finalZone2Pressure_u`);
    const { id: fanTypeId } = selector({ form }, `${accessor}.fanType`) || {};

    const testNum = selector({ form }, `${accessor}.testNum`);
    const equipmentNum = selector({ form }, `${accessor}.equipmentNum`);

    //At data point level (table header)
    const housePressureUnits = selector({ form }, `${accessor}.housePressure_u`);
    const fanPressureUnits = selector({ form }, `${accessor}.fanPressure_u`);
    const measuredFlowUnits = selector({ form }, `${accessor}.measuredFlow_u`);
    const zonePressureUnits = selector({ form }, `${accessor}.zonePressure_u`);
    const zone2PressureUnits = selector({ form }, `${accessor}.zone2Pressure_u`);

    const uploadedFileName = selector({ form }, `${accessor}.uploadedFileName`);
    const initStaticPressure = selector({ form }, `${accessor}.initStaticPressure`);
    const finalStaticPressure = selector({ form }, `${accessor}.finalStaticPressure`);

    const uploadedZoneVolume = selector({ form }, `${accessor}.uploadedZoneVolume`) || 0;
    const uploadedZoneVolumeUnits = selector({ form }, `${accessor}.uploadedZoneVolume_u`);

    // table value selectors
    const getHousePressure = (id) => selector({ form }, `${accessor}.dataPoints.point${id}.housePressure`);
    const getFanPressure = (id) => selector({ form }, `${accessor}.dataPoints.point${id}.fanPressure`);
    const getMeasuredFlow = (id) => selector({ form }, `${accessor}.dataPoints.point${id}.measuredFlow`);

    //Model-level
    const modelUnits = modelSelector({ form }, `modelData.uiSettings.primaryUnits`) || {};
    const houseVolume = modelSelector({ form }, `modelData.dimensions.volume.total`) || 0;

    const { minor: h2kMinor } = selector({ form }, "modelData.h2kVersion") || {};

    //Data points
    const dataPoints = selector({ form }, `${accessor}.dataPoints`) || {};

    const testKey = accessor.split(".tests.")[1];

    return {
        accessor,
        modelId,
        h2kMinor,
        modelUnits,
        houseVolume,
        contentOpen,
        initStaticPressureUnits,
        finalStaticPressureUnits,
        insideTempValue,
        insideTempUnits,
        zoneHeatedVolumeUnits,
        getHousePressure,
        getFanPressure,
        getMeasuredFlow,
        housePressureUnits,
        fanPressureUnits,
        measuredFlowUnits,
        zonePressureUnits,
        zone2PressureUnits,
        fanTypeId,
        dataPoints,
        initZonePressureUnits,
        initZone2PressureUnits,
        finalZonePressureUnits,
        finalZone2PressureUnits,
        testKey,
        testNum,
        equipmentNum,
        initStaticPressure,
        finalStaticPressure,
        uploadedFileName,
        uploadedZoneVolume,
        uploadedZoneVolumeUnits,
    };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TestDataPoints);
