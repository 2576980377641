import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectAllTags } from "store/imageGallery/selectors";
import { addNewTag } from "store/imageGallery/actions";

import { toCamelCase } from "utils";

import Input from "components/Input";
import HighlightedText from "components/HighlightedText";

import styles from "./styles.module.scss";

import removeBlue from "assets/images/icons/removeBlue.svg";
import blueAddNoCircle from "assets/images/icons/blueAddNoCircle.svg";

const Tags = ({ tags, allTags, imageId, allImages, setImages, addNewTag }) => {
    const inputRef = useRef(null);
    const tagsToAddRef = useRef(null);

    const [searchTag, setSearchTag] = useState("");
    const [isOnFocus, setIsOnFocus] = useState(false);
    const [isTagExist, setIsTagExist] = useState(false);

    const allImageTags = tags.map((tag) => tag.id);

    useEffect(() => {
        function handleClickOutside(event) {
            if (tagsToAddRef.current) {
                if (
                    inputRef.current &&
                    !inputRef.current.contains(event.target) &&
                    !tagsToAddRef.current.contains(event.target)
                ) {
                    setIsOnFocus(false);
                }

                return;
            }

            if (inputRef.current && !inputRef.current.contains(event.target)) {
                setIsOnFocus(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [inputRef]);

    const handleAddingTag = (event, tagToAdd) => {
        event.preventDefault();

        const newImages = allImages.map((img) => (img.id === imageId ? { ...img, tags: [...tags, tagToAdd] } : img));

        setImages(newImages);

        setIsOnFocus(false);
        setSearchTag("");
    };

    const handleCreateTag = (event, tagName) => {
        event.preventDefault();

        const newTagId = toCamelCase(tagName);

        const checkId = allTags.filter((tag) => tag.id === newTagId);

        if (checkId.length > 0) {
            setIsTagExist(true);

            setTimeout(() => {
                setIsTagExist(false);
            }, 1000);

            return;
        }

        const newTag = { text: tagName, id: newTagId, isCustom: true };

        addNewTag(newTag);
        handleAddingTag(event, newTag);
    };

    const handleDeleteTag = (event, tagToDelete) => {
        event.preventDefault();

        const newImages = allImages.map((img) =>
            img.id === imageId
                ? {
                      ...img,
                      tags: img.tags.filter(({ id }) => tagToDelete.id !== id),
                  }
                : img
        );

        setImages(newImages);
    };

    return (
        <div className={styles.imageViewTagsContainer}>
            <span className={styles.imageViewTagsSpan}>Tags</span>
            <div style={{ marginTop: "15px" }} ref={inputRef}>
                <Input
                    placeholder={"Add tag..."}
                    input={{
                        value: searchTag,
                        onChange: setSearchTag,
                        onFocus: (e) => setIsOnFocus(true),
                    }}
                />
            </div>
            {tags && tags.length > 0 && (
                <div className={styles.addedTagsWrapper} style={{ borderColor: isOnFocus ? "#62BCF8" : "#e1eaf0" }}>
                    {tags.map((tag) => (
                        <div key={tag.id} className={styles.tagContainer}>
                            <span>{tag.text}</span>
                            <img
                                src={removeBlue}
                                alt={"Delete Blue Button"}
                                onClick={(event) => handleDeleteTag(event, tag)}
                            />
                        </div>
                    ))}
                </div>
            )}
            {searchTag.length > 1 && isOnFocus && (
                <div className={styles.searchTagsContainer} ref={tagsToAddRef}>
                    {allTags
                        .filter(
                            (tag) =>
                                tag.text.toLowerCase().includes(searchTag.toLowerCase()) &&
                                !allImageTags.includes(tag.id)
                        )
                        .map((tag) => (
                            <div
                                id={"tagToAdd"}
                                key={tag.id}
                                className={styles.searchTagWrapper}
                                onClick={(event) => handleAddingTag(event, tag)}
                            >
                                {<HighlightedText text={tag.text} highlight={searchTag} />}
                            </div>
                        ))}
                    <div className={styles.createCustomTagContainer}>
                        <div
                            className={styles.createCustomTagWrapper}
                            style={{ cursor: isTagExist ? "not-allowed" : "pointer" }}
                            onClick={(event) => handleCreateTag(event, searchTag)}
                        >
                            <div className={styles.createCustomTagImageText}>
                                <img src={blueAddNoCircle} alt={"Add Tag - blue add no circle"} />
                                <span>{searchTag}</span>
                            </div>
                            <span className={styles.createCustomTagSpan}>Create custom tag</span>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    allTags: selectAllTags,
});

const mapDispatchToProps = (dispatch) => ({
    addNewTag: (tag) => dispatch(addNewTag(tag)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tags);
