import React, { useState, useEffect, useCallback } from 'react';
import classes from '../../style.module.scss';
import { Field } from 'redux-form';
import Button from 'components/Button';
import Select from 'components/Input/Select';
import Input from 'components/Input';
import Checkbox from 'components/Input/Checkbox';
import { DropDown } from 'components/Input/Select';
import Add from 'assets/images/icons/JSX/Add';
import { required, maxLength } from 'utils/fieldValidation';
import { getBaseUnits } from 'utils/fields';
import convertUnit from 'utils/convertUnit';
import { useOutsideClickHook } from 'utils/outsideClick';
import { getComponentTemplate } from 'utils/enclosure';
import moment from 'moment';
import WallComponent from './Wall/container';
import CeilingComponent from './Ceiling/container';
import ExpFloorComponent from './ExpFloor/container';
import BasementComponent from './Basement/container';
import CrawlspaceComponent from './Crawlspace/container';
import SlabComponent from './Slab/container';
import ArrowDown from 'assets/images/icons/JSX/arrow-down';
import { Collapse } from '@material-ui/core';
import Dialog from 'components/Dialog';
import debounce from "lodash/debounce";
import { isEqual } from 'lodash';

const charLim100 = maxLength(100);

const ceilingDefaults = getComponentTemplate('ceiling') || {};
const expFloorDefaults = getComponentTemplate('expFloor') || {};
const basementDefaults = getComponentTemplate('basement') || {};
const crawlspaceDefaults = getComponentTemplate('crawlspace') || {};
const slabDefaults = getComponentTemplate('slab') || {};
const wallDefaults = getComponentTemplate('wall') || {};

const getDefaultName = (id) => {
    if (id.includes('wall-')) {
        return 'Wall';
    }
    if (id.includes('ceiling-')) {
        return 'Ceiling';
    }
    if (id.includes('expFloor-')) {
        return 'Exposed Floor';
    }
    if (id.includes('basement-')) {
        return 'Basement';
    }
    if (id.includes('crawlspace-')) {
        return 'Crawlspace';
    }
    if (id.includes('slab-')) {
        return 'Slab';
    }
    return '';
};

const getUpdatedComponents = ({
    area,
    perimeter,
    components,
    primaryUnits,
    conversionFactor
}) => Object.keys(components).reduce((cache, key)=>{
    const componentData = components[key];
    const type = key.split('-')[0];

    const calculatedArea = parseFloat(convertUnit({
        value:area*Math.pow(conversionFactor, 2),
        type:'area',
        inputUnit:'m2',
        outputUnit:getBaseUnits('area', primaryUnits).trueBase, // may have to change this?
    }).toFixed(2));

    const calculatedPerimeter = parseFloat(convertUnit({
        value:perimeter*conversionFactor,
        type:'length',
        inputUnit:'m',
        outputUnit:getBaseUnits('perimeter', primaryUnits).trueBase
    }).toFixed(2));

    let newComponentData = componentData;
    
    if (type === 'ceiling') {
        const { measurements={} } = componentData;

        newComponentData = {
            ...componentData,
            measurements:{
                ...measurements,
                area:calculatedArea
            }
        }
    }

    if (type === 'expFloor') {
        const { measurements={} } = componentData;

        newComponentData = {
            ...componentData,
            measurements:{
                ...measurements,
                area:calculatedArea
            }
        }
    }

    if (type === 'wall') {
        const { measurements={} } = componentData;
        const { height=0 } = measurements;

        newComponentData = {
            ...componentData,
            measurements:{
                ...measurements,
                perimeter:calculatedPerimeter,
                area:parseFloat((calculatedPerimeter*height).toFixed(2))
            }
        }
    }

    if (type === 'basement') {
        const { floor={} } = componentData;
        const { measurements={} } = floor;

        newComponentData = {
            ...componentData,
            floor:{
                ...floor,
                measurements:{
                    ...measurements,
                    area:calculatedArea,
                    perimeter:calculatedPerimeter,
                }
            }
        }
    }

    if (type === 'crawlspace') {
        const { floor={} } = componentData;
        const { measurements={} } = floor;

        newComponentData = {
            ...componentData,
            floor:{
                ...floor,
                measurements:{
                    ...measurements,
                    area:calculatedArea,
                    perimeter:calculatedPerimeter,
                }
            }
        }
    }

    if (type === 'slab') {
        const { floor={} } = componentData;
        const { measurements={} } = floor;

        newComponentData = {
            ...componentData,
            floor:{
                ...floor,
                measurements:{
                    ...measurements,
                    area:calculatedArea,
                    perimeter:calculatedPerimeter,
                }
            }
        }
    }

    return {
        ...cache,
        [key]:newComponentData
    }
},{})

const Component = React.memo(({
    id,
    polygonComponents,
    primaryUnits,
    imageUnits,
    area,
    perimeter,
    change,
    conversionFactor,
    handleDeleteClick,
    modelChange,
    saveToState,
}) => {
    const [open, toggleOpen] = useState(true);
    const { label='' } = polygonComponents[id] || {};

    return (
        <div className={classes.polygonComponentWrapper}>
            <div className={`${classes.componentHeading} ${open && classes.open}`}>
                <p onClick={()=>toggleOpen(true)}>{label || getDefaultName(id)}</p>
                <div className={classes.arrow} onClick={()=>toggleOpen(!open)}>
                    <ArrowDown />
                </div>
            </div>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <div className={classes.polygonComponent}>
                    {
                        id.includes('wall-') && <WallComponent
                            componentId={id}
                            primaryUnits={primaryUnits}
                            imageUnits={imageUnits}
                            area={area}
                            perimeter={perimeter}
                            conversionFactor={conversionFactor}
                            change={change}
                            modelChange={modelChange}
                            saveToState={saveToState}
                        />
                    }
                    {
                        id.includes('ceiling-') && <CeilingComponent
                            componentId={id}
                            primaryUnits={primaryUnits}
                            imageUnits={imageUnits}
                            area={area}
                            perimeter={perimeter}
                            conversionFactor={conversionFactor}
                            change={change}
                            modelChange={modelChange}
                            saveToState={saveToState}
                        />
                    }
                    {
                        id.includes('expFloor-') && <ExpFloorComponent
                            componentId={id}
                            primaryUnits={primaryUnits}
                            imageUnits={imageUnits}
                            area={area}
                            perimeter={perimeter}
                            conversionFactor={conversionFactor}
                            change={change}
                            modelChange={modelChange}
                            saveToState={saveToState}
                        />
                    }
                    {
                        id.includes('basement-') && <BasementComponent
                            componentId={id}
                            primaryUnits={primaryUnits}
                            imageUnits={imageUnits}
                            area={area}
                            perimeter={perimeter}
                            conversionFactor={conversionFactor}
                            change={change}
                            modelChange={modelChange}
                            saveToState={saveToState}
                        />
                    }
                    {
                        id.includes('crawlspace-') && <CrawlspaceComponent
                            componentId={id}
                            primaryUnits={primaryUnits}
                            imageUnits={imageUnits}
                            area={area}
                            perimeter={perimeter}
                            conversionFactor={conversionFactor}
                            change={change}
                            modelChange={modelChange}
                            saveToState={saveToState}
                        />
                    }
                    {
                        id.includes('slab-') && <SlabComponent
                            componentId={id}
                            primaryUnits={primaryUnits}
                            imageUnits={imageUnits}
                            area={area}
                            perimeter={perimeter}
                            conversionFactor={conversionFactor}
                            change={change}
                            modelChange={modelChange}
                            saveToState={saveToState}
                        />
                    }
                </div>
                <span
                    className={classes.deletePolygonComponent}
                    onClick={()=>handleDeleteClick(id)}
                >
                    Delete Component
                </span>
            </Collapse>
        </div>
    )
}, isEqual);

const PolygonFields = ({
    cancel,
    onSet,
    change,
    primaryUnits,
    imageUnits,
    perimeter,
    area,
    conversionFactor,
    submitSucceeded,
    handleSubmit,
    components,
    activeComponent,
    activeImage,
    setActiveComponent,
    updateComponentsToDelete,
    removePolygon,
    setAction,
    shape,
    toDelete=[],
    includeInHeatedFloor=false,
    modelChange
}) => {
    const [open, toggleOpen] = useState(false);
    const [dialogOpen, toggleDialog] = useState(false);
    const [deleteDialogOpen, toggleDeleteDialog] = useState(false);
    const [componentToDelete, updateComponentToDelete] = useState(null);
    const [polygonComponents, updateComponents] = useState({});
    const ref = useOutsideClickHook(() => toggleOpen(false));

    const saveToState = useCallback(debounce(e => {
        handleSubmit();
    }, 500),[]);

    const handleAddComponent = (value) => {
        
        const dateId = moment().format('YYYYMMDDHHmmssSS');
        
        switch (value) {
            case 'wall':
                updateComponents({
                    ...polygonComponents,
                    [`wall-${dateId}`]:{
                        ...wallDefaults,
                        drawing:{
                            component:activeComponent,
                            image:activeImage,
                        }
                    }
                });
                change(
                    `components.wall-${dateId}`,
                    {
                        ...wallDefaults,
                        drawing:{
                            component:activeComponent,
                            image:activeImage,
                        }
                    }
                );
                break;
            case 'ceiling':
                updateComponents({
                    ...polygonComponents,
                    [`ceiling-${dateId}`]:{
                        ...ceilingDefaults,
                        drawing:{
                            component:activeComponent,
                            image:activeImage,
                        }
                    }
                });
                change(
                    `components.ceiling-${dateId}`,
                    {
                        ...ceilingDefaults,
                        drawing:{
                            component:activeComponent,
                            image:activeImage,
                        }
                    }
                );
                break;
            case 'expFloor':
                updateComponents({
                    ...polygonComponents,
                    [`expFloor-${dateId}`]:{
                        ...expFloorDefaults,
                        drawing:{
                            component:activeComponent,
                            image:activeImage,
                        }
                    }
                });
                change(
                    `components.expFloor-${dateId}`,
                    {
                        ...expFloorDefaults,
                        drawing:{
                            component:activeComponent,
                            image:activeImage,
                        }
                    }
                );
                break;
            case 'basement':
                const { floor:basementFloor={} } = basementDefaults;
                const { measurements:basementFloorMeasurements={} } = basementFloor;
                updateComponents({
                    ...polygonComponents,
                    [`basement-${dateId}`]:{
                        ...basementDefaults,
                        floor:{
                            ...basementFloor,
                            measurements:{
                                ...basementFloorMeasurements,
                                isRectangular:false,
                            }
                        },
                        drawing:{
                            component:activeComponent,
                            image:activeImage,
                        }
                    }
                });
                change(
                    `components.basement-${dateId}`,
                    {
                        ...basementDefaults,
                        floor:{
                            ...basementFloor,
                            measurements:{
                                ...basementFloorMeasurements,
                                isRectangular:false,
                            }
                        },
                        drawing:{
                            component:activeComponent,
                            image:activeImage,
                        }
                    }
                );
                break;
            case 'crawlspace':
                const { floor:crawlspaceFloor={} } = crawlspaceDefaults;
                const { measurements:crawlspaceFloorMeasurements={} } = crawlspaceFloor;
                updateComponents({
                    ...polygonComponents,
                    [`crawlspace-${dateId}`]:{
                        ...crawlspaceDefaults,
                        floor:{
                            ...crawlspaceFloor,
                            measurements:{
                                ...crawlspaceFloorMeasurements,
                                isRectangular:false,
                            }
                        },
                        drawing:{
                            component:activeComponent,
                            image:activeImage,
                        }
                    }
                });
                change(
                    `components.crawlspace-${dateId}`,
                    {
                        ...crawlspaceDefaults,
                        floor:{
                            ...crawlspaceFloor,
                            measurements:{
                                ...crawlspaceFloorMeasurements,
                                isRectangular:false,
                            }
                        },
                        drawing:{
                            component:activeComponent,
                            image:activeImage,
                        }
                    }
                );
                break;
            case 'slab':
                const { floor:slabFloor={} } = slabDefaults;
                const { measurements:slabFloorMeasurements={} } = slabFloor;
                updateComponents({
                    ...polygonComponents,
                    [`slab-${dateId}`]:{
                        ...slabDefaults,
                        floor:{
                            ...slabFloor,
                            measurements:{
                                ...slabFloorMeasurements,
                                isRectangular:false,
                            }
                        },
                        drawing:{
                            component:activeComponent,
                            image:activeImage,
                        }
                    }
                });
                change(
                    `components.slab-${dateId}`,
                    {
                        ...slabDefaults,
                        floor:{
                            ...slabFloor,
                            measurements:{
                                ...slabFloorMeasurements,
                                isRectangular:false,
                            }
                        },
                        drawing:{
                            component:activeComponent,
                            image:activeImage,
                        }
                    }
                );
                break;
            default: return null;
        };
    };

    const handlePolygonDelete = async () => {
        const componentsToDelete = Object.keys(polygonComponents).map(component => ({
            id:component,
            drawingType:'polyComponent'
        }));

        // await updateToRemoveFromFloor(activeComponent);
        await updateComponentsToDelete(componentsToDelete);
        // change('toRemoveFromFloor', [ ...toRemoveFromFloor, { id:activeComponent, drawingType:'polygon' } ]);
        await setActiveComponent('');
        await removePolygon({image:activeImage, polygonId:activeComponent});
        if (shape) {
            shape.destroy();
        }
        setAction({id:''});
    }

    const handleComponentDelete = async (id) => {
        const { [id]:polygon, ...remainingComponents } = polygonComponents;
        
        change('toDelete', [ ...toDelete, { id:id, drawingType:'polyComponent' } ]);
        change('components', remainingComponents);
        updateComponents(remainingComponents);
        saveToState();
    };

    const handleDeleteClick = (id) => {
        updateComponentToDelete(id);
        toggleDialog(true);
    };

    useEffect(() => {
        if (!activeComponent.includes('outline_')) {
            return;
        }
        updateComponents(components);
        change('components', components);
    },[activeComponent, perimeter, area]);
    
    return (
        <div className={classes.fields}>
            <Field
                className={classes.inputMarginBottom}
                component={Input}
                type="text"
                label="Polygon Name*"
                name="polygonName"
                validate={[required, charLim100]}
                placeholder="Example: First Floor"
                onChange={saveToState}
            />
            <Field
                className={classes.inputMarginBottom}
                component={Checkbox}
                name={"includeInHeatedFloor"}
                label="Include in heated floor area"
                type="checkbox"
                onChange={saveToState}
            />
            <Field
                className={classes.inputMarginBottom}
                component={Select}
                options={[
                    {
                        label:"Above Grade",
                        value:"above",
                    },
                    {
                        label:"Below Grade",
                        value:"below",
                    }
                ]}
                name="aboveOrBelowGrade"
                placeholder="Choose One"
                label="Above or Below Grade"
                disabled={!includeInHeatedFloor}
                onChange={saveToState}
            />
            <div className={classes.componentArea} ref={ref}>
                <h4>Components</h4>
                <div
                    className={`${classes.addText} ${open && classes.open}`}
                    onClick={()=>toggleOpen(!open)}
                >
                    Add <Add />
                </div>
                <DropDown
                    className={classes.addDropDown}
                    options={[
                        {
                            label:"Wall",
                            value:"wall",
                        },
                        {
                            label:"Ceiling",
                            value:"ceiling",
                        },
                        {
                            label:"Exposed Floor",
                            value:"expFloor",
                        },
                        {
                            label:"Basement",
                            value:"basement",
                        },
                        {
                            label:"Crawlspace",
                            value:"crawlspace",
                        },
                        {
                            label:"Slab-on-grade",
                            value:"slab",
                        }
                    ]}
                    open={open}
                    toggleOpen={toggleOpen}
                    onChange={(value) => {
                        handleAddComponent(value);
                        handleSubmit();
                    }}
                />
            </div>
            {
                Object.keys(polygonComponents)
                    .sort((a,b) => {
                        const aDate = a.split('-')[1];
                        const bDate = b.split('-')[1];

                        if(aDate > bDate) { return -1; }
                        if(aDate < bDate) { return 1; }
                        return 0;
                    })
                    .map(key => <Component
                        id={key}
                        key={key}
                        polygonComponents={polygonComponents}
                        primaryUnits={primaryUnits}
                        imageUnits={imageUnits}
                        perimeter={perimeter}
                        area={area}
                        conversionFactor={conversionFactor}
                        change={change}
                        modelChange={modelChange}
                        handleDeleteClick={handleDeleteClick}
                        saveToState={saveToState}
                    />)
            }
            <div className={`${classes.buttonRow} ${classes.singleRow}`} style={{marginTop:'0.25rem'}}>
                {
                    cancel && !submitSucceeded ?
                        <Button
                            smallPadding
                            onClick={cancel}
                            type='hollow'
                            className={classes.cancelButton}
                        >
                            Cancel
                        </Button> :
                            <Button
                                smallPadding
                                onClick={()=>toggleDeleteDialog(true)}
                                type='hollow'
                                className={classes.deleteButton}
                            >
                                Delete
                            </Button> 
                }
            </div>
            <Dialog
                open={deleteDialogOpen}
                classes={{
                    paperClass:classes.dialogPaper
                }}
            >
                <div className={classes.dialogText}>
                    <h2 style={{maxWidth:'34rem'}}>Are you sure you want to delete this polygon?</h2>
                    <p>Its components and all subcomponents will be deleted from the model when Drawing Capture is saved.</p>
                </div>
                <div className={classes.dialogFooter}>
                    <Button type="hollow" onClick={()=>toggleDeleteDialog(false)}>
                        Cancel
                    </Button>
                    <Button type="red" onClick={()=>{
                        toggleDeleteDialog(false);
                        handlePolygonDelete();
                    }}>
                        Delete
                    </Button>
                </div>
            </Dialog>
            <Dialog
                open={dialogOpen}
                classes={{
                    paperClass:classes.dialogPaper
                }}
            >
                <div className={classes.dialogText}>
                    <h2 style={{maxWidth:'34rem'}}>Are you sure you want to delete this component?</h2>
                    <p>This component and all subcomponents will be deleted from the model when Drawing Capture is saved.</p>
                </div>
                <div className={classes.dialogFooter}>
                    <Button type="hollow" onClick={()=>toggleDialog(false)}>
                        Cancel
                    </Button>
                    <Button type="red" onClick={()=>{
                        toggleDialog(false)
                        handleComponentDelete(componentToDelete);
                    }}>
                        Delete
                    </Button>
                </div>
            </Dialog>
        </div>
    )
};

export default PolygonFields;