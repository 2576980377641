import Konva from "konva";
import { validAngles } from "utils/drawing/helpers";

export const setBoundary = (pos, imageShape, shapeWidth, shapeHeight, stageCoords, scale) => {
    const { x = 0, y = 0 } = pos;
    const { x: stageX = 0, y: stageY = 0 } = stageCoords;
    const imageWidth = imageShape.getAttr("width"); // maybe shouldn't be in the utility func? calculated before?
    const imageHeight = imageShape.getAttr("height");
    const imageX = imageShape.x();
    const imageY = imageShape.y();

    const absoluteX = (x - stageX) / scale;
    const absoluteY = (y - stageY) / scale;

    let newX = x;
    let newY = y;

    // if position x is less than imageX, return imageX
    if (absoluteX <= imageX) {
        newX = imageX * scale + stageX;
    }

    // if position Y is less than imageX, return imageY
    if (absoluteY <= imageY) {
        newY = imageY * scale + stageY;
    }

    // if position x+shapeWidth is greater than imageX+imageWidth, return imageX+imageWidth - shapeWidth
    if (absoluteX + shapeWidth >= imageX + imageWidth) {
        newX = (imageX + imageWidth - shapeWidth) * scale + stageX;
    }

    // if position y+shapeHeight is greater than imageY+imageHeight, return imageY+imageHeight - shapeHeight
    if (absoluteY + shapeHeight >= imageY + imageHeight) {
        newY = (imageY + imageHeight - shapeHeight) * scale + stageY;
    }

    return {
        x: newX,
        y: newY,
    };
};

export const setLineBoundary = (pos, imageShape, points, stageCoords, scale) => {
    const { x = 0, y = 0 } = pos;
    const { x: stageX = 0, y: stageY = 0 } = stageCoords;
    const imageWidth = imageShape.getAttr("width"); // maybe shouldn't be in the utility func? calculated before?
    const imageHeight = imageShape.getAttr("height");
    const imageX = imageShape.getAttr("x");
    const imageY = imageShape.getAttr("y");

    const xPoints = points.filter((point, index) => index % 2 === 0);
    const yPoints = points.filter((point, index) => index % 2 !== 0);
    const xMin = Math.min(...xPoints);
    const yMin = Math.min(...yPoints);
    const xMax = Math.max(...xPoints);
    const yMax = Math.max(...yPoints);

    const absoluteX = (x - stageX) / scale;
    const absoluteY = (y - stageY) / scale;

    let newX = x;
    let newY = y;

    // if smallest xpoint is less than imageX, return imageX + xmin
    if (absoluteX + xMin <= imageX) {
        newX = (imageX - xMin) * scale + stageX;
    }

    // if smallest ypoint is less than imageX, return imageY + ymin
    if (absoluteY + yMin <= imageY) {
        newY = (imageY - yMin) * scale + stageY;
    }

    //if position of greatest xpoint is greater than imageX+imageWidth, return imageX+imageWidth - xposition
    if (absoluteX + xMax >= imageX + imageWidth) {
        newX = (imageX + imageWidth - xMax) * scale + stageX;
    }

    // if position of greatest ypoint is greater than imageY+imageHeight, return imageY+imageHeight - yposition
    if (absoluteY + yMax >= imageY + imageHeight) {
        newY = (imageY + imageHeight - yMax) * scale + stageY;
    }

    return {
        x: newX,
        y: newY,
    };
};

export const attachAnchors = (id, shape, image, stage) => {
    const { attrs: { points = [] } = {} } = shape || {};

    return points
        .map((point, index) => {
            // One anchor per two points (grouped x and y)
            if (index % 2 !== 0) {
                return null;
            }

            const x = point;
            const y = points[index + 1];

            const anchor = new Konva.Circle({
                id: `${id}_${index}`,
                x: x,
                y: y,
                radius: 5,
                stroke: "#262E3F",
                name: "anchor",
                strokeWidth: 2,
                draggable: true,
                ignoreStroke: true,
                image,
                shapeId: id,
                shapeType: shape.name(),
                xPointIndex: index,
                yPointIndex: index + 1,
                perfectDrawEnabled: false,
            });

            return anchor;
        })
        .filter((anchor) => anchor !== null);
};

export const removeAnchors = (group, stage) => {
    const anchors = group.getChildren((node) => node.name() === "anchor");
    return anchors.map((anchor) => anchor.destroy());
};

const handleOutlineSnapMove = ({ shape, anchorId, anchor }) => {
    const threshold = 10;
    const group = shape.getParent();
    const anchors = group.find(".anchor").filter((a) => a.getAttr("id") !== anchorId);
    const { attrs: { points = [] } = {} } = shape;
    const { attrs: { xPointIndex = 0, yPointIndex = 0 } = {} } = anchor;

    const newPoints = [...points];

    const diff = (a, b) => Math.abs(a - b);
    const closestAnchors = anchors.slice(1).reduce(
        (cache, point) => {
            const pointDistX = diff(anchor.x(), point.x());
            const pointDistY = diff(anchor.y(), point.y());

            let obj = cache;

            if (pointDistX < cache.distX) {
                obj = {
                    ...obj,
                    pointX: point,
                    distX: pointDistX,
                };
            }

            if (pointDistY < cache.distY) {
                obj = {
                    ...obj,
                    pointY: point,
                    distY: pointDistY,
                };
            }

            return obj;
        },
        {
            pointX: anchors[0],
            pointY: anchors[0],
            distX: diff(anchor.x(), anchors[0].x()),
            distY: diff(anchor.y(), anchors[0].y()),
        }
    );

    // X point
    if (closestAnchors.distX <= threshold) {
        anchor.setAttr("x", closestAnchors.pointX.x());
        newPoints[xPointIndex] = closestAnchors.pointX.x();
    } else {
        newPoints[xPointIndex] = anchor.x();
    }

    // Y point
    if (closestAnchors.distY <= threshold) {
        anchor.setAttr("y", closestAnchors.pointY.y());
        newPoints[yPointIndex] = closestAnchors.pointY.y();
    } else {
        newPoints[yPointIndex] = anchor.y();
    }

    shape.points(newPoints);
};

const handleLineSnapMove = ({ shape, anchorId, anchor }) => {
    // Find other point
    // Get angle relative to other point and X
    const threshold = 5;
    const group = shape.getParent();
    const fixedAnchor = group.find(".anchor").filter((a) => a.getAttr("id") !== anchorId)[0];
    const { attrs: { points = [] } = {} } = shape;
    const { attrs: { xPointIndex = 0, yPointIndex = 0 } = {} } = anchor;

    const newPoints = [...points];
    const angle = Math.atan2(anchor.y() - fixedAnchor.y(), anchor.x() - fixedAnchor.x()) * (180 / Math.PI);
    const snapAngle = validAngles.find((a) => {
        const diff = Math.abs(angle - a);
        return diff <= threshold;
    });

    if (snapAngle != null) {
        const targetAngle = snapAngle * (Math.PI / 180);
        const isVert = [90, -90].includes(snapAngle);
        const isHoriz = [180, -180, 0, -0].includes(snapAngle);

        // Default (in between axis)
        newPoints[xPointIndex] = anchor.x();
        newPoints[yPointIndex] = (anchor.x() - fixedAnchor.x()) * Math.tan(targetAngle) + fixedAnchor.y();

        // Vertical
        if (isVert) {
            newPoints[xPointIndex] = fixedAnchor.x();
            newPoints[yPointIndex] = anchor.y();
            anchor.setAttr("x", fixedAnchor.x());
        }

        // Horizontal
        if (isHoriz) {
            newPoints[xPointIndex] = anchor.x();
            newPoints[yPointIndex] = fixedAnchor.y();
            anchor.setAttr("y", fixedAnchor.y());
        }

        if (!isVert && !isHoriz) {
            anchor.setAttr("y", (anchor.x() - fixedAnchor.x()) * Math.tan(targetAngle) + fixedAnchor.y());
        }
    } else {
        anchor.setAttr("y", points[yPointIndex]);
        anchor.setAttr("x", points[xPointIndex]);
    }

    shape.points(newPoints);
};

export const handleAnchorDrag = ({
    shapeType = "",
    shape = {},
    anchor = {},
    keysDown = [],
    isSnapMode = false,
    snapX,
    snapY,
}) => {
    const { attrs: { points = [] } = {}, parent: { attrs: { x = 0, y = 0 } = {} } = {} } = shape;
    const { attrs: { xPointIndex = 0, yPointIndex = 0 } = {} } = anchor;
    const anchorId = anchor.getAttr("id");
    const newPoints = [...points];

    if (shapeType === "polygon" && keysDown.includes("Shift")) {
        return handleOutlineSnapMove({ shape, anchorId, anchor });
    }

    if ((shapeType === "line" || shapeType === "measure") && keysDown.includes("Shift")) {
        return handleLineSnapMove({ shape, anchorId, anchor });
    }

    newPoints[xPointIndex] = isSnapMode && !isNaN(snapX) ? snapX - x : anchor.x();
    newPoints[yPointIndex] = isSnapMode && !isNaN(snapY) ? snapY - y : anchor.y();

    shape.points(newPoints);
};

export const newTransformer = (shapeId) => {
    return new Konva.Transformer({
        id: `transformer_${shapeId}`,
        anchorStroke: "#262E3F",
        anchorFill: "transparent",
        anchorCornerRadius: 5,
        rotateEnabled: true,
        rotationSnaps: true,
        anchorStrokeWidth: 2,
        ignoreStroke: true,
        keepRatio: false,
        borderEnabled: false,
        rotateAnchorOffset: 15,
        enabledAnchors: ["top-left", "bottom-right", "top-right", "bottom-left"],
    });
};

const newMeasure = ({ points, id, image, listening }) => {
    const measure = new Konva.Line({
        points,
        stroke: "#0066B1",
        name: "measure",
        opacity: listening ? 1 : 0.8,
        id: id,
        image,
        strokeWidth: 2,
        hitStrokeWidth: 20,
        perfectDrawEnabled: false,
        listening,
    });

    return measure;
};

const newLine = ({ points, id, image }) => {
    const line = new Konva.Line({
        stroke: "#F5547C",
        strokeWidth: 2,
        name: "line",
        id: id,
        image,
        opacity: 1,
        hitStrokeWidth: 20,
        perfectDrawEnabled: false,
        points,
    });

    return line;
};

const newSnapLine = ({ x, y, points, id, image }) => {
    const line = new Konva.Line({
        points,
        stroke: "#00FFFF",
        strokeWidth: 2,
        name: "snap_line",
        id: id,
        image,
        opacity: 1,
        hitStrokeWidth: 20,
        perfectDrawEnabled: false,
        x,
        y,
    });

    return line;
};

const newBuiltPolygon = ({ points, id, image }) => {
    const outline = new Konva.Line({
        points,
        stroke: "#FF8C1D",
        fill: "rgba(255,140,29,0.5)",
        fillEnabled: true,
        strokeWidth: 2,
        closed: true,
        name: "polygon",
        id: id,
        image,
        opacity: 1,
        perfectDrawEnabled: false,
    });

    return outline;
};

const newBuiltMultiPointLine = ({ points, id, image }) => {
    const multiPoint = new Konva.Line({
        points,
        stroke: "rgb(24, 193, 173)",
        strokeWidth: 2,
        closed: false,
        name: "multiPointLine",
        id: id,
        image,
        opacity: 1,
        perfectDrawEnabled: false,
    });

    return multiPoint;
};

const newRectangle = ({ x, y, width, height, id, image, shapeChangeHandler, stage, selected = false }) => {
    const imageShape = stage.findOne(`#${image}`);
    const rectangle = new Konva.Rect({
        x: x,
        y: y,
        width,
        height,
        name: "rectangle",
        id,
        stroke: "#62BCF8",
        strokeWidth: 1,
        strokeScaleEnabled: false,
        fill: "rgba(98, 188, 248, 0.5)",
        draggable: selected,
        image,
        opacity: 1,
        dragBoundFunc: (pos) =>
            setBoundary(
                pos,
                imageShape,
                60,
                100,
                {
                    x: stage.x(),
                    y: stage.y(),
                },
                stage.scaleX()
            ),
        perfectDrawEnabled: false,
    });

    rectangle.addEventListener("transformend", () => {
        const height = rectangle.getAttr("height") * rectangle.getAttr("scaleY");
        const width = rectangle.getAttr("width") * rectangle.getAttr("scaleX");
        const x = rectangle.getAttr("x");
        const y = rectangle.getAttr("y");
        const newStage = rectangle.getStage();

        // id = "",
        // image = "",
        // x = 0,
        // y = 0,
        // shape,

        shapeChangeHandler({
            id,
            image,
            height,
            width,
            x,
            y,
            stage: newStage,
        });
    });

    return rectangle;
};

const newWindow = ({ x, y, width, height, id, image, shapeChangeHandler, stage, selected = false }) => {
    const imageShape = stage.findOne(`#${image}`);
    const windowComponent = new Konva.Rect({
        x: x,
        y: y,
        width,
        height,
        name: "window",
        id,
        stroke: "#62BCF8",
        strokeWidth: 1,
        strokeScaleEnabled: false,
        fill: "rgba(98,188,248, 0.6)",
        draggable: selected,
        image,
        opacity: 1,
        dragBoundFunc: (pos) =>
            setBoundary(
                pos,
                imageShape,
                60,
                60,
                {
                    x: stage.x(),
                    y: stage.y(),
                },
                stage.scaleX()
            ),
    });

    windowComponent.addEventListener("transformend", () => {
        const height = windowComponent.getAttr("height") * windowComponent.getAttr("scaleY");
        const width = windowComponent.getAttr("width") * windowComponent.getAttr("scaleX");
        const newStage = windowComponent.getStage();

        shapeChangeHandler({
            componentId: id,
            image,
            height,
            width,
            x,
            y,
            stage: newStage,
        });
    });

    return windowComponent;
};

const newPolygon = ({ id, image }) => {
    const outline = new Konva.Line({
        points: [0, 0],
        stroke: "#FF8C1D",
        fill: "rgba(255,140,29,0.5)",
        fillEnabled: true,
        strokeWidth: 2,
        closed: true,
        name: "polygon",
        id: id,
        image,
        opacity: 1,
        perfectDrawEnabled: false,
    });

    return outline;
};

const newMultiPointLine = ({ id, image }) => {
    const multiPoint = new Konva.Line({
        points: [0, 0],
        stroke: "rgb(24, 193, 173)",
        strokeWidth: 2,
        closed: false,
        name: "multiPointLine",
        id: id,
        image,
        opacity: 1,
        perfectDrawEnabled: false,
    });

    return multiPoint;
};

export const newGroup = ({ x, y, id, image, draggable }) => {
    const group = new Konva.Group({
        x: x,
        y: y,
        image,
        draggable: draggable,
        id: `group_${id}`,
    });

    return group;
};

export const newShape = ({
    shape,
    x = 0,
    y = 0,
    width = 0,
    height = 0,
    points = [],
    id = "",
    image,
    shapeChangeHandler,
    stage,
    selected = false,
    listening = true,
}) => {
    // Get konva shape
    const konvaShape = {
        measure: newMeasure({ points, id, image, listening }),
        line: newLine({ points, x, y, id, image, selected }),
        snapLine: newSnapLine({ x, y, points, id, image, selected }),
        builtPolygon: newBuiltPolygon({ points, id, image, selected }),
        rectangle: newRectangle({
            x,
            y,
            width,
            height,
            id,
            image,
            shapeChangeHandler,
            stage,
            selected,
        }),
        multiPointLine: newMultiPointLine({ points, id, image }),
        builtMultiPointLine: newBuiltMultiPointLine({
            points,
            id,
            image,
            selected,
        }),
        polygon: newPolygon({ id, image }),
    }[shape];

    if (shape === "measure" || shape === "line" || shape === "builtPolygon" || shape === "builtMultiPointLine") {
        const imageShape = stage.findOne(`#${image}`);
        const group = newGroup({
            x,
            y,
            id,
            image,
            draggable: false,
        });

        group.add(konvaShape);
        group.dragBoundFunc((pos) =>
            setLineBoundary(
                pos,
                imageShape,
                konvaShape.points(),
                {
                    x: stage.x(),
                    y: stage.y(),
                },
                stage.scaleX()
            )
        );

        // Get anchors
        if (selected) {
            const anchors = attachAnchors(id, konvaShape, image, stage);
            anchors.map((anchor) => group.add(anchor));
        }

        return group;
    }

    if (shape === "polygon" || shape === "multiPointLine") {
        const group = newGroup({
            x,
            y,
            id,
            image,
            draggable: false,
            shapeChangeHandler,
            stage,
        });
        group.add(konvaShape);

        if (selected) {
            const anchors = attachAnchors(id, konvaShape, image, stage);
            anchors.map((anchor) => group.add(anchor));
        }

        return group;
    }

    return konvaShape;
};

export const getCursorPosition = (event) => {
    // Extract necessary information from the event object
    const {
        evt: { offsetX, offsetY } = {},
        currentTarget: { attrs: { x: stageX, y: stageY, scaleX, scaleY } = {} } = {},
    } = event;

    // Calculate the cursor position relative to the stage
    const x = (offsetX - stageX) / scaleX;
    const y = (offsetY - stageY) / scaleY;

    return { x, y };
};

export const getShape = (id, layer) => {
    //TODO: try layer.find(id) ?? maybe only works for class/name...
    const activeArray = layer
        .getChildren((node) => {
            return node.getAttr("id") === id;
        })
        .toArray();

    const shape = activeArray.length > 0 ? activeArray[0] : null;

    return shape;
};

export const calcPolygonValues = ({ points = [] }) => {
    // Attaching first point to the end for reference
    const extendedPoints = [...points, ...points.slice(0, 2)];
    const values = points.reduce(
        (total, current, index) => {
            // y coord of point
            if (index % 2 !== 0) {
                return {
                    area: total.area - current * extendedPoints[index + 1],
                    perimeter: total.perimeter,
                };
            }

            // x coord of point
            return {
                perimeter:
                    total.perimeter +
                    Math.sqrt(
                        (extendedPoints[index + 2] - current) ** 2 +
                            (extendedPoints[index + 3] - extendedPoints[index + 1]) ** 2
                    ),
                area: total.area + current * extendedPoints[index + 3],
            };
        },
        {
            perimeter: 0,
            area: 0,
        }
    );

    return {
        perimeter: values.perimeter,
        area: Math.abs(values.area / 2),
    };
};

export const getMultiPointLineLength = (points = []) => {
    let length = 0;

    if (points.length <= 2) return 0;

    for (let i = 0; i < points.length; i += 2) {
        const x1 = points[i];
        const y1 = points[i + 1];
        const x2 = points[i + 2];
        const y2 = points[i + 3];

        if (!x2 && !y2) {
            break;
        }

        length += Math.hypot(x1 - x2, y1 - y2);
    }

    return length;
};

export const calcRectangleValues = (height, width) => {
    const perimeter = 2 * (height + width);
    const area = height * width;

    return { perimeter, area };
};
