import React from "react";

import { dropdownItemContainer } from "./style.module.scss";

const DropdownItem = ({ text, onClick, style = {} }) => {
    return (
        <div style={style} onClick={onClick} className={dropdownItemContainer}>
            <span>{text}</span>
        </div>
    );
};

export default DropdownItem;
