import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import sharedClasses from "../style.module.scss";
import WorkbookCheck from "assets/images/icons/WorkbookCheck.svg";

export default ({
    input = {},
    hideLabel = false,
    label = "",
    name,
    className,
    disabled,
    large,
    style = {},
    setValue,
    round,
}) => {
    const { value, onChange } = input;

    const classNames = [
        classes.checkboxWrapper,
        sharedClasses.inputField,
        className && className,
        disabled && classes.disabled,
        large && classes.large,
        // round && classes.round
    ].filter((c) => c);

    const checkBoxClasses = [value && classes.checked, round && classes.round].filter((c) => c);

    useEffect(() => {
        if (setValue != null) {
            onChange(setValue);
        }
    }, [setValue]);

    return (
        <label className={classNames.join(" ")} htmlFor={name} style={style}>
            <div className={`${classes.checkbox} ${checkBoxClasses.join(" ")}`}>
                {value && (
                    <img style={{ borderRadius: round ? "15rem" : "0.25rem" }} src={WorkbookCheck} alt="Checkmark" />
                )}
            </div>
            <div className={`${sharedClasses.label} ${classes.label} ${hideLabel ? "invisible" : ""}`} htmlFor={name}>
                {label}
            </div>
            <input
                {...input}
                disabled={disabled}
                name={name}
                id={name}
                className={classes.checkboxInput}
                type="checkbox"
            />
        </label>
    );
};
