import { createSelector } from "reselect";
import { commDir_DUMMY_DATA } from "utils/community";

const TEST_COMMUNITY = false;

export const selectUser = (state) => state.user || {};

export const makeSelectUnits = () => createSelector(selectUser, ({ units }) => units);

export const selectUserUid = createSelector([selectUser], (user) => user.uid);

export const selectCodeLib = createSelector([selectUser], (user) => user.codeLib);

export const selectUsersOrganization = createSelector([selectUser], (user) => user.organization);

export const selectUserOrgLoading = createSelector([selectUser], (user) => user.orgLoading || false);

export const selectUserName = createSelector([selectUser], (user) => user.name || "");

export const selectUserModelDir = createSelector([selectUser], (user) => user.modelDir || {});

export const selectUserModelDirLoading = createSelector([selectUser], (user) => user.modelDirLoading || false);

export const selectUserError = createSelector([selectUser], (user) => user.error);

export const selectUserCommDir = createSelector([selectUser], (user) => TEST_COMMUNITY ? commDir_DUMMY_DATA : user.commDir || {});

export const selectUserCommDirLoading = createSelector([selectUser], (user) => user.commDirLoading || false);