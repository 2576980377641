import React from "react";
import classes from "./style.module.scss";
import sharedClasses from "../style.module.scss";

export default () => {
    return (
        <>
            <p>
                The Drawing Capture tool is used to extract features from your building construction drawings. Once your
                drawing PDF is uploaded, you will be able to freely scroll through all the pages of your drawing.
            </p>
            <p>
                The Drawing Capture tool features four panes along its left and right sides. The top-left{" "}
                <strong>Tools</strong> pane contains the drawing tool selectors that allow users to switch between the
                various drawing tools. Below that, on the bottom-left, is the <strong>Elements</strong> pane, which
                displays all of the shapes added to a drawing. On the top right is the <strong>Info</strong> pane, which
                displays the dimensions of the active shape and the scale for the active page. On the bottom left is the{" "}
                <strong>Actions</strong> pane, which allows users to perform tool-specific actions such as attach to
                fields or define component details.
            </p>
            <h3>General Instructions</h3>
            <ul>
                <li>
                    Always start collecting data on a new page first by setting the <strong>Scale</strong>. Next, add{" "}
                    <strong>Polygons</strong> and create their associated components. Finish by adding{" "}
                    <strong>Windows</strong>, <strong>Doors</strong>, and <strong>Lines</strong> as applicable.
                </li>
                <li>
                    When making changes in the <strong>Actions</strong> pane, you must click the <strong>Set</strong>{" "}
                    button at the bottom of the pane to apply those changes to the component.
                </li>
                <li>
                    When exiting the Drawing Capture tool interface, you must click <strong>Save Drawing</strong> for
                    your changes to be applied to the overall model. If you exit the interface simply by clicking "X"
                    without saving, your changes will not be saved.
                </li>
                <li>
                    Any shape that has not been attached to a component or field will not be represented in your model
                    upon exiting the interface, however, it will be accessible the next time you open the interface.
                </li>
            </ul>
            <p>There are five tools that you can use to extract features from your drawings:</p>
            <h3>Scale</h3>
            <p>
                The scale is a special tool used to define the ratio of pixels to meters or feet for a drawing image.
                You must set the scale for a given page before using any other tools on that page.
            </p>
            <p>
                To set the scale, find a measurement on the page and align the scale line with the measurement. To
                minimize error, always use the longest measurement available on the page. Use the{" "}
                <strong>Actions</strong> pane to input the true length of the scale in meters or feet and inches and
                click <strong>Set Scale</strong> to finish. Once a scale has been applied to a page, you can start
                extracting features using the four other tools. You can modify an existing scale, but it will impact all
                of the components on that page.
            </p>
            <h3>Polygon</h3>
            <p>
                The polygon is a versatile tool that allows users to extract several different features from a drawing.
                To use the polygon, select the tool and click each of the corners around the perimeter of a given area,
                and then click <strong>Set Polygon</strong> to place the polygon. Polygon corners must be selected
                sequentially either clockwise or counterclockwise, never in a zig-zag pattern. A polygon that crosses
                over itself should not be used.
            </p>
            <p>
                The polygon can be used to calculate the <strong>Heated Floor Area</strong> of your building. Use the
                Actions pane to apply a polygon's area to either the <strong>Above</strong> or
                <strong>Below Grade Heated Floor Area</strong>.
            </p>
            <p>
                You can also use the polygon to create building components that require either a floor area or a
                perimeter. For example, a polygon drawn around the perimeter of a second storey wall could be used to
                create a wall component. This wall will have its perimeter field pre-populated and uneditable. A user
                could then fill out the wall <strong>Height</strong>, <strong>Type</strong>, and other required fields.
                Multiple components can be created from a single polygon. For example, the polygon used to create the
                second storey wall could also be used to create a ceiling component. In that case, the ceiling component
                would have its area field pre-populated. Once you have finished in the <strong>Actions</strong> pane,
                click the <strong>Set</strong> button in the bottom right to apply any changes.
            </p>
            <h3>Line</h3>
            <p>
                Use the Line tool to grab a single length measurement. You can also attach a line to the Height of{" "}
                <strong>Highest Ceiling Above Grade</strong> field. Each line can only be applied to a single field.
                When a field's value is obtained from the line tool, the field will be locked and uneditable in the
                Enclosure Tab. To alter the value in that field, you must either adjust the line in the Drawing Capture
                tool or detach the line from that field and adjust its value manually.
            </p>
            <h3>Window and Door</h3>
            <p>
                The window and door tools allow users to create their respective components based on measurements
                obtained from elevation drawings. Windows and doors cannot exist in a model without being attached to a
                parent component, so always make sure that you create the intended parent component before attempting to
                attach a window or door.
            </p>
            <p>
                When you create your first window, select the <strong>Set as model default</strong> checkbox in the New
                Code Drawer to apply that code to every new window created from that point onward.
            </p>
            <h3>Known Issues and Limitations</h3>
            <ul>
                <li>
                    Only a single PDF drawing document can be uploaded to a house model. Eventually, the software will
                    allow for multiple uploads, but at the moment, users must combine all relevant drawing images into a
                    single PDF before uploading.
                </li>
                <li>
                    If you are trying to attach a line, window, or door to a recently created component, but that
                    component or field doesn't show up in the <strong>Attach</strong> drop-down menu, ensure that you
                    clicked <strong>Set</strong> on the parent component. If that doesn't solve the issue, save your
                    drawing and try again.
                </li>
                <li>
                    You cannot currently create subcomponents that are two levels deep in the Drawing Capture tool. For
                    example, a window cannot currently be attached to a door within the Drawing Capture interface.
                </li>
                <li>
                    Currently, the polygon can only be used to get areas and perimeters of enclosed shapes. This means
                    that if a wall component would be comprised of a partial perimeter of the house, it cannot currently
                    be created from the polygon tool. At this time, to create components with partial perimeters, you
                    must take measurements manually using the line tool and add those to a component. In a future
                    update, the polygon tool will allow users to set anchors without enclosing an area, thereby
                    acquiring only the perimeter of the outline without "closing" the shape.
                </li>
                <li>At this time you cannot add Floor Headers via the Drawing Capture tool.</li>
            </ul>
        </>
    );
};
