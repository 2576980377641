import React from "react";
import classes from "./style.module.scss";
import sharedClasses from "../style.module.scss";

export default () => {
    return (
        <>
            <p>
                Use the General Tab to input high-level information about your model, including HOT2000 Version,
                Building Type, and Weather Data. This tab contains much of the information from the "House" screen of
                HOT2000.{" "}
            </p>
            <h3>What is different from HOT2000?</h3>
            <p>
                <strong>HOT2000 Version.</strong> Change your model's equivalent <strong>HOT2000 Version</strong>. Watch
                out for any fields that might change when switching versions! If a field is affected on a different tab,
                you might not notice it.
            </p>
            <p style={{ marginBottom: "1rem", marginTop: "1rem" }}>
                When switching between versions <strong>11.9 and 11.10</strong>, be sure to check these fields:
            </p>
            <ul>
                <li>Weather Region</li>
                <li>Weather Location</li>
                <li>Weather Library</li>
                <li>Blower Door Test: Unguarded</li>
            </ul>
            <p style={{ marginBottom: "1rem", marginTop: "1rem" }}>
                When switching between versions <strong>11.10 and 11.11</strong>, be sure to check these fields:
            </p>
            <ul>
                <li>Blower Door Test: "Unguarded" is now "Guarded"</li>
                <li>Greener Homes information under the Program section</li>
            </ul>
            <p>
                <strong>Unit Conversions.</strong> While the overall display unit system can be modified through the{" "}
                <strong>Primary Display Units</strong> field, the units for any unit-based field can be freely changed
                to other common units for that type of measurement. For example, most length fields can freely change
                between mm, cm, m, in, and ft. In some cases, fields have their options restricted for simplicity
                (heated floor areas cannot be defined in terms of mm², for example).
            </p>
            <p>
                <strong>HOT2000 Climate Map.</strong> We have included a link to the HOT2000 climate map directly into
                the general tab so users can easily determine their proper weather location.
            </p>
            <p>
                <strong>CSA F280-12 Fields.</strong> The following fields do not impact HOT2000 simulations, but are
                used to provide more accurate estimates of heat loss and heat gain in accordance with CSA F280-12
                calculations:
            </p>
            <ul>
                <li>CSA F280-12 Design Conditions</li>
                <li>Interior Window Shading</li>
            </ul>
            <p>
                <strong>Alternative Fuel Price Inputs. </strong> Use the Fuel Prices section to enter custom fuel
                prices. Values can be entered to represent the per-unit cost of fuel, fixed monthly costs, rebates, and
                taxes. These fuel prices will not be exported with the .h2k file, and are only used in calculations in
                the Upgrades tab.
            </p>
            <p>
                <strong>Notable fields that have moved:</strong>
            </p>
            <ul>
                <li>Above and Below Grade Heated Floor Area → Enclosure Tab</li>
                <li>Storeys and Plan Shape → Enclosure Tab</li>
                <li>Window Airtightness → Airtightness Tab</li>
                <li>Info Table → Review Tab</li>
            </ul>
        </>
    );
};
