import React, { useCallback, useState } from "react";
import classes from "./style.module.scss";
import Button from "components/Button";
import Upload from "assets/images/icons/Upload.svg";
import Loading from "components/Loading";
import { useDropzone } from "react-dropzone";

const getLoadingText = ({
    isUploading,
    progress,
    error,
    uploadSuccess,
    success,
}) => {
    if (isUploading) {
        return "Uploading File...";
    }
    if (uploadSuccess && !error && !success) {
        return "Generating Images...";
    }
    return "Uploading File...";
};

export default ({
    modelId,
    uid,
    generateImages,
    isUploading,
    isGenerating,
    progress,
    success,
    uploadSuccess,
    error,
}) => {
    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps,
        isDragActive,
        open,
    } = useDropzone({
        multiple: false,
        maxSize: 1073741824,
        accept: "application/pdf",
        noClick: true,
    });

    const generateDrawing = async (event) => {
        event.stopPropagation();
        await generateImages({ modelId, uid, file: acceptedFiles[0] });
    };

    return (
        <div className={classes.uploadWrapper}>
            <div
                className={`${classes.upload} ${
                    isDragActive ? classes.active : ""
                } ${acceptedFiles.length > 0 ? classes.filesUploaded : ""} ${
                    (isUploading || isGenerating) && classes.processing
                }`}
                {...getRootProps()}
            >
                <input {...getInputProps()} />
                {!isUploading && !isGenerating && (
                    <img src={Upload} alt="Upload icon" />
                )}
                {acceptedFiles.length === 0 && (
                    <h3>
                        {isDragActive
                            ? "Drop your file here..."
                            : "Drag and drop your drawing file"}
                    </h3>
                )}
                {fileRejections.length > 0 && (
                    <>
                        {fileRejections.map(({ errors }) =>
                            errors.map((e) => (
                                <p className={classes.error} key={e.code}>
                                    {e.message}
                                </p>
                            ))
                        )}
                        <Button type="slate" onClick={open}>
                            Upload another file
                        </Button>
                    </>
                )}
                {isUploading && (
                    <>
                        <div className={classes.progressBar}>
                            <span
                                style={{ width: `${progress}%` }}
                                className={classes.progress}
                            ></span>
                        </div>
                        <p className={classes.loadingText}>Uploading File...</p>
                    </>
                )}
                {isGenerating && (
                    <>
                        <div className={classes.loading}>
                            <Loading />
                        </div>
                        <p className={classes.loadingText}>
                            Generating Drawing...
                        </p>
                    </>
                )}
                {acceptedFiles.length > 0 && !isUploading && !isGenerating && (
                    <>
                        {acceptedFiles.map((file, index) => (
                            <p className={classes.filePath} key={index}>
                                {file.path}
                            </p>
                        ))}
                        <Button
                            type="blue"
                            onClick={generateDrawing}
                            disabled={isUploading || isGenerating}
                            className={classes.generateButton}
                        >
                            Generate Drawing
                        </Button>
                        <p className={classes.uploadDiff} onClick={open}>
                            Choose a different file
                        </p>
                    </>
                )}
                {acceptedFiles.length === 0 && fileRejections.length === 0 && (
                    <>
                        <p className={classes.initialText}>
                            Upload any drawing PDF to get started. Maxiumum file
                            size is 1 GB.
                        </p>
                        <Button type="slate" onClick={open}>
                            Browse Files
                        </Button>
                    </>
                )}
            </div>
        </div>
    );
};
