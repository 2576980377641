import React, { useEffect } from "react";
import classes from './style.module.scss';
import globalStyles from 'components/globalStyles.module.scss';
import { getDecimalPlaces, getValidation, required } from 'utils/fieldValidation';
import { getBaseUnits } from 'utils/fields';
import Drawer from "components/Drawer";
import EditIcon from "assets/images/icons/JSX/Edit"; //Replace with calculator
import InputRow from 'components/Input/Row';
import Input from 'components/Input';
import InputWithUnits from 'components/Input/InputWithUnits';
import Button from 'components/Button';
import { isEqual } from 'lodash';
import { Field } from "redux-form";

const areaPercentValidation = [required, ...getValidation('compositeRValueArea')];
const areaPercentValidationSection0 = [required, ...getValidation('compositeRValueAreaSection0')];
const areaPercentRemainingValidation = getValidation('compositeRValueArea');
const basementWallTypeValidation = [ required, ...getValidation('basementIntAddedIns') ];

const getFieldName = (fieldId) => ({
    basementIntAddedIns: 'Interior Added Insulation',
    basementExtAddedIns: 'Exterior Added Insulation',
    basementPonyWallType: 'Pony Wall',
    crawlspaceWallType: 'Crawlspace Wall',
}[fieldId])

const drawerContent = ({name}) => (
    {
        title:`Composite ${name}`,
        subTitle:`Define the details of your composite ${(name || '').toLowerCase()}`,
        icon:() => <EditIcon fill="#0eb0a9" />,
        introColor:"rgba(24, 193, 173, 0.1)"
    }
)

const CompositeRCalc = React.memo(({
    open,
    clearCompositeCalc,
    fieldId,
    fieldAccessor,
    rValUnits,
    modelUnits,
    section0RSI,
    section1RSI,
    section2RSI,
    section3RSI,
    section0Percentage,
    section1Percentage,
    section2Percentage,
    section3Percentage,
    invalid,
    change,
    totalEffRValue,
    handleSubmit,
    totalInvalid=false,
    percentInvalid=false,
    reset,
    rZeroNotAllowed
}) => {
    const {
        title,
        subTitle,
        icon:Icon,
        introColor,
    } = drawerContent({ 
        name: getFieldName(fieldId),
    });

    const disableApply = invalid || totalInvalid || percentInvalid;

    const handleCancel = () => {
        reset();
        clearCompositeCalc();
    };

    // Handle % changes
    useEffect(() => {
        const areaPercent = section0Percentage + section1Percentage + section2Percentage;
        change(`section3.percentage`, (100 - areaPercent));
    }, [
        section0Percentage,
        section1Percentage,
        section2Percentage,
    ]);

    return (
        <Drawer
            open={open}
            title={title}
            subtitle={subTitle}
            close={handleCancel}
            icon={Icon}
            introColor={introColor}
        >   
            <InputRow gridTemplate="1fr 1fr" className={classes.header}>
                <h3>Area Fraction</h3>
                <h3>R-Value</h3>
            </InputRow>
            <InputRow gridTemplate="1fr 1fr" className={classes.group}>
                <Field
                    component={Input}
                    type="number"
                    name={`section0.percentage`}
                    label="Section 1 % of Area"
                    placeholder={0.00}
                    validate={areaPercentValidationSection0}
                    decimals={getDecimalPlaces('compositeRValueArea')}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`section0.rsi`}
                    label={"R-Value"}
                    placeholder={0.00}
                    validate={basementWallTypeValidation}
                    decimals={getDecimalPlaces(fieldId)}
                    units={{
                        base:getBaseUnits(fieldId, modelUnits),
                        options:[rValUnits],
                        selected:rValUnits,
                        unitType:'thermalResistance',
                        accessor:`${fieldAccessor}_u`
                    }}
                    warning={section0RSI === 0 && section0Percentage !== 0 && rZeroNotAllowed && 'compositeSingleZeroRValue'}
                />
            </InputRow>
            <InputRow gridTemplate="1fr 1fr" className={classes.group}>
                <Field
                    component={Input}
                    type="number"
                    name={`section1.percentage`}
                    label="Section 2 % of Area"
                    placeholder={0.00}
                    validate={areaPercentValidation}
                    decimals={getDecimalPlaces('compositeRValueArea')}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`section1.rsi`}
                    label={"R-Value"}
                    placeholder={0.00}
                    validate={basementWallTypeValidation}
                    decimals={getDecimalPlaces(fieldId)}
                    units={{
                        base:getBaseUnits(fieldId, modelUnits),
                        options:[rValUnits],
                        selected:rValUnits,
                        unitType:'thermalResistance',
                        accessor:`${fieldAccessor}_u`
                    }}
                    warning={section1RSI === 0 && section1Percentage !== 0 && rZeroNotAllowed && 'compositeSingleZeroRValue'}
                />
            </InputRow>
            <InputRow gridTemplate="1fr 1fr" className={classes.group}>
                <Field
                    component={Input}
                    type="number"
                    name={`section2.percentage`}
                    label="Section 3 % of Area"
                    placeholder={0.00}
                    validate={areaPercentValidation}
                    decimals={getDecimalPlaces('compositeRValueArea')}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`section2.rsi`}
                    label={"R-Value"}
                    placeholder={0.00}
                    validate={basementWallTypeValidation}
                    decimals={getDecimalPlaces(fieldId)}
                    units={{
                        base:getBaseUnits(fieldId, modelUnits),
                        options:[rValUnits],
                        selected:rValUnits,
                        unitType:'thermalResistance',
                        accessor:`${fieldAccessor}_u`
                    }}
                     warning={section2RSI === 0 && section2Percentage !== 0 && rZeroNotAllowed && 'compositeSingleZeroRValue'}
                />
            </InputRow>
            <InputRow gridTemplate="1fr 1fr" className={classes.group}>
                <Field
                    component={Input}
                    type="number"
                    name={`section3.percentage`}
                    label="Remaining % of Area"
                    placeholder={0.00}
                    validate={areaPercentRemainingValidation}
                    decimals={getDecimalPlaces('compositeRValueArea')}
                    disabled
                    warning={percentInvalid ? 'compositeAreaFrac' : ''}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`section3.rsi`}
                    label={"R-Value"}
                    placeholder={0.00}
                    validate={basementWallTypeValidation}
                    decimals={getDecimalPlaces(fieldId)}
                    units={{
                        base:getBaseUnits(fieldId, modelUnits),
                        options:[rValUnits],
                        selected:rValUnits,
                        unitType:'thermalResistance',
                        accessor:`${fieldAccessor}_u`
                    }}
                    warning={section3RSI === 0 && section3Percentage !== 0 && rZeroNotAllowed && 'compositeSingleZeroRValue'}
                />
            </InputRow>
            <div className={classes.total}>
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`effRVal`}
                    label={"Total Effective R-Value"}
                    placeholder={0.00}
                    validate={basementWallTypeValidation}
                    setTouched={true}
                    decimals={getDecimalPlaces(fieldId)}
                    setValue={totalEffRValue}
                    units={{
                        base:getBaseUnits(fieldId, modelUnits),
                        options:[rValUnits],
                        selected:rValUnits,
                        unitType:'thermalResistance',
                        accessor:`${fieldAccessor}_u`
                    }}
                    disabled
                    warning={totalInvalid ? 'compositeZeroRValue' : ''}
                />
            </div>
            <div className={globalStyles.buttons} style={{ paddingBottom: "3rem" }}>
                <Button
                    large
                    type="hollow"
                    onClick={handleCancel}
                >
                    Cancel
                </Button>
                <Button
                    large
                    onClick={()=>{
                        if (disableApply) {
                            return;
                        }
                        handleSubmit();
                    }}
                    disabled={disableApply}
                >
                    {`Apply R-Value`}
                </Button>
            </div>
        </Drawer>
    )
}, isEqual);

export default CompositeRCalc;