import { connect } from 'react-redux';
import Drawing from './';
import { actions } from './_ducks';
import { unregisterField } from 'redux-form';
import isEmpty from 'lodash/isEmpty';
import { capitalize } from 'utils';
import { getComponentTemplate } from 'utils/enclosure';
import { getDecimalPlaces } from 'utils/fieldValidation';
import convertUnit from 'utils/convertUnit';
import { getBaseUnits } from 'utils/fields';
import moment from 'moment';
import { buildImageData } from './drawingHelpers';

const {
    toggleSaving,
    uploadDrawingData,
    resetDrawing,
    updateStage,
} = actions;

const mapStateToProps = ({
    model:{
        modelFiles=[],
        modelId,
        modelData={},
        fileUpload:{
            success:uploadSuccess=false,
        }={}
    },
    drawing:{
        tools:{
            active:activeTool='',
        }={},
        activeComponent='',
        imageData={},
        toDelete=[],
        toDetach=[],
        previousRefs={},
        saving=false,
        stage:{
            scale=1,
            coords={},
            size={}
        }
    },
},{
    modelSave,
    modelChange
}) => {
    const {
        uiSettings:{
            primaryUnits
        }={},
        defaultCodes={},
        components={},
        dimensions:{
            aboveGradeIntFloorArea:{
                items:aboveGradeAreaItems={}
            }={},
            belowGradeIntFloorArea:{
                items:belowGradeAreaItems={}
            }={},
        }={}
    } = modelData;

    const dependantData = Object.keys(imageData).reduce((cache, image) => {
        const { lines={} } = imageData[image] || {};

        const multipliers = Object.keys(lines).reduce((mCache, line) => {
            const {
                attachTo:{
                    path:componentPath='',
                }={},
                changeField:{
                    dependantMultiplier:multiplierPath='',
                }={}
            } = lines[line] || {};
        
            if (!multiplierPath || !componentPath || isEmpty(components)) {
                return mCache;
            }

            const fullMultuplierPath = `${componentPath}.${multiplierPath}`;

            const componentFound = componentPath.split('.').reduce((acc,key) => acc[key], components);
            if (isEmpty(componentFound)) {
                return mCache;
            }
            const multiplierValue = multiplierPath.split('.').reduce((acc,key) => acc[key], componentFound);

            return {
                ...mCache,
                [fullMultuplierPath]:multiplierValue
            }
        },{})

        return {
            ...cache,
            ...multipliers,
        }
    },{});

    // Flatten drawing components into one object
    const componentsInDrawing = Object.keys(components).reduce((cache, cat) => {
        const catComponents = components[cat] || {};

        // first-level components
        const categoryComponents = Object.keys(catComponents).reduce((cache2, component) => {
            const {
                drawing:{
                    component:drawingComponent=''
                }={},
                subcomponents={}
            } = catComponents[component] || {};

            const thisComponent = drawingComponent ?
                { [component]:catComponents[component] } :
                    {};

            const drawingSubComponents = Object.keys(subcomponents).reduce((cache3, subCat) => {
                const subCatComponents = subcomponents[subCat] || {};
                const subComponents = Object.keys(subCatComponents).filter(subComponent => {
                    const {
                        drawing:{
                            component:drawingSubComponent=''
                        }={},
                    } = subCatComponents[subComponent] || {};

                    return !!drawingSubComponent;
                }).reduce((cache4, subComponent) => {
                    const subComponentData = subCatComponents[subComponent] || {};
                    return {
                        ...cache4,
                        [subComponent]:subComponentData
                    }
                },{})

                return {
                    ...cache3,
                    ...subComponents
                }
            },{});

            return {
                ...cache2,
                ...drawingSubComponents,
                ...thisComponent
            }
        },{})

        return {
            ...cache,
            ...categoryComponents
        }
    },{});

    return {
        hasFiles:modelFiles.length > 0,
        images:modelFiles
            .filter(({ contentType='' }) => contentType === 'image/jpeg')
            .sort(({ fileName:fileA='' }, { fileName:fileB='' }) => {
                if(fileA.toLowerCase() < fileB.toLowerCase()) { return -1; }
                if(fileA.toLowerCase() > fileB.toLowerCase()) { return 1; }
                return 0;
            }),
        activeTool,
        modelId,
        activeComponent,
        imageData,
        primaryUnits,
        defaultCodes,
        dependantData,
        saving,
        previousRefs,
        toDelete,
        toDetach,
        componentsInDrawing,
        aboveGradeAreaItems,
        belowGradeAreaItems,
        modelSave,
        modelChange,
        stageScale:scale,
        stageCoords:coords,
        canvasSize:size
    }
};

const mapDispatchToProps = (
    dispatch,
    {
        modelId
    }
) => ({
    toggleSaving:(saving) => dispatch(toggleSaving(saving)),
    uploadDrawingData:(data) => dispatch(uploadDrawingData(data)),
    reset: async () => dispatch(resetDrawing()),
    updateStage: (updates) => dispatch(updateStage(updates))
});

const mergeProps = (state, dispatch, own) => {
    const {
        modelId,
        imageData, 
        primaryUnits, 
        dependantData={}, 
        previousRefs={}, 
        toDelete=[], 
        toDetach=[], 
        componentsInDrawing={}, 
        aboveGradeAreaItems:savedAboveGradeItems, 
        belowGradeAreaItems:savedBelowGradeItems,
        modelChange,
        modelSave
    } = state;
    const {
        toggleSaving,
        uploadDrawingData,
        reset,
    } = dispatch;

    return {
        ...state,
        ...dispatch,
        ...own,
        saveDrawing: async () => {
            toggleSaving(true);

            const {
                components:componentRefs={},
            } = previousRefs;

            const prepComponents = Object.keys(imageData).reduce((cache, key) => {
                const {
                    components:imageComponents={},
                    scale:{
                        input=1,
                        px=100,
                        displayUnits
                    }={}
                } = imageData[key] || {};
                const conversionFactor = input / px; // = m / px

                const componentList = Object.values(imageComponents)
                    .filter(({ attachTo={} }) => !isEmpty(attachTo))
                    .reduce((componentCache, component, index) => {
                        const { id='' } = component || {};
                        const prevRef = componentRefs[id] ? { prevRef:componentRefs[id] } : {};
                        
                        return {
                            ...componentCache,
                            [id]:{
                                image:key,
                                conversionFactor,
                                displayUnits,
                                displayOrderFactor:index,
                                ...prevRef,
                                ...component,
                            }
                        }
                    },{});

                return {
                    ...cache,
                    ...componentList
                }
            },{});

            
            const cleanedAboveGradeItems = Object.keys(savedAboveGradeItems).reduce((cache, key) => {
                const {
                    isDrawing = false
                } = savedAboveGradeItems[key] || {};

                const inPolys = Object.keys(imageData).some(image => {
                    const {
                        polygons={},
                    } = imageData[image] || {};

                    return !!polygons[key];
                });

                // if is drawing and isn't in ANY polys, remove
                if (isDrawing && !inPolys) {
                    return cache;
                }

                return {
                    ...cache,
                    [key]:savedAboveGradeItems[key]
                }
            },{});

            const cleanedBelowGradeItems = Object.keys(savedBelowGradeItems).reduce((cache, key) => {
                const {
                    isDrawing = false
                } = savedBelowGradeItems[key] || {};

                const inPolys = Object.keys(imageData).some(image => {
                    const {
                        polygons={},
                    } = imageData[image] || {};

                    return !!polygons[key];
                });

                // if is drawing and isn't in ANY polys, remove
                if (isDrawing && !inPolys) {
                    return cache;
                }

                return {
                    ...cache,
                    [key]:savedBelowGradeItems[key]
                }
            },{});

            const aboveBelowGradeItems = Object.keys(imageData).reduce((cache, key) => {
                const {
                    polygons={},
                    scale:{
                        px,
                        input=0,
                    }={}
                } = imageData[key] || {};

                const conversionFactor = input / px;

                const aboveGradeItems = Object.keys(polygons).reduce((cache, poly) => {
                    const {
                        includeInHeatedFloor=false,
                        aboveOrBelowGrade='',
                        area=0,
                        polygonName=''
                    } = polygons[poly] || {};


                    // Subtract items that used to be in above grade, but are no longer
                    if (savedAboveGradeItems[poly] && (aboveOrBelowGrade !== "above" || !includeInHeatedFloor)) {
                        const {
                            [poly]:thisItem,
                            ...remainingItems
                        } = cache || {};

                        return remainingItems;
                    }

                    // If not checked and is not currently in the items list, ignore
                    if (!includeInHeatedFloor || aboveOrBelowGrade !== "above") {
                        return cache;
                    }

                    const calculatedArea = parseFloat(convertUnit({
                        value:area*Math.pow(conversionFactor, 2),
                        type:'area',
                        inputUnit:'m2',
                        outputUnit:'m2', // may have to change this?
                    }).toFixed(2));

                    // Otherwise, we add the item
                    return {
                        ...cache,
                        [poly]:{
                            description:polygonName,
                            enabled:true,
                            isDrawing:true,
                            value:calculatedArea,
                        }
                    }

                }, cache.aboveGradeItems);

                const belowGradeItems = Object.keys(polygons).reduce((cache, poly) => {
                    const {
                        includeInHeatedFloor=false,
                        aboveOrBelowGrade='',
                        area=0,
                        polygonName=''
                    } = polygons[poly] || {};

                    // Subtract items that used to be in below grade, but are no longer
                    if (savedBelowGradeItems[poly] && (aboveOrBelowGrade !== "below" || !includeInHeatedFloor)) {
                        const {
                            [poly]:thisItem,
                            ...remainingItems
                        } = cache || {};

                        return remainingItems;
                    }

                    // If not checked and is not currently in the items list, ignore
                    if (!includeInHeatedFloor || aboveOrBelowGrade !== "below") {
                        return cache;
                    }

                    const calculatedArea = parseFloat(convertUnit({
                        value:area*Math.pow(conversionFactor, 2),
                        type:'area',
                        inputUnit:'m2',
                        outputUnit:'m2', // may have to change this?
                    }).toFixed(2));
                    
                    // Otherwise, we add the item
                    return {
                        ...cache,
                        [poly]:{
                            description:polygonName,
                            enabled:true,
                            isDrawing:true,
                            value:calculatedArea,
                        }
                    }

                }, cache.belowGradeItems);

                return {
                    aboveGradeItems,
                    belowGradeItems,
                }
            },{
                aboveGradeItems:cleanedAboveGradeItems,
                belowGradeItems:cleanedBelowGradeItems
            });

            const aboveGradeTotal = Object.values(aboveBelowGradeItems.aboveGradeItems)
                .reduce((acc, curr) => acc + curr.value, 0);

            const belowGradeTotal = Object.values(aboveBelowGradeItems.belowGradeItems)
                .reduce((acc, curr) => acc + curr.value, 0);

            const aboveBelowFieldsToUpdate = [
                {
                    fieldPath:'modelData.dimensions.belowGradeIntFloorArea.items',
                    value:aboveBelowGradeItems.belowGradeItems,
                },
                {
                    fieldPath:'modelData.dimensions.belowGradeIntFloorArea.total',
                    value:parseFloat(belowGradeTotal.toFixed(1)),
                },
                {
                    fieldPath:'modelData.dimensions.aboveGradeIntFloorArea.items',
                    value:aboveBelowGradeItems.aboveGradeItems,
                },
                {
                    fieldPath:'modelData.dimensions.aboveGradeIntFloorArea.total',
                    value:parseFloat(aboveGradeTotal.toFixed(1)),
                }
            ];

            const polygonComponentsToAttach = Object.keys(imageData).reduce((cache, key) => {
                const {
                    polygons={},
                } = imageData[key] || {};

                const components = Object.keys(polygons).reduce((cCache, cKey) => {
                    const { components:theseComponents={} } = polygons[cKey];
                    return {
                        ...cCache,
                        ...theseComponents
                    }
                },{})

                const componentArray = Object.keys(components).map((id, index) => {
                    const component = components[id] || {};
                    const type = id.split('-')[0];

                    // Get subcomponents if they exist in model
                    const {
                        subcomponents={}
                    } = componentsInDrawing[id] || {};

                    // get component data if it already exists

                    return {
                        type,
                        displayOrderFactor:index,
                        category:'other',
                        fieldPath:`modelData.components.${type}.${id}`,
                        ...component,
                        subcomponents,
                    }
                })

                return [
                    ...cache,
                    ...componentArray
                ]

            },[]);

            const lineFieldsToUpdate = Object.keys(imageData).reduce((cache, image) => {
                const {
                    lines={},
                    scale:{
                        input=1,
                        px=100,
                        displayUnits
                    }={}
                } = imageData[image] || {};

                const conversionFactor = input / px; // = m / px

                const lineArray = Object.keys(lines).reduce((cache, line) => {
                    let dependantFieldData = [];

                    const {
                        aboveGradeHeight=false,
                        attachTo:{
                            path:componentPath='',
                            componentId=''
                        }={},
                        changeField={},
                        length=1
                    } = lines[line] || {};

                    const {
                        path:fieldPath='',
                        key,
                        dependant='',
                        dependantKey='',
                        dependantMultiplier=''
                    } = changeField;

                    const isInDeletedComponent = !!fieldPath && toDelete.some(({ id }) => id === componentId);

                    if (aboveGradeHeight) {
                        const calculatedLength = convertUnit({
                            value:length*conversionFactor,
                            type:'length',
                            inputUnit:'m',
                            outputUnit: getBaseUnits('agHeightHighestCeiling',primaryUnits).trueBase
                        });

                        const fieldValue = parseFloat(calculatedLength.toFixed(getDecimalPlaces('agHeightHighestCeiling')));

                        return {
                            ...cache,
                            [line]:{
                                fieldsToSave:[
                                    {
                                        fieldPath:'modelData.dimensions.highestCeiling.total',
                                        value:fieldValue,
                                    },
                                    {
                                        fieldPath:'modelData.dimensions.highestCeiling.total_u',
                                        value:getBaseUnits('agHeightHighestCeiling',primaryUnits).displayBase,
                                    },
                                    {
                                        fieldPath:'modelData.dimensions.highestCeiling.total_drawingRef',
                                        value:{
                                            component:line,
                                            image,
                                        },
                                    },
                                ],
                                drawingReduxUpdates:{
                                    updates:{ aboveGradeHeightRef:true }
                                }
                            }
                        }
                    }

                    // Do not include in array if component it is attached to is being deleted, or if there's no field chosen
                    if (isInDeletedComponent || !fieldPath) {
                        return cache;
                    }

                    const calculatedLength = convertUnit({
                        value:length*conversionFactor,
                        type:'length',
                        inputUnit:'m',
                        outputUnit: getBaseUnits(key,primaryUnits).trueBase
                    });

                    const fieldValue = parseFloat(calculatedLength.toFixed(getDecimalPlaces(key)));

                    if (dependant) {
                        const multiplier = dependantData[`${componentPath}.${dependantMultiplier}`];
                        const dependantValue = parseFloat((calculatedLength*multiplier).toFixed(getDecimalPlaces(dependantKey)));
                        dependantFieldData = [
                            {
                                fieldPath:`modelData.components.${componentPath}.${dependant}`,
                                value:dependantValue,
                            },
                            {
                                fieldPath:`modelData.components.${componentPath}.${dependant}_u`,
                                value:getBaseUnits(dependantKey,primaryUnits).displayBase,
                            }
                        ]
                    }

                    const fieldRef = `${componentPath}.${fieldPath}`;

                    return {
                        ...cache,
                        [line]:{
                            fieldsToSave:[
                                {
                                    fieldPath:`modelData.components.${componentPath}.${fieldPath}`,
                                    value:fieldValue,
                                },
                                {
                                    fieldPath:`modelData.components.${componentPath}.${fieldPath}_u`,
                                    value:getBaseUnits(key,primaryUnits).displayBase,
                                },
                                {
                                    fieldPath:`modelData.components.${componentPath}.${fieldPath}_drawingRef`,
                                    value:{
                                        component:line,
                                        image,
                                    },
                                },
                                ...dependantFieldData
                            ],
                            drawingReduxUpdates:{
                                updates:{
                                    changeField:{
                                        ...changeField,
                                        fieldRef,
                                    }
                                }
                            }
                        }
                    }
                },{});

                return {
                    ...cache,
                    ...lineArray
                }
            },{});

            const componentsToDelete = toDelete.map(({
                id,
                parentPath=''
            }) => {
                const componentType = id.split('-')[0];
                return `modelData.components${parentPath}.${componentType}.${id}`
            });

            const linesToDetach = Object.keys(imageData).reduce((cache, image) => {
                const {
                    lines={},
                } = imageData[image] || {};

                // If components are being deleted with lines in them, we need to detach
                const imageLines = Object.keys(lines).filter(line => {
                    const {
                        attachTo:{
                            componentId=''
                        }={},
                        changeField:{
                            fieldRef=''
                        }={}
                    } = lines[line] || {};

                    return !!fieldRef && toDelete.some(({ id }) => id === componentId);
                }).reduce((lineCache, line) => ({
                    ...lineCache,
                    [line]:{
                        image,
                        lineId:line,
                    }
                }), {});

                return {
                    ...cache,
                    ...imageLines
                }
            },{});

            const fieldsToDetach = toDetach.map(({
                fieldRef,
            }) => {
                if (!fieldRef) {
                    return 'modelData.dimensions.highestCeiling.total_drawingRef'
                }
                return `modelData.components.${fieldRef}_drawingRef`;
            });

            const newImageData = buildImageData({
                imageData,
                linesToDetach,
                lineFieldsToUpdate,
                prepComponents,
            })


            await uploadDrawingData({modelId, drawingData:newImageData});
            await Promise.all([
                ...polygonComponentsToAttach.map(({
                    type,
                    id,
                    fieldPath='',
                    ...componentData
                }, index) => modelChange(fieldPath, componentData)),
                ...Object.values(prepComponents).map( async ({
                    type,
                    id,
                    height,
                    width,
                    image,
                    modelId,
                    componentModelId='',
                    attachTo:{
                        componentType:parentType='',
                        componentId:parentId='',
                        category:parentCategory='',
                        modelRef='',
                        subComponentLength=0
                    },
                    conversionFactor,
                    displayUnits,
                    componentName='',
                    displayOrderFactor=0,
                    prevRef='',
                    ...rest
                }, index) => {
                    const template = getComponentTemplate(type);
                    const { measurements } = template;
                    const timeStamp = moment().format('YYYYMMDDHHmmssSS');
                    // const displayOrder = subComponentLength + displayOrderFactor;
                    const displayOrder = 0; // TODO: figure out display order

                    const heightField = type === 'door' ? 'doorHeight' : 'windowHeight';
                    const widthField = type === 'door' ? 'doorWidth' : 'windowWidth';
                    const areaField = type === 'door' ? 'doorArea' : 'windowArea';

                    const calculatedHeight = convertUnit({
                        value:height*conversionFactor,
                        type:'length',
                        inputUnit:'m',
                        outputUnit: getBaseUnits(heightField,primaryUnits).trueBase
                    });
                    const calculatedWidth = convertUnit({
                        value:width*conversionFactor,
                        type:'length',
                        inputUnit:'m',
                        outputUnit:getBaseUnits(widthField,primaryUnits).trueBase
                    });
                    const calculatedArea = convertUnit({
                        value:width*conversionFactor*height*conversionFactor,
                        type:'area',
                        inputUnit:'m2',
                        outputUnit:getBaseUnits(areaField,primaryUnits).trueBase
                    });

                    const componentHeight = parseFloat(calculatedHeight.toFixed(getDecimalPlaces(heightField)));
                    const componentWidth = parseFloat(calculatedWidth.toFixed(getDecimalPlaces(widthField)));
                    const componentArea = parseFloat(calculatedArea.toFixed(getDecimalPlaces(areaField)));

                    let subcomponents = {};

                    if (modelRef) {
                        const modelRefArray = modelRef.split('.');
                        const modelRefId = modelRefArray[modelRefArray.length - 1];
                        // Get subcomponents if they exist in model
                        const {
                            subcomponents:existingSubcomponents={}
                        } = componentsInDrawing[modelRefId] || {};

                        subcomponents = existingSubcomponents;
                    }

                    // If "attachTo" has been changed
                    if (prevRef) {
                        const prevRefId = prevRef.split('.')[prevRef.split('.').length - 1];
                        const prevRefPath = `modelData.components.${prevRef}`;

                        const {
                            subcomponents:prevSubcomponents={}
                        } = componentsInDrawing[prevRefId];

                        // Use previous subcomponents
                        subcomponents = prevSubcomponents;

                        modelChange(prevRefPath, null);
                        unregisterField('model', prevRefPath);
                    }

                    const { measurements:measurementFields={}, ...remainingFields } = rest || {};

                    const componentData = {
                        ...template,
                        ...remainingFields,
                        label:componentName || capitalize(type),
                        displayOrder,
                        category:parentCategory,
                        measurements:{
                            ...measurements,
                            ...measurementFields,
                            width:componentWidth,
                            width_u:getBaseUnits(widthField,primaryUnits).displayBase,
                            height:componentHeight,
                            height_u:getBaseUnits(heightField,primaryUnits).displayBase,
                            area:componentArea,
                            area_u:getBaseUnits(areaField,primaryUnits).displayBase,
                        },
                        drawing:{
                            image,
                            component:id
                        },
                        subcomponents,
                    };

                    const componentId = modelRef ? modelRef.split('.')[modelRef.split('.').length - 1] : componentModelId;
                    const fieldPath = `modelData.components.${parentType}.${parentId}.subcomponents.${type}.${componentId}`;

                    return modelChange(fieldPath, componentData);
                }),
                ...fieldsToDetach.map(async fieldRef => {
                    modelChange(fieldRef, {});
                    return unregisterField('model', fieldRef);
                }),
                ...aboveBelowFieldsToUpdate.map(async({
                    fieldPath='',
                    value=0
                }) => modelChange(fieldPath, value)),
                ...Object.values(lineFieldsToUpdate).map(async ({
                    fieldsToSave=[],
                }) => fieldsToSave.map(({
                    fieldPath,
                    value
                }) => modelChange(fieldPath, value))),
                ...componentsToDelete.map(async (fieldPath) => {
                    modelChange(fieldPath, {});
                    return unregisterField('model', fieldPath);
                }),
            ]).then(async()=>{
                await modelSave();
                toggleSaving(false);
            }).catch((err)=>{
                // do something for errors
                // console.log('err', err);
                toggleSaving(false);
            });
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(Drawing);