import { firestore, COMM_COLL } from "_firebase";
import types from "./types";

const setCommunityData = (communityId, community, isSaving = false) => ({
    type: types.SET_COMMUNITY_DATA,
    communityId,
    community,
    isSaving,
});

export const setCommunityError = (error) => ({
    type: types.SET_COMMUNITY_ERROR,
    error,
});

const clearCommunityData = () => ({
    type: types.CLEAR_COMMUNITY_DATA,
});

const communityLoading = () => ({
    type: types.COMMUNITY_LOADING,
});

const getCommModel = (communityId, modelId) => async (dispatch) => {
    if (!communityId || !modelId) return null;

    const modelDetails = await firestore.doc(`${COMM_COLL}/${communityId}/modelDetails/${modelId}`).get();
    const modelUpgrades = await firestore.doc(`${COMM_COLL}/${communityId}/modelDetails/${modelId}/upgrades/data`).get();
    const resultsSummary = await firestore.doc(`${COMM_COLL}/${communityId}/resultsSummary/${modelId}/`).get();
    
    return {
        details: modelDetails.data() || {},
        upgrades: modelUpgrades.data() || {},
        results: resultsSummary.data() || {}
    };
};

const fetchCommModelData = (communityId, modelId) => (dispatch) => {
    return dispatch(getCommModel(communityId, modelId))
        .then((data) => {
            return data;
        })
        .catch(() => dispatch(setCommunityError("Community does not exist")));
}

const getCommunity = (communityId) => async (dispatch) => {
    if (!communityId) return null;

    const data = await firestore.doc(`${COMM_COLL}/${communityId}`).get();
    const modelDir = await firestore.doc(`${COMM_COLL}/${communityId}/modelDir/directory`).get();

    return {
        communityData: data.data(),
        modelDir: modelDir.data(),
    };
};

const fetchCommunityData = (communityId) => (dispatch) => {
    return dispatch(getCommunity(communityId))
        .then((data) => {
            return dispatch(setCommunityData(communityId, data));
        })
        .catch(() => dispatch(setCommunityError("Community does not exist")));
};

export default {
    fetchCommunityData,
    fetchCommModelData,
    clearCommunityData,
    communityLoading,
}