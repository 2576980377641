import { connect } from 'react-redux';
import { reduxForm, formValueSelector, reset } from 'redux-form';
import StandardCode from 'features/Model/Enclosure/ComponentList/Ceiling/Construction/StandardCode';
import ceilingCodeTemplate from 'features/Model/Enclosure/Templates/ceilingCode.json';
import { actions as userActions } from 'store/users';
import { actions as enclosureActions } from 'features/Model/Enclosure/_ducks';
import moment from 'moment';
import { getStandardRVal } from 'utils/enclosure/api';
import isEmpty from 'lodash/isEmpty';

const { addToCodeLib } = userActions;
const { setInitCode, getRValue } = enclosureActions;

const mapStateToProps = (
    {
        form,
        model:{
            modelId,
        },
        user:{
            uid,
            codeLib:{
                Ceiling:{
                    codes:ceilingCodeLib={}
                }={},
                CeilingFlat:{
                    codes:ceilingFlatCodeLib={}
                }={}
            }={}
        },
        enclosure:{
            initCodeValues:{
                ceilingInsType={}
            }={}
        }={}
    },
    {
        toggleOpen,
        modelFormChange,
        drawingFormChange,
        componentId,
        isFlat
    }
) => {
    const selector = formValueSelector('drawingCeilingCode');
    // const modelSelector = formValueSelector('model');
    // const modelSelector = formValueSelector('drawingPolygon');
    const { id:ceilingStructureId, value:ceilingStructureValue=0 } = selector({form}, 'ceilingCode.layers.structureType') || {};
    const { value:ceilingTypeSizeValue } = selector({form}, 'ceilingCode.layers.componentTypeSize') || {};
    const { id:ceilingSpacingId, value:ceilingSpacingValue } = selector({form}, 'ceilingCode.layers.spacing') || {};
    const { value:ceilingFramingValue } = selector({form}, 'ceilingCode.layers.framing') || {};
    const { value:ceilingIns1Value } = selector({form}, 'ceilingCode.layers.insulationLayer1') || {};
    const { value:ceilingIns2Value } = selector({form}, 'ceilingCode.layers.insulationLayer2') || {};
    const { value:ceilingInteriorValue } = selector({form}, 'ceilingCode.layers.interior') || {};
    const ceilingLabel = selector({form}, 'ceilingCode.label') || '';

    const frameSpacingValue = ceilingSpacingId === null ? ceilingFramingValue : ceilingSpacingValue;

    const ceilingValue = `2${ceilingStructureValue.toString().substr(-1)}${ceilingTypeSizeValue}${frameSpacingValue}${ceilingIns1Value}${ceilingIns2Value}${ceilingInteriorValue}${0}${0}${0}`;

    const { codeRef:editingCodeRef=null, isLibCode=false } = ceilingInsType;

    return {
        ceilingLabel,
        drawingFormChange,
        initialValues:{
            ceilingCode: isEmpty(ceilingInsType) ? ceilingCodeTemplate : ceilingInsType,
            addToLibrary:isLibCode
        },
        ceilingStructureId,
        ceilingTypeSizeValue,
        isSolid:parseFloat(ceilingStructureId) === 4,
        isPanel:parseFloat(ceilingStructureId) === 5,
        isFlat,
        makeSpacingDefault:['5','6','7','8','9','A','B','C'].includes(ceilingTypeSizeValue),
        makeInsulationDefault:['6','7','8','9','A','B','C'].includes(ceilingTypeSizeValue),
        ceilingValue,
        modelId,
        uid,
        ceilingCodeLib,
        ceilingFlatCodeLib,
        modelFormChange,
        editingCodeRef,
        isEditing:!!editingCodeRef,
        isLibCode,
        ceilingSpacingId,
        componentId,
        toggleOpen
    }
};

const mapDispatchToProps = ( dispatch ) => ({
    clearForm: () => dispatch(reset('drawingCeilingCode')),
    clearInitCode: () => dispatch(setInitCode({}, 'ceilingInsType'))
});

const mergeProps = (state, dispatch, own) => ({
    ...state,
    ...dispatch,
    ...own,
});

const onSubmit = async(
    form,
    dispatch,
    {
        toggleOpen,
        uid,
        drawingFormChange,
        editingCodeRef,
        componentId,
        isFlat,
        modelFormChange,
    }
) => {
    const { ceilingCode={}, addToLibrary=false } = form;
    const { codeRef, value, nominalRValue=null, label, isLibCode, ...rest } = ceilingCode;
    
    toggleOpen(false);

    // 1. Create and change code ref
    const ceilCodeType = isFlat ? 'CeilingFlat' : 'Ceiling';
    const newCodeRef = `${ceilCodeType}-S-${moment().format('YYYYMMDDHHmmssSS')}`;
    const setCodeRef = editingCodeRef || newCodeRef;

    // ***************************************
    // 2. Fetch rValue
    // ***************************************
    let rVal = 0;
    let nomRVal = 0;
    let warningType = '';

    await dispatch(getRValue({
        codeString: value,
        codeType: ceilCodeType,
        componentId:componentId,
        fieldId:'ceilingInsType'
    }))
        .then(({
            rVal:newRVal,
            warningType:warning
        }) => {
            rVal = parseFloat(newRVal.toFixed(2));
            nomRVal = parseFloat(newRVal.toFixed(2));
            warningType = warning;
        });

   const code = { ...rest, nominalRValue:nomRVal, label, value, codeRef:setCodeRef, warningType};

    // 3.1. If code value is already in model, update using existing ref
    modelFormChange(`modelData.codes.${setCodeRef}`, code);

    if (addToLibrary) {
        await dispatch(addToCodeLib(
            uid,
            {
                ...rest,
                nominalRValue:nomRVal,
                label,
                codeType:"Standard",
                componentType:ceilCodeType,
                value,
                warningType
            },
            ceilCodeType,
            setCodeRef
        ));
    }

    const fieldValue = {
        codeLabel:label,
        codeRef:setCodeRef,
    };

    //Add warning tag to field
    if (warningType === 'compression') {
        drawingFormChange(`components.${componentId}.ceilingInsType_warning`, 'compression');
    } else {
        drawingFormChange(`components.${componentId}.ceilingInsType_warning`, '');
    }
    
    // Update model "ceiling type" field in ceiling
    drawingFormChange(`components.${componentId}.ceilingInsType`, fieldValue);
    drawingFormChange(`components.${componentId}.ceilingInsType_nomRVal`, nomRVal);
    drawingFormChange(`components.${componentId}.ceilingInsType_effRVal`, nomRVal);

    // Clear ceiling code defaults for next time
    dispatch(setInitCode({}, 'ceilingInsType'));
    // Reset form
    dispatch(reset('drawingCeilingCode'));

};

const form = reduxForm({
    form:"drawingCeilingCode",
    enableReinitialize: true,
    onSubmit:onSubmit
})(StandardCode);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(form);