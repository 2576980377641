import React from "react";
import { DragOverlay } from "@dnd-kit/core";
import { createPortal } from "react-dom";

import ComponentType from "../ComponentList/ComponentType";
import NewComponentList from "../ComponentList/newContainer";
import classes from "../style.module.scss";
import Takeoff from "../Takeoff";

import { SortableContext, rectSwappingStrategy } from "@dnd-kit/sortable";

export const renderCompView = (mode, index, storey, h2kComp, model, formModelData, components) => {
    const { id, name, componentOptions } = storey;
    const { change, allowedCategories, storeyCategories, getCategories, activeId, isDragging, draggingType } = h2kComp;

    const compIndex = components?.findIndex(({ id }) => id === activeId);

    switch (mode.toLowerCase()) {
        case "takeoff": {
            return (
                <Takeoff
                    mode={mode}
                    index={index}
                    id={id}
                    name={name}
                    componentOptions={componentOptions}
                    key={`${id}-${index}`}
                    formModelData={formModelData}
                />
            );
        }
        case "combined":
            return (
                <React.Fragment key={`${id}-${index}`}>
                    <Takeoff
                        mode={mode}
                        index={index}
                        id={id}
                        name={name}
                        componentOptions={componentOptions}
                        formModelData={formModelData}
                    />

                    <div>
                        <div className={classes.storeyCat}>
                            <SortableContext
                                id={id}
                                disabled={!isDragging ? false : !allowedCategories.includes(id)}
                                items={components}
                                strategy={rectSwappingStrategy}
                            >
                                <NewComponentList
                                    activeId={activeId}
                                    isDragging={isDragging}
                                    draggingType={draggingType}
                                    key={`${id}-${index}`}
                                    name={name}
                                    change={change}
                                    catId={id}
                                    allowedCategories={allowedCategories}
                                    options={componentOptions}
                                    availableCategories={storeyCategories.map(({ id }) => id)}
                                    combinedView={true}
                                    getCategories={getCategories}
                                />
                                {createPortal(
                                    <DragOverlay style={{ height: "52px" }}>
                                        {activeId && compIndex >= 0 ? (
                                            <div style={{ height: "52px" }}>
                                                <ComponentType
                                                    id={activeId}
                                                    activeId={activeId}
                                                    clone={true}
                                                    label={components[compIndex].componentLabel}
                                                    type={components[compIndex].componentType}
                                                    componentId={components[compIndex].componentId}
                                                    items={components}
                                                    allowedCategories={allowedCategories}
                                                    destination={{}}
                                                    isDragging={isDragging}
                                                    parentAccessor={""}
                                                    typeDrop={"category"}
                                                    draggingType={draggingType}
                                                    change={change}
                                                />
                                            </div>
                                        ) : null}
                                    </DragOverlay>,
                                    document.body
                                )}
                            </SortableContext>
                        </div>
                    </div>
                </React.Fragment>
            );

        default: {
            return (
                <div key={`${id}-${index}`}>
                    <div key={`${id}-${index}`} className={classes.storeyCat}>
                        <SortableContext
                            id={id}
                            disabled={!isDragging ? false : !allowedCategories.includes(id)}
                            items={components}
                            strategy={rectSwappingStrategy}
                        >
                            <NewComponentList
                                activeId={activeId}
                                isDragging={isDragging}
                                draggingType={draggingType}
                                key={`${id}-${index}`}
                                name={name}
                                change={change}
                                catId={id}
                                allowedCategories={allowedCategories}
                                options={componentOptions}
                                availableCategories={storeyCategories.map(({ id }) => id)}
                                combinedView={true}
                                getCategories={getCategories}
                            />
                            {createPortal(
                                <DragOverlay style={{ height: "52px" }}>
                                    {activeId && compIndex >= 0 ? (
                                        <div style={{ height: "52px" }}>
                                            <ComponentType
                                                id={activeId}
                                                activeId={activeId}
                                                clone={true}
                                                label={components[compIndex].componentLabel}
                                                type={components[compIndex].componentType}
                                                componentId={components[compIndex].componentId}
                                                items={components}
                                                allowedCategories={allowedCategories}
                                                destination={{}}
                                                isDragging={isDragging}
                                                parentAccessor={""}
                                                typeDrop={"category"}
                                                draggingType={draggingType}
                                                change={change}
                                            />
                                        </div>
                                    ) : null}
                                </DragOverlay>,
                                document.body
                            )}
                        </SortableContext>
                    </div>
                    {/* <button onClick={()=>console.log(model.modelData.components)}> H2K STATE </button> */}
                </div>
            );
        }
    }
};
