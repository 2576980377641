import React, { useRef, useEffect, useState } from "react";
import { isEqual } from "lodash";
import { Field } from "redux-form";
import { DragOverlay } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";

import { getBaseUnits, getUnitOptions, getFoundationConfigOpts, getFoundationConfigParams } from "utils/fields";
import { getValidation, required, maxLength, getDecimalPlaces } from "utils/fieldValidation";
import { getInsulationThumbnail } from "utils/enclosure/basement";

import InputRow from "components/Input/Row";
import Select from "components/Input/Select";
import Accordion from "components/Accordion";
import InputWithUnits from "components/Input/InputWithUnits";
import FloorMeasurements from "./FloorMeasurements/container";
import FloorConstruction from "./FloorConstruction/container";
import Actions from "../Actions/container";
import SlabStats from "./SlabStats/container";
import Window from "../Window/container";
import Door from "../Door/container";
import FloorHeader from "../FloorHeader/container";
import WalkoutInstructions from "../WalkoutInstructions/index.jsx";
import Button from "components/Button";
import DetailsDrawer from "components/DetailsDrawer";
import ComponentHeading from "../ComponentHeading";
import Droppable from "components/DragAndDrop/Droppable";
import ComponentType from "../ComponentType";

import classes from "./style.module.scss";
import sharedClasses from "../style.module.scss";

import SlabIcon from "assets/images/components/Foundation-Slab.svg";
import OpenIcon from "assets/images/icons/JSX/OpenDrawer";

const charLim32 = maxLength(32);

const labelValidation = [required, charLim32, ...getValidation("slabLabel")];
const skirtValidation = getValidation("slabSkirtRVal");
const thermalBreakValidation = getValidation("slabThermalBreak");

const NewLineText = ({ text }) => {
    return text.split("\n").map((str, ind) => <li key={ind}>{str.split("- ")[1]}</li>);
};

export default React.memo(
    ({
        accessor,
        label = "",
        change,
        componentId,
        subComponents,
        parentPath = "",
        newestComponent,
        dragHandleProps,
        expPerimeterUnits,
        modelUnits,
        clearNewComponent,
        isNew = false,
        isRecentDuplicate = false,
        dragActive,
        allowedCategories,
        insConfig,
        configUnits,
        skirtValue,
        thermalBreakValue,
        skirtUnits,
        thermalBreakUnits,
        isDrawingComponent,
        updateFoundationConfig,
        contentOpen,
        activeId,
        isDragging,
        subCompObj,
        dropPosition,
        mapSubComponents,
    }) => {
        const [open, toggleOpen] = useState(false);
        const [nameDialogOpen, toggleNameDialog] = useState(false);
        const [items, setItems] = useState([]);

        const ref = useRef(null);

        useEffect(() => {
            if (!isDragging) setItems(mapSubComponents);
        }, [isDragging, mapSubComponents]);

        useEffect(() => {
            if (isNew && !isRecentDuplicate) {
                ref.current.scrollIntoView();
            }
        }, [isNew, isRecentDuplicate]);

        const lastComponentDisplayOrder = items.reduce(
            ({ displayOrder: prevDisplay }, { displayOrder: currentDisplay }) =>
                prevDisplay > currentDisplay ? prevDisplay : currentDisplay,
            0
        );

        const {
            skirt: isSkirt = false,
            skirtEditable: isSkirtEditable = false,
            thermalBreak: isThermalBreak = false,
            text: insConfigDescription = "No description available.",
        } = getFoundationConfigParams({ fieldKey: "slabInsConfig", configKey: insConfig }) || {};

        let configOpts = [
            {
                label: "SCN_1",
                value: "SCN_1",
            },
            {
                label: "SCA_17",
                value: "SCA_17",
            },
            {
                label: "SCB_25",
                value: "SCB_25",
            },
        ];

        if (!["SCN_1", "SCA_17", "SCB_25"].includes(insConfig)) {
            configOpts = [
                {
                    label: insConfig,
                    value: insConfig,
                },
                ...configOpts,
            ];
        }

        const activeSubIndex = activeId ? items.findIndex(({ id }) => id === activeId) : -1;

        return (
            <div
                className={`${classes.basement}  ${
                    dropPosition === "after"
                        ? sharedClasses.activeAfter
                        : dropPosition === "before"
                        ? sharedClasses.activeBefore
                        : ""
                }`}
            >
                <span ref={ref} className={sharedClasses.componentRef}></span>
                <Accordion
                    heading={
                        <ComponentHeading
                            label={label}
                            componentId={componentId}
                            isDrawingComponent={isDrawingComponent}
                            icon={SlabIcon}
                            toggleNameDialog={toggleNameDialog}
                        />
                    }
                    large
                    isDragging={isDragging}
                    toggleNew={() => clearNewComponent(componentId)}
                    dragActive={dragActive}
                    initOpenState={isNew && !isRecentDuplicate}
                    isNew={isNew}
                    hoverActions={
                        <Actions
                            nameField={{
                                name: `${accessor}.label`,
                                validate: labelValidation,
                                current: label,
                                change,
                            }}
                            label="Slab"
                            type="slab"
                            parentPath={parentPath}
                            componentId={componentId}
                            dragHandleProps={dragHandleProps}
                            newComponentDisplay={lastComponentDisplayOrder + 1}
                            change={change}
                            nameDialogOpen={nameDialogOpen}
                            toggleNameDialog={toggleNameDialog}
                        />
                    }
                    stats={() => <SlabStats accessor={accessor} />}
                    forceOpen={contentOpen}
                >
                    <DetailsDrawer
                        open={open}
                        close={() => toggleOpen(false)}
                        title="Walkout Workaround"
                        subtitle="Modelling a walkout basement with a slab-on-grade portion"
                    >
                        <WalkoutInstructions isSlab={true} />
                        <div className={classes.buttons}>
                            <Button large type="hollow" onClick={() => toggleOpen(false)}>
                                Close
                            </Button>
                        </div>
                    </DetailsDrawer>
                    <div className={classes.firstHeader}>
                        <h4>Measurements</h4>
                        <div className={classes.workaroundButton} onClick={() => toggleOpen(true)}>
                            View Walkout Workaround <OpenIcon />
                        </div>
                    </div>
                    <h5>Slab Floor</h5>
                    <FloorMeasurements accessor={accessor} change={change} isDrawingComponent={isDrawingComponent} />
                    <h4>Construction</h4>
                    <h5>Insulation Configuration</h5>
                    <InputRow gridTemplate="2fr 1fr">
                        <div className={classes.thumbnailWrapper}>
                            <div>
                                {getInsulationThumbnail(insConfig) && (
                                    <img
                                        className={classes.thumbnail}
                                        src={getInsulationThumbnail(insConfig)}
                                        alt="Insulation thumbnail"
                                    />
                                )}
                                <p className={classes.selectedConfig}>Selected Configuration: {insConfig}</p>
                            </div>
                            <div>
                                <div className={classes.selectConfigWrapper}>
                                    <Field
                                        component={Select}
                                        name={`${accessor}.configuration.type`}
                                        options={configOpts}
                                        label="Quick Select"
                                        hideLabel
                                        placeholder="Choose Configuration"
                                    />
                                    <span>OR</span>
                                    <Button
                                        onClick={() =>
                                            updateFoundationConfig({
                                                open: true,
                                                currentFormChange: change,
                                                fieldAccessor: `${accessor}.configuration`,
                                                fieldKey: "slabInsConfig",
                                            })
                                        }
                                    >
                                        Select New Configuration
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </InputRow>
                    <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                        <Field
                            component={InputWithUnits}
                            name={`${accessor}.wall.rValues.skirt`}
                            label="Skirt R-Value"
                            placeholder="0.00"
                            validate={skirtValidation}
                            change={change}
                            type="number"
                            disabled={!isSkirtEditable}
                            decimals={getDecimalPlaces("slabSkirtRVal")}
                            units={{
                                base: getBaseUnits("slabSkirtRVal", modelUnits),
                                options: getUnitOptions("thermalResistance"),
                                selected: skirtUnits,
                                unitType: "thermalResistance",
                                accessor: `${accessor}.wall.rValues.skirt_u`,
                            }}
                            warning={isSkirtEditable && skirtValue === 0 ? "slabSkirtBreakZero" : ""}
                        />
                        <Field
                            component={InputWithUnits}
                            name={`${accessor}.wall.rValues.thermalBreak`}
                            label="Thermal Break R-Value"
                            placeholder="0.00"
                            validate={thermalBreakValidation}
                            change={change}
                            type="number"
                            disabled={!isThermalBreak}
                            decimals={getDecimalPlaces("slabThermalBreak")}
                            units={{
                                base: getBaseUnits("slabThermalBreak", modelUnits),
                                options: getUnitOptions("thermalResistance"),
                                selected: thermalBreakUnits,
                                unitType: "thermalResistance",
                                accessor: `${accessor}.wall.rValues.thermalBreak_u`,
                            }}
                            warning={isThermalBreak && thermalBreakValue === 0 ? "slabSkirtBreakZero" : ""}
                        />
                    </InputRow>
                    <h5>Slab Floor</h5>
                    <FloorConstruction accessor={accessor} change={change} id={componentId} />
                    {/* <InputRow lastRow>
                    <Field
                        component={Checkbox}
                        name={`${accessor}.adjacentEnclosedSpace`}
                        label="Adjacent to enclosed unconditioned space"
                        large
                        type="checkbox"
                    />
                </InputRow> */}
                </Accordion>
                <SortableContext
                    items={items}
                    strategy={verticalListSortingStrategy}
                    id={`slab.${componentId}`}
                    disabled={!isDragging ? false : !allowedCategories.includes("slab")}
                >
                    <div
                        className={`${sharedClasses.subComponents} ${
                            items.length === 0 ? sharedClasses.noSubComponents : ""
                        } ${dragActive ? sharedClasses.dragActive : ""}`}
                    >
                        {items.length === 0 && (
                            <span className={sharedClasses.noSubcomponents}>
                                &nbsp;
                                {allowedCategories.includes("slab") && (
                                    <Droppable id={`slab.${componentId}`}></Droppable>
                                )}
                            </span>
                        )}
                        {items.map(({ id, componentId: subComponentId, componentType }, index) => (
                            <div key={subComponentId} className={classes.draggableComponent}>
                                <ComponentType
                                    key={id}
                                    id={id}
                                    index={index + 1}
                                    activeIndex={activeSubIndex}
                                    activeId={activeId}
                                    draggableId={`slab.${componentId}.subcomponents.${componentType}.${subComponentId}`}
                                    typeDrop={"component"}
                                    isDragging={isDragging}
                                    componentId={componentId}
                                    type={componentType}
                                    change={change}
                                    parentAccessor={accessor}
                                    parentPath={`${parentPath}.slab.${componentId}.subcomponents`}
                                    // isDragging={snapshot.isDragging}
                                    allowedCategories={allowedCategories}
                                    clearNewComponent={clearNewComponent}
                                    dragActive={dragActive}
                                />
                            </div>
                        ))}
                    </div>
                </SortableContext>
                <DragOverlay>
                    {activeId && activeSubIndex >= 0 ? (
                        <ComponentType
                            id={activeId}
                            activeId={activeId}
                            countSubComponents={items.length}
                            clone={true}
                            label={items[activeSubIndex].componentLabel}
                            type={items[activeSubIndex].componentType}
                            componentId={items[activeSubIndex].componentId}
                            parentAccessor={accessor}
                            parentPath={`${parentPath}.slab.${componentId}.subcomponents`}
                            items={items}
                            allowedCategories={[]}
                            destination={{}}
                            isDragging={isDragging}
                            typeDrop={"component"}
                        />
                    ) : null}
                </DragOverlay>
            </div>
        );
    },
    isEqual
);
