import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import isEmpty from 'lodash/isEmpty';
import SupplementaryHeating from './';

const mapStateToProps = (
    {
        form,
        model:{
            contentOpen=false,
        }={}
    },
    {
        accessor,
        formName="model"
    }
) => {
    const selector = formValueSelector(formName);
    const modelSelector = formValueSelector('model');

    const modelUnits = modelSelector({form}, `modelData.uiSettings.primaryUnits`) || {};

    const manufacturer = selector({form}, `${accessor}.manufacturer`) || '';
    const model = selector({form}, `${accessor}.model`) || '';

    const { id: energySourceId } = selector({form}, `${accessor}.energySource`) || {};
    const { id: equipTypeId } = selector({form}, `${accessor}.equipmentType`) || {};
    const { id: areaHeatedId, value: areaHeatedValue } = selector({form}, `${accessor}.specifications.areaHeated`) || {};
    const { id: capacityId, value: capacityValue } = selector({form}, `${accessor}.specifications.outputCapacity`) || {};
    const effValue = selector({form}, `${accessor}.specifications.efficiency`);
    const pilotLightValue = selector({form}, `${accessor}.specifications.pilotLight`);
    const flueDiameterValue = selector({form}, `${accessor}.specifications.flue.diameter`);
    const isMonthlyUsage = !isEmpty(selector({form}, `${accessor}.specifications.monthlyUsage`) || {});
    const monthlyUsageUnits = selector({form}, `${accessor}.specifications.monthlyUsage.uiUnits`);

    const capacityUnits = selector({form}, `${accessor}.specifications.outputCapacity_u`);
    const pilotUnits = selector({form}, `${accessor}.specifications.pilotLight_u`);
    const flueUnits = selector({form}, `${accessor}.specifications.flue.diameter_u`);
    const areaHeatedUnits = selector({form}, `${accessor}.specifications.areaHeated_u`);

    return {
        accessor,
        manufacturer,
        model,
        energySourceId,
        equipTypeId,
        effValue,
        capacityId,
        capacityValue,
        areaHeatedId,
        areaHeatedValue,
        capacityUnits,
        pilotLightValue,
        flueDiameterValue,
        pilotUnits,
        flueUnits,
        areaHeatedUnits,
        modelUnits,
        isMonthlyUsage,
        monthlyUsageUnits,
        contentOpen
    }
};

const mapDispatchToProps = () => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SupplementaryHeating);