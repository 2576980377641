
import JSZip from "jszip";
import { saveAs } from "file-saver";

export const saveCbatZipFile = (modelName, data) => {
    let zip = new JSZip();
    const foldername = `${modelName.split(".h2k")[0]}_CBAT_Inputs`
    let atLeastOneFile = false
    Object.keys(data).forEach((resKey) => {
        const filename = `${resKey}.csv`;
        
        if (data[resKey] !== "" && resKey !== "requestNum") {
            atLeastOneFile = true
            zip.folder(foldername).file(filename, data[resKey]);
        }
    });

    if (atLeastOneFile) {
        zip.generateAsync({type: "blob"}).then((blob) => {
            saveAs(blob, `${foldername}.zip`)
        });
    }
    
}