import React from 'react';
import sharedClasses from '../../style.module.scss';
import Tooltip from 'components/Tooltip';

export default React.memo(({
    slabInsLabel,
    slabCodeRef,
    slabRValue,
}) => (
    <div className={sharedClasses.stats} style={{gridTemplateColumns:'1.5fr 1fr 1.5fr 1fr'}}>
        <span
            data-for="slab-stats"
            data-tip={`<span><strong>Slab Insulation:</strong> ${slabInsLabel}</span>`}
            data-html
            className={sharedClasses.clipType}
        >
            <strong>Type:</strong> {slabInsLabel}
        </span>
        <span
            data-for="slab-stats"
            data-tip={`<span><strong>${slabCodeRef.includes('FloorsAdded-') ? 'Nom.' : 'Eff.'} R-Value:</strong> ${slabRValue}</span>`}
            data-html
        >
            <strong>R:</strong> {slabRValue}
        </span>
        {/* <span><strong>A:</strong> {area}</span> */}
        <Tooltip id="slab-stats" />
    </div>
));