import React from "react";
import classes from "../style.module.scss";
import HouseIcon from "assets/images/icons/JSX/House";
import CommunitiesIcon from "assets/images/icons/JSX/CommunitiesIcon";
import Button from "components/Button";
import { useHistory } from "react-router";

export const CreateFirstModel = ({ currentFolderId }) => {
    const history = useHistory();

    return (
        <div className={classes.dashboardEmpty}>
            <div className={classes.singleBox}>
                <div></div>
                <div className={classes.box}>
                    <HouseIcon />
                    <h3>Create your first House Model in this folder</h3>
                    <Button
                        onClick={() =>
                            history.push(`/create${currentFolderId ? `?folderId=${currentFolderId}` : ""}`)
                        }
                    >
                        Create New Model
                    </Button>
                </div>
                <div></div>
            </div>
        </div>
    );
}

export const CreateFirstComm = ({ currentFolderId }) => {
    const history = useHistory();

    return (
        <div className={classes.dashboardEmpty}>
            <div className={classes.singleBox}>
                <div></div>
                <div className={classes.box}>
                    <CommunitiesIcon />
                    <h3>Create your first Community in this folder</h3>
                    <Button
                        onClick={() =>
                            history.push(`/create${currentFolderId ? `?folderId=${currentFolderId}` : ""}`)
                        }
                    >
                        Create New Community
                    </Button>
                </div>
                <div></div>
            </div>
        </div>
    );
}