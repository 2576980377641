import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { AuthProvider } from "features/Auth";
import store from "store";
import "./index.scss";
import App from "components/AppContainer";
import * as serviceWorker from "./serviceWorker";

const app = (
    <Provider store={store}>
        <AuthProvider>
            <Router>
                <App />
            </Router>
        </AuthProvider>
    </Provider>
);

ReactDOM.render(app, document.getElementById("root"));
serviceWorker.unregister();
