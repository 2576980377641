import drawingFields from "utils/settings/drawingFields.json";
import { foundationSubComponentsFactory } from "features/Model/Enclosure/Takeoff/containers/TakeOffFoundation/utils/FoundationSubcomponentsFactory";
import { storeySubComponentsFactory } from "features/Model/Enclosure/Takeoff/containers/TakeOffStorey/utils/storeySubcomponentsFactory";

export const foundationTypes = ["basement", "walkout", "slab", "crawlspace"];

export const storeysTypes = ["foundation", "first", "second", "third", "lower", "upper", "other"];

export const floorAreas = [
    "total floor area & perimeter",
    "basement floor area",
    "crawl space floor area",
    "slab floor area",
];

export const selectOptionsForDropdown = ({ shapeType, foundation, storey }) => {
    const { options } = drawingFields;

    if (foundation) {
        let {
            foundation: { rectangle, polygon, line, multiPointLine },
        } = options;

        // if (foundation === "basement") polygon = [...polygon, "Basement Floor Area", "Pony Wall (Elevation)"];
        if (foundation === "basement") polygon = [...polygon, "Pony Wall (Elevation)"];

        // if (foundation === "walkout" || foundation === "slab")
        //     polygon = [...polygon, "Slab Floor Area", "Pony Wall (Elevation)"];
        if (foundation === "slab") polygon = polygon;

        if (foundation === "crawlspace") polygon = [...polygon, "Crawl space Floor Area"];

        if (foundation === "walkout")
            polygon = [...polygon, "Basement Floor Area", "Slab Floor Area", "Pony Wall (Elevation)"];

        if (shapeType === "polygon") return polygon;

        if (shapeType === "rectangle") return [...polygon, ...rectangle];

        if (shapeType === "line") return line;

        if (shapeType === "multiPointLine") return multiPointLine;
    }

    if (storey) {
        const {
            storey: { rectangle, polygon, line, multiPointLine },
        } = options;

        if (shapeType === "polygon") return [...polygon, "Attic Ceiling (Floor Plan)"];

        if (shapeType === "rectangle") return [...polygon, ...rectangle];

        if (shapeType === "line") return [...line, "Floor Header Height"];

        if (shapeType === "multiPointLine") return [...multiPointLine, "Floor Header Height"];
    }
};

export const changeFoundationValue = (dispatch, origin, value, unit = "") => {
    dispatch({
        type: "UPDATE_TAKEOFF_COMPONENT",
        data: {
            origin: origin,
            value: value,
            unit: unit,
        },
    });
};

export const changeStoreyValue = (dispatch, origin, value, unit = "", component) => {
    dispatch({
        type: "UPDATE_TAKEOFF_COMPONENT",
        data: {
            origin: origin,
            value: value,
            unit: unit,
            component: component,
        },
    });
};

export const addTakeoffSubComponent = (dispatch, parent, type, compFields) => {
    dispatch({
        type: "ADD_TAKEOFF_SUBCOMP",
        data: {
            parent,
            component: foundationSubComponentsFactory(type, compFields),
        },
    });
};

export const addTableComponent = (dispatch, parent, component) => {
    dispatch({
        type: "ADD_TOFF_TABLE_COMP",
        data: {
            parent,
            component: component,
        },
    });
};

export const editTableComponent = (dispatch, parent, id, field, value, unit = "") => {
    dispatch({
        type: "EDIT_TOFF_TABLE_COMP",
        data: {
            parent,
            id: id,
            field: field,
            value: value,
            unit: unit,
        },
    });
};

export const editSubComponent = (dispatch, id, parent, field, value, unit = "") => {
    dispatch({
        type: "EDIT_TAKEOFF_SUBCOMP",
        data: {
            parent,
            id: id,
            field: field,
            value: value,
            unit: unit,
        },
    });
};

export const addSubComponent = (dispatch, parent, type, storeyFields) => {
    dispatch({
        type: "ADD_TAKEOFF_SUBCOMP",
        data: {
            parent,
            component: storeySubComponentsFactory(type, storeyFields),
        },
    });
};
