import React, { useState } from "react";
import classes from "../style.module.scss";
import { useLocation } from "react-router";
import { useOutsideClickHook } from "utils/outsideClick";
import AddIcon from "assets/images/icons/JSX/Add";
import Dropdown from "components/Dropdown";
import { CreateDialog } from "components/Dashboard/Actions";

const DashboardTableHeader = ({
    uid,
    columns,
    folderLoading,
    createFolder,
    isRenoComm,
}) => {
    const { pathname } = useLocation();
    const path = pathname.split("/").filter((str) => str !== "" && str !== "dashboard" && str !== "communities") || [];
    const currentFolderId = path[path.length - 1] || null;

    const [isCreateOpen, setIsCreateOpen] = useState(false);
    const [toCreate, setToCreate] = useState(false);
    const [folderName, setFolderName] = useState("");

    const createRef = useOutsideClickHook(() => setIsCreateOpen(false));

    let createNewOptions = [
        {
            label: "New Community",
            onClick: () => {
                // history.push(`/create${currentFolderId ? `?folderId=${currentFolderId}` : ""}`);
                setIsCreateOpen(false);
            },
        },
    ];

    if (path.length <= 6) {
        createNewOptions = [
            ...createNewOptions,
            {
                label: "New Folder",
                onClick: () => {
                    setToCreate(true);
                    setIsCreateOpen(false);
                },
            },
        ];
    }

    const className = isRenoComm ? classes['renoComm'] : classes[`col${Object.keys(columns).length}`];
    const { actions: actionsLabel, ...itemDetails} = columns;

    return (
        <div className={`${classes.tableHead} ${className}`}>
            {Object.entries(itemDetails).map(([key, el]) => (
                <div key={key}> {el} </div>
            ))}
            {actionsLabel &&
                <div className={classes.createNew} ref={createRef}>
                    <span onClick={() => setIsCreateOpen(!isCreateOpen)}>
                        { actionsLabel } <AddIcon />
                    </span>
                    <Dropdown
                        className={classes.createDropdown}
                        open={isCreateOpen}
                        options={createNewOptions}
                    />
                    <CreateDialog
                        toCreate={toCreate}
                        newFolderName={folderName}
                        setNewFolderName={(newVal) => setFolderName(newVal)}
                        onClick={async () => {
                            await createFolder(uid, folderName, currentFolderId);
                            setToCreate(false);
                            setFolderName("");
                        }}
                        close={() => setToCreate(false)}
                        folderLoading={folderLoading}
                    />
                </div>
            }
        </div>
    );
}

export default DashboardTableHeader;