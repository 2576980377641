import React from "react";
import classes from "./style.module.scss";
import sharedClasses from "features/Model/sharedStyles.module.scss";
import { Field } from "redux-form";
import { getOptions, getBaseUnits, getUnitOptions } from "utils/fields";
import { maxLength, getValidation, getDecimalPlaces } from "utils/fieldValidation";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import InputWithUnits from "components/Input/InputWithUnits";
import Select from "components/Input/Select";
import Checkbox from "components/Input/Checkbox";
import Accordion from "components/Accordion";
import classNames from "classnames";

const charLim100 = maxLength(100);
const ashpFunctionValidation = getValidation("ashpFunction");
const ashpEquipTypeValidation = getValidation("ashpEquipType");
const ashpCapacityValidation = getValidation("ashpOutputCapacity");
const ashpHeatingEffValidation = getValidation("ashpHeatingEff");
const ashpCoolingEffValidation = getValidation("ashpCoolingEff");
const ashpCrankcaseHeaterValidation = getValidation("ashpCrankcaseHeater");
const sensibleHeatRatioValidation = getValidation("ashpSensibleHeatRatio");
const openableWindowAreaValidation = getValidation("ashpOpenableWindowArea");
const ashpTempCutoffValidation = getValidation("ashpTempCutoff");
const ashpTempRatingValidation = getValidation("ashpTempRatingType");
const ashpPerformanceTempValidation = getValidation("ashpPerformanceTemp");
const ashpPerformanceCopValidation = getValidation("ashpPerformanceCop");
const ashpPerformanceCapacityValidation = getValidation("ashpPerformanceCapacity");

const ccashpHeatingEffValidation = getValidation("ccashpHeatingEfficiency");
const ccashpCoolingEffValidation = getValidation("ccashpCoolingEfficiency");
const ccashpCapacityValidation = getValidation("ccashpCapacity");
const ccashpCopValidation = getValidation("ccashpCop");
const ccashpCapacityMaintenanceValidation = getValidation("ccashpCapacityMaintenance");

const ahriValidation = getValidation("ahriNumber");
const numHeadsValidation = getValidation("ashpMiniSplitNumHeads");

export default ({
    accessor,
    formName,
    functionId,
    equipTypeId,
    capacityId,
    capacityValue,
    capacityUnits,
    crankcaseHeaterUnits,
    openableWindowAreaUnits,
    ashpHighHeatTempUnits,
    ashpMidHeatTempUnits,
    ashpLowHeatTempUnits,
    ashpHighCoolTempUnits,
    ashpLowCoolTempUnits,
    ashpHighHeatCapUnits,
    ashpMidHeatCapUnits,
    ashpLowHeatCapUnits,
    ashpHighCoolCapUnits,
    ashpLowCoolCapUnits,
    tempCutoffId,
    tempCutoffValue,
    tempCutoffUnits,
    tempRatingId,
    tempRatingValue,
    tempRatingUnits,
    modelUnits,
    change,
    h2kMinor,
    lowHeatingEff,
    isColdClimate,
    hasColdClimateSection,
}) => {
    const handleCcashpChange = (e) => {
        if (e.target.value && !hasColdClimateSection) {
            change(`${accessor}.system.airHeatPump.coldClimate`, {
                heatingEfficiency: 0,
                coolingEfficiency: 0,
                capacity: 0,
                cop: 0,
                capacityMaintenance: 0,
            });
        }
    };

    return (
        <>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Field
                    component={Input}
                    name={`${accessor}.system.airHeatPump.manufacturer`}
                    type="text"
                    label="Manufacturer Name"
                    placeholder="Enter Manufacturer Name"
                    validate={charLim100}
                />
                <Field
                    component={Input}
                    name={`${accessor}.system.airHeatPump.model`}
                    type="text"
                    label="Model Name"
                    placeholder="Enter Model Name"
                    validate={charLim100}
                />
                {h2kMinor >= 11 && (
                    <Field
                        component={Input}
                        type="number"
                        name={`${accessor}.system.airHeatPump.ahri`}
                        label="AHRI Reference Number"
                        placeholder="0"
                        validate={ahriValidation}
                        decimals={getDecimalPlaces(`ahriNumber`)}
                    />
                )}
                <Field
                    component={Checkbox}
                    name={`${accessor}.system.airHeatPump.energyStar`}
                    label="Energy Star"
                    large
                    className={classes.checkbox}
                    type="checkbox"
                />
            </InputRow>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Field
                    component={Select}
                    name={`${accessor}.system.airHeatPump.function`}
                    options={getOptions({ fieldKey: "ashpFunction" })}
                    label="Unit Function"
                    placeholder="Choose Unit Function"
                    validate={ashpFunctionValidation}
                    info={"Note that justification must be provided if a heat pump is set to Heating only."}
                />
                <Field
                    component={Select}
                    name={`${accessor}.system.airHeatPump.equipmentType`}
                    options={getOptions({ fieldKey: "ashpEquipType" })}
                    label="Central Equipment Type"
                    placeholder="Choose Equipment Type"
                    validate={ashpEquipTypeValidation}
                />
                {h2kMinor >= 11 && equipTypeId === 2 && (
                    <Field
                        component={Input}
                        type="number"
                        name={`${accessor}.system.airHeatPump.numberOfHeads`}
                        label="Number of Heads"
                        placeholder="1"
                        validate={numHeadsValidation}
                        decimals={getDecimalPlaces(`ashpMiniSplitNumHeads`)}
                    />
                )}
                {h2kMinor >= 11 && (
                    <Field
                        component={Checkbox}
                        name={`${accessor}.system.airHeatPump.ccashp`}
                        label="Cold Climate ASHP"
                        large
                        className={classes.checkbox}
                        type="checkbox"
                        onChange={handleCcashpChange}
                    />
                )}
            </InputRow>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr 1fr">
                <Field
                    component={Select}
                    name={`${accessor}.system.airHeatPump.specifications.outputCapacity`}
                    options={[
                        {
                            label: "Calculated",
                            value: {
                                id: 0,
                                value: capacityValue,
                                uiUnits: "kW",
                            },
                        },
                        {
                            label: "User Specified",
                            value: {
                                id: 1,
                                value: capacityValue,
                                uiUnits: "kW",
                            },
                        },
                        {
                            label: "1.25 x cooling load",
                            value: {
                                id: 2,
                                value: capacityValue,
                                uiUnits: "kW",
                            },
                        },
                    ]}
                    label="Capacity Type"
                    placeholder="Choose Input Type"
                    validate={ashpCapacityValidation}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.system.airHeatPump.specifications.outputCapacity.value`}
                    label="Output Capacity"
                    placeholder="0.00"
                    validate={ashpCapacityValidation}
                    decimals={getDecimalPlaces(`ashpOutputCapacity`)}
                    change={change}
                    disabled={capacityId !== 1}
                    units={{
                        base: getBaseUnits(`ashpOutputCapacity`, modelUnits),
                        options: getUnitOptions("power"),
                        selected: capacityUnits,
                        unitType: "power",
                        accessor: `${accessor}.system.airHeatPump.specifications.outputCapacity_u`,
                    }}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.system.airHeatPump.crankcaseHeater`}
                    label="Crankcase Heater"
                    placeholder="0.00"
                    validate={ashpCrankcaseHeaterValidation}
                    decimals={getDecimalPlaces(`ashpCrankcaseHeater`)}
                    change={change}
                    disabled={capacityId !== 1}
                    units={{
                        base: getBaseUnits(`ashpCrankcaseHeater`, modelUnits),
                        options: getUnitOptions("power"),
                        selected: crankcaseHeaterUnits,
                        unitType: "power",
                        accessor: `${accessor}.system.airHeatPump.crankcaseHeater_u`,
                    }}
                />
                <Field
                    component={Input}
                    type="number"
                    name={`${accessor}.system.airHeatPump.specifications.coolingParams.sensibleHeatRatio`}
                    label="Sensible Heat Ratio"
                    placeholder="0.00"
                    validate={sensibleHeatRatioValidation}
                    decimals={getDecimalPlaces(`ashpSensibleHeatRatio`)}
                    disabled={functionId === 0}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.system.airHeatPump.specifications.coolingParams.openableWindowArea`}
                    label="Openable Window Area"
                    placeholder="0.00"
                    validate={openableWindowAreaValidation}
                    decimals={getDecimalPlaces(`ashpOpenableWindowArea`)}
                    change={change}
                    disabled={functionId === 0}
                    units={{
                        base: getBaseUnits(`ashpOpenableWindowArea`, modelUnits),
                        options: getUnitOptions("fraction"),
                        selected: openableWindowAreaUnits,
                        unitType: "fraction",
                        accessor: `${accessor}.system.airHeatPump.specifications.coolingParams.openableWindowArea_u`,
                    }}
                    info={"This field must be left at the default value (0%) for all EnerGuide files."}
                />
            </InputRow>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Field
                    component={Input}
                    type="number"
                    name={`${accessor}.system.airHeatPump.specifications.heatingEfficiency.value`}
                    label="Heating Efficiency"
                    placeholder="0.00"
                    validate={ashpHeatingEffValidation}
                    decimals={getDecimalPlaces("ashpHeatingEff")}
                    info={
                        "The HSPF for Region V should be used. If only the value for Region IV is known, divide that value by 1.15 and enter."
                    }
                    warning={lowHeatingEff ? "energyStarAshpLowHspf" : ""}
                />
                <Field
                    component={Select}
                    name={`${accessor}.system.airHeatPump.specifications.heatingEfficiency.isCop`}
                    options={[
                        {
                            label: "COP",
                            value: true,
                        },
                        {
                            label: "HSPF",
                            value: false,
                        },
                    ]}
                    label="Heating Efficiency Type"
                    placeholder="Choose Efficiency Type"
                />
                <Field
                    component={Input}
                    type="number"
                    name={`${accessor}.system.airHeatPump.specifications.coolingEfficiency.value`}
                    label="Cooling Efficiency"
                    placeholder="0.00"
                    validate={ashpCoolingEffValidation}
                    decimals={getDecimalPlaces("ashpCoolingEff")}
                    disabled={functionId === 0}
                    info={"If the value is unknown, enter a value of 10 SEER."}
                />
                <Field
                    component={Select}
                    name={`${accessor}.system.airHeatPump.specifications.coolingEfficiency.isCop`}
                    options={[
                        {
                            label: "COP",
                            value: true,
                        },
                        {
                            label: "SEER",
                            value: false,
                        },
                    ]}
                    label="Cooling Efficiency Type"
                    placeholder="Choose Efficiency Type"
                    disabled={functionId === 0}
                />
            </InputRow>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Field
                    component={Select}
                    name={`${accessor}.system.airHeatPump.temperature.cutoffType`}
                    options={[
                        {
                            label: "Balance point",
                            value: {
                                id: 0,
                            },
                        },
                        {
                            label: "Restricted",
                            value: {
                                id: 1,
                                value: tempCutoffValue,
                            },
                        },
                        {
                            label: "Unrestricted",
                            value: {
                                id: 2,
                            },
                        },
                    ]}
                    label="Temperature Cutoff Type"
                    placeholder="Choose Cutoff Type"
                    validate={ashpTempCutoffValidation}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.system.airHeatPump.temperature.cutoffType.value`}
                    label="Cutoff Temperature"
                    placeholder="0.00"
                    validate={ashpTempCutoffValidation}
                    decimals={getDecimalPlaces(`ashpTempCutoff`)}
                    change={change}
                    disabled={tempCutoffId !== 1}
                    units={{
                        base: getBaseUnits(`ashpTempCutoff`, modelUnits),
                        options: getUnitOptions("temperature"),
                        selected: tempCutoffUnits,
                        unitType: "temperature",
                        accessor: `${accessor}.system.airHeatPump.temperature.cutoffType_u`,
                    }}
                />
                <Field
                    component={Select}
                    name={`${accessor}.system.airHeatPump.temperature.ratingType`}
                    options={[
                        ...getOptions({ fieldKey: "ashpTempRatingType" }),
                        {
                            label: "User Specified",
                            value: {
                                id: 2,
                                value: tempRatingValue,
                            },
                        },
                    ]}
                    label="Temperature Rating Type"
                    placeholder="Choose Rating Type"
                    validate={ashpTempRatingValidation}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.system.airHeatPump.temperature.ratingType.value`}
                    label="Rating Temperature"
                    placeholder="0.00"
                    validate={ashpTempRatingValidation}
                    decimals={getDecimalPlaces(`ashpTempRatingType`)}
                    change={change}
                    disabled={tempRatingId !== 2}
                    setValue={tempRatingValue}
                    units={{
                        base: getBaseUnits(`ashpTempRatingType`, modelUnits),
                        options: getUnitOptions("temperature"),
                        selected: tempRatingUnits,
                        unitType: "temperature",
                        accessor: `${accessor}.system.airHeatPump.temperature.ratingType_u`,
                    }}
                />
            </InputRow>
            {h2kMinor >= 11 && isColdClimate && (
                <Accordion
                    className={sharedClasses.accordion}
                    heading={<span className={sharedClasses.heading}>Cold Climate Heat Pump Specifications</span>}
                    large
                >
                    <InputRow gridTemplate="1fr 1fr 1fr">
                        <Field
                            component={Input}
                            type="number"
                            name={`${accessor}.system.airHeatPump.coldClimate.heatingEfficiency`}
                            label="Heating Efficiency (HSPF)"
                            placeholder="0.00"
                            validate={ccashpHeatingEffValidation}
                            decimals={getDecimalPlaces("ccashpHeatingEfficiency")}
                            warning={lowHeatingEff ? "energyStarAshpLowHspf" : ""}
                        />
                        <Field
                            component={Input}
                            type="number"
                            name={`${accessor}.system.airHeatPump.coldClimate.coolingEfficiency`}
                            label="Cooling Efficiency (SEER)"
                            placeholder="0.00"
                            validate={ccashpCoolingEffValidation}
                            decimals={getDecimalPlaces("ccashpCoolingEfficiency")}
                            disabled={functionId === 0}
                        />
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.system.airHeatPump.coldClimate.capacity`}
                            label="Heating Capacity"
                            placeholder="0.00"
                            validate={ccashpCapacityValidation}
                            decimals={getDecimalPlaces(`ccashpCapacity`)}
                            change={change}
                            units={{
                                base: getBaseUnits(`ccashpCapacity`, modelUnits),
                                options: getUnitOptions("power"),
                                selected: capacityUnits,
                                unitType: "power",
                                accessor: `${accessor}.system.airHeatPump.coldClimate.capacity_u`,
                            }}
                        />
                    </InputRow>
                    <InputRow gridTemplate="1fr 1fr 1fr" className={sharedClasses.bottomAlign}>
                        <Field
                            component={Input}
                            type="number"
                            name={`${accessor}.system.airHeatPump.coldClimate.cop`}
                            label="COP at -15˚C (5˚F)"
                            placeholder="0.00"
                            validate={ccashpCopValidation}
                            decimals={getDecimalPlaces("ccashpCop")}
                        />
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.system.airHeatPump.coldClimate.capacityMaintenance`}
                            label="Capacity Maintenance [Max -15˚C (5˚F) / Rated 8.3˚C (47˚F)]"
                            placeholder="0.00"
                            validate={ccashpCapacityMaintenanceValidation}
                            decimals={getDecimalPlaces(`ccashpCapacityMaintenance`)}
                            change={change}
                            units={{
                                base: getBaseUnits(`ccashpCapacityMaintenance`, modelUnits),
                                options: ["%"],
                                selected: "%",
                                unitType: "fraction",
                                accessor: `${accessor}.system.airHeatPump.coldClimate.capacityMaintenance_u`,
                            }}
                        />
                    </InputRow>
                </Accordion>
            )}
            <h4 style={{ marginBottom: "1rem", marginTop: "1.75rem" }}>ASHP Performance Curves</h4>
            <div style={{ marginBottom: "1.25rem" }}>
                <p style={{ fontSize: "14px" }}>
                    NEEP performance curves only impact hourly load estimates, and do not impact HOT2000 simulation
                    results.
                </p>
            </div>

            <div
                className={classNames(classes.performanceWrapper, {
                    [classes.performanceVertical]: formName === "upgradeDrawer",
                })}
            >
                <div className={classes.performanceContainer}>
                    <h5 style={{ marginBottom: "1.25rem" }}>Heating Performance</h5>
                    <InputRow gridTemplate="1fr 1fr 1fr">
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.system.airHeatPump.performanceCurve.heating.highHeating.temperature`}
                            label="High Temperature"
                            placeholder="0.00"
                            validate={ashpPerformanceTempValidation}
                            decimals={getDecimalPlaces(`ashpPerformanceTemp`)}
                            change={change}
                            units={{
                                base: getBaseUnits(`ashpPerformanceTemp`, modelUnits),
                                options: getUnitOptions("temperature"),
                                selected: ashpHighHeatTempUnits,
                                unitType: "temperature",
                                accessor: `${accessor}.system.airHeatPump.performanceCurve.heating.highHeating.temperature_u`,
                            }}
                            info={"Typically 8.3˚C (47˚F)"}
                        />
                        <Field
                            component={Input}
                            type="number"
                            name={`${accessor}.system.airHeatPump.performanceCurve.heating.highHeating.cop`}
                            label="COP"
                            placeholder="0.00"
                            change={change}
                            validate={ashpPerformanceCopValidation}
                            decimals={getDecimalPlaces("ashpPerformanceCop")}
                        />
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.system.airHeatPump.performanceCurve.heating.highHeating.maxCapacity`}
                            label="Max Capacity"
                            placeholder="0.00"
                            validate={ashpPerformanceCapacityValidation}
                            decimals={getDecimalPlaces(`ashpPerformanceCapacity`)}
                            change={change}
                            units={{
                                base: getBaseUnits(`ashpPerformanceCapacity`, modelUnits),
                                options: getUnitOptions("power"),
                                selected: ashpHighHeatCapUnits,
                                unitType: "power",
                                accessor: `${accessor}.system.airHeatPump.performanceCurve.heating.highHeating.maxCapacity_u`,
                            }}
                        />
                    </InputRow>
                    <InputRow gridTemplate="1fr 1fr 1fr">
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.system.airHeatPump.performanceCurve.heating.midHeating.temperature`}
                            label="Middle Temperature"
                            placeholder="0.00"
                            validate={ashpPerformanceTempValidation}
                            decimals={getDecimalPlaces(`ashpPerformanceTemp`)}
                            change={change}
                            units={{
                                base: getBaseUnits(`ashpPerformanceTemp`, modelUnits),
                                options: getUnitOptions("temperature"),
                                selected: ashpMidHeatTempUnits,
                                unitType: "temperature",
                                accessor: `${accessor}.system.airHeatPump.performanceCurve.heating.midHeating.temperature_u`,
                            }}
                            info={"Typically -8.3˚C (17˚F)"}
                        />
                        <Field
                            component={Input}
                            type="number"
                            name={`${accessor}.system.airHeatPump.performanceCurve.heating.midHeating.cop`}
                            label="COP"
                            placeholder="0.00"
                            validate={ashpPerformanceCopValidation}
                            decimals={getDecimalPlaces(`ashpPerformanceCop`)}
                            change={change}
                        />
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.system.airHeatPump.performanceCurve.heating.midHeating.maxCapacity`}
                            label="Max Capacity"
                            placeholder="0.00"
                            validate={ashpPerformanceCapacityValidation}
                            decimals={getDecimalPlaces(`ashpPerformanceCapacity`)}
                            change={change}
                            units={{
                                base: getBaseUnits(`ashpPerformanceCapacity`, modelUnits),
                                options: getUnitOptions("power"),
                                selected: ashpMidHeatCapUnits,
                                unitType: "power",
                                accessor: `${accessor}.system.airHeatPump.performanceCurve.heating.midHeating.maxCapacity_u`,
                            }}
                        />
                    </InputRow>
                    <InputRow gridTemplate="1fr 1fr 1fr">
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.system.airHeatPump.performanceCurve.heating.lowHeating.temperature`}
                            label="Low Temperature"
                            placeholder="0.00"
                            validate={ashpPerformanceTempValidation}
                            decimals={getDecimalPlaces(`ashpPerformanceTemp`)}
                            change={change}
                            units={{
                                base: getBaseUnits(`ashpPerformanceTemp`, modelUnits),
                                options: getUnitOptions("temperature"),
                                selected: ashpLowHeatTempUnits,
                                unitType: "temperature",
                                accessor: `${accessor}.system.airHeatPump.performanceCurve.heating.lowHeating.temperature_u`,
                            }}
                            info={"Typically -15˚C (5˚F)"}
                        />
                        <Field
                            component={Input}
                            type="number"
                            name={`${accessor}.system.airHeatPump.performanceCurve.heating.lowHeating.cop`}
                            label="COP"
                            placeholder="0.00"
                            validate={ashpPerformanceCopValidation}
                            decimals={getDecimalPlaces(`ashpPerformanceCop`)}
                            change={change}
                        />
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.system.airHeatPump.performanceCurve.heating.lowHeating.maxCapacity`}
                            label="Max Capacity"
                            placeholder="0.00"
                            validate={ashpPerformanceCapacityValidation}
                            decimals={getDecimalPlaces(`ashpPerformanceCapacity`)}
                            change={change}
                            units={{
                                base: getBaseUnits(`ashpPerformanceCapacity`, modelUnits),
                                options: getUnitOptions("power"),
                                selected: ashpLowHeatCapUnits,
                                unitType: "power",
                                accessor: `${accessor}.system.airHeatPump.performanceCurve.heating.lowHeating.maxCapacity_u`,
                            }}
                        />
                    </InputRow>
                </div>
                <div className={classes.performanceContainer}>
                    <h5 style={{ marginBottom: "1.25rem" }}>Cooling Performance</h5>
                    <InputRow gridTemplate="1fr 1fr 1fr">
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.system.airHeatPump.performanceCurve.cooling.highCooling.temperature`}
                            label="High Temperature"
                            placeholder="0.00"
                            validate={ashpPerformanceTempValidation}
                            decimals={getDecimalPlaces(`ashpPerformanceTemp`)}
                            change={change}
                            units={{
                                base: getBaseUnits(`ashpPerformanceTemp`, modelUnits),
                                options: getUnitOptions("temperature"),
                                selected: ashpHighCoolTempUnits,
                                unitType: "temperature",
                                accessor: `${accessor}.system.airHeatPump.performanceCurve.cooling.highCooling.temperature_u`,
                            }}
                            info={"Typically 35˚C (95˚F)"}
                        />
                        <Field
                            component={Input}
                            type="number"
                            name={`${accessor}.system.airHeatPump.performanceCurve.cooling.highCooling.cop`}
                            label="COP"
                            placeholder="0.00"
                            validate={ashpPerformanceCopValidation}
                            decimals={getDecimalPlaces(`ashpPerformanceCop`)}
                            change={change}
                        />
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.system.airHeatPump.performanceCurve.cooling.highCooling.maxCapacity`}
                            label="Max Capacity"
                            placeholder="0.00"
                            validate={ashpPerformanceCapacityValidation}
                            decimals={getDecimalPlaces(`ashpPerformanceCapacity`)}
                            change={change}
                            units={{
                                base: getBaseUnits(`ashpPerformanceCapacity`, modelUnits),
                                options: getUnitOptions("power"),
                                selected: ashpHighCoolCapUnits,
                                unitType: "power",
                                accessor: `${accessor}.system.airHeatPump.performanceCurve.cooling.highCooling.maxCapacity_u`,
                            }}
                        />
                    </InputRow>
                    <InputRow gridTemplate="1fr 1fr 1fr">
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.system.airHeatPump.performanceCurve.cooling.lowCooling.temperature`}
                            label="Low Temperature"
                            placeholder="0.00"
                            validate={ashpPerformanceTempValidation}
                            decimals={getDecimalPlaces(`ashpPerformanceTemp`)}
                            change={change}
                            units={{
                                base: getBaseUnits(`ashpPerformanceTemp`, modelUnits),
                                options: getUnitOptions("temperature"),
                                selected: ashpLowCoolTempUnits,
                                unitType: "temperature",
                                accessor: `${accessor}.system.airHeatPump.performanceCurve.cooling.lowCooling.temperature_u`,
                            }}
                            info={"Typically 27.78˚C (82˚F)"}
                        />
                        <Field
                            component={Input}
                            type="number"
                            name={`${accessor}.system.airHeatPump.performanceCurve.cooling.lowCooling.cop`}
                            label="COP"
                            placeholder="0.00"
                            validate={ashpPerformanceCopValidation}
                            decimals={getDecimalPlaces(`ashpPerformanceCop`)}
                            change={change}
                        />
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.system.airHeatPump.performanceCurve.cooling.lowCooling.maxCapacity`}
                            label="Max Capacity"
                            placeholder="0.00"
                            validate={ashpPerformanceCapacityValidation}
                            decimals={getDecimalPlaces(`ashpPerformanceCapacity`)}
                            change={change}
                            units={{
                                base: getBaseUnits(`ashpPerformanceCapacity`, modelUnits),
                                options: getUnitOptions("power"),
                                selected: ashpLowCoolCapUnits,
                                unitType: "power",
                                accessor: `${accessor}.system.airHeatPump.performanceCurve.cooling.lowCooling.maxCapacity_u`,
                            }}
                        />
                    </InputRow>
                </div>
            </div>
        </>
    );
};
