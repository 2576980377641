import React from "react";

import styles from "./styles.module.scss";

const ButtonTips = ({ btnToPress, btnInfo }) => {
    return (
        <div className={styles.buttonTipContainer}>
            <span className={styles.buttonTipsText}>
                Hold <kbd>{btnToPress}</kbd> {btnInfo}
            </span>
        </div>
    );
};

export default ButtonTips;
