import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import ExpFloor from './';
import { getBaseUnits, unitLabels } from 'utils/fields';
import convertUnit from 'utils/convertUnit';

const mapStateToProps = (
    {
        form
    },
    {
        accessor
    }
) => {
    const selector = formValueSelector('model');
    const expFloorLength = selector({form}, `${accessor}.measurements.length`) || {};
    const expFloorArea = selector({form}, `${accessor}.measurements.area`) || {};
    const { codeLabel: expFloorLabel } = selector({form}, `${accessor}.expFloorInsType`) || {};
    const expFloorNomRVal  = selector({form}, `${accessor}.expFloorInsType_nomRVal`) || 0;
    const expFloorEffRVal  = selector({form}, `${accessor}.expFloorInsType_effRVal`) || 0;

    const lengthUnits = selector({form}, `${accessor}.measurements.length_u`);
    const areaUnits = selector({form}, `${accessor}.measurements.area_u`);
    const rValUnits = selector({form}, `${accessor}.expFloorInsType_u`);
    const modelUnits = selector({form}, `modelData.uiSettings.primaryUnits`);

    const { trueBase:rValBase, displayBase:rValDisplay } = getBaseUnits('expFloorInsType', modelUnits);
    const { trueBase:lengthBase, displayBase:lengthDisplay } = getBaseUnits('expFloorLength', modelUnits);
    const { trueBase:areaBase, displayBase:areaDisplay } = getBaseUnits('expFloorArea', modelUnits);

    const lengthU = lengthUnits || lengthDisplay || lengthBase;
    const areaU = areaUnits || areaDisplay || areaBase;
    const rValU = rValUnits || rValDisplay || rValBase;
    
    return {
        codeLabel:expFloorLabel,
        rValue:`${parseFloat(convertUnit({
            value:expFloorLabel === 'User Specified' ? expFloorEffRVal : expFloorNomRVal,
            type:'thermalResistance',
            inputUnit:rValBase,
            outputUnit:rValU,
        })).toFixed(1)}${rValU}`,
        length:`${parseFloat(convertUnit({
            value:expFloorLength,
            type:'length',
            inputUnit:lengthBase,
            outputUnit:lengthU,
        })).toFixed(1)}${lengthU}`,
        floorArea:`${parseFloat(convertUnit({
            value:expFloorArea,
            type:'area',
            inputUnit:areaBase,
            outputUnit:areaU,
        })).toFixed(1)}${unitLabels(areaU)}`,
    }
};

const mapDispatchToProps = () => ({});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ExpFloor);