import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import WallConstruction from "./";
import { actions } from "features/Model/Enclosure/_ducks";

const { setInitCode, updateCodeDrawer, updateCompositeCalc } = actions;

const mapStateToProps = (
    {
        form,
        user: { codeLib: { CrawlspaceWall: { codes = {} } = {}, Lintel: { codes: lintelCodes = {} } = {} } = {} },
        enclosure: { calculatingRValue = {} } = {},
        upgradeLibrary: { currentPackage = {} },
    },
    { accessor, formName = "model", componentAccessor = accessor, id, selectedUpgrade }
) => {
    const selector = formValueSelector(formName);
    const modelSelector = formValueSelector("model");

    const modelCodes =
        currentPackage?.upgrades?.[selectedUpgrade]?.codes || modelSelector({ form }, "modelData.codes") || {};
    const modelUnits = modelSelector({ form }, `modelData.uiSettings.primaryUnits`) || {};
    const { type: crawlspaceInsConfig } = modelSelector({ form }, `${componentAccessor}.configuration`) || {};

    const { codeRef: selectedCodeRef } = selector({ form }, `${accessor}.wall.crawlspaceWallInsType`) || {};
    const selectedNomRValue = selector({ form }, `${accessor}.wall.crawlspaceWallInsType_nomRVal`) || 0;
    const selectedEffRValue = selector({ form }, `${accessor}.wall.crawlspaceWallInsType_effRVal`) || 0;
    const compositeEffRValue = selector({ form }, `${accessor}.wall.crawlspaceWallInsType_composite.effRVal`) || 0;
    const crawlWallCodeWarning = selector({ form }, `${accessor}.wall.crawlspaceWallInsType_warning`) || "";
    const { codeRef: selectedLintelCodeRef } = selector({ form }, `${accessor}.wall.lintelInsType`) || {};
    const wallRValUnits = selector({ form }, `${accessor}.wall.crawlspaceWallInsType_u`);

    const { [id]: { crawlspaceWallInsType: isCalculatingRValue = false } = {} } = calculatingRValue;

    return {
        id,
        accessor,
        libCodes: codes,
        libLintelCodes: lintelCodes,
        modelCodes,
        selectedCodeRef,
        selectedLintelCodeRef,
        modelUnits,
        wallRValUnits,
        selectedNomRValue,
        selectedEffRValue,
        crawlspaceInsConfig,
        crawlWallCodeWarning,
        isCalculatingRValue,
        compositeEffRValue,
    };
};

const mergeProps = (state, dispatch, own) => ({
    ...state,
    ...dispatch,
    ...own,
});

const mapDispatchToProps = (dispatch) => ({
    setInitCode: async (code, type) => {
        return dispatch(setInitCode(code, type));
    },
    updateCodeDrawer: (updates) => dispatch(updateCodeDrawer({ updates })),
    updateCompositeCalc: (updates) => dispatch(updateCompositeCalc(updates)),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(WallConstruction);
