import React from "react";
import classes from "./style.module.scss";
import sharedClasses from "../style.module.scss";

export default () => {
    return (
        <>
            <p>
                Use the Mechanicals Tab to input information about a house's Heating, Cooling, Ventilation, Hot Water,
                and Generation systems. Each of these systems is broken out into its own sub-tab. The Temperatures and
                Base Loads screens from HOT2000 are also included as sub-tabs.
            </p>
            <h3>What is different from HOT2000?</h3>
            <p>
                <strong>Energy Star Systems.</strong> Some system types (Furnace, Boiler, AC, Heat Pumps, Hot Water,
                HRVs/ERVs) can be automatically populated based on the data from the Energy Star database. Only models
                available in Canada have been included in these searchable lists.
            </p>
            <p>
                <strong>CSA F280-12 Inputs.</strong> The following inputs do not impact the results of a HOT2000
                simulation, but are used to improve the accuracy of F280 heat loss and heat gain estimates:
            </p>
            <ul>
                <li>
                    <strong>Hydronic Heating System Distribution.</strong> If a hydronic distribution system passes
                    through an unconditioned space, details can be provided in the Heating Systems sub-tab.
                </li>
                <li>
                    <strong>Ductwork Specifications.</strong> If ductwork passes through any unconditioned space,
                    details can be provided in the Ventilation sub-tab.
                </li>
                <li>
                    <strong>Below grade set points.</strong> Separate indoor set points can be specified for basements
                    and heated crawlspaces in the Temperatures sub-tab.
                </li>
                <li>
                    <strong>Indoor set point overrides.</strong> Users can override the indoor set points specified by
                    the CSA F280-12 standard. This feature should be used carefully, as altering these set points will
                    produce results that are not compliant with F280 design conditions, but may be informative for other
                    purposes.{" "}
                </li>
            </ul>
            <p>
                <strong>Air Source Heat Pump Performance Curves.</strong> Users can enter performance curve info for air
                source heat pumps to improve the accuracy of hourly electrical load estimates associated with heat
                pumps. Without these inputs, default performance curves from HOT2000 are used to estimate the hourly
                electrical load of heat pumps.
            </p>
            <h3>Known Issues and Limitations</h3>
            <ul>
                <li>
                    The <strong>Favourite Systems</strong> functionality has not yet been added. This will allow users
                    to save favourite mechanical systems or sets of systems.
                </li>
                <li>
                    <strong>Multiple Systems</strong>, applicable to MURBs, and used for data collection purposes only,
                    have not yet been integrated into Volta SNAP.
                </li>
                <li>
                    The Energy Star list of HRVs and ERVs does not include system information in the exact format that
                    HOT2000 expects. Therefore, the following assumptions have been made:
                </li>
                <ul>
                    <li>
                        <strong>Cooling Efficiency</strong> information is not provided and is left as the default value
                        (25%).
                    </li>
                    <li>
                        <strong>Supply and Exhaust Air Flow</strong> are not provided and have both been overwritten
                        with the value &#34;Test airflow at 0°C CFM&#34; from the Energy Star database.
                    </li>
                    <li>
                        The <strong>Rated Fan Power at Temperature Condition 2 (-25˚C)</strong> is not provided in the
                        database, nor is the &#34;Fan Efficacy&#34; (in cfm/W) provided at -25˚C. The{" "}
                        <strong>Rated Fan Power at -25˚C</strong> was therefore estimated by dividing the provided flow
                        rate at -25˚C (cfm) by the provided Fan Efficacy at 0˚C (cfm/W). To obtain a more accurate
                        value, users will need to refer to the spec sheet for their HRV/ERV.
                    </li>
                </ul>
            </ul>
        </>
    );
};
