import { connect } from "react-redux";
import { reduxForm, formValueSelector, reset } from "redux-form";
import StandardCode from "./";
import crawlspaceWallCodeTemplate from "features/Model/Enclosure/Templates/crawlspaceWallCode.json";
import { actions as userActions } from "store/users";
import { actions as enclosureActions } from "features/Model/Enclosure/_ducks";
import { actions as upgradeActions } from "features/Model/Upgrades/_ducks";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import { getMatchCodeRefs } from "utils/enclosure/components";
import { standardCodeLogic } from "utils/codes";

const { addToCodeLib, deleteCode } = userActions;
const { setInitCode, getRValue } = enclosureActions;
const { updateUpgradeCodes } = upgradeActions;

const mapStateToProps = (
    {
        form,
        model: { modelId },
        user: { uid },
        enclosure: { initCodeValues: { ponyWallInsType = {} } = {} } = {},
        upgradeLibrary: { currentPackage, selectedUpgrade },
    },
    { fieldAccessor, modelFormChange, currentFormChange, toggleOpen, componentId }
) => {
    const selector = formValueSelector("ponyWallCode");
    const { id: crawlWallStructureId, value: crawlWallStructureValue = 0 } =
        selector({ form }, "ponyWallCode.layers.structureType") || {};
    const { value: crawlWallTypeSizeValue } = selector({ form }, "ponyWallCode.layers.componentTypeSize") || {};
    const { id: crawlWallSpacingId, value: crawlWallSpacingValue } =
        selector({ form }, "ponyWallCode.layers.spacing") || {};
    const { value: crawlWallFramingValue } = selector({ form }, "ponyWallCode.layers.framing") || {};
    const { value: crawlWallIns1Value } = selector({ form }, "ponyWallCode.layers.insulationLayer1") || {};
    const { value: crawlWallIns2Value } = selector({ form }, "ponyWallCode.layers.insulationLayer2") || {};
    const { value: crawlWallInteriorValue } = selector({ form }, "ponyWallCode.layers.interior") || {};
    const { value: crawlWallSheathingValue } = selector({ form }, "ponyWallCode.layers.sheathing") || {};
    const { value: crawlWallExteriorValue } = selector({ form }, "ponyWallCode.layers.exterior") || {};
    const { value: crawlWallStudsValue } = selector({ form }, "ponyWallCode.layers.studsCornerIntersection") || {};
    const crawlWallLabel = selector({ form }, "ponyWallCode.label") || "";

    const frameSpacingValue = crawlWallSpacingId === null ? crawlWallFramingValue : crawlWallSpacingValue;

    const crawlWallValue = `1${crawlWallStructureValue
        .toString()
        .substr(
            -1
        )}${crawlWallTypeSizeValue}${frameSpacingValue}${crawlWallIns1Value}${crawlWallIns2Value}${crawlWallInteriorValue}${crawlWallSheathingValue}${crawlWallExteriorValue}${crawlWallStudsValue}`;

    const { codeRef: editingCodeRef = null, isLibCode = false } = ponyWallInsType;

    const modelSelector = formValueSelector("model");
    const modelCodes = modelSelector({ form }, "modelData.codes") || {};
    const components = modelSelector({ form }, "modelData.components") || {};

    return {
        crawlWallLabel,
        fieldAccessor,
        modelFormChange,
        currentFormChange,
        initialValues: {
            ponyWallCode: isEmpty(ponyWallInsType) ? crawlspaceWallCodeTemplate : ponyWallInsType,
            addToLibrary: isLibCode,
        },
        crawlWallStructureId,
        crawlWallTypeSizeValue,
        isSolid: parseFloat(crawlWallStructureId) === 4,
        isPanel: parseFloat(crawlWallStructureId) === 5,
        makeSpacingDefault: ["5", "6", "7", "8", "9", "A", "B", "C"].includes(crawlWallTypeSizeValue),
        makeInsulationDefault: ["6", "7", "8", "9", "A", "B", "C"].includes(crawlWallTypeSizeValue),
        crawlWallValue,
        modelId,
        uid,
        editingCodeRef,
        isEditing: !!editingCodeRef,
        isLibCode,
        crawlWallSpacingId,
        toggleOpen,
        componentId,
        modelCodes,
        components,
        currentPackage,
        selectedUpgrade,
    };
};

const mapDispatchToProps = (dispatch) => ({
    clearForm: () => dispatch(reset("ponyWallCode")),
    clearInitCode: () => dispatch(setInitCode({}, "ponyWallInsType")),
});

const mergeProps = (state, dispatch, own) => ({
    ...state,
    ...dispatch,
    ...own,
});

const onSubmit = async (
    form,
    dispatch,
    {
        toggleOpen,
        uid,
        fieldAccessor,
        modelFormChange,
        currentFormChange,
        editingCodeRef,
        componentId,
        isEditing,
        modelCodes,
        components,
        currentPackage,
        selectedUpgrade,
    }
) => {
    const { ponyWallCode = {}, addToLibrary = false, selectedComponents = [] } = form;

    toggleOpen(false);

    standardCodeLogic({
        componentCodeType: "CrawlspaceWall",
        componentId,
        code: ponyWallCode,
        formInputName: "ponyWallInsType",
        codeType: "S",
        isCodeLibrary: false,
        isEditing,
        editingCodeRef,
        dispatch,
        getRValue,
        modelFormChange,
        currentFormChange,
        fieldAccessor,
        components,
        modelCodes,
        addToLibrary,
        addToCodeLib,
        uid,
        deleteCode,
        updateUpgradeCodes,
        selectedComponents,
        currentPackage,
        selectedUpgrade,
    });

    // Clear pony wall code defaults for next time
    dispatch(setInitCode({}, "ponyWallInsType"));
    // Reset form
    dispatch(reset("wallCode"));
};

const form = reduxForm({
    form: "ponyWallCode",
    enableReinitialize: true,
    onSubmit: onSubmit,
})(StandardCode);

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(form);
