import { connect } from "react-redux";
import Settings from "./";
import { actions } from "features/Model/Enclosure/_ducks";
import { formValueSelector } from "redux-form";

const { setInitCode, updateCodeDrawer } = actions;

const mapStateToProps = ({ form, user: { organization = {} } = {} }, { formName = "model" }) => {
    const selector = formValueSelector("model");
    const modelSelector = formValueSelector("model");

    return {
        organization,
    };
};

const mergeProps = (state, dispatch, own) => ({
    ...state,
    ...dispatch,
    ...own,
});

const mapDispatchToProps = (dispatch) => ({
    setInitCode: async (code, type) => {
        return dispatch(setInitCode(code, type));
    },
    updateCodeDrawer: (updates) => dispatch(updateCodeDrawer({ updates })),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Settings);
