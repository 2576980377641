import React, { useState, useEffect } from "react";
import classes from "../style.module.scss";
import Button from "components/Button";
import Input from "components/Input";
import { Link } from "react-router-dom";
import { checkValidity } from "utils/fields";
import { updateObject } from "utils";
import VoltaLogo from "assets/images/logo-color.svg";

export default ({ createUser, error }) => {
    const [valid, setValid] = useState(false);
    const [processing, toggleProcessing] = useState(false);

    useEffect(() => {
        toggleProcessing(false);
    }, [error]);

    const [regForm, setRegForm] = useState({
        name: {
            name: "name",
            value: "",
            type: "text",
            label: "Name",
            placeholder: "Your Name",
            valid: false,
            validation: {
                required: true,
            },
            validMessage: "",
            touched: false,
        },
        email: {
            name: "email",
            value: "",
            type: "email",
            label: "Email Address",
            placeholder: "Your Email",
            valid: false,
            validation: {
                required: true,
            },
            validMessage: "",
            touched: false,
        },
        password: {
            name: "password",
            value: "",
            type: "password",
            label: "Password",
            placeholder: "Your Password",
            valid: false,
            validation: {
                required: true,
                minLength: 6,
            },
            validMessage: "",
            touched: false,
        },
    });

    const onChangeHandler = (value, fieldName) => {
        const { isValid, message } = checkValidity(regForm[fieldName].label, value, regForm[fieldName].validation);

        const updatedControls = updateObject(regForm, {
            [fieldName]: updateObject(regForm[fieldName], {
                value,
                valid: isValid,
                validMessage: message,
                touched: true,
            }),
        });

        setValid(Object.values(updatedControls).every(({ valid }) => valid));
        setRegForm(updatedControls);
    };

    return (
        <div className={classes.authWrapper}>
            <div className={classes.formWrapper}>
                <div className={classes.top}>
                    <img src={VoltaLogo} alt="Volta Snap logo in colour" />
                    <h2>Contact Us</h2>
                </div>
                <div className={classes.form}>
                    <p>
                        Please contact{" "}
                        <a href="mailto:info@voltaresearch.org" target="_blank" rel="noopener noreferrer">
                            info@voltaresearch.org
                        </a>{" "}
                        to get set up with an account.
                    </p>
                    <p>
                        Already have an account? <Link to="/login">Log In.</Link>
                    </p>
                </div>
            </div>
            {/* <form className={classes.formWrapper}>
                <h2>Sign Up</h2>
                {
                    Object.values(regForm).map(({ name, type, label, valid, validMessage, touched, placeholder }) => (
                        <Input
                            key={name}
                            large 
                            name={name}
                            type={type} 
                            label={label}
                            placeholder={placeholder} 
                            hideLabel
                            invalid={!valid}
                            validMessage={validMessage}
                            touched={touched}
                            className={classes.input}
                            input={{
                                onChange:value => onChangeHandler(value, name)
                            }}
                        />
                    ))
                }
                <Button
                    disabled={!valid || processing}
                    type="submit"
                    onClick={event => {
                        toggleProcessing(true);
                        return createUser(event, regForm);
                    }}
                >
                    {processing ? "Processing" : "Sign Up"}
                </Button>
                {error !== null && <p className={classes.error}>{error}</p>}
                <p>Already have an account? <Link to='/login'>Log In.</Link></p>
            </form> */}
        </div>
    );
};
