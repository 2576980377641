import SectionHeading from 'components/SectionHeading';
import classes from './style.module.scss';
import React, { useCallback, useState, useRef, useEffect } from 'react';
import { getOptions } from 'utils/fields';
import { Field } from 'redux-form';
import {isEmpty} from 'lodash'
import { maxLength } from 'utils/fieldValidation';
import InputRow from 'components/Input/Row';
import Input from 'components/Input';
import Select from 'components/Input/Select';
import Button from 'components/Button';
import { checkValidity } from 'utils/fields';
import { updateObject } from 'utils';
import { changeUserPassword } from "utils/auth/api";
import { auth } from '_firebase';
import { mixpanel } from "components/Mixpanel";

const charLim200 = maxLength(200);

const initPassFormState = {
    oldPassword: {
        name:'oldPassword',
        value:'',
        type:'password',
        label:'Current Password',
        placeholder:'Your Current Password',
        valid:false,
        validation: {
            required: true,
            minLength: 6,
        },
        validMessage:'',
        touched:false
    },
    newPassword: {
        name:'newPassword',
        value:'',
        type:'password',
        label:'New Password',
        placeholder:'Your New Password',
        valid:false,
        validation: {
            required: true,
            minLength: 10,
            containLetter: true,
            containNumber: true,
            containSpecialChar: true,
        },
        validMessage:'',
        touched:false
    },
    confirmNewPassword: {
        name:'confirmNewPassword',
        value:'',
        type:'password',
        label:'Confirm New Password',
        placeholder:'Confirm Your New Password',
        valid:false,
        validation: {
            required: true,
            minLength: 10,
            containLetter: true,
            containNumber: true,
            containSpecialChar: true,
        },
        validMessage:'',
        touched:false
    },
}

export default () => {
    const [valid, setValid] = useState(false);
    const [error, setError] = useState("");
    const [newPasswordMatch, setNewPasswordMatch] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [submitBtnLabel, setSubmitBtnLabel] = useState("Change Password");
    const [passForm, setPassForm] = useState(initPassFormState);
 
    const onChangeHandler = (value, fieldName) => {
        const { isValid, message } = checkValidity(passForm[fieldName].label, value, passForm[fieldName].validation);
        
        const updatedControls = updateObject(passForm, {
            [fieldName]: updateObject(passForm[fieldName], {
                value,
                valid:isValid,
                validMessage:message,
                touched:true
            })
        });

        setValid(Object.values(updatedControls).every(({valid}) => valid))
        setPassForm(updatedControls);
    };

    useEffect(() => {
        if (passForm.newPassword.value === passForm.confirmNewPassword.value && passForm.confirmNewPassword.value !== "" && passForm.newPassword.value !== "") {
            setNewPasswordMatch(true);
        } else {
            setNewPasswordMatch(false);
        }
        setSubmitBtnLabel("Change Password");

        if (passForm.newPassword.value !== passForm.confirmNewPassword.value && passForm.confirmNewPassword.value !== "" && passForm.newPassword.value !== "") {
            setSubmitBtnLabel("New Passwords Must Match")
        }
    }, [passForm])

    const handlePasswordSubmit = async () => {
        // handleSubmit()
        setSubmitting(true)
        setSubmitBtnLabel('Submitting...');
        const response = await changeUserPassword({
            oldPassword: passForm.oldPassword.value,
            newPassword: passForm.newPassword.value
        });

        if (isEmpty(response.err)) {
            setSubmitting(false);
            setPassForm(initPassFormState);
            setError("");
            setSubmitBtnLabel('Password Changed!')
            setTimeout(() => {setSubmitBtnLabel('Logging out...')}, 1000);
            setTimeout(() => {
                mixpanel.track("Logout");
                mixpanel.reset();
                auth.signOut();
            }, 2000);
            
        } else {
            const {message: statusMessage = "Request failed with status code 500"} = response.err.toJSON()
            let errorCode = response?.err?.response?.data?.error || statusMessage;

            if (errorCode === "auth/wrong-password") {
                errorCode = "The old password provided is invalid."
            }

            setError(errorCode);
            setSubmitBtnLabel('Change Password');
        }
        
        

    };
        

    return (
        <div>
            <SectionHeading
                title="User Account"
                subtitle="Change your password"
            />
            <div className={classes.authWrapper}>
                <form className={classes.formWrapper}>
                    {
                        Object.values(passForm).map(({ name, type, label, valid, validMessage, touched, placeholder, value }) => (
                            <Input
                                key={name}
                                large 
                                name={name}
                                type={type} 
                                label={label}
                                placeholder={placeholder} 
                                // hideLabel
                                invalid={!valid}
                                validMessage={validMessage}
                                touched={touched}
                                className={classes.input}
                                input={{
                                    onChange:value => onChangeHandler(value, name),
                                    value:value
                                }}
                            />
                        ))
                    }
                    <Button
                        disabled={!valid || !newPasswordMatch || submitting}
                        type="submit"
                        onClick={event => {
                            setSubmitting(true);
                            handlePasswordSubmit()
                        }}
                    >
                        {submitBtnLabel}
                    </Button>
                    <div className={classes.errorMsg}>
                        { error && <p>{error}</p>}
                    </div>
                    <p>Your new password must be at least 10 characters long and contain at least one letter, number, and special character (e.g. !, $, &).</p>
                    <p>You will be logged out after successfully changing your password.</p>
                </form>
                
            </div>
        </div>
    )
}