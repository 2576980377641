import React from 'react';
import classes from '../style.module.scss';
import Button from 'components/Button';
import { calcPolygonValues, setLineBoundary } from 'utils/drawing/shapes';

const Polygon = ({
    cancel,
    onSet,
    addPolygon,
    activeComponent,
    activeImage,
    shape,
    setAction,
    stage,
    polygonCount
}) => (
    <div className={classes.polygonButtons}>
        <Button
            smallPadding
            onClick={cancel}
            type='hollow'
            className={classes.cancelButton}
        >
            Cancel
        </Button>
        <Button
            smallPadding
            onClick={async() => {
                const parentGroup = shape.getParent();
                const points = shape.points();
                const { perimeter, area } = calcPolygonValues({points});
                const imageShape = stage.findOne(`#${activeImage}`);
                const stageCoords = { x: stage.x(), y: stage.y() };

                parentGroup.setAttr('draggable', true);
                // I think it's okay to use these static points for now
                parentGroup.dragBoundFunc(pos => setLineBoundary(pos, imageShape, points, stageCoords, stage.scaleX()))

                await addPolygon(activeImage, {
                    polygonName: `Polygon ${polygonCount + 1}`,
                    id:activeComponent,
                    points,
                    perimeter,
                    area,
                    x:parentGroup.x(),
                    y:parentGroup.y(),
                });

                if (onSet) {
                    onSet(); // may not want to leave this all up to onSet???
                }

                await setAction({
                    id:'polygonFields',
                    meta:{
                        shape:shape.getParent(),
                    }
                });
            }}
            className={classes.saveButton}
            disabled={!onSet}
        >
            Set Polygon
        </Button>
    </div>
)

export default Polygon;