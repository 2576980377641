import React from "react";
import Tooltip from "components/Tooltip";
import linkOut from "assets/images/icons/linkOut.svg";
import uniqid from "uniqid";
import classes from "./style.module.scss";

const ChecklistLinkOut = ({ link, title = "" }) => {
    const id = uniqid("extLinkTooltip-");
    return (
        <button
            className={classes.linkOutContainer}
            onClick={(event) => {
                event.preventDefault();
                window.open(link, "_blank", "noreferrer");
            }}
            data-tip={title !== "" ? title : "Download document"}
            data-for={`${id}_roadmap`}
        >
            <img src={linkOut} alt="link out icon" />
            <div className={classes.linkOutText}>
                <span className={classes.linkOutMain}>{title !== "" ? title : "Download document"}</span>
                <span className={classes.linkOutSub}>{link}</span>
                <Tooltip id={`${id}_roadmap`} />
            </div>
            <Tooltip id={`${id}_roadmap`} />
        </button>
    );
};

export default ChecklistLinkOut;
